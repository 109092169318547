import { User } from './User'

/** Types of Suport Ticket - Feedback, Delete Request, or Clear EBT Holds */
export enum SupportTicketType {
  FEEDBACK = 'feedback',
  DELETE_REQUEST = 'delete-request',
  CLEAR_EBT_HOLD = 'clear-ebt-hold',
}

type SupportTicketBase = {
  // The document ID.
  id: string

  // The type of the support ticket
  type: SupportTicketType

  // The user who submitted the feedback.
  user?: Pick<User, 'id' | 'email' | 'name'>

  // The message left by the user.
  text: string

  // System information to help debug errors
  systemInfo: {
    appVersion: string
    deviceType: string
    browser: string
  }
}

// Feedback represents user-submitted feedback about the application.
export type Feedback = SupportTicketBase

export type DeleteRequest = SupportTicketBase & {
  type: SupportTicketType.DELETE_REQUEST
}

export type ClearCustomerHold = SupportTicketBase & {
  type: SupportTicketType.CLEAR_EBT_HOLD

  /** The customer that has the hold to be cleared */
  customer?: Pick<User, 'id' | 'email' | 'name'>

  /** A message about why the hold should be cleared */
  text: string
}

export type SupportTicket = Feedback | DeleteRequest | ClearCustomerHold

/** Returns a boolean checking if the ticket is of type ClearCustomerHold */
export function isClearEbtHoldTicket(ticket: SupportTicket): ticket is ClearCustomerHold {
  return ticket.type === SupportTicketType.CLEAR_EBT_HOLD
}
