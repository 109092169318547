import algoliasearch from 'algoliasearch'

import { AlgoliaIndexType } from '@models/Algolia'
import env from './Environment'

/** The search client object with our configuration */
export const searchClient = algoliasearch(env.ALGOLIA_ID, env.ALGOLIA_SEARCH_KEY)

/** The names of algolia indices for the current environment */
export const indexNameEnvName = {
  consumer: `${env.APP_ENV}_${AlgoliaIndexType.geosearch}`,
  admin: `${env.APP_ENV}_${AlgoliaIndexType.admindata}`,
}

/** The initialized search client for the specific index */
export const consumerIndex = searchClient.initIndex(indexNameEnvName.consumer)

/** The initialized search client for the specific index */
export const adminIndex = searchClient.initIndex(indexNameEnvName.admin)
