import { logIsFarmer } from '@api/FBAnalytics'
import { snapshotFarmAssociationsWithFarms } from '@api/FarmAssociations'
import { snapshotDeliveredPushNotifications } from '@api/Notifications'
import { registerForPushNotificationsAsync } from '@api/PushNotifications'
import { usersCollection } from '@api/framework/ClientCollections'
import { AvailAddonResult } from '@helpers/addons'
import { dequal } from '@helpers/customDequal'
import { CSA } from '@models/CSA'
import { Notification } from '@models/Notification'
import { FarmAssociation, isFarmAdmin } from '@models/User'
import { AnyAction, Dispatch } from 'redux'

import { AppPersist, RootState } from '../reducers/types'
import {
  ADD_UNSUBSCRIBE,
  SET_AVAIL_ADDONS,
  SET_AVAIL_ADDONS_CSAS,
  SET_AVAIL_ADDONS_PURCHASES,
  SET_MY_FARMS,
  SET_NOTIFICATIONS,
  SET_USER_LOCATION,
  SET_WHOLESALE,
  UPDATE_CART_INFO,
  UPDATE_CART_INFO_ORDER_CREATOR,
} from './actionTypes'
import { setCurrFarmAdmin, setIsAdmin } from './adminPersist'

import { AddonsPurchases, CurrentLocation } from '@/constants/types'
import { auth } from '@api/db'
import { PartialPick } from '@helpers/typescript'

export const loadFarmAssociations = (userId: string) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const unsubscribe = snapshotFarmAssociationsWithFarms(userId, (farmAssociations: FarmAssociation[]) => {
      if (farmAssociations.length === 0) {
        dispatch(setIsAdmin(false))
        return
      }

      ////When fassocs change, we may need to change the current admin farm listener.

      // Look for the current admin farm, either adminFarmId or the first farm that is an admin or manager in farmAssociations
      const adminFarmId = getState().adminPersist.adminFarmId
      const adminFarmAssociation = farmAssociations.find((fassoc) =>
        adminFarmId ? fassoc.id === adminFarmId : isFarmAdmin(fassoc),
      )

      // set isAdmin
      const isAdmin = getState().adminPersist.isAdmin
      if (isAdmin !== !!adminFarmAssociation) dispatch(setIsAdmin(!!adminFarmAssociation))

      //If a fassoc is found, set it as current farm admin
      if (adminFarmAssociation) {
        setCurrFarmAdmin(adminFarmAssociation.id)(dispatch, getState)
      } else {
        //if no fassoc found, remove any farm listener
        getState().adminState.farmUnListener?.()
      }

      // Track user as farmer in analytics
      logIsFarmer(!!adminFarmAssociation)

      //Check if there's changes to fassocs
      const current = getState().appPersist.farmsAssoc
      const updates = farmAssociations.filter((d) => current.every((cd) => !dequal(d, cd)))
      if (!updates.length) return

      // if there are role changes on farmAssociations, the auth id token should be refreshed
      if (
        updates.some((farmAssociation) => {
          const oldFarmAssociation = current.find((cd) => cd.id === farmAssociation.id)

          // role has changed to one of the admin roles
          const adminRoleChange = farmAssociation.role !== oldFarmAssociation?.role && isFarmAdmin(farmAssociation)

          // role has been stripped of as admin role
          const adminRoleRemoved =
            oldFarmAssociation && isFarmAdmin(oldFarmAssociation) && !isFarmAdmin(farmAssociation)

          return adminRoleChange || adminRoleRemoved
        })
      ) {
        auth().refreshIdToken()
      }

      dispatch({
        type: SET_MY_FARMS,
        payload: farmAssociations,
      })
    })
    dispatch({
      type: ADD_UNSUBSCRIBE,
      payload: unsubscribe,
    })
  }
}

export const LIMIT_N_NOTIFICATIONS = 10

export const loadNotifications = (userId: string) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    // Register for notifications, we should register every time the app is run
    // to make sure the token is up-to-date. But we will only store it if it has changed
    const user = getState().user
    registerForPushNotificationsAsync().then((note_token) => {
      if (note_token && note_token.length > 0 && user?.notificationToken !== note_token) {
        usersCollection.update({ id: userId, notificationToken: note_token })
      }
    })

    const unsubscribe = snapshotDeliveredPushNotifications(
      userId,
      (notifs: Notification[]) => {
        const current = getState().appPersist.notifications
        if (dequal(notifs, current)) return

        dispatch({
          type: SET_NOTIFICATIONS,
          payload: { notifs, userId, userNoteCount: getState().user.notificationBadgeCount },
        })
      },
      LIMIT_N_NOTIFICATIONS,
    )
    dispatch({
      type: ADD_UNSUBSCRIBE,
      payload: unsubscribe,
    })
  }
}

/** Sets the current location in redux.
 * @param newPartialLoc is meant to be a partial object with the keys 'coordinate' and 'city'. If defined, the partial object will override the current state. If null, the current location will be cleared.
 */
export const setCurrLocation = (newPartialLoc: PartialPick<CurrentLocation, 'coordinate'> | null) => {
  return (dispatch: Dispatch) => {
    // This is a check to ensure this value is correct in case the appPersist type ever changes
    const newValue: AppPersist['userLocation'] = null

    if (!newPartialLoc) {
      return dispatch({
        type: SET_USER_LOCATION,
        payload: newValue,
      })
    }

    const newLoc: CurrentLocation = {
      coordinate: newPartialLoc.coordinate,
      city: newPartialLoc.city ?? '',
      timestamp: Date.now(),
    }

    dispatch({
      type: SET_USER_LOCATION,
      payload: newLoc,
    })
  }
}

export const setAvailAddonsPurchasesAction = (addons: AddonsPurchases) => ({
  type: SET_AVAIL_ADDONS_PURCHASES,
  payload: addons,
})

export const setAvailAddonsAction = (addons: AvailAddonResult[]) => ({
  type: SET_AVAIL_ADDONS,
  payload: addons,
})

export const setAvailAddonsCSAs = (csas: CSA[]) => ({
  type: SET_AVAIL_ADDONS_CSAS,
  payload: csas,
})

/** Updates the cart info state for either the consumer or admin cart */
export const updateCartInfo = ({
  sessionId,
  cartId,
  isAdmin = false,
}: {
  sessionId: string
  cartId: string
  isAdmin?: boolean
}): { type: string; payload: AppPersist['cartInfo'] } => ({
  type: isAdmin ? UPDATE_CART_INFO_ORDER_CREATOR : UPDATE_CART_INFO,
  payload: { sessionId, cartId },
})

/** Sets partial wholesale data */
export const setWholesale = (data: Partial<AppPersist['wholesale']>): AnyAction => ({
  type: SET_WHOLESALE,
  payload: data,
})
