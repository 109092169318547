import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { adminFarmIdSelector, adminFarmSelector } from '../../../redux/selectors'
import { DashboardSummaryResult } from '../../components/Analytics/types'
import { AnalyticsPeriodInfoRecord, DateValue } from '../../components/Analytics/util'

import { useApiFx } from '@/hooks/useApiFx'
import { useHasPermissionWithFlag } from '@/hooks/useHasPermission'
import useKeyedState from '@/hooks/useKeyedState'
import { fetchAnalytics, fetchDashboardAnalytics } from '@api/Analytics'
import { AccessRight, Permission } from '@helpers/Permission'
import { DatePeriods, convertPeriodToRange } from '@helpers/time'
import { Farm } from '@models/Farm'
import { DateRange } from '@models/Schedule'
import { DashboardDataFlags } from '@shared/types/Analytics'

export const useAdminDashboardData = () => {
  const farm = useSelector(adminFarmSelector)
  const adminFarmId = useSelector(adminFarmIdSelector)

  const hasAccessCreateOrder = useHasPermissionWithFlag(Permission.Orders, AccessRight.Edit)
  const hasAccessProductSetup = useHasPermissionWithFlag(Permission.ProductSetup, AccessRight.Edit)
  const hasAccessAccounting = useHasPermissionWithFlag(Permission.Accounting)
  const hasAccessDistributions = useHasPermissionWithFlag(Permission.Distributions)

  const [{ period, range, useCache }, set, setState] = useKeyedState<{
    period: DatePeriods
    range: Partial<DateRange>
    /** useCache here determine whether we use cache table or latest table to run query and get data. */
    useCache: boolean
  }>({
    period: DatePeriods.This_Year,
    range: convertPeriodToRange(DatePeriods.This_Year, farm.timezone)!,
    useCache: true,
  })

  const dashboardFlags: DashboardDataFlags | undefined = useMemo(() => {
    const permissionsNotDefined = [hasAccessProductSetup, hasAccessAccounting, hasAccessDistributions].some(
      (perm) => perm === undefined,
    )
    // The permissions can be undefined as they can be loading, so don't fetch data until this is defined
    if (permissionsNotDefined) return undefined

    return {
      inventory: hasAccessProductSetup!,
      payouts: hasAccessAccounting!,
      openInvoices: hasAccessAccounting!,
      distributions: hasAccessDistributions!,
      orders: hasAccessProductSetup!,
    }
  }, [hasAccessProductSetup, hasAccessAccounting, hasAccessDistributions])

  const dashboardFx = useApiFx(
    fetchDashboardAnalytics,
    [farm.id, dashboardFlags, useCache],
    !!farm && adminFarmId === farm.id && !!dashboardFlags,
    {
      noRefocus: true,
    },
  )

  const dashboardSummaryFx = useApiFx(
    fetchDashboardSummary,
    [farm, period, { startDate: range.startDate!, endDate: range.endDate! }, useCache],
    !!farm && adminFarmId === farm.id && !!range.startDate && !!range.endDate,
    {
      noRefocus: true,
    },
  )

  const onRangePickerUpdate = useCallback(
    (period: DatePeriods, range: Partial<DateRange>) => {
      setState({ period, range, useCache })
    },
    [setState, useCache],
  )

  /** Refresh the data with live data */
  const onUseLiveDataClick = useCallback(() => {
    if (useCache) set('useCache', false)
    else {
      dashboardFx.refresh()
      dashboardSummaryFx.refresh()
    }
  }, [dashboardFx, dashboardSummaryFx, set, useCache])

  return {
    dashboardFx,
    dashboardSummaryFx,
    hasAccessAccounting,
    hasAccessCreateOrder,
    hasAccessProductSetup,
    hasAccessDistributions,
    farm,
    useCache,
    adminFarmId,
    period,
    range,
    onRangePickerUpdate,
    onUseLiveDataClick,
  }
}

export type UseAdminDashboardDataType = ReturnType<typeof useAdminDashboardData>

const fetchDashboardSummary = async (
  farm: Farm,
  period: DatePeriods,
  range: DateRange,
  useCache: boolean,
): Promise<DashboardSummaryResult> => {
  const res = await fetchAnalytics(
    'dashboardSummary',
    rangeInfoToDateValue(period, range),
    farm.id,
    farm.timezone,
    useCache,
  )
  return res as DashboardSummaryResult
}

/** Temporary implementation until DateValue is completely removed */
const rangeInfoToDateValue = (period: DatePeriods, range: DateRange): DateValue => {
  const { startDate, endDate } = range
  return {
    startDate: startDate.toJSDate(),
    endDate: endDate.toJSDate(),
    ...AnalyticsPeriodInfoRecord[period],
  }
}
