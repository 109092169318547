import { isLabelAccessible } from './helpers/helpers'
import { formStyles, useDynamicFormStyles } from './helpers/styles'
import { FormSharedProps } from './helpers/types'
import { View } from 'react-native'
import { DateTimePicker, DateTimePickerProps, ErrorText, Text } from '@elements'
import * as React from 'react'
import { isValidElement, ReactNode } from 'react'
import { isWeb } from '../../../constants/Layout'

type FormDateTimeInputType = FormSharedProps &
  DateTimePickerProps & {
    // Props specific to form inputs that will be rendered alongside the inputs
    errorMessage?: string
    label?: string | ReactNode
  }

/** This input component can be used to produce consistently styled picker inputs
 *
 * - If there's no placeholder, or it's loading, it will have a default placeholder with empty string as value, to prevent interference with Yup validation schemas */
export function FormDateTimeInput({ label, errorMessage, row, containerStyle, ...props }: FormDateTimeInputType) {
  const styles = useDynamicFormStyles(!!errorMessage, !!row)

  return (
    <View style={[isWeb ? formStyles.elementWrapper : formStyles.elementWrapperMobile, containerStyle]}>
      {isLabelAccessible(label) ? (
        <Text style={formStyles.inputLabel}>{label || ''}</Text>
      ) : isValidElement(label) ? (
        label
      ) : null}
      <DateTimePicker {...props} containerStyle={styles.inputContainer} />
      {!!errorMessage && <ErrorText style={formStyles.elementErrorStyle}>{errorMessage}</ErrorText>}
    </View>
  )
}
