import { StyleSheet, ViewStyle } from 'react-native'

import { Touchable, TouchableProps } from './elements/Touchable'
import Colors from '../constants/Colors'

type Props = {
  style?: ViewStyle | ViewStyle[]
  small?: boolean
}

export default function CircleButton({ style, children, ...rest }: Props & TouchableProps) {
  return (
    <Touchable activeOpacity={0.6} style={[styles.circleBtn, style]} {...rest}>
      {children}
    </Touchable>
  )
}

const styles = StyleSheet.create({
  circleBtn: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white,
    width: 50,
    height: 50,
    borderRadius: 50,
  },
})
