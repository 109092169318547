import { typography } from '@elements'
import { StyleSheet } from 'react-native'
import Colors from '../../../../constants/Colors'
import { useFnStyles } from '../../../../hooks/useFnStyles'

export const formStyles = StyleSheet.create({
  elementWrapper: {
    padding: 10,
    paddingTop: 0,
    flex: 1,
  },
  elementWrapperMobile: {
    padding: 10,
    paddingTop: 0,
    flex: 1,
  },
  inputLabel: {
    fontSize: 16,
    fontWeight: undefined,
    color: Colors.shades[600],
    fontFamily: typography.body.regular,
  },
  inputContainer: {
    borderColor: Colors.shades['100'],
    borderWidth: 1,
    borderRadius: 10,
    marginTop: 8,
  },
  inputStyle: {
    padding: 10,
  },
  error: {
    color: Colors.red,
    fontFamily: typography.body.medium,
  },
  elementErrorStyle: {
    marginLeft: 5,
  },
})

export const useDynamicFormStyles = (error: boolean, row: boolean) =>
  useFnStyles(
    (hasErrorMessage, rowInput) => ({
      inputContainer: {
        ...formStyles.inputContainer,
        borderColor: hasErrorMessage ? Colors.red : Colors.shades['100'],
      },
      elementInputContainer: {
        ...formStyles.inputContainer,
        // For picker element we don't want to show the border as that is handled by the element itself
        borderColor: hasErrorMessage ? Colors.red : Colors.shades['100'],
        height: 41.5,
      },
      inputStyle: {
        ...formStyles.inputStyle,
        ...(rowInput && { width: '100%' }),
      },
      main: {
        minWidth: 50, // This fixes the focus overflow border on web, where on small widths would exceed its parent container
      },
    }),
    error,
    row,
  )
