import { adminPermissionsSelector } from '@/redux/selectors'
import { AccessRight, Permission, hasFarmAdminPermission } from '@helpers/Permission'
import { useSelector } from 'react-redux'
import { useDeepCompareCallback, useDeepCompareMemo } from './useDeepEqualEffect'

/** Provides a helper that checks whether the current user has a given permission
 * This hook should be used inside the admin side only.
 * This hook will return the helper function that checks the permission
 */
export function useHasPermission(): (permission: Permission, type?: AccessRight.View | AccessRight.Edit) => boolean {
  const permissionData = useSelector(adminPermissionsSelector)

  return useDeepCompareCallback(
    (permission, type = AccessRight.View) => {
      return hasFarmAdminPermission(permissionData, permission, type)
    },
    [permissionData],
  )
}

/** Provides a helper that checks whether the current user has a given permission
 * - This hook should be used inside the admin side only. This should pass the permission as a argument and return boolean directly. If you know you will need to have same permissions check across entire page. then this hooks should be used.
 * - Will return `undefined` if permission data is not loaded
 */
export function useHasPermissionWithFlag(
  permission: Permission,
  type?: AccessRight.View | AccessRight.Edit,
): boolean | undefined {
  const permissionData = useSelector(adminPermissionsSelector)

  return useDeepCompareMemo(() => {
    return !permissionData ? undefined : hasFarmAdminPermission(permissionData, permission, type)
  }, [permission, permissionData, type])
}
