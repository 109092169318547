import { CreateUnitOpts, createUnitPrompt } from '@/admin/screens/Products/ProductForm/helpers/baseUnitAdd.helper'
import { Logger } from '@/config/logger'
import Colors from '@/constants/Colors'
import { ModalComponentProps } from '@/constants/types/modalTypes'
import { useApiFx } from '@/hooks/useApiFx'
import useKeyedState from '@/hooks/useKeyedState'
import { ADD_NEW_KEYWORD, AddNewDropdownItem, Button, ErrorText, Modal, Picker, Text, hideModal } from '@elements'
import { bullet } from '@helpers/display'
import { errorToString, isNonNullish, nonEmptyString } from '@helpers/helpers'
import { ReplaceOptional } from '@helpers/typescript'
import { PickerItemProps } from '@react-native-picker/picker'
import { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { BulkEditModalGenericProps } from './products/product'

type Doc = { id: string }

export type BulkEditSelectorCompProps<T extends Doc> = BulkEditModalGenericProps<T, string | undefined> & {
  /** fetches the selector options for this field */
  getSelectorOptions: () => Promise<PickerItemProps<string>[]>
  /** if passed, the picker will include a "Add" item, which opens the create unit prompt */
  createUnitOpts?: Pick<CreateUnitOpts, 'farmId' | 'type' | 'message'> & {
    /** If createUnitOpts are provided, it also needs a way to reopen this modal once the creation is done in the creation modal */
    reopen: () => void
  }
}

function BulkEditSelectorComp<T extends Doc>({
  ids,
  fetchByIds,
  getField,
  updateField,
  validate,
  onPassValidation,
  fieldNameDisplay,
  getItemDisplayName = (doc) => {
    if ('name' in doc && nonEmptyString(doc.name)) return doc.name
    return doc.id
  },
  getSelectorOptions,
  createUnitOpts,
}: BulkEditSelectorCompProps<T>) {
  const [{ isSubmitting, submitError, validationErrors }, set, setState] = useKeyedState({
    isSubmitting: false,
    submitError: undefined as unknown,
    validationErrors: [] as { error: unknown; id: string }[],
    /** the currently selected item from the picker (field value option) */
  })

  const { data, loading: isFetchingData, setState: setDataState } = useApiFx(fetchByIds, [ids])

  const { data: selectorOptions, loading: isFetchingOptions } = useApiFx(getSelectorOptions, [])

  const isLoading = isSubmitting || isFetchingData || isFetchingOptions

  /** This tells us whether the field exists within all the items selected */
  const { allSelectedSupportField, itemsWithNoField, noneSelectedSupportField } = useMemo(() => {
    const itemsWithNoField = data?.filter((doc) => getField(doc) === 'Field_not_exist') || []

    return {
      allSelectedSupportField: itemsWithNoField.length === 0,
      itemsWithNoField,
      noneSelectedSupportField: itemsWithNoField.length === data?.length,
    }
  }, [data, getField])

  const currentValue = useMemo(() => {
    if (!data) return ''
    // get the field value for each item that supports the field
    const fieldData = data.map(getField).filter((v): v is string | undefined => v !== 'Field_not_exist')

    // if all are defined and are the same, set the value. Else empty string will show no selected value on the picker
    if (!fieldData.every((v, _, arr) => nonEmptyString(v) && v === arr[0])) {
      return ''
    } else return fieldData[0] || ''
  }, [data, getField])

  const placeholder = `Select ${fieldNameDisplay}`

  /** applies the new value to the field in all the documents */
  const applyValueToData = (newVal: string) => {
    if (!data) return
    const newData = data.map((doc) => {
      return updateField(newVal, doc)
    })
    setState((p) => ({ ...p, validationErrors: [], submitError: undefined }))
    setDataState((s) => ({ ...s, data: newData }))
  }

  const onSelect = (selectedVal: string) => {
    // if createUnit options were provided, the value from the picker could be either the "new" option, or a regular option.
    if (createUnitOpts && selectedVal === ADD_NEW_KEYWORD) {
      // if creation options are defined, this must handle the case where they select the "new" option.
      // if they selected the "new" option, this will close the current modal and open a prompt, then reopen this on save
      createUnitPrompt({
        type: createUnitOpts.type,
        farmId: createUnitOpts.farmId,
        delayOnIos: 1000,
        onSave: createUnitOpts.reopen,
        message: createUnitOpts.message || fieldNameDisplay,
        hideOnSuccess: false,
        modalComponentProps: {
          onDismiss: createUnitOpts.reopen,
          goBack: createUnitOpts.reopen,
        },
      })
    } else if (selectedVal !== placeholder) {
      // if they're not creating a new unit, and they didn't choose the placeholder, update the data
      applyValueToData(selectedVal)
    }
  }

  const onSubmit = async () => {
    if (!data) return
    setState((p) => ({ ...p, isSubmitting: true, validationErrors: [], submitError: undefined }))
    const errors = data
      .filter((obj) => {
        // only need to validate those for which the field exists, because only those got updated
        return getField(obj) !== 'Field_not_exist'
      })
      .map((obj) => {
        try {
          validate(obj)
        } catch (error) {
          return { error, id: obj.id }
        }
      })
      .filter(isNonNullish)

    if (errors.length) {
      setState((p) => ({ ...p, isSubmitting: false, validationErrors: errors }))
      return
    }

    try {
      await onPassValidation(data)
      hideModal()
    } catch (error) {
      Logger.error(error)
      set('submitError', error)
    } finally {
      set('isSubmitting', false)
    }
  }

  const errorText = useMemo(() => {
    let txt = ''
    if (!data) return ''
    if (validationErrors.length) {
      txt += 'Some items are incompatible with these edits: \n\n'
      validationErrors.forEach(({ id, error }) => {
        txt += ` ${bullet} ${getItemDisplayName(data.find((p) => p.id === id)!)}: ${errorToString(error)}\n`
      })
    } else if (submitError) {
      txt += 'There was a problem submitting this request. Try again \n\n'
    }
    return txt
  }, [validationErrors, submitError, getItemDisplayName, data])

  const warning = useMemo(() => {
    let txt = ''
    if (noneSelectedSupportField) {
      txt += `None of the items in your selection support the field "${fieldNameDisplay}". Update your selection or choose a different field.`
    } else if (!allSelectedSupportField) {
      txt += `The field "${fieldNameDisplay}" is not supported by the following items: \n\n`
      itemsWithNoField.forEach((doc) => {
        txt += ` ${bullet} ${getItemDisplayName(doc)}\n`
      })
      txt += '\n\nYour edits will be applied to the rest of your selection.'
    }
    return txt
  }, [allSelectedSupportField, itemsWithNoField, fieldNameDisplay, getItemDisplayName, noneSelectedSupportField])

  const pickerItems = useMemo<PickerItemProps<string>[]>(
    () => (selectorOptions || []).concat(createUnitOpts ? [AddNewDropdownItem] : []),
    [selectorOptions, createUnitOpts],
  )

  return (
    <View style={styles.main}>
      <View style={styles.content}>
        {!!warning && (
          <Text style={styles.warningText} color={Colors.brown}>
            {warning}
          </Text>
        )}
        <Picker
          value={currentValue}
          items={pickerItems}
          onValueChange={onSelect}
          placeholder={placeholder}
          disabled={isLoading || noneSelectedSupportField}
          useWebNativePicker // the picker inside the modal NEEDS to use the regular picker, because the bottomsheet will show behind the modal
        />
      </View>
      <Button title="Update multiple" onPress={onSubmit} loading={isLoading} disabled={noneSelectedSupportField} />
      {errorText ? <ErrorText>{errorText}</ErrorText> : null}
    </View>
  )
}

type UnitCreationOptsWithoutReopen = Omit<NonNullable<BulkEditSelectorCompProps<any>['createUnitOpts']>, 'reopen'>

export type OpenBulkEditSelectorOpts<T extends Doc> = ModalComponentProps & {
  contentProps: ReplaceOptional<BulkEditSelectorCompProps<T>, 'createUnitOpts', UnitCreationOptsWithoutReopen>
}

/** opens the bulk edit modal for single-select fields */
export function openBulkEditSelector<T extends Doc>({
  contentProps: { createUnitOpts, ...contentPropsRest },
  ...modalComponentProps
}: OpenBulkEditSelectorOpts<T>) {
  const openModal = () =>
    Modal(
      <BulkEditSelectorComp
        {...contentPropsRest}
        // if createUnitOpts are provided, this should fill in the missing "reopen" property. Otherwise nothing should be passed
        createUnitOpts={createUnitOpts ? { ...createUnitOpts, reopen: openModal } : undefined}
      />,
      modalComponentProps,
    )

  openModal()
}

const styles = StyleSheet.create({
  main: {
    padding: 10,
    alignContent: 'center',
  },
  content: {
    padding: 15,
  },
  warningText: {
    backgroundColor: Colors.shadeGold,
    borderRadius: 20,
    padding: 10,
    margin: 10,
  },
})
