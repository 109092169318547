import { toComparable } from '@/hooks/useSort'

// flattenObject function is to flatten the object to an single level object.
export function flattenObject(obj: { [key: string]: any }): any {
  const flattened: { [key: string]: any } = {}
  Object.keys(obj).forEach((key) => {
    const value = obj[key]
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      Object.assign(flattened, flattenObject(value))
    } else {
      flattened[key] = value
    }
  })
  return flattened
}

export type SortRecord = { field?: string; asc: boolean }

//Sort Function for Admin Table //TODO: sort issue with Algolia pagination need to be addressed in the future.
export function customSortFun(
  arrObj: any,
  field: any,
  sortRecord: SortRecord,
  setSortRecord: (value: SortRecord) => void,
  setUpdatedHits: (value: any) => void,
) {
  const arrayObject = [...arrObj]
  let tempRecord: { field?: string; asc: boolean } = { asc: true }

  //check if the field in every object exists
  arrayObject.forEach((item, index) => {
    const object = flattenObject(item)
    if (!object[field] && field) {
      arrayObject[index][field] = ''
    }
  })

  arrayObject.sort((a, b): number => {
    if (sortRecord.field === field && sortRecord.asc) {
      tempRecord = { field, asc: false }
      toComparable(flattenObject(a)[field])
      toComparable(flattenObject(b)[field])
      if (flattenObject(a)[field] > flattenObject(b)[field]) {
        return -1
      } else if (flattenObject(a)[field] < flattenObject(b)[field]) {
        return 1
      } else {
        return 0
      }
    } else if (sortRecord.field === field && !sortRecord.asc) {
      toComparable(flattenObject(a)[field])
      toComparable(flattenObject(b)[field])
      tempRecord = { field, asc: true }

      if (flattenObject(a)[field] < flattenObject(b)[field]) {
        return -1
      } else if (flattenObject(a)[field] > flattenObject(b)[field]) {
        return 1
      } else {
        return 0
      }
    }

    if (sortRecord.field !== field) {
      tempRecord = { field, asc: true }
      toComparable(flattenObject(a)[field])
      toComparable(flattenObject(b)[field])
      if (flattenObject(a)[field] < flattenObject(b)[field]) {
        return -1
      } else if (flattenObject(a)[field] > flattenObject(b)[field]) {
        return 1
      } else {
        return 0
      }
    }
    return 0
  })

  setSortRecord(tempRecord)

  setUpdatedHits(arrayObject)
}
