import { memo } from 'react'
import { Media } from '@models/shared/Media'
import { StyleSheet, View } from 'react-native'
import { TextH3, TextH4, typography } from '@elements'
import Colors from '../../../../constants/Colors'
import { AddImages } from '@components'

/** Component for displaying and add images  */
export const PhotoSection = memo(function PhotoSection({
  onMediaChange,
  value,
  disabled = false,
}: {
  onMediaChange: (value: Media[]) => void
  value: Media[]
  disabled?: boolean
}) {
  return (
    <View style={styles.container}>
      <View style={styles.photosHeader}>
        <TextH3 size={18} color={Colors.black}>
          Photos*
        </TextH3>
        <TextH3 size={18} color={Colors.black}>
          {value?.length}/5
        </TextH3>
      </View>
      <View style={styles.marginHorizontal}>
        <TextH4 color={Colors.shades[200]} style={styles.textH4}>
          Horizontally oriented images work best.
        </TextH4>
        <TextH4 color={Colors.shades[200]} style={styles.textH4}>
          Recommended Aspect Ratio is 4:3 (1280x960)
        </TextH4>
      </View>
      <View style={styles.photoButtonWrapper}>
        <AddImages media={value} setMedia={onMediaChange} maxImages={5} disabled={disabled} />
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  photoButtonWrapper: {
    maxWidth: '100%',
    marginLeft: 10,
  },
  marginHorizontal: { marginHorizontal: 15 },
  textH4: { fontWeight: '400', fontFamily: typography.body.regular, flexWrap: 'wrap' },
  photosHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    paddingBottom: 15,
    paddingTop: 25,
  },
  container: {
    flexWrap: 'wrap',
    maxWidth: '100%',
  },
})
