import {
  cartAddDiscount,
  cartAdd as cartApiAdd,
  cartInit,
  cartRemoveDiscount,
  cartUpdateItem,
  cartUpdatePaymentSchedule,
  cartUpdateQuantity,
  cartUpdateVariableWeightQuantity,
} from '@api/Cart'
import { logAddToCart } from '@api/FBAnalytics'
import { Toast } from '@elements'
import { buildCartItem } from '@helpers/builders/buildCartItem'
import { canUpdateQuantity, throwItemQuantityError } from '@helpers/canUpdateQuantity'
import mergeDeep from '@helpers/mergeDeep'
import { Cart } from '@models/Cart'
import { Discount, PromoCode } from '@models/Coupon'
import { CartItem } from '@models/Order'
import { PaymentSchedule } from '@models/Product'
import { InsufficientStockError, OutOfStockError } from '@shared/errors/cart'
import { UpdateCartItemFields } from '@shared/types/v2/cart'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { insufficientStockAlert, outOfStockAlert } from '../../hooks/useCart'
import { AddToCartServiceOpts, CartService, CartServiceType } from '../types/cartService'

import { Logger } from '@/config/logger'
import { useCancelableFx } from '@/hooks/useCancelablePromise'
import { useCartCustomer } from '@/hooks/useCart/useCartCustomer'
import { useCartInfo } from '@/hooks/useCart/useCartInfo'
import { setCartCustomer } from '@/redux/actions/adminState'
import { updateCartInfo } from '@/redux/actions/appPersist'
import { setCartService, setNavProps } from '@/redux/actions/appState'
import { cartsCollection } from '@api/framework/ClientCollections'
import { formatMoney } from '@helpers/display'
import { extendErr, retry } from '@helpers/helpers'
import { MoneyCalc } from '@helpers/money'
import { cartSubtotal } from '@helpers/order'
import { sortCartItems } from '@helpers/sorting'
import { entries, values } from '@helpers/typescript'
import { getUnadjustedQuantity } from '@helpers/cart'

type CartServiceOpts = {
  /** The cart service reference for the cart to manage */
  cartServiceType?: CartServiceType
  /** Whether this cart service should operate in wholesale mode */
  isWholesale?: boolean
  /** The cart id of a draft cart. This is required for order edit and will only be used if the cart service type is set to order edit */
  draftCartId?: Cart['id']
}

/** Provides helpers which facilitate interacting with the shopping cart service. */
export function useSetCartService({
  cartServiceType = 'consumer',
  isWholesale = false,
  draftCartId,
}: CartServiceOpts = {}): CartService {
  const [isAdmin, setIsAdmin] = useState(cartServiceType === 'orderCreator')
  const dispatch = useDispatch()
  const customer = useCartCustomer({ cartServiceType })
  const { cartId, sessionId } = useCartInfo({ cartServiceType, draftCartId })
  const [discounts, setDiscounts] = useState<Cart['discounts']>({})
  const [items, setItems] = useState<CartItem[]>([])
  const [loading, setLoading] = useState(true)

  /** Initialize cart session, and update it whenever user id changes */
  useCancelableFx(
    async (isCurrent) => {
      if (cartServiceType === 'orderEdit') {
        // We don't initialize a new cart if this is for order editing, because that means the cart already exists, and we only need to listen to it
        return
      }
      /** TODO: This will be run twice when the redux state is cleared (signout or new session) because initially the sessionId is undefined, and a new one will be generated triggering the effect to rerun. This will only happen twice, so we are leaving it for now, but we should optimise this in the future by not having the sessionId change trigger a new run of cartInit. */

      const userId = customer?.id || undefined //Prevent an empty string from being sent as user id, given that the user redux state might be initialized as a dummy user object

      // We define isAdmin here instead of using the outer isAdmin state because this effect is intended to initialize a new cart based on the cartServiceType. The isAdmin state on the other hand is meant to be derived from the cart initialized; and therefore the isAdmin state should not be passed as an argument to cartInit because it is not the source of truth. Also the isAdmin state should not be a dependency of this hook because it might cause infinite loops
      const isAdmin = cartServiceType === 'orderCreator'

      const { cartId, sessionId: newSessionId } = await cartInit({
        sessionId,
        userId,
        isAdmin,
      })
      if (!isCurrent) return

      dispatch(updateCartInfo({ sessionId: newSessionId, cartId, isAdmin }))
    },
    [customer?.id, sessionId, dispatch, cartServiceType],
    () => Toast(cartId ? "Couldn't update the cart" : "Couldn't initialize the cart"),
  )

  /** Listens to the cart data and manages the local state for it */
  useEffect(() => {
    // Should restart loading when the cartId changes
    setLoading(true)

    if (!cartId) {
      // If undefined, should reset the data and abort. Loading should continue as true because the cart is not initialized yet, or the draft cart id might be loading
      // This may happen if there is a loading error in the draft order or draft cart api calls, after a param change
      setIsAdmin(false)
      setItems([])
      setDiscounts({})
      if (cartServiceType !== 'consumer') {
        /** We clear the cart customer to prevent possibly showing outdated data
         * - It won't do it for the consumer cart because that customer is managed by auth
         * - It will clear the redux state for order creator and order edit carts
         * - If a customer gets removed, it will trigger the cartInit fx, but the cartInit service is expected to only remove it from the DB cart document if it's an order creator cart (admin and not draft).
         */
        dispatch(setCartCustomer(undefined, cartServiceType))
      }
      return
    }

    return cartsCollection.snapshotDoc(cartId, (cartSnap) => {
      if (!cartSnap) {
        setIsAdmin(false)
        setItems([])
        setDiscounts({})
        setLoading(false)
        return
      }
      setIsAdmin(cartSnap.isAdmin)
      setItems(values(cartSnap.items).sort(sortCartItems))
      setDiscounts(cartSnap.discounts)
      setLoading(false)
    })
  }, [cartId, dispatch, cartServiceType])

  /** `addToCart` is the helper for components to add an item to remote cart */
  const addToCart = useCallback(
    async ({
      product,
      distribution,
      unit,
      price,
      pickups,
      csa,
      paymentSchedule,
    }: AddToCartServiceOpts): Promise<CartItem | void> => {
      setLoading(true)
      try {
        if (!cartId) throw new Error('cart must be initialized')

        let newItem = buildCartItem({
          product,
          distribution,
          unit,
          price,
          pickups,
          csa,
          paymentSchedule,
          isAdmin,
          isWholesale,
        })
        // Check the item quantities don't exceed the stock
        if (!canUpdateQuantity({ cartItem: newItem, cart: items, isWholesale })) {
          throwItemQuantityError(newItem.product, { isWholesale })
        }

        newItem = await cartApiAdd({ cartId, ...newItem, isAdmin, isWholesale })

        setItems((items) => {
          if (items.find((itm) => itm.id === newItem.id))
            return items.map((item) => (item.id === newItem.id ? newItem : item)).sort(sortCartItems)
          else return items.concat(newItem).sort(sortCartItems)
        })

        logAddToCart(product.id, distribution?.id || 'digital_product', product.farm.id)
        setLoading(false)
        return newItem
      } catch (err) {
        setLoading(false)
        if (err instanceof OutOfStockError) {
          dispatch(setNavProps())
          return outOfStockAlert()
        } else if (err instanceof InsufficientStockError) {
          dispatch(setNavProps())
          return insufficientStockAlert()
        }
        // Any other errors should be thrown so they can get handled by whoever called addToCart
        throw err
      }
    },
    [cartId, items, dispatch, isAdmin, isWholesale],
  )

  /** `updateQuantity` changes the quantity of an item in the cart */
  const updateQuantity = useCallback(
    async (itemId: string, newQuantity: number, isAdjustment = false): Promise<CartItem | void> => {
      if (!cartId) {
        Logger.error(new Error('Cart was not initialized when updateQuantity was called. itemId:' + itemId))
        return
      }
      try {
        setLoading(true)

        const cartItem = items.find((itm) => itm.id === itemId)
        if (!cartItem) throw new Error('This item id is not in the cart')

        const prevQuantity = getUnadjustedQuantity(cartItem)
        // Only validate quantity if it is not an adjustment, if it is an adjustment then we don't need to check against product inventory. See the comment at cartItem.unadjustedQuantity for why we are using unadjustedQuantity for inventory
        if (
          !isAdjustment &&
          !canUpdateQuantity({ cart: items, cartItem, delta: newQuantity - prevQuantity, isWholesale })
        ) {
          throwItemQuantityError(cartItem.product, { isWholesale })
        }

        let newItem: CartItem

        if (isAdjustment) {
          newItem = await cartUpdateVariableWeightQuantity(cartId, itemId, newQuantity)
        } else {
          newItem = await cartUpdateQuantity(cartId, itemId, newQuantity, isWholesale)
        }

        let newItems: CartItem[]
        if (newItem.quantity === 0) {
          newItems = items.filter((item) => item.id !== newItem.id)
        } else {
          newItems = items.map((item) => (item.id === newItem.id ? newItem : item))
        }

        setItems(newItems)
        setLoading(false)
        return newItem
      } catch (err) {
        setLoading(false)

        if (err instanceof OutOfStockError) {
          dispatch(setNavProps())
          return outOfStockAlert()
        } else if (err instanceof InsufficientStockError) {
          dispatch(setNavProps())
          return insufficientStockAlert()
        }
        // Any other errors should be thrown so they can get handled by whoever called addToCart
        throw err
      }
    },
    [cartId, items, dispatch, isWholesale],
  )

  /** `updatePaySchedule` changes the payment schedule of an item in the cart */
  const updatePaySchedule = useCallback(
    async (itemId: string, paymentSchedule: PaymentSchedule): Promise<CartItem | void> => {
      if (!cartId) {
        return Logger.error(new Error('Cart was not initialized when updatePaySchedule was called. itemId:' + itemId))
      }
      try {
        setLoading(true)
        const newItem = await cartUpdatePaymentSchedule(cartId, itemId, paymentSchedule, isWholesale)
        setItems((items) => items.map((item) => (item.id === newItem.id ? newItem : item)))
        setLoading(false)
        return newItem
      } catch {
        setLoading(false)
      }
    },
    [cartId, isWholesale],
  )

  /** updateCartItem updates the data of an item in cart, in a freestyle way, though with cartItem validation */
  const updateCartItem = useCallback(
    async (itemId: string, update: UpdateCartItemFields): Promise<CartItem | void> => {
      if (!cartId) {
        return Logger.error(new Error('Cart was not initialized when updatePaySchedule was called. itemId:' + itemId))
      }
      try {
        setLoading(true)
        const oldItm = items.find((itm) => itm.id === itemId)
        if (!oldItm) throw new Error("This cart item wasn't found in the cart")
        let updatedItem = mergeDeep(oldItm, update as Partial<CartItem>)
        buildCartItem({ ...updatedItem, isAdmin, isWholesale }) // only validate, but don't re-create, because building initializes quantity to 1

        // This service is not validating for quantity at this layer because it's best to also refresh the item with the latest product data, which is more suitable if done server side because here we only have access to the product copy currently in the cart

        updatedItem = await cartUpdateItem(cartId, itemId, update, isAdmin, isWholesale)
        setItems((items) => items.map((item) => (item.id === updatedItem.id ? updatedItem : item)))
        setLoading(false)
        return updatedItem
      } catch (err) {
        setLoading(false)
        if (err instanceof OutOfStockError) {
          dispatch(setNavProps())
          return outOfStockAlert()
        } else if (err instanceof InsufficientStockError) {
          dispatch(setNavProps())
          return insufficientStockAlert()
        }
        // Any other errors should be thrown so they can get handled by whoever called addToCart
        throw err
      }
    },
    [cartId, items, isAdmin, isWholesale, dispatch],
  )

  /** removeDiscount removes a cart's discount for a given farm */
  const removeDiscount = useCallback<CartService['removeDiscount']>(
    async (farmId: string) => {
      if (!cartId) {
        Logger.error('Cart was not initialized when removeCartDiscount was called', cartId)
        return
      }
      try {
        setLoading(true)
        await cartRemoveDiscount(cartId, farmId)
        // Should update the local discounts so only the one for the farmId is removed
        setDiscounts((discounts) => ({ ...discounts, [farmId]: undefined }))
        setLoading(false)
        return undefined
      } catch (e) {
        setLoading(false)
        throw e
      }
    },
    [cartId],
  )

  /** addDiscount adds a promo discount to the cart to be applied to the order.
   * - For now we're always adding discounts as promos in both admin and consumer carts because the promo is compatible with both types of cart. But the goal is that we later add coupons directly on admin carts.
   */
  const addPromo = useCallback<CartService['addPromo']>(
    async (farmId: string, promoId: PromoCode['id'], hasEbtPayment: boolean): Promise<Discount | void> => {
      if (!cartId) {
        Logger.error(new Error('Cart was not initialized when addPromo was called'))
        return
      }
      try {
        setLoading(true)
        const discount = await cartAddDiscount({
          cartId,
          farmId,
          id: promoId,
          type: 'promo',
          isWholesale,
          hasEbt: hasEbtPayment, // hasEbt will be validated by the server
        })

        setDiscounts((prev) => ({ ...prev, [farmId]: discount }))
        setLoading(false)
        return discount
      } catch (e) {
        setLoading(false)
        throw e
      }
    },
    [cartId, isWholesale],
  )

  /** If there are discounts on the cart, check if the cart subtotal is above the minimum on items change */
  useCancelableFx(
    async (isCurrent) => {
      if (!discounts || !values(discounts).length) return

      // For every farm discount, check if cart subtotal and compare with minimum
      entries(discounts).forEach(async ([farmId, discount]) => {
        if (!discount?.promo) return

        const orderMinimum = discount.promo.orderMinimum

        // Get the cart subtotal to determine if the order minimum has been met
        const subtotal = cartSubtotal(items, farmId, {
          isWholesale,
          ignoreOrderCutoffWindow: isAdmin,
          excludeClosedDistros: !isAdmin,
        })

        if (subtotal && orderMinimum && MoneyCalc.isLessThan(subtotal, orderMinimum)) {
          try {
            await retry(() => removeDiscount(farmId))
            if (!isCurrent) return

            Toast(
              `Promo code ${
                discount.promo.code
              } was removed because your order does not meet the minimum of ${formatMoney(orderMinimum)}`,
            )
          } catch (error) {
            Logger.error(extendErr(error, "A discount couldn't be removed when it was no longer valid in the cart."))
            if (!isCurrent) return
            Toast(
              'Your cart no longer supports the current discount and there was a problem updating it. Please check your internet connection and reload.',
            )
          }
        }
      })
    },
    [items, discounts, removeDiscount, isAdmin, isWholesale],
  )

  const service = useMemo(
    (): CartService => ({
      isCartInit: !!cartId,
      isAdmin,
      cartServiceType,
      cart: items,
      discounts,
      customer,
      addToCart,
      loadingCart: loading,
      updateQuantity,
      addPromo,
      removeDiscount,
      updatePaySchedule,
      updateCartItem,
    }),
    [
      isAdmin,
      items,
      discounts,
      addToCart,
      addPromo,
      removeDiscount,
      loading,
      updateQuantity,
      updatePaySchedule,
      updateCartItem,
      cartId,
      customer,
      cartServiceType,
    ],
  )

  /** Set the Cart Service helpers to redux, for either the admin or consumer cart */
  useEffect(() => {
    dispatch(setCartService(service, cartServiceType))
  }, [service, cartServiceType, dispatch])

  return service
}
