import React, { PropsWithChildren, RefObject, useState } from 'react'
import { StyleSheet, TextInput, TouchableOpacity, View, ViewStyle } from 'react-native'
import { Portal } from '@elements'
import { useFocusFx } from '../../../../hooks/useFocusFx'
import { useDeepCompareLayoutFnStyles } from '../../../../hooks/useFnStyles'
import { OverlayBackdrop } from './Backdrop'
import { initialPos, overlayBaseStyle, overlayContentPositioning, Position } from './overlayPosition'

export type OverlayMenuProps = {
  /** Similar to an Id for the autocomplete instance */
  value: string
  /** Will hide the overlay if false, defaults to true */
  isVisible?: boolean
  /** The element using the autocomplete */
  sourceRef: RefObject<TextInput | View | TouchableOpacity>
  /** Will be called when the overlay backdrop is clicked and the overlay should be hidden*/
  onBackdropPressed: () => void
  /** Props to customize how the overlay behaves */
  otherProps?: {
    /** Will set the width of the overlay to be the same as the source ref width. Else the width of the overlay will not be specified (auto based on the content) */
    matchWidth?: boolean
    /** Will act as an override to the container of the overlay, useful for things like maxHeight, etc. */
    contentContStyle?: ViewStyle
  }
}

/** The Menu Overlay component is used for displaying any content with a clickable backdrop. It handles positioning in relation to the click event as well. */
export function MenuOverlay({ onBackdropPressed, isVisible = true, ...props }: PropsWithChildren<OverlayMenuProps>) {
  const [sourcePos, setSourcePos] = useState<Position>(initialPos)
  const styles = useStyles({ ...props.otherProps, sourcePos })

  /** Measures the position of the source element */
  useFocusFx(() => {
    if (!props.sourceRef?.current) return

    props.sourceRef.current.measureInWindow((x, y, width, height) => {
      if (x === undefined || y === undefined || width === undefined || height === undefined) {
        // https://github.com/facebook/react-native/issues/29638
        throw new Error('Could not measure element, make sure the source element has collapsable set to false')
      }
      setSourcePos({ top: y, left: x, width, height })
    })
    // Value here is used to trigger the content to update, it signals it is a new instance of the list
    // Ref is intentionally in deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, props.sourceRef.current, isVisible])

  if (!isVisible) return null
  return (
    <Portal>
      <OverlayBackdrop onPress={onBackdropPressed} sourcePos={sourcePos} />
      <View style={styles.backdrop} pointerEvents="box-none">
        <View style={styles.contentContainer}>{props.children}</View>
      </View>
    </Portal>
  )
}

const useStyles = (state: OverlayMenuProps['otherProps'] & { sourcePos: Position }) =>
  useDeepCompareLayoutFnStyles(
    (layout, { sourcePos, contentContStyle, matchWidth }) => ({
      /** This is the style of the overlay container which holds the menu contents. This style must ensure it is positioned exactly where it is needed: below the source element */
      contentContainer: {
        /** basic styling for the Menu */
        ...overlayBaseStyle,
        ...contentContStyle,
        position: 'absolute',
        /** If the matchWidth option is specified, the width will be the same as the source element */
        width: matchWidth ? sourcePos.width : 'auto',
        /** Handles the positioning of the content in the overlay*/
        ...overlayContentPositioning(layout, sourcePos),
      },
      backdrop: {
        ...StyleSheet.absoluteFillObject,
      },
    }),
    state,
  )
