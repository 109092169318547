import Colors from '@/constants/Colors'
import { AlgoliaFarmDataContext } from '@/hooks/useAlgoliaFarmData/useAlgoliaFarmData'
import { useComponentRoute } from '@/hooks/useComponentRoute'
import { ShoppingStackParamList } from '@/navigation/types'
import { wholesaleSelector } from '@/redux/selectors'
import { BottomSheetList, Button, Icon, Text, TextH2, UniversalTag } from '@elements'
import { isNonNullish } from '@helpers/helpers'
import { emptyProductFilters } from '@models/Filters'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import {
  ProcessedMenuItem,
  ProductFilterGroup,
  getCategoryRefinementGroup,
  getEbtRefinementGroup,
  getLocationRefinementGroup,
  getTypeRefinementGroup,
} from '@screens/Shopping/FarmShop/Filtering/filterUtils'
import {} from '@screens/Shopping/FarmShop/sidebarHelpers'
import { useContext, useMemo, useState } from 'react'
import { ListRenderItemInfo, Pressable, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import { FilterItem } from '../FilterItem/FilterItem'

/** Generic algolia filter that handles multiple subfilters */
export type RootFilterRow = ProductFilterGroup & {
  /** When a filter options is selected */
  onSelect: (item: ProcessedMenuItem) => void
  /** When a filter option is cleared */
  onClear: () => void
  /** Whether the UI should show radio or checkbox button */
  icon?: 'radio' | 'checkbox' | 'link'
}

/** Component that contains the filters that can be applied to the shop */
export function ProductFilterDropdown() {
  const { isWholesale } = useSelector(wholesaleSelector)
  const [visibleModal, setVisibleModal] = useState(false)
  const [expandedId, setExpandedId] = useState<RootFilterRow['filterKey'] | undefined>()

  const {
    canFilterByEbt,
    categoryFilter,
    typeFilter,
    locationFilter,
    locations: dbLocs,
    ebtOnly,
    activeFiltersNo,
    products,
  } = useContext(AlgoliaFarmDataContext)

  const navigation = useNavigation<StackNavigationProp<ShoppingStackParamList, 'FarmShop'>>()
  const route = useRoute<RouteProp<ShoppingStackParamList, 'FarmShop'>>()
  const isCsaScreen = useComponentRoute()?.name === 'CSADetails'

  const items: RootFilterRow[] = useMemo(() => {
    const categoryGroup = getCategoryRefinementGroup(categoryFilter.items)
    const locGroup = getLocationRefinementGroup(dbLocs ?? [], locationFilter.items)
    const typeGroup = !isCsaScreen || isWholesale ? undefined : getTypeRefinementGroup(typeFilter.items)
    const ebtGroup = !canFilterByEbt ? undefined : getEbtRefinementGroup(ebtOnly ?? false)

    const groups: RootFilterRow[] = [categoryGroup, typeGroup, locGroup, ebtGroup].filter(isNonNullish).map((el) => {
      return {
        ...el,
        onSelect: (item) => navigation.setParams({ [el.filterKey]: item.shouldClear ? undefined : item.value }),
        onClear: () => navigation.setParams({ [el.filterKey]: undefined }),
      }
    })
    return groups
  }, [
    canFilterByEbt,
    categoryFilter.items,
    dbLocs,
    ebtOnly,
    isCsaScreen,
    locationFilter.items,
    navigation,
    typeFilter.items,
    isWholesale,
  ])

  const renderItem = ({ item }: ListRenderItemInfo<RootFilterRow>) => {
    const refinedItem = item.items.find((itm) => itm.isRefined && !itm.shouldClear)

    return (
      <Pressable onPress={() => setExpandedId(item.filterKey)} style={styles.listItemCont}>
        <View>
          <Text type="medium" size={16}>
            {item.title}
          </Text>
          {refinedItem && <UniversalTag onPress={item.onClear} label={refinedItem.label} />}
        </View>

        <Icon solid={false} iconSet="Entypo" color={Colors.shades[500]} name="chevron-right" />
      </Pressable>
    )
  }

  const expandedItem = useMemo(() => {
    if (!expandedId) return undefined
    return items.find((el) => el.filterKey === expandedId)
  }, [expandedId, items])

  function Footer() {
    return (
      <View style={styles.btnsCont}>
        <Button onPress={() => setVisibleModal(false)} title={`Show products (${products.length})`} />
        <Button
          outline
          onPress={() => {
            // If we are on the CSADetails page, this will navigate to the shop page (filters will be cleared too)
            if (isCsaScreen) {
              navigation.navigate('FarmShop', { farmSlug: route.params.farmSlug })
            } else {
              // If we are on the shop page, clear the current filters
              navigation.setParams(emptyProductFilters)
            }

            setVisibleModal(false)
          }}
          title="Reset filters"
        />
      </View>
    )
  }

  return (
    <>
      <Pressable onPress={() => setVisibleModal(true)} style={styles.filterIconCont}>
        <Icon size={18} name="list" color={Colors.shades[600]} />
        <Text>Filters {activeFiltersNo ? `(${activeFiltersNo})` : ''}</Text>
      </Pressable>

      <BottomSheetList
        overlayStyle={styles.overlay}
        ListFooterComponent={Footer}
        onBackdropPress={() => setVisibleModal(false)}
        ListHeaderComponent={() => (
          <TextH2 size={18} center>
            Refine Search
          </TextH2>
        )}
        items={items}
        isVisible={visibleModal}
        renderItem={renderItem}
      >
        {/* Second modal has to be inside the first modal so they can both be visible at the same time*/}
        <BottomSheetList
          onBackdropPress={() => {
            setExpandedId(undefined)
          }}
          ListHeaderComponent={() => (
            <View style={styles.titleCont}>
              <TextH2 center size={16}>
                Filter by {expandedItem?.title}
              </TextH2>
            </View>
          )}
          renderItem={({ item }) => (
            <FilterItem
              item={item}
              onPress={() => {
                expandedItem?.onSelect(item)
                setExpandedId(undefined)
              }}
              icon={expandedItem?.icon}
            />
          )}
          overlayStyle={styles.innerOverlay}
          isVisible={!!expandedItem}
          selectedItem={!expandedItem ? undefined : expandedItem.items.find((el) => el.isRefined)}
          items={!expandedItem ? [] : expandedItem.items}
        />
      </BottomSheetList>
    </>
  )
}

const styles = StyleSheet.create({
  innerOverlay: {
    height: '70%', // This is needed so the outer overlay can be visible
    paddingHorizontal: 0,
  },

  titleCont: {
    margin: 20,
    paddingBottom: 10,
  },
  listItemCont: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 15,
    borderBottomWidth: 1,
    borderBlockColor: Colors.shades['100'],
  },
  btnsCont: {
    marginTop: 40,
  },
  overlay: {
    height: '100%',
    paddingHorizontal: 0,
  },
  filterIconCont: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
})
