import { apiVersion } from '@api/App'

import { updatesAppVersion } from '../config/Environment'

// Semver identifies a version structure.
type Semver = {
  major: number
  minor: number
  patch: number
}

/** isValidAPIVersion returns true if the current API version is supported by the application. */
export async function isValidAPIVersion(): Promise<boolean> {
  const required = await apiVersion()
  return isValidAPIVersionUsing(updatesAppVersion, required)
}

/** isValidAPIVersionUsing accepts the current app version and the minimum required app version and returns true
 * if the app version satisfies the requirements of the required version. If it is not compatible the function will
 * return false.
 */
export function isValidAPIVersionUsing(app: string, required: string): boolean {
  const v1 = parseVersion(app)
  const v2 = parseVersion(required)
  return versionIsGreaterThanOrEqual(v1, v2)
}

/** parseVersion extracts the supplied version string into a Semver structure.
 * '1.0.1' => { major: 1, minor: 0, patch: 1 }
 */
function parseVersion(version: string): Semver {
  const [major, minor, patch] = version.split('.').map((part) => parseInt(part, 10))
  return { major: major || 0, minor: minor || 0, patch: patch || 0 }
}

/** versionIsGreaterThanOrEqual returns true when version v1 is greater than or equal to version v2. */
function versionIsGreaterThanOrEqual(v1: Semver, v2: Semver): boolean {
  if (v1.major > v2.major) {
    return true
  } else if (v1.major === v2.major) {
    if (v1.minor > v2.minor) {
      return true
    } else if (v1.minor === v2.minor) {
      return v1.patch >= v2.patch
    }
  }
  return false
}
