import { Alert, ButtonClear, LoadingView, Text } from '@elements'
import { groupByObj, isTruthy, removeDuplicates } from '@helpers/helpers'
import { StackScreenProps } from '@react-navigation/stack'
import { useCallback, useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { ConsumerScroll } from '../../../../components/ConsumerView'
import Colors from '../../../../constants/Colors'
import { isWeb } from '../../../../constants/Layout'
import { useApiFx } from '../../../../hooks/useApiFx'
import { useCartService } from '../../../../hooks/useCart'
import { ShoppingStackParamList } from '../../../../navigation/types'
import { wholesaleSelector } from '../../../../redux/selectors'

import { useFocusFx } from '@/hooks/useFocusFx'
import useKeyedState from '@/hooks/useKeyedState'
import { useLayout } from '@/hooks/useLayout'
import { useValidateCartAlert } from '@/hooks/useValidateCart'
import { setNavProps } from '@/redux/actions/appState'
import { farmsCollection } from '@api/framework/ClientCollections'
import { MessageWithIcon } from '@components'
import { FarmCartGroup } from './FarmCartGroup/FarmCartGroup'

type Props = StackScreenProps<ShoppingStackParamList, 'MyCart'>
type State = {
  isValidating: boolean
  expandedFarmIds: string[]
}

/** Screen that serves as a preview of cart items before checking out */
export function MyCart({ navigation }: Props) {
  const { isWholesale } = useSelector(wholesaleSelector)
  const [{ isValidating, expandedFarmIds }, set] = useKeyedState<State>({
    isValidating: false,
    expandedFarmIds: [],
  })
  const dispatch = useDispatch()
  const { isLargeDevice } = useLayout()
  const { cart: cartItems, loadingCart } = useCartService({ isWholesale })
  const farmIds = useMemo(() => removeDuplicates(cartItems.map((el) => el.product.farm.id)), [cartItems])

  const farmsFx = useApiFx(
    farmsCollection.fetchByIds.bind(farmsCollection),
    /** This will trigger a refresh when all the products from a farm are removed, but it will be addressed in a future pr if neccessary */
    [farmIds],
    /** Should run only when cart items are finished loading, to prevent empty cart flash. This won't trigger a re-fetch*/
    !loadingCart,
    {
      failedConditionMode: 'keep-loading',
    },
  )

  const sections = useMemo(() => {
    if (!farmsFx.data) return undefined

    const cartItemGroups = groupByObj(cartItems, (ci) => ci.product.farm.id)

    return Object.keys(cartItemGroups)
      .map((farmId) => {
        const farm = farmsFx.data?.find((farm) => farm.id === farmId)
        const items = cartItemGroups[farmId]
        if (!farm || !items.length) return undefined

        return { farm, items }
      })
      .filter(isTruthy)
  }, [cartItems, farmsFx.data])

  useFocusFx(() => {
    // If there is only one farm, it should be expanded by default
    if (sections?.length === 1) {
      set('expandedFarmIds', [sections[0].farm.id])
    }
  }, [sections, set])

  const validateCartAlert = useValidateCartAlert({ cartServiceType: 'consumer', isWholesale })

  const onToggleExpanded = useCallback(
    (farmId: string) => {
      const updatedList = expandedFarmIds.includes(farmId)
        ? expandedFarmIds.filter((el) => el !== farmId)
        : [...expandedFarmIds, farmId]
      set('expandedFarmIds', updatedList)
    },
    [expandedFarmIds, set],
  )

  useFocusFx(() => {
    if (!isWeb && isLargeDevice)
      navigation.setOptions({
        headerBackTitle: 'Back',
        headerTitle: 'My Cart',
      })
  }, [isLargeDevice, navigation])

  const goToCheckout = useCallback(
    async (farmId: string) => {
      if (!farmId) return Alert('Invalid Cart!', 'No farm association was found for this cart.') // This should never happen if there's products in cart

      // Update or delete all invalid items from cart
      set('isValidating', true)

      /** This async validation doesn't need trycatch because any error will be caught inside. The result will contain the user input from an awaited alert that handles the error */
      const res = await validateCartAlert({
        farmId,
        onGoToShopPress() {
          // This will be called if the user decided to continue shopping, to make up for any unavailable items
          dispatch(setNavProps()) // This will clear the farm cache, to force loading updated product data
          navigation.navigate('FarmShop', { farmSlug: farmId })
        },
      })
      set('isValidating', false)

      if (res.cancelCheckout || res.unavailCount > 0) dispatch(setNavProps()) // This will clear the farm cache, to force loading updated product data

      // If the user decided to cancel checkout, we abort.
      // If the cart still has items in it after validation, we can continue to checkout
      if (!res.cancelCheckout && res.newCartLength > 0) {
        return navigation.navigate('Checkout', { cartFarmId: farmId })
      }
    },
    [navigation, validateCartAlert, dispatch, set],
  )

  /** If any farm section is expanded, collapses all sections.
   * If none are expanded, expands all sections. */
  const onToggleAll = useCallback(() => {
    set('expandedFarmIds', expandedFarmIds.length ? [] : farmIds)
  }, [expandedFarmIds.length, farmIds, set])

  return (
    <ConsumerScroll contentContainerStyle={styles.contGap}>
      {sections && sections?.length >= 2 && (
        <View style={styles.contGap}>
          <Text size={18}>{`${cartItems.length} items in your Cart from ${sections.length} farms`}</Text>
          <ButtonClear
            small
            onPress={onToggleAll}
            title={`${expandedFarmIds.length ? 'Collapse' : 'Expand'} all items`}
          />
        </View>
      )}
      <LoadingView loading={farmsFx.loading} style={styles.cartWrapper}>
        {sections?.length ? (
          sections.map((group) => (
            <FarmCartGroup
              loading={isValidating}
              onCheckoutPress={goToCheckout}
              key={group.farm.id}
              // Prevent collapse feature when only one cart is available
              isExpanded={sections.length === 1 ? null : expandedFarmIds.includes(group.farm.id)}
              onToggleExpanded={onToggleExpanded}
              farm={group.farm}
              items={group.items}
              multipleExistingFarms={sections.length > 1}
            />
          ))
        ) : (
          <MessageWithIcon
            title="Empty Cart!"
            style={styles.emptyCart}
            icon="shopping-cart"
            iconColor={Colors.shades['300']}
          >
            <Text>Your cart is empty, click continue shopping to add products to your cart.</Text>
          </MessageWithIcon>
        )}
      </LoadingView>
    </ConsumerScroll>
  )
}

const styles = StyleSheet.create({
  cartWrapper: {
    gap: 20,
  },
  emptyCart: {
    flex: 1,
    padding: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  contGap: {
    gap: 16,
  },
})
