import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useDeviceSize } from '@/hooks/useLayout'
import { ToolTips } from '@components'
import { Text, Tooltip } from '@elements'
import { View } from 'react-native'

type Props = {
  title: string
  value: number | string | undefined
  growth?: { value: number; period: string }
  loading?: boolean
  loadingText?: string
  toolTip?: ToolTips
}
/** Statistics card used to display dashboard data */
export function StatCard({ title, value, growth, loading = false, loadingText = '---', toolTip }: Props) {
  const { isExtraSmallDevice, isLargeDevice } = useDeviceSize()
  const styles = useStyles()

  return (
    <View style={styles.main}>
      <View style={globalStyles.flexRowCenter}>
        <Text size={isExtraSmallDevice ? 12 : isLargeDevice ? 18 : 14} center type="medium">
          {title}
        </Text>
        {toolTip && <Tooltip id={toolTip} style={globalStyles.marginHorizontal10} />}
      </View>
      {!loading && growth !== undefined && (
        <Text size={isExtraSmallDevice ? 12 : 14} center color={Colors.green}>
          ({growth.value >= 0 && '+'}
          {growth.value}%{growth.period !== 'Custom' && ` since ${growth.period}`})
        </Text>
      )}
      <Text size={isExtraSmallDevice ? 16 : isLargeDevice ? 30 : 24}>{loading ? loadingText : value}</Text>
    </View>
  )
}
const useStyles = () =>
  useSizeFnStyles(({ isExtraSmallDevice, isLargeDevice }) => ({
    main: {
      borderWidth: 1,
      alignItems: 'center',
      gap: 4,
      borderRadius: 8,
      borderColor: Colors.shades['100'],
      flex: 1,
      padding: isExtraSmallDevice ? 6 : 18,
      justifyContent: 'space-between',
    },
    title: {
      fontSize: isExtraSmallDevice ? 12 : isLargeDevice ? 18 : 14,
    },
  }))
