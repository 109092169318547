import React from 'react'
import { Image, StyleSheet, View } from 'react-native'
import Colors from '@/constants/Colors'
import { farmerOnboardHeroAssets } from '@/assets/images/farmer_onboard'
import {
  FarmerOnboardDemoButton,
  FarmerOnboardOpenStoreButton,
  SectionContainer,
} from '@screens/FarmerOnboard/components'
import { HeaderText, Text } from '@elements'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useDeviceSize } from '@/hooks/useLayout'

/**
 * `HeroSection` is a component that renders a section containing call-to-action details and hero images.
 * The `HeroSection` component is composed of `HeroCallToActionDetails` and `HeroImageFactory` sub-components.
 */
export function HeroSection() {
  const styles = useHeroSectionStyles()

  return (
    <SectionContainer outerContainerStyle={styles.outerContainer} innerContainerStyle={styles.sectionContainerWidth}>
      <SectionDetails />
      <SectionImageFactory />
    </SectionContainer>
  )
}

const useHeroSectionStyles = () =>
  useSizeFnStyles(() => ({
    outerContainer: { marginTop: 0 },
    sectionContainerWidth: {
      width: '100%',
    },
  }))

function SectionDetails() {
  const styles = useSectionDetailsStyles()
  const { isSmallDevice } = useDeviceSize()
  const headerTextSize = isSmallDevice ? 36 : 45
  return (
    <View style={styles.detailsContainer}>
      <Text size={15} style={styles.proudToBeCooperativelyOwnedText}>
        PROUD TO BE COOPERATIVELY OWNED
      </Text>
      <HeaderText size={headerTextSize} style={styles.headerText}>
        Farmer-Owned
      </HeaderText>
      <HeaderText size={headerTextSize} style={styles.headerText}>
        Farmer-Designed
      </HeaderText>
      <View style={styles.callToActionButtonContainer}>
        <FarmerOnboardOpenStoreButton />
        <FarmerOnboardDemoButton inverse />
      </View>
    </View>
  )
}

const useSectionDetailsStyles = () =>
  useSizeFnStyles(({ isLargeDevice }) => ({
    detailsContainer: {
      marginTop: 50,
      marginLeft: isLargeDevice ? 8 : 0,
      marginRight: isLargeDevice ? 10 : 0,
      justifyContent: 'center',
      alignItems: isLargeDevice ? 'flex-start' : 'center',
    },
    proudToBeCooperativelyOwnedText: {
      color: Colors.darkGray,
      fontWeight: '800',
    },
    headerText: {
      color: Colors.darkGreen,
      fontStyle: 'italic',
    },
    callToActionButtonContainer: {
      flexDirection: isLargeDevice ? 'row' : 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      maxWidth: isLargeDevice ? 600 : '100%',
      alignSelf: 'center',
    },
  }))

function SectionImageFactory() {
  const { isLargeDevice } = useDeviceSize()
  if (isLargeDevice) return <SectionImagesStaggered />
  else return <SectionImage />
}

function SectionImagesStaggered() {
  const firstSetOfImages = farmerOnboardHeroAssets.slice(0, 3)
  const secondSetOfImages = farmerOnboardHeroAssets.slice(3, 6)

  return (
    <View style={staggeredStyles.staggeredImageContainers}>
      <View style={staggeredStyles.imageColumnOne}>
        {firstSetOfImages.map((image, index) => (
          <Image key={index} source={image.source} style={image.style} />
        ))}
      </View>
      <View style={staggeredStyles.imageColumnTwo}>
        {secondSetOfImages.map((image, index) => (
          <Image key={index} source={image.source} style={image.style} />
        ))}
      </View>
    </View>
  )
}

const staggeredStyles = StyleSheet.create({
  staggeredImageContainers: {
    flexDirection: 'row',
    gap: 17,
    height: 600,
  },
  imageColumnOne: {
    gap: 15,
  },
  imageColumnTwo: {
    gap: 24,
  },
})

function SectionImage() {
  return <Image source={farmerOnboardHeroAssets[0].source} style={imageStyles.image} />
}

const imageStyles = StyleSheet.create({
  image: {
    width: 300,
    height: 300 * (3 / 4),
    margin: 1,
    marginVertical: 10,
    borderRadius: 20,
  },
})
