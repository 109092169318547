import { CurrentLocation } from '@/constants/types'
import { useCoords } from '@/hooks/useCoords/useCoords'
import { HomeParamList } from '@/navigation/types'
import { setSearchLocation } from '@/redux/actions/appState'
import { GooglePlacesSearch, useCreateSearchParams } from '@components'
import { Button, Modal, hideModal } from '@elements'
import { CoordString } from '@helpers/coordinate'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { memo, useEffect, useRef, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch } from 'react-redux'

type SelectedLoc = Pick<CurrentLocation, 'city' | 'coordinate'>

/**  Will show the ask location modal when the location is not available */
export function useAskLocationModal() {
  const dispatch = useDispatch()
  const hasShown = useRef(false)
  const { params = {} } = useRoute<RouteProp<HomeParamList, 'ExploreScreen'>>()
  const coords = useCoords(params.near as CoordString)
  const navigation = useNavigation<StackNavigationProp<HomeParamList, 'ExploreScreen'>>()
  const createSearchParams = useCreateSearchParams()

  useEffect(() => {
    if (!coords && !hasShown.current) {
      hasShown.current = true

      const onSelect = (selectedLoc: SelectedLoc) => {
        // Setting the location will make the coords available to the map, and the effect above should hide this modal on set
        navigation.setParams(createSearchParams({ coords: selectedLoc.coordinate }))
        dispatch(setSearchLocation(selectedLoc))
        hideModal()
      }

      Modal(<AskLocationModal onSelect={onSelect} />, { title: 'Where are you looking for farms?' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coords])
}

export const AskLocationModal = memo(function AskLocationModal({ onSelect }: { onSelect: (loc: SelectedLoc) => void }) {
  const [selectedLoc, setSelectedLoc] = useState<SelectedLoc>()

  return (
    <View style={styles.cont}>
      {/* This View is necessary because the googleplacessearch inline mode will render the items as a fragment which means without the View they would be direct children of the main container which would not work for the flex alignment */}
      <View style={styles.searchCont}>
        <GooglePlacesSearch
          placeholder="Enter an address"
          types="geocode"
          onSelectEstablishment={(itm) => setSelectedLoc({ city: itm.name || '', coordinate: itm.coordinate })}
          contStyle={styles.inputCont}
          style={styles.input}
          inline
        />
      </View>
      <Button title="Go" disabled={!selectedLoc} onPress={() => onSelect(selectedLoc!)} style={styles.button} />
    </View>
  )
})

const elHeight = 50

const styles = StyleSheet.create({
  cont: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 20,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  searchCont: {
    flex: 3,
    minWidth: 300, // This is necessary for the flexWrap to push the button down on very small device sizes
    margin: 10,
  },
  inputCont: {
    borderRadius: 10,
    borderWidth: 1,
    marginHorizontal: 20,
  },
  button: {
    height: elHeight,
    width: 70,
    marginTop: 10,
  },
  input: {
    height: elHeight,
  },
})
