import { LoginComponent } from '@components'
import { LoadingView } from '@elements'
import { getDisplayName } from '@helpers/client'
import * as React from 'react'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { wholesaleSelector } from '../redux/selectors'

/**
 * If the app is in wholesale mode, will make sure the user is authenticated and has permission to access wholesale before displaying the underlying component. If the app is not in wholesale mode will show the component as usual
 */
export function withWholesaleAuth<P extends object>(Component: React.ComponentType<P>) {
  const NewComponent = memo(function (props: P): JSX.Element {
    const { isWholesale, wholesaleAccessRole } = useSelector(wholesaleSelector)

    const hasWholesaleAccess = wholesaleAccessRole !== undefined

    return (
      <LoadingView loading={isWholesale === undefined} switchMode>
        {isWholesale === false || hasWholesaleAccess ? <Component {...props} /> : <LoginComponent />}
      </LoadingView>
    )
  })
  NewComponent.displayName = `WithWholesaleAuth(${getDisplayName(Component)})`
  return NewComponent
}
