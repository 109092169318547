import { Text, TextH3 } from '@elements'
import { formatMoney } from '@helpers/display'
import { hasUnits, isShare, isStandard } from '@models/Product'
import { StyleSheet, View } from 'react-native'
import { Image } from 'react-native-elements'
import { useSelector } from 'react-redux'

import Colors from '../constants/Colors'
import { useLayout } from '../hooks/useLayout'
import { OrderItem as OrderItemType } from '../models/Order'
import { adminSchedulesSelector } from '../redux/selectors'
import { formatDistributionType } from '@helpers/location'

type PropTypes = {
  item: OrderItemType
  numPayments: number
  showAdmin?: boolean
}

export function OrderItem({ item, numPayments, showAdmin }: PropTypes) {
  const distros = useSelector(adminSchedulesSelector)
  const currDistro = item.distribution ? distros.find(({ id }) => id === item.distribution!.id) : undefined
  const { isSmallDevice } = useLayout()
  return (
    <View style={[styles.card, { flexDirection: isSmallDevice ? 'column' : 'row' }]}>
      <View style={styles.container1}>
        <Image
          source={{
            uri: item.product.image,
          }}
          style={styles.cardImage}
          resizeMode="cover"
        />
        <View style={styles.textContent}>
          <Text size={16} numberOfLines={2}>
            (x{item.quantity}) {item.product.name}
          </Text>
          {showAdmin ? (
            <>
              {!!item.csa?.name && (
                <Text numberOfLines={1} style={styles.productDesc}>
                  CSA: {item.csa?.name}
                </Text>
              )}
              {currDistro && (
                <Text numberOfLines={1} style={styles.productDesc}>
                  {formatDistributionType(currDistro?.location, { action: true, capitalize: true })} Location:{' '}
                  {currDistro.name}
                </Text>
              )}
            </>
          ) : (
            <Text numberOfLines={2} style={styles.productDesc}>
              {item.product.description}
            </Text>
          )}
          {isStandard(item.product) && (item.numPickups || 0) > 1 && (
            <TextH3 style={{ marginTop: 10 }}>
              No. {formatDistributionType(currDistro?.location, { plural: true })}: {item.numPickups}{' '}
            </TextH3>
          )}
        </View>
      </View>
      <View style={styles.container2}>
        <View style={{ alignItems: 'flex-end', marginRight: 20 }}>
          {hasUnits(item.product) ? (
            //If product has units, unit and price must be defined
            <Text size={12} numberOfLines={1}>
              ({formatMoney(item.purchasedUnitPrice!.amount)}/ {item.purchasedUnit!.name})
            </Text>
          ) : (
            //If product has no units, it must be a share
            <>
              {item.paymentSchedule.frequency !== 'ONCE' ? (
                <Text size={12} numberOfLines={1}>
                  ({item.paymentSchedule.frequency} price)
                </Text>
              ) : (
                <Text size={12} numberOfLines={1}>
                  (total price)
                </Text>
              )}
              <Text size={12} numberOfLines={1}>
                (upfront payment)
              </Text>
            </>
          )}
        </View>
        <View style={styles.priceCont}>
          {isShare(item.product) ? (
            <>
              <Text size={12} style={styles.fontBold} numberOfLines={1}>
                {formatMoney(
                  ((item.paymentSchedule.amount.value - item.paymentSchedule.deposit.value) / (numPayments || 1)) *
                    item.quantity,
                )}
              </Text>
              <Text size={12} style={styles.fontBold} numberOfLines={1}>
                {formatMoney(item.paymentSchedule.deposit.value * item.quantity)}
              </Text>
            </>
          ) : (
            <Text size={12} style={styles.fontBold} numberOfLines={1}>
              {/* We want the per-pickup price, so it shouldn't account for the number of pickups for MPSP */}
              {formatMoney(item.paymentSchedule.amount.value * item.quantity)}
            </Text>
          )}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container1: {
    flexDirection: 'row',
    flex: 2,
    minHeight: 100,
  },
  fontBold: {
    fontWeight: 'bold',
  },
  card: {
    flex: 1,
    flexDirection: 'row',
    overflow: 'hidden',
    marginBottom: 10,
    paddingBottom: 10,
    borderColor: Colors.shades[200],
  },
  container2: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    alignItems: 'center',
  },
  sharesHeader: {
    color: Colors.shades[500],
    marginVertical: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  cardImage: {
    flex: 1,
    width: 100,
    borderRadius: 5,
    height: 100,
    alignSelf: 'center',
  },
  textContent: {
    flex: 2,
    paddingHorizontal: 10,
    justifyContent: 'center',
  },
  priceCont: {
    alignItems: 'flex-end',
  },
  productDesc: {
    color: Colors.shades[300],
    fontSize: 12,
  },
})
