import { ScrollView, ScrollViewProps, StyleSheet, View, ViewProps } from 'react-native'

import { globalStyles } from '@/constants/Styles'
import { isWeb } from '../constants/Layout'
import { BreadCrumbs } from './elements/BreadCrumbs'

/** Non-scrollable container that has limited width and predefined styles, which should be used as a screen root component
 *  - Includes screen's `BreadCrumbs` if available
 */
export function ConsumerView({ style: styleProp, children, ...rest }: ViewProps) {
  return (
    <View style={[styles.container, styleProp]} {...rest}>
      {isWeb && <BreadCrumbs />}
      {children}
    </View>
  )
}

/** Scrollable container that has limited width and predefined styles, which should be used as a screen root component
 * - Includes screen's `BreadCrumbs` if available
 * - `style` should not contain `padding`, and `contentContainerStyle` should not contain `margin`, as it breaks the scrolling area
 */
export function ConsumerScroll({ style, contentContainerStyle, children, ...rest }: ScrollViewProps) {
  return (
    <ScrollView
      style={[globalStyles.flex1, style]}
      contentContainerStyle={[styles.contentContainer, contentContainerStyle]}
      {...rest}
    >
      {isWeb && <BreadCrumbs />}
      {children}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    alignSelf: 'center',
    width: '100%',
    maxWidth: 950,
  },
  contentContainer: {
    alignSelf: 'center',
    width: '100%',
    maxWidth: 950,
    paddingHorizontal: 20,
    paddingBottom: 50,
  },
})
