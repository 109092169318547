import { DeviceType, getDeviceTypeAsync } from 'expo-device'
import { Children, MouseEvent, cloneElement, useCallback, useEffect, useMemo, useState } from 'react'

import { HoverableProps, HoverablePropsChildren } from './types'

/** Web-only implementation of a component that can notify its hover status to its children via a prop */
export function Hoverable({ onHoverIn, onHoverOut, onHoverMove, children }: HoverableProps): JSX.Element {
  const [isHovered, setHovered] = useState(false)
  /** We need to get the device type because onHover functions should only run on desktop devices and not mobile phones */
  const [deviceType, setDeviceType] = useState(DeviceType.UNKNOWN)

  useEffect(() => {
    getDeviceTypeAsync().then((type) => setDeviceType(type))
  }, [])

  const handleMouseEnter = useCallback(
    (event: MouseEvent) => {
      if (onHoverIn) {
        onHoverIn({ x: event.nativeEvent.offsetX, y: event.nativeEvent.offsetY })
      }
      setHovered(true)
    },
    [onHoverIn],
  )

  const handleMouseLeave = useCallback(
    (event: MouseEvent) => {
      if (onHoverOut) {
        onHoverOut({ x: event.nativeEvent.offsetX, y: event.nativeEvent.offsetY })
      }
      setHovered(false)
    },
    [onHoverOut],
  )

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (onHoverMove) {
        onHoverMove({ x: event.nativeEvent.offsetX, y: event.nativeEvent.offsetY })
      }
    },
    [onHoverMove],
  )

  const child = useMemo(() => (typeof children === 'function' ? children(isHovered) : children), [children, isHovered])

  /** These are the props passed to the jsx children */
  const childrenProps: HoverablePropsChildren = {
    //onMouseEnter is vanilla react https://reactjs.org/docs/events.html#mouse-events. it can be added to any web component
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
    onMouseMove: handleMouseMove,
  }

  // TODO: Perhaps this shouldn't be limited to DeviceType.Desktop because a mouse can be attached to android devices as well
  if (deviceType === DeviceType.DESKTOP)
    return cloneElement<HoverablePropsChildren>(Children.only(child), childrenProps)

  return child
}
