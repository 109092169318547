import { CSA } from '@models/CSA'
import { Features } from '@models/Features'
import { User } from '@models/User'

import { AdminNavProps } from '@/constants/types/navProps'
import {
  ADD_QUEUE_ALGOLIA_PRODUCT,
  REMOVE_QUEUE_ALGOLIA_PRODUCT,
  SET_ADMIN_CSAS,
  SET_ADMIN_FEATURES_AVAILABLE,
  SET_ADMIN_NAV,
  SET_CUSTOMER_ORDER_CREATOR,
  SET_CUSTOMER_ORDER_EDIT,
} from './actionTypes'

import { CartServiceType } from '@/constants/types/cartService'
import { createAlgoliaAdminProduct } from '@helpers/builders/buildAlgoliaDocs'
import { AlgoliaAdminProduct, isAlgoliaAdminProduct } from '@models/Algolia'
import { Product } from '@models/Product'

export const setAdminCSAsAction = (csas: CSA[]) => ({
  type: SET_ADMIN_CSAS,
  payload: csas,
})

/** Will overwrite the navigation data with the provided data */
export const setAdminNav = (prop: Partial<AdminNavProps>) => ({
  type: SET_ADMIN_NAV,
  payload: prop,
})

type QueueDataOpts =
  | { prodId: string; action: 'removed'; data?: undefined }
  | { prodId: string; action?: 'added' | 'edited'; data: Product | AlgoliaAdminProduct }

type QueueDataPayload =
  | { prodId: string; action: 'removed'; data?: undefined }
  | { prodId: string; action: 'added' | 'edited'; data: AlgoliaAdminProduct }

/** Will add a product to the queue, along with its action.
 * - When a product is created or edited, it must be added to the queue with the Product as data.
 * - When a product is deleted, it must be added to the queue with the string 'removed' as data.
 */
export const addQueueAlgoliaProduct = ({
  prodId,
  action,
  data,
}: QueueDataOpts): { type: typeof ADD_QUEUE_ALGOLIA_PRODUCT; payload: QueueDataPayload } => ({
  type: ADD_QUEUE_ALGOLIA_PRODUCT,
  payload:
    action === 'removed'
      ? { prodId, action }
      : {
          prodId,
          action: action ?? 'added',
          data: isAlgoliaAdminProduct(data) ? data : createAlgoliaAdminProduct(data),
        },
})

/** Will remove a product from the queue by its product Id only. No need to specify the queue action */
export const removeQueueAlgoliaProduct = (prodId: string) => ({
  type: REMOVE_QUEUE_ALGOLIA_PRODUCT,
  payload: prodId,
})

export const setAdminFeaturesAvailable = (featuresAvailable: Record<Features, boolean>) => ({
  type: SET_ADMIN_FEATURES_AVAILABLE,
  payload: featuresAvailable,
})

/** Sets the customer for a cart service state.
 * @param customer The customer to be set
 * @param cartServiceType The cart service type, either 'orderCreator' or 'orderEdit'. It doesn't offer the option for the consumer cart because that is the signed in user, which is set by the global user listener and auth.
 */
export const setCartCustomer = (customer: User | undefined, cartServiceType: Exclude<CartServiceType, 'consumer'>) => ({
  type: cartServiceType === 'orderCreator' ? SET_CUSTOMER_ORDER_CREATOR : SET_CUSTOMER_ORDER_EDIT,
  payload: customer,
})
