import { RouteProp, useRoute } from '@react-navigation/native'
import { ExternalLinkingParamList } from '../../navigation/types'
import { AddPaymentMethod } from '../PaymentMethods/AddPaymentMethod/AddPaymentMethod'
import { grownbyRetailWebsiteLiveUrl } from '../../config/Environment'
import { openUrl } from '@helpers/client'

/** This page is made to be loaded in a webview or external web page and allows the user to add a payment method to their account. After it has been added it will redirect them to a success screen.
 * NOTE: I tried making it return them to the app, but it was too tricky to handle bringing them back to where they were in the checkout flow or invoice payment. So this way they need to manually close the window, but they won't lose their place in the GrownBy app
 * */
export function AddPaymentExternal() {
  const params = useRoute<RouteProp<ExternalLinkingParamList, 'AddPaymentExternal'>>()
  return (
    <AddPaymentMethod
      userId={params.params.userId}
      onDismiss={() => {
        openUrl(`${grownbyRetailWebsiteLiveUrl()}external/browser-return`, { target: '_self' })
      }}
      ebtEnabled={params.params.ebt !== 'disabled'}
      hasEbtCard={params.params.ebt === 'existing'}
      paymentForm={params.params.form === 'all' ? undefined : params.params.form}
    />
  )
}
