import { DividerVertical, ErrorText, Icon, LoadingView, Text } from '@elements'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { capitalize, formatAddress, formatMoney } from '@helpers/display'
import { Address } from '@models/Address'
import { Distribution } from '@models/Distribution'
import { LocationTypes, isLocalPickup, isNonPickup } from '@models/Location'
import { Zero } from '@models/Money'
import { DateTime } from 'luxon'
import { memo, useCallback, useMemo } from 'react'
import { Platform, View } from 'react-native'

import Colors from '../../constants/Colors'
import { ColumnSize, ResponsiveList, ResponsiveListProps, containerFlex } from '../ResponsiveList'
import WebLink from '../elements/WebLink'
import { PickupOpts } from './PickupOpts'

import { globalStyles } from '@/constants/Styles'
import { CartServiceType } from '@/constants/types/cartService'
import { useCartService } from '@/hooks/useCart'
import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { MessageWithIcon } from '@components'
import { UsePickupLocationDataProps, usePickupLocationData } from './usePickupLocationData'

type PickupLocationProps = Omit<UsePickupLocationDataProps, 'isAdmin'> & {
  cartServiceType?: CartServiceType
  /** callback for onPress on the checkbox of a schedule + location option.
   * This is only used in the addToCart flow and not in the ProductDetails screen  */
  onSelectSchedule?: (distro: Distribution) => void
  /** Called on pressing the "x" icon, which is shown when an address is selected .
   * This is only used in the addToCart flow and not in the ProductDetails screen  */
  resetAddress?: () => void
  /** if true, will show the available regions main text for all the regions currently visible */
  showAvailableRegions?: boolean
}

/** Additional properties added to the schedule options */
export type DistroExtended = Distribution & {
  /** The calculated pickups between the schedule and the product being added */
  pickups: DateTime[]
  firstPickup: DateTime
  /** If "disable" is true, the option checkbox UI will be disabled so it can't be selected for adding to cart*/
  disable?: boolean
  /** Whether the checkbox should be hidden for a particular schedule */
  hideCheckbox?: boolean
  orderDeadline: DateTime
}

/** Shows a list of location cards, each with sub-options for the distributions associated to each location. */
export const PickupLocation = memo(function PickupLocation({
  product,
  onSelectSchedule,
  address,
  locationType,
  resetAddress,
  showAvailableRegions,
  cartServiceType = 'consumer',
  isWholesale,
}: PickupLocationProps) {
  const { isAdmin } = useCartService({ cartServiceType, isWholesale })
  const { distrosByLoc, regions, loading } = usePickupLocationData({ product, address, locationType, isAdmin })

  const styles = useStyles()

  const renderItem = useCallback(
    //Each item (schedulesGroup) is an array of Distributions with same location id
    (schedulesGroup: DistroExtended[]) => {
      const loc: Distribution['location'] = schedulesGroup[0].location
      const costText = isLocalPickup(loc) ? '' : capitalize(loc.type) + ' Fee: ' + formatMoney(loc.cost ?? Zero)

      return (
        <View style={styles.locCard}>
          <Text type="medium" size={16}>
            {loc.name}
          </Text>
          {isLocalPickup(loc) ? (
            <WebLink url={getMapUrl(loc.address)} weight="medium">
              {formatAddress(loc.address)}
            </WebLink>
          ) : (
            <>
              <Text>{costText}</Text>
              <AvailableRegions isVisible locationType={loc.type} regions={loc.regions} shortPrefix />
            </>
          )}
          <PickupOpts
            distros={schedulesGroup}
            product={product}
            onPress={onSelectSchedule}
            cartServiceType={cartServiceType}
            isWholesale={isWholesale}
          />
        </View>
      )
    },
    [product, onSelectSchedule, styles.locCard, isWholesale, cartServiceType],
  )

  /** This component will be shown when there are no schedule options for the locationType filter */
  const ListEmpty = useCallback(() => {
    if (!locationType) {
      return (
        <MessageWithIcon icon="exclamation-triangle" title="No Schedules!">
          <Text>This product doesn't have any distribution schedules.</Text>
        </MessageWithIcon>
      )
    }

    if (isLocalPickup(locationType)) {
      return (
        <MessageWithIcon icon="exclamation-triangle" title="No Pickups!">
          <Text>This product is not being sold at any pickup locations.</Text>
        </MessageWithIcon>
      )
    } else if (isNonPickup(locationType)) {
      return (
        <View style={styles.noDeliverOptionsMsg}>
          {!!address && (
            <>
              <Icon name="times" color={Colors.red} onPress={resetAddress} />
              <DividerVertical clear />
            </>
          )}
          <ErrorText>
            {capitalize(locationType)} is only available at the specified locations. Please enter a matching address.
          </ErrorText>
        </View>
      )
    }
    return null
  }, [locationType, address, styles.noDeliverOptionsMsg, resetAddress])

  const keyOverride: ResponsiveListProps<DistroExtended[]>['keyExtractor'] = useCallback(
    (group: DistroExtended[]) => group[0]?.location.id,
    [],
  )

  return (
    <LoadingView loading={loading} switchMode success={distrosByLoc}>
      {(distrosByLoc) => (
        <>
          <AvailableRegions isVisible={showAvailableRegions} locationType={locationType} regions={regions} />

          {/* This ListEmpty needs to be outside the ResponsiveList's FlatList because there are specific UI requirements on the address-selection portion of the addToCart flow. This component is used both there and in the ProductDetails screen, with slightly different props. Changes should test on both places. */}
          {!distrosByLoc.length && <ListEmpty />}
          <ResponsiveList
            columnSize={ColumnSize.Large}
            data={distrosByLoc}
            contentContainerStyle={styles.respListCont}
            keyOverride={keyOverride}
            smallHorizontal={!onSelectSchedule}
            renderItem={renderItem}
          />
        </>
      )}
    </LoadingView>
  )
},
propsAreDeepEqual)

const getMapUrl = (distributionAddress: Address) => {
  const address = formatAddress(distributionAddress)
  const url = Platform.select({
    ios: 'http://maps.apple.com/maps?saddr=My+Location&daddr=',
    default: 'http://maps.google.com/maps?saddr=My+Location&daddr=',
  })
  return url + address
}

const useStyles = () =>
  useLayoutFnStyles((layout) => ({
    locCard: {
      ...containerFlex(layout, ColumnSize.Large),
      margin: 10,
      padding: 20,
      borderWidth: 1,
      borderColor: Colors.shades['100'],
      borderRadius: 10,
    },
    respListCont: { paddingHorizontal: 20 },
    noDeliverOptionsMsg: {
      margin: 10,
      maxWidth: 300,
      flexDirection: 'row',
      alignItems: 'center',
    },
  }))

/** Returns a text element with available regions when enabled and the locationType is nonPickup */
export function AvailableRegions({
  isVisible,
  locationType,
  regions,
  shortPrefix,
}: {
  isVisible?: boolean
  locationType?: LocationTypes
  regions?: string[]
  shortPrefix?: boolean
}) {
  const limitedRegionsStr = useMemo(() => {
    if (!regions?.length) return ''

    const maxRegions = 10

    if (regions.length <= maxRegions) {
      return regions.join(', ')
    }

    return regions.slice(0, maxRegions).join(', ') + ` and ${regions.length - maxRegions} more`
  }, [regions])

  return !!isVisible && !!locationType && isNonPickup(locationType) && regions?.length ? (
    <Text style={globalStyles.flex1}>
      {(shortPrefix ? '' : 'The product is available at the following ') +
        (locationType === LocationTypes.Shipping
          ? shortPrefix
            ? 'States: '
            : 'states: '
          : locationType === LocationTypes.Delivery
          ? shortPrefix
            ? 'Zip codes: '
            : 'zip codes: '
          : '') +
        limitedRegionsStr}
    </Text>
  ) : null
}
