import { connectRefinementList, connectStateResults } from 'react-instantsearch-native'
import { RefinementListProvided, StateResultsProvided } from 'react-instantsearch-core'
import { AlgoliaAdminDoc, AlgoliaAdminOrder } from '@models/Algolia'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/reducers/types'
import { Farm } from '@models/Farm'
import { adminFarmSelector } from '../../../../redux/selectors'
import { dateTimeInZone } from '@models/Timezone'
import { openOrdersCSV, OrderFilters } from '@helpers/links'
import Export from '../../../components/Export'
import { useCallback, useState } from 'react'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { AdminOrdersParamList } from '../../../navigation/types'
import { useFocusFx } from '../../../../hooks/useFocusFx'
import { Button } from '@elements'

export const ExportOrdersCSV = connectStateResults(
  ({ searchState }: StateResultsProvided<AlgoliaAdminOrder & AlgoliaAdminDoc>) => {
    const farm = useSelector<RootState, Farm>(adminFarmSelector)
    //default range is last 3 months
    const now = dateTimeInZone(farm.timezone)
    const defaultMinDate = now.minus({ months: 3 }).startOf('day')
    const defaultMaxDate = now
    let searchQuery: string | undefined
    const filters: OrderFilters = {
      csaName: searchState.menu?.['csas.name'],
      productName: searchState.menu?.['products.name'],
      locationName: searchState.menu?.['locations.name'],
      distributionName: searchState.menu?.['distributions.name'],
      dateRange: {
        min: searchState.range?.date.min ?? defaultMinDate.toMillis(),
        max: searchState.range?.date.max ?? defaultMaxDate.toMillis(),
      },
    }

    if (searchState.query) {
      searchQuery = searchState.query
    }

    return (
      <Export
        title="Export to CSV"
        onPress={() => {
          openOrdersCSV(farm.id, filters, searchQuery)
        }}
      />
    )
  },
)

/**
 * This will show the all draft orders if the farmer clicks the button. It will show all un-refined orders if the farmer clicks the button again.
 */
function ViewDraftOrdersButton({ refine }: RefinementListProvided) {
  const [viewDraftOrders, setViewDraftOrders] = useState<boolean>(false)
  const navigation = useNavigation<StackNavigationProp<AdminOrdersParamList, 'AdminOrderList'>>()
  const { params } = useRoute<RouteProp<AdminOrdersParamList, 'AdminOrderList'>>()

  useFocusFx(() => {
    if (params?.draftOnly) {
      refine(['true'])
      setViewDraftOrders(true)
    }
  }, [params?.draftOnly, refine])

  const onPressHandler = useCallback(() => {
    if (viewDraftOrders) {
      refine([])
      navigation.setParams({ draftOnly: undefined })
      setViewDraftOrders(false)
    } else {
      refine(['true'])
      navigation.setParams({ draftOnly: true })
      setViewDraftOrders(true)
    }
  }, [navigation, refine, viewDraftOrders])

  return <Button small title={viewDraftOrders ? 'View All Orders' : 'View Pending Orders'} onPress={onPressHandler} />
}

export const ViewDraftOrdersButtonRefined = connectRefinementList(ViewDraftOrdersButton)
