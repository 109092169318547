import { memo } from 'react'
import { Text as DefaultText, TextProps, TextStyle } from 'react-native'

import { useStringCompareFnStyles } from '@/hooks/useFnStyles'
import { spreadStyle } from '@/hooks/useMergeStyle'
import Colors from '../../constants/Colors'
import { DEVICE_SIZE, DEVICE_SIZES, isWeb } from '../../constants/Layout'

export const typography = {
  title: {
    bold: 'AveriaSerifLibreBold',
    regular: 'AveriaSerifLibreRegular',
  },
  body: {
    bold: 'MontserratBold',
    medium: 'MontserratMedium',
    regular: 'MontserratRegular',
  },
}

export const defaultTextSize = 12

/** adjustsFontSizeToFit */
export type BodyProps = Omit<
  TextProps,
  | 'size'
  | 'color'
  /** adjustsFontSizeToFit is not working on web, so it should not be used  */
  | 'adjustsFontSizeToFit'
> & {
  color?: string
  type?: 'regular' | 'medium' | 'bold'
  center?: boolean
  size?: number
}

/** Main text element for the app, with default body style */
function TextComp({ size = defaultTextSize, center, color, type = 'regular', style, ...props }: BodyProps) {
  const { body } = useBodyStyles(size, color, type, center, style)

  return (
    <DefaultText
      selectable={isWeb} // This option will make text selectable on web by default
      style={body}
      {...props}
    />
  )
}
export const Text = memo(TextComp)

const useBodyStyles = (
  size: NonNullable<BodyProps['size']>,
  color: BodyProps['color'],
  type: NonNullable<BodyProps['type']>,
  center: BodyProps['center'],
  style: BodyProps['style'],
) =>
  useStringCompareFnStyles(
    (size, color, type, center, style) => ({
      body: {
        ...fontStyle(size),
        color: color || Colors.shades[600],
        fontFamily: typography.body[type],
        ...(center ? { textAlign: 'center' } : {}),
        ...spreadStyle(style),
      },
    }),
    size,
    color,
    type,
    center,
    style,
  )

export function TextH1(props: HeaderProps) {
  return <HeaderText size={22} {...props} />
}

export function TextH2(props: BodyProps) {
  return <Text size={14} type="medium" {...props} />
}

export function TextH3(props: BodyProps) {
  return <Text size={13} color={Colors.shades[200]} type="medium" {...props} />
}

export function TextH4(props: BodyProps) {
  return <Text type="bold" {...props} />
}

export function ErrorText(props: BodyProps) {
  return <Text size={10} color={Colors.red} type="medium" {...props} />
}

export function TextLink(props: BodyProps) {
  return <Text color={Colors.green} type="bold" {...props} />
}

export type HeaderProps = BodyProps & {
  type?: 'regular' | 'bold'
}

function HeaderTextComp({ size = 20, center, color, type = 'bold', style, ...props }: HeaderProps) {
  const styles = useHeaderStyle({ size, color, type, center, style })
  return (
    <DefaultText
      // This option will make text selectable on web by default
      selectable={isWeb}
      style={styles.header}
      {...props}
    />
  )
}
export const HeaderText = memo(HeaderTextComp)

const useHeaderStyle = (opts: Pick<HeaderProps, 'size' | 'color' | 'type' | 'center' | 'style'>) =>
  useStringCompareFnStyles(
    ({ size = 20, color, type = 'bold', center, style }) => ({
      header: {
        ...fontStyle(size),
        color: color || Colors.shades[600],
        fontFamily: typography.title[type],
        ...spreadStyle([center && { textAlign: 'center' }, style]),
      },
    }),
    opts,
  )

export const fontSize = (size: number, increment = 2) => {
  if (DEVICE_SIZE > DEVICE_SIZES.SMALL_DEVICE) return size + increment
  return size
}

export const lineHeight = (size = defaultTextSize, factor = 1.5) => {
  return size * factor
}

export const fontStyle = (size: number): TextStyle => {
  const style: TextStyle = { fontSize: fontSize(size), lineHeight: lineHeight(size) }
  return style
}
