import { omit } from '@helpers/typescript'
import { Hits } from '@models/Hits'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { prepareMarshal, prepareUnmarshal } from './encoding'
import { marshalDate, unmarshalDate } from './Time'

/** marshalHits returns Firestore marshalled data for the supplied Hits. */
export function marshalHits(hit: Partial<Hits>): DocumentData {
  const data = omit(prepareMarshal(hit), 'id') as DocumentData

  if (hit.createdAt) {
    data.createdAt = marshalDate(hit.createdAt)
  }

  return data
}

/** unmarshalHits returns a Hits from the supplied Firestore data. */
export function unmarshalHits(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): Hits {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)
  const hit = { ...data, id } as Hits

  if (data.createdAt) {
    hit.createdAt = unmarshalDate(data.createdAt)
  }

  return hit
}
