import { Icon, Text } from '@elements'
import { formatPickupDate } from '@helpers/display'
import { DateTime } from 'luxon'
import { memo, useCallback, useMemo } from 'react'
import { ScrollView, View } from 'react-native'
import { Calendar } from 'react-native-calendars'
import { DateData } from 'react-native-calendars/src/types'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import Colors from '../../../constants/Colors'
import { globalStyles } from '../../../constants/Styles'
import { DatesMapDisplay, getDateKey, SelectableDate } from './DateSelector-helper'

export const CalendarSelector = memo(function CalendarSelector({
  availableDates,
  onDayPress,
  datesMap,
}: {
  availableDates: SelectableDate[] | undefined
  onDayPress: (date: DateTime) => void
  datesMap: DatesMapDisplay
}) {
  const styles = useStyles()
  const firstSelectedDate = useMemo(() => {
    if (!availableDates || !availableDates.length) return
    const idx = availableDates.findIndex((d) => d.selected)
    return idx === -1 ? getDateKey(availableDates[0].date) : getDateKey(availableDates[idx].date)
  }, [availableDates])

  const onDayPressMemo = useCallback(
    (dateObj: DateData) => {
      const idx = availableDates!.findIndex((e) => getDateKey(e.date) === dateObj.dateString)
      if (idx !== -1) onDayPress(availableDates![idx].date)
    },
    [availableDates, onDayPress],
  )

  if (!availableDates) return <View />
  return (
    <ScrollView>
      <View style={styles.datesWrapper}>
        <View style={styles.calendarWrapper}>
          <Calendar
            current={firstSelectedDate}
            firstDay={1}
            hideExtraDays
            renderArrow={(direction) => <Icon name={`arrow-${direction}`} />}
            onDayPress={onDayPressMemo}
            minDate={getDateKey(availableDates![0].date)}
            maxDate={getDateKey(availableDates![availableDates!.length - 1].date)}
            markedDates={datesMap}
            disabledByDefault
          />
        </View>
        <View style={styles.separator} />
        <View style={styles.selectedList}>
          <Text center type="medium" size={15}>
            Your pickup date
          </Text>

          <View style={styles.availableDatesCont}>
            {availableDates
              ? availableDates
                  .filter((date) => date.selected)
                  .map((date) => (
                    <View key={date.date.toMillis()} style={styles.availableDate}>
                      <Icon style={globalStyles.margin10} name="check" />
                      <Text>{formatPickupDate(date.date)}</Text>
                    </View>
                  ))
              : null}
          </View>
        </View>
      </View>
    </ScrollView>
  )
})

const useStyles = CreateResponsiveStyle(
  {
    datesWrapper: {
      marginTop: 10,
      flexDirection: 'row',
    },

    selectedDatesCont: {
      alignItems: 'center',
    },
    calendarWrapper: {
      flex: 1,
    },

    selectedList: {
      borderWidth: 1,
      borderColor: Colors.shades['100'],
      borderRadius: 10,
      padding: 2,
      width: '50%',
    },
    scrollContCalendar: {
      marginTop: 10,
    },
    availableDatesCont: {
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    availableDate: {
      flexDirection: 'row',
      alignItems: 'center',
      marginHorizontal: 15,
      minWidth: 200,
    },
    separator: {
      width: 20,
      height: 20,
    },
    direction: {
      flexDirection: 'row',
    },
  },
  {
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      datesWrapper: {
        flexDirection: 'column',
        marginTop: 5,
      },
      selectedList: {
        width: '100%',
      },

      selectedDatesCont: {
        maxHeight: 150,
      },
      separator: {
        height: 5,
      },
      direction: {
        flexDirection: 'column',
      },

      calendarWrapper: {
        flex: undefined,
      },
    },
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      availableDatesCont: {
        flexDirection: 'column',
        flexWrap: 'nowrap',
      },
    },
  },
)
