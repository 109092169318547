import { getPickups } from '@helpers/order'
import { getProductAvailability, matchesAppModeSchedule } from '@helpers/products'
import { isAfter } from '@helpers/time'
import { isDelivery, isLocalPickup, isShipping } from '@models/Location'
import { PaymentSchedule, Product, Standard, isDigital, isPhysical } from '@models/Product'
import { DateTime } from 'luxon'

export const makeTitle = (paySchedule: PaymentSchedule) => {
  let title: string
  if (paySchedule.frequency === 'ONCE') title = `Pay-in-full`
  else title = `${paySchedule.frequency.charAt(0) + paySchedule.frequency.substring(1).toLowerCase()} Payments`
  return title
}
export const getMinCutoffWindowText = (product: Standard, isWholesale: boolean | undefined) => {
  const cutoffDays = product.distributions
    .filter((d) => !d.isHidden && !d.closed)
    .filter(matchesAppModeSchedule(isWholesale))
    .map((distro) => distro.orderCutoffWindow)

  const value = Math.min(...cutoffDays)
  if (value === 0) return 'Same day'
  if (value === 1) return '1 day in advance'
  return `${value} days in advance`
}
export const getPickupOptsHeaderText = (prod: Product, isWholesale: boolean | undefined) => {
  const schedules = prod.distributions
    ?.filter((d) => !d.isHidden && !d.closed)
    .filter(matchesAppModeSchedule(isWholesale))
    .filter((d) => {
      const distAvail = getProductAvailability(prod, d, {
        excludeClosedDistros: true,
        isWholesale,
      })
      if (!distAvail) return false
      return isAfter(distAvail.endDate, DateTime.now())
    })

  if (!schedules?.length) return ''

  const hasLocal = schedules.some((d) => isLocalPickup(d.location))
  const hasDelivery = schedules.some((d) => isDelivery(d.location))
  const hasShipping = schedules.some((d) => isShipping(d.location))

  if (hasLocal && !hasDelivery && !hasShipping) return 'Pickup'
  if (!hasLocal && hasDelivery && !hasShipping) return 'Delivery'
  if (!hasLocal && !hasDelivery && hasShipping) return 'Shipping'
  if (hasLocal && hasDelivery && !hasShipping) return 'Pickup and Delivery'
  if (hasLocal && !hasDelivery && hasShipping) return 'Pickup and Shipping'
  if (!hasLocal && hasDelivery && hasShipping) return 'Delivery and Shipping'
  if (hasLocal && hasDelivery && hasShipping) return 'Pickup, Delivery and Shipping'
  return ''
}
export const estimateNPickups = (product: Product, isWholesale: boolean | undefined) => {
  if (isPhysical(product)) {
    const nPickupsPerDistro = product.distributions
      .filter(matchesAppModeSchedule(isWholesale))
      .map((d) => getPickups(d, product, { excludeClosedDistros: true }).length)

    const min = Math.min(...nPickupsPerDistro)
    const max = Math.max(...nPickupsPerDistro)
    if (min === max) return min.toString()
    return `${min} to ${max}`
  }
  if (isDigital(product)) return 'No pickups'
}
