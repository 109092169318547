import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { farmsSelector } from '../../../redux/selectors'

import { usePropRefinement } from '@/hooks/useAlgoliaPropRefinement/useAlgoliaPropRefinement'
import { useCoords } from '@/hooks/useCoords/useCoords'
import { useSearchScreenConfigure } from '@/hooks/useSearchScreenConfigure/useSearchScreenConfigure'
import { HomeParamList } from '@/navigation/types'

export const useHomeScreenWholesaleData = () => {
  const navigation = useNavigation<StackNavigationProp<HomeParamList, 'HomeScreen'>>()

  const categoryFilter = usePropRefinement({
    attribute: 'category',
    value: undefined,
    limit: 10,
    sortBy: ['count:desc'],
  })
  const coords = useCoords() // In the home screen there's no center parameter so this can simply use the best default
  useSearchScreenConfigure(coords)

  const fassocs = useSelector(farmsSelector)

  const favorites = useMemo(() => fassocs.filter((f) => f.isFavorite).map((f) => f.farm!), [fassocs])

  const onFavoritesSeeAllPress = useCallback(
    () => navigation.navigate('Shopping', { screen: 'MyFarmsScreen' }),
    [navigation],
  )

  return {
    favorites,
    categoryFilter,
    onFavoritesSeeAllPress,
  }
}

export type HomeScreenWholesaleType = ReturnType<typeof useHomeScreenWholesaleData>
