import { createStackNavigator } from '@react-navigation/stack'
import FarmGenAdminUserLogin from '@screens/FarmGenAdmin/FarmGenAdminUserLogin'

import { FarmGenAdminParamList } from './types'
import { AnalyticsAdmin } from '../screens/FarmGenAdmin/AnalyticsAdmin'
import FarmGenAdminScreen from '../screens/FarmGenAdmin/FarmGenAdminPanel'

const FarmGenAdminStack = createStackNavigator<FarmGenAdminParamList>()

export default function FarmGenAdminNavigator() {
  return (
    <FarmGenAdminStack.Navigator screenOptions={{ headerShown: false }}>
      <FarmGenAdminStack.Screen name="FarmGenAdmin" component={FarmGenAdminScreen} />
      <FarmGenAdminStack.Screen name="AnalyticsAdmin" component={AnalyticsAdmin} />
      <FarmGenAdminStack.Screen name="FarmGenAdminUserLogin" component={FarmGenAdminUserLogin} />
    </FarmGenAdminStack.Navigator>
  )
}
