import { addBaseUnit } from '@api/BaseUnits'
import { addLocalCategory } from '@api/Categories'
import { addLocalProducer } from '@api/Producers'
import { ADD_NEW_KEYWORD, hideModal, Prompt, PromptComponentProps, Toast } from '@elements'
import { wait } from '@helpers/helpers'
import { Platform } from 'react-native'

/**
 * Helper to easily create or set items for some single-select field types.
 *
 * - It is only intended for use as the onChange for a picker component.
 * - It takes in a value and a type of item, and if the value is not equal to the string '+ Add New', it sets the field value to the value. If the value is equal to the string '+ Add New', it
 * opens a modal to create a new item of the specified type.
 *
 *
 * @param value - the new value of the input. If this is empty it will default to showing the creation prompt
 * @param type - This is the type of item that you want to create.
 * @param farmId - Admin farm id.
 * @param onSave - This handles the value selected for any external purpose such as updating state. When a new item is being created, this will be called after the async creation is done.
 */
export const createUnitOrSetValue = (
  value: string,
  type: CreateUnitOpts['type'],
  farmId: CreateUnitOpts['farmId'],
  onSave: NonNullable<CreateUnitOpts['onSave']>,
) => {
  if (value !== ADD_NEW_KEYWORD) {
    onSave(value)
  } else {
    // Since this will be called when pressing a dropdown, this must specify the delayOnIos prop, to wait for the picker dropdown to close, and only then show modal. (iOS specific issue)
    createUnitPrompt({ type, farmId, onSave, delayOnIos: 1000 })
  }
}

export type CreateUnitOpts = {
  type: 'producer' | 'category' | 'baseUnit'
  farmId: string
  /** Handles the value created. If a new item is created it will be called after the request is fulfilled */
  onSave?: (newVal: string) => void
  /** if true, this will await this much miliseconds before opening the prompt on ios */
  delayOnIos?: number
  modalComponentProps?: Parameters<typeof Prompt>[2]
} & Partial<Omit<PromptComponentProps, 'onSubmit'>>

/** Opens a prompt for creating a new unit of a determined type for the given farm.
 * - This is entirely reusable anywhere in the app for the purpose of creating new units
 */
export async function createUnitPrompt({ type, farmId, onSave, inputProps, delayOnIos, ...rest }: CreateUnitOpts) {
  if (delayOnIos && Platform.OS === 'ios') await wait(delayOnIos)

  const { modalComponentProps, ...promptProps } = rest
  if (type === 'producer') {
    Prompt(
      'Add new producer',
      {
        onSubmit: (value) =>
          addLocalProducer(value, farmId).then((res) => {
            if (res === 'already-exists') {
              hideModal()
              Toast(`${value} already exists`)
            }
            onSave?.(value)
          }),
        inputProps: {
          placeholder: 'enter producer name here',
          defaultValue: '',
          ...inputProps,
        },
        ...promptProps,
      },
      modalComponentProps,
    )
  }
  if (type === 'category') {
    Prompt(
      'Create Category',
      {
        onSubmit: (value) =>
          addLocalCategory(value, farmId).then((res) => {
            if (res === 'already-exists') {
              hideModal()
              Toast(`${value} already exists`)
            }
            onSave?.(value)
          }),
        inputProps: { placeholder: 'enter category name here', defaultValue: '', ...inputProps },
        ...promptProps,
      },
      modalComponentProps,
    )
  }
  if (type === 'baseUnit') {
    Prompt(
      'Unit Name',
      {
        onSubmit: (value) =>
          addBaseUnit(value, farmId).then((res) => {
            if (res === 'already-exists') {
              hideModal()
              Toast(`${value} already exists`)
            }
            onSave?.(value)
          }),
        inputProps: { placeholder: 'enter unit value here', defaultValue: '', ...inputProps },
        ...promptProps,
      },
      modalComponentProps,
    )
  }
}
