import React from 'react'
import { Image, ImageBackground, View } from 'react-native'
import Colors from '@/constants/Colors'
import { farmerOnboardMiscellaneousAssets } from '@/assets/images/farmer_onboard'
import {
  FarmerOnboardDemoButton,
  FarmerOnboardOpenStoreButton,
  SectionContainer,
} from '@screens/FarmerOnboard/components'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { HeaderText } from '@elements'

/**
 * `CallToActionSection` is a prominent component designed to attract users' attention and encourage them to take action.
 * It displays a background image with a call-to-action message and buttons for users to open a store or schedule a demo.
 */
export function CallToActionSection() {
  const callToActionStyles = useCallToActionStyles()

  return (
    <ImageBackground
      source={farmerOnboardMiscellaneousAssets.backgroundManGardeningWithLadder}
      style={callToActionStyles.innerContainer}
      resizeMode="cover"
    >
      <SectionContainer
        innerContainerStyle={[callToActionStyles.innerContainer, callToActionStyles.sectionContainerWidth]}
      >
        <SectionDetails />
        <SectionImage />
      </SectionContainer>
    </ImageBackground>
  )
}

const useCallToActionStyles = () =>
  useSizeFnStyles(({ isLargeDevice }) => ({
    innerContainer: {
      minHeight: isLargeDevice ? 500 : 400,
    },
    sectionContainerWidth: {
      width: '90%',
      justifyContent: 'space-between',
    },
  }))

function SectionDetails() {
  const sectionDetailsStyles = useSectionDetailsStyles()

  return (
    <View style={sectionDetailsStyles.detailsContainer}>
      <View>
        <HeaderText size={45} style={sectionDetailsStyles.headerText}>
          Get started
        </HeaderText>
        <HeaderText size={45} style={sectionDetailsStyles.headerText}>
          for free
        </HeaderText>
      </View>
      <View style={sectionDetailsStyles.buttonsContainer}>
        <FarmerOnboardOpenStoreButton style={sectionDetailsStyles.buttonContainer} />
        <FarmerOnboardDemoButton style={sectionDetailsStyles.buttonContainer} />
      </View>
    </View>
  )
}

const useSectionDetailsStyles = () =>
  useSizeFnStyles(({ isLargeDevice }) => ({
    detailsContainer: {
      marginTop: isLargeDevice ? 50 : 30,
      alignItems: isLargeDevice ? 'flex-start' : 'center',
    },
    headerText: {
      color: Colors.white,
      maxWidth: isLargeDevice ? 300 : '100%',
      alignSelf: 'center',
    },
    buttonsContainer: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    buttonContainer: { minWidth: 240 },
  }))

function SectionImage() {
  const sectionImageStyles = useSectionImageStyles()

  return (
    <Image
      source={farmerOnboardMiscellaneousAssets.farmStoreCauliflowerPhoneCombined}
      style={sectionImageStyles.image}
    />
  )
}

const useSectionImageStyles = () =>
  useSizeFnStyles(({ isSmallDevice, isMedDevice }) => {
    const width = isSmallDevice ? 300 : isMedDevice ? 350 : 490
    const height = width / (561 / 454) // had to keep it the same size or the image would get cut off or become stretched. this would allow it to stay the same size on multiple screens. aspectRatio did not work on some simulators
    return {
      image: {
        width,
        height,
        resizeMode: 'contain',
      },
    }
  })
