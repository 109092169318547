import { Image } from '@components'
import { ButtonClear, Text, TextH1, Touchable } from '@elements'
import { formatShortAddress } from '@helpers/display'
import { Farm } from '@models/Farm'
import React, { memo } from 'react'
import { StyleSheet, View } from 'react-native'

import Colors from '@/constants/Colors'
import { SHADOW_2, globalStyles } from '@/constants/Styles'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { AlgoliaGeoDoc, AlgoliaGeoFarm, isGeoFarm } from '@models/Algolia'
import { displayProductCount } from '@helpers/products-display'

/** Farm card that can receive a farm document from firestore or algolia models */
export const TopFarmCard = memo(function TopFarmCard({
  farm,
  flagText,
}: {
  farm: Farm | AlgoliaGeoDoc<AlgoliaGeoFarm>
  flagText?: string
}) {
  const imageUrls = []
  if ('media' in farm) {
    farm.media.forEach((m) => {
      if (m.storageUrl) imageUrls.push(m.storageUrl)
    })
    if (!imageUrls.length && farm.logo) {
      imageUrls.push(farm.logo)
    }
  } else {
    if (farm.images.length) imageUrls.push(...farm.images)
    else if (farm.farm.logo) {
      imageUrls.push(farm.farm.logo)
    }
  }

  let productCount: number | undefined
  if ('productCount' in farm) {
    productCount = farm.productCount
  } else {
    productCount = farm.farm.productCount
  }

  return (
    <View style={styles.wrapper}>
      {!!flagText && (
        <View style={styles.flagCont}>
          <Text>{flagText}</Text>
        </View>
      )}
      <Touchable url={`/farms/${isGeoFarm(farm) ? farm.farm.urlSafeSlug : farm.urlSafeSlug}?goBack=home`}>
        <View style={globalStyles.flex1}>
          {imageUrls.length > 1 ? (
            <View style={styles.row}>
              <Image style={styles.farmImg} source={{ uri: imageUrls[0] }} />
              <View style={styles.width20} />
              <Image style={styles.farmImg} source={{ uri: imageUrls[1] }} />
            </View>
          ) : imageUrls.length ? (
            <View>
              <Image style={styles.largeFarmImg} source={{ uri: imageUrls[0] }} />
            </View>
          ) : (
            <View>
              <Image style={styles.largeFarmImg} source={{ uri: undefined }} />
            </View>
          )}

          <View style={styles.farmNameCont}>
            <TextH1 numberOfLines={2} type="regular" size={18}>
              {farm.name}
            </TextH1>
          </View>

          <Text>{formatShortAddress(farm.address)}</Text>

          {typeof productCount === 'number' && <Text>{displayProductCount(productCount)}</Text>}
        </View>
      </Touchable>
      <ButtonClear
        size={13}
        url={`/farms/${isGeoFarm(farm) ? farm.farm.urlSafeSlug : farm.urlSafeSlug}/shop?goBack=home`}
        style={styles.shopAllBtn}
        title="Shop now"
      />
    </View>
  )
},
propsAreDeepEqual)

/** This needs to be updated if the styling of this card component ever changes in a way that produces a different card width */
export const topFarmCardWidth = 330

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    padding: 20,
    paddingBottom: 0,
    width: 300,
    marginRight: 30,
    marginBottom: 10,
  },
  row: {
    flexDirection: 'row',
  },
  width20: {
    width: 20,
  },
  flagCont: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    position: 'absolute',
    backgroundColor: Colors.white,
    borderRadius: 5,
    zIndex: 5,
    left: 25,
    top: 25,
    ...SHADOW_2,
  },
  icon: {
    marginLeft: 10,
    height: 25,
    width: 25,
    resizeMode: 'contain',
  },

  farmImg: {
    flex: 1,
    aspectRatio: 1,
    borderRadius: 10,
  },
  largeFarmImg: {
    aspectRatio: 2.2,
    resizeMode: 'cover',
    borderRadius: 10,
  },
  shopAllBtn: {
    paddingLeft: 0,
  },
  ebtCont: {
    borderWidth: 1,
    borderColor: Colors.primaryGray,
    paddingVertical: 2,
    paddingHorizontal: 10,
    borderRadius: 12,
    marginVertical: 10,
    flexDirection: 'row',
    alignSelf: 'flex-start',
    alignItems: 'center',
  },
  ebtText: {
    marginLeft: 5,
    marginVertical: 4,
    color: Colors.primaryGray,
  },
  ebtWrapper: {
    minHeight: 40,
  },
  farmNameCont: {
    minHeight: 60,
    marginTop: 16,
    flex: 1,
  },
})
