import { Currency, Money } from '@models/Money'
import DecimalCalc from './decimal'

/** Will perform computations on money */
export class MoneyCalc {
  static isCompatible(a: Money, b: Money) {
    if (a?.currency !== b?.currency) {
      throw new Error(`${a.currency} is not compatible with ${b.currency}`)
    }
    return true
  }

  /** Operations */

  // Allows adding an infinite amount of values together
  static add(...values: Money[]) {
    const currency = values[0].currency
    values.map((amt) => MoneyCalc.isCompatible(amt, values[0]))

    const moneyReducer = (values: Money[]) =>
      values.reduce((prev: Money, curr: Money) => ({
        ...prev,
        value: DecimalCalc.add(prev.value, curr.value),
      }))

    return {
      value: moneyReducer(values).value,
      currency,
    }
  }

  static subtract(lhs: Money, rhs: Money): Money {
    MoneyCalc.isCompatible(lhs, rhs)

    return {
      value: DecimalCalc.subtract(lhs.value, rhs.value),
      currency: lhs.currency,
    }
  }

  static multiply(a: Money, b: number) {
    if (Number.isNaN(b)) {
      throw new Error(`${b} is not a number`)
    }

    return {
      value: DecimalCalc.multiply(a.value, b),
      currency: a.currency,
    }
  }

  static divide(a: Money, b: number) {
    if (Number.isNaN(b)) {
      throw new Error(`${b} is not a number`)
    }

    return {
      value: DecimalCalc.divide(a.value, b),
      currency: a.currency,
    }
  }

  // Comparisons
  static isEqual(a: Money, b: Money) {
    MoneyCalc.isCompatible(a, b)

    return a.value === b.value
  }

  static isGreaterThan(a: Money, b: Money) {
    MoneyCalc.isCompatible(a, b)

    return a.value > b.value
  }

  static isGTZero(a: Money) {
    return MoneyCalc.isGreaterThan(a, MoneyCalc.fromCents(0))
  }

  static isLessThan(a: Money, b: Money) {
    MoneyCalc.isCompatible(a, b)

    return a.value < b.value
  }

  static isGTE(a: Money, b: Money) {
    MoneyCalc.isCompatible(a, b)

    return MoneyCalc.isGreaterThan(a, b) || MoneyCalc.isEqual(a, b)
  }

  static isLTE(a: Money, b: Money) {
    MoneyCalc.isCompatible(a, b)

    return MoneyCalc.isLessThan(a, b) || MoneyCalc.isEqual(a, b)
  }

  // Other
  static max(a: Money, b: Money) {
    MoneyCalc.isCompatible(a, b)

    return MoneyCalc.isGTE(a, b) ? a : b
  }

  static min(a: Money, b: Money) {
    MoneyCalc.isCompatible(a, b)

    return MoneyCalc.isLTE(a, b) ? a : b
  }

  static isZero(a: Money) {
    return a.value === 0
  }

  static isInfinite(a: Money) {
    return a.value === Infinity
  }

  static fromCents(cents: number, currency: Currency = 'usd') {
    return { value: cents, currency }
  }

  static cents(a: Money) {
    return Math.round(a.value)
  }

  /**
   * Converts the given string representation of an amount into a Money object.
   * @param amount The string representation of the amount.
   * @param currency The currency code (optional, default value is 'usd').
   * @returns The Money object representing the amount and currency.
   */
  static fromString(amount: string, currency: Currency = 'usd') {
    // Check for negative numbers, denoted by '-' at the start of the string
    const isNegative = amount.startsWith('-')
    // Remove any non-numeric and non-decimal characters (this will also remove '$')
    const sanitizedAmount = amount.replace(/[^0-9.]/g, '')

    // Parse the string to float and convert to cents
    const centAmount = Math.round(parseFloat(sanitizedAmount) * 100)

    // Account for negative numbers by multiplying by -1 if needed
    const finalCentAmount = isNegative ? centAmount * -1 : centAmount

    if (isNaN(finalCentAmount)) throw new Error(`${amount} is not a valid amount`)

    return MoneyCalc.fromCents(finalCentAmount, currency)
  }

  /**
   * Converts a Money object to a string in the format '10.00'
   * All amounts are rounded to the nearest cent
   * @param amount The amount to convert to string
   */
  static toString(amount: Money) {
    const rounded = MoneyCalc.round(amount)
    return MoneyCalc.divide(rounded, 100).value.toFixed(2)
  }

  /**
   * Rounds a Money amount to the nearest cent
   * @param amt the money object to round
   */
  static round(amt: Money) {
    return { ...amt, value: Math.round(amt.value) }
  }

  /**
   * Allows any numerical operation on the money value. Example: MoneyCalc.round(money)
   * @param mathOp the operation to perform
   * @param a the money object to perform the operation on
   */
  static math(mathOp: (x: number) => number, a: Money) {
    return { ...a, value: mathOp(a.value) }
  }
}
