import { StyleSheet, View } from 'react-native'

import { memo } from 'react'
import Colors from '../../../constants/Colors'
import { SHADOW_2 } from '../../../constants/Styles'
import { useDeviceSize } from '../../../hooks/useLayout'
import { FilterMenu } from '../Filters'

export const MapControls = memo(function MapControls() {
  const { isLargeDevice } = useDeviceSize()

  return <View style={styles.mainContainer}>{isLargeDevice && <FilterMenu />}</View>
})

const styles = StyleSheet.create({
  mainContainer: { position: 'absolute', top: 0, left: 0, flexDirection: 'row', flexWrap: 'wrap', width: '100%' },
  redoText: {
    marginLeft: 5,
    color: Colors.white,
  },
  redoButton: {
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    borderRadius: 10,
    backgroundColor: Colors.green,
    marginVertical: 10,
  },
  redoContainer: { flex: 1, flexDirection: 'row', justifyContent: 'center', minWidth: 100, paddingHorizontal: 25 },
  control: {
    ...SHADOW_2,
    position: 'absolute',
    top: 10,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
})
