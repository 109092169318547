import { voidInvoice } from '@api/Invoices'
import { Alert, Divider, FormButton, FormInput, hideModal, Toast } from '@elements'
import { Formik, FormikProps } from 'formik'
import { useState } from 'react'
import { Text, View } from 'react-native'
import * as Yup from 'yup'

import { Logger } from '../../../../../config/logger'
import { globalStyles } from '../../../../../constants/Styles'
import { isErrorWithCode } from '@shared/Errors'
import { errorToString } from '@helpers/helpers'

type FormType = {
  note: string
}

const initialValues: FormType = {
  note: '',
}

const validationSchema = Yup.object<FormType>().shape({
  note: Yup.string(),
})

/** Modal to void or un-void an invoice */
export function VoidInvoice({ invoiceId, voidInv }: { invoiceId: string; voidInv: boolean }) {
  const [loading, setLoading] = useState(false)

  const onSubmitHandler = async (values: FormType) => {
    setLoading(true)
    try {
      const { success, message } = await voidInvoice(invoiceId, voidInv, values.note)
      setLoading(false)
      hideModal()
      Alert(
        success ? `Success!` : 'Failed!',
        success
          ? `Invoice successfully ${voidInv ? 'voided' : 'un-voided'}. The changes will show up shortly.`
          : message || 'Something went wrong, please try again later or contact support if this issue persists.',
        [
          {
            text: 'OK',
            onPress: () => {},
            style: 'default',
          },
        ],
      )
    } catch (e) {
      setLoading(false)
      hideModal()
      if (isErrorWithCode(e)) {
        Alert(`Error ${voidInv ? 'voiding' : 'un-voiding'} invoice`, errorToString(e))
      } else {
        Logger.error(e)
        Toast(
          `Could not ${
            voidInv ? 'void' : 'un-void'
          }, please try again later or contact support if this issue persists.`,
        )
      }
    }
  }
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmitHandler} validationSchema={validationSchema}>
      {({ values, handleChange, handleSubmit, handleBlur }: FormikProps<FormType>) => (
        <View style={globalStyles.margin10}>
          <Text>{`Please include reason for ${voidInv ? 'voiding' : 'un-voiding'} invoice here`}</Text>
          <Divider clear />
          <FormInput
            multiline
            numberOfLines={2}
            label={null}
            placeholder="Internal Note"
            value={values.note}
            onChangeText={handleChange('note')}
            onBlur={handleBlur('note')}
          />
          <FormButton loading={loading} title="Submit" onPress={handleSubmit} />
          <Text>
            {`Are you sure you want to ${
              voidInv ? 'void' : 'un-void'
            } this invoice? You can undo this action later if you change your mind.`}
          </Text>
        </View>
      )}
    </Formik>
  )
}
