import { isObject } from '@helpers/helpers'
import { ArrElement, PartialExcept, pick } from '@helpers/typescript'
import { PickerItemProps, PickerProps as RNPickerProps } from '@react-native-picker/picker'
import { StyleProp, ViewStyle } from 'react-native'

export type PickerProps = Pick<RNPickerProps<string>, 'onBlur' | 'onFocus'> & {
  /** `Item` with a string as `value`, usually an id, or some unique identifier not used for display purposes */
  items: PickerItemProps<string>[]
  /** A string as placeholder will be adapted to the other Item type. If more control is needed, pass a full DropdownItem object. If no placeholder is desired, pass null. */
  placeholder?: string | null | PickerItemProps<string>
  /** The currently selected item; `value` should match the string `value` in one of the `items`, for the item to become selected */
  value?: string
  /** Handler called with the newly selected item.
   * - On some devices, this might be called on mount.  */
  onValueChange: (value: string, index: number) => any
  loading?: boolean
  disabled?: boolean
  /** Activates various pre-defined styles on the icon and picker */
  minimal?: boolean
  /** Goes into the view container of the picker*/
  style?: StyleProp<ViewStyle>
  /** Whether should use the native picker on web
   * - if `true`, the native picker will show instead of `BottomSheetPicker` on small devices
   * - Must be used when inside used inside modals (see [issue](https://github.com/farmgenerations/grownby/issues/8135))
   */
  useWebNativePicker?: boolean
}

export const PICKER_PLACEHOLDER = 'Select an option...'

/** Static function that converts the placeholder (depending on its type) in a Picker Item type
 *
 * @returns a selectable Picker array items of initial items and the placeholder
 */
export const processPlaceholder = (
  items: PickerProps['items'],
  placeholder: PickerProps['placeholder'],
): PickerProps['items'] => {
  // If it's null, means the user wants no placeholder
  if (placeholder === null) return items

  if (typeof placeholder === 'string') {
    // If it's a string, we wrap it as object
    return [{ value: placeholder, label: placeholder }, ...items]
  }

  // If it's an object, means the user provided a picker item
  if (isObject(placeholder)) return [placeholder, ...items]

  // If it's undefined, we generate an empty default placeholder
  return [{ label: PICKER_PLACEHOLDER, value: PICKER_PLACEHOLDER }, ...items]
}

/** Gets the label that should be displayed in the picker
 *
 * Finds the Item that corresponds to the selected value, and returns its label
 *
 * If no Item is found (and a value is defined), it will return an empty text
 *
 * Returns placeholder if defined
 */
export const getLabel = ({
  items,
  value,
  placeholder,
}: Pick<PickerProps, 'items' | 'value' | 'placeholder'>): string => {
  if (value !== undefined) {
    const selectedItem = items.find((el) => el.value === value)
    if (selectedItem) return selectedItem.label ?? ''
  }
  if (typeof placeholder === 'string') return placeholder

  if (placeholder?.label) return placeholder.label

  return ''
}

/**
 * Parses raw items and handles the case when no item is found with the selected value.
 *
 * If no item is found, the placeholder will be shown. If there is no placeholder, an empty ("") label will be shown.
 *
 * This prevents component from showing the first item without actually being selected
 */
export const parseItems = ({ items, placeholder, value }: Pick<PickerProps, 'items' | 'value' | 'placeholder'>) => {
  const itemsWithPlaceholder = processPlaceholder(items, placeholder)

  const expectedLabel = getLabel({ items, value, placeholder })
  if (expectedLabel === '' && placeholder === null) {
    /** If the value doesn't correspond to any item, the default behavior is it will select the first item.
     * If the placeholder isn't null, we don't need to do anything because a default placeholder will be the first item.
     * Only if the placeholder is null, the first item would be an option provided in the items array. Therefore only in this case we need to create an artificial placeholder to be shown as the selected item.
     */
    return [{ label: PICKER_PLACEHOLDER, value: value ?? '' }, ...itemsWithPlaceholder]
  }

  return itemsWithPlaceholder
}

/** Receives a dropdown item object, and picks only the correct values. This is to ensure only good data is passed down, in cases where typescript may allow an object with additional values be assignable to the constraints of @type {DropdownItem} */
export const getPickerItem = (itm: ArrElement<PickerProps['items']>) =>
  pick(itm, 'label', 'value', 'color', 'fontFamily', 'testID', 'style', 'enabled')

/** Constant add new keyword used in dropdowns*/
export const ADD_NEW_KEYWORD = '+ Add New'

/** Add new item option, used in various dropdowns */
export const AddNewDropdownItem: PartialExcept<PickerItemProps<string>, 'label' | 'value'> = {
  label: ADD_NEW_KEYWORD,
  value: ADD_NEW_KEYWORD,
}
