import { Spinner } from '@elements'
import { StyleSheet, View } from 'react-native'

import { Column, styles as expandableRowStyles } from '../../OfflineTable/ExpandableRow'

interface ColumnFuncs<T> {
  name: NonNullable<Column<T>['process']>
  sku: NonNullable<Column<T>['process']>
  type: NonNullable<Column<T>['process']>
  quantitySold: NonNullable<Column<T>['process']>
  grossAmount: NonNullable<Column<T>['process']>
}

export const createColumns = <T extends unknown>(funcs: ColumnFuncs<T>): Column<T>[] => {
  return [
    {
      process: funcs.name,
      widthFlex: 2,
    },
    {
      process: funcs.sku,
      widthFlex: 1,
    },
    {
      process: (x) => <View style={{ alignItems: 'flex-start' }}>{funcs.type(x)}</View>,
      widthFlex: 1,
    },
    {
      process: funcs.quantitySold,
      widthFlex: 1,
    },
    {
      process: funcs.grossAmount,
      widthFlex: 1,
    },
  ]
}

/** Row component with loading indicator and default row container style */
export function LoadingRow() {
  return (
    <View style={[styles.loadingRow, expandableRowStyles.tableRow()]}>
      <Spinner size="small" />
    </View>
  )
}

const styles = StyleSheet.create({
  loadingRow: {
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export const indent1 = 20
export const indent2 = 40
export const indent3 = 60
