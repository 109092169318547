import { hideModal } from '@elements'
import { AlertButton, ScrollView, StyleSheet, Text, View } from 'react-native'

import Colors from '../../../constants/Colors'
import { isWeb } from '../../../constants/Layout'
import { globalStyles } from '../../../constants/Styles'
import { Button } from '../Button'
import { Divider } from '../Divider'

/** Web only alert component used by the Alert() helper in Popups.tsx
 * - This is not meant to be used standalone. But rather through modals implementation.
 */
export function AlertComponentWeb({ message, buttons }: { message?: string; buttons?: AlertButton[] }) {
  /** This is only for web. In mobile we use the Alert core api */
  if (!isWeb) return null
  return (
    <ScrollView
      contentContainerStyle={[styles.paddingHorizontal, buttons && buttons.length > 0 && styles.paddingVertical]}
    >
      <Text style={globalStyles.margin10}>{message}</Text>
      <Divider clear />
      {!!buttons?.length && (
        <View style={styles.btnWrapper}>
          {buttons.map((btn, idx) => (
            <Button
              key={idx}
              outline={btn.style !== 'cancel'}
              color={btn.style === 'destructive' ? Colors.red : Colors.green}
              title={btn.text || 'OK'}
              onPress={() => {
                hideModal()
                btn.onPress?.()
              }}
            />
          ))}
        </View>
      )}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  btnWrapper: { flexDirection: 'row', justifyContent: 'flex-end', flexWrap: 'wrap' },
  paddingHorizontal: {
    paddingHorizontal: 10,
  },
  paddingVertical: {
    paddingVertical: 10,
  },
})
