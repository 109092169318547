import { Text } from '@elements'
import { extractPaymentInfo, formatMoney, formatPickupDate, getOrderNum } from '@helpers/display'
import { isNonNullish, nonEmptyString } from '@helpers/helpers'
import { MoneyCalc } from '@helpers/money'
import { format } from '@helpers/time'
import {
  Invoice,
  InvoiceItem,
  getInvoiceDiscounts,
  getInvoiceTips,
  invoiceItemTotal,
  invoiceSubtotal,
  invoiceTotal,
} from '@models/Invoice'
import { Zero } from '@models/Money'
import { Order } from '@models/Order'
import { userName } from '@models/User'
import { DateTime } from 'luxon'
import { useMemo, useState } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import { useFocusFx } from '../hooks/useFocusFx'

import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { Total } from '@/constants/types'
import { getProductFeesFromInvoice } from '@helpers/productFee'
import { getServiceFeeNameForInvoice } from '@helpers/serviceFee'
import { FeeType } from '@models/ProductFee'

export type ProcessedOrderSummaryProps = {
  currentOrder: Order
  firstInvoice?: Invoice
  /** If we are on the admin side we should hide certain values like balance amounts */
  isAdmin?: boolean
}

type RowData = {
  left: string
  right?: string
  isHeader?: boolean
  subtitle?: string
}

export type InfoRowProps = {
  data: RowData
  infoRowContainerStyle?: StyleProp<ViewStyle>
}

export function InfoRow({ data, infoRowContainerStyle }: InfoRowProps) {
  const styles = useStyles()

  return (
    <View style={styles.marginBottom15}>
      <View style={[styles.infoRowCont, infoRowContainerStyle]}>
        <View style={styles.leftDataCont}>
          <Text type={data.isHeader ? 'medium' : undefined} size={12}>
            {data.left}
          </Text>
        </View>
        {!!data.right && (
          <View style={styles.marginLeft20}>
            <Text type={data.isHeader ? 'medium' : undefined}>{data.right}</Text>
          </View>
        )}
      </View>
      {!!data.subtitle && (
        <View style={styles.infoRowSubtitleCont}>
          <Text size={10} color={Colors.shades[200]}>
            {data.subtitle}
          </Text>
        </View>
      )}
    </View>
  )
}

export function ProcessedOrderSummary({ currentOrder, firstInvoice, isAdmin }: ProcessedOrderSummaryProps) {
  const styles = useStyles()
  const [total, setTotal] = useState<Total>({
    subtotal: Zero,
    tax: Zero,
    total: Zero,
    discounts: Zero,
    ebtEligibleAmount: Zero,
  })
  const [additionalFees, setAdditionalFees] = useState<InvoiceItem[]>([])

  useFocusFx(() => {
    if (!firstInvoice) return

    setTotal({
      subtotal: invoiceSubtotal(firstInvoice),
      tax: invoiceTotal({ items: getProductFeesFromInvoice(firstInvoice, FeeType.Tax) }),
      total: invoiceTotal(firstInvoice),
      discounts: getInvoiceDiscounts(firstInvoice),
      tips: getInvoiceTips(firstInvoice),
      ebtEligibleAmount: Zero,
    })

    setAdditionalFees(getProductFeesFromInvoice(firstInvoice, FeeType.Fee))
  }, [firstInvoice])

  const mappedRows = useMemo(
    () => (firstInvoice?.payments ? mapPaymentInfoToRows(firstInvoice.payments, currentOrder.date, isAdmin) : []),
    [firstInvoice?.payments, currentOrder.date, isAdmin],
  )

  const tips =
    firstInvoice && total.tips && MoneyCalc.isGTZero(total.tips)
      ? { left: getServiceFeeNameForInvoice(firstInvoice), right: formatMoney(total.tips) }
      : undefined

  const discounts =
    total.discounts && MoneyCalc.isGTZero(total.discounts)
      ? {
          left: 'Discounts',
          right: '-' + formatMoney(total.discounts),
          subtitle: firstInvoice?.couponApplied?.coupon.name,
        }
      : undefined

  const taxes = MoneyCalc.isGTZero(total.tax) ? { left: 'Taxes', right: formatMoney(total.tax) } : undefined

  const additionalFeesRows = additionalFees.map((fee) => ({
    left: fee.description,
    right: formatMoney(invoiceItemTotal(fee)),
  }))

  const OrderSummaryRows: RowData[] = firstInvoice
    ? [
        { left: 'Order Summary', isHeader: true },
        isAdmin && nonEmptyString(currentOrder.note) ? { left: 'Note', right: currentOrder.note } : undefined,
        nonEmptyString(currentOrder.purchaseOrder)
          ? { left: 'Purchase Order', right: currentOrder.purchaseOrder }
          : undefined,
        { left: 'Subtotal', right: formatMoney(total.subtotal) },
        discounts,
        tips,
        taxes,
        ...additionalFeesRows,
        { left: 'Total', right: formatMoney(total.total), isHeader: true },
        ...mapPaymentsToRows(firstInvoice?.payments),
      ].filter(isNonNullish)
    : []
  const PaymentMethodRows: RowData[] = [{ left: 'Payment Methods', isHeader: true }, ...mappedRows]

  const CustomerRows: RowData[] = [
    { left: 'Customer', isHeader: true },
    { left: userName(currentOrder.user) },
    { left: currentOrder.user.email },
  ]

  const FarmRows: RowData[] = [{ left: 'Farm', isHeader: true }, { left: currentOrder.farm.name }]

  return (
    <View style={styles.summary}>
      <View style={styles.subTitle}>
        <Text size={12}>Order {getOrderNum(currentOrder.orderNum)}</Text>
        <View style={styles.separator} />
        <Text numberOfLines={2} style={globalStyles.flex1} size={12}>
          Placed on: {formatPickupDate(currentOrder.date)}
        </Text>
      </View>

      <View style={styles.contents}>
        <View style={styles.rowCont}>
          {OrderSummaryRows.map((row) => (
            <InfoRow key={row.left} data={row} />
          ))}
        </View>
        <View style={[styles.rowCont, styles.rowBorder]}>
          {PaymentMethodRows.map((row) => (
            <InfoRow data={row} key={row.left} />
          ))}
        </View>
        {isAdmin ? (
          <View style={[styles.rowCont, styles.rowBorder]}>
            {CustomerRows.map((row) => (
              <InfoRow key={row.left} data={row} />
            ))}
          </View>
        ) : (
          <View style={[styles.rowCont, styles.rowBorder]}>
            {FarmRows.map((row) => (
              <InfoRow key={row.left} data={row} />
            ))}
          </View>
        )}
      </View>
    </View>
  )
}
export const mapPaymentInfoToRows = (
  payments: Invoice['payments'],
  orderDate: DateTime,
  isAdmin?: boolean,
): RowData[] => {
  const rows: RowData[] = []

  Object.values(payments).forEach((pmt) => {
    const lastUpdateDate = pmt.refunds?.[0].date || orderDate
    rows.push({ left: extractPaymentInfo(pmt) })

    if (!isAdmin && pmt.paymentMethod.ebtCashRemainingBalance) {
      rows.push({
        left: 'EBT CASH balance',
        right: formatMoney(pmt.paymentMethod.ebtCashRemainingBalance),
        subtitle: `(as of ${format(lastUpdateDate.toLocal(), 'MM/dd/yy @ h:mmaaa')})`,
      })
    }

    if (!isAdmin && pmt.paymentMethod.ebtRemainingBalance) {
      rows.push({
        left: 'EBT SNAP balance',
        right: formatMoney(pmt.paymentMethod.ebtRemainingBalance),
        subtitle: `(as of ${format(lastUpdateDate.toLocal(), 'MM/dd/yy @ h:mmaaa')})`,
      })
    }
    if (pmt.paymentMethod.farmCreditRemainingBalance) {
      rows.push({ left: 'Remaining Farm Credit', right: formatMoney(pmt.paymentMethod.farmCreditRemainingBalance) })
    }
  })

  return rows
}
export const mapPaymentsToRows = (payments: Invoice['payments']): RowData[] => {
  const rows: RowData[] = []

  // Filter values that are zero
  Object.values(payments)
    .filter((pmt) => !MoneyCalc.isZero(pmt.totalPaid))
    .map((pmt) => {
      rows.push({ left: 'Paid ' + extractPaymentInfo(pmt), right: formatMoney(pmt.totalPaid) })
    })
  return rows
}

const useStyles = CreateResponsiveStyle(
  {
    summary: {
      borderWidth: 1,
      borderColor: Colors.lightGray,
      borderRadius: 10,
      overflow: 'hidden',
      marginTop: 15,
    },
    subTitle: {
      flexDirection: 'row',
      backgroundColor: '#F2F4F2',
      padding: 15,
      paddingHorizontal: 20,
    },
    contents: {
      paddingVertical: 10,
      marginHorizontal: 5,
      flexDirection: 'row',
    },
    rowCont: {
      padding: 10,
      flexGrow: 1,
    },
    rowBorder: {
      borderLeftWidth: 1,
      borderColor: Colors.shades[100],
    },
    separator: {
      borderLeftWidth: 2,
      borderColor: Colors.shades[100],
      marginHorizontal: 10,
      height: '100%',
    },
    marginBottom15: {
      marginBottom: 15,
    },
    leftDataCont: {
      maxWidth: 350,
    },
    infoRowCont: {
      flexDirection: 'row',
      marginHorizontal: 15,
      justifyContent: 'space-between',
    },
    marginLeft20: {
      marginLeft: 20,
    },
    infoRowSubtitleCont: {
      marginLeft: 20,
      marginTop: 5,
    },
  },
  {
    [maxSize(DEVICE_SIZES.SM)]: {
      rowBorder: {
        borderLeftWidth: 0,
        borderTopWidth: 1,
      },
      contents: {
        flexDirection: 'column',
      },
      leftDataCont: {
        maxWidth: '100%',
      },
    },
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      subTitle: {
        paddingHorizontal: 5,
      },
      separator: {
        marginHorizontal: 5,
      },
    },
  },
)
