import { mapPaymentsToRows } from '@components'
import { Button, Text } from '@elements'
import { formatMoney, getOrderNum } from '@helpers/display'
import { MoneyCalc } from '@helpers/money'
import { format } from '@helpers/time'
import { Invoice } from '@models/Invoice'
import { Money, Zero } from '@models/Money'
import React from 'react'
import { View } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import { globalStyles } from '../../../constants/Styles'

import Colors from '@/constants/Colors'
import { objToStr } from '@helpers/log'

type InvoiceHeaderProps = {
  invoice: Invoice
  amountDue: Money
  onDownloadPress?: () => void
}

export function InvoiceHeader({ amountDue, invoice, onDownloadPress }: InvoiceHeaderProps) {
  const styles = useStyles()
  const isPartiallyPaid =
    MoneyCalc.isGreaterThan(invoice.amountTotal, invoice.amountPaid || Zero) &&
    !MoneyCalc.isZero(invoice.amountPaid || Zero)

  return (
    <View style={[styles.borderedCont, globalStyles.alignCenter]}>
      <Text type="medium" size={32}>
        {formatMoney(amountDue.value || invoice.amountTotal)}
      </Text>
      {isPartiallyPaid ? (
        <>
          <View style={styles.partiallyPaidCont}>
            <View style={{ marginRight: 10 }}>
              <Text size={12} style={styles.text}>
                Invoice number:
              </Text>
              <Text size={12} style={styles.text}>
                Due by
              </Text>
              <Text size={12} style={styles.text}>
                Total paid amount:
              </Text>
              {mapPaymentsToRows(invoice.payments).map((pmt) => (
                <Text key={objToStr(pmt)} size={12} style={styles.text}>
                  {pmt.left}
                </Text>
              ))}
            </View>
            <View style={{ marginLeft: 10 }}>
              <Text size={12} style={styles.text}>
                {getOrderNum(invoice.invoiceNum)}
              </Text>
              <Text size={12} style={styles.text}>
                {format(invoice.dueDate, 'MMM. dd, yyyy')}
              </Text>
              <Text size={12} style={styles.text}>
                {formatMoney(invoice.amountPaid ?? Zero)}
              </Text>
              {mapPaymentsToRows(invoice.payments).map((pmt) => (
                <Text key={objToStr(pmt)} size={12} style={styles.text}>
                  {pmt.right}
                </Text>
              ))}
            </View>
          </View>
        </>
      ) : (
        <>
          <Text size={14} style={styles.text}>
            Invoice number: {getOrderNum(invoice.invoiceNum)}
          </Text>

          <Text size={14} style={styles.text}>
            Due by {format(invoice.dueDate, 'MMM. dd, yyyy')}
          </Text>

          <Button outline style={globalStyles.margin20} title="Download Invoice" onPress={onDownloadPress} />
        </>
      )}
    </View>
  )
}
const useStyles = CreateResponsiveStyle(
  {
    margin: globalStyles.margin10,

    borderedCont: {
      borderWidth: 1,
      borderColor: Colors.shades[100],
      borderRadius: 10,
      padding: 20,
      marginVertical: 10,
    },
    header: {
      color: Colors.primaryGray,
    },
    text: {
      marginVertical: 5,
    },
    headerText: {
      fontSize: 14,
      marginTop: 15,
    },
    subtotalsContainer: {
      alignSelf: 'flex-end',
      flexDirection: 'row',
    },
    totalsContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginVertical: 10,
    },
    paymentHeaderRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    leftColumn: {
      marginRight: 30,
      alignItems: 'flex-end',
    },

    rightColumn: {
      alignItems: 'flex-end',
    },

    orderHeaderCont: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    rowLeftCont: {
      flex: 3,
      marginVertical: 10,
      flexDirection: 'row',
    },
    rowRightCont: {
      flex: 2,
      marginVertical: 10,
      flexDirection: 'row',
    },
    rightSideRow: {
      flex: 1,
      alignItems: 'flex-end',
    },
    partiallyPaidCont: {
      flex: 1,
      flexDirection: 'row',
    },
  },
  {
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {},
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      borderedCont: {
        padding: 5,
      },
      rowLeftCont: {
        flex: 1,
      },
      rightSideRow: {
        marginRight: 5,
      },
    },
  },
)
