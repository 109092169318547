import { createNewPhoneUser, syncMobileLoginToWebLogin } from '@api/Sessions'
import { loadUser } from '@api/Users'
import { Alert } from '@elements'
import { User } from '@models/User'
import { isNotFound } from '@shared/Errors'
import { useEffect, useRef, useState } from 'react'

import { Logger } from '../../../config/logger'
import { auth } from '@api/db'
import { errorToString } from '@helpers/helpers'
import { Platform } from 'react-native'

/**
 * FirebaseAuth has a feature on Android where it can determine if the device is registered with the provided phone number
 * and can automatically fill in the OTP and sign in the user. This is great for Android users, but it breaks our register
 * flow. We expect that all users will type in the OTP and click continue, because that is what triggers our check for if
 * the user exists and will create the user account if they do not exist. So this hook provides a helpful method for
 * determining if the user was signed in with autoVerify and can create their account if so. It also handles syncing the
 * sign in state from the mobile auth to the web auth.
 */

/** This hook is used to register users on Android devices that have autoVerify for phone auth enabled.*/
export const useRegisterWithAutoVerify = () => {
  const [newUserInfo, setNewUserInfo] = useState<Pick<User, 'name' | 'email' | 'phoneNumber'>>()
  const unsubscribeRef = useRef<() => void>(() => undefined)

  useEffect(() => {
    // Only on Android will we use this because all other devices follow the regular authentication flow.
    if (Platform.OS !== 'android') return

    // Subscribe to the native auth so that if the user is auto logged in we know about it
    unsubscribeRef.current = auth()
      .getNativeAuth()!
      .onAuthStateChanged((nativeAuthUser) => {
        // Make sure that the user is signed in to the native auth
        if (!nativeAuthUser?.uid) return

        // If the user is signed in on the NATIVE auth but not web auth then we need to sync that and sign then into the
        // web auth so that the requests to Firebase are all authenticated. So here we make sure they are not already
        // signed in to the web auth
        if (!auth().getAuth().currentUser) {
          syncMobileLoginToWebLogin(nativeAuthUser).catch((err) => {
            Alert('Error with login', errorToString(err))
          })
        }

        // If newUserInfo is defined, then it means the user is registering and not logging in. So we must create an
        // account. If it is not defined, then they are just logging in, and we can skip this.
        if (newUserInfo) {
          loadUser(nativeAuthUser.uid)
            .catch(async (err) => {
              // If there is an unknown error that happens here notify the user
              if (!isNotFound(err)) {
                Alert('Loading Error', 'There was an error loading your account, please contact support.')
                Logger.error('Unknown error when loading user', err)
                return
              }
              // If it is a not found error than it is what we expect, and we create the new user
              await createNewPhoneUser({ ...newUserInfo, id: nativeAuthUser.uid })
            })
            .catch((err) => {
              // There was an error creating the user
              Alert('Error Creating an Account', 'There was an error creating your account, please contact support.')
              Logger.error('Unknown error when creating a users account', err)
            })
        }
      })

    return unsubscribeRef.current
  }, [newUserInfo])

  return {
    start: setNewUserInfo,
    stop: unsubscribeRef.current,
  }
}
