import { Text, Touchable } from '@elements'
import { sortByEarliest, sortByLatest } from '@helpers/sorting'
import { Farm } from '@models/Farm'
import { Order, OrderItem, Pickup, isPickupItemActive } from '@models/Order'
import { ProductType, isShare } from '@models/Product'
import { dateTimeInZone } from '@models/Timezone'
import { RefObject } from 'react'
import { GestureResponderEvent, LayoutRectangle, ScrollView, StyleSheet, View, ViewStyle } from 'react-native'
import { useSelector } from 'react-redux'

import { SubRowsPickups } from './SubRowsPickups'

import { ExpandableRow, ExpandableRowProps } from '@/admin/components/OfflineTable/ExpandableRow'
import { ShareRowProp } from '@/admin/components/SubscriptionAccordion'
import Colors from '@/constants/Colors'
import { useHasPermissionWithFlag } from '@/hooks/useHasPermission'
import { RootState } from '@/redux/reducers/types'
import { adminFarmSelector } from '@/redux/selectors'
import { AccessRight, Permission } from '@helpers/Permission'

import { formatDistributionType } from '@helpers/location'
import { matchPickupItemsFromProductAndOrder } from '@helpers/pickups'

type Props = {
  pickups: Pickup[]
  reload: () => void
  shareRowProps: ShareRowProp[]
  scrollRef: RefObject<ScrollView>
  subscriptionsLayout: LayoutRectangle | undefined
}

/** Sub rows first level: From order rows to orderItem sub rows. */
export function useGenerateSubRowsOrderItems({
  pickups,
  reload,
  shareRowProps,
  scrollRef,
  subscriptionsLayout,
}: Props): ExpandableRowProps<Order>['generateSubRows'] {
  const farm = useSelector<RootState, Farm>(adminFarmSelector)
  const hasAccessEditOrder = useHasPermissionWithFlag(Permission.Orders, AccessRight.Edit) ?? false

  return (order: Order) => {
    let indexRow = 0
    const subRows: JSX.Element[] = []

    //get standard order items and share order items
    const standardOrderItems: OrderItem[] = []
    const shareOrderItems: OrderItem[] = []
    order.items.forEach((orderItem) => {
      if (!isShare(orderItem.product)) {
        //This will include standard and digital
        standardOrderItems.push(orderItem)
      } else {
        shareOrderItems.push(orderItem)
      }
    })

    const standardHeaderColumns = [
      { process: () => <Text style={reactNavStyles.fontWeightBold}>Standard Products</Text>, widthFlex: 2 },
      { process: () => <Text style={reactNavStyles.fontWeightBold}>Buying option</Text>, widthFlex: 1.5 },
      { process: () => <Text style={reactNavStyles.fontWeightBold}>Qty</Text>, widthFlex: 1 },
      {
        process: () => <Text style={reactNavStyles.fontWeightBold}>Schedule</Text>,
        widthFlex: 2,
      },
      { process: () => <Text style={reactNavStyles.fontWeightBold}>Location</Text>, widthFlex: 1.85 },
      { widthFlex: 1.8 },
      { widthFlex: 1.2 },
    ]
    const standardHeader = (
      <ExpandableRow
        key={`standardHeader_${order.orderNum}`}
        columns={standardHeaderColumns}
        item={order}
        index={indexRow}
        rowContainerStyle={[styles.backgroundColor(indexRow, true), styles.header]}
      />
    )

    if (standardOrderItems.length > 0) subRows.push(standardHeader)

    //Can be use in standard and share item
    const distroProcess = (item: OrderItem) => {
      const distroInfoFromPickup = pickups.find((pickup) => pickup.distribution.id === item.distribution?.id)
      const getDistroName = distroInfoFromPickup?.distribution.name

      return (
        <View key={item.id}>
          {distroInfoFromPickup ? (
            <Text numberOfLines={2}>{getDistroName}</Text>
          ) : (
            <Text numberOfLines={2}>No distribution</Text>
          )}
        </View>
      )
    }

    //Can be used for standard or share item
    const pickLocationProcess = (item: OrderItem) => {
      //digital product do not have pickup location
      if (!item.distribution) return <Text numberOfLines={2}>No Pickups</Text>

      //if there is a next pickup (active) and not cancelled
      const foundNextPickup = [...pickups].sort(sortByEarliest('date')).find(
        (pickup) =>
          pickup.date.endOf('day') > dateTimeInZone(farm.timezone) &&
          !!pickup.items.find((itm) => {
            return isPickupItemActive(itm) && matchPickupItemsFromProductAndOrder(item.product.id, order.id)(itm)
          }),
      )

      // If there is no next pickup, then get the last pickup. It doesn't matter if it is cancelled or not
      let lastPastPickup: Pickup | undefined

      if (!foundNextPickup) {
        lastPastPickup = [...pickups]
          .sort(sortByLatest('date'))
          .find((pickup) => pickup.items.find(matchPickupItemsFromProductAndOrder(item.product.id, order.id)))
      }

      return (
        <View key={item.id}>
          <Text numberOfLines={3}>
            {foundNextPickup ? foundNextPickup.distribution.locationName : lastPastPickup?.distribution.locationName}
          </Text>
        </View>
      )
    }

    //StandardProRows
    standardOrderItems.forEach((orderItem) => {
      const itemNameProcess = (item: OrderItem) => item.product.name
      const buyOptionProcess = (item: OrderItem) => item.purchasedUnit?.name || ''
      const qtyProcess = (item: OrderItem) => String(item.quantity)

      // display multiple pickups/deliveries if there are multiple pickups/deliveries for this standard orderItem
      const conditionProcess = () => {
        if (multiStandardPickups.length > 1) {
          return (
            <Text>
              {`Multiple ${formatDistributionType(
                { type: multiStandardPickups[0].distribution.locationType },
                { capitalize: true, plural: true },
              )}`}
            </Text>
          )
        } else return <View />
      }

      //get the all pickups for this order and orderItem
      const multiStandardPickups = [...pickups]
        .filter((pickup) => pickup.items.find(matchPickupItemsFromProductAndOrder(orderItem.product.id, order.id)))
        .sort(sortByLatest('date'))

      indexRow++
      subRows.push(
        <ExpandableRow
          key={`standardItem_${orderItem.product.id}_${order.orderNum}_${indexRow}`}
          columns={[
            { process: itemNameProcess, widthFlex: 2 },
            { process: buyOptionProcess, widthFlex: 1.5 },
            { process: qtyProcess, widthFlex: 1 },
            { process: distroProcess, widthFlex: 2 },
            { process: pickLocationProcess, widthFlex: 1.85 },
            { widthFlex: 1.8 },
            { process: conditionProcess, widthFlex: 1.2 },
          ]}
          item={orderItem}
          index={indexRow}
          entypoAccordionIcon={{
            expand: 'chevron-with-circle-up',
            collapse: 'chevron-with-circle-down',
          }}
          generateSubRows={
            orderItem.product.type === ProductType.Standard
              ? SubRowsPickups(multiStandardPickups, order.id, indexRow, reload, hasAccessEditOrder)
              : undefined
          }
          rowContainerStyle={[styles.backgroundColor(indexRow), styles.rowCont]}
        />,
      )
    })

    //ShareSubHeader (part of ShareProRows, but it is just a header)
    const shareHeaderColumns = [
      { process: () => <Text style={reactNavStyles.fontWeightBold}>Shares</Text>, widthFlex: 2 },
      { widthFlex: 1.5 },
      { widthFlex: 1 },
      { process: () => <Text style={reactNavStyles.fontWeightBold}>Schedule</Text>, widthFlex: 2 },
      { process: () => <Text style={reactNavStyles.fontWeightBold}>Location</Text>, widthFlex: 1.85 },
      { widthFlex: 1.8 },
      { widthFlex: 1.2 },
    ]
    const shareHeader = () => (
      <ExpandableRow
        key={`shareHeader_${order.orderNum}`}
        columns={shareHeaderColumns}
        item={order}
        index={++indexRow}
        rowContainerStyle={[styles.backgroundColor(indexRow, true), styles.header]}
      />
    )

    if (shareOrderItems.length > 0) subRows.push(shareHeader())

    //share sub rows
    shareOrderItems.forEach((orderItem) => {
      const itemNameProcess = (item: OrderItem) => item.product.name

      const manageProcess = (item: OrderItem) => {
        const rowData = shareRowProps.find(
          (prop) => prop.prodId === item.product.id && prop.orderNum === order.orderNum,
        )
        return (
          <Touchable
            children={rowData ? <Text style={reactNavStyles.blueText}>View details</Text> : null}
            onPress={(_evt: GestureResponderEvent) => scrollToSubscriptionFunc(rowData)}
            disabled={!rowData}
          />
        )
      }
      const scrollToSubscriptionFunc = (rowData: ShareRowProp | undefined) => {
        if (rowData && subscriptionsLayout) {
          /** expand share then scroll */
          //close all other accordions and open the selected one
          rowData.expand()
          //scroll to table & row `y` offset and expand accordion
          const rowLayout: LayoutRectangle = rowData.layout
          scrollRef.current?.scrollTo({
            animated: true,
            y: subscriptionsLayout.y + rowLayout.y + 50, //there is an additional scrollview with extra offset, which requires additional y offset, but it's impractical and unscalable to add the y offset of every single parent, so we just add 50 as an estimated extra offset, which works well, empirically
          })
        }
      }

      indexRow++
      subRows.push(
        <ExpandableRow
          key={`shareItem_${orderItem.product.id}_${order.orderNum}`}
          columns={[
            { process: itemNameProcess, widthFlex: 2 },
            { widthFlex: 1.5 },
            { widthFlex: 1 },
            { process: distroProcess, widthFlex: 2 },
            { process: pickLocationProcess, widthFlex: 1.85 },
            { widthFlex: 1.8 },
            { process: manageProcess, widthFlex: 1.2 },
          ]}
          item={orderItem}
          index={indexRow}
          rowContainerStyle={[styles.backgroundColor(indexRow), styles.rowCont]}
          entypoAccordionIcon={{
            expand: 'chevron-with-circle-up',
            collapse: 'chevron-with-circle-down',
          }}
        />,
      )
    })

    return subRows
  }
}

const styles = {
  rowCont: { borderWidth: 0 },
  backgroundColor: (indexRow: number, isHeader = false): ViewStyle => ({
    backgroundColor: isHeader ? Colors.lightGreen : indexRow % 2 === 0 ? Colors.lightGray : undefined,
  }),
  header: {} as ViewStyle,
}

const reactNavStyles = StyleSheet.create({
  fontWeightBold: { fontWeight: 'bold' },
  blueText: { color: Colors.blue, textDecorationLine: 'underline' },
  grayText: { color: Colors.shades['300'], textDecorationLine: 'underline' },
})
