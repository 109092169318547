import { capitalize, plural } from './display'
import { Order, OrderStatusType } from '@models/Order'
import { DraftOrder, DraftOrderState, isDraftOrder } from '@models/DraftOrder'

/** get order status (active, cancelled, item_cancelled) */
const orderStatus = (order: Order): OrderStatusType => {
  if (order.items.every((itm) => itm.cancelled)) {
    return OrderStatusType.Cancelled
  } else if (order.items.some((itm) => itm.cancelled)) {
    return OrderStatusType.ItemCancelled
  } else return OrderStatusType.Active
}
/** The label variation will depend on the order status. It can handle draft orders as well as regular orders. */
export const getOrderStatusLabel = (order: Order | DraftOrder): string => {
  if (isDraftOrder(order)) return capitalize(order.state)

  if (orderStatus(order) === OrderStatusType.Cancelled) {
    return 'Order cancelled'
  } else if (orderStatus(order) === OrderStatusType.ItemCancelled) {
    const count = order.items.filter((itm) => itm.cancelled).length
    return `${count} ${plural(count, ' item')} cancelled`
  } else return 'Final'
}

/** This will get a displayable status to show for draft orders in the UI*/
export const getDraftOrderStatus = (draftOrder: DraftOrder) => {
  switch (draftOrder.state) {
    case DraftOrderState.Pending:
      return 'Pending Approval'
    case DraftOrderState.Approved:
      return 'Pending Fulfilment'
    case DraftOrderState.Declined:
      return 'Order Declined'
    default:
      return 'Unknown Order Status'
  }
}
