import { getDisplayName } from '@helpers/client'
import { SignedInState } from '@models/User'
import * as React from 'react'
import { memo } from 'react'
import { useSelector } from 'react-redux'

import { ModalComponent } from '../components/elements/Overlays/Modal/ModalComponent'
import { LoginComponent, LoginComponentProps } from '../components/Login/LoginComponent'
import { userSelector } from '../redux/selectors'
import { useFocusFx } from './useFocusFx'
import useKeyedState from './useKeyedState'

import { ModalComponentProps } from '@/constants/types/modalTypes'
import { onUserAuthChange } from '@/redux/actions/user'
import { omit } from '@helpers/typescript'

/** The new component composed by the HOC will also receive these additional props. */
type ExtraProps = {
  /** onDismiss will be passed to the modal component if the hoc uses overlay */
  onDismiss?: ModalComponentProps['onDismiss']
}

/** Options for the functionality of the hoc */
type Options = Pick<LoginComponentProps, 'noSafeAreaInsets' | 'provider' | 'type'> & {
  /** Whether the component should have its login UI on an overlay modal (useful if your wrapped component is part of another screen and you would like to circumvent that flow) */
  useOverlay?: boolean
}

/** Protects the content behind authentication */
export function withAuth<P extends object>(Component: React.ComponentType<P & ExtraProps>, opts?: Options) {
  const NewComponent = memo(function (props: P & ExtraProps): JSX.Element {
    const user = useSelector(userSelector)
    const [{ authState, loadingState }, , setState] = useKeyedState<{
      authState: SignedInState
      loadingState: boolean
    }>({
      authState: SignedInState.UNKNOWN,
      loadingState: true,
    })
    useFocusFx(() => {
      return onUserAuthChange(({ state: newState }) => {
        setState({ authState: newState, loadingState: false })
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const isLoading =
      loadingState ||
      authState === SignedInState.UNKNOWN ||
      (authState === SignedInState.SIGNEDIN && (!user.id || !user.email))

    if (!isLoading && authState === SignedInState.SIGNEDIN) {
      return <Component {...props} />
    }

    if (!opts?.useOverlay) {
      return <LoginComponent loading={isLoading} {...opts} />
    }

    return (
      <ModalComponent visible onDismiss={props.onDismiss}>
        <LoginComponent loading={isLoading} {...omit(opts, 'noSafeAreaInsets')} noSafeAreaInsets />
      </ModalComponent>
    )
  })
  NewComponent.displayName = `WithAuth(${getDisplayName(Component)})`
  return NewComponent
}
