import { Button, ButtonGroup, KeyboardAvoidingScrollView, Picker, TextH1, TextH2 } from '@elements'
import { DefaultCatalog } from '@models/Product'
import { useContext } from 'react'
import { View } from 'react-native'

import { AdminUserAutoComplete } from '../../../components/AdminUserAutocomplete'
import { OrderCreatorScreenContext, catalogSelectorItems, goBackString, goBackUrl } from './OrderCreatorScreen.helper'
import { OrderSummary } from './components/OrderCreatorSummary/OrderCreatorSummary'

import { BackTo } from '@/admin/components/BackTo'
import { useCartService } from '@/hooks/useCart'
import { useLayout } from '@/hooks/useLayout'
import { adminFarmIdSelector, adminFarmSelector } from '@/redux/selectors'
import { useSelector } from 'react-redux'
import { CustomerStatus } from './CustomerStatus'
import { styles, useStyles } from './OrderCreatorScreenUI-styles'
import { OrderCreatorTable } from './OrderCreatorTable'
import { ProductRow } from './components/Rows/ProductRow'

/** UI for the create order screen */
export function OrderCreatorScreenUI() {
  const adminFarmId = useSelector(adminFarmIdSelector) || ''
  const farm = useSelector(adminFarmSelector)
  const {
    custId,
    goBack,
    typeButtons,
    typeSelectedIndex,
    orderType,
    distroDropdownOpts,
    keyedStateProdsFilters: {
      0: { schedule, pickupDate, csa, prodSearchTerm },
      3: settersProdsFilters,
    },
    keyedStatePayments: {
      0: { isSubmitting },
    },
    setDistro,
    loadingSchedules,
    dateDropdownOpts,
    setDate,
    csaDropdownOpts,
    setCsa,
    csasApiFx,
    customerFx: { data: customer, loading: loadingCustomer },
    placeOrderCheck,
    customerInputRef,
    onUserSelected,
    onSelectCatalog,
    isWholesaleOrderCreator,
    isLoadingProductsInit,
    isLoadingProducts,
    tableData,
  } = useContext(OrderCreatorScreenContext)
  const stylesResp = useStyles()
  const { isSmallDevice } = useLayout()
  const { cart } = useCartService({
    cartServiceType: 'orderCreator',
    farmId: adminFarmId,
    isWholesale: isWholesaleOrderCreator,
  })

  return (
    <KeyboardAvoidingScrollView extraHeight={150} contentContainerStyle={stylesResp.screenContStyle}>
      <View style={stylesResp.orderOptions}>
        {/** This BackTo should use url because using onPress might not work if the Customers navigator isn't loaded due to lazy loading (Happens when you reload the screen) */}
        <BackTo title={`Back to ${goBackString(goBack)}`} url={goBackUrl({ custId, goBack })} />
        <View style={styles.wrapRow}>
          <TextH1 style={stylesResp.wrapItem}>New Order</TextH1>
          {typeof isWholesaleOrderCreator === 'boolean' && farm.isWholesale ? (
            <Picker
              value={isWholesaleOrderCreator ? DefaultCatalog.Wholesale : DefaultCatalog.Retail}
              style={stylesResp.wrapItem}
              items={catalogSelectorItems}
              onValueChange={onSelectCatalog}
            />
          ) : null}
        </View>
        {typeButtons.length > 1 ? (
          <View style={stylesResp.marginV}>
            <TextH2 style={styles.marginTop15}>Select Product Type</TextH2>
            <ButtonGroup
              appearance="fill"
              buttons={typeButtons}
              selectedIndex={typeSelectedIndex}
              useIcon
              small={isSmallDevice}
            />
          </View>
        ) : null}
        {orderType !== 'digital' ? (
          <View style={styles.wrapRow}>
            <View style={stylesResp.wrapItem}>
              <TextH2>Select Schedule</TextH2>
              <Picker
                items={distroDropdownOpts}
                placeholder="Schedule @ Location"
                value={schedule?.id}
                onValueChange={setDistro}
                style={styles.marginV15}
                loading={loadingSchedules}
              />
            </View>
            {orderType === 'standard' ? (
              <View style={stylesResp.wrapItem}>
                <TextH2>Select Date</TextH2>
                <Picker
                  items={dateDropdownOpts}
                  placeholder="Choose date"
                  value={pickupDate?.toISODate()}
                  onValueChange={setDate}
                  style={styles.marginV15}
                  loading={loadingSchedules}
                  disabled={!schedule}
                />
              </View>
            ) : (
              <View style={stylesResp.wrapItem}>
                <TextH2>CSA Group</TextH2>
                <Picker
                  items={csaDropdownOpts}
                  placeholder="Choose CSA"
                  value={csa?.id}
                  onValueChange={setCsa}
                  style={styles.marginV15}
                  loading={csasApiFx.loading}
                />
              </View>
            )}
          </View>
        ) : null}
        <TextH2 style={styles.marginTop15}>Choose Products</TextH2>
        <OrderCreatorTable
          data={tableData}
          orderType={orderType}
          search={settersProdsFilters.prodSearchTerm}
          searchTerm={prodSearchTerm}
          isLoading={isLoadingProductsInit}
          isLoadingNonBlock={isLoadingProducts}
          ProductRow={ProductRow}
        />
      </View>
      {cart.length > 0 && (
        <View style={stylesResp.orderSummaryWrapper}>
          <View style={styles.btnsContainer}>
            <Button
              loading={isSubmitting || loadingCustomer}
              outline
              title="Place and start new"
              onPress={() => placeOrderCheck(true)}
            />
            <Button loading={isSubmitting || loadingCustomer} title="Place order" onPress={() => placeOrderCheck()} />
          </View>
          <OrderSummary>
            <TextH2 style={styles.marginTop15}>Add Customer</TextH2>
            <AdminUserAutoComplete inputRef={customerInputRef} onSelect={onUserSelected} />
            <CustomerStatus custId={custId} customer={customer} hasItems={cart.length > 0} loading={loadingCustomer} />
          </OrderSummary>
          <Button
            style={stylesResp.largePlaceOrderBtn}
            loading={isSubmitting || loadingCustomer}
            title="Place order"
            onPress={() => placeOrderCheck()}
          />
        </View>
      )}
    </KeyboardAvoidingScrollView>
  )
}
