import { BottomSheetList, Hoverable, Icon, LoadingView, Text, Toast } from '@elements'
import { memo, useCallback, useState } from 'react'
import { Pressable, StyleSheet } from 'react-native'

import Colors from '@/constants/Colors'
import { ProcessedMenuItem } from '@screens/Shopping/FarmShop/Filtering/filterUtils'
import { FilterItem } from '../FilterItem/FilterItem'

const placeholder = 'Select location'

type LocationDropdownProps = {
  locations: ProcessedMenuItem[]
  loading?: boolean
  onSelect: (item: ProcessedMenuItem) => void
}

export const LocationDropdown = memo(function LocationDropdown(props: LocationDropdownProps) {
  const { locations, onSelect, loading = false } = props

  const [visible, setVisible] = useState(false)
  const toggleVisible = useCallback(() => setVisible((c) => !c), [])

  const onLocationPress = useCallback(
    (item: ProcessedMenuItem) => {
      Toast(item?.shouldClear ? 'Showing all products' : `Showing products available at ${item.label}`)

      onSelect(item)
      setVisible(false)
    },
    [onSelect],
  )

  if (!locations?.length) return null

  return (
    <LocationDropdownUI
      loading={loading}
      toggleVisible={toggleVisible}
      locations={locations ?? []}
      visible={visible}
      onSelect={onLocationPress}
    />
  )
})

type LocationDropdownUIProps = LocationDropdownProps & {
  disabled?: boolean
  toggleVisible: () => void
  visible: boolean
}

/** UI component for the LocationDropdown */
export const LocationDropdownUI = memo(function LocationDropdownUI(props: LocationDropdownUIProps) {
  const { disabled = false, toggleVisible, locations, visible, onSelect, loading = false } = props
  const disabledOrLoading = disabled || loading

  const currLocation = locations.find((el) => el.isRefined && !el.shouldClear)

  return (
    <>
      <Hoverable>
        {(isHovered) => (
          <Pressable
            disabled={disabled}
            onPress={disabledOrLoading ? undefined : toggleVisible}
            style={[styles.cont, !disabledOrLoading && isHovered && styles.onHover]}
          >
            <LoadingView loading={loading} style={styles.iconCont} noDefaultLoadingContainerStyle>
              <Icon
                name="map-marker-alt"
                size={18}
                color={currLocation ? Colors.shades['500'] : Colors.shades['200']}
              />
            </LoadingView>

            <Text numberOfLines={1} color={disabledOrLoading || !currLocation ? Colors.shades['200'] : undefined}>
              {currLocation?.label ?? placeholder}
            </Text>
          </Pressable>
        )}
      </Hoverable>
      <BottomSheetList
        onBackdropPress={toggleVisible}
        isVisible={visible}
        items={locations}
        renderItem={({ item }) => <FilterItem item={item} onPress={() => onSelect(item)} />}
      />
    </>
  )
})

const styles = StyleSheet.create({
  cont: {
    flexDirection: 'row',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.shades['100'],
    backgroundColor: Colors.white,
    height: 40,
    alignItems: 'center',
    paddingRight: 8,
  },

  iconCont: {
    width: '10%',
    minWidth: 30,
    maxWidth: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  onHover: {
    borderColor: Colors.green,
  },
})
