import { hideModal, Modal } from '@elements'
import { EbtPaymentMethod } from '@models/PaymentMethod'
import { addHours } from 'date-fns'

import { VerifyEbtCard } from './AddPaymentMethod/VerifyEbtCard'

// Validate or request a new pin for ebt payment methods, force update allow
/**
 * Validate or request a new pin for ebt payment methods
 * @param method The payment method to check the pin on
 * @param userId The user id this payment method is for
 * @param forceUpdate if we should skip the check and force the pin to be updated, useful for checkout
 */
export function validateEbtPin(
  method: EbtPaymentMethod,
  userId: string,
  forceUpdate = false,
): Promise<EbtPaymentMethod> {
  return new Promise((resolve, reject) => {
    // If there is no pin or the pins expiration date is not at least an hour in the future
    if (forceUpdate || !method?.pin?.token || method.pin.expires < addHours(Date.now(), 1).getTime()) {
      //Prompt to Re-verify Pin
      Modal(
        <VerifyEbtCard
          onSuccess={(pin) => {
            hideModal()
            resolve({ ...method!, pin })
          }}
          payId={method!.id}
          userId={userId}
        />,
        {
          halfModal: true,
          noPadding: true,
          title: 'Verify your card details',
          onDismiss: () => {
            reject(new Error('User cancelled'))
          },
        },
      )
    } else {
      // If the pin already exists and is valid continue returning the method
      resolve(method)
    }
  })
}
