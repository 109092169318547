import { signInWithCustomToken } from '@api/Sessions'
import { Alert, Spinner } from '@elements'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import firebase from 'firebase/compat/app'
import { useEffect } from 'react'
import { View } from 'react-native'
import { useDispatch } from 'react-redux'

import Colors from '../../constants/Colors'
import { AppStackParamList, FarmGenAdminParamList } from '../../navigation/types'
import signout from '../../redux/actions/signout'
import { setRoleAdmin } from '../../redux/actions/user'

export default function FarmGenAdminUserLogin() {
  const { params } = useRoute<RouteProp<FarmGenAdminParamList, 'FarmGenAdminUserLogin'>>()
  const dispatch = useDispatch()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  useEffect(() => {
    if (params.token) {
      signout()
        .then(() => {
          signInWithCustomToken(params.token).then(() => {
            navigation.navigate('Consumer', { screen: 'User' })
            dispatch(setRoleAdmin())
          })
        })
        .catch((e: firebase.FirebaseError) => {
          Alert('Error', e.message)
        })
    }
  }, [params])

  return (
    <View style={{ flex: 1, justifyContent: 'center' }}>
      <Spinner size="large" color={Colors.green} />
    </View>
  )
}
