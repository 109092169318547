import { ToolTips } from '@components'
import { Button, HeaderText, Icon, Tooltip } from '@elements'
import { StyleSheet, View } from 'react-native'

import Colors from '@/constants/Colors'
import { useDeviceSize } from '@/hooks/useLayout'
import { memo } from 'react'

type Props = {
  goBack(): void
  title: string
  actionTitle: 'Edit' | 'Save' | 'Back'
  onPress(): void
  isLoading?: boolean
  toolTipId?: ToolTips
  toolTipTitle?: string
}

export const EditHeader = memo(function EditHeader({
  goBack,
  onPress: goAction,
  actionTitle,
  title,
  isLoading,
  toolTipId,
  toolTipTitle,
}: Props) {
  const { isSmallDevice } = useDeviceSize()

  return (
    <View style={styles.container}>
      <Icon name="times" color={Colors.shades[500]} onPress={goBack} />
      <HeaderText style={[styles.title, { fontSize: isSmallDevice ? 16 : 20 }]}>
        {title} {toolTipId && <Tooltip title={toolTipTitle ?? title} id={toolTipId} />}
      </HeaderText>
      <Button small title={actionTitle} onPress={goAction} loading={isLoading} />
    </View>
  )
})

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 5,
    paddingHorizontal: 20,
    borderBottomWidth: 1,
    borderBottomColor: Colors.shades['100'],
  },
  title: { flex: 1, marginHorizontal: 20 },
})
