import { LoaderWithMessage, SkeletonContent } from '@components'
import { BodyProps, LoadingView, Text } from '@elements'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { PaginationRenderState } from 'instantsearch.js/es/connectors/pagination/connectPagination'
import { memo, useMemo } from 'react'
import {
  FlatList,
  FlatListProps,
  ListRenderItemInfo,
  ScrollView,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native'

import { globalStyles } from '../../../constants/Styles'
import {
  ChevronColumnContainer,
  Column,
  ExpandableRow,
  ExpandableRowProps,
  styles as expandableRowStyles,
  getNeedsChevron,
} from './ExpandableRow'
import { Pagination } from './Pagination'
import { TableMenu, TableMenuProps } from './TableMenu'

import Colors from '@/constants/Colors'
import { isWeb } from '@/constants/Layout'

export type OfflineTableProps<T> = Omit<FlatListProps<T>, 'renderItem' | 'data'> & {
  data: readonly T[] | undefined
  /** View style for entire table component */
  containerStyle?: StyleProp<ViewStyle>
  /** Minimum row width for the table.
   * When the table width is smaller than this, the rows will become scrollable horizontally.
   * It should be set according to the specific use-case, based on how much space is required for a row to display correctly.
   * Should include the indent for the deepest subRow level, to ensure the row doesn't get cut out. */
  minWidth?: number
  /** Maximum height for the table.
   * When the rows height is larger than maximum height, the rows will become scrollable vertically.
   * It should be set according to the specific use-case, based on better UI/UX on screen and the big chunks of data that can be displayed without implement pagination */
  maxHeight?: number

  /** specs for the tableHeader component. */
  headerColumns?: (HeaderColumn<T> | SortableColumn<T>)[]
  /** Styles for the table header container and its text elements */
  headerProps?: { containerStyle: ViewStyle; textProps: BodyProps }
  /** If loading, will block the UI with a loading indicator instead. This is best to use when the table data is being initialized because you don't want to show results at all at this stage */
  isLoading?: boolean
  /** Loading state that will not block the table UI. This will be passed to the listEmpty component only. This is best to use for loading state that relates to the changes in search parameters or filters, assuming the table data is already initialized */
  isLoadingNonBlock?: boolean

  /** RenderItem is optional, unlike FlatList. If undefined, the table will use the ExpandableRow component by default. */
  renderItem?: (info: ListRenderItemInfo<T> & { length: number }) => React.ReactElement | null

  /** If the renderItem is undefined, these columns will be paseed to a default ExpandableRow component.
   * Use this if the rows' content can be specified in a stateless way. For more complex rows, use the `renderItem` instead. */
  columns?: ExpandableRowProps<T>['columns']
  /** If the renderItem is undefined, will be passed to a default ExpandableRow */
  onRowPress?: ExpandableRowProps<T>['onRowPress']
  /** If the renderItem is undefined, will be passed to a default ExpandableRow */
  onRowPressUrl?: ExpandableRowProps<T>['onRowPressUrl']
  /** If the renderItem is undefined, this generator will be passed to a default ExpandableRow. If this is defined, the table header will have a spacer on the first column, to align the header title with the row text after the subRow chevrons */
  generateSubRows?: ExpandableRowProps<T>['generateSubRows']
  /** If true, the default table header will have a spacer in the first column of the same size as the chevron in the expandable row, with the goal of aligning the header titles with the row text for the first column. This is only needed if the main rows have a subRows generator, because it creates the row chevron in the expandable row component.
   * - If this is passed, the spacer will be added regardless of the generateSubRows prop.
   */
  needsHeaderSpacer?: boolean

  //TableMenu props...
  search?: TableMenuProps['search']
  searchTerm?: TableMenuProps['searchTerm']
  placeholder?: TableMenuProps['placeholder']
  filters?: TableMenuProps['filters']
  tableMenuContainerStyle?: TableMenuProps['containerStyle']

  /** Shows/ hides the table's bottom pagination buttons UI */
  isPaginationEnabled?: boolean
  /** Pagination props from algolia `usePagination`*/
  paginationProps?: PaginationRenderState
}

/**
 * This is intended to serve as a non-connected, non-algolia, reusable version of the original Admin Table component.
 */
export const OfflineTable = memo(function OfflineTable<T>({
  containerStyle,
  headerProps,
  needsHeaderSpacer,
  minWidth = 600,
  maxHeight,
  data,
  ListHeaderComponent: ListHeaderComponentProp,
  headerColumns,
  columns,
  onRowPress,
  onRowPressUrl,
  keyExtractor: keyExtractorProp,
  renderItem: renderItemProp,
  isLoading = false,
  isLoadingNonBlock = false,
  generateSubRows,
  search,
  searchTerm,
  placeholder,
  filters,
  tableMenuContainerStyle = {
    backgroundColor: Colors.lightGreen,
  },
  paginationProps,
  ListEmptyComponent: listEmptyComponentProp,
  isPaginationEnabled = true,
  ...flatListProps
}: OfflineTableProps<T>) {
  const listEmptyComponent = useMemo(
    () => listEmptyComponentProp ?? getListEmptyComponent(isLoadingNonBlock),
    [isLoadingNonBlock, listEmptyComponentProp],
  )

  const length = data?.length ?? 0

  const renderItem = useMemo<FlatListProps<T>['renderItem']>(() => {
    /**This adapter takes the info from the flatlist renderItem, and adds the length before passing it to the custom renderItem */
    return function renderItemAdapter(info) {
      if (renderItemProp) return renderItemProp({ ...info, length })

      const renderItemDefault = getRenderItemDefault(columns, onRowPress, onRowPressUrl, generateSubRows)
      return renderItemDefault({ ...info, length })
    }
  }, [renderItemProp, columns, onRowPress, onRowPressUrl, generateSubRows, length])

  const ListHeaderComponent = useMemo(
    () =>
      ListHeaderComponentProp ??
      (() => (
        <DefaultTableHeader
          headerColumns={headerColumns}
          headerProps={headerProps}
          needsHeaderSpacer={needsHeaderSpacer}
          generateSubRows={generateSubRows}
        />
      )),
    [ListHeaderComponentProp, headerColumns, headerProps, needsHeaderSpacer, generateSubRows],
  )

  const keyExtractor: FlatListProps<T>['keyExtractor'] = useMemo(
    () => keyExtractorProp ?? ((_, i) => 'table_item_' + i),
    [keyExtractorProp],
  )

  return (
    <View style={[styles.container, containerStyle]}>
      <TableMenu
        search={search}
        searchTerm={searchTerm}
        placeholder={placeholder}
        filters={filters}
        containerStyle={tableMenuContainerStyle}
      />

      <ScrollView horizontal scrollEnabled contentContainerStyle={[globalStyles.flex1, { minWidth, maxHeight }]}>
        <LoadingView loading={isLoading} success={data} style={styles.loadingView}>
          {(data) => (
            <FlatList
              /**
               * 1. scrollEnabled controls whether the flatlist content area is scrollable as an inner scroll area independent of some outer scrolling component. When scrollEnabled is set to false, the FlatList will not respond to user scroll gestures, effectively making it static. This is what we normally refer to as nested scrolling, which often gets confused with the "nestedScrollEnabled" prop
               *
               * 2.
               * The decision to set scrollEnabled to 'isWeb' as the default behavior for the table component is motivated by several factors:
               * - Mobile Web Considerations: On mobile web, setting scrollEnabled to true is necessary to enable scrolling for the table. However, for small screens typical of native mobile apps, the preference is often to display all table contents without the need for scrolling on the table itself. The scrollable area is the entire screen.
               * - Consistency Across Platforms: By making 'isWeb' the default, the behavior of the table component remains consistent across all wed/non-web platforms and screen sizes.
               * - Current Usage: The decision is also influenced by the current usage of the Offline Table in the GrownBy, where most OfflineTable needs to set scrolledEnabled to be isWeb, so it match the current standard and requirement. In the future, if there is a need to change the default value, it should be reviewed and discussed with the team.
               *
               */
              scrollEnabled={isWeb}
              /** Setting nestedScrollEnabled to true on the inner FlatList helps ensure smoother, more predictable scrolling when the user interacts with the nested list. It's particularly relevant for Android, as iOS generally handles nested scrolling more gracefully */
              nestedScrollEnabled
              keyExtractor={keyExtractor}
              data={data}
              ListHeaderComponent={ListHeaderComponent}
              renderItem={renderItem}
              ListEmptyComponent={listEmptyComponent}
              {...flatListProps}
            />
          )}
        </LoadingView>
      </ScrollView>
      {paginationProps && isPaginationEnabled && <Pagination {...paginationProps} />}
    </View>
  )
}, propsAreDeepEqual) as <T>(props: OfflineTableProps<T>) => JSX.Element

export type HeaderColumn<T> = Pick<Column<T>, 'widthFlex'> & {
  /** Column title for table header. This string will be put inside a Text element which receives the widthFlex, and headerProps.textProps. Text style will be merged. */
  title?: string
  /** If 'process' is passed, the 'title' prop will be ignored, and the returned element will be rendered instead */
  process?: () => JSX.Element | null
}

export type SortableColumn<T> = HeaderColumn<T> & {
  sortDir?: 'desc' | 'asc'
  sortFunc?: (a: T, b: T) => number
}

type DefaultTableHeaderProps<T> = Pick<
  OfflineTableProps<T>,
  'headerColumns' | 'headerProps' | 'generateSubRows' | 'needsHeaderSpacer'
>

/** This component will be used by default for the table header */
export function DefaultTableHeader<T>({
  headerColumns,
  headerProps: { containerStyle, textProps: { style: textStyle, type = 'medium', ...textPropsRest } } = {
    containerStyle: {},
    textProps: {},
  },
  generateSubRows,
  needsHeaderSpacer = false,
}: DefaultTableHeaderProps<T>) {
  return (
    <View style={[styles.header, styles.tableRow, containerStyle]}>
      {headerColumns?.map(({ widthFlex, title = '', process }, index) => {
        const needsChevron = getNeedsChevron({ colIdx: index, hasSubrows: !!generateSubRows || needsHeaderSpacer })

        const key = `tableHeader_${index}_${title}`

        const colContent = process?.() ?? (
          <Text
            key={key}
            style={[needsChevron ? globalStyles.flex1 : expandableRowStyles.colContainer(widthFlex), textStyle]}
            type={type}
            {...textPropsRest}
          >
            {title}
          </Text>
        )

        if (!needsChevron) return colContent

        return <ChevronColumnContainer colContent={colContent} widthFlex={widthFlex} key={key} />
      })}
    </View>
  )
}

const getRenderItemDefault = function <T>(
  columns: OfflineTableProps<T>['columns'],
  onRowPress: OfflineTableProps<T>['onRowPress'],
  onRowPressUrl: OfflineTableProps<T>['onRowPressUrl'],
  generateSubRows?: ExpandableRowProps<T>['generateSubRows'],
): NonNullable<OfflineTableProps<T>['renderItem']> {
  return function DefaultRenderItem(ListItemInfo) {
    return (
      <ExpandableRow
        columns={columns}
        onRowPress={onRowPress}
        onRowPressUrl={onRowPressUrl}
        generateSubRows={generateSubRows}
        {...ListItemInfo}
      />
    )
  }
}

const getListEmptyComponent = (isLoading: boolean) =>
  function DefaultListEmptyComponent() {
    return (
      <SkeletonContent
        containerStyle={globalStyles.flex1}
        isLoading={isLoading}
        layout={new Array(10).fill({
          width: '95%',
          height: 20,
          marginHorizontal: '2.5%',
          marginVertical: 15,
        })}
      >
        <LoaderWithMessage icon="box-open" title="No results" loading={isLoading}>
          <Text>Did not find any matching results.</Text>
        </LoaderWithMessage>
      </SkeletonContent>
    )
  }

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderWidth: 1,
    borderRadius: 10,
    borderColor: Colors.shades['100'],
  },
  paginationContainer: {
    backgroundColor: Colors.secondaryGreen3,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    padding: 20,
  },
  header: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: Colors.lightGreen,
  },
  tableRow: {
    flexDirection: 'row',
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderBottomWidth: 1,
    borderBottomColor: Colors.shades['100'],
  },
  rowItem: {
    flex: 1,
  },
  link: {
    color: Colors.blue,
  },
  loadingView: { flex: 1, minHeight: 150 },
})
