import { isWeb } from '@/constants/Layout'
import { FlashList, FlashListProps } from '@shopify/flash-list'
import { memo, useState } from 'react'

type Props<T> = FlashListProps<T> & {
  /** Base width of the item. Used for columns number calculation */
  itemBaseWidth: number
  /** Initial number of columns, before calculation based on the item size and available space */
  initialNumColums?: number
}

/** FlashList wrapper that creates a grid layout, based on its available space and an itemBase width
 * - The columns are calculated automatically, based on its container size.
 * - In order to work as expected, `renderItem`'s style should contain `flex` and `aspectRatio` properties
 * - `scrollEnabled` is enabled only on Web by default, asumming that most of the screens that use this already have a `Scroll` component
 */
function ResponsiveGridComp<T>({ itemBaseWidth, initialNumColums = 3, ...props }: Props<T>) {
  const [numColumns, setNumColummns] = useState(initialNumColums)

  return (
    <FlashList
      onLayout={(e) => {
        const width = e.nativeEvent.layout.width

        // Width can be 0 when the component is unmounted, so we don't update the state
        if (width === 0) return

        const columns = Math.floor(width / itemBaseWidth)

        // Ensure that at least one column is set, in order to prevent crash.
        const numCols = Math.max(columns, 1)

        setNumColummns(numCols)
      }}
      numColumns={numColumns}
      scrollEnabled={isWeb}
      key={numColumns}
      {...props}
    />
  )
}

export const ResponsiveGrid = memo(ResponsiveGridComp) as typeof ResponsiveGridComp
