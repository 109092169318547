import { ActionBtn } from '@/admin/components/elements/ActionsMenuComponent'
import { canArchiveProductFee, updateProductFee } from '@api/ProductFees'
import { Alert, Loader, Modal } from '@elements'
import { errorToString } from '@helpers/helpers'
import { ProductFee } from '@models/ProductFee'
import { ArchiveProductFeeResponse } from '@shared/types/v2/productFee'
import { AddEditProductFee } from './AddEditProductFee'

/** the function to open AddEditProductFee Modal */
export const addEditProductFees = (productFee?: ProductFee) => {
  Modal(<AddEditProductFee productFee={productFee} />, { title: `${productFee ? 'Edit' : 'Add'} tax or flat fee` })
}

/** the helper to get tax and fee table three dot actions */
export const getActions = (productFee: ProductFee): ActionBtn<ProductFee>[] => [
  {
    title: 'Edit',
    onPress: (productFee: ProductFee) => addEditProductFees(productFee),
  },
  {
    title: productFee.archived ? 'Unarchive' : 'Archive',
    onPress: async (productFee: ProductFee) => {
      let canUpdate: ArchiveProductFeeResponse = { success: true, message: '' }
      Loader(true)
      try {
        // If the productFee is not archived now and it is going to be archived, we should check if it is used by any products. Archive only when the productFee is not used by any products.
        if (!productFee.archived) {
          canUpdate = await canArchiveProductFee({ farmId: productFee.farm.id, productFeeId: productFee.id })
        }
        // Should check success flag before archiving and un-archiving the productFee
        if (canUpdate.success) {
          await updateProductFee({ id: productFee.id, farm: productFee.farm, archived: !productFee.archived })
        } else {
          Alert('Warning', canUpdate.message)
        }
      } catch (error) {
        Alert('Error', errorToString(error))
      } finally {
        Loader(false)
      }
    },
  },
]
