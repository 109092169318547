import { useHandleSubmitProd } from '@/admin/screens/Products/components/ProductHeader'
import { FormikBasicInformation } from '@/admin/screens/Products/ProductForm/BasicInformation'
import {
  buildInitialValues,
  getSchemaContext,
  ProductDetailsForm,
} from '@/admin/screens/Products/ProductForm/helpers/formHelpers'
import { ProductFeeSection } from '@/admin/screens/Products/ProductForm/ProductFeeSection'
import { Logger } from '@/config/logger'
import Colors from '@/constants/Colors'
import { ModalComponentProps } from '@/constants/types/modalTypes'
import { createInitialApiFxReturn, useApiFx, UseApiFxReturn } from '@/hooks/useApiFx'
import { useCancelableFx } from '@/hooks/useCancelablePromise'
import useKeyedState, { createInitialKeyedState, KeyedState } from '@/hooks/useKeyedState'
import { productsCollection } from '@api/framework/ClientCollections'
import { Button, ErrorText, hideModal, LoadingView, Modal, Text } from '@elements'
import { buildProduct } from '@helpers/builders/buildProduct'
import { bullet } from '@helpers/display'

import { errorToString, isNonNullish, nonEmptyString, removeObjDuplicates } from '@helpers/helpers'
import { BaseProduct, Product, ProductType } from '@models/Product'
import { Formik, useFormikContext, yupToFormErrors } from 'formik'
import { createContext, useContext, useMemo } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { BulkEditModalGenericProps } from './product'

export type BulkEditProductFeesProps = BulkEditModalGenericProps<Product, Pick<BaseProduct, 'taxesAndFees'>>

const fetchByIds = productsCollection.fetchByIds.bind(productsCollection)

type State = {
  isSubmitting: boolean
  submitError: unknown
  validationErrors: { error: unknown; id: string }[]
}

type ContextType = {
  keyedState: KeyedState<State>
  dataFx: UseApiFxReturn<typeof fetchByIds>
}

const initialContextState: ContextType = {
  keyedState: createInitialKeyedState<State>({
    isSubmitting: false,
    submitError: undefined as unknown,
    validationErrors: [] as { error: unknown; id: string }[],
  }),
  dataFx: createInitialApiFxReturn<Product[]>(undefined),
}

const BulkEditProductFeesContext = createContext<ContextType>(initialContextState)

/** BulkEditProductFees Modal component */
function BulkEditProductFees(props: BulkEditProductFeesProps) {
  const { ids, fetchByIds, validate, onPassValidation } = props

  const keyedState = useKeyedState<State>({
    isSubmitting: false,
    submitError: undefined as unknown,
    validationErrors: [] as { error: unknown; id: string }[],
  })
  const [, set, setState] = keyedState

  const dataFx = useApiFx(fetchByIds, [ids])

  const onSubmit = async () => {
    if (!dataFx.data || dataFx.loading) return
    setState((p) => ({ ...p, isSubmitting: true, validationErrors: [], submitError: undefined }))
    const errors = dataFx.data
      .filter((obj) => {
        // only need to validate those for which the field exists, because only those will get updated
        return props.getField(obj) !== 'Field_not_exist'
      })
      .map((obj) => {
        try {
          validate(obj)
        } catch (error) {
          return { error, id: obj.id }
        }
      })
      .filter(isNonNullish)

    if (errors.length) {
      setState((p) => ({ ...p, isSubmitting: false, validationErrors: errors }))
      return
    }

    try {
      await onPassValidation(dataFx.data)
      hideModal()
    } catch (error) {
      Logger.error(error)
      set('submitError', error)
    } finally {
      set('isSubmitting', false)
    }
  }

  const initialValues = useMemo<ProductDetailsForm | undefined>(() => {
    if (dataFx.loading) return undefined

    const prods = dataFx.data
    const fees = removeObjDuplicates(prods?.flatMap((prod) => prod.taxesAndFees?.fees ?? []) ?? [])
    /** TODO: should remove ?? after migration for isTaxExempt */
    const isTaxExempt = !!prods?.every((prod) => prod.taxesAndFees?.isTaxExempt ?? true)

    return buildInitialValues(
      /** This is a dummy new product base, and won't become a full product. Using ProductType.Standard here mean to represent all product type */ {
        product: {
          type: ProductType.Standard,
          taxesAndFees: {
            fees,
            isTaxExempt,
          },
        },
        name: 'AddProduct',
        params: { type: ProductType.Standard },
      },
    )
  }, [dataFx])

  return (
    <BulkEditProductFeesContext.Provider value={{ keyedState, dataFx }}>
      <LoadingView loading={dataFx.loading || !initialValues} error={dataFx.err} switchMode>
        <Formik
          initialValues={initialValues!}
          enableReinitialize={false}
          onSubmit={onSubmit}
          validate={async (values) => {
            try {
              const context = getSchemaContext(values)

              // In BulkEditProductFees, we only need to validate the fees and isTaxExempt fields for productDetailsForm
              await FormikBasicInformation.validator.validateAt('fees', values, {
                abortEarly: false,
                context,
              })
              await FormikBasicInformation.validator.validateAt('isTaxExempt', values, {
                abortEarly: false,
                context,
              })

              return {}
            } catch (e) {
              return yupToFormErrors(e)
            }
          }}
        >
          {/* The content is rendered as a component inside Formik because it needs to access formik context */}
          <FormContent {...props} />
        </Formik>
      </LoadingView>
    </BulkEditProductFeesContext.Provider>
  )
}

/** From Component inside BulkEditProductFees */
function FormContent({
  getField,
  updateField,
  fieldNameDisplay,
  getItemDisplayName = (doc) => {
    if ('name' in doc && nonEmptyString(doc.name)) return doc.name
    return doc.id
  },
  ids,
}: BulkEditProductFeesProps) {
  const {
    keyedState: [{ isSubmitting, validationErrors, submitError }, , setState],
    dataFx: { data, loading: isFetchingData, setState: setDataState },
  } = useContext(BulkEditProductFeesContext)
  const formik = useFormikContext<ProductDetailsForm>()
  const submit = useHandleSubmitProd(formik)

  const isLoading = isSubmitting || isFetchingData

  /** This tells us whether the field exists within all the items selected */
  const { allSelectedSupportField, itemsWithNoField, noneSelectedSupportField } = useMemo(() => {
    const itemsWithNoField = data?.filter((doc) => getField(doc) === 'Field_not_exist') || []

    return {
      allSelectedSupportField: itemsWithNoField.length === 0,
      itemsWithNoField,
      noneSelectedSupportField: itemsWithNoField.length === data?.length,
    }
  }, [data, getField])

  /** When form productFees and isTaxExempt change, applies the changes to the data */
  useCancelableFx(async () => {
    if (!data || isLoading) return

    setState((p) => ({ ...p, validationErrors: [], submitError: undefined }))

    /** builds the product fields from the form state. we need to cast the product type because the form component sees the product in its most abstract possible way */
    const { taxesAndFees } = (await FormikBasicInformation.fromFormik(formik.values)) as Product

    // apply the edit to each item and update state
    const newData = data.map((doc) => {
      return updateField({ taxesAndFees }, doc)
    })
    setDataState((s) => ({ ...s, data: newData }))
  }, [data, isLoading, setDataState, setState, updateField, formik.values])

  const errorText = useMemo(() => {
    let txt = ''
    if (!data) return ''
    if (validationErrors.length) {
      txt += 'Some items are incompatible with these edits: \n\n'
      validationErrors.forEach(({ id, error }) => {
        txt += ` ${bullet} ` + getItemDisplayName(data.find((p) => p.id === id)!) + ': ' + errorToString(error) + '\n'
      })
    } else if (submitError) {
      txt += 'There was a problem submitting this request. Try again \n\n'
    }
    return txt
  }, [validationErrors, submitError, getItemDisplayName, data])

  const warning = useMemo(() => {
    let txt = ''
    if (noneSelectedSupportField) {
      txt += `None of the items in your selection support the field "${fieldNameDisplay}". Update your selection or choose a different field.`
    } else if (!allSelectedSupportField) {
      txt += `The field "${fieldNameDisplay}" is not supported by the following items: \n\n`
      itemsWithNoField.forEach((doc) => {
        txt += ` ${bullet} ${getItemDisplayName(doc)}\n`
      })
      txt += '\n\nYour edits will be applied to the rest of your selection.'
    }
    return txt
  }, [allSelectedSupportField, itemsWithNoField, fieldNameDisplay, getItemDisplayName, noneSelectedSupportField])

  return (
    <ScrollView style={styles.main}>
      <View style={styles.content}>
        <Text>{`The following changes will be applied to the products selected (${ids.length}).`}</Text>
        {!!warning && (
          <Text style={styles.warningText} color={Colors.brown}>
            {warning}
          </Text>
        )}
        {!isLoading && <ProductFeeSection />}
      </View>
      {errorText ? <ErrorText>{errorText}</ErrorText> : null}
      <View style={styles.buttonCont}>
        <Button
          style={styles.button}
          title="Update multiple"
          onPress={submit}
          loading={isLoading}
          disabled={noneSelectedSupportField}
        />
      </View>
    </ScrollView>
  )
}

export type OpenBulkEditProductFeesOpts = {
  contentProps: Pick<BulkEditProductFeesProps, 'ids'> & { onSuccess?: (newData: Product[]) => any }
} & ModalComponentProps

/** opens the bulk edit modal for Taxes & Fees section */
export function openBulkEditProductFees({
  contentProps: { ids, onSuccess },
  ...modalComponentProps
}: OpenBulkEditProductFeesOpts) {
  /**
   * - All product types should have the taxesAndFees field
   */
  const getField = (p: Product) => ({
    taxesAndFees: p.taxesAndFees,
  })

  Modal(
    <BulkEditProductFees
      ids={ids}
      fetchByIds={fetchByIds}
      getField={getField}
      onPassValidation={async (newData) => {
        await Promise.all(
          newData.map((updatedProd) => {
            const newDataValues = getField(updatedProd)
            return productsCollection.update({ id: updatedProd.id, ...newDataValues })
          }),
        )
        onSuccess?.(newData)
      }}
      updateField={(newData, prod) => ({ ...prod, ...newData })}
      validate={buildProduct}
      fieldNameDisplay="Taxes & Fees"
    />,
    {
      webWidth: 1000,
      title: 'Edit Taxes & Fees',
      ...modalComponentProps,
    },
  )
}

const styles = StyleSheet.create({
  main: {
    padding: 10,
    alignContent: 'center',
  },
  content: {
    padding: 15,
  },
  warningText: {
    backgroundColor: Colors.shadeGold,
    borderRadius: 20,
    padding: 10,
    margin: 10,
  },
  buttonCont: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  button: {
    width: 200,
  },
})
