import { memo, useCallback, useMemo } from 'react'
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete'

import { isCoord } from '../models/Coordinate'
import {
  debouncedTimeGooglePlaces,
  Establishment,
  GooglePlacesSearchContext,
  GooglePlacesSearchProps,
} from './googlePlacesSearch.helper'
import { GooglePlacesSearchUi } from './GooglePlacesSearchUi'

import { AutoCompleteItem } from '@/hooks/useAutoComplete'

export * from './googlePlacesSearch.helper'

/** Web implementation for the google places search component */
export const GooglePlacesSearch = memo(function GooglePlacesSearch({
  onSelect,
  onSelectEstablishment,
  types = '(cities)',
  inline,
  hasClearBtn,
  onClearText,
  contStyle,
  initialValue,
  enableReinitialize,
  autoSelectFirstResultOnce,
  inputRef,
  getOnSubmit,
  //Any props not part of the textInput props (Any custom props) must be destructured here and passed to context
  ...textInputProps
}: GooglePlacesSearchProps<google.maps.GeocoderResult>) {
  // In web we use the usePlacesAutocomplete 3rd party library to get the results
  const {
    ready,
    value: inputValue,
    suggestions: { data: searchResults, loading: isSearching },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: [types],
      componentRestrictions: { country: 'us' },
    },
    debounce: debouncedTimeGooglePlaces,
  })

  const getResultDetails = useCallback(async (item: AutoCompleteItem<google.maps.places.AutocompletePrediction>) => {
    //If this is the "Current location" item, it will be a coordinate
    const geoCodeResults = await getGeocode(
      isCoord(item.data)
        ? { location: { lat: item.data.latitude, lng: item.data.longitude } }
        : { address: item.data.description },
    )
    const detailItem: AutoCompleteItem<google.maps.GeocoderResult> = {
      text: item.text,
      data: geoCodeResults[0],
    }
    return detailItem
  }, [])

  const contextData = useMemo(
    () => ({
      textInputProps,
      customProps: {
        onSelect,
        onSelectEstablishment,
        inline,
        initialValue,
        enableReinitialize,
        autoSelectFirstResultOnce,
        types,
        hasClearBtn,
        onClearText,
        contStyle,
        inputRef,
        getOnSubmit,
      },
    }),
    [
      contStyle,
      hasClearBtn,
      onClearText,
      inline,
      initialValue,
      onSelect,
      onSelectEstablishment,
      types,
      textInputProps,
      enableReinitialize,
      autoSelectFirstResultOnce,
      inputRef,
      getOnSubmit,
    ],
  )

  return (
    <GooglePlacesSearchContext.Provider value={contextData}>
      {!!ready && (
        <GooglePlacesSearchUi
          getResultDetails={getResultDetails}
          inputValue={inputValue}
          setValue={setValue}
          isSearching={isSearching}
          searchResults={searchResults}
        />
      )}
    </GooglePlacesSearchContext.Provider>
  )
})

/** Components that use this component can make an establishment from the item passed to onSelect prop */
export const makeEstablishment = (item: AutoCompleteItem<google.maps.GeocoderResult>): Establishment => {
  const data: Establishment = {
    coordinate: {
      latitude: item.data.geometry.location.lat(),
      longitude: item.data.geometry.location.lng(),
    },
    address_components: item.data.address_components,
    name: item.text,
  }
  return data
}
