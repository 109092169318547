import { AlgoliaAdminCustomer } from '@models/Algolia'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback, useRef } from 'react'
import { TextInput } from 'react-native'
import { useSelector } from 'react-redux'

import { AdminOrdersParamList } from '@/admin/navigation/types'
import { useCartService } from '@/hooks/useCart'
import { useDiffFx } from '@/hooks/useDiffFx'
import { adminFarmIdSelector, isWholesaleOrderCreatorSelector } from '@/redux/selectors'
import { useCustomerData_reusable } from './useCustomerData-reusable'

/** This hook is used to get the customer data for the current order. It should include any state related to customer handling */
export function useCustomerData({ custId }: { custId?: string }) {
  const adminFarmId = useSelector(adminFarmIdSelector) || ''
  const isWholesaleOrderCreator = useSelector(isWholesaleOrderCreatorSelector)
  const { cart } = useCartService({
    cartServiceType: 'orderCreator',
    farmId: adminFarmId,
    isWholesale: isWholesaleOrderCreator,
  })

  const navigation = useNavigation<StackNavigationProp<AdminOrdersParamList, 'OrderCreator'>>()

  const { custPickupsFx, customerFx } = useCustomerData_reusable({
    custId,
    cart,
    farmId: adminFarmId,
    cartServiceType: 'orderCreator',
  })

  /** This input ref will control the customer selection text input */
  const customerInputRef = useRef<TextInput>(null)

  /** Called when a customer is selected in the autocomplete input. This should only set the nav param. Then a different fx should react to the navParam change, and handle fetching the customer for the current custId param **/
  const onUserSelected = useCallback(
    (user: AlgoliaAdminCustomer) => {
      navigation.setParams({ custId: user.id })
    },
    [navigation],
  )

  /** When the admin farm id changes: Any selected customer should be removed from the cart because it may not be a customer of the newly selected farm. */
  useDiffFx(
    ({ adminFarmId }) => {
      if (!adminFarmId) return

      // This scenario would mean the adminFarmId changed.
      if (adminFarmId.current && adminFarmId.previous) {
        // Should only reset if both the new and old values were defined. This would prevent clearing the data on mount
        navigation.setParams({ custId: undefined })
      }
    },
    // This should run on change to adminFarmId
    // eslint-disable-next-line react-hooks/exhaustive-deps
    { adminFarmId },
  )

  return {
    customerInputRef,
    onUserSelected,
    customerFx,
    custPickupsFx,
  }
}
