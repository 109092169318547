import { orderItemTotal } from '@helpers/order'
import { Order } from '@models/Order'
import { isShare, Product } from '@models/Product'

import { newSaleStat, SaleStats } from '@/constants/types'

/** Converts orders into a map of sale stats by product ids */
export function convertOrdersToSaleStats(orders: Order[]): Map<Product['id'], SaleStats> {
  const salesMap = new Map<Product['id'], SaleStats>()

  //iterate through each order
  for (const order of orders) {
    //iterate through each item on that order
    for (const orderItem of order.items) {
      const { quantity } = orderItem

      const curStat = salesMap.get(orderItem.product.id) || newSaleStat()

      let oldSalesByUnit = 0
      let oldSalesByOption = 0

      let buyingOptionName = ''

      if (!isShare(orderItem.product)) {
        buyingOptionName = orderItem.purchasedUnit?.name || ''
        oldSalesByUnit = curStat.salesByUnit?.get(buyingOptionName) || 0
        oldSalesByOption = curStat.salesByOption?.get(buyingOptionName) || 0
      }

      if (!orderItem.cancelled) {
        const unitAmount = (orderItem.purchasedUnit?.multiplier || 0) * quantity
        curStat.sales += quantity
        curStat.revenue += orderItemTotal(orderItem).value
        curStat.uniqueCustomers.add(order.user.id)
        if (!isShare(orderItem.product)) {
          curStat.unitSum += unitAmount
          curStat.salesByUnit!.set(buyingOptionName, unitAmount + oldSalesByUnit)
          curStat.salesByOption!.set(buyingOptionName, quantity + oldSalesByOption)
        }
      } else {
        curStat.cancelled += quantity
      }
      salesMap.set(orderItem.product.id, curStat)
    }
  }

  return salesMap
}
