import { MessageWithIcon } from '@components'
import { Text } from '@elements'

export const EmptyRedemptions = (
  <MessageWithIcon icon="user-tag" title="No Redemptions">
    <Text>No redemptions yet, share this coupon with your customers.</Text>
  </MessageWithIcon>
)
export const EmptyPromoCodes = (
  <MessageWithIcon icon="tags" title="No promo codes">
    <Text>No promo codes yet, add a new one to share this coupon with customers.</Text>
  </MessageWithIcon>
)
export const EmptyCoupons = (
  <MessageWithIcon icon="tags" title="No coupons">
    <Text>You do not have any coupons, add a new one to share with customers.</Text>
  </MessageWithIcon>
)
