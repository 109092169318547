import { loadFarmBalances } from '@api/Payments'
import { ConsumerView, MonthlyBalance } from '@components'
import { Divider, Modal, Text } from '@elements'
import { formatMoney } from '@helpers/display'
import { FarmBalance } from '@models/Payment'
import { User } from '@models/User'
import { useEffect, useState } from 'react'
import { FlatList } from 'react-native'
import { useSelector } from 'react-redux'

import { withAuth } from '@/hooks/withAuth'
import LoaderWithMessage from '../../components/LoaderWithMessage'
import { SectionButtonView } from '../../components/SectionView'
import { RootState } from '../../redux/reducers/types'
import { userSelector } from '../../redux/selectors'

function BalanceScreenComp() {
  const user = useSelector<RootState, User>(userSelector)
  const [loading, setLoading] = useState(false)
  const [farms, setFarms] = useState<FarmBalance[]>([])

  const openBalance = (item: FarmBalance) => {
    Modal(<MonthlyBalance startBal={item} />, {
      halfModal: true,
      title: `${item.farmName} Credit Balance`,
    })
  }

  useEffect(() => {
    if (!user.id) {
      return
    }
    setLoading(true)
    loadFarmBalances(user.id)
      .then((farms) => {
        setFarms(farms)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [user.id])

  return (
    <ConsumerView>
      {farms.length > 0 ? (
        <FlatList
          data={farms}
          keyExtractor={(item, idx) => item.farmId || idx.toString()}
          ItemSeparatorComponent={() => <Divider />}
          renderItem={({ item }) => (
            <SectionButtonView
              title={item.farmName}
              subtitle={formatMoney(item.amount)}
              onPress={() => {
                openBalance(item)
              }}
            />
          )}
        />
      ) : (
        <LoaderWithMessage loading={loading} icon="wallet" title="No Farm Credits!">
          <Text>You have no farm credits. Make a purchase and add a credit with that farm to see them here.</Text>
        </LoaderWithMessage>
      )}
    </ConsumerView>
  )
}

export const BalanceScreen = withAuth(BalanceScreenComp, { noSafeAreaInsets: true })
