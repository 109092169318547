import { Logger } from '@/config/logger'
import { logOrderFailed, logOrderPlaced } from '@api/FBAnalytics'
import { addOrder } from '@api/Orders'
import { Alert, Loader } from '@elements'
import { errorToString, nonEmptyString } from '@helpers/helpers'
import { MoneyCalc } from '@helpers/money'
import { CoverFee } from '@helpers/serviceFee'
import { SplitTenderPayment } from '@models/Order'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setNavProps } from '../../../redux/actions/appState'
import { wholesaleSelector } from '../../../redux/selectors'

import { Total } from '@/constants/types'
import { ShoppingStackParamList } from '@/navigation/types'
import { omit } from '@helpers/typescript'
import { CheckoutFormType } from './helpers'

/** Returns a callback for interacting with the api to place the order. This step should have no validations whatsoever. It is really just handling the transaction of order placing */
export function useHandleAddOrder(
  isDraftOrder: boolean,
  coverFee: CoverFee,
  total: Total,
  disableConfirmations: () => void,
) {
  const {
    params: { cartFarmId },
  } = useRoute<RouteProp<ShoppingStackParamList, 'Checkout'>>()
  const dispatch = useDispatch()
  const navigation = useNavigation<StackNavigationProp<ShoppingStackParamList, 'Checkout'>>()
  const { isWholesale } = useSelector(wholesaleSelector)

  return useCallback(
    async (tender: SplitTenderPayment, cartId: string, values: CheckoutFormType) => {
      try {
        Loader(true)
        // If the user chose to cover fees then pass the fee amount and if it should be applied to installments
        const fees = coverFee.id === 'no' ? undefined : omit(coverFee, 'value')

        const { id } = await addOrder({
          cartId,
          farmId: cartFarmId,
          tender,
          fees,
          note: nonEmptyString(values.note) ? values.note : undefined,
          purchaseOrder: nonEmptyString(values.purchaseOrder) ? values.purchaseOrder : undefined,
          isDraft: isDraftOrder,
          isWholesale: !!isWholesale,
        })
        if (!id) throw new Error('Order ID was not returned from the created order')
        logOrderPlaced(cartFarmId, MoneyCalc.cents(total.total))
        disableConfirmations() //Requirement: Must mark checkout as completed BEFORE navigating away, to prevent unwanted confirmation alerts.
        dispatch(setNavProps()) // This will clear the farm cache, to load the updated product data next time useFarmData gets called
        navigation.popToTop() //Requirement: Must reset this navigator (or Pop the current screen) before leaving, so next time they enter this navigator they won't land on checkout screen

        if (isDraftOrder) {
          navigation.navigate('OrdersNavigator', {
            screen: 'DraftOrderDetails',
            params: { draftOrderId: id, confirmation: true },
          })
        } else {
          navigation.navigate('OrdersNavigator', {
            screen: 'OrderSummary',
            params: { orderId: id, confirmation: true },
          })
        }

        Loader(false)
      } catch (err) {
        Logger.error(err)
        Loader(false)

        const msg = errorToString(err)
        logOrderFailed(msg)

        let displayMsg = msg || 'There was an error placing this order please try again in a little while. '
        if (msg.includes('declined'))
          displayMsg = 'Your credit card was declined. Please fix this card or try a different card.'
        if (msg.includes('security code'))
          displayMsg = "Your credit card's security code is incorrect, please fix it or try a different card."
        if (msg.includes('requires additional user action'))
          displayMsg =
            'The card you are using requires special authentication that is not supported at the time. Please try a different card.'
        Alert('Error Placing Order', displayMsg)
      }
    },
    [coverFee, cartFarmId, isWholesale, total.total, disableConfirmations, dispatch, navigation, isDraftOrder],
  )
}
