import { StyleSheet, View } from 'react-native'

import { memo } from 'react'
import Colors from '../../constants/Colors'

type Props = {
  style?: any
  clear?: boolean
  left?: number
  right?: number
}

export const DividerVertical = memo(function DividerVertical({ style, left = 5, right = 5, clear }: Props) {
  return (
    <View
      style={[
        styles.divider,
        {
          paddingLeft: left,
          paddingRight: right,
          borderLeftColor: clear ? Colors.transparent : Colors.shades['100'],
        },
        style,
      ]}
    />
  )
})

const styles = StyleSheet.create({
  divider: {
    height: '100%',
    borderLeftWidth: 2,
  },
})
