import * as React from 'react'

import { Hoverable } from '../components/elements/Hoverable/Hoverable'
import Colors, { withAlpha } from '../constants/Colors'

interface DefaultProps {
  noHover?: boolean
  color?: string
  children?: any
}
export const withColorHover = <T extends DefaultProps>(Component: React.ComponentType<T>) => {
  return function ColorHover(props: T): JSX.Element {
    return (
      <Hoverable>
        {function hoverHandler(isHovered: boolean) {
          const color = (() => {
            if (props.noHover) return props.color
            return isHovered ? withAlpha(props.color || Colors.green, 0.6) : props.color
          })()
          const colorProps = { ...props, color }

          return <Component {...colorProps} />
        }}
      </Hoverable>
    )
  }
}
