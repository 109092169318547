import { ErrorCode, ErrorWithCode } from '@shared/Errors'

/** Returns a promise with an abortable controller */
export function makeAbortable<T>(promise: Promise<T>, abortMsg = 'The promise was aborted') {
  const controller = new AbortController()

  const abortable = Promise.race([
    promise,
    new Promise((_, reject) => {
      controller.signal.addEventListener('abort', function onAbort() {
        reject(new ErrorWithCode({ code: ErrorCode.AbortedError, devMsg: abortMsg }))
        controller.signal.removeEventListener('abort', onAbort)
      })
    }),
  ])

  return { abortable, controller }
}
