import { reschedulePickup } from '@api/Pickups'
import { loadProduct } from '@api/Products'
import { Alert, Loader, Modal } from '@elements'
import { getOrderNum } from '@helpers/display'
import { getPickups } from '@helpers/order'
import { isSameWeek } from '@helpers/time'
import { Distribution } from '@models/Distribution'
import { Order, PickupItem, isPickupItemCancelled, isPickupItemOnVacation } from '@models/Order'
import { RescheduleRequestData } from '@models/OrderChanges'
import { Product, ProductType, Share } from '@models/Product'
import { dateTimeInZone } from '@models/Timezone'
import { DateTime } from 'luxon'

import { formatDistributionType } from '@helpers/location'
import { isLocalPickup } from '@models/Location'
import { Logger } from '../../../../config/logger'
import { ShareData } from './AdminCustomerSubscriptions'
import ShareRescheduleAll from './ShareRescheduleAll'

/** returnType */
type ReturnType = {
  newOrdsMap: Map<string, Order>
  newProdsMap: Map<string, Product>
}

/** It will load orders first and load share products that only appeared in the all orders */
export const loadOrdersMapAndProducts = async (ords: Order[]): Promise<ReturnType> => {
  const newOrdsMap = new Map<string, Order>()
  ords.forEach((ord) => {
    newOrdsMap.set(ord.id, ord)
  })

  //prodsSet will hold all products Id from all orders
  const prodsSet = new Set<string>()
  ords.forEach((ord) => {
    ord.items.forEach((item) => {
      if (item.product.type === ProductType.PrimaryShare || item.product.type === ProductType.AddonShare)
        prodsSet.add(item.product.id)
    })
  })

  const newProdsMap = new Map<string, Product>()

  /** load all purchased share products in a time is Promise.all instead of loading all products in one time to prevent non-error crash on mobile device because of too much products*/
  const products = await Promise.all(
    Array.from(prodsSet).map(
      (prodId) =>
        new Promise<Product | undefined>((res) =>
          loadProduct(prodId)
            .then((v) => res(v))
            .catch(() => {
              Logger.warn(`${prodId} does not exist and may have been deleted`)
              res(undefined)
            }),
        ),
    ),
  )
  products.forEach((prod) => {
    if (!prod) return
    newProdsMap.set(prod.id, prod)
  })

  return { newOrdsMap, newProdsMap }
}

export type SharePickup = {
  pickupId: string
  pickupDate: DateTime
  distId: string
  userId: string
  pickupItem: PickupItem
  rescheduleDate?: DateTime
  canReschedule?: boolean
  onVacation: boolean
  sameDistro?: boolean
}

/**
 * @distro is the new distro that the share will be rescheduled to
 * @sharePickups is the array of pickups that are currently active and available to be rescheduled and will hold data to be used in reschedule (refer to type SharePickup)
 */
export type RescheduleData = {
  distro: Distribution
  sharePickups: SharePickup[]
}

/**
 * @prodId is the share product Id
 * @orderId is the order Id
 * @shareMap is the holding shareId and its (pickups, quantity, orderNum ) data
 *
 * This function is used in Admin side in subscription for farmer to reschedule all share pickups (if all are available to reschedule) to a new distro. If not all pickups are available to reschedule, it will reschedule all the available ones in a row. For consistency and same pattern, when every share pickup are rescheduled, it should be rescheduled and mapped to the different distro in same week.
 */
export const rescheduleAll = async (
  shareMap: Map<string, ShareData>,
  prodId: string,
  orderId: string,
  onChange?: () => void,
) => {
  Loader(true)
  const prod = (await loadProduct(prodId)) as Share

  // shareMap key is the combination of prodId and orderId
  const shareId = `${prodId}_${orderId}`
  const curShareData = shareMap.get(shareId)
  const now = dateTimeInZone(prod.farm.timezone)

  //throw Error if share data is not found
  if (!curShareData) {
    Logger.warn(`share data for ${prodId} is not found`)
    Loader(false)
    Alert('Data Error', 'Current share is not found')
    return
  }

  const deliveryPickup = curShareData.pickups.find((pickup) => !isLocalPickup(pickup.distribution.locationType))

  //throw Alert if current share data pickups are for deliveries or shipping
  if (deliveryPickup) {
    Loader(false)
    return Alert(
      `${formatDistributionType(
        { type: deliveryPickup.distribution.locationType },
        { capitalize: true },
      )} cannot be changed`,
      `We currently don't allow rescheduling for ${formatDistributionType({
        type: deliveryPickup.distribution.locationType,
      })} . Please contact support for more information.`,
    )
  }

  /**
   * sharePickups is an array of objects that are built by an share active Pickups which are still available to be rescheduled in the future. (If the pickup is canceled, it will not be included in the array. Vacation will not be seen as canceled here.)
   */
  const sharePickups: SharePickup[] = curShareData?.pickups
    .filter((pickup) => {
      const pickupItem = pickup.items.find((itm) => itm.order.id === orderId && itm.product.id === prod.id)
      if (!pickupItem) {
        Logger.warn(`pickup item for ${prodId} is not found on ${pickup.date.toISODate()}`)
        Loader(false)
        Alert('Data Error', 'Current pickup item is not found')
        return
      }
      return !isPickupItemCancelled(pickupItem) && now.toMillis() < pickup.date.toMillis()
    })
    .map((pickup) => {
      const pickupItem = pickup.items.find((itm) => itm.order.id === orderId && itm.product.id === prod.id)!
      return {
        pickupId: pickup.id,
        pickupDate: pickup.date,
        distId: pickup.distribution.id,
        userId: pickup.user.id,
        pickupItem,
        onVacation: isPickupItemOnVacation(pickupItem),
      }
    })

  const distros = prod.distributions

  /** rescheduleObjs stores distroId - rescheduleData pairs. The rescheduleData is an object that contains the distro and all sharePickups. (rescheduleObjs holds all the data that will be useful for UI and server calls.)*/
  const rescheduleObjs: Record<string, RescheduleData> = {}

  for (let idx = 0; idx < distros.length; idx++) {
    const distro = distros[idx]

    //If the location type of the distro is not local pickup, then skip this distro.
    if (!isLocalPickup(distro.location.type)) continue

    //get all available pickups for the prod-distro relation
    const availPickups = getPickups(distro, prod, {
      ignoreOrderCutoffWindow: true,
      ignoreDisableBuyInFuture: true,
    })

    const copiedSharePickups = sharePickups.map((item) => ({ ...item })) as SharePickup[]

    //The availPickups date will be remove from itself when it is paired with a sharePickup, and it will also prevent duplicate reschedule on the same date.
    const data = copiedSharePickups.map((sharePickup) => {
      const foundIdx = availPickups.findIndex((availPickup) => isSameWeek(sharePickup.pickupDate, availPickup))
      sharePickup.sameDistro = distro.id === sharePickup.distId
      if (foundIdx !== -1) {
        sharePickup.canReschedule = true
        sharePickup.rescheduleDate = availPickups[foundIdx]
        availPickups.splice(foundIdx, 1)
      } else {
        sharePickup.canReschedule = false
      }

      return sharePickup
    })

    //If all sharePickups are either on vacation or can not be rescheduled or are on the same distro, then skip this distro.
    if (data.every((item) => !item.canReschedule || item.onVacation || item.sameDistro)) continue
    rescheduleObjs[distro.id] = { distro, sharePickups: data }
  }

  Loader(false)

  Modal(<ShareRescheduleAll rescheduleDataObjs={rescheduleObjs} onChange={onChange} />, {
    title: `Reschedule Share ${prod.name} on Order ${getOrderNum(curShareData.orderNum!)}`,
    webWidth: 800,
    noPadding: true,
  })
}

//This will handle real logic and build require data to be sent to server for function call.
export const confirmRescheduleAll = async (
  option: string,
  rescheduleData: Record<string, RescheduleData>,
  count: number,
  onChange?: () => void,
) => {
  Loader(true)
  const rescheduleDataObj = rescheduleData[option]

  if (!rescheduleDataObj) {
    Loader(false)
    return Alert('Error', 'No reschedule data found')
  }

  const newDistro = rescheduleDataObj.distro
  const sharePickups = rescheduleDataObj.sharePickups
  const canSchedulePickups = sharePickups.filter((item) => item.canReschedule && !item.onVacation && !item.sameDistro)

  //build the request object array
  const reqObjArr: RescheduleRequestData[] = canSchedulePickups.map((itm) => {
    return {
      pickId: itm.pickupId,
      distId: itm.distId,
      userId: itm.userId,
      items: [
        {
          oldItm: itm.pickupItem,
          newItm: {
            date: itm.rescheduleDate!,
            dist: newDistro,
          },
        },
      ],
    }
  })

  try {
    await reschedulePickup({ data: reqObjArr, type: 'rescheduleAll' })
    /** after await reschedulePickup and immediately run onChange() will cause UI glitch sometimes. We have no idea how long will take firestore to finish updating before fetch the new data, so setTimeout 10 secs before run onChange will be safe and more stable, so that data and UI can be correct. 25 Pickups for a subscription will take time to update and take time to reflect the new data on UI. */
    setTimeout(() => {
      onChange?.()
      Alert('Pickups Rescheduled', `You have successfully rescheduled all ${count} pickups to ${newDistro.name}`, [
        {
          text: 'Ok',
          onPress: () => Loader(false),
        },
      ])
    }, 10000)
  } catch (err) {
    Loader(false)
    Alert(
      'Failed to Reschedule',
      `One or more items were not rescheduled, please try again or contact support if the issue persists.`,
    )
  }
}
