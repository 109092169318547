import { Text, TextH2, TextH3, Icon as FAIcon } from '@elements'
import { formatInvoiceNum, formatMoney, formatPickupDate } from '@helpers/display'
import { MoneyCalc } from '@helpers/money'
import { Invoice, invoiceRefundAmount, InvoiceStatus, isRefunded } from '@models/Invoice'
import { dateTimeInZone } from '@models/Timezone'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { Icon } from 'react-native-elements'

import Colors from '../../constants/Colors'
import { globalStyles } from '../../constants/Styles'

type PropTypes = {
  invoice: Invoice
  onPress?: () => void
}

const opts = (invoice: Invoice) => {
  const now = dateTimeInZone(invoice.farm.timezone)

  if (isRefunded(invoice))
    if (MoneyCalc.isLessThan(invoiceRefundAmount(invoice), invoice.amountTotal))
      return {
        color: Colors.blue,
        icon: 'history',
        action: `PARTIAL REFUND (${formatMoney(invoiceRefundAmount(invoice))})`,
      }
    else
      return {
        color: Colors.blue,
        icon: 'history',
        action: 'REFUNDED',
      }

  if (invoice.status === InvoiceStatus.Void)
    return {
      color: Colors.blue,
      icon: 'times',
      action: 'VOIDED',
    }
  if (invoice.status === InvoiceStatus.Paid)
    return {
      color: Colors.green,
      icon: 'check',
      action: 'View Receipt',
      title: 'View Your Receipt',
    }
  if (invoice.status === InvoiceStatus.Failed)
    return {
      color: Colors.red,
      icon: 'times',
      action: 'Pay Invoice',
      title: 'Pay Your Invoice',
    }
  if (invoice.dueDate > now)
    return {
      color: Colors.blue,
      icon: 'clock',
      action: 'Not Due Yet',
    }
  return {
    color: Colors.blue,
    icon: 'clock',
    action: 'Pay Now',
    title: 'Pay Your Invoice',
  }
}

export function PaymentCard({ invoice, onPress }: PropTypes) {
  const navigation = useNavigation<StackNavigationProp<any>>()

  const payAction = () => {
    if (onPress) return onPress()

    return navigation.navigate('InvoiceConsumer', {
      invoiceId: invoice.id,
    })
  }

  return (
    <TouchableOpacity style={styles.paymentItem} onPress={payAction}>
      <Icon size={20} reverse name={opts(invoice).icon} type="font-awesome-5" color={opts(invoice).color} />
      <View style={globalStyles.flex1}>
        <TextH2>Invoice {formatInvoiceNum(invoice.invoiceNum)}</TextH2>
        <TextH3>{formatPickupDate(invoice.dueDate)}</TextH3>
      </View>
      <View style={{ alignItems: 'flex-end', flex: 1 }}>
        <Text type="medium" style={[styles.amountText, { color: opts(invoice).color }]}>
          {formatMoney(
            invoice.amountPaid === undefined || MoneyCalc.isZero(invoice.amountPaid)
              ? invoice.amountTotal
              : invoice.amountPaid,
          )}
        </Text>
        <Text type="bold">{opts(invoice).action}</Text>
      </View>
      <FAIcon name="chevron-right" style={{ marginLeft: 10 }} />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  paymentItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 5,
  },
  amountText: {
    fontSize: 18,
    fontWeight: '700',
  },
})
