import { ChatSession, isAccountParticipant } from '@models/Messaging'
import { userName } from '@models/User'

/** Adapter that gets the info used in the conversation card for different type of users */
export const getCardParticipantInfo = (participant: ChatSession['participants'][string]) => {
  if (isAccountParticipant(participant)) {
    return {
      logo: participant.account.logo,
      name: participant.account.name,
    }
  }

  return {
    logo: participant.user.avatar,
    name: userName(participant.user),
  }
}
