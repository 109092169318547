import { Farm } from '@models/Farm'
import { DEFAULT_APP_FEE, DEFAULT_MEMBER_APP_FEE } from '@/constants/CONSTANTS'

/**
 * This function will get the GrownBy application fee for a specific farm
 * @param farm the farm to get the app fee for
 */
export const getApplicationFee = (farm: Farm): number => {
  const isMember = typeof farm.memberId === 'string'
  const hasCustomFeeSet = farm.pricingModel?.appFeePercent !== undefined

  if (hasCustomFeeSet) {
    return farm.pricingModel!.appFeePercent!
  }

  if (isMember) {
    return DEFAULT_MEMBER_APP_FEE
  }

  return DEFAULT_APP_FEE
}
