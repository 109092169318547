import { MessageWithIcon } from '@components'
import { Text } from '@elements'
import { ProductListSkeleton } from '@screens/Shopping/FarmShop/components'
import { StyleSheet, View } from 'react-native'

type ListEmptyProps = {
  prodsLoading: boolean
  hasError: boolean
}

/** Component to show when no data is available, used inside SearchScreen*/
export function ListEmpty({ prodsLoading, hasError }: ListEmptyProps) {
  if (prodsLoading) {
    return <ProductListSkeleton />
  }
  if (hasError) {
    return (
      <View style={styles.main}>
        <MessageWithIcon icon="tractor" title="Load error">
          <Text center>There was an error while loading the products. Please try again.</Text>
        </MessageWithIcon>
      </View>
    )
  }

  return (
    <View style={styles.main}>
      <MessageWithIcon icon="tractor" title="Sorry, no products were found">
        <Text center>We couldn't find any matching results. Please adjust your search.</Text>
      </MessageWithIcon>
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    flexGrow: 1,
  },
})
