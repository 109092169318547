import { objToStr } from '@helpers/log'
import { memo, useMemo } from 'react'
import { StyleProp, TextStyle } from 'react-native'

import Colors from '../../constants/Colors'
import { withColorHover } from '../../hooks/withColorHover'
import { Icon } from './Icon/Icon'
import { FontAwesome5IconName } from './Icon/iconNames'
import { LoadingView } from './LoadingView'
import { Text } from './Text'
import { Touchable, TouchableProps } from './Touchable'

import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useFlatStyle } from '@/hooks/useMergeStyle'

type Props = TouchableProps & {
  textStyle?: StyleProp<TextStyle>
  icon?: FontAwesome5IconName
  color?: string
  title?: string
  url?: string
  loading?: boolean
  size?: number
  small?: boolean
}

function ButtonClearComp({
  style,
  textStyle,
  icon,
  color = Colors.green,
  title,
  loading = false,
  size: sizeProp = 16,
  disabled,
  small = false,
  ...rest
}: Props) {
  const styles = useStyles(small)
  const iconStyleMemo = useFlatStyle(styles.icon, textStyle)

  const textColor = !disabled ? color : Colors.shades['200']
  const size = useMemo(() => (small ? 12 : title ? sizeProp : 20), [title, sizeProp, small])

  const loadingPropsMemo = useMemo(() => ({ style: iconStyleMemo, size, color }), [iconStyleMemo, size, color])

  const textEl = useMemo(
    () => (title ? <Text style={textStyle} type="medium" size={size} color={textColor} children={title} /> : null),
    [title, objToStr(textStyle), textColor, size],
  )

  return (
    <Touchable {...rest} style={useFlatStyle(styles.container, style)} disabled={disabled || loading}>
      <LoadingView
        loading={loading}
        spinnerProps={loadingPropsMemo}
        children={useMemo(
          () => (icon ? <Icon name={icon} noHover style={iconStyleMemo} color={textColor} size={size} /> : undefined),
          [icon, iconStyleMemo, textColor, size],
        )}
        noDefaultLoadingContainerStyle
      />
      {textEl}
    </Touchable>
  )
}
export const ButtonClear = memo(withColorHover(ButtonClearComp))

const useStyles = (small: boolean) =>
  useSizeFnStyles(
    ({ isSmallDevice }, small) => ({
      container: {
        flexDirection: 'row',
        padding: small ? 0 : 10,
        alignItems: 'center',
      },
      icon: {
        paddingRight: isSmallDevice ? 5 : 10,
      },
    }),
    small,
  )
