import { Text } from '@elements'
import { StyleSheet, Switch, View, ViewStyle } from 'react-native'

import Colors from '@/constants/Colors'

export type ToggleButtonProps = Omit<ToggleProps, 'value'> & {
  value?: boolean
  title: string
  subtitle?: string
  iconRight?: boolean
  style?: ViewStyle
}

export function ToggleButton({
  title,
  subtitle,
  value,
  onChange,
  disabled,
  iconRight = false,
  style,
}: ToggleButtonProps) {
  return (
    <View style={[styles.container, iconRight && styles.iconRight, style]}>
      <Toggle onChange={() => onChange(!value)} value={!!value} disabled={disabled ?? value === undefined} />
      <View style={styles.marginHoriz5}>
        <Text size={14}>{title}</Text>
        {!!subtitle && <Text size={10}>{subtitle}</Text>}
      </View>
    </View>
  )
}

type ToggleProps = {
  value: boolean
  onChange(val: boolean): void
  disabled?: boolean
}

export function Toggle({ value, disabled, onChange }: ToggleProps) {
  return (
    <Switch
      trackColor={{ false: Colors.shades[300], true: Colors.green }}
      thumbColor="white"
      ios_backgroundColor="gray"
      onValueChange={() => onChange(!value)}
      value={value}
      disabled={disabled}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconRight: { flexDirection: 'row-reverse' },
  marginHoriz5: {
    marginHorizontal: 5,
  },
})
