import { useCoords } from '@/hooks/useCoords/useCoords'
import { useFocusFx } from '@/hooks/useFocusFx'
import { HomeParamList } from '@/navigation/types'
import { CoordString, getCoordString } from '@helpers/coordinate'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import { LocType } from '../searchScreen-helpers'

/** Will set a default coord to params once if none is defined */
export function useSetInitialCenter(locType: LocType | undefined, coordParam: CoordString | undefined) {
  const coordsDefault = useCoords()
  const navigation = useNavigation<NavigationProp<HomeParamList, 'SearchScreen'>>()

  useFocusFx(
    () => {
      if (locType !== 'coord' || !!coordParam || !coordsDefault) return

      const center = getCoordString(coordsDefault)
      if (!center) return

      navigation.setParams({ center })
    },
    [navigation, locType, coordParam, coordsDefault],
    { noRefocus: true, once: true },
  )
}
