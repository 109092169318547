import { SignInProviders, User } from '@models/User'

import { AnyAction } from 'redux'
import { ENABLE_NOTIFICATION, ENABLE_PROMOTION, HIDE_USER_TIPS, SET_USER, SET_USER_ROLE } from '../actions/actionTypes'

export const initialState: User = {
  id: '',
  avatar: '',
  name: {
    firstName: '',
    lastName: '',
  },
  email: '',
  notificationToken: '',
  notifications: {
    pushNotification: true,
    text: true,
  },
  promotions: {
    email: true,
    pushNotification: true,
    text: true,
  },
  customerRef: '',
  signInProvider: SignInProviders.Unknown,
}

const userReducer = (state = initialState, action: AnyAction): User => {
  switch (action.type) {
    case SET_USER:
      return action.payload
    case ENABLE_NOTIFICATION:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          ...action.payload,
        },
      }
    case ENABLE_PROMOTION:
      return {
        ...state,
        promotions: {
          ...state.promotions,
          ...action.payload,
        },
      }
    case HIDE_USER_TIPS:
      return {
        ...state,
        hideTips: true,
      }
    case SET_USER_ROLE:
      return {
        ...state,
        role: action.payload,
      }
    default:
      return state
  }
}
export default userReducer
