import { TextH1, TextH3, Button, Divider } from '@elements'
import { emailSupport } from '@helpers/client'
import { StackScreenProps } from '@react-navigation/stack'
import { StyleSheet, View } from 'react-native'

import { Image } from '../components/Image'
import { AppStackParamList } from '../navigation/types'

export default function NotFoundScreen({ navigation }: StackScreenProps<AppStackParamList, 'NotFound'>) {
  return (
    <View style={styles.container}>
      <Image source={require(`../assets/images/text_logo.png`)} style={styles.textLogo} />
      <Divider bottom={10} clear />
      <TextH1>Unfortunately we couldn't find this screen</TextH1>
      <TextH3>Here are some helpful links</TextH3>
      <Divider clear />
      <View style={{ width: 300 }}>
        <Button title="Home" onPress={() => navigation.replace('Consumer')} />
        <Button title="Manage Your Farm" onPress={() => navigation.navigate('AdminDrawerNavigator')} />
        <Button title="Contact Support" onPress={() => emailSupport()} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  textLogo: {
    height: 50,
    width: 202,
    resizeMode: 'contain',
  },
})
