import { addReview, deleteReview, updateReview } from '@api/Reviews'
import { Button, ButtonClear, Toast, hideModal } from '@elements'
import { pick } from '@helpers/typescript'
import { Review } from '@models/Review'
import { User } from '@models/User'
import { useState } from 'react'
import { StyleSheet, TextInput, View } from 'react-native'
import { Rating } from 'react-native-ratings'
import { useSelector } from 'react-redux'

import Colors from '../../constants/Colors'
import { globalStyles } from '../../constants/Styles'
import { withAuth } from '../../hooks/withAuth'
import { RootState } from '../../redux/reducers/types'
import { userSelector } from '../../redux/selectors'

function EditReview(props: { review?: Review; farmId: string; onDelete(): void; onUpdate(review: Review): void }) {
  const user = useSelector<RootState, User>(userSelector)

  const [description, setDescription] = useState(props.review?.description || '')
  const [rating, setRating] = useState(props.review?.rating || 0.01)
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async () => {
    if (rating < 0.1) {
      return Toast('Please leave a rating!')
    }
    setIsLoading(true)

    let newReview: Partial<Review> = {
      id: props.review?.id || '',
      idFarm: props.farmId,
      flaggedCount: 0,
      description,
      rating,
      user: pick(user, 'id', 'name', 'avatar'),
    }
    try {
      if (props.review?.id) {
        await updateReview({ description, rating, id: props.review.id })
      } else {
        newReview = await addReview(newReview as Review)
      }
      props.onUpdate(newReview as Review)
      setIsLoading(false)
      hideModal()
    } catch (e: any) {
      setIsLoading(false)
      Toast(e)
    }
  }

  const onDelete = async () => {
    setIsLoading(true)
    await deleteReview(props.review!.id!)
    props.onDelete()
    setIsLoading(false)
    hideModal()
  }
  return (
    <View style={{ marginVertical: 20 }}>
      <Rating startingValue={props.review?.rating ?? 0} onFinishRating={setRating} showRating={false} />
      <TextInput
        autoFocus
        placeholder="Leave a review..."
        style={styles.textBox}
        multiline
        numberOfLines={5}
        onChangeText={setDescription}
        defaultValue={description}
      />
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-around',
          marginHorizontal: 10,
        }}
      >
        {props.review?.id ? (
          <ButtonClear title="Delete" color={Colors.red} onPress={onDelete} />
        ) : (
          <ButtonClear title="Cancel" onPress={hideModal} />
        )}
        <Button style={globalStyles.flex1} loading={isLoading} title="Submit" onPress={onSubmit} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  button: {
    margin: 0,
    padding: 0,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    marginBottom: 16,
    fontWeight: 'bold',
  },
  textBox: {
    margin: 10,
    padding: 10,
    borderRadius: 5,
    borderWidth: 1,
  },
})

export default withAuth(EditReview)
