import { StyleSheet, View } from 'react-native'

import { isWeb } from '@/constants/Layout'
import Colors, { withAlpha } from '../../constants/Colors'

/** Renders the user location marker when there's a current location.
 * - The _props are not used because the map library parses them and processes the rendering process for us. That means it NEEDS to receive the props for this to work, but we don't need to handle them.
 * - This is specifically the implementation requirements for the web GoogleMapReact library.
 */
export function UserLocationMarker(_props: google.maps.LatLngLiteral) {
  if (!isWeb) return null
  return (
    <View style={styles.currLocation}>
      <View style={styles.currLocationInside} />
    </View>
  )
}

const styles = StyleSheet.create({
  currLocation: {
    position: 'absolute',
    top: -20,
    left: -20,
    backgroundColor: withAlpha(Colors.blue, 0.2),
    width: 40,
    height: 40,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: withAlpha(Colors.blue, 0.2),
  },
  currLocationInside: {
    backgroundColor: Colors.blue,
    width: 15,
    height: 15,
    borderRadius: 25,
    borderWidth: 2,
    borderColor: Colors.white,
  },
})
