import { PropsWithChildren, memo, useContext } from 'react'

import { OrderCreatorScreenContext } from '../../OrderCreatorScreen.helper'

import { adminFarmIdSelector } from '@/redux/selectors'
import { useSelector } from 'react-redux'
import { OrderSummary_Reusable } from './OrderCreatorSummary-reusable'

export type OrderSummaryProps = {
  children?: PropsWithChildren['children']
}
/** Adapter for the order creator to render the reusable component for order summary */
export const OrderSummary = memo(function OrderSummary({ children }: OrderSummaryProps) {
  const adminFarmId = useSelector(adminFarmIdSelector) || ''

  const {
    keyedStatePayments: [state],
    isWholesaleOrderCreator,
  } = useContext(OrderCreatorScreenContext)

  if (isWholesaleOrderCreator === undefined) return null

  return (
    <OrderSummary_Reusable
      cartServiceType="orderCreator"
      farmId={adminFarmId}
      isWholesale={isWholesaleOrderCreator}
      paymentsState={state}
      children={children}
    />
  )
})
