import { memo } from 'react'
import { StyleSheet } from 'react-native'
import { Skeleton as SkeletonElement, SkeletonProps } from 'react-native-elements'

export const DEFAULT_BONE_COLOR = '#E1E9EE'
export const DEFAULT_HIGHLIGHT_COLOR = '#F2F8FC'

export const Skeleton = memo(({ style, skeletonStyle, ...props }: SkeletonProps) => {
  return (
    <SkeletonElement {...props} style={[style, styles.style]} skeletonStyle={[skeletonStyle, styles.skeletonStyle]} />
  )
})

const styles = StyleSheet.create({
  style: {
    backgroundColor: DEFAULT_BONE_COLOR,
  },
  skeletonStyle: {
    backgroundColor: DEFAULT_HIGHLIGHT_COLOR,
  },
})
