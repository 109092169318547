import { loginAsUser } from '@api/FarmGenAdmin'
import { PartialExcept } from '@helpers/typescript'
import { SignInProviders, User } from '@models/User'
import * as Linking from 'expo-linking'
import { useState } from 'react'

import { ButtonClear } from '../../components/elements/ButtonClear'
import Colors from '../../constants/Colors'

function SignInOptions({ signInProvider, onPress }: { signInProvider?: SignInProviders; onPress(): void }) {
  switch (signInProvider) {
    case SignInProviders.Apple:
      return <ButtonClear color={Colors.black} icon="apple" onPress={onPress} />
    case SignInProviders.Google:
      return <ButtonClear color={Colors.google} icon="google" onPress={onPress} />
    case SignInProviders.Phone:
      return <ButtonClear color={Colors.green} icon="phone" onPress={onPress} />
    case SignInProviders.Email:
      return <ButtonClear color={Colors.blue} icon="envelope" onPress={onPress} />
    default:
      return <ButtonClear color={Colors.red} icon="question-circle" onPress={onPress} />
  }
}

export default function LoginAsUser(props: { item: PartialExcept<User, 'id'> }) {
  const [loginLink, setLink] = useState('')

  const loginUser = (userId: string) => {
    setLink('LOAD')
    loginAsUser(userId).then((token) => {
      const url = Linking.createURL(`/farm-generations/admin/user/${token}`)
      setLink(url)
    })
  }
  return (
    <>
      {loginLink ? (
        <ButtonClear icon="external-link-alt" loading={loginLink === 'LOAD'} url={loginLink} />
      ) : (
        <SignInOptions signInProvider={props.item.signInProvider} onPress={() => loginUser(props.item.id)} />
      )}
    </>
  )
}
