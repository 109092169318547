import { HalfModal } from './HalfModal'
import { ModalComponent } from './ModalComponent'
import { ModalComponentProps } from '../../../../constants/types/modalTypes'

import { isMobile } from '@/constants/Layout'

/** The ModalInline tries to use an optimal version of the modal for mobile, else it defaults to the regular modal component
 * - This component is used for screen-level overlays only
 */
export default function ModalInline({ halfModal, children, ...rest }: ModalComponentProps) {
  if (halfModal && isMobile)
    return (
      <HalfModal header={false} {...rest}>
        {children}
      </HalfModal>
    )
  else return <ModalComponent {...rest}>{children}</ModalComponent>
}
