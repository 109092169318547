import { Modal, hideModal } from '@elements'
import { ProductType, TemplateType } from '@models/Product'
import { StackNavigationProp } from '@react-navigation/stack'

import { ChooseProductType } from '../../ProductForm/ChooseProductType'

import { AdminProductsParamList } from '@/admin/navigation/types'

export const addProductModal = (navigation: StackNavigationProp<AdminProductsParamList, 'ProductList'>) => {
  Modal(
    <ChooseProductType
      onSelect={(type: ProductType | typeof TemplateType) => {
        hideModal()
        if (type === TemplateType) return navigation.replace('AddTemplate')
        navigation.replace('AddProduct', { type })
      }}
    />,
    { webWidth: 1000 },
  )
}
