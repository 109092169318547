import { Farm } from '@models/Farm'
import { arrayUnion, collection, doc, getDoc } from 'firebase/firestore'

import { db } from './db'
import { farmsCollection } from './framework/ClientCollections'

/** returns a list of the default available commodities that can be selected when creating a product. */
export async function loadDefaultBaseUnits(): Promise<string[]> {
  const buyingOptions = await getDoc(doc(collection(db(), 'buying_options'), 'all_options'))
  return buyingOptions.get('options')
}

/** returns the list of base units available to a specific farm, by combining the default ones with the custom ones saved for the farm */
export async function loadFarmBaseUnits(customBaseUnits: Farm['localBaseUnits']): Promise<string[]> {
  const baseUnits = await loadDefaultBaseUnits()
  return baseUnits.concat(...(customBaseUnits ?? [])).sort()
}

/** This receives the farm-specific custom base unit and saves it to the farm for later use */
export async function addBaseUnit(baseUnit: string, currFarm: string): Promise<'added' | 'already-exists'> {
  const farm = await farmsCollection.fetch(currFarm)
  const currBaseUnits = await loadFarmBaseUnits(farm.localBaseUnits)

  // block duplicates
  if (currBaseUnits.includes(baseUnit)) {
    return Promise.resolve('already-exists')
  }

  await farmsCollection.update({
    id: currFarm,
    localBaseUnits: arrayUnion(baseUnit) as any,
  })
  return 'added'
}
