import { AvailAddonResult } from '@helpers/addons'
import { Coordinate } from '@models/Coordinate'
import { Money } from '@models/Money'
import { PaymentInterval } from '@models/Payment'
import { ScaledSize } from 'react-native'

import { EdgeInsets } from 'react-native-safe-area-context'
import { DEVICE_SIZES } from './Layout'

/** Customized dimensions data for layout design */
export type Layout = ScaledSize &
  EdgeInsets & {
    /** The layout.width will have the width from useWindowDimensions, except in admin, where the sidebar width will be subtracted */
    width: number
    /** The screenWidth will always have the true screen width; whereas the layout.width in admin will not include the sidebar width */
    screenWidth: number
    /** This will hold the devices current size */
    size: DEVICE_SIZES
  }

export type CurrentLocation = {
  coordinate: Coordinate
  city: string
  /** Miliseconds when this location was set */
  timestamp: number
}

export type Total = Omit<PaymentInterval, 'date'> & {
  /** The summed amount of all tax from all taxable items */
  tax: Money
  /** Display only to show what was paid as a tip */
  tips?: Money
}

export type SaleStats = {
  sales: number
  salesByUnit?: Map<string, number>
  salesByOption?: Map<string, number>
  unitSum: number
  cancelled: number
  revenue: number
  uniqueCustomers: Set<string>
}

export type AddonsPurchases = {
  data: AvailAddonResult[]
  hash: number
}

export const newSaleStat = () => {
  return {
    sales: 0,
    cancelled: 0,
    revenue: 0,
    uniqueCustomers: new Set<string>(),
    salesByOption: new Map<string, number>(),
    salesByUnit: new Map<string, number>(),
    unitSum: 0,
  } as SaleStats
}
