import React, { ReactElement, ReactNode } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { useDeviceSize } from '../../../hooks/useLayout'

/** A react element with an optional row prop */
type ChildWithRowProp = ReactElement<{ row?: boolean }>

/** Check if it is an element and if so cast its type as a jsx element that can receive a row prop */
function canReceiveRow(child: ReactNode): child is ChildWithRowProp {
  return React.isValidElement(child)
}

type FormDisplayRowProps = {
  children: ReactNode | ChildWithRowProp | ChildWithRowProp[]
  // If true then the data will not be converted to a row for small devices
  ignoreSmall?: boolean
  style?: StyleProp<ViewStyle>
}

/** This component can be used to produce rows of inputs, its children should only be elements that have a row property */
export function FormDisplayRow({ children, ignoreSmall, style }: FormDisplayRowProps) {
  const { isSmallDevice } = useDeviceSize()
  // For small devices don't format as a row if we pass the ignoreSmall prop
  if (ignoreSmall && isSmallDevice) {
    return <View style={style}>{children}</View>
  }

  const childrenWithRowProp = React.Children.map(children, (child) => {
    // Check if the child is a valid React element with the row prop
    if (canReceiveRow(child)) {
      return React.cloneElement(child, { row: true })
    }
    return child
  })

  return <View style={[styles.row, style]}>{childrenWithRowProp}</View>
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    // We align flex-end so that it is aligned by inputs and not labels
    alignItems: 'flex-start',
  },
})
