import { Event, EventTypes, getEventMaxTries, getExpiration } from '@models/Event'
import { CustomerHoldResponse } from '@shared/types/v2/events'
import { limit, where } from 'firebase/firestore'
import { DateTime, DurationLike } from 'luxon'

import { eventsCollection } from './framework/ClientCollections'
import { callEndpoint } from './v2/call'
import { unmarshalDate } from './encoding/Time'

/**
 * Will log an event that can be tracked in firestore
 * @param event The event to be tracked ignoring time and expiration
 * @param expire an optional expiration time, will default to the amount specified by the event
 */
export async function logEvent(event: Omit<Event, 'expire' | 'time' | 'id'>, expire?: DurationLike) {
  const time = DateTime.now()
  const expiration = time.plus(expire ?? getExpiration(event.type))
  await eventsCollection.create({ ...event, time, expire: expiration, id: '' } as Partial<Event>)
}

/**
 * Will return a boolean indicating if the max number of tries for an EBT event have been reached.
 * @param event The event to search for
 * @param userId The userId to search for
 */
export async function hasHitEbtEventLimit(event: EventTypes, userId: string) {
  // The number of tries allowed. If this limit has been reached then this will return false
  const maxLimit = getEventMaxTries(event)

  const res = await eventsCollection.fetchAll(
    where('type', '==', event),
    where('data.user.id', '==', userId),
    limit(maxLimit),
  )
  // If we have not used the maxLimit tries on this event then it should be allowed
  return res.length >= maxLimit
}
/**
 * Will return an object containing the last_expire date of all events for a particular customer as well as an array containing the event types that reached their max limit
 */
export async function hasEbtHoldsDates(userId: string): Promise<CustomerHoldResponse> {
  const result = await callEndpoint('v2.Event.hasEbtHoldsDatesService', {
    userId,
  })

  return { ...result, last_expire: result.last_expire ? unmarshalDate(result.last_expire) : null }
}
/**
 * Will delete all holding events for a particular user
 */
export async function removeUserEvents(userId: string): Promise<void> {
  return await callEndpoint('v2.Event.removeUserEventsService', { userId })
}
