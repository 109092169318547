import React from 'react'
import { StyleSheet, View } from 'react-native'

import Colors from '@/constants/Colors'
import { Text } from '@elements'
import { ItemType } from '@screens/SignInSheet/components'
import { getCategoryName } from '@screens/SignInSheet/helpers'

/** Header component for a specific sign in item group */
export function SignInHeader({ item }: { item: Pick<ItemType, 'type'> }) {
  return (
    <View style={styles.header}>
      <View style={styles.largeCont}>
        <Text>{getCategoryName(item)}</Text>
      </View>
      <View style={styles.smallCont}>
        <Text>QTY</Text>
      </View>
      <View style={styles.mediumCont}>
        <Text>Buying Option</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: Colors.lightGray,
  },
  largeCont: {
    flex: 4,
  },
  mediumCont: {
    flex: 2,
  },
  smallCont: {
    flex: 1,
  },
})
