import { updateFarmAssociation } from '@api/FarmAssociations'
import { ConsumerScroll } from '@components'
import { Divider, Picker, Text, ToggleButton } from '@elements'
import { FarmAssociation, Notifications, SystemNotifications, User } from '@models/User'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { withAuth } from '@/hooks/withAuth'
import { enableNotification, enablePromotion } from '@/redux/actions/user'
import { RootState } from '@/redux/reducers/types'
import { farmsSelector, notificationsSelector, promotionsSelector, userSelector } from '@/redux/selectors'

function SectionButtonView({
  data,
  onChange,
}: {
  data?: Notifications
  onChange: (type: 'email' | 'pushNotification' | 'text', value: boolean) => void
}) {
  return (
    <>
      <ToggleButton iconRight title="Email" value={data?.email} onChange={(val) => onChange('email', val)} />
      <Divider />
      <ToggleButton
        iconRight
        title="Push Notification"
        subtitle="Receive messages on your device"
        value={data?.pushNotification}
        onChange={(val) => onChange('pushNotification', val)}
      />
      <Divider bottom={50} />
    </>
  )
}

function NotificationScreenComp() {
  const dispatch = useDispatch()

  const [farm, selectFarm] = useState<FarmAssociation>()
  const user = useSelector<RootState, User>(userSelector)
  const myFarms = useSelector<RootState, FarmAssociation[]>(farmsSelector)

  const notifications = useSelector<RootState, SystemNotifications>(notificationsSelector)
  const promotions = useSelector<RootState, Notifications>(promotionsSelector)

  const farmMessages = {
    email: farm?.farmMessages?.email ?? true,
    pushNotification: farm?.farmMessages?.pushNotification ?? notifications.pushNotification,
    text: farm?.farmMessages?.text ?? notifications.text,
  }

  // Load farm association and update existing if we have one defined
  useEffect(() => {
    if (farm) {
      const currFarm = myFarms.find((far) => far.id === farm.id)
      selectFarm(currFarm || myFarms[0])
    } else selectFarm(myFarms[0])
  }, [myFarms])

  const selectNewFarm = (id: string) => {
    const newFarm = myFarms.find((farm) => farm.id === id)
    if (newFarm) selectFarm(newFarm)
  }

  return (
    <ConsumerScroll>
      <Text size={16} type="medium">
        GrownBy Updates
      </Text>

      <Text>Receive order and account updates</Text>
      <ToggleButton
        iconRight
        title="Push Notification"
        subtitle="Receive messages on your device"
        value={notifications.pushNotification}
        onChange={(val) => dispatch(enableNotification('pushNotification', val))}
      />
      <Divider bottom={50} />

      {farm && myFarms.length > 0 && (
        <>
          <Text size={16} type="medium">
            Farm Messages and Reminders
          </Text>
          <Text>Receive order and farm updates</Text>
          <Picker
            value={farm?.id}
            onValueChange={selectNewFarm}
            items={
              myFarms.length > 0
                ? myFarms.map((farmAssociation) => ({
                    label: farmAssociation.name,
                    value: farmAssociation.id,
                  }))
                : []
            }
          />
          <SectionButtonView
            data={farmMessages}
            onChange={(type, value) => {
              updateFarmAssociation(
                {
                  ...farm,
                  farmMessages: { ...farm.farmMessages!, [type]: value },
                },
                user.id,
              )
            }}
          />
        </>
      )}

      <Text size={16} type="medium">
        Promotions and Tips
      </Text>
      <Text>
        Receive recipe ideas, request to review, pricing notices and other reminders related to your purchases on
        GrownBy
      </Text>
      <SectionButtonView onChange={(type, value) => dispatch(enablePromotion(type, value))} data={promotions} />
    </ConsumerScroll>
  )
}

export const NotificationScreen = withAuth(NotificationScreenComp, { noSafeAreaInsets: true })
