import { useSelector } from 'react-redux'

import { OrderProductType } from '@/admin/navigation/types'
import { KeyedState } from '@/hooks/useKeyedState'
import { adminFarmIdSelector, isWholesaleOrderCreatorSelector } from '@/redux/selectors'
import { Product } from '@models/Product'
import { OrderCreatorStateProductsFilters } from './OrderCreatorScreen.helper'
import { useSchedulesAndDatesReusable } from './useSchedulesAndDates-reusable'

/** Data layer for any state related to schedules and dates in the order creator */
export function useSchedulesAndDates(opts: {
  orderType: OrderProductType
  keyedState: KeyedState<OrderCreatorStateProductsFilters>
  isLoadingProducts: boolean
  dbProds: Product[] | undefined
}) {
  const isWholesaleOrderCreator = useSelector(isWholesaleOrderCreatorSelector)

  const adminFarmId = useSelector(adminFarmIdSelector) || ''

  return useSchedulesAndDatesReusable({
    ...opts,
    farmId: adminFarmId,
    isWholesale: isWholesaleOrderCreator,
  })
}
