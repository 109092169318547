// User
export const SET_USER = 'SET_USER'
export const SET_SIGNEDIN_TIME = 'SET_SIGNEDIN_TIME'
export const SIGN_OUT = 'SIGN_OUT'
export const HIDE_USER_TIPS = 'HIDE_USER_TIPS'
export const SET_USER_ROLE = 'SET_USER_ROLE'
export const ENABLE_PROMOTION = 'ENABLE_PROMOTION'
export const ENABLE_NOTIFICATION = 'ENABLE_NOTIFICATION'

// General
export const SET_WHOLESALE = 'SET_WHOLESALE'
export const SET_LAYOUT = 'SET_LAYOUT'
export const SET_NAV_ROUTE = 'SET_NAV_ROUTE'
export const SET_FEATURES_AVAILABLE = 'SET_FEATURES_AVAILABLE'
export const ADD_NAV_PROP = 'NAV_PROP'
export const SET_NAV_PROPS = 'SET_NAV_PROPS'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'

// Map / Explore
export const SET_REGION = 'SET_REGION'
export const SET_SEARCH_LOCATION = 'SET_SEARCH_LOCATION'
export const SET_CURRENT_HOVER = 'SET_CURRENT_HOVER'
export const SET_SEARCH_FILTERS = 'SET_SEARCH_FILTERS'

//Consumer cart
export const SET_CART_SERVICE = 'SET_CART_SERVICE'
export const UPDATE_CART_INFO = 'UPDATE_CART_INFO'

// Global listeners
export const SET_LISTENERS = 'SET_LISTENERS'
export const ADD_UNSUBSCRIBE = 'ADD_UNSUBSCRIBE'
export const SET_MY_FARMS = 'SET_MY_FARMS'
export const SET_USER_LOCATION = 'SET_USER_LOCATION'
export const SET_AVAIL_ADDONS = 'SET_AVAIL_ADDONS'
export const SET_AVAIL_ADDONS_PURCHASES = 'SET_AVAIL_ADDONS_PURCHASES'
export const SET_AVAIL_ADDONS_CSAS = 'SET_AVAIL_ADDONS_CSAS'

// Constant data
export const SET_CERTIFICATIONS = 'SET_CERTIFICATIONS'
export const SET_CATEGORIES = 'SET_CATEGORIES'

//ADMIN
export const SET_ADMIN_OPEN = 'SET_ADMIN_OPEN'
export const SET_ADMIN_FARM = 'SET_ADMIN_FARM'
export const SET_FARM_LISTENER = 'SET_FARM_LISTENER'
export const SET_ADMIN_FARMID = 'SET_ADMIN_FARMID'
export const SET_ADMIN_PERMISSIONS = 'SET_ADMIN_PERMISSIONS'
export const SET_ADMIN_NAV = 'SET_ADMIN_NAV'
export const SET_IS_ADMIN = 'SET_IS_ADMIN'
export const SET_ADMIN_DISTROS = 'SET_ADMIN_DISTROS'
export const SET_ADMIN_LOCS = 'SET_ADMIN_LOCS'
export const SET_ADMIN_PRODUCTS = 'SET_ADMIN_PRODUCTS'
export const SET_ADMIN_FEATURES_AVAILABLE = 'SET_ADMIN_FEATURES_AVAILABLE'
export const SET_ADMIN_CSAS = 'SET_CSAS'
export const ADD_QUEUE_ALGOLIA_PRODUCT = 'ADD_QUEUE_ALGOLIA_PRODUCT'
export const REMOVE_QUEUE_ALGOLIA_PRODUCT = 'REMOVE_QUEUE_ALGOLIA_PRODUCT'

//Admin cart
export const UPDATE_CART_INFO_ORDER_CREATOR = 'UPDATE_CART_INFO_ORDER_CREATOR'
export const SET_CART_SERVICE_ORDER_CREATOR = 'SET_CART_SERVICE_ORDER_CREATOR'
export const SET_CUSTOMER_ORDER_CREATOR = 'SET_CUSTOMER_ORDER_CREATOR'
export const SET_ISWHOLESALE_ORDER_CREATOR = 'SET_ISWHOLESALE_ORDER_CREATOR'

// Order edit
export const SET_CART_SERVICE_ORDER_EDIT = 'SET_CART_SERVICE_ORDER_EDIT'
export const SET_CUSTOMER_ORDER_EDIT = 'SET_CUSTOMER_ORDER_EDIT'
