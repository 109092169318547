import { getApp, isSetApp } from '@api/db'
import { User } from '@models/User'
import {
  getAnalytics as getFbAppAnalytics,
  logEvent as logFBEvent,
  setUserId,
  setUserProperties,
} from 'firebase/analytics'

import { Logger } from './logger'

/** If firebase app has been initialized through the main initializer helper, it will return the analytics firebase module. Else null. */
export const getAnalytics = () => {
  if (isSetApp()) {
    return getFbAppAnalytics(getApp())
  }
  Logger.warn(new Error('Analytics module was requested but firebase was not yet initialized')) // This shouldn't happen
  return null
}

export function setUser(user: User) {
  const analytics = getAnalytics()
  if (!analytics) return
  setUserId(analytics, user.id)
  setUserProperties(analytics, {
    loginMethod: user.signInProvider || 'unknown',
    customerRef: user.customerRef,
  })
}

function setUserProperty(name: string, value: string) {
  const analytics = getAnalytics()
  if (!analytics) return
  return setUserProperties(analytics, { name, value })
}

function setCurrentScreen(screen?: string) {
  const analytics = getAnalytics()
  if (!analytics) return
  if (!screen) return
  return logFBEvent(analytics, 'screen_view', { firebase_screen: screen, firebase_screen_class: screen })
}

function logEvent(name: string, properties?: { [key: string]: any }) {
  const analytics = getAnalytics()
  if (!analytics) return
  return logFBEvent(analytics, name, properties)
}

export default {
  setUser,
  setUserProperty,
  setCurrentScreen,
  logEvent,
}
