import { loadDefaultCategories } from '@api/Categories'
import { loadCertifications } from '@api/Certifications'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setCategories, setCertifications } from '@/redux/actions/adminPersist'

/** This will fetch any constant data required by the app, and set it to context */
export function useConstantAppData() {
  const dispatch = useDispatch()
  useEffect(() => {
    // Load all certifications
    loadCertifications().then((certs) => {
      dispatch(setCertifications(certs))
    })
    loadDefaultCategories().then((cats) => {
      dispatch(setCategories(cats))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
