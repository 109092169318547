import { OrderProductType } from '@/admin/navigation/types'
import { Product, hasUnits, isShare } from '@models/Product'
import { ShareRow } from './ShareRow'
import { StandardRow } from './StandardRow'

export type ProductRowProps = { prod: Product; index: number; orderType: OrderProductType }

/** Takes a product and order type, and switches between the different row components for the order type */
export function ProductRow({ prod, index, orderType }: ProductRowProps) {
  if ((orderType === 'standard' || orderType === 'digital') && hasUnits(prod)) {
    return <StandardRow prod={prod} index={index} />
  } else if (orderType === 'share' && isShare(prod)) {
    return <ShareRow prod={prod} index={index} />
  }
  return null
}
