import { canUpdateQuantity, findItemInCart } from '@helpers/canUpdateQuantity'
import { extendErr } from '@helpers/helpers'
import { getUnits, isActive, isInStock, isOutofPickups, shouldShow } from '@helpers/products'
import { CartItem } from '@models/Order'
import { Product, Unit, isAddon, isShare } from '@models/Product'

import { CartService } from '../../constants/types/cartService'
import { Toast } from '../elements/Overlays'

import { Logger } from '@/config/logger'
import { productsCollection } from '@api/framework/ClientCollections'
import { getAvailAddonsFromCart } from '@helpers/addons'
import { refreshCartItemData } from '@helpers/cartItem'
import { AlgoliaGeoDoc, AlgoliaGeoProduct, isGeoDoc } from '@models/Algolia'

/** Makes a testID key for the addToCart Button.
 * @param u refers to a selected unit, which belongs to the product. This should only be used in the product detail screen, where the addCartBtn works on a specific unit
 */
export const makeTestIdPrefix = (p: Pick<Product, 'name' | 'id'>, u?: Unit) => {
  return `addCartBtn-${p.name}-${u ? u.id : 'noUnit'}-${p.id}`
}

/** This status text is shown on the button in place of the "+" sign in some circumstances */
export enum AddCartBtnStatus {
  inCart = 'In Cart',
  outOfStock = 'Out of Stock',
  unavailable = 'Not Available',
  available = 'Add to cart',
}

export const getAddCartBtnStatus = (
  prod: Product | AlgoliaGeoDoc<AlgoliaGeoProduct>,
  cart: CartItem[],
  /** If it's a unit product, a unit must be specified, to search for matching products in the cart */
  unitProp?: Pick<Unit, 'id'>,
  isWholesale?: boolean,
): AddCartBtnStatus => {
  const itmInCart = findItemInCart({ product: prod, unit: unitProp }, cart)

  return itmInCart
    ? AddCartBtnStatus.inCart
    : !isInStock(prod, { isWholesale })
    ? AddCartBtnStatus.outOfStock
    : isOutofPickups(prod, { useCache: true, isWholesale })
    ? AddCartBtnStatus.unavailable
    : AddCartBtnStatus.available
}

type DisableAddCartBtnOpts = {
  prod: Product | AlgoliaGeoDoc<AlgoliaGeoProduct>
  cart: CartService['cart']
  isWholesale: boolean | undefined
  mode: 'consumer' | 'admin'
  availAddonsIds?: string[]
  /** if unitProp is defined, it will only check this unit. else will check all units for subtypes of product that have units */
  unitProp?: Unit
  /** Whether the result of hasPickups() will be cached for this product */
  useCache?: boolean
}

/** Logic for disabling the addCartBtn. Disables either the main button or the stepper '+' sign, depending on whether the product is in cart.
 * - Note: When the product is from algolia, this won't always disable the button because it doesn't have the full product data. It can't check for cart quantities in algolia products because it needs the full product data for that. In the product details screen this will work with the full data so it is meant to be the more reliable details view, while the card is intended to be less precise because the card's priority is the algolia search speed. This is a known limitation, but it's a desirable tradeoff that allowed using algolia in the product card component.
 */
export const disableAddCartBtn = ({
  prod,
  cart,
  availAddonsIds,
  unitProp,
  mode,
  useCache,
  isWholesale,
}: DisableAddCartBtnOpts) => {
  if (isWholesale === undefined) return true

  const isActiveOpts: Parameters<typeof isActive>[1] = { useCache, isWholesale }
  isActiveOpts.useCache ??= false // If undefined, will assign useCache false

  if (mode === 'admin') {
    isActiveOpts['excludeClosedDistros'] = false
    isActiveOpts['ignoreOrderCutoffWindow'] = true
  } else {
    isActiveOpts['excludeClosedDistros'] = true
    isActiveOpts['ignoreOrderCutoffWindow'] = false
  }

  if (!isActive(prod, isActiveOpts)) return true

  if (mode === 'consumer' && isAddon(prod) && availAddonsIds && !availAddonsIds.includes(prod.id)) return true

  /** If it's a geo product, the rest of the checks can't be done because they involve complex quantities calculations, so we do not disable the button.
   * This is OK because the addToCart flow also has these checks for quantities. It's a good compromise because not fetching the db product allows using the algolia data directly in the UI, thereby making the app significantly faster.
   */
  if (isGeoDoc(prod)) return false

  // This section compares the product quantities with those already in the cart, to determine if more quantities can still be added or not
  const cartItem = getCartItemForAddCartBtn(prod, cart, unitProp)
  if (!cartItem) {
    // If the product is not in the cart, we just need to know if the button should be disabled before starting the addToCart flow.
    // In this case it's not necessary to pass the pickups array to the cartItem because it's a hypothetical cartItem
    if (isShare(prod)) {
      return !canUpdateQuantity({
        cartItem: {
          product: prod,
          quantity: 1,
        },
        cart,
        isWholesale,
      })
    }

    const prodUnitsForCatalog = getUnits(prod, { isWholesale })

    // If it's a unit product, and there's no buying options, it should be disabled
    if (!prodUnitsForCatalog.length) return true

    if (unitProp) {
      // If unitProp exists, check for that one only

      return !canUpdateQuantity({
        cartItem: { product: prod, quantity: 1, unit: unitProp },
        cart,
        isWholesale,
      })
    } else {
      // If no unit specified, disable only if all units can't be added

      return prodUnitsForCatalog.every((unit) => {
        const cartItem = getCartItemForAddCartBtn(prod, cart, unit)

        if (cartItem) {
          const freshItem = refreshCartItemData(cartItem, prod)

          return !canUpdateQuantity({ cartItem: freshItem, cart, delta: 1, isWholesale })
        }

        return !canUpdateQuantity({ cartItem: { product: prod, quantity: 1, unit }, cart, isWholesale })
      })
    }
  } else {
    // (The item exists in the cart)

    const freshItem = refreshCartItemData(cartItem, prod)

    // Disable if the cartItem can't add more quantities given its existing quantities in the cart, which may come from a combination of unit multiplier,  item quantity, and pickups/ deliveries
    return !canUpdateQuantity({ cartItem: freshItem, cart, delta: 1, isWholesale })
  }
}

/**
 * Tries to find the product in the cart for the purpose of passing it to the AddCartBtn. If passed, the button will show the Stepper instead of the add button.
 * @param unitProp specifies in advance which unit to add
 */
export const getCartItemForAddCartBtn = (
  product: Product | AlgoliaGeoDoc<AlgoliaGeoProduct>,
  cart: CartService['cart'],
  unitProp?: Unit,
): CartItem | undefined => {
  if (unitProp) return findItemInCart({ product, unit: unitProp }, cart)

  // Will find the items that match the product id.
  const matches = cart.filter((itm) => itm.product.id === product.id)

  if (matches.length === 1) {
    // If a single match is found, it will be returned. This allows a single unit in cart to show the stepper in the product cards and in the product detail screen
    return matches[0]
  } else {
    /** If more than one match is found, none will be returned. This will result in showing the addCart button instead of the stepper.
    Scenarios: 
    If the matches are zero, it must show the addCart button regardless
    If the matches are greater than one, it makes no sense to show the stepper because the stepper can only be used for a single BO at a time. 
    So by showing the addCart button the user can initiate the addToCart flow and choose a different option. 
    In this case if the option they select is already in the cart, the expected behavior is the quantity should be increased. */
    return undefined
  }
}

/** Used by the Stepper, to increase or decrease quantity. Validates for stock
 * @param unitProp See getCartItemForAddCartBtn
 */
export const makeUpdateQuantity = (
  product: Product | AlgoliaGeoDoc<AlgoliaGeoProduct>,
  cart: CartService['cart'],
  updateQuantityService: CartService['updateQuantity'],
  isWholesale?: boolean,
  unitProp?: Unit,
): ((delta: number) => Promise<void>) =>
  async function updateQuantityAdapter(delta: number) {
    const cartItem = getCartItemForAddCartBtn(product, cart, unitProp)
    if (!cartItem) return

    const updatedCartItem = refreshCartItemData(cartItem, product)

    if (delta < 0 || canUpdateQuantity({ cartItem: updatedCartItem, delta, cart, isWholesale })) {
      await updateQuantityService(cartItem.id, cartItem.quantity + delta)
    }
    if (canUpdateQuantity({ cartItem: updatedCartItem, delta: delta + 1, cart, isWholesale }) === false) {
      Toast('You reached the max quantity available')
    }
  }

/** Check if suggested products modal should show after adding a product to cart.
 * - It should consider all possible cases for whether the modal should be shown, including fetching and checking there's at least one valid product.
 * - This should prevent opening the modal in the first place if there's not at least 1 valid suggestion
 */
export const shouldShowSuggested = async (
  /** the product added to cart before this modal might be shown */
  { product, csa }: CartItem,
  /** the list of items in the cart. IMPORTANT: Assumes this list already includes the item that just got added to the cart */
  cart: CartItem[],
  /** the ids for available addons. It is assumed to reflect the availability before the product got added (I.e. it's outdated) */
  availAddonsIds: string[],
  isWholesale?: boolean,
): Promise<{
  shouldShow: boolean
  prods: Product[]
}> => {
  if (!csa || !csa.suggestedProducts?.length) return { shouldShow: false, prods: [] }

  // Allow only if the added product is a share
  if (!isShare(product)) return { shouldShow: false, prods: [] }

  // Do not show if the added product is a suggested product for this csa
  if (csa.suggestedProducts.some((prodId) => prodId === product.id)) return { shouldShow: false, prods: [] }

  // Do not show if there is already a suggested product in cart
  if (cart.some((ci) => csa.suggestedProducts!.includes(ci.product.id))) return { shouldShow: false, prods: [] }

  try {
    let suggestedProds = await productsCollection.fetchByIds(csa.suggestedProducts)

    suggestedProds = suggestedProds
      .filter((p: Product) => {
        if (!isAddon(p)) return true
        if (availAddonsIds.includes(p.id)) return true

        /** If a suggested product is an addon not included in the availAddonsIds list, run the availability calculation on that addon, against the updated cart (The cart must include the item recently added) */
        return getAvailAddonsFromCart(cart, [p])[0].isAvail
      })
      .filter(
        (p) =>
          !isOutofPickups(p, { excludeClosedDistros: true, excludeHiddenDistros: true, isWholesale }) &&
          shouldShow(p, {
            isWholesale,
          }),
      )

    return {
      shouldShow: !!suggestedProds.length,
      prods: suggestedProds,
    }
  } catch (err) {
    Logger.error(extendErr(err, 'Error while fetching suggested products'))
    return { shouldShow: false, prods: [] }
  }
}
