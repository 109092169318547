import { Farm } from '@models/Farm'
import { Role, User } from '@models/User'

import { MarkEmailVerifiedRequest, VerifyEmailResult } from '@shared/types/v2/user'
import { AccountSetup, InviteType, ResetRequest, UserInvite } from '../models/ExternalLink'
import { dateTimeInZone } from '../models/Timezone'
import { writeFarmAssociationRole } from './FarmAssociations'
import { externalLinksCollection, farmAssociationsCollection } from './framework/ClientCollections'
import { callEndpoint } from './v2'

/** Creates an invite that will expire in 48 hours */
export async function inviteFarmUser(
  farm: Farm,
  email: string,
  user: User,
  type: InviteType,
  role = Role.Prospect as Role,
) {
  const now = dateTimeInZone()
  return externalLinksCollection.create({
    id: '',
    type,
    user: {
      email,
    },
    admin: {
      id: user.id,
      name: user.name,
    },
    role,
    expireDate: now.plus({ days: 30 }),
    farm: {
      id: farm.id,
      name: farm.name,
    },
  } as UserInvite)
}

/** Loads an invite and make sure it is not expired */
export async function loadFarmUserInvite(id: string) {
  return externalLinksCollection.fetch(id)
}

/** Loads an invite and make sure it is not expired */
export async function acceptFarmUserInvite(userId: string, invite: UserInvite) {
  externalLinksCollection.delete(invite.id)

  if (invite.type === InviteType.Admin) {
    return writeFarmAssociationRole(invite.farm.id, userId, invite.role, 'create')
  } else {
    return farmAssociationsCollection.resolve(userId).createWithId(
      {
        id: invite.farm.id,
        farmId: invite.farm.id,
        isFavorite: true,
        name: invite.farm.name,
        /** If invite is adminInvite, the role will be specific team role. If invite is customerInvite, the role will be Role.Prospect */
        role: invite.role,
        farmMessages: { email: true, pushNotification: true, text: true },
      },
      { merge: true },
    )
  }
}

/**
 * Creates an password reset request that expires in 48 hours or specified expiration time
 * @param email The email of the user to reset the password for
 * @param type The type accepted is 'reset-request' or 'account-setup'
 * */
export async function createResetPassword(
  email: string,
  type: AccountSetup['type'] | ResetRequest['type'] = 'reset-request',
) {
  const now = dateTimeInZone()

  externalLinksCollection.create({
    id: '',
    type,
    user: {
      // The reason to put lowercase here is that reset emails are case-sensitive
      email: email.toLowerCase(),
    },
    expireDate: now.plus({ days: 30 }),
  })
}

/** Calls a cloud function to update the users password */
export async function resetPasswordSubmit(request: ResetRequest | AccountSetup, newPassword: string) {
  // Handle reset server side so that we can verify the resetId
  return await callEndpoint('v2.User.resetPassword', {
    email: request.user.email,
    newPassword,
    resetId: request.id,
  })
}

/**
 * createVerifyEmail creates an email verification request that expires in 48 hours or specified expiration time
 * @param email The email of the user to reset the password for
 * @param userId The id of the user to reset the password for
 * */
export async function createVerifyEmail(email: string, userId: string) {
  const now = dateTimeInZone()

  return await externalLinksCollection.create({
    id: '',
    type: 'verify-email',
    user: {
      // The reason to put lowercase here is that reset emails are case-sensitive
      email: email.toLowerCase(),
      id: userId,
    },
    expireDate: now.plus({ days: 30 }),
  })
}

/** Loads an verifyEmail and make sure it is not expired */
export async function loadVerifyEmail(id: string) {
  return await externalLinksCollection.fetch(id)
}

/** process verifying email */
export async function processVerifyingEmail({
  email,
  userId,
  externalId,
}: MarkEmailVerifiedRequest): Promise<VerifyEmailResult> {
  return await callEndpoint('v2.User.markEmailVerifiedService', {
    email,
    userId,
    externalId,
  })
}
