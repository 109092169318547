import { ToolTips } from '@components'
import { Button, HeaderText, RowWrapper, Text, Tooltip } from '@elements'
import { openUrl } from '@helpers/client'
import { formatInvoiceNum, formatMoney, getOrderNum, InvalidAmount } from '@helpers/display'
import { InvoiceFilters, openDetailInvoicesCSV, openSummaryInvoicesCSV } from '@helpers/links'
import { format } from '@helpers/time'
import { AlgoliaAdminDoc, AlgoliaAdminInvoice, AlgoliaDocType } from '@models/Algolia'
import { Farm } from '@models/Farm'
import { DisplayInvStatus, displayInvStatus, InvoiceStatus } from '@models/Invoice'
import { dateTimeInZone } from '@models/Timezone'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { DateTime } from 'luxon'
import React, { useMemo, useState } from 'react'
import { RefinementListProvided, StateResultsProvided } from 'react-instantsearch-core'
import { connectRefinementList, connectStateResults } from 'react-instantsearch-native'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import { AdminTable } from '../../components/AdminTable/AdminTable'
import { AdminView } from '../../components/AdminView'
import CopyInvoiceUrl from './components/CopyInvoiceUrl'
import InvoiceAlgoliaActionWrapper from './components/InvoiceActionWrapper'

import { defaultRefinementLimit } from '@/admin/components/AdminTable/ConnectedRefinementDropdown'
import Export from '@/admin/components/Export'
import { AdminDrawerParamList } from '@/admin/navigation/types'
import { grownbyWebsiteBaseUrl } from '@/config/Environment'
import Colors from '@/constants/Colors'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useFocusFx } from '@/hooks/useFocusFx'
import { withAdminAuth } from '@/hooks/withAdminAuth'
import { withAdminIndex } from '@/hooks/withAlgoliaIndex'
import { RootState } from '@/redux/reducers/types'
import { adminFarmSelector, wholesaleSelector } from '@/redux/selectors'
import { Permission } from '@helpers/Permission'
import { isArray } from '@helpers/helpers'
import { globalStyles } from '../../../constants/Styles'
import { formatPaymentMethod } from '@helpers/reports'
import { PaymentForms } from '@models/PaymentMethod'

function InvoicesScreen() {
  const farm = useSelector<RootState, Farm>(adminFarmSelector)
  const { isWholesale } = useSelector(wholesaleSelector)
  const [statusFilterShowMore, setStatusFilterShowMore] = useState(false)
  const [paymentMethodsFilterShowMore, setPaymentMethodsFilterShowMore] = useState(false)
  const { params } = useRoute<RouteProp<AdminDrawerParamList, 'Invoices'>>()
  const now = dateTimeInZone(farm.timezone)
  const styles = useStyles()

  /** refinements are changed dynamically based on only viewing "pastDue invoices" or "all invoices" */
  const refinements = useMemo(() => {
    if (params?.pastDue) {
      return [
        {
          attribute: 'paymentMethods',
          placeholder: 'All methods',
          limit: defaultRefinementLimit,
          showMoreLimit: defaultRefinementLimit * 3,
          showMore: paymentMethodsFilterShowMore,
          setShowMore: setPaymentMethodsFilterShowMore,
        },
      ]
    } else {
      return [
        {
          attribute: 'status',
          placeholder: 'All statuses',
          limit: defaultRefinementLimit,
          showMoreLimit: defaultRefinementLimit * 3,
          showMore: statusFilterShowMore,
          setShowMore: setStatusFilterShowMore,
        },
        {
          attribute: 'paymentMethods',
          placeholder: 'All methods',
          limit: defaultRefinementLimit,
          showMoreLimit: defaultRefinementLimit * 3,
          showMore: paymentMethodsFilterShowMore,
          setShowMore: setPaymentMethodsFilterShowMore,
        },
      ]
    }
  }, [params?.pastDue, paymentMethodsFilterShowMore, statusFilterShowMore])

  return (
    <AdminView>
      <View style={styles.wrapper}>
        <View>
          <View style={globalStyles.flexRowCenter}>
            <HeaderText size={30}>Invoices</HeaderText>
            <Tooltip size={15} title="Invoices" id={ToolTips.INVOICES} />
          </View>
          <View style={styles.buttonContainer}>
            <RowWrapper>
              <ExportSummaryCSV />
              <ExportDetailCSV />
              <ViewAllPastDueButtonRefined attribute="status" />
            </RowWrapper>
          </View>
        </View>
        <AdminTable<AlgoliaAdminInvoice>
          type={AlgoliaDocType.INVOICE}
          refinements={refinements}
          disableFutureDates={false}
          dateRange={{
            timezone: farm.timezone,
            attribute: 'date',
          }}
          columns={[
            {
              title: 'Invoice Number',
              key: 'invoiceNum',
              process: (inv) => (
                <Text
                  style={styles.link}
                  onPress={() =>
                    // inv.pdf not null means that there's a stripe invoice available.
                    // Algolia Invoice doesn't have stripe invoice ref, so navigate to stripe invoices
                    openUrl(
                      inv.pdf
                        ? `https://dashboard.stripe.com/${farm.accountRef}/invoices/`
                        : `${grownbyWebsiteBaseUrl(isWholesale)}external/invoice/${inv.id}`,
                    )
                  }
                >
                  {inv.invoiceNum ? formatInvoiceNum(inv.invoiceNum) : InvalidAmount}
                </Text>
              ),
            },
            {
              title: 'Order Number',
              // @ts-expect-error, key here should be 'order.number' but just 'orderNum' is used
              key: 'orderNum',
              itemPressUrl: (inv) =>
                typeof inv.order.orderNum === 'number' ? `/admin/orders/view/${inv.order.id}` : undefined,
              process: (inv) =>
                typeof inv.order.orderNum === 'number' ? getOrderNum(inv.order.orderNum) : InvalidAmount,
            },
            {
              title: 'Name',
              // @ts-expect-error, key here should be 'user.name' but just 'name' is used
              key: 'name',
              itemPressUrl: (inv) => `/admin/customers/${inv.user.id}`,
              process: (inv) => inv.user.name,
            },
            {
              title: 'Amount',
              key: 'amountDue',
              process: (inv) => formatMoney(inv.amountDue || 0),
            },
            {
              title: 'Due Date',
              key: 'date',
              process: (inv) => format(DateTime.fromMillis(inv.date).setZone(farm.timezone), 'M/d/yy'),
            },
            {
              title: 'Method',
              key: 'paymentMethods',
              process: (inv) => displayPaymentMethod(inv),
            },
            {
              title: 'Status',
              key: 'status',
              process: (inv) => displayInvStatusFromAgolia(inv, now),
            },
            {
              title: 'Action',
              key: 'status',
              process: (inv) => {
                return <InvoiceAlgoliaActionWrapper inv={inv} />
              },
            },
            {
              title: 'Link',
              key: 'status',
              process: (inv) => <CopyInvoiceUrl invoice={inv} />,
            },
          ]}
          searchPlaceholder="Invoice Number or Customer"
        />
      </View>
    </AdminView>
  )
}
export default withAdminAuth(
  withAdminIndex(InvoicesScreen, AlgoliaDocType.INVOICE) as React.ComponentType,
  Permission.Orders,
)

const useStyles = () =>
  useSizeFnStyles(({ isSmallDevice }) => ({
    wrapper: {
      marginHorizontal: isSmallDevice ? 10 : 30,
      marginTop: isSmallDevice ? 10 : 30,
    },
    buttonContainer: {
      marginTop: 10,
    },
    btnWrapper: {
      marginTop: 5,
      marginRight: 5,
    },
    link: {
      color: Colors.blue,
    },
  }))

/** connect to Summary Invoice CSV EXCEL document */
const ExportSummaryCSV = connectStateResults(
  ({ searchState }: StateResultsProvided<AlgoliaAdminInvoice & AlgoliaAdminDoc>) => {
    const farm = useSelector<RootState, Farm>(adminFarmSelector)
    //default range is last 30 days
    const now = dateTimeInZone(farm.timezone)
    const defaultMinDate = now.minus({ months: 3 }).startOf('day')
    const defaultMaxDate = now

    let searchQuery: string | undefined
    const statusRefinementListArray = searchState.refinementList?.status
    const filters: InvoiceFilters = {
      paymentMethod: searchState.menu?.['paymentMethods'],
      status:
        searchState.menu?.['status'] || (isArray(statusRefinementListArray) ? statusRefinementListArray.join(',') : ''),
      dateRange: {
        min: searchState.range?.date.min ?? defaultMinDate.toMillis(),
        max: searchState.range?.date.max ?? defaultMaxDate.toMillis(),
      },
    }

    if (searchState.query) {
      searchQuery = searchState.query
    }
    return (
      <Export
        title="Export Summary CSV"
        onPress={() => {
          openSummaryInvoicesCSV(farm.id, filters, searchQuery)
        }}
      />
    )
  },
)

/** connect to Detail Invoice CSV EXCEL document */
const ExportDetailCSV = connectStateResults(
  ({ searchState }: StateResultsProvided<AlgoliaAdminInvoice & AlgoliaAdminDoc>) => {
    const farm = useSelector<RootState, Farm>(adminFarmSelector)
    //default range is last 30 days
    const now = dateTimeInZone(farm.timezone)
    const defaultMinDate = now.minus({ months: 3 }).startOf('day')
    const defaultMaxDate = now

    let searchQuery: string | undefined
    const statusRefinementListArray = searchState.refinementList?.status
    const filters: InvoiceFilters = {
      paymentMethod: searchState.menu?.['paymentMethods'],
      status:
        searchState.menu?.['status'] || (isArray(statusRefinementListArray) ? statusRefinementListArray.join(',') : ''),
      dateRange: {
        min: searchState.range?.date.min ?? defaultMinDate.toMillis(),
        max: searchState.range?.date.max ?? defaultMaxDate.toMillis(),
      },
    }

    if (searchState.query) {
      searchQuery = searchState.query
    }
    return (
      <Export
        title="Export Detail CSV"
        onPress={() => {
          openDetailInvoicesCSV(farm.id, filters, searchQuery)
        }}
      />
    )
  },
)

/**
 * This will show the all past due invoices if the farmer clicks the button
 * When all past due button is clicked, it will set the params to pastDue: true
 * So, when the farmer navigate to ViewInvoice and then back to Invoices, it will
 * keep and show all past due invoices instead of all invoices.
 * When the farmer clicks the button again, it will set the params to pastDue: undefined
 */
function ViewAllPastDueButton({ refine }: RefinementListProvided) {
  const [viewAllPastDue, setViewAllPastDue] = useState<boolean>(false)
  const navigation = useNavigation<StackNavigationProp<AdminDrawerParamList, 'Invoices'>>()
  const { params } = useRoute<RouteProp<AdminDrawerParamList, 'Invoices'>>()

  useFocusFx(() => {
    if (params?.pastDue) {
      refine([InvoiceStatus.Due, InvoiceStatus.Failed])
      setViewAllPastDue(true)
    }
  }, [params?.pastDue, refine])

  return (
    <Button
      small
      title={viewAllPastDue ? 'View All Invoices' : 'View All Past Due'}
      onPress={() => {
        if (viewAllPastDue) {
          refine([])
          navigation.setParams({ pastDue: undefined })
          setViewAllPastDue(false)
        } else {
          refine([InvoiceStatus.Due, InvoiceStatus.Failed, InvoiceStatus.Incomplete])
          navigation.setParams({ pastDue: true })
          setViewAllPastDue(true)
        }
      }}
    />
  )
}

const ViewAllPastDueButtonRefined = connectRefinementList(ViewAllPastDueButton)

/** This function will only be used here to mutate the PaymentMethod when displaying data from Algolia Invoice */
function displayPaymentMethod(inv: AlgoliaAdminInvoice): string {
  const paymentMethods = (inv.paymentMethodsDisplay || inv.paymentMethods).join(', ')
  if ((paymentMethods === 'Offline' || paymentMethods === 'Cash') && inv.status === 'Due') return ''
  else return paymentMethods
}

/** This function will only be used here to mutate the Status when displaying data from Algolia Invoice */
function displayInvStatusFromAgolia(inv: AlgoliaAdminInvoice, currTime: DateTime): DisplayInvStatus {
  const dueDate = DateTime.fromMillis(inv.date).setZone(currTime.zone)

  if (inv.status === InvoiceStatus.Incomplete && inv.paymentMethods.includes(formatPaymentMethod(PaymentForms.BANK))) {
    return `Pending (ACH)`
  } else if (
    inv.status === InvoiceStatus.Incomplete &&
    inv.paymentMethods.includes(formatPaymentMethod(PaymentForms.CARD))
  ) {
    return 'Pending (3D)'
  } else return displayInvStatus({ status: inv.status, dueDate })
}
