import { omit } from '@helpers/typescript'
import { Notification } from '@models/Notification'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { marshalDate, unmarshalDate } from './Time'
import { prepareMarshal, prepareUnmarshal } from './encoding'

/** marshalNotification transforms a notification into the Firestore storage structure. */
export function marshalNotification(notification: Partial<Notification>): DocumentData {
  const data = omit(prepareMarshal(notification), 'id', 'date') as DocumentData

  if (notification.sendAt) {
    data.sendAt = marshalDate(notification.sendAt)
  }

  if (notification.date) {
    data.date = marshalDate(notification.date)
  }

  if (notification.badgeCount === undefined) {
    delete data.badgeCount
  }

  return data
}

/** unmarshalNotification transforms a notification document to a Notification structure. */
export function unmarshalNotification(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): Notification {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)

  const notification = { ...data, id } as Notification

  if (typeof data.isRead === 'undefined') {
    notification.isRead = false
  }
  if (typeof data.isDelivered === 'undefined') {
    notification.isDelivered = false
  }
  if (data.sendAt) {
    notification.sendAt = unmarshalDate(data.sendAt)
  }
  if (data.date) {
    notification.date = unmarshalDate(data.date)
  }

  return notification
}
