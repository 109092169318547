import { useRef, useState } from 'react'

// useLoadingState provides state management for loading state with tracking across multiple requests. The hook
// returns the current loading state, a start loading function, and a stop loading function. The start loading
// function may be called multiple times. A stop loading function call must follow each start loading call.
export function useLoadingState(): [boolean, () => void, () => void] {
  const [isLoading, setIsLoading] = useState(false)
  const loadCount = useRef(0)

  function startLoading(): void {
    if (loadCount.current === 0) {
      setIsLoading(true)
    }
    loadCount.current++
  }

  function stopLoading(): void {
    loadCount.current--
    if (loadCount.current === 0) {
      setIsLoading(false)
    }
  }

  return [isLoading, startLoading, stopLoading]
}
