import Colors from '@/constants/Colors'
import { PickupItemStatus } from '@models/Order'
import React from 'react'
import { View } from 'react-native'
import { Icon, IconProps } from 'react-native-elements'

type Props = {
  status: PickupItemStatus
} & Omit<IconProps, 'type' | 'name'>

/** Icon which represents a PickupItemStatus */
export function StatusIcon({ status, ...props }: Props) {
  if (status === PickupItemStatus.Active) return <View />
  return <Icon {...getIconData(status)} {...props} />
}

const getIconData = (status: PickupItemStatus): Pick<IconProps, 'type' | 'name' | 'color'> => {
  switch (status) {
    case PickupItemStatus.Received:
      return { name: 'check-circle', type: 'material', color: Colors.green }

    case PickupItemStatus.Vacation:
      return { name: 'umbrella-beach', type: 'material-community', color: Colors.green }

    case PickupItemStatus.Donated:
      return { name: 'favorite', color: Colors.shades[200] }

    case PickupItemStatus.Cancelled:
      return { name: 'closecircle', type: 'antdesign', color: Colors.shades['200'] }
    case PickupItemStatus.Missed:
    case PickupItemStatus.Active:
      return { name: 'check-circle', color: Colors.shades[200] }
  }
}
