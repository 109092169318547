import { productsCollection } from '@api/framework/ClientCollections'
import { overWriteProduct } from '@api/Products'
import DecimalCalc from '@helpers/decimal'
import { Money } from '@models/Money'
import { hasUnitStock, StockType, UnitBase, UnitStandard } from '@models/Product'

/** Updates the price per unit of a UnitStandard */
export const savePricePerUnit = async (newUnitPrice: Money, prodId: string) => {
  const prod = await productsCollection.fetch(prodId)
  if (!hasUnitStock(prod)) throw new Error('Price per unit is only for UnitStock products')

  const updatedProduct: UnitStandard = {
    ...prod,
    pricePerUnit: newUnitPrice,
  }

  //Must also update the amounts of each unit price, which are derived from the pricePerUnit
  updatedProduct.units = updatedProduct.units.map(
    (unit): UnitBase<StockType.Unit> => ({
      ...unit,
      prices: unit.prices.map((p) => ({
        ...p,
        amount: { ...p.amount, value: DecimalCalc.multiply(newUnitPrice.value, unit.multiplier) },
      })),
    }),
  )
  return overWriteProduct(updatedProduct)
}
