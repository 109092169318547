import { ErrorText, hideModal, Text } from '@elements'
import { useState } from 'react'
import { ScrollView, StyleSheet, TextInput, View } from 'react-native'
import { InputProps } from 'react-native-elements'

import Colors from '../../../constants/Colors'
import { Button } from '../Button'

import { Logger } from '@/config/logger'
import { globalStyles } from '@/constants/Styles'

export type PromptComponentProps = {
  /** A message to show above the input */
  message?: string
  /** handler that receives the current input value */
  onSubmit: (inputValue: string) => void | Promise<void>
  /** Props for the Input component. */
  inputProps?: Omit<InputProps, 'onChangeText'>
  /** Whether the prompt should hide after successfully calling onSubmit. Default true */
  hideOnSuccess?: boolean
}

/** Prompt component used by the Prompt() helper in Popups.tsx
 * - This is not meant to be used standalone. But rather through modals implementation.
 */
export function PromptComponent({ message, onSubmit, inputProps, hideOnSuccess = true }: PromptComponentProps) {
  const [inputValue, setInputValue] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  return (
    <ScrollView contentContainerStyle={styles.padding10}>
      <Text style={globalStyles.margin10}>{message}</Text>
      <TextInput
        {...inputProps}
        placeholderTextColor={Colors.shades[200]}
        style={[styles.textInputStyle, globalStyles.margin10, inputProps?.style]}
        onChangeText={(text) => setInputValue(text)}
      />
      <View style={styles.btnWrapper}>
        <Button outline color={Colors.green} title="Cancel" onPress={hideModal} />
        <Button
          color={Colors.green}
          title="Save"
          onPress={async () => {
            setLoading(true)
            try {
              await onSubmit?.(inputValue)
              if (hideOnSuccess) hideModal()
            } catch (error) {
              Logger.error(error)
              setError('There was an error while saving. Please try again')
            }
            setLoading(false)
          }}
          loading={loading}
        />
      </View>
      {!!error && <ErrorText style={globalStyles.margin10}>{error}</ErrorText>}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  btnWrapper: { flexDirection: 'row', justifyContent: 'flex-end', flexWrap: 'wrap' },
  padding10: {
    padding: 10,
  },
  textInputStyle: {
    borderWidth: 1,
    borderRadius: 10,
    borderColor: Colors.shades[200],
    height: 50,
    color: Colors.shades[600],
    fontSize: 16,
    padding: 10,
    marginRight: -5,
  },
})
