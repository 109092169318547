import { isJest } from '@helpers/Platform'
import mergeDeep from '@helpers/mergeDeep'
import { omit } from '@helpers/typescript'
import { DeepPartial, PreloadedState } from 'redux'

export const getPreloadedState = <T extends Record<any, any>>(
  defaultState: T,
  overwritePersist = false,
  preloadedState: DeepPartial<T> = {},
): PreloadedState<T> | undefined => {
  if (preloadedState && overwritePersist) return mergeDeep(defaultState, preloadedState) as PreloadedState<T>
  else if (overwritePersist) return defaultState as PreloadedState<T>
  else if (isJest) return omit(defaultState, '_persist') as PreloadedState<T>
  else return undefined
}
