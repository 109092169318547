import { withAuth } from '../../../hooks/withAuth'
import { CheckoutUi } from './CheckoutUi'
import { CheckoutContext, useCheckoutData } from './useCheckoutData'

function CheckoutComp() {
  const checkoutData = useCheckoutData()

  return (
    <CheckoutContext.Provider value={checkoutData}>
      <CheckoutUi />
    </CheckoutContext.Provider>
  )
}
export const Checkout = withAuth(CheckoutComp, { noSafeAreaInsets: true })
