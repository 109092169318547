import { isAchPayment, isCashPayment, isCreditPayment, isEbtPayment, PaymentMethod } from '@models/PaymentMethod'
import { format, isValid } from 'date-fns'
import { useEffect, useState } from 'react'

import { FontAwesome5IconName } from '@elements'
import Colors from '../../constants/Colors'
import { PaymentButtonBase, PaymentButtonBaseProps } from './PaymentButtonBase'

const PaymentIcons: Record<string, FontAwesome5IconName> = {
  bank_account: 'university',
  visa: 'cc-visa',
  mastercard: 'cc-mastercard',
  discover: 'cc-discover',
  'American Express': 'cc-amex',
  amex: 'cc-amex',
  'Diners Club': 'cc-diners-club',
  jcb: 'cc-jcb',
  unknown: 'credit-card',
}
const PaymentColors: Record<string, string> = {
  bank_account: Colors.shades[500],
  visa: '#1A1F71',
  mastercard: '#EB001B',
  discover: '#E55C20',
}

export const getPaymentIcon = (pay: PaymentMethod) => {
  if (isCreditPayment(pay)) return PaymentIcons[pay.card_type] || 'credit-card'
  if (isAchPayment(pay)) return 'university'
  return PaymentIcons[pay.type] || 'credit-card'
}
export const getPaymentColor = (pay: PaymentMethod) => {
  if (isCreditPayment(pay)) return PaymentColors[pay.card_type] || Colors.shades[500]
  if (isAchPayment(pay)) return Colors.shades[500]
  return PaymentColors[pay.type] || Colors.shades[500]
}

const getPaymentText = (pay: PaymentMethod) => {
  if (isCreditPayment(pay) || isEbtPayment(pay)) {
    return `*${pay.last4}`
  }
  if (isAchPayment(pay)) {
    return `${pay.bank_name} *${pay.last4}`
  }
  return 'Pay Offline'
}

type Props = Pick<PaymentButtonBaseProps, 'style' | 'isEditable'> & {
  payment: PaymentMethod
  isSelected: boolean
  onPress(pmt: PaymentMethod): void
  disableText?: string
  /** If set, this will override the default button text */
  customTitle?: string
}

/** This is the payment button that allows users to select it */
export function PaymentButton({ payment, onPress, isSelected, style, disableText, isEditable, customTitle }: Props) {
  const [expire, setExpire] = useState<Date>(new Date())
  const iconRight = disableText ? undefined : isSelected ? 'check-square' : 'square'
  const iconRightSolid = iconRight === 'check-square'

  useEffect(() => {
    if (!payment) return
    if (isCreditPayment(payment) && payment.expiration) {
      // Months in JS are 0 indexed, so we need to subtract one
      setExpire(new Date(payment.expiration.year, payment.expiration.month - 1))
    }
  }, [payment])

  if (isCreditPayment(payment)) {
    const subtitle = disableText ?? (isValid(expire) ? `expires ${format(expire, 'MM/yyyy')}` : undefined)

    return (
      <PaymentButtonBase
        title={customTitle || getPaymentText(payment)}
        subtitle={subtitle}
        iconRight={iconRight}
        iconRightSolid={iconRightSolid}
        iconLeft={getPaymentIcon(payment)}
        iconLeftColor={getPaymentColor(payment)}
        onPress={() => onPress(payment)}
        isDisabled={!!disableText}
        style={style}
        isEditable={isEditable}
      />
    )
  }
  if (isAchPayment(payment)) {
    const subtitle = disableText ?? payment.account_type

    return (
      <PaymentButtonBase
        title={customTitle || getPaymentText(payment)}
        subtitle={subtitle}
        iconRight={iconRight}
        iconRightSolid={iconRightSolid}
        iconLeft={getPaymentIcon(payment)}
        iconLeftColor={getPaymentColor(payment)}
        onPress={() => onPress(payment)}
        isDisabled={!!disableText}
        style={style}
        isEditable={isEditable}
      />
    )
  }
  if (isCashPayment(payment)) {
    const subtitle = disableText ?? undefined
    return (
      <PaymentButtonBase
        title={customTitle || getPaymentText(payment)}
        subtitle={subtitle}
        iconRight={iconRight}
        iconLeft="money-bill-alt"
        iconLeftColor={Colors.green}
        onPress={() => onPress(payment)}
        isDisabled={!!disableText}
        style={style}
        isEditable={isEditable}
      />
    )
  }
  if (isEbtPayment(payment)) {
    const subtitle = disableText ?? undefined
    return (
      <PaymentButtonBase
        title={customTitle || getPaymentText(payment)}
        subtitle={subtitle}
        iconRight={iconRight}
        imageLeft={require('../../assets/images/icons/ebt-card.png')}
        onPress={() => onPress(payment)}
        isDisabled={!!disableText}
        style={style}
        isEditable={isEditable}
      />
    )
  }
  // This should never happen because we will only allow Cash and Credit for this button
  return null
}
