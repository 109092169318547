import { StyleSheet } from 'react-native'

export const sharedStyles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
    paddingVertical: 20,
    maxWidth: '90%',
    width: 800,
    alignSelf: 'center',
  },
  wrapperNoAlign: {
    paddingVertical: 20,
    maxWidth: '90%',
    width: 800,
    alignSelf: 'center',
  },
})
