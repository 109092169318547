import { Portal as DefaultPortal, usePortal } from '@gorhom/portal'
import { makeHandle } from '@helpers/helpers'
import { PropsWithChildren, useEffect } from 'react'
import { Platform } from 'react-native'
import { FullWindowOverlay } from 'react-native-screens'

export type PortalProps = Parameters<typeof DefaultPortal>[0]

/**
 * A component to implement a component-specific portal. https://github.com/gorhom/react-native-portal#simple-portal
 *
 * - Use this instead of the default portal component.
 * - This is intended to be used in a situation where you want to create a non-global portal instance. For most purposes the global portal should be used, with the portal helpers.
 * - For usage example, see useAutoComplete
 */
export function Portal({ children, ...portalProps }: PortalProps): JSX.Element {
  return (
    <DefaultPortal {...portalProps}>
      {/*
        https://github.com/gorhom/react-native-portal#react-native-screens-integration
        https://github.com/gorhom/react-native-portal/issues/35
      */}
      {Platform.OS === 'ios' ? <FullWindowOverlay>{children}</FullWindowOverlay> : children}
    </DefaultPortal>
  )
}

export const [getPortalHelpers, setPortalHelpers, isSetPortalHelpers] =
  makeHandle<ReturnType<typeof usePortal>>('portalHelpers')

/** Virtual component that makes portal helpers accessible anywhere through global context.
 * - Normally portal helpers would only be available inside components, through usePortal hook. This makes them available to global helpers such as Modal() by using handles
 */
export function PortalSetter({ children }: PropsWithChildren) {
  const portalHelpers = usePortal()

  useEffect(() => {
    // This must be set before rendering the children
    setPortalHelpers(portalHelpers)
  }, [portalHelpers])

  if (!isSetPortalHelpers()) {
    return null
  }

  return children
}
