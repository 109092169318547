import { addDistribution, canDistributionBeDeleted } from '@api/Distributions'
import { distrosCollection } from '@api/framework/ClientCollections'
import { Alert, Loader, Toast } from '@elements'
import { deepClone } from '@helpers/helpers'
import { Distribution } from '@models/Distribution'
import { StackNavigationProp } from '@react-navigation/stack'
import { Dispatch } from 'redux'

import { DistributionSchedulesParamList } from '@/admin/navigation/types'
import { Logger } from '@/config/logger'
import { setAdminNav } from '@/redux/actions/adminState'
import { setNavProps } from '@/redux/actions/appState'

export type CardAction = 'edit' | 'copy' | 'hide' | 'delete' | 'close'

const _getUniqueName = (originalName: string, distributions: Distribution[]): string => {
  const nameSet = new Set<string>()
  let uniqueName = originalName + ' - Copy'
  distributions.forEach((distro) => {
    nameSet.add(distro.name || distro.location.name)
  })

  let i = 1
  while (nameSet.has(uniqueName)) {
    uniqueName = originalName + ' - Copy (' + i.toString() + ')'
    i++
  }

  return uniqueName
}

//Copy Schedule - return promise
export const handleCopyDistro = async (distro: Distribution, distributions: Distribution[]) => {
  const copyOfDistribution = deepClone(distro)
  copyOfDistribution.name = _getUniqueName(distro.name || distro.location.name, distributions)
  await addDistribution(copyOfDistribution)
}

//Edit Schedule - return promise
export const handleEditPress = async (
  distro: Distribution,
  dispatch: Dispatch,
  navigation: StackNavigationProp<DistributionSchedulesParamList>,
) => {
  dispatch(setAdminNav({ distribution: distro }))
  navigation.navigate('EditDistribution', { id: distro.id })
  return Promise.resolve()
}

//Delete Schedule - return promise
export const handleDeletePress = async (distro: Distribution) => {
  return canDistributionBeDeleted(distro.farm.id, distro.id).then((result) => {
    if (result) {
      return Alert('Delete Distribution', 'Are you sure you want to delete this distribution?', [
        {
          text: 'Delete',
          onPress: async () => {
            return distrosCollection.delete(distro.id)
          },
        },
        {
          text: 'Cancel',
          style: 'cancel',
        },
      ])
    } else {
      return Alert(
        'Permission Denied',
        'This distribution cannot be deleted because it either has a pickup or product associated with it.',
        [
          {
            text: 'Ok',
            style: 'cancel',
          },
        ],
      )
    }
  })
}

//Hide Schedule - return promise
export const handleHideDistro = async (distro: Distribution) => {
  return distrosCollection.update({ id: distro.id, isHidden: !distro.isHidden })
}

//Toggle closed - return promise
export const handleCloseDistro = async (distro: Distribution) => {
  return distrosCollection.update({ id: distro.id, closed: !distro.closed })
}

const mutations: CardAction[] = ['close', 'hide', 'delete']

/** actionGen will generate the action handler for each action */
export const handleDistroAction = async (
  /** action type */
  action: CardAction,
  /** current distro */
  distro: Distribution,
  /** distro Groups */
  distributions: Distribution[],
  dispatch: Dispatch<any>,
  navigation: StackNavigationProp<DistributionSchedulesParamList>,
): Promise<void> => {
  switch (action) {
    case 'edit':
      await handleEditPress(distro, dispatch, navigation)
      break
    case 'copy':
      await handleCopyDistro(distro, distributions)
      break
    case 'hide': {
      await handleHideDistro(distro)
      break
    }
    case 'delete': {
      await handleDeletePress(distro)
      break
    }
    case 'close': {
      // We want to show a loader for 10 seconds to make sure that the product has time to update from the denormalizer
      Loader(true)
      await handleCloseDistro(distro)
      setTimeout(() => {
        Loader(false)
      }, 10000)
      break
    }
    default: {
      Logger.error(new Error("distro action wasn't handled by any case"))
      Toast('Something went wrong while handling your request')
    }
  }

  if (action in mutations) {
    dispatch(setNavProps()) //Clear the farm cache if the action might produce an out-of-date shop
  }
}
