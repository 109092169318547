import { DateTime, DurationInput } from 'luxon'

import { PaymentMethod } from './PaymentMethod'
import { User } from './User'
import { Money } from './Money'

/**
 * EventTypes is a Enum with all the different types of events that can be logged
 */
export enum EventTypes {
  /** @type  Event of exchanging an EBT card */
  EbtCardChangeAttempt = 'ebt-card-change-attempt',
  /** @type Event of invalid PIN for an EBT card */
  EbtInvalidPinAttempt = 'ebt-invalid-pin-attempt',
  /** @type Event of invalid EBT card number */
  EbtInvalidCardAttempt = 'ebt-invalid-card-attempt',
  /** @type Event of purchase utilizing an EBT card */
  EbtPurchase = 'ebt-purchase',
  /** @type Event of requesting a balance inquiry */
  EbtBalanceInquiry = 'ebt-balance-inquiry',
  /** @type Event of a EBT transaction larger than $100 */
  EbtLargeTransaction = 'ebt-large-transaction',
  /** @type Can be used for all logs that are triggered from an action a user makes (reschedule, edit product, place order...)  */
  UserAction = 'user-action',
  /** @type This can be used for all logs that are triggered from an action not taken by a user. (installment charged, notification sent...) */
  SystemAction = 'system-action',
}

export type EventBase<EventType extends EventTypes, Data extends Record<string, any>> = {
  id: string
  time: DateTime
  // This will mark when the event should be expired, defaults to 30 days but can be set to any value,
  // undefined can be added in the future and will keep the event permanently
  expire: DateTime
  type: EventType
  data: Data
}

export type EbtCardChangeAttempt = EventBase<
  EventTypes.EbtCardChangeAttempt,
  {
    user: Pick<User, 'id'>
    paymentMethod: Pick<PaymentMethod, 'id'>
  }
>
export type EbtInvalidPinAttempt = EventBase<
  EventTypes.EbtInvalidPinAttempt,
  {
    user: Pick<User, 'id'>
    paymentMethod: Pick<PaymentMethod, 'id'>
  }
>
export type EbtInvalidCardAttempt = EventBase<
  EventTypes.EbtInvalidCardAttempt,
  {
    user: Pick<User, 'id'>
    paymentMethod: Pick<PaymentMethod, 'id'>
  }
>
export type EbtPurchase = EventBase<
  EventTypes.EbtPurchase,
  {
    user: Pick<User, 'id'>
    paymentMethod: Pick<PaymentMethod, 'id'>
    payment: {
      amount: Money
    }
  }
>

export type EbtBalanceInquiry = EventBase<
  EventTypes.EbtBalanceInquiry,
  {
    user: Pick<User, 'id'>
    paymentMethod: Pick<PaymentMethod, 'id'>
  }
>

export type EbtLargeTransaction = EventBase<
  EventTypes.EbtLargeTransaction,
  {
    user: Pick<User, 'id'>
    paymentMethod: Pick<PaymentMethod, 'id'>
    payment: {
      amount: Money
    }
  }
>

//TODO: These should be flushed out more
export type UserActionEvent = EventBase<
  EventTypes.UserAction,
  {
    user: Pick<User, 'id'>
    // Any fields can be added here
    [record: string]: any
  }
>
export type SystemActionEvent = EventBase<
  EventTypes.SystemAction,
  {
    system: 'denormalizer' | 'cronjob' | 'notification'
    // Any fields can be added here
    [record: string]: any
  }
>

export type Event =
  | EbtCardChangeAttempt
  | EbtInvalidPinAttempt
  | EbtInvalidCardAttempt
  | EbtPurchase
  | EbtBalanceInquiry
  | EbtLargeTransaction

export type HoldEventTypes = EbtCardChangeAttempt | EbtInvalidPinAttempt | EbtInvalidCardAttempt

export type SyntheticEvent = UserActionEvent | SystemActionEvent

/** @return the expiration amount for a given event, can be updated if our requirements change */
export function getExpiration(type: EventTypes): DurationInput {
  switch (type) {
    case EventTypes.EbtCardChangeAttempt:
      return { days: 7 }
    case EventTypes.EbtInvalidPinAttempt:
      return { days: 1 }
    case EventTypes.EbtInvalidCardAttempt:
      return { days: 1 }
    case EventTypes.EbtBalanceInquiry:
      return { days: 7 }
    case EventTypes.EbtLargeTransaction:
      return { days: 7 }
    case EventTypes.EbtPurchase:
      return { days: 7 }
    default:
      return { days: 30 }
  }
}

// Will return the number of tries allowed in the given number of days above for each event
export function getEventMaxTries(type: EventTypes): number {
  switch (type) {
    case EventTypes.EbtCardChangeAttempt:
      return 3
    // this is the number of Invalid pin attempts for an account before setting a hold
    // To be a hold it assumes that the user has already tried to change the pin 3 times with one card
    // and is trying to change the pin on a different card.
    // This is a total of 5 attempts on 2 cards. When the user reaches 3 attempts on one card the FNS will
    // block that card for the current day of the attempts. This is why we are setting the hold on the second card.
    case EventTypes.EbtInvalidPinAttempt:
      return 5
    case EventTypes.EbtInvalidCardAttempt:
      return 3
    default:
      return 0
  }
}
