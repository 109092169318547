import { ListItemAccordion, ListItemAccordionProps, RadioButton } from '@elements'
import { ProcessedMenuItem } from '@screens/Shopping/FarmShop/Filtering/filterUtils'
import { memo } from 'react'
import { StyleSheet, View } from 'react-native'

export type Props = Pick<ListItemAccordionProps, 'isExpanded' | 'onToggle' | 'title'> & {
  onItemPress: (item: ProcessedMenuItem) => void
  items: ProcessedMenuItem[]
}

/** Expandable section with multiple radio buttons as selectable options */
export const FilterGroup = memo(function FilterGroup({ isExpanded, onToggle, items, title, onItemPress }: Props) {
  return (
    <ListItemAccordion isExpanded={isExpanded} onToggle={onToggle} title={title}>
      <View style={styles.itemsWrapper}>
        {items.map((item) => (
          <RadioButton
            key={item.value}
            onPress={() => onItemPress(item)}
            iconSize={18}
            isSelected={item.isRefined}
            label={item.label}
          />
        ))}
      </View>
    </ListItemAccordion>
  )
})

const styles = StyleSheet.create({
  itemsWrapper: {
    gap: 8,
  },
})
