import { Button, Divider, hideModal, Text } from '@elements'
import { openUrl } from '@helpers/client'
import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import { ToolTips } from './ToolTips'

import Colors from '@/constants/Colors'
import { globalStyles } from '../../../constants/Styles'

function Container({ children, secondary }: { children: React.ReactNode; secondary?: string | React.ReactNode }) {
  return (
    <View style={styles.container}>
      <Text>{children}</Text>
      {!!secondary && (
        <>
          <Divider clear />
          <Text>{secondary}</Text>
        </>
      )}
    </View>
  )
}

/** This component holds all text for static tooltips, it can be improved by extracting these from the component into a simple object */
export function InfoTooltip({ id }: { id: ToolTips }) {
  const contactSupport = () => {
    hideModal()
    openUrl('https://farmgenerations.coop/schedule')
  }

  if (id === ToolTips.SERVICE_FEE) {
    return (
      <Container secondary="*Fees may not be refundable on any cancelled orders.">
        Farmers are charged a service fee when they sell on GrownBy, which covers the costs of our cooperative and
        payment processing. You can make this service more affordable for your farmer by covering some or all of these
        fees. Thank you for supporting local farms!
      </Container>
    )
  } else if (id === ToolTips.PICKUP_LOCATION) {
    return (
      <Container>
        Choose your pickup location every time you place an order. Locations are set by the farm. Can’t make it to
        pickup one week? Edit your order or contact the farm on the Orders page.
      </Container>
    )
  } else if (id === ToolTips.EMAIL) {
    return (
      <Container>
        Enter a public-facing email address. This does not need to be the same as your GrownBy account email.
      </Container>
    )
  } else if (id === ToolTips.PHONE) {
    return <Container>Enter a public-facing phone number.</Container>
  } else if (id === ToolTips.ADDRESS) {
    return (
      <Container>
        This location is visible to the public on our farm map. If you do not want your location visible to the public,
        enter an alternative location or mailing address.
      </Container>
    )
  } else if (id === ToolTips.CERTIFICATIONS) {
    return (
      <Container>
        Certification status will be verified against data from certifiers. Only enter current certifications.
      </Container>
    )
  } else if (id === ToolTips.TAGS) {
    return (
      <Container>
        Add any custom tags to help customers identify your farm to the public. These will be displayed as part of you
        public facing farm profile and they are searchable on the map.
      </Container>
    )
  } else if (id === ToolTips.BRANDING) {
    return (
      <Container
        secondary="Primary color will be used as the background for invoices and cards. Accent color will be used for buttons and
      links."
      >
        Your logo will appear on all invoice receipts. Brand Color and Accent Color are not currently in use but will be
        incorporated in the future.
      </Container>
    )
  } else if (id === ToolTips.OFFLINE_PAYMENTS) {
    return (
      <Container>
        Customers can pay for their products by check, cash, or with EBT. These forms of payment are considered "offline
        payments". Please give customers clear directions for how they should pay. Tell them who to write checks to and
        where to send them; how they will pay by EBT; or when they can make a cash payment.
      </Container>
    )
  } else if (id === ToolTips.COOP_MEMBERSHIP) {
    return (
      <Container>
        Farm Generations is an agricultural cooperative owned by farmers. All active sellers are invited to join. Please
        visit{' '}
        <TouchableOpacity onPress={() => openUrl('https://farmgenerations.coop')}>
          <Text style={styles.linkText}>farmgenerations.coop</Text>
        </TouchableOpacity>{' '}
        for more information.
      </Container>
    )
  } else if (id === ToolTips.BASIC_NAME) {
    return <Container>Name of product in your farm shop.</Container>
  } else if (id === ToolTips.BASIC_CATEGORY) {
    return (
      <Container>
        This is the category that this product will display in within your farm shop. Add categories as necessary.
      </Container>
    )
  } else if (id === ToolTips.BASIC_COMMODITY) {
    return (
      <Container>
        Classify your products as general commodities. This helps GrownBy categorize products across farms.
      </Container>
    )
  } else if (id === ToolTips.BASIC_PRODUCER) {
    return (
      <Container>
        Is this your product or a product you buy in? Tell customers who produced this item with a producer name. You
        might add "Our Farm" our your farm name as an option.
      </Container>
    )
  } else if (id === ToolTips.BASIC_VARIETY) {
    return (
      <Container>
        What variety of commodity is this product? For instance: "Icelandic" Lamb or "Lacinato" Kale.
      </Container>
    )
  } else if (id === ToolTips.BASE_UNIT) {
    return (
      <Container>
        This is the base measurement or unit by which you sell this product. You may add any custom unit.
      </Container>
    )
  } else if (id === ToolTips.BASE_UNIT_GLOBAL) {
    return (
      <Container>
        Global Inventory tracks inventory across buying options. For instance, you may have 1,000 lbs of apples and sell
        those apples in 5 or 10 lb bags. Uncheck this box if you need to manage inventory by buying option. Tracking
        inventory by buying option is helpful for producers selling specific cuts of items; for example, if you have
        packages of cheese or meat in certain weights and you want to keep track of the inventory of packets of each
        weight.
      </Container>
    )
  } else if (id === ToolTips.COST_PER_UNIT) {
    return (
      <Container>
        This optional field is your estimated cost of production. This is not visible to consumers and is not the price
        of your item.
      </Container>
    )
  } else if (id === ToolTips.IN_STOCK) {
    return (
      <Container>
        Add inventory for this Buying Option. You must have inventory available for this product to sell.
      </Container>
    )
  } else if (id === ToolTips.IN_STOCK_GLOBAL) {
    return <Container>Add inventory of Units available across Buying Options.</Container>
  } else if (id === ToolTips.OPTION_NAME) {
    return (
      <Container>Shop name for this buying option. Examples: 1 pound bag, 1 quart, 4 ounces, 1 bunch, etc.</Container>
    )
  } else if (id === ToolTips.PRICE_OPTION) {
    return (
      <Container>
        This Price is calculated by multiplying your Price per Unit and the number of Units in this Buying Option. You
        may override this calculation and enter a different price.
      </Container>
    )
  } else if (id === ToolTips.PRICE_GLOBAL) {
    return <Container>Add Price for this Buying Option.</Container>
  } else if (id === ToolTips.PRICE_UNIT) {
    return (
      <Container>
        Add the Price of a Unit of this product. GrownBy will calculate prices for each Buying Option by multiplying
        this price by the number of Units per Buying Option.
      </Container>
    )
  } else if (id === ToolTips.OPTION_UNIT) {
    return (
      <Container>
        The number of base units in this buying option. For example, a 1 pound bag would contain 16 ounces -- if ounces
        are your base unit. If your base unit is pounds, you would have 1 unit for a 1 pound bag.
      </Container>
    )
  } else if (id === ToolTips.OPTION_SKU) {
    return (
      <Container>
        SKUs or Stock Keeping Units are visible in distribution data exports. SKUs are optional and used as an internal
        organizational system.
      </Container>
    )
  } else if (id === ToolTips.DISTRIBUTIONS) {
    return (
      <Container>
        Select the locations and schedules where you want to sell this product. If you need to add an additional
        location or distribution, go back to "Setup Farm" in the menu.
      </Container>
    )
  } else if (id === ToolTips.PRODUCTS_SCHEDULE_SELECT) {
    return (
      <Container>
        Select one or more schedules for this product. Changes only impact future purchases; to edit an existing order,
        you must edit the Schedule. The product can match or be a subset of the selected Schedule. You can limit the
        availability with Frequency, Start and End Dates.
      </Container>
    )
  } else if (id === ToolTips.PRODUCTS_SCHEDULE_FREQUENCY) {
    return (
      <Container>
        Select a frequency that this product is available. It must match or be a subset of the selected Schedule.
      </Container>
    )
  } else if (id === ToolTips.PRODUCTS_SCHEDULE_START) {
    return (
      <Container>
        Add the date that your product is available. It must match or be a subset of the selected Schedule.
      </Container>
    )
  } else if (id === ToolTips.PRODUCTS_SCHEDULE_END) {
    return <Container>Select the last day your product is distributed. This can always be changed.</Container>
  } else if (id === ToolTips.PRODUCTS_AVAILABILITY_OVERVIEW) {
    return <Container>Review your product availability before opening up sales to the public.</Container>
  } else if (id === ToolTips.PRODUCTS_SCHEDULE_NEXTDAY) {
    return (
      <Container>
        When checked, customers can only buy this product for the next available day. Otherwise, customers can purchase
        your product across its Availability. If this product has a minimum number of pickups, this option will be
        disabled.
      </Container>
    )
  } else if (id === ToolTips.PRODUCTS_MIN_PICKUPS) {
    return (
      <Container>
        The minimum number of pickups required to purchase this product. If this product can only be purchased for the
        next available day, this input will be disabled.
      </Container>
    )
  } else if (id === ToolTips.AVAILABILITY) {
    return (
      <Container>
        Choose a Year-Round or Seasonal Schedule. Seasonal schedules require adding availability dates to the calendar.
      </Container>
    )
  } else if (id === ToolTips.SHORTDESCRIPTION) {
    return <Container>Subtitle to your Product Name.</Container>
  } else if (id === ToolTips.LONGDESCRIPTION) {
    return (
      <Container>
        Tell customers details about this product or share. You might consider talking in-depth about your production
        techniques, offering information about variation or sizing, or perhaps logistics for the season.
      </Container>
    )
  } else if (id === ToolTips.CHANGE_WINDOW) {
    return (
      <Container>
        Controls how many days before a distribution a change can be made. If 0, a change can be made until the end of
        the relevant schedule. If 1, midnight the day before. If 2, midnight two days before, etc.
      </Container>
    )
  } else if (id === ToolTips.SHARE_TYPE) {
    return (
      <Container>
        Primary Shares can be purchased alone and they are generally recurring over a certain period of time. Addon
        Shares may be recurring products like a primary share, but are only available to customers who have purchased a
        Primary Share with a matching schedule.
      </Container>
    )
  } else if (id === ToolTips.SHARE_PRODUCTION) {
    return <Container>Give a general description of your production methods.</Container>
  } else if (id === ToolTips.TEMPLATE) {
    return (
      <Container>
        Templates allow you to create multiple variations of a product that will be aggregated on your harvest and
        distribution lists.
      </Container>
    )
  } else if (id === ToolTips.TEMPLATE_NAME) {
    return (
      <Container>
        Template Product Name is what customers will see on their sign-in sheets, and what farmers will see on their
        Daily Summary, which is used for harvest and packing. This Template Product Name will also appear in your shop.
      </Container>
    )
  } else if (id === ToolTips.SELECT_CSA) {
    return (
      <View style={styles.container}>
        <Text>Each share must be associated with a CSA Group.</Text>
        <Divider clear />
      </View>
    )
  } else if (id === ToolTips.SELECT_CSA_STANDARD) {
    return (
      <View style={styles.container}>
        <Text>
          Standard products may be associated with a CSA Group as a means of organizing your shop or limiting
          availability to CSA members.
        </Text>
        <Divider clear />
      </View>
    )
  } else if (id === ToolTips.ADDON_FAM_SIZE) {
    return <Container>What family size do you recommend for this share?</Container>
  } else if (id === ToolTips.MIN_MAX_ITEMS) {
    return <Container>Enter the range of items that you include with your share in each distribution.</Container>
  } else if (id === ToolTips.SCHEDULE_TYPE) {
    return (
      <Container>
        Choose a Year-Round or Seasonal Schedule. Seasonal schedules require adding availability dates to the calendar.
      </Container>
    )
  } else if (id === ToolTips.FREQUENCY) {
    return (
      <Container>
        You can sell shares on a weekly, bi-weekly or monthly basis with GrownBy. At this time, one share cannot be
        associated with more than one schedule type. Please make additional shares if you would like to offer multiple
        schedules.
      </Container>
    )
  } else if (id === ToolTips.SHARE_AVAILABILITY) {
    return <Container>This is the season that your share is available.</Container>
  } else if (id === ToolTips.SHARE_INVENTORY) {
    return <Container>How many total shares are available to be purchased.</Container>
  } else if (id === ToolTips.WEEKLY_BILLING) {
    return (
      <Container>
        Customers pay an upfront payment (can be $0) and pay for the share on a weekly basis that is timed with the
        beginning of share distributions.
      </Container>
    )
  } else if (id === ToolTips.MONTHLY_BILLING) {
    return (
      <Container>
        Customers pay an upfront payment (can be $0) and pay for the share on a monthly basis timed with the beginning
        of share distributions.
      </Container>
    )
  } else if (id === ToolTips.POLICIES) {
    return (
      <Container>
        Customers are notified of your CSA policy when purchasing the share; however, all shares must be cancelled
        manually by the farm in the Customers Tab.
      </Container>
    )
  } else if (id === ToolTips.VACATION_WEEKS) {
    return (
      <Container>
        Vacation weeks allow a customer to skip a pickup when they are away and receive farm credit for the missed week.
        You can choose any number of weeks, including 0 (none).
      </Container>
    )
  } else if (id === ToolTips.PICKUP_OPTIONS) {
    return <Container>All products sold on GrownBy can be delivered or picked up by customers.</Container>
  } else if (id === ToolTips.MISSED_PICKUP_MESSAGE) {
    return (
      <Container>
        This policy will apply to all shares added to this CSA Group. Your customers will receive a notification
        reminding them to pickup their share and your missed pickup policy is included. Please customize it here.
      </Container>
    )
  } else if (id === ToolTips.DISTRIB_SCHEDULE) {
    return (
      <Container>
        Pickups are based off the start date. If you select to start on the first Wednesday of the month then every
        pickup will be the first Wednesday.
      </Container>
    )
  } else if (id === ToolTips.FINAL_PAY_DATE) {
    return <Container>Enter the date that by which all payments should be made.</Container>
  } else if (id === ToolTips.TOTAL_SHARE_PRICE) {
    return (
      <Container>
        Total Share Price is the total amount that you would like to charge for a share. The upfront payment, if added,
        is immediately charged on purchase and will reduce the total amount due. The remainder of the Total Share Price
        will be then be charged over the number of weeks between the first distribution and the Final Payment Date.
      </Container>
    )
  } else if (id === ToolTips.TOTAL_SHARE_PRICE_MONTHLY) {
    return (
      <Container>
        Total Share Price is the total amount that you would like to charge for a share. The upfront payment, if added,
        is immediately charged on purchase and will reduce the total amount due. The remainder of the Total Share Price
        will be then be charged over the number of months between the first distribution and the Final Payment Date.
      </Container>
    )
  } else if (id === ToolTips.DEPOSIT) {
    return (
      <Container>
        This is the amount of money that customers will be charged if purchasing a share before the first distribution.
        Upfront payments do not apply after the first distribution.
      </Container>
    )
  } else if (id === ToolTips.NUM_PICKUPS) {
    return (
      <Container>
        The number of pickups your share will be available for. This determines how shares will be prorated, you can
        preview the specific pickups on the next page.
      </Container>
    )
  } else if (id === ToolTips.FNS) {
    return (
      <Container>
        An FNS number is issued to authorized sellers by the US Department of Agriculture.
        <TouchableOpacity onPress={() => openUrl('https://marketlink.org/about/contact')}>
          <Text style={styles.linkText}> Please reach out to MarketLink </Text>
        </TouchableOpacity>
        to apply for an FNS number and accept EBT on your farm.
      </Container>
    )
  } else if (id === ToolTips.INVOICES) {
    return (
      <Container>
        An invoice is created for each payment. If a customer chooses installments for a CSA share, there will be
        multiple invoices for their order. There are two exports available -- "Summary" puts each invoice in a row;
        "Detail" puts each item on a row.
      </Container>
    )
  } else if (id === ToolTips.ORDERS) {
    return (
      <Container>
        Here you'll find your orders, organized from newest to oldest. An order can have multiple invoices associated
        with it.
      </Container>
    )
  } else if (id === ToolTips.NEW_ORDER_MESSAGE) {
    return <Container>Message displayed to customers in the pickup reminder emails and notifications.</Container>
  } else if (id === ToolTips.LOCATIONS_ZONES) {
    return (
      <Container>
        Define where customer can receive their orders. Locations are the places where you distribute your products;
        Delivery Zones are defined by zip codes; and Shipping Zones are defined by states. After creating Locations or
        Zones, you can build schedules connected to them.
      </Container>
    )
  } else if (id === ToolTips.CouponsList) {
    return (
      <Container>
        First, create a Coupon. Then, create an associated Promo Code. Coupons can be filtered to only work for products
        in specific categories and/or for products from specific producers.{' '}
      </Container>
    )
  } else if (id === ToolTips.COUPON_REDEMPTION) {
    return (
      <Container>
        Redemptions are recorded each time a Promo Code is used on checkout.All Redemptions can be exported on the
        Promotions tab.
      </Container>
    )
  } else if (id === ToolTips.COUPON_PROMOCODE) {
    return (
      <Container>
        Promo Codes are necessary to apply Coupons. Please create a Promo Code without spaces. Click "Add Promo Code" to
        create one.
      </Container>
    )
  } else if (id === ToolTips.SCHEDULES) {
    return (
      <Container>
        Each product requires a schedule for sale. Before creating a Schedule, you must have saved Locations or Zones.
        Inactive Schedules can be hidden (Products with hidden schedules are not visible) or closed (Products with
        closed schedules are removed from your shop, but you can still create an order using them).
      </Container>
    )
  } else if (id === ToolTips.PRODUCTS) {
    return (
      <Container>
        GrownBy offers numerous product types: Standard; Digital: Subscriptions; and Farm Credit. Standard products are
        sold on one or more dates. Digital products do not require a Schedule. Subscriptions are either Primary Shares
        or Add-on Shares. The Farm Credit product (each farm can only have one) allows customers to purchase credit to
        be used through your shop.
      </Container>
    )
  } else if (id === ToolTips.CSA_GROUPS) {
    return (
      <Container>
        In order to create a CSA Share, you must first create a CSA Group. Each share can only be assigned to a single
        CSA Group, although templates can be used to assign the same product to multiple CSA Groups. A CSA Group is
        generally defined by a group of shares sold to community and distributed in the same area; some farms also use
        "private" CSA Groups for wholesale distribution and send the group link to buyers. Standard products can be
        assigned to CSA Groups.
      </Container>
    )
  } else if (id === ToolTips.CUSTOMERS) {
    return (
      <Container>
        This is list of GrownBy shoppers who have made a purchase from your farm or who have favorited your farm.
      </Container>
    )
  } else if (id === ToolTips.DIST_NAME) {
    return (
      <Container>
        Choose Schedule names which differentiate between Schedules at the same location. For example, "Weekly Mondays"
        or "Monthly Friday Pick-up".{' '}
      </Container>
    )
  } else if (id === ToolTips.LOCATION_NAME) {
    return (
      <Container>
        Visible to customers during checkout, on their orders page, and in pick-up reminders. Farmers will use this name
        to filter data on the app and in data exports. Choose a name that you and your customers will readily
        understand.
      </Container>
    )
  } else if (id === ToolTips.LOCATION_NICKNAME) {
    return (
      <Container>Nickname appears beneath the location name in product descriptions and during checkout. </Container>
    )
  } else if (id === ToolTips.DELIVERY_ZONE) {
    return (
      <Container>
        Create a zone for deliveries. Only customers with delivery addresses in a zip code you select here can order for
        delivery.
      </Container>
    )
  } else if (id === ToolTips.DELIVERY_FEE) {
    return <Container>This is the fee per delivery. Each order will incur an additional delivery fee.</Container>
  } else if (id === ToolTips.ORDER_CUTOFF_WINDOW) {
    return (
      <Container>
        Your cutoff window for new orders. If you enter "0", you can accept same-day orders; "1" is midnight the day
        before the distribution; "2" is two days before the distribution. Learn more here:{' '}
        <TouchableOpacity
          onPress={() => {
            openUrl(
              'https://grownby.zohodesk.com/portal/en/kb/articles/create-schedules-and-skip-exception-days-12-1-2023',
            )
          }}
        >
          <Text style={{ color: Colors.green }}>link </Text>
        </TouchableOpacity>
      </Container>
    )
  } else if (id === ToolTips.TOGGLE_HIDDEN) {
    return (
      <Container>
        Hiding a CSA Group, product, location, or schedule keeps the associated information, but hides it from customers
      </Container>
    )
  } else if (id === ToolTips.EXCEPTION_DAYS) {
    return (
      <Container>
        Customize your schedule with skip and exception days. Click 1x for a skip and 2x for an exception day. Changes
        to a Schedule here will impact all orders on these dates, including those placed in the past. Skip days are
        removed from the Schedule entirely. Exception days require you to reschedule the distribution to another date.
        For example, say July 4th is your regularly scheduled day and it's a Tuesday, but you plan to distribute instead
        on Thursday July 6th. To make that change, simply click on July 4th and make it red as your Exception Day and
        then click on July 6th to make it blue as your Reschedule Day. If you want to skip the day entirely and not
        reschedule, simply click it once as a Skip Day. Green indicates distribution days (either a small dot or a
        circle surrounding the date).
      </Container>
    )
  } else if (id === ToolTips.INSTALLMENT_PAYMENTS) {
    return <Container>A backup payment choice must be included for future payments that is not farm credit.</Container>
  } else if (id === ToolTips.DELIVERY_FEE_PAYMENTS) {
    return (
      <Container>
        A backup payment choice must be included for future delivery fees that is not farm credit or EBT.
      </Container>
    )
  } else if (id === ToolTips.SNAP_EBT_ELIGIBILITY) {
    return (
      <Container>
        If you have online authorization for SNAP sales, your farm must designate eligible products. A list of eligible
        products can be found here:
        <TouchableOpacity
          onPress={() => {
            openUrl('https://www.fns.usda.gov/snap/eligible-food-items')
          }}
        >
          <Text style={{ color: Colors.green }}>https://www.fns.usda.gov/snap/eligible-food-items</Text>
        </TouchableOpacity>
        If you are interested in accepting EBT payments in your farm shop, please reach out to us at &nbsp;
        <TouchableOpacity
          onPress={() => {
            openUrl('support@grownby.app')
          }}
        >
          <Text style={{ color: Colors.green }}>support@grownby.app</Text>
        </TouchableOpacity>
      </Container>
    )
  } else if (id === ToolTips.SALES_REPORT) {
    return (
      <View>
        <Container>
          Your farm sales in relationship to sale date and schedules. Total sales do not include any refunds, but they
          do include discounts.Exports include the same data in .csv format, either aggregated by product or with each
          sale in its own row.
        </Container>
      </View>
    )
  } else if (id === ToolTips.DAILY_SUMMARY) {
    return (
      <View>
        <Container>
          <Text type="bold">Daily Summary</Text> provides an aggregated list of all of your products that need to be
          harvested, packed, and distributed over the time frame selected.
        </Container>
        <Container>
          <Text type="bold">Daily Summary CSV</Text> provides the same information, in addition to category, producer,
          and SKU in columns.
        </Container>
      </View>
    )
  } else if (id === ToolTips.LOCATION_SUMMARY) {
    return (
      <View>
        <Container>
          <Text type="bold">Location Summary</Text> is a list of where your harvest is going; an aggregate list by
          location.
        </Container>
        <Container>
          <Text type="bold">Location Summary CSV</Text> provides the same information, in addition to category,
          producer, and SKU in columns. and SKU in columns.
        </Container>
      </View>
    )
  } else if (id === ToolTips.SIGN_IN_SHEETS) {
    return (
      <View>
        <Container>
          Sign-in generates a list customers, items to receive, and locations where items will be received.
        </Container>
        <Container>
          <Text type="bold">Start Sign-in</Text> will open a sign-in session. Sign-in sessions track the status of
          distributions: Active; Cancelled; Donated; Missed; Received; Vacation. After ending your Sign-in session, a
          notification will be sent to the farm. Sign-in sessions can be accessed and edited after ending. If you do not
          start a Sign-in for a given distribution, you can always open one later.
        </Container>
        <Container>
          <Text type="bold">Export to PDF</Text> will create a formatted and printable list of all of your customers who
          are receiving orders with checkboxes next to each name. There is a summary of products for each location on a
          cover sheet before the customer list.
        </Container>
        <Container>
          <Text type="bold">Export to CSV</Text> will create a spreadsheet with one item per line (customers receiving
          multiple items will be listed multiple times). Customer addresses and phone numbers are included here, as well
          as more extensive product information such as Category, SKU, Product Type, Producer.
        </Container>
        <Container>
          <Text type="bold">Generate Emails</Text> creates a list of all of the email addresses of customers receiving
          orders. This is useful for importing into your own email program.
        </Container>
      </View>
    )
  } else if (id === ToolTips.PACKING_SHEETS) {
    return (
      <View>
        <Container>
          <Text type="bold">Packing Sheets</Text> provides an aggregated list of all of your products that need to be
          harvested, packed, and distributed over the time frame selected.
        </Container>
        <Container>
          <Text type="bold">Packing Sheets PDF</Text> export provides printable, 8.5x11 packing sheet with one order per
          page. The packing sheet also includes delivery information, along with your farm and customer contact
          information.
        </Container>
      </View>
    )
  } else if (id === ToolTips.CREATE_FARM_PROFILE) {
    return (
      <View>
        <Container>
          Fill out your Farm Profile, Locations, Schedules and Stripe Setup to start selling on GrownBy.
        </Container>
        <View style={globalStyles.alignCenter}>
          <Text style={globalStyles.margin10}>If you need help with setup, please reach out to us!</Text>
          <Button style={globalStyles.flex1} title="Get Support" onPress={contactSupport} />
        </View>
      </View>
    )
  } else if (id === ToolTips.FARM_INFO) {
    return (
      <View>
        <Container>
          Here you will find information about your GrownBy account as well as data and links to your Stripe account.
        </Container>
      </View>
    )
  } else if (id === ToolTips.EBT_CARD_CHANGE) {
    return (
      <View>
        <Container>
          The number of times GrownBy users have added a new EBT card to their account. A higher than normal number
          could indicate that users are attempting to test out cards they have stolen.
        </Container>
      </View>
    )
  } else if (id === ToolTips.EBT_BALANCE_INQUIRIES) {
    return (
      <View>
        <Container>
          The number of times GrownBy users have requested the balance on their EBT card. A higher than normal number
          could indicate that there are users attempting to drain stolen EBT cards.
        </Container>
      </View>
    )
  } else if (id === ToolTips.EXCESSIVELY_LARGE_EBT_TRANSACTIONS) {
    return (
      <View>
        <Container>
          The number of EBT transactions placed on GrownBy that are over $100. A higher than normal number could
          indicate someone is attempting to drain stolen EBT cards.
        </Container>
      </View>
    )
  } else if (id === ToolTips.TWO_ACCOUNTS_SHARING_EBT_CARD) {
    return (
      <View>
        <Container>
          The number of EBT cards that are active on more than one GrownBy account. A higher than normal number could
          indicate someone has stolen an EBT card and is using it.
        </Container>
      </View>
    )
  } else if (id === ToolTips.SIGN_UPS) {
    return (
      <View>
        <Container>
          The number of users who have signed up for GrownBy in the last 7 days. A higher than normal number could
          indicate that someone is creating spam accounts to run through stolen EBT cards.
        </Container>
      </View>
    )
  } else if (id === ToolTips.TOO_MANY_EBT_TRANSACTIONS_IN_A_DAY) {
    return (
      <View>
        <Container>
          The number of EBT transactions in the last 7 days. A higher than normal number could indicate that someone is
          abusing EBT cards.
        </Container>
      </View>
    )
  } else if (id === ToolTips.ONLY_ALLOW_SCHEDULE_CHANGES) {
    return (
      <View>
        <Container>
          Allow customers to change their pickup to other days within the same week at the same location.
        </Container>
      </View>
    )
  } else if (id === ToolTips.ALLOW_BOTH_SCHEDULE_AND_LOCATION_CHANGES) {
    return (
      <View>
        <Container>
          Allow customers to change their pickup to other days within the same week at any location.
        </Container>
      </View>
    )
  } else if (id === ToolTips.DASHBOARD_GROSS_REVENUE) {
    return (
      <View>
        <Container>The sum of your invoices within the selected date range.</Container>
      </View>
    )
  } else if (id === ToolTips.ADD_SHIPPING_ZONE) {
    return (
      <View>
        <Container>Zone will be restricted to customers in selected state/s.</Container>
      </View>
    )
  } else if (id === ToolTips.ENTER_ZIP_CODE) {
    return (
      <View>
        <Container>Zone will be restricted to customers in selected zip code/s</Container>
      </View>
    )
  } else if (id === ToolTips.SHIPPING_FEE) {
    return (
      <View>
        <Container>The fees charged per shipment.</Container>
      </View>
    )
  } else if (id === ToolTips.CLOSED_FOR_NEW_ORDERS) {
    return (
      <View>
        <Container>
          Changing this status allows farms to easily "open" and "close" their ordering. When a Schedule is closed,
          customers cannot order products on it. Farm Admins can still create orders associated with closed Schedules.{' '}
        </Container>
      </View>
    )
  } else if (id === ToolTips.TAXES_AND_FEES_TITLE) {
    return (
      <View>
        <Container>Add Taxes and Fees via the Account Setting page.</Container>
      </View>
    )
  } else if (id === ToolTips.SHARE_TEMPLATES) {
    return (
      <View>
        <Container>
          Templates are a means of grouping the same share sold with different names or marketing strategies (price,
          schedule, etc.). They allow you to offer a variety of shares but aggregate the products in Distribution
          Reports. Templates must be created before creating associated shares. Templates are useful for providing
          options for different frequencies, discount price points, and sliding scales. For more information:{' '}
          <TouchableOpacity
            onPress={() => {
              openUrl('https://grownby.zohodesk.com/portal/en/kb/articles/share-templates]')
            }}
          >
            <Text style={{ color: Colors.green }}>click here.</Text>
          </TouchableOpacity>
        </Container>
      </View>
    )
  } else if (id === ToolTips.CATEGORIES) {
    return (
      <View>
        <Container>
          Categories organize products within your farm shop. Use this page to customize the categories you have added
          to your account. Pre-loaded GrownBy Categories cannot be edited or deleted but do not need to be used.
        </Container>
      </View>
    )
  } else if (id === ToolTips.PRODUCERS) {
    return (
      <View>
        <Container>
          In addition to your farm, you can sell products from other producers and include those producers here.
          Shoppers cannot sort the shop by producer but you can use this data to generate and organize revenue reports,
          packlists, etc. Shoppers will see the producer that a product is assigned to in the Product.
        </Container>
      </View>
    )
  } else if (id === ToolTips.TEAM_MEMBERS_TAB) {
    return (
      <View>
        <Container>
          Team Members allows you to share access to your GrownBy account with others. The person who started the
          account is the Owner. Contact us if you need to change account ownership. Admins have the same permissions as
          Owners, but can be removed. Accountants can only see financial information. Distributors can only see customer
          and distribution information.
        </Container>
      </View>
    )
  } else if (id === ToolTips.TAXES_AND_FEES_TAB) {
    return (
      <View>
        <Container>
          Taxes are by percentage and fees are whole number amounts. Taxes and fees can be filtered by state and zip
          code.
        </Container>
      </View>
    )
  } else if (id === ToolTips.ACH_PAYMENT_SETTING) {
    return (
      <View>
        <Container>
          Automated Clearing House (ACH) transactions are bank to bank transfers. Shoppers enter their account and
          routing number once. They can then use ACH to checkout, like a credit or debit card. ACH payments carry lower
          fees than cards (1%, capped at $5). Farms are charged a one-time, $1.50 fee the first time a shopper uses ACH.
          ACH payments take 5-10 days to settle to your account but are more reliable and cheaper than credit card
          transactions.
        </Container>
      </View>
    )
  } else if (id === ToolTips.CSA_GROUPS_ACTIONS) {
    return (
      <View>
        <Container>
          Edit, Copy, Hide, Delete, and control CSA page privacy. To make a CSA private, click Make Private. Then, click
          the three dots again and click "Get Link". Only customers with this link will be able to order products in a
          private CSA Group. Private CSA pages have a lock icon over image. transactions.
        </Container>
      </View>
    )
  } else {
    return (
      <View style={styles.container}>
        <Divider clear />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 10,
  },
  linkText: { color: Colors.blue, textDecorationLine: 'underline' },
})
