import { Text } from '@elements'
import { StyleSheet, View } from 'react-native'

import { globalStyles } from '@/constants/Styles'
import { AlgoliaGeoDoc, AlgoliaGeoProduct } from '@models/Algolia'
import { Product } from '@models/Product'
import { EbtIcon } from '../EbtIcon'

export type ProductInfoProps = {
  small: boolean
  cardText: string
  product: Product | AlgoliaGeoDoc<AlgoliaGeoProduct> | undefined
}
/** Retail version of the product info component placed under the image*/
export function ProductInfo({ small, product, cardText }: ProductInfoProps) {
  return (
    <View style={styles.textCont}>
      <View style={globalStyles.flex1}>
        <Text size={small ? 12 : 14} style={styles.nameText} numberOfLines={2}>
          <EbtIcon product={product} />
          {product?.name}
        </Text>

        <Text numberOfLines={2} size={small ? 10 : 12}>
          {cardText}
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  textCont: {
    flex: 1,
    padding: 5,
  },
  nameText: {
    marginTop: 10,
  },
})
