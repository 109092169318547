import { Fontisto, Ionicons, Zocial } from '@expo/vector-icons'
import { StyleSheet, View } from 'react-native'

import { FIRESTORE_BASE, openAdminLinks } from './helpers'
import { Divider } from '../../components/elements/Divider'
import { HeaderText, Text } from '../../components/elements/Text'
import { Touchable } from '../../components/elements/Touchable'
import WebLink from '../../components/elements/WebLink'
import Colors from '../../constants/Colors'

export default function () {
  return (
    <View>
      <HeaderText>Links</HeaderText>
      <WebLink style={styles.linkItm} url="https://expo.io/@ravi_farmgen/grownby?release-channel=staging">
        Staging Preview App
      </WebLink>
      <WebLink style={styles.linkItm} url="https://expo.io/@ravi_farmgen/grownby?release-channel=develop">
        Dev Preview App
      </WebLink>
      <WebLink style={styles.linkItm} url={FIRESTORE_BASE}>
        Firebase Database
      </WebLink>
      <WebLink
        style={styles.linkItm}
        url="https://appstoreconnect.apple.com/apps/1541472515/appstore/ios/version/inflight"
      >
        Apple Connect
      </WebLink>
      <Divider clear large />
      <HeaderText>Monitoring</HeaderText>
      <View style={styles.iconContainer}>
        <Touchable onPress={() => openAdminLinks('firebase')}>
          <Ionicons name="logo-firebase" />
          <Text size={16} center>
            Functions
          </Text>
        </Touchable>
        <Touchable onPress={() => openAdminLinks('sentry')}>
          <Fontisto name="sentry" />
          <Text size={16} center>
            Sentry
          </Text>
        </Touchable>
        <Touchable onPress={() => openAdminLinks('stripe')}>
          <Zocial name="stripe" />
          <Text size={16} center>
            Webhooks
          </Text>
        </Touchable>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  iconContainer: {
    flexDirection: 'row',
    margin: 20,
    justifyContent: 'space-around',
  },
  linkItm: {
    color: Colors.blue,
    margin: 5,
  },
})
