import { makeHandle } from '@helpers/helpers'
import { FirebaseApp } from 'firebase/app'
import { Firestore } from 'firebase/firestore'
import { Functions } from 'firebase/functions'
import { FirebaseStorage } from 'firebase/storage'

import { AppCheck } from '../config/AppCheck'
import { Authentication } from '../config/Auth'

export const [getApp, setApp, isSetApp] = makeHandle<FirebaseApp>('App')
export const [db, setDb] = makeHandle<Firestore>('Firestore')
export const [storage, setStorage] = makeHandle<FirebaseStorage>('Storage')
export const [auth, setAuth, isAuthSet] = makeHandle<Authentication>('Auth')
export const [functions, setFunctions] = makeHandle<Functions>('Functions')
export const [appCheck, setAppCheck] = makeHandle<AppCheck>('AppCheck')
