import { updateFarmAssociationNote } from '@api/FarmAssociations'
import { PhoneInput } from '@components'
import { Button, FormButton, TextH4, typography } from '@elements'
import { omit } from '@helpers/typescript'
import { FieldAttributes, Formik, FormikProps } from 'formik'
import { useState } from 'react'
import { View } from 'react-native'
import { Input } from 'react-native-elements'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import Colors from '@/constants/Colors'
import { CustomerDetailFormType } from '../../helpers/customerDetailYup'

type InputAttributes = FieldAttributes<object> & {
  label: string
}

export function CustomerDetail({ field, value, pronouns }: { field: string; value?: string; pronouns?: string }) {
  const styles = responsiveStyle()

  return (
    <View style={styles.detail}>
      <TextH4 style={styles.field}>{field}</TextH4>
      <View style={styles.info}>
        <TextH4 numberOfLines={3} style={{ fontFamily: typography.body.regular }}>
          {value}
        </TextH4>
        {!!pronouns && pronouns.length > 0 && (
          <TextH4 style={{ marginLeft: 10, fontFamily: typography.body.regular }}>{pronouns}</TextH4>
        )}
      </View>
    </View>
  )
}

export function DetailInputField({
  label,
  field,
  form,
  signInProvider,
}: {
  signInProvider?: string
  field: FieldAttributes<InputAttributes>
  form: FormikProps<CustomerDetailFormType & { [key: string]: string }>
} & FieldAttributes<InputAttributes>) {
  const styles = responsiveStyle()

  return (
    <View style={styles.inputContainer}>
      <TextH4 style={styles.inputLabel}>{label}</TextH4>
      {field && Object.keys(omit(form.values, 'phoneNumber', 'notes')).includes(field.name) ? (
        <View style={{ width: '100%', flexBasis: '75%' }}>
          <Input
            onChangeText={form.handleChange(field.name)}
            onBlur={form.handleBlur(field.name)}
            inputContainerStyle={{ borderBottomWidth: 0, width: 'auto' }}
            containerStyle={{ width: 'auto' }}
            style={styles.inputStyle}
            placeholderTextColor="#ababab"
            defaultValue={field.value}
            errorMessage={form.touched[field.name] ? form.errors[field.name] || '' : ''}
          />
        </View>
      ) : field.name === 'phoneNumber' ? (
        <View style={{ width: '100%', flexBasis: '75%' }}>
          <PhoneInput
            editable={signInProvider !== 'phone'}
            maskHandler={form.handleChange(field.name)}
            onBlur={form.handleBlur(field.name)}
            inputContainerStyle={{
              borderWidth: 0,
              borderBottomWidth: 0,
              backgroundColor: Colors.shades[75],
            }}
            inputStyle={styles.inputStyle}
            placeholderTextColor="#ababab"
            value={field.value}
            label=""
            errorMessage={form.touched[field.name] ? form.errors[field.name] || '' : ''}
          />
        </View>
      ) : field.name === 'notes' ? (
        <View style={{ width: '100%', flexBasis: '75%' }}>
          <Input
            style={styles.textareaStyle}
            multiline
            inputContainerStyle={{ borderBottomWidth: 0, width: 'auto' }}
            containerStyle={{ width: 'auto' }}
            numberOfLines={6}
            value={field.value}
            onChangeText={form.handleChange(field.name)}
          />
        </View>
      ) : (
        <View style={{ width: '100%', flexBasis: '75%' }}>
          <Input
            defaultValue={field.value}
            onChangeText={form.handleChange(field.name)}
            onBlur={form.handleBlur(field.name)}
            inputContainerStyle={{ borderBottomWidth: 0, width: 'auto' }}
            containerStyle={{ width: 'auto' }}
            placeholderTextColor="#ababab"
            style={styles.inputStyle}
          />
        </View>
      )}
    </View>
  )
}

type AddNoteForCustomerProps = {
  setNoteEdit: (value: boolean) => void
  note: string
  farmId: string
  customerId: string
  noteCallback: (customerId: string, farmId: string) => void
}

export function AddNoteForCustomer({ farmId, customerId, setNoteEdit, note, noteCallback }: AddNoteForCustomerProps) {
  const styles = responsiveStyle()
  const [loading, setLoading] = useState(false)

  return (
    <Formik
      initialValues={{
        note: note || '',
      }}
      onSubmit={(values) => {
        setLoading(true)
        updateFarmAssociationNote(farmId, customerId, values.note).then(() => {
          setNoteEdit(false)
          noteCallback(customerId, farmId)
          setLoading(false)
        })
      }}
    >
      {({ handleSubmit, handleChange, values }) => (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <View style={{ width: '100%' }}>
            <Input
              style={styles.textareaStyle}
              multiline
              inputContainerStyle={{ borderBottomWidth: 0, width: 'auto' }}
              containerStyle={{ width: 'auto' }}
              numberOfLines={6}
              value={values.note}
              onChangeText={handleChange('note')}
            />
            <View style={{ padding: 0, flexDirection: 'row', justifyContent: 'flex-end' }}>
              <Button
                title="Cancel"
                style={{ backgroundColor: Colors.primaryGray }}
                onPress={() => setNoteEdit(false)}
              />
              <FormButton title="Save Note" loading={loading} onPress={handleSubmit} />
            </View>
          </View>
        </View>
      )}
    </Formik>
  )
}

const responsiveStyle = CreateResponsiveStyle(
  {
    sectionTitle: {
      marginBottom: 10,
    },
    hyperlink: {
      color: Colors.blue,
      textDecorationLine: 'underline',
    },
    wrapperForm: {
      paddingHorizontal: 20,
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    wrapper: {
      paddingHorizontal: 20,
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    details: {
      flex: 1,
      paddingRight: 20,
    },
    detail: {
      flexDirection: 'row',
      paddingVertical: 10,
    },
    field: {
      color: '#ababab',
      fontWeight: '400',
      flexBasis: '25%',
      fontFamily: typography.body.regular,
    },
    info: {
      fontWeight: '400',
      flexDirection: 'row',
      fontFamily: typography.body.regular,
      flexBasis: '75%',
      justifyContent: 'flex-start',
    },
    addressInfo: {
      flex: 1,
      paddingLeft: 20,
    },
    cardHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 20,
    },
    buttons: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingHorizontal: 20,
    },
    inputContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: 10,
      width: '100%',
    },
    inputLabel: {
      color: '#ababab',
      fontWeight: '400',
      fontFamily: typography.body.regular,
      margin: 0,
      padding: 0,
      flexBasis: '25%',
    },
    inputStyle: {
      backgroundColor: Colors.shades[75],
      borderRadius: 5,
      padding: 5,
      color: Colors.shades[500],
      width: '100%',
      flexBasis: '75%',
    },
    textareaStyle: {
      backgroundColor: Colors.shades[75],
      borderRadius: 5,
      padding: 5,
      color: Colors.shades[500],
      width: '100%',
    },
  },
  {
    // maxSize Medium will apply the below style overrides to medium and small devices
    [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      info: {
        fontFamily: typography.body.regular,
      },
      field: {
        fontFamily: typography.body.regular,
      },
      inputLabel: {
        fontFamily: typography.body.regular,
      },
      hyperlink: {
        fontFamily: typography.body.regular,
      },
      inputStyle: {
        fontSize: 14,
      },
      detail: {
        flexDirection: 'column',
      },
      details: {
        paddingRight: 0,
      },
      addressInfo: {
        paddingLeft: 0,
      },
      wrapperForm: {
        paddingHorizontal: 0,
        flexDirection: 'column',
      },
      buttons: {
        paddingVertical: 40,
      },
    },
  },
)
