import { Icon, Text } from '@elements'
import * as React from 'react'
import { useCallback, useRef } from 'react'
import { TouchableOpacity, View, ViewStyle } from 'react-native'

import Colors from '@/constants/Colors'
import { isMobile } from '@/constants/Layout'
import { AutoCompleteItem, useAutoComplete } from '@/hooks/useAutoComplete'
import { useDeviceSize } from '@/hooks/useLayout'

export type ActionBtn<T extends object | undefined = undefined> = T extends object
  ? { title: string; onPress: (data: T) => any; disabled?: (data: T) => boolean }
  : { title: string; onPress: () => any; disabled?: () => boolean }

type Props<T extends object | undefined = undefined> = {
  /** Array of title and onPress, for dropdown options */
  buttons: ActionBtn<T>[]
  /** Argument being passed to the onPress of buttons in onSelect */
  data?: T
  containerStyle?: ViewStyle
  autoSelectKey?: string
  /** This will decide if three dot button should be disabled */
  disabled?: boolean
}

/** Opens the auto-complete modal with a simple set of button options or one action display */
export function ActionsMenuComponent<T extends object | undefined = undefined>({
  buttons,
  data,
  containerStyle,
  autoSelectKey = 'dropdownMenu',
  disabled = false,
}: Props<T>) {
  const ref = useRef<View>(null)
  const { isSmallDevice } = useDeviceSize()
  const { autoCompleteOverlay, showAutocomplete } = useAutoComplete()

  const isMobileSmall = isSmallDevice && isMobile

  const onSelect = useCallback(
    (item: AutoCompleteItem<ActionBtn<T>['onPress']>) => {
      if (!item.data) return
      if (data !== undefined) item.data(data)
      else (item.data as ActionBtn<undefined>['onPress'])()
    },
    [data],
  )

  const pressWithOneBtn = useCallback(() => {
    if (data !== undefined) buttons[0].onPress(data)
    else (buttons[0].onPress as ActionBtn<undefined>['onPress'])()
  }, [buttons, data])

  const openMenu = useCallback(() => {
    showAutocomplete(
      autoSelectKey,
      ref,
      buttons.map((btn) => ({ text: btn.title, data: btn.onPress })),
      onSelect,
      { matchWidth: false, autoCompleteContStyle: { minWidth: 150, marginLeft: isMobileSmall ? -40 : 0 } },
    )
  }, [showAutocomplete, autoSelectKey, buttons, isMobileSmall, onSelect])

  if (buttons.length === 1) {
    return (
      <View style={containerStyle}>
        <TouchableOpacity disabled={disabled || !!buttons[0].disabled} onPress={pressWithOneBtn}>
          <Text type="bold" color={disabled || buttons[0].disabled ? Colors.shades[400] : Colors.blue}>
            {buttons[0].title}
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  return (
    <View style={containerStyle}>
      {autoCompleteOverlay}
      <TouchableOpacity onPress={openMenu} disabled={disabled}>
        <View ref={ref} collapsable={false}>
          <Icon name="ellipsis-h" solid color={disabled ? Colors.shades[300] : Colors.blue} />
        </View>
      </TouchableOpacity>
    </View>
  )
}
