import { MessageWithIcon, ToolTips } from '@components'
import { Button, ButtonClear, HeaderText, LoadingView, Spinner } from '@elements'
import { AntDesign } from '@expo/vector-icons'
import { openUrl } from '@helpers/client'
import { formatMoney } from '@helpers/display'
import { UserRole, hasGrownByPrivilege } from '@models/User'
import { AppStatus } from '@screens/AppStatus'
import * as Linking from 'expo-linking'
import { useEffect, useState } from 'react'
import { StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import { useSelector } from 'react-redux'

import { DatePicker, DateRangeNames } from '../../admin/components/Analytics/DatePicker'
import LineGraph from '../../admin/components/Analytics/LineGraph'
import Table from '../../admin/components/Analytics/Table'
import { getYearToDate } from '../../admin/components/Analytics/util'
import { EmbeddedPage } from '../../components/EmbeddedPage'
import Colors from '../../constants/Colors'
import { isLargeDevice } from '../../constants/Layout'
import { globalStyles } from '../../constants/Styles'
import { adminFarmSelector, userSelector } from '../../redux/selectors'
import { MonitorBadge } from './components/MonitorBadge'

import { useApiFx } from '@/hooks/useApiFx'
import { fetchAnalytics } from '@api/Analytics'
import { getMonitoringData } from '@api/Monitoring'
import { EventTypes } from '@models/Event'

function StarRating(data: any) {
  return (
    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
      {[1, 2, 3, 4, 5].map((i) => {
        const color = data.rating >= i ? Colors.gold : Colors.black
        return <AntDesign key={i} name="star" size={10} color={color} />
      })}
    </View>
  )
}
/** GrownBy Analytics Admin Panel */
export function AnalyticsAdmin() {
  const user = useSelector(userSelector)
  const farm = useSelector(adminFarmSelector)
  const layout = useWindowDimensions()
  const [isAllowed, setIsAllowed] = useState<boolean>()

  const [avgCustomerSpend, setAvgCustomerSpend] = useState([])
  const [farmReviews, setFarmReviews] = useState([])
  const [farmSales, setFarmSales] = useState([])
  const [productSales, setProductSales] = useState([])
  const [totalSales, setTotalSales] = useState([])

  const [farmCount, setFarmCount] = useState([])
  const [userCount, setUserCount] = useState([])
  const [totalShopViews, setTotalShopViews] = useState([])

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setIsAllowed(hasGrownByPrivilege(user, UserRole.Admin))
  }, [user])
  const [date, setDate] = useState({
    label: DateRangeNames.This_Year,
    value: getYearToDate(),
  })

  useEffect(() => {
    setLoading(true)
    fetchAnalytics('global', date.value, undefined, farm.timezone).then((res) => {
      setTotalSales(res.totalSales)
      setFarmReviews(res.farmReviews)
      setFarmSales(res.farmSales)
      setProductSales(res.productSales.slice(0, 10))
      setAvgCustomerSpend(res.avgCustomerSpend)
      setFarmCount(res.farmCount)
      setUserCount(res.userCount)
      setTotalShopViews(res.totalShopViews)
      setLoading(false)
    })
  }, [date, farm.timezone])

  const monitoringData = useApiFx(getMonitoringData, [])

  return (
    <AppStatus scope="farmer">
      <ButtonClear icon="arrow-left" title="Back to App" url="/" />
      <LoadingView style={globalStyles.flex1} loading={isAllowed === undefined}>
        {isAllowed ? (
          <>
            <View style={styles.outerContainer}>
              <HeaderText>Events Monitoring:</HeaderText>
              <View style={styles.monitoringContainer}>
                <MonitorBadge
                  data={monitoringData.data?.eventCounts[EventTypes.EbtCardChangeAttempt]}
                  loading={monitoringData.loading}
                  title="EBT Card Change"
                  tooltipId={ToolTips.EBT_CARD_CHANGE}
                  timeWindow="Last 7 Days"
                />
                <MonitorBadge
                  data={monitoringData.data?.eventCounts[EventTypes.EbtBalanceInquiry]}
                  loading={monitoringData.loading}
                  title="EBT Balance Inquiry"
                  tooltipId={ToolTips.EBT_BALANCE_INQUIRIES}
                  timeWindow="Last 7 Days"
                />
                <MonitorBadge
                  data={monitoringData.data?.eventCounts[EventTypes.EbtLargeTransaction]}
                  loading={monitoringData.loading}
                  title="Invalid EBT Card Attempt"
                  tooltipId={ToolTips.EXCESSIVELY_LARGE_EBT_TRANSACTIONS}
                  timeWindow="Last 7 Days"
                />
                <MonitorBadge
                  data={monitoringData.data?.EBTduplicates}
                  loading={monitoringData.loading}
                  title="Sharing EBT Card"
                  tooltipId={ToolTips.TWO_ACCOUNTS_SHARING_EBT_CARD}
                  timeWindow="Now"
                />
                <MonitorBadge
                  data={monitoringData?.data?.userSignUpsLastWeek}
                  loading={monitoringData.loading}
                  title="Last Week Sign Ups"
                  tooltipId={ToolTips.SIGN_UPS}
                  timeWindow="Last 7 Days"
                />
                <MonitorBadge
                  data={monitoringData.data?.eventCounts[EventTypes.EbtPurchase]}
                  loading={monitoringData.loading}
                  title="EBT Daily Transactions"
                  tooltipId={ToolTips.TOO_MANY_EBT_TRANSACTIONS_IN_A_DAY}
                  timeWindow="Last 7 Days"
                />
              </View>
            </View>
            <View style={styles.box}>
              <HeaderText>All Time:</HeaderText>
              <View
                style={{
                  height: 300,
                  width: '100%',
                  flexDirection: isLargeDevice(layout) ? 'row' : 'column',
                  overflow: 'hidden',
                }}
              >
                <EmbeddedPage
                  uri="https://datastudio.google.com/embed/reporting/a995a7a6-8128-4abe-bc43-e15296f66ffb/page/Sv0uC"
                  width={isLargeDevice(layout) ? '50%' : '100%'}
                  height="100%"
                />
                <EmbeddedPage
                  uri="https://datastudio.google.com/embed/reporting/91db412d-49d4-4ca4-b61c-2f1475d73e82/page/Cd0uC"
                  width={isLargeDevice(layout) ? '50%' : '100%'}
                  height="100%"
                />
              </View>
              <View style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 10 }}>
                <HeaderText size={30}>Analytics</HeaderText>
                <DatePicker
                  date={date}
                  timezone={farm.timezone}
                  setDate={(name, date) => setDate({ label: name, value: date })}
                />
              </View>
              {loading ? (
                <View style={{ height: '100%', width: '100%', justifyContent: 'center' }}>
                  <Spinner size="large" color={Colors.green} />
                </View>
              ) : (
                <View>
                  <View
                    style={{
                      height: '100%',
                      alignItems: 'center',
                      flexDirection: isLargeDevice(layout) ? 'column' : 'column',
                    }}
                  >
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                      <LineGraph data={farmCount} title="Farms" dateFormat={date.value.dateTrunc} hasPastData />
                      <LineGraph data={userCount} title="Users" dateFormat={date.value.dateTrunc} hasPastData />
                    </View>
                    <LineGraph
                      data={totalShopViews}
                      title="Total Shop Views"
                      dateFormat={date.value.dateTrunc}
                      hasPastData
                    />
                    <View style={{ width: '100%' }}>
                      <Table
                        title="Farm Reviews"
                        values={farmReviews}
                        styles={{
                          farm: { width: '30%' },
                          date: { width: '15%' },
                          rating: { textAlign: 'right', width: '10%' },
                        }}
                        hidden={['farmId']}
                        customComponent={{ rating: StarRating }}
                        showHeaders
                        exportCsv
                      />
                    </View>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                      <LineGraph data={totalSales} title="Total Sales" dateFormat={date.value.dateTrunc} hasPastData />
                      <LineGraph
                        data={avgCustomerSpend}
                        title="Average Customer Spend"
                        dateFormat={date.value.dateTrunc}
                        hasPastData
                      />
                    </View>
                    <View style={{ width: '100%' }}>
                      <Table
                        title="Most Popular Products"
                        values={productSales}
                        styles={{
                          number_sold: { textAlign: 'right', width: '10%' },
                          farm: { marginRight: '10%', width: '30%' },
                          product_name: { width: '40%', marginRight: '5%' },
                        }}
                        hidden={['id']}
                        showHeaders
                        exportCsv
                      />
                    </View>
                    <View style={{ width: '100%' }}>
                      <Table
                        title="Farm Shop Sales"
                        values={farmSales.map((x: any) => ({ ...x, gross_sales: formatMoney(x.gross_sales) }))}
                        styles={{ farm: { width: '60%' }, gross_sales: { textAlign: 'right' } }}
                        showHeaders
                        exportCsv
                      />
                    </View>
                  </View>
                </View>
              )}
            </View>
          </>
        ) : (
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <MessageWithIcon icon="user-times" title="Off Limits">
              <Text>It looks like you don't have permission to access this page.</Text>
              <Button title="Home" onPress={() => openUrl(Linking.createURL('/'))} />
            </MessageWithIcon>
          </View>
        )}
      </LoadingView>
    </AppStatus>
  )
}

const styles = StyleSheet.create({
  box: {
    flex: 1,
    marginHorizontal: 10,
    padding: 20,
  },
  monitoringContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginVertical: 20,
  },
  outerContainer: {
    flexDirection: 'column',
    maxWidth: '100%',
    marginHorizontal: 40,
    marginVertical: 20,
  },
})
