import { useLayout } from '@/hooks/useLayout'
import { Button, ErrorText } from '@elements'
import { useContext } from 'react'
import { View } from 'react-native'
import { globalStyles } from '../../../constants/Styles'
import { PaymentOptionsContext } from './PaymentOptionsContext'
import { PaymentOptionsModalContext } from './PaymentOptionsModalContext'
import { displaySplitTenderErrors } from './helpers/validation'

/** This component will show the submit button and any errors that may exist */
export function SubmitPaymentButton() {
  const { isValid, validationError } = useContext(PaymentOptionsContext)
  const modalContext = useContext(PaymentOptionsModalContext)
  const { bottom } = useLayout()

  const submitPaymentConfiguration = () => {
    modalContext.setShowModal(false)
  }

  return (
    <View style={{ marginBottom: bottom }}>
      <Button title="Confirm Payment Methods" onPress={submitPaymentConfiguration} disabled={!isValid} />
      {!isValid && (
        <ErrorText style={globalStyles.marginHorizontal10} size={12}>
          {displaySplitTenderErrors(validationError).message}
        </ErrorText>
      )}
    </View>
  )
}
