import { AvailAddonResult } from '@helpers/addons'
import { EndpointMap } from '@shared/types/v2/endpoint'
import { callEndpoint } from './v2'

/** Gets the available addons for a cart */
export async function fetchAvailAddons(
  opts: EndpointMap['v2.Product.fetchAvailAddonsService']['request'],
): Promise<AvailAddonResult[]> {
  return callEndpoint('v2.Product.fetchAvailAddonsService', opts)
}
