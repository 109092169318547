import { isArray, isTruthy, removeUndefined } from '@helpers/helpers'
import { isEncodedTime } from '@helpers/time'
import { Cart } from '@models/Cart'
import { CartItem } from '@models/Order'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { Discount } from '@models/Coupon'
import { marshalCSA, unmarshalCSA } from './CSA'
import { marshalCoupon, marshalPromoCode, unmarshalCoupon, unmarshalPromoCode } from './Coupons'
import { marshalDistribution, unmarshalDistribution } from './Distribution'
import { marshalPaymentSchedule, marshalProduct, unmarshalPaymentSchedule, unmarshalProduct } from './Product'
import { marshalDate, unmarshalDate, WithMarshalledDates } from './Time'
import { prepareMarshal, prepareUnmarshal } from './encoding'

/** marshalCart returns an encoded value. */
export function marshalCart(cart: Partial<Cart>): WithMarshalledDates<Cart> {
  const data = prepareMarshal(cart) as unknown as WithMarshalledDates<Cart>

  if (cart.items) {
    const items: Record<string, WithMarshalledDates<CartItem>> = {}
    for (const id in cart.items) {
      items[id] = marshalCartItem(cart.items[id])
    }
    data.items = items
  }

  if (cart.discounts) {
    const marshaledDiscounts: { [id: string]: WithMarshalledDates<Discount> } = {}

    for (const id in cart.discounts) {
      const discount = cart.discounts[id]
      if (!discount) continue

      const { coupon, promo } = discount
      marshaledDiscounts[id] = {
        coupon: marshalCoupon(coupon),
      }
      if (promo) marshaledDiscounts[id].promo = marshalPromoCode(promo)
    }

    data.discounts = marshaledDiscounts
  }

  return removeUndefined(data)
}

/** unmarshalCart returns a decoded value. */
export function unmarshalCart(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): Cart {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)

  const cart = { ...data, id } as Cart

  const unmarshaledItems: Cart['items'] = {}
  for (const item of Object.values(cart.items)) {
    unmarshaledItems[item.id] = unmarshalCartItem(item)
  }

  const unmarshaledDiscounts: Cart['discounts'] = {}
  if (data.discounts) {
    for (const id in data.discounts) {
      const discount = data.discounts[id]
      if (!discount) continue

      const { coupon, promo } = discount
      unmarshaledDiscounts[id] = { coupon: unmarshalCoupon(coupon) }
      if (promo) unmarshaledDiscounts[id]!.promo = unmarshalPromoCode(promo)
    }
  }

  return { ...cart, id, items: unmarshaledItems, discounts: unmarshaledDiscounts }
}

/** encodes a cart item */
export function marshalCartItem(item: Partial<CartItem>): WithMarshalledDates<CartItem> {
  const data = { ...item } as DocumentData
  if (item.pickups) data.pickups = item.pickups.map((date) => marshalDate(date))
  if (item.paymentSchedule) data.paymentSchedule = marshalPaymentSchedule(item.paymentSchedule)
  if (item.product) data.product = marshalProduct(item.product, true)
  if (item.csa) data.csa = marshalCSA(item.csa, true)
  if (item.distribution) data.distribution = marshalDistribution(item.distribution, true)
  return removeUndefined(data) as WithMarshalledDates<CartItem>
}

/** decodes a cart item */
export function unmarshalCartItem(data: DocumentData): CartItem {
  const item = { ...data } as CartItem
  if (data.pickups && isArray(data.pickups))
    item.pickups = data.pickups.map((date) => isEncodedTime(date) && unmarshalDate(date)).filter(isTruthy)
  if (data.paymentSchedule) item.paymentSchedule = unmarshalPaymentSchedule(data.paymentSchedule)
  if (data.product) item.product = unmarshalProduct(item.product.id as string, data.product)
  if (data.csa) item.csa = unmarshalCSA(data.csa.id as string, data.csa)
  if (data.distribution) item.distribution = unmarshalDistribution(data.distribution.id as string, data.distribution)
  return item
}
