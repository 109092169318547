import { memo } from 'react'
import YouTubeWeb, { YouTubeProps } from 'react-youtube'

export interface PlayerProps {
  height: number
  width: number
  videoId: string
}

const mapWebProps = ({ videoId, height, width }: PlayerProps): YouTubeProps => ({
  videoId,
  opts: {
    height: (height || '').toString(),
    width: (width || '').toString(),
  },
})

export const Youtube = memo(function Youtube(props: PlayerProps) {
  return <YouTubeWeb {...mapWebProps(props)} />
})
