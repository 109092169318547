import { Logger } from '@/config/logger'
import { auth } from '@api/db'
import { Toast } from '@elements'
import {
  multiFactor,
  onAuthStateChanged,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  User,
} from 'firebase/auth'
import { useCallback, useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import { UseMultiFactorType } from './types'

const RECAPTCHA_CONTAINER = 'recaptcha-container'

/** Web version of the user 2FA enrollment handling */
export const useMultiFactor = (): UseMultiFactorType => {
  const recaptchaVerifierRef = useRef<RecaptchaVerifier | null>(null)
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [verificationId, setVerificationId] = useState('')
  const [isEnrolled, setIsEnrolled] = useState(false)

  useEffect(() => {
    recaptchaVerifierRef.current = new RecaptchaVerifier(RECAPTCHA_CONTAINER, { size: 'invisible' }, auth().getAuth())

    /// This subscription is needded in order to handle page refresh on web, as the user will be undefined initially
    const unsubscribe = onAuthStateChanged(auth().getAuth(), (user) => {
      setCurrentUser(user)
      setIsEnrolled(isUserEnrolled(user))
    })

    return () => {
      unsubscribe()
    }
  }, [])

  /** This function is used to enroll the user with multi-factor, it will send a verification code to the user */
  const onSendCode = useCallback(
    async (phone: string) => {
      try {
        if (!currentUser) return

        const multiFactorSession = await multiFactor(currentUser).getSession()
        // Specify the phone number and pass the MFA session.
        const phoneInfoOptions = {
          phoneNumber: phone.replace(' ', ''),
          session: multiFactorSession,
        }
        const phoneAuthProvider = new PhoneAuthProvider(auth().getAuth())

        // Send SMS verification code and get verificationId
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifierRef.current!,
        )

        setVerificationId(verificationId)

        Toast('Verification code sent.')
      } catch (e: any) {
        //reset reCAPTCHA if error
        recaptchaVerifierRef.current!.clear()
        Logger.error(e)
        throw e
      }
    },
    [currentUser],
  )

  /** Once the user receives their code to setup multi-factor login they will enter it here */
  const onConfirmCode = useCallback(
    async (code: string) => {
      if (!currentUser) return
      try {
        // Ask user for the verification code and create credential
        const cred = PhoneAuthProvider.credential(verificationId, code)
        // Assert credential
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)

        // Complete enrollment multiFactor
        await multiFactor(currentUser).enroll(multiFactorAssertion)

        Toast('Phone number verified.')
      } catch (e) {
        Logger.error(e)
        throw e
      }
    },
    [currentUser, verificationId],
  )

  /** unEnroll will un-enroll the user from multi-factor login*/
  const onUnEnroll = useCallback(async () => {
    if (!currentUser) return

    try {
      const multiFactorUser = multiFactor(currentUser)
      // Present user the option to choose which factor to un-enroll.
      await multiFactorUser.unenroll(multiFactorUser.enrolledFactors[0])
      setIsEnrolled(false)

      Toast('2-Factor login has been removed from your account.')
    } catch (e) {
      Logger.error(e)
      throw e
    }
  }, [currentUser])

  return {
    onUnEnroll,
    onConfirmCode,
    onSendCode,
    isEnrolled,
    /** On web, enrollment process is available */
    canBeUnEnrolled: true,
  }
}

/** The RecaptchaContainer renders an empty div that will be used as a
 * place that the recaptcha will be inserted into.
 * This is for web only, and it is important to note that collapsable must be false or react-native-web will try and remove this while rendering since it is an empty div.
 **/
export function RecaptchaContainer() {
  return <View nativeID={RECAPTCHA_CONTAINER} collapsable={false} />
}

const isUserEnrolled = (user: User | null) => {
  if (!user) return false
  return multiFactor(user).enrolledFactors.length > 0
}
