import { Event } from '@models/Event'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { marshalDate, unmarshalDate } from './Time'
import { prepareMarshal, prepareUnmarshal } from './encoding'

/** marshalEvent returns the event structure for Firestore. */
export function marshalEvent(event: Partial<Event>): DocumentData {
  const data = prepareMarshal(event) as DocumentData

  if (event.expire) {
    data.expire = marshalDate(event.expire)
  }
  if (event.time) {
    data.time = marshalDate(event.time)
  }
  return data
}

/** unmarshalEvent returns a event created from the Firestore data structure. */
export function unmarshalEvent(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): Event {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)
  const event = { ...data, id } as Event

  if (data.expire) {
    event.expire = unmarshalDate(data.expire)
  }
  if (data.time) {
    event.time = unmarshalDate(data.time)
  }

  return event
}
