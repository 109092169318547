import { FormBuilder } from '@components'
import { View, ViewProps, ViewStyle } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

/**
 * @deprecated because it is an extra wrapper for the form builder which is deprecated
 */
export default function FormWrapper({
  children,
  row,
  style,
}: {
  children: (JSX.Element | null | false)[] | JSX.Element | false
  row?: boolean
  style?: ViewStyle
}) {
  const styles = responsiveStyle()
  return (
    <FormBuilder row={row} style={[styles.formWrapper, style]}>
      {children}
    </FormBuilder>
  )
}

/** Simple wrapper for the product form that replaces the FormWrapper and helps us migrate away from it without putting this style on each component. After we are not using the FormWrapper anymore, this can be removed and the style can be added directly to the ProductForm. */
export function ProductFormWrapper({ style, ...props }: ViewProps) {
  const styles = responsiveStyle()
  return <View style={[styles.formWrapper, style]} {...props} />
}

const responsiveStyle = CreateResponsiveStyle(
  {
    formWrapper: {
      paddingHorizontal: 25,
      paddingBottom: 40,
    },
  },
  {
    [maxSize(DEVICE_SIZES.SM)]: {
      formWrapper: {
        paddingHorizontal: 0,
      },
    },
  },
)
