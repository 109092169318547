import { isNavUrl } from '@helpers/client'
import { BaseSyntheticEvent, memo } from 'react'
import { GestureResponderEvent, TouchableOpacity, TouchableOpacityProps } from 'react-native'

import { TouchableExternalLink, TouchableExternalLinkProps } from './TouchableExternalLink'
import { TouchableNavLink, TouchableNavLinkProps } from './TouchableNavLink'

export type TouchableProps<E extends BaseSyntheticEvent = GestureResponderEvent> = Omit<
  TouchableOpacityProps,
  'onPress'
> & {
  /** onPress may be used for handling either navigation (If url not provided), or for handling a side-effect (If url is provided) */
  onPress?: (e: E) => any
  /** Url may be an internal or external link */
  url?: string
  /** Navigation action that customizes the way navigation navigates to the url. (Used only if the url is an internal link) */
  navAction?: TouchableNavLinkProps['action']
  /** Options for opening external web url (Used only if the url is an external link) */
  webOpts?: TouchableExternalLinkProps['webOpts']
}

/** A Touchable capable of handling urls in all platforms.
 * - You should avoid passing both a url and a navigate() call in the onPress. If using a url, it will be converted to a navigate helper, so navigate() would get called twice. You can still use onPress for side-effects, in tandem with url navigation.
 * - If you avoid passing a url on mobile, you avoid calling useLinkProps(), which will increase performance; and you ultimately don't need a url for mobile navigation. direct navigate() works better than urls on mobile.
 */
export const Touchable = memo(function Touchable({
  url,
  navAction,
  onPress,
  webOpts,
  children,
  ...touchableProps
}: TouchableProps) {
  if (url) {
    // if external link...
    if (!isNavUrl(url)) {
      return (
        <TouchableExternalLink {...touchableProps} children={children} url={url} onPress={onPress} webOpts={webOpts} />
      )
    }
    //If it's a navigation link, use the dedicated component for useLinkProps()
    return <TouchableNavLink {...touchableProps} children={children} onPress={onPress} to={url} action={navAction} />
  }
  //Regular touchable if no url
  return (
    <TouchableOpacity
      {...touchableProps}
      onPress={(e) => {
        e.preventDefault()
        e.stopPropagation()
        onPress?.(e)
      }}
    >
      {children}
    </TouchableOpacity>
  )
})
