import { DateTime, Settings as LuxonSettings } from 'luxon'

import { apiVersionSnapshot } from '@api/App'
import { loadFeatures } from '@api/FeatureFlag'
import { VersionBlocker } from '@components'
import { Modal } from '@elements'
import { openUrl } from '@helpers/client'
import { SignedInState } from '@models/User'
import * as Linking from 'expo-linking'
import * as Notifications from 'expo-notifications'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import env, { updatesAppVersion } from '../../config/Environment'
import Analytics from '../../config/FBAnalytics'
import { isWeb, setOrientation } from '../Layout'
import { isValidAPIVersionUsing } from '../Version'
import { useAutomaticUpdates } from './useAutomaticUpdates'
import { useConstantAppData } from './useConstantAppData'
import { useSetAvailAddons } from './useSetAvailAddons'
import { useSetCartService } from './useSetCartService'
import { useSetLayout } from './useSetLayout'

import Sentry from '@/config/Sentry'
import { Logger } from '@/config/logger'
import { MAX_SESSION_DURATION } from '@/constants/CONSTANTS'
import { useCancelableFx } from '@/hooks/useCancelablePromise'
import { SET_SIGNEDIN_TIME } from '@/redux/actions/actionTypes'
import { setAdminFeaturesAvailable } from '@/redux/actions/adminState'
import { loadFarmAssociations, loadNotifications } from '@/redux/actions/appPersist'
import { setFeaturesAvailable, setListeners } from '@/redux/actions/appState'
import signout from '@/redux/actions/signout'
import { onUserAuthChange, setUserListener } from '@/redux/actions/user'
import {
  adminFarmIdSelector,
  hasListenersSelector,
  navPropsSelector,
  userSelector,
  wholesaleSelector,
} from '@/redux/selectors'
import { getStore } from '@/redux/store'
import loadGoogleMapsApi from 'load-google-maps-api'
import { LogBox } from 'react-native'
import { useSetWholesale } from './useSetWholesale'

/** Sets global snapshot listeners for the signed in user account.
 * Warning: Having too many global listeners not managed properly may produce the error "Excessive number of pending callbacks" */
export const useConsumerListeners = () => {
  const dispatch = useDispatch()
  const hasListeners = useSelector(hasListenersSelector)

  /**
   * - Sets the global listeners for the signed in user account.
   * - if the user has signed in, it will check if the session exceeded the max duration and signout if so.
   */
  useEffect(() => {
    //Warning: Having more instances of `onUSerAuthChange` around the app may produce hard to debug "excessive pending callbacks" errors. This should be the only instance of this listener, and no others should be required
    return onUserAuthChange(({ state, userId }) => {
      const signedInTime = getStore().getState().appPersist.signedInTime

      const hasTimeRemaining = Boolean(
        signedInTime && signedInTime.plus({ milliseconds: MAX_SESSION_DURATION }) > DateTime.now(),
      )

      if (state === SignedInState.SIGNEDIN && !hasListeners && (!signedInTime || hasTimeRemaining)) {
        if (signedInTime === undefined) {
          //If signedInTime is undefined, we set it. Otherwise, we leave it as-is. We should not overwrite it again every time we reload the user data
          dispatch({ type: SET_SIGNEDIN_TIME, payload: DateTime.now() })
        }
        dispatch(setUserListener(userId))
        dispatch(loadFarmAssociations(userId))
        dispatch(loadNotifications(userId))
        dispatch(setListeners(true))
      } else if (state === SignedInState.SIGNEDIN && !hasTimeRemaining) {
        signout()
      } else if (state === SignedInState.SIGNEDOUT && getStore().getState().user?.id) {
        /** https://github.com/farmgenerations/grownby/issues/8099 */
        /** Firesbase internally will sign the user out, and this cause any page that requires user authorized or permission fails , so when user is signed out internally, we need to sign user out completely, and this could help to solve the cache issue. When you manually clear cookies or cache, you will be signed out. Thus, whenever state is SIGNEDOUT we need to fully sign out user  */
        // Note this will trigger signout to be called twice on every signout which should be ok.
        signout()
      }
    })
  }, [hasListeners, dispatch])
}

/** Initialize error analytics and auth tracking */
export const useMonitorUserServices = () => {
  const user = useSelector(userSelector)

  useEffect(() => {
    if (user) {
      Sentry.setUser(user)
      Analytics.setUser(user)
    }
  }, [user])
}

export const useSetFeaturesContext = () => {
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const adminFarmId = useSelector(adminFarmIdSelector)
  const { farm } = useSelector(navPropsSelector)

  /** Sets the features available for the current admin farm */
  useCancelableFx(
    async (isCurrent) => {
      try {
        const features = await loadFeatures(adminFarmId, user.role)
        if (!isCurrent) return

        dispatch(setAdminFeaturesAvailable(features))
      } catch (err) {
        Logger.error(err)
      }
    },
    [adminFarmId, user.role, dispatch],
  )

  /** Sets the features available for the current farm in consumer screens */
  useCancelableFx(
    async (isCurrent) => {
      try {
        const features = await loadFeatures(farm?.id, user.role)
        if (!isCurrent) return

        dispatch(setFeaturesAvailable(features))
      } catch (err) {
        Logger.error(err)
      }
    },
    [farm?.id, user.role, dispatch],
  )
}

export const useVersionBlocker = () => {
  /** Enable the version blocker */
  useEffect(() => {
    // The reason we use updates version instead of native version, is we will only adjust the version in Firestore if we want to block old clients from connecting, so we would push an OTA or native build with the new version and it would block. If there is only a minor update the app will request them to restart to update. If it is a native change they will need to download the new app from the Store, with link from this version blocker Modal.
    return apiVersionSnapshot((required) => {
      const isValid = isValidAPIVersionUsing(updatesAppVersion, required)
      // Make sure app version is valid
      if (!isValid) Modal(<VersionBlocker />, { header: false, dismissable: false, webWidth: 400 })
    })
  }, [])
}

/** Listen for and handle push notification events.
 * - NOTE: This hook is not part of the main useAppInitialization because it was originally intended for the App.tsx, whereas the rest of the hooks here are intended for the Navigation container
 */
export const useNotifications = () => {
  useEffect(() => {
    Notifications.setNotificationHandler({
      handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: false,
        shouldSetBadge: false,
      }),
    })
  }, [])

  useEffect(() => {
    const handleReceivedNotification = async (notification: Notifications.Notification) => {
      const data = notification.request.content.data
      if (data.url) {
        const url = Linking.createURL(data.url as string)
        await openUrl(url)
      }
    }
    const subscription = Notifications.addNotificationReceivedListener(handleReceivedNotification)

    return () => Notifications.removeNotificationSubscription(subscription)
  }, [])
}

export const useSetOrientation = () =>
  useEffect(() => {
    setOrientation()
  }, [])

/** Loads the google api library which is needed by places autocomplete in web */
export const useGoogleMapsApi = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!isWeb) return setLoading(false)

    loadGoogleMapsApi({ libraries: ['places'], key: env.API_KEY }).then(() => setLoading(false))
  }, [])

  return loading
}

/** Includes all the services necessary to initialize the app.
 * - Returns a boolean that specifies the initialization state. When the return state is true the app is ready */
export function useAppInitialization(): boolean {
  const { isWholesale } = useSelector(wholesaleSelector)

  useEffect(() => {
    // if (process.env.NODE_ENV === 'development') {
    //   const whyDidYouRender = require('@welldone-software/why-did-you-render')
    //   whyDidYouRender(React, {
    //     trackAllPureComponents: true,
    //   })
    // }

    Sentry.initialize()

    // This setting tells Luxon to throw an error when there is an invalid date that we are attempting to work with.
    LuxonSettings.throwOnInvalid = true

    LogBox.ignoreLogs([
      'Setting a timer',
      'Non-serializable values were found in the navigation state',
      'source.uri should not be an empty string',
      // We have a lot of these warnings showing up, however there are no more issues with this in our app. So for now
      'StyleSheet.compose(a, b) is deprecated',
    ])
  }, [])

  useSetOrientation()
  useSetAvailAddons()
  useSetCartService({ cartServiceType: 'consumer', isWholesale })
  useAutomaticUpdates()
  useConstantAppData()
  useConsumerListeners()
  const isWholesaleInit = useSetWholesale()
  useMonitorUserServices()
  useSetFeaturesContext()
  useVersionBlocker()
  const loadingLayout = useSetLayout()
  const loadingGoogleApi = useGoogleMapsApi()

  /** These boolean states should be limited to initialization actions that need to be performed only once for a client session, and should be done before rendering the UI. It should not include booleans expected to change regularly throughout the normal course of the app.
   * - For example it shouldn't include loadingCart [true|false], because it will change whenever an item is added to the cart
   * - For example it should include loadingGoogleApi because that will be in a loading state:true only once at the beginning of a new client session, and is needed before the app is allowed to run. */
  const isAppInitialized = !loadingLayout && !loadingGoogleApi && isWholesaleInit

  return isAppInitialized
}
