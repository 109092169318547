import { Icon, TextH4, Touchable, TouchableProps, typography } from '@elements'
import { memo } from 'react'

import Colors from '../../constants/Colors'
import { DeviceSizers } from '../../hooks/useLayout'

import { useSizeFnStyles } from '@/hooks/useFnStyles'

export const BackTo = memo(function BackTo({
  title,
  style,
  ...touchableProps
}: {
  title: string
} & TouchableProps) {
  const styles = useStyles()

  return (
    <Touchable style={[styles.backCont, style]} {...touchableProps}>
      <Icon color={Colors.black} style={styles.icon} name="arrow-left" size={18} />
      <TextH4 style={styles.h4}>{title}</TextH4>
    </Touchable>
  )
})

// At least pulling this out helps with the issue of it being separated from the UI
const useStyles = () =>
  useSizeFnStyles(({ isLargeDevice, isMedDevice }: DeviceSizers) => ({
    icon: { marginRight: isLargeDevice ? 25 : 15 },
    h4: { fontFamily: typography.body.regular },
    backCont: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: isLargeDevice ? 50 : isMedDevice ? 25 : 10,
      justifyContent: 'flex-start',
    },
  }))
