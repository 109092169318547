import { useLinkProps } from '@react-navigation/native'
import { memo, useCallback, useMemo } from 'react'
import { GestureResponderEvent, TouchableOpacity } from 'react-native'

import { TouchableNavLinkProps } from './TouchableNavLink'

import { useDeepCompareMemoize } from '@/hooks/useDeepEqualEffect'

/** Used for navigation with react-navigation deep links. Similar to a customized react-navigation <Link /> */
export const TouchableNavLink = memo(function TouchableNavLink({
  to,
  action,
  onPress,
  children,
  style,
  ...touchableProps
}: TouchableNavLinkProps) {
  /** This approach to navigation in web creates a situation where both the href and onPress navigation call are present, and apparently the onPressNav takes precedence over the href. If the href is passed alone it gets handled by reloading the browser as if it were an external link, while either onClick alone, or both onClick and href produce the in-app navigation feel */
  const toMemo = useMemo(() => to, [useDeepCompareMemoize(to)])
  const actionMemo = useMemo(() => action, [useDeepCompareMemoize(action)])
  const { onPress: navigate, ...navLinkProps } = useLinkProps({ to: toMemo, action: actionMemo })

  return (
    <TouchableOpacity
      {...navLinkProps}
      {...touchableProps}
      style={style}
      onPress={useCallback(
        (e: GestureResponderEvent) => {
          navigate(e)
          //EVENT STOPPAGE MUST GO AFTER NAVIGATe, or navigation won't work.
          e.stopPropagation()
          e.preventDefault()
          // onPress passed from Touchable (used for side-effects)
          onPress?.(e)
        },
        [onPress, navigate],
      )}
    >
      {children}
    </TouchableOpacity>
  )
})
