import { withAdminAuth } from '@/hooks/withAdminAuth'
import { withAdminIndexHooks } from '@/hooks/withAlgoliaIndex'
import { AccessRight, Permission } from '@helpers/Permission'
import { OrderCreatorScreenContext } from './OrderCreatorScreen.helper'
import { OrderCreatorScreenUI } from './OrderCreatorScreenUI'
import { useOrderCreatorScreenData } from './useOrderCreatorScreenData'

function OrderCreatorScreenComp() {
  const data = useOrderCreatorScreenData()

  return (
    <OrderCreatorScreenContext.Provider value={data}>
      <OrderCreatorScreenUI />
    </OrderCreatorScreenContext.Provider>
  )
}

export const OrderCreatorScreen = withAdminAuth(
  withAdminIndexHooks(OrderCreatorScreenComp),
  Permission.Orders,
  AccessRight.Edit,
)
