import { disableAddCartBtn, getCartItemForAddCartBtn, makeTestIdPrefix } from '@components'
import { Toast } from '@elements'
import { DigitalProduct, Standard, Unit, isStandard } from '@models/Product'
import { memo, useCallback, useMemo } from 'react'

import { useCartService } from '../../../../../../hooks/useCart'

import { CartServiceType } from '@/constants/types/cartService'
import { useAddToCartFlow } from '@/hooks/useCart/addToCartFlow/useAddToCartFlow'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { getUnits } from '@helpers/products'
import { Distribution } from '@models/Distribution'
import { DateTime } from 'luxon'
import { AdminAddCartBtnUI } from './AddCartBtnUI'
import { getUnadjustedQuantity } from '@helpers/cart'

type AddBtnStandardProps_Reusable = {
  prod: Standard | DigitalProduct
  /** This 'unit' should come from the StandardRow. It is optional because in that case the unit selection will be part of the addCart flow */
  unit?: Unit
  isWholesale: boolean
  schedule: Distribution | undefined
  pickupDate: DateTime | undefined
  cartServiceType: CartServiceType
}

export const AddBtnStandard_Reusable = memo(function AddBtnStandard_Reusable({
  prod,
  unit: unitProp,
  isWholesale,
  schedule,
  pickupDate,
  cartServiceType,
}: AddBtnStandardProps_Reusable) {
  const farmId = prod.farm.id

  const cartSrv = useCartService({
    cartServiceType,
    farmId,
    isWholesale,
  })

  const { addToCartFlow, isLoadingAddCartFlow } = useAddToCartFlow({
    cartServiceType,
    isWholesale,
  })

  /** The buying option for this addCart button */
  const unit = useMemo(() => {
    if (unitProp) return unitProp
    const unitsForCatalog = getUnits(prod, { isWholesale })

    // When no unit prop is passed, but there's a single unit for this catalog, it should use it
    return unitsForCatalog.length === 1 ? unitsForCatalog[0] : undefined
  }, [unitProp, prod, isWholesale])

  const itemInCart = useMemo(() => getCartItemForAddCartBtn(prod, cartSrv.cart, unit), [prod, cartSrv, unit])

  const updateQuantity = useCallback(
    async (delta: number) => {
      if (!itemInCart) return
      try {
        const prevQuantity = getUnadjustedQuantity(itemInCart)
        await cartSrv.updateQuantity(itemInCart.id, prevQuantity + delta)
      } catch {
        Toast('Something went wrong while updating the item quantity')
      }
    },
    [itemInCart, cartSrv],
  )

  const disableAdd = useMemo(
    () =>
      // This call to disableAddCartBtn should absolutely be memoized because it involves a pickup calculation on db products
      disableAddCartBtn({
        prod,
        cart: cartSrv.cart,
        isWholesale,
        unitProp: unit,
        mode: cartSrv.isAdmin ? 'admin' : 'consumer',
      }),
    [prod, cartSrv.cart, cartSrv.isAdmin, isWholesale, unit],
  )

  /** If this is defined, the addToCart flow will skip the date selection step and add these dates automatically.
   * - If there is a pickupDate selected as filter, we want to add the product with this date only and the date selection modal skipped for quick-adding.
   * - The conditions: Must be a Standard either in wholesale mode or whose minPickups are either 1 or none. Otherwise this should be undefined because adding a single date would violate the minPickups restriction in retail mode. */
  const preSelectedPickups = useMemo(() => {
    if (!pickupDate || !isStandard(prod) || (!isWholesale && (prod.minPickups ?? 1) > 1)) return undefined
    else return [pickupDate]
  }, [pickupDate, prod, isWholesale])

  const onAddPress = useCallback(
    () =>
      addToCartFlow({
        product: prod,
        unit,
        distribution: schedule,
        pickups: preSelectedPickups,
      }),
    [prod, unit, schedule, preSelectedPickups, addToCartFlow],
  )

  if (!unit) {
    // This button might not receive a unit if it's the one on the main row, because the units go into the sub rows
    return null
  }

  return (
    <AdminAddCartBtnUI
      addToCart={onAddPress}
      disableAdd={disableAdd}
      isLoadingAddCartFlow={isLoadingAddCartFlow}
      isLoadingCart={cartSrv.loadingCart}
      itemInCart={itemInCart}
      updateQty={updateQuantity}
      key={makeTestIdPrefix(prod, unit)}
    />
  )
},
propsAreDeepEqual)
