import { Layout } from '../../constants/types'

type HeightType = 'proportional' | 'layout'

/** Aspect ratio used by most images in the app */
export const ASPECT_RATIO = 3 / 2

export type RatioHeightProps = {
  layout: Layout
  width?: number
  ratio?: number
  heightType?: HeightType
}

/** If passing a width, it'll get the height from that width. Else it will get the height from the layout width
 * - Can be used anywhere in the app for getting the correct height of some image.
 * - heightType can be customized based on the need. "proportional" is the same as ratio. "layout" will be 40% of the layout height.
 * */
export const getRatioHeight = ({ layout, width, ratio, heightType }: RatioHeightProps) => {
  // Need to make this a prop
  const aspectRatio = ratio ?? ASPECT_RATIO
  const proportionalHeight = (width ?? layout.width) / aspectRatio
  const layoutHeight = layout.height * 0.4

  return heightType
    ? heightType === 'proportional'
      ? proportionalHeight
      : layoutHeight
    : Math.min(proportionalHeight, layoutHeight)
}
