import { DeepPartial } from 'redux'

import { deepClone, isArray, isInstance, isObject } from './helpers'
import { isImmutable } from './typescript'

/**
 * Performs a deep merge of objects and returns a new object.
 * @param original the first, complete object of a given type.
 * @param {...object} objects - Objects to merge onto first one
 * - Does not mutate any original objects, creates deep clones.
 * - Arrays get replaced, not merged.
 * - Treats any class instance as an atomic value
 * - Tells apart regular objects from other JS structures that extend JS base 'object', such as null
 * @returns {object} New object with deeply merged key/values, of same type as original.
 */
export function mergeDeep<T extends Record<any, any>>(original: T, ...objects: DeepPartial<T>[]): T {
  return deepClone(objects).reduce((prev: T, obj: DeepPartial<T>) => {
    Object.keys(obj).forEach((key: keyof typeof obj) => {
      const pVal = prev[key]
      const oVal = obj[key] as T[keyof T]
      if (isImmutable(oVal) || isInstance(oVal)) {
        prev[key] = oVal
      } else if (isArray<T[keyof T]>(pVal) && isArray<T[keyof T]>(oVal)) {
        prev[key] = oVal // Arrays will be replaced, not merged
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal)
      } else {
        prev[key] = oVal
      }
    })
    return prev
  }, deepClone(original))
}

export default mergeDeep
