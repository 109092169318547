import { StyleSheet, View } from 'react-native'
import { CartStandard } from '@models/Order'
import { Alert, Button, Divider, DividerVertical, ErrorText, FormInput, Text, TextH2 } from '@elements'
import { globalStyles } from '../../../../../constants/Styles'
import { useCallback, useMemo, useState } from 'react'
import DecimalCalc from '@helpers/decimal'
import { isNum } from '@helpers/helpers'
import { formatMoney } from '@helpers/display'
import { useCartService } from '../../../../../hooks/useCart'
import { Farm } from '@models/Farm'
import { CartServiceType } from '../../../../../constants/types/cartService'
import { cartItemTotal } from '@helpers/order'
import { getUnadjustedQuantity, MAX_DECIMALS_FOR_VARIABLE_WEIGHT_ADJUSTMENTS } from '@helpers/cart'
import { isErrorWithCode } from '@shared/Errors'
import { Logger } from '../../../../../config/logger'

type Props = {
  farmId: Farm['id']
  cartServiceType?: CartServiceType
  isWholesale?: boolean
  /** We only allow variable weight adjustments on standard products. This cart item will never change as it is being passed in a modal */
  cartItem: CartStandard
}

/** This will give the initial value for the form. */
const getInitialTotalUnits = (cartItem: Props['cartItem']) =>
  DecimalCalc.multiply(cartItem.quantity * cartItem.unit.multiplier).toString()

/** Modal to allow variable weight adjustments for a cart item */
export function VariableWeightsModal({ cartItem, farmId, cartServiceType, isWholesale }: Props) {
  const cartSrv = useCartService({ farmId, cartServiceType, isWholesale })
  const [totalUnits, setTotalUnits] = useState<string>(getInitialTotalUnits(cartItem))
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const adjustedQuantity = useMemo(() => {
    const parsedUnits = parseFloat(totalUnits)
    if (isNaN(parsedUnits)) return undefined

    return DecimalCalc.divide(parsedUnits, cartItem.unit.multiplier)
  }, [cartItem, totalUnits])

  const formError = useMemo(() => {
    if (totalUnits === undefined) return 'Total units must be greater than 0.'
    if (!isNum(totalUnits)) return 'Total units must be a number.'
    if (parseFloat(totalUnits) === 0) return 'Total units must be greater than 0.'
    if (adjustedQuantity && Math.abs(cartItem.quantity - adjustedQuantity) >= 1)
      return 'You cannot adjust the quantity by more than 1.'
  }, [totalUnits, adjustedQuantity, cartItem.quantity])

  const itemPriceString = useMemo(() => {
    // This is expected to return '' when it is 0 because we don't need to show the price as 0, because it is an invalid adjustedQuantity and will show a form error already
    if (!adjustedQuantity) return ''

    const adjustedItem = { ...cartItem, quantity: adjustedQuantity }
    const itemTotal = cartItemTotal(adjustedItem, {
      excludeClosedDistros: !cartSrv.isAdmin,
      ignoreOrderCutoffWindow: cartSrv.isAdmin,
    })
    return formatMoney(itemTotal)
  }, [cartItem, adjustedQuantity, cartSrv.isAdmin])

  const submitVariableWeight = useCallback(async () => {
    const prevQuantity = cartItem.quantity
    const roundedQuantity = adjustedQuantity
      ? DecimalCalc.round(adjustedQuantity, MAX_DECIMALS_FOR_VARIABLE_WEIGHT_ADJUSTMENTS)
      : undefined
    if (!roundedQuantity) {
      if (roundedQuantity === 0 && adjustedQuantity !== 0) {
        return Alert('No Adjustment', 'Quantity is rounded to 4 decimal places and cannot be 0.')
      }
      return Alert('No Adjustment', 'You must enter a total units greater than 0.')
    }
    if (prevQuantity === roundedQuantity) {
      return Alert('No Adjustment', 'The total units you have entered has not changed so no adjustment was made.')
    }
    if (Math.abs(prevQuantity - roundedQuantity) >= 1) {
      return Alert('No Adjustment', 'The total units you entered requires too large of a quantity change.')
    }

    setIsSubmitting(true)

    cartSrv
      .updateQuantity(cartItem.id, roundedQuantity, true)
      .then(() => {
        Alert('Adjustment Successful', `The quantity has successfully been adjusted to ${roundedQuantity}.`)
      })
      .catch((err) => {
        // We only want to show the error to the customer if it is an ErrorWithCode meaning it is expected. Otherwise, don't provide more details and log it
        const errStr = isErrorWithCode(err) ? err.uiMsg : ''
        Alert('Adjustment Failed', `An error occurred while adjusting this item. ${errStr}`)
        // If it is not an error with code it means it is unexpected and we should log it
        if (!isErrorWithCode(err)) Logger.error(err)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }, [adjustedQuantity, cartItem, cartSrv])

  return (
    <View style={styles.modalContainer}>
      <Divider />
      <TextH2 style={globalStyles.marginVertical10}>
        {cartItem.product.name} x{getUnadjustedQuantity(cartItem)}
      </TextH2>
      <View style={styles.inputRow}>
        <Text type="medium">Total Units</Text>
        <DividerVertical clear />
        <FormInput
          row
          disabled={isSubmitting}
          value={totalUnits}
          label=""
          placeholder="10"
          keyboardType="decimal-pad"
          onChangeText={setTotalUnits}
          containerStyle={styles.inputContainer}
        />
        <Text type="medium">{cartItem.product.baseUnit}</Text>
      </View>
      {formError ? <ErrorText style={styles.errorStyle}>{formError}</ErrorText> : null}
      <Divider clear large />
      <View style={styles.resultsRow}>
        <Text type="medium">Billed Quantity</Text>
        <Text>{adjustedQuantity ? adjustedQuantity : ''}</Text>
      </View>
      <View style={styles.resultsRow}>
        <Text type="medium">Total Price</Text>
        <Text>{itemPriceString}</Text>
      </View>
      <Divider clear />
      <View style={globalStyles.flexRowCenter}>
        <View style={globalStyles.flex1} />
        <Button
          style={globalStyles.marginHorizontal10}
          title="Submit"
          loading={isSubmitting}
          disabled={!adjustedQuantity || !!formError}
          onPress={submitVariableWeight}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  modalContainer: {
    marginHorizontal: 20,
  },
  inputRow: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  errorStyle: {
    marginTop: 5,
  },
  resultsRow: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: 200,
  },
  inputContainer: {
    maxWidth: 100,
  },
})
