import { Button, TextH1 } from '@elements'
import { memo, useMemo } from 'react'
import { View } from 'react-native'

import Colors from '@/constants/Colors'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { DraftOrder, DraftOrderState } from '@models/DraftOrder'
import { isEmptyArray } from 'formik'

type DraftOrderHeaderProps = {
  draftOrder: DraftOrder
  onPressDeclineOrder: () => void
  onPressEditOrder: () => void
  onPressApproveOrder: () => void
  onPressFinalizeOrder: () => void
}

export const DraftOrderHeader = memo(function DraftOrderHeader({
  draftOrder,
  onPressDeclineOrder,
  onPressEditOrder,
  onPressApproveOrder,
  onPressFinalizeOrder,
}: DraftOrderHeaderProps): JSX.Element {
  const styles = useStyles()

  const buttons = useMemo(() => {
    if (draftOrder.state === DraftOrderState.Declined) return []

    const localButtons = [
      <Button
        key="decline"
        title="Decline Order"
        style={styles.button}
        onPress={onPressDeclineOrder}
        color={Colors.red}
        outline
        small
      />,
      <Button key="edit" title="Edit Order" style={styles.button} onPress={onPressEditOrder} outline small />,
    ]

    if (draftOrder.state === DraftOrderState.Pending) {
      localButtons.push(
        <Button key="approve" title="Approve Order" style={styles.button} onPress={onPressApproveOrder} small />,
      )
    }

    if (draftOrder.state === DraftOrderState.Approved) {
      localButtons.push(
        <Button key="fulfill" title="Fulfill Order" style={styles.button} onPress={onPressFinalizeOrder} small />,
      )
    }

    return localButtons
  }, [
    draftOrder.state,
    onPressApproveOrder,
    onPressDeclineOrder,
    onPressEditOrder,
    onPressFinalizeOrder,
    styles.button,
  ])

  return (
    <View style={styles.headerContainer}>
      <View>
        <TextH1 size={18}>Review Draft Order</TextH1>
      </View>
      {!isEmptyArray(buttons) && <View style={styles.buttonGroups}>{buttons}</View>}
    </View>
  )
})

const useStyles = () =>
  useSizeFnStyles(({ isSmallDevice }) => ({
    headerContainer: {
      flexDirection: isSmallDevice ? 'column' : 'row',
      alignItems: isSmallDevice ? 'flex-start' : 'center',
      justifyContent: 'space-between',
      gap: 4,
    },
    buttonGroups: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: 8,
    },
    button: {
      paddingHorizontal: 20,
    },
  }))
