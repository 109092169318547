import { CheckBox, Divider, ErrorText } from '@elements'
import { formatPickupDate, formatPickupTime } from '@helpers/display'
import { getOrderDeadline } from '@helpers/order'
import { format } from '@helpers/time'
import { Distribution } from '@models/Distribution'
import { FlashList, ListRenderItem } from '@shopify/flash-list'
import { DateTime } from 'luxon'
import { memo, useCallback } from 'react'
import { View } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import { SelectableDate } from './DateSelector-helper'

type Props = {
  selectableDates?: SelectableDate[]
  onDayPress: (v: DateTime) => void
  schedule: Distribution
  loading?: boolean
  /** If false, means stock has been exhausted from the date selection, so we must prevent more dates from being selected */
  canSelectMore?: boolean
}

export const ListSelector = memo(function ListSelector({
  selectableDates,
  onDayPress,
  schedule,
  loading = false,
  canSelectMore = true,
}: Props) {
  const styles = useStyles()

  const renderItem: ListRenderItem<SelectableDate> = useCallback(
    ({ item: { date, selected }, index }) => {
      if (!selectableDates) return null
      const pickupTimeText = formatPickupTime(schedule.schedule.hours, schedule.location.type)
      const formatted = formatPickupDate(date) + (pickupTimeText ? `  ${pickupTimeText}` : '')
      return (
        <View>
          {index > 0 && selectableDates[index].date.month !== selectableDates[index - 1].date.month && <Divider />}
          <View style={styles.deadlineCont}>
            <CheckBox
              disabled={loading || (!selected && !canSelectMore)}
              checked={selected}
              onChange={() => onDayPress(date)}
              title={formatted}
            />

            {index === 0 && (
              <ErrorText numberOfLines={2} style={styles.deadlineText}>
                {`Order deadline: ${format(
                  getOrderDeadline({ pickupDate: date, distro: schedule }),
                  "MM/dd/yyyy 'at' h:mma",
                )}`}
              </ErrorText>
            )}
          </View>
        </View>
      )
    },
    [selectableDates, schedule, loading, onDayPress, canSelectMore, styles],
  )

  return <FlashList estimatedItemSize={25} data={selectableDates} renderItem={renderItem} />
})

const useStyles = CreateResponsiveStyle(
  {
    deadlineText: {
      marginLeft: 10,
    },
    deadlineCont: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
    },
    itemCont: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
  },
  {
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      deadlineCont: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
  },
)
