import { useEffect, useState } from 'react'
import { TextInput, View, StyleSheet } from 'react-native'

import Colors from '../../constants/Colors'

type Props = {
  initial: string
  onChange(color: string): void
}

export default function ColorPicker({ initial, onChange }: Props) {
  const [color, setColor] = useState('')
  const [textVal, setTextVal] = useState('')
  const [error, setError] = useState(false)

  useEffect(() => {
    if (!initial) return
    setColor(initial)
    setTextVal(initial)
  }, [initial])

  function updateColor(text: string) {
    if (text[0] !== '#') text = '#' + text
    setTextVal(text)
    onChange(text)
    if (text.match(/#[a-fA-F0-9]{6}/)) {
      setColor(text)
      setError(false)
    }
  }
  function onBlurred() {
    if (!textVal.match(/#[a-fA-F0-9]{6}/)) setError(true)
  }

  return (
    <View style={[styles.container, error && { borderColor: Colors.red }]}>
      <View style={[styles.colorBox, { backgroundColor: color }]} />
      <TextInput style={styles.input} value={textVal} onChangeText={updateColor} maxLength={7} onBlur={onBlurred} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 5,
    borderWidth: 2,
    borderColor: Colors.shades[200],
    width: 120,
    height: 40,
    flexDirection: 'row',
    margin: 10,
  },
  colorBox: {
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    width: 36,
    height: 36,
  },
  input: {
    flex: 1,
    width: 116,
    marginLeft: -36,
    paddingLeft: 45,
  },
})
