import { useEffect, useState } from 'react'
import { useInstantSearch } from 'react-instantsearch'
import { useDebouncedCallback } from './useDebounce'

/** Makes it easier to accurately know the loading state, the error state and wther there's no results in algolia hooks.
 * https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-display/react-hooks/
 */
export const useAlgoliaState = () => {
  const { status, results, error } = useInstantSearch()
  const [state, set] = useState<{
    /** Whether a search is in progress. On mount, will be true initially, and will wait till algolia is initialized before becoming dependent on the algolia 'status' api. For component rendering, this is more useful than the algolia provided 'status' because status initially gets an artificial value */
    loadingSearch: boolean
    /** Whether the search really produced no results after being ready */
    noResults?: boolean
    /** Whether algolia is ready. Before this is true, any algolia value should be considered 'artificial' or 'initial' and thus potentially incorrect/ misleading */
    initSearch: boolean
    /** The message that algolia triggers when there is a search error (i.e no internet connection) */
    errorMsg?: string
  }>({
    loadingSearch: true,
    noResults: undefined,
    initSearch: false,
    errorMsg: undefined,
  })

  /**
   * This setter will debounce the setting of state, to make sure we only set state once, after any potentially intermittent changes in the loading state coming from algolia.
   *
   * We need to delay setting loading to false because in practice there may have a render cycle in which the hits/ results are still absent despite algolia state saying it is ready. */
  const setDebounce = useDebouncedCallback(set, 400)

  useEffect(() => {
    /** Initially, 'status' will have a value that doesn't reflect our query. Until its value reflects our query, we assume it is loading. Once it reflects our query, we believe it. */
    const loadingSearch = results.__isArtificial ? true : status === 'loading' || status === 'stalled'

    /** Initially, the results are artificial which is to say they don't reflect our query. While we wait for them to reflect our query, noResults will be undefined. Once the results are no longer artificial, we look at the number of hits to determine if there's indeed no results */
    const noResults = !results.__isArtificial && !loadingSearch && results.nbHits === 0

    /** When the results are no longer artificial, it means algolia has initialized */
    const initSearch = !results.__isArtificial

    const errorMsg = error?.message

    setDebounce({ initSearch, noResults, loadingSearch, errorMsg })
  }, [status, results.__isArtificial, results.nbHits, error?.message, setDebounce])

  return state
}
