import { loadFuturePickupsByUser } from '@api/Pickups'
import { Alert, Toast } from '@elements'
import { formatAddress, formatMoney, unmarshalPhoneNumber } from '@helpers/display'
import { groupBy, haveSameItems } from '@helpers/helpers'
import { createAddressString, getDeliveryFee, getUniqueDates, NoDeliveryFees } from '@helpers/location'
import { MoneyCalc } from '@helpers/money'
import { calculatePayments } from '@helpers/order'
import { CoverFee, CoverOptId, getServiceFeeAmountFromTender } from '@helpers/serviceFee'
import { isAfter, isSameDay } from '@helpers/time'
import { isValidAddress } from '@models/Address'
import { isNonPickup } from '@models/Location'
import { Zero } from '@models/Money'
import { CartItem, isCartPhysical, SplitTenderPayment } from '@models/Order'
import { isEbtPayment, isFarmCreditPayment } from '@models/PaymentMethod'
import { User } from '@models/User'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { DateTime } from 'luxon'
import { createContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { useFarmProp } from '../../../hooks/useFarmProp'
import { RootState } from '../../../redux/reducers/types'
import { userSelector, wholesaleSelector } from '../../../redux/selectors'
import { CartItemGroupType } from './CartItemsCheckout'
import { billingAddressSchema } from './CheckoutContact'
import { CheckoutFormType, getTotalAfterFeesNCredit, initialTotal, makeTouched, Touched } from './helpers'
import { useConfirmLeaveCheckout } from './useConfirmLeaveCheckout'
import { useHandleAddOrder } from './useHandleAddOrder'
import { usePlaceOrder } from './usePlaceOrder'
import { usePreCheckout } from './usePreCheckout'
import { useValidateCheckoutForm } from './useValidateCheckoutForm'

import { Logger } from '@/config/logger'
import { useApiFx } from '@/hooks/useApiFx'
import { useCartService } from '@/hooks/useCart'
import { useDeepCompareMemo } from '@/hooks/useDeepEqualEffect'
import { useFocusFx } from '@/hooks/useFocusFx'
import useKeyedState from '@/hooks/useKeyedState'
import { ShoppingStackParamList } from '@/navigation/types'
import { pick } from '@helpers/typescript'
import { ProductType } from '@models/Product'
import { isFeeProductFee, isTaxProductFee, ProductFeesForInvoice } from '@models/ProductFee'
import { Total } from '../../../constants/types'
import { PaymentSelectorOptions } from '../../PaymentMethods/PaymentSelection/helpers/types'

type CheckoutStateType = {
  formValidationLoading: boolean
  payMethodLoading: boolean
  /** Whether the cart was checked on screen load */
  initialCartCheckDone: boolean
  touched: Touched
  /** Ids of cartItems with errors */
  errors: string[] | undefined
  coverFee: CoverFee
  total: Total
  splitTender: SplitTenderPayment | undefined
  /** Additional fees that should be applied on products */
  additionalFees: ProductFeesForInvoice
}

export const checkoutInitialState: CheckoutStateType = {
  formValidationLoading: false,
  payMethodLoading: true,
  initialCartCheckDone: false,
  touched: {},
  errors: [],
  coverFee: { id: CoverOptId.None, value: Zero, tip: Zero, applyToInstallments: true },
  total: initialTotal,
  splitTender: undefined,
  additionalFees: [],
}

/** This hook will provide the data used by the checkout screen UI
 * - Anything not already available through other hooks should be returned from here
 * - Anything that would make JSX hard to read should go here
 */
export function useCheckoutData() {
  const navigation = useNavigation<StackNavigationProp<ShoppingStackParamList, 'Checkout'>>()
  const { params } = useRoute<RouteProp<ShoppingStackParamList, 'Checkout'>>()
  const { isWholesale } = useSelector(wholesaleSelector)
  const { cart, discounts, loadingCart } = useCartService({
    cartServiceType: 'consumer',
    farmId: params?.cartFarmId,
    isWholesale,
  })
  const [
    {
      coverFee,
      errors,
      formValidationLoading,
      payMethodLoading,
      splitTender,
      total,
      additionalFees,
      touched,
      initialCartCheckDone,
    },
    set,
    ,
    setters,
  ] = useKeyedState(checkoutInitialState)
  const disableConfirmations = useConfirmLeaveCheckout(!!cart.length, navigation)
  const user = useSelector<RootState, User>(userSelector)
  const { data: farm, loading: loadingFarm, err: errorFarm } = useFarmProp({ farmSlug: params?.cartFarmId })
  const { data: futurePickups, loading: loadingPickups } = useApiFx(loadFuturePickupsByUser, [user.id], !!user.id, {
    onError: (err) => Logger.error(err),
  })

  /** PO should be allowed when validating the current default address because here it is used for billing purposes.
   * - We might later need to differentiate between billing vs delivery addresses
   */
  const shouldRequireAddress = !isValidAddress(user.address, { allowPO: true })

  // For now, we are using drafts only for wholesale, we can use custom logic in the future to determine if it is a draft
  const isDraftOrder = !!isWholesale

  /** Initial form values.
   * - Form should re-initialize if these change. That would help in case there's an invalid address, so it can show the most updated data */
  const initialFormValues: CheckoutFormType = useDeepCompareMemo(
    () => ({
      billingAddress: {
        phoneNumber: user.phoneNumber ? unmarshalPhoneNumber(user.phoneNumber, false) : '', //Unmarshall with no country code
        street1: user.address?.street1 || '',
        street2: user.address?.street2 || '',
        city: user.address?.city || '',
        state: user.address?.state || '',
        zipcode: user.address?.zipcode || '',
      },
      note: '',
      purchaseOrder: '',
    }),
    [
      user.address?.city,
      user.address?.state,
      user.address?.street1,
      user.address?.street2,
      user.address?.zipcode,
      user.phoneNumber,
    ],
  )
  const schema: Yup.ObjectSchema<CheckoutFormType> = useMemo(
    () =>
      Yup.object().shape({
        /** The billingAddress schema is only required if the user address isn't valid */
        billingAddress: billingAddressSchema.when({
          is: () => shouldRequireAddress,
          then: (schema) => schema.required(),
        }),
        note: Yup.string().label('Note'),
        purchaseOrder: Yup.string().label('Purchase Order'),
      }),
    [shouldRequireAddress],
  )

  /** The amount of farm credit applied for the order */
  const farmCreditAppliedAmount = useMemo(
    () => splitTender?.find((pay) => isFarmCreditPayment(pay.paymentMethod))?.amount ?? Zero,
    [splitTender],
  )
  /** The amount that is set to be paid with EBT */
  const ebtPmtAmount = useMemo(() => splitTender?.find((pay) => isEbtPayment(pay.paymentMethod))?.amount, [splitTender])

  /** The total delivery fees for the entire cart, regardless of date due */
  const { itemsDeliveryFees: deliveryFeesTotal } = useMemo(() => {
    if (loadingPickups || !futurePickups) return NoDeliveryFees
    return getDeliveryFee(cart, { pickups: futurePickups })
  }, [cart, futurePickups, loadingPickups])

  const serviceFeeAmount = useMemo(() => {
    const totalTaxAndFee = MoneyCalc.add(total.tax, ...additionalFees.map((v) => v.amount))
    // The portion we apply fees to should be based on the total after discounts, however we don't want to include delivery fees or taxes and product fees
    const totalForFees = MoneyCalc.subtract(total.total, totalTaxAndFee)

    return splitTender
      ? getServiceFeeAmountFromTender(splitTender, totalForFees, farm?.pricingModel?.appFeePercent)
      : Zero
  }, [total, splitTender, farm?.pricingModel?.appFeePercent, additionalFees])

  /** Update the 'touched' obj for Payment Schedules whenever the items in cart are added/ removed.
   * - This is unrelated to the address formik form. This is for the payment schedules component, which doesn't need formik
   */
  useFocusFx(() => {
    //Only run if the item ids don't match
    if (
      !haveSameItems(
        cart.map((itm: CartItem) => itm.id),
        Object.keys(touched),
      )
    ) {
      set('touched', makeTouched(cart, touched))
    }
  }, [cart, touched, set])

  const validateCheckoutForm = useValidateCheckoutForm(set, shouldRequireAddress)

  /** Exit checkout if no farmId or no items in cart. */
  useFocusFx(() => {
    // If the check was completed, don't check again
    if (initialCartCheckDone) return

    if (!params?.cartFarmId) {
      Toast('No farm was selected for checkout')
      disableConfirmations() // Prevent showing confirmations before force-exiting
      return navigation.navigate('MyCart')
    }

    if (loadingCart) return

    if (!cart.length) {
      Alert('Empty Cart', 'There are no items in your cart. Please add some products before checking out.')
      disableConfirmations() // Prevent showing confirmations before force-exiting
      return navigation.navigate('FarmShop', { farmSlug: params?.cartFarmId })
    }
    set('initialCartCheckDone', true) // set check is done
  }, [loadingCart, params?.cartFarmId, cart, initialCartCheckDone, navigation, set, disableConfirmations])

  /** groups of cart items which have the same address */
  const itemGroups: CartItemGroupType[] = useMemo(() => {
    return groupBy(cart, (itm) =>
      isCartPhysical(itm)
        ? //group items by distro id and address.
          itm.distribution.location.id + createAddressString(itm.distribution.location.address!)
        : 'digital',
    ).map((group): CartItemGroupType => {
      const locId = group[0].distribution?.location.id
      const { itemsDeliveryFees, combinedDates, combinedPickups } = getDeliveryFee(group, {
        pickups: futurePickups,
        locId,
      })

      return {
        items: group,
        address: isCartPhysical(group[0]) ? formatAddress(group[0].distribution.location.address!) : undefined,
        locationFee:
          isCartPhysical(group[0]) && isNonPickup(group[0].distribution.location)
            ? group[0].distribution.location.cost
            : undefined,
        groupDeliveryTotal: itemsDeliveryFees,
        groupDeliveryTotalDueNow: undefined, //hard-coded because we invoice delivery fees always in the future
        combinedDeliveryDates: combinedDates,
        combinedDeliveryPickups: combinedPickups,
        uniqueDates: getUniqueDates(group),
        locType: isCartPhysical(group[0]) ? group[0].distribution.location.type : undefined,
      }
    })
  }, [cart, futurePickups])

  const payments = useMemo(() => {
    if (!cart.length || !farm || isWholesale === undefined) return undefined

    return calculatePayments(
      { items: cart, discounts, isAdmin: false },
      { farmId: farm.id, dueDateTolerance: farm.dueDateTolerance, timezone: farm.timezone, splitTender, isWholesale },
    )
    // splitTender is intentionally left out because we only need to update it when discounts change. Otherwise, there will
    // be an infinite loop of discount making total less which makes splitTender less which makes discount less and so on.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, discounts, isWholesale, farm])

  /** This holds all options for configuring the payment selector */
  const paymentSelectorOptions: PaymentSelectorOptions | undefined = useMemo(() => {
    if (!farm || !payments) return undefined

    const isFirstInvoiceToday = isSameDay(payments[0].date, DateTime.now(), farm.timezone)

    return {
      farm: pick(farm, 'id', 'name', 'offlinePayments', 'paymentTypes', 'dueDateTolerance'),
      amountTotal: MoneyCalc.add(total.total, coverFee.value || Zero),
      amountEbt: total.ebtEligibleAmount ?? Zero,
      // If we have more than one payment or the only payment is not today then it must have future payments. Also, if the order
      // is a draft then it will have payments in the future once the draft is confirmed.
      hasFuturePayments: payments.length > 1 || isDraftOrder || (payments.length === 1 && !isFirstInvoiceToday),
      hasDelivery: MoneyCalc.isGTZero(deliveryFeesTotal),
      hasFarmBalanceItem: cart.some((ci) => ci.product.type === ProductType.FarmBalance),
      /** For draft orders we will not allow farm credit because we don't know how much FC they will have when the invoice is due. */
      isInvoiceDueToday: isFirstInvoiceToday && !isDraftOrder,
      allowOfflinePayments: true,
    }
  }, [cart, deliveryFeesTotal, total, farm, coverFee.value, payments, isDraftOrder])

  const handleAddOrder = useHandleAddOrder(isDraftOrder, coverFee, total, disableConfirmations)

  const placeOrder = usePlaceOrder({
    handleAddOrder,
    disableConfirmations,
    splitTender,
  })

  const preCheckout = usePreCheckout(set, touched, placeOrder)

  /** Gets the cart total amounts */
  useFocusFx(() => {
    if (!payments || !farm?.timezone) return

    // If there is no payment due today don't list it in the checkout page. For wholesale, we will always show the first payment
    if (isAfter(payments[0].date, DateTime.now(), { granularity: 'day', zone: farm.timezone }) && !isWholesale) {
      setters.total(initialTotal)
      setters.additionalFees([])
      return
    }

    set('additionalFees', payments[0].taxesAndFees?.filter((itm) => isFeeProductFee(itm.productFee)) ?? [])

    const tax =
      payments[0].taxesAndFees
        ?.filter((itm) => isTaxProductFee(itm.productFee))
        .reduce((acc, itm) => MoneyCalc.add(acc, itm.amount), Zero) ?? Zero

    set('total', {
      subtotal: payments[0].subtotal,
      total: payments[0].total,
      discounts: payments[0].discounts,
      ebtEligibleAmount: payments[0].ebtEligibleAmount,
      tax,
    })
  }, [payments, farm?.timezone, set, setters, isWholesale])

  /** Disable when no selected payment unless it can be paid only with FC */
  const disablePlaceOrder: boolean = !splitTender || !splitTender.length

  const loadingPlaceOrderBtn = formValidationLoading || loadingCart || loadingFarm

  const totalAfterFeesText = formatMoney(getTotalAfterFeesNCredit(total.total, coverFee.value, farmCreditAppliedAmount))

  return {
    //base state
    cartFarmId: params?.cartFarmId,
    set,
    setters,
    coverFee,
    errors,
    formValidationLoading,
    payMethodLoading,
    splitTender,
    total,
    additionalFees,
    touched,
    initialCartCheckDone,
    paymentSelectorOptions,
    //functions
    handleAddOrder,
    placeOrder,
    validateCheckoutForm,
    preCheckout,
    //props/ derived data / memo
    farm,
    loadingFarm,
    errorFarm,
    shouldRequireAddress,
    initialFormValues,
    schema,
    serviceFeeAmount,
    farmCreditAppliedAmount,
    disablePlaceOrder,
    ebtPmtAmount,
    loadingPlaceOrderBtn,
    itemGroups,
    totalAfterFeesText,
  }
}

export type CheckoutData = ReturnType<typeof useCheckoutData>

export const CheckoutContext = createContext<CheckoutData>({} as CheckoutData)
