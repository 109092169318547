import React, { createContext, memo } from 'react'

import { withConsumerIndexHooks } from '../../../hooks/withAlgoliaIndex'
import { useLocation } from '../../../hooks/withLocation'

import { HomeScreenWholesaleType, useHomeScreenWholesaleData } from './HomeScreenWholesale.controller'
import { HomeScreenWholesaleView } from './components/HomeScreenWholesale.view'

function HomeScreenWholesaleComp() {
  useLocation()
  const value = useHomeScreenWholesaleData()

  return (
    <HomeScreenWholesaleContext.Provider value={value}>
      <HomeScreenWholesaleView />
    </HomeScreenWholesaleContext.Provider>
  )
}

export const HomeScreenWholesale = withConsumerIndexHooks(memo(HomeScreenWholesaleComp))

export const HomeScreenWholesaleContext = createContext<HomeScreenWholesaleType>({} as HomeScreenWholesaleType)
