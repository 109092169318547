import { ConversationView } from '@/components'
import Colors from '@/constants/Colors'
import { useDeviceSize } from '@/hooks/useLayout'
import { ConversationsList } from '@components'
import { ButtonClear, LoadingView, Text } from '@elements'
import { getOtherParticipant } from '@helpers/messaging'
import { isAccountParticipant } from '@models/Messaging'
import { userName } from '@models/User'
import { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { useMessages } from './MessagesScreen.context'

/** UI Layer for the Messages screen */
export function MessagesScreenView({ adminFarmId }: { adminFarmId?: string }) {
  const { chatsSnap, user, messagesSnap, selectedChatId } = useMessages()
  const { isSmallDevice } = useDeviceSize()

  const selectedChat = (chatsSnap.data ?? []).find((el) => el.id === selectedChatId)

  const participantInfo = useMemo(() => {
    if (!selectedChat) return undefined
    const other = getOtherParticipant(selectedChat.participants, adminFarmId ?? user.id)
    if (!other) return undefined

    if (isAccountParticipant(other)) {
      return {
        name: other.account.name,
        title: 'Go to farm profile',
        // We assume that the user is on the consumer side
        url: `/farms/${other.account.id}`,
      }
    }

    return {
      name: userName(other.user),
      title: 'Go to customer profile',
      // We assume that the user is on the admin side
      url: `/admin/customers/${user.id}`,
    }
  }, [adminFarmId, selectedChat, user.id])

  return (
    <LoadingView style={styles.content} error={chatsSnap.error} loading={chatsSnap.loading}>
      <View style={styles.leftContainer}>
        <ConversationsList />
      </View>
      {!isSmallDevice && (
        <View style={styles.rightContainer}>
          {selectedChat ? (
            <>
              {participantInfo && (
                <View style={styles.headerCont}>
                  <Text size={16}>{participantInfo.name}</Text>
                  <ButtonClear title={participantInfo.title} url={participantInfo.url} />
                </View>
              )}
              <ConversationView
                adminFarmId={adminFarmId}
                chatSession={selectedChat}
                loading={messagesSnap.loading}
                currUserId={user.id}
                data={messagesSnap.data ?? []}
              />
            </>
          ) : (
            <View style={styles.centered}>
              <Text>Please select a conversation </Text>
            </View>
          )}
        </View>
      )}
    </LoadingView>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    flexDirection: 'row',
    gap: 14,
  },
  rightContainer: {
    borderWidth: 1,
    borderRadius: 10,
    flex: 2,
    borderColor: Colors.shades['200'],
  },
  leftContainer: {
    borderWidth: 1,
    borderRadius: 10,
    flex: 1,
    minWidth: 300,
    overflow: 'hidden',
    borderColor: Colors.shades['200'],
  },
  centered: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  headerCont: {
    borderBottomWidth: 1,
    borderColor: Colors.shades['200'],
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 10,
  },
})
