import { Review } from '@models/Review'

import { Farm } from '../models/Farm'

export function calculateReviewChange(farm: Pick<Farm, 'reviews'>, before?: Review, after?: Review) {
  const avgRating = Math.max(farm.reviews?.rating || 0, 0)
  let numRatings = Math.max(farm.reviews?.numRatings || 0, 0)
  let newRating = 0

  if (!before && after) {
    //ADDED
    newRating = (numRatings * avgRating + after.rating) / (numRatings + 1)
    numRatings += 1
  } else if (!after && before) {
    //DELETED
    newRating = numRatings <= 1 ? 0 : (numRatings * avgRating - before.rating) / (numRatings - 1)
    numRatings -= 1
  } else if (after && before) {
    //UPDATED
    newRating = avgRating + (after.rating - before.rating) / numRatings
  }

  return {
    numRatings: Math.max(numRatings, 0),
    rating: Math.max(newRating, 0),
  }
}
