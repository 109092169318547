import Colors from '@/constants/Colors'
import { SIDEBAR_WIDTH } from '@/constants/Layout'
import { MenuItem } from '@/hooks/useAlgoliaFarmData/useAlgoliaFarmData-helpers'
import { RangeRefinementType } from '@/hooks/useAlgoliaRangeRefinement'
import { HomeParamList } from '@/navigation/types'
import { CheckBox } from '@elements'
import { RouteProp, useRoute } from '@react-navigation/native'
import { SearchScreenContext } from '@screens/SearchScreen/SearchScreen'
import { memo, useContext } from 'react'
import { StyleSheet, View } from 'react-native'
import { FilterGroup } from '../FilterGroup/FilterGroup'
import { RangeFilter } from '../RangeFilter/RangeFilter'
import { RangeSliderFilter } from '../RangeSliderFilter/RangeSliderFilter'
import { useSidebarData } from './Sidebar.controller'

export type SidebarProps = {
  categories: MenuItem[]
  practices?: MenuItem[]
  priceFilter: Pick<RangeRefinementType, 'currentFilter' | 'limits'>
  orderMinFilter: Pick<RangeRefinementType, 'currentFilter' | 'limits'>
  radius?: number
}

export const Sidebar = memo(function Sidebar(props: SidebarProps) {
  const { params: { locType } = {} } = useRoute<RouteProp<HomeParamList, 'SearchScreen'>>()
  const {
    searchLayer: { includeNearbyZipcodes, setIncludeNearbyZipcodes },
  } = useContext(SearchScreenContext)

  const {
    folded,
    toggleFolded,
    sections,
    onButtonPress,
    onUpdateOrderMin,
    onUpdatePriceRange,
    pickupRangeItem,
    priceRefinementGroup,
    orderMinRefinementGroup,
  } = useSidebarData(props)

  return (
    <View style={styles.main}>
      {locType === 'zip' && (
        <CheckBox
          size={20}
          title="Include nearby zip codes"
          onChange={setIncludeNearbyZipcodes}
          checked={includeNearbyZipcodes}
        />
      )}
      {sections.map((section) => {
        return (
          <FilterGroup
            key={section.filterKey}
            isExpanded={!folded.includes(section.filterKey)}
            onToggle={() => toggleFolded(section.filterKey)}
            onItemPress={onButtonPress}
            items={section.items}
            title={section.title}
          />
        )
      })}

      {locType === 'coord' && (
        <RangeSliderFilter
          isExpanded={!folded.includes('pickupRange')}
          onToggle={() => toggleFolded('pickupRange')}
          {...pickupRangeItem}
        />
      )}

      {priceRefinementGroup && (
        <RangeFilter
          items={priceRefinementGroup}
          selectedValue={props.priceFilter.currentFilter}
          onUpdate={onUpdatePriceRange}
          isExpanded={!folded.includes('priceFilter')}
          onToggle={() => toggleFolded('priceFilter')}
          title="Price"
        />
      )}
      {orderMinRefinementGroup && (
        <RangeFilter
          items={orderMinRefinementGroup}
          selectedValue={props.orderMinFilter.currentFilter}
          onUpdate={onUpdateOrderMin}
          isExpanded={!folded.includes('orderMinFilter')}
          onToggle={() => toggleFolded('orderMinFilter')}
          title="Order minimum"
        />
      )}
    </View>
  )
})

const styles = StyleSheet.create({
  main: {
    borderRightWidth: 1,
    borderColor: Colors.shades['100'],
    width: SIDEBAR_WIDTH,
    padding: 20,
    gap: 20,
  },
})
