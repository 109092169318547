/* eslint-disable no-console */
import { isArray, isObject } from '@helpers/helpers'
import { isLuxonDateTime } from '@helpers/time'

import { isImmutable } from './typescript'

/** Easily log complex data for debugging. Does nothing in production */
export const log = (...args: any): void => {
  /** This can't reference the "env" from config/Environment because the server tsconfig can't find some globals in it */
  if (process.env.NODE_ENV === 'production') return
  args.forEach((arg: any) => {
    if (arg instanceof Error) {
      return console.error(arg.message + '\n' + arg.stack)
    }
    const obj = makeDatesReadable(arg)
    /** Use objToStr for any non-null objects which have no fn values. The JSON.stringify inside objToStr makes fn values invisible in the log */
    return typeof obj === 'object' && !isImmutable(obj) && !hasFnValues(obj)
      ? console.log(objToStr(obj))
      : console.log(obj)
  })
}

/** Checks if an object contains function values deeply */
const hasFnValues = (obj: Record<any, any>): boolean =>
  Object.values(obj).some((v): boolean => {
    if (typeof v === 'function') return true
    if (typeof v === 'object' && v !== null) return hasFnValues(v)
    return false
  })

/** Wrapper for JSON.stringify, which can handle arrays, objects, Maps and Sets, and adds formatting configuration for reading logs easily.
 * - Warning: Functions in an object may become invisible in the JSON string
 */
export const objToStr = (arg: any): string =>
  arg instanceof Map
    ? JSON.stringify(Object.fromEntries(arg), undefined, 4)
    : arg instanceof Set
    ? JSON.stringify(Array.from(arg.values()), undefined, 4)
    : isImmutable(arg)
    ? String(arg)
    : JSON.stringify(arg, undefined, 4)

/** Recursively finds dates and converts them to iso strings for easy human readibility in console log */
export const makeDatesReadable = (arg: any): any => {
  if (isLuxonDateTime(arg)) return arg.toISO()
  if (arg instanceof Map) return makeDatesReadable(Object.fromEntries(arg))
  if (arg instanceof Set) return makeDatesReadable(Array.from(arg.values()))
  if (isArray(arg)) return arg.map((v) => makeDatesReadable(v))
  if (isObject(arg))
    return Object.fromEntries(
      Object.entries(arg).map(([k, v]) => {
        return [k, makeDatesReadable(v)]
      }),
    )
  return arg
}

export default log
