import { Text } from '@elements'
import { DayOfWeek } from '@models/Schedule'
import React from 'react'
import { TouchableOpacity, View, ViewProps, ViewStyle } from 'react-native'

import { globalStyles } from '../../../../constants/Styles'

import Colors from '@/constants/Colors'
import { useLayout } from '@/hooks/useLayout'

export type WeekDayProps = {
  /** value for the enabled state. defaults to true */
  enabled?: boolean
  /** The index of the day of week */
  dayN: DayOfWeek
  /** Name of the day */
  dayName: string
  /** Size of the circle */
  size?: number
  /** Handler called when state changes */
  onValueChange?: (enabled: boolean) => any
  /** How many characters of each day's name to display */
  dayNumChars?: number
  containerStyle?: ViewStyle
}

export function WeekDayCircle({
  enabled = true,
  dayN,
  dayName,
  size = 50,
  onValueChange,
  dayNumChars = 2,
  containerStyle,
}: WeekDayProps) {
  const { isSmallDevice } = useLayout()
  return (
    <TouchableOpacity
      key={dayN}
      style={[
        {
          height: size,
          width: size,
          borderRadius: size / 2,
          backgroundColor: enabled ? Colors.green : undefined,
          borderWidth: enabled ? 0 : 1,
          borderColor: Colors.blue,
          alignItems: 'center',
          justifyContent: 'center',
          margin: isSmallDevice ? 3 : 5,
        },
        containerStyle,
      ]}
      onPress={() => onValueChange?.(!enabled)}
    >
      <Text>{dayName.substring(0, dayNumChars)}</Text>
    </TouchableOpacity>
  )
}

export type WeekDaySelectorProps = {
  /** This object describes the structure of the days of week to use on first load. Days will be displayed in the order presented, and the dayN value will correspond to the key value */
  values?: { [dayN: number]: { dayName: string; enabled?: boolean } }
  onDayPress?: (dayN: number, enabled: boolean) => any
  viewProps?: ViewProps
} & Pick<WeekDayProps, 'size' | 'dayNumChars'>

export function WeekdaySelector({
  values = {
    1: { dayName: 'Monday' },
    2: { dayName: 'Tuesday' },
    3: { dayName: 'Wednesday' },
    4: { dayName: 'Thursday' },
    5: { dayName: 'Friday' },
    6: { dayName: 'Saturday' },
    7: { dayName: 'Sunday' },
  },
  onDayPress,
  viewProps = {},
  ...weekDayCircleProps
}: WeekDaySelectorProps) {
  const { style, ...viewPropsRest } = viewProps
  return (
    <View style={[globalStyles.flexRow, style]} {...viewPropsRest}>
      {Object.entries(values).map(([dayN, data]) => (
        <WeekDayCircle
          key={dayN}
          enabled={data.enabled}
          dayN={Number(dayN)}
          dayName={data.dayName}
          onValueChange={(enabled) => onDayPress?.(Number(dayN), enabled)}
          {...weekDayCircleProps}
        />
      ))}
    </View>
  )
}

export default WeekdaySelector
