import { getCoordString, validCoords } from '@helpers/coordinate'
import { errorToString, nonEmptyString, withTimeout } from '@helpers/helpers'
import { Optional } from '@helpers/typescript'
import { Address, ShortZip, isPO } from '@models/Address'
import { Coordinate } from '@models/Coordinate'
import { DataError } from '@shared/Errors'
import * as Location from 'expo-location'
import * as Yup from 'yup'

import { env } from '../config/Environment'
import { CurrentLocation } from '../constants/types'

import { Logger } from '@/config/logger'
import { GoogleAddressParser } from '@/constants/GoogleAddressParser'
import { ShortZipSchema } from '@helpers/builders/validators/sharedValidation'

export const GOOGLE_API_URL = 'https://maps.googleapis.com/maps/api/geocode/json'

/** These types were borrowed from expo Location */
export type GoogleApiGeocodingAddressComponent = {
  long_name: string
  short_name: string
  types: string[]
}

export type GoogleApiGeocodingResult = {
  address_components: GoogleApiGeocodingAddressComponent[]

  formatted_address: string

  geometry: {
    location: {
      lat: number

      lng: number
    }
  }
}

export type GoogleApiGeocodingResponse = {
  results: GoogleApiGeocodingResult[]

  status: string

  error_message?: string
}

/** Receives an address with no coordinates, geocodes it and returns the same address with the coordinates assigned. */
export async function geocode(address: Optional<Address, 'coordinate'>): Promise<Address & { coordinate: Coordinate }> {
  let query = `${address.city} ${address.state} ${address.zipcode}`
  if (!isPO(address)) {
    /** If it's a PO box address, the @type {Address['street1']} field may cause the geocoding to fail because as of now google api doesn't handle the PO box string well, but it should succeed with the rest of the address fields */

    /** Any "#" signs will make it fail */
    query = `${address.street1.replace('#', '').trim()} ` + query
  }
  const fetchUrl = `${GOOGLE_API_URL}?address=${encodeURI(query)}&key=${env.API_KEY}`
  const response = await fetch(fetchUrl)

  const geocodeResponse: GoogleApiGeocodingResponse = await response.json()
  if (!geocodeResponse.results?.[0] || !validCoords(geocodeResponse.results[0].geometry.location)) {
    /** In case of error, please pass a data error to Logger.error, which contains data that can help debug the problem */
    const err = new DataError('Could not geo-code address', { geocodeResponse, fetchUrl, address })
    Logger.error(err)
    throw err
  }

  return {
    ...address,
    coordinate: {
      latitude: geocodeResponse.results[0].geometry.location.lat,
      longitude: geocodeResponse.results[0].geometry.location.lng,
    },
  }
}

/** Converts a coordinate into a set of results that include raw address data */
export async function reverseGeocode(coords: Coordinate): Promise<GoogleApiGeocodingResult[]> {
  const params = {
    latlng: getCoordString(coords),
  }
  const query = Object.entries(params)
    .map((entry) => `${entry[0]}=${encodeURI(entry[1])}`)
    .join('&')
  const fetchUrl = `${GOOGLE_API_URL}?key=${env.API_KEY}&${query}`
  const fetchResponse = await withTimeout(fetch(fetchUrl), 3000)
  const geocodingResponse = (await withTimeout(fetchResponse.json(), 3000)) as GoogleApiGeocodingResponse

  if (geocodingResponse.error_message) {
    /** In case of error, pass a data error to Logger.error, which contains data that can help debug the problem */
    const err = new DataError('Could not reverse geo-code', { coords, fetchUrl, geocodingResponse })
    Logger.error(err)
    throw err
  }

  return geocodingResponse.results
}

/** Reverse geo-codes the coords, and parses the google address component results */
export async function getParsedAddressFromCoords(coords: Coordinate) {
  const res = await withTimeout(reverseGeocode(coords), 5000)
  return new GoogleAddressParser(res[0].address_components)
}

/** geo location based on true device coordinates
 * - If this returns null it means permission was not obtained
 * - If this returns a Coordinate, it means it is the real exact location. No fallback location should be used
 */
export const loadExactCoords = async (): Promise<Coordinate | null> => {
  const { status } = await Location.requestForegroundPermissionsAsync().catch(() => {
    // If something goes wrong here we have earlier decided to not log this error
    // Perhaps should be reconsidered. Not sure why this would error out unless it was something wrong
    return { status: Location.PermissionStatus.DENIED }
  })

  if (status !== Location.PermissionStatus.GRANTED) {
    return null
  }

  const currPosition = await Location.getCurrentPositionAsync({
    accuracy: Location.Accuracy.High,
    timeInterval: 5000,
  })
  const coords: Coordinate = { latitude: currPosition.coords.latitude, longitude: currPosition.coords.longitude }
  if (validCoords(coords)) return coords
  throw new Error('Could not get valid exact coordinates')
}

/** geolocation based on ip address. Will get an approximate location for the device
 * - This should NOT return any fallback location if the IP geolocation fails or is invalid
 */
export async function geoLocateIPAddr(): Promise<CurrentLocation> {
  const corsUrl = `https://geolocation-db.com/json/`

  // The IP location calls might take a long time by nature, so we should impose a max wait limit.
  const response = await withTimeout(fetch(corsUrl), 3000)
  const body = (await withTimeout(response.json(), 3000)) ?? {}

  const coordinate: Coordinate = {
    latitude: body.latitude,
    longitude: body.longitude,
  }
  if (!validCoords(coordinate)) {
    // If the coordinates geocoded are not valid, we fall back to the default location
    throw new Error('Could not get a valid coordinate from the IP geolocation')
  }

  const loc: CurrentLocation = {
    coordinate,
    // city is not always found by the service (when using VPN for example)
    // If the city is not valid or missing, we return the current location, with this as an empty string
    city: !nonEmptyString(body.city) || body.city === 'Not found' ? '' : body.city,
    timestamp: Date.now(),
  }

  if (!loc.city) {
    Logger.warn(new DataError('IP location service is not obtaining the city correctly', { loc }))
  }

  return loc
}

/** The data result type for the api.geonames.org api.
 * - This is based on observation and may at some point not reflect the most up to date data structure
 * - The actual data returned may include other fields that are not in this type
 */
type NearbyZipcodesData = {
  postalCodes?: { postalCode: ShortZip }[]
  status?: {
    message: string
    value: number
  }
}

const NearbyZipCodesSchema: Yup.ObjectSchema<NearbyZipcodesData> = Yup.object().shape({
  postalCodes: Yup.array().of(Yup.object().shape({ postalCode: ShortZipSchema.required() }).default(undefined)),
  status: Yup.object()
    .shape({
      message: Yup.string().required(),
      value: Yup.number().required(),
    })
    .default(undefined),
})

/** Fetches a set of zipcodes around a supplied zipcode */
export async function getNearbyZipcodes(zipcode: ShortZip, radius = 30): Promise<ShortZip[]> {
  if (!ShortZipSchema.isValidSync(zipcode)) {
    throw new DataError('The zipcode is not a valid zipcode', { zipcode })
  }

  const url = `http://api.geonames.org/findNearbyPostalCodesJSON?postalcode=${zipcode}&country=US&radius=${radius}&username=khevamann&maxRows=500&style=short`

  const response = await fetch(url).catch()
  const data = await response.json()
  const dataValidated = await NearbyZipCodesSchema.validate(data).catch((e) => {
    const err = new DataError(
      `The nearby zipcodes API is not returning the expected data structure. ${errorToString(e)}.`,
      {
        zipcode,
        radius,
        data,
      },
    )
    Logger.error(err)
    throw err
  })

  if (dataValidated.postalCodes) {
    return dataValidated.postalCodes.map((itm) => itm.postalCode)
  } else if (dataValidated.status) {
    throw new DataError('The postal codes were not returned. ' + dataValidated.status.message, {
      zipcode,
      radius,
      data,
    })
  } else {
    throw new DataError('Could not fetch the nearby zip codes for this zipcode.', { zipcode, radius, data })
  }
}
