import { Text, Icon } from '@elements'
import { StyleSheet, TouchableOpacity, TouchableOpacityProps } from 'react-native'

import Colors from '../../constants/Colors'
import { withColorHover } from '../../hooks/withColorHover'
import { FontAwesome5IconName } from '../elements/Icon/iconNames'

type Props = TouchableOpacityProps & {
  icon: FontAwesome5IconName
  title: string
  color: string
  onPress(): void
}

function LoginOptButton({ icon, color, onPress, title, ...props }: Props) {
  return (
    <TouchableOpacity
      activeOpacity={0.2}
      style={[styles.container, { backgroundColor: color }]}
      onPress={onPress}
      {...props}
    >
      <Icon size={20} name={icon} color="white" solid noHover />
      <Text type="bold" size={13} style={styles.textStyle}>
        {title}
      </Text>
    </TouchableOpacity>
  )
}

export default withColorHover(LoginOptButton)

const styles = StyleSheet.create({
  container: {
    borderRadius: 5,
    height: 50,
    flexDirection: 'row',
    marginHorizontal: 10,
    marginVertical: 5,
    paddingVertical: 5,
    paddingHorizontal: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  textStyle: {
    color: Colors.white,
    marginLeft: 20,
  },
})
