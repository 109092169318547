import { CachedCompute, encodeArgsBasic } from '@helpers/cachedCompute'
import { getPickups } from '@helpers/order'

import { CartService } from '@/constants/types/cartService'
import { removeObjDuplicates } from '@helpers/helpers'
import { matchesAppModeSchedule } from '@helpers/products'
import { Distribution, isDistroNonPickup } from '@models/Distribution'
import { CartItem, isCartPhysical } from '@models/Order'
import { Product, isAddon, isPhysical } from '@models/Product'
import { SetLocationFlowOpts, UseSetLocationFlowOpts } from './useSetLocationFlow'

/** This should be used instead of 'getPickups' anywhere inside the addToCart flow, for efficient caching of results limited to this area. */
export const { cachedFn: getPickupsCacheAddtoCartFlow, clearCache: clearGetPickupsCacheAddtoCartFlow } = CachedCompute(
  getPickups,
  (sch, prod, opts) => sch.id + (!!prod && 'id' in prod ? prod.id : '') + encodeArgsBasic(opts),
  { seconds: 120 },
)

/** Will try to find cart distros that match with a distribution id from the distro array of a product.
 * - Used for determining whether a distro should be selected automatically when adding a new product to cart
 * - Any nonPickup schedules returned are expected to have an address
 * - Not related to the calculation of addon availability
 */
export const getMatchingCartSchedules = ({
  cart,
  product,
  isAdmin = false,
  isWholesale,
}: {
  cart: CartItem[]
  product: Product
  isAdmin?: boolean
  /** If defined, will only consider the schedules for the given app mode */
  isWholesale: boolean | undefined
}) => {
  if (!isPhysical(product)) return []

  // Get the unique distros from the cart items
  // The matching distros should be obtained from the cart items, not from the product, to ensure any nonPickup location within them has a delivery address assigned
  const cartSchedules = removeObjDuplicates(cart.filter(isCartPhysical).map((ci) => ci.distribution))

  // Get the cart schedules that match with the product schedules
  // This filtering must part from the cart schedules, to ensure any non pickup schedules should already have an address
  const matchingSchedules = cartSchedules
    .filter((cartSch) => product.distributions.map((sch) => sch.id).includes(cartSch.id))
    .filter(matchesAppModeSchedule(isWholesale))
    .filter((cartSch) => !cartSch.isHidden && (isAdmin ? true : !cartSch.closed))

  if (matchingSchedules.some((sch) => isDistroNonPickup(sch) && !sch.location.address)) {
    // This is why the matching schedules should be obtained from the cart, not from the product. Cart schedules, if delivery, will already have an address assigned. The product.distributions won't
    throw new Error('If a matching schedule is delivery, it should have an address assigned')
  }

  return matchingSchedules.filter((sch) => {
    return (
      getPickupsCacheAddtoCartFlow(sch, product, {
        excludeClosedDistros: !isAdmin,
        ignoreDisableBuyInFuture: isAdmin,
        ignoreOrderCutoffWindow: isAdmin,
      }).length > 0
    )
  })
}

/** Basic eligibility for choosing a distro for adding to cart, without considering pickups calculation */
export const getCanAddScheduleBasic = ({
  dist,
  isAdmin,
  isWholesale,
}: {
  dist: Distribution | undefined
  isAdmin: boolean
  isWholesale: boolean | undefined
}) => {
  if (!dist || dist.isHidden) return false
  if (!isAdmin && dist.closed) return false
  if (isDistroNonPickup(dist) && !dist.location.address) return false
  if (!matchesAppModeSchedule(isWholesale)(dist)) return false
  return true
}

/** Auto-selects a distribution based on the SetPickup opts */
export function getAutoSelectedSchedule({
  distribution: preSelectedDistro,
  matchingSchedulesAvailAddon,
  matchingSchedulesCart,
  prod,
  isAdmin,
  isWholesale,
}: Pick<SetLocationFlowOpts, 'distribution' | 'matchingSchedulesAvailAddon' | 'matchingSchedulesCart' | 'prod'> &
  Pick<UseSetLocationFlowOpts, 'isWholesale'> &
  Pick<CartService, 'isAdmin'>): Distribution | undefined {
  // if defined by the end, means the distro will be auto-selected
  let autoSelectedDistro: Distribution | undefined = preSelectedDistro

  if (!autoSelectedDistro) {
    // Try the other scenarios that would lead to a single option to auto-select

    if (isAddon(prod) && !isAdmin && matchingSchedulesAvailAddon) {
      // check if there's only one valid distro option, and assign it
      if (
        matchingSchedulesAvailAddon.length === 1 &&
        getCanAddScheduleBasic({ dist: matchingSchedulesAvailAddon[0], isAdmin, isWholesale })
      ) {
        autoSelectedDistro = matchingSchedulesAvailAddon[0]
      }
    } else if (
      matchingSchedulesCart.length === 1 &&
      getCanAddScheduleBasic({ dist: matchingSchedulesCart[0], isAdmin, isWholesale })
    ) {
      // if there's only one valid distro option, assign it
      autoSelectedDistro = matchingSchedulesCart[0]
    } else if (
      prod.distributions.length === 1 &&
      getCanAddScheduleBasic({ dist: prod.distributions[0], isAdmin, isWholesale })
    ) {
      // if there's only one valid distro option, assign it
      autoSelectedDistro = prod.distributions[0]
    }
  }

  if (!!autoSelectedDistro && isDistroNonPickup(autoSelectedDistro) && !autoSelectedDistro.location.address) {
    const autoSelectedId = autoSelectedDistro.id
    // get the address from any cart matching distro of the same id
    const matchingAddress = matchingSchedulesCart.find((d) => d.id === autoSelectedId)?.location.address

    // assign the cart address to the autoSelected distro
    autoSelectedDistro.location.address = matchingAddress
  }

  return autoSelectedDistro
}
