import { formatRelative } from 'date-fns'
import { useEffect, useState } from 'react'
import { Platform, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { ButtonGroup } from 'react-native-elements'
import { useSelector } from 'react-redux'

import { loadReadNotes, markAllRead, markRead } from '../api/Notifications'
import LoaderWithMessage from '../components/LoaderWithMessage'
import { ButtonClear } from '../components/elements/ButtonClear'
import { Divider } from '../components/elements/Divider'
import { Text, TextH1, TextH2 } from '../components/elements/Text'
import Colors from '../constants/Colors'
import { SHADOW_2, globalStyles } from '../constants/Styles'
import { formatNotification } from '../constants/helpers/notification'
import { withAuth } from '../hooks/withAuth'
import { Notification } from '../models/Notification'
import { notificationsListSelector, userSelector } from '../redux/selectors'

function NotificationHistoryScreen() {
  const [tabIndex, setTabIndex] = useState(0)
  const [loading, setLoading] = useState({
    read: true,
    unread: false,
  })
  const [unreadNotifs, setUnreadNotifs] = useState<Notification[]>([])
  const [readNotifs, setReadNotifs] = useState<Notification[]>([])

  const notifications = useSelector(notificationsListSelector)
  const user = useSelector(userSelector)

  useEffect(() => {
    if (!notifications) return

    const unread = notifications.filter((el) => {
      return !el.isRead
    })
    setUnreadNotifs(unread)
  }, [notifications])

  const updateLoading = (key: keyof typeof loading, value: boolean) => {
    setLoading((curr) => ({ ...curr, [key]: value }))
  }

  const onMarkAllRead = () => {
    // Delay subsequential notification listener updates
    // This will mark the entire set of notifications as read, but also it needs a delay in order to fetch the next set of notifications
    updateLoading('unread', true)

    markAllRead(unreadNotifs)

    setTimeout(() => {
      updateLoading('unread', false)
    }, 1000)
  }
  const switchTabs = (idx: number) => {
    setTabIndex(idx)
    if (idx === 1 && readNotifs.length === 0) {
      loadReadNotes(user.id)
        .then((notes) => {
          setReadNotifs(notes)
        })
        .finally(() => updateLoading('read', false))
    }
  }
  const itemList = (item: Notification, index: number) => {
    const formattedNotification = formatNotification(item)
    return (
      <View key={index} style={Platform.OS === 'web' ? styles.webContainer : styles.mobileContainer}>
        <TextH1 style={{ marginBottom: 5 }}>{formattedNotification.title}</TextH1>

        <Text>{formatRelative(item.date.toJSDate(), new Date())}</Text>

        <Text>{formattedNotification.body}</Text>
        <View style={globalStyles.flexRow}>
          {!item.isRead && (
            <ButtonClear
              title="Mark as Read"
              onPress={() => {
                markRead(item.id)
              }}
            />
          )}
        </View>
      </View>
    )
  }

  return (
    <View style={globalStyles.flex1}>
      <ButtonGroup
        onPress={(idx) => switchTabs(idx)}
        selectedIndex={tabIndex}
        buttons={['Unread', 'Read']}
        containerStyle={{ maxWidth: 500, width: '90%', alignSelf: 'center' }}
        selectedButtonStyle={{ backgroundColor: Colors.green }}
      />
      {tabIndex === 0 && unreadNotifs.length > 0 && (
        <TouchableOpacity
          onPress={onMarkAllRead}
          style={{ width: Platform.OS === 'web' ? '60%' : '100%', alignSelf: 'center', margin: 10, padding: 10 }}
        >
          <TextH2 style={{ fontSize: 18, textAlign: 'center', color: Colors.green }}>Mark all as read</TextH2>
        </TouchableOpacity>
      )}

      <ScrollView style={{ width: '100%', height: '100%' }}>
        {tabIndex === 0 && (
          <View>
            {unreadNotifs.map((item, index) => itemList(item, index + readNotifs.length))}
            {unreadNotifs.length === 0 && (
              <LoaderWithMessage icon="bell-slash" loading={loading.unread} title="No Notifications">
                <Text>
                  You have no unread notifications! All your messages and reminders will show up here in the future.
                </Text>
              </LoaderWithMessage>
            )}
            <Divider clear top={20} />
          </View>
        )}
        {tabIndex === 1 && (
          <View>
            {readNotifs.map((item, index) => itemList(item, index + 1))}
            {readNotifs.length === 0 && (
              <LoaderWithMessage loading={loading.read} icon="bell-slash" title="No Notifications">
                <Text>You have no notifications! All your messages and reminders will show up here in the future.</Text>
              </LoaderWithMessage>
            )}
            <Divider clear top={20} />
          </View>
        )}
      </ScrollView>
    </View>
  )
}

export default withAuth(NotificationHistoryScreen, { noSafeAreaInsets: true })

const styles = StyleSheet.create({
  webContainer: {
    ...SHADOW_2,
    margin: 10,
    borderRadius: 10,
    backgroundColor: Colors.white,
    padding: 10,
    paddingLeft: 20,
    alignSelf: 'center',
    minWidth: 350,
    width: '60%',
  },
  mobileContainer: {
    ...SHADOW_2,
    backgroundColor: Colors.white,
    marginHorizontal: 20,
    marginVertical: 5,
    borderRadius: 10,
    padding: 10,
    paddingLeft: 20,
  },
  topTab: {
    width: 120,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
})
