import { FontAwesome5 } from '@expo/vector-icons'
import { TextProps, View, StyleSheet, ViewStyle, ViewProps, StyleProp } from 'react-native'

import Colors from '../../../constants/Colors'
import { Text } from '../Text'
import { Touchable } from '../Touchable'

export interface IconWithBadgeProps extends TextProps {
  style?: StyleProp<ViewStyle>
  size?: number
  name: string
  color?: string
  solid?: boolean
  badgeValue: number
  onPress?: () => void
  url?: string
}

export default function IconWithBadge({
  style,
  size = 30,
  color = Colors.green,
  onPress,
  badgeValue,
  url,
  ...props
}: IconWithBadgeProps) {
  return (
    <Container onPress={onPress} style={[{ width: size + 5 }, style]} url={url}>
      <View>
        <FontAwesome5 size={size} color={color} {...props} />
        {badgeValue > 0 && (
          <View style={[styles.badgeCont, { right: (size - 20) / 2 - 5, top: (size - 20) / 2 - 5 }]}>
            <Text size={10} color={Colors.white} allowFontScaling={false} center type="medium">
              {badgeValue > 9 ? '9+' : badgeValue}
            </Text>
          </View>
        )}
      </View>
    </Container>
  )
}

function Container({ children, ...rest }: ViewProps & { children: any; onPress?: () => void; url?: string }) {
  if (rest.onPress || rest.url) return <Touchable {...rest}>{children}</Touchable>
  return <View {...rest}>{children}</View>
}

const styles = StyleSheet.create({
  badgeCont: {
    position: 'absolute',
    justifyContent: 'center',
    right: 0,
    top: 0,
    borderWidth: 2,
    borderRadius: 18,
    paddingHorizontal: 3,
    height: 20,
    minWidth: 20,
    borderColor: Colors.white,
    backgroundColor: Colors.green,
  },
})
