import { Logger } from '@/config/logger'
import { useCartService } from '@/hooks/useCart'
import { useAddToCartFlow } from '@/hooks/useCart/addToCartFlow/useAddToCartFlow'
import { useLayout } from '@/hooks/useLayout'
import { HeaderText, Text, TextH3 } from '@elements'
import { findItemInCart } from '@helpers/canUpdateQuantity'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { formatMoney, formatUnitPriceRange } from '@helpers/display'
import { formatDistributionType } from '@helpers/location'
import { getStock, getUnitPrice, getUnits } from '@helpers/products'
import { CartItem, isCartStandard } from '@models/Order'
import { Product, hasUnits } from '@models/Product'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { memo, useCallback, useMemo } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { AddCartBtn } from '../../../components/AddCartBtn'
import { ButtonClear } from '../../../components/elements/ButtonClear'
import { Touchable } from '../../../components/elements/Touchable'
import Colors from '../../../constants/Colors'
import { ShoppingStackParamList } from '../../../navigation/types'
import { navPropsSelector, wholesaleSelector } from '../../../redux/selectors'
import { styles } from './ProductDetails'
import { SharePriceDetails } from './SharePriceDetails'

export const PriceContainer = memo(function PriceContainer({
  product,
  unitId,
  setUnitId,
}: {
  product: Product
  unitId: string
  setUnitId: (unitId: string) => void
}) {
  const { isWholesale } = useSelector(wholesaleSelector)
  const { isSmallDevice } = useLayout()
  const navigation = useNavigation<StackNavigationProp<ShoppingStackParamList, 'ProductDetails'>>()
  const {
    params: { farmSlug },
  } = useRoute<RouteProp<ShoppingStackParamList, 'ProductDetails'>>()
  const { csa } = useSelector(navPropsSelector)
  const { cart } = useCartService()
  const { modifyDates } = useAddToCartFlow({ cartServiceType: 'consumer', isWholesale })

  const onPressModifyDates = useCallback(
    (cartItemId: string) => {
      try {
        modifyDates(cartItemId)
      } catch (err) {
        Logger.error(err)
      }
    },
    [modifyDates],
  )

  const buyingOptions = useMemo(() => getUnits(product, { isWholesale }), [product, isWholesale])

  const unit = useMemo(
    () => (!unitId ? undefined : buyingOptions?.find((u) => u.id === unitId)),
    [buyingOptions, unitId],
  )

  const goBack = () => (navigation.canGoBack() ? navigation.goBack() : navigation.navigate('FarmShop', { farmSlug }))

  /** If the product is in cart, `itemInCart` should be defined as the cart item. Should consider the unit id for finding the product in the cart */
  const itemInCart: CartItem | undefined = useMemo(() => {
    return findItemInCart({ product, unit }, cart)
  }, [cart, product, unit])

  if (isWholesale === undefined) return null

  if (hasUnits(product)) {
    const stock = getStock(product, unitId)

    /*Unit prod & small screen */
    return isSmallDevice ? (
      <>
        {buyingOptions?.map((unit) => {
          const stock = getStock(product, unit.id)
          return (
            <View key={unit.id}>
              <View style={styles.unit}>
                <View>
                  <Text>{unit.name}</Text>
                  {stock === 0 ? (
                    <Text style={stock < 5 && { color: Colors.red }} type="medium">
                      Out of stock
                    </Text>
                  ) : (
                    <Text style={stock < 5 && { color: Colors.red }}>{stock} left in stock</Text>
                  )}
                </View>
                <View style={styles.unitPrice}>
                  <HeaderText>{`${formatUnitPriceRange(product.units, unit.id, isWholesale)}`}</HeaderText>
                  <Text>{`(${formatMoney(getUnitPrice(product, unit.id, isWholesale))}/${product.baseUnit})`}</Text>
                </View>
              </View>
            </View>
          )
        })}
      </>
    ) : (
      /*Unit prod & non-small screen */
      <>
        <View style={styles.marginV40}>
          <Text numberOfLines={1} type="bold" size={14} style={styles.marginBtm5}>
            {`${formatUnitPriceRange(product.units, unitId, isWholesale)}`}
          </Text>
          <Text>
            {unitId ? `(${formatMoney(getUnitPrice(product, unitId, isWholesale))}/${product.baseUnit})` : ''}
          </Text>
        </View>
        <Text>{unitId ? `Size: ${unit?.name}` : ''}</Text>
        {stock === 0 ? (
          <Text style={stock < 5 && { color: Colors.red }} type="medium">
            Out of stock
          </Text>
        ) : (
          <Text style={stock < 5 && { color: Colors.red }}>{stock} left in stock</Text>
        )}
        <View style={styles.unitContainer}>
          {buyingOptions?.map((unit) => {
            const hasStock = getStock(product, unit.id) > 0

            return (
              <Touchable
                key={unit.id}
                style={[
                  styles.unitItem,
                  unit.id === unitId && styles.unitItemSelected,
                  !hasStock && styles.unitItemDisabled,
                ]}
                onPress={() => setUnitId(unit.id)}
              >
                <Text color={unit.id === unitId ? Colors.green : undefined} size={10}>
                  {unit.name}
                </Text>
              </Touchable>
            )
          })}
        </View>
        <AddCartBtn product={product} unit={unitId ? unit : undefined} csa={csa} isWholesale={isWholesale} />
        {itemInCart && isCartStandard(itemInCart) && (
          <>
            <View style={styles.cartItemInfo}>
              <TextH3 style={styles.marginRight}>
                {itemInCart.pickups.length === 1
                  ? `${formatDistributionType(itemInCart.distribution.location, {
                      action: true,
                    })}: ${itemInCart.pickups[0].toISODate()}`
                  : `No. ${formatDistributionType(itemInCart.distribution.location, { plural: true })}: ${
                      itemInCart.pickups.length
                    }`}
              </TextH3>
              {itemInCart.pickups.length > 1 && <TextH3>Qty. per pickup: {itemInCart.quantity}</TextH3>}
            </View>
            {!itemInCart.product.disableBuyInFuture && (
              <ButtonClear
                title={itemInCart.pickups?.length === 1 ? 'Add More Dates' : 'Modify dates'}
                style={styles.btnBelowAddCart}
                onPress={() => onPressModifyDates(itemInCart.id)}
              />
            )}
          </>
        )}
        <ButtonClear title="Continue Shopping" style={styles.btnBelowAddCart} onPress={goBack} />
      </>
    )
  } else {
    // Shares
    return (
      <View style={styles.marginV20}>
        <SharePriceDetails product={product} />
        {!isSmallDevice && (
          <>
            <AddCartBtn product={product} csa={csa} isWholesale={isWholesale} />
            <ButtonClear title="Continue Shopping" style={styles.btnBelowAddCart} onPress={goBack} />
          </>
        )}
      </View>
    )
  }
},
propsAreDeepEqual)
