import { formatStreetAddress } from '@helpers/display'
import { Address } from '@models/Address'
import {
  AlgoliaGeoDistro,
  AlgoliaGeoDoc,
  AlgoliaGeoFarm,
  AlgoliaGeoProduct,
  isGeoDistro,
  isGeoFarm,
  isGeoProduct,
} from '@models/Algolia'
import { FarmStatus } from '@models/Farm'
import { FlatList, Pressable, StyleSheet, View } from 'react-native'

import { Image, ResizedSuffix } from '../../components/Image'
import { Icon } from '../../components/elements/Icon/Icon'
import { HeaderText, Text } from '../../components/elements/Text'
import { Touchable } from '../../components/elements/Touchable'
import Colors from '../../constants/Colors'
import { globalStyles } from '../../constants/Styles'
import { DistroContainer, ProductContainer, FarmContainer } from './FarmContainer'

type PopupCardProps = {
  data: AlgoliaGeoDoc[]
} & SingleCardProps

type SingleCardProps = {
  close: () => void
  showImage?: boolean
  onPressChevron?: () => void
}

const makeUrl = (data: AlgoliaGeoDoc<AlgoliaGeoFarm | AlgoliaGeoProduct | AlgoliaGeoDistro>) => {
  const farmUrl = `/farms/${data.farm.id}`
  const takeToShop = data.farm.status === FarmStatus.Registered
  return `${farmUrl}${takeToShop ? '/shop' : ''}?goBack=home`
}

function FarmCard({
  data,
  close,
  showImage,
  onPressChevron,
}: { data: AlgoliaGeoDoc<AlgoliaGeoFarm> } & SingleCardProps) {
  const hide = showImage === false
  return (
    <Touchable url={makeUrl(data)} onPress={close}>
      <View style={{ margin: 15, marginBottom: 0, height: 80 }}>
        <HeaderText size={16}>{data.farm.name}</HeaderText>
        <Text>
          at <Icon name="map-marker-alt" size={14} /> {formatStreetAddress(data.address as Address)}
        </Text>
        {data.farm.practices?.length > 0 && (
          <Text color={Colors.shades[300]} size={10} type="medium">
            {data.farm.practices.join(' • ')}
          </Text>
        )}
      </View>
      {onPressChevron && (
        <Pressable onPress={onPressChevron} style={[styles.chevron, { paddingTop: hide ? 20 : 10 }]}>
          <Icon name={hide ? 'sort-up' : 'sort-down'} size={30} color="black" />
        </Pressable>
      )}
      {!hide && <Image source={{ uri: data.images[0] }} style={styles.cardImg} />}
    </Touchable>
  )
}

function DistroCard({
  data,
  close,
  showImage,
  onPressChevron,
}: { data: AlgoliaGeoDoc<AlgoliaGeoDistro> } & SingleCardProps) {
  const hide = showImage === false
  return (
    <Touchable url={makeUrl(data)} onPress={close}>
      <View style={{ margin: 15, marginBottom: 0, height: 80 }}>
        <HeaderText size={14}>{data.farm.name}</HeaderText>
        <Text style={{ marginVertical: 5 }}>
          at <Icon name="map-marker-alt" size={14} /> {data.name}
        </Text>
        <Text numberOfLines={1} color={Colors.shades['300']}>
          {data.scheduleText}
        </Text>
      </View>
      {onPressChevron && (
        <Pressable onPress={onPressChevron} style={[styles.chevron, { paddingTop: hide ? 20 : 10 }]}>
          <Icon name={hide ? 'sort-up' : 'sort-down'} size={30} color="black" />
        </Pressable>
      )}
      {!hide && <Image source={{ uri: data.images[0] }} style={styles.cardImg} resizeSuffix={ResizedSuffix.THUMB} />}
    </Touchable>
  )
}

function ProductCard({
  data,
  close,
  showImage,
  onPressChevron,
}: { data: AlgoliaGeoDoc<AlgoliaGeoProduct> } & SingleCardProps) {
  const hide = showImage === false
  return (
    <Touchable url={makeUrl(data)} onPress={close}>
      <View style={{ margin: 15, marginBottom: 0, height: 100 }}>
        <HeaderText size={16}>{data.name}</HeaderText>
        <Text style={{ marginVertical: 5 }}>
          from <Text type="medium">{data.farm.name}</Text>
        </Text>
        <Text numberOfLines={2} color={Colors.shades['300']}>
          {data.shortDescription}
        </Text>
      </View>
      {onPressChevron && (
        <Pressable onPress={onPressChevron} style={[styles.chevron, { paddingTop: hide ? 20 : 10 }]}>
          <Icon name={hide ? 'sort-up' : 'sort-down'} size={30} color="black" />
        </Pressable>
      )}
      {!hide && <Image source={{ uri: data.images[0] }} style={[styles.cardImg, { height: 120 }]} />}
    </Touchable>
  )
}

function SingleCard({ data, ...props }: { data: AlgoliaGeoDoc } & SingleCardProps) {
  if (isGeoFarm(data)) return <FarmCard {...{ data, ...props }} />
  if (isGeoDistro(data)) return <DistroCard {...{ data, ...props }} />
  if (isGeoProduct(data)) return <ProductCard {...{ data, ...props }} />
  return null
}

function MultiCard(data: AlgoliaGeoDoc, close: () => void) {
  if (isGeoFarm(data)) return <FarmContainer farm={data.farm} close={close} />
  if (isGeoDistro(data)) return <DistroContainer data={data} close={close} />
  if (isGeoProduct(data)) return <ProductContainer data={data} close={close} />
  return null
}

/** The main popup header */
function MultiHeader(data: AlgoliaGeoDoc[]) {
  const hasProds = data.some(isGeoProduct)
  return (
    <View style={globalStyles.margin10}>
      <Text type="medium" size={14}>
        {hasProds ? data.filter(isGeoProduct).length : data.length} {hasProds ? 'products' : 'distributions'}
      </Text>
      {data[0]?.address && (
        <Text>
          at <Icon name="map-marker-alt" size={14} /> {formatStreetAddress(data[0].address as Address)}
        </Text>
      )}
    </View>
  )
}

function PopUpCard({ data, ...props }: PopupCardProps) {
  if (data.length === 1) {
    return SingleCard({ data: data[0], ...props })
  }
  return (
    <FlatList
      style={styles.popupList}
      contentContainerStyle={globalStyles.padding10}
      data={data}
      ListHeaderComponent={MultiHeader(data)}
      keyExtractor={(itm) => itm.id}
      renderItem={({ item }) => MultiCard(item, props.close)}
    />
  )
}

const styles = StyleSheet.create({
  cardImg: {
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    width: '100%',
    height: 140,
    alignSelf: 'flex-end',
  },
  chevron: { position: 'absolute', right: 0, top: 0, height: 65, width: 65, alignItems: 'center' },
  popupList: { paddingHorizontal: 10 },
})

export default PopUpCard
