import { ModalComponentProps } from '@/constants/types/modalTypes'
import { Modal } from './Popups'
import { PromptComponent, PromptComponentProps } from './PromptComponent'

/** Modal wrapper that opens a modal with a text input */
export function Prompt(
  title: string,
  contentProps: PromptComponentProps,
  modalComponentProps?: Omit<ModalComponentProps, 'title' | 'header' | 'dismissable'>,
): void {
  Modal(<PromptComponent {...contentProps} />, {
    header: true,
    title,
    dismissable: true,
    ...modalComponentProps,
  })
}
