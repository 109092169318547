import Colors from '@/constants/Colors'
import { Icon, Text } from '@elements'
import { formatMoney } from '@helpers/display'
import { MoneyCalc } from '@helpers/money'
import { Money } from '@models/Money'
import { memo } from 'react'
import { StyleSheet, View } from 'react-native'
import { LinearProgress } from 'react-native-elements'

/** Component that shows if the farm minimum was met or its completion progress */
export const FarmMinimum = memo(function FarmMinimum({ orderMin, subtotal }: { orderMin: Money; subtotal: Money }) {
  if (MoneyCalc.isGTE(subtotal, orderMin))
    return (
      <View style={styles.main}>
        <Text>{`${formatMoney(orderMin)} minimum met`}</Text>
        <Icon noHover solid name="check-circle" />
      </View>
    )

  return (
    <View style={styles.main}>
      <Text>{`${formatMoney(orderMin)} minimum`}</Text>
      <LinearProgress
        animation={{ duration: 500 }}
        value={subtotal.value / orderMin.value}
        color={Colors.green}
        style={styles.linearProgress}
      />
    </View>
  )
})

const styles = StyleSheet.create({
  main: {
    gap: 10,
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  linearProgress: {
    height: 12,
    borderRadius: 8,
    width: 100,
  },
})
