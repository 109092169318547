import { useEffect, useState } from 'react'
import { connectRange } from 'react-instantsearch-core'

import { fromJSDate } from '@helpers/time'
import { DatePicker, DateRangeNames } from '../Analytics/DatePicker'
import { getLast3MonthsDate } from '../Analytics/util'

type Props = {
  refine: (...args: any[]) => any
  timezone: string
  /**  reset hits to get reset correct data */
  setUpdatedHits: React.Dispatch<any>
  /** If disableFutureDates is true, the DatePicker won't allow any date to be chosen after today. By default is true */
  disableFutureDates?: boolean
}

const ConnectedDateRange = connectRange(function ConnectedDateRange({
  refine,
  timezone,
  setUpdatedHits,
  disableFutureDates,
}: Props) {
  const [date, setDate] = useState({
    label: DateRangeNames.Last_3_Months,
    value: getLast3MonthsDate(),
  })

  useEffect(() => {
    const refinedRange = {
      min: fromJSDate(date.value.startDate, timezone).startOf('day').toMillis(),
      max: fromJSDate(date.value.endDate, timezone).endOf('day').toMillis(),
    }
    refine(refinedRange)
    setUpdatedHits(null)
  }, [date, refine])

  return (
    <DatePicker
      timezone={timezone}
      setDate={(name, date) => setDate({ label: name, value: date })}
      date={date}
      disableFutureDates={disableFutureDates}
    />
  )
})

export default ConnectedDateRange
