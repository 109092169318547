import { AdminDrawerParamList } from '@/admin/navigation/types'
import { Logger } from '@/config/logger'
import { isWeb } from '@/constants/Layout'
import { adminFarmSelector, userSelector } from '@/redux/selectors'
import { InvalidMerchantIdError, linkStripeAccount, updateFarm } from '@api/Farms'
import { createStripeAccountHelper } from '@api/StripeClient'
import { Alert, Loader, Toast } from '@elements'
import { openUrl } from '@helpers/client'
import { errorToString } from '@helpers/helpers'
import { Farm } from '@models/Farm'
import { hasGrownByPrivilege, UserRole } from '@models/User'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useFormik } from 'formik'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { onFarmMerchantValidate, shouldValidateMerchantId, toForm } from './helpers'
import { PaymentsForm, validationSchema } from './types'

/** Data layer for payments tab */
export function usePaymentsData() {
  const [loading, setLoading] = useState(false)

  const farm = useSelector(adminFarmSelector)
  const user = useSelector(userSelector)

  const initialValues = useMemo<PaymentsForm>(() => toForm(farm), [farm])

  const navigation = useNavigation<StackNavigationProp<AdminDrawerParamList, 'FarmSetup'>>()

  const { params } = useRoute<RouteProp<AdminDrawerParamList, 'FarmSetup'>>()

  const hasGrownByAdminPrivilege = hasGrownByPrivilege(user, UserRole.Admin)

  useEffect(() => {
    if (isWeb && params?.stripeRedirect === 'return') {
      Alert(
        'Success: Your data was submitted to Stripe.',
        'Stripe may need additional information before your account is approved for selling. \n\nCheck your farm status and complete setup if more info is required.',
        [
          {
            text: 'Ok',
            onPress: () => navigation.setParams({ stripeRedirect: undefined }),
            style: 'default',
          },
        ],
      )
    }
  }, [navigation, params?.stripeRedirect])

  const onSubmitHandler = useCallback(
    async (values: PaymentsForm) => {
      setLoading(true)

      try {
        /* Check the Merchant ID only if the farm has ebt enabled and the value is different from the one in the db */
        if (shouldValidateMerchantId(farm, values)) {
          await onFarmMerchantValidate(farm, values)
        }
      } catch (error) {
        Logger.error(error)
        if (error instanceof InvalidMerchantIdError) {
          Alert('Invalid WorldPay Merchant ID', 'Please enter a valid WorldPay Merchant ID and try again later.')
        } else {
          Alert('Internal Server Error', 'Please try again later.')
        }
        setLoading(false)
        return
      }

      try {
        await updateFarm({
          offlinePayments: values.offlinePaymentsMessage,
          worldPayMerchantId: values.worldPayMerchantId,
          ebt: { ...farm.ebt, fnsOnline: values.fnsOnline },
          tipsAndFees: {
            showTipOption: values.showTipOption,
            customerChooseToPayFees: values.customerChooseToPayFees,
          },
          paymentTypes: {
            ...farm.paymentTypes,
            cash: values.offlineEnabled,
            ebt: values.ebtPaymentEnabled,
            check: values.achPaymentEnabled,
          },
          orderMinimum: {
            retail: values.minCartAmount,
          },
          id: farm.id,
        })

        setLoading(false)

        /** this will only be used when editing fnsOnline number after a farmer has already connect to WorldPay with valid merchantId */
        if (farm.paymentTypes.ebt && farm.worldPayMerchantId && values.fnsOnline !== farm.ebt?.fnsOnline) {
          Toast('Saved New FNS Online Number')
          return
        }

        Toast('Saved')

        const paymentsOnboardComplete = farm.onboardSteps?.payments && farm.onboardSteps?.ebt
        // Navigate to Onboard only if the onboard process is not complete
        if (!paymentsOnboardComplete) {
          navigation.navigate('Onboard')
        }
      } catch (err) {
        Logger.error(err)
        setLoading(false)
        Toast('There was an error updating your payment settings, please try again or contact support.')
      }
    },
    [farm, navigation],
  )

  const formik = useFormik({
    initialValues,
    onSubmit: onSubmitHandler,
    enableReinitialize: true,
    validationSchema,
  })

  return { hasGrownByAdminPrivilege, loading, farm, formik }
}

export type UsePaymentsDataType = ReturnType<typeof usePaymentsData>

export const connectToStripe = async (farm: Farm) => {
  let accountRef = farm.accountRef

  Loader(true)
  if (!accountRef) {
    try {
      accountRef = await createStripeAccountHelper(farm)
    } catch (err) {
      Loader(false) // first close the loader
      Alert('Stripe Error', errorToString(err) || 'Unable to create a Stripe account, please contact support')
      Logger.debug(err)
      return
    }
  }

  try {
    const stripeUrl = await linkStripeAccount(accountRef)
    /** Here we want to open the url in an in-app browser because this way the user can easily navigate back to the app once they are done with the stripe steps. */
    openUrl(stripeUrl)
  } catch (err) {
    Loader(false)
    Alert('Stripe Error', 'Unable to connect to Stripe, please try again later.')
    Logger.debug(err)
  }
  Loader(false)
}
