import Colors from '@/constants/Colors'
import { memo } from 'react'
import { Pressable, StyleProp, StyleSheet, ViewStyle } from 'react-native'
import { Icon } from '../Icon/Icon'
import { BodyProps, Text } from '../Text'

type Props = {
  disabled?: boolean
  isSelected: boolean
  onPress?: () => void
  contStye?: StyleProp<ViewStyle>
  label?: string
  iconSize?: number
} & Omit<BodyProps, 'children' | 'onPress' | 'disabled'>

export const RadioButton = memo(function RadioButton(props: Props) {
  const { disabled, isSelected, onPress, contStye, label, iconSize = 20, ...textProps } = props

  return (
    <Pressable disabled={disabled} onPress={onPress} style={[styles.container, contStye]}>
      <Icon
        size={iconSize}
        iconSet="Ionicons"
        disabled={disabled}
        name={isSelected ? 'radio-button-on' : 'radio-button-off'}
      />
      {!!label && (
        <Text color={disabled ? Colors.shades['200'] : undefined} disabled={disabled} {...textProps}>
          {label}
        </Text>
      )}
    </Pressable>
  )
})

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
})
