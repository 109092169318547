import { Text } from '@elements'
import { View } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import Colors from '@/constants/Colors'

export function ItemRow({
  leftSideValues,
  rightSideValues,
  isHeader = false,
}: {
  leftSideValues: string[]
  rightSideValues: string[]
  isHeader?: boolean
}) {
  const styles = useStyles()
  return (
    <View style={{ flexDirection: 'row', marginVertical: 5 }}>
      <View style={styles.rowLeftCont}>
        {leftSideValues.map((value, idx) => (
          <Text key={idx} color={isHeader ? Colors.primaryGray : undefined}>
            {value}
          </Text>
        ))}
      </View>
      <View style={styles.rowRightCont}>
        {rightSideValues.map((value, idx) => (
          <View key={idx} style={styles.rightSideRow}>
            <Text numberOfLines={1} color={isHeader ? Colors.primaryGray : undefined}>
              {value}
            </Text>
          </View>
        ))}
      </View>
    </View>
  )
}
const useStyles = CreateResponsiveStyle(
  {
    rowLeftCont: {
      flex: 3,
      marginVertical: 10,
      flexDirection: 'row',
    },
    rowRightCont: {
      flex: 2,
      marginVertical: 10,
      flexDirection: 'row',
    },
    rightSideRow: {
      flex: 1,
      alignItems: 'flex-end',
    },
  },
  {
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      rowLeftCont: {
        flex: 1,
      },
      rightSideRow: {
        marginRight: 5,
      },
    },
  },
)
