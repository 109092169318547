import React, { forwardRef, MutableRefObject, Ref } from 'react'
// eslint-disable-next-line no-restricted-imports
import { KeyboardAwareScrollView, KeyboardAwareScrollViewProps } from 'react-native-keyboard-aware-scroll-view'

/** Use this when creating a ref for this component, as follows `const scrollViewRef = useRef(null) as KeyboardAvoidingScrollViewRef` */
export type KeyboardAvoidingScrollViewRef = MutableRefObject<KeyboardAwareScrollView | null>

/** Re-export of internal component props, so they can be imported from here without eslint warning */
export type KeyboardAvoidingScrollViewProps = KeyboardAwareScrollViewProps

/** Re-export of internal component type, so it can be imported from here without eslint warning */
export type KeyboardAvoidingScrollViewType = KeyboardAwareScrollView

/** Scrollview configured for automatically scrolling to ensure any selected text input remains in view as the mobile keyboard opens.
 * - It's not perfect, you can't control the exact final position for the input. But it does help immensely by at least preventing the keyboard from occluding the input.
 * - It takes a varying amount of time to perform the automatic scrolling. Usually between 500-1000 milisecs. (From my testing in android emulator)
 * - Greater control is possible by using ref methods. https://github.com/APSL/react-native-keyboard-aware-scroll-view#programatically-scroll-to-any-textinput
 */
export const KeyboardAvoidingScrollView = forwardRef<KeyboardAwareScrollView, KeyboardAvoidingScrollViewProps>(
  function KeyboardAvoidingScrollView(props: KeyboardAvoidingScrollViewProps, ref: Ref<KeyboardAwareScrollView>) {
    return (
      <KeyboardAwareScrollView
        keyboardOpeningTime={0}
        enableOnAndroid
        extraHeight={250}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps="handled"
        ref={ref}
        {...props}
      />
    )
  },
)
