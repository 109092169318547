import { StyleSheet, View } from 'react-native'

import Colors from '../constants/Colors'
import { Text } from './elements/Text'

type Props = {
  certs?: object
}

export function Certifications({ certs }: Props) {
  if (!certs) return null
  return (
    <View style={styles.certContainer}>
      {Object.keys(certs).map((cert: string) => {
        return (
          <Text key={cert} style={styles.certText} type="bold">
            {cert}
          </Text>
        )
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  certContainer: {
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  certText: {
    padding: 5,
    textAlign: 'center',
    color: Colors.green,
  },
})
