import { Hit } from '@algolia/client-search'
import { getCoordString } from '@helpers/coordinate'
import { InvalidAmount } from '@helpers/display'
import { errorToString } from '@helpers/helpers'
import { isActive, matchesAppModeProduct } from '@helpers/products'
import { omit } from '@helpers/typescript'
import { AlgoliaGeoDoc, AlgoliaGeoProduct, FILTERS, FILTERS_QUERY, asFilter, asNumFilter } from '@models/Algolia'
import { Coordinate } from '@models/Coordinate'
import { Farm } from '@models/Farm'
import { Product, ProductType, hasUnits } from '@models/Product'
import {
  and,
  limit,
  or,
  where,
  type QueryCompositeFilterConstraint,
  type QueryNonFilterConstraint,
} from 'firebase/firestore'

import { consumerIndex } from '../config/Algolia'
import { updateFarm } from './Farms'
import { productsCollection } from './framework/ClientCollections'

import { OrderProductType } from '@/admin/navigation/types'
import { getProdTypes } from '@/admin/screens/Order/OrderCreatorScreen/OrderCreatorScreen.helper'
import { Logger } from '@/config/logger'
import { buildQueryFilter, parseAlgoliaResults } from '@helpers/algolia-client'
import { buildProduct } from '@helpers/builders/buildProduct'
import { sortByRankAndName } from '@helpers/sorting'
import { formatToSafeSlug } from '@helpers/urlSafeSlug'
import { ErrorWithCode } from '@shared/Errors'
import { SearchIndex } from 'algoliasearch'
import { DateTime } from 'luxon'
import { errorCatcher } from './Errors'
import { buildUrlSafeSlug } from './UrlSafeSlugs'
import { callEndpoint } from './v2'

/** loadProductsByFarm returns all of the available products for a farm.
 * - If the farm has too many products, there might be an untraceable crash with no error on mobile, therefore filtering by isHidden is best done server-side, and limit should also prevent that.
 */
export async function loadProductsByFarm(farmSlug: string, limitN = 100): Promise<Product[]> {
  return productsCollection.fetchAll(
    and(
      or(where('farm.urlSafeSlug', '==', farmSlug), where('farm.id', '==', farmSlug)),
      where('isHidden', '==', false),
    ),
    limit(limitN),
  )
}

/** loadProductsByDistribution returns all of the available products for a distribution.
 * - This api should NOT filter by "isHidden: false". schedule editing validation depends on getting ALL products for the distId
 */
export async function loadProductsByDistribution(distId: string, farmSlug: string): Promise<Product[]> {
  return productsCollection.fetchAll(
    and(
      or(where('farm.urlSafeSlug', '==', farmSlug), where('farm.id', '==', farmSlug)),
      where(`distributions.${distId}.id`, '==', distId),
    ),
  )
}

/** Loads all the featured farm products that are not hidden*/
export async function loadFeaturedByFarm(farmSlug: string, isWholesale: boolean | undefined): Promise<Product[]> {
  const prods = await productsCollection.fetchAll(
    and(
      or(where('farm.urlSafeSlug', '==', farmSlug), where('farm.id', '==', farmSlug)),
      where('isFeatured', '==', true),
      where('isHidden', '==', false),
    ),
  )

  // Filter out any products that do not match the app mode. This is done as a post-processing step because adding this to the above query is too complex for Firestore
  const prodsForAppMode = prods.filter(matchesAppModeProduct(isWholesale))
  // This fulfills the requirement of the "Only show in CSA" field, for the FarmDetail screen, which is to exclude any product that has this value set to true
  return prodsForAppMode.filter((p) => (hasUnits(p) ? !p.hideFromShop : true)).sort(sortByRankAndName)
}

/** loadProductsByFarmAndType returns all non-hidden products for a farm filtered by the supplied product type. */
export async function loadProductsByFarmAndType(
  farmSlug: string,
  productType: ProductType,
  /** Optional limit, if undefined will not limit the query */
  limitN?: number,
): Promise<Product[]> {
  const queryParams: [QueryCompositeFilterConstraint, ...QueryNonFilterConstraint[]] = [
    and(
      or(where('farm.urlSafeSlug', '==', farmSlug), where('farm.id', '==', farmSlug)),
      where('type', '==', productType),
      where('isHidden', '==', false),
    ),
  ]

  // Only add a limit if we explicitly pass one to this function
  if (limitN) queryParams.push(limit(limitN))

  return productsCollection.fetchAll(...queryParams)
}

/** loadProductsByCSA returns all of the available products for a CSA. */
export async function loadProductsByCSA(csaId: string): Promise<Product[]> {
  return productsCollection.fetchAll(where('csa', 'array-contains', csaId))
}

/** loadProduct returns the product stored at the supplied ID. */
export async function loadProduct<T extends Product = Product>(productId: string): Promise<T> {
  return productsCollection.fetch(productId) as Promise<T>
}

/** addProduct saves a new product to the database with validation. */
export async function addProduct(product: Product, farm?: Farm): Promise<Product> {
  if (!product.farm?.id && !farm?.id)
    throw new ErrorWithCode({
      code: 'farm-id-required',
      devMsg: 'The farmId is required to add a product',
    })
  const newId = productsCollection.reference().id
  // Validate the urlSafeSlug
  let validSafeSlug = formatToSafeSlug(product.name)
  validSafeSlug = await buildUrlSafeSlug({
    slug: validSafeSlug,
    type: 'create',
    collection: 'products',
    id: newId,
    farmId: product.farm.id ?? farm?.id,
  })

  if (!validSafeSlug) {
    throw new ErrorWithCode({
      code: 'invalid-slug',
      devMsg:
        /**TODO: Use this when urlSafeSlug feature is implemented. Please choose a distinct name for your product. The product name is essential for creating a unique and secure URL. */
        'Please choose a distinct name for your product to separate it from others.',
    })
  }
  // Assign valid urlSafeSlug
  const newProduct = { ...product, id: newId, urlSafeSlug: validSafeSlug }

  // Validate the product
  buildProduct(newProduct)

  await productsCollection.createWithId(newProduct)

  // Update onboard walk-through
  if (farm && farm.onboardSteps && !farm.onboardSteps?.products) {
    updateFarm({ id: farm.id, onboardSteps: { ...(farm.onboardSteps || {}), products: true } })
  }
  return newProduct
}

/** updateProductInfo updates the product with a validated update */
export async function updateProduct(updatedProduct: Product): Promise<Product> {
  // When the product name is included or changed and, we need to validate the urlSafeSlug
  if (updatedProduct.name) {
    if (!updatedProduct.farm?.id)
      throw new ErrorWithCode({
        code: 'farm-id-required',
        devMsg: 'The farmId is required to update the product name.',
      })

    let validSafeSlug = formatToSafeSlug(updatedProduct.name)

    validSafeSlug = await buildUrlSafeSlug({
      slug: validSafeSlug,
      type: 'update',
      collection: 'products',
      farmId: updatedProduct.farm.id,
      id: updatedProduct.id,
    })

    if (!validSafeSlug) {
      throw new ErrorWithCode({
        code: 'invalid-slug',
        devMsg:
          /**TODO: Use this when urlSafeSlug feature is implemented. Please choose a distinct name for your product. The product name is essential for creating a unique and secure URL. */
          'Please choose a distinct name for your product to separate it from others.',
      })
    }
    // Assign valid urlSafeSlug
    updatedProduct.urlSafeSlug = validSafeSlug
  }

  // Validate the product
  buildProduct(updatedProduct)

  // @ts-expect-error: The update method is problematic
  await productsCollection.update({
    ...omit(
      updatedProduct,
      'farm' /** Omitting the farm just in case, because it shouldn't be updated in this manner anyway */,
    ),
    isDraft: false,
  })

  return updatedProduct
}

/** overWriteProduct sets the product with a validated update and prevent bad data from editing every time */
export async function overWriteProduct(updatedProduct: Product): Promise<Product> {
  // When the product name is included or changed and, we need to validate the urlSafeSlug
  if (updatedProduct.name) {
    if (!updatedProduct.farm?.id)
      throw new ErrorWithCode({
        code: 'farm-id-required',
        devMsg: 'The farmId is required to update the product name.',
      })

    let validSafeSlug = formatToSafeSlug(updatedProduct.name)

    validSafeSlug = await buildUrlSafeSlug({
      slug: validSafeSlug,
      type: 'update',
      collection: 'products',
      farmId: updatedProduct.farm.id,
      id: updatedProduct.id,
    })

    if (!validSafeSlug) {
      throw new ErrorWithCode({
        code: 'invalid-slug',
        devMsg:
          /**TODO: Use this when urlSafeSlug feature is implemented. Please choose a distinct name for your product. The product name is essential for creating a unique and secure URL. */
          'Please choose a distinct name for your product to separate it from others.',
      })
    }
    // Assign valid urlSafeSlug
    updatedProduct.urlSafeSlug = validSafeSlug
  }

  // Validate the product
  buildProduct(updatedProduct)

  await productsCollection.set(updatedProduct)
  return updatedProduct
}

/** Deletes a product by id. Deleting a product should always use this logic and not the collection.delete() api directly.
 * - If successful, returns  { success: true }
 * - Won't remove products with sales
 *  */
export async function deleteProduct(productId: string, farmId: string): Promise<{ success: boolean; error?: string }> {
  try {
    //Don't allow deleting a product that has sales
    const hasSales = await productHasSales(productId, farmId)
    if (hasSales) return { success: false }

    await productsCollection.delete(productId)
    return { success: true }
  } catch (error) {
    Logger.error(error)

    return { success: false, error: errorToString(error) }
  }
}

/** Checks whether any orders have been made for the product */
export async function productHasSales(productId: string, farmId: string): Promise<boolean> {
  return await callEndpoint('v2.Product.productHasSale', { productId, farmId })
}

/** Will load the nearest shares to a users location */
export async function getNearbyShares(
  coords: Coordinate,
  { limit = 10 }: { limit?: number } = { limit: 10 },
): Promise<Hit<AlgoliaGeoDoc<AlgoliaGeoProduct>>[]> {
  const result = await consumerIndex.search<AlgoliaGeoDoc<AlgoliaGeoProduct>>('', {
    length: limit,
    aroundLatLng: getCoordString(coords),
    facetFilters: [
      FILTERS.Product,
      FILTERS.NotHidden,
      FILTERS.Registered,
      FILTERS.Retail,
      asFilter<Pick<AlgoliaGeoProduct, 'priceInMap'>>(`priceInMap:-${InvalidAmount}`), // Filter out prods with invalid price
      asFilter<Pick<AlgoliaGeoProduct, 'isInStock'>>(`isInStock:true`),
      asFilter<Pick<AlgoliaGeoProduct, 'isPrivate'>>(`isPrivate:false`),
      [
        //Only select shares
        asFilter<Pick<AlgoliaGeoProduct, 'type'>>(`type:${ProductType.PrimaryShare}`),
        asFilter<Pick<AlgoliaGeoProduct, 'type'>>(`type:${ProductType.AddonShare}`),
      ],
    ],
    numericFilters: [asNumFilter(`lastAvailStamp > ${DateTime.now().plus({ minutes: 5 }).toMillis()}`)],
  })

  return parseAlgoliaResults(result).hits
}

/** Loads nearby products based on some coordinate */
export const getNearbyProducts = async (
  coords: Coordinate,
  opts: { isWholesale: boolean },
  searchOpts?: Parameters<SearchIndex['search']>[1],
): Promise<Hit<AlgoliaGeoDoc<AlgoliaGeoProduct>>[]> => {
  const result = await consumerIndex.search<AlgoliaGeoDoc<AlgoliaGeoProduct>>('', {
    facetFilters: [
      FILTERS.Product,
      FILTERS.NotHidden,
      FILTERS.Registered,
      opts.isWholesale ? FILTERS.Wholesale : FILTERS.Retail,
      asFilter<Pick<AlgoliaGeoProduct, 'priceInMap'>>(`priceInMap:-${InvalidAmount}`),
      asFilter<Pick<AlgoliaGeoProduct, 'isInStock'>>(`isInStock:true`),
      asFilter<Pick<AlgoliaGeoProduct, 'isPrivate'>>(`isPrivate:false`),
    ],
    numericFilters: [asNumFilter(`lastAvailStamp > ${DateTime.now().plus({ minutes: 5 }).toMillis()}`)],
    aroundLatLng: getCoordString(coords),
    // Radius in meters, ~200 miles
    aroundRadius: 320000,
    ...searchOpts,
  })

  return parseAlgoliaResults(result).hits
}

/** Snapshots of products by farm id and product type. Returns only active products
 *
 * - The query might be more specific to include distroId and csaId but there's a firebase limitation of only one array filter in the query. The type filter already is considered an array filter, it seems. Plus, the new or() query syntax isn't available in our sdk version. Not a big deal though.
 * - If the query were more specific, the only benefit might be to fetch less data, but either way the initial query will have the full data because the initial state of the filters is undefined. So by the time the query became narrower, the larger-scope query would've already been sent, and cached by the firebase client sdk
 */
export function snapshotProductsOrderCreator(
  callback: (products: Product[]) => void,
  onError = errorCatcher,
  farmId: string,
  orderType: OrderProductType,
  isWholesale?: boolean,
): () => void {
  const qParams = [where('isHidden', '==', false), where('farm.id', '==', farmId)]
  const prodTypes = getProdTypes(orderType)
  if (prodTypes.length) {
    qParams.push(where('type', 'in', prodTypes))
  }

  const q = productsCollection.query(...qParams)
  return productsCollection.snapshotMany(
    q,
    (prods) =>
      callback(
        prods.filter((p) =>
          isActive(p, {
            excludeClosedDistros: false,
            ignoreOrderCutoffWindow: true,
            isWholesale,
          }),
        ),
      ),
    onError,
  )
}

/** Gets the nearby featured products from algolia. Used on the homescreen wholesale */
export async function getNearbyFeaturedWholesale(): Promise<Hit<AlgoliaGeoDoc<AlgoliaGeoProduct>>[]> {
  const result = await consumerIndex.search<AlgoliaGeoDoc<AlgoliaGeoProduct>>('', {
    hitsPerPage: 3,
    filters: buildQueryFilter([
      FILTERS_QUERY.Product,
      FILTERS_QUERY.NotAddon,
      FILTERS_QUERY.NotHidden,
      FILTERS_QUERY.Wholesale,
      FILTERS_QUERY.WholesaleFarm,
      FILTERS_QUERY.NotPrivateProd,
      asFilter<AlgoliaGeoProduct, 'isFeatured'>('isFeatured:true'),
    ]),
  })

  return parseAlgoliaResults(result).hits
}
