import { UseApiFxReturn } from '@/hooks/useApiFx'
import { KeyedState } from '@/hooks/useKeyedState'
import { draftOrdersCollection } from '@api/framework/ClientCollections'
import { OrderCreatorStateProductsFilters } from '../OrderCreatorScreen/OrderCreatorScreen.helper'
import { useProductsReusable } from '../OrderCreatorScreen/useProducts-reusable'

/** State management for data related to products.
 * - This hook is a wrapper for the reusable useProducts. It's job is to take the screen-specific data and pass it to the reusable hook.
 */
export function useProducts({
  keyedState,
  draftOrderFx: { data: draft },
}: {
  keyedState: KeyedState<OrderCreatorStateProductsFilters>
  draftOrderFx: UseApiFxReturn<typeof draftOrdersCollection.fetch>
}) {
  return useProductsReusable({
    keyedState,
    // In order edit, the order's farm is the farm id for the products. Do NOT use the admin farm id for this.
    farmId: draft?.farm.id || '',
    // In order edit, the catalog mode is determined by the order's isWholesale flag
    isWholesale: draft?.isWholesale,
    // In order edit, we only show standard products because we currently only have wholesale draft orders, and wholesale only allows standard products, so there's no need for a product type selector, or a navigation param
    orderType: 'standard',
  })
}
