import { FontAwesome5 } from '@expo/vector-icons'
import { StyleSheet, View } from 'react-native'

import { TextH3, Text } from './elements/Text'
import { Touchable } from './elements/Touchable'
import Colors from '../constants/Colors'

export type SectionButton = {
  title: string
  onPress?: () => void
  url?: string
  subtitle?: string
  icon?: string
  hiddenTitle?: string
  disabled?: boolean
}

export type Section = {
  title: string
  buttons: SectionButton[]
}

export function SectionButtonView({
  title,
  onPress,
  subtitle,
  icon = 'chevron-right',
  url,
  disabled = false,
}: SectionButton) {
  return (
    <Touchable onPress={onPress} style={styles.container} url={url} disabled={disabled}>
      <View>
        <Text size={14} color={disabled ? Colors.shades['300'] : undefined}>
          {title}
        </Text>
        {!!subtitle && (
          <Text size={10} color={disabled ? Colors.shades['100'] : Colors.shades['400']}>
            {subtitle}
          </Text>
        )}
      </View>
      <FontAwesome5 name={icon} size={20} />
    </Touchable>
  )
}

export function SectionView({ title, buttons }: Section) {
  return (
    <View>
      <TextH3 style={{ marginTop: 20 }}>{title}</TextH3>
      {buttons.map((btn, i) => (
        <SectionButtonView key={btn.title + i} {...btn} />
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})
