import { useFarmDataFromCache, wholesaleSelector } from '@/redux/selectors'
import { isTruthy } from '@helpers/helpers'
import { getIdFromSlug } from '@helpers/urlSafeSlug'
import { AlgoliaGeoDoc, AlgoliaGeoProduct } from '@models/Algolia'
import { ProductFilters } from '@models/Filters'
import { hasUnits } from '@models/Product'
import { createContext, useMemo, useState } from 'react'
import { ConfigureProps, useConfigure, useHits } from 'react-instantsearch'
import { useSelector } from 'react-redux'
import { usePropRefinement } from '../useAlgoliaPropRefinement/useAlgoliaPropRefinement'
import { useAlgoliaState } from '../useAlgoliaState'
import { useTextRefinement } from '../useAlgoliaTextRefinement'
import { useAvailAddons } from '../useAvailAddons'
import { useFocusFx } from '../useFocusFx'
import { buildFilters, getLocations, getProducts } from './useAlgoliaFarmData-helpers'

type Props = ProductFilters & {
  /** The farm slug for which the algolia data will be fetched */
  farmSlug: string
}

/** Hook that gets the algolia data needed for shopping screens
 * - this hook should NOT fetch things from the database. That's the job of the useFarmData hook
 */
export function useAlgoliaFarmData({ farmSlug, categoryId, locationId, csaId, type, searchTerm, ebtOnly }: Props) {
  const { isWholesale } = useSelector(wholesaleSelector)

  /** This will access the farm and db locations from redux, which were set by the useFarmData hook. Requires { fetchLocs: true } in useFarmData opts */
  const { farm, locs: dbLocs } = useFarmDataFromCache(farmSlug)

  const farmId = useMemo(() => getIdFromSlug(farmSlug, farm), [farmSlug, farm])

  /** This is the loading state for the product algolia search for the UI. */
  const [isLoadingProducts, setLoadingProducts] = useState(true)
  const { loadingSearch, errorMsg } = useAlgoliaState()

  /** Updates the product loading state, considering other data like farm */
  useFocusFx(() => {
    if (!farmId) {
      // this is waiting for the farm, before considering whether products are done loading
      setLoadingProducts(true)
    } else {
      setLoadingProducts(loadingSearch)
    }
  }, [loadingSearch, farmId])

  const configurationMemo: ConfigureProps = useMemo(() => {
    return {
      filters: buildFilters({
        farmId,
        filterByEbt: ebtOnly ?? false,
        csaId,
        isWholesale,
      }),
      hitsPerPage: 400,
      // Will make sure that the filter counts are taking in account only one version of the product
      facetingAfterDistinct: true,
    }
  }, [csaId, ebtOnly, farmId, isWholesale])

  /** Configures the search filter string */
  useConfigure(configurationMemo)

  const categoryFilter = usePropRefinement({ attribute: 'category', value: categoryId })
  const typeFilter = usePropRefinement({ attribute: 'type', value: type })
  const locationFilter = usePropRefinement({ attribute: 'locations.id', value: locationId })
  useTextRefinement(searchTerm)

  /** Returns db locations that are filtered by algolia */
  const { locations, loadingLocations } = useMemo(
    () => getLocations(dbLocs, locationFilter.items),
    [dbLocs, locationFilter.items],
  )

  const { hits = [] } = useHits<AlgoliaGeoDoc<AlgoliaGeoProduct>>()
  const { availAddonsIds } = useAvailAddons()

  /** Product data. This creates a list of products ready for the UI, based on the current filters
   * - It will merge the csa addons into the algolia results, if we are in the CSADetail screen (If we are filtering by csaId)
   * - Screens other than the CSADetail screen should not show addons
   * - The algolia filters should also be applied to addons (Even though the addons are DB product type)
   */
  const products = useMemo(
    () => getProducts({ hits, csaId, availAddonsIds, isLoading: loadingSearch, isWholesale }),
    [loadingSearch, hits, csaId, availAddonsIds, isWholesale],
  )

  const canFilterByEbt = useMemo(
    () => isWholesale === false && products.some((prod) => hasUnits(prod) && prod.isEbt),
    [products, isWholesale],
  )

  const activeFiltersNo = [locationId, categoryId, type, !!searchTerm?.length].filter(isTruthy).length

  return {
    products,
    isLoadingProducts,
    csaId,
    errorMsg,
    locations,
    loadingLocations,
    categoryFilter,
    typeFilter,
    locationFilter,
    ebtOnly,
    canFilterByEbt,
    searchTerm,
    activeFiltersNo,
  }
}

export type AlgoliaFarmDataContextType = ReturnType<typeof useAlgoliaFarmData>
export const AlgoliaFarmDataContext = createContext<AlgoliaFarmDataContextType>({} as AlgoliaFarmDataContextType)
