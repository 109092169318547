import { useDispatch, useSelector } from 'react-redux'

import { useAvailAddons } from '@/hooks/useAvailAddons'
import { useCartInfo } from '@/hooks/useCart/useCartInfo'
import { setAvailAddonsAction, setAvailAddonsCSAs } from '@/redux/actions/appPersist'
import { fetchAvailAddons as getAvailAddons } from '@api/Addons'
import { csasCollection } from '@api/framework/ClientCollections'
import { haveSameItems, removeDuplicates, wait } from '@helpers/helpers'
import { useCancelableDeepCompareFx } from '../../hooks/useCancelablePromise'
import { useCartService } from '../../hooks/useCart'
import { userSelector, wholesaleSelector } from '../../redux/selectors'

/**
 * Calculates and sets the available addons from both past purchases and cart
 */
export function useSetAvailAddons() {
  const { isWholesale } = useSelector(wholesaleSelector)
  const { id: userId } = useSelector(userSelector)
  const { cart } = useCartService()
  const { cartId } = useCartInfo({ cartServiceType: 'consumer' })
  const dispatch = useDispatch()

  useCancelableDeepCompareFx(
    async (isCurrent) => {
      if (isWholesale !== false || !cartId) {
        // This should clear the data if the app were no longer in wholesale mode or cartId is removed for any reason
        dispatch(setAvailAddonsAction([]))
        return
      }

      // Debounce the run in case it gets triggered again shortly afterward
      await wait(500)
      if (!isCurrent) return

      const availAddons = await getAvailAddons({ cartId, userId })
      if (!isCurrent) return

      dispatch(setAvailAddonsAction(availAddons))
    },
    [
      // (Run conditions)
      // - If the item ids change
      // eslint-disable-next-line react-hooks/exhaustive-deps
      cart.map((itm) => itm.id).sort(),
      // - If the schedule ids change
      // eslint-disable-next-line react-hooks/exhaustive-deps
      cart.map((itm) => itm.distribution?.id).sort(),
      userId,
      cartId,
      dispatch,
      isWholesale,
    ],
  )

  const { availAddonsResults, availAddonCsas } = useAvailAddons()

  /** The current list of matching csa ids for all the availAddon results, deduped and sorted. Intended to be a lightweight deep-compare dependency */
  const matchingCsaIds = removeDuplicates(availAddonsResults.flatMap(({ matchingCSAIds }) => matchingCSAIds)).sort()

  /** When avail addons change, fetch any missing matching csas. These csas are part of the availAddons context */
  useCancelableDeepCompareFx(
    async (isFxActive) => {
      if (isWholesale !== false) {
        // This should clear the data if the app were no longer in wholesale mode
        dispatch(setAvailAddonsCSAs([]))
        return
      }

      const currentCsaIds = availAddonCsas.map(({ id }) => id)
      if (haveSameItems(matchingCsaIds, currentCsaIds)) return

      const newCsas = await csasCollection.fetchByIds(matchingCsaIds)
      if (!isFxActive) return

      dispatch(setAvailAddonsCSAs(newCsas))
    },
    // Should deepCompare the matching csa ids
    [matchingCsaIds, availAddonCsas, dispatch, isWholesale],
  )
}
