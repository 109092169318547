import { ErrorText, LoadingView, Text } from '@elements'
import { User, userName } from '@models/User'
import { styles } from './OrderCreatorScreenUI-styles'

type Props = {
  /** Id for the customer selected for order creation */
  custId: string | undefined
  /** Data for the User selected for order creation. This may have a different user from the one in the custId, only temporarily when a customer is changed, while the new one is being fetched */
  customer: User | undefined
  /** Loading state for customer data fx */
  loading: boolean
  /** Whether there's at least one cart item for this order */
  hasItems: boolean
}
/** Small UI for the customer data state  */
export function CustomerStatus({ custId, customer, hasItems, loading }: Props) {
  const notSelectedText = 'No customer selected'

  const text =
    customer && custId === customer.id
      ? `${userName(customer)} | ${customer.email}`
      : customer && custId !== customer.id
      ? 'Updating customer...'
      : notSelectedText

  return (
    <LoadingView loading={loading}>
      {hasItems && text === notSelectedText ? (
        <ErrorText>{text}</ErrorText>
      ) : (
        <Text style={styles.marginV15}>{text}</Text>
      )}
    </LoadingView>
  )
}
