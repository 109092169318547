import { Text } from '@elements'
import { Ionicons } from '@expo/vector-icons'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import { globalStyles } from '../../../../constants/Styles'

import Colors from '@/constants/Colors'

type Props = {
  onSelect?: () => void
  disabled?: boolean
  isSelected: boolean
  value: string
}

export function RadioButton({ value, disabled, onSelect, isSelected }: Props) {
  return (
    <TouchableOpacity disabled={disabled} onPress={onSelect} style={styles.container}>
      <View style={globalStyles.flexRow}>
        <Ionicons
          name={isSelected ? 'radio-button-on' : 'radio-button-off'}
          size={20}
          color={disabled ? Colors.lightGray : Colors.green}
        />
        <Text size={14} style={{ paddingLeft: 5 }}>
          {value}
        </Text>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 10,
  },
})
