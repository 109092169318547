import Colors from '@/constants/Colors'
import { useBooleanState } from '@/hooks/useControlledState'
import { DynamicTooltip, Text, TextH2, TextH3 } from '@elements'
import { formatMoney } from '@helpers/display'
import { objToStr } from '@helpers/log'
import { CartItemGroupType, getDeliveryDescr, getDeliveryDetails } from '@screens/Shopping/Checkout/CartItemsCheckout'
import { memo } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { styles } from './styles'

/** Component that displays the data for a group of nonPickup cart items that have the same location */
export const DeliveryGroupItem = memo(function DeliveryGroupItem({
  items,
  address,
  groupDeliveryTotal,
  locationFee,
  combinedDeliveryDates,
  combinedDeliveryPickups,
  uniqueDates,
  locType,
}: CartItemGroupType) {
  const [showingDetails, toggleShowDetails] = useBooleanState(false)
  const descr = getDeliveryDescr(locationFee!, locType!, combinedDeliveryDates, uniqueDates.length, false)
  const details = getDeliveryDetails({
    nDates: uniqueDates.length,
    combinedDeliveryDates,
    combinedDeliveryPickups,
    deliveryTotal: groupDeliveryTotal,
    locationFee,
    locType,
  })

  return (
    <View>
      <View key={objToStr(items.map((itm) => itm.id).sort())} style={styles.deliveryGroupTop}>
        <Text style={styles.itemProdName}>{address}</Text>
        <TextH2>{formatMoney(groupDeliveryTotal)}</TextH2>
      </View>
      <View style={styles.deliveryDetailsCont}>
        <TouchableOpacity onPress={toggleShowDetails}>
          <TextH3 color={Colors.green}>{showingDetails ? 'Hide details' : 'Details'}</TextH3>
          {showingDetails ? (
            <Text style={styles.deliveryDetailsText}>
              {descr} <DynamicTooltip content={details} />
            </Text>
          ) : null}
        </TouchableOpacity>
      </View>
    </View>
  )
})
