import env from '@/config/Environment'
import { Logger } from '@/config/logger'
import { CartServiceType } from '@/constants/types/cartService'
import { useCartService } from '@/hooks/useCart'
import { useAddToCartFlow } from '@/hooks/useCart/addToCartFlow/useAddToCartFlow'
import { useBooleanState } from '@/hooks/useControlledState'
import { useFocusFx } from '@/hooks/useFocusFx'
import {
  CartButtons,
  CartButtonsHelpers,
  EbtIcon,
  Image,
  PayScheduleExt,
  Stepper,
  disableAddCartBtn,
  makeTestIdPrefix,
} from '@components'
import { ButtonClear, Modal, Text, TextH2, TextH3, Toast } from '@elements'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { formatMoney, formatPickupDate } from '@helpers/display'
import { formatDistributionType } from '@helpers/location'
import { getProratedAmount } from '@helpers/order'
import { Farm } from '@models/Farm'
import { CartItem, isCartStandard, isCartStandardMulti } from '@models/Order'
import { memo, useCallback, useMemo } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { cantRemoveLastItemAlert } from '../../../OrderEditScreen/OrderSummaryItem'
import { styles } from './styles'
import { VariableWeightsModal } from '../../../OrderEditScreen/components/VariableWeightsModal'
import { getUnadjustedQuantity } from '@helpers/cart'

/** Reusable UI for each cart item in the order summary. It is compatible with both the order creator and order edit */
export const OrderSummaryItem_Reusable = memo(function OrderSummaryItem_Reusable({
  itemId,
  farm,
  isWholesale,
  cartServiceType,
}: {
  itemId: CartItem['id']
  farm: Farm
  isWholesale: boolean | undefined
  cartServiceType: Extract<CartServiceType, 'orderCreator' | 'orderEdit'>
}) {
  const cartSrv = useCartService({ farmId: farm.id, cartServiceType, isWholesale })

  const cartItem = useMemo(() => cartSrv.cart.find((i) => i.id === itemId), [cartSrv.cart, itemId])

  // Validate the data is correct
  useFocusFx(() => {
    if (cartItem && farm.id !== cartItem.product.farm.id) {
      const err = new Error('The farm of the cart item does not match the farm id')
      if (env.APP_ENV !== 'prod') throw err
      Logger.error(err)
    }
  }, [cartItem, farm.id])

  const [showingPickups, toggleShowingPickups] = useBooleanState(false)

  const paySchedules: PayScheduleExt[] = useMemo(() => {
    if (!cartItem) return []
    return CartButtonsHelpers.processPaySchedules(cartItem, {
      isAdmin: cartSrv.isAdmin,
      isWholesale,
      farm,
    })
  }, [cartItem, farm, isWholesale, cartSrv.isAdmin])

  const itemAmount = useMemo(
    () =>
      !cartItem
        ? null
        : getProratedAmount(cartItem, {
            excludeClosedDistros: !cartSrv.isAdmin,
            ignoreOrderCutoffWindow: cartSrv.isAdmin,
          }).itemAmount,
    [cartItem, cartSrv.isAdmin],
  )

  const { modifyDates, isLoadingAddCartFlow } = useAddToCartFlow({
    cartServiceType,
    isWholesale,
  })

  const onPressModifyDates = useCallback(() => {
    try {
      modifyDates(itemId)
    } catch (err) {
      Logger.error(err)
    }
  }, [modifyDates, itemId])

  const editVariableWeight = useCallback(() => {
    if (!cartItem) return Toast('This item was not found in the cart')
    if (!isCartStandard(cartItem)) return Toast('This item cannot have weight adjustments.')

    Modal(
      <VariableWeightsModal
        farmId={farm.id}
        cartServiceType={cartServiceType}
        isWholesale={isWholesale}
        cartItem={cartItem}
      />,
      { title: 'Adjust variable weight' },
    )
  }, [cartItem, farm.id, cartServiceType, isWholesale])

  const updateQty = async (delta: number) => {
    if (!cartItem) return Toast('This item was not found in the cart')
    const prevQuantity = getUnadjustedQuantity(cartItem)

    if (cartServiceType === 'orderEdit') {
      // In order edit we won't allow removing the item if it's the only item in the cart
      if (cartSrv.cart.length === 1 && prevQuantity === 1 && delta < 0) {
        return cantRemoveLastItemAlert()
      }
    }
    try {
      await cartSrv.updateQuantity(itemId, prevQuantity + delta)
    } catch (err) {
      Logger.error(err)
      Toast('Something went wrong while updating the quantity')
    }
  }

  const removeItem = async () => {
    if (cartServiceType === 'orderEdit') {
      // In order edit we won't allow removing the item if it's the only item in the cart
      if (cartSrv.cart.length === 1) {
        return cantRemoveLastItemAlert()
      }
    }
    try {
      await cartSrv.updateQuantity(itemId, 0)
    } catch (err) {
      Logger.error(err)
      Toast('Something went wrong while removing this item')
    }
  }

  // This call to disableAddCartBtn should absolutely be memoized because it involves a pickup calculation on db products
  const disableAdd = useMemo(
    () =>
      !cartItem
        ? true
        : disableAddCartBtn({
            prod: cartItem.product,
            cart: cartSrv.cart,
            isWholesale,
            unitProp: cartItem.unit,
            mode: cartSrv.isAdmin ? 'admin' : 'consumer',
          }),
    [cartItem, cartSrv.cart, cartSrv.isAdmin, isWholesale],
  )

  if (!cartItem) {
    return null
  }

  return (
    <View key={itemId} style={styles.cartItemContainer}>
      {cartItem.product.images.length > 0 && (
        <Image resizeMode="cover" style={styles.image} type="product" source={{ uri: cartItem.product.images[0] }} />
      )}
      <View style={styles.itemTop}>
        <TextH2 style={styles.itemProdName}>
          <EbtIcon product={cartItem.product} /> {cartItem.product.name}
        </TextH2>
        <TextH2>{formatMoney(itemAmount)}</TextH2>
      </View>
      <Text style={styles.itemBuyingOpt}>{cartItem.unit?.name}</Text>
      {cartItem.pickups && (
        <>
          <View style={styles.itemPickupsCont}>
            <TextH3>
              No. {formatDistributionType(cartItem.distribution.location, { plural: true })}: {cartItem.pickups.length}
            </TextH3>
            {cartItem.pickups.length > 1 && (
              <TextH3>
                Qty./ {formatDistributionType(cartItem.distribution.location)}: {cartItem.quantity}
              </TextH3>
            )}
          </View>
          <View style={styles.itemPickupsCont}>
            {cartItem.pickups.length === 1 ? (
              <TextH3 size={11}>{formatPickupDate(cartItem.pickups[0])}</TextH3>
            ) : (
              <ButtonClear
                style={styles.buttonClear}
                size={13}
                title={
                  (!showingPickups ? 'Show ' : 'Hide ') +
                  formatDistributionType(cartItem.distribution.location, { plural: true })
                }
                onPress={toggleShowingPickups}
              />
            )}
            {isCartStandard(cartItem) && (
              <ButtonClear
                style={styles.buttonClear}
                size={13}
                title={isWholesale ? 'Modify date' : cartItem.pickups?.length === 1 ? 'Add more dates' : 'Modify Dates'}
                onPress={onPressModifyDates}
                loading={isLoadingAddCartFlow}
              />
            )}
          </View>
          {showingPickups
            ? cartItem.pickups!.map((pickup, idx) => (
                <View style={styles.pickupsExpanded} key={idx}>
                  <TextH3 size={11}>{formatPickupDate(pickup)}, </TextH3>
                </View>
              ))
            : null}
        </>
      )}
      <View style={styles.stepperContainer}>
        <Stepper
          key={makeTestIdPrefix(cartItem.product, cartItem.unit)}
          style={styles.stepperOverride}
          cartItem={cartItem}
          updateQuantity={updateQty}
          loading={isLoadingAddCartFlow}
          disableAdd={disableAdd}
        />
        <TouchableOpacity onPress={removeItem} disabled={isLoadingAddCartFlow}>
          <Text>Remove</Text>
        </TouchableOpacity>
      </View>
      {isCartStandard(cartItem) && <ButtonClear size={10} title="Edit Variable Weight" onPress={editVariableWeight} />}
      {isCartStandardMulti(cartItem) && (
        <CartButtons
          item={cartItem}
          paySchedules={paySchedules}
          updatePaySchedule={(ps) => cartSrv.updatePaySchedule(itemId, ps)}
          touched
        />
      )}
    </View>
  )
},
propsAreDeepEqual)
