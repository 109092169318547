import { CustomInput, UniversalTag } from '@elements'
import { nonEmptyString, removeDuplicates } from '@helpers/helpers'
import { RegionType } from '@models/Location'
import { memo, useState } from 'react'
import { StyleSheet, View } from 'react-native'

import { getShortState } from '@/assets/data/states'
import { CustomInputProps } from '../../../components/elements/CustomInput'

type Props = CustomInputProps & {
  values: string[]
  type: RegionType
  onUpdate: (props: string[]) => void
}

/** Will clean zipcodes and add every 5 numbers as a code */
const cleanZipcode = (text: string): [string, string[]] => {
  const tags = []
  let cleaned = text.replace(/[^0-9]/g, '').trim()

  if (cleaned.length === 5) {
    tags.push(cleaned)
    cleaned = ''
  } else {
    while (cleaned.length >= 5) {
      tags.push(cleaned.slice(0, 5))
      cleaned = cleaned.slice(5)
    }
  }
  return [cleaned, tags]
}

/** Will clean states and add every 2 letters as a state */
const cleanState = (text: string): [string, string[]] => {
  const tags = []
  let cleaned = text
    .replace(/[^a-zA-Z]/g, '')
    .toUpperCase()
    .trim()

  if (cleaned.length === 2) {
    tags.push(cleaned)
    cleaned = ''
  } else {
    while (cleaned.length >= 2) {
      tags.push(cleaned.slice(0, 2))
      cleaned = cleaned.slice(2)
    }
  }
  return [cleaned, tags]
}

export const StateZipInput = memo(function StateZipInput({ values, onUpdate, type, ...inputProps }: Props) {
  const [text, setText] = useState('')
  const [errorStateMessage, setErrorStateMessage] = useState('')

  const removeTag = (tag: string) => {
    onUpdate(values.filter((v) => v !== tag))
  }

  const onChange = (text: string) => {
    // Will clean the type and return a list of tags and the resulting text
    const [cleaned, tags] = type === RegionType.Zipcode ? cleanZipcode(text) : cleanState(text)

    let canUpdate = true

    /** validate state code before it can be added to result */
    if (type === RegionType.State && text.length === 2) {
      setErrorStateMessage('')
      canUpdate = nonEmptyString(text) && !!getShortState(text)
      if (!canUpdate) setErrorStateMessage(`${text.toUpperCase()} is not a valid 2 digit state code`)
    }

    /** validate zip code before it can be added to result */
    if (type === RegionType.Zipcode && text.length === 5 && !/^\d+$/.test(text)) {
      canUpdate = false
      setErrorStateMessage(`${text} is not a valid 5-digit zipcode`)
    }

    // Remove duplicates and update the added codes/states and set the rest as input
    if (canUpdate) onUpdate(removeDuplicates([...values, ...tags]))

    setText(cleaned)
  }

  return (
    <View>
      <CustomInput
        placeholder={type === RegionType.Zipcode ? '00000' : 'NY'}
        value={text}
        onChangeText={onChange}
        helperText={errorStateMessage}
        {...inputProps}
      />
      <TagContainer tags={values} onPress={removeTag} />
    </View>
  )
})
type TagContProps = {
  tags: string[]
  onPress?(tag: string): void
}

/** This component allows handling dynamic display of simple list of tags */
const TagContainer = memo(function TagContainer({ tags, onPress }: TagContProps) {
  return (
    <View style={styles.tagContainer}>
      {tags.map((tag) => (
        <UniversalTag label={tag} key={tag} disabled={!onPress} onPress={() => onPress && onPress(tag)} />
      ))}
    </View>
  )
})

const styles = StyleSheet.create({
  tagContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
})
