import { omit } from '@helpers/typescript'
import { TemplateProduct } from '@models/Product'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { prepareMarshal, prepareUnmarshal } from './encoding'

/** unmarshalTemplateProduct converts the data provided by the Firestore document into the structure expected by the TemplateProduct model. */
export function unmarshalTemplateProduct(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): TemplateProduct {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)
  return { ...data, id } as TemplateProduct
}

/** marshalTemplateProduct converts the templateProduct structure into a Firebase document structure. */
export function marshalTemplateProduct(templateProduct: Partial<TemplateProduct>): DocumentData {
  return omit(prepareMarshal(templateProduct), 'id')
}
