import { LoadingView } from '@elements'
import { memo } from 'react'
import { StyleProp, TextProps, TextStyle } from 'react-native'

import Colors from '../../constants/Colors'
import { withColorHover } from '../../hooks/withColorHover'
import { Icon } from './Icon/Icon'
import { FontAwesome5IconName } from './Icon/iconNames'
import { Text } from './Text'
import { Touchable, TouchableProps } from './Touchable'

import { useDeepCompareLayoutFnStyles } from '@/hooks/useFnStyles'
import { spreadStyle } from '@/hooks/useMergeStyle'

export type ButtonProps = Omit<TouchableProps, 'children'> & {
  textStyle?: StyleProp<TextStyle>
  icon?: FontAwesome5IconName
  size?: number
  small?: boolean
  outline?: boolean
  color?: string
  title: string
  loading?: boolean
  /** Props for the text component inside the button */
  textProps?: Omit<TextProps, 'title'>
}

export const Button = memo(
  withColorHover(function Button({
    style,
    textStyle,
    small = false,
    outline = false,
    icon,
    size = 14,
    color = Colors.green,
    onPress,
    title,
    loading = false,
    disabled = false,
    textProps = {},
    ...touchableProps
  }: ButtonProps) {
    const primaryColor = (() => {
      if (!outline) return 'white'
      if (disabled || loading) return Colors.shades['400']
      return color
    })()
    const styles = useStyles({ small, outline, disabled, loading, color, style, textStyle, primaryColor })

    return (
      <Touchable
        activeOpacity={loading ? 0.9 : 0.2}
        style={styles.container}
        disabled={disabled || loading}
        onPress={onPress}
        {...touchableProps}
      >
        {icon && <Icon name={icon} color={primaryColor} style={styles.iconStyle} size={size} />}

        <LoadingView loading={loading} spinnerProps={{ color: primaryColor }} switchMode noDefaultLoadingContainerStyle>
          <Text size={size} type="medium" style={styles.textStyle} children={title} {...textProps} />
        </LoadingView>
      </Touchable>
    )
  }),
)

const useStyles = (args: Partial<ButtonProps> & { primaryColor: string }) => {
  return useDeepCompareLayoutFnStyles(
    ({ isSmallDevice, isMedDevice }, { small, outline, disabled, loading, color, style, textStyle, primaryColor }) => ({
      container: {
        borderRadius: 10,
        borderWidth: 1,
        minHeight: small ? 40 : 45,
        flexDirection: 'row',
        marginHorizontal: small ? 0 : 10,
        marginVertical: small ? 0 : 5,
        paddingHorizontal: small ? 5 : 20,
        alignItems: 'center',
        justifyContent: 'center',
        ...(outline
          ? { borderColor: primaryColor }
          : { borderColor: Colors.transparent, backgroundColor: disabled || loading ? Colors.shades['200'] : color }),
        ...spreadStyle(style),
      },
      textStyle: {
        textAlign: 'center',
        fontWeight: '600',
        color: primaryColor,
        ...spreadStyle(textStyle),
      },
      iconStyle: {
        paddingRight: isSmallDevice ? 5 : isMedDevice ? 10 : 15,
      },
    }),
    args,
  )
}
