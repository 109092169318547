import { FontAwesome5 } from '@expo/vector-icons'
import * as Font from 'expo-font'
import * as SplashScreen from 'expo-splash-screen'
import { useState } from 'react'

import { Logger } from '@/config/logger'
import { useCancelableFx } from '@/hooks/useCancelablePromise'

export default function useCachedResources() {
  const [loading, setLoading] = useState(true)

  // Load any resources or data that we need prior to rendering the app
  useCancelableFx(async function loadResourcesAndDataAsync() {
    try {
      SplashScreen.preventAutoHideAsync()

      // Load fonts
      // TODO: Test whether using recommended approach useFonts is better https://docs.expo.dev/versions/latest/sdk/font/
      await Font.loadAsync({
        ...FontAwesome5.font,
        AveriaSerifLibreBold: require('../../assets/fonts/AveriaSerifLibre-Bold.ttf'),
        AveriaSerifLibreRegular: require('../../assets/fonts/AveriaSerifLibre-Regular.ttf'),
        MontserratBold: require('../../assets/fonts/Montserrat-Bold.ttf'),
        MontserratMedium: require('../../assets/fonts/Montserrat-SemiBold.ttf'),
        MontserratRegular: require('../../assets/fonts/Montserrat-Medium.ttf'),
      })
    } catch (e) {
      // We might want to provide this error information to an error reporting service
      Logger.warn(e)
    } finally {
      setLoading(false)
      SplashScreen.hideAsync()
    }
  }, [])

  return loading
}
