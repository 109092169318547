import { CheckBox, Divider, FormMoneyInput, Text, TextH2 } from '@elements'

import React from 'react'
import { View } from 'react-native'

import { AdminCard } from '@/admin/components/AdminCard'
import { globalStyles } from '@/constants/Styles'
import { FormikProps } from 'formik'
import { PaymentsForm } from './types'

/** Tip options the farm can specify for payments */
export function TipOptions({ values, setFieldValue, errors, touched }: FormikProps<PaymentsForm>) {
  return (
    <AdminCard noMargins>
      <TextH2 size={18}>Tips & Fees</TextH2>

      <View style={globalStyles.margin10} />
      <Text>
        You can choose to show a tip option to customers on checkout as well as an option that allows the customer to
        cover farm fees.
      </Text>
      <Divider clear />
      <CheckBox
        checked={values.customerChooseToPayFees}
        onChange={(val) => setFieldValue('customerChooseToPayFees', val)}
        title="Let customers choose to pay fees"
      />
      <Divider clear />
      <CheckBox
        checked={values.showTipOption}
        onChange={(val) => setFieldValue('showTipOption', val)}
        title="Show tip option to customers"
      />
      <Divider clear />
      <CheckBox
        checked={values.minCartSelected}
        onChange={(val) => {
          setFieldValue('minCartSelected', val)
          if (!val) {
            // Reset min cart mount when the value is minimum cart field is not enabled
            setFieldValue('minCartAmount', undefined)
          }
        }}
        title="Minimum purchase amount in the cart (does not apply to shares)"
      />
      {values.minCartSelected && (
        <FormMoneyInput
          value={values.minCartAmount}
          onChangeText={(value) => {
            setFieldValue('minCartAmount', value)
          }}
          errorMessage={touched.minCartAmount && errors.minCartAmount ? errors.minCartAmount : ''}
        />
      )}
    </AdminCard>
  )
}
