import { dequal } from '@helpers/customDequal'
import { Distribution, DistributionConstraint } from '@models/Distribution'
import { PhysicalProduct, Product, isPhysical, isShare } from '@models/Product'
import { Frequency, getScheduleAvailability, isSeasonalSchedule } from '@models/Schedule'
import { ProductError, isProdErr, prodDistroErrCodes } from '@shared/errors/product'
import { DateTime } from 'luxon'

import { buildProduct, validateFreqConstraint, validateProdDistro } from './builders/buildProduct'
import { deepClone } from './helpers'
import { getDistributionPickups, getPickups } from './order'
import { getDistroNConstraint, getProductFrequency } from './products'
import { isFuture } from './time'
import { omit } from './typescript'

/** Validates that a schedule edit will produce no invalid product data
 * @param prod the product to validate
 * @param updatedSchedule the updated schedule from the product. It is assumed this has the same id as a schedule in the product
 * @param updatedConstraint if the schedule update also requires an update to the constraint for that schedule, it should go here. If undefined, validation will use any existing constraint for the schedule
 */
export function validateProdWithUpdatedSchedule(
  prod: PhysicalProduct,
  updatedSchedule: Distribution,
  updatedConstraint: DistributionConstraint | undefined,
) {
  // First check if the product is valid prior to the schedule edit
  let prodIsValidBeforeEdit = true

  try {
    buildProduct(prod)
  } catch {
    prodIsValidBeforeEdit = false
  }

  // If the product is invalid prior to the schedule edit we won't block the schedule edit on this ground
  // This is consistent with the current behavior of schedule editing in general (Outside of this function) because the entire schedule editing validation flow is not intended to validate the entire product, but rather it only validates the product data related to the schedule change
  // However, here we're saying if the product was valid before the edit, we will not allow it to become invalid
  // If the product was already invalid however, it will only do the default schedule-related validation and not the validation for the product as a whole
  if (!prodIsValidBeforeEdit) return

  // Otherwise (if the product was valid before the edit) we will validate the product as a whole with the updated schedule in it

  // This is intended to ensure the schedule is in the product and get the constraint if any
  // If the schedule is not in the product it should error
  const [_, constr] = getDistroNConstraint(prod, updatedSchedule.id)

  const prodClone = deepClone(prod)

  prodClone.distributions = prodClone.distributions.map((sch) =>
    sch.id === updatedSchedule.id ? updatedSchedule : sch,
  )

  if (updatedConstraint) {
    if (constr) {
      prodClone.distributionConstraints = prodClone.distributionConstraints?.map((c) =>
        c.id === updatedConstraint.id ? updatedConstraint : c,
      )
    } else {
      prodClone.distributionConstraints = [...(prodClone.distributionConstraints ?? []), updatedConstraint]
    }
  }

  // This should throw an error if the product is invalid with the updated schedule and constraint
  buildProduct(prodClone)
}

/**
 * Given a delta in the number of pickups between two versions of a distribution associated with a product, this will produce a new end date for the product's distro constraint, which results in the same number of pickups for both the old and the new distro
 *
 * @param endDate th product's distribution constraint end date for the distro
 * @param freq the frequency of the new distro
 * @param deltaPickups the difference in the number of pickups for the product, between the new and old distribution
 * @returns the new end date which equalizes the number of pickups despite the change in the distribution
 */
const adjustEndDateByDeltaPickups = (endDate: DateTime, freq: Frequency, deltaPickups: number) => {
  // If the number of pickups is the same we do not need to change the endDate
  if (deltaPickups === 0) return endDate
  // If we have missing pickups add them. if we have too many remove them
  switch (freq) {
    case Frequency.DAILY:
      return endDate.minus({ days: deltaPickups })
    case Frequency.MONTHLY:
      return endDate.minus({ months: deltaPickups })
    case Frequency.BIWEEKLY:
      return endDate.minus({ weeks: deltaPickups * 2 })
    case Frequency.WEEKLY:
      return endDate.minus({ weeks: deltaPickups })
    default:
      return endDate
  }
}

/**
 * This will try to expand the distribution constraint so it is fits the new distribution
 *
 * @param prod a product associated with a distribution whose schedule was updated. (product has the old distro)
 * @param newDist the updated distribution
 * @param deltaPickups is the difference in the number of pickups for this product, between the old and new distribution.
 * - If deltaPickups is omitted, the change will simply limit the constraint to the new distro schedule, without attempting to match number of pickups
 * - If deltaPickups is provided, the change will be as close as possible to matching the number of pickups, while being compatible with the new distro schedule
 * @returns the new product with updated distribution and constraints
 */
function adjustDateConstraint(prod: PhysicalProduct, newDist: Distribution, deltaPickups = 0): PhysicalProduct {
  // Copy the old constraint into the new one, or make a new empty one
  // Clone so we don't mutate the constraint in the product
  const oldConstraint = prod.distributionConstraints?.find((constraint) => newDist.id === constraint.id)
  const newConstraint: DistributionConstraint = oldConstraint ? deepClone(oldConstraint) : { id: newDist.id }

  // If there's no date constraint, use the old schedule availability
  if (!newConstraint?.dateRange)
    newConstraint.dateRange = getScheduleAvailability(prod.distributions.find((d) => d.id === newDist.id)!.schedule)

  // Adjust end date

  // First we need the frequency. If a constraint exists, should check it is still valid
  if (newConstraint.frequency) validateFreqConstraint(newConstraint.frequency, newDist.schedule.frequency)
  // If the frequency were invalid, an error would be thrown by validateFreqConstraint, so here we can assure tsc the freq won't be null. ("fn()!")
  const freq = getProductFrequency(prod, newDist, { strict: true })!

  // Creates a new end date that tries to make the number of pickups match.
  // This end date may be incompatible with the new distro, so we will limit in next step
  const newEndDate = adjustEndDateByDeltaPickups(newConstraint.dateRange.endDate, freq, deltaPickups)

  // Restrict the new endDate to the new distro end date
  newConstraint.dateRange.endDate = isSeasonalSchedule(newDist.schedule)
    ? DateTime.min(newEndDate, newDist.schedule.season.endDate)
    : newEndDate

  // Adjust start date

  // Limit the constraint start date to the new schedule
  newConstraint.dateRange.startDate = DateTime.max(
    getScheduleAvailability(newDist.schedule).startDate,
    newConstraint.dateRange.startDate,
  )

  // The newDistributionConstraints should include the new constraint for this distro.
  const newDistributionConstraints = oldConstraint
    ? prod.distributionConstraints?.map((c) => (c.id === newConstraint.id ? newConstraint : c))
    : [...(prod.distributionConstraints ?? []), newConstraint]

  const adjustedProd: PhysicalProduct = {
    ...deepClone(prod),
    distributions: prod.distributions.map((d) => (d.id === newDist.id ? newDist : d)),
    distributionConstraints: newDistributionConstraints,
  }

  /** Validate the product / distro combination resulting from this adjustment. it shouldn't be necessary to validate the entire product here because if a product has invalid data for reasons unrelated to the schedule edit, it would be blocked for no good reason */
  validateProdDistro(adjustedProd, newDist)

  return adjustedProd
}

/** On a distribution change, we want to know whether the change will produce a change in the number of pickups for a given share associated with it, this will allow us to have a clean 1-to-1 mapping. Specifically, this will:
 * 1. adjust a product previously associated with it by making its distribution constraints compatible with the new distro
 * 2. calculate the future pickups for both the old and new distro for later processing
 *
 * @param prod The original share to be adjusted
 * @param oldDist the product distribution before the change
 * @param newDist the product distribution after the change
 * @returns
 * - futurePickupsOldDistro: the future pickup dates with the old distro;
 * - futurePickupsNewDistro: the pickup dates with the new distro;
 * - updatedConstraint: the updated distribution constraint
 */
export function onScheduleChangeDeltaPickups(
  prod: PhysicalProduct,
  oldDist: Distribution,
  newDist: Distribution,
): {
  futurePickupsOldDistro: DateTime[]
  futurePickupsNewDistro: DateTime[]
  /** Will hold the new constraint for any products we altered to fit the new distribution requirements */
  updatedConstraint: DistributionConstraint | undefined
} {
  const futurePickupsOldDistro = getDistributionPickups(oldDist, prod).filter(
    isFuture(oldDist.location.timezone, 'day'),
  )

  /**
   * This will make the distro constraint compatible with the new distro
   *
   * An error might be thrown by `adjustDateConstraint`. It MUST be allowed to throw, because it is meant to get caught by `validateLinkedProducts`, so it can be added to one of the error maps (The fixable error map VS the blocking error map). An error here would mean this process couldnt adjust the product availability to the new schedule in a valid way.
   */
  let adjustedProd = adjustDateConstraint(prod, newDist)

  let futurePickupsNewDistro = getDistributionPickups(newDist, adjustedProd).filter(
    isFuture(oldDist.location.timezone, 'day'),
  )

  // If new pickups different from the old, we try to adjust again, this time providing the delta.
  // This will produce a new endDate in the distroConstraint that tries to match the number of pickups, while staying within the schedule limits
  const delta = futurePickupsNewDistro.length - futurePickupsOldDistro.length
  if (delta !== 0) {
    /** Any errors thrown by `adjustDateConstraint` MUST be allowed to throw because they're meant to get caught by validateLinkedProducts */
    adjustedProd = adjustDateConstraint(prod, newDist, delta)
    futurePickupsNewDistro = getPickups(newDist, adjustedProd).filter(isFuture(oldDist.location.timezone, 'day'))
  }

  // It should only return an updated constraint if the product constraint actually was updated
  const newConstraint = adjustedProd.distributionConstraints?.find((c) => c.id === newDist.id)
  const oldConstraint = prod.distributionConstraints?.find((c) => c.id === newDist.id)

  // It should return the newConstraint if it either changed, or if it didn't previously exist
  const updatedConstraint =
    (newConstraint && oldConstraint && !dequal(newConstraint, oldConstraint)) || (!oldConstraint && newConstraint)
      ? newConstraint
      : undefined

  return {
    futurePickupsOldDistro,
    futurePickupsNewDistro,
    updatedConstraint,
  }
}

export type DeltaPickupsErrObj = {
  kind: 'deltaPickups'
  product: Product
  /** deltaPickups will only have a value if the product is a share whose only error was an altered number of future pickups. the number will be non zero */
  deltaPickups: number
  error?: undefined
}

export type ValidationErrObj = {
  kind: 'validationError'
  product: Product
  deltaPickups?: undefined
  /** The error will most likely be a ProductError type */
  error: unknown
}

export type ProdErrorsMap<V extends DeltaPickupsErrObj | ValidationErrObj = DeltaPickupsErrObj | ValidationErrObj> =
  Map<string, V>

/**
 * Receives the products linked to a schedule being edited, and checks whether the schedule change impacts the products in any incompatible way, such as altering the number of pickups for shares, or invalid constraints, or any other product error.
 *
 * @returns a map of fixable and non fixdable product errors related to the schedule change. in the case of number of future pickups altered, shares data will include the delta pickups.
 *
 * Server: This helper will also be used in the server to compute the changes. This will ensure the behavior will be similar on client and in schedule onUpdate triggered after the update.
 */
export async function validateLinkedProducts(
  oldDist: Distribution,
  newDist: Distribution,
  linkedProds: Product[],
): Promise<{
  blockingErrors: ProdErrorsMap
  fixableErrors: ProdErrorsMap<ValidationErrObj>
  updatedConstraints: Map<string, DistributionConstraint>
  futurePickupsDiff: Map<string, { oldDist: DateTime[]; newDist: DateTime[] }>
}> {
  const blockingErrors: ProdErrorsMap = new Map()
  const fixableErrors: ProdErrorsMap<ValidationErrObj> = new Map()
  const updatedConstraints: Map<string, DistributionConstraint> = new Map()
  const futurePickupsDiff: Map<string, { oldDist: DateTime[]; newDist: DateTime[] }> = new Map()

  linkedProds.forEach((prod) => {
    if (!isPhysical(prod)) return
    try {
      /** All linked physical products should pass through onScheduleChangeDeltaPickups.
       * It'll try to adjust any distro constraints, get the number of future pickups left,
       * and throw errors if new distro not valid with the adjustments.
       *
       * IMPORTANT: Standard products should also pass through this step, even if we don't care about their future pickups because there is a server onUpdate which will try to update the pickups for this distro and related product data. That onUpdate should modify pickups for standard products too; It will also update any distro constraints that are fixable for standard products, and in some cases unassign the distro from inactive ones. Therefore this is the last line of defense against an unwanted schedule edit. If a standard product cannot be adjusted and doesn't qualify for unlinking, then this should block the editing
       */
      const { futurePickupsNewDistro, futurePickupsOldDistro, updatedConstraint } = onScheduleChangeDeltaPickups(
        prod,
        oldDist,
        newDist,
      )

      // Only for shares we care about whether the future pickups are the same, because only shares have a numerPickups field
      if (futurePickupsOldDistro.length !== futurePickupsNewDistro.length && isShare(prod)) {
        blockingErrors.set(prod.id, {
          kind: 'deltaPickups',
          product: prod,
          deltaPickups: futurePickupsNewDistro.length - futurePickupsOldDistro.length,
        })
      }

      if (updatedConstraint) {
        updatedConstraints.set(prod.id, updatedConstraint)
      }

      futurePickupsDiff.set(prod.id, { oldDist: futurePickupsOldDistro, newDist: futurePickupsNewDistro })

      validateProdWithUpdatedSchedule(prod, newDist, updatedConstraint)
    } catch (err) {
      // If the error is fixable add it to a separate map `fixableErrors`
      if (isErrorFixable(prod, err)) {
        fixableErrors.set(prod.id, { kind: 'validationError', product: prod, error: removeDistFromErrorData(err) })

        // If the error isn't fixable, it will be added to the blockingErrors map. Any error in this map should block the schedule editing
      } else
        blockingErrors.set(prod.id, {
          kind: 'validationError',
          product: prod,
          error: removeDistFromErrorData(err),
        })
    }
  })

  return { blockingErrors, fixableErrors, updatedConstraints, futurePickupsDiff }
}

/** Normally the product error generated by the product validator will have a distro property which refers to the distribution the error is related to. For the use-case of schedule editing, we don't want the distro being part of the error message. However we don't want to completely remove that part of the error everywhere because it's useful in other places. So here we're just re-creating the error, without the optional 'dist' property, which should automatically remove the schedule name from the generated error message. */
const removeDistFromErrorData = (err: unknown): Error => {
  return isProdErr(err) ? new ProductError(omit(err.data, 'dist')) : (err as Error)
}

/** Determines if a product and error combo could make a product qualify for being unlinked from a distribution on a distribution edit attempt.
 * - assumes the error originated from the product validation that takes place during distribution edit for linked products.
 * - the criteria for whether a product could be unlinked should be: If it's a product error related to the distro or the product is old data.
 */
const isErrorFixable = (p: Product, err: unknown) => {
  return (
    isProdErr(err) && prodDistroErrCodes.includes(err.data.code) // The error code must be distro-related to qualify for unlinking
  )
}
