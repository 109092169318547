import { AddToCartFlow } from '@/hooks/useCart/addToCartFlow/useAddToCartFlow'
import { Stepper } from '@components'
import { Button } from '@elements'
import { CartItem } from '@models/Order'
import { memo } from 'react'
import { StyleSheet } from 'react-native'

type AddBtnAdminProps = {
  itemInCart: CartItem | undefined
  updateQty: (delta: number) => void
  isLoadingCart: boolean
  isLoadingAddCartFlow: boolean
  disableAdd: boolean
  addToCart: () => ReturnType<AddToCartFlow>
}

/** UI-only reusable add-to-cart button for the order creator table. This is intended to be data-agnostic so it can be reused in order editing */
export const AdminAddCartBtnUI = memo(function AdminAddCartBtnUI({
  itemInCart,
  updateQty,
  isLoadingAddCartFlow,
  isLoadingCart,
  disableAdd,
  addToCart,
}: AddBtnAdminProps) {
  return itemInCart ? (
    <Stepper
      style={styles.stepper}
      cartItem={itemInCart}
      updateQuantity={updateQty}
      loading={isLoadingCart}
      disableAdd={disableAdd}
    />
  ) : (
    <Button
      title="Add"
      onPress={addToCart}
      loading={isLoadingCart || isLoadingAddCartFlow}
      disabled={disableAdd}
      style={styles.addCartBtn}
    />
  )
})

export const styles = StyleSheet.create({
  stepper: { borderWidth: 0 },
  addCartBtn: {
    flex: 1,
    marginHorizontal: 0,
    paddingHorizontal: 0,
  },
})
