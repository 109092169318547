import { dequal } from '@helpers/customDequal'
import { isObject } from '@helpers/helpers'
import { DateTime } from 'luxon'
import { isValidElement } from 'react'

import { isLuxonDateTime } from '../time'
import { keys } from '../typescript'

/** Identifies whether there is a jsx element inside the data structure */
const hasJsx = (val: any): boolean => {
  /** isvalidElement must be checked before the other checks, like isArray and isObject, because isObject will be true for jsx */
  return isValidElement(val)
    ? true
    : Array.isArray(val)
    ? val.some((inner) => hasJsx(inner))
    : isObject(val)
    ? Object.values(val).some((inner) => hasJsx(inner))
    : false
}

/** Can be passed to a react.memo to deep compare component props and prevent unnecessary renders hardcore.
 * - It prevents passing jsx elements to dequal, to prevent unexpected results.
 * - This should only be used in components where the props are complex, or have multiple levels of nested objects, or change often, etc. A regular component doesn't merit this level of memoization, and would be overkill, a waste of resources. (Use only in special circumstances where you can justify this is helpful, on a case-by-case basis)
 */
export function propsAreDeepEqual<Props extends Record<any, any>>(
  prev: Readonly<Props>,
  next: Readonly<Props>,
): boolean {
  const nextK = keys(next).sort()
  const prevK = keys(prev).sort()
  if (!dequal(nextK, prevK)) return false

  let isEqual = true
  nextK.forEach((k) => {
    //Don't consider meta for deep inequality
    if (k.toString().startsWith('_') || k.toString().toLowerCase().includes('meta')) {
      return
    }

    const nv = next[k]
    const pv = prev[k]
    if (hasJsx([pv, nv])) {
      /** compare jsx elements by simple referential equality. if they're both memoized, this might think they're equal which is good. this is why it's best to avoid passing children or jsx into components props, there's no way to compare them deeply */
      // eslint-disable-next-line eqeqeq
      if (nv != pv) isEqual = false
    } else if (isLuxonDateTime(nv) && isLuxonDateTime(pv)) {
      if (!(nv as DateTime).equals(pv)) isEqual = false
    } else if (!dequal(nv, pv)) isEqual = false
  })

  return isEqual
}
