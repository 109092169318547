import { Money } from '@models/Money'
import * as yup from 'yup'
import { validateFromSchema } from './helpers'

/**
 * TODO: Refactor this to have currency defined in Money model as an enum
 */
export enum Currency {
  USD = 'usd',
  CAD = 'cad',
}

export const moneySchema: yup.ObjectSchema<Money> = yup
  .object({
    value: yup.number().required().min(0),
    currency: yup.mixed<Currency>().oneOf(Object.values(Currency)).required(),
  })
  .typeError('Money must be an object')
  .defined()

/** Validates a money object */
export const validateMoney = (money: object) => validateFromSchema(moneySchema, money)
