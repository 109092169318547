import React from 'react'
import { Image, StyleSheet, View } from 'react-native'
import { farmerOnboardMiscellaneousAssets } from '@/assets/images/farmer_onboard'
import Colors from '@/constants/Colors'
import { HeaderText, Icon, Text } from '@elements'
import { SectionContainer } from '@screens/FarmerOnboard/components'

/**
 * `PricingSection` is a component that renders a section detailing the pricing structure and fees associated with the platform.
 */
export function PricingSection() {
  return (
    <SectionContainer outerContainerStyle={pricingSectionStyles.outerContainer} divider>
      <View style={pricingSectionStyles.leftContainer}>
        <HeaderText size={30} style={pricingSectionStyles.transparentPricingText}>
          Transparent Pricing
        </HeaderText>
        <Text size={11} style={pricingSectionStyles.description}>
          We keep our fees as low as possible while covering our costs, and many shoppers cover all farmer fees.
        </Text>
        <SectionIconWithText text="Free to get started" />
        <SectionIconWithText text="Pay only when you make a sale" />
        <SectionIconWithText text="2.9%+.$30 credit card fee + 2% Co-op Service Charge" />
        <SectionIconWithText text="Customers can and often cover all service charges" />
        <SectionIconWithText text="Free offline payments" />
        <SectionIconWithText text="Free SNAP Online processing for eligible farms" />
      </View>
      <View style={pricingSectionStyles.rightContainer}>
        <Image source={farmerOnboardMiscellaneousAssets.purchasingGroceries} style={pricingSectionStyles.icon} />
      </View>
    </SectionContainer>
  )
}

const pricingSectionStyles = StyleSheet.create({
  outerContainer: { paddingBottom: 40 },
  leftContainer: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    marginLeft: 20,
  },
  transparentPricingText: {
    color: Colors.darkGreen,
    textAlign: 'center',
    fontStyle: 'italic',
  },
  rightContainer: { marginTop: 10 },
  description: {
    color: Colors.darkGray,
    maxWidth: 450,
    marginVertical: 10,
    lineHeight: 30,
  },
  icon: {
    width: 498,
    height: 338,
  },
})

function SectionIconWithText({ text }: { text: string }) {
  return (
    <View style={iconWithTextStyles.iconWithTextContainer}>
      <Icon iconSet="MaterialIcons" name="check" color="green" size={13} />
      <Text size={13} style={iconWithTextStyles.iconWithText}>
        {text}
      </Text>
    </View>
  )
}

const iconWithTextStyles = StyleSheet.create({
  iconWithTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    marginBottom: 15,
    marginLeft: 5,
  },
  iconWithText: {
    marginLeft: 5,
  },
})
