import { Farm } from '@models/Farm'
import { AnyAction } from 'redux'

import {
  SET_ADMIN_DISTROS,
  SET_ADMIN_FARM,
  SET_ADMIN_FARMID,
  SET_ADMIN_LOCS,
  SET_ADMIN_PERMISSIONS,
  SET_ADMIN_PRODUCTS,
  SET_CATEGORIES,
  SET_CERTIFICATIONS,
  SET_IS_ADMIN,
  SET_ISWHOLESALE_ORDER_CREATOR,
  UPDATE_CART_INFO_ORDER_CREATOR,
} from '../actions/actionTypes'
import { AdminPersist } from './types'

export const initialState: AdminPersist = {
  farm: {} as Farm,
  locations: [],
  distributions: [],
  certifications: [],
  categories: ['Fruits', 'Meats', 'Vegetables', 'Nuts', 'Grains', 'Eggs'],
  cartInfo: {},
  isWholesaleOrderCreator: undefined,
}

const adminPersistReducer = (state = initialState, action: AnyAction): AdminPersist => {
  switch (action.type) {
    case SET_IS_ADMIN:
      return {
        ...state,
        isAdmin: action.payload,
      }
    case SET_ADMIN_FARM:
      return {
        ...state,
        farm: action.payload,
      }
    case SET_ADMIN_DISTROS:
      return {
        ...state,
        distributions: action.payload,
      }
    case SET_ADMIN_LOCS:
      return {
        ...state,
        locations: action.payload,
      }
    case SET_ADMIN_FARMID:
      return {
        ...state,
        adminFarmId: action.payload,
      }
    case SET_ADMIN_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      }
    case SET_CERTIFICATIONS:
      return {
        ...state,
        certifications: action.payload,
      }
    case SET_ADMIN_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      }
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      }
    case UPDATE_CART_INFO_ORDER_CREATOR:
      return {
        ...state,
        cartInfo: action.payload,
      }
    case SET_ISWHOLESALE_ORDER_CREATOR:
      return {
        ...state,
        isWholesaleOrderCreator: action.payload,
      }
    default:
      return state
  }
}
export default adminPersistReducer
