import { memo } from 'react'
import { View } from 'react-native'

import Colors from '@/constants/Colors'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { Image } from '@components'
import { Text, TextH1 } from '@elements'
import { formatMoney, formatPickupDate, plural } from '@helpers/display'
import { formatDistributionType } from '@helpers/location'
import { cartItemTotal } from '@helpers/order'
import { Cart } from '@models/Cart'
import { isNonPickup } from '@models/Location'
import { CartStandard, isCartStandard } from '@models/Order'

interface DraftOrderItemsProps {
  draftCart: Cart
}

export const DraftOrderItems = memo(function DraftOrderItems({ draftCart }: DraftOrderItemsProps): JSX.Element {
  const styles = useStyles()

  /* Right now we only support draft orders for wholesale so it will always be standard products. Once we switch to supporting retail orders as drafts we will need to update this to support shares and digital products */
  const cartItems = Object.values(draftCart.items).filter(isCartStandard)

  return (
    <View>
      <View style={styles.headerContainer}>
        <TextH1 size={18}>Requested Items</TextH1>
      </View>
      <View style={styles.itemsContainer}>
        {cartItems.map((ci) => (
          <DraftOrderItem key={ci.id} item={ci} />
        ))}
      </View>
    </View>
  )
})

interface DraftOrderItemProps {
  item: CartStandard
}

const DraftOrderItem = memo(function DraftOrderItem({ item }: DraftOrderItemProps): JSX.Element {
  const styles = useStyles()

  return (
    <View style={styles.itemContainer}>
      <View style={styles.itemLeft}>
        <Image
          source={{
            uri: item.product.images[0],
          }}
          style={styles.itemImage}
          resizeMode="cover"
        />
        <View style={styles.itemInfo}>
          <Text size={12} numberOfLines={2}>
            (x{item.quantity}) {item.product.name}
          </Text>
          <Text size={12} color={Colors.primaryGray}>
            {isNonPickup(item.distribution.location)
              ? `${formatDistributionType(item.distribution.location, { capitalize: true })} Zone`
              : `Pickup Location`}
            : {item.distribution.location.name}
          </Text>
          <Text size={12} color={Colors.primaryGray}>
            {formatDistributionType(item.distribution.location, { capitalize: true })}{' '}
            {plural(item.pickups.length, 'Date')} : {item.pickups.map(formatPickupDate).join(', ')}
          </Text>
        </View>
      </View>
      <View style={styles.itemRight}>
        <Text size={12}>
          ({formatMoney(item.price.amount)}/ {item.unit.name})
        </Text>
        <Text size={12}>{formatMoney(cartItemTotal(item))}</Text>
      </View>
    </View>
  )
})

const useStyles = () =>
  useSizeFnStyles(({ isSmallDevice }) => ({
    headerContainer: {
      marginBottom: 20,
    },
    itemsContainer: {
      borderWidth: 1,
      borderColor: Colors.shades['200'],
      borderRadius: 10,
      overflow: 'hidden',
    },
    itemContainer: {
      flexDirection: isSmallDevice ? 'column' : 'row',
      paddingHorizontal: isSmallDevice ? 16 : 64,
      paddingVertical: isSmallDevice ? 16 : 32,
      justifyContent: 'space-between',
      gap: 8,
    },
    itemLeft: {
      flexDirection: 'row',
      gap: 16,
    },
    itemImage: {
      width: 100,
      borderRadius: 5,
      height: 100,
      alignSelf: 'center',
    },
    itemInfo: {
      flexDirection: 'column',
      gap: 8,
    },
    itemRight: {
      flexDirection: 'row',
      gap: 8,
    },
  }))
