import { StyleSheet } from 'react-native'
import { Input, InputProps } from 'react-native-elements'

import Colors from '../constants/Colors'

export function TextInputBox(props: InputProps) {
  return (
    <Input
      {...props}
      inputContainerStyle={[styles.textContainerStyle, props.inputContainerStyle]}
      inputStyle={[styles.inputStyle, props.inputStyle]}
      containerStyle={[styles.containerStyle, props.containerStyle, props.rightIcon && styles.hasRightIcon]}
    />
  )
}

const styles = StyleSheet.create({
  textContainerStyle: {
    borderWidth: 1,
    borderRadius: 10,
    borderColor: '#ABABAB',
  },
  inputStyle: {
    height: 50,
    color: Colors.shades[600],
    fontSize: 16,
    padding: 10,
    marginRight: -5,
  },
  containerStyle: {
    borderColor: '#ABABAB',
  },
  hasRightIcon: {
    marginRight: -50,
  },
})
