import { StackNavigationProp } from '@react-navigation/stack'

import { AdminOrdersParamList, CustomerParamList } from '@/admin/navigation/types'
import { Logger } from '@/config/logger'

export const goBackString = (nav: StackNavigationProp<any>, custId?: string) => {
  const state = nav.getState()
  return state.routeNames.includes('AdminOrderList')
    ? 'All Orders'
    : state.routeNames.includes('CustomerDetails') && !!custId
    ? 'Customer Details'
    : 'Previous Screen'
}

/** Should go back to the previous screen, whether that is customers, orders -> match current needs. */
export const goBack = (nav: StackNavigationProp<any>, custId?: string) => {
  const state = nav.getState()
  if (state.routeNames.includes('AdminOrderList'))
    (nav as StackNavigationProp<AdminOrdersParamList>).navigate('AdminOrderList')
  else if (state.routeNames.includes('CustomerDetails') && !!custId)
    (nav as StackNavigationProp<CustomerParamList>).navigate('CustomerDetails', { custId })
  else if (nav.canGoBack()) {
    nav.goBack()
  } else {
    Logger.error(new Error('Something went wrong while trying to navigate back'))
  }
}
