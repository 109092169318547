import { RangeItem } from '@screens/SearchScreen/components/RangeFilter/RangeFilter'

/** Static filter options for price */
export const priceOptions: RangeItem[] = [
  {
    label: '$0 to $20',
    value: {
      min: undefined,
      max: 2000,
    },
    isRefined: false,
  },
  {
    label: '$20 to $40',
    value: {
      min: 2000,
      max: 4000,
    },
    isRefined: false,
  },
  {
    label: '$40 to $60',
    value: {
      min: 4000,
      max: 6000,
    },
    isRefined: false,
  },
  {
    label: '$60 to $80',
    value: {
      min: 6000,
      max: 8000,
    },
    isRefined: false,
  },
  {
    label: '$80 to $100',
    value: {
      min: 8000,
      max: 10000,
    },
    isRefined: false,
  },
]

/** Static filter options for orderMinimum */
export const orderMinOptions: RangeItem[] = [
  {
    label: '$100 or less',
    value: {
      min: undefined,
      max: 10000,
    },
  },
  {
    label: '$200 or less',

    value: {
      min: undefined,
      max: 20000,
    },
  },
  {
    label: '$300 or less',
    value: {
      min: undefined,
      max: 30000,
    },
  },
]
