import { loadInvoice } from '@api/Invoices'
import { Spinner, Text } from '@elements'
import { AlgoliaAdminInvoice } from '@models/Algolia'
import { Invoice } from '@models/Invoice'
import { useSelector } from 'react-redux'

import { userSelector } from '../../../../redux/selectors'
import { ActionBtn, ActionsMenuComponent } from '../../../components/elements/ActionsMenuComponent'
import { invoiceActionGenerator } from '../helper/invoiceActionGenerator'

import { Logger } from '@/config/logger'
import { useFocusFx } from '@/hooks/useFocusFx'
import { useHasPermissionWithFlag } from '@/hooks/useHasPermission'
import useKeyedState from '@/hooks/useKeyedState'
import { AccessRight, Permission } from '@helpers/Permission'

type Props = {
  inv: AlgoliaAdminInvoice
}

/**
 * This component is used to render the invoice action buttons with the invoice data from Algolia.
 */
export default function InvoiceAlgoliaActionWrapper({ inv }: Props) {
  const user = useSelector(userSelector)
  const hasAccessInvAction = useHasPermissionWithFlag(Permission.Orders, AccessRight.Edit)
  const [{ localInv, loading, buttons }, set, setWhole] = useKeyedState<{
    localInv?: Invoice
    loading: boolean
    buttons: ActionBtn<Invoice>[]
  }>({ loading: true, buttons: [] })

  useFocusFx(() => {
    loadInvoice(inv.id)
      .then((localInv) => {
        if (localInv) {
          const buttons = invoiceActionGenerator(localInv, user)
          setWhole({ localInv, loading: false, buttons })
        } else {
          set('loading', false)
        }
      })
      .catch((e) => {
        Logger.error(e)
        set('loading', false)
      })
  }, [inv])

  if (loading) return <Spinner style={{ alignSelf: 'flex-start' }} size={20} />

  if (buttons.length === 0 || !localInv) {
    return <Text>N/A</Text>
  }

  return (
    <ActionsMenuComponent<Invoice>
      containerStyle={{
        alignItems: 'flex-start',
      }}
      data={localInv}
      buttons={buttons}
      disabled={!hasAccessInvAction}
    />
  )
}
