import { Image, MessageWithIcon } from '@components'
import { Text, typography } from '@elements'
import { getOrderNum } from '@helpers/display'
import { groupBy } from '@helpers/helpers'
import { sortByName, sortByProperty } from '@helpers/sorting'
import { isPickupItemCancelled, isPickupItemOnVacation, Order, Pickup, PickupItem } from '@models/Order'
import { ProductType } from '@models/Product'
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native'

import Colors from '../../constants/Colors'

type PickupOrderItemsType = {
  pickup: Pickup
  goToOrderDetails: (ordId: Order['id']) => void
}

export function PickupOrderItems({ pickup, goToOrderDetails }: PickupOrderItemsType) {
  const groupedPickupItems = makeItemListData(pickup.items)

  return (
    <>
      {groupedPickupItems.map((pickupItemGroup, i) => {
        const order = pickupItemGroup[0].order
        return (
          <PickupOrderItemsList
            key={`PickupOrderItems-${pickup.id}-og-${i}`}
            items={pickupItemGroup}
            title={`Order ${getOrderNum(order.orderNum)}`}
            onTitleClick={() => goToOrderDetails(order.id)}
          />
        )
      })}
    </>
  )
}

/** Returns an array where each sub-array has PickupItem of the same order */
export const makeItemListData = (pickupItems: PickupItem[]): PickupItem[][] => {
  const items: PickupItem[] = pickupItems.filter((pi) => !isPickupItemCancelled(pi))

  // Sort by name, then by type
  items.sort((a, b) => sortByName(a, b, (item) => item.product.name))
  items.sort(
    sortByProperty(
      [ProductType.PrimaryShare, ProductType.AddonShare, ProductType.Standard],
      (item) => item.product.type,
    ),
  )

  return groupBy(items, (itm) => itm.order.id)
}

type ItemListProps = {
  items: PickupItem[]
  title: string
  onTitleClick?: () => void
  style?: StyleProp<ViewStyle>
}

/** Modified version of ItemList (From OrderCard), which visually indicates which pickup items have been skipped.
 * The prop Item type includes the PickupItem, OrderItem and Order.
 *
 * This shows the horizontal list of item names and images for each order inside the PickupCard
 */
function PickupOrderItemsList({ items, title, onTitleClick, style }: ItemListProps) {
  const itemNamesList = makeItemNamesList(items)

  return (
    <View style={[itemListStyles.container, style]}>
      <TouchableOpacity onPress={onTitleClick}>
        <Text size={16} type="regular" style={[itemListStyles.title, onTitleClick && itemListStyles.clickableTitle]}>
          {title}
        </Text>
      </TouchableOpacity>
      {items.length ? (
        <>
          <Text size={14} type="regular" style={{ fontFamily: typography.body.regular }}>
            {itemNamesList}
          </Text>
          <View style={itemListStyles.itemImages}>
            {items.map((item, idx) => (
              <ItemImage key={`pickupOrderItem${idx}`} item={item} />
            ))}
          </View>
        </>
      ) : (
        <MessageWithIcon icon="box-open" title="No Items" style={itemListStyles.noItems} />
      )}
    </View>
  )
}

const makeItemNamesList = (items: PickupItem[]) =>
  items.reduce((str, item, index) => {
    const name = item.product.name
    const isFirst = index === 0
    const isLast = index === items.length - 1

    const itemStr = `${name} (${item.orderItem.purchasedUnit?.name ? `${item.orderItem.purchasedUnit.name}, ` : ''}x${
      item.orderItem.quantity
    })`

    // First product
    if (isFirst) return itemStr

    if (isLast) {
      // Only 2 products and is last
      if (items.length === 2) return `${str} and ${itemStr}`
      // More than 2 products and is last
      return `${str}, and ${itemStr}`
    }

    return `${str}, ${itemStr}`
  }, '')

function ItemImage({ item }: { item: PickupItem }) {
  const isSkip = isPickupItemOnVacation(item)
  return (
    <View>
      <View style={itemListStyles.itemImage}>
        <Image
          key={item.id}
          source={{
            uri: item.product.image,
          }}
          style={[itemListStyles.itemImage, isSkip && { opacity: 0.6 }]}
          resizeMode="cover"
        />
        {isSkip && (
          <View
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <View
              style={{
                transform: [{ rotate: '45deg' }],
                height: 10,
                width: 100,
                backgroundColor: Colors.red,
                borderRadius: 5,
              }}
            />
          </View>
        )}
      </View>
      {isSkip && <Text style={{ width: '100%', textAlign: 'center', color: Colors.red }}>Skipped</Text>}
    </View>
  )
}

const itemListStyles = StyleSheet.create({
  container: {
    alignItems: 'flex-start',
    marginTop: 18,
  },
  title: {
    fontFamily: typography.body.regular,
    marginBottom: 2,
  },
  clickableTitle: {
    color: Colors.green,
  },
  itemImages: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 8,
  },
  itemImage: {
    borderRadius: 10,
    minWidth: 100,
    maxWidth: 100,
    height: 100,
    marginRight: 10,
  },
  noItems: {
    padding: 10,
    paddingBottom: 0,
  },
})
