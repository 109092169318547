import { ImageStyle, StyleSheet, ViewStyle } from 'react-native'

import Colors from './Colors'
import { DEVICE_SIZE, DEVICE_SIZES } from './Layout'

export const SHADOW_2 = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 1,
  },
  shadowOpacity: 0.2,
  shadowRadius: 1.41,

  elevation: 2,
}

export const SHADOW_5 = {
  shadowColor: Colors.black,
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.25,
  shadowRadius: 3.84,

  elevation: 5,
}

export const SHADOW_BLUR = {
  shadowColor: Colors.shades['200'],
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.3,
  shadowRadius: 10,

  elevation: 5,
}

export const SHADOW_10 = {
  shadowColor: Colors.shades['400'],
  shadowOffset: {
    width: 0,
    height: 5,
  },
  shadowOpacity: 0.34,
  shadowRadius: 6.27,

  elevation: 10,
}

export const flexContainer = (styles?: ViewStyle): ViewStyle => {
  if (DEVICE_SIZE === DEVICE_SIZES.SMALL_DEVICE) return {}
  return {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    ...styles,
  }
}

// Returns a style for maintaining a 3x2 aspect ratio, since we suggest a 1200x800 image size
export function imageAspect(width: number): ImageStyle {
  return {
    width,
    height: width * 0.67,
  }
}

export const globalStyles = StyleSheet.create({
  flex05: {
    flex: 0.5,
  },
  flex1: {
    flex: 1,
  },
  flex2: {
    flex: 2,
  },
  flexRow: {
    flexDirection: 'row',
  },
  flexColumn: {
    flexDirection: 'column',
  },
  flexRowCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  padding10: {
    padding: 10,
  },
  margin10: {
    margin: 10,
  },
  marginHorizontal10: {
    marginHorizontal: 10,
  },
  marginVertical10: {
    marginVertical: 10,
  },
  margin20: {
    margin: 20,
  },
  alignCenter: {
    alignItems: 'center',
  },
})
