import { getStore } from '@/redux/store'
import { Cart } from '@models/Cart'
import { Discount } from '@models/Coupon'
import { CartItem } from '@models/Order'
import { PaymentSchedule } from '@models/Product'
import { User } from '@models/User'
import { AddToCartApiInput, UpdateCartItemFields } from '@shared/types/v2/cart'

/** The options for adding an item to cart, using the cart service */
export type AddToCartServiceOpts = Omit<AddToCartApiInput, 'cartId'>

/** The Cart Service is a wrapper around the Cart API, which emits convenience helper methods to interact with the Cart API */
export type CartService = {
  /** Whether the cart is ready and the functions can be called. If not ready, the cart service fns will do nothing and return undefined */
  isCartInit: boolean
  cartServiceType: CartServiceType
  /** True if there's any pending cart operation. Will be true before initialization is done */
  loadingCart: boolean
  /** Whether this cart has the privileges of the order creator */
  isAdmin: boolean
  /** The items in the cart */
  cart: CartItem[]
  /** The discounts applied to the cart */
  discounts: Cart['discounts']
  /** The user assigned to purchase the items in this cart */
  customer: User | undefined
  /** Adds a product to cart */
  addToCart: (opts: AddToCartServiceOpts) => Promise<CartItem | void>
  /** Updates the quantity of an item in the cart.
   * - To remove an item from cart, call this with zero quantity.
   * - To increase the quantity, newQuantity should be the current quantity plus the additional.
   * - To decrease, newQuantity should be the current quantity minus the delta to subtract
   * - To adjust variable weight, newQuantity should be the adjusted amount and isAdjustment should be set to true */
  updateQuantity: (itemId: string, newQuantity: number, isAdjustment?: boolean) => Promise<CartItem | void>
  /** Updates the payment schedule associated with an existing cart item.*/
  updatePaySchedule: (itemId: string, paymentSchedule: PaymentSchedule) => Promise<CartItem | void>
  /** Adds a promo to the cart.
   * @param hasEbtPayment whether the current checkout payment options include any EBT total amount greater than zero.
   */
  addPromo: (farmId: string, promoId: string, hasEbtPayment: boolean) => Promise<Discount | void>
  /** Removes a coupon from the cart.*/
  removeDiscount: (farmId: string) => Promise<undefined>
  /** Updates a cart item with partial data, which gets merged into the existing item */
  updateCartItem: (itemId: string, update: UpdateCartItemFields) => Promise<CartItem | void>
}

export const initialCartService: CartService = {
  isAdmin: false,
  cart: [],
  cartServiceType: 'consumer',
  discounts: {},
  loadingCart: true,
  isCartInit: false,
  customer: undefined,
  addToCart: async () => undefined,
  addPromo: async () => undefined,
  removeDiscount: async () => undefined,
  updateQuantity: async () => undefined,
  updatePaySchedule: async () => undefined,
  updateCartItem: async () => undefined,
}

/** The controlled set of cart service instances which may exist in a client app */
export type CartServiceType = 'consumer' | 'orderCreator' | 'orderEdit'

/** Gets the current admin privilege status for a registered cart service type in redux */
export function getIsAdminForCartServiceType(cartServiceType: CartServiceType) {
  const { getState } = getStore()
  const state = getState()

  if (cartServiceType === 'consumer') {
    return state.appState.cartService.isAdmin
  } else if (cartServiceType === 'orderCreator') {
    return state.adminState.cartService.isAdmin
  } else if (cartServiceType === 'orderEdit') {
    return state.adminState.cartServiceOrderEdit.isAdmin
  } else {
    throw new Error(`Invalid cart service type: ${cartServiceType}`)
  }
}
