import { formatNotification } from '@helpers/notification'
import { formatRelative } from 'date-fns'
import { useState } from 'react'
import { FlatList, StyleSheet, useWindowDimensions, View } from 'react-native'
import { Overlay } from 'react-native-elements'
import { useSelector } from 'react-redux'

import { MessageWithIcon } from '../../components/LoaderWithMessage'
import { Divider } from '../../components/elements/Divider'
import IconWithBadge from '../../components/elements/Icon/IconWithBadge'
import { HeaderText, Text } from '../../components/elements/Text'
import Colors from '../../constants/Colors'
import { isSmallDevice } from '../../constants/Layout'
import { notificationsListSelector, unreadCountSelector } from '../../redux/selectors'

export function AdminNotifications() {
  const [isVisible, setIsVisible] = useState(false)
  const notifications = useSelector(notificationsListSelector)
  const unreadCount = useSelector(unreadCountSelector)
  const layout = useWindowDimensions()

  return (
    <>
      <IconWithBadge
        style={{ marginHorizontal: 10 }}
        name="bell"
        badgeValue={unreadCount}
        onPress={() => setIsVisible(true)}
      />
      <Overlay
        isVisible={isVisible}
        onBackdropPress={() => setIsVisible(false)}
        backdropStyle={{ backgroundColor: Colors.semiTransparent }}
        overlayStyle={{
          position: 'absolute',
          right: isSmallDevice() ? 30 : 100,
          top: isSmallDevice() ? 150 : 60,
          borderRadius: 10,
          borderWidth: isSmallDevice() ? 1 : 0,
          borderColor: Colors.shades[100],
        }}
      >
        <FlatList
          style={{
            maxHeight: isSmallDevice() ? layout.height * 0.65 : layout.height / 2,
            maxWidth: isSmallDevice() ? layout.width - 60 : layout.width,
            marginTop: 10,
            paddingHorizontal: 10,
          }}
          data={notifications}
          renderItem={({ item }) => {
            const note = formatNotification(item)
            return (
              <View style={styles.itemContainer}>
                <View style={[styles.unreadDot, !item.isRead && { backgroundColor: Colors.green }]} />
                <View style={{ width: '95%' }}>
                  <Text type="bold" style={{ marginBottom: 5 }}>
                    {note.title}
                  </Text>
                  <Text style={{ marginBottom: 5 }} numberOfLines={3}>
                    {note.body}
                  </Text>

                  <Text color={Colors.shades['200']} style={{ marginBottom: 5 }}>
                    {formatRelative(item.date.toJSDate(), new Date())}
                  </Text>
                </View>
              </View>
            )
          }}
          ListHeaderComponent={() => (
            <>
              <HeaderText>Notifications</HeaderText>
              <Divider clear />
            </>
          )}
          ListEmptyComponent={() => (
            <MessageWithIcon icon="bell-slash" title="No Notifications">
              <Text>You have no notifications! All your messages and reminders will show up here in the future.</Text>
            </MessageWithIcon>
          )}
        />
      </Overlay>
    </>
  )
}

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 10,
    width: isSmallDevice() ? '100%' : 500,
  },
  unreadDot: {
    marginRight: 10,
    width: 10,
    height: 10,
    borderRadius: 5,
  },
})
