import { EventBridge } from './EventBridge'
import { EPROTECT_PIN, EPROTECT_PIN_PAN } from './constants'
import { EProtectClientInterface, eProtectMessage, eProtectResponse } from './types'

export default class EProtectClientWrapper implements EProtectClientInterface {
  event: EventBridge<eProtectResponse>
  client: EProtectClientInterface
  constructor(mode: 'pin' | 'combined') {
    this.event = new EventBridge('eProtect-Response')
    const config = mode === 'pin' ? EPROTECT_PIN : EPROTECT_PIN_PAN
    //@ts-expect-error This is defined in the externally loaded script so nothing we can do here
    // The callback here will dispatch an event that is picked up from the caller of the request
    this.client = new EprotectIframeClient({ ...config, callback: this.event.dispatch.bind(this.event) })
  }

  async sendResponse(_: eProtectResponse) {
    // NO-OP: On web we do not need the sendResponse function as the ifram will handle it all
  }

  async getCombinedTokens(message: eProtectMessage) {
    // Sends a request to the iFrame to load this data
    this.client.getCombinedTokens(message)
    // Wait for a response from the iframe and return it
    return this.event.listen()
  }

  getCheckoutPin(message: eProtectMessage): any {
    // Sends a request to the iFrame to load this data
    this.client.getCheckoutPin(message)
    // Wait for a response from the iframe and return it
    return this.event.listen()
  }
}
