import { loadFeatures } from '@api/FeatureFlag'
import { Features } from '@models/Features'
import { UserRole } from '@models/User'
import { useSelector } from 'react-redux'

import { useApiFx } from './useApiFx'
import { useDeepCompareCallback } from './useDeepEqualEffect'

import { adminFeaturesAvailableSelector, featuresAvailableSelector } from '@/redux/selectors'

/** Provides a helper that checks whether a feature is available for the current admin farm.
 * - Uses the adminState cache
 * - If there's no admin farm, the feature states will reflect the global state
 * - If there's no farm-specific state for a given feature, will have the global state for the feature
 */
export function useAdminFeatureFlag(): (feature: Features) => boolean | undefined {
  const availableFeatures = useSelector(adminFeaturesAvailableSelector)
  return useDeepCompareCallback(
    (feature: Features) => {
      if (!availableFeatures) {
        // If features are not available, they must be loading, so don't return false until it's actually false
        return undefined
      }

      return availableFeatures[feature]
    },
    [availableFeatures],
  )
}

/** Provides a helper that checks whether a feature is available for the current consumer farm
 * - Uses the appState cache
 * - If there's no consumer farm, the feature states will reflect the global state
 * - If there's no farm-specific state for a given feature, will have the global state for the feature
 */
export function useConsumerFeatureFlag(): (feature: Features) => boolean | undefined {
  const availableFeatures = useSelector(featuresAvailableSelector)
  return useDeepCompareCallback(
    (feature: Features) => {
      if (!availableFeatures) {
        // If features are not available, they must be loading, so don't return false until it's actually false
        return undefined
      }

      return availableFeatures[feature]
    },
    [availableFeatures],
  )
}

/** Provides a helper that checks whether a feature is available, either globally (If no farmId), or for the farm identified in the provided farmId
 * - Doesn't use redux, so will call the api.
 * - This one is ideal for situations where you need the features for a specific farm on demand
 * - If a farmId is provided and there's no farm-specific state for a given feature, will have the global state for the feature
 */
export function useFeatureFlag(params?: {
  farmId?: string
  userRole?: UserRole
}): (feature: Features) => boolean | undefined {
  const { data, loading } = useApiFx(loadFeatures, [params?.farmId, params?.userRole])
  return useDeepCompareCallback(
    (feature: Features) => {
      if (loading) return undefined
      return data?.[feature]
    },
    [data, loading],
  )
}
