import { Alert } from '@elements'

/** This function will show an alert to the user that their cart will be cleared and allow them to confirm or cancel.*/
export function alertReplaceCart(): Promise<boolean> {
  return new Promise((resolve) =>
    Alert(
      'Warning',
      'Your cart will be cleared so that you can build your custom share. You can also place an order for the items in your cart then come back to customize your share.',
      [
        { text: 'Cancel', style: 'cancel', onPress: () => resolve(false) },
        { text: 'Continue', onPress: () => resolve(true) },
      ],
    ),
  )
}
