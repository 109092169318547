import { SplitTenderPayment, SplitTenderPaymentItemBase } from '@models/Order'
import { Money } from '@models/Money'
import { Farm } from '@models/Farm'
import { FarmBalance } from '@models/Payment'
import { PaymentMethod } from '@models/PaymentMethod'
import { User } from '@models/User'

/**
 * These are options that can be passed to the payment selector to control what is allowed and required for payments
 */
export type PaymentSelectorOptions = {
  /** The farm to use for loading details about what payments they accept and any messaging*/
  farm: Pick<Farm, 'id' | 'name' | 'offlinePayments' | 'paymentTypes' | 'dueDateTolerance'>

  /** The total amount for the order or invoice */
  amountTotal: Money
  /** The total amount payable by EBT */
  amountEbt: Money

  /** These options determine special restrictions to apply to certain tenders*/
  hasFuturePayments: boolean
  hasDelivery: boolean
  hasFarmBalanceItem: boolean
  /** Invoices that are not do today will not be eligible for farm credit */
  isInvoiceDueToday: boolean
  allowOfflinePayments?: boolean
}

export type PaymentOptionsContextType = {
  userId: User['id']
  /** The state of the payment selector*/
  isLoading: boolean

  isValid: boolean
  /** This will hold the validation error if there is any */
  validationError: SplitTenderErrors | undefined

  /** The configured split tender */
  splitTender: SplitTenderPayment
  /** The options supplied that determine what payment configuration is allowed*/
  options: PaymentSelectorOptions

  /** A helper for components to update the splitTender on the context*/
  updateSplitTender: (newTender: SplitTenderPaymentItemBase) => void
  /** A function that will update the context and select the new payment method that was just added */
  onAddNewPaymentMethod: (newPmt: PaymentMethod) => void

  /** The payment methods and farm credit for the given user */
  userPaymentMethods: PaymentMethod[] | undefined
  userFarmBalance: FarmBalance | undefined

  /** This will be called when splitTender is saved, or options change */
  onSplitTenderUpdated(splitTender: SplitTenderPayment | undefined): void

  /** Helper to refresh the list of payment methods */
  refreshPaymentMethods: () => void
}

/** Holds all the error states for validation */
export enum SplitTenderErrors {
  /** The options provided to the payment selector are invalid. This is a configuration error and not a user error. */
  OPTIONS_INVALID = 'OPTIONS_INVALID',
  /** This means that the user has not selected any payment option */
  NO_PAYMENT_SELECTED = 'NO_PAYMENT_SELECTED',
  /** This means that the user has not selected enough payments to cover the total */
  NOT_ENOUGH_FUNDS = 'NOT_ENOUGH_FUNDS',
  /** This means that the user selected EBT, but no amount was entered */
  EBT_AMOUNT_NOT_SET = 'EBT_AMOUNT_NOT_SET',
  /** This means that we have installments but are using finite payments, so the user needs to select a backup payment */
  NEEDS_INSTALLMENT_PAYMENT = 'NEEDS_INSTALLMENT_PAYMENT',
  /** This means that we have delivery fees but are using finite payments, so the user needs to select a backup payment */
  NEEDS_DELIVERY_PAYMENT = 'NEEDS_DELIVERY_PAYMENT',
  /** This means the user choose an online method paired with offline which we do not allow as it is a partial payment */
  PARTIAL_PAYMENT = 'PARTIAL_PAYMENT',
}
