import { User } from '@models/User'
import { DefaultCatalog } from '@models/Product'
import { capitalize } from './display'

/**
 * This function check if a user is a wholesale buyer.
 * It returns true if the user is an institution account or an institution member (wholesale authorized user). Otherwise, it returns false.
 */
export function isWholesaleBuyer(user: Pick<User, 'institution' | 'isInstitutionAuthorizedUser'>): boolean {
  // Check if the user is an institution account
  // Or Check if the user is an institution member (wholesale authorized user)
  if (user.institution?.accountType === 'wholesale-buyer' || user.isInstitutionAuthorizedUser) {
    return true
  }

  // If neither condition is met, the user is considered non-wholesale
  return false
}

/** Will format the catalog for display*/
export function formatCatalog(catalog: DefaultCatalog | undefined = DefaultCatalog.Retail) {
  return catalog === DefaultCatalog.WholesaleRetail ? 'Retail & Wholesale' : capitalize(catalog)
}
