import { globalStyles } from '@/constants/Styles'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useDeviceSize } from '@/hooks/useLayout'
import { SafeAreaView, Skeleton } from '@elements'
import { ProductListSkeleton } from '@screens/Shopping/FarmShop/components'
import { memo } from 'react'
import { View } from 'react-native'

export const FarmDetailsSkeleton = memo(function FarmDetailsSkeleton() {
  const styles = useStyles()
  const layout = useDeviceSize()

  return (
    <SafeAreaView style={globalStyles.flex1}>
      <View style={styles.main}>
        <View style={styles.mediaCarouselCont}>
          <View style={styles.mediaCarousel}>
            <Skeleton style={globalStyles.flex1} />
          </View>
          <View style={styles.headerContent}>
            <Skeleton />
            <Skeleton style={styles.mediumSkeleton} />
            <Skeleton />
            <Skeleton />
          </View>
        </View>
        <View style={styles.contentCont}>
          <Skeleton style={styles.aboutSkeleton} />
          <Skeleton />
          <ProductListSkeleton itemsNo={layout.isLargeDevice ? 10 : 4} />
        </View>
      </View>
    </SafeAreaView>
  )
})

const useStyles = () =>
  useSizeFnStyles(({ isLargeDevice }) => ({
    main: {
      maxWidth: 1200,
      flex: 1,
      alignSelf: 'center',
      width: '100%',
    },
    mediaCarousel: {
      flexBasis: isLargeDevice ? 500 : 300,
      flexGrow: 1,
      flexShrink: 1,
      aspectRatio: 4 / 3,
      maxHeight: 400,
    },
    mediaCarouselCont: {
      padding: isLargeDevice ? 20 : 0,
      flexDirection: isLargeDevice ? 'row' : 'column',
    },
    headerContent: {
      // flex props will apply when alignment is row
      flexBasis: isLargeDevice ? 500 : undefined,
      flexGrow: isLargeDevice ? 2 : undefined,
      flexShrink: isLargeDevice ? 1 : undefined,
      padding: 20,
      gap: 15,
    },
    smallSkeleton: {
      height: 30,
    },
    mediumSkeleton: {
      height: 80,
    },
    aboutSkeleton: {
      width: 120,
      height: 25,
    },
    contentCont: {
      padding: 20,
      gap: 15,
      flex: 1,
    },
  }))
