import { MoneyCalc } from '@helpers/money'
import { Money } from '@models/Money'
import { useCallback } from 'react'
import { InputProps } from 'react-native-elements'
import { MaskedInput } from './MaskedInput'
import Masks from './Masks'

/**
 * @param useCustomInput Specifies if the mask should use the CustomInput component instead of defalt Input
 */
export type MoneyInputProps = Omit<InputProps, 'onChangeText' | 'value'> & {
  value?: Money
  onChangeText?: (value?: Money) => void
  useCustomInput?: boolean
}

/** MoneyInput is a wrapper around our input component that will format the UI to display it as a $ amount instead of cents */
export function MoneyInput({ value, onChangeText, ...props }: MoneyInputProps) {
  // Convert the money to a string or leave blank if undefined
  const valueMasked = value ? MoneyCalc.cents(value).toString() : undefined

  // Convert the string value back to a money object to be returned
  const onChangeTextMask = useCallback(
    (_: string, unmasked: string) => {
      const numVal = parseInt(unmasked, 10)
      if (onChangeText) {
        // If it is not a number than set the input to empty, or if it is 00 meaning empty. If it is truly a 0 then the string will just be 0, so we can safely treat 00 as an empty input, and clear it.
        if (isNaN(numVal) || unmasked === '00') {
          return onChangeText()
        }
        onChangeText(MoneyCalc.fromCents(numVal))
      }
    },
    [onChangeText],
  )

  return (
    <MaskedInput
      mask={Masks.MONEY_MASK(valueMasked?.length ?? 0)}
      value={valueMasked ?? ''}
      onChangeText={onChangeTextMask}
      placeholder="$0.00"
      keyboardType="decimal-pad"
      {...props}
    />
  )
}
