import env from '../../../../config/Environment'

const EPROTECT_BASE_URL =
  env.APP_ENV === 'prod' ? 'https://request.eprotect.vantivcnp.com/' : 'https://request.eprotect.vantivprelive.com/'
export const EPROTECT_URL = `${EPROTECT_BASE_URL}eProtect/js/eProtect-iframe-client4.min.js`

const BASE_CONFIG = {
  paypageId: env.EPROTECT_PAYPAGEID,
  style: 'grownbyebtstyles1',
  reportGroup: '*merchant1500',
  timeout: '30000',
  div: 'payframe',
  // this is overridden in the request to the iFrame
  callback: null,
  htmlTimeout: '30000',

  placeholderText: {
    accountNumber: 'EBT SNAP PAN',
    pin: 'EBT SNAP PIN',
  },
  minPanLength: '4',
  enhancedUxFeatures: {
    /** This is disabled because it will cause an account validation error with ebt cards that are not mod10 like: 6004868739125867974 */
    // inlineFieldValidations: true,
  },
}

// For EBT payments we require a pin reentry on checkout
export const EPROTECT_PIN = {
  ...BASE_CONFIG,
  checkoutPinMode: true,
}
// This allows a customer to add their card and they do not need to enter a PIN if not on checkout
export const EPROTECT_PAN = BASE_CONFIG

// This will allow a customer to add their card with pin and number and checkout immediately without requiring a new pin
export const EPROTECT_PIN_PAN = {
  ...BASE_CONFIG,
  checkoutCombinedMode: true,
}
