/* eslint-disable react/no-this-in-sfc */

import {
  BodyProps,
  DateTimePickerForm,
  DEPRECATED_FormMoneyInput,
  ErrorText,
  MaskedInput,
  MaskedInputProps,
  MoneyInput,
  MoneyInputProps,
  Text,
  TextH2,
  typography,
  ToolTips,
  Tooltip,
} from '@elements'
import { objToStr } from '@helpers/log'
import { ReactElement, useMemo } from 'react'
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native'
import { Input, InputProps } from 'react-native-elements'
import { TextInputMask, TextInputMaskProps } from 'react-native-masked-text'

import Colors from '../constants/Colors'
import { getDeviceSize } from '../constants/Layout'
import { globalStyles } from '../constants/Styles'
import { useLayout } from '../hooks/useLayout'
import { Divider } from './elements/Divider'
import { NumberInput } from './elements/MaskedInput/NumberInput'

type FBElProps = {
  label?: string
  errorMessage?: string
  placeholder?: string
  type?: TextInputMaskProps['type'] | BodyProps['type']
  rightIcon?: MoneyInputProps['rightIcon'] | InputProps['rightIcon']
  onChangeText?: MoneyInputProps['onChangeText'] | TextInputMaskProps['onChangeText']
  mask?: MaskedInputProps['mask']
  style?: TextStyle //currently used only for an error text style
}

type FormBuilderElement = null | false | ReactElement<FBElProps>

type FormBuilderProps = {
  style?: StyleProp<ViewStyle>
  children: FormBuilderElement[] | FormBuilderElement
  label?: string
  id?: ToolTips
  row?: boolean
}

//FIXME: isLabelAccessible is being used to determine if something can be rendered as a JSX element. It does not sufficiently prevent a non-element from being rendered as an element
const isLabelAccessible = (value: any): boolean => !value?.props?.label || typeof value.props.label === 'string'

/** @deprecated */
export function FormBuilder({ style, label, id, children, row }: FormBuilderProps) {
  const layout = useLayout()
  const childrenArray = Array.isArray(children) ? children : [children]

  const styleMemo = useMemo<Record<string, StyleProp<ViewStyle | TextStyle>>>(
    () => ({
      mergeStyle: [
        row && !layout.isSmallDevice ? { flexDirection: 'row', flex: 1 } : { flexDirection: 'column' },
        style,
      ],
    }),
    [objToStr(style), row, layout.isSmallDevice],
  )

  const styleFn = useMemo(() => {
    const styleObj = {
      rowStyle: row ? styles.formInputSmall : styles.inputContainer,
      errorStyle: (value: ReactElement<FBElProps>): StyleProp<ViewStyle> => ({
        borderColor: value?.props?.errorMessage ? Colors.red : Colors.shades['100'],
      }),
      containerPadding(value: ReactElement<FBElProps>): StyleProp<ViewStyle> {
        return [this.rowStyle, this.errorStyle(value), globalStyles.padding10]
      },
      containerMargin(value: ReactElement<FBElProps>): StyleProp<ViewStyle> {
        return [this.rowStyle, this.errorStyle(value), { marginTop: 8 }]
      },
    }
    return styleObj
  }, [row])

  /** FIXME: Some component types are not being handled in deviceSize(layout.width) <= 0. [TextInputMask, AutoCompleteInput] */
  return getDeviceSize(layout.width) > 0 ? (
    <View style={styleMemo.mergeStyle}>
      {!!label && (
        <>
          <View style={globalStyles.flexRowCenter}>
            <TextH2>{label}</TextH2>
            {id ? <Tooltip title={label} id={id} style={{ marginLeft: 10 }} /> : <></>}
          </View>
          <Divider clear />
        </>
      )}
      {childrenArray.map((value, index) => {
        // Allow us to pass conditionals in and they will be ignored if false
        if (!value) return

        if (value?.type === TextInputMask) {
          const { type, onChangeText, ...props } = value.props
          return (
            <View style={row ? { maxWidth: 300, width: layout.width / 4 } : { marginHorizontal: 10 }} key={index}>
              <Text style={styles.inputLabel}>{value.props.label || value.props.placeholder}</Text>
              <View style={[row ? styles.formInputSmall : styles.inputContainer, { marginBottom: 10 }]}>
                <TextInputMask
                  key={index}
                  style={styles.maskInputStyle}
                  onChangeText={onChangeText as TextInputMaskProps['onChangeText']}
                  type={(type as TextInputMaskProps['type']) ?? 'custom'}
                  {...props}
                  placeholderTextColor="#86939e"
                />
              </View>
            </View>
          )
        } else if (value?.type === ErrorText) {
          const { type, ...props } = value.props
          return (
            <ErrorText
              {...props}
              type={type as BodyProps['type']}
              style={[value.props.style, { paddingHorizontal: 15, marginTop: -5, marginBottom: 5 }]}
              key={index}
            />
          )
        } else if (value.type === DateTimePickerForm) {
          return (
            <View style={styles.elementContainer} key={index}>
              {isLabelAccessible(value.props.label) ? (
                <Text style={styles.inputLabel}>{value.props.label || ''}</Text>
              ) : (
                value.props.label
              )}
              <View>{value}</View>
              {!!value.props.errorMessage && <ErrorText>{value.props.errorMessage}</ErrorText>}
            </View>
          )
        } else if (value?.type === MaskedInput) {
          const { onChangeText, mask, rightIcon, ...props } = value.props
          return (
            <MaskedInput
              key={index}
              placeholderTextColor={Colors.shades['200']}
              errorStyle={styles.error}
              label={value.props.label || value.props.placeholder}
              accessibilityLabel={isLabelAccessible(value) ? value.props.label : value.props.placeholder}
              labelStyle={styles.inputLabel}
              inputStyle={[styles.inputStyle, row && { width: '100%' }]}
              inputContainerStyle={styleFn.containerMargin(value)}
              containerStyle={row ? globalStyles.flex1 : {}}
              rightIcon={rightIcon}
              onChangeText={onChangeText as MaskedInputProps['onChangeText']}
              mask={mask}
              {...props}
            />
          )
        } else if (
          value?.type === DEPRECATED_FormMoneyInput ||
          value?.type === MoneyInput ||
          value?.type === NumberInput
        ) {
          const Comp = value.type
          return (
            <Comp
              key={index}
              placeholderTextColor={Colors.shades['200']}
              errorStyle={styles.error}
              label={value.props.label || value.props.placeholder}
              accessibilityLabel={isLabelAccessible(value) ? value.props.label : value.props.placeholder}
              labelStyle={styles.inputLabel}
              inputStyle={[styles.inputStyle, row && { width: '100%' }]}
              inputContainerStyle={styleFn.containerMargin(value)}
              containerStyle={row ? globalStyles.flex1 : {}}
              rightIcon={value.props.rightIcon ? value.props.rightIcon : null}
              {...value.props}
            />
          )
        } else if (value?.props?.onChangeText) {
          const { rightIcon, onChangeText, ...props } = value.props
          return (
            <Input
              key={index}
              placeholderTextColor={Colors.shades['200']}
              errorStyle={styles.error}
              label={value.props.label || value.props.placeholder}
              accessibilityLabel={isLabelAccessible(value) ? value.props.label : value.props.placeholder}
              labelStyle={styles.inputLabel}
              inputStyle={[styles.inputStyle, row && { width: '100%' }]}
              inputContainerStyle={styleFn.containerMargin(value)}
              containerStyle={row ? globalStyles.flex1 : {}}
              rightIcon={rightIcon as InputProps['rightIcon']}
              onChangeText={onChangeText as InputProps['onChangeText']}
              {...props}
            />
          )
        }
        // Allows us to pass non inputs to FormBuilder and it will ignore them
        else return value
      })}
    </View>
  ) : (
    // Mobile View
    <View style={styleMemo.mergeStyle}>
      {label ? (
        <>
          <Text>{label}</Text>
          <Divider clear />
        </>
      ) : null}
      {childrenArray.map((value, index) => {
        // Allow us to pass conditionals in and they will be ignored if false
        if (!value) return

        if (value?.type === DateTimePickerForm)
          return (
            <View style={styles.elementContainer} key={index}>
              {isLabelAccessible(value.props.label) ? (
                <Text style={styles.inputLabel}>{value.props.label || ''}</Text>
              ) : (
                //FIXME: This may crash the app if the value is not a JSX element
                value.props.label
              )}
              <View>{value}</View>
              {!!value.props.errorMessage && <ErrorText>{value.props.errorMessage}</ErrorText>}
            </View>
          )
        else if (value?.type === MaskedInput) {
          const { onChangeText, mask, ...props } = value.props
          return (
            <MaskedInput
              key={index}
              accessibilityLabel={isLabelAccessible(value) ? value.props.label : value.props.placeholder}
              placeholderTextColor={Colors.shades['200']}
              inputContainerStyle={row ? styles.formInputSmall : styles.inputContainer}
              inputStyle={[styles.inputStyle, row && { width: '100%' }]}
              errorStyle={styles.error}
              onChangeText={onChangeText as MaskedInputProps['onChangeText']}
              mask={mask}
              {...props}
            />
          )
        } else if (
          value?.type === DEPRECATED_FormMoneyInput ||
          value?.type === MoneyInput ||
          value?.type === NumberInput
        ) {
          const Comp = value.type
          return (
            <Comp
              key={index}
              accessibilityLabel={isLabelAccessible(value) ? value.props.label : value.props.placeholder}
              placeholderTextColor={Colors.shades['200']}
              inputContainerStyle={row ? styles.formInputSmall : styles.inputContainer}
              inputStyle={[styles.inputStyle, row && { width: '100%' }]}
              errorStyle={styles.error}
              {...value.props}
            />
          )
        } else if (value?.props?.onChangeText) {
          const { onChangeText, ...props } = value.props
          return (
            <Input
              key={index}
              accessibilityLabel={isLabelAccessible(value) ? value.props.label : value.props.placeholder}
              placeholderTextColor={Colors.shades['200']}
              inputContainerStyle={styleFn.containerMargin(value)}
              inputStyle={[styles.inputStyle, row && { width: '100%' }]}
              errorStyle={styles.error}
              onChangeText={onChangeText as InputProps['onChangeText']}
              {...props}
            />
          )
        }
        // Allows us to pass non inputs to FormBuilder and it will ignore them
        else return value
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  elementContainer: { margin: 10, marginTop: 0, flex: 1 },
  inputLabel: {
    fontSize: 16,
    fontWeight: undefined,
    color: Colors.shades[600],
    fontFamily: typography.body.regular,
    margin: 3,
  },
  inputContainer: {
    borderColor: Colors.shades['100'],
    borderWidth: 1,
    borderRadius: 10,
  },
  formInputSmall: {
    borderColor: Colors.shades['100'],
    borderWidth: 1,
    borderRadius: 10,
  },
  inputStyle: {
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  maskInputStyle: {
    fontSize: 18,
    flex: 1,
    minHeight: 40,
    paddingHorizontal: 10,
  },
  error: {
    color: Colors.red,
    fontFamily: typography.body.medium,
  },
})
