import { Alert } from '@elements'
import { RouteProp } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useEffect, useState } from 'react'
import { ActivityIndicator, View, ViewStyle } from 'react-native'
import { WebViewProgressEvent } from 'react-native-webview/lib/WebViewTypes'

import WebView from '../../components/elements/WebView/WebView'
import Colors from '../../constants/Colors'
import { Layout } from '../../constants/types'
import { useLayout } from '../../hooks/useLayout'
import { OrdersParamList } from '../../navigation/types'

interface Props {
  route: RouteProp<OrdersParamList, 'ViewInvoice'>
  navigation: StackNavigationProp<OrdersParamList, 'ViewInvoice'>
}
export default function ViewInvoice({ route, navigation }: Props) {
  const { title, url } = route.params
  const [progress, setProgress] = useState(0)
  const layout = useLayout()

  useEffect(() => {
    navigation.setOptions({ title })
  }, [title, navigation])

  useEffect(() => {
    if (!url || url.length < 10) {
      navigation.goBack()
      Alert('There was an error loading this URL, please contact support')
    }
  }, [url, navigation])

  const loadProgress = (evt: WebViewProgressEvent) => {
    setProgress(evt.nativeEvent.progress)
  }
  if (!url || url.length < 10) return null
  return (
    <>
      <WebView source={{ uri: url }} onLoadProgress={loadProgress} />
      {progress < 1 && (
        <View style={progCont(layout)}>
          <ActivityIndicator color={Colors.green} size="large" />
        </View>
      )}
    </>
  )
}

const progCont = (layout: Layout): ViewStyle => ({
  position: 'absolute',
  backgroundColor: Colors.transparent,
  width: layout.width,
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
})
