import { Farm } from '@models/Farm'
import { PaymentForms } from '@models/PaymentMethod'

import { callEndpoint } from './v2'
import env from '../config/Environment'

type StripePayment = {
  type: PaymentForms
  number?: string
  cvc?: string
  exp_month?: string
  exp_year?: string
  routing_number?: string
  account_number?: string
  country?: 'us'
}

type StripeTokenObj = {
  id: string
  object: string
  card: {
    id: string
    object: 'card'
    brand: 'American Express' | 'Diners Club' | 'Discover' | 'JCB' | 'MasterCard' | 'UnionPay' | 'Visa' | 'Unknown'
    country: 'US'
    cvc_check: 'pass'
    exp_month: number
    exp_year: number
    fingerprint: string
    funding: 'credit' | 'debit' | 'prepaid' | 'unknown'
    last4: string
    metadata: object
    name: null
  }
  created: string
  type: 'card'
}

const STRIPE_URL = 'https://api.stripe.com/v1/'

const STRIPE_KEY = env.STRIPE_KEY

export const createToken = async (details: StripePayment): Promise<StripeTokenObj & { error?: any }> => {
  const newDetails = convertDetails(details.type, details)
  const token: any = await stripeCall('tokens', newDetails)
  return await token.json()
}

function convertDetails(type: string, details: any) {
  delete details.type
  const convertedDetails: any = {}
  for (const data in details) {
    const string = type + '[' + data + ']'
    if (details.hasOwnProperty(data)) convertedDetails[string] = details[data]
  }
  return convertedDetails
}

// Call stripe and request a token to make a card
function stripeCall(service: RequestInfo, params: { [key: string]: any }, bearerKey = STRIPE_KEY): Promise<Response> {
  return fetch(STRIPE_URL + service, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Bearer ' + bearerKey,
    },
    body: encodeFormUrl(params),
  })
}

// Will encode the data to url format
const encodeFormUrl = (data: any) => {
  const params = new URLSearchParams(data)
  return params.toString()
}

export const createStripeAccountHelper = async (farm: Farm) => {
  return await callEndpoint('v2.Stripe.createStripeAccountService', farm)
}
