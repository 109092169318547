import { ShortZip } from '@models/Address'
import * as yup from 'yup'
import { listStateAbbr, ShortState } from '../../../../assets/data/states'

export const ShortZipSchema = yup
  .string<ShortZip>()
  // Start at the first character in the string (^), it should match exactly 5 numeric digits, and then the end of the string ($)
  .matches(/^\d{5}$/, 'Must be a 5-digit zip code')
  .required()

export const ShortStateSchema = yup
  .mixed<ShortState>()
  .oneOf(listStateAbbr(), 'State code must be a valid state')
  .required()
