// WARNING: For future refators, these two imports must always be the first import of the entry file. If this entry file is ever removed in a refactor or restructuring for future upgrades, which ever is/are the new entry file/s should still import this as the very first thing.
// WARNING: During handling of this file, special attention should be placed to prevent auto-import from modifying the import order of these imports. (IT HAS HAPPENED SO DON'T DELETE THIS WARNING)

import 'react-native-gesture-handler' //react-native-gesture-handler must be the first import of the entry file, according to react navigation docs https://reactnavigation.org/docs/stack-navigator/#installation and also expo docs https://docs.expo.dev/versions/latest/sdk/gesture-handler/#api, WARNING: If you delete these comments inline, import reordering might kick in. With these comments, it seems to no longer do the auto reordering in my IDE on save.
import 'polyfill-object.fromentries' // This must go AFTER gesture-handler import. Don't remove this inline comment, as it may prevent IDEs from doing auto import reordering on save.
import './shared/YupCustomMethods' // This must be included so that YupCustomMethods are added to the Yup global object
/**
 * This file may need to be redesigned/ or removed, since it makes no sense to have. We created it out of necessity when custom entry points no longer worked for a past expo version. Now they work again. https://docs.expo.dev/versions/latest/sdk/register-root-component/#what-if-i-want-to-name-my-main-app-file-something-other-than-appjs and https://docs.expo.dev/build-reference/migrating/#custom--main--entry-point-in . The OLD reference is no longer applicable (https://docs.expo.dev/versions/latest/sdk/register-root-component/)
 */
import { registerRootComponent } from 'expo'

/**
 * Our solution for app switching is implemented inside the AppSwitcher app; So this layer doesn't have any important or necessary logic or code; which is why this file may be best removed unless we later find a way to handle the app swithing here somehow. There is no obvious benefit to that though.
 * - Unfortunately env variables don't work here. So you can't registerRootComponent(CompNavigator) only if env.IS_COMP === true; this is why we no longer handle the app switching at this layer.
 * - You also can't console.log here, so you can't debug any value here at runtime.
 */
import { AppSwitcher } from './App'

registerRootComponent(AppSwitcher)

// For simple testing purposes, you may enable registering a specific component instead of the default one
// import CompNavigator from './components/ComponentNavigator/App'
// registerRootComponent(CompNavigator)
