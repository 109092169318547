import { MenuExposed, MenuProvided } from 'react-instantsearch-core'
import { connectMenu } from 'react-instantsearch-native'
import { ViewStyle } from 'react-native'

import OfflineRefinementDropdown from '../OfflineTable/OfflineRefinementDropdown'

import { useFocusFx } from '@/hooks/useFocusFx'
import { PickerProps } from '@elements'
import { sortByName } from '@helpers/sorting'

export const defaultRefinementLimit = 15

/** These are the props that can be passed to the connected component from the outside */
export type RefinementDropdownExternalProps = MenuExposed & {
  /** the placeholder will show on the UI when there is no current refinement */
  placeholder: string
  /** style for the picker container */
  style?: ViewStyle
  /** formats the refinement item label */
  format?: (label: string) => string
  /** Whether the dropdown should use the showMoreLimit instead of the default limit */
  setShowMore?: (showMore: boolean) => void
  /** will be called when a default refinement is applied */
  onDefaultRefinementApplied?: () => void
}

/** These are the props that will be received inside the component */
export type RefinementDropdownProps = MenuProvided & RefinementDropdownExternalProps

const SHOW_MORE = '<<Show More>>'

function RefinementDropdown({
  items,
  refine,
  placeholder,
  style,
  format,
  setShowMore,
  showMore,
  defaultRefinement,
  currentRefinement,
  onDefaultRefinementApplied,
}: RefinementDropdownProps) {
  /** This must apply the default refinement when it changes, because the default refinement will only be applied the first time the component renders. And sometimes we navigate to the screen with different navigation parameters, so we want the default refinement to apply the new value every time we navigate */
  useFocusFx(() => {
    if (defaultRefinement) {
      refine(defaultRefinement)
      onDefaultRefinementApplied?.()
    }
    // Only meant to run on change to defaultRefinement
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultRefinement])

  const refineSelection = (val: string) => {
    if (val === SHOW_MORE) {
      return setShowMore?.(true)
    }
    if (val === placeholder) {
      refine() // means un-refine)
    } else refine(val)
  }

  let dropdownItems: PickerProps['items'] = items.reduce((arr, itm) => {
    // Don't include duplicates
    if (arr.find((existing) => existing.value === itm.value)) return arr

    const formattedLabel = format ? format(itm.label) : itm.label

    return [
      ...arr,
      {
        label: `${formattedLabel} (${itm.count})`,
        value: itm.label,
      },
    ]
  }, [] as PickerProps['items'])

  dropdownItems.sort((a, b) => sortByName(a, b, (itm) => itm.label ?? itm.value ?? ''))

  if (!showMore && items.length >= defaultRefinementLimit) {
    dropdownItems = dropdownItems.concat([{ label: SHOW_MORE, value: SHOW_MORE }])
  }

  return (
    <OfflineRefinementDropdown
      style={style}
      placeholder={placeholder}
      items={dropdownItems}
      onValueChange={refineSelection}
      value={currentRefinement}
    />
  )
}

export default connectMenu(RefinementDropdown)
