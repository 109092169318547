import { objToStr } from '@helpers/log'
import * as React from 'react'
import { memo, ReactNode, useMemo } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import Colors from '../constants/Colors'
import { SHADOW_10, SHADOW_2 } from '../constants/Styles'
import { Touchable, TouchableProps } from './elements/Touchable'
import { Image, ResizedSuffix } from './Image'

import { useDeviceSize } from '@/hooks/useLayout'

export type ImageCardProps = {
  imageUri?: string
  children: React.ReactNode | React.ReactNode[]
  contentContainerStyle?: StyleProp<ViewStyle>
  small?: boolean
  tallImg?: boolean
  /** When this action has an element that will use navigation, it must not use urls if there's already a url passed to the main ImageCard. Otherwise it creates a nested html <a> tag, which produces an invalid html error */
  action?: ReactNode
} & Omit<TouchableProps, 'children'>

export const ImageCard = memo(function ImageCard({
  imageUri,
  style,
  contentContainerStyle,
  children,
  small,
  tallImg,
  action,
  ...rest
}: ImageCardProps) {
  const { isSmallDevice } = useDeviceSize()

  const imageUriProp = useMemo(() => ({ uri: imageUri }), [imageUri])
  const imageStyle = useMemo(() => [styles.cardImg, small && styles.compact, tallImg && styles.tall], [small, tallImg])
  const touchableStyle = useMemo(
    () => [styles.cardCont, isSmallDevice && styles.cardSmall, style],
    [objToStr(StyleSheet.flatten(style))],
  )
  const childrenContainerStyle = useMemo(
    () => [styles.cardContent, contentContainerStyle],
    [objToStr(StyleSheet.flatten(contentContainerStyle))],
  )
  const actionEl = useMemo(() => (action ? <View style={styles.actionEl}>{action}</View> : null), [action])

  return (
    <Touchable style={touchableStyle} {...rest}>
      <Image source={imageUriProp} resizeSuffix={ResizedSuffix.THUMB} style={imageStyle} />
      <View style={childrenContainerStyle}>{children}</View>
      {actionEl}
    </Touchable>
  )
})
export default ImageCard

const styles = StyleSheet.create({
  cardCont: {
    ...SHADOW_10,
    margin: 15,
    borderRadius: 10,
    backgroundColor: Colors.white,
  },
  cardSmall: {
    ...SHADOW_2,
    margin: 5,
  },
  cardImg: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    width: '100%',
    height: 140,
  },
  compact: {
    height: 120,
  },
  tall: {
    height: 180,
  },
  cardContent: {
    zIndex: 1,
    padding: 10,
  },
  actionEl: {
    margin: 10,
    marginTop: 0,
    zIndex: 1,
    position: 'relative',
  },
})
