import { useCoords } from '@/hooks/useCoords/useCoords'
import { useFocusFx } from '@/hooks/useFocusFx'
import { HomeParamList, SearchScreenParams } from '@/navigation/types'
import { getParsedAddressFromCoords } from '@api/Addresses'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import { LocType } from '../searchScreen-helpers'

/** Will set a default region to params once if the locType is NonPickup and no region is defined */
export function useSetInitialRegion(
  locType: LocType | undefined,
  regionParam: SearchScreenParams['region'] | undefined,
) {
  const navigation = useNavigation<NavigationProp<HomeParamList, 'SearchScreen'>>()
  const coords = useCoords()

  useFocusFx(
    () => {
      if (locType === 'coord' || !!regionParam || !coords) return
      getParsedAddressFromCoords(coords).then((parser) => {
        const addr = parser.getAddress()
        const region = locType === 'state' ? addr.state : locType === 'zip' ? addr.zipcode : undefined
        if (!region) return
        navigation.setParams({ region })
      })
    },
    [navigation, regionParam, locType, coords],
    { noRefocus: true, once: true },
  )
}
