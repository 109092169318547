import { Column, ExpandableRow } from '@/admin/components/OfflineTable/ExpandableRow'
import { Logger } from '@/config/logger'
import Colors from '@/constants/Colors'
import { isWeb } from '@/constants/Layout'
import { useDeviceSize } from '@/hooks/useLayout'
import { adminFarmSelector } from '@/redux/selectors'
import {
  CategoryWithCount,
  canDeleteLocalCategory,
  deleteLocalCategoryFromFarm,
  editLocalCategory,
} from '@api/Categories'
import { Alert, Prompt, Text, Toast, Touchable } from '@elements'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import React, { memo, useCallback, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

type RowProps = {
  categoryRecord: Record<string, CategoryWithCount>
  index: number
}

export const CategoryRow = memo(function CategoryRow({ categoryRecord, index }: RowProps) {
  const farm = useSelector(adminFarmSelector)
  const [loading, setLoading] = useState(false) // Add loading state
  const categoryName = Object.keys(categoryRecord)[0]
  const productCount = categoryRecord[categoryName].count
  const { isSmallDevice } = useDeviceSize()
  const handleEditPress = useCallback(async () => {
    Prompt('Edit Category', {
      onSubmit: async (value) => {
        if (value && value !== categoryName) {
          try {
            setLoading(true)
            await editLocalCategory({ farmId: farm.id, oldCategoryName: categoryName, newCategoryName: value })
            Toast('Category edited successfully')
          } catch (error) {
            Logger.error(error)
            Toast('Something went wrong while editing this category')
          } finally {
            setLoading(false)
          }
        }
      },
      inputProps: { placeholder: 'Edit category name here', defaultValue: categoryName },
    })
  }, [farm.id, categoryName])

  const handleDeletePress = useCallback(async () => {
    Alert('Delete Category', 'Are you sure you want to delete this Category?', [
      {
        text: 'Delete',
        onPress: async () => {
          try {
            setLoading(true)
            const canDelete = await canDeleteLocalCategory(farm.id, categoryName)
            if (canDelete) {
              await deleteLocalCategoryFromFarm(farm.id, categoryName)
              Toast('Category deleted successfully')
            } else {
              Toast('This Category cannot be deleted because it has a product associated with it.')
            }
          } catch (error) {
            Logger.error(error)
            Toast('Something went wrong while deleting this category')
          } finally {
            setLoading(false)
          }
        },
      },
      {
        text: 'Cancel',
        style: 'cancel',
      },
    ])
  }, [farm.id, categoryName])

  const columns: Column<Record<string, CategoryWithCount>>[] = [
    {
      process: () => (
        <Text numberOfLines={2} onPress={handleEditPress}>
          {categoryName}
        </Text>
      ),
      widthFlex: 2,
    },
    {
      process: () => <Text>{productCount}</Text>,
      widthFlex: 1.5,
    },
    {
      process: (category: Record<string, CategoryWithCount>) => (
        <View>
          {category[categoryName].isDefault ? (
            <Text>{isWeb && !isSmallDevice ? 'GrownBy Category' : 'Default'}</Text>
          ) : (
            <Touchable onPress={handleEditPress} style={styles.rowBtnCont} disabled={loading}>
              <Text type="medium" color={Colors.green}>
                Edit
              </Text>
            </Touchable>
          )}
        </View>
      ),
      widthFlex: 0.5,
    },
    {
      widthFlex: 0.5,
      process: () => (
        <Touchable onPress={handleDeletePress} style={styles.rowBtnCont}>
          <Text type="medium" color={Colors.green}>
            Delete
          </Text>
        </Touchable>
      ),
    },
  ]

  return (
    <ExpandableRow<Record<string, CategoryWithCount>>
      item={categoryRecord}
      index={index}
      columns={columns}
      rowContainerStyle={styles.row}
    />
  )
}, propsAreDeepEqual)

const styles = StyleSheet.create({
  row: {
    borderBottomWidth: 0,
    backgroundColor: Colors.white,
    alignItems: 'center',
  },
  rowBtnCont: {
    alignItems: 'center',
    minWidth: 18,
  },
})
