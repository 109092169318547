import { HeaderText, Spinner } from '@elements'
import { formatMoney } from '@helpers/display'
import { useEffect, useState } from 'react'
import { Dimensions, ScrollView, View } from 'react-native'
import { useSelector } from 'react-redux'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import { fetchAnalytics } from '@api/Analytics'
import { Permission } from '@helpers/Permission'
import { useLayout } from '../../hooks/useLayout'
import { withAdminAuth } from '../../hooks/withAdminAuth'
import { adminFarmSelector } from '../../redux/selectors'
import { AdminView } from '../components/AdminView'
import { DatePicker, DateRangeNames } from '../components/Analytics/DatePicker'
import LineGraph from '../components/Analytics/LineGraph'
import PieGraph from '../components/Analytics/PieGraph'
import { StatCard } from '../components/Analytics/StatCard'
import { getYearToDate } from '../components/Analytics/util'

function AdminAnalyticsScreen() {
  const farm = useSelector(adminFarmSelector)
  const layout = useLayout()

  const [newCustomers, setNewCustomers] = useState([])
  const [dailyRevenue, setRevenue] = useState([])
  const [dailyOrders, setOrders] = useState([])
  const [csaSales, setCsaSales] = useState([])
  const [salesPerLocation, setSalesPerLocation] = useState([])

  const [totalCustomers, setTotalCustomers] = useState(0)
  const [avgCustomerSpend, setAvgCustomerSpend] = useState(0)
  const [shopViews, setShopViews] = useState([])

  const [date, setDate] = useState({
    label: DateRangeNames.This_Year,
    value: getYearToDate(),
  })

  const [loading, setLoading] = useState(false)
  const styles = useStyles()
  useEffect(() => {
    setLoading(true)
    fetchAnalytics('analytics', date.value, farm.id, farm.timezone)
      .then((res) => {
        setNewCustomers(res.farmCustomers)
        setOrders(res.farmOrders)
        setAvgCustomerSpend(Math.round(res.avgCustomerSpend[0].value))
        setTotalCustomers(res.totalCustomers[0].value)
        setRevenue(res.farmRevenue)
        setCsaSales(res.csaSales)
        setSalesPerLocation(res.salesPerLocation)
        setShopViews(res.farmShopViews)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [date, farm.id, farm.timezone])

  return (
    <AdminView>
      <View style={styles.wrapper}>
        <HeaderText size={30}>Analytics</HeaderText>
        <DatePicker
          date={date}
          timezone={farm.timezone}
          setDate={(name, date) => setDate({ label: name, value: date })}
        />
        <View style={styles.spacing} />
        {loading ? (
          <View
            style={{
              height: Dimensions.get('window').height / 1.5,
              justifyContent: 'center',
            }}
          >
            <Spinner size="large" color="#6DBE4B" />
          </View>
        ) : (
          <View>
            <ScrollView horizontal contentContainerStyle={{ flexGrow: 1 }}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-around', flex: 1 }}>
                <StatCard title="Average Customer Spend" value={formatMoney(avgCustomerSpend)} />
                <StatCard title="Total Customers" value={totalCustomers} />
              </View>
            </ScrollView>
            <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
              <LineGraph data={dailyOrders} title="Orders" hasPastData dateFormat={date.value.dateTrunc} />
              <LineGraph data={shopViews} title="Shop Views" hasPastData dateFormat={date.value.dateTrunc} />
            </View>
            <View style={{ flexDirection: layout.width >= 896 ? 'row' : 'column', justifyContent: 'space-evenly' }}>
              <LineGraph data={newCustomers} title="Customers" hasPastData dateFormat={date.value.dateTrunc} />
              <LineGraph data={dailyRevenue} title="Revenue" hasPastData isRevenue dateFormat={date.value.dateTrunc} />
            </View>
            <View style={{ flexDirection: layout.width >= 896 ? 'row' : 'column', justifyContent: 'space-evenly' }}>
              <PieGraph data={csaSales} title="CSA Sales" />
              <PieGraph data={salesPerLocation} title="Sales Per Location" showTooltip />
            </View>
          </View>
        )}
      </View>
    </AdminView>
  )
}

export default withAdminAuth(AdminAnalyticsScreen, Permission.AccountSetup)

const useStyles = CreateResponsiveStyle(
  {
    wrapper: {
      margin: 30,
    },

    spacing: {
      height: 10,
    },
  },
  {
    [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      wrapper: {
        margin: 10,
      },
    },
  },
)
