import { Infinite, Money } from '@models/Money'
import { useCallback } from 'react'
import { InputProps } from 'react-native-elements'

import { MoneyInput } from '../MaskedInput/MoneyInput'

type FormMoneyInputProps = Omit<InputProps, 'onChangeText' | 'value'> & {
  value?: Money
  onChangeText?: (value: Money) => void
  useCustomInput?: boolean
}

/**
 * TODO: This was originally built poorly and should not be used anymore there is an issue to remove this input, but that
 * should be done for a larger release so we can test thoroughly
 * @deprecated
 */
export function DEPRECATED_FormMoneyInput({ value, onChangeText, ...props }: FormMoneyInputProps) {
  const onChange = useCallback(
    (value?: Money) => {
      if (onChangeText) {
        onChangeText(value === undefined ? Infinite : value)
      }
    },
    [onChangeText],
  )
  return <MoneyInput value={value?.value === Infinity ? undefined : value} onChangeText={onChange} {...props} />
}
