import { Layout } from '../../../../constants/types'
import { StatusBar, ViewStyle } from 'react-native'
import { SHADOW_10 } from '../../../../constants/Styles'
import Colors from '../../../../constants/Colors'

/** This type is used to position items using absolute styling based on position offsets */
type StylePosition = {
  top: number
  bottom: number
  left: number
  right: number
}

/** This styling helper handles positioning the overlay content on the screen.
 * @default The content will be positioned underneath the source element and aligned to the left of it
 * If the source element is positioned right of 60% of the screen then we show the content aligned to the right of the source
 * If the source element is positioned below 60% of the screen height then we show the content above the source element
 */
export const overlayContentPositioning = (layout: Layout, sourcePos: Position) => {
  // add the status bar height for accurate position on mobile
  const statusBarHeight = StatusBar.currentHeight ?? 0

  // the default position assumes we will have the content below and left aligned
  const position: Partial<StylePosition> = {
    top: sourcePos.top + sourcePos.height + statusBarHeight,
    left: sourcePos.left,
  }

  // Determine if the source element is in the top 60% of the layout.
  const isPositionedInTop60Percent = sourcePos.top < layout.height * 0.6
  // Determine if the source element is in the left 60% of the layout.
  const isPositionedInLeft60Percent = sourcePos.left < layout.screenWidth * 0.6

  // If the source element is in the right 40% of the layout, adjust the position to align it to the right
  if (!isPositionedInLeft60Percent) {
    delete position.left
    position.right = layout.screenWidth - (sourcePos.left + sourcePos.width)
  }

  // If the source element is in the bottom 40% of the layout, adjust the content to render it above the source
  if (!isPositionedInTop60Percent) {
    delete position.top
    position.bottom = layout.height - sourcePos.top
  }

  return position
}
/** Absolute position of an element on the screen */
export type Position = {
  top: number
  left: number
  width: number
  height: number
}
export const initialPos: Position = { top: 0, left: 0, width: 0, height: 0 }

/** Base styles for overlays, and is also used for autocomplete in inline mode */
export const overlayBaseStyle: ViewStyle = {
  ...SHADOW_10,
  borderWidth: 1,
  borderColor: Colors.shades['100'],
  backgroundColor: Colors.white,
  borderRadius: 5,
}
