import { addCSA, loadCSAsByFarm, updateCSA } from '@api/CSAs'
import { uploadImageAsync } from '@api/FirebaseStorage'
import {
  Alert,
  Divider,
  FormInput,
  FormNumberInput,
  FormUndefinedInitialValue,
  HeaderText,
  Text,
  ToggleButton,
  Tooltip,
  typography,
} from '@elements'
import { CSA } from '@models/CSA'
import { Media } from '@models/shared/Media'
import { RouteProp } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Formik, FormikConfig } from 'formik'
import * as React from 'react'
import { useEffect } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { Logger } from '../../../config/logger'
import Colors from '../../../constants/Colors'
import { globalStyles } from '../../../constants/Styles'
import { withAdminAuth } from '../../../hooks/withAdminAuth'
import { setAdminCSAsAction } from '../../../redux/actions/adminState'
import { adminCsasSelector, adminFarmSelector } from '../../../redux/selectors'
import { AdminView } from '../../components/AdminView'
import { AdminDrawerParamList, CSAParamList } from '../../navigation/types'

import FormSectionHeader from '@/admin/screens/Products/components/FormSectionHeader'
import { useDeviceSize } from '@/hooks/useLayout'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize, minSize } from 'rn-responsive-styles'

import InputLabel from '@/admin/components/InputLabel'
import { ToolTips } from '@components'
import { AccessRight, Permission } from '@helpers/Permission'
import { YUP_WHOLE_NUMBER_REAL } from '@helpers/Yup'
import { errorToString } from '@helpers/helpers'
import { omit } from '@helpers/typescript'
import { isErrorWithCode } from '@shared/Errors'
import { CSAPhotoSection } from './components//CSAPhotosSection'
import { CSAHeader } from './components/CSAHeader'
import { CSASuggestedProducts } from './components/CSASuggestedProducts'
import { CSAFormik, ChangeOptionStates } from './components/type'

interface Props {
  route: RouteProp<CSAParamList, 'CSADetail'>
  navigation: StackNavigationProp<CSAParamList, 'CSADetail'>
}

/** Will get the Formik ChangeOptionState from the given CSA change options */
function getChangeOptKeyFromCSA(changeOptions: CSA['changeOptions']): ChangeOptionStates {
  let changeOptionKey: ChangeOptionStates = 'allow-reschedule-and-location'

  if (changeOptions) {
    const { blockLocationSwitching, blockRescheduling } = changeOptions

    if (blockLocationSwitching === true && blockRescheduling === true) {
      changeOptionKey = 'block-all-rescheduling'
    } else if (blockLocationSwitching === true) {
      changeOptionKey = 'allow-reschedule-only'
    }
  }

  return changeOptionKey
}

const FORMIK_TO_CSA_MAPPING: Record<ChangeOptionStates, CSA['changeOptions']> = {
  'allow-reschedule-and-location': undefined,
  'allow-reschedule-only': { blockLocationSwitching: true },
  'block-all-rescheduling': { blockLocationSwitching: true, blockRescheduling: true },
}

/** Will get the CSA change options from the given ChangeOptionStates we use in the form */
function getCSAChangeOptsFromKey(changeOptionsKey: ChangeOptionStates): CSA['changeOptions'] {
  return FORMIK_TO_CSA_MAPPING[changeOptionsKey]
}

/** Generates the initial form data. If csa provided, will generate form data for the csa. Otherwise will generate blank initial data. */
const getInitialCsaFormData = (csa?: CSA): CSAFormik =>
  csa
    ? {
        ...omit(csa, 'farm'),
        changeOptions: getChangeOptKeyFromCSA(csa.changeOptions),
      }
    : {
        id: '',
        name: '',
        images: [],
        shortDescription: '',
        description: '',
        changeWindow: FormUndefinedInitialValue,
        numOfCustomers: 0,
        numOfSharesSold: 0,
        missedPickupMessage: '',
        paymentTypes: {
          offline: true,
          ebt: true,
        },
        changeOptions: 'allow-reschedule-and-location',
        isHidden: false,
        newOrderNote: '',
        urlSafeSlug: '',
      }

function CSADetailScreenComp({ route: { params }, navigation }: Props) {
  const dispatch = useDispatch()
  const { isSmallDevice } = useDeviceSize()
  const sha1 = require('sha1')
  const farm = useSelector(adminFarmSelector)
  const adminCsas = useSelector(adminCsasSelector)
  const styles = useStyles()
  const [isNewImage, setIsNewImage] = React.useState(false)

  const [csa, setCsa] = React.useState<CSAFormik>(getInitialCsaFormData())

  const csaValidationSchema = Yup.object<Pick<CSAFormik, 'name' | 'description' | 'images' | 'changeWindow'>>().shape({
    name: Yup.string().trim().label('Name').required(),
    description: Yup.string().trim().label('Description').required(),
    changeWindow: YUP_WHOLE_NUMBER_REAL('Change Window', { allowZero: true }),
    images: Yup.array<Media>().label('Images').min(1, 'Must upload at least one image').required(),
  })

  /** Find the csa id (If editing) in the admin csas data */
  useEffect(() => {
    if (params?.csaId) {
      const csaToEdit = adminCsas.find((item) => item.id === params?.csaId)
      //FIXME: This should return a 404. this happens when the user manually changes the id in the url to a non-existent one (unlikely)
      if (csaToEdit) {
        setCsa(getInitialCsaFormData(csaToEdit))
      }
    }
  }, [adminCsas, params?.csaId])

  /** Loads and sets the admin csas to redux */
  useEffect(() => {
    if (farm?.id) {
      loadCSAsByFarm(farm.id).then((val) => {
        dispatch(setAdminCSAsAction(val))
      })
    }
  }, [farm?.id, dispatch])

  async function uploadTransform(photos: Media[]): Promise<Media[]> {
    // If the image is already uploaded don't upload the uploaded version which will cause a break in the url
    try {
      return await Promise.all(
        photos.map((m: Media) => {
          // Check if image is already hosted
          if (m.storageUrl.includes('http://') || m.storageUrl.includes('https://')) return m
          else return uploadImageAsync(sha1(m.storageUrl), m)
        }),
      )
    } catch (err) {
      Alert('Error saving images', 'There was an error saving your images. Please try again.')
      Logger.error('Error saving images', err)
      // If there is an error, return the original photos
      return photos.filter((m: Media) => {
        if (m.storageUrl.includes('http://') || m.storageUrl.includes('https://')) return true
      })
    }
  }

  /** goBack should go back to either the csa list screen or the addProduct if that's where the user came from. */
  const goBack = () => {
    if (params?.goBack === 'AddProduct')
      (navigation as unknown as StackNavigationProp<AdminDrawerParamList>).navigate('Products', {
        screen: 'AddProduct',
      })
    else navigation.navigate('CSAGroup')
  }

  const handleSubmitCSA: FormikConfig<CSAFormik>['onSubmit'] = async (formValues: CSAFormik) => {
    if (!farm) return

    try {
      // Also should start using snapshot listener for the admin csas

      const newCsa: CSA = {
        ...formValues,
        changeOptions: getCSAChangeOptsFromKey(formValues.changeOptions),
        farm: { id: farm.id, urlSafeSlug: farm.urlSafeSlug },
      }

      if (!params?.csaId) {
        if (newCsa?.images && newCsa?.images.length > 0) {
          const img = await uploadTransform(newCsa.images)
          newCsa.images = img
        }

        const { id } = await addCSA(newCsa)
        newCsa.id = id
        dispatch(setAdminCSAsAction([...adminCsas, newCsa]))
      } else {
        if (isNewImage) {
          const img = await uploadTransform(newCsa.images)
          newCsa.images = img
        }
        const i = adminCsas.findIndex((singleCSA) => singleCSA.id === newCsa.id)
        adminCsas[i] = newCsa

        /**TODO: When we decide to keep going this feature (Adding product slug to url, then we can return this feature) */
        // let proceed = true

        // // Show a warning if the csa name has changed
        // if (formatToSafeSlug(newCsa.name) !== formatToSafeSlug(csa.name)) {
        //   proceed = await new Promise((resolve) =>
        //     Alert('Name Change warning', getNameChangeOnUrlSafeSlugWarningText('csas'), [
        //       { text: 'Cancel', style: 'cancel', onPress: () => resolve(false) },
        //       { text: 'Continue', onPress: () => resolve(true) },
        //     ]),
        //   )
        // }

        // if (!proceed) return

        await updateCSA(newCsa)
        dispatch(setAdminCSAsAction([...adminCsas]))
      }

      goBack()
    } catch (err) {
      if (isErrorWithCode(err)) {
        Alert('Error saving CSA:', errorToString(err))
      } else {
        Alert(
          'Error saving CSA:',
          'There was an error while tyring to save your CSA. Please check your internet connection and try again.',
        )
      }
      Logger.error('Error saving CSA', err)
    }
  }

  return (
    <Formik<CSAFormik>
      enableReinitialize
      validationSchema={csaValidationSchema}
      initialValues={csa}
      onSubmit={handleSubmitCSA}
    >
      {({
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        resetForm,
      }) => (
        <AdminView
          customHeader={
            <CSAHeader
              isLoading={isSubmitting}
              goBack={() => {
                setCsa(getInitialCsaFormData())
                resetForm()
                goBack()
              }}
              title={`${csa?.id ? 'Edit' : 'Add'} CSA Group`}
              actionTitle="Save"
              goAction={handleSubmit}
            />
          }
        >
          <View style={styles.container}>
            <View style={styles.basicInformation}>
              <HeaderText style={styles.basicInformationText}>Basic Information</HeaderText>

              <FormInput
                label="CSA Name"
                placeholder="Name of your CSA Group"
                value={values.name}
                onChangeText={handleChange('name')}
                errorMessage={touched.name && errors.name ? errors.name : ''}
              />

              <FormInput
                label="Short Description"
                placeholder="Description"
                value={values.shortDescription}
                onChangeText={handleChange('shortDescription')}
                maxLength={100}
              />

              <FormInput
                multiline
                textAlignVertical="top"
                numberOfLines={7}
                placeholder="Tell customers more about your CSA Group"
                label={<InputLabel label="Description" tooltipId={ToolTips.LONGDESCRIPTION} required />}
                value={values.description}
                onChangeText={handleChange('description')}
                errorMessage={touched.description && errors.description ? errors.description : ''}
              />

              <Divider clear />
              <View style={globalStyles.flexRowCenter}>
                <FormNumberInput
                  label={<InputLabel label="Change Window" tooltipId={ToolTips.CHANGE_WINDOW} required />}
                  placeholder="ex.4"
                  containerStyle={styles.changeWindowInputContainer}
                  value={values.changeWindow}
                  errorMessage={touched.changeWindow && errors.changeWindow ? errors.changeWindow : ''}
                  onChangeText={(v) => setFieldValue('changeWindow', v)}
                  onBlur={handleBlur('changeWindow')}
                />

                <Text style={[styles.inputLabel, styles.changeWindowDescription]}>Days before distribution</Text>
              </View>
              <View style={styles.toggleButtonContainer}>
                <Divider clear />
                <View style={globalStyles.flexRowCenter}>
                  <ToggleButton
                    // value is only used to present to the UI
                    value={values.changeOptions === 'allow-reschedule-only'}
                    // disregard the value from onChange
                    onChange={(value) => {
                      if (value) {
                        setFieldValue('changeOptions', 'allow-reschedule-only')
                      } else {
                        setFieldValue('changeOptions', 'block-all-rescheduling')
                      }
                    }}
                    style={styles.toggleButton}
                    title="Only allow schedule changes"
                  />
                  <Tooltip title="Only allow schedule changes" id={ToolTips.ONLY_ALLOW_SCHEDULE_CHANGES} />
                </View>

                <Divider />
                <View style={globalStyles.flexRowCenter}>
                  <ToggleButton
                    // value is only used to present to the UI
                    value={values.changeOptions === 'allow-reschedule-and-location'}
                    // disregard the value from onChange
                    onChange={(value) => {
                      if (value) {
                        setFieldValue('changeOptions', 'allow-reschedule-and-location')
                      } else {
                        setFieldValue('changeOptions', 'block-all-rescheduling')
                      }
                    }}
                    style={styles.toggleButton}
                    title={`Allow ${isSmallDevice ? '' : 'both '}schedule and location changes`}
                  />
                  <Tooltip
                    title={`Allow ${isSmallDevice ? '' : 'both '}schedule and location changes`}
                    id={ToolTips.ALLOW_BOTH_SCHEDULE_AND_LOCATION_CHANGES}
                  />
                </View>
              </View>
              <Divider bottom={10} />
              <FormSectionHeader title="Customer Messages" />
              <Text style={styles.customerMessagesText}>
                Add custom messages for new CSA orders and missed pickups.
              </Text>

              <FormInput
                label={<InputLabel label="New Order Message" tooltipId={ToolTips.NEW_ORDER_MESSAGE} />}
                multiline
                textAlignVertical="top"
                numberOfLines={5}
                placeholder="Add a message that will be sent to anyone ordering items from this CSA"
                value={values.newOrderNote}
                onChangeText={handleChange('newOrderNote')}
              />
              <Divider clear />
              <FormInput
                label={<InputLabel label="Missed Pickup Message" tooltipId={ToolTips.NEW_ORDER_MESSAGE} />}
                placeholder="Add a message that will be included in pickup reminders with missed pickup information. This is only for pickups (not delivery or shipping)."
                multiline
                numberOfLines={5}
                value={values.missedPickupMessage}
                onChangeText={handleChange('missedPickupMessage')}
              />

              <HeaderText>Suggested products {values.suggestedProducts?.length ?? 0}/3</HeaderText>
              <Text style={styles.suggestedProductDescription}>
                Products added here will be suggested in a window after adding products from this CSA Group to the cart.
              </Text>
              <CSASuggestedProducts />
            </View>
            <View style={styles.photoSection}>
              <CSAPhotoSection setIsNewImage={() => setIsNewImage(true)} />
            </View>
          </View>
        </AdminView>
      )}
    </Formik>
  )
}

export const CSADetailScreen = withAdminAuth(CSADetailScreenComp, Permission.ProductSetup, AccessRight.Edit)

const useStyles = CreateResponsiveStyle(
  {
    changeWindowInputContainer: {
      width: 240,
    },
    customerMessagesText: {
      marginLeft: 15,
    },
    toggleButtonContainer: {
      paddingLeft: 25,
    },
    newOrderMessageInput: {
      textAlignVertical: 'top',
      numberOfLines: 5,
    },
    missedPickupMessageInput: {
      textAlignVertical: 'top',
      numberOfLines: 5,
    },
    toggleButton: {
      alignSelf: 'flex-start',
    },
    inputLabel: {
      fontFamily: typography.body.regular,
      fontSize: 14,
      color: Colors.shades['500'],
    },
    container: {
      flexDirection: 'row',
    },
    basicInformation: {},
    basicInformationText: { marginVertical: 15 },
    inputs: {},
    photoSection: {},
    suggestedProductDescription: { margin: 8 },
    changeWindowDescription: {},
  },
  {
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      container: {
        paddingHorizontal: 5,
        paddingBottom: 0,
      },
      changeWindowDescription: { width: 90 },
    },
    [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      container: { flexDirection: 'column', marginLeft: 1 },
      inputs: {
        width: '100%',
        flexDirection: 'column',
      },
    },

    [minSize(DEVICE_SIZES.LARGE_DEVICE)]: {
      photoSection: {
        flex: 0.4,
        paddingLeft: 12,
        alignItems: 'flex-start',
      },
      container: {
        marginLeft: 30,
      },
      basicInformation: {
        flex: 0.6,
        paddingRight: 13,
      },
    },
  },
)
