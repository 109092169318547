import { omit } from '@helpers/typescript'
import { UserAddress } from '@models/UserAddress'
import type { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { prepareMarshal, prepareUnmarshal } from './encoding'

/** unmarshalUserAddress converts the data provided by the Firestore document into the structure expected by the UserAddress model. */
export function unmarshalUserAddress(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): UserAddress {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)

  return { ...data, id } as UserAddress
}

/** marshalUserAddress converts the address structure into a Firebase document structure. */
export function marshalUserAddress(address: Partial<UserAddress>): DocumentData {
  return omit(prepareMarshal(address), 'id')
}
