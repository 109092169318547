import Colors from '@/constants/Colors'
import useKeyedState from '@/hooks/useKeyedState'
import { FormBuilder } from '@components'
import { Button, Divider, FormButton, HeaderText, Text, Toast } from '@elements'
import { Formik, FormikProps } from 'formik'
import { useCallback, useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { Input } from 'react-native-elements'
import * as Yup from 'yup'
import { MultiFactorLogin } from '../MultiFactorLogin'

type FormType = {
  verificationCode: string
}

const registerValidationSchema = Yup.object<FormType>().shape({
  verificationCode: Yup.string().required('This field is required'),
})

/** Component state */
type StateTypes = {
  errorMessage: string
  loaders: {
    confirmCode: boolean
  }
}

const initialState: StateTypes = {
  errorMessage: '',
  loaders: {
    confirmCode: false,
  },
}

/** Component props */
type Props = {
  multiFactor: MultiFactorLogin
}

/** The Multi-factor login is a modal and used to verify code that is sent to an user's phone to check if user passes the verification. */
export function MultiFactorLoginComponent({ multiFactor }: Props) {
  const [data, set] = useKeyedState<StateTypes>(initialState)
  const { errorMessage, loaders } = data

  useEffect(() => {
    multiFactor.sendCodeWhenLogin()
    // We want to only send this the first time the component is mounted
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /** Resend Code Handler */
  const resendHandler = useCallback(() => {
    multiFactor.sendCodeWhenLogin().then(() => {
      Toast('Verification code has been sent again.')
    })
  }, [multiFactor])

  /** Verify Code Handler */
  const submitHandler = useCallback(
    async (values: FormType) => {
      set('loaders', (loaders) => ({ ...loaders, confirmCode: true }))
      await multiFactor
        .confirmCodeWithLogin(values.verificationCode)
        .then(() => {
          Toast('Verification code has been confirmed.')
          set('errorMessage', '')
        })
        .catch((message: string) => {
          set('errorMessage', message)
        })
      set('loaders', (loaders) => ({ ...loaders, confirmCode: false }))
    },
    [multiFactor, set],
  )

  return (
    <Formik
      initialValues={{
        verificationCode: '',
      }}
      onSubmit={submitHandler}
      validationSchema={registerValidationSchema}
    >
      {({ values, errors, handleBlur, touched, handleChange, handleSubmit }: FormikProps<FormType>) => (
        <View style={styles.container}>
          <Divider clear />
          <HeaderText>Two-Factor Authentication</HeaderText>
          <Text>
            Please enter the code we have sent to your phone number{' '}
            {multiFactor.phoneNumberHint ? multiFactor.phoneNumberHint : ''} to complete login.
          </Text>
          <Divider clear />
          <FormBuilder>
            <Input
              inputStyle={styles.paddingLeft10}
              numberOfLines={1}
              placeholder="Verification Code"
              errorMessage={touched.verificationCode ? errors.verificationCode : ''}
              value={values.verificationCode}
              onChangeText={handleChange('verificationCode')}
              onBlur={handleBlur('verificationCode')}
              rightIcon={<Button title="Resend" onPress={resendHandler} small size={10} />}
            />
            <FormButton title="Verify Code" loading={loaders.confirmCode} onPress={handleSubmit} small size={10} />
          </FormBuilder>

          {!!errorMessage && (
            <Text
              color={Colors.red}
            >{`Something went wrong setting up MFA, please try again in a little while.${errorMessage}`}</Text>
          )}
        </View>
      )}
    </Formik>
  )
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 15,
  },
  headerText: {
    textAlign: 'center',
    maxWidth: 400,
  },
  paddingLeft10: {
    paddingLeft: 10,
  },
})
