import { addNewLead } from '@api/FarmGenAdmin'
import { Alert, ErrorText, FormButton, FormInput, formStyles, hideModal, Spinner, Text } from '@elements'
import { errorToString } from '@helpers/helpers'
import { Farm } from '@models/Farm'
import { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import Colors from '../../constants/Colors'
import { userSelector } from '../../redux/selectors'

type Props = {
  farm: Farm
}

/** This modal is used to present a dialog for users to invite a farm to GrownBy which will add them as a lead in Zoho */
export function InviteFarm({ farm }: Props) {
  const user = useSelector(userSelector)
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')

  const handleSubmit = async () => {
    setIsLoading(true)
    setError('')

    try {
      await addNewLead(farm, user, message)
      hideModal()
      Alert('Farm Invited', `${farm.name} has been invited to join GrownBy. Thank you!`)
    } catch (e) {
      setError('Error inviting farm: ' + errorToString(e))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <View>
      <View style={styles.farmInfoContainer}>
        <Text center size={14} type="bold">
          {farm.name}
        </Text>
        <Text center size={14}>
          {farm.address.street1}
        </Text>
        <Text center size={14}>
          {farm.address.city}, {farm.address.state} {farm.address.zipcode}
        </Text>
      </View>
      {isLoading ? (
        <Spinner />
      ) : (
        <View>
          <FormInput
            multiline
            textAlignVertical="top"
            numberOfLines={4}
            label={null}
            placeholder="(Optional) Leave a message for the farm..."
            value={message}
            onChangeText={setMessage}
            inputStyle={styles.formInputStyle}
          />
          <FormButton title="Invite" onPress={handleSubmit} />
          {!!error && <ErrorText>{error}</ErrorText>}
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  farmInfoContainer: {
    backgroundColor: Colors.lightGreen,
    borderRadius: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: Colors.shades[200],
    margin: 10,
  },
  formInputStyle: {
    ...formStyles.inputStyle,
    minHeight: 150,
  },
})
