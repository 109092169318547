import { errorToString } from '@helpers/helpers'

/** ErrorCode identifies common error codes. These values may be transmitted across API boundaries, and map to error codes on the server.
 */
export enum ErrorCode {
  InvalidArgument = 'InvalidArgument',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  INSUFFICIENT_STOCK = 'INSUFFICIENT_STOCK',
  INVALID_PIN = 'INVALID_PIN',
  INVALID_ACCOUNT = 'INVALID_ACCOUNT',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  INVALID_MERCHANT_ID = 'INVALID_MERCHANT_ID',
  FirebaseClientInternal = 'FirebaseClientInternal',
  NotFoundError = 'NotFoundError',
  UnauthorizedError = 'UnauthorizedError',
  FieldError = 'FieldError',
  DataError = 'DataError',
  DualMessageError = 'DualMessageError',
  MissingData = 'MissingData',
  AbortedError = 'AbortedError',
}

/** These error types are categories of errors and can be used to identify broad groups of errors while allowing the code to be
 * more specific. Adding new groups here should be limited to true categorical errors. For example, Validation error but not
 * product-specific validation error or any other model.*/
export enum ErrorTypes {
  Validation = 'validation-error',
  Unknown = 'unknown',
  NotFound = 'not-found',
}

/** The NotFoundError is thrown when a document cannot be found. */
export class NotFoundError extends Error {
  collection: string
  scope: string | { [key: string]: any }

  constructor(collection: string, scope: string | { [key: string]: any }) {
    const message = `${collection}: ${JSON.stringify(scope)} was not found`
    super(message)

    this.name = ErrorCode.NotFoundError
    this.message = message
    this.collection = collection
    this.scope = scope
  }
}

/** isNotFound returns true if the supplied error is a not found error. */
export function isNotFound(err: unknown): err is NotFoundError {
  return (err as Error).name === ErrorCode.NotFoundError
}

/** The NotFoundError is thrown when a document cannot be found. */
export class UnauthorizedError extends Error {
  collection: string
  scope: string | { [key: string]: any }

  constructor(collection: string, scope: string | { [key: string]: any }) {
    const message = `${collection}: Incorrect permissions to access ${JSON.stringify(scope)}`
    super(message)

    this.name = ErrorCode.UnauthorizedError
    this.message = message
    this.collection = collection
    this.scope = scope
  }
}

/** isUnauthorized returns true if the supplied error is a not found error. */
export function isUnauthorized(err: Error): boolean {
  return err.name === ErrorCode.UnauthorizedError
}

/** The FieldError is thrown when a field validation fails. */
export class FieldError extends Error {
  fieldName: string
  errorMessage: string

  constructor(fieldName: string, message: string) {
    super(message)

    this.name = ErrorCode.FieldError
    this.message = `${fieldName} ${message}`
    this.fieldName = fieldName
    this.errorMessage = message
  }
}

/** Simple extension to Error which stores a data object intended to help debugging. For example, data could be a corrupt value, or an Id, or an entire document */
export class DataError<D extends any = any> extends Error {
  data: D | undefined

  constructor(msg: string, data?: D) {
    super(msg)

    this.data = data
    this.name = ErrorCode.DataError
    Object.setPrototypeOf(this, DataError.prototype)
  }
}

/** isDataError returns true if the supplied error is a data error. */
export function isDataError(err: unknown): err is DataError {
  return err instanceof DataError
}

/** Constructor options for a dual msg error */
export type DualMessageErrorOpts<Data extends any = any> = { devMsg: string; uiMsg: string; data?: Data }

/** Error that expands on the DataError and provides two different error messages. One for the UI and a spearate one for debugging purposes.
 * - This is useful in situations where we are caught between the two diametrically oposing requirements of: On one hand we need a user-friendly error message that doesn't trip people up. On the other hand we need a technically accurate error message that conveys information in a way that's useful for engineers. */
export class DualMessageError<D extends any = any> extends DataError<D> {
  devMsg: string
  uiMsg: string

  constructor({ uiMsg, devMsg, data }: DualMessageErrorOpts<D>) {
    /** By default the ui error is the one passed to the main error constructor in case it's parsed automatically by a helper that extracts the error message from the default error class */
    super(uiMsg, data)
    this.devMsg = devMsg
    this.uiMsg = uiMsg
    this.name = ErrorCode.DualMessageError
    Object.setPrototypeOf(this, DualMessageError.prototype)
  }
}

/** Constructor options for an error with code */
export type ErrorWithCodeOpts<Code extends string = string, Data extends any = any> = Omit<
  DualMessageErrorOpts<Data>,
  'uiMsg'
> & {
  code: Code
  /** Type can be a general error type like ValidationError, whereas code is more specific */
  type?: ErrorTypes
  uiMsg?: string
}

/** Error that expands on DualMessageError and allows tagging an error with an error code.
 * - It assigns the code to the default Error.name property
 * - The purpose of an error code is to easily identify an error in various places, by using a reference that won't change. For example, error messages might change, so you shouldn't use them as a means to identify an error. */
export class ErrorWithCode<C extends string, D extends any = any> extends DualMessageError<D> {
  type: ErrorTypes
  constructor({ devMsg, uiMsg = devMsg, data, code, type }: ErrorWithCodeOpts<C, D>) {
    super({ uiMsg, devMsg, data })
    this.name = code
    this.type = type ?? ErrorTypes.Unknown
    Object.setPrototypeOf(this, ErrorWithCode.prototype)
  }
}

/** isErrorWithCode returns true if the supplied error is of the ErrorWithCode type.
 * @param code If a specific code is passed, it will check that the code in the error matches this code. */
export function isErrorWithCode<S extends string, D = any>(err: unknown, code?: S): err is ErrorWithCode<S, D> {
  return err instanceof ErrorWithCode && (code ? code === err.name : true)
}

/** Converts a raw error to an error with code. Useful when catching an error from a 3rd party */
export function withCode<C extends string, D extends any = any>(
  rawErr: unknown,
  opts: ErrorWithCodeOpts<C, D>,
): ErrorWithCode<C, D> {
  const err = new ErrorWithCode({
    ...opts,
    devMsg: `${opts.devMsg}: ${errorToString(rawErr)}`,
  })
  err.stack = (rawErr as Error)?.stack
  err.cause = (rawErr as Error)?.cause
  return err
}

/** Throws an errorWithCode that represents a firebase client internal error.
 * - It is presumed an intermittent or slow network is the most likely culprit for this kind of error.
 * @param data is a data object related to the error, which may help debug the reason why the error was thrown. Will usually be the parameters or query of the request. */
export function firebaseClientInternalErr(data?: any) {
  throw new ErrorWithCode({
    code: ErrorCode.FirebaseClientInternal,
    devMsg: 'An internal error occurred in Firebase Client Library while processing this request',
    uiMsg: 'An error occurred with this request, check your internet connection and try again.',
    data,
  })
}
