import { ImageSourcePropType, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import { Image, ImageProps } from '../../components/Image'
import { globalStyles } from '../../constants/Styles'

import { useLayout } from '@/hooks/useLayout'
import { useFlatStyle } from '@/hooks/useMergeStyle'

type PropTypes = {
  image: ImageSourcePropType
  children: JSX.Element | JSX.Element[]
  style?: StyleProp<ViewStyle>
  imageContStyle?: StyleProp<ViewStyle>
  imageLeft?: boolean
  height?: number
  resizeMode?: ImageProps['resizeMode']
}

/**
 * Feature card that incorporates a specified image and children component, adjusting the orientation based on device size (isSmallDevice)
 * @param height -  represents the base height of the component, which will increase by 25% if isSmallDevice
 * The content should fit based on the outside layout management & height
 * @param imageLeft places the image in the left side of the card (defaults on the right)
 */
export function FeatureCard({
  image,
  height = 300,
  children,
  imageLeft = false,
  resizeMode,
  imageContStyle,
  style,
}: PropTypes) {
  const { isSmallDevice } = useLayout()
  const boxHeight = isSmallDevice ? height * 1.25 : height
  const containerStyle = useFlatStyle([
    {
      flexDirection: isSmallDevice ? 'column' : imageLeft ? 'row' : 'row-reverse',
      height: isSmallDevice ? undefined : boxHeight,
      borderRadius: 10,
      overflow: 'hidden',
    },
    style,
  ])

  const imgCont = useFlatStyle([
    {
      aspectRatio: 3 / 2,
    },
    imageContStyle,
  ])
  return (
    <View style={containerStyle}>
      <View style={imgCont}>
        <Image source={image} style={styles.img} resizeMode={resizeMode ?? 'cover'} />
      </View>
      <View style={globalStyles.flex1}>{children}</View>
    </View>
  )
}
const styles = StyleSheet.create({
  img: {
    height: '100%',
    width: '100%',
  },
})
