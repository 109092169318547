import { parseCoordString, validCoords } from '@helpers/coordinate'
import { ArrElement } from '@helpers/typescript'
import { Coordinate } from '@models/Coordinate'
import { RefinementListProvided } from 'react-instantsearch-core'

import { Address } from '@models/Address'
import { CurrentLocation } from '../../constants/types'

/** This is set so the to prevent that the calculated Zoom that can be calculated to infinity (throwing native error on Android.) */
export const maxZoom = 20

/** Will try to get the coordinates for map, with graceful degradation. Will try params first, else searchLoc, else userLoc, else will be undefined */
export function getDefaultCoords(params: {
  userAddress?: Address
  userLoc?: CurrentLocation | null
  searchLoc?: CurrentLocation
  /** Intended to receive a coordinate from a navigation parameter, or some other custom source */
  center?: Coordinate | string
}): Coordinate | undefined {
  // If we passed a custom location that takes precedence
  const urlCoords = params.center
    ? typeof params.center === 'string'
      ? parseCoordString(params.center)
      : params.center
    : undefined

  if (urlCoords && validCoords(urlCoords)) return urlCoords

  // If we have searched for a location that follows in precedence
  if (params.searchLoc?.coordinate && validCoords(params.searchLoc.coordinate)) return params.searchLoc.coordinate

  // If not we try current known device location
  if (params.userLoc && validCoords(params.userLoc.coordinate)) return params.userLoc.coordinate

  // Lastly try the signed-in user's address
  if (params.userAddress && validCoords(params.userAddress.coordinate)) return params.userAddress.coordinate

  return undefined
}

/** Category refinement handler */
export const handleRefine = ({
  item,
  listProps,
  updateParam,
  initiallyOn = false,
}: {
  /** toggled item, or hit, originating from connectRefinementList() */
  item: ArrElement<RefinementListProvided['items']>
  listProps: RefinementListProvided
  /** helper that takes a category string and sets it as the navigation "categories" parameter of the explore screen, the string is expected to be a single string that encodes the various categories considered active navigation parameters */
  updateParam: (stringifiedArr: string | undefined) => void
  /** If "initiallyOn", the new categories will be all possible categories except that of the toggled hit; this is what you would want if all options were assumed to be initially "on". If false, the new categories will be only the toggled hit */
  initiallyOn?: boolean
}) => {
  const { items, currentRefinement } = listProps
  if (currentRefinement.length || !initiallyOn) {
    //Item.value already holds the new currentRefinement array you would need, if you were to toggle this item
    const newParam: string | undefined = item.value.join(',')
    //If toggling the only item selected, value will be empty string (''). This should be handled by VirtualParamRefinement to clear refinement.
    updateParam(newParam)
  } else
    updateParam(
      items
        .filter((itm) => itm.label !== item.label)
        .map((itm) => itm.label)
        .join(','),
    )
}
