/**
 * All types of Tooltips for the application
 */
export enum ToolTips {
  SERVICE_FEE = 'service-fee',
  PICKUP_LOCATION = 'pickup-location',
  EMAIL = 'email',
  PHONE = 'phone',
  ADDRESS = 'address',
  CERTIFICATIONS = 'certifications',
  TAGS = 'tags',
  BRANDING = 'branding',
  OFFLINE_PAYMENTS = 'offline-payments',
  COOP_MEMBERSHIP = 'coop-membership',
  BASIC_NAME = 'basic-name',
  BASIC_CATEGORY = 'basic-category',
  BASIC_COMMODITY = 'basic-commodity',
  BASIC_PRODUCER = 'basic-producer',
  BASIC_VARIETY = 'basic-variety',
  BASE_UNIT = 'base-unit',
  BASE_UNIT_GLOBAL = 'base-unit-global',
  COST_PER_UNIT = 'cost-per-unit',
  IN_STOCK = 'in-stock',
  IN_STOCK_GLOBAL = 'in-stock-global',
  OPTION_NAME = 'option-description',
  OPTION_PRICE = 'option-price',
  OPTION_UNIT = 'option-units',
  OPTION_SKU = 'option-sku',
  DISTRIBUTIONS = 'distributions',
  SHORTDESCRIPTION = 'short-description',
  LONGDESCRIPTION = 'long-description',
  CHANGE_WINDOW = 'change-window',
  AVAILABILITY = 'availability',
  DESCRIPTIONS = 'descriptions',
  SHARE_TYPE = 'share-type',
  SHARE_PRODUCTION = 'share-production',
  SELECT_CSA = 'select-csa',
  SELECT_CSA_STANDARD = 'select-csa-standard',
  FAM_SIZE = 'fam-size',
  MIN_MAX_ITEMS = 'min-max-items',
  SCHEDULE_TYPE = 'schedule-type',
  FREQUENCY = 'frequency',
  SHARE_AVAILABILITY = 'share-availability',
  SHARE_INVENTORY = 'share-inventory',
  TOTAL_SHARE_PRICE = 'total-share-price',
  DELIVERY_TYPE = 'delivery-type',
  DELIVERY_DETAILS = 'delivery-details',
  DELIVERY_ZONE = 'delivery-zone',
  DELIVERY_FEE = 'delivery-fee',
  PICKUP_TYPE = 'pickup-type',
  PICKUP_DETAILS = 'pickup-details',
  OFFERINGS = 'offerings',
  PRICING = 'pricing',
  ADDON_SHARES = 'addon-shares',
  ADDON_FAM_SIZE = 'addon-fam-size',
  ADDON_MIN_MAX_ITEMS = 'addon-min-max-items',
  ADDON_PRICING = 'addon-pricing',
  WEEKLY_BILLING = 'weekly-billing',
  MONTHLY_BILLING = 'monthly-billing',
  POLICIES = 'policies',
  VACATION_WEEKS = 'vacation-weeks',
  PICKUP_OPTIONS = 'pickup-options',
  MISSED_PICKUP_MESSAGE = 'missed-pickup-message',
  DISTRIB_SCHEDULE = 'distrib-schedule',
  FINAL_PAY_DATE = 'final-pay-date',
  TOTAL_SHARE_PRICE_WEEKLY = 'total-share-price-weekly',
  TOTAL_SHARE_PRICE_MONTHLY = 'total-share-price-monthly',
  DEPOSIT = 'deposit',
  UNITS_SOLD = 'units-sold',
  NUM_PICKUPS = 'num-pickups',
  FNS = 'fns',
  INVOICES = 'invoices',
  ORDERS = 'orders',
  NEW_ORDER_MESSAGE = 'new-order-message',
  LOCATIONS_ZONES = 'locations_zones',
  CouponsList = 'coupons-list',
  SCHEDULES = 'schedules',
  PRODUCTS_SCHEDULE_SELECT = 'products-schedule-select',
  PRODUCTS_SCHEDULE_FREQUENCY = 'products-schedule-frequency',
  PRODUCTS_SCHEDULE_START = 'products-schedule-start',
  PRODUCTS_SCHEDULE_END = 'products-schedule-end',
  PRODUCTS_AVAILABILITY_OVERVIEW = 'products-availability-overview',
  PRODUCTS_SCHEDULE_NEXTDAY = 'products-schedule-nextday',
  PRODUCTS_MIN_PICKUPS = 'products-min-pickups',
  PRODUCTS = 'products',
  TEMPLATE_NAME = 'template-name',
  TEMPLATE = 'template',
  CSA_GROUPS = 'csa-groups',
  CUSTOMERS = 'customers',
  DIST_NAME = 'dist-name',
  LOCATION_NAME = 'location-name',
  LOCATION_NICKNAME = 'location-nickname',
  ORDER_CUTOFF_WINDOW = 'order-cutoff-window',
  EXCEPTION_DAYS = 'exception-days',
  TOGGLE_HIDDEN = 'toggle-hidden',
  INSTALLMENT_PAYMENTS = 'installment-payments',
  DELIVERY_FEE_PAYMENTS = 'delivery-fee-payments',
  SNAP_EBT_ELIGIBILITY = 'snap-ebt-eligibility',
  COUPON_REDEMPTION = 'redemptions',
  COUPON_PROMOCODE = 'promocode',
  PRICE_OPTION = 'price-option',
  PRICE_GLOBAL = 'price-global',
  PRICE_UNIT = 'price-unit',
  SALES_REPORT = 'sales-report',
  DAILY_SUMMARY = 'daily-summary',
  LOCATION_SUMMARY = 'location-summary',
  SIGN_IN_SHEETS = 'sign-in-sheets',
  PACKING_SHEETS = 'packing-sheets',
  CREATE_FARM_PROFILE = 'create-farm-profile',
  FARM_INFO = 'farm-info',
  EBT_CARD_CHANGE = 'ebt-card-change',
  EBT_BALANCE_INQUIRIES = 'ebt-balance-inquiries',
  EXCESSIVELY_LARGE_EBT_TRANSACTIONS = 'excessively-large-ebt-transactions',
  TWO_ACCOUNTS_SHARING_EBT_CARD = 'two-accounts-sharing-ebt-card',
  SIGN_UPS = 'sign-ups',
  TOO_MANY_EBT_TRANSACTIONS_IN_A_DAY = 'too-many-ebt-transactions-in-a-day',
  ALLOW_BOTH_SCHEDULE_AND_LOCATION_CHANGES = 'allow-schedule-and-location-changes',
  ONLY_ALLOW_SCHEDULE_CHANGES = 'only-allow-schedule-changes',
  DASHBOARD_GROSS_REVENUE = 'dashboard-gross-revenue',
  SHIPPING_FEE = 'add-shipping-fee',
  ADD_SHIPPING_ZONE = 'add-shipping-zone',
  ENTER_ZIP_CODE = 'enter-zip-code',
  CLOSED_FOR_NEW_ORDERS = 'closed-for-new-orders',
  TAXES_AND_FEES_TITLE = 'taxes-and-fees-title',
  SHARE_TEMPLATES = 'share-templates',
  CATEGORIES = 'categories',
  PRODUCERS = 'producerS',
  TEAM_MEMBERS_TAB = 'team-members-tab',
  TAXES_AND_FEES_TAB = 'taxes-and-fees-tab',
  ACH_PAYMENT_SETTING = 'ach-payment-setting',
  CSA_GROUPS_ACTIONS = 'csa-groups-actions',
}
