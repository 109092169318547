import { TextH1, TextH4 } from '@elements'
import { formatMoney } from '@helpers/display'
import { FarmBalance } from '@models/Payment'
import { StyleSheet, View } from 'react-native'

import Colors from '../constants/Colors'

export function MonthlyBalance({ startBal }: { startBal: FarmBalance }) {
  return (
    <View style={styles.container}>
      <View style={styles.amountWrapper}>
        <TextH1 style={styles.amount}>{formatMoney(startBal?.amount || 0)}</TextH1>
        <TextH4 style={{ color: Colors.shades['300'] }}>Current Balance</TextH4>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 10,
  },
  amountWrapper: {
    alignItems: 'center',
    marginVertical: 20,
  },
  amount: {
    color: Colors.green,
    fontSize: 35,
  },
})
