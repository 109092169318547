import { StyleProp, StyleSheet, TextStyle } from 'react-native'
import { Input, InputProps } from 'react-native-elements'

import { typography } from './Text'

import Colors from '@/constants/Colors'

/**
 * @param helperText is displayed specified AND there are no errors (on errors place)
 * @param helperTextStyle helper text style
 */
export type CustomInputProps = InputProps & {
  helperText?: string
  helperTextStyle?: StyleProp<TextStyle>
}

function CustomInput({
  labelStyle,
  errorStyle,
  inputStyle,
  inputContainerStyle,
  containerStyle,
  helperText,
  helperTextStyle,
  errorMessage,
  ...props
}: CustomInputProps) {
  const shouldDisplayHelper = !errorMessage?.length && helperText?.length
  return (
    <Input
      inputStyle={[styles.inputStyle, inputStyle]}
      inputContainerStyle={[
        styles.inputContainer,
        (errorMessage?.length ?? 0) > 0 && { borderColor: Colors.red },
        inputContainerStyle,
      ]}
      labelStyle={[styles.label, labelStyle]}
      containerStyle={[styles.wrapper, styles.container, containerStyle]}
      errorMessage={shouldDisplayHelper ? helperText : errorMessage}
      errorStyle={shouldDisplayHelper ? [styles.helper, helperTextStyle] : [styles.error, errorStyle]}
      {...props}
    />
  )
}

export default CustomInput

const styles = StyleSheet.create({
  label: {
    fontSize: 14,
    fontFamily: typography.body.medium,
    color: Colors.black,
    marginBottom: 4,
  },
  inputStyle: {
    fontFamily: typography.body.regular,
    fontSize: 14,
    paddingLeft: 10,
    color: Colors.shades[500],
  },
  inputContainer: {
    borderWidth: 1,
    borderColor: Colors.shades[100],
    borderRadius: 10,
  },
  wrapper: {
    // Minimum height in order to hide when component height changes on error
    minHeight: 90,
  },
  container: {
    paddingHorizontal: 0,
  },
  error: {
    fontFamily: typography.body.medium,
    color: Colors.red,
  },
  helper: {
    fontFamily: typography.body.regular,
    fontSize: 14,
    color: Colors.shades[200],
  },
})
