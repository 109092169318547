import { HeaderText, Text, TextH2 } from '@elements'
import { canUpdateQuantity, findItemInCart } from '@helpers/canUpdateQuantity'
import { formatMoney, formatUnitPriceRange } from '@helpers/display'
import { getStock, getUnitPrice, getUnits, matchesAppModeBO } from '@helpers/products'
import { ProductType, Unit, UnitProduct, hasUnits } from '@models/Product'
import { StyleSheet, TextStyle, TouchableOpacity, View } from 'react-native'

import { CartServiceType } from '@/constants/types/cartService'
import { useMemo } from 'react'
import Colors from '../../constants/Colors'
import { useCartService } from '../../hooks/useCart'
import { Divider } from '../elements/Divider'
import { Icon } from '../elements/Icon/Icon'

/** Component that allows selecting buying options for unit products */
export function UnitSelection({
  product,
  close,
  cartServiceType = 'consumer',
  isWholesale,
}: {
  product: UnitProduct
  close: (unit?: Unit) => void
  cartServiceType?: CartServiceType
  isWholesale: boolean
}) {
  const { cart } = useCartService({ cartServiceType, isWholesale })

  const unitData = useMemo(() => {
    return getUnits(product, { isWholesale })
      .filter(matchesAppModeBO(isWholesale))
      .map((unit, index) => {
        const isLastUnit = index === product.units.length - 1
        const cartItem = findItemInCart({ product, unit }, cart)
        const canAddUnit = cartItem
          ? canUpdateQuantity({ cartItem, cart, delta: 1, isWholesale })
          : canUpdateQuantity({ cartItem: { product, quantity: 1, unit }, cart, isWholesale })

        const stock = getStock(product, unit.id)
        const optionStyle = getStyle(stock, canAddUnit)

        const unitPriceRangeString = `${formatUnitPriceRange(product.units, unit.id, isWholesale)} / ea`
        const unitPriceString = `(${formatMoney(getUnitPrice(product, unit.id, isWholesale))}/${product.baseUnit})`

        return {
          unit,
          index,
          isLastUnit,
          cartItem,
          canAddUnit,
          stock,
          optionStyle,
          unitPriceRangeString,
          unitPriceString,
        }
      })
  }, [cart, isWholesale, product])

  if (isWholesale === undefined) {
    close()
    return null
  }

  if (!hasUnits(product)) close()
  const isFarmCredit = product.type === ProductType.FarmBalance

  return (
    <View style={styles.container}>
      {unitData.map(
        ({ unit, cartItem, canAddUnit, stock, optionStyle, unitPriceRangeString, unitPriceString, isLastUnit }) => {
          return (
            <View key={unit.id}>
              <TouchableOpacity style={styles.item} onPress={() => close(unit)} disabled={!canAddUnit}>
                <View>
                  {isFarmCredit ? (
                    <HeaderText style={optionStyle}>{unit.name}</HeaderText>
                  ) : (
                    <>
                      <Text style={optionStyle}>{unit.name}</Text>
                      {stock === 0 ? (
                        <Text style={[optionStyle, stock < 5 && { color: Colors.red }]} type="medium">
                          Out of stock
                        </Text>
                      ) : (
                        <Text style={[optionStyle, stock < 5 && { color: Colors.red }]}>{stock} left in stock</Text>
                      )}
                    </>
                  )}
                  {!!cartItem && <Text color={Colors.green}>(In the cart)</Text>}
                </View>

                <View style={styles.priceCont}>
                  {isFarmCredit ? (
                    <TextH2 color={Colors.green}>Add</TextH2>
                  ) : (
                    <>
                      <HeaderText style={optionStyle}>{unitPriceRangeString}</HeaderText>
                      <Text style={optionStyle}>{unitPriceString}</Text>
                    </>
                  )}
                </View>

                <Icon name="chevron-right" style={styles.chevron} />
              </TouchableOpacity>
              <Divider clear={isLastUnit} />
            </View>
          )
        },
      )}
    </View>
  )
}

const getStyle = (qty: number, canAddUnit: boolean): TextStyle | undefined => {
  return qty < 1 || !canAddUnit ? { color: Colors.shades['300'] } : {}
}

const styles = StyleSheet.create({
  container: {
    margin: 10,
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  priceCont: { flex: 1, alignItems: 'flex-end', marginRight: 5 },
  chevron: { flexShrink: 1, flexGrow: 0 },
})
