import { RouteProp, useRoute } from '@react-navigation/native'
import { memo } from 'react'
import { RefinementListExposed, RefinementListProvided } from 'react-instantsearch-core'
import { connectRefinementList } from 'react-instantsearch-native'

import { useFocusFx } from '@/hooks/useFocusFx'
import { HomeParamList } from '@/navigation/types'
import { itemsLimit } from './withListRefinement'

const ConnectParamRefinement = connectRefinementList(
  ({
    items,
    refine,
    currentRefinement,
    paramKey,
  }: RefinementListProvided & { paramKey: keyof NonNullable<HomeParamList['ExploreScreen']> }) => {
    const { params } = useRoute<RouteProp<HomeParamList, 'ExploreScreen'>>()

    //Refines by the param on update if the new param values differ from the current refinement for this attribute, and are valid
    useFocusFx(() => {
      const encodedVal = params?.[paramKey]
      const paramValues = encodedVal?.split(',') //param values are encoded as comma separated string values
      if (!paramValues) return

      if (paramValues[0] === '' || !items.length) {
        refine([])
      } else {
        //Only refine if params differ from current refinement
        if (
          paramValues.some((v) => !currentRefinement.includes(v)) ||
          currentRefinement.some((label) => !paramValues.includes(label)) ||
          // Check for the case in which an existing refinement and parameter is no longer a refinement option due to city change
          paramValues.some((v) => !items.map((i) => i.label).includes(v))
        ) {
          //Only refine by available items
          //If the navigation parameter/s don't match the available refinement options, this will clear refinement
          const availCats = paramValues.filter((v) => items.map((itm) => itm.label).includes(v))
          refine(availCats)
        }
      }
      /** It's OK to ignore items, since they will be the most up-to-date whenever params change. And prop paramKey should never change. */
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    return null
  },
)

/** Virtual component (no render). Refines by an attribute specified as a prop in the exposed outer layer.
 * - Precondition: Assumes the attribute has been set as a navigation parameter to the explore screen; so components have to call navigation.setParams() to execute a refinement.
 * - Handles the call to `refine` when there's a change in the nav param for the attribute.
 * - Hooks warning: This functionality can't be replaced with algolia hooks because the `refine` type from the hooks api is different and cannot receive arrays of categories so as to refine by multiple values simultaneously. */
export const VirtualParamRefinement = memo(function VirtualParamRefinement(
  props: RefinementListExposed & { paramKey: keyof NonNullable<HomeParamList['ExploreScreen']> },
) {
  return <ConnectParamRefinement {...props} limit={itemsLimit} />
})
