import { Logger } from '@/config/logger'
import { snapshotUser, updateUser } from '@api/Users'
import { auth } from '@api/db'
import { Alert } from '@elements'
import { extendErr } from '@helpers/helpers'
import { SignedInState, User, UserRole } from '@models/User'
import { Dispatch } from 'redux'

import { RootState } from '../reducers/types'
import {
  ADD_UNSUBSCRIBE,
  ENABLE_NOTIFICATION,
  ENABLE_PROMOTION,
  HIDE_USER_TIPS,
  SET_USER,
  SET_USER_ROLE,
  SIGN_OUT,
} from './actionTypes'

type SignedInData = { state: SignedInState.SIGNEDIN; userId: string }
type SignedOutData = { state: SignedInState.SIGNEDOUT; userId?: undefined }

export const onUserAuthChange = (callback: (data: SignedInData | SignedOutData) => void) => {
  /** Holds the most recent userId value from auth listener */
  let userIdRef: string | undefined = ''

  return auth().onAuthStateChanged(
    (authUser) => {
      const userId = authUser?.uid

      if (userId === userIdRef) {
        // Prevent running the callback if there was no actual change to the user id value
        // It should only run the callback only once every time the user signed in or out.
        return
      } else {
        userIdRef = userId
      }

      if (!userId) return callback({ state: SignedInState.SIGNEDOUT })
      return callback({ state: SignedInState.SIGNEDIN, userId })
    },
    (e) => {
      Logger.error(extendErr(e, 'Error while listening to authState:'))
      return () => {}
    },
  )
}

/** Set or replace the entire user data in context.
 * - We have a global user listener that calls this, so this doesn't need to be called from every place that edits the user.
 */
export const setUser = (data: User) => {
  return {
    type: SET_USER,
    payload: data,
  }
}

/**
 * Set a listener for the user data.
 * - This will only be called if the userId data changes.
 */
export const setUserListener = (userId: string) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const unsubscribe = snapshotUser(userId, (user) => {
        // If there is an existing user role and the new user change does not have a role then don't update it. This is
        // to allow custom permissions for the GrownBy admin team logging in as a user
        const userRole = user.role ?? getState().user.role
        dispatch(setUser({ ...user, role: userRole }))
      })
      dispatch({
        type: ADD_UNSUBSCRIBE,
        payload: unsubscribe,
      })
    } catch (err) {
      Alert('Unable to load account', 'Please try signing in again, or contact support.')
      Logger.error(err)
    }
  }
}

/** Tells redux to reset all the data to initial state */
export function signOutAction() {
  return { type: SIGN_OUT }
}

export const enableNotification = (type: 'pushNotification' | 'text', value: boolean) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const user = getState().user
    if (user) {
      dispatch({
        type: ENABLE_NOTIFICATION,
        payload: { [type]: value },
      })
      try {
        await updateUser({
          id: user.id,
          notifications: {
            ...getState().user.notifications,
            [type]: value,
          },
        })
      } catch {
        dispatch({
          type: ENABLE_NOTIFICATION,
          payload: { [type]: !value },
        })
      }
    }
  }
}

export const enablePromotion = (type: 'email' | 'pushNotification' | 'text', value: boolean) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const user = getState().user
    if (user) {
      dispatch({
        type: ENABLE_PROMOTION,
        payload: { [type]: value },
      })
      try {
        await updateUser({
          id: user.id,
          promotions: {
            ...getState().user.promotions,
            [type]: value,
          },
        })
      } catch {
        dispatch({
          type: ENABLE_PROMOTION,
          payload: { [type]: !value },
        })
      }
    }
  }
}

export const setRoleAdmin = () => {
  return {
    type: SET_USER_ROLE,
    payload: UserRole.Admin,
  }
}

export const hideUserTips = () => {
  return {
    type: HIDE_USER_TIPS,
    payload: true,
  }
}
