import { PaymentSelectorOptions, SplitTenderErrors } from './types'
import { SplitTenderPayment } from '@models/Order'
import { isCashPayment, isEbtPayment, isInfinitePayment } from '@models/PaymentMethod'
import { Infinite, Zero } from '@models/Money'
import { MoneyCalc } from '@helpers/money'
import { DataError } from '@shared/Errors'

/**
 * Will validate that the provided options are all valid
 * @param opts the options to validate
 */
export const validateOptions = (opts: PaymentSelectorOptions) => {
  // TODO: Fill out any more validation, for example, ebtTotal should always be less than total amount
  if (MoneyCalc.isGreaterThan(opts.amountEbt, opts.amountTotal)) {
    throw new DataError('EBT amount cannot be greater than the total amount', opts)
  }

  return true
}

/**
 * This function will validate that the splitTender works with the provided options, can be used to determine if the
 * splitTender is valid for new options, and also as a final validation before the payment is submitted
 * @param splitTender The payment split to consider for the validation
 * @param opts The current options to validate against
 */
export const validateSplitTenderWithOptions = (
  splitTender: SplitTenderPayment,
  opts: PaymentSelectorOptions,
): SplitTenderErrors | undefined => {
  // Make sure that split tender is not empty
  if (!splitTender || splitTender.length < 1) {
    return SplitTenderErrors.NO_PAYMENT_SELECTED
  }

  // Make sure there is no partial payment (part paid now and part offline)
  if (splitTender.length > 1) {
    // If there are multiple payments and one of them is Offline, block it (partial payment)
    if (splitTender.some((tender) => isCashPayment(tender.paymentMethod))) {
      return SplitTenderErrors.PARTIAL_PAYMENT
    }
  }
  // Make sure the user entered an EBT amount
  const ebtPay = splitTender?.find((pay) => isEbtPayment(pay.paymentMethod))
  if (ebtPay && !ebtPay?.amount) {
    return SplitTenderErrors.EBT_AMOUNT_NOT_SET
  }

  // Make sure the EBT amount is not greater than the total amount
  if (ebtPay?.amount && MoneyCalc.isGreaterThan(ebtPay.amount, opts.amountEbt)) {
    return SplitTenderErrors.EBT_AMOUNT_NOT_SET
  }
  // Make sure we have an infinite payment or EBT method for future payments if applicable
  if (
    opts.hasFuturePayments &&
    !splitTender.some(({ paymentMethod }) => isInfinitePayment(paymentMethod.source) || isEbtPayment(paymentMethod))
  ) {
    return SplitTenderErrors.NEEDS_INSTALLMENT_PAYMENT
  }
  // Make sure we have an infinite payment method for delivery fees if applicable
  if (opts.hasDelivery && !splitTender.some(({ paymentMethod }) => isInfinitePayment(paymentMethod.source))) {
    return SplitTenderErrors.NEEDS_DELIVERY_PAYMENT
  }

  // Make sure we have enough tender to cover the order total
  const tenderTotal = splitTender.map((pay) => pay.amount ?? Infinite).reduce((a, b) => MoneyCalc.add(a, b), Zero)
  if (MoneyCalc.isGreaterThan(opts.amountTotal, tenderTotal)) {
    return SplitTenderErrors.NOT_ENOUGH_FUNDS
  }
  return undefined
}

/**
 * This function will format an error with a user-friendly message for display
 * @param err The error type to convert to a message
 */
export function displaySplitTenderErrors(err: SplitTenderErrors | undefined) {
  switch (err) {
    case SplitTenderErrors.NEEDS_DELIVERY_PAYMENT:
      return {
        title: 'Payments Missing',
        message: 'Please select a payment method for delivery fees.',
      }
    case SplitTenderErrors.NEEDS_INSTALLMENT_PAYMENT:
      return {
        title: 'Payments Missing',
        message: 'Please select a payment method for installment payments.',
      }
    case SplitTenderErrors.NO_PAYMENT_SELECTED:
      return {
        title: 'Select a payment option',
        message: 'Please select a payment method.',
      }
    case SplitTenderErrors.NOT_ENOUGH_FUNDS:
      return {
        title: 'Select a payment option',
        message: 'Please select an additional payment method.',
      }
    case SplitTenderErrors.EBT_AMOUNT_NOT_SET:
      return {
        title: 'Not EBT amount specified',
        message: 'Please choose an amount you would like to charge to your EBT card.',
      }
    case SplitTenderErrors.PARTIAL_PAYMENT:
      return {
        title: 'Order error',
        message: 'Cannot use offline payment in addition to others. Please select offline or another payment method.',
      }
    case SplitTenderErrors.OPTIONS_INVALID:
      return {
        title: 'Invalid Options',
        message: 'The provided payment selection options are invalid, please contact support.',
      }
    default:
      return {
        title: 'Invalid payments',
        message:
          'Could not determine payment configuration. Please try selecting a different payment option or contact support',
      }
  }
}
