import { AdminOrdersParamList } from '@/admin/navigation/types'
import { Logger } from '@/config/logger'
import { UseApiFxReturn } from '@/hooks/useApiFx'
import { useCartService } from '@/hooks/useCart'
import { useDeepCompareCallback } from '@/hooks/useDeepEqualEffect'
import { KeyedState } from '@/hooks/useKeyedState'
import { useValidateCartAlert } from '@/hooks/useValidateCart'
import { adminCartInfoSelector, adminFarmIdSelector, isWholesaleOrderCreatorSelector } from '@/redux/selectors'
import { cartsCollection } from '@api/framework/ClientCollections'
import { Alert, Toast } from '@elements'
import { SplitTenderPayment } from '@models/Order'
import { isEbtPayment, pmt_CashMethod } from '@models/PaymentMethod'
import { User } from '@models/User'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import { validateEbtPin } from '@screens/PaymentMethods/validateEbtPin'
import { TextInput } from 'react-native'
import { useSelector } from 'react-redux'
import { OrderCreatorStatePayments } from './OrderCreatorScreen.helper'

/** Handler for whe user attempts to place an order. It will verify the order configuration is valid for a new order */
export function usePlaceOrderCheck({
  custId,
  keyedStatePayments: [{ markAsPaidInfo, splitTender }, set],
  customerFx: { data: customer },
  customerInputRef,
  placeOrderProceed,
}: {
  custId: string | undefined
  customerFx: UseApiFxReturn<(custId: string | undefined) => Promise<User | undefined>>
  customerInputRef: React.RefObject<TextInput>
  keyedStatePayments: KeyedState<OrderCreatorStatePayments>
  placeOrderProceed: (payments: SplitTenderPayment, startNew?: boolean) => void
}) {
  const adminFarmId = useSelector(adminFarmIdSelector) || ''
  const isWholesaleOrderCreator = useSelector(isWholesaleOrderCreatorSelector)
  const { cartId } = useSelector(adminCartInfoSelector)
  const { cart } = useCartService({
    cartServiceType: 'orderCreator',
    farmId: adminFarmId,
    isWholesale: isWholesaleOrderCreator,
  })
  const validateCartAlert = useValidateCartAlert({
    cartServiceType: 'orderCreator',
    isWholesale: isWholesaleOrderCreator,
  })
  const navigation = useNavigation<NavigationProp<AdminOrdersParamList, 'OrderCreator'>>()

  return useDeepCompareCallback(
    async (startNew?: boolean) => {
      if (!cartId) return Toast('Cart is not initialized')
      if (!cart.length) return Toast('Cart is empty')

      if (!customer || !custId || customer.id !== custId)
        return Alert(
          'No customer selected',
          'Please select the customer for this order under the "Add Customer" header',
          [{ text: 'Select customer', onPress: () => customerInputRef.current?.focus() }],
        )

      // start loading before any async code
      set('isSubmitting', true)

      try {
        /** Check whether the customer has been assigned to the cart
         * This is a safety measure against the scenario where the cartInit failed to update the cart when we dispatched the selected customer. */
        const cartDoc = await cartsCollection.fetch(cartId)
        if (cartDoc.user?.id !== customer.id) {
          set('isSubmitting', false)
          navigation.setParams({ custId: undefined })
          return Alert(
            'Cart not updated',
            'Something went wrong while assigning the customer to the cart. Please select a customer and try again',
            [{ text: 'Select customer', onPress: () => customerInputRef.current?.focus() }],
          )
        }

        // Validate the cart and show an alert if there's any issues
        const res = await validateCartAlert({ farmId: adminFarmId })

        /** We will only proceed to adding the order if the user didn't cancel on any alert prompts notifying them of cart changes AND the remaining cart isn't empty */
        if (res.cancelCheckout || res.newCartLength === 0) {
          return set('isSubmitting', false)
        }

        // If the order is marked as already paid then we should not check split tender and treat the payment as Offline
        if (markAsPaidInfo.markAsPaid) {
          return placeOrderProceed([{ paymentMethod: pmt_CashMethod }], startNew)
        }

        // Make sure payments are defined, this should never be false after the payment selector confirms it
        // it sets the loading state to false if there's an error
        if (!splitTender || splitTender.length === 0) {
          set('isSubmitting', false)
          return Alert('Order Error', 'No payment methods specified for this order')
        }

        // If there is an ebt payment then check the pin and request it if necessary
        const ebtPayment = splitTender?.find((pmt) => isEbtPayment(pmt.paymentMethod))
        if (ebtPayment && isEbtPayment(ebtPayment.paymentMethod)) {
          const { pin } = await validateEbtPin(ebtPayment.paymentMethod, customer.id, true)
          ebtPayment.paymentMethod.pin = pin
        }

        placeOrderProceed(splitTender, startNew)
      } catch (error) {
        Logger.error(error)
        set('isSubmitting', false)
        return Toast('Something went wrong. Please try again.')
      }
    },
    [
      customer,
      markAsPaidInfo,
      custId,
      placeOrderProceed,
      splitTender,
      cartId,
      cart.length,
      set,
      validateCartAlert,
      adminFarmId,
      customerInputRef,
      navigation,
    ],
  )
}
