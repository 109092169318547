import { FarmStatus } from '@models/Farm'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import * as React from 'react'
import { ReactNode, RefObject, useMemo } from 'react'
import { Platform, ScrollView, ScrollViewProps } from 'react-native'
import { useSelector } from 'react-redux'

import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { KeyboardAvoidingScrollView, KeyboardAvoidingScrollViewRef } from '@elements'
import Colors from '../../constants/Colors'
import { adminFarmSelector } from '../../redux/selectors'
import { AdminDrawerParamList } from '../navigation/types'
import { Banner } from './Banner'
import { Footer } from './Footer'

type Props = {
  children: ReactNode
  hideHeader?: boolean
  hideFooter?: boolean
  variant?: 'white'
  style?: ScrollViewProps['contentContainerStyle']
  customHeader?: ReactNode
} & (
  | {
      /** If true, the AdminView will use the regular ScrollView from react-native core components instead of the KeyboardAvoidingScrollView which is desirable in most circumstances */
      useDefaultScrollView: true
      /** ref that will be passed to the scroll component */
      scrollRef?: RefObject<ScrollView>
    }
  | {
      /** If true, the AdminView will use the regular ScrollView from react-native core components instead of the KeyboardAvoidingScrollView which is desirable in most circumstances */
      useDefaultScrollView?: false
      /** ref that will be passed to the scroll component */
      scrollRef?: KeyboardAvoidingScrollViewRef
    }
)

/** Scroll component that surrounds the screen content for admin screens */
export function AdminView({
  children,
  hideHeader,
  hideFooter,
  scrollRef,
  style,
  variant,
  customHeader,
  useDefaultScrollView = false,
}: Props) {
  const farm = useSelector(adminFarmSelector)
  const navigation = useNavigation<StackNavigationProp<AdminDrawerParamList>>()
  const route = useRoute<RouteProp<AdminDrawerParamList>>()
  const styles = useStyles()

  const goPayments = () => {
    navigation.navigate('FarmSetup', { tab: 'payments' })
  }

  const ScrollComponent = useMemo(
    () => (useDefaultScrollView ? ScrollView : KeyboardAvoidingScrollView),
    [useDefaultScrollView],
  )

  return (
    <>
      {hideHeader ? null : (
        <>
          {farm?.status === FarmStatus.PendingStripe && (
            <Banner success>
              Good Work! Stripe is verifying the information you have provided. We will let you know when your account
              is approved. In the meantime you can start creating your locations and schedules.
            </Banner>
          )}
          {farm?.status === FarmStatus.Claimed && route.name !== 'FarmProfileSetUp' && route.name !== 'Onboard' && (
            <Banner onPress={goPayments}>
              There are steps required before your farm can be approved to sell. Click here to complete the setup
              walk-through.
            </Banner>
          )}
          {farm?.status === FarmStatus.NeedInfoStripe && route.name !== 'FarmSetup' && (
            <Banner onPress={goPayments}>
              Stripe requires more information before you can start selling on GrownBy. Click here to resolve this.
            </Banner>
          )}
        </>
      )}
      {customHeader ? customHeader : null}
      <ScrollComponent
        keyboardShouldPersistTaps="handled"
        style={[styles.scrollView, variant === 'white' && styles.white]}
        // @ts-expect-error // The correct scrollRef type will be enforced by the AdminView Props
        ref={scrollRef}
        scrollIndicatorInsets={{ right: Platform.OS === 'ios' ? 1 : undefined }}
        contentContainerStyle={style}
      >
        {children}
        {hideFooter ? null : <Footer />}
      </ScrollComponent>
    </>
  )
}

const useStyles = () =>
  useLayoutFnStyles(({ left, right }) => ({
    scrollView: {
      paddingLeft: left,
      paddingRight: right,
    },
    white: {
      backgroundColor: Colors.white,
    },
  }))
