import Colors from '@/constants/Colors'
import { Hoverable, Icon, Text } from '@elements'
import { getMessageText, getOtherParticipant, getRelativeTimeString } from '@helpers/messaging'
import { ChatSession } from '@models/Messaging'
import { User } from '@models/User'
import { memo, useMemo } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { Image } from '../../Image'
import { getCardParticipantInfo } from './ConversationCard.helpers'

type ConversationCardProps = {
  chat: ChatSession
  currUserId: User['id']
  onPress: (item: ChatSession) => void
  /** Whether the conversation is selected. Used when selecting a certain conversation in order to see messages */
  isSelected?: boolean
  /** FIXME - Whether it has new (unread) messages */
  hasNewMessage?: boolean
}

/** Card that holds a reference for a chat session, showing the other's participant's info, latest message, etc */
export const ConversationCard = memo(function ConversationCard({
  chat,
  currUserId,
  isSelected,
  hasNewMessage = false,
  onPress,
}: ConversationCardProps) {
  const participantInfo = useMemo(() => {
    const participant = getOtherParticipant(chat.participants, currUserId)

    if (!participant) return undefined
    return getCardParticipantInfo(participant)
  }, [chat.participants, currUserId])

  return !participantInfo ? null : (
    <Hoverable>
      {(isHovered) => (
        <TouchableOpacity onPress={() => onPress(chat)}>
          <View style={[styles.main, (isHovered || isSelected) && styles.hovered]}>
            <View style={styles.imageCont}>
              <Image source={{ uri: participantInfo.logo }} style={styles.image} />
            </View>
            <View style={styles.textCont}>
              <Text size={16}>{participantInfo.name}</Text>
              <Text>{getMessageText(chat.lastMessage)}</Text>
              <Text color={Colors.shades['200']}>{getRelativeTimeString(chat.lastMessage.sentAtUtc.toLocal())}</Text>
            </View>
            <View>
              <Icon
                noHover
                color={Colors.black}
                size={24}
                name={hasNewMessage ? 'mail-unread-outline' : 'mail-outline'}
                iconSet="Ionicons"
              />
            </View>
          </View>
        </TouchableOpacity>
      )}
    </Hoverable>
  )
})

const styles = StyleSheet.create({
  main: {
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    borderTopWidth: 1,
    borderColor: Colors.shades['200'],
  },
  hovered: {
    backgroundColor: Colors.shades['75'],
  },
  imageCont: {
    alignSelf: 'flex-start',
  },
  image: {
    borderRadius: 50,
    height: 50,
    width: 50,
  },
  textCont: {
    gap: 8,
    flex: 1,
  },
})
