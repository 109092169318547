import { StyleSheet, View } from 'react-native'

import { Spinner } from './elements/Spinner'
import { Text, TextH1 } from './elements/Text'

type SpinnerWithTitleProps = {
  title: string
  text?: string
}

export function SpinnerWithTitle({ title, text }: SpinnerWithTitleProps) {
  return (
    <View style={styles.container}>
      <TextH1 style={styles.title}>{title}</TextH1>
      {text ? <Text style={styles.text}>{text}</Text> : null}
      <Spinner />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 30,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 700,
    textAlign: 'center',
  },
  title: {
    padding: 20,
  },
  text: {
    paddingBottom: 10,
  },
})
