import { useFocusFx } from '@/hooks/useFocusFx'
import { useSearchBox } from 'react-instantsearch'

/** Algolia refinement helper to filter data by a string search */
export function useTextRefinement(value: string | undefined) {
  const { refine, clear, query } = useSearchBox()

  /** This keeps the current algolia filter refinement in sync with the refinement value from params */
  useFocusFx(
    () => {
      if (value) {
        refine(value)
      } else {
        clear()
      }
    },
    [clear, refine, value],
    { noRefocus: true },
  )

  return { currentFilter: query, clearRefinement: clear }
}
