import Colors from '@/constants/Colors'
import { Text } from '@elements'
import { formatLongDate } from '@helpers/display'
import { ChatMessage } from '@models/Messaging'

type DateSeparatorProps = {
  item: Pick<ChatMessage, 'sentAtUtc'>
}

/** Chat messages separator that renders formatted date
 * Used to separate messages from different days
 */
export function DateSeparator({ item }: DateSeparatorProps) {
  return (
    <Text center color={Colors.primaryGray}>
      {formatLongDate(item.sentAtUtc.toLocal())}
    </Text>
  )
}
