import { splitToGroups } from '@helpers/helpers'
import { Notification } from '@models/Notification'
import { limit, orderBy, where, writeBatch } from 'firebase/firestore'
import { DateTime } from 'luxon'

import { db } from './db'
import { notificationsCollection } from './framework/ClientCollections'

import { LIMIT_N_NOTIFICATIONS } from '@/redux/actions/appPersist'

// snapshotDeliveredPushNotifications listens for changes to notifications associated with the supplied userId.
// Only notifications that have been delivered are included in the result set to not give the caller access to
// notifications that are scheduled for future delivery.
export function snapshotDeliveredPushNotifications(
  userId: string,
  callback: (notifs: Notification[]) => void,
  limitN = LIMIT_N_NOTIFICATIONS,
): () => void {
  const query = notificationsCollection.query(
    where('user.id', '==', userId),
    where('isDelivered', '==', true),
    where('isRead', '==', false),
    orderBy('date.utc', 'desc'),
    limit(limitN),
  )
  return notificationsCollection.snapshotMany(query, (notifications) => {
    callback(
      notifications.sort((n1, n2) => {
        const a = n1.date ?? DateTime.fromMillis(0)
        const b = n2.date ?? DateTime.fromMillis(0)
        return b.toMillis() - a.toMillis()
      }),
    )
  })
}

export async function loadReadNotes(userId: string) {
  const notes = await notificationsCollection.fetchAll(
    where('user.id', '==', userId),
    where('isDelivered', '==', true),
    where('isRead', '==', true),
    orderBy('date.utc', 'desc'),
    limit(50),
  )

  if (notes.length === 0) return []
  return notes.sort((n1, n2) => {
    const a = n1.date ?? DateTime.fromMillis(0)
    const b = n2.date ?? DateTime.fromMillis(0)
    return b.toMillis() - a.toMillis()
  })
}

export async function markRead(id: string) {
  return notificationsCollection.update({ id, isRead: true })
}

export async function markAllRead(notifications: Notification[]) {
  // Split notifications into batches of 500 to be processed at a time
  const groups = splitToGroups(notifications, 500)
  groups.forEach(async (group) => {
    const batch = writeBatch(db())
    group.forEach((not) => {
      batch.update(notificationsCollection.reference(not.id), { isRead: true })
    })
    await batch.commit()
  })
}
