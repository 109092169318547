import { useCallback, useEffect, useRef, useState } from 'react'
// Hook
// Graciously adapted from https://usehooks.com/useDebounce/
/**
 * This hook is used to debounce a value so that it won't be updated on rapid changes.
 * @param value the value to debounce
 * @param delay the delay to update the latest value
 */
export function useDebouncedValue<T>(value: T, delay = 1000) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay], // Only re-call effect if value or delay changes
  )

  return debouncedValue
}

/**
 * This hook is used to debounce a callback function the output is a function that will be called only after there have
 * been no attempts to call it for 1 second. Note this function will only call the latest callback passed to it, and there
 * is no way to monitor when the execution finishes for this function.
 * @param callback the callback to call
 * @param delay the amount of delay to wait before sending the update (note, changing the delay will not affect an existing scheduled call)
 */
export function useDebouncedCallback<T extends any[]>(callback: (...args: T) => void, delay = 1000) {
  const callbackRef = useRef(callback)
  const timeoutRef = useRef<NodeJS.Timeout>()

  callbackRef.current = callback

  const debouncedCallback = useCallback(
    (...args: T) => {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => {
        callbackRef.current(...args)
      }, delay)
    },
    [delay],
  )

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [delay])

  return debouncedCallback
}
