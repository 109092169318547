import { globalStyles } from '@/constants/Styles'
import { useCancelableFocusFx } from '@/hooks/useCancelablePromise'
import { useHasPermission } from '@/hooks/useHasPermission'
import { withAuth } from '@/hooks/withAuth'
import { SignInSheetParamList } from '@/navigation/types'
import { MessageWithIcon } from '@components'
import { LoadingView } from '@elements'
import { Permission } from '@helpers/Permission'
import { getDisplayName } from '@helpers/client'
import { nonEmptyString } from '@helpers/helpers'
import { StackScreenProps } from '@react-navigation/stack'
import { memo, useMemo } from 'react'
import { useSignInSheetContext } from './useSignInSheetData'

type SignInSheetScreenProps = StackScreenProps<SignInSheetParamList>

/** Fetches data, protects the sign in sheet screens behind permission check */
function withDataAndPermissionCheck<P extends SignInSheetScreenProps>(Component: React.ComponentType<P>) {
  const NewComponent = memo(function (props: P): JSX.Element {
    const { signInSummary, onFetchAllData, dataLoading } = useSignInSheetContext()
    const hasPermission = useHasPermission()
    const params = props.route.params

    useCancelableFocusFx(
      async (isCurrent) => {
        onFetchAllData(params.id, isCurrent)
      },
      [onFetchAllData, params.id],
      { noRefocus: true },
    )

    const isAuthorized = useMemo(() => {
      // If the user has farm permissions, they can access the page
      if (hasPermission(Permission.Distributions)) {
        return true
      }

      // If the sign in summary is shareable and the token matches, they can access the page
      if (
        nonEmptyString(signInSummary?.share?.token) &&
        nonEmptyString(params.token) &&
        signInSummary.share.token === params.token
      ) {
        return true
      }

      return false
    }, [hasPermission, params.token, signInSummary?.share?.token])

    return (
      <LoadingView style={globalStyles.flex1} loading={dataLoading}>
        {isAuthorized ? (
          <Component {...props} />
        ) : (
          <MessageWithIcon icon="user-times" title="Permission denied">
            You don't have permission to access this page.
          </MessageWithIcon>
        )}
      </LoadingView>
    )
  })

  NewComponent.displayName = `WithDataAndAuth(${getDisplayName(Component)})`
  return NewComponent
}

/** Adds an additional auth requirement on top of the data fetching and permission check */
export function withDataAndAuth<P extends SignInSheetScreenProps>(Component: React.ComponentType<P>) {
  return withAuth(withDataAndPermissionCheck(Component))
}
