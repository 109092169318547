import { DateTime } from 'luxon'

/** Timezone identifies a timezone name (e.g. America/New_York). */
export type Timezone = string

/** dateTimeInZone returns a DateTime that is initialized in the supplied timezone. For transitionary purposes,
 * the timezone defaults to UTC. */
export function dateTimeInZone(tz: Timezone = 'UTC'): DateTime {
  return DateTime.local().setZone(tz)
}
