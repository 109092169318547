import { PaymentOptionsContext, usePaymentOptionsData } from './PaymentOptionsContext'
import { ModalInline } from '@elements'
import { PaymentSelectionModal } from './PaymentSelectionModal'
import { PaymentSelectorOptions } from './helpers/types'
import React from 'react'
import { SplitTenderPayment } from '@models/Order'
import { User } from '@models/User'
import { PaymentOptionsModalContext, usePaymentOptionsModalData } from './PaymentOptionsModalContext'
import { PaymentSelectorDisplay } from './PaymentSelectorDisplay'

type Props = {
  userId: User['id']
  /** This unique ID will determine if we should reset the payment configuration. Each place that implements PaymentSelector
   * can determine the appropriate uniqueID */
  uniqueId: string

  /** Will allow controlling the selector options from the outside, this must be a memoized field */
  options: PaymentSelectorOptions

  /** This is the callback for when splitTender is saved or marked invalid and should update caller */
  onSplitTenderUpdated(splitTender: SplitTenderPayment | undefined): void

  disabled?: boolean
}

/** This component displays the selected payment on checkout with the option to edit*/
export function PaymentSelector({ userId, uniqueId, options, onSplitTenderUpdated, disabled }: Props) {
  const data = usePaymentOptionsData(userId, options, uniqueId, onSplitTenderUpdated)
  const modalData = usePaymentOptionsModalData()

  return (
    <PaymentOptionsModalContext.Provider value={modalData}>
      <PaymentOptionsContext.Provider value={data}>
        <ModalInline
          visible={modalData.isModalOpen}
          // This is an iOS only prop and helps us with knowing when the new modal is safe to open after closing the previous
          onDismiss={modalData.onModalDismiss}
          header
          title="Choose a payment method"
        >
          <PaymentSelectionModal />
        </ModalInline>
        <PaymentSelectorDisplay onPress={modalData.openLocalModal} disabled={disabled} />
      </PaymentOptionsContext.Provider>
    </PaymentOptionsModalContext.Provider>
  )
}
