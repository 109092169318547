import { DateTime, DurationLike } from 'luxon'

import { isInstance, isObject, nonEmptyString } from './helpers'
import { objToStr } from './log'
import { isLuxonDateTime } from './time'
import { isImmutable, TypedFn } from './typescript'

type CachedComputeReturn<CacheType, ArgsType extends Parameters<any>> = {
  cachedFn: TypedFn<CacheType, ArgsType>
  clearCache(): void
  cache: Map<string, CacheItem<CacheType>>
  /** Enables and disables caching, useful for testing to configure at a root level if we want to have caching work */
  toggleEnabled: () => void
}

type CacheItem<CacheType> = { data: CacheType; stamp: number; nCalls: number }

/**  CachedCompute returns a function that attempts to find an item by ID, caching the result for subsequent computations.
If the ID supplied to the computeFn is in the cache, the cached value will be returned. Otherwise the computeFn
will be called and its result will be stored in the cache for future computations.
@param computeFn is the function whose result will be cached.
@param encodeArgs is a function that receives the computeFn's arguments and returns a string that identifies the unique combination of arguments for the computeFn. When the resulting id matches the id of an earlier call the cached result will be returned. By default the encodeArgs converts the arguments into strings in a basic way.
@param cacheDuration is the lifetime of a cached result. Results older than this will be replaced with new calls.
*/
export function CachedCompute<CacheType, ArgsType extends Parameters<any>>(
  computeFn: TypedFn<CacheType, ArgsType>,
  encodeArgs: (...args: ArgsType) => string = encodeArgsBasic,
  cacheDuration: DurationLike = { minutes: 5 },
): CachedComputeReturn<CacheType, ArgsType> {
  let cache = new Map<string, CacheItem<CacheType>>()
  let isEnabled = true

  function cachedFn(...args: ArgsType): CacheType {
    const argsId = encodeArgs(...args)
    if (isEnabled && cache.has(argsId)) {
      const { data, stamp, nCalls } = cache.get(argsId)!
      cache.set(argsId, { data, stamp, nCalls: nCalls + 1 })

      if (stamp >= DateTime.now().minus(cacheDuration).toMillis()) return data
    }
    const data = computeFn(...args)
    const stamp = DateTime.now().toMillis()
    cache.set(argsId, { data, stamp, nCalls: 1 })
    return data
  }

  return { cachedFn, clearCache: () => (cache = new Map()), cache, toggleEnabled: () => (isEnabled = !isEnabled) }
}

/** Creates a string representation of a set of function arguments
 * - For best results while encoding objects with optional properties as arguments, default values should be provided to any optional properties. This would ensures the cache will recognize them under the same key, whether optional values are specified or not from the outside.
 */
export const encodeArgsBasic = <ArgsType extends Parameters<any>>(...args: ArgsType): string => {
  return args
    .map((a): string => {
      if (Array.isArray(a)) {
        return encodeArgsBasic(...a)
      } else if (isObject(a)) {
        // If the object has an id, use that as arg reference
        if ('id' in a && nonEmptyString(a['id'])) {
          return a['id']
        }
        return encodeArgsBasic(
          //Object data should be encoded as "key:value", and sorted alphabetically.
          ...Object.entries(a)
            .map(([k, v]) => `${k}:${encodeArgsBasic(v)}`)
            .sort((a, b) => (a < b ? -1 : 1)),
        )
      } else if (typeof a === 'function') {
        return `[function:${a.name || 'AnonymousFn'}]`
      } else if (isInstance(a)) {
        if (isLuxonDateTime(a)) {
          return a.toMillis().toString()
        }
        return `[class:${Object.getPrototypeOf(a) ?? 'unknown'}]`
      } else if (isImmutable(a)) return String(a)
      else return objToStr(a)
    })
    .join(',')
}
