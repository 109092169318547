import { DrawerActions, useNavigation } from '@react-navigation/native'
import { StyleSheet, TouchableOpacity } from 'react-native'

import { Icon } from './elements/Icon/Icon'
import Colors from '../constants/Colors'
import { isWeb } from '../constants/Layout'
import { SHADOW_10 } from '../constants/Styles'

import { useDeviceSize } from '@/hooks/useLayout'

type Props = {
  style?: any
  clear?: boolean
  isAdmin?: boolean
}

export default function HamburgerMenu({ style, clear, isAdmin = false }: Props) {
  const navigation = useNavigation()
  const { isSmallDevice } = useDeviceSize()

  const openSideBar = () => {
    navigation.dispatch(DrawerActions.openDrawer())
  }

  /// Hide this when UserDropdown is showing (non small devices)
  if ((!isWeb && !isAdmin) || !isSmallDevice) return null
  return (
    <TouchableOpacity
      activeOpacity={0.8}
      onPress={openSideBar}
      style={[styles.baseMenu, !clear && styles.menuContainer, style]}
    >
      <Icon name="bars" />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  baseMenu: {
    padding: 15,
  },
  menuContainer: {
    ...SHADOW_10,
    borderRadius: 10,
    backgroundColor: Colors.shades['50'],
  },
})
