import { PaymentSchedule, Share } from '@models/Product'
import { memo, useContext } from 'react'

import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { AddBtnShare_Reusable } from '../../../OrderCreatorScreen/components/AddCartButtons/ShareBtn_Reusable'
import { OrderEditContext } from '../../useOrderEditData'

export type AddBtnShareProps = {
  prod: Share
  /** The payment schedule for the product in the cart. It is not optional because it is meant to receive the value from the paySchedule selector for the row which is initially undefined */
  paySchedule: PaymentSchedule | undefined
}

export const AddBtnShare = memo(function AddBtnShare({ prod, paySchedule }: AddBtnShareProps) {
  const {
    draftOrderFx: { data: draft },
    keyedStateProds: [{ schedule, csa }],
  } = useContext(OrderEditContext)

  if (!draft || draft.isWholesale === undefined) return null
  return (
    <AddBtnShare_Reusable
      cartServiceType="orderEdit"
      csa={csa}
      isWholesale={draft.isWholesale}
      paySchedule={paySchedule}
      prod={prod}
      schedule={schedule}
    />
  )
}, propsAreDeepEqual)
