import { DropdownMenu, Icon, Text } from '@elements'
import { freqToString } from '@helpers/display'
import { getPaymentSchedules } from '@helpers/order'
import { CartItem } from '@models/Order'
import { PaymentSchedule } from '@models/Product'
import { useMemo, useState } from 'react'
import { View } from 'react-native'

import Colors from '../../../../../constants/Colors'
import { globalStyles } from '../../../../../constants/Styles'

import { AutoCompleteProps } from '@/hooks/useAutoComplete'

type PayScheduleSelectorProps = Pick<CartItem, 'product' | 'pickups' | 'price'> & {
  /** callback for when a payment schedule is selected */
  setter(ps: PaymentSchedule): void
  /** an optional initial payment schedule */
  paySchedule?: PaymentSchedule
}
/** Small dropdown menu with the payment schedules as options */
export function PayScheduleSelector({ product, setter, paySchedule, pickups, price }: PayScheduleSelectorProps) {
  const [ACprops, setACprops] = useState<AutoCompleteProps<any> | null>(null)
  const autoSelectKey = `payScheduleSelector_${product.id}` //Helps determine whether the arrow should point up or down
  const isOpen = ACprops?.value === autoSelectKey
  const buttons = useMemo(() => {
    try {
      // If the price is undefined and this is a standard product, this will throw an error
      return getPaymentSchedules({ product, pickups, price }).map((ps) => ({
        title: freqToString(ps.frequency),
        onPress: () => setter(ps),
      }))
    } catch (error) {
      return []
    }
  }, [product, pickups, price, setter])

  return (
    <DropdownMenu buttons={buttons} data={buttons} autoSelectKey={autoSelectKey} onChangeState={setACprops}>
      <View style={globalStyles.flexRowCenter}>
        <Text style={{ color: Colors.blue }}>
          {paySchedule ? freqToString(paySchedule.frequency) : 'Select Payment Schedule'}
        </Text>
        <Icon size={16} color={Colors.shades['500']} name={isOpen ? 'chevron-up' : 'chevron-down'} />
      </View>
    </DropdownMenu>
  )
}
