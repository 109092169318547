import { Text } from '@elements'
import React, { memo } from 'react'
import { View } from 'react-native'
import { DateData } from 'react-native-calendars/src/types'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import Colors from '../../../../constants/Colors'
import { DateMarking, DistroMarking } from '../ProductForm/AvailabilityOverview'

export const AvailabilityDayComponent = memo(function AvailabilityDayComponent({
  dateMap,
  date,
}: {
  dateMap: DateMarking
  date?: DateData
}) {
  const styles = useStyles()
  if (!date) return null

  const distros = dateMap[date.dateString]
  return (
    <View key={date.dateString} style={[styles.dayContainer, { height: 25 * Math.max(distros?.length || 0, 4) }]}>
      <Text style={styles.dayString} type="medium">
        {date.day}
      </Text>
      <View>
        {distros?.map((dist: DistroMarking) => (
          <View style={styles.distContainer} key={`${date.dateString}_${dist.id}`}>
            <View style={[styles.distColor, { backgroundColor: dist.color }]} />
            <Text numberOfLines={1} style={styles.distName}>
              {dist.name}
            </Text>
          </View>
        ))}
      </View>
    </View>
  )
})

const useStyles = CreateResponsiveStyle(
  {
    dayContainer: {
      width: '100%',
      justifyContent: 'space-between',
      height: 100,
      borderTopWidth: 1,
      borderLeftWidth: 1,
      marginTop: 0,
      marginBottom: 0,
      borderColor: Colors.shades['100'],
    },
    dayString: {
      margin: 10,
      marginBottom: 0,
    },
    distContainer: { flexDirection: 'row', alignItems: 'center' },
    distColor: { width: 12, height: 12, borderRadius: 12, marginLeft: 5 },
    distName: { flex: 1, marginHorizontal: 5 },
  },
  {
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      distColor: { width: 10, height: 10, borderRadius: 10, marginLeft: 5 },
    },
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      distColor: { width: 8, height: 8, borderRadius: 8, marginLeft: 4 },
    },
  },
)
