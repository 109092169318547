import { AutoCompleteInput, AutoCompleteInputProps } from '@elements'
import { AlgoliaAdminProduct, FILTERS_QUERY } from '@models/Algolia'
import connectAutocomplete, {
  AutocompleteConnectorParams,
  AutocompleteWidgetDescription,
} from 'instantsearch.js/es/connectors/autocomplete/connectAutocomplete'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { useConfigure, useConnector } from 'react-instantsearch'
import { Hit } from 'react-instantsearch-core'
import { useSelector } from 'react-redux'

import { AutoCompleteItem } from '@/hooks/useAutoComplete'
import { useDeepCompareMemo } from '@/hooks/useDeepEqualEffect'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { withAdminIndexHooks } from '@/hooks/withAlgoliaIndex'
import { adminFarmSelector } from '@/redux/selectors'
import { buildQueryFilter } from '@helpers/algolia-client'
import { useDebouncedValue } from '../../hooks/useDebounce'

type Props = {
  onSelect: (item: AlgoliaAdminProduct) => void
  inline?: AutoCompleteInputProps<any>['inline']
}

function ProductAutoCompleteComp({ onSelect: onSelectProp, inline = false }: Props) {
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(false)
  const farm = useSelector(adminFarmSelector)

  const debouncedSearchTerm = useDebouncedValue(searchTerm)

  useConfigure({
    /** Only non hidden products will be available to be set as suggested products */
    filters: buildQueryFilter([`farmId:${farm.id}`, FILTERS_QUERY.Product, FILTERS_QUERY.NotHidden]),
    hitsPerPage: 5,
  })

  const { indices, refine, currentRefinement } = useConnector<
    AutocompleteConnectorParams,
    AutocompleteWidgetDescription
  >(connectAutocomplete)

  const items = useDeepCompareMemo(() => {
    //This `unknown` is necessary because the default `Hit` type from algolia hooks is coercive and incompatible with the `Hit` type from react-instantsearch-core
    const hits = (indices?.[0]?.hits ?? []) as unknown as Hit<AlgoliaAdminProduct>[]
    return hits.map((prod) => ({ text: prod.name, data: prod }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indices?.[0]?.hits])

  /** Refines search from the most recent searchTerm.  */
  useEffect(() => {
    refine(debouncedSearchTerm) //If empty string, it will clear the search
  }, [debouncedSearchTerm])

  /** Disables loader when currentRefinement reflects new searchTerm */
  useEffect(() => {
    if (loading && (debouncedSearchTerm === currentRefinement || !debouncedSearchTerm)) setLoading(false)
    // Only needs to re-run on change to debouncedSearchTerm and currentRefinement
  }, [currentRefinement, debouncedSearchTerm])

  const onChangeText = useCallback((t: string) => {
    setLoading(true)
    setSearchTerm(t)
  }, [])

  const onSelect = useCallback(
    (itm: AutoCompleteItem<Hit<AlgoliaAdminProduct>>) => {
      setSearchTerm('') //clear the search when user selected
      onSelectProp(itm.data)
    },
    [onSelectProp],
  )

  const clear = useCallback(() => setSearchTerm(''), [])

  const styles = useStyles()

  return (
    <AutoCompleteInput
      inline={inline}
      contStyle={styles.inputContainer}
      style={styles.input}
      value={searchTerm}
      onChangeText={onChangeText}
      items={items}
      onSelect={onSelect}
      autoCompleteId="productSearch"
      options={{ matchWidth: true }}
      placeholder="Search for products"
      loading={loading}
      clear={searchTerm ? clear : undefined}
    />
  )
}

export const ProductAutoComplete = memo(withAdminIndexHooks(ProductAutoCompleteComp))

const useStyles = () =>
  useSizeFnStyles(({ isLargeDevice, isMedDevice }) => ({
    inputContainer: {
      marginVertical: isLargeDevice ? 15 : isMedDevice ? 10 : 5,
    },
    input: { padding: 10 },
  }))
