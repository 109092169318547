import { Text } from '@elements'
import { AntDesign, Fontisto } from '@expo/vector-icons'
import { GestureResponderEvent, LayoutChangeEvent, TouchableOpacity, View } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import { GrayCircle } from './HelperComponents'
import Colors from '../../../constants/Colors'
import { globalStyles, SHADOW_5 } from '../../../constants/Styles'
import { useDeviceSize } from '../../../hooks/useLayout'

type ButtonProps = {
  onPress?: (evt: GestureResponderEvent) => void
  onWidth?: (evt: LayoutChangeEvent) => void
}

//The MapKeyButton can be used alone (in overlay mode), or in conjunction with the MapKey component (below). In overlay mode, everything is handled by MapKeyButton. In non-overlay mode, the MapKey must be imported separately, and state for showing/hiding the MapKey must be handled by the parent component.
//By default, the MapKey will work in overlay-mode in small devices
function MapKeyButton({ onPress, onWidth }: ButtonProps) {
  const { isSmallDevice } = useDeviceSize()
  const styles = useStyles()

  return (
    <View style={styles.buttonCont} onLayout={onWidth}>
      <TouchableOpacity onPress={onPress} style={styles.buttonStyle}>
        <Text>{isSmallDevice ? 'Key' : 'Map Key'}</Text>
        <View style={styles.plusBtn}>
          <AntDesign name="plus" size={isSmallDevice ? 15 : 19} />
        </View>
      </TouchableOpacity>
    </View>
  )
}
export default MapKeyButton

type MapKeyProps = {
  onPress?: (evt: GestureResponderEvent) => void
}

export function MapKey({ onPress }: MapKeyProps) {
  const styles = useStyles()

  return (
    <TouchableOpacity style={styles.mapKeyWrapper} onPress={onPress}>
      <View style={styles.item}>
        <Text style={styles.text}>Map Key</Text>
        <View style={globalStyles.flex1}>
          <AntDesign name="minus" size={26} style={styles.flexEnd} />
        </View>
      </View>

      <View style={styles.item}>
        <Fontisto name="map-marker" size={30} style={styles.marginHorizontal} color={Colors.blue} />
        <Text style={styles.text}>Farm or pickup location</Text>
      </View>

      <View style={styles.item}>
        <View style={styles.marginHorizontal}>
          <Fontisto name="map-marker" size={30} color={Colors.blue} />
          <Text style={styles.pickupCount}>4</Text>
        </View>
        <Text style={styles.text}>Multiple farm distributions at same location</Text>
      </View>

      <View style={styles.item}>
        <GrayCircle visible />
        <Text style={styles.text}>Farm not yet selling on GrownBy</Text>
      </View>
    </TouchableOpacity>
  )
}

const useStyles = CreateResponsiveStyle(
  {
    buttonCont: {
      height: 40,
      width: 130,
      marginLeft: 10,
      marginBottom: 10,
      justifyContent: 'flex-end',
    },
    mapKeyWrapper: {
      ...SHADOW_5,
      flex: 1,
      backgroundColor: Colors.white,
      borderRadius: 10,
      margin: 10,
      justifyContent: 'flex-end',
      paddingHorizontal: 20,
      paddingVertical: 10,
      maxWidth: 400,
    },
    buttonStyle: {
      flexDirection: 'row',
      backgroundColor: Colors.white,
      borderColor: Colors.semiTransparent,
      paddingHorizontal: 10,
      paddingVertical: 10,
      borderRadius: 10,
      alignItems: 'center',
    },
    item: {
      flexDirection: 'row',
      marginBottom: 12,
    },
    text: {
      alignSelf: 'center',
      marginLeft: 5,
    },
    pickupCount: {
      position: 'absolute',
      top: 3,
      left: 7,
      color: Colors.white,
    },
    overlay: {
      padding: 0,
      borderRadius: 10,
    },
    plusBtn: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    marginHorizontal: {
      marginHorizontal: 5,
    },
    flexEnd: {
      alignSelf: 'flex-end',
    },
  },
  {
    [maxSize(DEVICE_SIZES.SM)]: {
      buttonCont: {
        width: 60,
        marginBottom: 4,
        marginLeft: 5,
      },
      mapKeyWrapper: {
        marginBottom: 4,
        marginLeft: 5,
      },
    },
  },
)
