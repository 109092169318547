import { Logger } from '@/config/logger'
import { loadVerifyEmail, processVerifyingEmail } from '@api/ExternalLinks'
import { MessageWithIcon } from '@components'
import { Icon, LoadingView, SafeAreaView, Toast } from '@elements'
import { dateTimeInZone } from '@models/Timezone'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useState } from 'react'

import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { useFocusFx } from '@/hooks/useFocusFx'
import { VerifyEmailResult } from '@shared/types/v2/user'
import { ExternalLinkingParamList } from '../../navigation/types'

type ErrorType = {
  title: string
  icon: string
  message: string
}

const Errors: { [keys: string]: ErrorType } = {
  expired: {
    title: 'Expired Link',
    icon: 'user-clock',
    message:
      'This link has expired, please request a new link to verify your email by going to the Profile/Personal Information page and click green verify Email link.',
  },
  invalid: {
    title: 'Invalid Link',
    icon: 'exclamation-triangle',
    message:
      'This link does not exist or can not be loaded, if you reached this page in error click the X to exit and return home.',
  },
  failed: {
    title: 'Failed to Verify Email',
    icon: 'exclamation-triangle',
    message:
      'Something went wrong, please try again later or contact support if the issue persists. Please click the X to exit and return home.',
  },
}

/** VerifyEmailComp is for verify-email external link. */
export function VerifyEmailComp() {
  const { params } = useRoute<RouteProp<ExternalLinkingParamList, 'VerifyEmail'>>()
  const navigation = useNavigation<StackNavigationProp<ExternalLinkingParamList, 'VerifyEmail'>>()

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<ErrorType>()

  useFocusFx(() => {
    if (params.id)
      loadVerifyEmail(params.id)
        .then(async (data) => {
          const now = dateTimeInZone()
          if (data.type !== 'verify-email') return setError(Errors.invalid)
          if (data.expireDate < now) return setError(Errors.expired)

          await processVerifyingEmail({
            email: data.user.email,
            userId: data.user.id,
            externalId: params.id,
          })
            .then((result) => {
              if (result === VerifyEmailResult.SUCCESS) {
                Toast('Email verified successfully!')
              } else {
                Toast('Email verification failed.')
                setError(Errors.failed)
              }
            })
            .catch((err) => {
              Logger.error(err)
              Toast('Email verification failed.')
              setError(Errors.failed)
            })
        })
        .catch((err) => {
          Logger.error(err)
          setError(Errors.invalid)
        })
        .finally(() => setIsLoading(false))
    else {
      setError(Errors.invalid)
      setIsLoading(false)
    }
  }, [params.id])

  return (
    <SafeAreaView style={globalStyles.flex1}>
      {!isLoading && (
        <Icon
          name="times"
          size={36}
          color={Colors.shades[500]}
          style={globalStyles.margin20}
          onPress={() => navigation.navigate('Consumer')}
        />
      )}
      <LoadingView
        loading={isLoading}
        spinnerProps={{ size: 'large' }}
        error={error?.message}
        messageWithIconProps={{ title: error?.title, icon: error?.icon }}
      >
        <MessageWithIcon
          icon="check-circle"
          iconColor={Colors.green}
          title="Email Verified"
          children="Your email has been successfully verified."
        />
      </LoadingView>
    </SafeAreaView>
  )
}
