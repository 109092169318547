import { createNumberMask, Mask, Masks } from 'react-native-mask-input'

export const DOLLAR_MASK = createNumberMask({
  prefix: ['$'],
  delimiter: ',',
  separator: '.',
  precision: 2,
})

const moneyPrefix = (sigDigits: number) => {
  if (sigDigits === 0) return ''
  if (sigDigits === 1) return '$0.0'
  if (sigDigits === 2) return '$0.'
  return '$'
}
// Will mask a number dollar amount padding it with the correct number of zeros for cents
export const MONEY_MASK = (sigDigits: number) => {
  const prefix = moneyPrefix(sigDigits)

  return createNumberMask({
    prefix: [prefix],
    delimiter: ',',
    separator: '.',
    precision: 2,
  })
}

// Will mask a credit card wth correct number groupings
const CREDIT_CARD_MASK = (text = '') => {
  const rawValue = text.replace(/\D+/g, '')
  // Amex cards are 15 digits and always start with 34 or 37, so we should format them as expected then use the default mask for all other credit cards
  if (rawValue.startsWith('34') || rawValue.startsWith('37')) {
    return [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/]
  } else {
    return Masks.CREDIT_CARD
  }
}

// Will mask a MM/YY date
const DATE_MMYY_MASK: Mask = [
  /[0-1]/, // First digit of the month can be 0 or 1
  /\d/, // Second digit of the month can be any digit
  '/', // Separator
  /\d/, // First digit of the year can be any digit
  /\d/, // Second digit of the year can be any digit
]

// create a mask for any number
export const NUMBER_MASK = createNumberMask({
  delimiter: '',
  separator: '.',
  precision: 2,
})

// eslint-disable-next-line import/no-default-export
export default {
  DOLLAR_MASK,
  MONEY_MASK,
  NUMBER_MASK,
  CREDIT_CARD_MASK,
  DATE_MMYY_MASK,
}
