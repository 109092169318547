import { Logger } from '@/config/logger'
import { auth } from '@api/db'
import {
  MultiFactorError,
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
} from 'firebase/auth'

const RECAPTCHA_CONTAINER = 'recaptcha-container'

/** This class is handles all the state and methods for signing a user in with multi-factor */
export class MultiFactorLogin {
  /** The resolver is used to resolve web multi-factor error */
  resolver: MultiFactorResolver

  verificationId: string | null = null
  recaptchaVerifier: RecaptchaVerifier
  /** phoneNumberHint will contain a masked version of the phone number to display to the user */
  phoneNumberHint: string | null = null

  constructor(error: MultiFactorError) {
    this.resolver = auth().getMultiFactorResolver(error)
    this.recaptchaVerifier = new RecaptchaVerifier(RECAPTCHA_CONTAINER, { size: 'invisible' }, auth().getAuth())
    //@ts-ignore phoneNumber says it doesn't exist but it does
    this.phoneNumberHint = this.resolver.hints[0].phoneNumber ?? null
  }
  /** On mobile, we require both the resolver and native resolver to handle auth, web it is a no-op*/
  async initializeMobile(_email: string, _password: string) {
    // no-op
  }
  /** This function will send the multiFactor verification code when the user attempts to login */
  async sendCodeWhenLogin() {
    try {
      const phoneInfoOptions = {
        multiFactorHint: this.resolver.hints[0],
        session: this.resolver!.session,
      }
      const phoneAuthProvider = new PhoneAuthProvider(auth().getAuth())
      // Send SMS verification code.
      this.verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, this.recaptchaVerifier)
    } catch (e) {
      //reset reCAPTCHA if error
      this.recaptchaVerifier.clear()
      Logger.error(e)
      throw e
    }
  }
  /** This function is called after the user enters the code sent to their phone. It will log them in */
  async confirmCodeWithLogin(code: string) {
    if (!this.verificationId) return

    try {
      // Ask user for the SMS verification code to generate credential
      const cred = PhoneAuthProvider.credential(this.verificationId, code)
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)
      // Complete sign-in
      await this.resolver.resolveSignIn(multiFactorAssertion)
    } catch (e) {
      Logger.error(e)
      throw e
    }
  }
}
