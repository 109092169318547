import { HomeParamList } from '@/navigation/types'
import { GooglePlacesSearchProps } from '@components'
import { DropdownMenuBtn } from '@elements'
import { entries } from '@helpers/typescript'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import {
  LocTypeDisplay,
  locTypeToDisplayMap,
  submitEstablishment,
  submitLogistics,
  useInitialAddress,
} from '@screens/SearchScreen/searchScreen-helpers'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

/** Business logic adapter for the logistics on Search screen */
export function useLogistics() {
  const dispatch = useDispatch()
  const navigation = useNavigation<StackNavigationProp<HomeParamList, 'SearchScreen'>>()
  const { locType = 'zip', center, region } = useRoute<RouteProp<HomeParamList, 'SearchScreen'>>().params ?? {}
  const [errorStr, setErrorStr] = useState('')
  const initialAddressFx = useInitialAddress({ currentParams: { center, region } })

  /** This stores the handler used internally by google places search for on submit. It needs to be a ref because if you store this in state there will be an error saying you can't set state in this component while rendering the google places search component.
   * - We need to do this because it enables the user to just hit the submit button and not be required to focus the input and select an autocomplete item from the results. If we did not do this, then the user might be confused because the input text shows their address, but they would still be required to click on the input, select an autocomplete item, and then click the submit button. So this is supporting a significantly better user experience.
   */
  const submitCurrentAddress = useRef<
    Parameters<NonNullable<GooglePlacesSearchProps<any>['getOnSubmit']>>[0] | undefined
  >(undefined)

  /** This is the locType display derived from the location type parameter */
  const locTypeDisplay = useMemo<LocTypeDisplay>(() => {
    if (!locType) {
      throw new Error('There should be an initial value for the location type display')
    }
    const locTypeDisplay = locTypeToDisplayMap[locType]

    if (!locTypeDisplay) {
      throw new Error('The display location should always match a location type parameter.')
    }
    return locTypeDisplay
  }, [locType])

  const locTypeButtons = useMemo<DropdownMenuBtn[]>(
    () =>
      entries(locTypeToDisplayMap).map(([k, v]) => ({
        title: v,
        onPress: () =>
          submitLogistics({
            locType: k,
            dispatch,
            setErrorStr,
            submitCurrentAddress,
            handleParams: navigation.setParams,
            onPlaceNotFound: 'handleParams',
          }),
      })),
    [navigation, dispatch, setErrorStr],
  )

  const onSelectEstablishment = useCallback<NonNullable<GooglePlacesSearchProps<any>['onSelectEstablishment']>>(
    (item) => submitEstablishment({ place: item, locType, dispatch, setErrorStr, handleParams: navigation.setParams }),
    [dispatch, locType, setErrorStr, navigation.setParams],
  )

  const onClearAddress = useCallback(
    () => navigation.setParams({ center: undefined, radius: undefined, region: undefined }),
    [navigation],
  )

  return {
    onClearAddress,
    onSelectEstablishment,
    locTypeButtons,
    locTypeDisplay,
    submitCurrentAddress,
    initialAddressFx,
    errorStr,
  }
}
