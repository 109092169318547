import { cartsCollection, draftOrdersCollection } from '@api/framework/ClientCollections'
import { ErrorCode, isErrorWithCode, isNotFound } from '@shared/Errors'

/** Fetches the draft order for order editing, and customizes the error messages for that use case */
export async function fetchDraftForEdit(id: string) {
  try {
    return draftOrdersCollection.fetch(id)
  } catch (error) {
    if (isErrorWithCode(error, ErrorCode.MissingData)) {
      // The navigation param is missing
      throw new Error(
        'The screen is missing a draft order id. Please return to the orders list and select a draft order to edit.',
      )
    } else if (isNotFound(error)) {
      throw new Error('The specified draft order could not be found')
    } else
      throw new Error(
        'An unexpected error prevented the draft order data from being fetched. Please try again or reach out to support.',
      )
  }
}

/** Fetches the cart for a draft order, and customizes the error messages to that specific use case */
export async function fetchDraftCart(id: string) {
  try {
    return cartsCollection.fetch(id)
  } catch (error) {
    if (isNotFound(error)) {
      throw new Error('The items for this draft order were not found. Please try again or reach out to support.')
    } else
      throw new Error(
        'An unexpected error prevented the draft order items from being fetched. Please try again or reach out to support.',
      )
  }
}
