import { DateTime } from 'luxon'
import { Farm } from './Farm'
import { User } from './User'

export type ChatSession = {
  id: string
  /** Participants are stored as an object to make it easier to query by participants */
  participants: Record<string, AccountParticipant | CustomerParticipant>
  /** This will hold all user participants involved in the chat session. It should be customers as well as all users that
   * are Admin or Managers on the farm when there is no lastRespondedUser this list can be used to determine who to send notifications too */
  participantIds: User['id'][]
  /** This will hold a list of users that have not read the last message. It is used for querying all unread messages by participant. */
  unreadIds: User['id'][]
  /** Will hold the last message and can be used for showing a snippet as well as showing notification badges */
  lastMessage: ChatMessage
}

type AccountParticipant = {
  /** Common field to facilitate db querying */
  participantId: Farm['id']
  account: Pick<Farm, 'id' | 'name' | 'logo'>
  /** This field will be used to determine who should get notifications for chat messages. We will only send to the last farm user that responded. Will be undefined initially for a new session so that all admin get the message */
  lastRespondedUser?: Pick<User, 'id'>
  /** Last read will be used in the UI to show the last message that the user has read */
  lastRead?: DateTime
  /** This will be used for querying conversations that are unread*/
  hasUnreadMessage: boolean
  /** */
  type: ParticipantType.farm
}

type CustomerParticipant = {
  /** Common field to facilitate db querying */
  participantId: User['id']
  user: Pick<User, 'id' | 'name' | 'avatar'>
  /** Last read will be used in the UI to show the last message that the user has read as well as if they have any unread messages */
  lastRead?: DateTime
  /** This will be used for querying conversations that are unread*/
  hasUnreadMessage: boolean
  /** */
  type: ParticipantType.user
}

/** Whether the chat participant is a Farm Account participant */
export function isAccountParticipant(value: AccountParticipant | CustomerParticipant): value is AccountParticipant {
  return value.type === ParticipantType.farm
}

export type ChatMessage = {
  id: string
  /** This will show the name of the logged-in user that replied, not the name of the farm when a farmer is messaging */
  sender: Pick<User, 'id' | 'name'>
  /** The date when the message was sent.
   * - Should be UTC because the timezone of the farm / user is not always available
   * - Should be converted to local datetime when used on UI
   */
  sentAtUtc: DateTime
  /** Wil hold the message content. For now we are only supporting text messages but can support image in the future*/
  content: ImageMessage | TextMessage
  chatSession: Pick<ChatSession, 'id'>
}

type ImageMessage = {
  url: string
  type: 'image'
}
type TextMessage = {
  content: string
  type: 'text'
}

export type MessageParticipant = {
  id: string
  type: ParticipantType
}

/** Properties required to send a chat message */
export type SendMessageProps = {
  /** The sender of the message, including timezone and sender details. */
  from: MessageParticipant & {
    sender: ChatMessage['sender']
  }
  /** The recipient of the message */
  to: MessageParticipant
  /** The content of the chat message */
  message: ChatMessage['content']
  /** The ID of the chat session, if it exists */
  chatSessionId: string | undefined
}

export enum ParticipantType {
  farm = 'farm',
  user = 'user',
}
