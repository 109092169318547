import Colors from '@/constants/Colors'
import { format } from '@helpers/time'
import { DateTime } from 'luxon'

/* A date with a toggled selected state */
export type SelectableDate = { date: DateTime; selected: boolean }

/** Similar to a selectable date but this has data for the UI based on the selected status */
export type DisplayDate = {
  selected: boolean
  selectedColor: string
  disabled: boolean
}

/** Maps dates as keys with display data for a given date */
export type DatesMapDisplay = Record<string, DisplayDate>

export const INITIAL_DISPLAYDATE: DisplayDate = { disabled: false, selected: false, selectedColor: Colors.green }

/** converts a date into a string key for the dates map display */
export const getDateKey = (date: DateTime): string => format(date, 'yyyy-MM-dd')
