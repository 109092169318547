import { useCallback } from 'react'

import { RootState } from '@/redux/reducers/types'
import { useSelectorRoot } from '@/redux/selectors'

/**
 * Accessor for avail addons anywhere. Intended for the consumer side.
 *
 * @returns availAddons calculation results and their matching csas and schedules
 */
export const useAvailAddons = () => {
  const selectorFn = useCallback(
    ({ appPersist: { availAddonCsas, availAddons } }: RootState) => ({
      /** This includes only the availAddons where isAvail is true, meaning they are available to add to cart. Use this when you only need to access the available addons for adding to cart and don't care to explain why a given addon may not be in the list */
      availAddonsIds: availAddons.filter(({ isAvail }) => !!isAvail).map((res) => res.id),

      /** This includes all the availAddons results, which will include those where isAvail is false, and their reason for being unavailable. Use this when you need to explain why an addon is not available */
      availAddonsResults: availAddons,

      /** availAddonCsas includes the CSAs for any addons in the list, whether available or not */
      availAddonCsas,
    }),
    [],
  )
  return useSelectorRoot(selectorFn)
}
