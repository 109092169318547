import Colors from '@/constants/Colors'
import { isWeb } from '@/constants/Layout'
import { Button, typography } from '@elements'
import { useCallback, useState } from 'react'
import { StyleSheet, TextInput, View } from 'react-native'

type ConversationInputProps = {
  onSendMessagePress: (msg: string) => void
}

/** TextInput wrapper that follows the design for chat screen */
export function ConversationInput({ onSendMessagePress }: ConversationInputProps) {
  const [isFocused, setIsFocused] = useState(false)
  const [input, setInput] = useState('')

  const onSendPress = useCallback(() => {
    onSendMessagePress(input)
    setInput('')
  }, [input, onSendMessagePress])

  return (
    <View style={[styles.main, isFocused && styles.focused]}>
      <TextInput
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholderTextColor={Colors.shades['200']}
        style={[styles.input, isWeb && styles.webInput]}
        placeholder="Type your message"
        multiline
        value={input}
        onChangeText={setInput}
      />
      {/* FIXME: Update button to accept multiple icon sets https://github.com/farmgenerations/grownby/issues/9315*/}
      <Button disabled={!input.length} onPress={onSendPress} style={styles.sendBtn} small title="Send" />
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    borderWidth: 1,
    borderRadius: 10,
    borderColor: Colors.shades['200'],
    margin: 10,
    gap: 10,
  },
  input: {
    minWidth: 50,
    fontFamily: typography.body.regular,
    flex: 1,
    padding: 10,
  },
  focused: {
    borderColor: Colors.green,
  },

  webInput: {
    // This will remove the default border applied on focus (WEB only) as it will change the borderColor only for the input and will not include the icons as well
    //@ts-ignore
    outlineStyle: 'none',
  },
  sendBtn: {
    alignSelf: 'flex-start',
    marginRight: 5,
    marginTop: 5,
  },
})
