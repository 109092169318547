import { omit } from '@helpers/typescript'
import { PaymentMethod } from '@models/PaymentMethod'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { prepareMarshal, prepareUnmarshal } from './encoding'

/** marshalPaymentMethod returns Firestore marshalled data for the supplied PaymentMethod. */
export function marshalPaymentMethod(location: Partial<PaymentMethod>): DocumentData {
  return omit(prepareMarshal(location), 'id') as DocumentData
}

/** unmarshalPaymentMethod returns a PaymentMethod from the supplied Firestore data. */
export function unmarshalPaymentMethod(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): PaymentMethod {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)
  return { ...data, id } as PaymentMethod
}
