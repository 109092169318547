import { isPrimary, Product } from '@models/Product'
import { Money, Zero } from '@models/Money'
import { MoneyCalc } from '@helpers/money'
import { calculateSharePricePerPickup } from '@helpers/products'
import { productsCollection } from './framework/ClientCollections'
import { ErrorWithCode } from '@shared/Errors'

export type CustomShareData = {
  /** The CSA group for the custom products*/
  csa: string
  /** Price will be the amount of the most expensive installment option */
  price: Money
  /** We are just passing a few fields of the product back which will be used on the client */
  product: Pick<Product, 'id' | 'name' | 'images'>
  /** The promo code for the product */
  promoCode: string
}

/**
 * Load all data for the custom share to allow the user to customize
 */
export async function loadCustomShareData(productId: string, promoCode: string) {
  const prod = await productsCollection.fetch(productId)
  if (!isPrimary(prod) || !prod.csa[0])
    throw new ErrorWithCode({
      code: 'INVALID_PRODUCT',
      devMsg: 'Your share could not be customized please contact support.',
    })
  // Will get the most expensive installment option
  const productPerPickupPrice = prod.paymentSchedules.reduce(
    (maxPrice, schedule) => MoneyCalc.max(maxPrice, calculateSharePricePerPickup(prod, schedule)),
    Zero,
  )

  return {
    product: prod,
    price: productPerPickupPrice,
    csa: prod.csa[0],
    promoCode,
  }
}
