import { FontAwesome } from '@expo/vector-icons'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { format } from '@helpers/time'
import { LocationSummary } from '@models/Summary'
import { DateTime } from 'luxon'
import { memo } from 'react'
import { TouchableOpacity, View } from 'react-native'

import { styles } from './styles'
import { Divider } from '../../../components/elements/Divider'
import { Text } from '../../../components/elements/Text'
import { useDimensionalSort, useSort } from '../../../hooks/useSort'
import { adminCard } from '../../constants/AdminCards'

import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { selectedUnitName } from '@helpers/reports'

type LocationSummaryProps = {
  summary: LocationSummary
}

type RowDataType = {
  date: DateTime
  locationName: string
  orderCount: number
  items: { productName: string; quantity: number; unit: string }[]
}

type RowType = {
  locationName: string
  data: (index: number) => RowDataType
}

function LocationSummarySheet({ summary }: LocationSummaryProps) {
  const itemSorter = useDimensionalSort<{ productName: string; quantity: number; unit: string }>()

  const rows = summary.items.map(({ location, date, items, orderCount }) => {
    const unsortedItems = items.flatMap((item) => {
      const productName = item.product.name
      return item.selectedUnits.map((selectedUnit) => ({
        productName,
        quantity: selectedUnit.quantity,
        unit: selectedUnitName(item, selectedUnit),
      }))
    })

    return {
      locationName: location.name,
      data: (index: number): RowDataType => ({
        date,
        locationName: location.name,
        orderCount,
        items: itemSorter.at(index).apply(unsortedItems),
      }),
    }
  })
  const sorter = useSort<RowType>()
  const sortedRows = sorter.apply(rows)
  const localStyles = useStyles()

  return (
    <View>
      {sortedRows.map((item, index) => (
        <View key={index}>
          <View style={localStyles.container}>
            <View style={localStyles.sortContainer}>
              <Text style={localStyles.divText1}>{item.data(index).locationName}</Text>
              <TouchableOpacity onPress={() => sorter.sortBy('locationName')}>
                <FontAwesome name="sort" size={15} color="black" />
              </TouchableOpacity>
            </View>
            <Text style={localStyles.divText2}>
              {item.data(index).orderCount} Orders - {format(item.data(index).date, 'MM/dd/yyyy')}
            </Text>
            <View style={styles.tableHeader}>
              <View style={styles.sortContainer}>
                <Text style={styles.divTextHeader}>Product</Text>
                <TouchableOpacity onPress={() => itemSorter.at(index).sortBy('productName')}>
                  <FontAwesome name="sort" size={15} color="black" />
                </TouchableOpacity>
              </View>

              <View style={styles.sortContainer}>
                <Text style={styles.divTextHeader}>Qty</Text>
                <TouchableOpacity onPress={() => itemSorter.at(index).sortBy('quantity')}>
                  <FontAwesome name="sort" size={15} color="black" />
                </TouchableOpacity>
                <Text style={styles.divTextHeader}>| Unit</Text>
                <TouchableOpacity onPress={() => itemSorter.at(index).sortBy('unit')}>
                  <FontAwesome name="sort" size={15} color="black" />
                </TouchableOpacity>
              </View>
            </View>
            {item.data(index).items.map((subitem, subindex) => (
              <View key={subindex}>
                <Divider />
                <View style={styles.itemWrapper}>
                  <Text style={localStyles.subText}>{subitem.productName}</Text>
                  <Text style={localStyles.subText}>
                    {subitem.quantity} | {subitem.unit}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      ))}
    </View>
  )
}

export default memo(LocationSummarySheet, propsAreDeepEqual)

const useStyles = () =>
  useLayoutFnStyles((layout) => ({
    container: {
      ...adminCard(layout),
      alignSelf: 'center',
      marginBottom: 20,
      padding: 0,
      borderRadius: 10,
      marginHorizontal: 20,
      width: layout.width / 1.1,
    },
    sortContainer: {
      ...styles.sortContainer,
      alignItems: 'center',
      justifyContent: 'center',
    },
    divText1: {
      ...styles.divText,
      fontSize: 18,
      marginBottom: 10,
      marginTop: 20,
      fontWeight: '400',
    },
    divText2: {
      ...styles.divText,
      fontWeight: 'normal',
      fontSize: 16,
      paddingBottom: 8,
      marginVertical: 10,
    },
    subText: {
      ...styles.subtext,
      paddingHorizontal: 15,
    },
  }))
