import { Image, View } from 'react-native'
import Colors from '@/constants/Colors'
import { SectionContainer } from '@screens/FarmerOnboard/components'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { HeaderText, Text } from '@elements'
import { FarmerOnboardTestimonialData, farmerOnboardTestimonialsData } from '@/assets/images/farmer_onboard'
import { useDeviceSize } from '@/hooks/useLayout'

/**
 * `TestimonialSection` is a functional component that renders a section displaying testimonials from farmers.
 * It showcases what farmers are saying about the platform, providing social proof and building trust with potential users.
 */
export function TestimonialSection() {
  const styles = useTestimonialSectionStyles()
  const { isSmallDevice } = useDeviceSize()

  return (
    <>
      <HeaderText size={isSmallDevice ? 34 : 44} style={styles.greenText}>
        What Farmers are Saying
      </HeaderText>
      <SectionContainer outerContainerStyle={styles.outerContainer} innerContainerStyle={styles.innerContainer}>
        {farmerOnboardTestimonialsData.map((value, index) => (
          <TestimonialCard key={index + '_testimonial_card'} {...value} />
        ))}
      </SectionContainer>
    </>
  )
}

const useTestimonialSectionStyles = () =>
  useSizeFnStyles(({ isSmallDevice }) => ({
    innerContainer: {
      width: '100%',
    },
    outerContainer: { paddingBottom: 40 },
    greenText: {
      color: Colors.darkGreen,
      fontStyle: 'italic',
      textAlign: 'center',
      paddingVertical: isSmallDevice ? 1 : 15,
    },
  }))
function TestimonialCard({ src, description, testimonialsName, from }: FarmerOnboardTestimonialData) {
  const styles = useTestimonialCardStyles()

  return (
    <View style={styles.card}>
      <Image source={src} style={styles.image} />
      <View style={styles.textContainer}>
        <Text size={23} style={styles.description}>
          {description}
        </Text>
        <Text size={15} style={styles.nameAndFrom}>
          {testimonialsName}
        </Text>
        <Text size={15} style={styles.nameAndFrom}>
          {from}
        </Text>
      </View>
    </View>
  )
}

const useTestimonialCardStyles = () =>
  useSizeFnStyles(({ isSmallDevice, isMedDevice, isLargeDevice }) => ({
    card: {
      backgroundColor: Colors.white,
      // width: isSmallDevice ? '100%' : isLargeDevice ? 420 : 390,
      width: isSmallDevice ? '100%' : isLargeDevice ? '32%' : 390,
      maxWidth: 600,
      height: 700,
      padding: 0,
      borderRadius: 30,
      margin: 8,
      borderWidth: 1,
      borderColor: Colors.green,
    },
    image: {
      width: '100%',
      height: '60%',
      borderTopLeftRadius: 30,
      borderTopRightRadius: 30,
    },
    textContainer: {
      flex: 1,
      padding: 15,
    },
    description: {
      textAlign: 'left',
    },
    nameAndFrom: {
      marginTop: 5,
      textAlign: 'left',
    },
  }))
