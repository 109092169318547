import { useState } from 'react'
import * as React from 'react'
import { Text, View, StyleSheet } from 'react-native'

import EmailLogin from './EmailLogin'
import EmailRegister from './EmailRegister'
import EmailReset from './EmailReset'

export enum EmailTypes {
  SignIn = 0,
  Register = 1,
  Reset = 2,
}

export default function Email({ type = EmailTypes.SignIn }: { type?: EmailTypes }) {
  const [currType, setType] = useState<EmailTypes>(type)
  const [email, setEmail] = React.useState<string>('')
  return (
    <View style={styles.container}>
      {currType === EmailTypes.SignIn && <EmailLogin email={email} setEmail={setEmail} />}
      {currType === EmailTypes.Register && <EmailRegister email={email} setEmail={setEmail} />}
      {currType === EmailTypes.Reset && <EmailReset email={email} setEmail={setEmail} />}

      {currType === EmailTypes.SignIn ? (
        <>
          <Text style={{ fontSize: 16, margin: 8, marginVertical: 16, width: '100%' }}>
            Need to create an account?{' '}
            <Text style={{ fontWeight: 'bold' }} onPress={() => setType(EmailTypes.Register)}>
              Register.
            </Text>
          </Text>
          <Text style={{ fontSize: 16, marginHorizontal: 8 }}>
            Forgot password?{' '}
            <Text style={{ fontWeight: 'bold' }} onPress={() => setType(EmailTypes.Reset)}>
              Reset it.
            </Text>
          </Text>
        </>
      ) : (
        <Text style={{ fontSize: 16, margin: 8 }}>
          Already have an account?{' '}
          <Text style={{ fontWeight: 'bold' }} onPress={() => setType(EmailTypes.SignIn)}>
            Sign in.
          </Text>
        </Text>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
