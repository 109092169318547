import { Certifications } from '@/components'
import { globalStyles } from '@/constants/Styles'
import { useDeviceSize } from '@/hooks/useLayout'
import { ShoppingStackParamList } from '@/navigation/types'
import { Image } from '@components'
import { Button, Divider, HeaderText, Modal, Text, Toast } from '@elements'
import { formatShortAddress } from '@helpers/display'
import { Farm, FarmStatus } from '@models/Farm'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { memo, useCallback } from 'react'
import { StyleSheet, View } from 'react-native'
import { InviteFarm } from '../InviteFarm'
import { FarmStats } from './FarmStats'

type Props = {
  farm?: Farm
  isStarred?: boolean
  numFavorites: number
  setIsFavoriteCallbackOpen: (val: boolean) => void
  shareFarm?: () => void
}

export const StickyFarmHeader = memo(function Sticky({
  farm,
  isStarred,
  numFavorites,
  setIsFavoriteCallbackOpen,
  shareFarm,
}: Props) {
  const { isSmallDevice } = useDeviceSize()
  const navigation = useNavigation<StackNavigationProp<ShoppingStackParamList, 'FarmShop'>>()

  const onActionPress = useCallback(() => {
    if (farm?.status === FarmStatus.Registered) {
      navigation.navigate('FarmShop', { farmSlug: farm.urlSafeSlug })
    } else {
      // We should not be able to get here until the farm is loaded, but if we do show a message.
      if (!farm) {
        Toast('Please wait until the farm is loaded.')
      } else {
        Modal(<InviteFarm farm={farm} />, { title: 'Invite to GrownBy' })
      }
    }
  }, [navigation, farm])

  if (!farm) return null

  return (
    <View>
      {!!farm.properties && <Certifications certs={farm.properties} />}
      <View style={styles.farmHeader}>
        <Image type="logo" style={styles.farmLogo} source={{ uri: farm.logo }} />
        <View style={globalStyles.flex1}>
          <HeaderText size={24}>{farm.name}</HeaderText>
          <Text size={14}>{formatShortAddress(farm.address)}</Text>
        </View>
      </View>
      <FarmStats
        farm={farm}
        isStarred={isStarred}
        numFavorites={numFavorites}
        onIconPress={() => setIsFavoriteCallbackOpen(true)}
      />
      <Divider clear />
      {!isSmallDevice && (
        <>
          <Button
            style={styles.stickyMainBtn}
            title={farm.status === FarmStatus.Registered ? 'Shop Now' : 'Invite to GrownBy'}
            onPress={onActionPress}
          />
          <View style={globalStyles.flexRow}>
            <Button
              style={styles.stickyBtnsRow}
              title={`${isStarred ? 'Favorited' : 'Favorite'}`}
              outline={!isStarred}
              icon="heart"
              onPress={() => setIsFavoriteCallbackOpen(true)}
            />
            <Button style={styles.stickyBtnsRow} title="Share" outline icon="share" onPress={shareFarm} />
          </View>
        </>
      )}
    </View>
  )
})

const styles = StyleSheet.create({
  stickyBtnsRow: { flex: 1, justifyContent: 'center' },
  stickyMainBtn: { paddingHorizontal: 80 },

  farmHeader: {
    marginVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  farmLogo: {
    width: 60,
    height: 60,
    marginRight: 10,
    borderRadius: 30,
  },
})
