/** OutOfStockError identifies a product added to a cart which is out of stock. */
export class OutOfStockError extends Error {
  constructor() {
    super('Product is out of stock')
    Object.setPrototypeOf(this, OutOfStockError.prototype)
  }
}

/** Error denoting the product's stock does not support the quantities in the cart item */
export class InsufficientStockError extends Error {
  constructor() {
    super('Product stock is insufficient')
    Object.setPrototypeOf(this, InsufficientStockError.prototype)
  }
}

/** InvalidArgumentError identifies an invalid attempt to add a product to cart, due to the request data being malformed or incomplete */ export class InvalidArgumentError extends Error {
  constructor(reason = '') {
    super('Invalid argument: ' + reason)
    Object.setPrototypeOf(this, InvalidArgumentError.prototype)
  }
}

/** InvalidQuantityError is thrown when the quantity specified is not within the valid range. */
export class InvalidQuantityError extends Error {
  constructor(msg?: string) {
    super(msg ?? 'Quantity must be greater than or equal to zero')
    Object.setPrototypeOf(this, InvalidQuantityError.prototype)
  }
}

/** ItemNotInCartError is thrown when the a specified cart item is not found. */
export class ItemNotInCartError extends Error {
  constructor() {
    super('Item not in cart')
    Object.setPrototypeOf(this, ItemNotInCartError.prototype)
  }
}
