import { Logger } from '@/config/logger'
import { getPersistor, getStore, isPersistorSet } from '@/redux/store'
import { Toast } from '@elements'
import { errorToString, retry } from '@helpers/helpers'
import AsyncStorage from '@react-native-async-storage/async-storage'
import * as Notifications from 'expo-notifications'
import { auth, isAuthSet } from '../../api/db'
import { ErrorWithCode } from '@shared/Errors'
import { signOutAction } from './user'

/**
 * This helper will handle signing a user out of the app in a complete sense.
 * - Includes firebase auth signout.
 * - Includes calling the redux signout action, which clears state and calls listener cleanups.
 * - Development: Signout can also be used as a quick way to reset the state in the mobile dev app, useful if mobile is slow, and is easier than reinstalling the app
 */
export default async function signOut() {
  try {
    await Promise.all([
      // Firebase signout with retry
      retry(async () => {
        if (isAuthSet()) {
          await auth().signOut()
        }
      }),

      // Clearing persistor and AsyncStorage with retry
      retry(async () => {
        if (isPersistorSet()) {
          await getPersistor().purge()
          await AsyncStorage.clear()
        }
      }),

      // Resetting notification badge count with retry
      retry(() => Notifications.setBadgeCountAsync(0)),
    ])
  } catch (error) {
    Logger.error(new ErrorWithCode({ code: 'signout-error', devMsg: errorToString(error), data: error }))
    Toast('Something went wrong in signout')
  } finally {
    // Dispatching signout action
    /** getStore() is the most convenient way to do this because this signOut helper needs to be used in places where you can't use redux easily like the error boundary */
    getStore().dispatch(signOutAction())
  }
}
