import { PaymentSchedule, Share } from '@models/Product'
import { memo, useContext } from 'react'

import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { OrderCreatorScreenContext } from '../../OrderCreatorScreen.helper'
import { AddBtnShare_Reusable } from './ShareBtn_Reusable'

export type AddBtnShareProps = {
  prod: Share
  /** The payment schedule for the product in the cart. It is not optional because the user must select a value in the selector UI which is in the share row */
  paySchedule: PaymentSchedule | undefined
}

export const AddBtnShare = memo(function AddBtnShare({ prod, paySchedule }: AddBtnShareProps) {
  const {
    isWholesaleOrderCreator,
    keyedStateProdsFilters: [{ schedule, csa }],
  } = useContext(OrderCreatorScreenContext)

  if (isWholesaleOrderCreator === undefined) return null
  return (
    <AddBtnShare_Reusable
      cartServiceType="orderCreator"
      csa={csa}
      isWholesale={isWholesaleOrderCreator}
      paySchedule={paySchedule}
      prod={prod}
      schedule={schedule}
    />
  )
}, propsAreDeepEqual)
