import { useFocusFx } from '@/hooks/useFocusFx'
import { HomeParamList } from '@/navigation/types'
import { milesToKm } from '@helpers/coordinate'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import { LocType } from '../searchScreen-helpers'

/** Will set a default radius to params once if the locType is coords and no radius is defined */
export function useSetInitialRadius(locType: LocType | undefined, radiusParam: number | undefined) {
  const navigation = useNavigation<NavigationProp<HomeParamList, 'SearchScreen'>>()

  useFocusFx(
    () => {
      if (locType !== 'coord' || typeof radiusParam === 'number') return
      navigation.setParams({ radius: Math.floor(milesToKm(100) * 1000) })
    },
    [navigation, radiusParam, locType],
    { noRefocus: true, once: true },
  )
}
