import { Picker, PickerProps, getPickerItem } from '@elements'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { isObject } from '@helpers/helpers'
import { memo } from 'react'

import { useFlatStyle } from '@/hooks/useMergeStyle'
import { StyleSheet } from 'react-native'
import { sortByName } from '@helpers/sorting'

/** Simple offline alternative to ConnectedRefinementDropdown, meant for use with the TableMenu of OfflineTable.
 *
 * - This serves as an adapter to ensure settings passed into the picker are adequate for using it with search refinement functions in the onValueChange prop. Settings required for search-related requirements should be adapted at this level, and not in the lower-level components such as DropdownPicker or RNPickerSelect.
 */
const OfflineRefinementDropdown = memo(function OfflineRefinementDropdown({
  items,
  style,
  placeholder,
  ...rest
}: PickerProps) {
  return (
    <Picker
      style={useFlatStyle([styles.picker, style])}
      items={items.sort((a, b) => sortByName(a, b, (itm) => itm.label ?? itm.value ?? ''))}
      placeholder={getOfflineRefinementPlaceholder(placeholder)}
      {...rest}
    />
  )
},
propsAreDeepEqual)

export default OfflineRefinementDropdown

/** Creates a placeholder for the dropdown picker, which is safe for using the picker with search refinements
 *
 * - TLDR: The main goal of this helper is to ensure the placeholder item object doesn't have an undefined value.
 */
export const getOfflineRefinementPlaceholder = (placeholder: PickerProps['placeholder']) => {
  return typeof placeholder === 'string'
    ? { key: undefined, value: placeholder, label: placeholder } //In this item, the value must not be undefined, to prevent algolia refinement issues. This is different from the default placeholder in the lower-level/s, in which undefined value is a valid type. This is an important part of this component as an adapter of the more generic pickers for use in the OfflineTable with search refinements
    : isObject(placeholder)
    ? { ...getPickerItem(placeholder), value: placeholder.value ?? placeholder.label } //If it's an item object, get only the values supported, in case it's an extended object.
    : placeholder === null
    ? null // If it's null, pass it down, which means no placeholder
    : { key: undefined, value: 'Select item...', label: 'Select item...' } //If undefined, create a default placeholder whose value is NOT undefined, for the same reason, to prevent this from being called on mount, with an undefined value passed into the search refinement helper
}

const styles = StyleSheet.create({
  picker: {
    flex: 1,
    minWidth: 150,
    margin: 2,
  },
})
