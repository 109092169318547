import { Farm } from '@models/Farm'
import { User } from '@models/User'

import { filterChatsBySearchValue } from '@helpers/messaging'
import { ChatMessage, ChatSession, SendMessageProps } from '@models/Messaging'
import { limit, orderBy, where } from 'firebase/firestore'
import { chatMessagesCollection, chatSessionsCollection } from './framework/ClientCollections'
import { callEndpoint } from './v2'

/** TODO: Should be replaced by instant messaging feature now */
/** sendUserMessage sends a message to the user. */
export async function sendUserMessage(
  to: User,
  from: Farm,
  farmerId: string,
  subject: string,
  message: string,
): Promise<void> {
  return await callEndpoint('v2.Messaging.sendUserMessageService', {
    customerId: to.id,
    farmId: from.id,
    farmerId,
    subject,
    message,
  })
}

/** Will send a chat message to a participant */
export async function sendChatMessage({ to, from, message, chatSessionId }: SendMessageProps) {
  return callEndpoint('v2.Messaging.sendChatMessageService', { to, from, message, chatSessionId })
}

/** Will create a snapshot listener for the conversations that a participant is in */
export function conversationListener(
  callback: (conversations: ChatSession[]) => void,
  onError: (err: Error) => void,
  userId: string,
  onlyUnread: boolean,
  searchValue: string,
  farmId?: string,
) {
  const participantId = farmId ?? userId

  const participantQuery = onlyUnread
    ? // This will select conversations where the participantId is included and has unread notifications
      where('unreadIds', 'array-contains', userId)
    : // This will select conversations where the participantId is included
      where('participantIds', 'array-contains', userId)

  const query = chatSessionsCollection.query(
    // Load only conversations that the user is apart of
    participantQuery,
    orderBy('lastMessage.sentAtUtc.utc', 'desc'),
    limit(10),
  )

  return chatSessionsCollection.snapshotMany(
    query,
    (chats) => callback(filterChatsBySearchValue(chats, participantId, searchValue)),
    onError,
  )
}

/** Will create a snapshot listener for the chat messages of a conversation */
export function chatMessageListener(
  callback: (messages: ChatMessage[]) => void,
  onError: (err: Error) => void,
  chatSessionId: string,
) {
  const query = chatMessagesCollection.resolve(chatSessionId).query(orderBy('sentAtUtc.utc', 'desc'), limit(10))

  return chatMessagesCollection.snapshotMany(query, callback, onError)
}
