import { hasOwnProperty } from '@helpers/helpers'
import { Image as ExpoImage } from 'expo-image'
import React, { memo } from 'react'
import { Image as NativeImage, ImageProps as NativeImageProps, ImageURISource } from 'react-native'

import {
  FARM_IMAGE_PLACEHOLDER,
  FARM_LOGO_PLACEHOLDER,
  PRODUCT_IMAGE_PLACEHOLDER,
  PROFILE_IMAGE_PLACEHOLDER,
} from '../constants/Placeholders'

/** Image resize suffix, applied on the url */
export enum ResizedSuffix {
  // TODO: Temporarily using large size for thumbs until 3.5 when we generate better sized thumbs
  THUMB = '400x400',
  LARGE = '1200x800',
  // Will skip resizing when we pass full as resize option
  NONE = '',
}

export type ImageProps = NativeImageProps & {
  type?: 'profile' | 'farm' | 'product' | 'logo'
  resizeSuffix?: ResizedSuffix
}

/** Given an image type will return the placeholder image */
const getPlaceholder = (type?: ImageProps['type']) => {
  if (type === 'profile') return PROFILE_IMAGE_PLACEHOLDER
  if (type === 'product') return PRODUCT_IMAGE_PLACEHOLDER
  if (type === 'logo') return FARM_LOGO_PLACEHOLDER
  return FARM_IMAGE_PLACEHOLDER
}

/** Will get the resized version of a uri image */
const getResizedImageUrl = (originalUrl: string, suffix: ResizedSuffix) => {
  const split = originalUrl.split('?')
  const extension = split[0].endsWith('.png') ? '.png' : ''
  if (split[0].endsWith('.png')) {
    split[0] = split[0].replace('.png', '')
  }
  return split[0] + '_' + suffix + extension + '?' + split[1]
}

/** This will route the image to the correct component based on the source type */
export function Image({ source, ...rest }: ImageProps) {
  if (hasOwnProperty(source, 'uri')) {
    return <ExpoRemoteImage uri={(source as ImageURISource).uri} {...rest} />
  }
  return <NativeImage source={source} {...rest} />
}

/** Expo image implementation
 * @param type The type of the image
 * @param uri The remote address of the image. Resizing can be added, in order to improve performance
 * - Should not have `source` as a param, because only `uri` is used in the first place, and it will break the memoization
 */
export const ExpoRemoteImage = memo(function ExpoRemoteImage({
  uri,
  type,
  resizeSuffix = ResizedSuffix.LARGE,
  style,
}: Omit<ImageProps, 'source'> & { uri?: string }) {
  return (
    <ExpoImage
      contentFit="cover"
      source={canBeResized(uri, resizeSuffix) ? getResizedImageUrl(uri!, resizeSuffix) : uri}
      transition={100}
      placeholderContentFit="cover"
      placeholder={getPlaceholder(type)}
      style={style}
    />
  )
})

/** Whether resizing can be applied to the image */
export const canBeResized = (uri: string | undefined, resize: ResizedSuffix): boolean =>
  (resize !== ResizedSuffix.NONE && uri?.startsWith('https://firebasestorage.googleapis.com/')) ?? false
