import { ViewStyle } from 'react-native'

import Colors from '@/constants/Colors'
import { Layout } from '../../constants/types'

/**
 * Global admin styles go here
 * @deprecated use `AdminCard` component instead
 */
export const adminCard = (layout: Layout): ViewStyle => ({
  width: layout.width * 0.95,
  borderRadius: 10,
  marginTop: layout.height / 30,
  padding: 20,
  borderColor: Colors.shades['100'],
  borderWidth: 1,
})
