import * as Device from 'expo-device'
import { DeviceType } from 'expo-device'
import * as ScreenOrientation from 'expo-screen-orientation'
import { Platform, ScaledSize } from 'react-native'
import { EdgeInsets } from 'react-native-safe-area-context'

/** Specifies the width of the admin sidebar. This width will be subtracted from the layoutSelector's layout.width */
export const SIDEBAR_WIDTH = 250

export enum DEVICE_SIZES {
  EXTRA_SMALL_DEVICE = -1,
  SMALL_DEVICE = 0,
  MEDIUM_DEVICE = 1,
  LARGE_DEVICE = 2,
  EXTRA_LARGE_DEVICE = 3,
}

/** Will compute the content area on the admin side depending on if the drawer is open or closed */
export function getAdminWidth(width: number) {
  // The drawer will be open when the device is greater than 768 (meaning medium or larger)
  if (width > SIDEBAR_WIDTH && width > 768) width -= SIDEBAR_WIDTH
  return width
}

/** Will compute the device size from the given width */
export function getDeviceSize(width: number, opts?: { isAdmin?: boolean }) {
  // This will subtract the sidebar width on admin if the sidebar is open
  const computedWidth = opts?.isAdmin ? getAdminWidth(width) : width

  if (computedWidth > 1200) return DEVICE_SIZES.EXTRA_LARGE_DEVICE
  if (computedWidth > 992) return DEVICE_SIZES.LARGE_DEVICE
  if (computedWidth > 768) return DEVICE_SIZES.MEDIUM_DEVICE
  if (computedWidth > 540) return DEVICE_SIZES.SMALL_DEVICE
  return DEVICE_SIZES.EXTRA_SMALL_DEVICE
}

/** Sets the custom insets handle for the device.
 * - We're supposed to use these instead of the ones from useSafeAreaInsets, because here we're customizing some values. */
export function getCustomInsets(insets: EdgeInsets) {
  if (Platform.OS === 'android') {
    /** Inset top in ios has an extra padding by default, while android is exact (0). So we add this to the inset top on android to make them equal. (Makes android have extra 10, like ios)
     * - This will make it so that you can put content directly against the safe area, and it will look ok and be the exact same on both platforms. */
    return {
      top: insets.top + insetTopCompensation,
      bottom: insets.bottom + insetTopCompensation,
      right: insets.right,
      left: insets.left,
    }
  } else {
    return insets
  }
}

/** This device size will be kept up-to-date by setDeviceSize/ useLayout, on changes to window dimensions */
export let DEVICE_SIZE: DEVICE_SIZES = DEVICE_SIZES.MEDIUM_DEVICE
export const setDeviceSize = (layout: ScaledSize, isAdmin?: boolean) =>
  (DEVICE_SIZE = getDeviceSize(layout.width, { isAdmin }))

const withSize = (layout?: ScaledSize, isAdmin?: boolean): DEVICE_SIZES =>
  layout?.width ? getDeviceSize(layout?.width, { isAdmin }) : DEVICE_SIZE

/** DEPRECATED. PLEASE TRANSITION TO USING `{isSmallDevice} = useLayout()` INSTEAD
 * - Layout can be passed to these to get dynamic size. Without it the size will only be updated on reRender
 * - On admin size, if layout.width is passed, they will reflect the width not including the sidebar.
 */
export const isSmallDevice = (layout?: ScaledSize, isAdmin?: boolean) =>
  withSize(layout, isAdmin) <= DEVICE_SIZES.SMALL_DEVICE
export const isMedDevice = (layout?: ScaledSize, isAdmin?: boolean) =>
  withSize(layout, isAdmin) === DEVICE_SIZES.MEDIUM_DEVICE
export const isLargeDevice = (layout?: ScaledSize, isAdmin?: boolean) =>
  withSize(layout, isAdmin) >= DEVICE_SIZES.LARGE_DEVICE

// Tablets will be medium size in portrait and large in landscape
export const isTablet = (layout?: ScaledSize, isAdmin?: boolean) =>
  withSize(layout, isAdmin) >= DEVICE_SIZES.MEDIUM_DEVICE && isMobile
export const isWeb = Platform.OS === 'web'
export const isMobile = Platform.OS === 'android' || Platform.OS === 'ios'

export const insetTopCompensation = isMobile ? 10 : 0

export const setOrientation = async () => {
  const device = await Device.getDeviceTypeAsync()
  if (device === DeviceType.PHONE && isMobile)
    await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP)
}
