import * as yup from 'yup'
import { mainNumPatternForms } from '@helpers/display'
import { nonEmptyString } from '@helpers/helpers'
import { getState } from '@/assets/data/states'
import { TestContext } from 'yup'

/*
 * Yup validation for address fields
 * If any of the address fields are filled, all of them must be filled
 */
export interface CustomerDetailFormType {
  firstName: string
  lastName: string
  email: string
  email2?: string
  phoneNumber?: string
  pronouns?: string
  street1?: string
  street2?: string
  city?: string
  state?: string
  zipcode?: string
  notes?: string
}

/**
 * Yup validation for address fields
 * If any of the address fields are filled, all of them must be filled
 */
const isAddressRequiredTest = (fieldName: string) => {
  return function (this: TestContext) {
    // formParent accesses the parent of the form and can use the form's values
    const formParent = this.parent

    // If any of the address fields are filled, all of them must be filled
    if (formParent.street1 || formParent.city || formParent.state || formParent.zipcode) {
      // if the field is filled then it has satisfied the test
      return !!formParent[fieldName]
    }
    return true
  }
}

export const customerDetailSchema = yup.object<CustomerDetailFormType>().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email('Email is invalid').required('Email is required'),
  email2: yup.string().email('Email2 is invalid'),
  phoneNumber: yup.string().matches(mainNumPatternForms, 'Please enter a valid phone number.').label('Phone Number'),
  pronouns: yup.string(),
  street1: yup.string().test('street1', 'Street1 is required', isAddressRequiredTest('street1')),
  street2: yup.string(),
  city: yup.string().test('city', 'City is required', isAddressRequiredTest('city')),
  state: yup
    .string()
    .trim()
    .test('State code', 'Must enter a valid state', function (value) {
      // Only check for valid state if the field is not empty
      return !nonEmptyString(value) || !!getState(value)
    })
    .test('state', 'State is required', isAddressRequiredTest('state')),
  zipcode: yup.string().test('zipcode', 'Zipcode is required', isAddressRequiredTest('zipcode')),
  notes: yup.string(),
})
