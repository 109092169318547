import { AdminOrdersParamList } from '@/admin/navigation/types'
import { Logger } from '@/config/logger'
import { useFocusFx } from '@/hooks/useFocusFx'
import { SET_ISWHOLESALE_ORDER_CREATOR } from '@/redux/actions/actionTypes'
import { setOrderCreatorIsWholesale } from '@/redux/actions/adminPersist'
import { adminFarmIdSelector, adminFarmSelector, isWholesaleOrderCreatorSelector } from '@/redux/selectors'
import { PickerProps } from '@elements'
import { DefaultCatalog } from '@models/Product'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'

/** State related to the catalog selector */
export function useCatalogMode() {
  const isWholesaleOrderCreator = useSelector(isWholesaleOrderCreatorSelector)
  const adminFarmId = useSelector(adminFarmIdSelector) || ''
  const farm = useSelector(adminFarmSelector)
  const dispatch = useDispatch()
  const navigation = useNavigation<NavigationProp<AdminOrdersParamList, 'OrderCreator'>>()

  /** Sets the initial order creator mode */
  useFocusFx(
    () => {
      if (isWholesaleOrderCreator !== undefined) {
        return
      }
      dispatch<AnyAction>({
        type: SET_ISWHOLESALE_ORDER_CREATOR,
        // If the current admin farm is wholesale enabled, the order creator will be in wholesale mode initially
        payload: farm.isWholesale === true,
      })
    },
    [farm, dispatch, isWholesaleOrderCreator],
    { noRefocus: true },
  )

  /** If the admin farm changes, and the new admin farm isn't wholesale enabled, this will de-select the wholesale catalog if it was previously selected before switching farms */
  useFocusFx(() => {
    if (adminFarmId && adminFarmId === farm.id && isWholesaleOrderCreator && !farm.isWholesale) {
      dispatch<AnyAction>({
        type: SET_ISWHOLESALE_ORDER_CREATOR,
        payload: false,
      })
    }
  }, [farm, adminFarmId, isWholesaleOrderCreator, dispatch])

  /** When the catalog switches to wholesale, reset the order type filter, to ensure there's no product filters incompatible with the wholesale catalog */
  useFocusFx(() => {
    if (isWholesaleOrderCreator === true) {
      navigation.setParams({ orderType: undefined })
    }
    // Only run on change to catalog selector
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWholesaleOrderCreator])

  const onSelectCatalog = useCallback<PickerProps['onValueChange']>(
    (val) => {
      if (val === DefaultCatalog.Retail) dispatch(setOrderCreatorIsWholesale(false))
      else if (val === DefaultCatalog.Wholesale) dispatch(setOrderCreatorIsWholesale(true))
      else {
        Logger.error(new Error('Catalog selection not recognized'))
      }
    },
    [dispatch],
  )

  return { onSelectCatalog }
}
