import { ArrElement } from '@helpers/typescript'

import { DailySummary, DistributionDetailsSummary, LocationSummary, PackListSummary } from '@models/Summary'

import { marshalDateRange, unmarshalDateRange } from './Schedule'
import { marshalDate, unmarshalDate } from './Time'

/** marshalDailySummary encodes a DailySummary for transport over a Firebase callable function. */
export function marshalDailySummary(summary: DailySummary): any {
  const data = { ...summary } as any

  if (summary.dateRange) {
    data.dateRange = marshalDateRange(summary.dateRange)
  }

  return data
}

/** unmarshalDailySummary decodes an encoded DailySummary that was transported over a Firebase callable function. */
export function unmarshalDailySummary(data: any): DailySummary {
  const summary = { ...data } as DailySummary

  if (data.dateRange) {
    summary.dateRange = unmarshalDateRange(data.dateRange)
  }

  return summary
}

/** marshalLocationSummary encodes a LocationSummary for transport over a Firebase callable function. */
export function marshalLocationSummary(summary: LocationSummary): any {
  const data = { ...summary } as any

  data.dateRange = marshalDateRange(summary.dateRange)
  data.items = summary.items.map((item) => ({
    ...item,
    date: marshalDate(item.date),
  }))

  return data
}

/** unmarshalLocationSummary decodes an encoded LocationSummary that was transported over a Firebase callable function. */
export function unmarshalLocationSummary(data: any): LocationSummary {
  const summary = { ...data } as LocationSummary

  if (data.dateRange) {
    summary.dateRange = unmarshalDateRange(data.dateRange)
  }
  if (Array.isArray(data.items)) {
    summary.items = data.items.map((item: any) => unmarshalLocationSummaryItem(item))
  }

  return summary
}

/** marshalDistributionDetailsSummary encodes a DistributionDetailsSummary for transport over a Firebase callable function. */
export function marshalDistributionDetailsSummary(summary: DistributionDetailsSummary): any {
  const data = { ...summary } as any

  data.dateRange = marshalDateRange(summary.dateRange)
  data.items = summary.items.map((item) => ({
    ...item,
    deliveryDate: marshalDate(item.deliveryDate),
  }))

  return data
}

/** unmarshalDistributionDetailsSummary decodes an encoded DistributionDetailsSummary that was transported over a Firebase callable function. */
export function unmarshalDistributionDetailsSummary(data: any): DistributionDetailsSummary {
  const summary = { ...data } as DistributionDetailsSummary

  if (data.dateRange) {
    summary.dateRange = unmarshalDateRange(data.dateRange)
  }
  if (Array.isArray(data.items)) {
    summary.items = data.items.map((item: any) => unmarshalDistributionDetailsSummaryItem(item))
  }

  return summary
}

/** marshalPackListSummary encodes a PackListSummary for transport over a Firebase callable function. */
export function marshalPackListSummary(summary: PackListSummary): any {
  const data = { ...summary } as any

  data.dateRange = marshalDateRange(summary.dateRange)
  data.items = summary.items.map((item) => ({
    ...item,
    deliveryDate: marshalDate(item.deliveryDate),
    order: { ...item.order, date: marshalDate(item.order.date) },
  }))

  return data
}

/**  unmarshalPackListSummary decodes PackListSummary encoded DistributionDetailsSummary that was transported over a Firebase callable function. */
export function unmarshalPackListSummary(data: any): PackListSummary {
  const summary = { ...data } as PackListSummary

  if (data.dateRange) {
    summary.dateRange = unmarshalDateRange(data.dateRange)
  }
  if (Array.isArray(data.items)) {
    summary.items = data.items.map((item: any) => unmarshalPackListSummaryItem(item))
  }

  return summary
}

/** unmarshalLocationSummaryItem returns the unmarshaled location summary items. */
function unmarshalLocationSummaryItem(data: any): ArrElement<LocationSummary['items']> {
  const item = { ...data } as ArrElement<LocationSummary['items']>

  if (data.date) {
    item.date = unmarshalDate(data.date)
  }

  return item
}

/** unmarshalDistributionDetailsSummaryItem returns the unmarshaled distribution details items. */
function unmarshalDistributionDetailsSummaryItem(data: any): ArrElement<DistributionDetailsSummary['items']> {
  const item = { ...data } as ArrElement<DistributionDetailsSummary['items']>

  if (data.deliveryDate) {
    item.deliveryDate = unmarshalDate(data.deliveryDate)
  }

  return item
}

/** unmarshalDistributionDetailsSummaryItem returns the unmarshaled distribution details items. */
function unmarshalPackListSummaryItem(data: any): ArrElement<PackListSummary['items']> {
  const item = { ...data } as ArrElement<PackListSummary['items']>

  if (data.deliveryDate) {
    item.deliveryDate = unmarshalDate(data.deliveryDate)
  }
  if (data.order.date) {
    item.order.date = unmarshalDate(data.order.date)
  }

  return item
}
