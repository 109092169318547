import { FirebaseApp } from 'firebase/app'
import {
  ApplicationVerifier,
  Auth,
  AuthCredential,
  browserLocalPersistence,
  browserPopupRedirectResolver,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  ErrorFn,
  getMultiFactorResolver,
  GoogleAuthProvider,
  initializeAuth,
  MultiFactorError,
  NextOrObserver,
  OAuthProvider,
  onAuthStateChanged,
  PhoneAuthProvider,
  signInWithCredential,
  signInWithCustomToken as signInWithCustomTokenFB,
  signInWithPhoneNumber,
  signInWithPopup,
  User,
  UserCredential,
} from 'firebase/auth'
import env from './Environment'

/** This class is a wrapper for the Firebase Native library */
export class Authentication {
  private readonly auth: Auth

  constructor(app: FirebaseApp) {
    this.auth = initializeAuth(app, {
      persistence: !env.IS_COMP ? browserLocalPersistence : undefined,
      popupRedirectResolver: browserPopupRedirectResolver,
    })
  }
  /**
   * Returns the provider ID of the current user.
   * @returns The provider ID.
   */
  getProvider() {
    return this.auth.currentUser?.providerData[0]?.providerId
  }
  /**
   * Returns the web auth object.
   */
  getAuth() {
    return this.auth
  }
  /**
   * Returns the Native auth object. Which is null for web
   */
  getNativeAuth(): null {
    return null
  }
  /**
   * Returns whether the current user's email is verified or not.
   * @returns True if the email is verified, false otherwise.
   */
  getEmailVerified() {
    return this.auth.currentUser?.emailVerified
  }
  /** Call getIdToken should force id token to be refreshed instantly before it expires.
   * ex: user auth customClaim data changes can be refreshed without needing re-authenticate user to present changes.
   */
  refreshIdToken() {
    return this.auth.currentUser?.getIdToken(true)
  }
  /**
   * Connects to the authentication emulator using the provided URL and options.
   * @param url - The URL of the authentication emulator.
   * @param options - Additional options for connecting to the emulator.
   */
  connectAuthEmulator(url: string, options?: { disableWarnings: boolean }) {
    return connectAuthEmulator(this.auth, url, options)
  }
  /**
   * Sets up a listener for changes in the authentication state.
   * @param nextOrObserver - The callback function or observer.
   * @param errorFn - The error callback function.
   */
  onAuthStateChanged(nextOrObserver: NextOrObserver<User>, errorFn?: ErrorFn) {
    return onAuthStateChanged(this.auth, nextOrObserver, errorFn)
  }
  /**
   * Signs in the user with the provided custom token.
   * @param token - The custom token for authentication.
   */
  signInWithCustomToken(token: string) {
    return signInWithCustomTokenFB(this.auth, token)
  }
  /**
   * Signs in the user with the provided credential.
   * @param credential - The authentication credential.
   */
  signInWithCredential(credential: AuthCredential) {
    return signInWithCredential(this.auth, credential)
  }
  /**
   * Creates a new user account with the provided email and password.
   * @param email - The user's email address.
   * @param password - The user's password.
   */
  createUserWithEmailAndPassword(email: string, password: string) {
    return createUserWithEmailAndPassword(this.auth, email, password)
  }

  // This is implemented on the mobile version but not web version
  signInWithAppleNative(_token: string, _secret: string): Promise<UserCredential> {
    throw new Error('Unimplemented')
  }
  /**
   * Signs in the user with the Apple provider on web platforms.
   * @returns A promise that resolves with the user credential.
   */
  signInWithAppleWeb() {
    const provider = new OAuthProvider('apple.com')
    provider.addScope('email')
    provider.addScope('name')
    return signInWithPopup(this.auth, provider)
  }
  /**
   * Signs in the user with the provided Google token and secret.
   * @param token - The Google token.
   * @param secret - The secret for the token (optional).
   * @returns A promise that resolves with the user credential.
   */
  signInWithGoogle(token: string, secret?: string) {
    const credential = GoogleAuthProvider.credential(token, secret)
    return signInWithCredential(this.auth, credential)
  }
  /**
   * Signs in the user with the provided verification ID and verification code for phone authentication.
   * @param verificationId - The verification ID.
   * @param verificationCode - The verification code.
   * @returns A promise that resolves with the user credential.
   */
  signInWithPhoneCredential(verificationId: string, verificationCode: string) {
    const credential = PhoneAuthProvider.credential(verificationId, verificationCode)
    return signInWithCredential(this.auth, credential)
  }
  /**
   * This is really used to send the verification code to the user and doesn't actually sign in
   * @param phoneNumber - The user's phone number.
   * @param appVerifier - The app verifier.
   * @returns A promise that resolves with the confirmation result.
   */
  signInWithPhoneNumber(phoneNumber: string, appVerifier: ApplicationVerifier) {
    return signInWithPhoneNumber(this.auth, phoneNumber, appVerifier)
  }
  /**
   * Signs in the user with the provided email and password.
   * @param email - The user's email address.
   * @param password - The user's password.
   * @returns A promise that resolves with the user credential.
   */
  signInWithEmailAndPassword(email: string, password: string) {
    const credential = EmailAuthProvider.credential(email, password)
    return signInWithCredential(this.auth, credential)
  }
  /**
   * Retrieves the multi-factor resolver to complete the login flow with.
   * @param error - The multi-factor error.
   * @returns The multi-factor resolver.
   */
  getMultiFactorResolver(error: MultiFactorError) {
    return getMultiFactorResolver(this.auth, error)
  }
  /**
   * Signs out the user.
   */
  signOut() {
    return this.auth.signOut()
  }
  /**
   * Deletes the current user's account.
   * @returns A promise that resolves when the account is deleted.
   */
  deleteUser() {
    const currUser = this.auth.currentUser
    if (currUser) {
      return currUser.delete()
    }
  }
}
