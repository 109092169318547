import { formatUrl, isNavUrl, openUrl } from '@helpers/client'
import { Link } from '@react-navigation/native'
import { GestureResponderEvent, StyleProp, TextStyle, TouchableOpacity, TouchableOpacityProps } from 'react-native'

import { withColorHover } from '../../hooks/withColorHover'
import { BodyProps, fontStyle, Text, typography } from './Text'

import { isMobile } from '@/constants/Layout'

type PropTypes = {
  style?: StyleProp<TextStyle>
  size?: BodyProps['size']
  children: string
  url: string
  weight?: 'regular' | 'medium' | 'bold'
  onPress?: TouchableOpacityProps['onPress']
}

/**
 * Will open the link with openUrl for mobile. On web if the link is not in GrownBy it will
 * be redirected. Else it will use the Link component to build an anchor tag for the react navigation.
 */
function WebLink({ style, children, url, weight = 'regular', onPress, size }: PropTypes) {
  const textEl = (
    <Text style={getLinkStyle(weight, style)} size={size}>
      {children}
    </Text>
  )

  //if url is a navigation link, render the <Link> regardless of platform. Don't format the url for this.
  if (isNavUrl(url))
    return (
      // don't remove touchable opacity, it's for visual feedback only
      <TouchableOpacity
        // @ts-ignore
        href={formattedUrl}
        hrefAttrs={{
          target: '_blank',
          rel: 'noopener',
        }}
        role="link"
        onPress={(e) => {
          e.preventDefault()
          onPress?.(e as GestureResponderEvent)
        }} //this allows propagation to continue without opening the link through the browser default mechanism
      >
        <Link to={url}>{textEl}</Link>
      </TouchableOpacity>
    )
  const formattedUrl = formatUrl(url)

  //if using the mobile apps, handle navigation with openUrl()
  if (isMobile)
    return (
      <TouchableOpacity
        onPress={(e) => {
          openUrl(formattedUrl)
          onPress?.(e)
        }}
      >
        {textEl}
      </TouchableOpacity>
    )

  //if using web, use rn-web's href prop
  return (
    // don't remove touchable opacity, it's for visual feedback only
    <TouchableOpacity
      // @ts-ignore
      href={formattedUrl}
      hrefAttrs={{
        target: '_blank',
        rel: 'noopener',
      }}
      role="link"
      onPress={(e) => {
        e.preventDefault()
        openUrl(formattedUrl)
        onPress?.(e)
      }}
    >
      <Text style={getLinkStyle(weight, style)}>{children}</Text>
    </TouchableOpacity>
  )
}

const getLinkStyle = (weight: 'regular' | 'medium' | 'bold', style?: StyleProp<TextStyle>): StyleProp<TextStyle> => [
  {
    ...fontStyle(12),
    textDecorationLine: 'underline',
    fontFamily: typography.body[weight],
  },
  style,
]

export default withColorHover(WebLink)
