import { ListItemAccordion, ListItemAccordionProps, Slider } from '@elements'
import { memo } from 'react'

export type RangeSliderFilterProps = Pick<ListItemAccordionProps, 'isExpanded' | 'onToggle' | 'title'> & {
  value?: number
  onUpdate: (value: number) => void
  sliderLabel: string
  maxValue: number
}

/**
 * Expandable section that includes a slider filter component
 */
export const RangeSliderFilter = memo(function RangeFilter({
  isExpanded,
  onToggle,
  value,
  onUpdate,
  title,
  maxValue,
  sliderLabel,
}: RangeSliderFilterProps) {
  return (
    <ListItemAccordion isExpanded={isExpanded} onToggle={onToggle} title={title}>
      <Slider step={10} value={value} maximumValue={maxValue} label={sliderLabel} onSlidingComplete={onUpdate} />
    </ListItemAccordion>
  )
})
