import { Button, TextH3 } from '@elements'
import { capitalizeFirst } from '@helpers/display'
import { isEmptyValue, nonEmptyString } from '@helpers/helpers'
import { FieldArray, FieldArrayRenderProps, FormikContextType, getIn, useFormikContext } from 'formik'
import { ReactNode } from 'react'
import { View } from 'react-native'

/** Will try to find a string inside an error object's values */
function findErrorString(obj: object): string | undefined {
  return Object.values(obj).find((v) => {
    if (nonEmptyString(v)) return true
    if (typeof obj === 'object') return findErrorString(obj)
    return false
  })
}

type CustomHelpers = {
  /** Will return a string with an error for the field. It will pass the error string as it is in the formik state for that field. In nested schemas this will include the entire field path before the error message */
  showError(key: string, checkTouched?: boolean): string
  /** Will return a string with an error for the field, but will remove the fieldPath from the error string.
   * - This is useful to prevent errors that say "buyingOptions[0].prices[0].priceGroup is invalid", instead if will only say "PriceGroup is invalid".
   * - Should be the recommended approach for showing errors inside the formikArray, for better UX */
  showSimpleError(key: string, checkTouched?: boolean): string
}

/**
 * This function will convert formik top level helpers into array item specific helpers
 * @param formik The formik instance to take the helpers from
 * @param arrayKey The array-key on the formik type
 * @param idx The index of the current element
 */
function getArrayContext<FormType extends Record<TKey, any[]>, TKey extends string>(
  formik: FormikContextType<FormType>,
  arrayKey: TKey,
  idx: number,
): FormikContextType<FormType[TKey][0]> & CustomHelpers {
  return {
    ...formik,
    values: getIn(formik.values, `${arrayKey}[${idx}]`) ?? {},
    errors: getIn(formik.errors, `${arrayKey}[${idx}]`) ?? {},
    touched: getIn(formik.touched, `${arrayKey}[${idx}]`) ?? {},
    handleChange: (key: any) => formik.handleChange(`${arrayKey}[${idx}].${key}`),
    handleBlur: (key: any) => formik.handleBlur(`${arrayKey}[${idx}].${key}`),
    setFieldValue: (key: string, value: any) => formik.setFieldValue(`${arrayKey}[${idx}].${key}`, value),
    showError: (key: string, checkTouched = true): string => {
      // Most of the time we don't want to show errors until the user has touched the field
      if (checkTouched && !getIn(formik.touched, `${arrayKey}[${idx}].${key}`)) return ''

      const error = getIn(formik.errors, `${arrayKey}[${idx}].${key}`)
      if (nonEmptyString(error)) return error

      if (isEmptyValue(error)) return ''

      if (typeof error === 'object') {
        const errStr = findErrorString(error)
        if (errStr) return errStr
      }

      return `${arrayKey}[${idx}].${key} is not valid`
    },
    showSimpleError: (key: string, checkTouched = true): string => {
      const fieldPath = `${arrayKey}[${idx}].${key}`

      // Most of the time we don't want to show errors until the user has touched the field
      if (checkTouched && !getIn(formik.touched, fieldPath)) return ''

      const error = getIn(formik.errors, fieldPath)

      if (nonEmptyString(error)) {
        // Remove the field path from the error so it doesn't show up in the error message
        return capitalizeFirst(error.replace(fieldPath, '').trim())
      }

      if (isEmptyValue(error)) return ''

      if (typeof error === 'object') {
        const errStr = findErrorString(error)
        if (errStr) {
          // Remove the field path from the error so it doesn't show up in the error message
          let formatted = errStr.replace(fieldPath, '').trim()
          // It might start with a '.' because it was from a nested object
          if (formatted.startsWith('.')) formatted = formatted.slice(1)

          return capitalizeFirst(formatted)
        }
      }

      return "This field's value is not valid"
    },
  }
}

export type FormikArrayRenderArgs<FormType extends Record<TKey, any[]>, TKey extends keyof FormType> = {
  formik: FormikContextType<FormType[TKey][0]> & CustomHelpers
  arrayHelpers: FieldArrayRenderProps
  index: number
}

type Props<FormType extends Record<TKey, any[]>, TKey extends keyof FormType> = {
  name: TKey
  renderItem(params: FormikArrayRenderArgs<FormType, TKey>): ReactNode
  emptyItem?: FormType[TKey][0]
}

export function FormikArray<FormType extends Record<TKey, any[]>, TKey extends keyof FormType & string>({
  name,
  renderItem,
  emptyItem,
}: Props<FormType, TKey>) {
  const arrayFormik = useFormikContext<FormType>()

  const _renderItem = (arrayHelpers: FieldArrayRenderProps) => {
    const arrValue: FormType[TKey] = getIn(arrayFormik.values, name) ?? []
    return arrValue.length > 0 ? (
      arrValue.map((_, index) => {
        // Will get array item level helpers from the form helpers
        const formik = getArrayContext(arrayFormik, name, index)
        return renderItem({ formik, arrayHelpers, index })
      })
    ) : (
      <View>
        <TextH3>No {name} found. Click the button below to add one.</TextH3>
        <Button
          // when a user presses the button it will add a new FormType item to the first position in the array
          onPress={() => arrayHelpers.push(emptyItem)}
          title="Add New"
          icon="plus"
          outline
          style={{ width: '100%', flex: 1 }}
        />
      </View>
    )
  }

  return <FieldArray name={name} render={_renderItem} />
}
