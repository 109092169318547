import { Text, Icon, Touchable } from '@elements'
import { ArrElement } from '@helpers/typescript'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { RefinementListProvided } from 'react-instantsearch-core'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { handleRefine } from './helpers'
import { ResizedSuffix, Image } from '../../components/Image'
import Colors from '../../constants/Colors'
import { globalStyles } from '../../constants/Styles'
import { certificationSelector } from '../../redux/selectors'

import { HomeParamList } from '@/navigation/types'

/** Similar to ParamRefinementList, but specifically for certifications, which require special handling for the images */
function RefinementListCert(props: RefinementListProvided) {
  const practices = useSelector(certificationSelector)
  const navigation = useNavigation<StackNavigationProp<HomeParamList, 'ExploreScreen'>>()

  const onRefinementPress = (itm: ArrElement<RefinementListProvided['items']>) => {
    handleRefine({
      item: itm,
      listProps: props,
      updateParam: (v?: string) => navigation.setParams({ certifications: v }),
    })
  }

  return (
    <View style={styles.container}>
      {props.items.map((item) => {
        const cert = practices.find((cert) => cert.id === item.label)
        return (
          <Touchable style={globalStyles.flexRowCenter} onPress={() => onRefinementPress(item)} key={item.label}>
            <Icon
              size={25}
              color={Colors.green}
              name={`${item.isRefined ? 'check-' : ''}square`}
              solid={item.isRefined}
            />
            {!!cert?.badge && (
              <Image source={{ uri: cert.badge }} resizeSuffix={ResizedSuffix.NONE} style={styles.imageBadge} />
            )}
            <Text type="medium" color={Colors.shades['400']} style={styles.marginLeft}>
              {`${cert?.title || item.label} (${item.count})`}
            </Text>
          </Touchable>
        )
      })}
    </View>
  )
}

export default RefinementListCert

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 25,
  },
  imageBadge: { width: 25, height: 25, marginLeft: 10 },
  marginLeft: { marginLeft: 5 },
})
