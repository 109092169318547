import { DropdownButton, DropdownMenuBtn, Text, TextH2 } from '@elements'

import { StyleSheet, View } from 'react-native'

import LinkButton from '@/admin/components/LinkButton'
import { OfflineTable, OfflineTableProps } from '@/admin/components/OfflineTable/OfflineTable'
import { LoaderWithMessage } from '@/components'
import { useApiFx } from '@/hooks/useApiFx'
import { useDeviceSize } from '@/hooks/useLayout'
import { adminFarmIdSelector, adminFarmSelector } from '@/redux/selectors'
import { loadPayoutsByFarm } from '@api/Payouts'
import { openUrl } from '@helpers/client'
import { capitalize, formatMoney, formatShortDate } from '@helpers/display'
import { openPayoutsCSV } from '@helpers/links'
import { DatePeriods, convertPeriodToRange } from '@helpers/time'
import { PaymentSources } from '@models/Invoice'
import { Payout } from '@models/Payout'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { sharedStyles } from './styles'
import { TabScreen } from './types'

const goPayouts = (accountRef: string, payoutRef?: string) => {
  const url = `https://dashboard.stripe.com/${accountRef}/payouts/${payoutRef || ''}`
  openUrl(url)
}

/** Payouts related info tab */
export const PayoutsTab = memo(function PayoutsTab({ isVisible }: TabScreen) {
  const farmId = useSelector(adminFarmIdSelector)
  const farm = useSelector(adminFarmSelector)

  const layout = useDeviceSize()

  const payoutsApi = useApiFx(loadPayoutsByFarm, [farmId], !!farmId && isVisible, {
    noRefocus: true,
    ignoreConditionAfterFirstLoad: true,
  })

  if (!isVisible) {
    return <View />
  }

  const columns: OfflineTableProps<Payout>['columns'] = [
    { process: (item) => (item.arrivalDate ? formatShortDate(item.arrivalDate) : '') },
    { process: (item) => formatMoney(item.amount), widthFlex: 0.75 },
    { process: (item) => item.ref, widthFlex: 2 },
    {
      process: (item) => {
        const source = capitalize(item.source)

        if (item.source === PaymentSources.STRIPE) {
          return <LinkButton title={source} onPress={() => goPayouts(farm.accountRef, item.ref)} />
        }

        return source
      },
    },
  ]

  return (
    <View style={sharedStyles.baseGap}>
      <View style={sharedStyles.tabHeaderCont}>
        <TextH2 size={16}>Payouts</TextH2>
        <View style={styles.exportBtnCont}>
          <DropdownButton
            style={styles.exportBtn}
            small={layout.isExtraSmallDevice}
            outline
            data={{
              farmId: farm.id,
              zone: farm.timezone,
              withInvoices: false,
            }}
            buttons={dropdownButtons}
            title="Export Summary CSV"
            bottomSheetProps={{ ListHeaderComponent: () => <Text>Select a date range to generate CSV</Text> }}
          />

          <DropdownButton
            style={styles.exportBtn}
            small={layout.isExtraSmallDevice}
            outline
            data={{ farmId: farm.id, zone: farm.timezone, withInvoices: true }}
            buttons={dropdownButtons}
            title="Export Detail CSV"
            bottomSheetProps={{ ListHeaderComponent: () => <Text>Select a date range to generate CSV</Text> }}
          />
        </View>
      </View>
      <OfflineTable
        headerColumns={[
          { title: 'Deposit Date' },
          { title: 'Deposit Amount', widthFlex: 0.75 },
          { title: 'ID', widthFlex: 2 },
          { title: 'Processor' },
        ]}
        minWidth={500}
        columns={columns}
        data={payoutsApi.data}
        isLoading={payoutsApi.loading}
        ListEmptyComponent={
          <LoaderWithMessage loading={payoutsApi.loading} icon="money-check-alt" title="No Payouts">
            <Text center>
              You do not have any payouts yet. After you receive your first payout it will be available here
            </Text>
          </LoaderWithMessage>
        }
      />
    </View>
  )
})

const styles = StyleSheet.create({
  exportBtnCont: {
    flexDirection: 'row',
    gap: 20,
  },
  exportBtn: {
    marginHorizontal: 0,
  },
})

/** Dropdown buttons associtated with the default date ranges
 * - onPress will generate the summary / enDetail csv based on the current farm id and selected range
 */
const dropdownButtons: DropdownMenuBtn<{ farmId: string; zone: string; withInvoices: boolean }>[] = Object.values(
  DatePeriods,
)
  .filter((period) => period !== DatePeriods.Custom)
  .map((period) => {
    return {
      title: period,
      onPress: ({ farmId, zone, withInvoices }) => {
        //Range will always be truthy as Custom is filtered above
        const dateRange = convertPeriodToRange(period, zone)!
        openPayoutsCSV(farmId, { dateRange }, withInvoices)
      },
    }
  })
