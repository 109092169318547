import { signInWithEmailAndPassword } from '@api/Sessions'
import { loadUserByEmail } from '@api/Users'
import { Alert, FormButton } from '@elements'
import { SignInProviders } from '@models/User'
import { isNotFound } from '@shared/Errors'
import { Formik, FormikProps } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { Keyboard, StyleSheet } from 'react-native'
import { Input } from 'react-native-elements'
import * as Yup from 'yup'

import { AuthError, AuthErrorCodes, MultiFactorError } from 'firebase/auth'
import Sentry from '../../../config/Sentry'
import { Logger } from '../../../config/logger'
import { isWeb } from '../../../constants/Layout'
import { globalStyles } from '../../../constants/Styles'
import { RecaptchaContainer } from '../../../hooks/useMultiFactor/useMultiFactor'
import { correctProvider } from '../LoginHelper'
import { MultiFactorLogin } from '../MultiFactor/MultiFactorLogin'
import { MultiFactorLoginComponent } from '../MultiFactor/components/MultiFactorLoginComponent'

type FormType = {
  email: string
  password: string
}

const validationSchema = Yup.object<FormType>().shape({
  email: Yup.string().label('Email').email('Enter a valid email').required('Please enter a valid email'),
  password: Yup.string().label('Password').required().min(6, 'Password must have at least 6 characters'),
})

type Props = {
  email: string
  setEmail(email: string): void
}

export default function EmailLogin({ email, setEmail }: Props) {
  const form = useRef<any>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [multiFactorLogin, setMultiFactorLogin] = useState<MultiFactorLogin>()

  useEffect(() => {
    if (form.current && email) {
      form.current!.setFieldValue('email', email)
    }
  }, [email])

  async function onLoginHandler(values: FormType) {
    setIsLoading(true)
    Keyboard.dismiss()

    // Try signing in
    await signInWithEmailAndPassword(values.email, values.password).catch(async (e: AuthError) => {
      setIsLoading(false)
      if (e.code === AuthErrorCodes.USER_DISABLED) {
        return Alert('Login Failed', 'Your account has been suspended, please contact GrownBy support to resolve this.')
      }
      // If this check fails the email was used with a different provider
      await loadUserByEmail(values.email)
        .then(async (user) => {
          if (user.signInProvider === SignInProviders.Email) {
            if (e.code === AuthErrorCodes.INVALID_PASSWORD)
              return Alert('Login Failed', 'The password you have entered is incorrect.')

            /**
             * If the user has multi-factor enabled, when they login we need to send them a code to verify, so we need to catch this error to handle multiFactor login verification.
             */
            if (e.code === AuthErrorCodes.MFA_REQUIRED) {
              try {
                /** Pass e as argument inside MultiFactorLogin class will give us all the data and functionality to process MultiFactorLogin flow */
                const multiFactorLogin = new MultiFactorLogin(e as MultiFactorError) //MultiFactorError extends from AuthError so we need to cast it
                /** If users log with app, then we need to sign them in using native signInWithEmailAndPassword function as well and process MultiFactorLogin flow because you have to use native way to be able to handle multiFactor setup and login.  */
                if (!isWeb) {
                  await multiFactorLogin.initializeMobile(values.email, values.password)
                }
                return setMultiFactorLogin(multiFactorLogin)
              } catch (e: unknown) {
                Logger.error(e)
                return Alert(
                  'Login Error',
                  'An unknown error has occurred while logging you in. Please contact support.',
                )
              }
            } else {
              Logger.error(e)
              return Alert('Login Error', 'An unknown error has occurred while logging you in. Please contact support.')
            }
          }
          correctProvider(user, 'Login')
        })
        .catch((err) => {
          // User does not exist, so we need to create their account
          if (isNotFound(err)) {
            Alert(
              'Login Failed',
              'The email you have entered is not associated with an account. Click register to create one.',
            )
          } else {
            Alert('Login Error', 'An unknown error has occurred while logging you in. Please contact support.')
            Sentry.captureException(err)
          }
        })
    })
  }

  const initialValues: FormType = { email: '', password: '' }

  return (
    <Formik initialValues={initialValues} onSubmit={onLoginHandler} validationSchema={validationSchema} innerRef={form}>
      {({ setFieldValue, handleChange, values, errors, touched, handleSubmit, handleBlur }: FormikProps<FormType>) => (
        <>
          <Input
            style={globalStyles.flex1}
            numberOfLines={1}
            value={values.email}
            placeholder="Email"
            onChangeText={(e) => {
              setEmail(e)
              setFieldValue('email', e)
            }}
            autoCapitalize="none"
            autoComplete="email"
            keyboardType="email-address"
            onBlur={handleBlur('email')}
            leftIcon={{ type: 'font-awesome', name: 'envelope' }}
            leftIconContainerStyle={styles.leftIconContainer}
            errorMessage={touched.email ? errors.email : ''}
            textContentType="emailAddress"
            testID="email_login_input"
          />
          <Input
            numberOfLines={1}
            value={values.password}
            secureTextEntry
            placeholder="Password"
            onChangeText={handleChange('password')}
            autoCapitalize="none"
            autoComplete="password"
            onBlur={handleBlur('password')}
            leftIcon={{ type: 'font-awesome', name: 'lock' }}
            leftIconContainerStyle={styles.leftIconContainer}
            errorMessage={touched.password ? errors.password : ''}
            textContentType="password"
            returnKeyType="done"
            onSubmitEditing={() => handleSubmit()}
            testID="email_login_password"
          />
          <RecaptchaContainer />
          {multiFactorLogin ? (
            <MultiFactorLoginComponent multiFactor={multiFactorLogin} />
          ) : (
            <FormButton
              title="Login"
              loading={isLoading}
              onPress={handleSubmit}
              style={styles.margin}
              testID="email_login_button"
            />
          )}
        </>
      )}
    </Formik>
  )
}

const styles = StyleSheet.create({
  leftIconContainer: {
    marginRight: 8,
    opacity: 0.75,
  },
  margin: {
    margin: 8,
  },
})
