import { Text } from '@elements'
import { formatInvoiceNum, formatMoney } from '@helpers/display'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Pressable, View } from 'react-native'

import { withAdminAuth } from '../../../hooks/withAdminAuth'
import { AdminView } from '../../components/AdminView'
import { AdminDrawerParamList } from '../../navigation/types'

import { SmallTable } from '@/admin/components/Analytics/SmallTable'
import { RecentOrders } from '@/admin/components/Analytics/types'
import { grownbyWebsiteBaseUrl } from '@/config/Environment'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { DateRangePicker } from '@components'
import { openUrl } from '@helpers/client'
import { useCallback } from 'react'
import { useAdminDashboardData } from './AdminDashboard.controller'
import { AdminDashboardHeader } from './components/AdminDashboardHeader'
import { SignInTable } from './components/SignInTable'
import { SummaryStatsCards } from './components/SummaryCards'
import { useSelector } from 'react-redux'
import { wholesaleSelector } from '../../../redux/selectors'

function AdminDashboardScreenComp() {
  const navigation = useNavigation<StackNavigationProp<AdminDrawerParamList>>()

  const {
    hasAccessAccounting,
    hasAccessCreateOrder,
    hasAccessDistributions,
    hasAccessProductSetup,
    farm,
    useCache,
    dashboardFx,
    dashboardSummaryFx,
    range,
    period,
    onRangePickerUpdate,
    onUseLiveDataClick,
  } = useAdminDashboardData()
  const { isWholesale } = useSelector(wholesaleSelector)

  const { distributions, openInvoices, orders, inventory, payouts } = dashboardFx.data ?? {}
  const { revenue, newOrders, shopViews } = dashboardSummaryFx.data ?? {}

  const styles = useStyles()

  /** Navigate to the appropriate order details screen based on whether the order is a draft or regular order. */
  const orderNavigationHandler = useCallback(
    (itm: RecentOrders) => {
      itm.isDraft
        ? navigation.navigate('Orders', { screen: 'AdminDraftOrderDetails', params: { draftOrderId: itm.id } })
        : navigation.navigate('Orders', { screen: 'AdminOrderDetails', params: { orderId: itm.id } })
    },
    [navigation],
  )

  return (
    <AdminView style={styles.main}>
      <AdminDashboardHeader
        hasAccessCreateOrder={hasAccessCreateOrder ?? false}
        hasAccessProductSetup={hasAccessProductSetup ?? false}
        onUseLiveDataClick={onUseLiveDataClick}
        useCache={useCache}
      />

      <DateRangePicker timezone={farm.timezone} range={range} period={period} onUpdate={onRangePickerUpdate} />
      <View style={styles.statCardsCont}>
        <SummaryStatsCards
          newOrders={newOrders}
          revenue={revenue}
          shopViews={shopViews}
          loading={dashboardSummaryFx.loading}
          hasAccessAccounting={hasAccessAccounting ?? false}
          period={period}
        />
      </View>

      {hasAccessDistributions && <SignInTable data={distributions} isLoading={dashboardFx.loading} />}

      {hasAccessProductSetup && (
        <View style={styles.tablesWrapper}>
          <SmallTable
            isLoading={dashboardFx.loading}
            rightHeaderBtn={{
              title: 'See all',
              onPress: () => navigation.navigate('Orders', { screen: 'AdminOrderList' }),
            }}
            style={styles.responsiveTable}
            data={orders}
            getColumns={(itm) => [
              itm.orderDate,
              itm.userName,
              <Pressable key={itm.id} onPress={() => orderNavigationHandler(itm)}>
                <Text style={styles.underline}>{`${formatInvoiceNum(itm.orderNum)}${
                  itm.isDraft ? ` (${itm.state})` : ''
                }`}</Text>
              </Pressable>,
              formatMoney(itm.amount),
            ]}
            title="New Orders"
            headers={['Date', 'Customer', 'Order #', 'Total']}
            columnsStyle={{ 3: styles.smallColumn }}
          />

          <SmallTable
            style={styles.responsiveTable}
            isLoading={dashboardFx.loading}
            rightHeaderBtn={{
              title: 'See all',
              onPress: () => navigation.navigate('Products', { screen: 'ProductList', initial: false }),
            }}
            data={inventory}
            getColumns={(itm) => [
              itm.orderDate,
              <Pressable
                key={itm.productName}
                onPress={() => {
                  navigation.navigate('Products', { screen: 'EditProduct', params: { prodId: itm.productId } })
                }}
              >
                <Text numberOfLines={2} style={styles.underline}>
                  {itm.productName}
                </Text>
              </Pressable>,
              itm.inventory ?? '',
            ]}
            title="Inventory"
            headers={['Last order', 'Product', 'Stock']}
            columnsStyle={{ 2: styles.smallColumn }}
          />
        </View>
      )}

      {hasAccessAccounting && (
        <View style={styles.tablesWrapper}>
          <SmallTable
            style={styles.responsiveTable}
            isLoading={dashboardFx.loading}
            title="Open Invoices"
            data={openInvoices}
            rightHeaderBtn={{ title: 'See all', onPress: () => navigation.navigate('Invoices', { pastDue: true }) }}
            headers={['Customer', 'Invoice #', 'Due']}
            getColumns={(itm) => [
              itm.userName,
              <Pressable
                key={itm.id}
                onPress={() => openUrl(`${grownbyWebsiteBaseUrl(isWholesale)}external/invoice/${itm.id}`)}
              >
                <Text style={styles.underline}>{formatInvoiceNum(parseInt(itm.invoiceNum, 10))}</Text>
              </Pressable>,
              formatMoney(itm.amountDue),
            ]}
            columnsStyle={{ 2: styles.smallColumn }}
          />

          <SmallTable
            style={styles.responsiveTable}
            isLoading={dashboardFx.loading}
            title="Payouts"
            columnsStyle={{ 1: styles.smallColumn }}
            data={payouts?.map((x) => ({
              arrivalDate: `Payout initiated on ${x.arrivalDate}`,
              amount: formatMoney(x.amount),
            }))}
            rightHeaderBtn={{
              title: 'See all',
              onPress: () => openUrl(`https://dashboard.stripe.com/${farm.accountRef}/payouts/`),
            }}
            headers={['Payout', 'Total']}
            getColumns={(itm) => [itm.arrivalDate, itm.amount]}
          />
        </View>
      )}
    </AdminView>
  )
}

export const AdminDashboardScreen = withAdminAuth(AdminDashboardScreenComp)

const useStyles = () =>
  useSizeFnStyles(({ isExtraSmallDevice }) => ({
    main: {
      padding: 20,
      gap: 30,
    },
    tablesWrapper: {
      flexDirection: 'row',
      gap: 20,
      flexWrap: 'wrap',
    },
    responsiveTable: {
      flexBasis: 500,
      flexGrow: 1,
      flexShrink: 1,
    },

    statCardsCont: {
      flexDirection: 'row',
      gap: isExtraSmallDevice ? 8 : 20,
    },

    smallColumn: {
      maxWidth: 80,
      alignItems: 'flex-end',
    },

    underline: { textDecorationLine: 'underline' },
  }))
