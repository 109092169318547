import { Logger } from '@/config/logger'
import { loadFarmUserInvite, resetPasswordSubmit } from '@api/ExternalLinks'
import { Image } from '@components'
import { Alert, FormButton, Icon, SafeAreaView, Text, TextH1 } from '@elements'
import { FontAwesome5 } from '@expo/vector-icons'
import { errorToString } from '@helpers/helpers'
import { ResetRequest } from '@models/ExternalLink'
import { dateTimeInZone } from '@models/Timezone'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Formik, FormikProps } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { View } from 'react-native'
import { Input } from 'react-native-elements'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'
import * as Yup from 'yup'

import LoaderWithMessage from '../../components/LoaderWithMessage'
import Colors from '../../constants/Colors'
import { globalStyles } from '../../constants/Styles'
import { ExternalLinkingParamList } from '../../navigation/types'

type FormType = {
  password: string
  confirmPassword: string
}

type ErrorType = {
  title: string
  icon: string
  message: string
}

const Errors: { [keys: string]: ErrorType } = {
  expired: {
    title: 'Expired Link',
    icon: 'user-clock',
    message:
      'This link has expired, please request a new link to reset your password by going to the login screen and clicking email then forgot password.',
  },
  invalid: {
    title: 'Invalid Link',
    icon: 'exclamation-triangle',
    message: 'This link does not exist, if you reached this page in error click the X to exit and return home.',
  },
}

const registerValidationSchema = Yup.object<FormType>().shape({
  password: Yup.string()
    .label('Password')
    .required()
    .matches(
      /^(?=.*([A-Z]){1,})(?=.*[0-9]{1,})(?=.*[a-z]{1,}).{8,}$/,
      'Must Contain at least 8 Characters, One Uppercase, One Lowercase, and One Number',
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Please confirm your password'),
})

/** ResetPassword is for password reset-request. This page will be used when user intentionally request password reset. */
export default function ResetPassword() {
  const { params } = useRoute<RouteProp<ExternalLinkingParamList, 'ResetPassword'>>()
  const navigation = useNavigation<StackNavigationProp<ExternalLinkingParamList, 'ResetPassword'>>()
  const [request, setRequest] = useState<ResetRequest>()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<ErrorType>(Errors.invalid)
  const [isPassHidden, setIsPassHidden] = useState(true)
  const [isPassConfirmHidden, setIsPassConfirmHidden] = useState(true)
  const styles = useStyles()

  useEffect(() => {
    if (params.id)
      loadFarmUserInvite(params.id)
        .then((data) => {
          const now = dateTimeInZone()
          if (data.type !== 'reset-request') return setError(Errors.invalid)
          if (data.expireDate < now) return setError(Errors.expired)
          setRequest(data)
        })
        .catch((err) => {
          Logger.error(err)
          setError(Errors.invalid)
        })
        .finally(() => setIsLoading(false))
  }, [params.id])

  const resetPassword = useCallback(
    (values: FormType) => {
      setIsLoading(true)
      if (!request) return
      if (!values.password) return
      resetPasswordSubmit(request, values.password)
        .then(() => {
          setIsLoading(false)
          Alert('Reset Success', 'Your password has been successfully reset.', [
            {
              text: 'Login',
              onPress: () => {
                navigation.navigate('Consumer')
              },
            },
          ])
        })
        .catch((err) => {
          const msg = errorToString(err)
          setIsLoading(false)
          Logger.error(err)
          if (msg === 'invalid password reset request')
            return Alert('Reset Failed', 'This link has already been used or is invalid')
          Alert('Reset Failed', 'Failed to reset your password, please try again, or contact support.')
        })
    },
    [navigation, request],
  )

  const initialValues: FormType = {
    password: '',
    confirmPassword: '',
  }

  return (
    <SafeAreaView style={styles.safeAreaView}>
      <Icon
        name="times"
        size={36}
        color={Colors.shades[500]}
        style={globalStyles.margin20}
        onPress={() => navigation.navigate('Consumer')}
      />
      {request ? (
        <View style={styles.container}>
          <Image
            type="logo"
            resizeMode="contain"
            style={styles.logo}
            source={{
              uri: 'https://mcusercontent.com/72dc18c711476fd92a7442f27/images/b65b5619-4a87-4dac-aac8-c6a054f4a5c6.png',
            }}
          />
          <TextH1 style={styles.title}>Reset Password</TextH1>
          <Text style={styles.subtitle}>
            Please enter a new password to use for GrownBy. After resetting your password you will be taken to the login
            page.
          </Text>
          <Formik initialValues={initialValues} onSubmit={resetPassword} validationSchema={registerValidationSchema}>
            {({ handleChange, values, errors, touched, handleSubmit, handleBlur }: FormikProps<FormType>) => (
              <>
                <Input
                  numberOfLines={1}
                  value={values.password}
                  secureTextEntry={isPassHidden}
                  placeholder="Password"
                  onChangeText={handleChange('password')}
                  autoCapitalize="none"
                  autoComplete="password"
                  onBlur={handleBlur('password')}
                  leftIcon={{ type: 'font-awesome', name: 'lock' }}
                  leftIconContainerStyle={styles.leftIconContainer}
                  rightIcon={
                    isPassHidden ? (
                      <FontAwesome5 name="eye" size={16} onPress={() => setIsPassHidden(!isPassHidden)} />
                    ) : (
                      <FontAwesome5 name="eye-slash" size={16} onPress={() => setIsPassHidden(!isPassHidden)} />
                    )
                  }
                  errorMessage={touched.password ? errors.password : ''}
                />

                <Input
                  numberOfLines={1}
                  value={values.confirmPassword}
                  secureTextEntry={isPassConfirmHidden}
                  placeholder="Confirm Password"
                  onChangeText={handleChange('confirmPassword')}
                  autoCapitalize="none"
                  autoComplete="password"
                  onBlur={handleBlur('confirmPassword')}
                  leftIcon={{ type: 'font-awesome', name: 'lock' }}
                  leftIconContainerStyle={styles.leftIconContainer}
                  onSubmitEditing={() => handleSubmit()}
                  returnKeyType="done"
                  rightIcon={
                    isPassConfirmHidden ? (
                      <FontAwesome5
                        name="eye"
                        size={16}
                        onPress={() => {
                          setIsPassConfirmHidden(!isPassConfirmHidden)
                        }}
                      />
                    ) : (
                      <FontAwesome5
                        name="eye-slash"
                        size={16}
                        onPress={() => {
                          setIsPassConfirmHidden(!isPassConfirmHidden)
                        }}
                      />
                    )
                  }
                  errorMessage={touched.confirmPassword ? errors.confirmPassword : ''}
                />
                <FormButton loading={isLoading} title="Reset Password" onPress={handleSubmit} />
              </>
            )}
          </Formik>
          <Text style={styles.instruction}>
            Resetting password for:{'\n'}
            <Text type="bold">{request.user.email}</Text>.
          </Text>
        </View>
      ) : (
        <LoaderWithMessage loading={isLoading} icon={error.icon} title={error.title}>
          <Text>{error.message}</Text>
        </LoaderWithMessage>
      )}
    </SafeAreaView>
  )
}

const useStyles = CreateResponsiveStyle(
  {
    safeAreaView: {
      flex: 1,
      alignContent: 'center',
    },
    container: {
      padding: 16,
      alignItems: 'center',
      width: '100%',
      maxWidth: 600,
      alignSelf: 'center',
    },
    title: {
      fontSize: 24,
      margin: 12,
      textAlign: 'center',
    },
    subtitle: {
      fontSize: 20,
      margin: 12,
      lineHeight: 24,
      textAlign: 'center',
    },
    instruction: {
      textAlign: 'center',
      marginVertical: 8,
      lineHeight: 20,
    },
    input: {
      marginTop: 8,
      borderColor: Colors.black,
      borderWidth: 1,
    },
    leftIconContainer: {
      marginRight: 8,
      opacity: 0.75,
    },
    logo: {
      width: 220,
      height: 50,
    },
  },
  {
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      title: {
        fontSize: 18,
      },
      subtitle: {
        fontSize: 14,
      },
    },
  },
)
