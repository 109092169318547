import { Spinner, Text, TextH1 } from '@elements'
import { FontAwesome5 } from '@expo/vector-icons'
import { memo } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import Colors from '../constants/Colors'

type Props = {
  loading: boolean
  icon?: string
  iconColor?: string
  title: string
  children?: JSX.Element | JSX.Element[] | string
  style?: StyleProp<ViewStyle>
}

function LoaderWithMessage({ loading, ...rest }: Props) {
  if (loading) {
    return (
      <View style={styles.container}>
        <Spinner />
      </View>
    )
  }

  return <MessageWithIcon {...rest} />
}
export default memo(LoaderWithMessage)

export type MessageWithIconProps = Partial<Omit<Props, 'loading'>>

function MessageWithIconComp({
  icon = 'exclamation-triangle',
  title = 'Something went wrong',
  iconColor = Colors.shades['300'],
  children,
  style,
}: MessageWithIconProps) {
  return (
    <View style={[styles.container, style]}>
      <FontAwesome5 color={iconColor} name={icon} size={40} />
      <TextH1 center style={styles.title}>
        {title}
      </TextH1>
      {typeof children === 'string' ? <Text center>{children}</Text> : children}
    </View>
  )
}

export const MessageWithIcon = memo(MessageWithIconComp)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 30,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 700,
    textAlign: 'center',
  },
  title: {
    padding: 20,
  },
})
