import * as Clipboard from 'expo-clipboard'
import { Share as Sharing, ShareContent, ShareOptions } from 'react-native'

import { Toast } from '../../components/elements/Overlays/Popups'

// On web react-native Share uses the Web Share API which has limited support right now which is why we have the
// clipboard fallback (https://caniuse.com/web-share)

export default async function shareContent(content: ShareContent, options?: ShareOptions) {
  const url = 'url' in content ? content.url : undefined
  const message = 'message' in content ? content.message : undefined

  await Sharing.share(content, options).catch((error) => {
    if (error.name === 'AbortError') return
    fallbackShare({ url, message })
  })
}

function fallbackShare({ url, message }: { url?: string; message?: string }) {
  if (url) {
    Clipboard.setString(url)
    Toast('Link copied to clipboard')
  } else if (message) {
    Clipboard.setString(message)
    Toast('Message copied to clipboard')
  } else {
    Toast('Unable to share')
  }
}
