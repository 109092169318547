import { Alert } from '@elements'
import { isNonNullish } from '@helpers/helpers'
import { useCallback } from 'react'

import { CheckoutFormType, Touched } from './helpers'
import { checkoutInitialState } from './useCheckoutData'

import { useCartService } from '@/hooks/useCart'
import { SetHelper } from '@/hooks/useKeyedState'
import { ShoppingStackParamList } from '@/navigation/types'
import { wholesaleSelector } from '@/redux/selectors'
import { RouteProp, useRoute } from '@react-navigation/native'
import { FormikHelpers } from 'formik'
import { useSelector } from 'react-redux'

/** preCheckout is called when the place order button is first pressed. Form must've passed validation by this time. It simply checks that all items have been touched; If so, it will proceed to calling placeOrder. */
export function usePreCheckout(
  set: SetHelper<typeof checkoutInitialState>,
  touched: Touched,
  placeOrder: (values: CheckoutFormType) => Promise<void>,
) {
  const {
    params: { cartFarmId },
  } = useRoute<RouteProp<ShoppingStackParamList, 'Checkout'>>()
  const { isWholesale } = useSelector(wholesaleSelector)
  const { cart } = useCartService({ cartServiceType: 'consumer', farmId: cartFarmId, isWholesale })

  return useCallback(
    (values: CheckoutFormType, formikHelpers: FormikHelpers<CheckoutFormType>) => {
      formikHelpers.setSubmitting(true)
      set('errors', undefined)
      //If some items haven't received a selection for payment schedule, alert and return
      const untouchedIds = Object.keys(touched).filter((k) => !touched[k])
      const untouchedItms = untouchedIds
        .map((id) => cart.find((itm) => itm.id === id))
        .filter(isNonNullish)
        .map((itm) => itm.product.name)
      if (untouchedIds.length) {
        Alert('Please select share payment option', 'Select a payment option for ' + untouchedItms.join(', ') + '.')
        set('errors', untouchedIds)
        formikHelpers.setSubmitting(false)
        return
      }

      placeOrder(values)
        .catch(() => {
          /* No-op, but needs to catch user cancelled error */
        })
        .finally(() => formikHelpers.setSubmitting(false))
    },
    [cart, set, touched, placeOrder],
  )
}
