import { useDeepCompareLayoutFnStyles } from '../../../../hooks/useFnStyles'
import { GestureResponderEvent, Pressable, StatusBar, ViewStyle } from 'react-native'
import { memo } from 'react'
import type { Position } from './overlayPosition'

const backdropBaseStyle: ViewStyle = {
  position: 'absolute',
  height: '100%',
  width: '100%',
}

type Props = {
  sourcePos: Position
  onPress: (evt: GestureResponderEvent) => void
}

/**Overlay backdrop will place a full screen backdrop that does not cover the rectangle specified in sourcePos to allow
 * interaction to behave as normal with that element. */
export const OverlayBackdrop = memo(function OverlayBackdrop({ sourcePos, onPress }: Props) {
  const styles = useStyles(sourcePos)
  return (
    <>
      <Pressable style={styles.leftStyle} onPress={onPress} />
      <Pressable style={styles.rightStyle} onPress={onPress} />
      <Pressable style={styles.bottomStyle} onPress={onPress} />
      <Pressable style={styles.topStyle} onPress={onPress} />
    </>
  )
})

const useStyles = (sourcePos: Position) =>
  useDeepCompareLayoutFnStyles(
    (layout, sourcePos) => ({
      leftStyle: {
        ...backdropBaseStyle,
        width: sourcePos.left,
      },
      rightStyle: {
        ...backdropBaseStyle,
        right: 0,
        // We use screenWidth instead of regular with as this is an overlay and we don't want it to consider the admin sidebar
        width: layout.screenWidth - (sourcePos.left + sourcePos.width),
      },
      topStyle: {
        ...backdropBaseStyle,
        height: sourcePos.top + (StatusBar.currentHeight ?? 0),
      },
      bottomStyle: {
        ...backdropBaseStyle,
        bottom: 0,
        height: layout.height - (sourcePos.top + sourcePos.height + (StatusBar.currentHeight ?? 0)),
      },
    }),
    sourcePos,
  )
