import { useCallback, useState } from 'react'

export const useInfiniteScroll = (initialLimit: number, itemsLength: number, pageSize?: number) => {
  const [limit, setLimit] = useState(initialLimit)
  const [loading, setLoading] = useState(false)

  const increaseLimit = useCallback(
    (value?: number) => {
      if (itemsLength < limit) {
        return
      }
      if (loading) return

      setLimit((curr) => curr + (value ?? pageSize ?? initialLimit))
    },
    [initialLimit, itemsLength, limit, loading, pageSize],
  )
  const forceIncreaseLimit = useCallback((value: number) => {
    setLimit((curr) => curr + value)
  }, [])

  return { increaseLimit, limit, loading, setLoading, forceIncreaseLimit }
}
