import { View } from 'react-native'
import { Avatar, AvatarProps } from 'react-native-elements'
import { useSelector } from 'react-redux'

import { globalStyles } from '../constants/Styles'
import { certificationSelector } from '../redux/selectors'
import { memo } from 'react'

export const FarmBadges = memo(function FarmBadges({
  practices,
  size = 50,
  ...rest
}: { practices: string[] } & AvatarProps) {
  const certifications = useSelector(certificationSelector)

  if (!practices) return null
  return (
    <View style={globalStyles.flexRow}>
      {practices.map((certID) => {
        const url = certifications.find((cert) => cert.id === certID)
        return (
          <View style={globalStyles.margin10} key={certID}>
            <Avatar size={size} rounded source={{ uri: url?.badge }} key={certID} {...rest} />
          </View>
        )
      })}
    </View>
  )
})
