import { Button, KeyboardAvoidingScrollView, Text, TextH1 } from '@elements'
import { StackScreenProps } from '@react-navigation/stack'
import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { Input } from 'react-native-elements'

import { useSignInSheetContext } from './useSignInSheetData'

import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { useFocusFx } from '@/hooks/useFocusFx'
import { SignInSheetParamList } from '@/navigation/types'
import { SharedScreenHeader, sharedStyles } from './components'
import { withDataAndAuth } from './withDataAndAuth'

type Props = StackScreenProps<SignInSheetParamList, 'AddNotes'>

function AddNotesScreenComp({ navigation, route }: Props) {
  const { onSaveNotes, signInSummary } = useSignInSheetContext()
  /** SignIn notes, that can be seen or edited */
  const [notes, setNotes] = useState<string>('')
  const [saving, setSaving] = useState(false)

  const onEndPress = async () => {
    setSaving(true)
    await onSaveNotes(notes)
    setSaving(false)
    navigation.navigate('FinalizeSignIn', { id: route.params.id, token: route.params.token })
  }

  useFocusFx(() => {
    setNotes(signInSummary?.notes ?? '')
  }, [signInSummary?.notes])

  return (
    <KeyboardAvoidingScrollView contentContainerStyle={sharedStyles.wrapper}>
      <SharedScreenHeader />
      <View style={globalStyles.margin20}>
        <TextH1 center>Add Notes</TextH1>
      </View>
      <Text style={styles.textCont} center>
        Add any notes about today. These will be emailed to the farm.
      </Text>
      <View style={styles.noteWrapper}>
        <Text>Add a note</Text>
        <Input
          containerStyle={styles.contStyle}
          style={styles.input}
          inputContainerStyle={styles.noBorder}
          numberOfLines={7}
          multiline
          value={notes}
          onChangeText={setNotes}
          placeholder="Everyone loved the carrots!"
        />
      </View>
      <Button loading={saving} onPress={onEndPress} title="Continue" />
    </KeyboardAvoidingScrollView>
  )
}

export const AddNotesScreen = withDataAndAuth(AddNotesScreenComp)

const styles = StyleSheet.create({
  noBorder: {
    borderBottomWidth: 0,
  },
  input: {
    borderWidth: 2,
    borderColor: Colors.shades['100'],
    borderRadius: 8,
    padding: 10,
    margin: 0,
    textAlignVertical: 'top',
    minHeight: 200, // on iOS, multiline does not have expected height (its height will depend on the amount of text content)
  },
  textCont: {
    width: 250,
  },
  contStyle: {
    paddingHorizontal: 0,
    paddingBottom: 0,
  },
  noteWrapper: {
    width: '90%',
    maxWidth: 400,
    marginVertical: 20,
  },
})
