import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { ProductFee } from '@models/ProductFee'
import { prepareMarshal, prepareUnmarshal } from './encoding'

/** marshalProductFee returns the Firestore data for the supplied productFee. */
export function marshalProductFee(productFee: Partial<ProductFee>): DocumentData {
  const data = prepareMarshal(productFee) as DocumentData

  return data
}

/** unmarshalProductFee returns a productFee created from the Firestore data structure.*/
export function unmarshalProductFee(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): ProductFee {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)
  const productFee = { ...data, id } as ProductFee

  return productFee
}
