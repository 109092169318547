import { ReactElement, memo } from 'react'
import { DropdownMenu, DropdownMenuProps } from './DropdownMenu'

import { ButtonProps, LoadingView } from '@elements'

import Colors from '../../constants/Colors'
import { Icon } from './Icon/Icon'
import { Text } from './Text'

import { useDeepCompareLayoutFnStyles } from '@/hooks/useFnStyles'
import { spreadStyle } from '@/hooks/useMergeStyle'
import { ReplaceOptional } from '@helpers/typescript'
import { TouchableOpacityProps } from 'react-native'

export type DropdownButtonProps<T> = Omit<
  ReplaceOptional<DropdownMenuProps<T>, 'touchableProps', Omit<TouchableOpacityProps, 'style'> | undefined>,
  'children' | 'contentViewStyle'
> &
  Pick<
    ButtonProps,
    | 'outline'
    | 'disabled'
    | 'loading'
    | 'color'
    | 'icon'
    | 'size'
    | 'title'
    | 'textProps'
    | 'textStyle'
    | 'small'
    | 'style'
  >

/** This component integrates the Dropdown Menu with the contents of the Button component
 * - Rationale: The button component can't be used by default inside the dropdown menu because it handles the onPress, at the exclusion of the dropdown menu onPress, which is the one that needs to get called for the dialog to open.
 */
export const DropdownButton = memo(function DropdownButton<T extends object>({
  outline,
  disabled,
  loading = false,
  color = Colors.green,
  icon = 'chevron-down',
  size,
  title,
  textProps,
  textStyle,
  small,
  style,
  ...dropdownMenuProps
}: DropdownButtonProps<T>) {
  const { touchableProps, ...dropdownMenuPropsRest } = dropdownMenuProps

  const primaryColor = (() => {
    if (!outline) return 'white'
    if (disabled || loading) return Colors.shades['400']
    return color
  })()

  const styles = useStyles({
    small,
    outline,
    disabled,
    loading,
    color,
    style,
    textStyle,
    primaryColor,
  })

  return (
    <DropdownMenu
      {...dropdownMenuPropsRest}
      touchableProps={{
        ...touchableProps,
        disabled: touchableProps?.disabled || disabled,
      }}
      contentViewStyle={styles.container}
    >
      {icon && <Icon name={icon} color={primaryColor} style={styles.iconStyle} size={size} />}
      <LoadingView loading={loading} spinnerProps={{ color: primaryColor }} switchMode noDefaultLoadingContainerStyle>
        <Text size={size} type="medium" style={styles.textStyle} children={title} {...textProps} />
      </LoadingView>
    </DropdownMenu>
  )
}) as <T>(props: DropdownButtonProps<T>) => ReactElement<any, any> | null

const useStyles = (args: Partial<ButtonProps> & { primaryColor: string }) => {
  return useDeepCompareLayoutFnStyles(
    ({ isSmallDevice, isMedDevice }, { small, outline, disabled, loading, color, style, textStyle, primaryColor }) => ({
      container: {
        borderRadius: 10,
        borderWidth: 1,
        minHeight: small ? 40 : 45,
        flexDirection: 'row',
        marginHorizontal: small ? 0 : 10,
        marginVertical: small ? 0 : 5,
        paddingHorizontal: small ? 5 : 20,
        alignItems: 'center',
        justifyContent: 'center',
        ...(outline
          ? { borderColor: primaryColor }
          : { borderColor: Colors.transparent, backgroundColor: disabled || loading ? Colors.shades['200'] : color }),
        ...spreadStyle(style),
      },
      textStyle: {
        textAlign: 'center',
        fontWeight: '600',
        color: primaryColor,
        ...spreadStyle(textStyle),
      },
      iconStyle: {
        paddingRight: isSmallDevice ? 5 : isMedDevice ? 10 : 15,
      },
    }),
    args,
  )
}
