import { Logger } from '@/config/logger'
import { useApiFx } from '@/hooks/useApiFx'
import { useCartService } from '@/hooks/useCart'
import { useCartInfo } from '@/hooks/useCart/useCartInfo'
import { withAuth } from '@/hooks/withAuth'
import { ShoppingStackParamList } from '@/navigation/types'
import { loadCustomShareData } from '@api/CustomShares'
import { Alert, Button, Loader, LoadingView, hideModal, HeaderText, Text } from '@elements'
import { errorToString } from '@helpers/helpers'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { isErrorWithCode } from '@shared/Errors'
import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { alertReplaceCart } from './helpers'
import { Image, MessageWithIcon } from '@components'
import { formatMoney } from '@helpers/display'

type Props = {
  farmId: string
  customShareId: string
  promoCode: string
}

/*** This modal will show the user a preview of their custom share and allow them to edit it */
function ShareCustomizationModalComp(props: Props) {
  const navigation = useNavigation<StackNavigationProp<ShoppingStackParamList>>()
  const { cart, addPromo, updateQuantity } = useCartService({ farmId: props.farmId })
  const { cartId } = useCartInfo()
  const shareDataFx = useApiFx(loadCustomShareData, [props.customShareId, props.promoCode])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const continuePress = async () => {
    if (!shareDataFx.data) {
      return Alert('Error Customizing Share', "Couldn't load data for share customization. Please contact support.")
    }
    if (!cartId) {
      return Alert('Error Customizing Share', "Couldn't load your cart. Please contact support.")
    }

    if (cart.length > 0) {
      // Since showing an alert will hide the modal we show a full screen loader if we need to remove items from the cart
      Loader(true)
      const confirmed = await alertReplaceCart()
      if (!confirmed) {
        Loader(false)
        return
      } else {
        //TODO: Remove all items from the cart, will be improved for real share customization flow
        for (const itm of cart) {
          await updateQuantity(itm.id, 0)
        }
      }
    }

    setIsSubmitting(true)

    try {
      // Add the promo to the cart
      await addPromo(props.farmId, shareDataFx.data.promoCode, false)
    } catch (err: unknown) {
      if (isErrorWithCode(err)) {
        return Alert('Error Adding Promo', errorToString(err))
      } else {
        Logger.error('Error Customizing Share', err)
        return Alert('Error Adding Promo', `Unable to add the promo to your cart. (${errorToString(err)})`)
      }
    } finally {
      // Since we may use both a loader on the buttons and fullscreen loader we need to hide both
      setIsSubmitting(false)
      Loader(false)
    }

    hideModal()
  }

  const cancelPress = () => {
    navigation.navigate('FarmShop', { farmSlug: props.farmId })
    hideModal()
  }

  return (
    <LoadingView
      loading={shareDataFx.loading}
      success={shareDataFx.data}
      error={shareDataFx.err}
      ErrorComponent={ErrorComponent}
    >
      {(shareData) => (
        <>
          <HeaderText>Your {shareData.product.name} is ready to customize!</HeaderText>
          <View style={styles.contentContainer}>
            <Image source={{ uri: shareData.product.images[0] }} style={styles.image} />
            <View style={styles.descriptionText}>
              <Text>How it works:</Text>
              <Text style={styles.textMarginTop}>
                1) Select any products on this page. You have <Text type="bold">{formatMoney(shareData.price)}</Text> to
                apply to these products and you may add additional items to your cart from the farm's shop.
              </Text>
              <Text style={styles.textMarginTop}>
                2) Checkout. The promo code <Text type="bold">{shareData.promoCode}</Text> will be applied in your cart.
              </Text>
            </View>
          </View>
          <View style={styles.buttonContainer}>
            <Button outline title="Cancel" onPress={() => cancelPress()} loading={isSubmitting} />
            <Button title="Customize!" onPress={() => continuePress()} loading={isSubmitting} />
          </View>
        </>
      )}
    </LoadingView>
  )
}

function ErrorComponent({ error }: { error: string }) {
  //TODO: Unstable to do it this way
  if (error === 'No active coupon found for this share') {
    return (
      <MessageWithIcon title="Share Customization Finished" icon="exclamation-triangle">
        <Text>The window to customize your share has ended and your farmer built a box for you.</Text>
      </MessageWithIcon>
    )
  }
  return (
    <MessageWithIcon title="Loading Failed" icon="exclamation-triangle">
      <Text>Unable to load share customization, please contact support. ({error})</Text>
    </MessageWithIcon>
  )
}

// Make sure the user is logged in to use this component
export const ShareCustomizationModal = withAuth(ShareCustomizationModalComp)

const styles = StyleSheet.create({
  image: {
    width: 100,
    height: 100,
    borderRadius: 10,
  },
  contentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 10,
  },
  descriptionText: {
    flex: 1,
    margin: 10,
  },
  textMarginTop: {
    marginTop: 5,
  },
  buttonContainer: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
})
