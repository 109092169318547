import { Product } from '@models/Product'
import { memo, useContext } from 'react'

import { OrderCreatorScreenContext } from '../../OrderCreatorScreen.helper'

import { AddBtnShare } from '../AddCartButtons/ShareBtn'
import { ShareRow_Reusable } from './ShareRow_Reusable'

export type ShareRowProps = {
  prod: Product
  index: number
}
/** Row component for share products of the order creator table  */
export const ShareRow = memo(function ShareRow({ prod, index }: ShareRowProps) {
  const {
    isWholesaleOrderCreator,
    keyedStateProdsFilters: [{ schedule }],
  } = useContext(OrderCreatorScreenContext)

  if (isWholesaleOrderCreator === undefined) return null

  return (
    <ShareRow_Reusable
      AddCartBtn={AddBtnShare}
      index={index}
      isWholesale={isWholesaleOrderCreator}
      prod={prod}
      schedule={schedule}
      cartServiceType="orderCreator"
    />
  )
})
