import { Logger } from '@/config/logger'
import { useDeviceSize } from '@/hooks/useLayout'
import { wholesaleSelector } from '@/redux/selectors'
import { ButtonClear, Toast } from '@elements'
import { canUpdateQuantity } from '@helpers/canUpdateQuantity'
import { getUnadjustedQuantity } from '@helpers/cart'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { CartItem, isCartPhysical } from '@models/Order'
import { memo, useCallback, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'
import { useCartService } from '../../../../hooks/useCart'
import { ProductDetails } from './components/ProductDetails'
import { ProductDistroDetails } from './components/ProductDistroDetails'

type Props = {
  item: CartItem
  viewProduct(item: CartItem): void
}

export const CartItemComponent = memo(function CartItemComponent({ item, viewProduct: viewProductProp }: Props) {
  const { isWholesale } = useSelector(wholesaleSelector)
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const { cart, updateQuantity } = useCartService()

  const { isLargeDevice, isExtraSmallDevice } = useDeviceSize()
  const [loading, setLoading] = useState(false)
  const styles = useStyles()

  const viewProduct = useCallback(() => {
    viewProductProp(item)
  }, [viewProductProp, item])

  const canUpdate = useCallback(
    (delta = 1): boolean =>
      canUpdateQuantity({
        delta,
        cartItem: item,
        cart,
        isWholesale,
      }),
    [item, cart, isWholesale],
  )

  const isPhysical = isCartPhysical(item)

  const setQuantity = useCallback(
    async (delta: number) => {
      if (canUpdate(delta)) {
        try {
          setLoading(true)
          const prevQuantity = getUnadjustedQuantity(item)
          await updateQuantity(item.id, prevQuantity + delta)
        } catch (error) {
          Logger.error(error)
          Toast('Something went wrong while updating the quantity')
        } finally {
          setLoading(false)
        }
      } else {
        return Toast('The quantity for this item cannot be updated because stock is insufficient')
      }

      if (delta > 0 && !canUpdate(delta + 1)) {
        Toast('You reached the max quantity available')
      }
    },
    [canUpdate, item, updateQuantity],
  )

  if (!isLargeDevice)
    return (
      <View style={styles.smallWrapperView}>
        <View style={styles.mediumDetailWrapper}>
          <TouchableOpacity style={styles.card} onPress={viewProduct}>
            <ProductDetails item={item} setQuantity={setQuantity} loading={loading} />
          </TouchableOpacity>
          {!isExtraSmallDevice && isPhysical && (
            <ProductDistroDetails item={item} setQuantity={setQuantity} loading={loading} />
          )}
        </View>
        {isExtraSmallDevice && isPhysical && (
          <ButtonClear
            size={16}
            onPress={() => setShowDetails(!showDetails)}
            title={`${showDetails ? 'Hide ' : 'Show '} Details`}
          />
        )}
        {showDetails && isExtraSmallDevice && isPhysical && (
          <ProductDistroDetails item={item} setQuantity={setQuantity} loading={loading} />
        )}
      </View>
    )

  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.card} onPress={viewProduct}>
        <ProductDetails item={item} setQuantity={setQuantity} loading={loading} />
      </TouchableOpacity>
      {isPhysical && <ProductDistroDetails item={item} setQuantity={setQuantity} loading={loading} />}
    </View>
  )
}, propsAreDeepEqual)

const useStyles = CreateResponsiveStyle(
  {
    container: {
      height: 254,
      alignItems: 'flex-start',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    card: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      padding: 20,
      width: '100%',
    },
    mediumDetailWrapper: { flexDirection: 'row' },
    smallWrapperView: {
      flex: 1,
      paddingVertical: 10,
      width: '100%',
    },
  },
  {
    [maxSize(DEVICE_SIZES.XS)]: {
      container: {
        flexDirection: 'column',
      },
      card: {
        padding: 10,
      },
    },
  },
)
