import { Toast } from '@elements'

import { useDeepCompareFocusFx } from '@/hooks/useFocusFx'

/** This attribute refinement object holds data about a categorical search refinement for a given attribute */
type AttributeRefinementData = {
  /** The current refinement for the attribute. In a controlled component, this would be the current value of the dropdown picker.
   * It might be the same as the default value, or it could be a value found in the current refinement options. */
  rfmt: string
  /** The default value for this refinement. This is the value passed to the placeholder value for the dropdown pickers used for filters.
   * Usually something like "Select schedule..."
   */
  defaultVal: string
  /** The current available refinement items/ options. These come as the categorical filters from the search client.
   * The item object type is generalistic here which is good because any item type that has a value will extend this type and be compatible */
  currOpts: { value: string }[]
  /** The callback to run when a current refinement is not found among the available options.
   * This might internallly call a search api method to clear the refinement for the current attribute. It may also internally call a state setter,
   *  or anything else necessary for clearing the search state of a given attribute */
  clear: () => void
}

/** When refinement options change, will check if the current refinement is still among the available options. If not, will clear the current refinement.
 * - Will check whenever there is a change to the refinement items's values.
 */
export function useClearGhostRefinements(data: AttributeRefinementData[]) {
  useDeepCompareFocusFx(() => {
    let hasCleared = false
    data.forEach((attr) => {
      // If the current refinement state is the default, do nothing
      if (attr.rfmt === attr.defaultVal) return
      // Try to find the current refinement in the refinement items currently available
      const valueInItems = attr.currOpts.find((itm) => itm.value === attr.rfmt)
      // If not found clear the refinement
      if (!valueInItems) {
        hasCleared = true
        attr.clear()
      }
    })
    if (hasCleared) Toast('Some filter refinements were cleared because they became unavailable')
    // We're saying this only needs to run when algolia menu filters have updated item values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.map((attribute) => attribute.currOpts.map((itm) => itm.value))])
}
