import { loadFarm } from '@api/Farms'
import { Button, Picker, Icon, PICKER_PLACEHOLDER, Text, TextH2, WebLink, fontSize, hideModal } from '@elements'
import { Ionicons } from '@expo/vector-icons'
import { openUrl } from '@helpers/client'
import { unmarshalPhoneNumber } from '@helpers/display'
import { Farm } from '@models/Farm'
import { Formik } from 'formik'
import { useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'

import Colors from '@/constants/Colors'
import { farmsSelector } from '@/redux/selectors'

type Option = {
  title: string
  selected: boolean
}

const helpOptions: Option[] = [
  {
    title: "I'm having trouble with GrownBy.",
    selected: false,
  },
  {
    title: 'I need help with an order.',
    selected: false,
  },
]

export function AccountHelp() {
  const farms = useSelector(farmsSelector)
  const [selectedOption, setSelectedOption] = useState<Option | null>(null)
  const [selectedFarm, setSelectedFarm] = useState<Farm | undefined>(undefined)

  const firstOptionSelect = selectedOption === helpOptions[0]
  return (
    <Formik
      initialValues={{}}
      /* either open help desk link for software related help or email directly the farmer if it's a farmer related issue. */
      onSubmit={() => {
        if (firstOptionSelect) {
          openUrl('https://grownby.zohodesk.com/portal/en/newticket')
          hideModal()
        }
        if (selectedOption === helpOptions[1]) {
          hideModal()
        }
      }}
    >
      {({ handleSubmit }) => (
        <View style={{ paddingHorizontal: 10 }}>
          {helpOptions.map((option) => (
            <TouchableOpacity
              key={option.title}
              style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10 }}
              onPress={() => setSelectedOption(option)}
            >
              <Ionicons
                name={option.title === selectedOption?.title ? 'radio-button-on' : 'radio-button-off'}
                size={20}
                color={Colors.green}
                style={{ marginRight: 20 }}
              />
              <Text>{option.title}</Text>
            </TouchableOpacity>
          ))}
          {/* if the user chooses `GrownBy` help: */}
          {firstOptionSelect && (
            <View>
              <Button title="Proceed" onPress={() => handleSubmit()} />
            </View>
          )}
          {/* if the user chooses `order help`: */}
          {selectedOption === helpOptions[1] && (
            <View style={{ marginVertical: 20 }}>
              <Text>Which farm did you order from?</Text>
              {/* Map through user's farm associations */}
              <Picker
                items={farms.map((farm) => ({ label: farm.name, value: farm.id }))}
                // when user selects a farm, it grabs the ID and sets it to the selectedFarm
                onValueChange={async (value) => {
                  if (value === PICKER_PLACEHOLDER) {
                    setSelectedFarm(undefined)
                    return
                  }
                  const farm = await loadFarm(value)
                  setSelectedFarm(farm)
                }}
                value={selectedFarm?.id}
              />
            </View>
          )}
          {selectedOption === helpOptions[1] && selectedFarm && (
            <>
              <View style={{ marginTop: 10, paddingHorizontal: 10 }}>
                <TextH2>Contact {selectedFarm.name}</TextH2>
                {!!selectedFarm.website && (
                  <View style={styles.contactItem}>
                    <Icon name="globe" size={25} />
                    <WebLink style={{ paddingLeft: 15 }} url={selectedFarm.website}>
                      {selectedFarm.website}
                    </WebLink>
                  </View>
                )}
                {!!selectedFarm.phoneNumber && (
                  <View style={styles.contactItem}>
                    <Icon name="phone" size={25} />
                    <WebLink style={{ paddingLeft: 15 }} url={`tel:${selectedFarm.phoneNumber}`}>
                      {unmarshalPhoneNumber(selectedFarm.phoneNumber)}
                    </WebLink>
                  </View>
                )}
                {!!selectedFarm.email && (
                  <View style={styles.contactItem}>
                    <Icon name="envelope" size={25} />
                    <WebLink style={{ paddingLeft: 15 }} url={`mailto:${selectedFarm.email}`}>
                      {selectedFarm.email}
                    </WebLink>
                  </View>
                )}
              </View>
              <View style={{ marginTop: 30 }}>
                <Button title="Done" onPress={() => handleSubmit()} />
              </View>
            </>
          )}
        </View>
      )}
    </Formik>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  socialMedia: {
    marginHorizontal: 10,
  },
  contactText: {
    marginLeft: 20,
    fontSize: fontSize(18),
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  webContact: {
    flex: 0.5,
    borderWidth: 1,
    borderColor: Colors.shades['100'],
    borderRadius: 10,
    padding: 20,
    marginTop: 0,
    marginHorizontal: 40,
  },
  farmHeader: {
    marginVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  farmLogo: {
    width: 60,
    height: 60,
    marginRight: 10,
    borderRadius: 30,
  },
  contactItem: {
    flexDirection: 'row',
    marginTop: 15,
    alignItems: 'center',
  },
})
