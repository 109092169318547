import { ExternalLink } from '@models/ExternalLink'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { marshalDate, unmarshalDate } from './Time'
import { prepareMarshal, prepareUnmarshal } from './encoding'

/** marshalExternalLink returns a Firebase data structure for the supplied invite. */
export function marshalExternalLink(invite: Partial<ExternalLink>): DocumentData {
  const data = prepareMarshal(invite) as DocumentData

  if (invite.expireDate) {
    data.expireDate = marshalDate(invite.expireDate)
  }

  return data
}

/** unmarshalExternalLink returns an invite constructed from the Firestore data. */
export function unmarshalExternalLink(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): ExternalLink {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)
  const invite = { ...data, id } as ExternalLink

  if (data.expireDate) {
    invite.expireDate = unmarshalDate(data.expireDate)
  }

  return invite
}
