import { dequal } from '@helpers/customDequal'
import { useIsFocused } from '@react-navigation/native'
import { DependencyList, useEffect, useRef } from 'react'

import { useDeepCompareMemoize } from './useDeepEqualEffect'

export type FocusFxOpts = {
  /** If true, the effect will not run on re-focus, only on dependency change while already focused.
   * - Warning: "noRefocus" should not be used for listeners because useFocusFx calls cleanup on focus change.
   */
  noRefocus?: boolean
  /** If true the fx callback will only run once */
  once?: boolean
}

type FocusFXCallback = (focusInfo: {
  isRefocus: boolean
  depsChangedSinceLastRun: boolean
  hasFocused: boolean
  hasRun: boolean
}) => void | (() => void)

/** useEffect that will not run when the screen is out of focus.*/
export const useFocusFx = (
  callback: FocusFXCallback,
  deps: DependencyList,
  { noRefocus = false, once = false }: FocusFxOpts = {},
) => {
  const hasRun = useRef(false)
  const hasFocused = useRef(false)
  const isFocused = useIsFocused()
  const prevFocus = useRef(true)

  const depsRefAtLastRun = useRef<DependencyList>([])

  useEffect(() => {
    if (once && hasRun.current) return

    let cleanup: void | (() => void) = undefined

    // Whether the current run is a refocus
    const isRefocus = isFocused && prevFocus.current === false
    // Whether deps have an inequality
    const depsChangedSinceLastRun = !dequal(depsRefAtLastRun.current, deps)

    // If not focused, should not run
    // If it's the first time focusing, should run
    // If focused and deps changed, should run
    // If focused and deps not changed, run if it's not refocusing, or if noRefocus isn't true
    if (isFocused && (!hasFocused.current || depsChangedSinceLastRun || !isRefocus || !noRefocus)) {
      depsRefAtLastRun.current = deps
      cleanup = callback({ isRefocus, depsChangedSinceLastRun, hasFocused: hasFocused.current, hasRun: hasRun.current })
      hasRun.current = true
    }

    if (isFocused && !hasFocused.current) hasFocused.current = true

    // Update refs
    prevFocus.current = isFocused

    return cleanup
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps.concat(isFocused))
}

/** useEffect that will not run when the screen is out of focus.
 * - re-runs only on deep inequality in deps
 */
export function useDeepCompareFocusFx(
  callback: FocusFXCallback,
  deps: DependencyList,
  { noRefocus = false, once = false }: FocusFxOpts = {},
) {
  const hasRun = useRef(false)
  const hasFocused = useRef(false)
  const isFocused = useIsFocused()
  const prevFocus = useRef(true)

  const depsRefAtLastRun = useRef<DependencyList>([])

  useEffect(() => {
    if (once && hasRun.current) return

    let cleanup: void | (() => void) = undefined

    // Whether the current run is a refocus
    const isRefocus = isFocused && prevFocus.current === false
    // Whether deps have an inequality
    const depsChangedSinceLastRun = !dequal(depsRefAtLastRun.current, deps)

    // If not focused, should not run
    // If it's the first time focusing, should run
    // If focused and deps changed, should run
    // If focused and deps not changed, run if it's not refocusing, or if noRefocus isn't true
    if (isFocused && (!hasFocused.current || depsChangedSinceLastRun || !isRefocus || !noRefocus)) {
      depsRefAtLastRun.current = deps
      cleanup = callback({ isRefocus, depsChangedSinceLastRun, hasFocused: hasFocused.current, hasRun: hasRun.current })
      hasRun.current = true
    }

    if (isFocused && !hasFocused.current) hasFocused.current = true

    // Update refs
    prevFocus.current = isFocused

    return cleanup
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useDeepCompareMemoize(deps.concat(isFocused)))
}
