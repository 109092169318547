import { Address } from '@models/Address'
import { Distribution } from '@models/Distribution'
import { Invoice, InvoicePayment, InvoicePaymentMethod, PaymentSources } from '@models/Invoice'
import { Money } from '@models/Money'
import { Order, OrderItem, PickupItem } from '@models/Order'
import { EbtCardTypes, isEbtPayment, mapSourceToType } from '@models/PaymentMethod'
import {
  CancellationTypes,
  PaymentSchedule,
  Product,
  ProductType,
  Share,
  Unit,
  UnitPrice,
  isPayInFull,
  isPayPerPickup,
} from '@models/Product'
import { DayOfWeek, Hours } from '@models/Schedule'
import { DateTime } from 'luxon'

import { Location, LocationTypes } from '@models/Location'
import { isEmptyValue, isNonNullish, isNum } from './helpers'
import { DistroOpts, GetPickupsOtherOpts, getPickups, getProratedAmount } from './order'
import { findPriceForAppMode, matchesAppModePrice } from './products'
import { format } from './time'
import { PartialExcept } from './typescript'

export function formatAddress(address: PartialExcept<Address, 'street1' | 'city' | 'state' | 'zipcode'>) {
  return `${address.street1}, ${address.city}, ${address.state} ${address.zipcode}`
}

export function formatShortAddress(address: PartialExcept<Address, 'city' | 'state'>) {
  return `${address.city}, ${address.state}`
}

export function formatStreetAddress(address: PartialExcept<Address, 'street1' | 'city'>) {
  return `${address.street1}, ${address.city}`
}

export function formatExtendedAddress(address: PartialExcept<Address, 'state' | 'city' | 'zipcode'>) {
  return `${address.city}, ${address.state}, ${formatZipcode(address.zipcode)}`
}

export function plural(count: number, singular: string, multiple?: string): string {
  const common: Record<string, string> = {
    is: 'are',
    has: 'have',
    was: 'were',
    this: 'these',
  }
  multiple = multiple || common[singular] || singular + 's'
  return count === 1 ? singular : multiple!
}

export function formatInvoiceNum(invoiceNum?: number) {
  if (invoiceNum !== undefined) return '#' + invoiceNum.toString().padStart(6, '0')
  return ''
}

export function getOrderNum(orderNum?: number) {
  if (orderNum !== undefined) return '#' + orderNum.toString().padStart(6, '0')
  return ''
}

/**
 * @param characters the string which may include blank spaces
 * @returns Capitalized string
 * @example capitalize("loReM IPSUM") => "Lorem Ipsum"
 */
export function capitalize(characters: string) {
  if (characters.length === 0) return ''
  return characters.toLowerCase().replace(/(^\w)|(\s+\w)/g, (letter) => letter.toUpperCase())
}

/**
 * Capitalizes only the first letter of a string
 */
export const capitalizeFirst = (value: string) => {
  if (value.length === 0) return ''
  return value[0].toUpperCase() + value.slice(1)
}

export function formatZipcode(zip: string | number) {
  if (!zip) return ''
  if (typeof zip === 'number') zip = zip.toString()
  return zip.padStart(5, '0')
}

/**
 * Will format dates given in 00:00-23:59 to 12:00am-11:59pm
 * startTime and endTime must have leading zero 00:00
 */
export function formatPickupTime({ startTime, endTime }: Hours, locationType: Location['type']) {
  if (locationType === LocationTypes.Shipping) return ''

  const start = formatTime(startTime).replace(/:00/g, '')
  const end = formatTime(endTime).replace(/:00/g, '')
  return `${start} - ${end}`
}

export function formatTime(time: DateTime): string
export function formatTime(time: string): string
/**
 * Will format dates given in either string format 23:59 or DateTime format to 11:59pm
 */
export function formatTime(time: string | DateTime): string {
  const date: DateTime = DateTime.isDateTime(time) ? time : DateTime.fromFormat(time, 'HH:mm')

  // We use toLowerCase so that PM is formatted as pm
  return date.toFormat('h:mma').toLowerCase()
}

/** Returns the day of the week as a string instead of iso number */
export function getDayofWeekName(dayNum: DayOfWeek) {
  const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  return weekDays[dayNum - 1]
}

export function formatPickupDate(date: DateTime) {
  return format(date, 'iii, MMM do yyyy')
}

/** formatShortPickupDate is like formatPickupDate but condenses the output for cases where space is limited e.g. mobile views. */
export function formatShortPickupDate(date: DateTime): string {
  return format(date, 'MM/dd/yy')
}

/** Formats a price in cents to a dollar amount Ex. 190004 to $1,900.04 */
export function formatMoney(price: Money | number | null): string {
  if (!isNonNullish(price)) return InvalidAmount
  const cents = typeof price !== 'number' ? price.value : price
  if (!isNum(cents)) return InvalidAmount
  return '$' + (cents / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

/**
 *
 * CALCULATIONS
 *
 */

/** Returns a money-formatted price for the product as a string for display purposes. It may return a single price or a range, depending on the arguments
 * - If standard product, providing the unit price will get the exact value.
 * - If share, providing paySchedule will get the exact value.
 * - If only product is provided, will get a range of values.
 * - If a defaultCatalog is provided for a standard product, it will only consider the prices that match that catalog
 * - It won't pro-rate results.
 */
export function getPriceString(
  product: Product,
  { price, paySchedule, isWholesale }: { price?: UnitPrice; paySchedule?: PaymentSchedule; isWholesale?: boolean } = {},
): string {
  switch (product.type) {
    case ProductType.Digital:
    case ProductType.Standard:
    case ProductType.FarmBalance: {
      if (price) return formatMoney(price.amount)

      return getPriceRangeString(product, { prorate: false, isWholesale })
    }
    case ProductType.PrimaryShare:
    case ProductType.AddonShare:
      return paySchedule
        ? formatMoney(paySchedule.amount)
        : getPriceRangeString(product, { prorate: false, isWholesale })
    default:
      throw new Error('Wrong product type')
  }
}

/** Will return a string range of the price of all units or a particular unit */
export function formatUnitPriceRange(units: Unit[], unitId: string, isWholesale: boolean | undefined) {
  const unit = unitId ? units.find((unit) => unit.id === unitId) : undefined

  let prices: number[] = []

  if (!unit) {
    // If no unit take a range of all possible unit/ price combinations for the catalog
    prices = units.flatMap((unit) => unit.prices.filter(matchesAppModePrice(isWholesale)).map((pr) => pr.amount.value))
  } else {
    // Use the catalog price for the unit
    const price = findPriceForAppMode(unit.prices, isWholesale)
    if (price) {
      prices = [price.amount.value]
    }
  }
  return toMoneyRange(prices)
}

/** Returns the current price range of the product. If share, result will be prorated
 * - By default this uses proration settings for the consumer side. If admin mode required, can be customized with distro options */
export function getPriceRangeString(
  product: Product,
  opts: DistroOpts & Pick<GetPickupsOtherOpts, 'useCache'> & { prorate?: boolean; isWholesale?: boolean } = {},
): string {
  let prices: number[] = []
  switch (product.type) {
    case ProductType.Standard:
    case ProductType.Digital:
    case ProductType.FarmBalance:
      prices = product.units.flatMap((u) =>
        u.prices
          .filter((price) => {
            if (opts.isWholesale === undefined) return true
            const isMatch = matchesAppModePrice(opts.isWholesale)
            return isMatch(price)
          })
          .map((price) => price.amount.value),
      )
      break
    case ProductType.PrimaryShare:
    case ProductType.AddonShare:
      prices = product.paymentSchedules
        .map((paymentSchedule) => {
          // apply opts defaults
          opts.excludeClosedDistros ??= true
          opts.excludeHiddenDistros ??= true
          opts.ignoreOrderCutoffWindow ??= false
          opts.useCache ??= true

          //should return the prorated amount, for each product distro
          return product.distributions
            .filter((dist) => {
              return (
                (opts.excludeHiddenDistros ? !dist.isHidden : true) && (opts.excludeClosedDistros ? !dist.closed : true)
              )
            })
            .map((distribution) =>
              opts.prorate
                ? getProratedAmount({ product, distribution, paymentSchedule }, opts).itemAmount
                : paymentSchedule.amount.value,
            )
        })
        .flat()
        .filter(isNonNullish)
      break
    default:
      throw new Error('Wrong product type')
  }
  return toMoneyRange(prices)
}

export function getDepositRange(product: Product): string {
  let prices = []
  switch (product.type) {
    case ProductType.Standard:
      prices = [0]
      break
    case ProductType.PrimaryShare:
    case ProductType.AddonShare:
      prices = product.paymentSchedules.filter((pmt) => pmt.frequency !== 'ONCE').map((val) => val.deposit.value)
      break
    default:
      throw new Error(`${product.type} does not have pricing information`)
  }
  return toMoneyRange(prices)
}

/** Will get the `OrderItem` from a `PickupItem` */
export function getOrderItem(orders: Order[], pItem: PickupItem): OrderItem | undefined {
  const ord = orders.find((order) => order.id === pItem.order.id)
  if (!ord || !ord.items) return

  return ord.items.find((oi) => oi.id === pItem.id)
}

export function readableCancellation(policy?: CancellationTypes) {
  if (policy === undefined) return 'Contact farmer'
  if (policy === CancellationTypes.FlexPreSeason) return 'Flexible pre season'
  return capitalize(policy)
}

/** truncate returns a string limited the length of the input string to the maxLength. If input has a length larger than maxLength, the result will be truncated with ellipses to denote the operation. */
export function truncate(input: string, maxLength: number): string {
  if (input.length <= maxLength) {
    return input
  }
  return input.slice(0, maxLength - 3) + '...'
}

export function getPaymentSchedulesString(schedules: PaymentSchedule[]): string[] {
  return schedules.map((schedule) => {
    const freq = schedule.frequency
    if (freq === 'ONCE') return `${formatMoney(schedule.amount)} total upfront`
    return `${formatMoney(schedule.amount)} total ${freq.toLowerCase()} (${formatMoney(schedule.deposit)} deposit)`
  })
}

/** Returns the product schedule which has the most pickups left, as well as the pickups left.
 * - This is used for getting an example price, which requires an arbitrary distro to be chosen. The advantage is, this can get a sample price without asking for any user input.
 * - Will return null distro if product has no schedules or pickups left.
 */
export const getDefaultDistroAndPickupsLeft = (
  product: Share,
): { distribution: Distribution | null; pickups: DateTime[] } => {
  const pickupsBySchedule: Record<Distribution['id'], DateTime[]> = {}

  // Spread in order to avoid altering when sorting is applied
  const distros = [...product.distributions]

  if (!distros.length) return { distribution: null, pickups: [] }

  const getPickupsOpts = {
    excludeClosedDistros: true,
    excludeHiddenDistros: true,
    ignoreOrderCutoffWindow: false,
    useCache: true,
  }

  if (distros.length > 1) {
    // If there's several schedules, this should sort them, so the first one has the most pickups left.
    distros.sort((a, b) => {
      const pickupsA = getPickups(a, product, getPickupsOpts)
      pickupsBySchedule[a.id] = pickupsA

      const pickupsB = getPickups(b, product, getPickupsOpts)
      pickupsBySchedule[b.id] = pickupsB

      return pickupsA.length > pickupsB.length ? -1 : 1
    })
  } else {
    pickupsBySchedule[distros[0].id] = getPickups(distros[0], product, getPickupsOpts)
  }

  // If there were no distros with any pickups, defaultDistro should be null
  const defaultDistro =
    !isEmptyValue(pickupsBySchedule) &&
    Object.values(pickupsBySchedule).some((dates) => isNonNullish(dates) && dates.length > 0)
      ? distros[0]!
      : null
  return { distribution: defaultDistro, pickups: defaultDistro ? pickupsBySchedule[defaultDistro.id] ?? [] : [] }
}

/** Returns the prorated total of a share, based on the arbitrarily chosen payinfull schedule and the distro with the most pickups left
 * - By default this uses proration settings for the consumer side. If admin mode required, can be extended to receive options
 */
export const getProratedTotalSimple = (product: Share): number | null => {
  const { distribution } = getDefaultDistroAndPickupsLeft(product)
  if (!distribution) return null
  const paymentSchedule = product.paymentSchedules.find(isPayInFull) ?? product.paymentSchedules[0]
  if (!paymentSchedule) return null
  return getProratedAmount(
    { product, distribution, paymentSchedule },
    {
      excludeClosedDistros: true,
      ignoreOrderCutoffWindow: false,
      useCache: true,
    },
  ).itemAmount
}

/** Returns the number of pickups left, based on an arbitrary distribution chosen for simplicity */
export const getPickupsLeftSimple = (product: Share): number => {
  const { distribution, pickups } = getDefaultDistroAndPickupsLeft(product)
  if (!distribution) return 0
  return pickups.length
}

/** Constant string used for representing a non valid price. Should be displayed instead of zero ($0.00) whenever a price value does not represent a true price */
export const InvalidAmount = 'N/A' as const

/** Returns a string used in various screens, which includes the prorated total, and the number of pickups it includes.
 * - Useful as a price summary which requires no options chosen by the user
 */
export const getSharePriceShortText = (product: Share): string => {
  const price = getProratedTotalSimple(product)
  const pickupsLeft = getPickupsLeftSimple(product)
  if (!price || !pickupsLeft) return InvalidAmount
  return `${formatMoney(price)} for ${pickupsLeft} ${plural(pickupsLeft, 'share')}`
}

/** Will get the pickup price based on a single distro (chosen arbitrarily for simplicity) and the pay in full payment schedule */
export const pickupPriceSimple = (product: Share): string => {
  const total = getProratedTotalSimple(product)
  const pickupsLeft = getPickupsLeftSimple(product)
  if (typeof total !== 'number' || pickupsLeft === 0) return InvalidAmount
  return formatMoney(total / pickupsLeft)
}

/** Will return the current prorated price per pickup, as a range, for a share's payschedule, based on all of the share's distributions and remaining pickups.
 * - this is intended to be an accurate estimate of all possible choices. so this should not use any arbitrarily chosen distro or payment schedule.
 * - By default this uses proration settings for the consumer side. If admin mode required, can be extended to receive options */
export function getPickupPriceRangeForPaySchedule(product: Share, paymentSchedule: PaymentSchedule): number[] {
  const opts = {
    excludeHiddenDistros: true,
    excludeClosedDistros: true,
    ignoreOrderCutoffWindow: false,
    useCache: true,
  }

  return product.distributions
    .filter((dist) => {
      return (opts.excludeHiddenDistros ? !dist.isHidden : true) && (opts.excludeClosedDistros ? !dist.closed : true)
    })
    .map((distribution) => {
      const amt = getProratedAmount({ product, distribution, paymentSchedule }, opts).itemAmount
      if (amt === null) return
      if (amt === 0) return amt
      return amt / getPickups(distribution, product, opts).length
    })
    .filter(isNonNullish)
}

/** Will return a share's prorated pickup price range from lowest to highest, considering all payment schedules and distributions
 * - this is intended to be an accurate estimate of all possible choices. so this should not use any arbitrarily chosen distro or payment schedule.
 */
export function pickupPriceRangeString(product: Share) {
  const prices = product.paymentSchedules
    .map((paySchedule) => {
      return getPickupPriceRangeForPaySchedule(product, paySchedule)
    })
    .flat()
  return toMoneyRange(prices)
}

/** Formats any number array as a money range string with min and max values */
export const toMoneyRange = (arr: number[]): string => {
  if (!arr.length) return InvalidAmount
  // if not all values are the same, format as a range string
  if (arr.length > 1 && arr.some((amt) => amt !== arr[0]))
    return `${formatMoney(Math.min(...arr))} - ${formatMoney(Math.max(...arr))}`
  //else return the only value
  return formatMoney(arr[0])
}

export const countryPattern = /[+]?[\d][-\s.]/ //Matches "+1 ", "1 ", "+1-", "+1."

export const websitePattern =
  /^https?:\/\/(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}(?:[/?#][^\s]*)?$/

export const mainNumPatternServer = /\d{10}(?!\d)/ //Matches "1234567890" or "9998887777" 10 digits without punctuation, but not more than 10 digits. If the string has more than 10 digits, will use the last 10

export const mainNumPatternForms = /[(]?[\d]{3}[)]?[-\s.]?[\d]{3}[-\s.]?[\d]{4}(?!\d)/ //Matches "(999)-888-7777", "999-888-7777", "999 888 7777", "9998887777", etc. Main number must have exactly 10 digits total, not including country code

export const phonePatternForms = /[+]?[\d][-\s.][(]?[\d]{3}[)]?[-\s.]?[\d]{3}[-\s.]?[\d]{4}(?!\d)/ //Pattern for form validation of entire phone number field (countryCode + mainNumber). To match, must have the countryCode and the main number using any common separators. Main number must have exactly 10 digits. If it matches this, it is guaranteed to be marshallable correctly. Matches "+1 (888) 777-6666", "1 222-3333-111" "+1 999 000 9999", "+1 3334445555"

export const phonePatternServer = /[+][\d][-\s.]\d{10}(?!\d)/ //Matches "+1 1234567890", a country code and exactly 10 digits with no punctuations

const defaultCountryCode = '+1 '

/** Format phone for firebase and auth.
 * - Will double-check the input matches @see {phonePatternForms}.
 * - If hasCountry=false, won't expect a countryCode, and will provide the default "+1 ".
 * - It will convert the input from phonePatternForms into @see {phonePatternServer} */
export function marshalPhoneNumber(userNum: string, hasCountry: boolean | undefined = true): string | undefined {
  //Double-check it matches the phonePatternForms
  const formPattern = hasCountry ? phonePatternForms : mainNumPatternForms //Check the entire num, or just main num
  if (!userNum.match(formPattern)?.[0]) return undefined //Form validation should prevent this scenario by validating on the same pattern

  //If it already matches the server pattern, then we're done
  if (userNum.match(phonePatternServer)) return userNum

  //Else, we must transform it into phonePatternServer

  //First get the country code, if any
  let countryCode = ''
  if (hasCountry && userNum.match(countryPattern)?.[0]) {
    //If it has a country code, separate this section from the main number
    countryCode += userNum.match(countryPattern)![0]
    countryCode = countryCode.replace(/\D/g, '')
    countryCode = `+${countryCode} `
  } else countryCode += defaultCountryCode

  //Get the main number, and make it match mainNumPatternServer. Then join with country code and return
  const mainNum = userNum.match(mainNumPatternForms)?.[0].replace(/[^\d]+/g, '')
  //If there were exactly 10 digits after the country code, it should match the mainNumPatternServer after removing punctuation
  if (!mainNum || !mainNum.match(mainNumPatternServer)) return undefined
  const marshalledPhone = countryCode + mainNum
  if (marshalledPhone.match(phonePatternServer)?.[0]) return marshalledPhone
  else return undefined
}

/** Unmarshall database phone number for display purposes.
 * - If includeCountry=false, it will only return the main number section
 * - Phone is expected in the database pattern @see {phonePatternServer} */
export const unmarshalPhoneNumber = (phone: string, includeCountry = true): string => {
  if (!phone.match(phonePatternServer)) return phone //If data is stored in wrong format, pass it as-is

  //Else, get the country code and main num, and format the main num. We can assume they will match because this code will only run if the number matches the ponePatternServer
  const countryCode = includeCountry ? phone.match(countryPattern)![0] : ''
  const mainNum = phone.match(mainNumPatternServer)![0]
  return countryCode + mainNum.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
}

/**
 * Converts a string into a url-compliant string, which can be used to build a larger url
 */
export const slugify = (str: string, separator = '-') => {
  return str
    .toString()
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, separator)
}

export const extractReadablePayments = (invoice: Invoice): string[] => {
  const data: string[] = []
  Object.values(invoice.payments).forEach((payment) => {
    data.push(extractPaymentInfo(payment))
  })
  return data
}

export const extractPaymentInfo = (payment: InvoicePayment): string => {
  let source = getReadablePayment(payment.source)
  if (isEbtPayment({ type: mapSourceToType(payment.source) })) {
    const { last4, card_type } = payment.paymentMethod as InvoicePaymentMethod<PaymentSources.WORLD_PAY_EBT>

    if (card_type) {
      source += ' '
      source += card_type === EbtCardTypes.SNAP ? 'SNAP' : card_type === EbtCardTypes.CASH ? 'CASH' : ''
    }
    if (last4) source += ` ****${last4}`

    return source
  } else if (payment.source === PaymentSources.STRIPE || payment.source === PaymentSources.STRIPE_INVOICE) {
    const { last4, card_type } = payment.paymentMethod as InvoicePaymentMethod<PaymentSources.STRIPE>
    if (last4 && card_type) return `${card_type.toUpperCase()} ****${last4}`

    return source
  } else if (payment.source === PaymentSources.STRIPE_ACH) {
    const { last4, bank_name } = payment.paymentMethod as InvoicePaymentMethod<PaymentSources.STRIPE_ACH>
    if (last4 && bank_name) return `${bank_name.toUpperCase()} ****${last4}`

    return source
  }

  // If no card info provided, return the result from getPaymentSrcText (e.g Offline, Farm Credit)
  return source
}
export const getReadablePayment = (source: PaymentSources) => {
  if (source === PaymentSources.OFFLINE) return 'Offline'
  if (source === PaymentSources.FARM_CREDIT) return 'Farm Credit'
  if (source === PaymentSources.STRIPE) return 'Credit Card'
  if (source === PaymentSources.STRIPE_ACH) return 'Bank Account'
  if (source === PaymentSources.STRIPE_INVOICE) return 'Stripe Invoice'
  if (source === PaymentSources.WORLD_PAY_EBT) return 'EBT'
  return ''
}

/** Turns myemail@domain.com into mye****@*******com */
export const anonymizeEmail = (email: string, pad = 3): string | undefined => {
  const sides = email.split('@')
  if (sides.length !== 2) {
    return undefined
  }
  const side1 = sides[0]
    .split('')
    .map((char, i) => (i < pad ? char : '*'))
    .join('')
  const side2 = sides[1]
    .split('')
    .map((char, i) => (i >= sides[1].length - pad ? char : '*'))
    .join('')
  return side1 + '@' + side2
}

export const anonymizePhone = (phone: string): string | undefined => {
  if (!phone.length) return undefined
  return (
    phone
      .split('')
      .map((char, i) => (!isNum(char) ? char : i >= phone.length - 4 ? char : '*'))
      .join('') || undefined
  )
}

export const freqToString = (frequency: PaymentSchedule['frequency']): string => {
  if (frequency === 'ONCE') return `Pay-in-full`
  else return `${frequency.charAt(0) + frequency.substring(1).toLowerCase()}`
}

export const alphabet = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
]

/** Long date, i.e. Monday, May 1, 2020 */
export const formatLongDate = (date: DateTime) => format(date, 'eeee, MMMM do, yyyy')

/** Date with formatted month, i.e. May 1, 2020 */
export const formatDateWithMonth = (date: DateTime) => format(date, 'MMMM d, yyyy')

/** Formal short date, i.e. 2020-05-01 */
export const formatFormalShortDate = (date: DateTime) => format(date, 'yyyy-MM-dd')

/** Short date, i.e. 10/22/2023 */
export const formatShortDate = (date: DateTime) => format(date, 'MM/dd/yyyy')

/** mini date, i.e. 10/22/23 */
export const formatMiniDate = (date: DateTime) => format(date, 'MM/dd/yy')

/** Date with formatted month and year only included for non-current years, i.e. May 1 for same year or May 1, 2020 if the year is different*/
export const formattedDateOptionalYear = (date: DateTime) => {
  const now = DateTime.now()
  // If we are in the same year as the display date then don't show the year
  if (now.year === date.year) return format(date, 'MMMM d')
  return format(date, 'MMMM d, yyyy')
}

/** getPaymentScheduleDescription returns a UI-friendly representation of a payment schedule. */
export function getPaymentScheduleDescription(ps: Pick<PaymentSchedule, 'paymentType' | 'frequency'>): string {
  if (isPayInFull(ps)) {
    return 'Pay-in-full'
  }

  if (isPayPerPickup(ps)) {
    return 'Pay-per-pickup'
  }

  switch (ps.frequency) {
    case 'ONCE':
      return 'Once'
    case 'WEEKLY':
      return 'Weekly'
    case 'MONTHLY':
      return 'Monthly'
    default:
      throw new Error(`Unexpected frequency: ${ps.frequency}`)
  }
}

/** This unicode character will render a bullet point inside a Text component */
export const bullet = '\u2022'
