import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { PartialExcept, omit } from '@helpers/typescript'
import React, { memo, useCallback, useMemo } from 'react'

import { ProductCardSquare } from './ProductCardSquare'
import { paddedProdWidth, paddedProdWidthSM } from './ProductCardSquare-helpers'
import { ProductCardProps } from './useProductCardData'
import { SeeMoreList, SeeMoreListProps } from '../SeeMoreList'

import { useDeviceSize } from '@/hooks/useLayout'
import { AlgoliaGeoDoc, AlgoliaGeoProduct } from '@models/Algolia'
import { Product } from '@models/Product'

function SeeMoreProductListComp<T extends Product | AlgoliaGeoDoc<AlgoliaGeoProduct>>({
  cardWidth: cardWidthProp,
  renderItem: renderItemProp,
  productCardProps,
  small: smallProp,
  ...props
}: PartialExcept<SeeMoreListProps<T>, 'data' | 'title'> & {
  /** productCardProps are expected if no renderItem is passed in props */
  productCardProps?: Omit<ProductCardProps, 'product'>
  small?: boolean
}) {
  const { isExtraSmallDevice } = useDeviceSize()

  const cardWidth = useMemo(
    () => (cardWidthProp ?? smallProp ? paddedProdWidthSM : isExtraSmallDevice ? paddedProdWidthSM : paddedProdWidth),
    [cardWidthProp, isExtraSmallDevice, smallProp],
  )
  const renderItem = useCallback(
    (product: T, index: number) => {
      return (
        renderItemProp?.(product, index) ?? (
          <ProductCardSquare product={product} small={smallProp ?? isExtraSmallDevice} {...productCardProps!} />
        )
      )
    },
    [isExtraSmallDevice, productCardProps, renderItemProp, smallProp],
  )

  return <SeeMoreList cardWidth={cardWidth} renderItem={renderItem} {...props} />
}

/** SeeMore list that has predefined values like renderItem, cardWidth, etc. Specifically designed for products
 *
 * If not defined, cardWidth will depend on small prop if defined, or on the layout size.
 */
export const SeeMoreProductList = memo(SeeMoreProductListComp, (prev, next) => {
  const prevCompare = {
    ...prev,
    productCardProps: prev.productCardProps
      ? omit(prev.productCardProps, 'onPressCsaNavigate', 'onPressMobileNavigate')
      : null,
  }
  const nextCompare = {
    ...next,
    productCardProps: next.productCardProps
      ? omit(next.productCardProps, 'onPressCsaNavigate', 'onPressMobileNavigate')
      : null,
  }
  return propsAreDeepEqual(prevCompare, nextCompare)
}) as typeof SeeMoreProductListComp
