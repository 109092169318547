import { Tooltip, ToolTips } from '@elements'
import { StyleProp, StyleSheet, TextStyle } from 'react-native'

import { memo } from 'react'
import Colors from '../../constants/Colors'
import { globalStyles } from '../../constants/Styles'
import { FontAwesome5IconName, Icon, IconProps } from './Icon/Icon'
import { Spinner } from './Spinner'
import { BodyProps, Text } from './Text'
import { Touchable } from './Touchable'

export interface CheckBoxProps extends Omit<IconProps, 'onPress' | 'name'> {
  /** If "checked" is undefined, the icon will be the "minus-square" */
  checked?: boolean
  disabled?: boolean
  loading?: boolean
  title?: string
  titleStyle?: StyleProp<TextStyle>
  textWeight?: BodyProps['type']
  onChange?: (val: boolean) => void
  toolTipId?: ToolTips
  toolTipTitle?: string
}

/** Default Checkbox Element */
export const CheckBox = memo(function CheckBox({
  checked,
  size = 25,
  color: colorProp = Colors.green,
  onChange,
  disabled,
  title,
  loading,
  titleStyle,
  toolTipId,
  textWeight = 'medium',
  /** Should pass toolTipTitle because title is not always as string. */
  toolTipTitle,
  ...iconProps
}: CheckBoxProps) {
  const color = disabled ? Colors.shades['200'] : colorProp

  const iconName: FontAwesome5IconName = `${typeof checked === 'boolean' ? (checked ? 'check-' : '') : 'minus-'}square`

  return (
    // We intentionally want to wrap both the icon and text inside a Pressable, so the touchable area includes both. Therefore the onPress is handled outside the CheckIcon
    <Touchable style={globalStyles.flexRowCenter} onPress={() => onChange?.(!checked)} disabled={disabled || loading}>
      {loading ? (
        <Spinner size={size} color={color} style={styles.defaultSpinnerStyle} />
      ) : (
        <Icon size={size} color={color} name={iconName} solid={checked} noHover={!!disabled} {...iconProps} />
      )}
      <Text
        type={textWeight}
        color={disabled ? Colors.shades['200'] : undefined}
        style={[styles.defaultTextStyle, titleStyle]}
      >
        {title} {toolTipId && <Tooltip title={toolTipTitle ?? ''} id={toolTipId} />}
      </Text>
    </Touchable>
  )
})

const styles = StyleSheet.create({
  defaultSpinnerStyle: { flex: 0, marginVertical: 6, marginHorizontal: 12 },
  defaultTextStyle: { marginLeft: 10 },
})
