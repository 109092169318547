import { AutoCompleteInput, AutoCompleteInputProps, UniversalTag } from '@elements'
import React, { memo, useCallback, useMemo, useState } from 'react'

import Colors from '@/constants/Colors'
import { AutoCompleteItem } from '@/hooks/useAutoComplete'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { View } from 'react-native'

type Props = {
  onSelect: (item: AutoCompleteItem<string>) => void
  onRemove: (item: AutoCompleteItem<string>) => void
  inline?: AutoCompleteInputProps<any>['inline']
  items: AutoCompleteItem<string>[]
  /** Helps differentiate this autocomplete from any other autocomplete instance */
  autoCompleteId: string
  placeholder: string
  selectedItems: AutoCompleteItem<string>[]
}

/** MultiSelectAutocomplete is a autocomplete component to handle multiple selections and removes. ex: category, producer, productFee etc. */
function MultiSelectAutocomplete({
  onSelect: onSelectProp,
  onRemove,
  items,
  inline = false,
  autoCompleteId,
  placeholder,
  selectedItems,
}: Props) {
  const [searchTerm, setSearchTerm] = useState('')

  const filteredItems = useMemo(() => {
    // We don't want to show all results in the autocomplete, only the first 5 like Algolia does, so we should slice the
    // results to only return the 5 most relevant
    return items.filter((item) => item.text.toLowerCase().includes(searchTerm.toLowerCase())).slice(0, 5) ?? []
  }, [items, searchTerm])

  const onChangeText = useCallback((t: string) => {
    setSearchTerm(t)
  }, [])

  const onSelect = useCallback(
    (itm: AutoCompleteItem<string>) => {
      setSearchTerm('') //clear the search when an item is selected
      onSelectProp(itm)
    },
    [onSelectProp],
  )

  const clear = useCallback(() => setSearchTerm(''), [])

  const styles = useStyles()

  return (
    <>
      <AutoCompleteInput
        inline={inline}
        contStyle={styles.inputContainer}
        style={styles.input}
        value={searchTerm}
        onChangeText={onChangeText}
        items={filteredItems}
        onSelect={onSelect}
        autoCompleteId={autoCompleteId}
        options={{ matchWidth: true }}
        placeholder={placeholder}
        clear={searchTerm ? clear : undefined}
      />
      <View style={styles.itemsCont}>
        {(selectedItems ?? []).map((item) => (
          <UniversalTag label={item.text} key={item.data} onPress={() => onRemove(item)} />
        ))}
      </View>
    </>
  )
}

export const MultiSelectAutocompleteComp = memo(MultiSelectAutocomplete)

const useStyles = () =>
  useSizeFnStyles(({ isLargeDevice, isMedDevice, isSmallDevice }) => ({
    inputContainer: {
      marginVertical: isLargeDevice ? 15 : isMedDevice ? 10 : 5,
    },
    input: { padding: 10 },
    itemsCont: {
      marginVertical: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    itemsOption: {
      margin: isSmallDevice ? 1 : 3,
      flexDirection: 'row',
      alignItems: 'center',
      padding: isSmallDevice ? 1.5 : 2.5,
      borderWidth: 1,
      borderColor: Colors.shades[200],
      borderRadius: 20,
    },
    nameStyle: {
      fontSize: isSmallDevice ? 10 : 12,
    },
    spacing: {
      width: 10,
    },
  }))
