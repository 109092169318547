import { DateTimePicker, Icon, Picker, Text } from '@elements'
import { DatePeriods } from '@helpers/time'

import { StyleSheet, View } from 'react-native'

import { DateTime } from 'luxon'

import Colors from '@/constants/Colors'
import { isWeb } from '@/constants/Layout'
import { DateRange } from '@models/Schedule'
import { Timezone, dateTimeInZone } from '@models/Timezone'
import { memo, useCallback, useMemo } from 'react'
import { convertPeriodToPickerOption } from './helpers'

type Props = {
  periods?: DatePeriods[]
  period: DatePeriods
  range: Partial<DateRange>
  timezone: Timezone
  onUpdate: (period: DatePeriods, range: Partial<DateRange>) => void
  enableFutureDates?: boolean
}

/** Reusable range selector
 * - Contains `Picker` with specific or default `DatePeriods`, that contain specific labels based on their computed range
 * - On `DatePeriods.Custom`, it will also show `startDate` & `endDate` dateTime pickers.
 */
export const DateRangePicker = memo(function DateRangePicker({
  periods = Object.values(DatePeriods),
  period,
  range,
  timezone,
  onUpdate,
  enableFutureDates = false,
}: Props) {
  const showCustomDate = period === DatePeriods.Custom

  const onUpdateStartDate = useCallback(
    (date: DateTime) => {
      const startDate = date.startOf('day')

      onUpdate(period, { ...range, startDate })
    },
    [onUpdate, range, period],
  )

  const onUpdateEndDate = useCallback(
    (date: DateTime) => {
      const endDate = date.endOf('day')
      onUpdate(period, { ...range, endDate })
    },
    [onUpdate, range, period],
  )

  const pickerOpts = useMemo(() => {
    return periods.map((period) => convertPeriodToPickerOption(period, timezone))
  }, [periods, timezone])

  const onPeriodChange = useCallback(
    (value: string) => {
      const foundPeriod = pickerOpts.find((opt) => opt.value === value)

      if (!foundPeriod) return

      // If there is no range (Custom is selected) the preveious range values
      onUpdate(foundPeriod.value, foundPeriod.range ?? {})
    },
    [onUpdate, pickerOpts],
  )

  return (
    <View style={styles.main}>
      <Picker items={pickerOpts} onValueChange={onPeriodChange} value={period} />
      {showCustomDate && (
        <View style={styles.selectorsWrapper}>
          <View style={styles.datePickerWrapper}>
            <Icon name="calendar" size={18} color={Colors.shades[200]} />

            <DateTimePicker
              onChange={onUpdateStartDate}
              timezone={timezone}
              containerStyle={styles.dateTimePicker}
              mode="date"
              value={range.startDate}
              maxDate={enableFutureDates ? undefined : dateTimeInZone(timezone).startOf('day')}
            />
          </View>

          <Text>to</Text>

          <View style={styles.datePickerWrapper}>
            <Icon name="calendar" size={18} color={Colors.shades[200]} />

            <DateTimePicker
              onChange={onUpdateEndDate}
              timezone={timezone}
              containerStyle={styles.dateTimePicker}
              mode="date"
              value={range.endDate}
              maxDate={enableFutureDates ? undefined : dateTimeInZone(timezone).endOf('day')}
            />
          </View>
        </View>
      )}
    </View>
  )
})

const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    gap: 16,
    alignItems: 'center',
    flexWrap: 'wrap',
  },

  dateTimePicker: {
    padding: 4,
    borderWidth: 0,
    maxWidth: isWeb ? 100 : undefined, //DateTimePicker is different on web, as it takes more space by default
  },
  selectorsWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  datePickerWrapper: {
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
    height: 40,
    borderWidth: 1,
    borderRadius: 10,
    paddingHorizontal: 6,
    borderColor: Colors.shades['100'],
  },
})
