import { updateFarm } from '@api/Farms'
import { hideModal } from '@elements'
import { openUrl } from '@helpers/client'
import { dateTimeInZone } from '@models/Timezone'
import { useState } from 'react'
import { StyleSheet, TextStyle, View } from 'react-native'
import { useSelector } from 'react-redux'

import { Button } from '../../components/elements/Button'
import { Text, TextH1, TextH2 } from '../../components/elements/Text'
import Colors from '../../constants/Colors'
import { getDeviceSize } from '../../constants/Layout'
import { Layout } from '../../constants/types'
import { adminFarmSelector } from '../../redux/selectors'
import { TermsAndConditions } from '../../screens/UserScreen/TermsAndConditions'

import { useLayout } from '@/hooks/useLayout'

export function AdminTerms({ onSubmit }: { onSubmit: () => void }) {
  const farm = useSelector(adminFarmSelector)
  const { isSmallDevice, ...layout } = useLayout()
  const [page, setPage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const setComplete = async () => {
    setIsLoading(true)
    const now = dateTimeInZone(farm.timezone)
    await updateFarm({ acceptedTerms: now, id: farm.id })
    onSubmit() //Navigate
    hideModal()
    setIsLoading(false)
  }

  return (
    <View style={styles.container}>
      <TextH1 style={styles.headerText}>Please review our policies and Terms of Service</TextH1>
      {page === 0 ? (
        <View style={styles.textContainer}>
          <View style={rowContainer(layout)}>
            <TextH2 style={[titleText(layout), { fontSize: isSmallDevice ? 14 : 24 }]}>2%*</TextH2>
            <Text style={otherText(layout)}>
              GrownBy charges a 2% fee on all sales on the site. This does not include payment processing fees.
            </Text>
          </View>
          <View style={rowContainer(layout)}>
            <TextH2 style={titleText(layout)}>Cooperative Membership</TextH2>
            <Text style={otherText(layout)}>
              After you start selling on GrownBy, you can become a member of the Farm Generations Cooperative.
            </Text>
          </View>
          <View style={rowContainer(layout)}>
            <TextH2 style={titleText(layout)}>Terms of Service</TextH2>
            <Text style={otherText(layout)}>Please review our Terms of Service on the next page.</Text>
          </View>
        </View>
      ) : (
        <View style={{ height: layout.height * 0.6, borderRadius: 3, borderWidth: 1, marginBottom: 10 }}>
          <TermsAndConditions />
        </View>
      )}
      {page === 0 ? (
        <Button title="Read Terms of Service" onPress={() => setPage(1)} />
      ) : (
        <Button title="I agree to the Terms of Service" onPress={setComplete} loading={isLoading} />
      )}
      <Text style={styles.footerText}>
        Please visit{' '}
        <Text style={styles.hyperlink} onPress={() => openUrl('http://farmgenerations.coop')}>
          farmgenerations.coop
        </Text>{' '}
        for more information on pricing and cooperative membership.
      </Text>
    </View>
  )
}

const rowContainer = (layout: Layout): TextStyle => {
  if (getDeviceSize(layout.screenWidth) > 0)
    return {
      flexDirection: 'row',
    }
  return {
    flexDirection: 'column',
  }
}
const titleText = (layout: Layout): TextStyle => {
  if (getDeviceSize(layout.screenWidth) > 0)
    return {
      flex: 0.5,
      textAlign: 'right',
      fontSize: 20,
      margin: 10,
    }
  return {
    fontSize: 20,
    marginHorizontal: 10,
    marginTop: 10,
  }
}
const otherText = (layout: Layout): TextStyle => {
  if (getDeviceSize(layout.screenWidth) > 0)
    return {
      flex: 1,
      margin: 10,
    }
  return {
    marginHorizontal: 10,
    marginBottom: 10,
  }
}

const styles = StyleSheet.create({
  container: {
    margin: 20,
    alignSelf: 'center',
    alignItems: 'center',
    maxWidth: 500,
  },
  headerText: {
    textAlign: 'center',
    maxWidth: 400,
  },
  textContainer: {
    marginVertical: 20,
  },
  footerText: {
    margin: 10,
    fontStyle: 'italic',
  },
  hyperlink: {
    color: Colors.blue,
    textDecorationLine: 'underline',
  },
})
