import { Skeleton } from '@elements'
import { StyleSheet, View } from 'react-native'

import { globalStyles } from '@/constants/Styles'
import { prodSharedStyles } from './ProductCardSquare-helpers'

/** Skeleton item for product card */
export function ProductCardHomeSkeleton() {
  return (
    <View style={[prodSharedStyles.smallWrapper, prodSharedStyles.responsiveWrapper]}>
      <View style={styles.imageWrapper}>
        <View style={styles.imageCont}>
          <Skeleton style={styles.image} />
        </View>
      </View>
      <View style={styles.textContainer}>
        <View style={globalStyles.flex1}>
          <View style={globalStyles.margin10} />
          <Skeleton height={30} />
          <View style={globalStyles.margin10} />
          <Skeleton />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  imageWrapper: {
    width: '100%',
    aspectRatio: 1,
  },
  image: {
    height: '100%',
    width: '100%',
    resizeMode: 'cover',
  },
  imageCont: {
    flex: 1,
    borderRadius: 10,
    overflow: 'hidden',
  },

  textContainer: { flex: 1, padding: 5 },
})
