import { SignInSheetParamList } from '@/navigation/types'
import { createStackNavigator } from '@react-navigation/stack'
import { AddNotesScreen } from '../screens/SignInSheet/AddNotesScreen'
import { EditSignInScreen } from '../screens/SignInSheet/EditSignInScreen'
import { ElectronicSignInSheet } from '../screens/SignInSheet/ElectronicSignInSheet'
import { EndSignInScreen } from '../screens/SignInSheet/EndSignInScreen'
import { FinalizeSignInScreen } from '../screens/SignInSheet/FinalizeSignInScreen'
import { PickupSummaryScreen } from '../screens/SignInSheet/PickupSummaryScreen'
import { PostDonationScreen } from '../screens/SignInSheet/PostDonationScreen'
import { SignInDataProvider } from '../screens/SignInSheet/useSignInSheetData'

const SignInSheetStack = createStackNavigator<SignInSheetParamList>()

export function SignInSheetNavigator() {
  return (
    <SignInDataProvider>
      <SignInSheetStack.Navigator
        screenOptions={{
          headerShown: false,
        }}
      >
        <SignInSheetStack.Screen name="ElectronicSignInSheet" component={ElectronicSignInSheet} />
        <SignInSheetStack.Screen name="EndSignIn" component={EndSignInScreen} />
        <SignInSheetStack.Screen name="PostDonation" component={PostDonationScreen} />
        <SignInSheetStack.Screen name="AddNotes" component={AddNotesScreen} />
        <SignInSheetStack.Screen name="FinalizeSignIn" component={FinalizeSignInScreen} />
        <SignInSheetStack.Screen name="PickupSummary" component={PickupSummaryScreen} />
        <SignInSheetStack.Screen name="EditSignIn" component={EditSignInScreen} />
      </SignInSheetStack.Navigator>
    </SignInDataProvider>
  )
}
