import { FontAwesome5 } from '@expo/vector-icons'
import { Media } from '@models/shared/Media'
import { ResizeMode, Video } from 'expo-av'
import * as React from 'react'
import { memo, useCallback, useRef, useState } from 'react'
import {
  Animated,
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native'

import Colors from '../../constants/Colors'
import { isWeb } from '../../constants/Layout'
import { FARM_IMAGE_PLACEHOLDER } from '../../constants/Placeholders'
import { SHADOW_5 } from '../../constants/Styles'
import { Image, ResizedSuffix } from '../Image'
import { Icon } from '../elements/Icon/Icon'
import { getRatioHeight } from './getRatioHeight'

import { useDeepCompareMemo } from '@/hooks/useDeepEqualEffect'
import { useLayout } from '@/hooks/useLayout'

type IndicatorProps = {
  currPos: number
  numPos: number
  height: number
  style: StyleProp<ViewStyle>
}

//The circles below the Carousel, which represent the media id being shown
const Indicator = memo(function Indicator({ currPos, numPos, height, style }: IndicatorProps) {
  if (numPos < 2) return null
  return (
    <Animated.View style={[indicatorStyle(height), style]}>
      {Array(numPos)
        .fill(0)
        .map((_, idx) => (
          <FontAwesome5 key={idx} style={styles.indicatorIcon} name="circle" color="white" solid={idx === currPos} />
        ))}
    </Animated.View>
  )
})

type CarouselProps = {
  media?: Media[]
  /** Do not pass a 'height' property in this style. It is meant to get height dynamically */
  style?: StyleProp<ViewStyle>
  headerTranslate?: Animated.AnimatedInterpolation<number>
  imageTranslate?: Animated.AnimatedInterpolation<number>
  imageOpacity?: Animated.AnimatedInterpolation<number>
  /** Overrides internal height calculation. Used when rendering mediaCarousel outside of parallaxHeaders. */
  contHeight?: number
}

export const ParallaxMediaCarousel = memo(function ParallaxMediaCarousel({
  media = [],
  style,
  headerTranslate = new Animated.Value(0),
  imageOpacity = new Animated.Value(1),
  imageTranslate = new Animated.Value(0),
  contHeight,
}: CarouselProps) {
  const layout = useLayout()
  const opacity = useRef(new Animated.Value(0)).current
  const scrollRef = useRef<ScrollView>(null)
  const [currPos, setCurrPos] = useState(0)
  const [width, setWidth] = useState(layout.isLargeDevice ? layout.width / 3 : layout.width)

  const onScroll = useCallback(
    (evt: NativeSyntheticEvent<NativeScrollEvent>) => {
      const scrollPos = Math.round(evt.nativeEvent.contentOffset.x / width)
      if (currPos !== scrollPos) setCurrPos(scrollPos)
    },
    [width, currPos],
  )

  const height = useDeepCompareMemo(
    () =>
      contHeight ?? getRatioHeight(layout.isLargeDevice ? { layout, heightType: 'proportional', width } : { layout }), //If using parallaxheaderweb(large device), force proportional height. Else height will be based on screen width
    [contHeight, layout, width],
  )

  const onLayout = useCallback(
    (e: LayoutChangeEvent) => {
      //If large, use container width. Else continue using layout.width initial value
      const newWidth = layout.isLargeDevice ? e.nativeEvent.layout.width : layout.width
      if (newWidth !== width) setWidth(newWidth)
    },
    [layout, width],
  )

  const onRightIconPress = useCallback(() => {
    scrollRef.current?.scrollTo({
      animated: true,
      x: currPos * width + width,
      y: 0,
    })
  }, [currPos, width])
  const onLeftIconPress = useCallback(() => {
    scrollRef.current?.scrollTo({
      animated: true,
      x: currPos * width - width,
      y: 0,
    })
  }, [currPos, width])

  const onLoadVid = useCallback(() => {
    Animated.timing(opacity, {
      toValue: 1,
      useNativeDriver: !isWeb,
    }).start()
  }, [opacity])

  return (
    <>
      {/*Arrows*/}
      {isWeb && (
        <>
          {/*Left arrow*/}
          {currPos !== 0 && (
            <Animated.View
              style={[
                {
                  transform: [
                    { translateX: imageTranslate?.interpolate({ inputRange: [0, 100], outputRange: [0, -100] }) },
                  ],
                },
                arrowStyle(height, 'left'),
              ]}
            >
              <Icon
                // the leftIcon style is to offset the icon to the left by 2px to make it look centered
                style={[styles.icon, styles.leftIcon]}
                name="chevron-left"
                size={20}
                color={Colors.shades[500]}
                onPress={onLeftIconPress}
              />
            </Animated.View>
          )}

          {/*Right arrow*/}
          {currPos !== media.length - 1 && (
            <Animated.View
              style={[
                {
                  transform: [{ translateX: imageTranslate }],
                },
                arrowStyle(height, 'right'),
              ]}
            >
              <Icon
                style={styles.icon}
                name="chevron-right"
                size={20}
                color={Colors.shades[500]}
                onPress={onRightIconPress}
              />
            </Animated.View>
          )}
        </>
      )}

      {/*Small indicator circles. One for each image*/}
      <Indicator
        style={{ opacity: imageOpacity, transform: [{ translateY: headerTranslate }] } as Animated.Animated}
        currPos={currPos}
        numPos={media.length}
        height={height}
      />

      {/*Carousel of images and videos*/}
      <Animated.ScrollView
        snapToInterval={width}
        decelerationRate="fast"
        horizontal
        bounces={false}
        showsHorizontalScrollIndicator={false}
        style={[headerStyle(height), style]}
        disableIntervalMomentum
        scrollEventThrottle={16}
        onScroll={onScroll}
        ref={scrollRef}
        onLayout={onLayout}
      >
        {media && media.length > 0 ? (
          <>
            {media.map((media: Media, idx) => (
              <View key={idx}>
                {media.type === 'video' ? (
                  <Video
                    isLooping
                    isMuted
                    resizeMode={ResizeMode.COVER}
                    shouldPlay
                    onLoad={onLoadVid}
                    source={{
                      uri: media.storageUrl,
                    }}
                    style={{ height, width }}
                  />
                ) : (
                  <Animated.View
                    style={{
                      opacity: imageOpacity,
                      transform: [{ translateY: imageTranslate }],
                      width,
                      height,
                    }}
                  >
                    <Image
                      type="farm"
                      style={{ width, height }}
                      source={{ uri: media.storageUrl }}
                      resizeSuffix={ResizedSuffix.LARGE}
                      resizeMode="cover"
                    />
                  </Animated.View>
                )}
              </View>
            ))}
          </>
        ) : (
          <Animated.Image
            style={{
              resizeMode: 'cover',
              opacity: imageOpacity,
              transform: [{ translateY: imageTranslate }],
              height,
              width,
            }}
            source={FARM_IMAGE_PLACEHOLDER}
          />
        )}
      </Animated.ScrollView>
    </>
  )
})

const arrowStyle = (headerHeight: number, side: 'left' | 'right'): StyleProp<ViewStyle> => [
  styles.arrow,
  { top: headerHeight * 0.5 },
  side === 'left' ? { left: 10 } : { right: 10 },
]

const indicatorStyle = (headerHeight: number): StyleProp<ViewStyle> => [styles.indicator, { top: headerHeight - 30 }]

const headerStyle = (headerHeight: number): ViewStyle => ({
  backgroundColor: Colors.shades['100'],
  height: headerHeight,
})

const styles = StyleSheet.create({
  icon: { paddingLeft: 2 },
  leftIcon: { marginLeft: -2 },
  indicatorIcon: {
    margin: 2,
  },
  indicator: {
    flexDirection: 'row',
    position: 'absolute',
    right: 10,
    zIndex: 10,
  },
  arrow: {
    ...SHADOW_5,
    width: 30,
    height: 30,
    flexDirection: 'row',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
    backgroundColor: '#FFFFFFAA',
    borderRadius: 80,
  },
})
