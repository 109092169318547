import { MoneyInput, MoneyInputProps } from '@elements'
import Colors from '../../../constants/Colors'
import { globalStyles } from '../../../constants/Styles'
import { isLabelAccessible } from './helpers/helpers'
import { formStyles, useDynamicFormStyles } from './helpers/styles'
import { FormSharedProps } from './helpers/types'

type FormMoneyInputProps = FormSharedProps & MoneyInputProps

/** This input component can be used to produce consistently styled money inputs */
export function FormMoneyInput({
  label,
  labelStyle,
  inputStyle,
  inputContainerStyle,
  containerStyle,
  errorStyle,
  style,
  ...props
}: FormMoneyInputProps) {
  const styles = useDynamicFormStyles(!!props?.errorMessage, !!props.row)

  return (
    <MoneyInput
      placeholderTextColor={Colors.shades['200']}
      errorStyle={[formStyles.error, errorStyle]}
      label={label === null ? undefined : label ?? props.placeholder}
      accessibilityLabel={isLabelAccessible(label) ? label : props.placeholder}
      labelStyle={[formStyles.inputLabel, labelStyle]}
      inputStyle={[styles.inputStyle, inputStyle]}
      inputContainerStyle={[styles.inputContainer, inputContainerStyle]}
      containerStyle={[props.row && globalStyles.flex1, containerStyle]}
      style={[styles.main, style]}
      keyboardType="decimal-pad"
      {...props}
    />
  )
}
