import { YUP_MONEY_REQUIRED, YUP_WHOLE_NUMBER_REAL } from '@helpers/Yup'
import { Coupon, CouponType } from '@models/Coupon'
import { Money } from '@models/Money'
import * as Yup from 'yup'
import { Builder } from './Builder'
import { YupValidationError, isYupValidationError, validateFromSchema } from './validators/helpers'

export const couponSchema: Yup.ObjectSchema<Coupon> = Yup.object().shape({
  id: Yup.string().required(),
  farm: Yup.object().shape({
    id: Yup.string().required(),
  }),
  name: Yup.string().trim().label('Name').required(),
  type: Yup.mixed<CouponType>().label('Coupon Type').required().oneOf([CouponType.Fixed, CouponType.Percent]),
  value: Yup.mixed<number | Money>()
    .required()
    .when('type', {
      is: CouponType.Percent,
      then: () => YUP_WHOLE_NUMBER_REAL('Value', { allowDecimal: true }).max(1, 'Cannot be greater than 100%'),
      otherwise: () => YUP_MONEY_REQUIRED('Value'),
    }),
  timesRedeemed: Yup.number().required(),
  archived: Yup.boolean(),
  ebtOnly: Yup.boolean().label('EBT Only'),
  categories: Yup.array().of(Yup.string().required()),
  producers: Yup.array().of(Yup.string().required()),
  isSystemCoupon: Yup.boolean().optional(),
})

export class CouponBuilder extends Builder<Coupon> {
  constructor() {
    super('coupon')
  }

  validate(coupon: Partial<Coupon>): Coupon {
    try {
      return validateFromSchema(couponSchema, coupon)
    } catch (error) {
      if (isYupValidationError(error)) {
        throw new YupValidationError({ path: 'coupon.' + (error.data?.path ?? ''), msg: error.message })
      }
      throw error
    }
  }
}
