import { signInWithGoogle } from '@api/Sessions'
import { Alert } from '@elements'
import { errorToString } from '@helpers/helpers'
import { GoogleAuthRequestConfig, useIdTokenAuthRequest } from 'expo-auth-session/providers/google'
import { maybeCompleteAuthSession } from 'expo-web-browser'
import { AuthError } from 'firebase/auth'
import { useEffect } from 'react'

import LoginOptButton from './LoginOptButton'
import env from '../../config/Environment'
import { Logger } from '@/config/logger'

export interface AccountLinking extends AuthError {
  email?: string
  providerId?: string
  signInMethod?: string
  oauthAccessToken?: string
}

const config: Partial<GoogleAuthRequestConfig> = {
  expoClientId: env.GOOGLE_EXPO_CLIENT_ID,
  webClientId: env.GOOGLE_WEB_CLIENT_ID,
  androidClientId: env.GOOGLE_ANDROID_CLIENT_ID,
  iosClientId: env.GOOGLE_IOS_CLIENT_ID,
}

// The `maybeCompleteAuthSession()` function is crucial for handling OAuth authentication flows, especially with web-based sign-ins like Google. It ensures that when the app is redirected back after authentication, it processes the URL parameters correctly and finalizes the auth session. This function should be called at the top level to immediately check for auth redirections upon app launch or resume, ensuring the sign-in process completes successfully.
maybeCompleteAuthSession()

export function GoogleLogin() {
  const [request, response, promptAsync] = useIdTokenAuthRequest(config)

  useEffect(() => {
    if (response?.type === 'success') {
      const { id_token } = response.params
      signInWithGoogle(id_token).catch((e) => {
        switch (e.code) {
          case 'auth/popup-closed-by-user':
            Logger.debug('Popup Closed')
            break
          default:
            Alert('Login Failed', `There was an error signing into your account. (${errorToString(e)})`)
            break
        }
        Logger.error('error', e)
      })
    }
  }, [response])

  if (!request) return null
  return <LoginOptButton color="#DB4437" title="Login with Google" icon="google" onPress={() => promptAsync()} />
}
