import { Picker as RNPicker } from '@react-native-picker/picker'
import { memo } from 'react'
import { StyleSheet, View } from 'react-native'

import { Spinner } from '../Spinner'
import { fontSize, typography } from '../Text'
import { PickerProps, parseItems } from './helpers'

import Colors from '@/constants/Colors'
import { useDeviceSize } from '@/hooks/useLayout'
import { BottomSheetPicker } from '../BottomSheet/BottomSheetPicker'
/**
 * Web <select /> component. This opens a dropdown picker on web.

 * - In web, the default chevron-down icon is not customizable, and the ref.focus() does not work. So a custom chevron-down icon would not be able to open the picker through ref.focus.
 */
export const Picker = memo(function Picker({ useWebNativePicker, ...props }: PickerProps) {
  const { isSmallDevice } = useDeviceSize()

  /** Small devices will show the bottom picker */
  if (isSmallDevice && !useWebNativePicker) {
    return <BottomSheetPicker {...props} />
  }

  const { items, placeholder, style, value, disabled, loading, minimal, ...rest } = props

  return (
    <View style={style}>
      <RNPicker
        enabled={!disabled && !loading}
        style={[styles.wrapper, disabled || loading ? styles.disabled : undefined, minimal && styles.minimal]}
        // If value is null it will throw an error to the console, this happens from Algolia Menu default refinements. We should always cast null as undefined for the web picker
        selectedValue={value ?? undefined}
        mode="dropdown"
        {...rest}
      >
        {parseItems({ items, placeholder, value }).map((item) => (
          <RNPicker.Item key={item.value} {...item} />
        ))}
      </RNPicker>
      {/* Chevron Down Icon is not showing in Web as pickerRef.focus() is not working for web, therefore the icon won't be pressable */}
      {loading && (
        <View style={styles.iconCont}>
          <Spinner size="small" />
        </View>
      )}
    </View>
  )
})

const styles = StyleSheet.create({
  wrapper: {
    height: 40,
    borderWidth: 1,
    borderColor: Colors.lightGray,
    borderRadius: 8,
    fontFamily: typography.body.regular,
    fontSize: fontSize(14, 2),
    color: Colors.shades['600'],
    paddingLeft: 10,
    backgroundColor: Colors.transparent,
    paddingRight: 20, // to ensure the text is never behind the icon
  },
  disabled: {
    color: Colors.shades['300'],
  },
  minimal: {
    borderWidth: 0,
  },
  /** This needs to be above the picker, that's why flex row wouldn't work */
  iconCont: {
    right: 20,
    width: 30,
    top: 4,
    bottom: 4,
    zIndex: 2,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
  },
})
