// eslint-disable-next-line no-restricted-imports
import { ActivityIndicator, StyleSheet, View } from 'react-native'

import Colors from '../../../constants/Colors'

/** Full screen loader component to be used inside a portal */
export default function LoaderFullscreen() {
  return (
    <View style={styles.cont}>
      <ActivityIndicator size="large" color={Colors.shades['50']} />
    </View>
  )
}

const styles = StyleSheet.create({
  cont: {
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.6,
    backgroundColor: Colors.black,
    ...StyleSheet.absoluteFillObject,
  },
})
