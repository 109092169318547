import { SkeletonContent } from '@components'
import { Divider, HeaderText, Hoverable, Icon, Text, Touchable } from '@elements'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { getCoordString } from '@helpers/coordinate'
import { formatStreetAddress } from '@helpers/display'
import { Address } from '@models/Address'
import {
  AlgoliaGeoDistro,
  AlgoliaGeoDoc,
  AlgoliaGeoFarm,
  AlgoliaGeoProduct,
  isGeoDistro,
  isGeoFarm,
  isGeoProduct,
} from '@models/Algolia'
import { FarmStatus } from '@models/Farm'
import { ProductType } from '@models/Product'
import { memo, useState } from 'react'
import { ImageStyle, StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native'
import { useDispatch } from 'react-redux'

import { Tag } from '../../admin/components/ProductTag'
import { EbtIcon } from '../../components/EbtIcon'
import { Image, ResizedSuffix } from '../../components/Image'
import Colors from '../../constants/Colors'
import { SHADOW_BLUR, globalStyles } from '../../constants/Styles'
import { setCurrentHover } from '../../redux/actions/appState'
import { FarmContainer } from './FarmContainer'
import { GrayCircle } from './components/HelperComponents'

import { useLayout } from '@/hooks/useLayout'

const makeFarmTitle = (name: string, unlisted: boolean) => {
  return `${unlisted ? ' ' : ''}${name}`
}

function FarmContent({ farm }: { farm: AlgoliaGeoDoc<AlgoliaGeoFarm> }) {
  return (
    <View style={styles.textContainer}>
      <HeaderText numberOfLines={1}>{farm.name}</HeaderText>
      <Text color={Colors.shades[300]} numberOfLines={1}>
        {formatStreetAddress(farm.address as Address)}
      </Text>
      <Divider clear />
      <View style={globalStyles.flex1}>
        <Text color={Colors.shades[300]} size={12}>
          {farm.farm.productCount} products available at {farm.locationCount} distributions
        </Text>
        <Divider style={globalStyles.flex1} clear />
        {!!farm.farm.practices && (
          <Text color={Colors.shades[300]} size={10} type="medium">
            {farm.farm.practices.join(' • ')}
          </Text>
        )}
      </View>
    </View>
  )
}

function FarmContentDoc({ doc }: { doc: AlgoliaGeoDoc<AlgoliaGeoFarm | AlgoliaGeoDistro | AlgoliaGeoProduct> }) {
  const {
    address,
    farm: { status, practices, productCount, name },
  } = doc
  const hasShop = status === FarmStatus.Registered
  return (
    <Touchable url={makeFarmUrl(doc)}>
      <HeaderText numberOfLines={1}>
        <GrayCircle visible={!hasShop} size={14} />
        {makeFarmTitle(name, !hasShop)}
      </HeaderText>
      <Text color={Colors.shades[300]} numberOfLines={1}>
        {formatStreetAddress(address as Address)}
      </Text>
      <Divider clear />
      <View style={globalStyles.flex1}>
        <Text color={Colors.shades[300]} size={12}>
          {hasShop ? `${productCount} products available` : 'Not on GrownBy yet'}
        </Text>
        <Divider style={globalStyles.flex1} clear />
        <Text color={Colors.shades[300]} size={10} type="medium">
          {practices.join(' • ')}
        </Text>
      </View>
    </Touchable>
  )
}

function DistroContent({ distro }: { distro: AlgoliaGeoDoc<AlgoliaGeoDistro> }) {
  return (
    <View style={styles.textContainer}>
      <HeaderText numberOfLines={1}>{distro.farm.name}</HeaderText>
      <Text color={Colors.shades[300]} numberOfLines={1}>
        at <Icon name="map-marker-alt" size={14} color={Colors.shades[300]} /> {distro.distroNickname}
      </Text>
      <Divider clear />
      <Text>{distro.scheduleText}</Text>
      <View style={globalStyles.flex1}>
        <Divider style={globalStyles.flex1} clear />
        <Text color={Colors.shades[300]} size={10} type="medium">
          {distro.farm.productCount} products available across all pickup locations
        </Text>
      </View>
    </View>
  )
}

function DistroLine({ distro }: { distro: AlgoliaGeoDoc<AlgoliaGeoDistro> }) {
  return (
    <Hoverable>
      {(isHovered) => (
        <Touchable
          style={{ marginVertical: 5, flexDirection: 'row', alignItems: 'center' }}
          url={makeFarmUrl(distro, true)}
        >
          <Icon
            name="map-marker-alt"
            size={17}
            color={Colors.shades[isHovered ? 200 : 100]}
            style={{ marginRight: 5 }}
            noHover
          />
          <Text color={Colors.shades[isHovered ? 400 : 300]}>{`${distro.distroNickname}. ${distro.scheduleText}`}</Text>
        </Touchable>
      )}
    </Hoverable>
  )
}

function DistrosSection({ distros, isSmall = false }: { distros: MultilLocFarmData['distros']; isSmall?: boolean }) {
  const maxAllowed = 7
  const minAllowed = 3
  const [max, setMax] = useState(minAllowed)
  const collapsed = distros.length - max
  const underline: TextStyle = { textDecorationLine: 'underline' }
  const plural = collapsed === 1 ? '' : 's'
  const handlePress = () => {
    if (max < maxAllowed) setMax(maxAllowed)
    else setMax(minAllowed)
  }
  return (
    <>
      {distros.map((distro, i) => {
        if (i < max) return <DistroLine key={distro.id} distro={distro} />
        if (i === max && max < maxAllowed)
          return (
            <Hoverable key={distro.id}>
              {(isHovered) => (
                <Text
                  onPress={handlePress}
                  color={Colors.green}
                  style={[{ marginVertical: 5 }, isHovered && underline]}
                >{`+ ${collapsed} more pickup location${plural}`}</Text>
              )}
            </Hoverable>
          )
      })}
      {distros.length > maxAllowed && max === maxAllowed && (
        <Hoverable>
          {(isHovered) => (
            <Touchable
              style={{ marginVertical: 3, flexDirection: 'row', alignItems: 'center' }}
              url={makeFarmUrl(distros[0])}
            >
              <MaterialCommunityIcons
                name="map-marker-multiple"
                size={!isSmall ? 23 : 21}
                color={Colors.shades[isHovered ? 200 : 100]}
                style={{ marginRight: 5, marginLeft: -4 }}
                noHover
              />
              <Text
                color={Colors.shades[isHovered ? 400 : 300]}
              >{`See all (${collapsed} more location${plural})`}</Text>
            </Touchable>
          )}
        </Hoverable>
      )}
      {distros.length > minAllowed && max === maxAllowed && (
        <Hoverable>
          {(isHovered) => (
            <Text onPress={handlePress} color={Colors.green} style={[{ marginVertical: 5 }, isHovered && underline]}>
              - See less pickup locations
            </Text>
          )}
        </Hoverable>
      )}
    </>
  )
}

function ProductContent({ product }: { product: AlgoliaGeoDoc<AlgoliaGeoProduct> }) {
  return (
    <View style={styles.textContainer}>
      <HeaderText numberOfLines={1}>
        {product.name} <EbtIcon visible={product.isEbt} />
      </HeaderText>
      <Text color={Colors.shades[300]} numberOfLines={1}>
        {product.distroNickname}
      </Text>
      <Text numberOfLines={1}>{product.shortDescription}</Text>
      <Text size={14} type="bold" style={{ marginVertical: 5 }}>
        {product.type === ProductType.Standard
          ? `From ${product.priceInMap ? product.priceInMap : 0}`
          : `${product.priceInMap ? product.priceInMap : '??'}/pickup`}
      </Text>

      <FarmContainer farm={product.farm} />
    </View>
  )
}

export const makeFarmUrl = (
  doc: AlgoliaGeoDoc<AlgoliaGeoFarm | AlgoliaGeoProduct | AlgoliaGeoDistro>,
  useLocation = false,
) => {
  const farmUrl = `/farms/${doc.farm.id}`
  const hasShop = doc.farm.status === FarmStatus.Registered
  const goBackParam = '?goBack=explore'
  const shopOrFarm = hasShop ? '/shop' : ''
  const location = useLocation && hasShop && isGeoDistro(doc) ? '&locationId=' + doc.locationId : ''
  return farmUrl + shopOrFarm + goBackParam + location
}

export type MultilLocFarmData = {
  farm?: AlgoliaGeoDoc<AlgoliaGeoFarm>
  /** Distros of the farm prop */
  distros: AlgoliaGeoDoc<AlgoliaGeoDistro>[]
  objectID: AlgoliaGeoDoc<AlgoliaGeoFarm>['objectID']
}

/** A card for the map list component, which aggregates distros and farm documents into a single card */
export function GeoSearchCardMulti({ data }: { data: AlgoliaGeoDoc<AlgoliaGeoProduct> | MultilLocFarmData }) {
  const dispatch = useDispatch()
  const onHoverIn = () => dispatch(setCurrentHover(getCoordString(doc._geoloc)))
  const onHoverOut = () => {
    /**we don't want to reset the hovered state on hover out. It should stay after hover */
  }
  const { isSmallDevice } = useLayout()
  const isProd = isGeoProduct(data)

  if (isProd) {
    const prodData = data as AlgoliaGeoDoc<AlgoliaGeoProduct>
    const onProdHoverIn = () => dispatch(setCurrentHover(getCoordString(prodData._geoloc)))
    return (
      <Hoverable onHoverIn={onProdHoverIn} onHoverOut={onHoverOut}>
        <View>
          <GeoSearchCard data={prodData} />
        </View>
      </Hoverable>
    )
  }
  data = data as MultilLocFarmData

  const doc = data.farm || data.distros[0]
  if (!doc) return <GeoSearchSkeleton />

  const contStyle = (isHovered: boolean): StyleProp<ViewStyle> => [
    styles.touchableContainer,
    isHovered && styles.outline,
    isSmallDevice && { flexDirection: 'column' },
  ]

  const imageStyle: StyleProp<ImageStyle> = [
    styles.cardImage,
    { overflow: 'hidden' },
    isSmallDevice && styles.smallCardImage,
  ]

  return (
    <Hoverable onHoverIn={onHoverIn} onHoverOut={onHoverOut}>
      {(isHovered) => (
        <View style={contStyle(isHovered)}>
          <Touchable style={imageStyle} url={makeFarmUrl(doc)}>
            <Image
              source={{ uri: doc.images[0] }}
              style={globalStyles.flex1}
              resizeMode="cover"
              resizeSuffix={ResizedSuffix.THUMB}
              type={isProd ? 'product' : 'farm'}
            />
          </Touchable>
          <View style={styles.textContainer}>
            <FarmContentDoc doc={doc} />
            <DistrosSection distros={(data as MultilLocFarmData).distros} isSmall={isSmallDevice} />
          </View>
        </View>
      )}
    </Hoverable>
  )
}

export const GeoSearchCard = memo(function GeoSearchCard({
  data,
  onCardPress,
}: {
  data: AlgoliaGeoDoc<AlgoliaGeoProduct>
  onCardPress?: () => void
}) {
  if (!data.id) return <GeoSearchSkeleton />

  return (
    <Touchable onPress={onCardPress} style={styles.touchableContainer} url={makeFarmUrl(data)}>
      <Image
        source={{ uri: data.images[0] }}
        style={styles.cardImage}
        resizeMode="cover"
        resizeSuffix={ResizedSuffix.THUMB}
        type={data.docType === 'product' ? 'product' : 'farm'}
      />
      {isGeoFarm(data) && <Tag inverse color={Colors.green} title="FARM" contStyle={styles.tagStyle} />}
      {isGeoDistro(data) && <Tag inverse color={Colors.blue} title="PICKUP" contStyle={styles.tagStyle} />}

      {isGeoFarm(data) && <FarmContent farm={data} />}
      {isGeoDistro(data) && <DistroContent distro={data} />}
      {isGeoProduct(data) && <ProductContent product={data} />}
    </Touchable>
  )
})

/** This needs to be updated if the styling of this card component ever changes in a way that produces a different card width */
export const geoSearchCardWidth = 550

function GeoSearchSkeleton() {
  return (
    <SkeletonContent
      containerStyle={globalStyles.flex1}
      isLoading
      layout={[{ width: '95%', height: 165, margin: 10, borderRadius: 10 }]}
    />
  )
}

const styles = StyleSheet.create({
  touchableContainer: {
    margin: 10,
    flexDirection: 'row',
    borderRadius: 10,
    ...SHADOW_BLUR,
    borderWidth: 1,
    borderColor: Colors.transparent,
    // Background color is needed in order to have shadows on iOS / Android
    // If not, iOS shadows are not visible / on Android are applied inwards
    backgroundColor: Colors.white,
  },
  isSelected: {
    borderWidth: 2,
    borderColor: Colors.green,
  },
  textContainer: {
    paddingHorizontal: 16,
    paddingVertical: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    flex: 2,
  },
  cardImage: {
    flex: 1,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },

  smallCardImage: {
    flex: undefined,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 10,
    minHeight: 175,
  },
  tagStyle: { position: 'absolute', left: 0, top: 5 },
  outline: {
    borderColor: Colors.shades[100],
  },
})
