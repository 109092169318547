import { Logger } from '@/config/logger'
import { userSelector } from '@/redux/selectors'
import { sendChatMessage } from '@api/Messaging'
import { LoadingView, Toast } from '@elements'
import { createParticipantsData, flagMessagesWithDates } from '@helpers/messaging'
import { ChatMessage, ChatSession } from '@models/Messaging'
import { useCallback, useMemo } from 'react'
import { FlatList, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import { ChatBubble } from '../ChatBubble/ChatBubble'
import { ConversationInput } from './components/ConversationInput'
import { DateSeparator } from './components/DateSeparator'

type ConversationViewProps = {
  /** Messages that are showing*/
  data: ChatMessage[]
  /** Current user's id, used for showing different UI for the user's own messages */
  currUserId: string
  loading: boolean
  error?: string
  chatSession?: ChatSession
  adminFarmId?: string
}

/** Component that shows the messages from a conversation */
export function ConversationView({
  data,
  currUserId,
  chatSession,
  loading,
  error,
  adminFarmId,
}: ConversationViewProps) {
  const user = useSelector(userSelector)
  const items = useMemo(() => flagMessagesWithDates(data), [data])

  const onSendMessage = useCallback(
    async (message: string) => {
      if (!chatSession) return
      const data = createParticipantsData(chatSession, adminFarmId ?? user.id, user)

      if (!data) {
        Toast('Unable to send message, please contact support.')
        return
      }

      try {
        await sendChatMessage({
          to: data.to,
          from: data.from,
          chatSessionId: chatSession?.id,
          message: { type: 'text', content: message },
        })
      } catch (err) {
        Logger.error(err)
      }
    },
    [adminFarmId, chatSession, user],
  )

  return (
    <LoadingView switchMode error={error} loading={loading}>
      <FlatList
        data={items}
        contentContainerStyle={styles.main}
        inverted
        renderItem={({ item }) => (
          <View>
            {item.shouldShowDate && <DateSeparator item={item} />}
            <ChatBubble currUserId={currUserId} item={item} />
          </View>
        )}
        extraData={currUserId}
        ListHeaderComponent={() => <ConversationInput onSendMessagePress={onSendMessage} />}
      />
    </LoadingView>
  )
}

const styles = StyleSheet.create({
  main: {
    flexGrow: 1,
  },
})
