//https://stackoverflow.com/a/69480481
import { useState } from 'react'
import { Keyboard } from 'react-native'

import { useFocusFx } from './useFocusFx'

/** @deprecated use Keyboard.isVisible instead */
export function useKeyboard() {
  const [isKeyboardVisible, setKeyboardVisible] = useState(false)

  useFocusFx(() => {
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
      setKeyboardVisible(true)
    })
    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
      setKeyboardVisible(false)
    })

    return () => {
      keyboardDidHideListener.remove()
      keyboardDidShowListener.remove()
    }
  }, [])

  return isKeyboardVisible
}
