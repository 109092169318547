import { BackTo } from '@/admin/components/BackTo'
import { withAdminAuth } from '@/hooks/withAdminAuth'
import { adminFarmIdSelector } from '@/redux/selectors'
import { HeaderText } from '@elements'
import { ChatScreenComp_Reusable } from '@screens/ChatScreen/ChatScreen'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

function AdminChatScreenComp() {
  const adminFarmId = useSelector(adminFarmIdSelector)

  return (
    <View style={styles.main}>
      <BackTo url="/admin/messages" title="Messages" />
      <HeaderText size={24}>Chat</HeaderText>
      <ChatScreenComp_Reusable adminFarmId={adminFarmId} />
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    flex: 1,
    padding: 20,
    gap: 10,
  },
})

/** Admin Chat Screen. Will be the same as on the consumer side but with Admin Auth Check and a wrapper*/
export const AdminChatScreen = withAdminAuth(AdminChatScreenComp)
