import { memo, useCallback, useContext, useState } from 'react'
import { LayoutChangeEvent, StyleSheet, View } from 'react-native'

import { isMobile } from '../../../constants/Layout'
import { useFnStyles } from '../../../hooks/useFnStyles'
import { useDeviceSize } from '../../../hooks/useLayout'
import { ExploreContext } from './ExploreContext'
import { FloatingButton } from './HelperComponents'
import MapKeyButton, { MapKey } from './MapKey'
import { MobileCarousel } from './MobileCarousel'

type Props = {
  hasSelectedMarker: boolean
}

/** Renders the UI for anything that goes on the bottom of the visible map  */
export const MapBottomOverlay = memo(function MapBottomOverlay({ hasSelectedMarker }: Props) {
  const { isSmallDevice, isLargeDevice } = useDeviceSize()
  const { setCondensedView } = useContext(ExploreContext)
  const [showKey, setShowKey] = useState(false)
  const [floatMargin, setFloatMargin] = useState(isSmallDevice ? 60 : 140)

  const rStyles = useFnStyles(
    (floatMargin) => ({
      floatingBtn: {
        flex: 1,
        alignItems: 'center',
        marginRight: floatMargin,
      },
    }),
    floatMargin,
  )

  const onWidth = useCallback((e: LayoutChangeEvent) => setFloatMargin(e.nativeEvent.layout.width), [setFloatMargin])
  const toggleShowKey = useCallback(() => setShowKey((prev) => !prev), [setShowKey])

  return (
    <View style={styles.bottomContainer}>
      {showKey && (
        <View style={styles.row}>
          <MapKey onPress={toggleShowKey} />
        </View>
      )}
      <View style={styles.row}>
        <MapKeyButton onPress={toggleShowKey} onWidth={onWidth} />
        <View style={rStyles.floatingBtn}>
          {!isLargeDevice && !!floatMargin && <FloatingButton view="map" onPress={setCondensedView} />}
        </View>
      </View>
      {isMobile && !isLargeDevice && !hasSelectedMarker && <MobileCarousel />}
    </View>
  )
})

const styles = StyleSheet.create({
  bottomContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    width: '100%',
  },
})
