import { loadCSAsByFarm } from '@api/CSAs'
import { LoaderWithMessage, ToolTips } from '@components'
import { Button, HeaderText, Text, ToggleButton, Tooltip } from '@elements'
import { CSA } from '@models/CSA'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import * as React from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { setAdminCSAsAction } from '../../../redux/actions/adminState'
import { RootState } from '../../../redux/reducers/types'
import { adminCsasSelector, adminFarmIdSelector } from '../../../redux/selectors'
import { AdminView } from '../../components/AdminView'
import InputLabel from '../../components/InputLabel'
import { OfflineTable, OfflineTableProps } from '../../components/OfflineTable/OfflineTable'
import { CSAParamList } from '../../navigation/types'
import { CSACardAdmin } from './components/CSACardAdmin'

import { isWeb } from '@/constants/Layout'
import { globalStyles } from '@/constants/Styles'
import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { useDeviceSize } from '@/hooks/useLayout'
import { withAdminAuth } from '@/hooks/withAdminAuth'
import { Permission } from '@helpers/Permission'

function CSAGroupScreen() {
  const { isSmallDevice } = useDeviceSize()
  const dispatch = useDispatch()
  const navigation = useNavigation<StackNavigationProp<CSAParamList>>()

  const farmId = useSelector(adminFarmIdSelector)
  const adminAllCSAs = useSelector<RootState, CSA[]>(adminCsasSelector)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showHidden, setShowHidden] = useState(false)

  const styles = useStyles()

  /** csasVisible holds "adminAllCSAs with hidden CSAs" or "adminAllCSAs without hidden CSAs" due to the value change of showHidden  */
  const csasVisible = useMemo(
    () => adminAllCSAs.filter((csa) => (showHidden ? true : csa.isHidden !== true)),
    [adminAllCSAs, showHidden],
  )

  useEffect(() => {
    if (farmId) {
      setIsLoading(true)
      loadCSAsByFarm(farmId).then((val) => {
        // FIXME: Should use snapshot listener. Loading false should go after dispatch.
        setIsLoading(false)
        dispatch(setAdminCSAsAction(val))
      })
    }
  }, [dispatch, farmId])

  const csaDetail = useCallback(
    (csa: CSA) => {
      navigation.navigate('CSADetail', { csaId: csa.id })
    },
    [navigation],
  )

  /** RenderItem for offlineTable (CSA list) */
  const renderItem = useCallback<NonNullable<OfflineTableProps<CSA>['renderItem']>>(
    ({ item: csa, index }) => {
      return (
        <CSACardAdmin
          isLastCSA={csasVisible.length - 1 === index}
          csa={csa}
          key={`${csa.id}-${index}`}
          index={index}
          csaDetail={csaDetail}
        />
      )
    },
    [csasVisible, csaDetail],
  )

  const navAddCSA = useCallback(() => {
    navigation.navigate('CSADetail', { csaId: '' })
  }, [navigation])

  return (
    <AdminView style={styles.container}>
      <View style={styles.header}>
        <View style={globalStyles.flexRowCenter}>
          <HeaderText size={30}>CSA Groups</HeaderText>
          <Tooltip id={ToolTips.CSA_GROUPS} size={15} title="CSA Groups" />
        </View>
        <View style={styles.headerRow}>
          <Button style={styles.csaButton} small title="Add CSA groups" onPress={navAddCSA} />
          <View style={globalStyles.flexRowCenter}>
            <ToggleButton value={showHidden} title="Show hidden" onChange={setShowHidden} />
            <InputLabel
              style={styles.toggleInputLabel}
              label=""
              tooltipId={ToolTips.TOGGLE_HIDDEN}
              tooltipTitle="Toggle Hidden CSAs"
            />
          </View>
        </View>
      </View>

      <OfflineTable<CSA>
        /** By default the scrollEnable is false, so the mobile app can scroll on the list without problem.
           * The reason to set scrollEnabled
           - 'isWeb' => For mobile web, we have to set scrollEnable true to make table scrollable and then trigger entire screen can be scrollable as well. And for normal big screen web, the behavior stays same.
           - '!isSmallDevice', for bigger touch screen like IPad, we have to make the table scrollable and since we set breakpoint as isSmallDevice, we set !isSmallDevice for all bigger touch screen to be able to scroll on app, for web version, it is covered by setting 'isWeb' */
        scrollEnabled={isWeb || !isSmallDevice}
        data={csasVisible}
        minWidth={1200}
        headerColumns={[
          { widthFlex: 0.5 /** Accounts for the image */ },
          { title: 'Name', widthFlex: 2 },
          { title: 'Short Description', widthFlex: 3 },
          {},
          {},
          {
            process: () => (
              <View style={styles.actionsCont}>
                <Text>Actions</Text>
                <Tooltip id={ToolTips.CSA_GROUPS_ACTIONS} />
              </View>
            ),
          },
        ]}
        isLoading={isLoading}
        renderItem={renderItem}
        containerStyle={styles.offlineTableContainer}
        ListEmptyComponent={() => (
          <LoaderWithMessage loading={isLoading} icon="box" title="No CSAs!">
            <Text>You have no CSAs!</Text>
          </LoaderWithMessage>
        )}
      />
    </AdminView>
  )
}

const useStyles = () =>
  useLayoutFnStyles(({ isSmallDevice, height }) => ({
    container: {
      paddingHorizontal: isSmallDevice ? 10 : 30,
      paddingTop: isSmallDevice ? 10 : 30,
    },
    offlineTableContainer: {
      /** If it is not smallDevice or not extraSmallDevice, it means templates table is enabled to have nested scroll, so the maxHeight should be set to height * 0.77 (depend on the the table size and best view) to help to achieve scroll functionality. Otherwise, the maxHeight should be auto adjusted to correct height of table to be able to show all rows from scrolling entire screen if need.
       * Some case 'auto' maxHeight is not working, so we have to set maxHeight to 100% to make it work.
       */
      maxHeight: !isSmallDevice ? height * 0.77 : '100%',
    },
    headerRow: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    header: {
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 10,
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    csaButton: {
      marginRight: 20,
    },
    toggleInputLabel: {
      paddingBottom: 10,
    },
    actionsCont: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
    },
  }))

export default withAdminAuth(CSAGroupScreen, Permission.ProductSetup)
