import {
  EbtFilter,
  FarmStatusFilter,
  FILTERS,
  GeoDocTypeFilter,
  HiddenFilter,
  initialFiltersMapSearch,
  isEbtFilter,
  isStatusFilter,
  MapFilters,
} from '@models/Algolia'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useDeepCompareCallback } from '@/hooks/useDeepEqualEffect'
import { HomeParamList } from '@/navigation/types'
import { setSearchFilter } from '@/redux/actions/appState'
import { searchFiltersSelector } from '@/redux/selectors'

/** The different kinds of facet filters used in the map */
type MapFacetFilter = GeoDocTypeFilter | FarmStatusFilter | HiddenFilter | EbtFilter

export const useBuildFilters = () => {
  const dispatch = useDispatch()
  const currFilters = useSelector(searchFiltersSelector)
  const navigation = useNavigation<StackNavigationProp<HomeParamList, 'ExploreScreen'>>()

  /** Controls how map filters should change when toggled. Updates navigation params and dispatches new map filters */
  return useDeepCompareCallback(
    (toggledFilter: MapFacetFilter) => () => {
      const currTypeFilter = currFilters[0]
      const currStatusFilter = currFilters[1]
      const currEbtFilter = currFilters[2]

      let newTypeFilter = [...currFilters[0]]
      let newStatusFilter = [...currFilters[1]]
      let newEbtFilter = [...currFilters[2]]

      if (isStatusFilter(toggledFilter)) {
        //manage the status filter
        newStatusFilter = currStatusFilter.includes(toggledFilter)
          ? [] //if filter already exists, remove it. since this filter only has two values: reg/ non-reg, it's the same as reseting the filter
          : [toggledFilter] // if the opposite filter exists, or if no filter existed before, refine by the toggled status only

        //Updating docType filter as side-effect of toggling the status filter...
        newTypeFilter =
          newStatusFilter[0] === FILTERS.NotRegistered
            ? [FILTERS.Farm] //If refining by unregistered status, show only farms
            : (!newStatusFilter.length || newStatusFilter[0] === FILTERS.Registered) &&
              currStatusFilter[0] === FILTERS.NotRegistered
            ? initialFiltersMapSearch[0] // Else if the previous refinement was notRegistered, and we're reseting the status, or refining by registered, reset doctypes because products and distros must have been hidden previously
            : currTypeFilter //Else, leave the docType filter as-is

        // if refining by notRegistered, reset any existing product category refinements. otherwise no results will be returned because this doesn't get removed automatically
        // this change gets handled by VirtualParamRefinement for category attribute
        if (newStatusFilter[0] === FILTERS.NotRegistered) navigation.setParams({ category: undefined })

        dispatch(
          setSearchFilter([
            newTypeFilter,
            newStatusFilter,
            newEbtFilter,
            FILTERS.NotHidden,
            FILTERS.NotInactiveFarm,
            FILTERS.NotPrivateProd,
          ]),
        )
      } else if (isEbtFilter(toggledFilter)) {
        /** Info: For Ebt filter, we're assuming there's only two possible states: Either filter by ebt, or not filter by ebt (show all) */

        //#1. toggle the ebt filter
        newEbtFilter = !currEbtFilter.length ? [FILTERS.Ebt] : []
        //#2. if we're enabling the ebt filter, then make sure...
        if (!currEbtFilter.length) {
          //  #2.1. the status filter isn't `NotRegistered`
          if (currFilters[1].includes(FILTERS.NotRegistered)) newStatusFilter = []
          //  #2.2. the type filter includes products
          if (!currTypeFilter.find((f) => f === FILTERS.Product)) newTypeFilter.push(FILTERS.Product)
        }
        dispatch(
          setSearchFilter([
            newTypeFilter,
            newStatusFilter,
            newEbtFilter,
            FILTERS.NotHidden,
            FILTERS.NotInactiveFarm,
            FILTERS.NotPrivateProd,
          ]),
        )
      } else if (toggledFilter === FILTERS.Distro) {
        newTypeFilter = currTypeFilter.includes(FILTERS.Distro)
          ? currTypeFilter.filter((f) => f !== FILTERS.Distro)
          : currTypeFilter.concat(FILTERS.Distro)
        dispatch(
          setSearchFilter([
            newTypeFilter,
            newStatusFilter,
            newEbtFilter,
            FILTERS.NotHidden,
            FILTERS.NotInactiveFarm,
            FILTERS.NotPrivateProd,
          ]),
        )
      } else throw new Error(`Build filters is not configured to handle this filter type: ${toggledFilter}`)
    },
    [currFilters, dispatch, navigation],
  )
}

/** Returns a helper that checks if a given filter is activated in the current filters */
export const useFiltersIncludes = (filters: MapFilters) =>
  useCallback(
    (filter: MapFacetFilter) => {
      let isIncluded = false

      filters.forEach((f) => {
        if (isIncluded) return

        if (typeof f === 'string') {
          if (f === filter) isIncluded = true
        } else {
          f?.forEach((subf) => {
            if (subf === filter) isIncluded = true
          })
        }
      })
      return isIncluded
    },
    [filters],
  )
