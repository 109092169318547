import { loadFarm } from '@api/Farms'
import { loadProduct } from '@api/Products'
import { Button, Divider, hideModal, LoadingView, TextH2, TextH4 } from '@elements'
import { getOrderItem } from '@helpers/display'
import { getProductAvailability } from '@helpers/products'
import { isAfter } from '@helpers/time'
import { isPickupItemActive, OrderItem, Pickup, PickupItem } from '@models/Order'
import { CancellationTypes, isShare } from '@models/Product'
import { dateTimeInZone } from '@models/Timezone'
import { useState } from 'react'
import { ScrollView, StyleSheet, Text, TextStyle, View } from 'react-native'

import { useApiFx } from '@/hooks/useApiFx'
import { useCancelableFocusFx } from '@/hooks/useCancelablePromise'
import { getStore } from '@/redux/store'
import { ordersCollection } from '@api/framework/ClientCollections'
import { Image, MessageWithIcon } from '@components'
import { getCsaChangeDeadlineFromPickup } from '@helpers/order'
import Colors from '../../constants/Colors'

const cancellationPolicies = async (pickup: Pickup, item?: OrderItem): Promise<{ canCancel: boolean; msg: string }> => {
  const now = dateTimeInZone(item?.distribution?.location.timezone)
  if (!item?.product || !isShare(item.product))
    return {
      canCancel: false,
      msg: '',
    }
  if (!item.product.cancellationPolicy)
    return { canCancel: false, msg: 'Please contact the farmer to request cancellation' }
  // Will check if the change deadline has passed
  const changeDeadline = getCsaChangeDeadlineFromPickup(
    pickup.date,
    item.csa?.changeWindow,
    pickup.distribution.hours.startTime,
  )

  if (item.product.cancellationPolicy === CancellationTypes.Strict) {
    // If we are in the change window or cancellation is strict then allow cancellation but no refund
    return { canCancel: false, msg: 'You will not receive a refund if cancelled' }
  } else if (isAfter(now, changeDeadline)) {
    return { canCancel: false, msg: 'The change window has already passed' }
  } else if (item.product.cancellationPolicy === CancellationTypes.Flexible) {
    // It is not after the change window so user gets full refund
    //FIXME: Change to full refund once we have refunding credit cards setup
    //return { canCancel: true, msg: 'You will receive a full refund' }
    return { canCancel: true, msg: 'You will receive a farm credit' }
  } else if (item.product.cancellationPolicy === CancellationTypes.FlexPreSeason) {
    // Load the product to get the availability
    const product = await loadProduct(item.product.id)
    if (!isShare(product) || !item.distribution) return { canCancel: false, msg: 'Wrong data' }

    const isWholesale = getStore().getState().appPersist.wholesale.isWholesale

    // Get a cutoff date before which full refunds can be issued. It'll be the availability start date minus one month.
    // Allow until the end of the day on the cutoff day
    const refundCutoffDate = (getProductAvailability(product, item.distribution.id, { isWholesale })?.startDate || now)
      .minus({ months: 1 })
      .endOf('day')

    if (isAfter(now, refundCutoffDate)) {
      // Since the season starts in less than 1 month or has already started, user get credit
      return { canCancel: true, msg: 'You will receive a farm credit' }
    } else {
      // Since it is more than a month from season start we give the user a full refund
      //FIXME: Change to full refund once we have refunding credit cards setup
      // return { canCancel: true, msg: 'You will receive a full refund' }
      return { canCancel: true, msg: 'You will receive a farm credit' }
    }
  } else if (item.product.cancellationPolicy === CancellationTypes.Moderate) {
    // It is not after the change window so user gets a balance credit
    return { canCancel: true, msg: 'You will receive a farm credit' }
  }
  return { canCancel: false, msg: 'This share cannot be cancelled' }
}

type Details = {
  item: PickupItem
  orderItem?: OrderItem
  cancel: {
    canCancel: boolean
    msg: string
  }
}

/** This modal doesn't cancel anything, it gives information to the consumer on how to reach out to the farmer to cancel an order */
export function ConsumerCancel({ pickup }: { pickup: Pickup }) {
  const orderIds = pickup.items.map((pi) => pi.order.id)
  /** This is is meant to fetch specifically those orders related to this pickup, to ensure every orderItem from the Pickup is found within the orders array */
  const { data: orders, loading, err } = useApiFx(ordersCollection.fetchByIds.bind(ordersCollection), [orderIds])
  const [itemDetails, setDetails] = useState<Details[]>([])
  const { data: farm } = useApiFx(loadFarm, [pickup.farm.id])

  /** calculates cancellation details for the active pickup items */
  useCancelableFocusFx(
    async (isCurrent) => {
      if (!orders || loading) return

      const getCancellation = (item: PickupItem) => {
        const ordItem = getOrderItem(orders, item)
        return cancellationPolicies(pickup, ordItem)
      }

      const activePickupItems = pickup.items.filter((itm) => isPickupItemActive(itm))
      const requests = activePickupItems.map(getCancellation)
      const newItems = (await Promise.all(requests)).map((cncl, idx) => {
        const item = activePickupItems[idx]
        return {
          item,
          cancel: cncl,
          orderItem: getOrderItem(orders, item),
        }
      })
      if (!isCurrent) return

      setDetails(newItems)
    },
    [pickup, orders, loading],
  )

  return (
    <>
      <LoadingView loading={loading} error={err}>
        {itemDetails.length > 0 ? (
          <ScrollView style={styles.scrollMargin}>
            {itemDetails.map(
              ({ orderItem, cancel }: Details, idx) =>
                orderItem && (
                  <View key={idx}>
                    <View style={styles.card}>
                      <Image
                        source={{
                          uri: orderItem.product.image,
                        }}
                        style={styles.cardImage}
                        resizeMode="cover"
                      />
                      <View style={styles.textContent}>
                        <TextH2 numberOfLines={1}>{orderItem.product.name}</TextH2>
                        <Text numberOfLines={1} style={styles.description}>
                          Quantity: {orderItem.quantity}
                        </Text>
                        <TextH4 style={textColor(cancel.canCancel)}>{cancel.msg}</TextH4>
                      </View>
                    </View>
                  </View>
                ),
            )}
            <Divider clear />
          </ScrollView>
        ) : (
          <MessageWithIcon title="No orders were found" />
        )}
      </LoadingView>
      <Divider clear />
      <Button url={`/farms/${farm?.id}?goBack=orders`} title="Contact Farm" onPress={hideModal} />
    </>
  )
}

const textColor = (value: boolean): TextStyle => ({
  color: value ? Colors.green : Colors.red,
})

const styles = StyleSheet.create({
  card: {
    display: 'flex',
    flexDirection: 'row',
    marginVertical: 5,
    alignItems: 'center',
  },
  cardImage: {
    borderRadius: 10,
    flex: 1,
    aspectRatio: 1,
    alignSelf: 'center',
    maxWidth: 108,
  },
  supportText: { marginLeft: 10, marginTop: -5, marginBottom: 10 },
  textContent: {
    flex: 2,
    padding: 10,
  },
  description: {
    color: Colors.shades[300],
    fontSize: 12,
  },
  scrollMargin: {
    marginHorizontal: 20,
  },
})
