import { Alert, Button, Picker, HeaderText, Icon, PICKER_PLACEHOLDER, Text, hideModal, PickerProps } from '@elements'
import { formatPickupDate } from '@helpers/display'
import { Distribution } from '@models/Distribution'
import React, { useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'

import { RescheduleData, SharePickup, confirmRescheduleAll } from './helper'
import { globalStyles } from '../../../../constants/Styles'

import Colors from '@/constants/Colors'
import { entries } from '@helpers/typescript'

type Props = {
  /**
   * @rescheduleDataObjs ,this will hold distId - RescheduleData pairs
   */
  rescheduleDataObjs: Record<string, RescheduleData>
  onChange?: () => void
}

const onClose = () => {
  hideModal()
}

export function ShareRescheduleAll({ rescheduleDataObjs, onChange }: Props) {
  const [option, setOption] = useState('')

  const count = !rescheduleDataObjs[option]
    ? 0
    : rescheduleDataObjs[option].sharePickups.filter((sp) => sp.canReschedule && !sp.onVacation && !sp.sameDistro)
        .length

  /** Create a dropDown items with each distro name and its id */
  const distroItems: PickerProps['items'] = entries(rescheduleDataObjs).map((obj) => ({
    label: obj[1].distro.closed ? `[Closed]${obj[1].distro.name}` : obj[1].distro.name,
    value: obj[0],
  }))

  // if there is no options to reschedule, then stop here (same distro is excluded already and deliver/shipping distro is excluded as well)
  if (distroItems.length === 0)
    return (
      <View style={styles.container}>
        <Text style={{ marginVertical: 30 }}>Sorry, rescheduling is not available for this item.</Text>
        <Button onPress={onClose} title="Close" style={{ marginHorizontal: 0 }} />
      </View>
    )

  return (
    <View style={styles.container}>
      <View style={globalStyles.flex1}>
        <HeaderText size={15}>*Choose a schedule for reschedule</HeaderText>
        <Picker
          value={option}
          items={distroItems}
          onValueChange={(value) => {
            // If placeholder selected
            if (value === PICKER_PLACEHOLDER) {
              setOption('')
              return
            }
            setOption(value)
          }}
        />
        <HeaderText size={15} style={{ marginTop: 10 }}>
          Result Preview:
        </HeaderText>
        {!option ? <Text>No result.</Text> : <PreviewResult rescheduleData={rescheduleDataObjs[option]} />}
      </View>

      {!option ? (
        <Button onPress={onClose} title="Close" style={{ marginHorizontal: 0 }} />
      ) : (
        <View style={globalStyles.flexRow}>
          <Button onPress={onClose} title="Close" style={globalStyles.flex1} />
          <Button
            onPress={() =>
              Alert(
                'Confirmation',
                `Are you sure you are going to reschedule these ${count} pickups to new schedule? `,
                [
                  {
                    text: 'Ok',
                    onPress: () => confirmRescheduleAll(option, rescheduleDataObjs, count, onChange),
                  },
                  {
                    text: 'Cancel',
                    style: 'cancel',
                  },
                ],
              )
            }
            title={`Reschedule(${count})`}
            disabled={count === 0}
            style={globalStyles.flex1}
          />
        </View>
      )}
    </View>
  )
}

export default ShareRescheduleAll

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 20,
    minHeight: 400,
  },
})

type PreviewResultProps = {
  rescheduleData: RescheduleData
}

function PreviewResult({ rescheduleData }: PreviewResultProps) {
  if (!rescheduleData) return <Text>No result.</Text>

  const newDistro = rescheduleData.distro
  const sharePickups = rescheduleData.sharePickups

  return (
    <ScrollView nestedScrollEnabled horizontal contentContainerStyle={{ minWidth: 750 }}>
      <View style={globalStyles.flex1}>
        <ScrollView nestedScrollEnabled style={{ maxHeight: 300 }}>
          {sharePickups.map((sharePickup) => (
            <ResultRow
              key={`${sharePickup.pickupId}_${sharePickup.distId}_${sharePickup.pickupDate.toISODate()}`}
              {...sharePickup}
              newDistro={newDistro}
            />
          ))}
        </ScrollView>
      </View>
    </ScrollView>
  )
}

/**
 * Custom component to display the rows of reschedule result.
 */
function ResultRow({
  pickupDate,
  newDistro,
  rescheduleDate,
  canReschedule,
  onVacation,
  sameDistro,
}: SharePickup & { newDistro: Distribution }) {
  if (onVacation) {
    return (
      <View style={{ flexDirection: 'row', marginVertical: 3 }}>
        <Text>{`${formatPickupDate(pickupDate)}(current schedule)`}</Text>
        <Icon style={{ marginHorizontal: 5 }} solid name="times" color={Colors.red} />
        <Text color={Colors.red}>Vacation pickup could not be reschedule</Text>
      </View>
    )
  } else if (sameDistro) {
    return (
      <View style={{ flexDirection: 'row', marginVertical: 3 }}>
        <Text>{`${formatPickupDate(pickupDate)}(current schedule)`}</Text>
        <Icon style={{ marginHorizontal: 5 }} solid name="times" color={Colors.red} />
        <Text color={Colors.red}>The Pickup is already in this schedule</Text>
      </View>
    )
  } else if (canReschedule)
    return (
      <View style={{ flexDirection: 'row', marginVertical: 3 }}>
        <Text>{`${formatPickupDate(pickupDate)}(current schedule)`}</Text>
        <Icon style={{ marginHorizontal: 5 }} solid name="arrow-right" />
        <Text>{`${formatPickupDate(rescheduleDate!)}(${newDistro.name})`}</Text>
      </View>
    )
  else
    return (
      <View style={{ flexDirection: 'row', marginVertical: 3 }}>
        <Text>{`${formatPickupDate(pickupDate)}(current schedule)`}</Text>
        <Icon style={{ marginHorizontal: 5 }} solid name="times" color={Colors.red} />
        <Text color={Colors.red}>No availability.</Text>
      </View>
    )
}
