import { PartialPick } from '@helpers/typescript'
import { Distribution } from '@models/Distribution'
import { Product } from '@models/Product'

export enum ReturnStates {
  NO_CHANGE,
  DO_NOTHING,
  SAVE,
  PROCEED,
}

export type ReturnTypes<T = PartialPick<Distribution, 'id'>> =
  | {
      status: ReturnStates.NO_CHANGE | ReturnStates.DO_NOTHING | ReturnStates.PROCEED
    }
  | {
      status: ReturnStates.SAVE
      data: T
      linkedProducts: Product[]
    }
