import { TouchableOpacity, View, StyleSheet } from 'react-native'

import Colors from '../../../constants/Colors'
import { PropsWithChildren, useCallback, useRef, useState } from 'react'
import { MenuOverlay } from '../Overlays/MenuOverlay/MenuOverlay'
import { Hoverable, Icon } from '@elements'
import { TooltipBaseProps } from './Tooltip'

const hitSlop = { top: 5, bottom: 5, left: 5, right: 5 }

/** This is the base tooltip for web that handles the rendering, it should never be called directly only through Tooltip */
export function TooltipBase({ children, size = 10, ...iconProps }: PropsWithChildren<TooltipBaseProps>) {
  const sourceRef = useRef<TouchableOpacity>(null)
  const [showTooltip, setShowTooltip] = useState<'hovered' | 'clicked' | 'closed'>('closed')

  const toolTipHoverOut = useCallback(() => {
    // We only hide on hover out if the tooltip was opened with hover, otherwise the user must click to close
    if (showTooltip === 'hovered') {
      setShowTooltip('closed')
    }
  }, [showTooltip, setShowTooltip])

  return (
    <>
      <Hoverable onHoverIn={() => setShowTooltip('hovered')} onHoverOut={toolTipHoverOut}>
        <TouchableOpacity
          ref={sourceRef}
          onPress={() => setShowTooltip('clicked')}
          hitSlop={hitSlop}
          style={style.tooltipBtn}
        >
          <Icon iconSet="AntDesign" name="questioncircleo" size={size} color={Colors.shades[300]} {...iconProps} />
        </TouchableOpacity>
      </Hoverable>
      <MenuOverlay
        value="tooltip"
        sourceRef={sourceRef}
        onBackdropPressed={() => setShowTooltip('closed')}
        isVisible={showTooltip !== 'closed'}
      >
        <View style={style.tooltipWrapper}>{children}</View>
      </MenuOverlay>
    </>
  )
}

const style = StyleSheet.create({
  tooltipWrapper: {
    maxWidth: 300,
    margin: 10,
  },
  tooltipBtn: { paddingRight: 5, paddingVertical: 5 },
})
