import { Spinner, Text, TextH1, TextH2 } from '@elements'
import { openUrl } from '@helpers/client'
import { ScrollView, StyleProp, TextStyle, TouchableOpacity, View } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import Colors from './../../../constants/Colors'

const formatSnakeCase = (str: string) => {
  return str.replace('_', ' ').trim()
}

const convertDataToCsv = (data: any[]) => {
  const headers =
    Object.keys(data[0])
      .map((x) => formatSnakeCase(x))
      .join(',') + '\n'
  const csv = data
    .map((row) => {
      return Object.keys(row)
        .map((key) => {
          return row[key]
        })
        .join(',')
    })
    .join('\n')
  return headers + csv
}

function Table<T extends object>({
  title,
  values,
  styles,
  customHeaderStyles,
  seeAll,
  hidden,
  showDate = false,
  customComponent,
  customHeaders,
  showHeaders = false,
  exportCsv = false,
  isLoading = false,
}: {
  title: string
  values: T[]
  styles?: Partial<Record<keyof T, TextStyle>>
  /** This customHeaderStyles will be applied when you pass customerHeaders and showHeaders */
  customHeaderStyles?: Partial<Record<string, TextStyle>>
  seeAll?: () => void
  hidden?: (keyof T)[]
  showDate?: boolean
  customComponent?: Partial<Record<keyof T, (data: T) => JSX.Element>>
  showHeaders?: boolean
  customHeaders?: string[]
  exportCsv?: boolean
  isLoading?: boolean
}) {
  const tableStyles = useStyles()
  return (
    <View style={tableStyles.wrapper}>
      <View style={tableStyles.buttonWrapper}>
        <TextH1 style={tableStyles.titleStyle}>
          {title} {isLoading && <Spinner size={20} />}
        </TextH1>
        <TouchableOpacity style={{ marginLeft: 'auto', marginRight: 20 }} onPress={seeAll}>
          {seeAll && <TextH2 style={tableStyles.seeAllLink}>See All</TextH2>}
          {exportCsv && (
            <Text
              style={tableStyles.exportLink}
              onPress={() => {
                const csv = convertDataToCsv(values)
                openUrl(`data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`)
              }}
            >
              Export to CSV
            </Text>
          )}
        </TouchableOpacity>
      </View>
      <ScrollView contentContainerStyle={tableStyles.scrollContainer}>
        {values &&
          !!values.length &&
          showHeaders &&
          (customHeaders ? (
            <Headers headers={customHeaders} headerStyles={customHeaderStyles} />
          ) : (
            <Headers headers={Object.keys(values[0])} headerStyles={styles} hidden={hidden} />
          ))}
        <ScrollView>
          {showDate && (
            <Text>
              {new Date().toLocaleDateString('en-us', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </Text>
          )}
          {values.map((v, idx) => {
            const data = Object.keys(v).filter((k) => (hidden ? !hidden.includes(k as keyof T) : true))
            return (
              <View key={idx} style={tableStyles.valuesWrapper}>
                {data.map((k, idx) => {
                  const property = k as keyof T
                  const custom = customComponent ? customComponent[property] : null
                  let style: StyleProp<TextStyle> = {
                    width: `${(1 / data.length) * 100}%`,
                  }
                  if (styles && styles.hasOwnProperty(property)) style = { ...style, ...styles[property] }
                  return custom ? (
                    <View key={idx} style={style}>
                      {custom(v)}
                    </View>
                  ) : (
                    <Text key={idx} style={style}>
                      {v[property] as any}
                    </Text>
                  )
                })}
              </View>
            )
          })}
        </ScrollView>
      </ScrollView>
    </View>
  )
}

function Headers({
  headers,
  headerStyles,
  hidden,
}: {
  headers: string[]
  headerStyles?: Record<string, TextStyle | undefined>
  hidden?: any[]
}) {
  const tableStyles = useStyles()
  return (
    <View style={tableStyles.headerWrapper}>
      {headers.map((h) => {
        if (hidden && hidden.includes(h)) return null
        let style: StyleProp<TextStyle> = {
          width: `${Math.round((1 / headers.length) * 100)}%`,
          marginVertical: 10,
        }
        if (headerStyles && headerStyles.hasOwnProperty(h)) style = { ...style, ...headerStyles[h] }

        return (
          <Text size={14} type="medium" key={h} style={style}>
            {formatSnakeCase(h)}
          </Text>
        )
      })}
    </View>
  )
}

export default Table

const useStyles = CreateResponsiveStyle(
  {
    wrapper: {
      borderColor: '#E7E7E7',
      borderWidth: 1.5,
      borderRadius: 10,
      padding: 15,
      margin: '2%',
      flexGrow: 1,
    },
    headerWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    titleStyle: {
      fontSize: 22,
      alignItems: 'center',
      display: 'flex',
    },
    valuesWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: 50,
    },
    scrollContainer: {
      flexDirection: 'column',
      paddingHorizontal: 20,
    },
    seeAllLink: {
      color: Colors.green,
      textDecorationLine: 'underline',
    },
    exportLink: {
      borderRadius: 10,
      padding: 10,
      backgroundColor: Colors.green,
      color: Colors.white,
    },
    buttonWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: 20,
      marginTop: 10,
      marginBottom: 10,
    },
  },
  {
    [maxSize(DEVICE_SIZES.MD)]: {
      scrollContainer: {
        paddingHorizontal: 10,
      },
    },
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      scrollContainer: {
        paddingHorizontal: 5,
      },
      titleStyle: {
        fontSize: 16,
      },
    },
  },
)
