import { Button, Text, TextH1 } from '@elements'
import { getOrderNum } from '@helpers/display'
import { PickupItemStatus } from '@models/Order'
import React, { memo } from 'react'
import { StyleSheet, View } from 'react-native'

import { getReceivedLabelByLocation, GroupedItemType, isDistroSummaryItemActive } from '../helpers'

import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { ArrElement } from '@helpers/typescript'
import { useSignInSheetContext } from '../useSignInSheetData'
import { SwipeableSignInItem } from './SwipeableSignInItem'

type Props = {
  /** Data related to a specific order, including DistributionSummaryItems  */
  orderData: ArrElement<GroupedItemType['orders']>
  /** Whether the component should display extra information about the order, or just its items */
  showOrderInfo?: boolean
}

/** Component that renders all the DistributionSummaryItems for a specific order, and each item is individually swipeable */
export const SignInSummaryOrder = memo(function SignInSummaryOrder({ orderData, showOrderInfo = false }: Props) {
  const { onUpdateOrderItemsStatus, onUpdateStatusByItem } = useSignInSheetContext()
  if (!showOrderInfo) {
    return orderData.items.map((item) => (
      <SwipeableSignInItem onUpdate={onUpdateStatusByItem} key={item.pickupItem.id} summaryItem={item} />
    ))
  }

  return (
    <View style={styles.card}>
      <View style={globalStyles.margin20}>
        <TextH1 size={18}>Order {getOrderNum(orderData.orderInfo.orderNum)}</TextH1>
        <View style={styles.marginV5} />
        <Button
          style={styles.marginHoriz0}
          //Disable button if no active items
          disabled={!orderData.items.some(isDistroSummaryItemActive)}
          onPress={() => onUpdateOrderItemsStatus(orderData.orderInfo.id, PickupItemStatus.Received)}
          title={`Mark all as ${getReceivedLabelByLocation(orderData.items[0].location).toLowerCase()}`}
        />
        <View style={styles.marginV5} />
      </View>
      <OrderItemRowHeader />
      {orderData.items.map((item) => (
        <SwipeableSignInItem onUpdate={onUpdateStatusByItem} key={item.pickupItem.id} summaryItem={item} />
      ))}
    </View>
  )
})
/** Header for the order items */
export function OrderItemRowHeader() {
  return (
    <View style={styles.orderItemRowHeader}>
      <Text>Product name</Text>
      <Text>QTY</Text>
      <View />
      <View />
    </View>
  )
}

const styles = StyleSheet.create({
  card: {
    borderRadius: 8,
    borderColor: Colors.shades[100],
    borderWidth: 1,
    width: '100%',
    marginVertical: 10,
    overflow: 'hidden',
  },

  marginV5: {
    marginVertical: 5,
  },

  orderItemRowHeader: {
    padding: 10,
    borderTopWidth: 1,
    borderColor: Colors.shades['100'],
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.shades['100'],
  },

  marginHoriz0: {
    marginHorizontal: 0,
  },
})
