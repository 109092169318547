import { Notification, FormattedNotification } from '@models/Notification'
import Handlebars from 'handlebars/dist/handlebars'

// formatNotification returns a FormattedNotification object that has its property Handlebar placeholders interpolated
// with the Notification data.
export function formatNotification({
  title,
  body,
  data,
}: Pick<Notification, 'title' | 'body' | 'data'>): FormattedNotification {
  return {
    title: interpolateContent(title, data),
    body: interpolateContent(body, data),
  }
}

// interpolateContent fills in the placeholders found in the supplied message with the given data.
function interpolateContent<T>(message: string, data?: T): string {
  if (!data) {
    return message
  }

  const template = Handlebars.compile(message)
  return template(data)
}
