import { FormButton, Modal, Text } from '@elements'
import { User } from '@models/User'
import { TermsAndConditions } from '@screens/UserScreen/TermsAndConditions'
import { Formik, FormikProps } from 'formik'
import { StyleSheet, View } from 'react-native'
import { Input } from 'react-native-elements'
import * as Yup from 'yup'

type FormType = {
  firstName: string
  lastName: string
  email: string
}

const codeValidationSchema = Yup.object<FormType>().shape({
  firstName: Yup.string().required('This field is required'),
  lastName: Yup.string().required('This field is required'),
  email: Yup.string().label('Email').email('Enter a valid email').required('Please enter a valid email'),
})

type Props = {
  onSubmit(user: Pick<User, 'email' | 'name'>): void
  loading: boolean
}

export default function PhoneRegister({ onSubmit, loading }: Props) {
  const initialValues: FormType = { email: '', firstName: '', lastName: '' }

  return (
    <View>
      <Formik
        initialValues={initialValues}
        onSubmit={({ email, firstName, lastName }) => onSubmit({ email, name: { firstName, lastName } })}
        validationSchema={codeValidationSchema}
      >
        {({ handleChange, values, errors, touched, handleSubmit, handleBlur }: FormikProps<FormType>) => (
          <>
            <Input
              numberOfLines={1}
              value={values.firstName}
              placeholder="First Name"
              onChangeText={handleChange('firstName')}
              textContentType="givenName"
              autoCapitalize="words"
              onBlur={handleBlur('firstName')}
              leftIcon={{ type: 'font-awesome', name: 'user' }}
              leftIconContainerStyle={styles.marginRight}
              errorMessage={touched.firstName ? errors.firstName : ''}
            />
            <Input
              numberOfLines={1}
              value={values.lastName}
              placeholder="Last Name"
              onChangeText={handleChange('lastName')}
              textContentType="familyName"
              autoCapitalize="words"
              onBlur={handleBlur('lastName')}
              leftIcon={{ type: 'font-awesome', name: 'user' }}
              leftIconContainerStyle={styles.marginRight}
              errorMessage={touched.lastName ? errors.lastName : ''}
            />
            <Input
              numberOfLines={1}
              value={values.email}
              placeholder="Email"
              onChangeText={handleChange('email')}
              autoCapitalize="none"
              autoComplete="email"
              keyboardType="email-address"
              onBlur={handleBlur('email')}
              leftIcon={{ type: 'font-awesome', name: 'envelope' }}
              leftIconContainerStyle={styles.marginRight}
              errorMessage={touched.email ? errors.email : ''}
            />

            <Text style={styles.marginHorizontal}>
              By signing up, you agree to GrownBy's{' '}
              <Text
                size={13}
                type="bold"
                onPress={() => Modal(<TermsAndConditions />, { title: 'Terms and Conditions' })}
              >
                terms and conditions.
              </Text>
            </Text>
            <FormButton loading={loading} title="Register" onPress={handleSubmit} style={styles.margin8} />
          </>
        )}
      </Formik>
    </View>
  )
}

const styles = StyleSheet.create({
  margin8: {
    margin: 8,
  },
  marginRight: {
    marginRight: 8,
  },
  marginHorizontal: {
    marginHorizontal: 10,
  },
})
