import { useFocusFx } from '@/hooks/useFocusFx'
import useKeyedState from '@/hooks/useKeyedState'
import { Button, FormMoneyInput, ListItemAccordion, ListItemAccordionProps, RadioButton } from '@elements'
import { MoneyCalc } from '@helpers/money'
import { memo, useCallback } from 'react'
import { StyleSheet, View } from 'react-native'

export type RangeItem = {
  label: string
  value: {
    min?: number
    max?: number
  }
  isRefined?: boolean
}

export type RangeFilterProps = Pick<ListItemAccordionProps, 'isExpanded' | 'onToggle' | 'title'> & {
  items: RangeItem[]
  onUpdate: (range: RangeItem['value']) => void
  selectedValue?: RangeItem['value']
}

/**
 * Expandable section that includes pre-defined numeric range options and custom inputs for user-defined range
 * */
export const RangeFilter = memo(function RangeFilter({
  isExpanded,
  onToggle,
  items,
  selectedValue,
  onUpdate,
  title,
}: RangeFilterProps) {
  const [{ min, max }, set, setState] = useKeyedState<RangeItem['value']>({
    min: undefined,
    max: undefined,
  })

  /** Effect that sets / resets the custom range input values
   * - Setting the values can happen on page refresh
   * - Resetting can happen when an pre-defined option is selected / refined
   */
  useFocusFx(
    () => {
      if (items.every((el) => !el.isRefined) && selectedValue) {
        setState(selectedValue)
      } else {
        setState({ max: undefined, min: undefined })
      }
    },
    [items, selectedValue, setState],
    { noRefocus: true },
  )

  const onRangeOptPress = useCallback(
    (idx: number) => {
      onUpdate(items[idx].value)
    },
    [items, onUpdate],
  )

  const onCustomFilterPress = useCallback(() => {
    onUpdate({ max, min })
  }, [min, max, onUpdate])

  return (
    <ListItemAccordion isExpanded={isExpanded} onToggle={onToggle} title={title}>
      <View style={styles.itemsWrapper}>
        {items.map((opt, idx) => {
          return (
            <RadioButton
              key={idx}
              label={opt.label}
              isSelected={!!opt.isRefined}
              onPress={() => onRangeOptPress(idx)}
            />
          )
        })}

        <View style={styles.inputWrapper}>
          <FormMoneyInput
            onChangeText={(price) => set('min', price?.value)}
            value={min ? MoneyCalc.fromCents(min) : undefined}
            label="From"
            containerStyle={styles.inputCont}
            inputContainerStyle={styles.input}
          />
          <FormMoneyInput
            onChangeText={(price) => set('max', price?.value)}
            value={max ? MoneyCalc.fromCents(max) : undefined}
            label="To"
            containerStyle={styles.inputCont}
            inputContainerStyle={styles.input}
          />
          <Button style={styles.btn} title="Go" disabled={!min && !max} outline small onPress={onCustomFilterPress} />
        </View>
      </View>
    </ListItemAccordion>
  )
})

export const styles = StyleSheet.create({
  itemsWrapper: {
    gap: 8,
  },
  inputCont: {
    width: undefined,
    paddingHorizontal: 0,
  },
  input: {
    width: 70,
  },
  btn: {
    height: 41.5,
    flex: 1,
    marginBottom: 10,
    alignSelf: 'flex-end',
  },
  inputWrapper: {
    flexDirection: 'row',
    gap: 10,
    marginTop: 10,
  },
})
