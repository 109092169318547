import { hasOwnProperty } from '@helpers/helpers'
import { AuthErrorCodes } from 'firebase/auth'

/** The authentication errors code array */
const reLoginNeededCodes: string[] = [
  // can not find the error code for this, but this should be included
  'auth/id-token-expired',
  AuthErrorCodes.INTERNAL_ERROR,
  AuthErrorCodes.INVALID_APP_CREDENTIAL,
  AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN,
  AuthErrorCodes.TOKEN_EXPIRED, //user token expired
]

/** Whether it should alert the user to re-login */
export const shouldShowReLoginAlert = (err: unknown) => {
  if (typeof err === 'string') {
    return reLoginNeededCodes.includes(err)
  } else if (hasOwnProperty(err, 'code') && typeof err.code === 'string') {
    return reLoginNeededCodes.includes(err.code)
  }
  return false
}
