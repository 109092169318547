import { LocalPickup } from '@models/Location'
import { Product } from '@models/Product'
import { formatAddress } from './display'
import { hasOwnProperty } from './helpers'

/** Codes for cart item validation rejection */
export enum ItemRejectReason {
  Deleted = 'deleted',
  OutofStock = 'out_of_stock',
  /** The product stock can't fulfill the quantity in cart */
  Insufficient = 'insufficient',
  /** buying option no longer exists */
  UnitDeleted = 'unit_deleted',
  /** Unavailable means the product has no pickups left */
  Unavailable = 'unavailable',
  /** Error produced by cart item creator validation */
  CartItemError = 'cart_item_error',
  /** IncompatibleLocation will be used when a nonpickup address is no longer compatible with the current distro location region */
  IncompatibleLocation = 'incompatible_location',
  /** When there was a significant change in the schedule or location */
  ScheduleChanged = 'schedule_changed',
  /** LocationNotFound will be used when either the schedule was no longer part of the product, or its location is no longer of the same type */
  LocationNotFound = 'location_notfound',
  /** AddressChanged will be used if a localpickup location has a different address than the one when the item was added to the cart */
  AddressChanged = 'address_changed',
  /** When the selected price of the cart item is changed on the product */
  PriceChanged = 'price_changed',
  /** When the product has a set minimum number of pickups and the cart item does not satisfy the minimum */
  InsufficientPickups = 'insufficient_pickups',
  /** When the item selections such as schedule, buying option and price are incompatible with the current app catalog mode */
  IncompatibleOptionsForCatalog = 'incompatible_options_for_catalog',
}

/** Helps present a display string for a given invalid item scenario */
export const ItemRejectMessages: Record<ItemRejectReason, (details: InvalidItemDetails) => string> = {
  deleted: () => 'The product is no longer being sold',
  out_of_stock: () => 'The product is out of stock',
  insufficient: () => 'The quantity in the cart exceeds the most recent quantity in stock',
  unit_deleted: () => 'The buying option selected is no longer available',
  unavailable: () => 'The product is no longer available',
  cart_item_error: () => 'Something went wrong while validating this cart item',
  incompatible_location: () => 'The address for this product is no longer compatible with the current available region',
  schedule_changed: () => 'The schedule configuration for this product changed significantly',
  location_notfound: () => 'The product is no longer being offered at the location you selected',
  address_changed: (details) =>
    `The location for this item has changed since the time it was added to the cart.${
      details.newAddress ? ` The new address is ${formatAddress(details.newAddress!)}` : ''
    }`,
  price_changed: () => 'The product price has changed',
  insufficient_pickups: (details) =>
    `The product requires a minimum number of pickups${details.minPickups ? ':' + details.minPickups : ''}`,
  incompatible_options_for_catalog: () =>
    'Some of the item options such as buying option, price or schedule are incompatible with the current price catalog',
}

/** A load error represents an issue while fetching a product for the cart*/
export const isLoadErr = (res: ProdFetchResult): res is { id: string; err: Error } =>
  hasOwnProperty(res, 'err') && hasOwnProperty(res, 'id')

/** This type holds either the product or a load error */
export type ProdFetchResult = Product | { id: string; err: Error }

export type InvalidItemDetails = {
  /** The id of an invalid cart item  */
  id: string
  /** If the invalid reason is "insufficient", this should be the most recent remaining quantity available for this item. If item has units, should be the remaining quantity for the selected buying option & price */
  quantity?: number
  /** The reason why the item is invalid */
  reason: ItemRejectReason
  /** If the reject reason was AddressChanged, the 'newAddress' field will have the most recent address. */
  newAddress?: LocalPickup['address']
  /** The number of minimum pickups expected */
  minPickups?: number
}
