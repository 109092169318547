// Money represents a transactional value of currency.
import { hasOwnProperty, isObject } from '@helpers/helpers'
import { MoneyCalc } from '@helpers/money'

export type Money = {
  // The decimal amount of money.
  value: number

  // The currency of the money. At this stage only dollar amounts in USD are supported.
  currency: Currency
}

// Available currencies.
export type Currency = 'usd' | 'cad'

// Zero identifies a zero value for the money type.
export const Zero = MoneyCalc.fromCents(0)
// Infinite identifies an infinite amount of money.
export const Infinite = MoneyCalc.fromCents(Infinity)

// Refactor to use MoneyCalc?
// makeMoney returns a Money structure.
export const makeMoney = MoneyCalc.fromCents
// addMoney returns the sum of a and b. It will throw an error if the currencies are incompatible.
export const addMoney = MoneyCalc.add
// moneySubtract returns lhs less rhs.
export const moneySubtract = MoneyCalc.subtract
// multiplyMoney multiplies the amount by b. b must be an integer.
export const multiplyMoney = MoneyCalc.multiply
// isZero returns true if the amount is zero.
export const moneyIsZero = MoneyCalc.isZero
// moneyIsEqual returns true if lhs equals rhs.
export const moneyIsEqual = MoneyCalc.isEqual
// moneyIsGreaterThan returns true if lhs is greater than rhs.
export const moneyIsGreaterThan = MoneyCalc.isGreaterThan
// moneyMax returns the amount of the two supplied arguments that is the greater amount.
export const moneyMax = MoneyCalc.max
// moneyMin returns the amount of the two supplied arguments that is the lesser amount.
export const moneyMin = MoneyCalc.min
// cents returns the value of the money in cents.
export const cents = MoneyCalc.cents

/** Identifies a value as a Money object */
export function isMoney(money: any): money is Money {
  return isObject(money) && hasOwnProperty(money, 'value') && hasOwnProperty(money, 'currency')
}
