import * as React from 'react'
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { StyleProp, TouchableOpacity, TouchableOpacityProps, View, ViewStyle } from 'react-native'

import { AutoCompleteItem, AutoCompleteProps, useAutoComplete } from '@/hooks/useAutoComplete'
import { useDeviceSize } from '@/hooks/useLayout'
import { BottomSheetList, BottomSheetListProps } from './BottomSheet/BottomSheetList'

export type DropdownMenuBtn<T = object> = { title: string; onPress: (onPressArgData: T) => any }

export type DropdownMenuProps<T> = {
  children: React.ReactNode | React.ReactNode[]
  /** Array of title and onPress, for dropdown options */
  buttons: DropdownMenuBtn<T>[]
  /** Argument being passed to the onPress of buttons in onSelect */
  data?: T
  /** style for the outer view */
  containerStyle?: ViewStyle
  autoSelectKey?: string
  /** pass otherProps for autocomplete */
  otherProps?: AutoCompleteProps<any>['otherProps']
  /** Callback that will be called with the relevant state inside this component whenever it changes */
  onChangeState?: (state: AutoCompleteProps<any> & { isVisibleSheet: boolean }) => void
  /** props for the touchable that opens the dialog */
  touchableProps?: Omit<TouchableOpacityProps, 'onPress'>
  /** style for the view that wraps the children */
  contentViewStyle?: StyleProp<ViewStyle>
  /** props to pass to the bottom sheet modal */
  bottomSheetProps?: Omit<
    BottomSheetListProps<DropdownMenuBtn<T>>,
    'isVisible' | 'items' | 'onBackdropPress' | 'getTitle' | 'onItemPress'
  >
}

/** Renders a touchable element pre configured to open an autocomplete list displaying the data provided */
export const DropdownMenu = React.memo(function DropdownMenu<T extends object>({
  children,
  buttons,
  data: onPressArgData,
  containerStyle,
  autoSelectKey = 'dropdownMenu',
  otherProps,
  onChangeState,
  touchableProps,
  contentViewStyle,
  bottomSheetProps,
}: DropdownMenuProps<T>) {
  const { isSmallDevice } = useDeviceSize()
  /**If true, will use bottomsheet. Else will use autocomplete */
  const shouldUseBottomSheet = isSmallDevice
  const [isVisibleSheet, setIsVisibleSheet] = useState(false)
  const ref = useRef<View>(null)
  const { autoCompleteOverlay, showAutocomplete, state: acProps } = useAutoComplete()

  useEffect(() => {
    onChangeState?.({ ...acProps, isVisibleSheet })
  }, [onChangeState, acProps, isVisibleSheet])

  const onSelect = useCallback(
    (item: AutoCompleteItem<DropdownMenuBtn<T>['onPress']>) => {
      if (!item.data) return
      item.data(onPressArgData as T)
    },
    [onPressArgData],
  )

  const openMenu = useCallback(
    () =>
      shouldUseBottomSheet
        ? setIsVisibleSheet(true)
        : showAutocomplete(
            autoSelectKey,
            ref,
            buttons.map((btn) => ({ text: btn.title, data: btn.onPress })),
            onSelect,
            otherProps,
          ),
    [autoSelectKey, buttons, showAutocomplete, onSelect, otherProps, shouldUseBottomSheet],
  )

  return (
    <>
      <View style={containerStyle}>
        <TouchableOpacity onPress={openMenu} {...touchableProps}>
          <View ref={ref} collapsable={false} style={contentViewStyle}>
            {children}
          </View>
        </TouchableOpacity>
      </View>
      {shouldUseBottomSheet ? (
        <BottomSheetList
          isVisible={isVisibleSheet}
          items={buttons}
          onBackdropPress={() => setIsVisibleSheet((p) => !p)}
          getTitle={(button) => button.title ?? ''}
          onItemPress={(button) => {
            setIsVisibleSheet(false)
            onSelect({ text: button.title, data: button.onPress })
          }}
          {...bottomSheetProps}
        />
      ) : (
        autoCompleteOverlay
      )}
    </>
  )
}) as <T>(props: DropdownMenuProps<T>) => ReactElement<any, any> | null
