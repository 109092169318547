import React from 'react'
import { Alert as MobileAlert, AlertButton } from 'react-native'

import { ModalComponentProps } from '../../../constants/types/modalTypes'
import { AlertComponentWeb } from './AlertComponentWeb'
import LoaderFullscreen from './LoaderFullscreen'
import { HalfModal } from './Modal/HalfModal'
import { ModalComponent } from './Modal/ModalComponent'
import { getPortalHelpers } from './Portal/Portal'
import { ToastComponent } from './Toast/ToastComponent'

import { isMobile, isWeb } from '@/constants/Layout'

/** This is the name of the the portal layer that hosts the modal component. It can be referenced to control the modal host portal from various places using the portal helpers */
export const MODAL_HOST = 'MODAL_HOST'

/** Shows an alert, with custom behavior in web and mobile, using the global portal.
 * - In web it uses a custom alert component. In mobile, it uses the default alert api
 */
export function Alert(
  title: string,
  /** The message to display in the alert*/
  message?: string,
  buttons?: AlertButton[],
  modalOpts?: Omit<ModalComponentProps, 'visible' | 'children' | 'dismissable' | 'onDismiss'>,
): void {
  const { addPortal, removePortal } = getPortalHelpers()
  const hasButtons = buttons && buttons.length > 0
  if (isWeb) {
    const modalEl = getModalAdapter(
      <AlertComponentWeb key="alertComponent" message={message} buttons={hasButtons ? buttons : [{ text: 'OK' }]} />,
      {
        /** header must be true because we're only showing title in modal component, and the title is inside the header of the modalcomponent */
        header: true,
        title,
        /** 'dismissable' will always be set to false, so we can keep it consistent, because iOS provides no control over this */
        dismissable: false,
        /** This onDismiss is set to undefined because we do not allow alerts to be dismissed without pressing an alert
         *  button so this will never be triggered */
        onDismiss: undefined,
        ...modalOpts,
      },
    )

    addPortal(MODAL_HOST, modalEl)
  } else {
    // On web the Alert is shown using the modal portal meaning it hides an existing modal, however on mobile it uses
    // the native alert which does not hide the modal, so we need to hide the modal manually to keep the behavior consistent
    removePortal(MODAL_HOST)
    MobileAlert.alert(title, message, buttons, {
      /** 'cancelable' will always be set to false, so we can keep it consistent, because iOS provides no control over this */
      cancelable: false,
      /** This onDismiss is set to undefined because we do not allow alerts to be dismissed without pressing an alert
       *  button so this will never be triggered */
      onDismiss: undefined,
    })
  }
}

/** Wraps the modal content inside the correct modal wrapper and returns it ready to be rendered as a jsx.element */
const getModalAdapter = (modalContent: JSX.Element, modalOpts?: Omit<ModalComponentProps, 'children' | 'visible'>) => {
  let modalAdapter = <></>
  if (modalOpts?.halfModal && isMobile)
    modalAdapter = (
      <HalfModal key="halfModal" visible header={false} {...modalOpts}>
        {modalContent}
      </HalfModal>
    )
  else
    modalAdapter = (
      <ModalComponent key="modalComponent" visible {...modalOpts}>
        {modalContent}
      </ModalComponent>
    )
  return modalAdapter
}

/** Shows an element above the current screen by wrapping the content inside a modal adapter component and passing it through the global portal */
export function Modal(modalContent: JSX.Element, modalOpts?: Omit<ModalComponentProps, 'visible' | 'children'>): void {
  const { addPortal } = getPortalHelpers()
  addPortal(MODAL_HOST, getModalAdapter(modalContent, modalOpts))
}

/** Hides the modal by removing the modal portal host*/
export const hideModal = () => {
  const { removePortal } = getPortalHelpers()
  removePortal(MODAL_HOST)
}

/** Helper that shows the gloal toast dialog with the specified text, using the toast portal host */
export function Toast(msg: string): void {
  const { addPortal } = getPortalHelpers()
  addPortal('toastHost', <ToastComponent msg={msg} key="toast" />)
}

/** Helper that toggles a full-screen transparent loader which blocks user action, using the loader portal host.
 * @param visible true will show, false will hide */
export function Loader(visible: boolean) {
  const { addPortal, removePortal } = getPortalHelpers()
  if (visible) addPortal('loaderHost', <LoaderFullscreen key="loader" />)
  else removePortal('loaderHost')
}
