import env from '../config/Environment'

// Load certifications returns a local list of certifications
export async function loadCertifications(): Promise<any[]> {
  // Get the bucket to load certifications from
  const bucket = env.APP_ENV === 'prod' ? 'grownby-certifications' : `grownby-${env.APP_ENV}-certifications`
  return [
    {
      badge: `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/organic.jpg?alt=media&token=6a2aab14-c97b-465f-ae3d-f689f2933eab`,
      id: 'organic',
      title: 'USDA Organic',
    },
    {
      badge: `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/animal-welfare.jpg?alt=media&token=1f9d0c06-3487-456b-88fe-1ddde13e498d`,
      id: 'animal-welfare',
      title: 'Animal Welfare Approved',
    },
    {
      badge: `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/naturally-grown150.jpg?alt=media&token=e529828c-b8ae-4066-89e7-846e6b430a7d`,
      id: 'naturally-grown',
      title: 'Certified Naturally Grown',
    },
    {
      badge: `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/Demeter-Green-150.jpg?alt=media&token=932d3798-fa2a-466c-9154-c15b4fe61cc5`,
      id: 'demeter',
      title: 'Demeter Certified',
    },
    {
      badge: `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/grassfed.jpg?alt=media&token=8981ff71-abbc-45c2-b0ed-b675eafb86ce`,
      id: 'grassfed',
      title: 'American Grassfed',
    },
    {
      badge: `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/real-organic.png?alt=media&token=0d0658df-05d8-47c8-b7f1-3aef971fd68d`,
      id: 'real-organic',
      title: 'Real Organic',
    },
  ]
}
