import { getProductAvailability } from '@helpers/products'
import { formatDateRange } from '@helpers/time'
import { Product, isPhysical } from '@models/Product'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { wholesaleSelector } from '../../../redux/selectors'
import { DetailItem } from './DetailItem'

export const AvailabilitySection = memo(function AvailabilitySection({ product }: { product: Product }) {
  const { isWholesale } = useSelector(wholesaleSelector)

  if (!isPhysical(product) || isWholesale === undefined) return null

  const avail = getProductAvailability(product, undefined, {
    excludeClosedDistros: true,
    isWholesale,
  })
  if (!avail) return null

  return <DetailItem name="Available">{formatDateRange(avail, 'MMM dd, yyyy')}</DetailItem>
})
