import { TextH1, hideModal } from '@elements'
import { FontAwesome5 } from '@expo/vector-icons'
import { useCallback } from 'react'
import { KeyboardAvoidingView, Platform, Pressable, StyleSheet, TouchableOpacity, View } from 'react-native'

import Colors from '../../../../constants/Colors'
import { ModalComponentProps } from '../../../../constants/types/modalTypes'
import { ModalComponent } from './ModalComponent'

import { globalStyles } from '@/constants/Styles'

/** A customized wrapper and alternative to the the main modal component which renders a
 * component as a modal that takes up the bottom half of the screen; It is optimized for mobile.
 *
 * WARNING: No child should use [KeyboardAvoidingView] or any of its derived, as it will
 *  overlap with this KeyboardAvoidingView, and will produce unexpected behaviours
 */
export function HalfModal({
  title,
  visible,
  children,
  noPadding,
  onDismiss: onDismissProp,
  ...otherProps
}: ModalComponentProps) {
  const onDismiss = useCallback(() => {
    onDismissProp?.()
    hideModal()
  }, [onDismissProp])
  return (
    <ModalComponent
      transparent // "transparent" is only for the half modal, so the area above the bottom-anchored modal can be transparent. The regular modal component usage is not adapted to use this. The entire modal background becomes transparent which is odd
      visible={visible}
      animationType="fade"
      presentationStyle="overFullScreen"
      {...otherProps}
    >
      <KeyboardAvoidingView style={globalStyles.flex1} behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
        <View style={styles.container}>
          {/*Top half area which will be independently pressable*/}
          <Pressable style={globalStyles.flex1} onPress={onDismiss} />
          <View style={[styles.wrapper, noPadding && styles.padding0]}>
            <View style={[styles.header, !noPadding && styles.padding0]}>
              <TextH1>{title ? title : ''}</TextH1>
              <TouchableOpacity onPress={onDismiss}>
                <FontAwesome5 name="times" size={25} />
              </TouchableOpacity>
            </View>
            {children}
          </View>
        </View>
      </KeyboardAvoidingView>
    </ModalComponent>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.semiTransparent,
    justifyContent: 'flex-end',
  },

  wrapper: {
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    backgroundColor: Colors.white,
    padding: 20,
  },

  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15,
    padding: 20,
    paddingBottom: 0,
  },
  padding0: {
    padding: 0,
  },
})
