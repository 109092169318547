import { getShortState, ShortState } from '@/assets/data/states'
import { YUP_MONEY_REQUIRED, YUP_WHOLE_NUMBER_REAL } from '@helpers/Yup'
import { ShortZip } from '@models/Address'
import { Farm } from '@models/Farm'

import { RegionType } from '@models/Location'
import { Money } from '@models/Money'
import { FeeType, FeeValueType, NoneValue, ProductFee } from '@models/ProductFee'
import * as yup from 'yup'
import { nonEmptyString } from '../helpers'
import { Builder } from './Builder'
import { isYupValidationError, validateFromSchema, YupValidationError } from './validators/helpers'
import { ShortStateSchema, ShortZipSchema } from './validators/sharedValidation'

export const productFeeSchema: yup.ObjectSchema<ProductFee> = yup.object().shape({
  id: yup.string().required(),
  name: yup.string().trim('Fee name cannot contain spaces at the beginning or end').required(),
  farm: yup.object<Pick<Farm, 'id'>>().shape({ id: yup.string().required() }).required(),
  type: yup.mixed<FeeType>().required().oneOf(Object.values(FeeType)),
  valueType: yup.mixed<FeeValueType>().required().oneOf(Object.values(FeeValueType)),
  value: yup
    .mixed<number | Money>()
    .required()
    .when('type', ([type]) => {
      if (type === FeeType.Tax)
        return YUP_WHOLE_NUMBER_REAL('Value', { allowDecimal: true }).max(1, 'Cannot be greater than 1')
      else if (type === FeeType.Fee) return YUP_MONEY_REQUIRED('Value')
      throw new Error('Invalid fee type')
    }),
  regionType: yup
    .mixed<ProductFee['regionType']>()
    .required()
    .oneOf([...Object.values(RegionType), NoneValue]),
  regions: yup
    .array<ShortState[] | ShortZip[]>()
    .required()
    .when('regionType', {
      is: (regionType: RegionType) => regionType === RegionType.State,
      then: (schema) =>
        schema.test('Valid state', 'State must be a valid state code', (v?: ShortState[]) => {
          if (!Array.isArray(v)) {
            return false
          }
          // Check each element in the array
          return (
            v.length === 0 ||
            v.every(
              (s) => nonEmptyString(s) && !!getShortState(s) && ShortStateSchema.validateSync(s, { strict: true }),
            )
          )
        }),
      otherwise: (schema) =>
        schema.when('regionType', {
          is: (regionType: RegionType) => regionType === RegionType.Zipcode,
          then: (schema) =>
            schema.test('5 digits', 'Zip code must be 5 digits', (v?: ShortZip[]) => {
              if (!Array.isArray(v)) {
                return false
              }
              // Check each element in the array
              return (
                v.length === 0 ||
                v.every(
                  (zip: string) => !!zip && zip.length === 5 && ShortZipSchema.validateSync(zip, { strict: true }),
                )
              )
            }),
          // Default validation schema when 'regionType' is not State or Zipcode
          otherwise: (schema) => schema.default([]),
        }),
    }),
  archived: yup.boolean().required(),
})

export class ProductFeeBuilder extends Builder<ProductFee> {
  constructor() {
    super('productFee')
  }

  /**
   * This function will validate a productFee object
   * @param productFee the productFee object to validate
   */
  validate(productFee: Partial<ProductFee>): ProductFee {
    try {
      return validateFromSchema(productFeeSchema, productFee)
    } catch (error) {
      if (isYupValidationError(error)) {
        throw new YupValidationError({ path: 'productFee.' + (error.data?.path ?? ''), msg: error.message })
      }
      throw error
    }
  }
}
