import { Platform } from 'react-native'

import WebView from './elements/WebView/WebView'

type Props = {
  uri: string
  height: number | string
  width: number | string
}
export function EmbeddedPage({ uri, width, height }: Props) {
  if (Platform.OS === 'web') {
    return <iframe frameBorder={0} height={height} width={width} src={uri} style={{ padding: 3 }} />
  } else {
    return <WebView source={{ uri }} />
  }
}
