import { hasOwnProperty } from '@helpers/helpers'
import { DateTime } from 'luxon'
import { Cart } from './Cart'
import { Farm } from './Farm'
import { Money } from './Money'
import { Order, SplitTenderPayment } from './Order'

export enum DraftOrderState {
  /** When a draft order is created it gets the status pending, meaning it has yet to be approved */
  Pending = 'pending',
  /** Once it has been approved by the farmer it is marked as approved, but remains a draft order */
  Approved = 'approved',
  /** If the draft order is declined by the farmer, it is not deleted until 90 days after declining */
  Declined = 'declined',
}

/** A draft order holds a reference to a cart that as been placed as an order by the user but not finalized by the farmer. When a DraftOrder is finalized, it is deleted from the collection and converted to a true order. */
export type DraftOrder = Pick<Order, 'date' | 'user'> & {
  /** The document identifier from Firestore */
  id: string
  state: DraftOrderState
  /** This helps make comparisons easy, all draft orders will have the flag true which allows us to do type narrowing safely */
  isDraft: true
  /** We create the order number here so that the draft can be linked to the finalized order by this number */
  orderNum: Order['orderNum']
  farm: Pick<Farm, 'id' | 'name'>
  cart: Pick<Cart, 'id'>
  /** The cart subtotal for the draft order. */
  subtotal: Money
  /** This specifies how the order will be paid once the order is finalized, we should allow only Infinite payments like ACH, Offline or CC. */
  tender: SplitTenderPayment
  /** This tracks the last edit date for this draft, it is useful to trigger certain events when the cart changes. */
  cartLastUpdate: DateTime
  /** Whether the order was placed in wholesale mode */
  isWholesale: boolean
  /** See Order model's note field. This field will be copied directly onto the order on finalization. */
  note?: string
  /** See Order model's purchaseOrder field. This field will be copied directly onto the order on finalization. */
  purchaseOrder?: string
  /** This field tracks when the draft order expires and should be finalized or declined automatically.
   * If the draft order is pending, it will be 24 hours after the order cutoff date for the first distribution.
   * If the draft order is approved, it will be set to the end of the distribution.
   * If the draft order is declined, it will be set to 90 days after declining. */
  expiration: DateTime
}

/** Number of days after a draft order is declined before it expires  */
export const DRAFT_ORDER_EXPIRATION_DAYS_AFTER_DECLINE = 90

/** Differentiates a draft order from a finalized order */
export function isDraftOrder(order: Order | DraftOrder): order is DraftOrder {
  return hasOwnProperty(order, 'isDraft') && order.isDraft === true
}
