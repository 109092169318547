import { StyleSheet, View } from 'react-native'

import { Text, TextH1 } from '../elements/Text'

export function ServiceUnavailable() {
  return (
    <View style={styles.container}>
      <TextH1 style={styles.headerText}>Under Maintenance</TextH1>
      <Text>GrownBy is currently down for maintenance. Please wait or check back soon.</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 20,
    alignSelf: 'center',
    alignItems: 'center',
    maxWidth: 500,
  },
  headerText: {
    textAlign: 'center',
    maxWidth: 400,
  },
})
