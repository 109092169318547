import { addUserAddress, updateUserAddress } from '@api/UserAddress'
import { CheckBox, ErrorText, FormButton, hideModal, Toast } from '@elements'
import { nonEmptyString } from '@helpers/helpers'
import { UserAddress } from '@models/UserAddress'
import { Formik, FormikProps } from 'formik'
import * as React from 'react'
import { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { Input } from 'react-native-elements'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { FormBuilder } from '../../components/FormBuilder'
import Colors from '../../constants/Colors'
import { userSelector } from '../../redux/selectors'

import { getShortState } from '@/assets/data/states'
import { Logger } from '@/config/logger'

type FormType = Omit<UserAddress, 'id' | 'coordinate' | 'state'> & { state: string }

export const contactValidation = Yup.object<FormType>().shape({
  street1: Yup.string().trim().label('Street Address 1').required(),
  city: Yup.string().trim().label('City').required(),
  state: Yup.string()
    .trim()
    .label('State')
    .test('validate-state', 'State is invalid', (value: any) => nonEmptyString(value) && !!getShortState(value))
    .required(),
  zipcode: Yup.string()
    .trim()
    .label('Zip code')
    .required()
    .min(5, 'Zip code must be 5 digits')
    .max(5, 'Zip code must be 5 digits'),
})

export default function AddAddress({
  address,
  onAdded,
  addresses,
}: {
  address?: UserAddress
  onAdded?: (address: UserAddress) => void
  /** The current user addresses*/ addresses?: UserAddress[]
}) {
  const [error, setError] = useState('')
  const userId = useSelector(userSelector).id

  const initialValues: FormType = address || {
    street1: '',
    city: '',
    state: '',
    zipcode: '',
    isDefault: !addresses?.find((itm) => itm.isDefault),
  }

  const onSubmitHandler = async (values: FormType) => {
    if (error) setError('')
    const state = getShortState(values.state)
    if (!state) return Toast('The address state is not valid')

    const newAddress: Omit<UserAddress, 'coordinate'> = {
      ...values,
      id: address?.id || '',
      isDefault: values.isDefault || !addresses?.find((itm) => itm.isDefault),
      state,
    }

    try {
      if (address?.id) {
        // updating an address
        const resAddress = await updateUserAddress(userId, newAddress)
        onAdded?.(resAddress)
      } else {
        // adding a new address
        const resAddress = await addUserAddress(userId, newAddress)
        onAdded?.(resAddress)
      }
      hideModal()
    } catch (e) {
      Logger.warn(e)
      setError('Address is invalid. Please double-check and try again')
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmitHandler} validationSchema={contactValidation}>
      {({
        handleChange,
        values,
        errors,
        touched,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }: FormikProps<FormType>) => (
        <KeyboardAwareScrollView contentContainerStyle={styles.container}>
          <FormBuilder>
            <Input
              onChangeText={handleChange('street1')}
              onBlur={handleBlur('street1')}
              value={values.street1}
              placeholder="Street Address 1"
              errorMessage={touched.street1 ? errors.street1 : ''}
            />
            <Input
              onChangeText={handleChange('street2')}
              onBlur={handleBlur('street2')}
              value={values.street2}
              placeholder="Street Address 2"
              errorMessage={touched.street2 ? errors.street2 : ''}
            />
            <FormBuilder row>
              <Input
                onChangeText={handleChange('city')}
                onBlur={handleBlur('city')}
                value={values.city}
                placeholder="City"
              />
              <Input
                onChangeText={handleChange('state')}
                onBlur={handleBlur('state')}
                value={values.state}
                placeholder="State"
              />
              <Input
                placeholder="Zip Code"
                value={values.zipcode}
                keyboardType="number-pad"
                maxLength={5}
                onChangeText={handleChange('zipcode')}
                onBlur={handleBlur('zipcode')}
              />
            </FormBuilder>
            {touched.city && errors.city ? <ErrorText>{errors.city}</ErrorText> : <></>}
            {touched.state && errors.state ? <ErrorText>{errors.state}</ErrorText> : <></>}
            {touched.zipcode && errors.zipcode ? <ErrorText>{errors.zipcode}</ErrorText> : <></>}
            <Input
              multiline
              numberOfLines={3}
              label="Delivery Instructions"
              value={values.notes}
              onChangeText={handleChange('notes')}
              onBlur={handleBlur('notes')}
              errorMessage={touched.notes ? errors.notes : ''}
            />
            <View style={{ margin: 15 }}>
              {/*Don't allow users to remove a default address without adding a different one */}
              {!address?.isDefault && (
                <CheckBox
                  checked={!!values.isDefault}
                  onChange={(val) => setFieldValue('isDefault', val)}
                  title="Set as Default"
                />
              )}
            </View>
          </FormBuilder>
          <FormButton loading={isSubmitting} title="Save Address" onPress={handleSubmit} />
          {!!error && <ErrorText>{error}</ErrorText>}
        </KeyboardAwareScrollView>
      )}
    </Formik>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 20,
  },
  maskedInput: {
    fontSize: 20,
    margin: 10,
    paddingBottom: 5,
    borderBottomWidth: 1,
    borderBottomColor: Colors.shades['300'],
  },
  error: { color: Colors.red, marginBottom: 5 },
})
