import { MediaCarousel } from '@components'
import { Divider, ReadMore, Text } from '@elements'
import { hyperlinkOpenUrl } from '@helpers/links'
import React, { memo, useMemo } from 'react'
import { View } from 'react-native'
import HyperLink from 'react-native-hyperlink'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import { FarmHeaderSkeleton } from '../FarmHeaderSkeleton'

import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { useLayout } from '@/hooks/useLayout'
import { CSA } from '@models/CSA'

type Props = {
  loading: boolean
  csa?: CSA
}

export const AboutCSA = memo(function AboutCSA({ loading, csa }: Props) {
  const { isSmallDevice, ...layout } = useLayout()
  const styles = useStyles()

  const carouselSize = useMemo(() => {
    // Do not allow the width of the carousel to be more than 600px
    const width = isSmallDevice ? layout.width - 30 : 600
    const height = width / 1.5 // 3/2 aspect ratio
    return { width, height }
  }, [isSmallDevice, layout.width])

  if (loading) {
    return <FarmHeaderSkeleton />
  }
  if (!csa) return null

  return (
    <View>
      <Text type="medium" style={styles.marginV20} size={20}>{`About ${csa.name}`}</Text>
      <Text type="medium">{csa.shortDescription}</Text>
      <Divider clear />
      <View style={styles.carouselWrapper}>
        <MediaCarousel height={carouselSize.height} width={carouselSize.width} media={csa.images} />

        <View style={[styles.csaDescriptionCont, !isSmallDevice && globalStyles.flex1]}>
          <Divider clear />
          <HyperLink linkStyle={{ color: Colors.blueLink }} onPress={hyperlinkOpenUrl}>
            {isSmallDevice ? <Text>{csa.description}</Text> : <ReadMore numLines={20}>{csa.description}</ReadMore>}
          </HyperLink>
        </View>
      </View>
    </View>
  )
})

const useStyles = CreateResponsiveStyle(
  {
    carouselWrapper: {
      flexDirection: 'row',
    },

    marginV20: {
      marginVertical: 20,
    },

    csaDescriptionCont: {
      marginHorizontal: 20,
      marginVertical: 0,
    },
  },
  {
    [maxSize(DEVICE_SIZES.SM)]: {
      carouselWrapper: {
        flexDirection: 'column',
      },
      csaDescriptionCont: {
        marginVertical: 20,
        marginHorizontal: 0,
      },
    },
  },
)
