import { Toast, hideModal } from '@elements'
import { extendErr } from '@helpers/helpers'
import { CSA } from '@models/CSA'
import { Product, Unit } from '@models/Product'
import { useCallback, useMemo, useState } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { suggestedProductsModal } from '../modals/SuggestedProducts'
import {
  disableAddCartBtn,
  getAddCartBtnStatus,
  getCartItemForAddCartBtn,
  makeTestIdPrefix,
  makeUpdateQuantity,
  shouldShowSuggested,
} from './AddCartBtn-helper'

import { Logger } from '@/config/logger'
import { CartServiceType } from '@/constants/types/cartService'
import { useAvailAddons } from '@/hooks/useAvailAddons'
import { useCartService } from '@/hooks/useCart'
import { useAddToCartFlow } from '@/hooks/useCart/addToCartFlow/useAddToCartFlow'
import { useDeepCompareCallback, useDeepCompareMemo } from '@/hooks/useDeepEqualEffect'
import { productsCollection } from '@api/framework/ClientCollections'
import { AlgoliaGeoDoc, AlgoliaGeoProduct, isGeoDoc } from '@models/Algolia'
import { CartItem } from '@models/Order'

export type AddCartBtnProps = {
  /** the product to add to cart. */
  product: Product | AlgoliaGeoDoc<AlgoliaGeoProduct>
  /** If provided, the button will add the product with this unit only. Else it will open the UnitSelection for products with multiple units. */
  unit?: Unit
  /** This csa will be added to the `CartItem`, when the product is added to cart */
  csa?: CSA
  outline?: boolean
  style?: StyleProp<ViewStyle>
  /** Called when the AddCartBtn is pressed. Not called by the Stepper. */
  onPress?: (p: Product | AlgoliaGeoDoc<AlgoliaGeoProduct>) => any
  testID?: string
  /**Whether the stepper should not show, even if the product is in cart. If true it will continue to show the "+" icon even when the product is in the cart */
  disableStepper?: boolean
  /** Which cart will this button add items to */
  cartServiceType?: CartServiceType
  isWholesale: boolean | undefined
}

/** This hook will provide the data used by the ADD TO CART BUTTON UI
 * - Anything not already available through other hooks should be returned from here
 * - Anything that would make JSX hard to read should go here
 */
export const useAddCartBtnData = ({
  product,
  unit,
  csa,
  style,
  outline,
  onPress,
  disableStepper = false,
  isWholesale,
  cartServiceType = 'consumer',
}: AddCartBtnProps) => {
  const [loading, setLoading] = useState(false)
  const { availAddonsIds } = useAvailAddons()
  const { cart, updateQuantity: updateQtyService } = useCartService({ cartServiceType, isWholesale })
  const { addToCartFlow } = useAddToCartFlow({ isWholesale, cartServiceType })

  const addCartStatus = useDeepCompareMemo(
    () => getAddCartBtnStatus(product, cart, unit, isWholesale),
    [cart, unit, product, isWholesale],
  )

  const cartItem = useDeepCompareMemo(() => getCartItemForAddCartBtn(product, cart, unit), [cart, unit, product])

  /** This is the fn used for the stepper add and minus buttons. It wraps the addToCart service to provide useful alerts and check whether the quantity can be updated before calling the cart api */
  const updateQuantity = useCallback(
    async (delta: number) => {
      try {
        setLoading(true)
        await makeUpdateQuantity(product, cart, updateQtyService, isWholesale, unit)(delta)
      } catch (error) {
        Toast('Something went wrong while updating the quantity')
      }
      setLoading(false)
    },
    [product, cart, updateQtyService, isWholesale, unit],
  )

  /** This will disable the add button in the stepper */
  const disableAdd = useMemo(
    () =>
      disableAddCartBtn({
        prod: product,
        cart,
        availAddonsIds,
        unitProp: unit,
        mode: 'consumer',
        useCache: true,
        isWholesale,
      }) || loading,
    [cart, product, availAddonsIds, unit, loading, isWholesale],
  )

  const id = makeTestIdPrefix(product, unit) + cartItem ? '' : '-btn'

  /** Handles initiating the addToCart flow */
  const onPressMemo = useDeepCompareCallback(async () => {
    let newItem: CartItem | null = null
    let prodDb: Product
    try {
      setLoading(true)
      onPress?.(product)

      if (isGeoDoc(product)) {
        /** Here we fetch the product from the database only at the time it is needed for adding to cart purposes, and ony if the current product data is an algolia geo product. This means the algolia model can be used for display purposes without fetching from the db, and thus, without requiring double-fetching (Fetching from algolia + fetching from db) */
        prodDb = await productsCollection.fetch(product.id)
      } else {
        prodDb = product
      }
      newItem = await addToCartFlow({ product: prodDb, unit, csa })
    } catch (err) {
      Logger.error(extendErr(err, 'Error while adding product to cart'))
      return setLoading(false)
    }

    if (!csa || !newItem) return setLoading(false)
    try {
      const { shouldShow, prods } = await shouldShowSuggested(
        newItem,
        [...cart, newItem], // The cart passed here must include the newly added item, so it can calculate addon availability on suggested products
        availAddonsIds,
        isWholesale,
      )
      if (!shouldShow) return setLoading(false)

      await suggestedProductsModal(csa, prods)
      setLoading(false)
    } catch (err) {
      Logger.error(extendErr(err, 'Error while handling csa suggested products'))
      hideModal()
      return setLoading(false)
    }
  }, [csa, onPress, product, addToCartFlow, unit, cart, availAddonsIds, isWholesale])

  return {
    loading,
    cartItem,
    disableAdd,
    id,
    updateQuantity,
    style,
    outline,
    onPressMemo,
    addCartStatus,
    disableStepper,
  }
}

export type AddCartBtnData = ReturnType<typeof useAddCartBtnData>
