import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { StyleSheet } from 'react-native'

export const useStyles = () =>
  useLayoutFnStyles(({ isLargeDevice, isMedDevice, isSmallDevice, width }) => {
    return {
      orderOptions: { flex: isLargeDevice ? 1 : undefined, margin: isLargeDevice ? 20 : 10 },
      screenContStyle: { flexDirection: isLargeDevice ? 'row' : 'column' },
      orderSummaryWrapper: {
        marginVertical: 20,
        marginHorizontal: isLargeDevice ? 20 : 10,
        flexDirection: isLargeDevice ? 'column' : 'column-reverse',
        flex: isLargeDevice ? 0.5 : undefined,
      },
      wrapItem: {
        flex: 1,
        minWidth: isSmallDevice ? width * 0.9 : 300,
        marginRight: 10,
        marginVertical: isLargeDevice ? 12 : isMedDevice ? 8 : 3,
      },
      marginV: { marginVertical: isLargeDevice ? 12 : isMedDevice ? 8 : 3 },
      largePlaceOrderBtn: { alignSelf: 'center', paddingHorizontal: isLargeDevice ? 50 : isMedDevice ? 200 : 75 },
    }
  })

export const styles = StyleSheet.create({
  flex1: { flex: 1 },
  wrapRow: { flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' },
  btnsContainer: {
    marginTop: 20,
    marginBottom: 10,
    flexDirection: 'row',
    flexWrap: 'wrap-reverse',
    alignItems: 'center',
    alignSelf: 'flex-end',
    alignContent: 'center',
    justifyContent: 'flex-end',
  },
  marginTop15: { marginTop: 15 },
  marginV15: { marginVertical: 15 },
})
