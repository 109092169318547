import { OfflineTable } from '@/admin/components/OfflineTable/OfflineTable'
import { ActionBtn, ActionsMenuComponent } from '@/admin/components/elements/ActionsMenuComponent'
import { AddFarmAdmin } from '@/admin/components/modals'
import { globalStyles } from '@/constants/Styles'
import { useHasPermissionWithFlag } from '@/hooks/useHasPermission'
import { adminFarmSelector, userSelector } from '@/redux/selectors'
import { ToolTips } from '@components'
import { ButtonClear, Modal, TextH2, Tooltip } from '@elements'
import { Permission, isManager } from '@helpers/Permission'
import { capitalize } from '@helpers/display'
import { isNonNullish } from '@helpers/helpers'
import { Farm, ManagerType } from '@models/Farm'
import { userName } from '@models/User'
import { memo, useMemo } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { getActions } from '../helper'
import { sharedStyles } from './styles'
import { TabScreen } from './types'

/** This will be used to display farm team member role. */
const displayRole = (manager: ManagerType) => {
  const role = manager.farmAssociation.role
  if (!role) return ''

  if (isManager(role)) {
    return 'Owner'
  }

  return capitalize(role)
}
const addNewAdmin = (farm: Farm) => Modal(<AddFarmAdmin farm={farm} />, { title: 'Add new Team Member' })

/** Farm memebers related tab */
export const MembersTab = memo(function MembersTab({ isVisible }: TabScreen) {
  const farm = useSelector(adminFarmSelector)
  const user = useSelector(userSelector)

  const hasAccessAdminRoleRight = useHasPermissionWithFlag(Permission.AccountSetup)

  /** This generates the actions  */
  const roleActions: ActionBtn<ManagerType>[] = useMemo(() => getActions(farm.id), [farm.id])

  if (!isVisible) {
    return <View />
  }

  return (
    <View style={sharedStyles.baseGap}>
      <View style={sharedStyles.tabHeaderCont}>
        <View style={globalStyles.flexRowCenter}>
          <TextH2 size={16}>Team Members</TextH2>
          <Tooltip id={ToolTips.TEAM_MEMBERS_TAB} />
        </View>
      </View>
      <OfflineTable<ManagerType>
        data={farm.managers ?? []}
        minWidth={500}
        headerColumns={[
          { title: 'Name' },
          { title: 'Email', widthFlex: 1.5 },
          { title: 'Role' },
          hasAccessAdminRoleRight ? { title: 'Actions', widthFlex: 0.5 } : undefined,
        ].filter(isNonNullish)}
        columns={[
          { process: (manager: ManagerType) => userName(manager.user) },
          { process: (manager: ManagerType) => manager.user.email, widthFlex: 1.5 },
          { process: displayRole },
          hasAccessAdminRoleRight
            ? {
                process: (manager: ManagerType) =>
                  //TODO: Add notification reference later
                  /**
                   * Manager can not be changed (should not happen in production, but could happen in dev.). Only show action button when the current user role (from redux) is manager and admin. Also, admin can not change their own role. And, manager is always the manager and can not be changed.
                   */
                  !isManager(manager.farmAssociation?.role) && manager.user.id !== user.id ? (
                    <ActionsMenuComponent<ManagerType> data={manager} buttons={roleActions} />
                  ) : (
                    ''
                  ),
                widthFlex: 0.5,
              }
            : undefined,
        ].filter(isNonNullish)}
      />
      {hasAccessAdminRoleRight && (
        <View style={globalStyles.alignCenter}>
          <ButtonClear title="Add New" onPress={() => addNewAdmin(farm)} />
        </View>
      )}
    </View>
  )
})
