import { SimpleCart } from '@models/Cart'
import { CartItem, isCartDigital, OrderItem } from '@models/Order'
import { DateTime } from 'luxon'
import { formatDistributionType } from './location'
import { isBefore } from './time'

/** We won't allow more than 4 decimal points of precision for adjustments. This is an artificial limit for display purposes and can be removed safely. */
export const MAX_DECIMALS_FOR_VARIABLE_WEIGHT_ADJUSTMENTS = 4

/**
 * Retrieves the pickup dates by location type from the cart.
 *
 * @param cart A simple cart object with items as an array of cart items.
 * @returns An object containing pickup dates grouped by location type. The keys are the location type strings (ex. 'Delivery') and the values are arrays of pickup dates.
 */
export function getCartPickupDatesGroupedByLocationType(cart: Pick<SimpleCart, 'items'>) {
  return cart.items.reduce((sections, cartItem) => {
    // Digital items have no distribution or pickups
    if (isCartDigital(cartItem)) return sections

    const { distribution, pickups } = cartItem

    // Ex. pickup, delivery, shipping, etc.
    const locationType = formatDistributionType(distribution.location, { capitalize: true })
    // The previously found pickup dates for this location type
    const existingPickups = sections[locationType] ?? []
    // Combines the previously found pickup dates with the new ones
    const combinedPickups = [...existingPickups, ...pickups]

    // Sorts pickups and removes any duplicates
    const distinctSortedPickups = combinedPickups
      .sort((a, b) => (isBefore(a, b) ? -1 : 1))
      .filter((pickup, index, arr) => !index || !pickup.equals(arr[index - 1]))

    return { ...sections, [locationType]: distinctSortedPickups }
  }, {} as Record<string, DateTime[]>)
}

/** Will return the original quantity from a cart item or order item before any adjustments */
export const getUnadjustedQuantity = (item: Pick<CartItem | OrderItem, 'quantity' | 'unadjustedQuantity'>) => {
  // We should first use unadjustedQuantity and only use quantity if unadjusted doesn't exist. If Unadjusted quantity doesn't exist then there has been no adjustment and quantity is the same.
  return item.unadjustedQuantity ?? item.quantity
}
