import { Icon, Picker, Text } from '@elements'

import { DateTime } from 'luxon'
import { memo, useCallback } from 'react'
import { StyleSheet, View } from 'react-native'

import { DateTimePicker } from '../../../components/elements/DateTimePicker/DateTimePicker'
import {
  DateValue,
  getLast30DaysDate,
  getLast3MonthsDate,
  getLast6MonthsDate,
  getLast7DaysDate,
  getLastYearDate,
  getMonthToDate,
  getPreviousDate,
  getThisWeekDate,
  getTodayDate,
  getYearToDate,
  getYesterdayDate,
} from './util'

import Colors from '@/constants/Colors'
import { isWeb } from '@/constants/Layout'
import { fromJSDate, toJSDate } from '@helpers/time'
import { dateTimeInZone } from '@models/Timezone'

export enum DateRangeNames {
  Today = 'Today',
  Yesterday = 'Yesterday',
  This_Week = 'This Week',
  Last_7_days = 'Last 7 days',
  This_Month = 'This Month',
  Last_30_days = 'Last 30 days',
  Last_3_Months = 'Last 3 Months',
  Last_6_Months = 'Last 6 Months',
  This_Year = 'This Year',
  Last_Year = 'Last Year',
  Custom = 'Custom',
}

type Option = {
  name: DateRangeNames
  getDate: () => DateValue
  pastLabel: string
}

const options: Option[] = [
  { name: DateRangeNames.Today, getDate: getTodayDate, pastLabel: 'yesterday' },
  { name: DateRangeNames.Yesterday, getDate: getYesterdayDate, pastLabel: 'the day before yesterday' },
  { name: DateRangeNames.This_Week, getDate: getThisWeekDate, pastLabel: 'last week' },
  { name: DateRangeNames.Last_7_days, getDate: getLast7DaysDate, pastLabel: 'the previous 7 days' },
  { name: DateRangeNames.This_Month, getDate: getMonthToDate, pastLabel: 'last month' },
  { name: DateRangeNames.Last_30_days, getDate: getLast30DaysDate, pastLabel: 'the previous 30 days' },
  {
    name: DateRangeNames.Last_3_Months,
    getDate: getLast3MonthsDate,
    pastLabel: 'the previous 3 months',
  },
  {
    name: DateRangeNames.Last_6_Months,
    getDate: getLast6MonthsDate,
    pastLabel: 'the previous 6 months',
  },
  { name: DateRangeNames.This_Year, getDate: getYearToDate, pastLabel: 'last year' },
  { name: DateRangeNames.Last_Year, getDate: getLastYearDate, pastLabel: 'the year before last year' },
  { name: DateRangeNames.Custom, getDate: getYearToDate, pastLabel: '' },
]

interface DatePickerProps {
  timezone: string
  setDate(name: DateRangeNames, date: DateValue, pastLabel: string): void
  showCustomPickers?: boolean
  date: { label: DateRangeNames; value: DateValue; pastLabel?: string }
  /** If disableFutureDates is true, the date selector won't allow any date to be chosen after today. By default is true */
  disableFutureDates?: boolean
  /** Whether should use the native picker on web
   * - if `true`, the native picker will show instead of `BottomSheetPicker` on small devices
   * - Must be used when inside used inside modals (see [issue](https://github.com/farmgenerations/grownby/issues/8135))
   */
  useWebNativePicker?: boolean
}

// Will get an option with the matching name, if none exist it will return the custom option
const getOptionByName = (name: DateRangeNames) => {
  return options.find((opt) => opt.name === name) ?? options[options.length - 1]
}

function DatePickerComponent({
  date,
  setDate,
  timezone,
  showCustomPickers = true,
  disableFutureDates = true,
  useWebNativePicker = false,
}: DatePickerProps): JSX.Element {
  const selectedOption = getOptionByName(date.label) ?? options[0]

  const onChangeStartDate = useCallback(
    (newDate: DateTime) => {
      const customOpt = getOptionByName(DateRangeNames.Custom)

      setDate(
        DateRangeNames.Custom,
        {
          ...customOpt.getDate(),
          pastStartDate: getPreviousDate(toJSDate(newDate.startOf('day')), date.value.endDate),
          startDate: toJSDate(newDate.startOf('day')),
          endDate: date.value.endDate,
        },
        '',
      )
    },
    [date.value.endDate, setDate],
  )

  const onChangeEndDate = useCallback(
    (newDate: DateTime) => {
      const customOpt = getOptionByName(DateRangeNames.Custom)

      setDate(
        DateRangeNames.Custom,
        {
          ...customOpt.getDate(),
          pastStartDate: getPreviousDate(date.value.startDate, toJSDate(newDate.endOf('day'))),
          startDate: date.value.startDate,
          endDate: toJSDate(newDate.endOf('day')),
        },
        '',
      )
    },
    [date.value.startDate, setDate],
  )

  return (
    <View style={styles.main}>
      <Picker
        items={options
          // If we disabled showCustomPickers then we should not show this here
          .filter((opt) => opt.name !== DateRangeNames.Custom || showCustomPickers)
          .map((option) => ({ label: option.name, value: option.name }))}
        onValueChange={(value) => {
          const option = getOptionByName(value as Option['name'])
          if (option.name !== DateRangeNames.Custom) {
            setDate(option.name, option.getDate(), option.pastLabel)
          }
        }}
        value={selectedOption.name}
        placeholder={null}
        useWebNativePicker={useWebNativePicker}
      />
      {(selectedOption.name === DateRangeNames.Custom || showCustomPickers) && (
        <View style={styles.selectorsWrapper}>
          <View style={styles.pickerCont}>
            <Icon name="calendar" size={18} color={Colors.shades[200]} />

            <DateTimePicker
              onChange={onChangeStartDate}
              timezone={timezone}
              containerStyle={styles.dateTimePicker}
              mode="date"
              value={fromJSDate(date.value.startDate, timezone)}
              maxDate={disableFutureDates ? dateTimeInZone(timezone).startOf('day') : undefined}
            />
          </View>

          <Text>to</Text>

          <View style={styles.pickerCont}>
            <Icon name="calendar" size={18} color={Colors.shades[200]} />

            <DateTimePicker
              onChange={onChangeEndDate}
              timezone={timezone}
              containerStyle={styles.dateTimePicker}
              mode="date"
              value={fromJSDate(date.value.endDate, timezone)}
              maxDate={disableFutureDates ? dateTimeInZone(timezone).endOf('day') : undefined}
            />
          </View>
        </View>
      )}
    </View>
  )
}
/** @deprecated This has to be replaced by `DateRangePicker`  */
export const DatePicker = memo(DatePickerComponent)

const styles = StyleSheet.create({
  dateTimePicker: {
    padding: 4,
    borderWidth: 0,
    maxWidth: isWeb ? 100 : undefined, //DateTimePicker is different on web, as it takes more space by default
  },
  main: {
    flexDirection: 'row',
    gap: 16,
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  selectorsWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },

  pickerCont: {
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
    height: 40,
    borderWidth: 1,
    borderRadius: 10,
    paddingHorizontal: 6,
    borderColor: Colors.shades['100'],
  },
})
