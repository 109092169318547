import { errorToString, removeObjDuplicates, retry } from '@helpers/helpers'
import type { QueryDocumentSnapshot } from 'firebase/firestore'
import { DependencyList, useEffect, useRef } from 'react'

import { errorCatcher } from '@api/Errors'
import { useIsFocused } from '@react-navigation/native'
import { useCancelableDeepFocusFx } from './useCancelablePromise'
import { FocusFxOpts } from './useFocusFx'
import useKeyedState from './useKeyedState'

/** A database document with an existing ID */
type DocObj = { id: string }

export type ProgLoadState<T extends DocObj> = { loading: boolean; data: T[]; err?: string }

export const defaultProgressiveLoadingState: ProgLoadState<any> = { loading: true, data: [] }

export type ProgressiveLoadingOpts<T extends DocObj, C = QueryDocumentSnapshot> = {
  /*gets a new set of results for each iteration */
  getPageData: GetNewPageFn<T, C>
  /** filters out results before being added to the main state */
  filter?: (doc: T) => boolean
  /** desired number of results at which loading will end */
  targetLength?: number
  /** if a fn is provided, the target length won't be enough to meet the stopping condition based on results length, until this also returns false. In other words, if a shouldFetchMore exists, the targetLength needs to be met, and also the return boolean of shouldFetchMore() needs to be false, for reaching a stopping condition. other stopping conditions still apply such as maxCalls */
  shouldFetchMore?: (currentResults: T[]) => boolean
  /** how many results to fetch on each iteration */
  pageLength?: number
  /** the maximum number of async iterations to run */
  maxCalls?: number
  /** loading won't begin until this is true */
  runCondition?: boolean
  /** will apply any change desired for the results after they've been filtered */
  transform?: (filteredResults: T[]) => T[] | Promise<T[]>
  /** if getFromCache returns an array of items, this will end early */
  getFromCache?: () => T[] | undefined
  /** dependencies. on deep-compare change while on focus, they will trigger a re-run of the progressive loading. use with caution because this will cause the whole progressive paginated api sequence to run again */
  deps?: DependencyList
  /** Optional function that will be called on error and can perform custom error handling. This callback will be memoized with no updates, to allow inline fn for convenience. */
  onError?: (e: unknown) => void
  /** When the run condition fails, this will determine whether the state contines to wait, with loading:true, or whether a failed run condition constitutes a reason why we should stop loading and considered things done. This varies a lot in the use-case */
  failedConditionMode?: 'stop-loading' | 'keep-loading'
  /** Called whenever a new state is set. Can be defined inline */
  onStateSet?: (newState: ProgLoadState<T>) => void
  /** Effect dependencies for onStateSet */
  depsOnState?: DependencyList
  initialState?: Partial<ProgLoadState<T>>
  onDone?: (finalState: ProgLoadState<T>) => void
  /** If true, when a page data returns less items than the page length, the iteration will stop, assuming the query ran out of data. default true. */
  abortOnPageLengthUnfulfilled?: boolean
} & FocusFxOpts

export type PageData<T, C> = {
  /** The results fetched for the page */
  data: T[]
  /** The cursor of the last result of the page*/
  lastCursor: C
  /** If results for the page are less than the page length, it means the pagination ran out of data. In that case, getPageData can provide a value of `false`, to instruct the the iteration to stop. If it is undefined, the data length will be used to determine this */
  pageLengthFulfilled?: boolean
}

/** Given a cursor and a page length, it should return the next page of data.
 * @param cursor is the cursor of the last result from the last fetched page. If it's the first page, cursor will be null.
 * @param pageLength tells the fetcher how many results to get for this page.
 */
export type GetNewPageFn<T extends DocObj, C = QueryDocumentSnapshot> = (
  cursor: C | null,
  pageLength: number,
) => Promise<PageData<T, C>>

/** Calls an api in paginated sequences and accumulates the results in state */
export function useProgressiveLoading<T extends DocObj, C = QueryDocumentSnapshot>({
  getPageData,
  filter = () => true,
  targetLength = 30,
  shouldFetchMore = () => false,
  pageLength = 10,
  maxCalls = Math.ceil(targetLength / pageLength), // division ceiling will allow for a final partial page to be fetched
  runCondition,
  transform = (res) => res,
  getFromCache,
  deps = [],
  onError = errorCatcher,
  failedConditionMode,
  onStateSet,
  depsOnState,
  initialState = {},
  noRefocus = true,
  onDone,
  abortOnPageLengthUnfulfilled = true,
}: ProgressiveLoadingOpts<T, C>) {
  //Get a ref for isFocused, so we can use it inside the hook without requiring re-renders to use it inside the fx
  const isFocused = useIsFocused()
  const isFocusedRef = useRef(isFocused)
  useEffect(() => {
    isFocusedRef.current = isFocused
  }, [isFocused])

  const [state, , , , setPartial] = useKeyedState<ProgLoadState<T>>(
    { ...defaultProgressiveLoadingState, ...initialState },
    {
      onStateSet,
      deps: depsOnState,
    },
  )

  useCancelableDeepFocusFx(
    async (isCurrent) => {
      if (runCondition === false) {
        if (!failedConditionMode || failedConditionMode === 'stop-loading') setPartial({ loading: false })
        return
      }

      /** On each trigger, this should reset the state, while still allowing the "initialState" prop to override the resetted state */
      setPartial({ loading: true, data: [], err: undefined, ...initialState })

      if (targetLength === 0) {
        return setPartial({ loading: false, err: undefined, data: [] })
      }

      if (getFromCache) {
        const cache = getFromCache()
        if (cache && cache.length) return setPartial({ loading: false, err: undefined, data: cache })
      }

      let accumulatedResults: T[] = []
      let cursor: C | null = null
      let nCall = 1

      while (accumulatedResults.length < targetLength || shouldFetchMore(accumulatedResults)) {
        const pageResult: PageData<T, C> = await retry(() => getPageData(cursor, pageLength))
        if (!isCurrent) break

        const { data, lastCursor, pageLengthFulfilled = data.length === pageLength } = pageResult

        const filteredData = data.filter(filter)

        accumulatedResults = await transform(removeObjDuplicates([...accumulatedResults, ...filteredData]))

        if (filteredData.length > 0) {
          setPartial({ data: accumulatedResults, err: undefined })
        }

        if (
          /** shouldFetchMore() must produce false in order to end iteration based on targetLength */
          (accumulatedResults.length >= targetLength && shouldFetchMore(accumulatedResults) === false) ||
          nCall >= maxCalls ||
          (abortOnPageLengthUnfulfilled && pageLengthFulfilled === false) || // Abort if a page returned less results than the page length
          isFocusedRef.current === false // Abort if component is no longer focused
        ) {
          setPartial({ data: accumulatedResults, loading: false })
          break
        }
        nCall++
        if (pageResult.lastCursor) {
          cursor = lastCursor
        }
      }
      onDone?.(state)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps.concat([runCondition]),
    {
      noRefocus,
      onErr: (error) => {
        setPartial({ data: [], err: errorToString(error), loading: false })
        onError?.(error)
      },
    },
  )

  return state
}
