import { pick } from '@helpers/typescript'
import {
  ADD_QUEUE_ALGOLIA_PRODUCT,
  REMOVE_QUEUE_ALGOLIA_PRODUCT,
  SET_ADMIN_CSAS,
  SET_ADMIN_FEATURES_AVAILABLE,
  SET_ADMIN_NAV,
  SET_CART_SERVICE_ORDER_CREATOR,
  SET_CART_SERVICE_ORDER_EDIT,
  SET_CUSTOMER_ORDER_CREATOR,
  SET_CUSTOMER_ORDER_EDIT,
  SET_FARM_LISTENER,
} from '../actions/actionTypes'
import { AdminState } from './types'

import { initialCartService } from '@/constants/types/cartService'

export const initialState: AdminState = {
  CSAs: [],
  navProps: {},
  queueAlgoliaProducts: {},
  cartService: initialCartService,
  cartServiceOrderEdit: initialCartService,
}

const adminReducer = (state = initialState, action: any): AdminState => {
  switch (action.type) {
    case SET_ADMIN_NAV:
      return {
        ...state,
        navProps: { ...state.navProps, ...action.payload },
      }
    case SET_ADMIN_CSAS:
      return { ...state, CSAs: action.payload }
    case SET_FARM_LISTENER:
      return {
        ...state,
        farmUnListener: action.payload,
      }
    case ADD_QUEUE_ALGOLIA_PRODUCT:
      return {
        ...state,
        queueAlgoliaProducts: {
          ...state.queueAlgoliaProducts,
          [action.payload.prodId]: pick(action.payload, 'action', 'data'),
        },
      }
    case REMOVE_QUEUE_ALGOLIA_PRODUCT: {
      delete state.queueAlgoliaProducts[action.payload]
      return {
        ...state,
        queueAlgoliaProducts: state.queueAlgoliaProducts,
      }
    }
    case SET_ADMIN_FEATURES_AVAILABLE:
      return {
        ...state,
        featuresAvailable: action.payload,
      }
    case SET_CART_SERVICE_ORDER_CREATOR:
      return {
        ...state,
        cartService: action.payload,
      }
    case SET_CUSTOMER_ORDER_CREATOR:
      return {
        ...state,
        cartService: { ...state.cartService, customer: action.payload },
      }
    case SET_CART_SERVICE_ORDER_EDIT:
      return {
        ...state,
        cartServiceOrderEdit: action.payload,
      }
    case SET_CUSTOMER_ORDER_EDIT:
      return {
        ...state,
        cartServiceOrderEdit: { ...state.cartServiceOrderEdit, customer: action.payload },
      }
    default:
      return state
  }
}
export default adminReducer
