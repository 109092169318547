import { omit } from '@helpers/typescript'
import { Location } from '@models/Location'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { prepareMarshal, prepareUnmarshal } from './encoding'

/** marshalLocation returns Firestore marshalled data for the supplied Location. */
export function marshalLocation(location: Partial<Location>): DocumentData {
  const data = omit(prepareMarshal(location), 'id') as DocumentData
  if (location.name) {
    data.nameUppercase = location.name.toUpperCase()
  }
  return data
}

/** unmarshalLocation returns a Location from the supplied Firestore data. */
export function unmarshalLocation(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): Location {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)
  return { ...data, id } as Location
}
