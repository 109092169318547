import Colors from '@/constants/Colors'
import { GooglePlacesSearch } from '@components'
import { DropdownMenu, ErrorText, Icon, Text } from '@elements'
import { useLogistics } from '@screens/SearchScreen/hooks/useLogistics'
import { memo } from 'react'
import { StyleSheet, View } from 'react-native'
import { SearchScreenInput } from './SearchScreenInput'

/** Search screen header content component, that includes Search input & Logistics selection */
export const SearchScreenHeaderComp = memo(function SearchScreenHeaderComp() {
  const {
    onSelectEstablishment,
    locTypeButtons,
    locTypeDisplay,
    submitCurrentAddress,
    initialAddressFx,
    errorStr,
    onClearAddress,
  } = useLogistics()

  return (
    <View style={styles.main}>
      <SearchScreenInput />

      <DropdownMenu contentViewStyle={styles.dropdownContent} buttons={locTypeButtons}>
        <Text children={locTypeDisplay} />
        <Icon size={16} color={Colors.shades['500']} name="chevron-down" />
      </DropdownMenu>
      <View style={styles.addressInputCont}>
        <GooglePlacesSearch
          types="address"
          placeholder={initialAddressFx.data || 'Enter your location'}
          initialValue={initialAddressFx.data}
          enableReinitialize
          editable={!initialAddressFx.loading}
          autoComplete="postal-address"
          hasClearBtn
          onClearText={onClearAddress}
          style={styles.addressInput}
          getOnSubmit={(onSubmit) => (submitCurrentAddress.current = onSubmit)}
          onSelectEstablishment={onSelectEstablishment}
        />
      </View>

      {!!errorStr && <ErrorText>{errorStr}</ErrorText>}
    </View>
  )
})

const styles = StyleSheet.create({
  main: {
    maxWidth: 800,
    alignSelf: 'center',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    marginLeft: 70, // Nedeed in order to avoid overfowing the sidebar width (250)
  },
  dropdownContent: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: 8,
    height: 40,
    paddingHorizontal: 10,
    backgroundColor: Colors.white,
    width: 110, // Set in order to avoid the change of the dropdown width when options change
    marginLeft: 30,
  },

  addressInputCont: {
    flex: 1,
    backgroundColor: Colors.white,
    borderRadius: 8,
  },
  addressInput: {
    height: 40,
  },
})
