import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { memo, useState } from 'react'
import { View } from 'react-native'

import Colors from '../../constants/Colors'
import { Button } from './Button'
import { ButtonClear } from './ButtonClear'

import { useDeepCompareMemo } from '@/hooks/useDeepEqualEffect'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useFocusFx } from '@/hooks/useFocusFx'

interface CommonProps {
  /** 'fill' will use the Button component, whereas 'clear' will use the ButtonClear component for the buttons array */
  appearance?: 'fill' | 'clear'
  /** `useIcon` will put a check circle icon on the selected button */
  useIcon?: boolean
  /** applies the small style to the inner button components */
  small?: boolean
}

// Simple solution to return the new index on select
interface SimpleProps {
  buttons: string[]
  selectedIndex?: number
  onSelect: (index: number) => void
}

export type ButtonGroupButton = { label: string; onPress: () => void }

// Allows for custom handling for each value
interface CustomProps {
  buttons: ButtonGroupButton[]
  /** The selected index will be used as the initial state, AND also as the current state whenever it changes, so it turns this into a controlled component */
  selectedIndex?: number
  /** Receives the index of the selected button */
  onSelect?: undefined
}

type ButtonGroupProps = (SimpleProps | CustomProps) & CommonProps

function isCustomBtn(props: string | ButtonGroupButton): props is ButtonGroupButton {
  return props.hasOwnProperty('label')
}

function isSimple(props: ButtonGroupProps): props is SimpleProps {
  return props.hasOwnProperty('onSelect')
}

function ButtonGroupComp(props: ButtonGroupProps) {
  const [selectedIx, setSelectedIx] = useState(props.selectedIndex || 0)
  const styles = useStyles()

  useFocusFx(() => {
    if (isSimple(props)) {
      props.onSelect(selectedIx)
    }
    // this is only meant to run in simple props when the selected index changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIx])

  useFocusFx(() => {
    // If the selected index has been changed externally, change the internal state as well
    if (typeof props.selectedIndex === 'number' && selectedIx !== props.selectedIndex) {
      setSelectedIx(props.selectedIndex)
    }
    // this only needs to run when the prop selectedIndex changes, because its purpose is to update the inner selected state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedIndex])

  return (
    <View style={styles.container}>
      {useDeepCompareMemo(() => {
        const Comp = props.appearance === 'fill' ? Button : ButtonClear
        const offColor = props.appearance === 'fill' ? Colors.shades[300] : Colors.shades[500]
        return props.buttons.map((btn, index) => (
          <Comp
            key={isCustomBtn(btn) ? btn.label : btn}
            title={isCustomBtn(btn) ? btn.label : btn}
            color={selectedIx === index ? Colors.green : offColor}
            style={[styles.button, props.appearance !== 'fill' && selectedIx === index && styles.selectedClear]}
            onPress={() => {
              if (isCustomBtn(btn)) {
                btn.onPress()
              }
              setSelectedIx(index)
            }}
            icon={props.useIcon && selectedIx === index ? 'check-circle' : undefined}
            small={props.small}
          />
        ))
      }, [props.buttons, props.appearance, selectedIx, props.useIcon, styles, props.small])}
    </View>
  )
}
const ButtonGroup = memo(ButtonGroupComp, propsAreDeepEqual)
export default ButtonGroup

const useStyles = () =>
  useSizeFnStyles(({ isSmallDevice }) => ({
    container: {
      flexDirection: 'row',
      marginVertical: 10,
    },
    button: {
      padding: 0,
      marginHorizontal: isSmallDevice ? 6 : 10,
    },
    selectedClear: {
      borderBottomWidth: 3,
      borderBottomColor: Colors.green,
    },
  }))
