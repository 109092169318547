import * as React from 'react'
import { LayoutChangeEvent, StyleProp, View, ViewStyle } from 'react-native'

export interface ICustomViewStyle extends ViewStyle {
  children?: ICustomViewStyle[]
  key?: number | string
}

export interface ISkeletonContentProps {
  isLoading: boolean
  layout?: ICustomViewStyle[]
  duration?: number
  containerStyle?: StyleProp<ViewStyle>
  boneColor?: string
  highlightColor?: string
  children?: any
}

const { useState, useCallback } = React

const useLayout = () => {
  const [size, setSize] = useState<any>({ width: 0, height: 0 })

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    const { width, height } = event.nativeEvent.layout
    setSize({ width, height })
  }, [])

  return [size, onLayout]
}

function SkeletonContentComp({
  containerStyle = containerStyleDefault,
  layout = [],
  isLoading = true,
  boneColor = '#E1E9EE',
  children,
}: ISkeletonContentProps) {
  const [componentSize, onLayout] = useLayout()

  const getBoneWidth = (boneLayout: ICustomViewStyle): number =>
    (typeof boneLayout.width === 'string' ? componentSize.width : boneLayout.width) || 0
  const getBoneHeight = (boneLayout: ICustomViewStyle): number =>
    (typeof boneLayout.height === 'string' ? componentSize.height : boneLayout.height) || 0

  const getBoneStyles = (boneLayout: ICustomViewStyle): ICustomViewStyle => {
    const { backgroundColor, borderRadius } = boneLayout
    const boneWidth = getBoneWidth(boneLayout)
    const boneHeight = getBoneHeight(boneLayout)
    const boneStyle: ICustomViewStyle = {
      width: boneWidth,
      height: boneHeight,
      borderRadius: borderRadius || 4,
      ...boneLayout,
    }
    boneStyle.overflow = 'hidden'
    boneStyle.backgroundColor = backgroundColor || boneColor
    return boneStyle
  }
  const getBoneContainer = (layoutStyle: ICustomViewStyle, childrenBones: JSX.Element[], key: number | string) => (
    <View key={layoutStyle.key || key} style={layoutStyle}>
      {childrenBones}
    </View>
  )

  const getStaticBone = (layoutStyle: ICustomViewStyle, key: number | string): JSX.Element => (
    <View key={layoutStyle.key || key} style={getBoneStyles(layoutStyle)} />
  )

  const getBones = (
    bonesLayout: ICustomViewStyle[] | undefined,
    childrenItems: any,
    prefix: string | number = '',
  ): JSX.Element[] => {
    if (bonesLayout && bonesLayout.length > 0) {
      const iterator: number[] = new Array(bonesLayout.length).fill(0)
      return iterator.map((_, i) => {
        // has a nested layout
        if (bonesLayout[i].children && bonesLayout[i].children!.length > 0) {
          const containerPrefix = bonesLayout[i].key || `bone_container_${i}`
          const { children: childBones, ...layoutStyle } = bonesLayout[i]
          return getBoneContainer(layoutStyle, getBones(childBones, [], containerPrefix), containerPrefix)
        }
        return getStaticBone(bonesLayout[i], prefix ? `${prefix}_${i}` : i)
      })
      // no layout, matching children's layout
    }
    return React.Children.map(childrenItems, (child, i) => {
      const styling = child.props.style || {}
      return getStaticBone(styling, i)
    })
  }

  return (
    <View style={containerStyle} onLayout={onLayout}>
      {isLoading ? getBones(layout!, children) : children}
    </View>
  )
}

const containerStyleDefault: ViewStyle = {
  alignItems: 'center',
  flex: 1,
  justifyContent: 'center',
}

export const SkeletonContent = React.memo(SkeletonContentComp)
