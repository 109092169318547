import { DateTime } from 'luxon'
import { Pickup } from '@models/Order'
import { dateTimeInZone } from '@models/Timezone'
import { getCsaChangeDeadlineFromPickup } from '@helpers/order'
import { isAfter } from '@helpers/time'

export const isAfterCSAChangeWindow = (
  pickupDate: DateTime,
  startTime: Pickup['distribution']['hours']['startTime'],
  isAdmin: boolean,
  changeWindow: number | undefined,
): boolean => {
  const now = dateTimeInZone(pickupDate.zoneName)

  const cutoffDate = getCsaChangeDeadlineFromPickup(pickupDate, isAdmin ? 0 : changeWindow, startTime)
  return isAfter(now, cutoffDate)
}
