import { MultiSelectAutocompleteComp } from '@/admin/components/MultiSelectAutocomplete'
import { useApiFx } from '@/hooks/useApiFx'
import { AutoCompleteItem } from '@/hooks/useAutoComplete'
import { adminFarmSelector } from '@/redux/selectors'
import { loadFarmCategories } from '@api/Categories'
import { Toast } from '@elements'
import { useFormikContext } from 'formik'
import * as React from 'react'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { CouponFormType } from '../AddEditCoupon'

/** MultipleSelect Categories Autocomplete component for AddEditCoupon Form  */
export function CategoriesSelection() {
  const { values, setFieldValue } = useFormikContext<CouponFormType>()
  const farm = useSelector(adminFarmSelector)
  const { data: categories } = useApiFx(loadFarmCategories, [farm.localCategories])

  const onCategorySelect = useCallback(
    (category: AutoCompleteItem<string>) => {
      if (values.categories?.includes(category.data)) {
        Toast(`${category.text} is already selected`)
        return
      }

      setFieldValue('categories', [...(values.categories ?? []), category.data])
    },
    [setFieldValue, values.categories],
  )

  const onRemoveCategory = useCallback(
    (category: AutoCompleteItem<string>) => {
      setFieldValue(
        'categories',
        values.categories!.filter((item) => item !== category.data),
      )
    },
    [setFieldValue, values.categories],
  )

  const items = useMemo(() => {
    if (!categories) return []

    const filtered = categories.filter((cat) => !values.categories?.includes(cat.name))

    return filtered.map((category) => ({ text: category.name, data: category.name }))
  }, [categories, values.categories])

  const selectedItems = useMemo(() => {
    return values.categories?.map((category) => ({ data: category, text: category })) ?? []
  }, [values.categories])

  return (
    <MultiSelectAutocompleteComp
      inline
      onSelect={(item) => onCategorySelect(item)}
      onRemove={onRemoveCategory}
      items={items}
      autoCompleteId="categorySearch"
      placeholder="Search for categories"
      selectedItems={selectedItems}
    />
  )
}
