import { LoadingView, Text, Touchable } from '@elements'
import { Feather } from '@expo/vector-icons'
import { CartItem } from '@models/Order'
import { memo } from 'react'
import { StyleProp, StyleSheet, TouchableOpacityProps, View, ViewStyle } from 'react-native'

import { makeTestIdPrefix } from './AddCartBtn-helper'

import Colors from '@/constants/Colors'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'

export type StepperProps = {
  cartItem: CartItem
  /** Function to run when quantity increases/ decreases */
  updateQuantity: (delta: number) => any
  /** "disabled" Should disable the stepper "+" button when the max stock is reached */
  disableAdd?: TouchableOpacityProps['disabled']
  style?: StyleProp<ViewStyle>
  loading?: boolean
}

const hitslop = { top: 4, right: 4, bottom: 4, left: 4 }

export const StepperHome = memo(function StepperHome({
  cartItem,
  updateQuantity,
  style,
  loading = false,
  disableAdd = false,
}: StepperProps) {
  const testIdPrefix = makeTestIdPrefix(cartItem.product, cartItem.unit)

  const onAdd = () => {
    updateQuantity(1)
  }

  const onSubstract = () => {
    updateQuantity(-1)
  }

  return (
    <View style={[styles.wrapper, style]} testID={testIdPrefix + '-stepper'} key={testIdPrefix}>
      <Touchable hitSlop={hitslop} testID={testIdPrefix + '-step[-]'} onPress={onSubstract}>
        <Feather name="minus-circle" size={22} color={Colors.green} />
      </Touchable>
      <LoadingView loading={loading} style={styles.qtyContainer} noDefaultLoadingContainerStyle>
        <Text type="medium" size={14}>
          {cartItem.quantity}
        </Text>
      </LoadingView>

      <Touchable hitSlop={hitslop} testID={testIdPrefix + '-step[+]'} onPress={onAdd} disabled={disableAdd}>
        <Feather name="plus-circle" size={22} color={disableAdd ? Colors.shades[200] : Colors.green} />
      </Touchable>
    </View>
  )
},
propsAreDeepEqual)

const styles = StyleSheet.create({
  wrapper: {
    borderRadius: 10,
    minHeight: 32,
    paddingHorizontal: 4,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Colors.white,
  },
  qtyContainer: {
    width: 35,
    alignItems: 'center',
  },
})
