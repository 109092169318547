import { memo, ReactNode } from 'react'

import { DateTimePicker, DateTimePickerProps } from './DateTimePicker'

type Props = DateTimePickerProps & {
  // These props are intercepted by FormBuilder
  errorMessage?: string
  label?: string | ReactNode
}

/** Wrapper for DateTimePicker intended for the Form Builder */
export default memo(function DateTimePickerForm(props: Props) {
  return <DateTimePicker {...props} />
})
