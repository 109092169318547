import { useState } from 'react'

import { Money } from '../models/Money'

// Sorter identifies the functions provided by the useSort hook.
export type Sorter<T> = {
  // sortBy applies the sort order to the field specified by the input. If the supplied field matches the current
  // field stored in the state the sorting order will be reversed. Setting a new name will reset the sort order.
  sortBy(field: keyof T): void

  // apply returns the items in the order specified by the hook's state.
  apply(items: T[]): T[]
}

export type DimensionalSorter<T> = {
  at(index: number): Sorter<T>
}

type SortState<T> = {
  field?: keyof T
  asc: boolean
}

// useSort is a React hook that abstracts sorting state into a reusable component.
export function useSort<T>(): Sorter<T> {
  const [state, setState] = useState<SortState<T>>({ asc: true })
  return sorter(state, setState)
}

// useDimensionalSort is a React hook that is like useSort but allows the state to be stored in a secondary
// dimension. This is used to facilitate views that include multiple tables.
export function useDimensionalSort<T>(): DimensionalSorter<T> {
  const [state, setState] = useState<SortState<T>[]>([])

  function at(index: number): Sorter<T> {
    return sorter(state[index] || { asc: true }, (newState: SortState<T>) => {
      const updateState = [...state]
      updateState[index] = newState
      setState(updateState)
    })
  }

  return { at }
}

// sorter provides the implementation of the sort state functionality. It returns a Sorter that is bound
// to the supplied state.
function sorter<T>(state: SortState<T>, setState: (newState: SortState<T>) => void): Sorter<T> {
  function sortBy(field: keyof T): void {
    if (state.field === field) {
      setState({ ...state, asc: !state.asc })
      return
    }
    setState({ field, asc: true })
  }

  function apply(items: T[]): T[] {
    const field = state.field
    if (!field) {
      return items
    }

    let direction = 1
    if (!state.asc) {
      direction = -1
    }

    return items.sort((a, b) => {
      const v1 = toComparable(a[field])
      const v2 = toComparable(b[field])

      if (v1 > v2) {
        return direction
      } else if (v1 < v2) {
        return -direction
      }
      return 0
    })
  }

  return { sortBy, apply }
}

// toComparable returns a value that can be used by the sort function.
export function toComparable(object: unknown): number | string {
  if (typeof object === 'number') {
    return object
  }
  if (typeof object === 'string') {
    return object
  }

  // Provide a value for the Money type.
  if (typeof object === 'object' && object) {
    if ('value' in object && typeof (object as Money).value === 'number') {
      return (object as Money).value
    }
  }

  throw new Error('input value is not a recognized sortable type')
}
