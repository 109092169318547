import { Button, PickerProps, SearchInputBar } from '@elements'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { memo } from 'react'
import { StyleProp, TextInputProps, View, ViewStyle } from 'react-native'

import { useDeviceSize } from '../../../hooks/useLayout'
import OfflineRefinementDropdown from './OfflineRefinementDropdown'

import Colors from '@/constants/Colors'
import { useControlledState } from '@/hooks/useControlledState'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useFocusFx } from '@/hooks/useFocusFx'

export type TableMenuProps = {
  /** If `search` is provided, the search input will show. This is the handler for the onChangeText of a TextInput. It should update the list of items in the table as a result of a change in the input text */
  search?: TextInputProps['onChangeText']
  /** Current search term for the TextInput */
  searchTerm?: string
  placeholder?: string
  /** Array of props to become filters with DropDownPicker */
  filters?: PickerProps[]
  containerStyle?: StyleProp<ViewStyle>
}

/**
 * Offline, non-algolia component, equivalent to ConnectedSearchBox, which implements search and table filters as dropdown menus.
 */
export const TableMenu = memo(function TableMenu({
  filters,
  placeholder: searchPlaceholder,
  containerStyle,
  search,
  searchTerm,
}: TableMenuProps) {
  const { isSmallDevice } = useDeviceSize()
  /** Whether the filters are collapsable within a button that shows or hides them */
  const mayCollapseFilters = isSmallDevice
  const [showingRefin, [showRefin, hideRefin]] = useControlledState(!mayCollapseFilters, [true, false])
  const styles = useStyles()

  useFocusFx(() => {
    /** When the screen size is no longer in a collapsable size, we don't want to keep the filters hidden because they only get collapsed in smaller screen sizes */
    if (!mayCollapseFilters && !showingRefin) showRefin()
  }, [mayCollapseFilters, showingRefin, showRefin])

  return filters?.length || search ? (
    <View style={[styles.container, containerStyle]}>
      {mayCollapseFilters && (
        <Button
          outline
          title={`${showingRefin ? 'Hide' : 'Show'} Filters`}
          icon="filter"
          onPress={showingRefin ? hideRefin : showRefin}
          style={styles.buttonStyle}
        />
      )}
      {showingRefin && (
        <>
          {filters?.map((props, idx) => (
            <OfflineRefinementDropdown
              key={typeof props.placeholder === 'string' ? props.placeholder : idx.toString()}
              {...props}
            />
          ))}
          {search && (
            <SearchInputBar
              onChangeText={search}
              value={searchTerm}
              placeholder={searchPlaceholder}
              contStyle={styles.searchBarStyle}
            />
          )}
        </>
      )}
    </View>
  ) : null
},
propsAreDeepEqual)

const useStyles = () =>
  useSizeFnStyles(({ isSmallDevice }) => ({
    container: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      paddingHorizontal: isSmallDevice ? 5 : 20,
      paddingVertical: 10,
      borderBottomWidth: 1,
      borderBottomColor: Colors.shades['100'],
    },
    searchBarStyle: {
      flex: 1,
      minWidth: 260,
      margin: 2,
      borderRadius: 8,
      height: 40,
      borderWidth: 1,
    },
    buttonStyle: {
      flex: 1,
      justifyContent: 'center',
      minWidth: 150,
      minHeight: 40,
      paddingHorizontal: 0,
      marginHorizontal: 2,
      marginVertical: 2,
      borderRadius: 8,
    },
  }))
