import { omit } from '../typescript'

interface BuilderInterface<Type extends object> {
  /** The name of the builder, used for helpful error messages*/
  name: string

  /**
   * Assembles a partial object into a validated object of the given type.
   * - Will throw errors if a valid type cannot be built from the provided data */
  build?(data: object, options?: object): Type | Omit<Type, 'id'>

  /**
   * Will take a pre-built object of the given type and will validate it.
   * - Will throw errors if the object data doesn't meet the requirements of the given type.
   * - Will return a copy of the object if it valid for the given type.
   */
  validate(data: Type, options?: object): Type
}

/** The base builder class, should be extended to implement builders for each data type */
export abstract class Builder<Type extends object> implements BuilderInterface<Type> {
  name: string
  protected constructor(name: string) {
    this.name = name
  }

  /**
   * validateWithoutId will take the data without the ID and add the ID to handle validation, then will return the data as
   * the complete type omitting ID. The ID should be added from outside the builder
   * @param data The partial data to validate as Type
   */
  validateWithoutId(data: Partial<Type>): Omit<Type, 'id'> {
    const res = this.validate({ ...data, id: 'id-for-validation' }) as Type & { id: string }
    return omit(res, 'id') as Omit<Type, 'id'>
  }

  abstract validate(data: Partial<Type>, options?: object): Type
}
