import { deletePromoCode } from '@api/Coupons'
import { Alert, Toast } from '@elements'
import { PromoCode } from '@models/Coupon'

import { Logger } from '../../../config/logger'

export function deletePromo(promo: PromoCode, onDelete: () => void) {
  Alert('Delete Promo Code', 'Are you sure you want to delete this promo code?', [
    {
      text: 'Delete',
      style: 'destructive',
      onPress: () => {
        deletePromoCode(promo)
          .then(() => {
            onDelete()
            Toast('Promo code deleted')
          })
          .catch((err) => {
            Logger.error(err)
            Alert('Failed', 'Could not delete this promo code, please contact support')
          })
      },
    },
    {
      text: 'Cancel',
      style: 'cancel',
    },
  ])
}
