import { Alert, ButtonClear, Icon, LoadingView, Text, Toast } from '@elements'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { errorToString } from '@helpers/helpers'
import { MoneyCalc } from '@helpers/money'
import { Money } from '@models/Money'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { Input } from 'react-native-elements'

import { Logger } from '../../../config/logger'
import Colors from '../../../constants/Colors'
import { globalStyles } from '../../../constants/Styles'
import { useDeepCompareCallback } from '../../../hooks/useDeepEqualEffect'

import { CartServiceType } from '@/constants/types/cartService'
import { useCartService } from '@/hooks/useCart'
import { useControlledState } from '@/hooks/useControlledState'
import { Discount, PromoCode } from '@models/Coupon'
import { getFarmCartDiscount } from '@models/Cart'

type EnterCartPromoProps = {
  /** The id of a farm whose products are in the cart, and from which the discount belongs */
  cartFarmId: string
  cartServiceType?: CartServiceType
  isWholesale: boolean
} & Omit<EnterPromoProps, 'addPromo' | 'discount' | 'loading' | 'removeDiscount'>

/** Wrapper for the EnterPromo component, which is adapted to use the cart service */
export const EnterCartPromo = memo(function EnterCartPromo({
  cartFarmId,
  cartServiceType = 'consumer',
  ebtTotal,
  isWholesale,
  ...rest
}: EnterCartPromoProps) {
  const { addPromo, removeDiscount, loadingCart, discounts } = useCartService({
    cartServiceType,
    farmId: cartFarmId,
    isWholesale,
  })

  /** This may be undefined because the discounts object might have no discount for this farm id */
  const discount = useMemo(() => getFarmCartDiscount({ discounts }, cartFarmId), [discounts, cartFarmId])

  const hasEbtPayment = MoneyCalc.isGTZero(ebtTotal)

  const addPromoForCartFarm = useCallback(
    async (promoId: string) => {
      await addPromo(cartFarmId, promoId, hasEbtPayment)
    },
    [cartFarmId, addPromo, hasEbtPayment],
  )

  const removeDiscountForCartFarm = useCallback(() => removeDiscount(cartFarmId), [removeDiscount, cartFarmId])

  return (
    <EnterPromo
      addPromo={addPromoForCartFarm}
      discount={discount}
      loading={loadingCart}
      removeDiscount={removeDiscountForCartFarm}
      ebtTotal={ebtTotal}
      {...rest}
    />
  )
})

type EnterPromoProps = {
  /**
   * For invoices: The invoice EBT total.
   * For carts: The EBT payment amount from the current checkout payment options */
  ebtTotal: Money
  style?: StyleProp<ViewStyle>
  initialShowInputState?: boolean
  /**
   * For invoices: The current discount selected, which will be applied when the user decides to pay.
   * For carts: The discount in the cart for the farm whose items are being checked out.
   */
  discount?: Discount
  /** Fn to call when the user submits a promo code from the input */
  addPromo: (promoCode: PromoCode['code']) => Promise<void>
  loading: boolean
  removeDiscount: () => Promise<void>
}

/** UI that handles adding a promo code to either a cart or an invoice */
export const EnterPromo = memo(function EnterPromo({
  style,
  initialShowInputState = false,
  ebtTotal,
  addPromo,
  discount,
  loading,
  removeDiscount,
}: EnterPromoProps) {
  const [promoCode, setPromoCode] = useState<PromoCode['code']>('')
  const [isShowingInput, [showInput, hideInput]] = useControlledState(initialShowInputState, [true, false])

  useEffect(() => {
    if (loading) return
    if (!MoneyCalc.isGTZero(ebtTotal) && discount?.coupon.ebtOnly) {
      // If the coupon is EBT only and we no longer have an ebtAmount then we should remove the coupon and notify the user
      removeDiscount()
      setPromoCode('')
      hideInput()
      Alert(
        'Discount Removed',
        'Your discount has been removed because you are no longer paying with an EBT payment method.',
      )
    }
  }, [ebtTotal, discount?.coupon.ebtOnly, removeDiscount, loading, hideInput])

  const onSubmitPromo = useDeepCompareCallback(async () => {
    if (!promoCode) return Toast('Promo code missing')
    try {
      await addPromo(promoCode)
    } catch (err) {
      const msg = errorToString(err)
      Logger.warn('Failed applying discount' + msg)
      Alert('Adding discount failed', msg)

      if (discount) {
        // If validation failed, and there is a previous discount then remove
        try {
          await removeDiscount()
          setPromoCode('')
        } catch (error) {
          Logger.error(
            `Could not remove a discount after validation failed. Promo: ${promoCode}. Existing coupon id: ${discount.coupon.id}`,
          )
        }
      }
    }
  }, [addPromo, promoCode, discount, removeDiscount])

  const setPromoCodeUppercase = useCallback(
    (text: string) => {
      setPromoCode(text.trim().toUpperCase())
    },
    [setPromoCode],
  )

  return (
    <View style={style}>
      {isShowingInput ? (
        <View style={styles.inputContainer}>
          <Input
            autoCorrect={false}
            autoCapitalize="characters"
            inputStyle={styles.inputStyle}
            inputContainerStyle={styles.borderBottom}
            containerStyle={globalStyles.flex1}
            placeholder="Apply promo code"
            onChangeText={setPromoCodeUppercase}
            renderErrorMessage={false}
            value={promoCode}
            disabled={loading}
          />
          <ButtonClear title="Apply" onPress={onSubmitPromo} disabled={loading} loading={loading} />
        </View>
      ) : (
        <ButtonClear
          title="+ Add Promo Code"
          size={12}
          style={styles.paddingLeft}
          onPress={showInput}
          disabled={loading}
        />
      )}

      <LoadingView loading={loading} style={styles.appliedCoupon}>
        {discount ? (
          <>
            <Text style={styles.marginRight}>Applied Discount {discount?.coupon.name}</Text>
            <Icon color={Colors.red} name="times" onPress={removeDiscount} />
          </>
        ) : null}
      </LoadingView>
    </View>
  )
},
propsAreDeepEqual)

const styles = StyleSheet.create({
  inputContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    borderColor: Colors.shades['100'],
    borderWidth: 1,
    borderRadius: 10,
    maxWidth: 300,
    minWidth: 280,
  },
  inputStyle: {
    marginTop: 2,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  borderBottom: {
    borderBottomWidth: 0,
  },
  paddingLeft: {
    paddingLeft: 0,
  },
  marginRight: {
    marginRight: 5,
  },
  appliedCoupon: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})
