import { loadCSAsByFarm } from '@api/CSAs'
import { loadLocationsByFarm } from '@api/Locations'
import { productsCollection } from '@api/framework/ClientCollections'
import { addonHasPotentialMatches } from '@helpers/addons'
import mergeDeep from '@helpers/mergeDeep'
import { shouldShow } from '@helpers/products'
import { sortByName, sortByProperty } from '@helpers/sorting'
import { keys, omit } from '@helpers/typescript'
import { CSA } from '@models/CSA'
import { Farm } from '@models/Farm'
import { Location } from '@models/Location'
import { AddonShare, Product, ProductType, isAddon, isPrimary } from '@models/Product'
import {
  DocumentSnapshot,
  and,
  getDocs,
  limit,
  or,
  orderBy,
  startAfter,
  where,
  type QueryCompositeFilterConstraint,
  type QueryNonFilterConstraint,
} from 'firebase/firestore'
import { DateTime } from 'luxon'
import { createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ApiFxState, initialApiFxState, useApiFx } from './useApiFx'
import { useFarmProp } from './useFarmProp'
import useKeyedState from './useKeyedState'
import { ProgLoadState, defaultProgressiveLoadingState, useProgressiveLoading } from './useProgressiveLoading'

import { FarmCachedData } from '@/constants/types/navProps'
import { updateFarmCache } from '@/redux/actions/appState'
import { useFarmDataFromCache, wholesaleSelector } from '@/redux/selectors'
import { matchesIdOrSlug } from '@helpers/urlSafeSlug'
import { DeepPartial } from 'redux'

type UseFarmDataOpts = { fetchLocs?: boolean; fetchCsas?: boolean; fetchProds?: boolean }

type UseFarmDataState = {
  farm: ApiFxState<() => Promise<Farm>>
  /** The relevant products of the current farm */
  prods: ProgLoadState<Product> & {
    /** The relevant addons of the current farm */
    addons?: AddonShare[]
  }
  /** The relevant csas of the current farm. These should be already filtered for non-hidden. */
  csas: ApiFxState<() => Promise<CSA[]>>
  /** Locations of a farm's products */
  locs: ApiFxState<() => Promise<Location[]>>
}

const initialFarmDataState: UseFarmDataState = {
  farm: initialApiFxState,
  prods: { ...defaultProgressiveLoadingState, addons: [] },
  csas: initialApiFxState,
  locs: initialApiFxState,
}

/** It should get an initial state based on the cache data */
const getInitialState = ({
  cachedFarm,
  cachedProds,
  cachedCsas,
  cachedLocs,
}: {
  cachedFarm?: Farm
  cachedProds?: Product[]
  cachedCsas?: CSA[]
  cachedLocs?: Location[]
}): UseFarmDataState => {
  const update = {} as DeepPartial<UseFarmDataState>

  /** Only replace the default with cache if the cache data isn't empty */
  if (cachedFarm) update['farm'] = { data: cachedFarm }
  if (cachedProds?.length) update['prods'] = { data: cachedProds }
  if (cachedCsas?.length) update['csas'] = { data: cachedCsas }
  if (cachedLocs?.length) update['locs'] = { data: cachedLocs }

  return mergeDeep(initialFarmDataState, update)
}

/** Fetches data from DB for a given farm, and sets it to a redux cache that can be re-used by various screens.
 * @param farmSlug could be a slug or id for the farm */
export function useFarmData(
  farmSlug?: string,
  { fetchCsas, fetchLocs, fetchProds }: UseFarmDataOpts = { fetchProds: true },
): UseFarmDataState {
  const { isWholesale } = useSelector(wholesaleSelector)
  const dispatch = useDispatch()

  /** Gets the farm data in the cache */
  const {
    farm: cachedFarm,
    prods: cachedProds,
    csas: cachedCsas,
    locs: cachedLocs,
    time: cachedTime,
    loadingCompleted: isCacheComplete,
  } = useFarmDataFromCache(farmSlug || '')

  const [state, , , , setPartial] = useKeyedState<UseFarmDataState>(
    getInitialState({ cachedFarm, cachedProds, cachedCsas, cachedLocs }),
    {
      /** Syncs the local state with the farm cache */
      onStateSet(state) {
        const update: Partial<FarmCachedData> = {}
        if (!farmSlug) return

        // Will try to assign the farm slug as the cache key, even if the farmSlug navigation parameter is possibly an id
        let cacheKey: string | undefined = ''

        if (keys(state.farm).includes('data') && matchesIdOrSlug(state.farm.data, farmSlug)) {
          update.farm = state.farm.data
          if (!cacheKey) cacheKey = state.farm.data?.urlSafeSlug
        }
        if (keys(state.prods).includes('data') && state.prods.data?.every((p) => matchesIdOrSlug(p.farm, farmSlug))) {
          update.prods = state.prods.data
          if (!cacheKey) cacheKey = state.prods.data?.[0]?.farm.urlSafeSlug
        }
        if (keys(state.csas).includes('data') && state.csas.data?.every((csa) => matchesIdOrSlug(csa.farm, farmSlug))) {
          update.csas = state.csas.data
          if (!cacheKey) cacheKey = state.csas.data?.[0]?.farm.urlSafeSlug
        }
        if (keys(state.locs).includes('data') && state.locs.data?.every((loc) => matchesIdOrSlug(loc.farm, farmSlug))) {
          update.locs = state.locs.data
          if (!cacheKey) cacheKey = state.locs.data?.[0]?.farm.urlSafeSlug
        }
        if (Object.values(state).every((val) => !val.loading)) update.loadingCompleted = true

        if (keys(update).length && cacheKey) {
          dispatch(updateFarmCache(cacheKey, update))
        }
      },
    },
  )

  const isCacheInvalid = !isCacheComplete || DateTime.now() > cachedTime.plus({ hours: 1 })

  /** Load farm */
  useFarmProp({
    farmSlug,
    onStateSet: (state) => {
      setPartial({ farm: { ...state } })
    },
  })

  /** Load products */
  useProgressiveLoading<Product, DocumentSnapshot | null>({
    getPageData: async (cursor, pageLength) => {
      const params: [QueryCompositeFilterConstraint, ...QueryNonFilterConstraint[]] = [
        and(
          or(where('farm.urlSafeSlug', '==', farmSlug), where('farm.id', '==', farmSlug)),
          where('isHidden', '==', false),
        ),
        limit(pageLength),
        orderBy('category'),
      ]
      if (cursor) params.push(startAfter(cursor))
      // We are using query here because we need to expose the DocumentSnapshot to pass as the cursor
      const q = productsCollection.query(...params)
      const docs = (await getDocs(q)).docs.filter((doc) => doc.exists())
      const data = docs.map((doc) => productsCollection.unmarshal(doc))
      const lastCursor = docs[docs.length - 1] ?? null
      return { data, lastCursor }
    },
    pageLength: 20,
    targetLength: 250,
    runCondition: !!farmSlug && !state.prods.err && fetchProds,
    filter: (prod) => shouldShow(prod, { isWholesale }),
    transform: (prods) =>
      prods
        .sort(sortByName)
        .sort(sortByProperty([ProductType.PrimaryShare, ProductType.AddonShare], ({ type }) => type)),
    /** Initialize the progressive loading state with the products state */
    initialState: omit(state.prods, 'addons'),
    onStateSet: (newState) => {
      let addons: AddonShare[] = []
      if (newState.data?.length) {
        const primaries = newState.data.filter(isPrimary)
        addons = newState.data.filter(isAddon).filter((p) => addonHasPotentialMatches(p, primaries))
      }
      setPartial({ prods: { ...newState, addons } })
    },
    depsOnState: [setPartial],
    getFromCache: () => {
      if (!farmSlug) return undefined
      const isValid =
        cachedProds?.length && cachedProds.every((p) => matchesIdOrSlug(p.farm, farmSlug)) && !isCacheInvalid
      return isValid ? cachedProds : undefined
    },
    deps: [farmSlug, isWholesale],
    failedConditionMode: fetchProds ? 'keep-loading' : 'stop-loading',
    noRefocus: false, // We do want it to run on refocus, and that's when it'll use the getFromCache
  })

  /** Get the active csas for the farm */
  useApiFx(loadCSAsByFarm, [farmSlug], !!farmSlug && !state.csas.err && fetchCsas, {
    initialState: { data: cachedCsas },
    onStateSet: (state) => {
      setPartial({ csas: { ...state } })
    },
    getFromCache: () => {
      if (!farmSlug) return
      if (cachedCsas?.length && cachedCsas.every(({ farm }) => matchesIdOrSlug(farm, farmSlug)) && !isCacheInvalid) {
        return cachedCsas
      }
      return undefined
    },
    transform: (csas) => csas.filter((csa) => !csa.isHidden),
    failedConditionMode: fetchCsas ? 'keep-loading' : 'stop-loading',
  })

  /** Get the locations that include products from the current farm */
  useApiFx(loadLocationsByFarm, [farmSlug], !!farmSlug && !state.locs.err && fetchLocs, {
    initialState: { data: cachedLocs },
    onStateSet: (state) => {
      // every time this state changes inside useApiFx, we sync it to the outer state
      setPartial({ locs: { ...state } })
    },
    getFromCache: () => {
      if (!farmSlug) return

      if (cachedLocs?.length && cachedLocs.every(({ farm }) => matchesIdOrSlug(farm, farmSlug)) && !isCacheInvalid)
        return cachedLocs
    },
    failedConditionMode: fetchLocs ? 'keep-loading' : 'stop-loading',
  })

  return state
}

export const FarmDataContext = createContext<UseFarmDataState>(initialFarmDataState)
