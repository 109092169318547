import { loadFarmBaseUnits } from '@api/BaseUnits'
import { ToolTips } from '@components'
import {
  AddNewDropdownItem,
  ButtonClear,
  CheckBox,
  FormDisplayRow,
  FormInput,
  FormMoneyInput,
  FormPickerInput,
} from '@elements'
import { PartialPick } from '@helpers/typescript'
import { YUP_MONEY_OPTIONAL, YUP_MONEY_REQUIRED, YUP_WHOLE_NUMBER_REAL } from '@helpers/Yup'
import { Money } from '@models/Money'
import {
  GlobalStockProduct,
  hasUnits,
  isDigital,
  isStandard,
  Product,
  ProductType,
  Standard,
  Unit,
  UnitProduct,
} from '@models/Product'
import { useFormikContext } from 'formik'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { ProductSchemaContext } from '@helpers/builders/buildProduct'
import InputLabel from '../../../components/InputLabel'
import FormSectionHeader from '../components/FormSectionHeader'
import { createUnitOrSetValue } from './helpers/baseUnitAdd.helper'
import { ProductFormikComponent } from './helpers/ProductFormikComponent'

import { useApiFx } from '@/hooks/useApiFx'
import { adminFarmSelector } from '@/redux/selectors'
import DecimalCalc from '@helpers/decimal'
import { View } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'
import { ProductTypeForm } from './helpers/ProductTypeInfo'

export type UnitsForm = {
  baseUnit?: UnitProduct['baseUnit']
  costPerUnit?: Standard['costOfProduction']
  showCostPerUnit?: boolean
  pricePerUnit?: UnitProduct['pricePerUnit']
  /** This global quantity is only here to serve nonShare products. The share global quantity is handled by the SharePricing form component */
  globalQuantity?: GlobalStockProduct['quantity']
  unitStock: UnitProduct['unitStock']
}

const unitSchema: Yup.ObjectSchema<UnitsForm, ProductSchemaContext> = Yup.object<ProductSchemaContext>().shape({
  baseUnit: Yup.string().when('type', {
    is: (type: ProductType) => hasUnits({ type }),
    then: (schema) => schema.required('Unit is required'),
  }),
  costPerUnit: YUP_MONEY_OPTIONAL('Cost per unit', { allowZero: true }),
  pricePerUnit: Yup.mixed<Money>().when('$unitStock', {
    is: true,
    then: () => YUP_MONEY_REQUIRED('Price per unit'),
  }),
  globalQuantity: Yup.number().when(['$unitStock', 'type'], {
    is: ($unitStock: boolean, type: ProductType) => !$unitStock && hasUnits({ type }),
    then: () => YUP_WHOLE_NUMBER_REAL('Quantity', { allowDecimal: true, allowZero: true }),
  }),
  unitStock: Yup.boolean().required(),
  showCostPerUnit: Yup.boolean().optional(),
})

const toFormik = (product: PartialPick<Product, 'type'>): UnitsForm => {
  // Editing product since it has units
  if (!hasUnits(product)) {
    // This case should never happen, this component shouldn't render if the product type has no units
    return {
      baseUnit: '',
      costPerUnit: undefined,
      showCostPerUnit: false,
      pricePerUnit: undefined,
      globalQuantity: undefined,
      unitStock: false,
    }
  }

  return {
    baseUnit: product.baseUnit ?? '',
    costPerUnit: isStandard(product) ? product.costOfProduction ?? undefined : undefined,
    pricePerUnit: product.pricePerUnit ?? undefined,
    globalQuantity: product.quantity, // OK for this to be optional, in case of unit stock.
    unitStock: product.unitStock ?? false,
  }
}

function fromFormik(formValues: UnitsForm & ProductTypeForm): Partial<Product> {
  const base: PartialPick<Product, 'type'> = {
    type: formValues.type,
  }

  // If this is not an advanced pricing product then don't return anything
  if (!hasUnits(base)) return {}

  if (isStandard(base)) {
    base.costOfProduction = formValues.costPerUnit
  }

  base.baseUnit = formValues.baseUnit! // schema should've enforced it as required for unit products
  base.unitStock = formValues.unitStock
  base.pricePerUnit = !formValues.unitStock ? undefined : formValues.pricePerUnit
  base.quantity = !formValues.unitStock ? Number(formValues.globalQuantity ?? 0) : undefined

  return base
}

export const FormikUnits = new ProductFormikComponent(unitSchema, toFormik, fromFormik)

/** Displays form fields related to units on the form */
export function UnitsComponent() {
  const farm = useSelector(adminFarmSelector)
  const baseUnits = useApiFx(loadFarmBaseUnits, [farm.localBaseUnits], !!farm)
  const styles = useStyles()

  const {
    setFieldValue,
    values: formValues,
    handleBlur,
    errors,
    touched,
    setTouched,
    setValues,
    setFieldTouched,
  } = useFormikContext<
    UnitsForm & {
      type: ProductType
      /** form' `buyingOptions` is populated primarily in AdvancedPricing component but we also access it here */
      buyingOptions: Unit[]
    }
  >()
  const type = formValues.type

  /** 'Manage Stock Globally' checkbox onChange handler */
  const onChangeGlobalStock = useCallback(async () => {
    // first we update the global quantity in this if/else
    if (formValues.unitStock) {
      // If we're switching from unit-stock to global-stock, the new global stock will be the sum of unit-stock quantities
      await setFieldValue(
        'globalQuantity',
        formValues.buyingOptions.reduce(
          (agg, curr) => DecimalCalc.add(agg, DecimalCalc.multiply(curr.quantity ?? 0, curr.multiplier ?? 1)),
          0,
        ),
      )
    } else {
      // Set globalQuantity to undefined when switching from global-stock to unit-stock. Note: using setFieldValue to set undefined value will let formik.values to delete the key (like it never existed), so setValues is used instead.
      await setValues({ ...formValues, globalQuantity: undefined })
    }

    // toggle the unitStock value
    await setFieldValue('unitStock', !formValues.unitStock)
    //should manually set touched after setFieldValue happens to trigger validation again
    setFieldTouched('globalQuantity', true)
  }, [formValues, setFieldTouched, setFieldValue, setValues])

  // unitBase onchange handler
  const unitBaseOnChange = useCallback(
    (value: string) => {
      createUnitOrSetValue(value, 'baseUnit', farm.id, (v) =>
        setFieldValue('baseUnit', v).then(() => setTouched({ ...touched, baseUnit: true })),
      )
    },
    [farm.id, setFieldValue, setTouched, touched],
  )

  const units = useMemo(() => {
    if (!baseUnits.data) return []

    return [...baseUnits.data]
      .sort()
      .map((baseUnit) => ({ label: baseUnit, value: baseUnit }))
      .concat([AddNewDropdownItem])
  }, [baseUnits.data])

  return (
    <View style={styles.formContainer}>
      <FormSectionHeader title="Units" />
      <CheckBox
        title="Manage Stock Globally"
        disabled={isDigital(formValues)}
        onChange={onChangeGlobalStock}
        checked={!formValues.unitStock}
        style={styles.paddingLeft10}
        toolTipId={ToolTips.BASE_UNIT_GLOBAL}
        toolTipTitle="Global Inventory"
      />
      <FormDisplayRow ignoreSmall>
        <FormPickerInput
          loading={baseUnits.loading}
          label={<InputLabel label="Unit" tooltipId={ToolTips.BASE_UNIT} required />}
          items={units}
          onValueChange={unitBaseOnChange}
          value={formValues.baseUnit}
          errorMessage={touched.baseUnit ? errors.baseUnit : ''}
        />
        {formValues.unitStock ? (
          <FormMoneyInput
            value={formValues.pricePerUnit}
            label={<InputLabel label="Price Per Unit" tooltipId={ToolTips.PRICE_UNIT} required />}
            onChangeText={async (value) => {
              await setFieldValue('pricePerUnit', value)
              setFieldTouched('pricePerUnit')
            }}
            onBlur={handleBlur('pricePerUnit')}
            errorMessage={touched.pricePerUnit ? errors.pricePerUnit : undefined}
          />
        ) : (
          <FormInput
            value={formValues.globalQuantity?.toString()}
            placeholder="0"
            label={
              <InputLabel label="In Stock" tooltipId={ToolTips.IN_STOCK_GLOBAL} tooltipTitle="Global Stock" required />
            }
            onChangeText={(value: string) => setFieldValue('globalQuantity', value)}
            onBlur={handleBlur('globalQuantity')}
            errorMessage={touched.globalQuantity ? errors.globalQuantity : undefined}
          />
        )}
        {isStandard({ type }) &&
          (formValues.showCostPerUnit ? (
            <FormMoneyInput
              row
              value={formValues.costPerUnit}
              label={<InputLabel label="Cost Per Unit" tooltipId={ToolTips.COST_PER_UNIT} />}
              onChangeText={(value) => setFieldValue('costPerUnit', value)}
              errorMessage={errors.costPerUnit as string}
            />
          ) : (
            <ButtonClear
              style={styles.addCostPerUnitBtn}
              title="+ Add Cost Per Unit"
              onPress={() => setFieldValue('showCostPerUnit', true)}
            />
          ))}
      </FormDisplayRow>
    </View>
  )
}

const useStyles = CreateResponsiveStyle(
  {
    formContainer: {
      paddingHorizontal: 25,
      paddingBottom: 40,
    },
    inputLabel: {
      marginTop: 2.2,
      marginBottom: 6,
    },
    picker: {
      minWidth: 299,
      height: 45,
    },
    inputMaxWidth: {
      maxWidth: 300,
    },
    paddingLeft10: {
      paddingLeft: 10,
    },
    addCostPerUnitBtn: {
      alignSelf: 'center',
      // We add a margin here because the other inputs have labels, so this positions the button about center
      marginTop: 20,
    },
  },
  {
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      picker: {
        marginBottom: 35,
        padding: 5,
      },
      formContainer: {
        paddingHorizontal: 0,
      },
    },
  },
)
