/* eslint-disable no-restricted-imports */
import { useEffect, useState } from 'react'
import { useWindowDimensions } from 'react-native'
import { useDispatch } from 'react-redux'

import { getCustomInsets, setDeviceSize } from '@/constants/Layout'
import { setLayout } from '@/redux/actions/appState'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

/** Initializes the layoutSelector service, along with insets */
export const useSetLayout = () => {
  // loading only needs to be true on the first app render, so no need to setLoading(true) on subsequent layout changes
  const [loading, setLoading] = useState(true)
  const layout = useWindowDimensions()
  const insets = useSafeAreaInsets()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setLayout({ ...layout, ...getCustomInsets(insets) }))
    setDeviceSize(layout)
    setLoading(false)
  }, [layout, insets, dispatch])

  return loading
}
