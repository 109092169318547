import { HeaderText } from '@elements'
import { View, StyleSheet } from 'react-native'

import Colors from '@/constants/Colors'

type Props = {
  progress?: number
  height?: number
  showValue?: boolean
}

export default function ProgressBar({ progress = 0.05, height, showValue }: Props) {
  return (
    <View style={styles.container}>
      <View style={[styles.wrapper, { height: height || 15 }]}>
        <View style={[styles.track, { width: `${Math.round(progress * 100)}%` }]} />
      </View>
      {showValue && Math.round(progress * 100) < 100 ? (
        <HeaderText style={{ marginLeft: 10, width: 45 }} color={Colors.purple}>{`${Math.round(
          progress * 100,
        )}%`}</HeaderText>
      ) : (
        <HeaderText style={{ marginLeft: 10, width: 60 }} color={Colors.purple}>
          100%
        </HeaderText>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  wrapper: {
    flex: 1,
    backgroundColor: Colors.shades['100'],
    borderRadius: 10,
    overflow: 'hidden',
  },
  track: {
    flex: 1,
    backgroundColor: Colors.purple,
  },
})
