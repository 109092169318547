import { getShortState } from '@/assets/data/states'
import { Address } from '@models/Address'
import { SimpleCart } from '@models/Cart'
import { Invoice, InvoiceItem, invoiceItemTotal, isProductFee, PaymentSources } from '@models/Invoice'
import { Money, Zero } from '@models/Money'
import { CartItem, isCartDigital } from '@models/Order'
import { isShare, Product } from '@models/Product'
import {
  FeeType,
  isFixedProductFee,
  isPercentProductFee,
  NoneValue,
  ProductFee,
  ProductFeesForInvoice,
} from '@models/ProductFee'
import { addItemToInvoice } from '../services/InvoiceService'
import DecimalCalc from './decimal'
import { formatMoney } from './display'
import { deepClone, isNonNullish } from './helpers'
import { createProductFeeId } from './invoice'
import { MoneyCalc } from './money'
import { ArrElement, PartialPick, pick } from './typescript'

/** Formats the product fee rate for number or Money */
export function formatProductFeeRate(productFee: Pick<ProductFee, 'valueType'>): string {
  if (isPercentProductFee(productFee)) {
    return `${DecimalCalc.multiply(productFee.value, 100)}%`
  } else if (isFixedProductFee(productFee)) {
    return formatMoney(productFee.value)
  } else {
    throw new Error('Invalid Product Fee rate')
  }
}

/**
 * This helper function returns the tax amount for an invoice item in an invoice
 * @param productFeeFromCart The productFeeFromCart object that has the productFee object and the products to which it should be applied
 * @param invItem the invoice item to compute the tax amount for
 */
function getTaxForInvoiceItem(productFeeFromCart: ProductFeeFromCart, invItem: InvoiceItem): Money {
  const product = productFeeFromCart.items.find((itm) => itm.product.id === invItem.product?.id)
  if (!product) return Zero
  if (!isPercentProductFee(productFeeFromCart.productFee))
    throw new Error('Fixed value type is not supported for taxes')
  return MoneyCalc.multiply(invoiceItemTotal(invItem), productFeeFromCart.productFee.value)
}

/**
 * This helper function returns the fee amount for an invoice item in an invoice
 * @param productFeeFromCart The productFeeFromCart object that has the productFee object and the products to which it should be applied
 * @param invItem the invoice item to compute the fee amount for
 * @param isFirstInvoice This is true when the invoice is the first invoice for the order. This only has an affect on fees
 */
function getFeeForInvoiceItem(
  productFeeFromCart: ProductFeeFromCart,
  invItem: InvoiceItem,
  isFirstInvoice: boolean,
): Money {
  const product = productFeeFromCart.items.find((itm) => itm.product.id === invItem.product?.id)
  if (!product) return Zero
  if (isPercentProductFee(productFeeFromCart.productFee)) {
    return MoneyCalc.multiply(invoiceItemTotal(invItem), productFeeFromCart.productFee.value)
  } else if (isFixedProductFee(productFeeFromCart.productFee)) {
    // If it is a share, it should not charge the flat fee on each invoice, only the first. This is because a share can have
    // multiple invoices for the same quantity (weekly payments for one share), whereas standard has at most one invoice per
    // quantity (pay-per-pickup)
    if (!isFirstInvoice && isShare(product.product)) return Zero
    // If it is a share and a first invoice with deposit, the flat fee should be charged on unFront deposit invoiceItem, so the installment invoiceItem should not charge flat fee again.
    if (
      isFirstInvoice &&
      isShare(product.product) &&
      !invItem.appliedProductFees?.find((productFee) => productFee.id === productFeeFromCart.productFee.id)
    )
      return Zero
    return MoneyCalc.multiply(productFeeFromCart.productFee.value, invItem.quantity)
  } else {
    throw new Error('Invalid fee value type')
  }
}

/**
 * This helper returns the amount of a certain productFee given an invoice
 *
 * @param invoice The invoice to calculate taxes or fees for
 * @param productFeeFromCart The productFeeFromCart has the productFee object and the products to which it should be applied
 * @param opts This holds options like isFirstInvoice which is true when the invoice is the first invoice for the order. This only has an affect on fees
 */
export function getProductFeeTotalForInvoice(
  invoice: PartialPick<Invoice, 'items'>,
  productFeeFromCart: ProductFeeFromCart,
  opts: { isFirstInvoice: boolean },
): Money {
  const feeType = productFeeFromCart.productFee.type
  if (feeType === FeeType.Tax) {
    return invoice.items
      .map((itm) => getTaxForInvoiceItem(productFeeFromCart, itm))
      .reduce((acc, val) => MoneyCalc.add(acc, val), Zero)
  } else if (feeType === FeeType.Fee) {
    return invoice.items
      .map((itm) => getFeeForInvoiceItem(productFeeFromCart, itm, opts.isFirstInvoice))
      .reduce((acc, val) => MoneyCalc.add(acc, val), Zero)
  } else {
    throw new Error(`Invalid fee type, expected either tax or fee and found ${feeType}`)
  }
}

/**
 * This helper returns all productFees that should be applied to an invoice along with the amount for each productFee.
 *
 * @param invoice The invoice to calculate taxes or fees for
 * @param productFeeFromCartMap The productFeeFromCartMap has the productFee object and the products to which it should be applied in a Map format with the productFee.id as the key
 * @param opts This holds options like isFirstInvoice which is true when the invoice is the first invoice for the order. This only has an effect on fees
 */
export function getAllProductFeesForInvoice(
  invoice: PartialPick<Invoice, 'items'>,
  productFeeFromCartMap: ProductFeesFromCartMap,
  opts: { isFirstInvoice: boolean },
): ProductFeesForInvoice {
  const allProductFees: ProductFeesForInvoice = []
  productFeeFromCartMap.forEach((prodFee) => {
    const total = getProductFeeTotalForInvoice(invoice, prodFee, opts)
    if (MoneyCalc.isGTZero(total)) {
      allProductFees.push({ productFee: prodFee.productFee, amount: total })
    }
  })

  return allProductFees
}

/** This helper checks if the regions of the productFee matches the state or zipcode of an address. If the productFee has no regions, then it should seen as passing match and return true.
 *
 * @param productFee The product fee to check if it matches the location
 * @param address Access state or zipcode in an address to check if it matches the product fee regions
 */
export function isProductFeeMatchingRegion(productFee: ProductFee, address: Address): boolean {
  // If the productFee has no regions or regionType is none, then it should return true because when there is no need to match regions, then it should be considered as 'pass'.
  if (productFee.regions.length === 0 || productFee.regionType === NoneValue) {
    return true
  }

  const isZipcodeMatched = productFee.regions.findIndex((region) => region === address.zipcode) !== -1

  /** We allow to setup full name of state to be set as a location address, so we have to make sure we change address.state as short state before comparison. */
  const isStateMatched = productFee.regions.findIndex((region) => region === getShortState(address.state)) !== -1

  return isZipcodeMatched || isStateMatched
}

/**
 * Represents product fees extracted from a cart.
 * Each element contains details of a product fee and the associated cart items.
 */
type ProductFeeFromCart = {
  productFee: ProductFee
  items: { product: Pick<Product, 'id' | 'type'> }[]
}
export type ProductFeesFromCartMap = Map<ProductFee['id'], ProductFeeFromCart>

/**
 * This helper function returns all product fees (taxes and fees) along with the products in the cart to which they should be applied, in a Map format.
 *
 * It follows these rules:
 *
 * 1. If product.taxesAndFees.isTaxExempt is true, the product should apply fees only.
 * 2. All type products should apply product fees (taxes and fees) that have no regions specified or regionType is none.
 * 3. If product fees have regions, then regions from them should match the location address (state or zipcode) before applying.
 * - Physical products should use schedule location address.
 * - Digital products should use farm address
 */
export function getProductFeesFromCart({ items }: PartialPick<SimpleCart, 'items'>): ProductFeesFromCartMap {
  const productFeesMap: ProductFeesFromCartMap = new Map()
  /** Deep clone the items to avoid mutation */
  const localItems = deepClone(items)

  for (const item of localItems) {
    const productId = item.product.id
    const address = isCartDigital(item) ? item.product.farm.address : item.distribution?.location?.address
    let productFees = item.product.taxesAndFees.fees

    /** location address should be defined for all physical products and farm address should be defined for all digital products, so it can be used to match and find the available productFee. */
    if (!isNonNullish(address)) throw new Error('Location address or farm address is not defined')

    /** If no productFees for current product, then continue */
    if (!isNonNullish(productFees)) continue

    /** If current product is tax-exempt, it should only include fees and not taxes */
    if (item.product.taxesAndFees.isTaxExempt) {
      productFees = productFees.filter((fee) => fee.type === FeeType.Fee)
    }

    /** After all filters, if productFees has no values, then skip here */
    if (productFees.length === 0) continue

    for (const productFee of productFees) {
      /** Set fee.id to the Map if it doesn't exist. */
      if (!productFeesMap.has(productFee.id)) {
        productFeesMap.set(productFee.id, { productFee, items: [] })
      }

      /** If at least one productFee inside productFeesMap has already include this productId, then we should have already added to this product to all productFees that this product should apply, and this must be a secondary same product in the cart, so we can ignore it. */
      if (productFeesMap.get(productFee.id)?.items.find((itm) => itm.product.id === productId)) break

      // Check the productFee regions and match (zip code or state) with the location address
      if (isProductFeeMatchingRegion(productFee, address)) {
        const curProductFeeFromMap = productFeesMap.get(productFee.id)
        /** The below if else statement should extra guarantee that we add the product to the Map. */
        if (curProductFeeFromMap) {
          curProductFeeFromMap.items.push({ product: pick(item.product, 'id', 'type') })
        } else {
          productFeesMap.set(productFee.id, { productFee, items: [{ product: pick(item.product, 'id', 'type') }] })
        }
      }
    }
  }

  return productFeesMap
}

/** This function returns an array of product fees that were applied to the cart item. */
export function getProductFeesForCartProduct(productFees: ProductFeesFromCartMap, productId: Product['id']) {
  const feeArray = Array.from(productFees.values())

  const feesForProduct = feeArray.filter((fee) => fee.items.some((itm) => itm.product.id === productId))
  return feesForProduct.map((fee) => fee.productFee)
}

/**
 * This function will take an invoices and a cart and compute all fees then add them and return the new invoices.
 * @param invoices The list of invoices to add the fees onto
 * @param cartItems The list of cartItems to compute fees from
 * @param paymentSource The payment source that should be added to the fees
 */
export function addProductFeesToInvoices(
  invoices: Invoice[],
  cartItems: CartItem[],
  paymentSource: PaymentSources,
): Invoice[] {
  const productFeesForCart = getProductFeesFromCart({ items: cartItems })
  return invoices.map((inv, idx) => {
    const productFeesForInvoice = getAllProductFeesForInvoice(inv, productFeesForCart, {
      isFirstInvoice: idx === 0,
    })
    // We don't need to deep clone this invoice as the helpers here do not mutate invoice, they create a new invoice
    let newInv = inv

    // Loop through all product fees and add each product fee to the invoice as a separate line item
    productFeesForInvoice.forEach((productFee) => {
      newInv = addProductFeeToInvoice(newInv, productFee, paymentSource)
    })

    return newInv
  })
}

/**
 * This function will add a single product fee to an invoice. It should add it as a new invoice item
 * @param invoice The invoice to add the fee onto
 * @param productFee The product fee to be added to the invoice
 * @param feeTotal The total amount for the fee across all invoice items
 * @param paymentSource The payment source that should be added to the fees
 */
function addProductFeeToInvoice(
  invoice: Invoice,
  { productFee, amount: feeTotal }: ArrElement<ProductFeesForInvoice>,
  paymentSource: PaymentSources,
) {
  // We will show the percent for percent fees but not show the fixed value for fixed fees
  const feeDesc = productFee.name + (isPercentProductFee(productFee) ? ` (${formatProductFeeRate(productFee)})` : '')

  const feeItem: InvoiceItem = {
    id: createProductFeeId(productFee),
    description: feeDesc,
    quantity: 1,
    payments: [
      {
        source: paymentSource,
        amount: feeTotal,
      },
    ],
  }
  return addItemToInvoice(invoice, feeItem)
}

/** This function extract productFee items from an invoice
 * @param invoice The invoice to extract productFee items from
 * @param type The type of productFee to extract from the invoice, if not provided, it will extract all productFee items
 */
export function getProductFeesFromInvoice(
  invoice: PartialPick<Invoice, 'items'>,
  type?: ProductFee['type'],
): InvoiceItem[] {
  if (type === FeeType.Fee) {
    return invoice.items.filter((item) => item.id.startsWith('fee_'))
  } else if (type === FeeType.Tax) {
    return invoice.items.filter((item) => item.id.startsWith('tax_'))
  } else {
    return invoice.items.filter((item) => isProductFee(item.id))
  }
}
