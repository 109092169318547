import { Text } from '@elements'
import { Order, Pickup } from '@models/Order'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { RefObject, useCallback, useEffect, useState } from 'react'
import { LayoutRectangle, ScrollView } from 'react-native'

import { AdminCustomerHeader } from './components/Header'
import {
  filterOrders,
  filterOrdersByTime,
  ordersSelection,
  timeRangeSelection,
  useOnOrderOptionChange,
  useOnTimeRangeChange,
} from './helper/dropdownDataAndFilterFunc'

import { AdminCard } from '@/admin/components/AdminCard'
import { OfflineTable } from '@/admin/components/OfflineTable/OfflineTable'
import { ShareRowProp } from '@/admin/components/SubscriptionAccordion'
import { AdminDrawerParamList, CustomerParamList } from '@/admin/navigation/types'
import Colors from '@/constants/Colors'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useHasPermissionWithFlag } from '@/hooks/useHasPermission'
import { useDeviceSize } from '@/hooks/useLayout'
import { MessageWithIcon } from '@components'
import { AccessRight, Permission } from '@helpers/Permission'
import { ListItem } from 'react-native-elements'
import { useGenerateSubRowsOrderItems } from './components/SubRowsOrderItems'
import { columnsElementsGen } from './helper/columnsElementsGen'

type Props = {
  customerId: string
  /** All pickups for user and farm */
  pickups: Pickup[]
  /** All orders for user and farm. Map of key: order.id, and vale: Order */
  ordersMap: Map<string, Order>
  shareRowProps: ShareRowProp[]
  /** Reloads orders and pickups. Should call after a reschedule */
  reload: () => void
  scrollRef: RefObject<ScrollView>
  subscriptionsLayout: LayoutRectangle | undefined
}

export const defaultOrderOptionSelection = 'All Orders'
export const defaultTimeRangeOptionSelection = 'All Time'

/** The Orders Section Component inside AdminCustomerDetail Screen */
export function AdminCustomerOrdersSection({
  customerId,
  pickups,
  ordersMap,
  shareRowProps,
  reload,
  scrollRef,
  subscriptionsLayout,
}: Props) {
  const { isExtraSmallDevice } = useDeviceSize()
  const [orders, setOrders] = useState<Order[]>([])
  const [orderOptionsSelected, setOrderOptionsSelected] = useState<string>(defaultOrderOptionSelection)
  const [timeRangeSelected, setTimeRangeSelected] = useState<string>(defaultTimeRangeOptionSelection)
  const [displayOrders, setDisplayOrders] = useState<Order[]>([])
  //limit is used to limit the number of orders displayed at a time
  const [limit, setLimit] = useState<number>(10)
  const [openSection, setOpenSection] = useState<boolean>(false)
  const navigation = useNavigation<StackNavigationProp<CustomerParamList & AdminDrawerParamList>>()
  const hasAccessEditOrder = useHasPermissionWithFlag(Permission.Orders, AccessRight.Edit)

  const styles = useStyles()

  const onPressListItemAccordion = useCallback(() => {
    setOpenSection((value) => !value)
    // Reset limit
    setLimit(10)
  }, [])

  /** To handle when oder picker option get changed */
  const onOrderOptionChange = useOnOrderOptionChange(
    orders,
    pickups,
    setOrderOptionsSelected,
    setDisplayOrders,
    timeRangeSelected,
  )

  /** To handle when time picker option get changed */
  const onTimeRangeChange = useOnTimeRangeChange(
    orders,
    pickups,
    setTimeRangeSelected,
    setDisplayOrders,
    orderOptionsSelected,
  )

  /** Callback to get the rendered orders in the offline table */
  const generateSubRows = useGenerateSubRowsOrderItems({
    pickups,
    shareRowProps,
    reload,
    scrollRef,
    subscriptionsLayout,
  })

  useEffect(() => {
    const ordersArray: Order[] = []
    ordersMap.forEach((value) => {
      ordersArray.push(value)
    })
    setOrders(ordersArray)
    //do this to get the correct displayOrder every time.
    const orderFilterBySelection = filterOrders(ordersArray, orderOptionsSelected, pickups)
    const orderFilterByTime = filterOrdersByTime(orderFilterBySelection, timeRangeSelected)
    setDisplayOrders(orderFilterByTime)
  }, [ordersMap, pickups, orderOptionsSelected, timeRangeSelected])

  const handleLoadMore = useCallback(() => {
    setLimit((prev) => prev + 10)
  }, [])

  return (
    <AdminCard style={styles.adminCardContainer}>
      <ListItem.Accordion
        isExpanded={openSection}
        onPress={onPressListItemAccordion}
        containerStyle={styles.listItemContainer}
        pad={isExtraSmallDevice ? 0 : 10}
        content={
          <AdminCustomerHeader
            title={`Orders (${ordersMap.size})`}
            containerStyle={styles.headerContainer}
            btns={
              hasAccessEditOrder && openSection
                ? [
                    {
                      title: 'New order',
                      onPress: () => {
                        navigation.navigate('Orders', {
                          screen: 'OrderCreator',
                          params: {
                            custId: customerId,
                            orderType: 'standard',
                            goBack: 'customerDetails',
                          },
                        })
                      },
                    },
                  ]
                : []
            }
          />
        }
      >
        <OfflineTable<Order>
          minWidth={!ordersMap.size ? undefined : 1180}
          data={displayOrders.slice(0, limit)}
          containerStyle={styles.offlineTableContainer}
          headerProps={{
            containerStyle: { backgroundColor: Colors.transparent, borderBottomWidth: 0 },
            textProps: { type: 'bold' },
          }}
          headerColumns={[
            { title: 'Total', widthFlex: 1.5 },
            { title: 'Order #', widthFlex: 1.5 },
            { title: 'Date placed', widthFlex: 2.4 },
            { title: '# of items', widthFlex: 2 },
            { title: 'Pickup Location', widthFlex: 2.1 },
            { title: 'Status', widthFlex: 2 },
            { title: 'Action', widthFlex: 1.4 },
          ]}
          columns={columnsElementsGen(navigation)}
          generateSubRows={generateSubRows}
          filters={[
            {
              value: orderOptionsSelected,
              items: ordersSelection,
              placeholder: defaultOrderOptionSelection,
              onValueChange: onOrderOptionChange,
            },
            {
              value: timeRangeSelected,
              items: timeRangeSelection,
              placeholder: defaultTimeRangeOptionSelection,
              onValueChange: onTimeRangeChange,
            },
          ]}
          ListEmptyComponent={
            <MessageWithIcon icon="receipt" title="No Orders">
              <Text>This customer does not have any orders. You can create one for them with the link above.</Text>
            </MessageWithIcon>
          }
        />
        {displayOrders.length > limit && (
          <Text style={styles.showMore} type="bold" size={16} color={Colors.green} onPress={handleLoadMore}>
            Show More
          </Text>
        )}
      </ListItem.Accordion>
    </AdminCard>
  )
}

const useStyles = () =>
  useSizeFnStyles(({ isExtraSmallDevice, isSmallDevice }) => ({
    offlineTableContainer: {
      borderWidth: 0,
      backgroundColor: Colors.transparent,
      marginTop: 10,
    },
    showMore: {
      alignSelf: 'center',
      marginTop: 10,
    },
    adminCardContainer: {
      paddingVertical: isSmallDevice ? 10 : 20,
      paddingHorizontal: isExtraSmallDevice ? 10 : 20,
      marginTop: isSmallDevice ? 10 : 20,
    },
    headerContainer: {
      flex: 1,
      marginBottom: 0,
    },
    listItemContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 0,
    },
  }))
