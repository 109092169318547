import { Platform, View, Text } from 'react-native'
import { useSelector } from 'react-redux'

import NoData from './NoData'
import Victory from './victory'
import Colors from '../../../constants/Colors'
import { layoutSelector } from '../../../redux/selectors'

const VictoryTooltip = Victory.VictoryTooltip
const VictoryLabel = Victory.VictoryLabel

const VictoryPie = Victory.VictoryPie
interface Props {
  data: any[]
  title: string
  showTooltip?: boolean
}
function PieGraph({ data, title, showTooltip = false }: Props) {
  const layout = useSelector(layoutSelector)
  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20,
      }}
    >
      <View
        style={{
          width: Platform.OS === 'web' && layout.width >= 896 ? layout.width / 2.5 : layout.width,
        }}
      >
        <Text style={{ fontSize: 24, fontWeight: 'bold', textAlign: 'center' }}>{title}</Text>
        {data.length > 0 ? (
          showTooltip ? (
            <VictoryPie
              data={data}
              labelComponent={<VictoryTooltip text={({ datum }) => `${datum.x} - ${datum.y}`} />}
              colorScale={[Colors.green, Colors.gold, Colors.gold, Colors.yellow, Colors.blue, 'navy']}
            />
          ) : (
            <VictoryPie
              style={{
                parent: {
                  overflow: 'visible',
                },
              }}
              labelComponent={<VictoryLabel renderInPortal />}
              data={data}
              colorScale={[Colors.green, Colors.gold, Colors.gold, Colors.yellow, Colors.blue, 'navy']}
            />
          )
        ) : (
          <NoData />
        )}
      </View>
    </View>
  )
}
export default PieGraph
