import { Button, ButtonClear, DateTimePicker, LoadingView, Picker, Text, TextH1 } from '@elements'
import { StackScreenProps } from '@react-navigation/stack'
import React, { useCallback } from 'react'
import { FlatList, ScrollView, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { DistributionSummaryParamList } from '@/admin/navigation/types'
import { Logger } from '@/config/logger'
import Colors from '@/constants/Colors'
import { isWeb } from '@/constants/Layout'
import { globalStyles } from '@/constants/Styles'
import { useApiFx } from '@/hooks/useApiFx'
import { useCancelableFocusFx } from '@/hooks/useCancelablePromise'
import useKeyedState from '@/hooks/useKeyedState'
import { withAdminAuth } from '@/hooks/withAdminAuth'
import { AppStackParamList } from '@/navigation/types'
import { adminFarmSelector } from '@/redux/selectors'
import { loadLocationsByFarm } from '@api/Locations'
import { loadSummariesByFarm } from '@api/SignInSummaries'
import { Permission } from '@helpers/Permission'
import { formatFormalShortDate } from '@helpers/display'
import { errorToString } from '@helpers/helpers'
import { Location } from '@models/Location'
import { SignInSummary } from '@models/SignInSummary'
import { sharedStyles } from '@screens/SignInSheet/components'
import { getBQSummary, parseDateString } from '@screens/SignInSheet/helpers'
import { DateTime } from 'luxon'
import { SummaryCard } from './components'

type Props = StackScreenProps<DistributionSummaryParamList & AppStackParamList, 'SignInSheetSelector'>

type SignInSheetSelectorState = {
  locations?: Location[]
  selectedLocation?: Location
  selectedDate?: DateTime
  /* When the locations are initially being fetched, we want to show a loading spinner on the location picker **/
  locationsLoading: boolean
  /* When the user presses next, we want to show a loading spinner in the button **/
  nextLoading: boolean
  error?: string
}

function SignInSheetSelectorScreenComp({ navigation, route }: Props) {
  const [{ locations, selectedLocation, selectedDate, locationsLoading, nextLoading, error }, , setState] =
    useKeyedState<SignInSheetSelectorState>({
      locationsLoading: true,
      nextLoading: true,
    })
  const farm = useSelector(adminFarmSelector)
  const { data: farmSummaries, loading: summariesLoading } = useApiFx(loadSummariesByFarm, [farm?.id], !!farm?.id)

  /**This preselects the selectedDate and location, if they are available from the params*/
  useCancelableFocusFx(
    async (isCurrent) => {
      try {
        setState((prev) => ({ ...prev, locationsLoading: true, error: undefined }))

        const locations = await loadLocationsByFarm(farm.id)
        const selectedLocation = route.params?.locId
          ? locations.find((loc) => loc.id === route.params?.locId)
          : undefined
        const selectedDate = route.params?.date ? parseDateString(route.params.date, farm.timezone) : undefined

        if (!isCurrent) return
        setState((prev) => ({
          ...prev,
          selectedDate,
          selectedLocation,
          locations,
          locationsLoading: false,
          nextLoading: false,
        }))
      } catch (err) {
        Logger.error(err)
        if (!isCurrent) return
        setState((prev) => ({ ...prev, error: errorToString(err), locationsLoading: false }))
      }
    },
    [farm.id, farm.timezone, route.params?.date, route.params?.locId, setState],
  )

  const onSelectDate = async (date: DateTime) => {
    const day = date.startOf('day')
    setState((prev) => ({ ...prev, error: undefined, selectedDate: day }))
  }

  const onSelectLocation = async (locId: string) => {
    const selectedLocation = locations?.find((loc) => loc.id === locId)
    setState((prev) => ({ ...prev, selectedLocation, error: undefined }))
  }

  const onNextPress = useCallback(async () => {
    if (!selectedDate || !selectedLocation) return

    try {
      setState((prev) => ({ ...prev, nextLoading: true, error: undefined }))
      await getBQSummary(farm.id, selectedDate, selectedLocation.id)

      navigation.navigate('LocationSummary', {
        locId: selectedLocation.id,
        date: formatFormalShortDate(selectedDate),
      })
    } catch (err) {
      // will throw an error if no available data
      setState((prev) => ({ ...prev, error: errorToString(err) }))
    } finally {
      setState((prev) => ({ ...prev, nextLoading: false }))
    }
  }, [selectedDate, selectedLocation, setState, farm.id, navigation])

  const onSignInCardPress = useCallback(
    (item: SignInSummary) => {
      navigation.navigate('SignInSheet', {
        screen: 'EditSignIn',
        params: {
          id: item.id,
        },
      })
    },
    [navigation],
  )

  return (
    <ScrollView contentContainerStyle={sharedStyles.wrapperNoAlign}>
      <View style={styles.header}>
        <ButtonClear
          title="Back"
          icon="arrow-left"
          onPress={() => navigation.navigate('DistributionSummary', { tab: 'signIn' })}
        />
      </View>
      <TextH1 center>Sign-in</TextH1>
      <View style={globalStyles.margin10} />
      <Text center>Where are you signing people in?</Text>
      <View style={globalStyles.margin20} />
      <Text>Location</Text>
      <Picker
        loading={locationsLoading || !locations?.length}
        value={selectedLocation?.id}
        items={locations?.map((loc) => ({ value: loc.id, label: loc.name })) ?? []}
        onValueChange={onSelectLocation}
      />
      <View style={globalStyles.margin20} />
      <Text>Date</Text>
      <DateTimePicker mode="date" value={selectedDate} onChange={onSelectDate} timezone={farm.timezone} />
      {!!error && (
        <Text color={Colors.red} type="medium" size={12}>
          {error}
        </Text>
      )}
      <View style={globalStyles.margin20} />
      <Button
        style={styles.btn}
        disabled={!selectedDate || !selectedLocation}
        loading={nextLoading}
        onPress={onNextPress}
        title="Next"
      />
      <View style={globalStyles.margin20} />
      <LoadingView loading={summariesLoading}>
        <View style={globalStyles.margin10}>
          <Text>Past Sign-in Sessions</Text>
        </View>
        <FlatList
          scrollEnabled={isWeb}
          ListFooterComponent={() => <View style={styles.footer} />}
          data={farmSummaries ?? []}
          renderItem={({ item }) => {
            const location = locations?.find((l) => l.id === item.location.id)
            if (!location) return null
            return <SummaryCard location={location} onPress={onSignInCardPress} item={item} />
          }}
        />
      </LoadingView>
    </ScrollView>
  )
}

export const SignInSheetSelectorScreen = withAdminAuth(SignInSheetSelectorScreenComp, Permission.Distributions)

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  footer: {
    height: 40,
  },

  btn: {
    marginHorizontal: 0,
  },
})
