import { auth } from '@api/db'
import { FormBuilder, PhoneInput } from '@components'
import { Divider, ErrorText, HeaderText, Text } from '@elements'
import { mainNumPatternForms } from '@helpers/display'
import { nonEmptyString } from '@helpers/helpers'
import { FormikProps, getIn } from 'formik'
import { StyleSheet } from 'react-native'
import { Input } from 'react-native-elements'
import * as Yup from 'yup'

import { useDeviceSize } from '../../../hooks/useLayout'
import { BillingAddressFormType, CheckoutFormType } from './helpers'

import { getShortState } from '@/assets/data/states'

/* - This schema and models/Addresses/validateAddress() must enforce the same validation logic for checkout.
 * - Preventing duplicate phone numbers isn't necessary here and happens only in checkout/validateCheckoutForm() */
export const billingAddressSchema = Yup.object<BillingAddressFormType>().shape({
  phoneNumber: Yup.string()
    .matches(mainNumPatternForms, 'Phone number must be in 10 digit format.')
    .label('Phone Number'),
  street1: Yup.string().trim().label('Street Address 1').required('Street1 is required'),
  city: Yup.string().trim().label('City').required('City is required'),
  state: Yup.string()
    .trim()
    .label('State')
    .required()
    .min(2, 'State must be at least 2 letters')
    .test(
      'short code',
      'State should be a valid state name',
      (value: any) => nonEmptyString(value) && !!getShortState(value),
    ),
  zipcode: Yup.string()
    .trim()
    .label('Zip code')
    .required()
    .min(5, 'Zip code must be 5 digits')
    .max(5, 'Zip code must be 5 digits')
    .matches(/[0-9]{5}/, 'Zip code must be numeric'),
})

export default function CheckoutContact({
  formikProps: { handleChange, values, errors, touched, handleBlur, setFieldError },
}: {
  formikProps: FormikProps<CheckoutFormType>
}) {
  const { isSmallDevice } = useDeviceSize()
  return (
    <FormBuilder style={styles.container}>
      <Divider clear large />
      <HeaderText>Contact Information</HeaderText>
      <Text style={styles.marginV5}>
        Your phone number will be used by farmers to contact you for any last minute changes or important information.
      </Text>
      <Divider clear />
      <PhoneInput
        editable={auth().getProvider() !== 'phone'}
        value={values.billingAddress.phoneNumber}
        maskHandler={handleChange('billingAddress.phoneNumber')}
        onBlur={handleBlur('billingAddress.phoneNumber')}
        onChange={() => setFieldError('billingAddress.phoneNumber', undefined)}
        label={!isSmallDevice}
        errorMessage={getIn(errors, 'billingAddress.phoneNumber')}
        renderErrorMessage={getIn(touched, 'billingAddress.phoneNumber')}
      />
      <Divider clear />
      <HeaderText style={styles.marginV10}>Billing Address</HeaderText>
      <Input
        onChangeText={handleChange('billingAddress.street1')}
        onChange={() => setFieldError('billingAddress.street1', undefined)}
        onBlur={handleBlur('billingAddress.street1')}
        value={values.billingAddress.street1}
        placeholder="Street Address 1"
        errorMessage={getIn(touched, 'billingAddress.street1') ? getIn(errors, 'billingAddress.street1') : ''}
      />
      <Input
        onChangeText={handleChange('billingAddress.street2')}
        onChange={() => setFieldError('billingAddress.street2', undefined)}
        onBlur={handleBlur('billingAddress.street2')}
        value={values.billingAddress.street2}
        placeholder="Street Address 2"
        errorMessage={getIn(touched, 'billingAddress.street2') ? getIn(errors, 'billingAddress.street2') : ''}
      />
      <FormBuilder row>
        <Input
          onChangeText={handleChange('billingAddress.city')}
          onChange={() => setFieldError('billingAddress.city', undefined)}
          onBlur={handleBlur('billingAddress.city')}
          value={values.billingAddress.city}
          placeholder="City"
          errorMessage={getIn(touched, 'billingAddress.street1') ? getIn(errors, 'billingAddress.city') : ''}
        />
        <Input
          onChangeText={handleChange('billingAddress.state')}
          onChange={() => setFieldError('billingAddress.state', undefined)}
          onBlur={handleBlur('billingAddress.state')}
          value={values.billingAddress.state}
          placeholder="State"
          errorMessage={getIn(touched, 'billingAddress.state') ? getIn(errors, 'billingAddress.state') : ''}
        />
        <Input
          placeholder="Zip Code"
          value={values.billingAddress.zipcode}
          keyboardType="number-pad"
          maxLength={5}
          onChangeText={handleChange('billingAddress.zipcode')}
          onChange={() => setFieldError('billingAddress.zipcode', undefined)}
          onBlur={handleBlur('billingAddress.zipcode')}
          errorMessage={getIn(touched, 'billingAddress.zipcode') ? getIn(errors, 'billingAddress.zipcode') : ''}
        />
        {getIn(errors, 'billingAddress.updateError') ? (
          <ErrorText>{getIn(errors, 'billingAddress.updateError')}</ErrorText>
        ) : null}
      </FormBuilder>
    </FormBuilder>
  )
}

const styles = StyleSheet.create({
  marginV10: { marginVertical: 10 },
  marginV5: { marginVertical: 5 },
  container: {
    marginHorizontal: 20,
    marginVertical: 10,
  },
})
