import { Alert, DropdownMenu, DropdownMenuBtn, Icon, Toast } from '@elements'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AdminProductsParamList } from '@/admin/navigation/types'
import Colors from '@/constants/Colors'
import { SaleStats } from '@/constants/types'
import { AlgoliaAdminProduct } from '@models/Algolia'
import { FC, memo } from 'react'
import { StyleSheet } from 'react-native'
import { Dispatch } from 'redux'

import { addProduct, deleteProduct } from '@api/Products'
import { openUrl, shoppingUrl } from '@helpers/client'
import { ProductType } from '@models/Product'

import { grownbyWebsiteBaseUrl } from '@/config/Environment'
import { Logger } from '@/config/logger'
import { addQueueAlgoliaProduct } from '@/redux/actions/adminState'
import { productsCollection } from '@api/framework/ClientCollections'
import { errorToString } from '@helpers/helpers'
import { DataError } from '@shared/Errors'
import { wholesaleSelector } from '../../../../../redux/selectors'

export interface RowActionsProps {
  algoliaProd: AlgoliaAdminProduct
  sales: SaleStats
}

export const RowActions: FC<RowActionsProps> = memo(function RowActions({ algoliaProd, sales }) {
  const navigation = useNavigation<StackNavigationProp<AdminProductsParamList, 'ProductList'>>()
  const { isWholesale } = useSelector(wholesaleSelector)

  const dispatch = useDispatch()
  return (
    <DropdownMenu
      buttons={generateButtons({ algoliaProd, sales, dispatch, navigation, isWholesale })}
      data={{ algoliaProd, sales, dispatch, navigation, isWholesale }}
      containerStyle={styles.menu}
    >
      <Icon name="ellipsis-h" solid color={Colors.blue} />
    </DropdownMenu>
  )
})

const styles = StyleSheet.create({
  menu: { alignItems: 'flex-start' },
})

type RowActionsData = RowActionsProps & {
  dispatch: Dispatch
  navigation: StackNavigationProp<AdminProductsParamList, 'ProductList'>
  isWholesale?: boolean
}

export const generateButtons = ({ algoliaProd, isWholesale }: RowActionsData): DropdownMenuBtn<RowActionsData>[] => {
  const buttons: DropdownMenuBtn<RowActionsData>[] = [
    {
      title: 'View',
      onPress: async ({ algoliaProd }) => {
        const prod = await productsCollection.fetch(algoliaProd.id)
        const productConsumerUrl = shoppingUrl({
          farmSlug: prod.farm.urlSafeSlug,
          prodId: algoliaProd.id,
          csaId: prod.csa?.[0],
        })
        const url = `${grownbyWebsiteBaseUrl(isWholesale)}${productConsumerUrl.substring(1)}`
        const urlData = {
          target: algoliaProd.id,
        }
        const openResult = await openUrl(url, urlData)
        if (!openResult) {
          Logger.error(new DataError("Couldn't open url", { url, urlData, prod }))
          Toast("Couldn't open the shop url")
        }
      },
    },
    {
      title: 'Edit',
      onPress: ({ navigation, algoliaProd }) => {
        navigation.navigate('EditProduct', { prodId: algoliaProd.id })
      },
    },
    {
      title: `${algoliaProd.isHidden ? 'Unhide' : 'Hide'}`,
      onPress: ({ algoliaProd, dispatch }) =>
        productsCollection
          .update({ id: algoliaProd.id, isHidden: !algoliaProd.isHidden })
          .then(() => {
            Toast(`The product was ${algoliaProd.isHidden ? 'unhidden' : 'hidden'}`)
            dispatch(
              addQueueAlgoliaProduct({
                prodId: algoliaProd.id,
                data: { ...algoliaProd, isHidden: !algoliaProd.isHidden },
                action: 'edited',
              }),
            )
          })
          .catch((err) => {
            Toast('Update failed')
            Logger.error(err)
          }),
    },
  ]

  if (algoliaProd.type !== ProductType.FarmBalance)
    buttons.push({
      title: 'Copy',
      onPress: async ({ dispatch }) => {
        try {
          const prod = await productsCollection.fetch(algoliaProd.id)

          const copyOfProduct = { ...prod }
          copyOfProduct.name = algoliaProd.name + ' - Copy'
          const dbProd = await addProduct(copyOfProduct)
          Toast(`The product was copied`)
          //put in a temporary local queue, while it gets processed into algolia
          dispatch(addQueueAlgoliaProduct({ prodId: dbProd.id, data: dbProd }))
        } catch (err) {
          Logger.error(err)
          Alert('Error copying Product:', errorToString(err))
        }
      },
    })

  buttons.push({
    title: `${algoliaProd.isFeatured ? 'Unfeature' : 'Feature'}`,
    onPress: ({ algoliaProd, dispatch }) =>
      productsCollection
        .update({ id: algoliaProd.id, isFeatured: !algoliaProd.isFeatured })
        .then(() => {
          Toast(`The product was ${algoliaProd.isFeatured ? 'unfeatured' : 'featured'}`)
          dispatch(
            addQueueAlgoliaProduct({
              prodId: algoliaProd.id,
              data: { ...algoliaProd, isFeatured: !algoliaProd.isFeatured },
              action: 'edited',
            }),
          )
        })
        .catch((err) => {
          Logger.error(err)
          Toast('Update failed')
        }),
  })

  buttons.push({
    title: 'Delete',
    onPress: ({ sales, algoliaProd, dispatch }) => {
      if (sales.sales && sales.sales > 0) {
        Alert(
          'Cannot Delete Product',
          'This product has already made sales and cannot be deleted. You can hide this product to not show it in your shop or distribution reports.',
        )
      } else {
        Alert('Delete Product', 'Are you sure you want to delete this product?', [
          {
            text: 'Delete',
            onPress: async () => {
              const { success, error } = await deleteProduct(algoliaProd.id, algoliaProd.farmId)
              if (success) {
                dispatch(addQueueAlgoliaProduct({ prodId: algoliaProd.id, action: 'removed' }))
                Toast('The product was deleted')
              } else {
                Alert(
                  error ? 'Something went wrong' : 'Cannot Delete Product',
                  error
                    ? error
                    : 'This product has already made sales and cannot be deleted. You can hide this product to not show it in your shop or distribution reports.',
                )
              }
            },
          },
          {
            text: 'Cancel',
            style: 'cancel',
          },
        ])
      }
    },
  })

  return buttons
}
