import { DigitalProduct, Standard, Unit } from '@models/Product'
import { memo, useContext } from 'react'

import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { OrderCreatorScreenContext } from '../../OrderCreatorScreen.helper'
import { AddBtnStandard_Reusable } from './StandardBtn_Reusable'

export type AddBtnStandardProps = {
  prod: Standard | DigitalProduct
  /** This 'unit' should come from the StandardRow. It is optional because in that case the unit selection will be part of the addCart flow */
  unit?: Unit
}

export const AddBtnStandard = memo(function AddBtnStandard({ prod, unit }: AddBtnStandardProps) {
  const {
    isWholesaleOrderCreator,
    keyedStateProdsFilters: [{ schedule, pickupDate }],
  } = useContext(OrderCreatorScreenContext)

  if (isWholesaleOrderCreator === undefined) return null

  return (
    <AddBtnStandard_Reusable
      cartServiceType="orderCreator"
      isWholesale={isWholesaleOrderCreator}
      pickupDate={pickupDate}
      prod={prod}
      schedule={schedule}
      unit={unit}
    />
  )
}, propsAreDeepEqual)
