import { ButtonClear, LoadingView, Text, TextH1 } from '@elements'
import { StyleProp, View, ViewStyle } from 'react-native'

import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useDeviceSize } from '@/hooks/useLayout'
import { isValidElement } from 'react'

type Props<T> = {
  title: string
  data?: T[]
  rightHeaderBtn?: { title: string; onPress: () => void }
  headers: string[]
  getColumns: (itm: T) => (string | number | JSX.Element)[]
  isLoading: boolean
  columnsStyle?: { [columnIndex: number]: StyleProp<ViewStyle> }
  style?: StyleProp<ViewStyle>
  emptyMessage?: string
}

/** Data table used for very small and simple sets of data. Has minimal functionality */
export function SmallTable<T>({
  title,
  data,
  rightHeaderBtn,
  headers,
  getColumns,
  isLoading,
  columnsStyle = {},
  style,
  emptyMessage = 'No available data',
}: Props<T>) {
  const { isExtraSmallDevice } = useDeviceSize()
  const styles = useStyles()
  return (
    <View style={[styles.main, style]}>
      <View style={styles.tableHeader}>
        <View style={styles.titleCont}>
          <TextH1 size={20}>{title}</TextH1>
        </View>
        {rightHeaderBtn && <ButtonClear title={rightHeaderBtn.title} onPress={rightHeaderBtn.onPress} small />}
      </View>

      <View style={styles.headersCont}>
        {headers.map((header, i) => (
          <View key={i} style={[styles.columnContainer, columnsStyle[i]]}>
            <Text type="medium" numberOfLines={1} size={isExtraSmallDevice ? 12 : 14}>
              {header}
            </Text>
          </View>
        ))}
      </View>

      <LoadingView loading={isLoading} style={styles.rowsWrapper} success={data}>
        {(data) => {
          if (!data.length) {
            return (
              <View style={globalStyles.padding10}>
                <Text center>{emptyMessage}</Text>
              </View>
            )
          }
          return data.map((item, i) => (
            <View style={styles.rowContainer} key={i}>
              {getColumns(item).map((colValue, colIdx) => (
                <View style={[styles.columnContainer, columnsStyle[colIdx]]} key={`${i}_${colIdx}`}>
                  {isValidElement(colValue) ? colValue : <Text numberOfLines={2}>{colValue}</Text>}
                </View>
              ))}
            </View>
          ))
        }}
      </LoadingView>
    </View>
  )
}

const useStyles = () =>
  useSizeFnStyles(({ isExtraSmallDevice }) => ({
    titleCont: {
      flexDirection: 'row',
      gap: 10,
    },
    spinner: {
      maxWidth: 35,
    },
    headersCont: {
      flexDirection: 'row',
      marginVertical: 10,
      gap: isExtraSmallDevice ? 6 : 12,
      alignItems: 'center',
    },
    tableHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    rowContainer: {
      flexDirection: 'row',
      gap: isExtraSmallDevice ? 6 : 12,
    },
    columnContainer: {
      flex: 1,
    },
    rowsWrapper: {
      gap: 16,
    },
    main: {
      borderRadius: 8,
      padding: isExtraSmallDevice ? 8 : 20,
      borderColor: Colors.shades['100'],
      borderWidth: 1,
    },
  }))
