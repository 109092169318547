import { Button, Divider, Text, TextH1 } from '@elements'
import { Ionicons } from '@expo/vector-icons'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { View } from 'react-native'

import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { SignInSheetParamList } from '@/navigation/types'
import { SharedScreenHeader, sharedStyles } from './components'
import { withDataAndAuth } from './withDataAndAuth'

type Props = StackScreenProps<SignInSheetParamList, 'PostDonation'>

function PostDonationScreenComp({ navigation, route }: Props) {
  const onContinuePress = () => {
    navigation.navigate('AddNotes', { id: route.params.id, token: route.params.token })
  }

  return (
    <View style={sharedStyles.wrapper}>
      <SharedScreenHeader />
      <View style={[globalStyles.margin20, globalStyles.alignCenter]}>
        <Ionicons name="heart" size={24} color={Colors.green} />
        <TextH1 center>Thank you!</TextH1>
      </View>
      <Text center>Thank you for sharing the harvest today!</Text>
      <Divider clear />
      <Button onPress={onContinuePress} title="Continue" />
    </View>
  )
}

export const PostDonationScreen = withDataAndAuth(PostDonationScreenComp)
