import { useSelector } from 'react-redux'

import { CartService, CartServiceType } from '../../constants/types/cartService'

import {
  cartServiceConsumerSelector,
  cartServiceOrderCreatorSelector,
  cartServiceOrderEditSelector,
} from '@/redux/selectors'
import { matchesAppModeProduct } from '@helpers/products'
import { getFarmCartItems } from '@models/Cart'
import { useMemo } from 'react'

type UseCartServiceOpts = {
  cartServiceType?: CartServiceType
  farmId?: string
  isWholesale?: boolean
}

/** Selects the cart service for either consumer or admin cart.
 * @param isAdmin if true, will return the admin cart. Else the consumer cart.
 * @param farmId if passed, the items will only include those for this farm.
 * @param isWholesale if passed, the items will only include those matching the catalog mode
 */
export function useCartService({
  cartServiceType = 'consumer',
  farmId,
  isWholesale,
}: UseCartServiceOpts = {}): CartService {
  const service = useSelector(
    cartServiceType === 'orderCreator'
      ? cartServiceOrderCreatorSelector
      : cartServiceType === 'consumer'
      ? cartServiceConsumerSelector
      : cartServiceOrderEditSelector,
  )

  return useMemo(() => {
    const farmItems = farmId ? getFarmCartItems({ items: service.cart, farmId, isWholesale }) : service.cart

    const isMatch = matchesAppModeProduct(isWholesale)
    const catalogItems = farmItems.filter((ci) => isMatch(ci.product))

    return {
      ...service,
      cart: catalogItems,
    }
  }, [farmId, service, isWholesale])
}
