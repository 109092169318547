import { DateTime } from 'luxon'

import { Farm } from './Farm'
import { Role, User } from './User'
import { PartialPick } from '@helpers/typescript'

export type ExternalLink = UserInvite | ResetRequest | AccountSetup | VerifyEmail

/** User Invitation type */
export enum InviteType {
  Admin = 'admin-invite',
  Customer = 'customer-invite',
}

export type BaseLink = {
  id: string
  user: PartialPick<User, 'email'>
  expireDate: DateTime
}

export type UserInvite = BaseLink & {
  type: InviteType
  farm: Pick<Farm, 'id' | 'name'>
  admin: Pick<User, 'name' | 'id'>
  role: Role
}

export type ResetRequest = BaseLink & {
  type: 'reset-request'
}

export type AccountSetup = BaseLink & {
  type: 'account-setup'
}

export type VerifyEmail = BaseLink & {
  user: Pick<User, 'email' | 'id'>
  type: 'verify-email'
  /** By default isInitial will be false, it will only be true when the email is sent right after SignUp. Any manual request email verification will be isInitial 'false' */
  isInitial?: boolean
}
