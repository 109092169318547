import { Coordinate, HitRegion, ShortCoord, isCoord, isShortCoord } from '@models/Coordinate'
import { isNum, nonEmptyString } from './helpers'

export const validCoords = (coords: Coordinate | Partial<Coordinate> | ShortCoord | Partial<ShortCoord>): boolean => {
  //both should be numbers, and at least one must be not zero
  return isCoord(coords)
    ? typeof coords.latitude === 'number' &&
        typeof coords.longitude === 'number' &&
        coords.latitude !== 0 &&
        coords.longitude !== 0
    : isShortCoord(coords)
    ? typeof coords.lat === 'number' && typeof coords.lng === 'number' && coords.lat !== 0 && coords.lng !== 0
    : false
}

/** Encodes a coordinate into a string. This is the format expected by algolia */
export type CoordString = `${number},${number}`

/** Converts a coordinate into a coordinate string */
export const getCoordString = (geoloc: ShortCoord | Coordinate | undefined): CoordString | '' =>
  isShortCoord(geoloc) ? `${geoloc.lat},${geoloc.lng}` : isCoord(geoloc) ? `${geoloc.latitude},${geoloc.longitude}` : ''

/** Converts a coordstring back to a coordinate */
export function parseCoordString(coordString: string): Coordinate | null {
  if (!nonEmptyString(coordString)) return null

  const [latitude, longitude] = (coordString || ',').split(',').map((s) => (isNum(s) ? parseFloat(s) : 0))
  const coord: Coordinate = { latitude, longitude }
  return validCoords(coord) ? coord : null
}

/** Converts a coordinate into a short coord */
export const shortenCoordinate = (coord: Coordinate): ShortCoord => ({
  lat: coord.latitude,
  lng: coord.longitude,
})

/** Converts a short coord into a coordinate */
export const lengthenCoord = (coord: ShortCoord): Coordinate => ({
  latitude: coord.lat,
  longitude: coord.lng,
})

/** Will get a region centered around the group of results */
export function getRegionForCoordinates(points: ShortCoord[]): Omit<HitRegion, 'zoom'> {
  let minX = points[0].lat
  let maxX = points[0].lng
  let minY = points[0].lat
  let maxY = points[0].lng

  // calculate rect
  points.map((point) => {
    minX = Math.min(minX, point.lat)
    maxX = Math.max(maxX, point.lat)
    minY = Math.min(minY, point.lng)
    maxY = Math.max(maxY, point.lng)
  })

  const midX = (minX + maxX) / 2
  const midY = (minY + maxY) / 2
  const deltaX = maxX - minX
  const deltaY = maxY - minY

  return {
    latitude: midX,
    longitude: midY,
    latitudeDelta: deltaX,
    longitudeDelta: deltaY,
  }
}

export const kmsPerDegreeLat = 111.33
export const kmsPerDegreeLng = (40075 * Math.cos(kmsPerDegreeLat)) / 360

/** Returns a radius based on a map region */
export function getRadiusFromRegion(region: Omit<HitRegion, 'zoom'>) {
  const latMeters = Math.abs(region.latitudeDelta * kmsPerDegreeLat * 1000)
  const lngMeters = Math.abs(region.longitudeDelta * kmsPerDegreeLng * 1000)

  // The resulting value must be an integer to work with algolia's Configure "aroundRadius" prop
  return Math.round(Math.max(latMeters, lngMeters) / 2)
}

/**
 * Returns the distance between two coordinates in miles or km
 * https://stackoverflow.com/a/365853
 */
export function getDistance(
  { latitude: lat1, longitude: lon1 }: Coordinate,
  { latitude: lat2, longitude: lon2 }: Coordinate,
  opts = { miles: true },
): number {
  function degreesToRadians(degrees: number): number {
    return (degrees * Math.PI) / 180
  }

  const earthRadiusKm = 6371

  const dLat = degreesToRadians(lat2 - lat1)
  const dLon = degreesToRadians(lon2 - lon1)

  lat1 = degreesToRadians(lat1)
  lat2 = degreesToRadians(lat2)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const kmDistance = earthRadiusKm * c
  return opts.miles ? kmToMiles(kmDistance) : kmDistance
}

const MileInKMs = 1.609344
export const kmToMiles = (km: number) => km / MileInKMs
export const milesToKm = (miles: number) => miles * MileInKMs

function degrees_to_radians(degrees: number) {
  return degrees * (Math.PI / 180)
}

function radians_to_degrees(radians: number) {
  return radians / (Math.PI / 180)
}

/**
 *
 * @param lat1 initial latitude, in degrees
 * @param lng1 initial longitude, in degrees
 * @param distance target distance from initial, in kms
 * @param bearing (true) heading in degrees
 * @param R optional radius of sphere, defaults to mean radius of earth
 * @returns new lat/lon coordinate {d}km from initial, in degrees
 */
export const getPointAtDistance = (
  { lat: lat1, lng: lng1 }: ShortCoord,
  distance: number,
  bearing: number,
  R = 6371,
): ShortCoord => {
  lat1 = degrees_to_radians(lat1)
  lng1 = degrees_to_radians(lng1)
  bearing = degrees_to_radians(bearing)
  const lat2 = Math.asin(
    Math.sin(lat1) * Math.cos(distance / R) + Math.cos(lat1) * Math.sin(distance / R) * Math.cos(bearing),
  )
  const lng2 =
    lng1 +
    Math.atan2(
      Math.sin(bearing) * Math.sin(distance / R) * Math.cos(lat1),
      Math.cos(distance / R) - Math.sin(lat1) * Math.sin(lat2),
    )
  return { lat: radians_to_degrees(lat2), lng: radians_to_degrees(lng2) }
}
