import { Image, MessageWithIcon } from '@components'
import { Text, typography } from '@elements'
import { OrderItem } from '@models/Order'
import { ScrollView, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native'

import Colors from '../../constants/Colors'

type ItemListProps = {
  items: OrderItem[]
  title: string
  onTitleClick?: () => void
  style?: StyleProp<ViewStyle>
}

/** This item list is used in OrderCard only */
export function ItemList({ items, title, onTitleClick, style }: ItemListProps) {
  const filteredItems = items.filter((item) => !item.cancelled)

  const titleText = (
    <Text size={16} type="regular" style={[styles.title, onTitleClick && styles.clickableTitle]}>
      {title}
    </Text>
  )

  return (
    <View style={[styles.container, style]}>
      {onTitleClick ? <TouchableOpacity onPress={onTitleClick}>{titleText}</TouchableOpacity> : titleText}
      {filteredItems.length ? (
        <>
          <Text size={14} type="regular" style={{ fontFamily: typography.body.regular }}>
            {filteredItems.reduce((str, item, index) => {
              const name = item.product.name
              const isFirst = index === 0
              const isLast = index === items.length - 1

              const itemStr = `${name} (${item.purchasedUnit?.name ? `${item.purchasedUnit.name}, ` : ''}x${
                item.quantity
              })`

              // First product
              if (isFirst) return itemStr

              if (isLast) {
                // Only 2 products and is last
                if (items.length === 2) return `${str} and ${itemStr}`
                // More than 2 products and is last
                return `${str}, and ${itemStr}`
              }

              return `${str}, ${itemStr}`
            }, '')}
          </Text>

          <ScrollView horizontal style={styles.itemImagesCont}>
            {filteredItems.map((item) => (
              <Image
                key={item.id}
                source={{
                  uri: item.product.image,
                }}
                style={styles.itemImage}
                resizeMode="cover"
              />
            ))}
          </ScrollView>
        </>
      ) : (
        <MessageWithIcon icon="box-open" title="No Items" style={styles.noItems} />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-start',
    marginTop: 18,
    width: '100%',
  },
  title: {
    fontFamily: typography.body.regular,
    marginBottom: 2,
  },
  clickableTitle: {
    color: Colors.green,
  },
  itemImagesCont: {
    marginTop: 8,
    height: 100,
    width: '100%',
  },
  itemImage: {
    borderRadius: 10,
    minWidth: 100,
    maxWidth: 100,
    height: '100%',
    marginRight: 10,
  },
  noItems: {
    padding: 10,
    paddingBottom: 0,
  },
})
