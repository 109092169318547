import { SkeletonContent } from '@components'
import { Button, Modal } from '@elements'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useCallback } from 'react'
import { ScaledSize, StyleSheet, View, ViewStyle } from 'react-native'
import { useDispatch } from 'react-redux'

import Colors from '../constants/Colors'
import { isSmallDevice } from '../constants/Layout'
import { globalStyles } from '../constants/Styles'
import { Farm, FarmStatus } from '../models/Farm'
import { addNavProp } from '../redux/actions/appState'
import { ImageCard } from './ImageCard'
import { Rating } from './Rating'
import { containerFlex } from './ResponsiveList'
import { HeaderText, Text, fontStyle } from './elements/Text'

import { AppStackParamList } from '@/navigation/types'
import { InviteFarm } from '@screens/FarmDetailScreen/InviteFarm'
import { useLayout } from '../hooks/useLayout'
import { displayProductCount } from '@helpers/products-display'

type FarmCardProps = {
  farm?: Farm
  // Force the width to this size if passed
  width?: number
  onCardPress?: () => void
}

const detailsLink = (farmSlug: string) => `/farms/${farmSlug}`

/** Simple farm card used on the Favorite farms page */
export function FarmCard({ farm, width, onCardPress: onCardPressProp }: FarmCardProps) {
  const layout = useLayout()
  const dispatch = useDispatch()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const onActionPress = useCallback(() => {
    if (!farm) return
    if (farm.status !== FarmStatus.Registered) Modal(<InviteFarm farm={farm} />, { title: 'Invite to GrownBy' })
    // We don't know where this card may be used, so we navigate from the lowest level
    else
      navigation.navigate('Consumer', {
        screen: 'Shopping',
        params: { screen: 'FarmShop', params: { farmSlug: farm.urlSafeSlug } },
      })
  }, [farm, navigation])

  const onCardPress = useCallback(() => {
    dispatch(addNavProp({ farm }))
    onCardPressProp?.()
  }, [onCardPressProp, dispatch, farm])

  if (!farm?.id) return <FarmSkeleton />
  return (
    <ImageCard
      style={!width && cardStyle(layout)}
      contentContainerStyle={styles.textCont}
      imageUri={farm.media.filter((m) => m.type === 'image')[0]?.storageUrl}
      onPress={onCardPress}
      url={detailsLink(farm.urlSafeSlug)}
      action={
        // This action element must not use urls because it creates invalid html error <a> cannot be a descendant of <a>
        <Button
          onPress={onActionPress}
          style={styles.shopNow}
          title={farm.status === FarmStatus.Registered ? 'Shop Now' : 'Invite'}
        />
      }
    >
      <HeaderText style={styles.farmName} size={16}>
        {farm.name}
      </HeaderText>
      <View style={styles.farmInfo}>
        <Text style={styles.address}>
          {farm.address.city}, {farm.address.state}
        </Text>
        <Rating ratings={farm.reviews} />
      </View>
      <Text style={globalStyles.margin10}>{displayProductCount(farm.productCount)}</Text>
    </ImageCard>
  )
}

const cardStyle = (layout: ScaledSize): ViewStyle => ({
  ...containerFlex(layout),
  width: isSmallDevice() ? layout.width * 0.95 : Math.max(layout.width * 0.5, 300),
  alignSelf: 'center',
  height: '90%',
})

function FarmSkeleton() {
  return (
    <SkeletonContent
      containerStyle={globalStyles.flex1}
      isLoading
      layout={[{ width: '90%', minWidth: 300, height: 250, margin: 15 }]}
    />
  )
}

const styles = StyleSheet.create({
  textCont: {
    marginHorizontal: 15,
  },
  farmName: {
    margin: 5,
    marginLeft: 0,
  },
  farmInfo: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  address: {
    marginRight: 10,
    fontSize: 12,
    color: Colors.shades[400],
  },
  shopNow: {
    ...fontStyle(16),
    color: Colors.green,
    alignSelf: 'flex-end',
  },
  cardCont: {
    borderRadius: 10,
    borderWidth: 0.1,
    paddingBottom: 10,
    margin: 15,
    paddingTop: 0,
    paddingHorizontal: 0,
    marginBottom: 10,
  },
})
