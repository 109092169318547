import { Text } from '@elements'
import { View } from 'react-native'
import Colors from '../../../constants/Colors'
import { styles } from './ProductDetails'

/** Formats a line of text */
export function DetailItem({ name, children }: { name: string; children: any }) {
  return (
    <View style={styles.detailItm}>
      <Text size={12} color={Colors.shades['300']} style={styles.width150}>
        {name}
      </Text>
      <Text size={12}>{children}</Text>
    </View>
  )
}
