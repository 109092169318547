import { updateProduct } from '@api/Products'
import { ArrElement } from '@helpers/typescript'
import { hasUnitStock, StockType, UnitBase, UnitStandard } from '@models/Product'

/** Updates the quantity of a UnitStandard's unit */
export const saveUnitStockQuantity = async (
  newQuantity: number,
  product: UnitStandard,
  unit: ArrElement<UnitStandard['units']>,
) => {
  if (!hasUnitStock(product)) throw new Error('Invalid product type')

  const updatedUnit: UnitBase<StockType.Unit> = { ...unit, quantity: newQuantity }

  const updatedProduct = { ...product } as typeof product

  updatedProduct.units[product.units.findIndex((u) => u.id === unit.id)] = updatedUnit

  return updateProduct(updatedProduct)
}
