import { userSelector } from '@/redux/selectors'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useFetchMessages } from './useFetchMessages'

/** Data layer hook that handles the business logic for the Messages screen */
export function useMessagesScreenData(adminFarmId?: string) {
  const user = useSelector(userSelector)
  const [selectedChatId, setSelectedChatId] = useState<string | undefined>()
  const { chatsSnap, messagesSnap, onSearchValueChange } = useFetchMessages({
    userId: user.id,
    farmId: adminFarmId,
    chatSessionId: selectedChatId,
  })

  return {
    onItemPress: setSelectedChatId,
    user,
    selectedChatId,
    chatsSnap,
    messagesSnap,
    onSearchValueChange,
    adminFarmId,
  }
}
