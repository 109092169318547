import Colors from '../../../constants/Colors'
import { PaymentButtonBase, PaymentButtonBaseProps } from '../PaymentButtonBase'

type Props = Omit<PaymentButtonBaseProps, 'title' | 'iconRight' | 'iconLeft' | 'iconLeftColor' | 'imageLeft'>

/** For adding new Credit Cards */
export function AddCreditCardButton(props: Props) {
  return (
    <PaymentButtonBase
      title="Add Credit Card"
      iconRight="chevron-right"
      iconLeft="credit-card"
      iconLeftColor={Colors.shades[500]}
      {...props}
    />
  )
}

/** For adding new Bank Accounts */
export function AddBankAccountButton(props: Props) {
  return (
    <PaymentButtonBase
      title="Add Bank Account"
      subtitle="Save your farmer 2% in fees by using a bank account."
      iconRight="chevron-right"
      iconLeft="university"
      iconLeftColor={Colors.purple}
      {...props}
    />
  )
}

/** For adding new ebt cards */
export function AddEbtCardButton(props: Props) {
  return (
    <PaymentButtonBase
      title="Add EBT Card"
      iconRight="chevron-right"
      imageLeft={require('../../../assets/images/icons/ebt-card.png')}
      {...props}
    />
  )
}

/** Generic add payment button that can be used to allow adding either EBT or credit card */
export function AddPaymentMethodButton(props: Props) {
  return (
    <PaymentButtonBase
      title="Add Payment Method"
      iconRight="chevron-right"
      iconLeftColor={Colors.shades[500]}
      iconLeft="credit-card"
      {...props}
    />
  )
}
