import { Button, KeyboardAvoidingScrollView, Picker, Text, TextH1, TextH2 } from '@elements'
import { useContext } from 'react'
import { View } from 'react-native'

import { goBackString, goBackUrl } from '../OrderCreatorScreen/OrderCreatorScreen.helper'

import { BackTo } from '@/admin/components/BackTo'
import { useCartService } from '@/hooks/useCart'
import { capitalize } from '@helpers/display'
import { DefaultCatalog } from '@models/Product'
import { CustomerStatus } from '../OrderCreatorScreen/CustomerStatus'
import { styles, useStyles } from '../OrderCreatorScreen/OrderCreatorScreenUI-styles'
import { OrderCreatorTable } from '../OrderCreatorScreen/OrderCreatorTable'
import { OrderSummary } from './OrderSummary'
import { ProductRow } from './components/Rows/ProductRow'
import { OrderEditContext } from './useOrderEditData'
import Colors from '../../../../constants/Colors'

/** Screen main UI for order editing */
export function OrderEditUi() {
  const {
    keyedStateProds: {
      0: { pickupDate, prodSearchTerm, schedule },
      3: settersProds,
    },
    keyedStatePayments: [{ isSubmitting }],
    isLoadingProductsInit,
    isLoadingProducts,
    tableData,
    distroDropdownOpts,
    setDistro,
    loadingSchedules,
    dateDropdownOpts,
    setDate,
    draftOrderFx,
    customerFx,
    onPressApprove,
    onPressDecline,
  } = useContext(OrderEditContext)

  const { cart } = useCartService({
    cartServiceType: 'orderEdit',
    farmId: draftOrderFx.data?.farm.id,
    isWholesale: draftOrderFx.data?.isWholesale,
  })

  const stylesResp = useStyles()

  return (
    <KeyboardAvoidingScrollView extraHeight={150} contentContainerStyle={stylesResp.screenContStyle}>
      <View style={stylesResp.orderOptions}>
        <BackTo
          title={`Back to ${goBackString('orders')}`}
          /** This BackTo should use url because using onPress might not work if the Customers navigator isn't loaded due to lazy loading (Happens when you reload the screen) */
          url={goBackUrl({ custId: draftOrderFx.data?.user.id, goBack: 'orders' })}
        />
        <View style={styles.wrapRow}>
          <TextH1>Edit Order #{draftOrderFx.data?.orderNum.toString() ?? '...'}</TextH1>
        </View>
        <View style={styles.wrapRow}>
          <View style={stylesResp.wrapItem}>
            <TextH2 style={styles.marginTop15}>Customer</TextH2>
            <CustomerStatus
              custId={draftOrderFx.data?.user.id}
              customer={customerFx.data}
              hasItems={cart.length > 0}
              loading={customerFx.loading}
            />
          </View>
          <View style={stylesResp.wrapItem}>
            <TextH2 style={styles.marginTop15}>Catalog</TextH2>
            <Text>
              {draftOrderFx.loading
                ? '...'
                : capitalize(draftOrderFx.data?.isWholesale ? DefaultCatalog.Wholesale : DefaultCatalog.Retail)}
            </Text>
          </View>
        </View>

        <View style={styles.wrapRow}>
          <View style={stylesResp.wrapItem}>
            <TextH2>Select Schedule</TextH2>
            <Picker
              items={distroDropdownOpts}
              placeholder="Schedule @ Location"
              value={schedule?.id}
              onValueChange={setDistro}
              style={styles.marginV15}
              loading={loadingSchedules}
            />
          </View>
          <View style={stylesResp.wrapItem}>
            <TextH2>Select Date</TextH2>
            <Picker
              items={dateDropdownOpts}
              placeholder="Choose date"
              value={pickupDate?.toISODate()}
              onValueChange={setDate}
              style={styles.marginV15}
              loading={loadingSchedules}
              disabled={!schedule}
            />
          </View>
        </View>

        <TextH2 style={styles.marginTop15}>Choose Products</TextH2>
        <OrderCreatorTable
          data={tableData}
          orderType="standard"
          search={settersProds.prodSearchTerm}
          searchTerm={prodSearchTerm}
          isLoading={isLoadingProductsInit}
          isLoadingNonBlock={isLoadingProducts}
          ProductRow={ProductRow}
        />
      </View>
      {cart.length > 0 && (
        <View style={stylesResp.orderSummaryWrapper}>
          <View style={styles.btnsContainer}>
            <Button outline color={Colors.red} title="Decline Order" loading={isSubmitting} onPress={onPressDecline} />
            <Button title="Save and Approve" loading={isSubmitting} onPress={onPressApprove} />
          </View>
          <OrderSummary />
          <Button
            style={stylesResp.largePlaceOrderBtn}
            title="Save and Approve"
            loading={isSubmitting}
            onPress={onPressApprove}
          />
        </View>
      )}
    </KeyboardAvoidingScrollView>
  )
}
