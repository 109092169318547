import { Farm } from '@models/Farm'
import { ChatMessage, ChatSession, isAccountParticipant, ParticipantType, SendMessageProps } from '@models/Messaging'
import { User } from '@models/User'
import { DateTime } from 'luxon'
import { isSameDay } from './time'
import { keys, pick } from './typescript'

/** Gets the participant data that is not the current user */
export const getOtherParticipant = (participants: ChatSession['participants'], participantId: string) => {
  const key = keys(participants).find((id) => id !== participantId)

  return key ? participants[key] : undefined
}

/** Gets the current user participant data*/
export const getCurrentParticipant = (participants: ChatSession['participants'], participantId: string) => {
  const key = keys(participants).find((id) => id === participantId)

  return key ? participants[key] : undefined
}

/** Gets the text that should be displayed
 * - Only text is supported for now
 */
export const getMessageText = (item: ChatMessage): string => {
  if (item.content.type === 'text') {
    return item.content.content
  }

  return ''
}

/** Filters conversations by a text value
 * - If the other participant is a user, checks for the user's firstName and lastName
 * - If the other participant is a farm, checks for the farm's name
 */
export const filterChatsBySearchValue = (
  chats: ChatSession[],
  currUserId: string,
  searchValue: string,
): ChatSession[] => {
  if (!searchValue.length) return chats

  return chats.filter((chat) => {
    const other = getOtherParticipant(chat.participants, currUserId)
    if (!other) return false

    if (isAccountParticipant(other)) {
      return other.account.name.includes(searchValue)
    }

    const { firstName, lastName } = other.user.name
    return [firstName, lastName].some((val) => val.includes(searchValue))
  })
}

/**
 * Returns a string that represents the relative time difference between a given date and a reference date,
 * such as "2 days ago". The smallest unit of time displayed is minutes.
 * @param value target date to get the relative terms
 * @param base the reference date to compare with. Defaults to current date if not provided
 */
export const getRelativeTimeString = (value: DateTime, base?: DateTime) => {
  const result = value.toRelative({ base })
  // Do not show seconds
  if (result.includes('second')) return 'just now'

  return result
}

type FlaggedMessages = ChatMessage & { shouldShowDate?: boolean }

/**
 * Processes a list of chat messages to determine which messages should display their date.
 * The date is shown for:
 * 1. The last message in the list.
 * 2. Messages sent on a different day from the next message (in reverse chronological order).
 *
 * The list of messages is expected to be sorted in descending order by date (most recent first).
 *
 * @param data - An array of chat messages, each containing a `sentAt` date.
 * @returns A new array of chat messages where some messages are flagged to display their date.
 */
export const flagMessagesWithDates = (data: ChatMessage[]): FlaggedMessages[] => {
  const itemsCopy = [...data]

  return data.map((el, idx) => {
    // Show the date for the last message
    if (idx === itemsCopy.length - 1) return { ...el, shouldShowDate: true }

    // Show the date if the next message (in reverse order) is from a different day
    if (!isSameDay(el.sentAtUtc, itemsCopy[idx + 1].sentAtUtc)) {
      return { ...el, shouldShowDate: true }
    }

    return el
  })
}

/**
 * Creates the participant IDs for a farm participant.
 * This includes the IDs of all managers associated with the farm. */
export const createFarmParticipantIds = (participant: Pick<Farm, 'managers'>): ChatSession['participantIds'] => {
  return (participant.managers ?? []).map((manager) => manager.user.id)
}

/** Create the participant ID for a user participant.
 * @returns An array containing the user's ID.
 */
export const createUserParticipantIds = (participant: Pick<User, 'id'>): ChatSession['participantIds'] => {
  return [participant.id]
}

/**
 * Creates a record of farm participant information.
 * @returns A record mapping the farm ID to its participant details.
 */
export const createFarmParticipantInfo = (
  participant: Pick<Farm, 'id' | 'name' | 'logo'>,
): ChatSession['participants'] => {
  return {
    [participant.id]: {
      participantId: participant.id,
      account: pick(participant, 'id', 'name', 'logo'),
      type: ParticipantType.farm,
      hasUnreadMessage: false,
    },
  }
}

/**
 * Creates a record of user participant information.
 * @returns A record mapping the user ID to their participant details.
 */
export const createUserParticipantInfo = (
  participant: Pick<User, 'id' | 'name' | 'avatar'>,
): ChatSession['participants'] => {
  return {
    [participant.id]: {
      participantId: participant.id,
      user: pick(participant, 'id', 'name', 'avatar'),
      type: ParticipantType.user,
      hasUnreadMessage: false,
    },
  }
}

/** Adapter that creates participants data for sending a message */
export const createParticipantsData = (
  chatSession: ChatSession,
  sender: string,
  user: User,
): Pick<SendMessageProps, 'from' | 'to'> | undefined => {
  const other = getOtherParticipant(chatSession?.participants, sender)
  const current = getCurrentParticipant(chatSession.participants, sender)

  if (!other || !current) return undefined

  return {
    to: { id: other.participantId, type: other.type },
    from: {
      id: current.participantId,
      sender: { id: user.id, name: user.name },
      type: current.type,
    },
  }
}
