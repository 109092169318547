import { formatMoney } from '@helpers/display'
import { getPaymentSchedules } from '@helpers/order'
import { getProductAvailability } from '@helpers/products'
import { formatDateRange } from '@helpers/time'
import { PaymentSchedule, Product, Share, isShare } from '@models/Product'
import { memo, useMemo, useState } from 'react'

import { useFocusFx } from '../../../../../../hooks/useFocusFx'
import { ExpandableRow, ExpandableRowProps } from '../../../../../components/OfflineTable/ExpandableRow'
import { PayScheduleSelector } from '../PayScheduleSelector'

import { CartServiceType } from '@/constants/types/cartService'
import { useCartService } from '@/hooks/useCart'
import { Distribution } from '@models/Distribution'
import { AddBtnShareProps } from '../AddCartButtons/ShareBtn'

export type ShareRowProps_Reusable = {
  prod: Product
  index: number
  isWholesale: boolean
  schedule: Distribution | undefined
  AddCartBtn: React.FC<AddBtnShareProps>
  cartServiceType: CartServiceType
}

export const ShareRow_Reusable = memo(function ShareRow_Reusable({
  prod,
  index,
  isWholesale,
  schedule,
  AddCartBtn,
  cartServiceType,
}: ShareRowProps_Reusable) {
  const [paySchedule, setPaySchedule] = useState<PaymentSchedule>()

  const farmId = prod.farm.id

  const { isAdmin } = useCartService({ cartServiceType, farmId, isWholesale })

  //We need to reset the component on hitId change, because the table will swap the hitId
  //when recycling the same component instance on a new data point
  useFocusFx(() => {
    /**Although this is expected to receive a share, algolia sometimes returns different data for a split second, before
     * the results reflect the actual filters. In that flash of a second, this component might receive a standard product instead of a share. If it does, the getPaymentSchedules will throw an error if it receives a standard product because
     * it expects a unit price. So this should prevent this fx from running on any non-share product
     */
    if (!isShare(prod)) return
    setPaySchedule(getPaymentSchedules({ product: prod })[0])
    //eslint thinks prod isn't necessary in this hook, but we know it needs to reset on prod change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prod])

  const cols = useMemo<ExpandableRowProps<Share>['columns']>(
    () => [
      { process: (itm) => itm.name, widthFlex: 2 },
      { process: (itm) => <PayScheduleSelector product={itm} setter={setPaySchedule} paySchedule={paySchedule} /> },
      { process: (itm) => itm.type },
      {
        process: (itm) => {
          const avail = getProductAvailability(itm, schedule, {
            isWholesale,
            excludeClosedDistros: !isAdmin,
          })
          return avail ? formatDateRange(avail, 'MM/dd/yy') : ''
        },
      },
      { process: () => (paySchedule ? formatMoney(paySchedule.amount) : '-') },
      {
        widthFlex: 2,
        process: (itm) => <AddCartBtn prod={itm} paySchedule={paySchedule} />,
      },
    ],
    [schedule, paySchedule, isWholesale, AddCartBtn, isAdmin],
  )
  if (!isShare(prod)) return null

  return <ExpandableRow item={prod} index={index} columns={cols} />
})
