import { Text, Icon, Touchable } from '@elements'
import { ArrElement } from '@helpers/typescript'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { RefinementListProvided } from 'react-instantsearch-core'
import { StyleSheet, View } from 'react-native'

import { handleRefine } from './helpers'
import Colors from '../../constants/Colors'
import { globalStyles } from '../../constants/Styles'
import { HomeParamList } from '../../navigation/types'

/**
 * Displays a list of refinement options. Used in the AllFiltersModal.
 * - Assumes the attribute has been set up as a navigation param, and somewhere in the explore screen or its children components
 *  is an effect that reacts to changes in navigation param, and finally executes the call to refine().
 */
function RefinementListParam({
  paramKey,
  ...props
}: RefinementListProvided & { paramKey: keyof NonNullable<HomeParamList['ExploreScreen']> }) {
  const navigation = useNavigation<StackNavigationProp<HomeParamList, 'ExploreScreen'>>()

  const onRefinementPress = (itm: ArrElement<RefinementListProvided['items']>) => {
    function updateParam(v: string | undefined) {
      navigation.setParams({ [paramKey]: v })
    }
    handleRefine({ item: itm, listProps: props, updateParam })
  }

  return (
    <View style={styles.container}>
      {props.items.map((item) => (
        <Touchable key={item.label} style={globalStyles.flexRowCenter} onPress={() => onRefinementPress(item)}>
          <Icon
            size={25}
            color={Colors.green}
            name={`${item.isRefined ? 'check-' : ''}square`}
            solid={item.isRefined}
          />
          <Text type="medium" color={Colors.shades['400']} style={styles.marginLeft}>
            {`${item.label} (${item.count})`}
          </Text>
        </Touchable>
      ))}
    </View>
  )
}

export default RefinementListParam

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 25,
  },
  marginLeft: {
    marginLeft: 5,
  },
})
