import { useIsFocused } from '@react-navigation/native'
import React from 'react'
import { StatusBar, StatusBarProps } from 'react-native'

/** Status bar that can be attached to a specific component (i.e Header),
 * overriding the default dark-content StatusBar when focused
 */
export function LightStatusBar(props: Omit<StatusBarProps, 'barStyle'>) {
  const isFocused = useIsFocused()

  return isFocused ? <StatusBar barStyle="light-content" {...props} /> : null
}
