/* eslint-disable no-restricted-imports */
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { layoutSelector, layoutSizeSelector } from '../redux/selectors'
import { useDeepCompareMemo } from './useDeepEqualEffect'

import { DEVICE_SIZES, getDeviceSize } from '@/constants/Layout'
import { Layout } from '@/constants/types'

export type DeviceSizers = {
  size: DEVICE_SIZES
  isExtraSmallDevice: boolean
  isSmallDevice: boolean
  isMedDevice: boolean
  isLargeDevice: boolean
  isExtraLargeDevice: boolean
}

const isExtraSmallDevice = (size: DEVICE_SIZES) => size === DEVICE_SIZES.EXTRA_SMALL_DEVICE
const isSmallDevice = (size: DEVICE_SIZES) => size <= DEVICE_SIZES.SMALL_DEVICE
const isMedDevice = (size: DEVICE_SIZES) => size === DEVICE_SIZES.MEDIUM_DEVICE
const isLargeDevice = (size: DEVICE_SIZES) => size >= DEVICE_SIZES.LARGE_DEVICE
const isExtraLargeDevice = (size: DEVICE_SIZES) => size >= DEVICE_SIZES.EXTRA_LARGE_DEVICE

export type UseLayoutReturn = Layout & DeviceSizers

/**
 * Will return helpers for the device size and layout, rerenders on any size change.
 * This should replace `layoutSelector` and `useWindowDimensions` eventually.
 */
export function useLayout(): UseLayoutReturn {
  const layout = useSelector(layoutSelector)

  return useDeepCompareMemo(
    () => ({
      ...layout,
      isExtraSmallDevice: isExtraSmallDevice(layout.size),
      isSmallDevice: isSmallDevice(layout.size),
      isMedDevice: isMedDevice(layout.size),
      isLargeDevice: isLargeDevice(layout.size),
      isExtraLargeDevice: isExtraLargeDevice(layout.size),
    }),
    [layout],
  )
}

/**
 * Will return helpers for the device size. Only renders on breakpoint jumps
 */
export function useDeviceSize(): DeviceSizers {
  const size = useSelector(layoutSizeSelector)

  return useMemo(
    () => ({
      size,
      isExtraSmallDevice: isExtraSmallDevice(size),
      isSmallDevice: isSmallDevice(size),
      isMedDevice: isMedDevice(size),
      isLargeDevice: isLargeDevice(size),
      isExtraLargeDevice: isExtraLargeDevice(size),
    }),
    [size],
  )
}

export type WindowSizers = {
  size: DEVICE_SIZES
  isExtraSmallWindow: boolean
  isSmallWindow: boolean
  isMedWindow: boolean
  isLargeWindow: boolean
  isExtraLargeWindow: boolean
}

/** Will return sizer helpers for the provided window width data. Use this when you need to know the size of the current component. */
export function useWindowSizeFromWidth(...args: Parameters<typeof getDeviceSize>): WindowSizers {
  const size = getDeviceSize(...args)

  return useMemo(
    () => ({
      size,
      isExtraSmallWindow: isExtraSmallDevice(size),
      isSmallWindow: isSmallDevice(size),
      isMedWindow: isMedDevice(size),
      isLargeWindow: isLargeDevice(size),
      isExtraLargeWindow: isExtraLargeDevice(size),
    }),
    [size],
  )
}
