import { Logger } from '@/config/logger'
import { ButtonClear } from '@elements'
import { CartItem, isCartStandard } from '@models/Order'
import { useCallback } from 'react'
import { StyleSheet } from 'react-native'

type ModifyDatesButtonProps = {
  item: CartItem
  modifyDates: (id: string) => Promise<void>
}

/** Modify Dates button for opening a modal to edit product schedule */
export function ModifyDatesButton({ item, modifyDates }: ModifyDatesButtonProps) {
  const onPressModifyDates = useCallback(() => {
    try {
      modifyDates(item.id)
    } catch (err) {
      Logger.error(err)
    }
  }, [modifyDates, item])

  if (!isCartStandard(item) || item.product.disableBuyInFuture) {
    return null
  }

  return <ButtonClear style={styles.modifyDatesButtonContainer} size={14} title="Edit" onPress={onPressModifyDates} />
}

const styles = StyleSheet.create({
  modifyDatesButtonContainer: {
    padding: 0,
    margin: 0,
  },
})
