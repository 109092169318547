import { DateRange, isSeasonalSchedule, isYearRoundSchedule, Schedule } from '@models/Schedule'
import { DocumentData } from 'firebase/firestore'

import { marshalDate, unmarshalDate } from './Time'
import { prepareMarshal } from './encoding'

/** marshalSchedule returns a Firebase document representing a schedule. */
export function marshalSchedule(schedule: Schedule): DocumentData {
  const data = prepareMarshal(schedule) as DocumentData

  if (isSeasonalSchedule(schedule)) {
    data.season = marshalDateRange(schedule.season)
  }
  if (isYearRoundSchedule(schedule)) {
    data.pickupStart = marshalDate(schedule.pickupStart)
  }

  if (Array.isArray(schedule.exceptions)) {
    data.exceptions = schedule.exceptions.map((exception) => {
      const ex = exception.sourceDate
        ? {
            sourceDate: marshalDate(exception.sourceDate),
            targetDate: exception.targetDate ? marshalDate(exception.targetDate) : undefined,
          }
        : exception
      if (ex.sourceDate && !ex.targetDate) delete ex.targetDate
      return ex
    })
  }

  return data
}

/** unmarshalSchedule returns a Schedule object from a Firebase document. */
export function unmarshalSchedule(data: DocumentData): Schedule {
  const schedule = { ...data } as Schedule

  if (isSeasonalSchedule(schedule)) {
    schedule.season = unmarshalDateRange(data.season)
  }
  if (isYearRoundSchedule(schedule)) {
    schedule.pickupStart = unmarshalDate(data.pickupStart)
  }
  if (Array.isArray(data.exceptions)) {
    schedule.exceptions = data.exceptions.map((exception) => {
      if (exception.sourceDate) {
        if (!exception.targetDate)
          return {
            sourceDate: unmarshalDate(exception.sourceDate),
          }
        return {
          sourceDate: unmarshalDate(exception.sourceDate),
          targetDate: unmarshalDate(exception.targetDate),
        }
      } else {
        return exception
      }
    })
  }

  return schedule
}

/** marshalDateRange transforms a DateRange structure into a serializable structure. */
export function marshalDateRange(dateRange: DateRange): any {
  return { startDate: marshalDate(dateRange.startDate), endDate: marshalDate(dateRange.endDate) }
}

/** unmarshalDateRange transforms a serialized range structure into a DateRange type. */
export function unmarshalDateRange(dateRange: any): DateRange {
  return {
    startDate: unmarshalDate(dateRange.startDate),
    endDate: unmarshalDate(dateRange.endDate),
  }
}
