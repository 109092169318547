import { RefObject } from 'react'
import { StyleProp, TextInput, TextInputProps, TextProps, View, ViewStyle } from 'react-native'

import { IconProps } from '../Icon/Icon'

export type SearchInputBarProps = TextInputProps & {
  /** Style for the View that wraps the TextInput and icons */
  contStyle?: StyleProp<ViewStyle>
  /** Controls the spinner. When loading false, will instead show the icon */
  loading?: boolean
  /** Called when pressing the icon */
  onIconPress?: TextProps['onPress']
  /** Ref for the TextInput */
  inputRef?: RefObject<TextInput>
  /** Ref for the container View */
  contRef?: RefObject<View>
  /** Which icon to show */
  icon?: IconProps<'Feather'>['name']
  hideIcon?: boolean
  /** When present, will show a "X" button on the right, and will call this on press */
  clear?: () => void
}

export const defaultPlaceholder = 'Search...'
export const defaultIconName = 'search'
