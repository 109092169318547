import { StyleSheet, ViewStyle } from 'react-native'

import { Image } from '../../components/Image'
import { Touchable } from '../../components/elements/Touchable'
import { isSmallDevice } from '../../constants/Layout'

type Props = {
  style?: ViewStyle
  onPress?: () => void
  white?: boolean
  url?: string
  type?: 'logo' | 'icon'
}

export function LogoResponsive({ style, onPress, white, url, type }: Props) {
  return (
    <Touchable style={style} onPress={onPress} url={url}>
      {type === 'icon' || (isSmallDevice() && type !== 'logo') ? (
        <Image source={require(`../../assets/images/icon_logo.png`)} style={styles.iconLogo} />
      ) : white ? (
        <Image source={require(`../../assets/images/text_logo_white.png`)} style={styles.textLogo} />
      ) : (
        <Image source={require(`../../assets/images/text_logo.png`)} style={styles.textLogo} />
      )}
    </Touchable>
  )
}

const styles = StyleSheet.create({
  iconLogo: {
    height: 50,
    width: 87,
  },
  textLogo: {
    height: 40,
    width: 163,
    resizeMode: 'contain',
  },
})
