import { Button, Text, TextH2, Tooltip, WebLink } from '@elements'

import { StyleSheet, View } from 'react-native'

import { AdminCard } from '@/admin/components/AdminCard'
import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { adminFarmSelector } from '@/redux/selectors'
import { ToolTips } from '@components'
import { getApplicationFee } from '@helpers/applicationFee'
import { formatMoney } from '@helpers/display'
import { format } from '@helpers/time'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { sharedStyles } from './styles'
import { TabScreen } from './types'

/** Memberships info tab */
export const MembershipsTab = memo(function MembershipsTab({ isVisible }: TabScreen) {
  const farm = useSelector(adminFarmSelector)

  if (!isVisible) {
    return <View />
  }

  return (
    <View style={sharedStyles.baseGap}>
      <View style={sharedStyles.tabHeaderCont}>
        <TextH2 size={16}>Membership & Plans</TextH2>
      </View>
      <AdminCard noMargins style={styles.coopCard}>
        <View style={styles.coopMainCont}>
          <View style={globalStyles.flexRowCenter}>
            <TextH2>Farm Generations Co-op Membership</TextH2>
            <Tooltip id={ToolTips.COOP_MEMBERSHIP} title="Co-op Membership" style={styles.tooltip} />
          </View>

          <View style={styles.rowContainer}>
            <View style={styles.verticalCont}>
              <Text color={Colors.shades[400]}>Member #</Text>
              {farm.joinDate && <Text color={Colors.shades[400]}>Join Date</Text>}
            </View>

            <View style={styles.verticalCont}>
              {farm.memberId ? (
                <Text>{farm.memberId}</Text>
              ) : (
                <WebLink url="https://www.farmgenerations.coop/coop">Join now</WebLink>
              )}
              {farm.joinDate && <Text>{format(farm.joinDate, 'MMM do, yyyy')}</Text>}
            </View>
          </View>
        </View>

        {!farm.memberId && (
          <Button style={styles.joinBtn} small title="Join now" url="https://www.farmgenerations.coop/coop" />
        )}
      </AdminCard>
      <AdminCard noMargins>
        <View>
          <TextH2>GrownBy Plan</TextH2>
          <View style={styles.rowContainer}>
            <View style={styles.verticalCont}>
              <Text color={Colors.shades[400]}>Hosting Charge</Text>
              <Text color={Colors.shades[400]}>GrownBy Fee</Text>
            </View>
            <View style={styles.verticalCont}>
              <Text>{formatMoney(farm.pricingModel?.hostingFee || 0)}/MONTH</Text>
              <Text>{getApplicationFee(farm)} %</Text>
            </View>
          </View>
        </View>
      </AdminCard>
    </View>
  )
})

const styles = StyleSheet.create({
  coopMainCont: {
    flexBasis: 400,
    marginTop: 10,
    flex: 3,
  },
  coopCard: {
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  rowContainer: {
    marginVertical: 10,
    flexDirection: 'row',
  },
  tooltip: { marginLeft: 5 },

  verticalCont: {
    gap: 10,
    flexBasis: 150,
    flexShrink: 1,
  },
  joinBtn: {
    flexBasis: 150,
    flex: 1,
    alignSelf: 'flex-start',
  },
})
