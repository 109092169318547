import { DraftOrder } from '@models/DraftOrder'
import { CartItem } from '@models/Order'
import { useCustomerData_reusable } from '../OrderCreatorScreen/useCustomerData-reusable'

/** Handles customer related state for order editing */
export function useCustomerData({ draft, cart }: { draft: DraftOrder | undefined; cart: CartItem[] }) {
  return useCustomerData_reusable({
    cart,
    farmId: draft?.farm.id || '',
    cartServiceType: 'orderEdit',
    custId: draft?.user.id,
  })
}
