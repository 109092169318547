import { memo, useRef, useState } from 'react'
import { Animated, StyleSheet, View } from 'react-native'

import { globalStyles } from '../../constants/Styles'
import { WebFooter } from '@screens/Home/WebFooter'
import { BreadCrumbs } from '../elements/BreadCrumbs'
import { Divider } from '../elements/Divider'
import { LoadingView } from '../elements/LoadingView'
import { ScreenView } from '../elements/ScreenView'
import { ParallaxMediaCarousel } from './ParallaxMediaCarousel'
import { ParallaxProps } from './index'

import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { useFocusFx } from '@/hooks/useFocusFx'

export default memo(function ParallaxHeaderWeb({ loading = false, webElements, media, children, back }: ParallaxProps) {
  const yScroll = useRef(new Animated.Value(0)).current
  const [scrollDist, setScrollDist] = useState(0) //the available sthe available space for the sticky to animate its vertical position.
  const [contentHeight, setContentHeight] = useState(0)
  const [stickyHeight, setStickyHeight] = useState(0)

  useFocusFx(() => {
    setScrollDist(Math.max(contentHeight - stickyHeight, 0))
  }, [contentHeight, stickyHeight])

  const { body } = useLayoutFnStyles(({ screenWidth }) => ({
    body: {
      width: screenWidth - 40,
      padding: 20,
      maxWidth: 1200,
      alignSelf: 'center',
    },
  }))

  return (
    <ScreenView>
      <Animated.ScrollView
        scrollEventThrottle={1}
        onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: yScroll } } }], {
          useNativeDriver: false, // This is the web only component
        })}
      >
        <View style={body}>
          <BreadCrumbs goBack={back} />
          <LoadingView style={styles.topSection} loading={loading}>
            <View style={globalStyles.flex1} onLayout={(e) => setContentHeight(e.nativeEvent.layout.height)}>
              <ParallaxMediaCarousel style={styles.carousel} media={media} />
              {webElements?.content && (
                <>
                  <Divider large clear />
                  <View>{webElements.content}</View>
                </>
              )}
            </View>
            <View style={styles.rightCont}>
              <Animated.View
                style={[
                  styles.sticky,
                  {
                    transform: [
                      {
                        translateY: yScroll.interpolate({
                          inputRange: [0, scrollDist],
                          outputRange: [0, scrollDist],
                          extrapolate: 'clamp',
                        }),
                      },
                    ],
                  },
                ]}
                onLayout={(e) => setStickyHeight(e.nativeEvent.layout.height)}
              >
                {webElements?.sticky}
              </Animated.View>
            </View>
          </LoadingView>
          {children && !loading ? (
            <>
              <Divider large />
              {children}
            </>
          ) : null}
        </View>
        {!loading && <WebFooter />}
      </Animated.ScrollView>
    </ScreenView>
  )
})

const styles = StyleSheet.create({
  carousel: { borderRadius: 10 },
  rightCont: {
    flex: 0.7,
    paddingHorizontal: 40,
  },
  topSection: {
    flexDirection: 'row',
  },
  sticky: {
    minWidth: '85%',
  },
})
