import * as ImagePicker from 'expo-image-picker'
import { PropsWithChildren } from 'react'
import { Alert, Text } from '@elements'
import { useDropzone } from 'react-dropzone'
import { StyleSheet, View } from 'react-native'
import { FontAwesome5 } from '@expo/vector-icons'
import Colors from '../constants/Colors'

type ImageDropzoneProps = {
  /** Will be called with the image when a new image is dropped*/
  onImageAdded: (image: ImagePicker.ImagePickerAsset) => void
}

/** When a new image is dropped it should process the image. */
const onDrop = (onImageAdded: ImageDropzoneProps['onImageAdded']) => (acceptedFiles: File[]) => {
  acceptedFiles.forEach((file) => {
    const reader = new FileReader()

    if (!file.type.startsWith('image')) {
      Alert('Could not upload file', 'Only images can be uploaded at this time.')
    }

    reader.onerror = () =>
      Alert(
        'Could not upload image',
        'Unable to upload this image, please try clicking the button to upload this file.',
      )

    reader.onload = () => {
      // Ensure reader.result is treated as ArrayBuffer
      const arrayBuffer = reader.result as ArrayBuffer
      const blob = new Blob([arrayBuffer], { type: file.type })
      const url = URL.createObjectURL(blob)
      const image = new Image()
      image.src = url

      image.onload = function () {
        const imageAsset: ImagePicker.ImagePickerAsset = {
          uri: url,
          width: image.width,
          height: image.height,
          type: file.type.startsWith('video') ? 'video' : 'image', // Handle type based on the file type
          fileName: file.name,
        }

        // Read the file as Data URL to get Base64
        const base64Reader = new FileReader()
        base64Reader.readAsDataURL(file)
        base64Reader.onloadend = () => {
          imageAsset.uri = base64Reader.result as string // Ensure the result is treated as string
          onImageAdded(imageAsset)
        }
      }
    }

    // Start reading the file as ArrayBuffer
    reader.readAsArrayBuffer(file)
  })
}
/** Web only component that allows for dropping images to upload them*/
export function ImageDropzone({ onImageAdded, children }: PropsWithChildren<ImageDropzoneProps>) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: onDrop(onImageAdded) })

  return (
    <div {...getRootProps()} style={{ ...styles.defaultStyle, ...(isDragActive && styles.activeDragStyle) }}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <View style={styles.activeDragWrapper}>
          <FontAwesome5 size={23} name="plus" color={Colors.green} />
          <Text color={Colors.shades[200]}>Drop Here</Text>
        </View>
      ) : (
        children
      )}
    </div>
  )
}

const styles = StyleSheet.create({
  activeDragWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  defaultStyle: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  activeDragStyle: {
    // @ts-expect-error: This is not valid React Native styles and should only be used on web in React environments
    border: `4px dashed ${Colors.green}`,
    backgroundColor: Colors.lightGreen,
  },
})
