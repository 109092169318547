import { withAdminAuth } from '@/hooks/withAdminAuth'
import { adminFarmIdSelector } from '@/redux/selectors'
import { HeaderText } from '@elements'
import { MessagesScreenComp } from '@screens/MessagesScreen/MessagesScreen'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

function AdminMessagesScreenComp() {
  const adminFarmId = useSelector(adminFarmIdSelector)

  return (
    <View style={styles.main}>
      <HeaderText size={24}>Messages</HeaderText>
      <MessagesScreenComp adminFarmId={adminFarmId} />
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    flex: 1,
    padding: 20,
    gap: 10,
  },
})

/** Admin Messages Screen. Will be the same as on the consumer side but with Admin Auth Check and a wrapper*/
export const AdminMessagesScreen = withAdminAuth(AdminMessagesScreenComp)
