import { HeaderText, Icon } from '@elements'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { memo, useCallback, useEffect, useState } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import { toggleFarmAssociationFavorite } from '../api/FarmAssociations'
import { WithAuthCallback } from '../hooks/WithAuthCallback'
import { Farm, FarmStatus } from '../models/Farm'
import { FarmAssociation, User } from '../models/User'
import { RootState } from '../redux/reducers/types'
import { farmsSelector, userSelector } from '../redux/selectors'
import { Rating } from './Rating'
import { Toast } from './elements/Overlays/Popups'

import { Logger } from '@/config/logger'
import Colors from '@/constants/Colors'

type Props = {
  farm: Farm
}

export const FavoriteFarm = memo(function FavoriteFarm({ farm }: Props) {
  const [shouldRunToggle, setShouldRunToggle] = useState(false)
  const [isStarred, setIsStarred] = useState<boolean | undefined>()
  const [numFavorites, setNumFavorites] = useState(0)

  const user = useSelector<RootState, User>(userSelector)
  const myFarms = useSelector<RootState, FarmAssociation[]>(farmsSelector)

  //Check if user has farm in favorites
  useEffect(() => {
    if (!user.id) return

    if (myFarms.find((f) => f.isFavorite && f.id === farm.id)) {
      setIsStarred(true)
    } else {
      setIsStarred(false)
    }
  }, [farm, myFarms, user?.id])

  // Update numFavorites
  useEffect(() => {
    setNumFavorites(farm.numFavorites ?? 0)
  }, [farm.numFavorites])

  /** This toggle must be run only if user is signed in */
  const toggleFavorite = useCallback(() => {
    if (!farm) return
    setNumFavorites(isStarred ? numFavorites - 1 : numFavorites + 1)
    setIsStarred((prev) => !prev)
    setShouldRunToggle(false)
    toggleFarmAssociationFavorite(farm.id, !!isStarred)
      .then(() => {
        Toast(isStarred ? 'Farm removed from favorites' : 'Farm added to favorites')
      })
      .catch((e) => {
        // Undo the optimistic state changes if there's an error
        Logger.error(e)
        Toast('Could not toggle favorite')
        setIsStarred(isStarred)
        setNumFavorites(numFavorites)
      })
  }, [farm, isStarred, numFavorites])

  return (
    <>
      {shouldRunToggle && (
        /** Handles toggle based on auth state */
        <WithAuthCallback
          // will be run if user is signed in. Else, auth modal will show, and will run after user signs in
          callback={() => toggleFavorite()}
          // set it to false, to make sure it runs only once
          onDismiss={() => setShouldRunToggle(false)}
        />
      )}
      <View style={{ flexDirection: 'row', alignItems: 'center', minHeight: 20 }}>
        {farm.status === FarmStatus.Registered && <Rating ratings={farm.reviews} />}
        <Icon
          name="heart"
          color={isStarred ? Colors.red : Colors.lightGray}
          size={15}
          noHover
          solid
          onPress={() => setShouldRunToggle(true)}
        />
        <HeaderText color={Colors.shades[300]} style={{ marginLeft: 5, marginRight: 15 }} size={12}>
          ({numFavorites})
        </HeaderText>
      </View>
    </>
  )
}, propsAreDeepEqual)
