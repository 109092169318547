// The maximum decimal places we will consider when rounding, this helps prevent an overflow if we are trying to multiply
// two numbers with lots of decimal places, see tests for examples
const MAX_DECIMAL_PLACES = 15

/** Will perform computations on two decimals preserving the correct decimal places */
export class DecimalCalc {
  public static add(...nums: number[]): number {
    return DecimalCalc._decimalReducer(nums, 'add')
  }

  public static subtract(num1: number, num2: number): number {
    return DecimalCalc._operationWithPrecision(num1, num2, 'subtract')
  }

  public static multiply(...nums: number[]): number {
    return DecimalCalc._decimalReducer(nums, 'multiply')
  }

  public static divide(num1: number, num2: number): number {
    return DecimalCalc._operationWithPrecision(num1, num2, 'divide')
  }

  /** Round a number to a certain precision. Will default to round as a whole number.
   * Precision is the number of decimal places to round to, however you can use -precision to round 12 to 10.
   * See tests for examples */
  public static round(num1: number, precision = 0): number {
    const factor = Math.pow(10, precision)
    return Math.round(num1 * factor) / factor
  }

  // Utility method to apply an operation to an array of numbers
  private static _decimalReducer = (values: number[], opp: 'add' | 'subtract' | 'multiply' | 'divide') =>
    values.reduce((prev: number, curr: number) => DecimalCalc._operationWithPrecision(prev, curr, opp))

  // Utility method to calculate the number of decimal places
  private static _decimalPlaces(num: number): number {
    // If the number is an integer, it has no decimal places
    if (Number.isInteger(num)) return 0

    let e = 1,
      p = 0
    // Limit to a maximum of 15 decimal places
    while (Math.round(num * e) / e !== num && p < MAX_DECIMAL_PLACES) {
      e *= 10
      p++
    }
    return p
  }

  // Generic method to perform any operation with precision
  private static _operationWithPrecision(
    num1: number,
    num2: number,
    operation: 'add' | 'subtract' | 'multiply' | 'divide',
  ): number {
    const precision1 = this._decimalPlaces(num1)
    const precision2 = this._decimalPlaces(num2)
    const precision = Math.max(precision1, precision2)

    const factor = Math.pow(10, precision)
    // Round these numbers in case they had more than 15 decimal places, the _decimalPlaces exited early as we don't want more precision than that
    const n1 = Math.round(num1 * factor)
    const n2 = Math.round(num2 * factor)

    let result: number
    switch (operation) {
      case 'add':
        result = (n1 + n2) / factor
        break
      case 'subtract':
        result = (n1 - n2) / factor
        break
      case 'multiply':
        // Dividing by factor twice is better than / (factor * factor) because it helps keep the calculation smaller
        result = (n1 * n2) / factor / factor
        break
      case 'divide':
        // We do not check for division by 0 issue as JS defaults to using Infinity, so we maintain that behavior
        result = n1 / n2
        break
      default:
        throw new Error('Invalid operation.')
    }

    return result
  }
}

export default DecimalCalc
