import { ImageSourcePropType, StyleProp, ImageStyle } from 'react-native'

type FarmerOnboardHeroAsset = {
  source: ImageSourcePropType
  style: StyleProp<ImageStyle>
}

export const farmerOnboardHeroAssets: FarmerOnboardHeroAsset[] = [
  {
    source: require('./watermelon.png'),
    style: { width: 386, height: 264 },
  },
  {
    source: require('./miroshnichenko.png'),
    style: { width: 386, height: 282 },
  },
  {
    source: require('./carrots-cut-off.png'),
    style: { width: 386, height: 44 },
  },
  {
    source: require('./cows-on-farm.png'),
    style: { width: 399, height: 167 },
  },
  {
    source: require('./two-farmers-portrait.png'),
    style: { width: 399, height: 318 },
  },
  {
    source: require('./vegetable-cut-off.png'),
    style: { width: 399, height: 88 },
  },
]
