import { omit, pick } from '@helpers/typescript'
import { SupportTicket } from '@models/SupportTicket'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'
import { prepareMarshal, prepareUnmarshal } from './encoding'

/** marshalSupportTicket returns the supplied Support Ticket data as a Firebase document structure.  */
export function marshalSupportTicket(ticket: Partial<SupportTicket>): DocumentData {
  const data = omit(prepareMarshal(ticket), 'id') as DocumentData

  if (data.user) {
    data.user = pick(data.user, 'id', 'email', 'name')
  }

  return data
}

/** unmarshalSupportTicket transforms the Firestore data to an Support Ticket object. */
export function unmarshalSupportTicket(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
) {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)
  return { ...data, id } as SupportTicket
}
