import { Text } from '@elements'
import { usePortal } from '@gorhom/portal'
import React, { useCallback, useEffect, useRef } from 'react'
import { Animated, Easing, Pressable, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import Colors from '../../../../constants/Colors'
import { globalStyles } from '../../../../constants/Styles'
import { isAdminOpenSelector } from '../../../../redux/selectors'
import { ToastProps } from './ToastComponent'

import { useSizeFnStyles } from '@/hooks/useFnStyles'

/** Web version of appWide toast dialog */
export function ToastComponent({ msg }: ToastProps) {
  const { removePortal } = usePortal()
  const isAdmin = useSelector(isAdminOpenSelector)
  const animatedValue = useRef(new Animated.Value(-50))

  const styles = useStyles()

  const close = useCallback(() => removePortal('toastHost'), [removePortal])

  useEffect(() => {
    let isCurrent = true

    if (!msg) return
    Animated.timing(animatedValue.current, {
      toValue: isAdmin ? 30 : 90, // on customer side, toast should be lower and under header
      duration: 300,
      easing: Easing.linear,
      useNativeDriver: false,
    }).start()

    setTimeout(() => {
      if (isCurrent) close() // Prevents close from being called twice
    }, 5000)

    return () => {
      isCurrent = false
    }
  }, [msg, close, isAdmin])

  if (!msg) return null

  return (
    <View style={styles.container} pointerEvents="box-none">
      <Animated.View
        style={[
          styles.overlay,
          {
            top: animatedValue.current,
          },
        ]}
      >
        <Pressable onPress={close} style={styles.touchable}>
          <Text size={15} color={Colors.white}>
            {msg}
          </Text>
        </Pressable>
      </Animated.View>
    </View>
  )
}

const useStyles = () =>
  useSizeFnStyles(({ isLargeDevice, isMedDevice }) => ({
    overlay: {
      position: 'absolute',
      opacity: 0.9,
      right: 20,
      alignItems: 'center',
      alignSelf: 'flex-end',
      justifyContent: 'center',
      backgroundColor: Colors.darkGreen,
      borderRadius: 10,
      maxWidth: isLargeDevice ? 450 : isMedDevice ? 350 : '90%',
    },
    container: {
      ...StyleSheet.absoluteFillObject,
      justifyContent: 'center',
      alignItems: 'center',
    },
    touchable: globalStyles.padding10,
  }))
