import Colors from '@/constants/Colors'
import { Text } from '@elements'
import { formatTime } from '@helpers/display'
import { getMessageText } from '@helpers/messaging'
import { ChatMessage } from '@models/Messaging'
import { userName } from '@models/User'
import { StyleSheet, View } from 'react-native'

type ChatBubbleProps = {
  /** Message data to show */
  item: ChatMessage
  /** Current user's id, used for showing different UI for the user's own messages */
  currUserId: string
}

/** Component that shows the message content and message details */
export function ChatBubble({ item, currUserId }: ChatBubbleProps) {
  const isOwnChat = currUserId === item.sender.id

  return (
    <View style={[styles.bubble, isOwnChat && styles.alignRight]}>
      <View style={styles.usernameWrapper}>
        <Text size={14} type="medium">
          {isOwnChat ? 'You' : userName(item.sender)}
        </Text>
        <Text size={12} color={Colors.primaryGray}>
          {formatTime(item.sentAtUtc.toLocal())}
        </Text>
      </View>

      <Text>{getMessageText(item)}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  bubble: {
    borderRadius: 10,
    backgroundColor: Colors.semiGreen,
    margin: 10,
    padding: 10,
    alignSelf: 'flex-start',
    gap: 10,
  },
  alignRight: {
    alignSelf: 'flex-end',
  },
  usernameWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
})
