/**
 * This file should hold any product helpers dedicated to generating strings for display purposes.
 * - It should be compatible with server usage because only strings are being generated from data objects. Therefore please do not include any client dependencies incompatible with server project.
 */

import { EbtEligibility } from '@models/Product'
import { plural } from './display'

/**
 * @param ebtEligibility - The EBT eligibility value.
 * @returns The readable representation of the EBT eligibility.
 */
export const getReadableEbtEligibility = (ebtEligibility: EbtEligibility): string => {
  switch (ebtEligibility) {
    case EbtEligibility.EbtEligible:
      return 'SNAP Eligible'
    case EbtEligibility.EbtOnly:
      return 'SNAP Only'
    case EbtEligibility.NotEbtEligible:
    default:
      return 'Not SNAP Eligible'
  }
}

/** Since product count is being stored as an estimate we need to display it correctly in the UI. For 0-10 product count is the true count, then above that we round down to a reasonable number. So we should display + to indicate it is not an exact count. For example if the product has 25 products we say 20+, if they have 240 we say 200+ and over 1000 we say 1000+ */
export function displayProductCount(productCount: number): string {
  if (productCount === 0) {
    return 'No products in stock'
  }
  if (productCount < 10) {
    return `${productCount} ${plural(productCount, 'product')} in stock`
  }
  return `${productCount}+ products in stock`
}

export const defaultAddonNotAvailableText =
  'To purchase this addon you must first add a matching Primary Share to your cart'
