import Colors from '@/constants/Colors'
import { useDeviceSize } from '@/hooks/useLayout'
import { Divider, HeaderText, Icon, WebLink } from '@elements'
import { openUrl } from '@helpers/client'
import { unmarshalPhoneNumber } from '@helpers/display'
import { Farm } from '@models/Farm'
import { memo } from 'react'
import { StyleSheet, View } from 'react-native'

export const ContactUs = memo(function ContactUs({
  website,
  phoneNumber,
  email,
  socialmedia,
}: Pick<Farm, 'website' | 'phoneNumber' | 'email' | 'socialmedia'>) {
  const { isSmallDevice } = useDeviceSize()
  return (
    <View style={!isSmallDevice && styles.largeCont}>
      <HeaderText size={18}>Contact Us</HeaderText>
      {!!website && (
        <View style={styles.contactItem}>
          <Icon name="globe" size={25} />
          <WebLink url={website}>{website}</WebLink>
        </View>
      )}
      {!!phoneNumber && (
        <View style={styles.contactItem}>
          <Icon name="phone" size={25} />
          <WebLink url={`tel:${phoneNumber}`}>{unmarshalPhoneNumber(phoneNumber)}</WebLink>
        </View>
      )}
      {!!email && (
        <View style={styles.contactItem}>
          <Icon name="envelope" size={25} />
          <WebLink url={`mailto:${email}`}>{email}</WebLink>
        </View>
      )}
      <Divider clear />
      {!!socialmedia && Object.values(socialmedia!).some((i) => i) && (
        <>
          <HeaderText size={18}>Follow Us</HeaderText>
          <View style={styles.contactItem}>
            {!!socialmedia?.instagram && (
              <Icon
                style={styles.socialMedia}
                name="instagram"
                size={25}
                color={Colors.black}
                onPress={() => openUrl(socialmedia?.instagram || '')}
              />
            )}
            {!!socialmedia?.facebook && (
              <Icon
                style={styles.socialMedia}
                name="facebook"
                size={25}
                color={Colors.facebook}
                onPress={() => {
                  openUrl(socialmedia?.facebook || '')
                }}
              />
            )}
            {!!socialmedia?.twitter && (
              <Icon
                style={styles.socialMedia}
                name="twitter"
                size={25}
                color={Colors.twitter}
                onPress={() => {
                  openUrl(socialmedia?.twitter || '')
                }}
              />
            )}
          </View>
        </>
      )}
    </View>
  )
})

const styles = StyleSheet.create({
  largeCont: {
    borderWidth: 1,
    borderColor: Colors.shades['100'],
    borderRadius: 10,
    padding: 20,
  },
  socialMedia: {
    marginHorizontal: 10,
  },

  contactItem: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 15,
    marginTop: 10,
  },
})
