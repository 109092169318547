import { capitalizeFirst } from '@helpers/display'
import { isAfter } from '@helpers/time'
import { Pickup, PickupItem, PickupItemStatus, isPickupItemActive } from '@models/Order'
import { dateTimeInZone } from '@models/Timezone'
import { ShareData } from '../AdminCustomerSubscriptionsSection/AdminCustomerSubscriptions'
import { matchPickupItemsFromProductAndOrder } from '@helpers/pickups'

/** This helper will be use to configure each subscription overall status, only (Cancelled, Completed, and Active) */
export function configureSubscriptionStatus(
  cancelled: boolean,
  isPast: boolean,
  shareMap: Map<string, ShareData>,
  prodId: string,
  orderId: string,
): string {
  //If all pickups are cancelled (this item in the order is cancelled), then show cancelled
  if (cancelled) {
    return capitalizeFirst(PickupItemStatus.Cancelled)
  } else {
    const key = `${prodId}_${orderId}`

    //If any pickups associated with this Subscription are associated with a SignInSummary, and have a status of active then we want to display active and not "Completed". (This is because some customers may pick up after a sign in sheet, so we don't want to mark them completed if the farmer is using sign in sheets and doesn't mark it received)
    const shouldDisplayActive = (shareMap.get(key)?.pickups ?? []).some(
      (pickup) =>
        !!pickup.items.find(
          (item) =>
            item.status === PickupItemStatus.Active &&
            matchPickupItemsFromProductAndOrder(item.product.id, orderId)(item),
        ) && !!pickup.signInSummary?.id,
    )

    if (shouldDisplayActive) return capitalizeFirst(PickupItemStatus.Active)
    //If all pickups are in the past, then just show completed
    else if (isPast) return 'Completed'
    //Otherwise, show active
    else return capitalizeFirst(PickupItemStatus.Active)
  }
}

/** This helper is to configure the correct pickupItem status based on whether the pickup is associated with opened digital signIn-sheet  */
export function configurePickupItemStatus(pickup: Pickup, item: PickupItem): string {
  // If the status of the pickupItem is not active, then show the status
  if (!isPickupItemActive(item)) {
    return capitalizeFirst(item.status)
  } else {
    // If the pickupItem is active, and the pickup is associated with a signInSummary, then display active
    if (pickup.signInSummary?.id) {
      return capitalizeFirst(PickupItemStatus.Active)
    } else {
      // If the pickupItem is active, and the pickup is not associated with a signInSummary, and the pickup was in the past, then display completed
      const now = dateTimeInZone(pickup.date.zoneName)
      const isPast = isAfter(now, pickup.date, { granularity: 'day', zone: pickup.date.zoneName })
      if (isPast) {
        return 'Completed'
      } else {
        // If the pickupItem is active, and the pickup is not associated with a signInSummary, and the pickup is in the future, then display active
        return capitalizeFirst(PickupItemStatus.Active)
      }
    }
  }
}
