import { useFocusFx } from '@/hooks/useFocusFx'
import { useMemo } from 'react'
import { UseMenuProps, useClearRefinements, useMenu } from 'react-instantsearch'

type Props = {
  value: string | undefined
} & UseMenuProps

/** Algolia refinement helper to filter data by a specific property */
export const usePropRefinement = ({ attribute, value, limit = 50, sortBy = ['name'] }: Props) => {
  /** Creates algolia filters. On refine, the value passed as argument will be selected*/
  const { items, refine, canRefine, ...rest } = useMenu({ attribute, limit, sortBy })

  /** This will clear the current refinement */
  const { refine: clearRefinement } = useClearRefinements({ includedAttributes: [attribute] })

  /** This keeps the current algolia filter refinement in sync with the refinement value from params */
  useFocusFx(
    () => {
      if (!canRefine) {
        // If we cannot refine, we should neither refine nor clear the refinement, to prevent an infinite loop
        return
      }
      if (value) {
        refine(value)
      } else {
        clearRefinement()
      }
    },
    [value, canRefine, refine, clearRefinement],
    { noRefocus: true },
  )

  /** Current selected filter. Undefined if no selected  */
  const currentFilter = useMemo(() => {
    const selectedCategory = items.find((el) => el.isRefined)
    return selectedCategory
  }, [items])

  return { currentFilter, items, clearRefinement, canRefine, ...rest }
}
