import { Spinner } from '@elements'
import { StyleSheet, View } from 'react-native'
import { MessageWithIcon } from '@components'

/** A callback that stripe sends responses to if it needs additional verification for a card */
export function StripeAuthorizationCallback() {
  // This will send a message telling the parent to close this webview
  //@ts-ignore
  window.top.postMessage('close-iframe')
  return (
    <View style={styles.container}>
      <Spinner />
    </View>
  )
}

/** This shows a landing page for an in-app browser telling the user to close it. */
export function InAppBrowserReturn() {
  return (
    <View style={styles.container}>
      <MessageWithIcon title="Success" icon="check">
        Please close this window and return to GrownBy.
      </MessageWithIcon>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
})
