import { marshalDate, unmarshalDate } from '@api/encoding/Time'
import { isJest } from '@helpers/Platform'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { DeepPartial, Reducer, Store, applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { Persistor, createTransform, persistReducer, persistStore } from 'redux-persist'
import { PersistConfig, TransformInbound } from 'redux-persist/es/types'
import { PersistPartial } from 'redux-persist/lib/persistReducer'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import thunk from 'redux-thunk'

import { DateTime } from 'luxon'
import env from '../../config/Environment'
import rootReducer, { persistWhiteList as defaultPersistWhiteList, initialRootState } from '../reducers'
import { getPreloadedState } from '../reducers/getPreloadedState'
import { RootState } from '../reducers/types'

/** This is necessary to ensure dates get correctly serialized in redux persist, and get rehydrated correctly as well. */
DateTime.prototype.toJSON = function () {
  return JSON.stringify({ ...marshalDate(this), _dateTime: true })
}

/**
 * Creates a store, with an optional preloaded state. Usable for both tests and the main app.
 * @param preloadedState overwrites the default initial state for redux store. If undefined, the store will initialize with a clean default.
 * @param persistWhiteList the slices of the root state that will be persisted. If none, will use default whitelist. If empty array, nothing will be persisted.
 * @param overwritePersist whether the resulting preloaded state should overwrite the state in the persistWhiteList. The first time the store is initialized, this is best if true. Once there's already data persisted, best if false.
 * @returns `{ store: Store; persistor: Persistor }`
 */
export const storeCreator = async (
  preloadedState?: DeepPartial<RootState>,
  persistWhiteList: (keyof RootState)[] = defaultPersistWhiteList,
  overwritePersist?: boolean,
): Promise<{ store: Store; persistor: Persistor }> => {
  let storeKey = `grownby3.0-${env.APP_ENV}`
  // Don't mix persisted data from dev to emulator and vice versa
  if (env.IS_EMULATOR) storeKey += '_emulator'
  if (env.IS_COMP) storeKey += '_components'
  if (env.IS_WHOLESALE) storeKey += '_wholesale'

  /** If no value provided for overwritePersist, we can check whether there is an existing store for the current storeKey */
  if (overwritePersist === undefined && !isJest) {
    const savedKeys = await AsyncStorage.getAllKeys()
    //If the key already exists, we don't overwrite. Else we write the resulting preloaded state
    overwritePersist = !savedKeys.includes('persist:' + storeKey)
  }

  const preloadedStateResult = getPreloadedState<RootState & PersistPartial>(
    initialRootState,
    overwritePersist,
    preloadedState,
  )

  // This key will separate persisted data across different builds.
  const persistConfig: PersistConfig<RootState> = {
    key: storeKey,
    whitelist: persistWhiteList,
    storage: AsyncStorage,
    stateReconciler: autoMergeLevel2,
    transforms: [createTransform(deHydrate, reHydrate, { whitelist: persistWhiteList })],
  }

  const finalReducer = (isJest ? rootReducer : persistReducer<RootState>(persistConfig, rootReducer)) as Reducer<
    RootState & PersistPartial
  >
  const composedEnhancers = composeWithDevTools(applyMiddleware(thunk))
  const store = createStore(finalReducer, preloadedStateResult, composedEnhancers)
  const persistor = persistStore(store)

  return { store, persistor }
}

export const deHydrate = (toDehydrate: TransformInbound<any, string, RootState>) => JSON.stringify(toDehydrate)

export const reHydrate = (toRehydrate: string) =>
  JSON.parse(toRehydrate, (_key, value) => {
    if (typeof value === 'string' && value.includes('_dateTime')) {
      return unmarshalDate(JSON.parse(value))
    } else {
      return value
    }
  })
