import { adminFarmIdSelector, adminFarmSelector } from '@/redux/selectors'
import { CartItem } from '@models/Order'
import { memo, useContext } from 'react'
import { useSelector } from 'react-redux'
import { OrderCreatorScreenContext } from '../../OrderCreatorScreen.helper'
import { OrderSummaryItem_Reusable } from './OrderSummaryItem-reusable'

/** Adapter for the order creator to use the reusable component for order summary item */
export const OrderSummaryItem = memo(function OrderSummaryItem({ itemId }: { itemId: CartItem['id'] }) {
  const adminFarmId = useSelector(adminFarmIdSelector)
  const adminFarm = useSelector(adminFarmSelector)

  const { isWholesaleOrderCreator } = useContext(OrderCreatorScreenContext)

  if (adminFarmId !== adminFarm.id) return null

  return (
    <OrderSummaryItem_Reusable
      cartServiceType="orderCreator"
      farm={adminFarm}
      isWholesale={isWholesaleOrderCreator}
      itemId={itemId}
    />
  )
})
