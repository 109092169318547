import { FormButton } from '@elements'
import { isFunction } from '@helpers/helpers'
import { Formik, FormikConfig, FormikProps } from 'formik'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'

import { Button } from './elements/Button'
import Colors from '../constants/Colors'
import { Layout } from '../constants/types'
import { useLayout } from '../hooks/useLayout'

export type FormikStepProps<FormikValues> = {
  validationSchema?: any | (() => any)
  children: (props: FormikProps<FormikValues>) => React.ReactElement
  form?: FormikProps<FormikValues>
}

export const FormikStep = <FormikValues,>({ children, form }: FormikStepProps<FormikValues>): React.ReactElement => {
  return isFunction(children) ? children(form!) : children
}
export function FormikStepper<FormikValues extends object>({
  children,
  forceStep,
  setForceStep,
  hasBack,
  backAction,
  forceDisable = false,
  ...props
}: FormikConfig<FormikValues> & {
  forceStep?: number
  setForceStep?: (val: number) => void
  hasBack?: boolean
  backAction?: () => void
  forceDisable?: boolean
}) {
  const childrenArray = React.Children.map<
    FormikConfig<FormikValues>['children'],
    FormikConfig<FormikValues>['children']
  >(children, (x) => x) as React.ReactElement<FormikStepProps<FormikValues>>[]
  const [step, setStep] = useState(0)
  const currentChild = childrenArray[step]
  const layout = useLayout()

  // This allows you to force the picker to jump to a certain step
  useEffect(() => {
    if (forceStep === undefined || !setForceStep) return
    if (forceStep !== -1 && forceStep !== step) setStep(forceStep)
    setForceStep(-1)
  }, [forceStep])

  function isLastStep() {
    return step === childrenArray.length - 1
  }

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      enableReinitialize
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props.onSubmit(values, helpers)
          setStep(0)
        } else {
          setStep((s) => s + 1)
          helpers.setTouched({})
        }
      }}
    >
      {(form) => (
        <>
          {React.cloneElement(currentChild, { form })}
          <View style={styles.containerRow}>
            {step > 0 || hasBack ? (
              <Button
                style={buttonNav(layout)}
                color={Colors.semiTransparent}
                title="Back"
                disabled={form.isSubmitting}
                onPress={!hasBack ? () => setStep((s) => s - 1) : backAction}
              />
            ) : null}

            <FormButton
              style={buttonNav(layout)}
              title={form.isSubmitting ? 'Submitting' : isLastStep() ? 'Submit' : 'Next'}
              disabled={form.isSubmitting ? form.isSubmitting : isLastStep() ? forceDisable : false}
              onPress={form.handleSubmit}
            />
          </View>
        </>
      )}
    </Formik>
  )
}

const buttonNav = (layout: Layout) => ({
  width: layout.width / 3,
  maxWidth: 200,
})

const styles = StyleSheet.create({
  containerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 20,
  },
})
