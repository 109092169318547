import { hasOwnProperty } from '@helpers/helpers'
import { memo, useCallback, useMemo } from 'react'
import { FlatList, FlatListProps, ListRenderItem, ScaledSize, ViewStyle } from 'react-native'

import { DEVICE_SIZES, getDeviceSize } from '../constants/Layout'

import { useDeviceSize } from '@/hooks/useLayout'

/** Controls the size of the columns */
export enum ColumnSize {
  Large = 0,
  Medium = 1,
  Small = 2,
}

export const getNumCols = (deviceSize: number, columnSize: ColumnSize = ColumnSize.Medium) => {
  return Math.max(deviceSize + columnSize, 1)
}

/** Calculates the flex value for the list children.
 * This should be added to the style of renderItem component of the ResponsiveList
 */
export function containerFlex(layout: Pick<ScaledSize, 'width'>, size: ColumnSize = ColumnSize.Medium): ViewStyle {
  const screenSizeNum = getDeviceSize(layout.width)
  return {
    flex: 1 / getNumCols(screenSizeNum, size),
    // maxWidth is important for small devices where the list might be horizontal
    maxWidth: Math.floor(layout.width * 0.9),
  }
}

export type ResponsiveListProps<T> = {
  data: T[]
  keyOverride?: (item: T, idx: number) => string
  /** This columnSize value determines how many columns will appear for the given screen width */
  columnSize?: ColumnSize
  renderItem(item: T): JSX.Element
  /** If true, smallHorizontal will make it so on small devices the flatList becomes horizontally scrolling instead of using the resulting number of columns */
  smallHorizontal?: boolean
  /** The device size to use for calculation of number of columns. If undefined, will get the default device size. This can help when you want the device size of a modal instead of the real device size */
  deviceSize?: DEVICE_SIZES
} & Omit<FlatListProps<T>, 'renderItem'>

function ResponsiveListComp<T>({
  renderItem,
  data,
  keyOverride,
  columnSize = ColumnSize.Medium,
  deviceSize: deviceSizeProp,
  smallHorizontal = false,
  columnWrapperStyle: columnWrapperStyleProp,
  ...otherProps
}: ResponsiveListProps<T>) {
  const { isSmallDevice, size: deviceSizeReal } = useDeviceSize()
  const deviceSize = deviceSizeProp ?? deviceSizeReal
  const horizontal = !!(smallHorizontal && isSmallDevice)
  const numCols = getNumCols(deviceSize, columnSize)

  const render: ListRenderItem<T> = useCallback(({ item }) => renderItem(item), [renderItem])

  const keyExt: FlatListProps<T>['keyExtractor'] = useCallback(
    (item: T, idx: number): string => {
      if (keyOverride) return keyOverride(item, idx)
      return hasOwnProperty(item, 'id') && typeof item.id === 'string' ? item.id : idx.toString()
    },
    [keyOverride],
  )

  const columnWrapperStyle = useMemo(
    () => (horizontal || numCols <= 1 ? undefined : columnWrapperStyleProp),
    [columnWrapperStyleProp, horizontal, numCols],
  )

  return (
    <FlatList
      numColumns={horizontal ? undefined : numCols} // If it's in horizontal mode, numCols must not be used
      /**Key must be deviceSize because if numCols changes while re-rendering, FlatList throws error:
       * "Changing numColumns on the fly is not supported. Change the key prop on FlatList when changing the number of columns to force a fresh render of the component." */
      key={deviceSize}
      horizontal={horizontal} // In horizontal mode, it is horizontally scrollable, so it no longer uses columns
      columnWrapperStyle={columnWrapperStyle}
      data={data}
      keyExtractor={keyExt}
      renderItem={render}
      {...otherProps}
    />
  )
}

export const ResponsiveList = memo(ResponsiveListComp) as typeof ResponsiveListComp
