import { SafeAreaView, Spinner, hideModal } from '@elements'
import { useCallback, useState } from 'react'
import { Platform, View, ViewStyle } from 'react-native'
import { WebViewNavigation } from 'react-native-webview'
import { WebViewProgressEvent } from 'react-native-webview/lib/WebViewTypes'

import Colors from '../constants/Colors'
import { globalStyles } from '../constants/Styles'
import { Layout } from '../constants/types'
import { useLayout } from '../hooks/useLayout'
import WebView from './elements/WebView/WebView'

type Props = {
  url: string
  close(): void
}

export function Payment3DAuth({ url, close }: Props) {
  const [progress, setProgress] = useState(0)
  const layout = useLayout()

  const loadProgress = useCallback((evt: WebViewProgressEvent) => {
    setProgress(evt.nativeEvent.progress)
  }, [])

  const onClose = useCallback(() => {
    hideModal()
    close()
  }, [close])

  const handleNavChange = useCallback(
    (newState: WebViewNavigation) => {
      if (newState.url.includes('/external/authorization-callback')) onClose()
    },
    [onClose],
  )

  return (
    <SafeAreaView style={globalStyles.flex1}>
      <WebView
        source={{ uri: url }}
        onNavigationStateChange={handleNavChange}
        onLoadProgress={loadProgress}
        onLoad={useCallback(() => setProgress(1), [])}
        onClose={onClose}
        iframe
      />
      {progress < 1 && (
        <View style={progCont(layout)}>
          <Spinner />
        </View>
      )}
    </SafeAreaView>
  )
}

const progCont = (layout: Layout): ViewStyle => ({
  position: 'absolute',
  backgroundColor: Colors.transparent,
  width: Platform.OS !== 'web' ? layout.width : 600,
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
})
