import { useDebouncedValue } from '@/hooks/useDebounce'
import { ButtonGroup, SearchInputBar } from '@elements'
import { memo, useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'

type ConversationHeaderProps = {
  tabIdx: number
  onTabPress: (idx: number) => void
  onTextChange: (value: string) => void
}

/** Component that renders a search input and a tab selector, used for filtering conversations */
export const ConversationsListHeader = memo(function ConversationsListHeader({
  tabIdx,
  onTabPress,
  onTextChange,
}: ConversationHeaderProps) {
  const [inputValue, setInputValue] = useState('')
  const debouncedValue = useDebouncedValue(inputValue, 500)

  useEffect(() => {
    onTextChange(debouncedValue)
  }, [debouncedValue, onTextChange])

  return (
    <View style={styles.header}>
      <SearchInputBar value={inputValue} onChangeText={setInputValue} />
      <ButtonGroup onSelect={onTabPress} selectedIndex={tabIdx} small buttons={['All', 'Unread']} />
    </View>
  )
})

const styles = StyleSheet.create({
  header: {
    gap: 10,
    padding: 10,
  },
})
