import { Button, typography } from '@elements'
import { FC } from 'react'
import { CreateResponsiveStyle } from 'rn-responsive-styles'

import { ButtonProps } from '../../components/elements/Button'

interface ExportProps extends ButtonProps {
  onPress: () => void
}

const Export: FC<ExportProps> = ({ style, ...props }) => {
  const styles = useExportStyles()

  return (
    <Button
      small
      size={14}
      style={[styles.export, style]}
      textStyle={{ fontFamily: typography.body.medium, fontWeight: '600' }}
      outline
      {...props}
    />
  )
}

const useExportStyles = CreateResponsiveStyle({
  export: { margin: 0, paddingHorizontal: 10 },
})

export default Export
