import { Image } from '@components'
import { Button, Text, TextH1 } from '@elements'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'

import { globalStyles } from '@/constants/Styles'
import { SignInSheetParamList } from '@/navigation/types'
import { formatLongDate } from '@helpers/display'
import { SharedScreenHeader, sharedStyles } from './components'
import { useSignInSheetContext } from './useSignInSheetData'
import { withDataAndAuth } from './withDataAndAuth'

type Props = StackScreenProps<SignInSheetParamList, 'EditSignIn'>

function EditSignInScreenComp({ navigation, route }: Props) {
  const { signInSummary, location } = useSignInSheetContext()

  return (
    <ScrollView contentContainerStyle={sharedStyles.wrapper}>
      <SharedScreenHeader />
      <View style={globalStyles.margin20}>
        <Image style={styles.image} source={require('../../assets/images/farmer-onboard/farmer-onboard-image.png')} />
      </View>
      <TextH1 size={24} center>
        Edit Sign-in
      </TextH1>

      <View style={globalStyles.margin20} />
      {location && (
        <Text size={18} type="medium" center>
          {location.name}
        </Text>
      )}
      <View style={globalStyles.margin10} />
      {signInSummary && (
        <Text size={16} center>
          {formatLongDate(signInSummary.date)}
        </Text>
      )}
      <View style={globalStyles.margin20} />
      <Button
        onPress={() => navigation.navigate('ElectronicSignInSheet', { id: route.params.id, token: route.params.token })}
        title="Edit Sign-in"
      />
    </ScrollView>
  )
}

export const EditSignInScreen = withDataAndAuth(EditSignInScreenComp)

const styles = StyleSheet.create({
  image: {
    height: 200,
    width: 200,
  },

  btn: {
    alignSelf: 'flex-start',
  },
})
