import { loadFuturePickupsByUserAndFarm } from '@api/Pickups'
import { loadUser } from '@api/Users'
import { Toast } from '@elements'
import { getDeliveryFee } from '@helpers/location'
import { objToStr } from '@helpers/log'
import { MoneyCalc } from '@helpers/money'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { CartServiceType } from '@/constants/types/cartService'
import { useApiFx } from '@/hooks/useApiFx'
import { setCartCustomer } from '@/redux/actions/adminState'
import { CartItem } from '@models/Order'

/** Data layer for any state related to the cart customer.
 * - This hook is meant to be reusable between the order creator and order edit screens.
 */
export function useCustomerData_reusable({
  custId,
  cart,
  farmId,
  cartServiceType,
}: {
  custId?: string
  cart: CartItem[]
  farmId: string | undefined
  cartServiceType: Extract<CartServiceType, 'orderCreator' | 'orderEdit'>
}) {
  const dispatch = useDispatch()

  /** Gets the customer data for the current custId navigation parameter. */
  const customerFx = useApiFx(
    async (custId: string | undefined) => {
      if (!custId) {
        // This shouldn't error when no customer id is defined. Simply return undefined
        // This is necessary exactly as it is because it allows the customer to be cleared
        return Promise.resolve(undefined)
      }
      return loadUser(custId)
    },
    [custId],
    undefined, // This should run regardless of the custId param so the state can be cleared if necessary
    {
      onError: () => Toast('Could not load the selected customer'),
      onStateSet: ({ data }) => {
        // This should set or clear the cart customer state depending on the cart service type
        if (cartServiceType === 'orderCreator') {
          /** When customer changes in the order creator, this must set the customer to redux for the cart service to access it and assign it to the admin db cart */
          dispatch(setCartCustomer(data, 'orderCreator'))
        } else if (cartServiceType === 'orderEdit') {
          dispatch(setCartCustomer(data, 'orderEdit'))
        }
      },
      noRefocus: true,
    },
  )

  /** This is a condition for the "custPickupsFx" to determine whether it should fetch the customer's pickups or not, it should not fetch them if there's no delivery fees in the cart, since that would be pointless */
  const shouldLoadCustPickups = useMemo(() => {
    /** Here we need to determine whether the cart has delivery fees, without considering future pickups. However, we can't use the state 'hasDeliveryFees' because that one is calculated based on future pickups (which is the result of the next useApiFx effect). So if we used it, there would be a race condition where both states depend on each other's value */
    const { itemsDeliveryFees } = getDeliveryFee(cart) // dont include pickups in this call to getDeliveryFee()
    return itemsDeliveryFees && MoneyCalc.isGTZero(itemsDeliveryFees)
    // this only needs to run when product ids in cart change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objToStr(cart.map((i) => i.product.id).sort())])

  /** custPickupsFx. Fetches future pickups for the customer. These future pickups are used for determining combined delivery dates */
  const custPickupsFx = useApiFx(loadFuturePickupsByUserAndFarm, [custId ?? '', farmId], shouldLoadCustPickups, {
    onError: () =>
      Toast("There was an error while loading the customer's pickups. Can't determine correct delivery fees."),
    failedConditionMode: 'stop-loading',
    noRefocus: true,
  })

  return {
    customerFx,
    custPickupsFx,
  }
}
