import { ToolTips } from '@components'
import { TextH3, TextH4, Tooltip, typography } from '@elements'
import { StyleSheet, View } from 'react-native'

import Colors from '../../../../constants/Colors'

export default function FormSectionHeader({
  title,
  subtitle,
  toolTipId,
  toolTipTitle,
}: {
  title: string
  subtitle?: string
  toolTipId?: ToolTips
  toolTipTitle?: string
}) {
  return (
    <View style={styles.basicInfoHeader}>
      <TextH3 size={18} color={Colors.black}>
        {title} {toolTipId && <Tooltip title={toolTipTitle ?? title} id={toolTipId} />}
      </TextH3>
      {!!subtitle && (
        <TextH4 size={14} style={styles.subtitle} color={Colors.shades[400]}>
          {subtitle}
        </TextH4>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  basicInfoHeader: {
    paddingHorizontal: 10,
    paddingBottom: 15,
    paddingTop: 25,
  },
  subtitle: {
    marginTop: 5,
    fontFamily: typography.body.regular,
    fontSize: 15,
    fontWeight: '400',
  },
})
