import { StyleSheet } from 'react-native'

export const sharedStyles = StyleSheet.create({
  tabHeaderCont: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
    marginTop: 20,
    flexWrap: 'wrap',
  },
  baseGap: {
    gap: 20,
  },
})
