import { AlgoliaGeoDistro, AlgoliaGeoDoc, AlgoliaGeoProduct } from '@models/Algolia'
import { Farm } from '@models/Farm'
import { StyleSheet, View, ViewStyle } from 'react-native'

import { Image } from '../../components/Image'
import { Text } from '../../components/elements/Text'
import { Touchable } from '../../components/elements/Touchable'
import Colors from '../../constants/Colors'
import { globalStyles } from '../../constants/Styles'
import { displayProductCount } from '@helpers/products-display'

type FarmContainerProps = (Pick<AlgoliaGeoDoc, 'farm'> | { farm: Farm }) & {
  close?: () => void
  style?: ViewStyle
}

/** FarmContainer used on the PopUpCards */
export function FarmContainer({ farm, close, style }: FarmContainerProps) {
  return (
    <View style={[styles.snippet, style]}>
      <View style={styles.snippetInner}>
        <Image type="logo" source={{ uri: farm.logo }} style={styles.farmProfile} />
        <View style={globalStyles.flex1}>
          <Text numberOfLines={1}>{farm.name}</Text>
          <Text color={Colors.shades[300]} size={10}>
            {displayProductCount(farm.productCount)}
          </Text>
          {!!close && (
            <Touchable url={`/farms/${farm.id}/shop`} onPress={close}>
              <Text color={Colors.green} type="medium">
                Shop Now
              </Text>
            </Touchable>
          )}
        </View>
      </View>
    </View>
  )
}

/** DistroContainer used on the PopUpCards */
export function DistroContainer({ data, close }: { data: AlgoliaGeoDoc<AlgoliaGeoDistro>; close: () => void }) {
  return (
    <View style={styles.snippet}>
      <View style={styles.snippetInner}>
        <Image type="farm" source={{ uri: data.images[0] }} style={styles.farmProfile} />
        <View style={globalStyles.flex1}>
          <Text numberOfLines={1} type="medium">
            {data.farm.name}
          </Text>
          <Text numberOfLines={1}>{data.name}</Text>
          <Text numberOfLines={1}>{data.scheduleText}</Text>
          <Touchable url={`/farms/${data.farm.id}/shop`} onPress={close}>
            <Text color={Colors.green} type="medium">
              Shop Now
            </Text>
          </Touchable>
        </View>
      </View>
    </View>
  )
}

/** ProductContainer used on the PopUpCards */
export function ProductContainer({ data, close }: { data: AlgoliaGeoDoc<AlgoliaGeoProduct>; close: () => void }) {
  return (
    <View style={styles.snippet}>
      <View style={styles.snippetInner}>
        <Image type="farm" source={{ uri: data.images[0] }} style={styles.farmProfile} />
        <View style={globalStyles.flex1}>
          <Text numberOfLines={1}>{data.name}</Text>
          <Text numberOfLines={1} type="medium">
            {data.farm.name}
          </Text>
          <Touchable url={`/farms/${data.farm.id}/shop`} onPress={close}>
            <Text color={Colors.green} type="medium">
              Shop Now
            </Text>
          </Touchable>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  snippet: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: 20,
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderWidth: 3,
    borderColor: Colors.secondaryGreen3,
    borderRadius: 10,
    flex: 1,
  },
  snippetInner: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  farmProfile: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 10,
  },
})
