import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { OrderCreatorStateProductsFilters } from './OrderCreatorScreen.helper'

import { AdminOrdersParamList, OrderProductType } from '@/admin/navigation/types'
import { KeyedState } from '@/hooks/useKeyedState'
import { adminFarmIdSelector, isWholesaleOrderCreatorSelector } from '@/redux/selectors'
import { useProductsReusable } from './useProducts-reusable'

/** State management for data related to products in the order creator.
 * - This wraps the reusable hook for products data layer, for the portions that are common to both the order creator and order edit screens.
 * - Any data code shared by both screens should be in the reusable hook; while any code related to only this specific screen should be here.
 */
export function useProducts({
  orderType,
  keyedState,
}: {
  orderType: OrderProductType
  keyedState: KeyedState<OrderCreatorStateProductsFilters>
}) {
  const isWholesaleOrderCreator = useSelector(isWholesaleOrderCreatorSelector)
  const adminFarmId = useSelector(adminFarmIdSelector) || ''
  const navigation = useNavigation<StackNavigationProp<AdminOrdersParamList, 'OrderCreator'>>()

  /** This hook handles the portions of the product state which are reusable with both order reator and order edit screens. The rest of the code in this hook is meant to be specific to the order creator */
  const useProductsReturn = useProductsReusable({
    farmId: adminFarmId,
    isWholesale: isWholesaleOrderCreator,
    orderType,
    keyedState,
  })

  /** Options for the product type being filtered by the button group */
  const typeButtons = useMemo(
    () =>
      [
        {
          label: 'Standard',
          onPress: () => {
            navigation.setParams({ orderType: 'standard' })
          },
        },
        {
          label: 'Subscription',
          onPress: () => {
            navigation.setParams({ orderType: 'share' })
          },
        },
        {
          label: 'Digital',
          onPress: () => {
            navigation.setParams({ orderType: 'digital' })
          },
        },
      ].filter((btn) => {
        if (isWholesaleOrderCreator === undefined) return false
        if (isWholesaleOrderCreator) return btn.label === 'Standard'
        return true
      }),
    [navigation, isWholesaleOrderCreator],
  )

  /** This is the selected index for the product type selector */
  const typeSelectedIndex = useMemo(() => {
    return orderType && ['standard', 'share', 'digital'].includes(orderType)
      ? ['standard', 'share', 'digital'].indexOf(orderType)
      : undefined
  }, [orderType])

  return {
    ...useProductsReturn,
    typeButtons,
    typeSelectedIndex,
  }
}
