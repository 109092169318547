import { RouteProp, useRoute } from '@react-navigation/native'
import { useSelector } from 'react-redux'

import {
  OrderCreatorStatePayments,
  OrderCreatorStateProductsFilters,
  orderCreatorInitialStatePayments,
  orderCreatorInitialStateProductsFilters,
} from './OrderCreatorScreen.helper'

import { AdminOrdersParamList } from '@/admin/navigation/types'
import { useSetCartService } from '@/constants/appInitialization/useSetCartService'
import useKeyedState from '@/hooks/useKeyedState'
import { isWholesaleOrderCreatorSelector } from '@/redux/selectors'
import { useCatalogMode } from './useCatalogMode'
import { useCsas } from './useCsas'
import { useCustomerData } from './useCustomerData'
import { usePaymentsAndTotalsData } from './usePaymentsAndTotalsData'
import { usePlaceOrderCheck } from './usePlaceOrderCheck'
import { usePlaceOrderProceed } from './usePlaceOrderProceed'
import { useProducts } from './useProducts'
import { useSchedulesAndDates } from './useSchedulesAndDates'

/** State hook for the order creator */
export function useOrderCreatorScreenData() {
  const isWholesaleOrderCreator = useSelector(isWholesaleOrderCreatorSelector)
  const { params = {} } = useRoute<RouteProp<AdminOrdersParamList, 'OrderCreator'>>()
  const { orderType = 'standard', custId, goBack } = params

  const keyedStateProdsFilters = useKeyedState<OrderCreatorStateProductsFilters>(
    orderCreatorInitialStateProductsFilters,
  )

  const keyedStatePayments = useKeyedState<OrderCreatorStatePayments>(orderCreatorInitialStatePayments)

  useSetCartService({ cartServiceType: 'orderCreator', isWholesale: isWholesaleOrderCreator })

  /** CATALOG */

  const { onSelectCatalog } = useCatalogMode()

  /** CUSTOMER */

  const { customerInputRef, onUserSelected, customerFx, custPickupsFx } = useCustomerData({
    custId,
  })

  /** PAYMENTS & TOTALS */

  const { payments, paymentSelectorOptions } = usePaymentsAndTotalsData({
    custPickupsFx,
    keyedState: keyedStatePayments,
  })

  /** PRODUCTS */

  const {
    clearSearch,
    dbProds,
    refineSearch,
    typeButtons,
    typeSelectedIndex,
    isLoadingProductsInit,
    isLoadingProducts,
    tableData,
  } = useProducts({
    orderType,
    keyedState: keyedStateProdsFilters,
  })

  /** SCHEDULES & DATES */

  const { dateDropdownOpts, distroDropdownOpts, loadingSchedules, setDate, setDistro } = useSchedulesAndDates({
    dbProds,
    isLoadingProducts: isLoadingProductsInit,
    orderType,
    keyedState: keyedStateProdsFilters,
  })

  /** CSAS */

  const { csaDropdownOpts, setCsa, csasApiFx } = useCsas({
    orderType,
    keyedState: keyedStateProdsFilters,
  })

  /** SUBMISSION */

  /** `placeOrderProceed` will call the addOrder api. This is only meant to be called by `placeOrderCheck`, once preliminary checks are satisfactory */
  const placeOrderProceed = usePlaceOrderProceed({ keyedStatePayments, keyedStateProdsFilters })

  /** `placeOrderCheck` is the first part of the order placing process. It will validate all the conditions are met for placing an order. If all checks pass, it will call placeOrderProceed() */
  const placeOrderCheck = usePlaceOrderCheck({
    custId,
    customerFx,
    keyedStatePayments,
    customerInputRef,
    placeOrderProceed,
  })

  return {
    // Screen params
    orderType,
    custId,
    goBack,

    // Catalog
    isWholesaleOrderCreator,
    onSelectCatalog,

    // Main State
    keyedStateProdsFilters,
    keyedStatePayments,
    payments,
    paymentSelectorOptions,

    // Algolia
    refineSearch,
    clearSearch,

    // Customer
    customerInputRef,
    onUserSelected,
    customerFx,
    custPickupsFx,

    // Products
    isLoadingProducts,
    isLoadingProductsInit,
    tableData,
    typeButtons,
    typeSelectedIndex,

    // Schedules
    loadingSchedules,
    setDistro,
    distroDropdownOpts,

    // Dates
    dateDropdownOpts,
    setDate,

    // CSAs
    csasApiFx,
    setCsa,
    csaDropdownOpts,

    // Submission
    placeOrderCheck,
    placeOrderProceed,
  }
}
