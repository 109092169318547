import { PaymentOptionsContext } from './PaymentOptionsContext'
import { ButtonClear, HeaderText, Text } from '@elements'
import React, { PropsWithChildren, useContext } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import { formatSplitTender } from './helpers/display'
import { formatMoney } from '@helpers/display'
import Colors from '../../../constants/Colors'
import { MoneyCalc } from '@helpers/money'
import { isFarmCreditPayment } from '@models/PaymentMethod'
import { Zero } from '@models/Money'
import { wholesaleSelector } from '@/redux/selectors'
import { useSelector } from 'react-redux'

type PaymentSelectorDisplayProps = {
  onPress: () => void
  disabled?: boolean
}

/** This is the UI that displays the selected payment options and allows toggling the selector */
export function PaymentSelectorDisplay({ onPress, disabled }: PaymentSelectorDisplayProps) {
  const {
    isValid,
    isLoading,
    splitTender,
    userFarmBalance,
    options: { amountTotal, hasFuturePayments, hasDelivery, farm },
  } = useContext(PaymentOptionsContext)
  const isDisabled = (MoneyCalc.isZero(amountTotal) && !hasFuturePayments && !hasDelivery) || disabled
  const farmCreditApplied = splitTender.find((tender) => isFarmCreditPayment(tender.paymentMethod))
  // If the payment method is farm credit and the amount is 0 then it is free
  const isFree = splitTender.length === 1 && farmCreditApplied && MoneyCalc.isZero(farmCreditApplied.amount ?? Zero)
  const remainingFarmBalance = MoneyCalc.subtract(
    MoneyCalc.fromCents(userFarmBalance?.amount ?? 0),
    farmCreditApplied?.amount ?? Zero,
  )
  const requiresBackupPmt = hasFuturePayments || hasDelivery
  // We should show the payment button unless there is only one payment method, and it is farm credit AND there are no future payments that require a backup or the whole order is free
  const shouldShowPaymentButton =
    !(splitTender.length === 1 && isFarmCreditPayment(splitTender[0].paymentMethod) && !requiresBackupPmt) || isFree

  return (
    <View>
      {farmCreditApplied && !isFree && (
        <PaymentSelectionBox onPress={onPress} disabled={isDisabled}>
          <HeaderText size={16} style={styles.headerText}>
            {farm.name} Credit
          </HeaderText>
          <Text>
            {formatMoney(farmCreditApplied.amount ?? Zero)} applied | New balance: {formatMoney(remainingFarmBalance)}
          </Text>
        </PaymentSelectionBox>
      )}
      {shouldShowPaymentButton && (
        <PaymentSelectionBox onPress={onPress} disabled={isDisabled}>
          <HeaderText
            color={!isValid && !isLoading ? Colors.red : Colors.shades[600]}
            size={16}
            style={styles.headerText}
          >
            Payment
          </HeaderText>
          <DisplayTextComponent />
        </PaymentSelectionBox>
      )}
    </View>
  )
}

/** This component generates the display text for the payment button by making each payment type formatted as a string and amount*/
function DisplayTextComponent() {
  const { isLoading, isValid, splitTender, options } = useContext(PaymentOptionsContext)
  const { isWholesale } = useSelector(wholesaleSelector)

  const isFreeOrder = MoneyCalc.isZero(options.amountTotal) && !options.hasFuturePayments && !options.hasDelivery

  if (isLoading) return <Text>Loading...</Text>
  if (!isValid) return <Text>Add or select a payment method</Text>
  if (isFreeOrder) return <Text>Free</Text>

  return (
    <Text>
      {formatSplitTender(splitTender, options.amountTotal, { isWholesale, farm: options.farm }).map(
        ([pmtDesc, pmtAmt], idx) => (
          <React.Fragment key={idx}>
            {idx !== 0 ? ' | ' : ''}
            <Text type="medium">{pmtDesc}: </Text>
            {MoneyCalc.isZero(pmtAmt) ? 'Backup Payment' : formatMoney(pmtAmt)}
          </React.Fragment>
        ),
      )}
    </Text>
  )
}

/** Simple reusable wrapper of the payment selector box*/
function PaymentSelectionBox({ onPress, disabled, children }: PropsWithChildren<PaymentSelectorDisplayProps>) {
  return (
    <Pressable style={styles.container} onPress={onPress} disabled={disabled}>
      <View>{children}</View>
      <ButtonClear small title="Edit" disabled={disabled} onPress={onPress} />
    </Pressable>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: 10,
    padding: 15,
    borderWidth: 1,
    borderColor: Colors.shades[100],
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 10,
  },
  headerText: {
    paddingBottom: 5,
  },
})
