import { Falsy, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import Colors from '@/constants/Colors'
import React from 'react'

type AdminHalfCardProps = {
  /** Components that will be wrapped in a new container with the default styles applied */
  children: (JSX.Element | Falsy)[]
  /** Additional style for the wrapper component */
  style?: StyleProp<ViewStyle>
  /** Additional style applied to each child's container, i.e if the component should have larger width (default is `flexBasis:400`)*/
  itemStyle?: StyleProp<ViewStyle>
}
/** Admin Card wrapper component, used for aplying consistent styling for its children
 * - Should be placed at the root level of the screen, in order to get the expected behaviour
 */
export function AdminHalfCardsContainer({ children, style, itemStyle }: AdminHalfCardProps) {
  return (
    <View style={[styles.halfCardsContainer, style]}>
      {React.Children.map(children, (child) => (
        <View style={[styles.halfCard, itemStyle]}>{child}</View>
      ))}
    </View>
  )
}

export const styles = StyleSheet.create({
  halfCard: {
    borderRadius: 10,
    padding: 20,
    borderColor: Colors.shades['100'],
    borderWidth: 1,
    marginTop: 20,
    flexShrink: 1, // This will shrink the component (if the screen has 300px, it will have 300px instead of 400, which is the flexBasis value)
    flexGrow: 1, // This will expand the component (if screen has 600px, it will will have 600px instead of 400, which is the flexBasis value)
    // This will make sure that the starting point of the component is 400px, so its parent will have < 800px (~ isSmallDevice) the content will wrap.
    flexBasis: 400,
  },
  halfCardsContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    columnGap: 20,
    width: '95%',
    alignSelf: 'center',
  },
})
