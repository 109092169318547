import { Replace } from '@helpers/typescript'
import { CartItem } from '@models/Order'
import { User } from '@models/User'

import { isArray } from '@helpers/helpers'
import { matchesAppModeProduct } from '@helpers/products'
import { Discount } from './Coupon'
import { Farm } from './Farm'

/** Cart identifies a shopping cart. */
export type Cart = {
  id: string

  /** Cart session id. Its function is to help retrieve the same cart id when the app is reopened, in the event that the user is signed out */
  session: { id: string }

  /** The user association. If the user is signed in, it will try to find an existing cart from a different device */
  user?: Pick<User, 'id'>

  /** The items in the cart. */
  items: Record<CartItem['id'], CartItem>

  /** If the user adds a promo code that will be handled here.
   * - The key will hold the farm id for the farm whose items are receiving the Discount
   */
  discounts?: Record<Farm['id'], Discount | undefined>

  /** Whether the cart was initialized from the admin order creator. If false, it's a consumer cart. This allows the order service to do different validations based on the type of cart */
  isAdmin: boolean

  /** Whether the cart is a draft cart, a draft cart should always be referenced by a draft order and will be deleted only if the Draft Order is also deleted. */
  isDraft?: boolean
}

/** Converts an array of cart items into the Cart['items'] type */
export const itemsToCart = (items: CartItem[]): Pick<Cart, 'items'> => ({
  items: Object.fromEntries(items.map((itm) => [itm.id, itm])),
})

type GetFarmCartItemsOpts = {
  items: CartItem[] | Pick<Cart, 'items'>
  farmId?: Farm['id']
  isWholesale?: boolean
}

/** Selects the items that belong to the given farm id and wholesale mode */
export function getFarmCartItems({ items, farmId, isWholesale }: GetFarmCartItemsOpts): CartItem[] {
  const itemsArr = isArray(items) ? items : Object.values(items.items)
  const isMatch = matchesAppModeProduct(isWholesale)
  return itemsArr.filter((itm) => (farmId ? itm.product.farm.id === farmId : true) && isMatch(itm.product))
}

/** Gets the discount for the given farm id from the cart */
export function getFarmCartDiscount(cart: Pick<Cart, 'discounts'>, farmId: string): Discount | undefined {
  return cart.discounts?.[farmId]
}

/**This cart type is like the main Cart model, but items are in array form. It makes it more manageable for most situations of data processing */
export type SimpleCart = Replace<Cart, 'items', CartItem[]>
