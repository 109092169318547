import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import useKeyedState from '@/hooks/useKeyedState'
import { HomeParamList } from '@/navigation/types'
import { GooglePlacesSearch, GooglePlacesSearchProps } from '@components'
import { Alert, Button, DropdownMenu, DropdownMenuBtn, ErrorText, FormInput, Icon, Text, TextH1 } from '@elements'
import { entries, values } from '@helpers/typescript'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import {
  LocType,
  LocTypeDisplay,
  locTypeToDisplayMap,
  submitLogistics,
  useInitialAddress,
} from '@screens/SearchScreen/searchScreen-helpers'
import { memo, useCallback, useMemo, useRef } from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch } from 'react-redux'
import { LogisticsSelectionCarousel } from './LogisticsSelectionCarousel'

function LogisticsSelectionComp() {
  const dispatch = useDispatch()
  const navigation = useNavigation<NavigationProp<HomeParamList, 'HomeScreen'>>()
  const initialAddressFx = useInitialAddress()
  const [{ locTypeDisplay, query, errorStr }, , , setters] = useKeyedState({
    locTypeDisplay: 'Delivery' as LocTypeDisplay,
    query: '',
    errorStr: '',
  })

  /** This stores the handler used internally by google places search for on submit. It needs to be a ref because if you store this in state there will be an error saying you can't set state in this component while rendering the google places search component.
   * - We need to do this because it enables the user to just hit the submit button and not be required to focus the input and select an autocomplete item from the results. If we did not do this, then the user might be confused because the input text shows their address, but they would still be required to click on the input, select an autocomplete item, and then click the submit button. So this is supporting a significantly better user experience.
   */
  const submitCurrentAddress = useRef<
    Parameters<NonNullable<GooglePlacesSearchProps<any>['getOnSubmit']>>[0] | undefined
  >(undefined)

  const locTypeButtons = useMemo<DropdownMenuBtn[]>(
    () => values(locTypeToDisplayMap).map((v) => ({ title: v, onPress: () => setters.locTypeDisplay(v) })),
    [setters],
  )

  /** This is the locType param derived from the location type display, which will be used as a parameter for the search screen */
  const locTypeParam = useMemo<LocType>(() => {
    if (!locTypeDisplay) {
      throw new Error('There should be an initial value for the location type display')
    }
    const locTypeParam = entries(locTypeToDisplayMap).find(
      ([_, locTypeDisplayInMap]) => locTypeDisplay === locTypeDisplayInMap,
    )?.[0]

    if (!locTypeParam) {
      throw new Error('The display location should always match a location type parameter.')
    }
    return locTypeParam
  }, [locTypeDisplay])

  const onSubmitLogistics = useCallback(async () => {
    const { status } = await submitLogistics({
      locType: locTypeParam,
      dispatch,
      query,
      setErrorStr: setters.errorStr,
      submitCurrentAddress,
      handleParams: (params) => navigation.navigate('SearchScreen', params),
    })
    if (status === 'empty') {
      Alert(
        'Invalid Address',
        'The address entered is not producing valid results. Try writing your address in a different way, or try with a different address',
      )
    }
  }, [locTypeParam, query, setters, dispatch, submitCurrentAddress, navigation])
  return (
    <View style={styles.main}>
      <LogisticsSelectionCarousel />
      <View style={styles.selectionCont}>
        <TextH1 size={24}>What are you looking for today?</TextH1>

        <FormInput
          containerStyle={styles.noHorizPadding}
          placeholder="Search for products or farms"
          label={null}
          onChangeText={setters.query}
          value={query}
        />
        <View style={styles.addressInputWrapper}>
          <DropdownMenu contentViewStyle={styles.dropdownContent} buttons={locTypeButtons}>
            <Text children={locTypeDisplay} />
            <Icon size={16} color={Colors.shades['500']} name="chevron-down" />
          </DropdownMenu>
          <View style={globalStyles.flex1}>
            <GooglePlacesSearch
              types="address"
              placeholder={initialAddressFx.data || 'Enter your location'}
              initialValue={initialAddressFx.data}
              enableReinitialize
              editable={!initialAddressFx.loading}
              autoComplete="postal-address"
              hasClearBtn
              style={styles.addressInput}
              getOnSubmit={(onSubmit) => (submitCurrentAddress.current = onSubmit)}
              onSubmitEditing={onSubmitLogistics}
            />
            {!!errorStr && <ErrorText>{errorStr}</ErrorText>}
          </View>
        </View>
        <Button
          style={styles.button}
          title="Find food"
          loading={initialAddressFx.loading}
          onPress={onSubmitLogistics}
        />
      </View>
    </View>
  )
}

export const LogisticsSelection = memo(LogisticsSelectionComp)

const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    margin: 15,
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 20,
  },
  button: {
    alignSelf: 'flex-start',
    marginLeft: 0,
  },
  addressInputWrapper: {
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  noHorizPadding: {
    paddingHorizontal: 0,
  },
  addressInput: {
    borderColor: Colors.shades['100'],
    height: 40,
    borderRadius: 8,
    borderWidth: 1,
  },
  dropdownContent: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 10,
    borderWidth: 1,
    borderColor: Colors.shades['100'],
    borderRadius: 8,
    height: 40,
    paddingHorizontal: 10,
  },

  selectionCont: {
    flexBasis: 400,
    flex: 1,
    gap: 10,
  },
})
