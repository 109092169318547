import { extendErr, retry } from '@helpers/helpers'
import { CartItem } from '@models/Order'
import { PaymentSchedule } from '@models/Product'
import { DataError, ErrorCode, isErrorWithCode } from '@shared/Errors'
import { InsufficientStockError, OutOfStockError } from '@shared/errors/cart'
import {
  AddToCartApiInput,
  CartAddDiscountRequest,
  CartAddRequest,
  CartInitRequest,
  CartInitResponse,
  CartUpdateItemRequest,
  UpdateCartItemFields,
} from '@shared/types/v2/cart'

import { marshalCartItem, unmarshalCartItem } from './encoding/Cart'
import { marshalPaymentSchedule } from './encoding/Product'
import { callEndpoint } from './v2'

import { Logger } from '@/config/logger'
import { Discount } from '@models/Coupon'
import { unmarshalDateObject } from './encoding/Time'

type CartInitOpts = {
  sessionId: string | undefined
  userId: string | undefined
  isAdmin: boolean
}

/**
 * - Once a cart has been initialized with a session ID subsequent calls to the function with the same ID will return the same cart.
 * - Assigning a user to the cart is optional to allow unauthenticated sessions to start adding items to the cart.
 * - Once a user has been authenticated this function can be called again using the same session ID.
 * - A user is required before beginning the checkout process.
 *
 * There is an issue in server sdk that makes the firestore user not found server side, although it is found client size. It was observed to only happen during the first 3 seconds after the creation of the user document. If cartInit fails in this manner, the cart won't have a user id assigned at the time of checkout. To address that, we retry cartInit in 3 seconds intervals.
 */
export async function cartInit({ isAdmin, sessionId, userId }: CartInitOpts): Promise<CartInitResponse> {
  try {
    // By default, we retry cartInit because we expect it to fail some of the time.
    // Explanation: The firebase issue that when a user creates a new account, the admin firestore sdk won't find the user for a few seconds, even after the user doc has been fully created.
    return retry(
      async () => {
        const request: CartInitRequest = { sessionId, userId, isAdmin }
        return callEndpoint('v2.Cart.cartInitService', request)
      },
      4,
      3000,
    )
  } catch (e) {
    Logger.error(extendErr(e, 'Error while calling cartInit service:'))
    throw e
  }
}

/** cartAdd calls the cartAdd API service to add the supplied product information as a new item in the cart. */
export async function cartAdd({
  cartId,
  product,
  distribution,
  unit,
  price,
  csa,
  pickups,
  paymentSchedule,
  isAdmin,
  isWholesale,
}: AddToCartApiInput): Promise<CartItem> {
  const item = {
    product,
    distribution,
    unit,
    price,
    csa,
    pickups,
    paymentSchedule,
  } as Partial<CartItem> // This cast is difficult to remove, but it is truly a partial cart item

  const request: CartAddRequest = {
    cartId,
    ...marshalCartItem(item),
    isAdmin,
    isWholesale,
  }

  try {
    const cartItem = await callEndpoint('v2.Cart.cartAddService', request)
    return unmarshalCartItem(cartItem)
  } catch (err) {
    Logger.error(new DataError('Error while calling cartAdd service.', request))

    if (isErrorWithCode(err, ErrorCode.OUT_OF_STOCK)) {
      throw new OutOfStockError()
    }
    if (isErrorWithCode(err, ErrorCode.INSUFFICIENT_STOCK)) {
      throw new InsufficientStockError()
    }
    throw err
  }
}

/** cartUpdateQuantity updates the quantity of an item specified by the supplied ID to use the new quantity value. */
export async function cartUpdateQuantity(
  cartId: string,
  itemId: string,
  newQuantity: number,
  isWholesale?: boolean,
): Promise<CartItem> {
  try {
    const update = await callEndpoint('v2.Cart.cartUpdateQuantityService', { cartId, itemId, newQuantity, isWholesale })
    return unmarshalCartItem(update)
  } catch (err) {
    Logger.error(
      new DataError('Error while calling updateQuantity service.', {
        error: err,
        cartId,
        itemId,
        newQuantity,
      }),
    )
    if (isErrorWithCode(err, ErrorCode.OUT_OF_STOCK)) {
      throw new OutOfStockError()
    }
    if (isErrorWithCode(err, ErrorCode.INSUFFICIENT_STOCK)) {
      throw new InsufficientStockError()
    }
    throw err
  }
}

/** This fn updates the variable weight of an item specified by the supplied ID. */
export async function cartUpdateVariableWeightQuantity(
  cartId: string,
  itemId: string,
  adjustedQuantity: number,
): Promise<CartItem> {
  try {
    const update = await callEndpoint('v2.Cart.cartUpdateVariableWeightService', {
      cartId,
      itemId,
      adjustedQuantity,
    })
    return unmarshalCartItem(update)
  } catch (err) {
    Logger.error(
      new DataError('Error while calling updateVariableWeight service.', {
        error: err,
        cartId,
        itemId,
        adjustedQuantity,
      }),
    )
    if (isErrorWithCode(err, ErrorCode.OUT_OF_STOCK)) {
      throw new OutOfStockError()
    }
    if (isErrorWithCode(err, ErrorCode.INSUFFICIENT_STOCK)) {
      throw new InsufficientStockError()
    }
    throw err
  }
}

/** cartUpdatePaymentSchedule updates the payment schedule associated with a cart item. */
export async function cartUpdatePaymentSchedule(
  cartId: string,
  itemId: string,
  paymentSchedule: PaymentSchedule,
  isWholesale?: boolean,
): Promise<CartItem> {
  try {
    const update = await callEndpoint('v2.Cart.cartUpdatePaymentScheduleService', {
      cartId,
      itemId,
      paymentSchedule: marshalPaymentSchedule(paymentSchedule),
      isWholesale,
    })
    return unmarshalCartItem(update)
  } catch (err) {
    Logger.error(
      new DataError('Error while calling updatePaymentSchedule service.', {
        error: err,
        cartId,
        itemId,
        paymentSchedule: marshalPaymentSchedule(paymentSchedule),
      }),
    )
    throw err
  }
}

/** updates data inside an item in the cart */
export async function cartUpdateItem(
  cartId: string,
  itemId: string,
  update: UpdateCartItemFields,
  isAdmin?: boolean,
  isWholesale?: boolean,
): Promise<CartItem> {
  try {
    const req: CartUpdateItemRequest = {
      cartId,
      itemId,
      update: marshalCartItem(update as Partial<CartItem>),
      isAdmin,
      isWholesale,
    }
    const res = await callEndpoint('v2.Cart.cartUpdateItemService', req)
    return unmarshalCartItem(res)
  } catch (err) {
    Logger.error(
      new DataError('Error while updating a cart item.', {
        error: err,
        cartId,
        itemId,
        update,
      }),
    )

    if (isErrorWithCode(err, ErrorCode.OUT_OF_STOCK)) {
      throw new OutOfStockError()
    }
    if (isErrorWithCode(err, ErrorCode.INSUFFICIENT_STOCK)) {
      throw new InsufficientStockError()
    }

    throw err
  }
}

/** Will add a discount to a cart */
export const cartAddDiscount = async (req: CartAddDiscountRequest): Promise<Discount> => {
  try {
    const marshaledDiscount = await callEndpoint('v2.Cart.cartAddDiscountService', req)
    return unmarshalDateObject<Discount>(marshaledDiscount)
  } catch (err) {
    Logger.error(extendErr(err, 'Error while adding discount.'))
    throw err
  }
}

/** Will remove a discount from the cart*/
export function cartRemoveDiscount(cartId: string, farmId: string): Promise<void> {
  try {
    return callEndpoint('v2.Cart.cartRemoveDiscountService', { cartId, farmId })
  } catch (err) {
    Logger.error(
      new DataError('Error while removing discount.', {
        error: err,
        cartId,
        farmId,
      }),
    )
    throw err
  }
}
