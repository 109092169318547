import { StyleSheet, View } from 'react-native'
import Colors from '@/constants/Colors'
import { Button, HeaderText, Icon } from '@elements'

type Props = {
  goBack(): void
  title: string
  actionTitle: 'Edit' | 'Save' | 'Submit'
  goAction(): void
  isLoading?: boolean
}
export function CSAHeader({ goBack, goAction, actionTitle, title, isLoading }: Props) {
  return (
    <View style={styles.container}>
      <Icon name="times" color={Colors.shades[500]} onPress={goBack} />
      <HeaderText style={styles.title}>{title}</HeaderText>
      <Button small title={actionTitle} onPress={goAction} loading={isLoading} />
    </View>
  )
}
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 5,
    paddingHorizontal: 20,
    borderBottomWidth: 1,
    borderBottomColor: Colors.shades['100'],
  },
  title: { flex: 1, marginHorizontal: 20 },
})
