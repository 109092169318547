import { VersionBlocker } from '@components'
import { Modal } from '@elements'
import * as React from 'react'

import { Logger } from '../../config/logger'
import { isWeb } from '../../constants/Layout'
import { isValidAPIVersion } from '../../constants/Version'

type ErrorHandler = (error: Error, info: React.ErrorInfo) => void
type ErrorHandlingComponent = (props: ErrorBoundaryProps, error?: Error) => React.ReactNode

type ErrorState = { error?: Error }

export type ErrorBoundaryProps = {
  children: React.ReactNode
  // If it is the root we will not try and check the app version
  isRoot?: boolean
}

export default function Catch(
  component: ErrorHandlingComponent,
  errorHandler?: ErrorHandler,
): React.ComponentType<ErrorBoundaryProps> {
  return class extends React.Component<ErrorBoundaryProps, ErrorState> {
    state: ErrorState = {
      error: undefined,
    }

    static getDerivedStateFromError(error: Error) {
      return { error }
    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
      Logger.error(error)
      // Check if there is a newer version not installed, only if we are not at the root error boundary as this does not
      // Have access to Modals and Alerts, and any pressing issue should be caught by a lower level error boundary
      if (!this.props.isRoot) checkForNewVersions()

      // If we have a custom handler then pass it here
      if (errorHandler) errorHandler(error, info)
    }

    render() {
      return component(this.props, this.state.error)
    }
  }
}

// Will check if there are newer versions available
const checkForNewVersions = () => {
  // Should only run this on Native apps
  if (isWeb) return

  try {
    // Make sure app version is valid
    isValidAPIVersion().then((res) => {
      if (!res) Modal(<VersionBlocker />, { header: false, dismissable: false, webWidth: 400 })
    })
  } catch (e) {
    Logger.error(e)
    //No-op
    // Should not throw errors from here as this is the error boundary and will crash the entire app
  }
}
