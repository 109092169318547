import { Image } from '@components'
import { Alert, Button, Text, TextH1 } from '@elements'
import React, { useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'

import { globalStyles } from '@/constants/Styles'
import { AppStackParamList, SignInSheetParamList } from '@/navigation/types'
import { formatLongDate } from '@helpers/display'
import { errorToString } from '@helpers/helpers'
import { StackScreenProps } from '@react-navigation/stack'
import { SharedScreenHeader, sharedStyles } from './components'
import { useSignInSheetContext } from './useSignInSheetData'
import { withDataAndAuth } from './withDataAndAuth'

type Props = StackScreenProps<SignInSheetParamList & AppStackParamList, 'FinalizeSignIn'>

function FinalizeSignInScreenComp({ navigation }: Props) {
  const { onFinishSignIn, location, signInSummary } = useSignInSheetContext()
  const [loading, setLoading] = useState(false)

  const onEndSignInPress = async () => {
    try {
      setLoading(true)
      await onFinishSignIn()
      setLoading(false)
      navigation.navigate('Consumer', {
        screen: 'Home',
      })
    } catch (err) {
      Alert(errorToString(err))
    }
  }

  return (
    <ScrollView contentContainerStyle={sharedStyles.wrapper}>
      <SharedScreenHeader />
      <View style={globalStyles.margin20}>
        <Image style={styles.image} source={require('../../assets/images/farmer-onboard/farmer-onboard-image.png')} />
      </View>
      <TextH1 size={24} center>
        Hooray!
      </TextH1>
      <Text size={16} center>
        You helped people eat local food today!
      </Text>
      <View style={globalStyles.margin20} />
      <Text size={18} type="medium" center>
        {location?.name}
      </Text>
      {signInSummary && (
        <Text size={16} center>
          {formatLongDate(signInSummary.date)}
        </Text>
      )}
      <View style={globalStyles.margin20} />
      <Button loading={loading} onPress={onEndSignInPress} title="End Sign-in" />
    </ScrollView>
  )
}

export const FinalizeSignInScreen = withDataAndAuth(
  FinalizeSignInScreenComp as React.ComponentType<StackScreenProps<SignInSheetParamList, 'FinalizeSignIn'>>,
)

const styles = StyleSheet.create({
  image: {
    height: 200,
    width: 200,
  },
})
