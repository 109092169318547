import env from '@/config/Environment'
import { isWeb } from '@/constants/Layout'
import { shoppingUrl } from '@helpers/client'
import { AlgoliaGeoDoc, AlgoliaGeoProduct } from '@models/Algolia'
import { CSA } from '@models/CSA'
import { Product } from '@models/Product'
import { ProductCardProps } from './useProductCardData'

/** Creates the card url */
export function getCardUrl(
  product: Product | AlgoliaGeoDoc<AlgoliaGeoProduct> | undefined,
  farmSlug: string | undefined,
  csaId: CSA['id'] | undefined,
  goBack: 'home' | undefined,
): string | undefined {
  return isWeb && product?.id && farmSlug ? shoppingUrl({ csaId, farmSlug, prodId: product.id, goBack }) : undefined
}

/** Validates the card props during development */
export function validateCardProps({ cardAction, onPressCsaNavigate }: ProductCardProps) {
  if (env.APP_ENV === 'prod') return
  if (cardAction === 'csa' && !onPressCsaNavigate)
    throw new Error("A card with 'View CSA' action needs to have a function that navigates to the CSA page.")
}
