import { Text } from '@elements'
import { FontAwesome5 } from '@expo/vector-icons'
import { connectPagination } from 'react-instantsearch-native'
import { TouchableWithoutFeedbackProps, View } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import { useLayout } from '@/hooks/useLayout'
import { useEffect } from 'react'
import { Icon } from '../../../components/elements/Icon/Icon'
import { FontAwesome5IconName } from '../../../components/elements/Icon/iconNames'
import { Touchable } from '../../../components/elements/Touchable'
import Colors from '../../../constants/Colors'

type Props = {
  canRefine: boolean
  refine: (page: number) => void
  nbPages: number
  /** In connected pagination, the currentRefinement starts from 1 */
  currentRefinement: number
  onPress?: () => void
}

// Will get a list of page numbers/ `...` to show in the list
// Note: we use 0 to represent '...' and it is replaced with an icon when rendered
const getPageNumbers = (nbPages: number, currentRefinement: number, totalSpots = 5) => {
  // Make sure total spots is valid, should be odd so we can have the same number on each side of the currentRefinement
  if (totalSpots % 2 === 0 || totalSpots < 5)
    throw new Error('Total spots must be an odd number greater than or equal to 5.')

  // If nbPages is <= to the number of spots just show all pages
  if (nbPages <= totalSpots) {
    return Array.from({ length: nbPages }, (_, i) => i + 1)
  }

  // If currRefinement is less than the spots then we should show all numbers before it, and fill the rest at the end
  if (currentRefinement < totalSpots / 2) {
    return [...Array.from({ length: totalSpots - 2 }, (_, i) => i + 1), 0, nbPages]
  }

  // If currRefinement is more than the spots then we should show all numbers after it, and fill the rest at the start
  if (nbPages - currentRefinement < totalSpots / 2) {
    return [1, 0, ...Array.from({ length: totalSpots - 2 }, (_, i) => nbPages - (totalSpots - 3) + i)]
  }

  // If the range does not include the start or end then we center the number
  // We have [1, ...] at the start and [..., nbPages] at the end, so we subtract 4 spots from the total
  const spotsLeft = totalSpots - 4

  // Create an array that has currentRefinement in the middle and an even number of pages on both sides
  const centerFiller = Array.from({ length: spotsLeft }, (_, i) => currentRefinement - Math.floor(spotsLeft / 2) + i)
  return [1, 0, ...centerFiller, 0, nbPages]
}

function PageButton(
  props: TouchableWithoutFeedbackProps & { icon?: FontAwesome5IconName; number?: number; selected?: boolean },
) {
  const styles = useStyles()

  return (
    <Touchable
      onPress={props.onPress}
      disabled={props.number === 0 || props.disabled}
      style={[styles.button, props.selected && styles.btnSelected]}
    >
      {!!props.icon && <Icon name={props.icon} disabled={props.disabled} />}
      {!!props.number && (
        <Text color={props.selected ? Colors.white : undefined} type="bold">
          {props.number}
        </Text>
      )}
      {props.number === 0 && <FontAwesome5 name="ellipsis-h" color={Colors.shades['400']} />}
    </Touchable>
  )
}

function ConnectedPaginationComp({ nbPages, currentRefinement, refine, onPress }: Props) {
  const layout = useLayout()
  const styles = useStyles()

  const deltaRefine = (delta: number) => {
    if (currentRefinement + delta > 0 && currentRefinement + delta < nbPages + 1) refine(currentRefinement + delta)
  }
  const totalSpots = layout.isLargeDevice ? 9 : 5
  const pageNumbers = getPageNumbers(nbPages, currentRefinement, totalSpots)

  // currentRefinement starts from number 1, so currentRefinement of first page is 1
  const disableLeftArrow = currentRefinement === 1
  const disableRightArrow = currentRefinement === nbPages

  // If the current refinement is in a page that no longer exists, reset page refinement
  useEffect(() => {
    if (!currentRefinement) return
    if (currentRefinement > nbPages) refine(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nbPages])

  /** Should not prevent the ConnectedPagination from showing if there's a current refinement because if you're at some page other than the first, and you change the filters, there might be less results, so your current page would be empty */
  if (nbPages === 1 && currentRefinement === 1) return null
  return (
    <View style={styles.container}>
      <PageButton
        icon="chevron-left"
        disabled={disableLeftArrow}
        onPress={() => {
          deltaRefine(-1)
          onPress?.()
        }}
      />
      {pageNumbers.map((num, index) => (
        <PageButton
          key={`${num}_${index}`}
          number={num}
          onPress={() => {
            refine(num)
            onPress?.()
          }}
          selected={currentRefinement === num}
        />
      ))}
      <PageButton
        icon="chevron-right"
        disabled={disableRightArrow}
        onPress={() => {
          deltaRefine(1)
          onPress?.()
        }}
      />
    </View>
  )
}
const useStyles = CreateResponsiveStyle(
  {
    container: {
      alignSelf: 'center',
      flexDirection: 'row',
    },
    button: {
      width: 50,
      height: 50,
      borderRadius: 5,
      alignItems: 'center',
      justifyContent: 'center',
    },
    btnSelected: {
      backgroundColor: Colors.green,
    },
  },
  {
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      button: {
        width: 35,
        height: 35,
      },
    },
  },
)

export const ConnectedPagination = connectPagination(ConnectedPaginationComp)
