import { ErrorWithCode } from '@shared/Errors'
import { callEndpoint } from './v2'

/**
 * This function will check if the personal user account is an authorized user on any Wholesale accounts, if so it will
 * return a token to allow signing in to that Wholesale account and the role they should have on that account
 */
export async function getWholesaleAuthorization() {
  try {
    return await callEndpoint('v2.Wholesale.getWholesaleAuthorization', null)
  } catch (e) {
    throw new ErrorWithCode({
      code: 'wholesale-authorization',
      devMsg: 'An unknown error occurred while trying to check if a user is Wholesale authorized.',
      uiMsg: 'We were unable to determine your status as a Wholesaler, please contact support.',
      data: e,
    })
  }
}
