import { FontAwesome5IconName } from '@elements'
import { FontAwesome5 } from '@expo/vector-icons'
import {
  createDrawerNavigator,
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItemList,
} from '@react-navigation/drawer'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import IconWithBadge from '../components/elements/Icon/IconWithBadge'
import { Header } from '../components/Header/Header'
import { Image } from '../components/Image'
import Colors from '../constants/Colors'
import { unreadCountSelector } from '../redux/selectors'
import { AppStatus } from '../screens/AppStatus'
import { HomeNavigator, NotificationNavigator, OrdersNavigator, ShoppingNavigator } from './ConsumerNavigatorCommon'
import { ConsumerNavigatorParamList } from './types'
import UserNavigator from './UserNavigator'

const WebConsumerDrawer = createDrawerNavigator<ConsumerNavigatorParamList>()

function DrawerHeader(props: DrawerContentComponentProps) {
  return (
    <View>
      <DrawerContentScrollView>
        <Image
          source={require(`../assets/images/text_logo.png`)}
          style={{ width: 202, height: 50, marginVertical: 20, alignSelf: 'center', resizeMode: 'contain' }}
        />
        <DrawerItemList {...props} />
      </DrawerContentScrollView>
    </View>
  )
}

export default function ConsumerNavigator() {
  const unreadCount = useSelector(unreadCountSelector)
  return (
    <AppStatus scope="consumer">
      <WebConsumerDrawer.Navigator
        initialRouteName="Home"
        drawerContent={(props: DrawerContentComponentProps) => <DrawerHeader {...props} />}
        screenOptions={{
          header: () => <Header />,
          drawerStyle: { justifyContent: 'flex-start' },
          drawerType: 'front',
        }}
      >
        <WebConsumerDrawer.Screen
          name="Home"
          component={HomeNavigator}
          options={{
            drawerLabel: 'Home',
            headerShown: false,
            header: undefined,
            drawerIcon: ({ focused }) => <DrawerIcon name="home" focused={focused} />,
          }}
        />
        <WebConsumerDrawer.Screen
          name="Shopping"
          component={ShoppingNavigator}
          options={{
            drawerLabel: 'My Farms',
            headerShown: false, //Header must come from within Shopping navigator in order for it to have the screen names as available options in navigation.getState().routeNames
            header: undefined,
            drawerIcon: ({ focused }) => <DrawerIcon name="heart" focused={focused} />,
          }}
        />
        <WebConsumerDrawer.Screen
          name="OrdersNavigator"
          component={OrdersNavigator}
          options={{
            drawerLabel: 'Orders',
            drawerIcon: ({ focused }) => <DrawerIcon name="box" focused={focused} />,
          }}
        />
        <WebConsumerDrawer.Screen
          name="Notifications"
          component={NotificationNavigator}
          options={{
            drawerIcon: ({ focused }) => (
              <View>
                {unreadCount === 0 ? (
                  <DrawerIcon name="bell" focused={focused} />
                ) : (
                  <IconWithBadge size={20} name="bell" solid={focused} badgeValue={unreadCount} onPress={() => {}} />
                )}
              </View>
            ),
          }}
        />
        <WebConsumerDrawer.Screen
          name="User"
          component={UserNavigator}
          options={{
            //Drawer item is hidden but the drawer stack needs to be defined
            drawerItemStyle: { height: 0 },
            drawerLabel: 'Profile',
            drawerIcon: ({ focused }) => <DrawerIcon name="user-circle" focused={focused} />,
          }}
        />
      </WebConsumerDrawer.Navigator>
    </AppStatus>
  )
}

function DrawerIcon(props: { name: FontAwesome5IconName; focused: boolean }) {
  return (
    <FontAwesome5
      size={20}
      style={{ width: 20, alignSelf: 'center' }}
      solid={props.focused}
      color={Colors.green}
      {...props}
    />
  )
}
