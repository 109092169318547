import { Divider, DynamicTooltip, ErrorText, Text, TextH2 } from '@elements'
import { FontAwesome5 } from '@expo/vector-icons'
import { formatMoney } from '@helpers/display'
import { objToStr } from '@helpers/log'
import { MoneyCalc } from '@helpers/money'
import { isNonPickup } from '@models/Location'
import { EnterCartPromo } from '@screens/Shopping/components/EnterPromo'
import { PropsWithChildren, memo } from 'react'
import { View } from 'react-native'

import Colors from '@/constants/Colors'
import { OrderCreatorStatePayments } from '../../OrderCreatorScreen.helper'
import { OrderCreatorPaymentOptions } from '../PaymentOptions'

import { CartServiceType } from '@/constants/types/cartService'
import { useCartService } from '@/hooks/useCart'
import { Zero } from '@models/Money'
import { OrderSummaryItem as OrderEditSummaryItem } from '../../../OrderEditScreen/OrderSummaryItem'
import { DeliveryGroupItem } from './DeliveryGroupItem'
import { OrderSummaryItem as OrderCreatorSummaryItem } from './OrderSummaryItem'
import { styles } from './styles'

export type OrderSummaryProps_Reusable = {
  children?: PropsWithChildren['children']
  isWholesale: boolean
  farmId: string
  paymentsState: OrderCreatorStatePayments
  cartServiceType: Extract<CartServiceType, 'orderCreator' | 'orderEdit'>
}
/** Section in the order creator which holds the current cart items and all relevant details for finishing order placement.
 * - This is a reusable component for both the order creator and order edit.
 */
export const OrderSummary_Reusable = memo(function OrderSummary_reusable({
  children,
  farmId,
  isWholesale,
  paymentsState: {
    total,
    additionalFees,
    itemGroups,
    deliveryFeesData: { itemsDeliveryFees, err: deliveryFeesError },
  },
  cartServiceType,
}: OrderSummaryProps_Reusable) {
  const { cart } = useCartService({ cartServiceType, farmId, isWholesale })

  return (
    <View style={styles.wrapper}>
      <View style={styles.padded}>
        <TextH2 size={18}>Order Summary</TextH2>
        {children}
      </View>
      {cart.length > 0 ? (
        <View>
          <View style={[styles.headerOrderTotal, styles.padded]}>
            <TextH2>Total</TextH2>
            <TextH2>{formatMoney(total.total)}</TextH2>
          </View>
          <View style={styles.padded}>
            {cart.map((ci) =>
              cartServiceType === 'orderCreator' ? (
                <OrderCreatorSummaryItem key={ci.id} itemId={ci.id} />
              ) : cartServiceType === 'orderEdit' ? (
                <OrderEditSummaryItem key={ci.id} itemId={ci.id} />
              ) : null,
            )}
            <View style={styles.subtotalContainer}>
              <View style={styles.subtotalRow}>
                <Text>Subtotal</Text>
                <Text style={styles.subtotalAmount}>{formatMoney(total.subtotal)}</Text>
              </View>
            </View>
          </View>
          <EnterCartPromo
            cartFarmId={farmId}
            cartServiceType={cartServiceType}
            ebtTotal={total.ebtEligibleAmount ?? Zero}
            style={styles.addCoupon}
            isWholesale={isWholesale}
          />

          <View style={styles.taxesAndFeesAndDiscountsCont}>
            {!!total.discounts && !MoneyCalc.isZero(total.discounts) && (
              <View style={styles.subtotalRow}>
                <Text color={Colors.green}>Discounts</Text>
                <Text color={Colors.green} style={styles.totalDiscount}>
                  {`-${formatMoney(total.discounts)}`}
                </Text>
              </View>
            )}
            {additionalFees.length > 0 &&
              additionalFees.map((item) => (
                <View style={styles.subtotalRow} key={item.productFee.id}>
                  <Text>{item.productFee.name}</Text>
                  <Text style={styles.subtotalAmount}>{formatMoney(item.amount)}</Text>
                </View>
              ))}
            {MoneyCalc.isGTZero(total.tax) && (
              <View style={styles.subtotalRow}>
                <Text>Tax</Text>
                <Text style={styles.subtotalAmount}>{formatMoney(total.tax)}</Text>
              </View>
            )}
          </View>
          <View style={styles.orderTotalCont}>
            <TextH2 size={16}>Total</TextH2>
            <TextH2 style={styles.orderTotalAmount} size={16}>
              {formatMoney(total.total)}
            </TextH2>
          </View>
          {itemGroups.some((g) => g.locType && isNonPickup(g.locType)) && (
            <>
              <Divider large />
              <View style={styles.padded}>
                <TextH2 style={styles.deliverySectionTitle}>Delivery fees</TextH2>
                <View style={styles.deliveryGroupCont}>
                  {itemGroups
                    .filter((g) => g.locType && isNonPickup(g.locType))
                    .map((group) => (
                      <DeliveryGroupItem key={objToStr(group.items.map((ci) => ci.id).sort())} {...group} />
                    ))}
                </View>
              </View>
              <View style={styles.deliveryTotalCont}>
                <View style={styles.deliveryTotalMain}>
                  <TextH2 size={16}>Delivery total</TextH2>
                  <Text>
                    (Billed separately){' '}
                    <DynamicTooltip content="Delivery fees will be billed as a separate invoice per delivery, due on the delivery date" />
                  </Text>
                </View>
                <TextH2 size={16}>{deliveryFeesError ? '   -  ' : formatMoney(itemsDeliveryFees)}</TextH2>
              </View>
              {deliveryFeesError ? (
                <ErrorText>Couldn't calculate delivery fees due to an unexpected error</ErrorText>
              ) : null}
            </>
          )}
          {/* We don't want to show the payment options in order edit */}
          {cartServiceType === 'orderCreator' && <OrderCreatorPaymentOptions />}
        </View>
      ) : (
        <View style={styles.emptyCartCont}>
          <Text style={styles.emptyCartText}>No items yet</Text>
          <FontAwesome5 color={Colors.shades['300']} name="shopping-cart" size={60} />
        </View>
      )}
    </View>
  )
})
