import { formatMoney } from '@helpers/display'
import { DistributionSales, DistributionSalesForBuyingOption } from '@shared/types/v2/sales'

import { createColumns } from './helpers'
import { ExpandableRow } from '../../OfflineTable/ExpandableRow'
import { Tag } from '../../ProductTag'

import Colors from '@/constants/Colors'
import { memo } from 'react'

interface DistributionProps {
  data: DistributionSales | DistributionSalesForBuyingOption
  index: number
  indent: number
}

export const Distribution = memo(function Distribution({ data, indent, index }: DistributionProps) {
  return (
    <ExpandableRow<DistributionSales>
      item={data}
      index={index}
      columns={createColumns({
        name(buyingOption) {
          return buyingOption.name
        },
        sku() {
          return ''
        },
        type() {
          return <Tag color={Colors.brown} title="Schedule" />
        },
        quantitySold(buyingOption) {
          return buyingOption.quantitySold.toString()
        },
        grossAmount(buyingOption) {
          return formatMoney(buyingOption.grossAmount)
        },
      })}
      indent={indent}
      indentMode="first-col"
    />
  )
})
