import { errorToString } from '@helpers/helpers'
import { ErrorWithCode, isErrorWithCode } from '@shared/Errors'
import { ValidateUrlSafeSlugRequest } from '@shared/types/v2/urlSafeSlug'
import { callEndpoint } from './v2/call'

/**
 * Builds a urlSafeSlug for a document based on the provided parameters using the
 * 'v2.UrlSafeSlug.validateUrlSafeSlugService' endpoint.
 *
 * @param object - The request object
 * @returns A Promise that resolves to a urlSafeSlug for the document. If validation fails, the Promise will resolve to an empty string.
 */
export async function buildUrlSafeSlug(object: ValidateUrlSafeSlugRequest): Promise<string> {
  return await callEndpoint('v2.UrlSafeSlug.validateUrlSafeSlugService', object).catch((e) => {
    // If it is an error with code then pass it as is
    if (isErrorWithCode(e)) throw e

    // Otherwise we throw a generic ErrorWithCode
    throw new ErrorWithCode({
      code: 'verify-slug-failed',
      devMsg: errorToString(e),
      /** TODO: When we implement CSA and Product Slug into URL, we need to remove check  */
      uiMsg: `An unknown error occurred while saving ${object.collection}${
        object.collection === 'farms' ? ", (couldn't verify url safe name)." : '.'
      } Please contact support.`,
    })
  })
}
