import { withAuth } from '@/hooks/withAuth'
import { ConsumerView, ConversationView } from '@components'
import { useChatScreenData } from './hooks/useChatScreenData'

/** Shows user conversation messages. This component is used in both the consumer and admin chat screens so it must remain compatible with both */
export function ChatScreenComp_Reusable({ adminFarmId }: { adminFarmId?: string }) {
  const { messages, currUserId, loading, error, chatSession } = useChatScreenData()

  return (
    <ConversationView
      adminFarmId={adminFarmId}
      chatSession={chatSession}
      error={error}
      currUserId={currUserId}
      data={messages ?? []}
      loading={loading}
    />
  )
}

function ConsumerChatScreenComp() {
  return (
    <ConsumerView>
      <ChatScreenComp_Reusable />
    </ConsumerView>
  )
}

export const ConsumerChatScreen = withAuth(ConsumerChatScreenComp)
