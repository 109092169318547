import { Address, isPO } from '@models/Address'
import { Coordinate } from '@models/Coordinate'
import { ErrorTypes, ErrorWithCode } from '@shared/Errors'
import * as yup from 'yup'
import { getState } from '../../../assets/data/states'
import { Builder } from './Builder'
import { validateFromSchema } from './validators/helpers'
import { ShortZipSchema } from './validators/sharedValidation'

const coordinateSchema: yup.ObjectSchema<Coordinate> = yup
  .object({
    latitude: yup
      .number()
      .required()
      .test('Not zero', 'The latitude should not be zero', (val) => val !== 0),
    longitude: yup
      .number()
      .required()
      .test('Not zero', 'The longitude should not be zero', (val) => val !== 0),
  })
  .defined()

export const addressSchema: yup.ObjectSchema<Address> = yup
  .object({
    coordinate: coordinateSchema.required(),
    street1: yup.string().required(),
    street2: yup.string().optional(),
    city: yup.string().required(),
    // Should validate that the address is one of the ones specified in our state map
    state: yup
      .string()
      .test('state', 'State is invalid', function (value: unknown) {
        if (typeof value !== 'string') return false
        return !!getState(value)
      })
      .required(),
    zipcode: ShortZipSchema.required(),
    country: yup.string().optional(),
  })
  .defined()

/**
 * This builder will create an address object */
export class AddressBuilder extends Builder<Address> {
  static schema = addressSchema

  constructor() {
    super('address')
  }

  build(address: Partial<Address>, opts: { allowPO: boolean }): Address {
    return this.validate(address, opts)
  }

  validate(address: unknown, opts: { allowPO: boolean } = { allowPO: false }): Address {
    const validAddress: Address = validateFromSchema(addressSchema, address)

    if (!opts.allowPO && isPO(validAddress)) {
      throw new ErrorWithCode({
        type: ErrorTypes.Validation,
        code: 'no-po-address',
        devMsg: 'The address provided is a PO box which is not allowed.',
      })
    }

    return validAddress
  }
}
