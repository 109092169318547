import { createNavigationContainerRef } from '@react-navigation/native'

import { AppStackParamList } from './types'

export const navRef = createNavigationContainerRef<AppStackParamList>()

export function navigateRef(name: string, params?: any) {
  if (navRef.isReady()) {
    navRef.navigate<any>(name, params)
  }
}
