import { HeaderText, LoadingView, Text, TextH4 } from '@elements'
import { formatMoney, formatShortAddress } from '@helpers/display'
import { Farm } from '@models/Farm'
import { View } from 'react-native'

import { Image } from '@components'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'
import Colors from '../../constants/Colors'
import { globalStyles } from '../../constants/Styles'

type Props = {
  farm?: Farm
  loading?: boolean
  error?: string
  rightText: string
  credit?: number | null
  actionButton?: JSX.Element
}

/** This is the header for the cart and checkout screens that shows what farm you are shopping at */
export function FarmSnippet({ farm, loading, error, rightText, credit, actionButton }: Props) {
  const styles = useStyles()
  return (
    <LoadingView loading={loading ?? false} error={error} success={farm}>
      {(farm) => (
        <View style={styles.snippet}>
          <View style={[globalStyles.flexRowCenter, globalStyles.flex1]}>
            <Image type="logo" source={{ uri: farm.logo }} style={styles.farmProfile} />
            <View style={styles.farmNameCont}>
              <HeaderText size={18} numberOfLines={2}>
                {farm.name}
              </HeaderText>
              <Text numberOfLines={1}>{formatShortAddress(farm.address)}</Text>
            </View>
          </View>
          {!!credit && (
            <Text style={globalStyles.marginVertical10}>{`Farm Credit Available: ${formatMoney(credit)}`}</Text>
          )}
          <View style={styles.rightContainer}>
            {rightText ? <TextH4>{rightText}</TextH4> : null}
            {actionButton ? actionButton : null}
          </View>
        </View>
      )}
    </LoadingView>
  )
}

const useStyles = CreateResponsiveStyle(
  {
    snippet: {
      flexDirection: 'row',
      alignItems: 'center',
      borderBottomWidth: 1,
      borderBottomColor: Colors.shades['100'],
      paddingVertical: 10,
      paddingHorizontal: 20,
      justifyContent: 'space-between',
      backgroundColor: Colors.secondaryGreen3,
    },
    farmProfile: {
      width: 50,
      height: 50,
      borderRadius: 25,
    },
    rightContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    farmNameCont: {
      marginLeft: 10,
      flex: 0.9,
    },
  },
  {
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      snippet: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      rightContainer: {
        alignSelf: 'flex-end',
      },
    },
  },
)
