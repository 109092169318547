import React, { memo, useCallback, useRef, useState } from 'react'
import { Animated, Platform, ScrollView, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { useSelector } from 'react-redux'

import Colors from '../../constants/Colors'
import { navRouteSelector } from '../../redux/selectors'
import CircleButton from '../CircleButton'
import ShoppingCart from '../ShoppingCart'
import { Divider } from '../elements/Divider'
import { Icon } from '../elements/Icon/Icon'
import { LoadingView } from '../elements/LoadingView'
import { Spinner } from '../elements/Spinner'
import { ParallaxMediaCarousel } from './ParallaxMediaCarousel'
import { getRatioHeight } from './getRatioHeight'
import { ParallaxButtons, ParallaxProps } from './index'

import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { useFocusFx } from '@/hooks/useFocusFx'
import { useLayout } from '@/hooks/useLayout'

const BAR_HEIGHT = 48 // Should be the same in the bar height style and the scroll dist

export const ParallaxHeader = memo(function ParallaxHeader(props: ParallaxProps) {
  const route = useSelector(navRouteSelector)
  // iOS has negative initial scroll value because content inset...
  const layout = useLayout()
  const headerHeight = getRatioHeight({ layout })
  const [scrollDist, setScrollDist] = useState(0)
  const yScrolling = useRef(new Animated.Value(Platform.OS === 'ios' ? -headerHeight : 0)).current
  const scrollView = useRef<ScrollView>(null)
  const isFooterScreen = route === 'ProductDetails' || route === 'FarmDetailScreen'
  const fStyles = useStyles(isFooterScreen, headerHeight)

  // Because of content inset the scroll value will be negative on iOS so bring
  // it back to 0.
  const scrollY = Animated.add(yScrolling, Platform.OS === 'ios' ? headerHeight : 0)
  const headerTranslate = scrollY.interpolate({
    inputRange: [0, scrollDist],
    outputRange: [0, -scrollDist],
    extrapolate: 'clamp',
  })

  const imageOpacity = scrollY.interpolate({
    inputRange: [0, scrollDist / 2, scrollDist],
    outputRange: [1, 1, 0],
    extrapolate: 'clamp',
  })

  const textOpacity = scrollY.interpolate({
    inputRange: [0, scrollDist / 2, scrollDist],
    outputRange: [0, 0, 1],
    extrapolate: 'clamp',
  })
  const imageTranslate = scrollY.interpolate({
    inputRange: [0, scrollDist],
    outputRange: [0, 100],
    extrapolate: 'clamp',
  })

  useFocusFx(() => {
    const dist = headerHeight - BAR_HEIGHT
    setScrollDist(dist > 0 ? dist : 0)
  }, [headerHeight])

  return (
    <View style={fStyles.container}>
      <Animated.ScrollView
        ref={scrollView}
        scrollEventThrottle={1}
        onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: yScrolling } } }], {
          useNativeDriver: true,
        })}
        // iOS offset for RefreshControl
        contentInset={{
          top: headerHeight,
        }}
        contentOffset={{
          x: 0,
          y: -headerHeight,
        }}
        keyboardShouldPersistTaps="always" //prevents error VirtualizedLists should never be nested inside plan ScrollViews. Which refers to the scrollview inside ParallaxHeader
      >
        <LoadingView style={fStyles.scrollview} loading={props.loading ?? false}>
          {props.webElements?.sticky}
          <Divider large />
          {props.webElements?.content}
          {props.children}
        </LoadingView>
      </Animated.ScrollView>
      <LoadingView loading={props.loading ?? false} switchMode>
        <ParallaxMediaCarousel
          style={[styles.header, { transform: [{ translateY: headerTranslate }] }] as StyleProp<ViewStyle>}
          media={props.media}
          headerTranslate={headerTranslate}
          imageTranslate={imageTranslate}
          imageOpacity={imageOpacity}
        />
      </LoadingView>
      <Animated.View style={styles.bar}>
        <Animated.Text
          numberOfLines={1}
          allowFontScaling={false}
          style={[styles.title, { opacity: textOpacity, marginRight: props.buttons?.right ? 100 : 50 }]}
          onPress={useCallback(() => scrollView.current?.scrollTo({ y: 0 }), [scrollView.current])}
        >
          {props.loading ? 'Loading...' : props.title}
        </Animated.Text>

        <LeftIcon button={props.buttons?.left} />
        <RightIcon button={props.buttons?.right} />

        <CircleButton style={[styles.circleBtn, { right: 4 }]}>
          <ShoppingCart />
        </CircleButton>
      </Animated.View>
    </View>
  )
})

function LeftIcon({ button }: { button?: ParallaxButtons['left'] }) {
  if (!button) return null
  if (button.icon === 'loading') return <Spinner size="large" style={[styles.circleBtn, styles.left4]} />
  return (
    <CircleButton onPress={button.onPress} style={[styles.circleBtn, styles.left4]}>
      <Icon name={button.icon} size={25} color={Colors.black} />
    </CircleButton>
  )
}

function RightIcon({ button }: { button?: ParallaxButtons['right'] }) {
  if (!button) return null
  if (button.icon === 'loading')
    return <Spinner size="large" style={[styles.circleBtn, { right: 50, flex: undefined, alignSelf: undefined }]} />

  return (
    <CircleButton onPress={button.onPress} style={[styles.circleBtn, { right: 50 }]}>
      <Icon name={button.icon} solid={button.isSolid} color={Colors.red} size={25} />
    </CircleButton>
  )
}
const useStyles = (isFooterScreen: boolean, headerHeight: number) => {
  return useLayoutFnStyles(
    ({ isSmallDevice, height }, isFooterScreen, headerHeight) => ({
      // iOS uses content inset, which acts like padding.
      scrollview: {
        padding: 20,
        paddingTop: Platform.OS !== 'ios' ? headerHeight + 15 : 15,
      },
      container: {
        maxHeight: height,
        flex: 1,
        paddingBottom: isSmallDevice && isFooterScreen ? 50 : 0, //Leave space for the footer in some screens
      },
    }),
    isFooterScreen,
    headerHeight,
  )
}
const styles = StyleSheet.create({
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: Colors.white,
  },
  bar: {
    flexDirection: 'row',
    backgroundColor: Colors.transparent,
    height: BAR_HEIGHT,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  title: {
    flex: 1,
    marginLeft: 50, //For the back button
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 16,
    lineHeight: 20,
    textAlign: 'center',
    alignSelf: 'center',
  },
  circleBtn: { position: 'absolute', top: 4, width: 40, height: 40, borderRadius: 40 },
  left4: {
    left: 4,
  },
})
