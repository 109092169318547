import { ExpandableRow } from '@/admin/components/OfflineTable/ExpandableRow'
import { Logger } from '@/config/logger'
import Colors from '@/constants/Colors'
import { adminFarmSelector } from '@/redux/selectors'
import { canDeleteLocalProducer, deleteLocalProducerFromFarm, editLocalProducer } from '@api/Producers'
import { Alert, Prompt, Text, Toast, Touchable } from '@elements'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import React, { memo, useCallback, useState } from 'react'
import { StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'

type RowProps = {
  producerRecord: Record<string, number>
  index: number
}
export const ProducerRow = memo(function ProducerRow({ producerRecord, index }: RowProps) {
  const farm = useSelector(adminFarmSelector)
  const [loading, setLoading] = useState(false)
  const producerName = Object.keys(producerRecord)[0]
  const productCount = producerRecord[producerName]

  const handleEditPress = useCallback(async () => {
    Prompt('Edit Producer', {
      onSubmit: async (value) => {
        if (value && value !== producerName) {
          try {
            setLoading(true)
            await editLocalProducer({ farmId: farm.id, oldProducerName: producerName, newProducerName: value })
            Toast('Producer edited successfully')
          } catch (error) {
            Logger.error(error)
            Toast('Something went wrong while editing this producer')
          } finally {
            setLoading(false)
          }
        }
      },
      inputProps: { placeholder: 'Edit producer name here', defaultValue: producerName },
    })
  }, [farm.id, producerName])

  const handleDeletePress = useCallback(async () => {
    try {
      setLoading(true)
      const canDelete = await canDeleteLocalProducer(farm.id, producerName)
      if (canDelete) {
        Alert('Delete Producer', 'Are you sure you want to delete this Producer?', [
          {
            text: 'Delete',
            onPress: async () => {
              try {
                await deleteLocalProducerFromFarm(farm.id, producerName)
                Toast('Producer deleted successfully')
              } catch (error) {
                Logger.error(error)
                Toast('Something went wrong while deleting this producer')
              }
            },
          },
          {
            text: 'Cancel',
            style: 'cancel',
          },
        ])
      } else {
        Toast('This Producer cannot be deleted because it has a product associated with it.')
      }
    } catch (error) {
      Logger.error(error)
      Toast('Something went wrong')
    } finally {
      setLoading(false)
    }
  }, [farm.id, producerName])

  const columns = [
    {
      process: () => (
        <Text numberOfLines={2} onPress={handleEditPress}>
          {producerName}
        </Text>
      ),
      widthFlex: 2,
    },
    {
      process: () => <Text>{productCount}</Text>,
      widthFlex: 1.5,
    },
    {
      widthFlex: 0.5,
      process: () => (
        <Touchable onPress={handleEditPress} style={styles.rowBtnCont} disabled={loading}>
          <Text type="medium" color={Colors.green}>
            Edit
          </Text>
        </Touchable>
      ),
    },
    {
      widthFlex: 0.5,
      process: () => (
        <Touchable onPress={handleDeletePress} style={styles.rowBtnCont} disabled={loading}>
          <Text type="medium" color={Colors.green}>
            Delete
          </Text>
        </Touchable>
      ),
    },
  ]

  return <ExpandableRow<Record<string, number>> item={producerRecord} index={index} columns={columns} />
}, propsAreDeepEqual)

const styles = StyleSheet.create({
  rowBtnCont: {
    alignItems: 'center',
    minWidth: 18,
  },
})
