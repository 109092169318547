import { AddressBuilder } from './AddressBuilder'
import { DistributionBuilder } from './DistributionBuilder'
import { LocationBuilder } from './LocationBuilder'
import { PickupBuilder } from './PickupBuilder'
import { ProductBuilder } from './ProductBuilder'
import { ProductFeeBuilder } from './ProductFeeBuilder'
import { ScheduleBuilder } from './ScheduleBuilder'
import { CouponBuilder } from './CouponBuilder'
import { PickupItemBuilder } from './PickupItemBuilder'

// NOTE: Builders from this file should be used only outside other builders. For using as part of another builder, it should be imported directly from the builder file. See the ProductFeeBuilder used in buildProducts for an example.

export const addressBuilder = new AddressBuilder()
export const locationBuilder = new LocationBuilder()
export const scheduleBuilder = new ScheduleBuilder()
export const distributionBuilder = new DistributionBuilder()
export const productBuilder = new ProductBuilder()
export const couponBuilder = new CouponBuilder()
export const productFeeBuilder = new ProductFeeBuilder()
export const pickupItemBuilder = new PickupItemBuilder()
export const pickupBuilder = new PickupBuilder()
