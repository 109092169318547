import { Logger } from '@/config/logger'
import { globalStyles } from '@/constants/Styles'
import useKeyedState from '@/hooks/useKeyedState'
import { sendChatMessage } from '@api/Messaging'
import { Button, FormInput, hideModal, KeyboardAvoidingScrollView, Text } from '@elements'
import { SendMessageProps } from '@models/Messaging'
import { useCallback } from 'react'
import { StyleSheet, View } from 'react-native'

type SendChatMessageProps = Pick<SendMessageProps, 'to' | 'from'> & {
  isAdmin?: boolean
}

/** Component that will send a chat message to a specific farm or user */
export function SendChatMessage({ to, from, isAdmin }: SendChatMessageProps) {
  const [{ message, errorMsg, isSending, messageSent }, , , setters, setPartial] = useKeyedState({
    message: '',
    errorMsg: '',
    isSending: false,
    messageSent: false,
  })

  const onSendPress = useCallback(async () => {
    try {
      if (!message) {
        setters.errorMsg('A message is required')
        return
      }
      setPartial({
        isSending: true,
        errorMsg: undefined,
        messageSent: false,
      })

      await sendChatMessage({ to, from, message: { type: 'text', content: message }, chatSessionId: undefined })

      setPartial({
        isSending: false,
        messageSent: true,
      })
    } catch (err) {
      setPartial({ errorMsg: 'Something went wrong. Please try again', isSending: false })
      Logger.error(err)
    }
  }, [from, message, setPartial, setters, to])

  return (
    <KeyboardAvoidingScrollView contentContainerStyle={styles.contentCont}>
      <FormInput
        placeholder="Enter your message"
        label=""
        value={message}
        onChangeText={setters.message}
        inputStyle={styles.textArea}
        disabled={isSending || messageSent}
        multiline
        errorMessage={errorMsg}
      />
      {messageSent ? (
        <View style={styles.successSentCont}>
          <Text>Message successfully sent!</Text>
          <View style={globalStyles.flexRow}>
            <Button title="Close" onPress={hideModal} />
            <Button
              title="Go to messages"
              onPress={hideModal}
              url={isAdmin ? '/admin/messages' : '/profile/messages'}
            />
          </View>
        </View>
      ) : (
        <Button title="Send" loading={isSending} onPress={onSendPress} />
      )}
    </KeyboardAvoidingScrollView>
  )
}

const styles = StyleSheet.create({
  textArea: {
    padding: 10,
    height: 150,
  },
  contentCont: {
    gap: 14,
  },
  successSentCont: {
    alignItems: 'center',
    gap: 12,
  },
})
