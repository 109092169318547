import { connectStateResults } from 'react-instantsearch-native'
import { StyleSheet, View, ViewStyle } from 'react-native'

import { Spinner } from '@elements'
import { memo } from 'react'
import { Button } from '../../../components/elements/Button'
import Colors from '../../../constants/Colors'
import { SHADOW_5 } from '../../../constants/Styles'
import { useDeviceSize } from '../../../hooks/useLayout'

export const LoadingState = connectStateResults(({ searching, isSearchStalled }) => {
  const { isSmallDevice } = useDeviceSize()
  const loading: boolean = (searching || isSearchStalled) ?? true
  return loading ? (
    <View style={{ position: 'absolute', top: isSmallDevice ? 20 : 100, width: '100%', alignItems: 'center' }}>
      <Spinner />
    </View>
  ) : null
})

type ViewType = 'list' | 'map'

type FloatingBtnProps = {
  btnStyle?: ViewStyle
  view: ViewType
  onPress: (view: ViewType) => void
}

/** Button that appears above the map, which allows switching from list to map view on non-large devices */
export const FloatingButton = memo(function FloatingButton({ btnStyle, view, onPress }: FloatingBtnProps) {
  return (
    <Button
      style={[styles.floatingBtn, btnStyle]}
      title={view === 'map' ? 'Show List' : 'Show Map'}
      icon={view === 'map' ? 'list-ul' : 'map-marked-alt'}
      onPress={() => onPress(view === 'map' ? 'list' : 'map')}
    />
  )
})

/** Cirle UI to show as a map overlay */
export const GrayCircle = memo(function GrayCircle({ visible, size = 20 }: { visible: boolean; size?: number }) {
  return visible ? (
    <View style={{ justifyContent: 'center', alignItems: 'center', backgroundColor: Colors.transparent }}>
      <View
        style={{
          height: size,
          width: size,
          borderRadius: size * 2,
          backgroundColor: Colors.shades[200],
          marginHorizontal: 5,
          overflow: 'hidden',
        }}
      />
    </View>
  ) : null
})

const styles = StyleSheet.create({
  floatingBtn: {
    ...SHADOW_5,
    borderRadius: 50,
    maxWidth: 200,
    alignSelf: 'center',
  },
})
