import { ProductSchemaContext } from '@helpers/builders/buildProduct'
import { PartialPick } from '@helpers/typescript'
import { Product, ProductType, isShare } from '@models/Product'
import * as Yup from 'yup'
import { ProductFormikComponent } from './ProductFormikComponent'

/** Form type that contains information about the product type, which is required by all forms to identify the subtype of product */
export type ProductTypeForm = {
  type: ProductType
  hasTemplate: boolean
}

const productTypeSchema = Yup.object<ProductSchemaContext>().shape({
  type: Yup.mixed<ProductType>().oneOf(Object.values(ProductType)).required(),
  hasTemplate: Yup.boolean().required(),
})

const hasTemplate = (product: PartialPick<Product, 'type'>): boolean => {
  if (isShare(product) && product.templateProduct?.id) return true

  return false
}

const toFormik = (product: PartialPick<Product, 'type'>): ProductTypeForm => {
  return {
    type: product.type,
    hasTemplate: hasTemplate(product),
  }
}

function fromFormik(values: { type: ProductType }): Partial<Product> {
  return {
    type: values.type,
  }
}
export const FormikTypeInformation = new ProductFormikComponent(productTypeSchema, toFormik, fromFormik)
