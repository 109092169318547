import { dequal } from '@helpers/customDequal'
import { isNonNullish } from '@helpers/helpers'
import { RefObject, useCallback, useMemo, useRef, useState } from 'react'
import { TextInput, View, useWindowDimensions } from 'react-native'

import { useFocusFx } from './useFocusFx'

export type Measure = {
  x?: number
  y?: number
  width: number
  height: number
  pageX: number
  pageY: number
}

/** Measures an element on mount, unmount, and screen resize
 * - To use this reliably on all platforms, you must set the prop `collapsable={false}` on the element that receives this ref. https://github.com/facebook/react-native/issues/3282#issuecomment-269870513
 * - `measure` only works with View and TextInput. `measureInWindow` may work for other core components
 */
export default function useMeasure<T extends View | TextInput>(): [Measure | undefined, RefObject<T>, () => void] {
  const { height, width } = useWindowDimensions()
  const [measure, setMeasure] = useState<Measure>()
  const ref = useRef<T>(null) //ref.current only changes on mount and unmount

  /** Measuring function, could be passed to the component's onLayout, to update ref measurement onLayout change */
  const measureEl = useCallback(
    () =>
      ref.current?.measure((x, y, width, height, pageX, pageY) => {
        const newMeasure: Measure = { x, y, width, height, pageX, pageY }
        if (Object.values(newMeasure).every((v) => isNonNullish(v)) && !dequal(newMeasure, measure))
          setMeasure({ x, y, pageX, pageY, width, height })
      }),
    // It is meant to trigger on screen size change, even if width-height aren't necessary in the calculation
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [width, height, measure, ref.current],
  )

  /** Runs measuring on fn update */
  useFocusFx(() => {
    measureEl()
  }, [measureEl])

  // no need ref in deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => [measure, ref, measureEl], [measure, measureEl])
}
