import { ConsumerScroll } from '@components'
import { RouteProp } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { ExternalLinkingParamList } from '../../navigation/types'
import { InvoiceBase } from './components/InvoiceBase'

import { withAuth } from '@/hooks/withAuth'
import { isAdminSelector } from '@/redux/selectors'

interface Props {
  route: RouteProp<ExternalLinkingParamList, 'InvoiceExternal'>
  navigation: StackNavigationProp<ExternalLinkingParamList, 'InvoiceExternal'>
}

function InvoiceExternalComp({ route, navigation }: Props) {
  const isAdmin = useSelector(isAdminSelector)
  const { id } = route.params

  const onBackPress = useCallback(() => {
    const canGoBack = navigation.canGoBack()

    if (canGoBack) {
      navigation.goBack()
    } else {
      if (isAdmin) navigation.navigate('AdminDrawerNavigator', { screen: 'Invoices' })
      //person who can see the invoice and is the customer of the invoice farm
      else navigation.navigate('Consumer')
    }
  }, [isAdmin, navigation])

  //Based on firebase rules, only manager/admin or invoice owner can see the invoice, so if the invoice can not be loaded then it means the user is not authorized to see it.
  return (
    <ConsumerScroll>
      <InvoiceBase id={id} onClosePress={onBackPress} />
    </ConsumerScroll>
  )
}

export const InvoiceExternal = withAuth(InvoiceExternalComp)
