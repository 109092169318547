import { FeeWaiveOptionType, isLocalPickup, Location, LocationTypes, NonPickup } from '@models/Location'
import { ErrorTypes, ErrorWithCode } from '@shared/Errors'
import * as yup from 'yup'
import { TestContext, ValidationError } from 'yup'
import { hasOwnProperty } from '../helpers'
import { AddressBuilder } from './AddressBuilder'
import { Builder } from './Builder'
import { isYupValidationError, validateFromSchema, YupValidationError } from './validators/helpers'
import { ShortStateSchema, ShortZipSchema } from './validators/sharedValidation'
import { moneySchema } from './validators/validateMoney'

const feeWaiveOptionTypeSchema = yup.mixed<FeeWaiveOptionType>().oneOf(Object.values(FeeWaiveOptionType))

const regionsSchema: yup.Schema<any> = yup.array().of(
  yup.mixed().test({
    name: 'regions-validation',
    exclusive: true,
    message: 'Invalid region type for the specified location type',
    test(value, context: TestContext) {
      if (!value) return false
      const locationType = this.options.context!.type as LocationTypes

      try {
        if (locationType === LocationTypes.Delivery) {
          ShortZipSchema.validateSync(value, { strict: true })
          return true
        } else if (locationType === LocationTypes.Shipping) {
          ShortStateSchema.validateSync(value, { strict: true })
          return true
        } else {
          // If the location type is unknown then check that it is either a valid zip or state
          return ShortZipSchema.isValidSync(value) || ShortStateSchema.isValidSync(value)
        }
      } catch (error) {
        if (error instanceof ValidationError) {
          // If one of the region validators failed, show the validation error from that
          return context.createError({ message: error.message })
        }
        // Return false that validation failed, and it will show the default message
        return false
      }
    },
  }),
)

// Base location schema, we omit address because it is overridden by the specific location type
export const locationSchema = yup
  .object()
  .shape({
    id: yup.string().required(),
    farm: yup
      .object({
        id: yup.string().required(),
        urlSafeSlug: yup.string().required(),
      })
      .required(),
    name: yup.string().required(),
    nickname: yup.string().optional(),
    timezone: yup.string().required(),
    type: yup.mixed().when('$type', {
      is: (type: LocationTypes) => isLocalPickup(type),
      then: (schema) =>
        schema
          .oneOf([LocationTypes.MARKET, LocationTypes.STAND, LocationTypes.COMMUNITY, LocationTypes.FARM])
          .required(),
      otherwise: (schema) => schema.oneOf([LocationTypes.Shipping, LocationTypes.Delivery]).required(),
    }),
    address: yup.mixed().when('$type', {
      is: (type: LocationTypes) => isLocalPickup(type),
      then: () => AddressBuilder.schema.required(),
      otherwise: (schema) => schema.isUndefined(),
    }),
    cost: yup.mixed().when('$type', {
      is: (type: LocationTypes) => isLocalPickup(type),
      then: (schema) => schema.isUndefined(),
      otherwise: () => moneySchema.required(),
    }),
    feeWaiveOption: yup.mixed().when('$type', {
      is: (type: LocationTypes) => isLocalPickup(type),
      then: (schema) => schema.isUndefined(),
      otherwise: () => feeWaiveOptionTypeSchema.required(),
    }),
    regions: yup.mixed().when('$type', {
      is: (type: LocationTypes) => isLocalPickup(type),
      then: (schema) => schema.isUndefined(),
      otherwise: () => regionsSchema.required() as yup.Schema<NonPickup['regions']>,
    }),
  })
  .defined() as unknown as yup.ObjectSchema<Location>

/**
 * This builder will create a location object */
export class LocationBuilder extends Builder<Location> {
  constructor() {
    super('location')
  }

  build(location: Location): Location {
    return location
  }

  validate(location: unknown, opts?: { ignoreFields?: undefined }): Location
  validate<Key extends keyof Location>(location: unknown, opts: { ignoreFields: Key[] }): Omit<Location, Key>
  /**
   * This function will validate a location object
   * @param location the location object to validate
   */
  validate<Key extends keyof Location>(location: unknown): Omit<Location, Key> {
    try {
      if (!hasOwnProperty(location, 'type')) {
        throw new ErrorWithCode({
          type: ErrorTypes.Validation,
          code: 'missing-location-type',
          devMsg: 'Data passed to validate location is not a valid type',
        })
      }
      // Context is used for the region validator to know what type of location it is
      return validateFromSchema(locationSchema, location, {
        context: { type: location.type },
      })
    } catch (error) {
      if (isYupValidationError(error)) {
        throw new YupValidationError({ path: 'location.' + (error.data?.path ?? ''), msg: error.message })
      }
      throw error
    }
  }
}
