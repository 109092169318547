import { Alert, Toast } from '@elements'
import { formatZipcode } from '@helpers/display'
import { hasOwnProperty } from '@helpers/helpers'
import { omit, pick } from '@helpers/typescript'
import { InviteType } from '@models/ExternalLink'
import { Farm } from '@models/Farm'
import { SignInProviders, User } from '@models/User'
import { UserAddress } from '@models/UserAddress'
import { isNotFound } from '@shared/Errors'
import { getDownloadURL, ref } from 'firebase/storage'

import { buildUser } from '@helpers/builders/buildUser'
import { geocode } from './Addresses'
import { inviteFarmUser } from './ExternalLinks'
import { loadFarmAssociation } from './FarmAssociations'

import { createFirestoreAndAuthUser, loadUserByEmail } from './Users'
import { storage } from './db'

import { getShortState } from '@/assets/data/states'
import { getRunConfiguration } from '@/config/Environment'
import { Logger } from '@/config/logger'
import { getAddressErrors } from '@models/Address'

const bucketNameSuffix = 'farmdata'

export type AddCustomerData = Pick<UserAddress, 'street1' | 'street2' | 'city' | 'state' | 'zipcode'> & {
  firstName: string
  lastName: string
  email: string
  email2?: string
  phoneNumber?: string
}

/** create New User data without assigning Id for it yet. */
const createUserData = async (data: AddCustomerData, farm: Farm): Promise<Omit<User, 'id'>> => {
  const user: Omit<User, 'id'> = omit(
    buildUser('', data.firstName, data.lastName, data.email, SignInProviders.Email, ''),
    'id',
  )

  if (data.street1 && data.state && data.city && data.zipcode) {
    const address: UserAddress = {
      id: '',
      city: data.city,
      state: data.state,
      street1: data.street1,
      street2: data.street2,
      zipcode: formatZipcode(data.zipcode),
      coordinate: { latitude: 0, longitude: 0 },
    }
    try {
      const res = await geocode(address)
      address.coordinate = res.coordinate
    } catch (err) {
      Logger.debug(err)
    }

    // Adds the address to the new user
    user.address = address
  } else {
    user.address = undefined
  }

  user.invitedByFarm = pick(farm, 'id', 'name')
  user.phoneNumber = data.phoneNumber

  if (user.address) {
    const errors = getAddressErrors(user.address)
    if (errors) {
      throw new Error(Object.values(errors).join(', '))
    }

    // Confirms the state is valid and in state code form (ex. CA)
    const stateCode = getShortState(user.address.state)
    if (!stateCode) throw new Error('Invalid state')

    user.address.state = stateCode
  }

  return user
}

const checkUserExists = async (email: string): Promise<User | false> => {
  try {
    return await loadUserByEmail(email)
  } catch (e) {
    if (isNotFound(e)) return false
    throw e
  }
}

const checkIsFarmCustomer = async (userId: string, farmId: string): Promise<boolean> => {
  try {
    await loadFarmAssociation(userId, farmId)
    return true
  } catch (e) {
    if (isNotFound(e)) return false
    throw e
  }
}

/** invite existing user to be the farm customer */
export const inviteExistingCustomer = async (customer: User, farm: Farm, farmAdmin: User) => {
  const association = await checkIsFarmCustomer(customer.id, farm.id)
  if (association) {
    // User exists and is a customer so do nothing
    Toast(`${customer.email} is already a customer`)
  } else {
    // User exists and not customer so invite them instead
    try {
      await inviteFarmUser(farm, customer.email, farmAdmin, InviteType.Customer)
      Alert(
        'Customer Invited!',
        `${customer.email} is already shopping on GrownBy! We will send them an invitation to favorite your farm and be added to your customer list.`,
      )
    } catch (err) {
      Logger.error(err)
      Alert(
        'Unable to invite',
        `We were unable to add this customer to your farm, please try again in a little while or contact support.`,
      )
    }
  }
}

/** add new user to be the farm customer  */
export const addCustomer = async (data: AddCustomerData, farm: Farm, farmAdmin: User) => {
  try {
    const customer = await checkUserExists(data.email)
    if (customer) {
      await inviteExistingCustomer(customer, farm, farmAdmin)
    } else {
      const userData = await createUserData(data, farm)
      await createFirestoreAndAuthUser(userData, farm.id)
      Toast('Customer added!')
    }
  } catch (err) {
    Logger.debug(err)
    if (hasOwnProperty(err, 'code') && typeof err.code === 'string') {
      throw Error(err.code)
    }
    throw err
  }
}

export const GetCustomerImportTemplate = async () => {
  const csvRef = ref(
    storage(),
    `gs://${getRunConfiguration().projectId}-${bucketNameSuffix}/files/Customers Import Template.csv`,
  )

  return await getDownloadURL(csvRef)
}
