import { Farm } from '@models/Farm'
import { arrayRemove, arrayUnion, limit, where } from 'firebase/firestore'

import { loadProductsByFarm } from '@api/Products'
import { farmsCollection, productsCollection } from '@api/framework/ClientCollections'
import { callEndpoint } from '@api/v2'
import { EditLocalProducerRequest, EditLocalProducerResponse } from '@shared/types/v2/product'

/** Will get the producers from the farm */
export function loadFarmProducers(localProducers?: Farm['localProducers']): string[] {
  return localProducers?.sort() ?? []
}

/** This receives the farm-specific custom Producer and add it to the farm firestore doc. */
export async function addLocalProducer(pro: string, currFarm: string): Promise<'already-exists' | 'added'> {
  const farm = await farmsCollection.fetch(currFarm)
  const currProducers = farm.localProducers ?? []

  // block duplicates
  if (currProducers.includes(pro)) {
    return Promise.resolve('already-exists')
  }
  await farmsCollection.update({
    id: currFarm,
    localProducers: arrayUnion(pro) as any,
  })

  return 'added'
}
/**
 * List all local producers for a given farm and count the number of products associated with each producer.
 */
export async function listLocalProducersAndCountAssociatedProducts(
  currFarm: string,
  localProducers: Farm['localProducers'],
): Promise<Record<string, number>[]> {
  const products = await loadProductsByFarm(currFarm)

  const producerCounts = countProductsPerProducer(localProducers ?? [], products)

  return Object.keys(producerCounts).map((producer) => ({
    [producer]: producerCounts[producer],
  }))
}

/**
 * Edit a local producer for a specific farm by updating the producer name in-place.
 * This also updates the producer name for all products and template products associated with the old producer.
 */
export async function editLocalProducer(data: EditLocalProducerRequest): Promise<EditLocalProducerResponse> {
  return await callEndpoint('v2.Product.editLocalProducerService', data)
}

/**
 * Delete a local producer from a farm.
 */
export async function deleteLocalProducerFromFarm(farmId: string, producerName: string): Promise<boolean> {
  try {
    await farmsCollection.update({
      id: farmId,
      localProducers: arrayRemove(producerName) as any,
    })
    return true
  } catch (err) {
    return false
  }
}

/** Checks if a local producer can be deleted for the farm */
export async function canDeleteLocalProducer(farmId: string, producerName: string): Promise<boolean> {
  const prodsWithProducer = await productsCollection.fetchAll(
    where('farm.id', '==', farmId),
    where('producer', '==', producerName),
    limit(1),
  )

  return prodsWithProducer.length === 0
}

function countProductsPerProducer(localProducers: string[], products: any[]): Record<string, number> {
  const producerCounts: Record<string, number> = {}

  localProducers.forEach((producer) => {
    producerCounts[producer] = 0
  })

  products.forEach((product) => {
    if (product.producer && localProducers.includes(product.producer)) {
      producerCounts[product.producer]++
    }
  })

  return producerCounts
}
