import { MessageWithIcon } from '@components'
import { Text } from '@elements'
import { memo } from 'react'
import { ListEmpty, ListEmptyProps } from '../FarmShop/components'

type Props = ListEmptyProps & {
  invalidCsa: boolean
}

/** Empty list component for the CSA screen. It is a superset of the Shop's ListEmpty component */
export const ListEmptyCSA = memo(function ListEmptyCSA(props: Props) {
  const { prodsLoading, invalidCsa, hasFiltering, hasError } = props

  if (invalidCsa) {
    return (
      <MessageWithIcon icon="exclamation-triangle" title="Invalid Link!">
        This CSA does not exist or is hidden, please check your link.
      </MessageWithIcon>
    )
  }
  if (prodsLoading || hasError || hasFiltering) {
    // If the state is shared with the shop list empty component, return that component
    return <ListEmpty prodsLoading={prodsLoading} hasError={hasError} hasFiltering={hasFiltering} />
  }

  return (
    <MessageWithIcon icon="tractor" title="Not Available!">
      <Text>This CSA does not have any more shares available at this time.</Text>
    </MessageWithIcon>
  )
})
