import Colors from '@/constants/Colors'
import { useDeviceSize } from '@/hooks/useLayout'
import { MessagesParamList } from '@/navigation/types'
import { ChatSession } from '@models/Messaging'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useMessages } from '@screens/MessagesScreen/MessagesScreen.context'
import { memo, useCallback, useState } from 'react'
import { FlatList, StyleSheet, View } from 'react-native'
import { ConversationCard } from '../ConversationCard/ConversationCard'
import { ConversationsListHeader } from './components/ConversationListHeader'

/** List of conversations between the logged in user and other participants */
export const ConversationsList = memo(function ConversationsList() {
  const [tabIdx, setTabIdx] = useState(0)
  const { selectedChatId, chatsSnap, onItemPress, onSearchValueChange, adminFarmId, user } = useMessages()
  const { isSmallDevice } = useDeviceSize()

  const navigation = useNavigation<StackNavigationProp<MessagesParamList, 'Chat'>>()

  const onCardPress = useCallback(
    (item: ChatSession) => {
      //On small devices, we are not selecting the chat as it should navigate to the chat screen
      if (isSmallDevice) {
        navigation.navigate('Chat', { id: item.id })
      } else {
        onItemPress(item.id)
      }
    },
    [isSmallDevice, navigation, onItemPress],
  )

  return (
    <FlatList
      ListHeaderComponent={() => (
        <ConversationsListHeader onTextChange={onSearchValueChange} tabIdx={tabIdx} onTabPress={setTabIdx} />
      )}
      data={chatsSnap.data}
      renderItem={({ item }) => (
        <ConversationCard
          isSelected={selectedChatId === item.id}
          chat={item}
          currUserId={adminFarmId ?? user.id}
          onPress={onCardPress}
        />
      )}
      ListFooterComponent={() => <View style={styles.footer} />}
      extraData={[selectedChatId, adminFarmId, user.id, tabIdx]}
    />
  )
})

const styles = StyleSheet.create({
  footer: {
    height: 20,
    borderTopWidth: 1,
    borderColor: Colors.shades['200'],
  },
})
