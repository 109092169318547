import { Text, typography } from '@elements'
import { ForwardedRef, forwardRef, memo, useCallback } from 'react'
import { StyleSheet, TextInput, TextInputProps, View } from 'react-native'
import { InputProps, Input as RNEInput } from 'react-native-elements'
import { MaskInputProps, formatWithMask } from 'react-native-mask-input'

import { useDeviceSize } from '../../hooks/useLayout'

import Colors from '@/constants/Colors'
import InputAutocomplete from '@/constants/inputAutocomplete'

//To receive the masked text, pass a "maskHandler" prop, which is similar to "onTextChange", but receives the masked, unmasked, and obfuscated text changed. If maskHandler is provided, onChangeText will have no effect.
type PhoneInputProps = Omit<InputProps, 'label' | 'onChangeText' | 'style'> & {
  maskHandler: MaskInputProps['onChangeText']
  label?: string | boolean //If undefined, will use default value. If false, won't use label. If string, will use that as label.
}

const mainNumMask = ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

const maskText = (text = '') =>
  formatWithMask({
    text,
    mask: mainNumMask,
  })

const defaultLabel = 'Phone Number'

// Component for entering phone in 10 digit format. already includes the "+1 " in a non-editable text. masks the input using easy-to-debug helper directly on the string. To use with formik, yup schema must use the "mainNumberPatternForms" regex from display.ts. Works on all platforms because UI and masking are two separate concerns so it's more maintainable.
export const PhoneInput = memo(function PhoneInput({
  value,
  maskHandler,
  placeholder = '(000) 123-1234',
  label = defaultLabel,
  inputContainerStyle,
  labelStyle,
  inputStyle,
  errorStyle,
  ...rest
}: PhoneInputProps) {
  const { isSmallDevice } = useDeviceSize()
  const { masked } = maskText(value)
  const resolvedLabel =
    typeof label === 'string' ? label : label === true || label === undefined ? defaultLabel : undefined

  const onChangeTextMask = useCallback(
    (text: string) => {
      if (maskHandler) {
        const { masked, unmasked, obfuscated } = maskText(text)
        maskHandler(masked, unmasked, obfuscated)
      }
    },
    [maskHandler],
  )

  return (
    <RNEInput
      InputComponent={InputWithCountryCode}
      inputContainerStyle={[styles.inputContainer, inputContainerStyle]}
      value={masked}
      onChangeText={onChangeTextMask}
      label={resolvedLabel}
      labelStyle={[!isSmallDevice && styles.labelLarge, labelStyle]}
      inputStyle={inputStyle}
      errorStyle={[styles.errorText, errorStyle]}
      placeholder={placeholder}
      {...InputAutocomplete.PHONE_NUMBER}
      {...rest}
    />
  )
})

// This component will create a separated input with the +1 then a text input for the phoneNumber
const InputWithCountryCode = forwardRef((props: TextInputProps, ref: ForwardedRef<TextInput>) => {
  return (
    <View style={styles.countryCodeWrapper}>
      <Text size={16}>+1 </Text>
      <TextInput ref={ref} {...props} />
    </View>
  )
})

const styles = StyleSheet.create({
  inputContainer: {
    paddingLeft: 5,
    borderWidth: 1,
    borderColor: Colors.shades['100'],
    borderRadius: 10,
  },
  countryCodeWrapper: { alignItems: 'center', flexDirection: 'row', flex: 1 },
  labelLarge: {
    fontSize: 16,
    color: Colors.shades['600'],
    marginBottom: 8,
    fontFamily: typography.body.regular,
    fontWeight: undefined,
  },
  errorText: {
    margin: 5,
    fontSize: 12,
    color: Colors.red,
    fontFamily: typography.body.medium,
  },
})
