import { SetStateAction, useState, useRef } from 'react'

// useSerialState provides a useState-compatible state hook that guards asynchronous updates that are older than
// the current state. The caller assigns a serial value, provided by the getSerial function, when setting state
// to identify the order. The caller may update state without a serial value to utilize standard useState behaviour.
export function useSerialState<S>(
  initialValue: S | (() => S),
): [S, (newState: SetStateAction<S>, update?: number) => void, () => number] {
  const [state, setState] = useState(initialValue)
  const serial = useRef(0)

  function setStateWithSerial(newState: SetStateAction<S>, updateSerial?: number): void {
    if (updateSerial === undefined) {
      setState(newState)
      return
    }
    if (updateSerial < serial.current) {
      return
    }
    setState(newState)
  }

  function getSerial(): number {
    const value = new Date().getTime()
    serial.current = value
    return value
  }

  return [state, setStateWithSerial, getSerial]
}
