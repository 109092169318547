import { FontAwesome5IconName, Icon, Text } from '@elements'
import { ImageSourcePropType, Pressable, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { Image } from '@components'

import Colors from '../../constants/Colors'

export type PaymentButtonBaseProps = {
  title: string
  subtitle?: string
  onPress(): void
  style?: StyleProp<ViewStyle>
  iconRight?: FontAwesome5IconName
  iconRightSolid?: boolean
  iconLeft?: FontAwesome5IconName
  iconLeftColor?: string
  imageLeft?: ImageSourcePropType
  isDisabled?: boolean
  isEditable?: boolean
  /** Either iconLeft and iconColorLeft should be passed or imageLeft, but not both */
} & (
  | { iconLeft: FontAwesome5IconName; iconLeftColor: string; imageLeft?: never }
  | { iconLeft?: never; iconLeftColor?: never; imageLeft: ImageSourcePropType }
)

/** This component is the base that all payment buttons are built from, it handles styling and rendering the sections required */
export function PaymentButtonBase({
  onPress,
  title,
  subtitle,
  style,
  iconRight,
  iconRightSolid,
  iconLeft,
  imageLeft,
  iconLeftColor,
  isDisabled,
  isEditable,
}: PaymentButtonBaseProps) {
  return (
    <Pressable style={[styles.containerStyle, style]} onPress={() => !isDisabled && onPress()} disabled={isDisabled}>
      {iconLeft ? (
        <Icon style={styles.iconStyle} color={isDisabled ? Colors.shades[300] : iconLeftColor} name={iconLeft} />
      ) : (
        <Image style={[styles.imageStyle, isDisabled && styles.disabledOpacity]} source={imageLeft} />
      )}
      <View style={styles.textCont}>
        <Text type="medium" color={isDisabled ? Colors.shades[300] : undefined} size={14}>
          {title}
        </Text>
        {!!subtitle &&
          (isDisabled ? (
            <Text color={Colors.shades[300]}>{subtitle}</Text>
          ) : (
            <Text type="medium" size={10}>
              {subtitle}
            </Text>
          ))}
      </View>
      {!!iconRight && (
        <Icon
          color={isDisabled ? Colors.shades[300] : isEditable ? Colors.red : Colors.green}
          name={isEditable ? 'trash' : iconRight}
          solid={iconRightSolid}
          size={20}
        />
      )}
    </Pressable>
  )
}

const styles = StyleSheet.create({
  containerStyle: {
    margin: 10,
    padding: 15,
    marginVertical: 5,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  textCont: {
    flex: 1,
    marginHorizontal: 10,
    justifyContent: 'center',
  },
  iconStyle: {
    fontSize: 30,
    paddingRight: 10,
  },
  errorTextCont: {
    maxWidth: '50%',
  },
  imageStyle: {
    width: 33.43,
    height: 26,
    marginRight: 10,
  },
  disabledOpacity: {
    opacity: 0.6,
  },
})
