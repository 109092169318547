import React from 'react'
import { Pressable, StyleSheet, View } from 'react-native'

import Colors, { withAlpha } from '@/constants/Colors'
import { Text } from '@elements'

type TabSelectorProps = {
  buttons: { title: string; onPress?: () => void; badge: number }[]
  selectedIndex: number
  onSelect?: (index: number) => void
}
/** Selector used  for individually select a specific tab */
export function TabSelector({ buttons, selectedIndex, onSelect }: TabSelectorProps) {
  return (
    <View style={style.wrapper}>
      {buttons.map((btn, idx) => (
        <Pressable
          key={btn.title}
          onPress={() => onSelect?.(idx)}
          style={[style.tab, idx === selectedIndex && style.selectedTab]}
        >
          <Text size={16}>{btn.title}</Text>

          <View style={style.badgeCont}>
            <Text>{btn.badge}</Text>
          </View>
        </Pressable>
      ))}
    </View>
  )
}
const style = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    borderRadius: 20,
    paddingVertical: 6,
    paddingHorizontal: 7,
    alignItems: 'center',
    backgroundColor: Colors.shades['100'],
    alignSelf: 'center',
  },
  tab: {
    flexDirection: 'row',
    borderRadius: 20,
    paddingVertical: 6,
    paddingHorizontal: 20,
    alignItems: 'center',
  },
  selectedTab: {
    backgroundColor: Colors.white,
  },
  badgeCont: {
    marginLeft: 10,
    justifyContent: 'center',
    alignItems: 'center',
    height: 24,
    width: 24,
    backgroundColor: withAlpha(Colors.shades['300'], 0.2),
    borderRadius: 4,
  },
})
