import { ToolTips } from '@components'
import { Properties } from '@models/Farm'
import { useState } from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'
import { Input } from 'react-native-elements'

import { UniversalTag, Tooltip } from '@elements'
import { Divider } from '../../components/elements/Divider'
import { Text, TextH2 } from '../../components/elements/Text'
import { globalStyles } from '../../constants/Styles'

type Props = {
  style?: ViewStyle
  values: Properties
  title?: string
  onUpdate: (props: Properties) => void
}

export function TagInput({ style, values, onUpdate, title = 'Tags' }: Props) {
  const [text, setText] = useState('')

  const clean = (text: string) => text.replace(/[\W_]+/g, '')

  const addTag = (text: string) => {
    if (text.trim().length === 0) return
    const newVal = { ...values }
    newVal[text] = true
    onUpdate(newVal)
    setText('')
  }

  const removeTag = (tag: string) => {
    delete values[tag]
    onUpdate(values)
  }

  return (
    <View style={style}>
      <View style={globalStyles.flexRowCenter}>
        <TextH2>{title}</TextH2>
        <Tooltip id={ToolTips.TAGS} title="Tags" style={{ marginLeft: 10 }} />
      </View>
      <Divider large clear />
      <Text>Enter a tag and press space or enter to save.</Text>
      <Input
        maxLength={20}
        value={'#' + text}
        onChangeText={(text) => (text[text.length - 1] !== ' ' ? setText(clean(text)) : addTag(clean(text)))}
        onBlur={() => addTag(text)}
      />
      <View style={styles.tagContainer}>
        {Object.keys(values).map((tag) => (
          <UniversalTag key={tag} label={`#${tag}`} onPress={() => removeTag(tag)} />
        ))}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  tagContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
})
