import { omit } from '@helpers/typescript'
import { Distribution } from '@models/Distribution'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { marshalSchedule, unmarshalSchedule } from './Schedule'
import { prepareMarshal, prepareUnmarshal } from './encoding'

/** marshalDistribution returns the marshalled distribution data for Firestore. */
export function marshalDistribution(distribution: Partial<Distribution>, isNested = false): DocumentData {
  let data = omit(prepareMarshal(distribution), 'id') as DocumentData

  if (isNested) {
    data = prepareMarshal(distribution) as DocumentData
  }

  if (distribution.schedule) {
    data.schedule = marshalSchedule(distribution.schedule)
  }

  return data
}

/** unmarshalDistribution returns a Distribution from the Firestore data. */
export function unmarshalDistribution(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): Distribution {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)
  const distribution = { ...data, id } as Distribution

  if (data.schedule) {
    distribution.schedule = unmarshalSchedule(data.schedule)
  }

  return distribution
}
