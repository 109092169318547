import { CartServiceType } from '@/constants/types/cartService'
import { dequal } from '@helpers/customDequal'
import { useSelectorRoot } from '../../redux/selectors'

type UseCartInfoOpts = { cartServiceType?: CartServiceType; draftCartId?: string }

/** Selects cart info based on the admin mode */
export const useCartInfo = ({ cartServiceType = 'consumer', draftCartId }: UseCartInfoOpts = {}) =>
  useSelectorRoot(
    (rs) =>
      cartServiceType === 'orderCreator'
        ? rs.adminPersist.cartInfo
        : cartServiceType === 'consumer'
        ? rs.appPersist.cartInfo
        : {
            cartId: draftCartId,
            // It doesn't need the session id because that's only used when calling cart init
            sessionId: undefined,
          },
    dequal,
  )
