import { formatMoney } from '@helpers/display'
import { parseISO } from 'date-fns'
import { useEffect, useState } from 'react'
import { View, Text, Platform } from 'react-native'
import { Circle, Line, G } from 'react-native-svg'
import { useSelector } from 'react-redux'

import NoData from './NoData'
import { DateType } from './util'
import Victory from './victory'
import Colors from '../../../constants/Colors'
import { globalStyles } from '../../../constants/Styles'
import { layoutSelector } from '../../../redux/selectors'

const VictoryChart = Victory.VictoryChart
const VictoryAxis = Victory.VictoryAxis
const VictoryLine = Victory.VictoryLine
const VictoryTooltip = Victory.VictoryTooltip
const VictoryVoronoiContainer = Victory.VictoryVoronoiContainer

function FlyOut(props: any) {
  const { x, y } = props

  return (
    <G>
      <Circle cx={x} cy={y} r="5" stroke={Colors.black} strokeWidth={1.5} fill="white" />
      <Line transform={`translate(${x}, 50)`} x1={0} y1={200} stroke="#000" strokeWidth={0.5} />
    </G>
  )
}
function CustomTooltip(props: any) {
  return (
    <G>
      <VictoryTooltip
        {...props}
        renderInPortal={false}
        style={{ background: Colors.transparent }}
        text=""
        flyoutWidth={150}
        flyoutHeight={50}
        flyoutComponent={<FlyOut />}
        pointerWidth={0}
      />
    </G>
  )
}
interface Props {
  data: {
    y: number
    x: string
    y2: number
    x2: string
  }[]
  dateFormat?: DateType
  title: string
  hasPastData?: boolean | null
  isRevenue?: boolean
}

const roundToTwoDecimals = (num: number) => {
  return Math.round(num * 100) / 100
}

const calculateDiff = (a: number, b: number) => {
  if (b === 0) {
    return 0
  }
  const diff = (a - b) / b
  return roundToTwoDecimals(diff * 100)
}
function LineGraph({ data, title, hasPastData, isRevenue = false, dateFormat = 'MONTH' }: Props) {
  const layout = useSelector(layoutSelector)
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const formatDate = (date: Date) => {
    let d = date
    if (typeof d === 'string') {
      d = parseISO(d)
    }
    switch (dateFormat) {
      case 'DAY':
        return `${monthNames[d.getMonth()]} ${d.getDate()}`
      case 'MONTH':
        return `${monthNames[d.getMonth()]} ${d.getFullYear()}`
    }
    return `${monthNames[d.getMonth()]} ${d.getDate()}`
  }

  const xTickValues: (Date | string)[] = data.map((d) => {
    return new Date(d.x)
  })

  const total = roundToTwoDecimals(
    data.reduce((ac, obj) => {
      return ac + obj.y
    }, 0),
  )
  const prevTotal = roundToTwoDecimals(
    data.reduce((ac, obj) => {
      return ac + (obj.y2 ?? 0)
    }, 0),
  )

  const presentDateRange =
    data.length > 0 ? `${formatDate(parseISO(data[0].x))} - ${formatDate(parseISO(data[data.length - 1].x))}` : ''

  const pastDateRange =
    data.length > 0 ? `${formatDate(parseISO(data[0].x2))} - ${formatDate(parseISO(data[data.length - 1].x2))}` : ''
  const [currentTotal, setCurrentTotal] = useState({
    value: total,
    date: presentDateRange,
  })
  const [pastTotal, setPastTotal] = useState({
    value: prevTotal,
    date: pastDateRange,
  })

  const pastData = data.map((d) => {
    return {
      x: d.x,
      y: d.y2,
    }
  })

  const [growth, setGrowth] = useState(calculateDiff(currentTotal.value, pastTotal.value))
  useEffect(() => {
    setGrowth(calculateDiff(currentTotal.value, pastTotal.value))
  }, [currentTotal, pastTotal])
  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20,
      }}
    >
      <View
        style={{
          width: Platform.OS === 'web' && layout.width >= 896 ? layout.width / 2 : layout.width,
        }}
      >
        <View
          style={{
            position: 'relative',
            top: 50,
            alignItems: 'center',
            marginLeft: '10%',
            flexDirection: 'row',
          }}
        >
          <View>
            <View style={globalStyles.flexRowCenter}>
              <Text
                style={{
                  fontSize: 24,
                  marginRight: 10,
                }}
              >
                {title}
              </Text>

              <Text
                style={{
                  backgroundColor: growth > 0 ? Colors.green : growth < 0 ? Colors.red : Colors.primaryGray,
                  fontSize: 10,
                  borderRadius: 10,
                  padding: 5,
                  color: Colors.white,
                }}
              >
                {growth}%
              </Text>
            </View>
            <Text
              style={{
                fontSize: 24,
              }}
            >
              {isRevenue ? formatMoney(currentTotal.value) : currentTotal.value}
            </Text>

            <Text
              style={{
                fontSize: 12,
              }}
            >
              {currentTotal.date}
            </Text>
          </View>
          {hasPastData && (
            <View
              style={{
                marginLeft: 'auto',
                marginRight: '10%',
              }}
            >
              <Text
                style={{
                  fontSize: 18,
                  color: Colors.shades[200],
                  textAlign: 'right',
                }}
              >
                {isRevenue ? formatMoney(pastTotal.value) : pastTotal.value}
              </Text>

              <Text
                style={{
                  fontSize: 12,
                  color: Colors.shades[200],
                  textAlign: 'right',
                }}
              >
                {pastTotal.date}
              </Text>
            </View>
          )}
        </View>

        {data.length > 0 ? (
          <VictoryChart
            domainPadding={30}
            scale={{ x: 'time' }}
            containerComponent={
              <VictoryVoronoiContainer
                labels={({ datum }) =>
                  `${formatDate(datum.x)} - ${
                    isRevenue ? formatMoney(roundToTwoDecimals(datum.y)) : roundToTwoDecimals(datum.y)
                  }`
                }
                events={{
                  onMouseOut: () => {
                    setCurrentTotal({ value: total, date: presentDateRange })
                    setPastTotal({ value: prevTotal, date: pastDateRange })
                  },
                }}
                onActivated={(d) => {
                  if (d[0].childName === 'chart-line-1') {
                    setCurrentTotal({ date: formatDate(parseISO(d[0].x)), value: d[0].y })
                    setPastTotal({
                      date: formatDate(parseISO(data[d[0].eventKey].x2)) ?? '',
                      value: pastData[d[0].eventKey].y ?? 0,
                    })
                  } else {
                    setPastTotal({ date: formatDate(parseISO(data[d[0].eventKey].x2)), value: d[0].y })
                    setCurrentTotal({
                      date: formatDate(parseISO(pastData[d[0].eventKey].x)) ?? '',
                      value: data[d[0].eventKey].y ?? 0,
                    })
                  }
                }}
                labelComponent={
                  <CustomTooltip
                    orientation="right"
                    style={{ fontSize: 9, padding: '2px' }}
                    flyoutStyle={{ stroke: '#DBDBDB', strokeWidth: 0.5 }}
                    cornerRadius={0}
                    pointerLength={0}
                  />
                }
              />
            }
          >
            <VictoryAxis
              tickValues={xTickValues}
              style={{
                axis: { stroke: Colors.shades[200] },
                axisLabel: { fontSize: 10, paddingLeft: 30 },
                ticks: { stroke: Colors.shades[200], size: 5 },
                tickLabels: {
                  fontSize: ['ios', 'android'].includes(Platform.OS) ? 8 : 10,
                  angle: -45,
                  color: Colors.red,
                  fill: Colors.shades[200],
                },
              }}
              tickFormat={(t: any, i: any) => {
                const date = parseISO(t)
                return i % 2 === 0 ? formatDate(date) : ''
              }}
            />
            {/* <VictoryAxis dependentAxis tickFormat={(x) => x} /> */}
            <VictoryLine data={data} style={{ data: { stroke: Colors.green } }} />
            {hasPastData && (
              <VictoryLine data={pastData} style={{ data: { stroke: Colors.shades[200], opacity: 0.3 } }} />
            )}
          </VictoryChart>
        ) : (
          <NoData />
        )}
      </View>
    </View>
  )
}

export default LineGraph
