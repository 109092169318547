/** Helpers in this file can't be imported to server, because they contain react-native dependencies */
import * as WebBrowser from 'expo-web-browser'
import { Linking, Platform } from 'react-native'

import { Alert } from '@elements'
import { isSmallDevice, isWeb } from '../Layout'

import { Logger } from '@/config/logger'
import { ComponentType } from 'react'

export const isNavUrl = (url: string): boolean => url[0] === '/'

export const formatUrl = (url: string): string => {
  let formattedUrl = url.replace(/\r\n|\r|\n/g, '%0D%0A')

  const isMailOrTel = formattedUrl.match(new RegExp(/^(mailto:|tel:|sms:)/))
  // Don't format mailto or tel links
  if (isMailOrTel) {
    return encodeURI(formattedUrl)
  }
  if (isNavUrl(formattedUrl)) return formattedUrl

  const pattern = new RegExp(`([w]*[.]?)?`, 'i')
  formattedUrl = formattedUrl.replace(pattern, '')

  // Add http:// if missing from the url
  if (!new RegExp(/^https?:\/\//i).test(formattedUrl)) {
    formattedUrl = 'http://' + formattedUrl
  }
  return formattedUrl
}

/** Opens a url in both mobile and web, accepts web-only props
 * @param url is the external url to open
 * @param webOpts are options for window.open() on web, else will use Linking api
 */
export async function openUrl(url: string, webOpts?: { target?: string; features?: string }) {
  let wasOpened = true
  if (isWeb && webOpts) {
    const result = window.open(url, webOpts.target, webOpts.features)
    if (!result) wasOpened = false
    return wasOpened
  }
  const canOpen = await Linking.canOpenURL(formatUrl(url))
  if (canOpen) {
    await Linking.openURL(formatUrl(url))
    return wasOpened
  } else {
    Logger.error('A url passed to openUrl was deemed not openable by the Linking api')
    wasOpened = false
    return wasOpened
  }
}

/** Opens a url as an ephemeral in-app browser that doesn't share cookies with the system browser.
 * - This is useful when we want them to complete a step outside the app, and then return.
 * - Since the browser opens inside the app, there is no app-switching which has a more responsive feel in mobile as compared to opening the system browser, which takes longer.
 * - This also guarantees a clean web cache for whatever url we pass here.
 */
export async function openUrlInApp(url: string) {
  if (isWeb && isSmallDevice()) {
    // Mobile Web (i.e Safari) needs at first the instance of the window and
    // only then we can assign that link to the window ref.
    // This way Safari won't block the Popup Window.
    const windowRef = window.open()
    windowRef?.location.assign(url)
  } else {
    WebBrowser.openBrowserAsync(url)
  }
}

export async function openAppStore() {
  const IOS_URL = 'https://apps.apple.com/us/app/grownby/id1541472515'
  const ANDROID_URL = 'https://play.google.com/store/apps/details?id=coop.farmgenerations.grownby'
  const WEB_URL = 'https://grownby.app'

  const url = Platform.select({ ios: IOS_URL, android: ANDROID_URL, web: WEB_URL }) || WEB_URL
  return openUrl(url)
}

export const emailSupport = async () => {
  openUrl('https://grownby.zohodesk.com/portal/en/newticket').catch(() =>
    Alert('Something went wrong', 'Please email support@grownby.app.'),
  )
}

/** Returns a screen url based on screen navigation params. Works for farmshop, csa detail and product detail screens */
export function shoppingUrl({
  prodId,
  csaId,
  farmSlug,
  goBack,
}: {
  /** if prodId is passed, will create a link for the product detail screen */
  prodId?: string
  /** if a prodId is passed, the csaId will be passed to the product details screen. If no prodId is passed, but a csaId is passed, will create a link for the csa details screen for the given csa id */
  csaId?: string
  farmSlug: string
  goBack?: 'home'
}) {
  let baseUrl = `/farms/${farmSlug}/shop`
  if (prodId) {
    baseUrl += `/product/${prodId}`
    if (csaId) baseUrl += `?csaId=${csaId}`
  } else {
    if (csaId) baseUrl += `/csa/${csaId}`
  }
  if (goBack) {
    if (baseUrl.includes('?')) {
      baseUrl += `&goBack=${goBack}`
    } else {
      baseUrl += `?goBack=${goBack}`
    }
  }
  return baseUrl
}

/** WEB ONLY */
export const loadScript = (url: string, callback: () => void, id: string) => {
  let script: any
  script = document.getElementById(id)
  if (script) {
    // If the script already exists and has been loaded then call the callback immediately
    if (script.isLoaded) callback()
    // If it has not been loaded then add the new callback to the existing one
    const oldFunc = script.onload
    script.onload = () => {
      script.isLoaded = true
      oldFunc?.()
      callback()
    }
  } else {
    // If the script doesn't exists then create it
    script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = url
    script.setAttribute('id', id)
    script.onload = () => {
      script.isLoaded = true
      callback()
    }
    document.getElementsByTagName('head')[0].appendChild(script)
  }
}
/** Gets the display name of a component. For use by HOCs, for making wrapped components retain their original display name for easy debugging in devtools
 * https://reactjs.org/docs/higher-order-components.html#convention-wrap-the-display-name-for-easy-debugging
 */
export function getDisplayName(WrappedComponent: ComponentType<any>) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}
