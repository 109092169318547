import { Text } from '@elements'
import { View } from 'react-native'

import Colors from '@/constants/Colors'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { memo } from 'react'

type Props = {
  leftSideValues?: string[]
  rightSideValues?: (string | JSX.Element)[]
  leftOpts?: { isTitleLeft?: boolean; color?: string }
  rightOpts?: { isTitleRight?: boolean; color?: string }
}

export const ItemRow = memo(function ItemRow({ leftSideValues, rightSideValues, leftOpts, rightOpts }: Props) {
  const styles = useStyles()
  return (
    <View style={styles.container}>
      <View style={styles.rowLeftCont}>
        {leftSideValues?.map((value, idx) => (
          <Text
            key={idx}
            type={leftOpts?.isTitleLeft ? 'bold' : 'regular'}
            size={leftOpts?.isTitleLeft ? 12 : 10}
            color={leftOpts?.color ? leftOpts.color : leftOpts?.isTitleLeft ? Colors.black : Colors.primaryGray}
          >
            {value}
          </Text>
        ))}
      </View>
      <View style={styles.rowRightCont}>
        {rightSideValues?.map((value, idx) => {
          return (
            <View key={idx} style={styles.rightSideRow}>
              {typeof value === 'string' ? (
                <Text
                  numberOfLines={1}
                  type={rightOpts?.isTitleRight ? 'bold' : 'regular'}
                  size={10}
                  color={
                    rightOpts?.color ? rightOpts?.color : rightOpts?.isTitleRight ? Colors.black : Colors.primaryGray
                  }
                >
                  {value}
                </Text>
              ) : (
                value
              )}
            </View>
          )
        })}
      </View>
    </View>
  )
})

const useStyles = () =>
  useSizeFnStyles(({ isExtraSmallDevice }) => ({
    container: {
      flexDirection: 'row',
      marginVertical: 4,
      marginHorizontal: 10,
      alignItems: 'center',
    },
    rowLeftCont: {
      flex: isExtraSmallDevice ? 1 : 3,
      marginVertical: 2,
      flexDirection: 'row',
      alignItems: 'center',
    },
    rowRightCont: {
      flex: 2,
      marginVertical: isExtraSmallDevice ? 1 : 2,
      flexDirection: 'row',
      alignItems: 'center',
    },
    rightSideRow: {
      flex: 1,
      alignItems: 'flex-end',
      marginRight: isExtraSmallDevice ? 5 : 0,
    },
  }))
