import { omit } from '@helpers/typescript'
import { CSA } from '@models/CSA'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { prepareMarshal, prepareUnmarshal } from './encoding'

/** marshalCSA returns the Firestore data for the supplied CSA. */
export function marshalCSA(csa: Partial<CSA>, isNested = false): DocumentData {
  let data = omit(prepareMarshal(csa), 'id') as DocumentData

  if (isNested) {
    data = { ...csa } as DocumentData
  }
  return data
}

/** unmarshalCSA returns a CSA created from the Firestore data structure. */
export function unmarshalCSA(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): CSA {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)
  return { ...data, id } as CSA
}
