/**
 * This can be used by any shared module, and can be imported into any file of frontend and backend.
 * - Any code here must not fail in any platform
 * - tsconfig requirement: lib: ["DOM"]. Else, will type error on `typeof window`.
 */

//Borrowed from https://github.com/flexdinesh/browser-or-node/blob/master/src/index.js

export const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined'

export const isNode =
  typeof process === 'object' &&
  typeof process.env === 'object' &&
  typeof process.versions === 'object' &&
  typeof process.versions.node === 'string'

//https://stackoverflow.com/questions/39468022/how-do-i-know-if-my-code-is-running-as-react-native
//https://github.com/facebook/react-native/issues/1331
export const isReactNative = typeof navigator === 'object' && navigator.product === 'ReactNative'

/** Dependency-less helper to tell if code is running in frontend (web or mobile) */
export const isClient = isBrowser || isReactNative

/**
 * process.env.NODE_ENV is the only variable that's always available in react-native. But when jest tests are running, jest assigns NODE_ENV === 'test', and assigns a worked id to tests https://jestjs.io/docs/environment-variables#jest_worker_id
 * - Checking for the JEST_WORKER_ID is necessary in addition to the NODE_ENV because when running some firebase cli commands, the NODE_ENV might also be 'test'.
 */
export const isJest = isNode && process.env.NODE_ENV === 'test' && process.env.JEST_WORKER_ID !== undefined

const platform = {
  isBrowser,
  isNode,
  isReactNative,
  isClient,
  isJest,
}
// console.log('platform:', platform)

export default platform
