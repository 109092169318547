import { Button, Spinner, Touchable } from '@elements'
import { Feather } from '@expo/vector-icons'
import { memo } from 'react'

import { AddCartBtnStatus } from './AddCartBtn-helper'
import { StepperHome } from './StepperHome'
import { AddCartBtnProps, useAddCartBtnData } from './useAddCartBtnData'

import Colors from '@/constants/Colors'
import { SHADOW_2, SHADOW_5 } from '@/constants/Styles'
import { useFnStyles } from '@/hooks/useFnStyles'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'

/**
 * Button that handles the entire flow for adding a product to cart, used in the Home UI.
 * @returns adds to cart via the `useAddToCart` hook, with all the correct options, after all the modals selections.
 */
export const AddCartBtnIcon = memo(function AddCartBtnIcon(props: AddCartBtnProps) {
  const { loading, cartItem, disableAdd, id, updateQuantity, style, outline, onPressMemo, addCartStatus } =
    useAddCartBtnData(props)

  const styles = useStyles(disableAdd, addCartStatus)

  // Stepper: This should only be used for products with a single buying option
  if (cartItem)
    return (
      <StepperHome
        cartItem={cartItem}
        disableAdd={disableAdd}
        key={id}
        updateQuantity={updateQuantity}
        style={style}
        loading={loading}
      />
    )

  if (addCartStatus !== AddCartBtnStatus.available) {
    return (
      <Button
        small
        outline={outline}
        onPress={onPressMemo}
        title={addCartStatus}
        textStyle={addCartStatus !== AddCartBtnStatus.inCart && styles.unavailableText}
        disabled={disableAdd}
        loading={loading}
        size={10}
        style={[styles.textButton, style]}
        testID={id}
        key={id}
      />
    )
  }
  return (
    <Touchable
      activeOpacity={0.95}
      onPress={onPressMemo}
      style={[styles.addBtn, style]}
      disabled={
        false /**Intentionally not using the "disableAdd" calculation here because if the product is available but not available for addToCart, means it's an unavailable addon, so we want the addToCart flow to show an alert with a message about why the addon is not available */
      }
    >
      {loading ? (
        <Spinner size="small" />
      ) : (
        <Feather name="plus" size={20} color={disableAdd ? Colors.shades[200] : Colors.green} />
      )}
    </Touchable>
  )
}, propsAreDeepEqual)

const useStyles = (disabled: boolean, addCartStatus: AddCartBtnStatus) =>
  useFnStyles(
    (disabled, addCartStatus) => ({
      addBtn: {
        padding: 2,
        alignItems: 'center',
        justifyContent: 'center',
        width: 35,
        backgroundColor: Colors.white,
        height: 35,
        borderRadius: 100,
        opacity: disabled ? 0.75 : 1,
        ...SHADOW_5,
      },
      textButton: {
        backgroundColor: Colors.white,
        paddingHorizontal: 10,
        paddingVertical: 0,
        marginRight: 0,
        borderWidth: 0,
        marginBottom: 0,
        minHeight: 35,
        opacity: addCartStatus === AddCartBtnStatus.inCart ? 1 : 0.9,
        borderRadius: 5,
        ...SHADOW_2,
      },
      unavailableText: {
        color: Colors.shades['200'],
      },
    }),
    disabled,
    addCartStatus,
  )
