import { SignInSummary } from '@models/SignInSummary'

import { DocumentData, DocumentSnapshot } from 'firebase/firestore'
import { prepareMarshal, prepareUnmarshal } from './encoding'
import { marshalDate, unmarshalDate } from './Time'

/** marshalSignInSummary transforms the SignInSummary structure into Firestore data.*/
export function marshalSignInSummary(signInSummary: Partial<SignInSummary>): DocumentData {
  const data = prepareMarshal(signInSummary) as DocumentData

  if (signInSummary.date) {
    data.date = marshalDate(signInSummary.date)
  }
  if (signInSummary.endDate) {
    data.endDate = marshalDate(signInSummary.endDate)
  }
  return data
}

/** unmarshalPickup transforms the Firestore data to an SignInSummary object.*/
export function unmarshalSignInSummary(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): SignInSummary {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)

  const signInSummary = { ...data, id } as SignInSummary
  if (data.date) {
    signInSummary.date = unmarshalDate(data.date)
  }

  if (data.endDate) {
    signInSummary.endDate = unmarshalDate(data.endDate)
  }
  return signInSummary
}
