import { Location } from '@models/Location'
import { SignInSummary } from '@models/SignInSummary'
import { limit, orderBy, where } from 'firebase/firestore'
import { signinSummariesCollection } from './framework/ClientCollections'

import { PartialExcept } from '@helpers/typescript'
import { uuidString } from '@helpers/uuid'
import { UpdateSignInItemStatusesRequest } from '@shared/types/v2/signInSummary'
import { DateTime } from 'luxon'
import { marshalSignInSummary, unmarshalSignInSummary } from './encoding/SignInSummary'
import { callEndpoint } from './v2'

/** Tries to find a `SignInSummary` doc with a specific `date` and `location`*/
export async function loadSummaryByDateAndLocation(
  farmId: string,
  location: Pick<Location, 'id'>,
  date: DateTime,
): Promise<SignInSummary | undefined> {
  const summaries = await signinSummariesCollection.fetchAll(
    where('location.id', '==', location.id),
    where('date.utc', '==', date.toUTC().toISO()),
    where('farm.id', '==', farmId),
    limit(1),
  )
  if (summaries.length === 1) {
    return summaries[0]
  }
  return undefined
}

/** Loads `SignInSummaries` for a specified farm, ordered by last modified date */
export async function loadSummariesByFarm(farmId: string, limitNo = 5) {
  return signinSummariesCollection.fetchAll(
    where('farm.id', '==', farmId),
    limit(limitNo),
    // Order by the last edited
    orderBy('meta.updatedAt.utc', 'desc'),
  )
}

/** Updates `SignInSummary` document data */
export async function updateSignInSummary(item: PartialExcept<SignInSummary, 'id'>) {
  return await signinSummariesCollection.update(item)
}

/** Returns the share token for the sign in sheet. If sign in sheet does not already have a share token, creates one. */
export async function getSignInSummaryShareToken(signInSummary: SignInSummary) {
  const token = signInSummary.share?.token ?? uuidString()

  if (!signInSummary.share) {
    await updateSignInSummary({ id: signInSummary.id, share: { token } })
  }

  return token
}

/**
 * Creates a new SignInSummary to store all data associated with the eSign In session and links all pickups to the summary for future reference
 */
export async function addSignInSummary(
  item: Pick<SignInSummary, 'location' | 'farm' | 'items' | 'date'>,
): Promise<SignInSummary> {
  const resSignInSummary = await callEndpoint('v2.SignInSummary.createSignInSummaryService', marshalSignInSummary(item))
  return unmarshalSignInSummary(resSignInSummary.id, resSignInSummary)
}

/** Server function that updates multiple items statuses at once */
export async function onUpdateItemStatuses(props: UpdateSignInItemStatusesRequest) {
  await callEndpoint('v2.SignInSummary.updateSignInItemStatusesService', props)
}
