import { FirebaseApp } from 'firebase/app'
import {
  AppCheck as AppCheckFb,
  getToken,
  initializeAppCheck as initializeAppCheckFB,
  ReCaptchaV3Provider,
} from 'firebase/app-check'

import env from './Environment'

/** This is the web version of the app check wrapper that provides a consistent interface for using AppCheck between
 * web and mobile. In the AppCheck.ts we have the mobile version that has the same public methods as this class.*/
export class AppCheck {
  // We store the reference to appCheck after it has been initialized here so that we can use it in the public methods we have below.
  private readonly appCheck: AppCheckFb
  constructor(app: FirebaseApp) {
    // This will set the debug token for using AppCheck during development on localhost, to test AppCheck failing
    // this can be removed. See: https://firebase.google.com/docs/app-check/web/debug-provider#localhost
    if (__DEV__) {
      //@ts-expect-error: FIREBASE_APPCHECK_DEBUG_TOKEN does not exist on the window
      self.FIREBASE_APPCHECK_DEBUG_TOKEN = env.APP_CHECK_DEBUG_TOKEN
    }
    this.appCheck = initializeAppCheckFB(app, {
      provider: new ReCaptchaV3Provider(env.RECAPTCHA_SITE_KEY),
      isTokenAutoRefreshEnabled: true,
    })
  }
  /** Will return the App Check token for the device which can be passed to any backend call */
  async getToken() {
    const res = await getToken(this.appCheck)
    return res.token
  }
}
