import { omit } from '@helpers/typescript'
import { Review } from '@models/Review'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { prepareMarshal, prepareUnmarshal } from './encoding'

/** marshalUser returns the Firestore document data for the supplied user. */
export function marshalReview(review: Partial<Review>): DocumentData {
  const data = omit(prepareMarshal(review), 'id') as DocumentData

  return data
}

/** unmarshalUser transforms the data into the User structure. */
export function unmarshalReview(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): Review {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)
  const review = { ...data, id } as Review

  return review
}
