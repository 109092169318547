import { PartialExcept } from '@helpers/typescript'
import { Review } from '@models/Review'
import { where } from 'firebase/firestore'

import { reviewsCollection } from './framework/ClientCollections'

/** Will load all reviews for the farm, and return a review written by the user separately */
export const loadReviewsByFarmAndUser = async (
  farmId: string,
  userId?: string,
): Promise<{ myReview?: Review; reviews: Review[] }> => {
  const reviewData = await reviewsCollection.fetchAll(where('idFarm', '==', farmId))

  if (!userId) return { reviews: reviewData, myReview: undefined }

  const userReview = reviewData.find((r) => r.user.id === userId)

  const othersReviews = reviewData.filter((r) => r.user.id !== userId)

  if (userReview) othersReviews.unshift(userReview)

  return { myReview: userReview, reviews: othersReviews }
}

export const addReview = async (data: Omit<Review, 'meta'>): Promise<Review> => {
  return reviewsCollection.create(data)
}

export const updateReview = async (data: PartialExcept<Review, 'id'>) => {
  return reviewsCollection.update(data)
}

export const deleteReview = async (id: string) => {
  return reviewsCollection.delete(id)
}
