//Allows preserving the CurrentRefinement state while unmounting the UI controls of a ConnectedRefinementList.
// For example, the AllFiltersModal.

import * as React from 'react'
import { RefinementListExposed, RefinementListProvided } from 'react-instantsearch-core'
import { connectRefinementList } from 'react-instantsearch-native'

/** An object where each key is a field of an algolia document configured to be used as a filterable attribute. The values are of type @see {RefinementListProvided} which is an object of data and tools provided by algolia to help in filtering tasks for the attribute key */
export type AttributeRefinements = { [attribute: string]: RefinementListProvided }

/** Props object where 'attributeRefinements' is of type @see {AttributeRefinements} */
export type AttributeRefinementsProp = { attributeRefinements: AttributeRefinements }

/** Receives an object of mixed properties and returns only the ones belonging to RefinementListProvided type */
const getRefinementListProvided = (rest: RefinementListExposed & RefinementListProvided): RefinementListProvided => {
  const { canRefine, createURL, currentRefinement, isFromSearch, items, refine, searchForItems } =
    rest as RefinementListProvided
  return { canRefine, createURL, currentRefinement, isFromSearch, items, refine, searchForItems }
}

type ConnectedRefinementListHeadlessProps = {
  /** The UI to render. It is expected to receive RefinementListProvided props */
  Component: React.ComponentType<any>
  /** An optional, additional set of RefinementListProvided props, from other attributes. The final `attributeRefinements` prop received by the component will include all extra sets in the AttributeRefinementProp, where the key is the attribute, and the value is each set of props. */
  extraAttrRefinements?: AttributeRefinements
  /** Anything of the props type that does not include the attribute refinements */
  other?: any
} & RefinementListExposed &
  RefinementListProvided

/** A component connected to algolia, whose props can configure the algolia connector, and can render a component received through props */
export const ConnectedRefinementListHeadless = connectRefinementList(function ConnectedRefinementListHeadlessComp({
  Component,
  extraAttrRefinements,
  other,
  ...rest
}: ConnectedRefinementListHeadlessProps) {
  const refinementListprovided = getRefinementListProvided(rest)

  /** These attribute refinements will be passed to the component. */
  let attributeRefinementsChild: AttributeRefinements = { [rest.attribute]: refinementListprovided }

  /** If there are extra attribute refinements specified, it means the product will receive an attributeRefinements object for several attributes keys, so we can just merge them.
   * For example this allows the same component to receive refinement utils for 'categories' as well as 'farm.tags'.
   */
  if (extraAttrRefinements) attributeRefinementsChild = { ...extraAttrRefinements, ...attributeRefinementsChild }

  return <Component attributeRefinements={attributeRefinementsChild} {...other} />
})

/**
 * HOC that wraps a component in a connector and sets the connector options.
 *
 * @param Component a component whose props extend AttributeRefinementProp, to be rendered inside a wrapper that connects it to algolia.
 * @param refinementOptions are the options for the refinement list connector.
 */
export const withListRefinement = <T extends AttributeRefinementsProp>(
  Component: React.ComponentType<T>,
  refinementOptions: RefinementListExposed,
) => {
  return function ConnectedRefinementListHeadlessAdapter({ attributeRefinements, ...other }: T) {
    return (
      <ConnectedRefinementListHeadless
        Component={Component}
        {...refinementOptions}
        extraAttrRefinements={attributeRefinements}
        other={other}
      />
    )
  }
}

/** Provides a `attributeRefinements` prop to the component, which holds the `RefinementListProvided` props for several attributes, grouped by attribute name as key */
export const withAttributeRefinements = <T extends AttributeRefinementsProp>(Component: React.ComponentType<T>) => {
  const WithTagsRefinement = withListRefinement(Component, { attribute: 'farm.tags', limit: itemsLimit })
  const WithCategoryRefinement = withListRefinement(WithTagsRefinement, { attribute: 'category', limit: itemsLimit })
  return withListRefinement(WithCategoryRefinement, {
    attribute: 'farm.practices',
    limit: itemsLimit,
  }) as React.ComponentType<Omit<T, 'attributeRefinements'>>
}

/** the minimum number of displayed items */
export const itemsLimit = 50
