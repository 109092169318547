import { isSetApp } from '@api/db'
import { SafeAreaProviderCompat } from '@react-navigation/elements'
import React, { useEffect, useState } from 'react'
import { StatusBar } from 'react-native'

import { toJsx } from '@test/helpersJsx'
import { Spinner } from './components/elements/Spinner'
import env from './config/Environment'
import { initializeDb } from './config/Firebase'
import Colors from './constants/Colors'
import { useNotifications } from './constants/appInitialization/initializationHooks'
import useCachedResources from './constants/appInitialization/useCachedResources'
import { Navigation } from './navigation'
import ErrorBoundary from './navigation/ErrorBoundary/ErrorBoundary'
import { ReduxProvider } from './redux/store/ReduxProvider'

function MainApp() {
  const [loadingDb, setLoadingDb] = useState(true)

  useEffect(() => {
    initializeDb()
    setLoadingDb(false)
  }, [])

  useNotifications()

  const loadingResources = useCachedResources()

  if (loadingResources || loadingDb || !isSetApp()) return <Spinner />

  return (
    <ReduxProvider>
      <ErrorBoundary isRoot>
        <SafeAreaProviderCompat>
          {/* 
          - A status bar is required here in order for insets to work in the DevClient on Android: See:https://github.com/farmgenerations/grownby/pull/6565.
          - The default status bar should have dark content. This can be overwritten by using <LightStatusBar /> component */}
          <StatusBar backgroundColor={Colors.transparent} barStyle="dark-content" translucent />
          <Navigation />
        </SafeAreaProviderCompat>
      </ErrorBoundary>
    </ReduxProvider>
  )
}

/** This component will determine whether to show the main app, or the component navigator app. */
export function AppSwitcher() {
  const [compNavigator, setCompNavigator] = useState<JSX.Element>(<></>)
  useEffect(() => {
    if (__DEV__ && env.IS_COMP === true) {
      /** - Requirement: The component navigator code must not be part of the main app bundle for production. To achieve that, it must import the module inside the fx because that prevents that code from being bundled (Search for "code-splitting" to learn more about this) */
      import('./components/ComponentNavigator/App').then((module) => {
        setCompNavigator(toJsx(module.CompNavigatorApp))
      })
    }
  }, [])

  if (env.IS_COMP !== true) return <MainApp />

  return compNavigator
}
