import { CancelPickupRequest, RescheduleRequest } from '@models/OrderChanges'
import { DocumentData } from 'firebase/firestore'

import { marshalDistribution, unmarshalDistribution } from './Distribution'
import { marshalPickupItem, unmarshalPickupItem } from './Pickup'
import { marshalDate, unmarshalDate } from './Time'

/** marshalRescheduleRequest transforms an RescheduleRequest structure to Firestore data. */
export function marshalRescheduleRequest(request: Partial<RescheduleRequest>): DocumentData {
  const rescheduleData = { ...request } as DocumentData

  request.data?.map((item, index) => {
    if (Array.isArray(item.items)) {
      rescheduleData.data[index].items = item.items.map((item) => ({
        oldItm: marshalPickupItem(item.oldItm),
        newItm: {
          date: marshalDate(item.newItm.date),
          dist: marshalDistribution(item.newItm.dist, true),
        },
      }))
    }
  })

  return rescheduleData
}

/** unmarshalRescheduleRequest transforms the Firestore data to an RescheduleRequest object. */
export function unmarshalRescheduleRequest(rescheduleData: DocumentData): RescheduleRequest {
  const request = { ...rescheduleData } as RescheduleRequest

  // DocumentData has no specific type, so set any for item
  rescheduleData.data?.map((item: any, index: number) => {
    if (Array.isArray(item.items)) {
      request.data[index].items = item.items.map((item: any) => ({
        oldItm: unmarshalPickupItem(item.oldItm),
        newItm: {
          date: unmarshalDate(item.newItm.date),
          dist: unmarshalDistribution(item.newItm.dist.id, item.newItm.dist),
        },
      }))
    }
  })

  return request
}

/**  marshalCancelPickupRequest transforms an CancelPickupRequest structure to Firestore data. */
export function marshalCancelPickupRequest(request: Partial<CancelPickupRequest>): DocumentData {
  const data = { ...request } as DocumentData

  if (Array.isArray(request.items)) {
    data.items = request.items.map((item) => marshalPickupItem(item))
  }

  return data
}

/** unmarshalCancelPickupRequest transforms the Firestore data to an CancelPickupRequest object. */
export function unmarshalCancelPickupRequest(data: DocumentData): CancelPickupRequest {
  const request = { ...data } as CancelPickupRequest

  if (Array.isArray(data.items)) {
    request.items = data.items.map((item) => unmarshalPickupItem(item))
  }

  return request
}
