import * as Device from 'expo-device'
import * as React from 'react'

import { DependencyList, useEffect } from 'react'
import { trackHit, trackProductHit } from '../api/Analytics'
import { AnalyticsDeviceType, HitScope } from '../models/Analytics'

/** useHitTrack sets up an effect that sends a tracking notification to the server. */
export function useHitTrack(farmId: string, scope: HitScope) {
  useDeviceEffect((deviceType) => trackHit(farmId, scope, deviceType), [farmId, scope])
}

/** useProductHitTrack is like useHitTrack but includes a product reference. */
export function useProductHitTrack(farmId: string, productId: string) {
  useDeviceEffect((deviceType) => trackProductHit(farmId, productId, deviceType), [farmId, productId])
}

/** useDeviceEffect provides a wrapper around useEffect that passes the current device type. */
function useDeviceEffect(effect: (deviceType: AnalyticsDeviceType) => void, deps?: DependencyList) {
  useEffect(() => {
    getSimpleDeviceTypeAsync((type) => effect(type))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

/** getDeviceTypeAsync wraps Device.getDeviceTypeAsync to convert the result to an internal DeviceType value. */
export function getSimpleDeviceTypeAsync(callback: (deviceType: AnalyticsDeviceType) => void) {
  Device.getDeviceTypeAsync().then((deviceType) => {
    /** * This device type is only an approximation and shouldn't be used for non-analytics functionality.
     * - For example, if a user accesses the app on mobile web, this would consider it "mobile", while in reality it's running web. So the inaccuracy is this assumes only Desktop devices are "web" */
    const type = deviceType === Device.DeviceType.DESKTOP ? 'web' : 'mobile'
    callback(type)
  })
}

/** Allows you to run an effect with a callback that receives the device type direct from the expo api without coercing the value into a simplified 'web' | 'mobile' binary.
 * - TODO: This should probably be a global constant.
 */
export function useDeviceTypeFx(effect: (deviceType: Device.DeviceType) => void, deps: DependencyList = []) {
  const [deviceType, setDeviceType] = React.useState(Device.DeviceType.UNKNOWN)

  useEffect(() => {
    Device.getDeviceTypeAsync().then((type) => setDeviceType(type))
  }, [])

  useEffect(() => {
    if (deviceType === Device.DeviceType.UNKNOWN) return
    effect(deviceType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps.concat(deviceType))
}
