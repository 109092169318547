import { addDistribution, canDistributionBeDeleted } from '@api/Distributions'
import { snapshotLocationsByFarm } from '@api/Locations'
import { distrosCollection } from '@api/framework/ClientCollections'
import { FormBuilder, MessageWithIcon, ToolTips } from '@components'
import {
  Alert,
  CheckBox,
  DateTimePickerForm,
  ErrorText,
  FormColorPicker,
  FormPickerInput,
  Icon,
  LoadingView,
  Text,
  TextH2,
  TextH3,
  Toast,
  Tooltip,
} from '@elements'
import { getDayofWeekName } from '@helpers/display'
import { retry } from '@helpers/helpers'
import { getDayOfWeek, getWeekOfMonth, isValidDateRange } from '@helpers/time'
import { pick } from '@helpers/typescript'
import { Distribution, getDistLocationFields } from '@models/Distribution'
import { Farm } from '@models/Farm'
import { Location, LocationTypes, isNonPickup, isShipping } from '@models/Location'
import {
  Exception,
  Frequency,
  SeasonalSchedule,
  YearRoundSchedule,
  isInstanceException,
  isSeasonalSchedule,
  isSkipException,
  isValidException,
  isWiderFreq,
  isPatternException,
} from '@models/Schedule'
import { dateTimeInZone } from '@models/Timezone'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Formik, FormikProps } from 'formik'
import { DateTime } from 'luxon'
import { useCallback, useState } from 'react'
import { View } from 'react-native'
import { Input } from 'react-native-elements'
import { useDispatch, useSelector } from 'react-redux'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'
import * as Yup from 'yup'

import { globalStyles } from '../../../constants/Styles'
import DistributionCalendar from './DistributionCalendar'
import { EditHeader } from './components/EditHeader'
import { RadioButton } from './components/RadioButton'
import WeekdaySelector from './components/WeekdaySelector'
import { validateDistributionEdit } from './helpers/editValidation'
import { ReturnStates } from './helpers/types'

import { AdminView } from '@/admin/components/AdminView'
import InputLabel from '@/admin/components/InputLabel'
import { AdminDrawerParamList, DistributionSchedulesParamList } from '@/admin/navigation/types'
import { Logger } from '@/config/logger'
import Colors, { HexColor, colorBank } from '@/constants/Colors'
import { useCancelableFocusFx } from '@/hooks/useCancelablePromise'
import { useLayout } from '@/hooks/useLayout'
import { withAdminAuth } from '@/hooks/withAdminAuth'
import { setAdminLocations } from '@/redux/actions/adminPersist'
import { setAdminNav } from '@/redux/actions/adminState'
import { setNavProps } from '@/redux/actions/appState'
import { adminFarmIdSelector, adminFarmSelector, adminLocsSelector, adminParamsSelector } from '@/redux/selectors'
import { AccessRight, Permission } from '@helpers/Permission'
import { getScheduleColorFromId } from '@helpers/distributions'
import { getDistributionPickups } from '@helpers/order'
import { formatCatalog } from '@helpers/wholesale'
import { DefaultCatalog } from '@models/Product'
import { useSnapshot } from '../../../hooks/useApiFx'
import { invalidPickupSkippedPrompt } from './helpers/validationPrompts'

type FormType = Pick<Distribution, 'name' | 'orderCutoffWindow' | 'closed'> & {
  notes?: string
  locationId: string
  scheduleType: 'Seasonal' | 'Year Round'
  frequency: Frequency
  /** This start date form value will be used for both seasonal startDate and yearRound pickupStart */
  startDate: DateTime
  endDate?: DateTime
  startTime: DateTime
  endTime: DateTime
  week: string
  color: HexColor
  exceptions: Exception[]
  defaultCatalog: DefaultCatalog
}

const frequencyOptions = [
  { label: 'Daily', value: Frequency.DAILY },
  {
    label: 'Weekly',
    value: Frequency.WEEKLY,
  },
  {
    label: 'Biweekly',
    value: Frequency.BIWEEKLY,
  },
  {
    label: 'Monthly',
    value: Frequency.MONTHLY,
  },
]

//Combine distribution and schedule validation schemas
const validationSchema: Yup.ObjectSchema<Omit<FormType, 'week'>> = Yup.object().shape({
  locationId: Yup.string().label('Location').required('Location is required'),
  name: Yup.string().trim().label('Schedule name').required(),
  defaultCatalog: Yup.string<DefaultCatalog>().required().oneOf(Object.values(DefaultCatalog)),
  orderCutoffWindow: Yup.number()
    .label('Order cutoff window')
    .min(0, 'You cannot have a negative cutoff window')
    .test(
      'Is whole?',
      'You can only specify whole numbers as cutoff windows',
      (value) => typeof value === 'number' && Math.round(value) === value,
    )
    .required(),
  notes: Yup.string().label('Notes').optional(),
  scheduleType: Yup.string<FormType['scheduleType']>()
    .label('Schedule Type')
    .required()
    .oneOf(['Seasonal', 'Year Round']),
  startDate: Yup.mixed<DateTime>()
    .label('Start Date')
    .required()
    .when('scheduleType', {
      is: 'Seasonal',
      then: (schema: Yup.MixedSchema<DateTime>) =>
        schema.test('isBefore', 'Start date must be before end date', function (date) {
          return isValidDateRange({ startDate: date, endDate: this.parent.endDate as DateTime })
        }),
    }),
  endDate: Yup.mixed<DateTime>()
    .label('End Date')
    .when('scheduleType', {
      is: (scheduleType: FormType['scheduleType']) => scheduleType === 'Seasonal',
      then: (schema) =>
        schema.required().test('isAfter', 'End date must be after the start date', function (date) {
          return isValidDateRange({ startDate: this.parent.startDate as DateTime, endDate: date })
        }),
      otherwise: (schema) => schema.optional(),
    }),
  startTime: Yup.mixed<DateTime>()
    .label('Start Time')
    .required()
    .test('isBefore', 'Start time must be before end time', function (date) {
      return (date as FormType['startTime']) < (this.parent.endTime as FormType['endTime'])
    }),
  endTime: Yup.mixed<DateTime>()
    .required()
    .label('End Time')
    .test('isAfter', 'End time must be after start time', function (date) {
      return (date as FormType['endTime']) > (this.parent.startTime as FormType['startTime'])
    }),
  frequency: Yup.mixed<Frequency>()
    .label('Frequency')
    .required()
    .oneOf([Frequency.MONTHLY, Frequency.BIWEEKLY, Frequency.WEEKLY, Frequency.DAILY]),
  closed: Yup.boolean(),
  color: Yup.string<FormType['color']>().required().oneOf(colorBank),
  exceptions: Yup.array<Exception>()
    .required()
    .test('isValid', 'Invalid exception', function (exceptions) {
      return exceptions.every((exception) => isValidException(exception))
    }),
})

/** Creates an INCOMPLETE distribution from the form values, for use by the calendar in this screen. Should not be used for onSubmit */
function createDummyDistro(values: FormType, farm: Farm): Distribution | undefined {
  // Make sure we have the required values to build the distro
  if (!values.startDate || !values.frequency) return
  if (values.scheduleType === 'Seasonal' && !values.endDate) return

  let tempDist: Distribution
  if (values.scheduleType === 'Seasonal') {
    const seasonalDefault: Distribution<Location, SeasonalSchedule> = {
      schedule: {
        dayOfWeek: getDayOfWeek(values.startDate),
        frequency: values.frequency,
        hours: {
          startTime: values.startTime.toFormat('HH:mm'),
          endTime: values.endTime.toFormat('HH:mm'),
        },
        season: {
          startDate: values.startDate,
          endDate: values.endDate!,
        },
      },
      id: '',
      name: values.name,
      farm,
      location: {
        id: values.locationId,
        name: '',
        type: LocationTypes.FARM,
        timezone: farm.timezone,
        address: farm.address,
      },
      notes: '',
      orderCutoffWindow: !isNaN(Number(values.orderCutoffWindow)) ? Number(values.orderCutoffWindow) : 1,
      priceGroup: { type: 'default-catalog', catalog: values.defaultCatalog },
    }
    tempDist = seasonalDefault
  } else {
    const yearlyDefault: Distribution<Location, YearRoundSchedule> = {
      schedule: {
        dayOfWeek: getDayOfWeek(values.startDate),
        frequency: values.frequency,
        hours: {
          startTime: values.startTime.toFormat('HH:mm'),
          endTime: values.endTime.toFormat('HH:mm'),
        },
        pickupStart: values.startDate,
      },
      id: '',
      name: values.name,
      farm,
      location: {
        id: values.locationId,
        name: '',
        type: LocationTypes.FARM,
        timezone: farm.timezone,
        address: farm.address,
      },
      notes: values.notes ?? '',
      orderCutoffWindow: !isNaN(Number(values.orderCutoffWindow)) ? Number(values.orderCutoffWindow) : 1,
      priceGroup: { type: 'default-catalog', catalog: values.defaultCatalog },
    }
    tempDist = yearlyDefault
  }
  if (values.frequency !== Frequency.WEEKLY) {
    tempDist.schedule.week = getWeekOfMonth(values.startDate) - 1
  }

  return tempDist
}

/** Initial form values to fill into formik context */
const getInitialFormValues = (farm: Farm, schedule?: Distribution): FormType => ({
  name: schedule?.name || '',
  defaultCatalog:
    schedule?.priceGroup?.type === 'default-catalog' ? schedule.priceGroup.catalog : DefaultCatalog.Retail,
  locationId: schedule?.location.id || '',
  notes: schedule?.notes || '',
  orderCutoffWindow: schedule?.orderCutoffWindow ?? 1, //it MUST use `??`, not `||`, otherwise it will overwrite any 0 with 1. Zero should be valid
  scheduleType: schedule ? (isSeasonalSchedule(schedule.schedule) ? 'Seasonal' : 'Year Round') : 'Seasonal',
  frequency: schedule?.schedule.frequency || Frequency.WEEKLY,
  startDate: schedule
    ? isSeasonalSchedule(schedule.schedule)
      ? schedule.schedule.season.startDate
      : schedule.schedule.pickupStart
    : dateTimeInZone(farm.timezone).startOf('day'),
  endDate: schedule
    ? isSeasonalSchedule(schedule.schedule)
      ? schedule.schedule.season.endDate
      : undefined
    : dateTimeInZone(farm.timezone).plus({ month: 12 }).startOf('day'),
  startTime: schedule
    ? DateTime.fromFormat(schedule.schedule.hours.startTime, 'HH:mm')
    : dateTimeInZone(farm.timezone).startOf('day').plus({ hours: 9 }),
  endTime: schedule
    ? DateTime.fromFormat(schedule.schedule.hours.endTime, 'HH:mm')
    : dateTimeInZone(farm.timezone).startOf('day').plus({ hours: 17 }),
  week: schedule ? schedule.schedule.week?.toString() || '' : '',
  closed: schedule?.closed,
  color: schedule?.color || getScheduleColorFromId(schedule?.id || '') || colorBank[0],
  exceptions: schedule?.schedule.exceptions || [],
})

const addException = (
  setFieldValue: (field: string, newValue: Exception[]) => void,
  currentExceptions: Exception[],
  e: Exception,
) => {
  setFieldValue('exceptions', [...currentExceptions, e])
}

const removeException = (
  setFieldValue: (field: string, newValue: Exception[]) => void,
  currentExceptions: Exception[],
  e: Exception,
) => {
  isInstanceException(e)
    ? setFieldValue(
        'exceptions',
        currentExceptions.filter((currEx) => e.sourceDate !== currEx.sourceDate),
      )
    : setFieldValue(
        'exceptions',
        currentExceptions.filter((currEx) => e.dayOfWeek !== currEx.dayOfWeek),
      )
}

function DistributionDetailScreenComp() {
  const dispatch = useDispatch()
  const navigation = useNavigation<StackNavigationProp<DistributionSchedulesParamList, 'AddDistribution'>>()
  const { params } = useRoute<RouteProp<DistributionSchedulesParamList, 'AddDistribution' | 'EditDistribution'>>()
  const { distribution } = useSelector(adminParamsSelector)
  const farm = useSelector(adminFarmSelector)
  const farmId = useSelector(adminFarmIdSelector)
  const locations = useSelector(adminLocsSelector)
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState<string>()
  const [showEndDatePicker, setShowEndDatePicker] = useState(true) //whether to show the end date selector
  const [canDelete, setCanDelete] = useState(false)
  const { isSmallDevice, width } = useLayout()
  const [closed, setClosed] = useState<Distribution['closed']>(false)

  const styles = useResponsiveStyles()

  const isEdit = !!params?.id

  /** Syncs the farm distros into admin redux */
  const { loading } = useSnapshot(snapshotLocationsByFarm, [farmId!], !!farmId, {
    onStateChange: ({ data }) => dispatch(setAdminLocations(data ?? [])),
  })

  // Don't show hours when editing/adding a shipping distro
  const shouldShowHours = useCallback(
    (locationId: string) => {
      if (distribution) {
        return !isShipping(distribution.location)
      }
      const location = locations.find((loc) => loc.id === locationId)
      if (!location) {
        return true
      }
      return !isShipping(location)
    },
    [distribution, locations],
  )

  /** Loads the distro on edit, and manages loading state.
   * - This fx should be a "focus" effect because if the screen is out of focus and the farm id changes, we don't want to send the request.
   */
  useCancelableFocusFx(
    async (isCurrent) => {
      if (!farm?.id || farmId !== farm.id) return

      // If edit...
      if (isEdit) {
        let distroToEdit: Distribution
        try {
          distroToEdit = await retry(() => distrosCollection.fetch(params.id))
          if (!isCurrent) return
          if (!distroToEdit) throw new Error('Could not load the schedule')
          const canDelete = await canDistributionBeDeleted(farm.id, params.id)
          if (!isCurrent) return
          setCanDelete(canDelete)
          setShowEndDatePicker(isSeasonalSchedule(distroToEdit.schedule))
          setClosed(distroToEdit.closed)
          dispatch(setAdminNav({ distribution: distroToEdit }))
        } catch (err) {
          Logger.error(err)
          setError(
            'This distribution could not be loaded, please click the "X" in the upper right corner and go back and select a distribution from the list.',
          )
        }
      } else {
        dispatch(setAdminNav({ distribution: undefined }))
      }
    },
    [params?.id, isEdit, farm?.id, farmId, dispatch],
  )

  const handleSubmitDistribution = useCallback(
    async (values: FormType) => {
      const location = locations.find((loc) => loc.id === values.locationId)
      if (!location) {
        Alert(
          'Error Loading Locations',
          'We were unable to load your locations, please refresh this page and try again.',
        )
        return
      }

      const distLocation = getDistLocationFields(location)

      let data: Distribution

      if (values.scheduleType === 'Seasonal') {
        const seasonalData: Distribution<Location, SeasonalSchedule> = {
          id: '',
          name: values.name,
          farm: pick(farm, 'id', 'name', 'status', 'timezone'),
          location: distLocation,
          schedule: {
            dayOfWeek: getDayOfWeek(values.startDate),
            frequency: values.frequency as Frequency,
            hours: {
              startTime: values.startTime.toFormat('HH:mm'),
              endTime: values.endTime.toFormat('HH:mm'),
            },
            season: {
              startDate: values.startDate,
              endDate: values.endDate!,
            },
            exceptions: values.exceptions.length ? values.exceptions : undefined,
          },
          notes: values.notes || '',
          orderCutoffWindow: !isNaN(Number(values.orderCutoffWindow)) ? Number(values.orderCutoffWindow) : 1,
          closed,
          color: values.color,
          priceGroup: { type: 'default-catalog', catalog: values.defaultCatalog },
        }
        data = seasonalData
      } else {
        const yearlyData: Distribution<Location, YearRoundSchedule> = {
          id: '',
          name: values.name,
          farm: pick(farm, 'id', 'name', 'status', 'timezone'),
          location: distLocation,
          schedule: {
            dayOfWeek: getDayOfWeek(values.startDate),
            frequency: values.frequency as Frequency,
            hours: {
              startTime: values.startTime.toFormat('HH:mm'),
              endTime: values.endTime.toFormat('HH:mm'),
            },
            pickupStart: values.startDate,
            exceptions: values.exceptions.length ? values.exceptions : undefined,
          },
          notes: values.notes || '',
          orderCutoffWindow: !isNaN(Number(values.orderCutoffWindow)) ? Number(values.orderCutoffWindow) : 1,
          closed,
          color: values.color,
          priceGroup: { type: 'default-catalog', catalog: values.defaultCatalog },
        }
        data = yearlyData
      }

      // If the frequency is bi-weekly or monthly we need to set the week of month we start on to calculate pickups
      if (isWiderFreq(values.frequency, Frequency.WEEKLY)) {
        data.schedule.week = getWeekOfMonth(values.startDate) - 1
      }

      // Need to remove exceptions so getDistributionPickups includes skipped pickups
      const dataWithoutExceptions = { ...data, schedule: { ...data.schedule, exceptions: [] } }
      const pickups = getDistributionPickups(dataWithoutExceptions)
      const isFirstPickupSkipped = data.schedule.exceptions?.some((ex) => {
        const firstPickup = pickups[0]

        if (!firstPickup) return false

        if (isPatternException(ex)) {
          return ex.dayOfWeek === firstPickup.weekday
        }

        if (isSkipException(ex)) {
          return ex.sourceDate.toISODate() === firstPickup.toISODate()
        }

        return false
      })
      const isLastPickupSkipped = data.schedule.exceptions?.some((ex) => {
        const lastPickup = pickups[pickups.length - 1]

        if (!lastPickup) return false

        if (isPatternException(ex)) {
          return ex.dayOfWeek === lastPickup.weekday
        }

        if (isSkipException(ex)) {
          return ex.sourceDate.toISODate() === lastPickup.toISODate()
        }

        return false
      })

      if (isFirstPickupSkipped) {
        return invalidPickupSkippedPrompt('first')
      }

      if (isLastPickupSkipped) {
        return invalidPickupSkippedPrompt('last')
      }

      // Update or save changes
      if (isEdit && distribution) {
        // Check that we are actually updating anything and warn the farmer of any important changes
        try {
          setSubmitting(true)

          const newDist = { ...data, id: distribution.id }
          const result = await validateDistributionEdit(distribution, newDist)

          // Since there is nothing to update display toast and proceed with going back to distribution list
          if (result.status === ReturnStates.NO_CHANGE) Toast('Nothing to update, going back')

          // return here, so we don't go back if something requires more attention or a popup was cancelled
          if (result.status === ReturnStates.DO_NOTHING) {
            Toast('Not saved')
            return setSubmitting(false)
          }

          // If the farmer agrees and there are changes then proceed to update
          if (result.status === ReturnStates.SAVE) {
            await distrosCollection.update(result.data)
            if (result.linkedProducts.length > 0) {
              //If there's linked products, clear the farm cache
              dispatch(setNavProps())
            }
            Toast('Schedule was updated successfully')
          }
        } catch (e) {
          Logger.error(e)
          return Alert(
            'Error saving distribution',
            'There was an error saving this distribution, please contact support if this issue persists.',
          )
        } finally {
          setSubmitting(false)
        }
      } else {
        setSubmitting(true)
        await addDistribution(data, farm)
        Toast('New Schedule is added successfully')
        setSubmitting(false)
      }
      if (params?.goBack === 'AddProduct') {
        navigation.setParams({ goBack: undefined })
        return (navigation as unknown as StackNavigationProp<AdminDrawerParamList>).navigate('Products', {
          screen: 'AddProduct',
        })
      }
      navigation.navigate('Schedules')
    },
    [locations, distribution, farm, closed, isEdit, dispatch, navigation, params?.goBack],
  )

  /** Will make sure that the user is not switching the location type between pickup and non-pickup types for the new schedule */
  const validateLocationChange = (
    newLocId: string,
    currLocId: string,
    setFieldValue: (field: string, newValue: string) => void,
  ) => {
    const current = locations.find((loc) => loc.id === currLocId)
    const newLoc = locations.find((loc) => loc.id === newLocId)
    if (!newLoc) {
      Logger.error('Could not find location from selected ID')
      setFieldValue('locationId', currLocId)
      return
    }
    // If there is no current selection like on a new distro then allow the change
    if (!current) return setFieldValue('locationId', newLocId)

    // If the two locations are not the same pickup type then we should not save
    if (
      (isNonPickup(current.type) && !isNonPickup(newLoc.type)) ||
      (!isNonPickup(current.type) && isNonPickup(newLoc.type))
    ) {
      Alert('Unable to change location', 'You cannot switch this schedule to a location with a different type.')
      setFieldValue('locationId', currLocId)
    } else {
      setFieldValue('locationId', newLocId)
    }
  }

  return (
    <LoadingView
      loading={Object.values(loading).some((v) => v)}
      switchMode //switchMode necessary for correct scroll from AdminView as container
    >
      <Formik
        initialValues={getInitialFormValues(farm, distribution)}
        onSubmit={handleSubmitDistribution}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
        }: FormikProps<FormType>) => (
          <AdminView
            style={styles.addScheduleScreenContainer}
            customHeader={
              <EditHeader
                isLoading={error ? false : Object.values(loading).some((v) => v) || submitting}
                goBack={() => {
                  if (params?.goBack === 'AddProduct') {
                    navigation.setParams({ goBack: undefined })
                    ;(navigation as unknown as StackNavigationProp<AdminDrawerParamList>).navigate('Products', {
                      screen: 'AddProduct',
                    })
                  } else {
                    navigation.navigate('Schedules')
                  }
                }}
                title={isEdit && !!distribution?.name ? `Edit Schedule - ${distribution.name}` : 'Add Schedule'}
                actionTitle={error ? 'Back' : 'Save'}
                onPress={() => {
                  if (error) {
                    if (params?.goBack === 'AddProduct') {
                      navigation.setParams({ goBack: undefined })
                      ;(navigation as unknown as StackNavigationProp<AdminDrawerParamList>).navigate('Products', {
                        screen: 'AddProduct',
                      })
                    } else {
                      navigation.navigate('Schedules')
                    }
                  } else {
                    handleSubmit()
                  }
                }}
              />
            }
          >
            {error ? (
              <MessageWithIcon icon="truck-loading" title="Distribution not found">
                <Text>{error}</Text>
              </MessageWithIcon>
            ) : (
              <FormBuilder style={styles.distributionSession}>
                {/* Location or Zone selector */}
                <FormPickerInput
                  label="Select a location or zone"
                  placeholder="Select a Location or Zone"
                  items={locations.map((loc) => ({
                    label: `${loc.name}${!isNonPickup(loc) && loc.nickname ? ` (${loc.nickname})` : ''}`,
                    value: loc.id,
                  }))}
                  value={values.locationId}
                  onValueChange={(newLocId) => validateLocationChange(newLocId, values.locationId, setFieldValue)}
                  errorMessage={!touched.locationId ? undefined : errors.locationId}
                />

                {/* Catalog selection */}
                {farm.isWholesale ? (
                  <FormPickerInput
                    value={values.defaultCatalog}
                    items={Object.values(DefaultCatalog).map((v) => ({
                      label: formatCatalog(v),
                      value: v,
                    }))}
                    onValueChange={handleChange('defaultCatalog')}
                    label="Catalog"
                  />
                ) : null}

                {/* Schedule name */}
                <Input
                  label={
                    <InputLabel label="Schedule Name" tooltipTitle="Schedule Name" tooltipId={ToolTips.DIST_NAME} />
                  }
                  labelStyle={styles.labelStyle}
                  onChangeText={(text) => {
                    handleChange('name')(text)
                  }}
                  errorMessage={touched.name && !!errors.name ? `* ${errors.name}` : ''}
                  onBlur={handleBlur('name')}
                  value={values.name}
                  rightIcon={
                    touched.name && errors.name
                      ? { type: 'font-awesome', name: 'times-circle', size: 24, color: Colors.red }
                      : false
                  }
                />

                {/* Schedule Color */}

                <FormColorPicker
                  onChange={(color) => setFieldValue('color', color)}
                  color={values.color}
                  containerStyle={styles.colorPickerTriggerContainer}
                  errorMessage={touched.color && !!errors.color ? `* ${errors.color}` : ''}
                  label="Schedule Color"
                  placeholder="Pick a color for the schedule"
                />
                <TextH3 color={Colors.shades[300]} style={styles.placeholder}>
                  An easy color for admins to identify the schedule. Pick one to check the color of the schedule on the
                  calendar
                </TextH3>

                {/* Notes */}
                <Input
                  label="Notes"
                  labelStyle={styles.labelStyle}
                  onChangeText={handleChange('notes')}
                  onBlur={handleBlur('notes')}
                  value={values.notes}
                  rightIcon={
                    touched.notes && errors.notes
                      ? { type: 'font-awesome', name: 'times-circle', size: 24, color: Colors.red }
                      : false
                  }
                />
                <TextH3 color={Colors.shades[300]} style={styles.placeholder}>
                  Notes to help people find you (optional)
                </TextH3>

                {/* Order cutoff window */}
                <Input
                  label={
                    <InputLabel
                      label="Order Cutoff Window"
                      tooltipTitle="Order Cutoff Window"
                      tooltipId={ToolTips.ORDER_CUTOFF_WINDOW}
                    />
                  }
                  inputMode="numeric"
                  labelStyle={styles.labelStyle}
                  onChangeText={handleChange('orderCutoffWindow')}
                  onBlur={handleBlur('orderCutoffWindow')}
                  errorMessage={
                    touched.orderCutoffWindow && !!errors.orderCutoffWindow ? `* ${errors.orderCutoffWindow}` : ''
                  }
                  value={values.orderCutoffWindow.toString()}
                  rightIcon={
                    touched.orderCutoffWindow && errors.orderCutoffWindow
                      ? { type: 'font-awesome', name: 'times-circle', size: 24, color: Colors.red }
                      : false
                  }
                />
                <TextH3 color={Colors.shades[300]} style={styles.placeholder}>
                  Minimum is 0 (until end of pickup)
                </TextH3>

                {/* Schedule Type */}
                <View style={styles.scheduleTypeContainer}>
                  <Text size={14} type="bold" style={styles.scheduleTypeLabel}>
                    Schedule type
                  </Text>
                  <View style={styles.radioButtonGroups}>
                    <RadioButton
                      value="Seasonal"
                      isSelected={values.scheduleType === 'Seasonal'}
                      disabled={isEdit}
                      onSelect={() => {
                        setFieldValue('scheduleType', 'Seasonal')
                        setShowEndDatePicker(true)
                      }}
                    />
                    <RadioButton
                      value="Year-round"
                      isSelected={values.scheduleType === 'Year Round'}
                      disabled={isEdit}
                      onSelect={() => {
                        setFieldValue('scheduleType', 'Year Round')
                        setShowEndDatePicker(false)
                      }}
                    />
                  </View>
                  {isEdit && (
                    <ErrorText style={styles.scheduleTypeError}>
                      * You cannot change the type of a schedule after it has been created. You must create a new
                      schedule to make changes to the type.
                    </ErrorText>
                  )}
                </View>
                {/* Date Range */}
                <View style={styles.dateRangeContainer}>
                  <View>
                    <Text size={14} type="bold" style={styles.datePickerLabel}>
                      Start Date <Tooltip title="Distribution Schedule" id={ToolTips.DISTRIB_SCHEDULE} />
                    </Text>
                    <DateTimePickerForm
                      containerStyle={styles.dateTimePickerContainer}
                      onChange={(item) => setFieldValue('startDate', item)}
                      value={values.startDate ? values.startDate : undefined}
                      timezone={farm.timezone}
                      maxDate={values.endDate}
                    />
                    {errors.startDate && touched.startDate && <ErrorText>{errors.startDate as string}</ErrorText>}
                  </View>

                  {showEndDatePicker && (
                    <View style={styles.endDatePickerContainer}>
                      <Text type="bold" style={styles.datePickerLabel}>
                        End Date
                      </Text>
                      <DateTimePickerForm
                        containerStyle={styles.dateTimePickerContainer}
                        onChange={(item) => setFieldValue('endDate', item)}
                        value={values.endDate ? values.endDate : undefined}
                        timezone={farm.timezone}
                        minDate={values.startDate}
                      />
                      {!!errors.endDate && touched.endDate && <ErrorText>{errors.endDate}</ErrorText>}
                    </View>
                  )}
                </View>
                {/* Time */}
                {shouldShowHours(values.locationId) && (
                  <View style={styles.timeContainer}>
                    <View>
                      <Text size={14} type="bold" style={styles.datePickerLabel}>
                        Start Time
                      </Text>
                      <DateTimePickerForm
                        containerStyle={styles.dateTimePickerContainer}
                        mode="time"
                        onChange={(item) => setFieldValue('startTime', item)}
                        timezone={farm.timezone}
                        value={values.startTime}
                      />
                      {errors.startTime && touched.startTime && <ErrorText>{errors.startTime as string}</ErrorText>}
                    </View>
                    <View style={styles.endDatePickerContainer}>
                      <Text type="bold" style={styles.datePickerLabel}>
                        End Time
                      </Text>
                      <DateTimePickerForm
                        containerStyle={styles.dateTimePickerContainer}
                        mode="time"
                        onChange={(item) => setFieldValue('endTime', item)}
                        timezone={farm.timezone}
                        value={values.endTime}
                      />
                      {errors.endTime && touched.endTime ? <ErrorText>{errors.endTime as string}</ErrorText> : null}
                    </View>
                  </View>
                )}
                {/* Frequency */}
                <Text size={14} type="bold" style={styles.frequencyLabel}>
                  Frequency
                </Text>
                <FormPickerInput
                  disabled={isEdit && !canDelete}
                  placeholder="Select frequency"
                  items={frequencyOptions}
                  value={values.frequency}
                  onValueChange={(item) => {
                    setFieldValue('frequency', item)
                    setFieldValue('exceptions', distribution?.schedule.exceptions || [])
                  }}
                  errorMessage={!touched.frequency ? undefined : errors.frequency}
                />
                {errors.frequency && touched.frequency ? (
                  <ErrorText style={styles.frequencyError}>* {errors.frequency}</ErrorText>
                ) : null}
                {isEdit && !canDelete && (
                  <ErrorText style={styles.frequencyNotAllowed}>
                    * You cannot change the frequency of a schedule after it has products or pickups associated with it.
                  </ErrorText>
                )}

                <View style={globalStyles.flexRowCenter}>
                  <Text size={14} type="bold" style={styles.closedLabel}>
                    Closed for new orders
                  </Text>
                  <Tooltip id={ToolTips.CLOSED_FOR_NEW_ORDERS} />
                </View>
                <Text style={globalStyles.margin10}>
                  You can close this schedule temporarily to prevent customers from buying from it.
                </Text>
                <CheckBox
                  checked={closed ?? false}
                  onChange={(v) => setClosed(v)}
                  title="Close Schedule"
                  style={globalStyles.margin10}
                />

                <View style={globalStyles.flexColumn}>
                  <View style={globalStyles.flexRowCenter}>
                    <Text size={14} type="bold" style={styles.closedLabel}>
                      Exception Days{' '}
                    </Text>
                    <Tooltip id={ToolTips.EXCEPTION_DAYS} />
                  </View>
                  {errors.exceptions ? (
                    <ErrorText style={styles.exceptionsError}>
                      {Array.isArray(errors.exceptions) ? errors.exceptions.join(', ') : errors.exceptions}
                    </ErrorText>
                  ) : null}
                </View>
                {values.frequency === Frequency.DAILY && (
                  <WeekdaySelector
                    viewProps={{ style: { marginTop: 30 } }}
                    onDayPress={(dayN, enabled) => {
                      enabled
                        ? removeException(setFieldValue, values.exceptions, { dayOfWeek: dayN })
                        : addException(setFieldValue, values.exceptions, { dayOfWeek: dayN })
                    }}
                    size={isSmallDevice ? Math.min((width - 120) / 7, 50) : 50}
                    dayNumChars={2}
                    values={{
                      1: { dayName: 'Monday', enabled: !values.exceptions.find((e) => e.dayOfWeek === 1) },
                      2: { dayName: 'Tuesday', enabled: !values.exceptions.find((e) => e.dayOfWeek === 2) },
                      3: { dayName: 'Wednesday', enabled: !values.exceptions.find((e) => e.dayOfWeek === 3) },
                      4: { dayName: 'Thursday', enabled: !values.exceptions.find((e) => e.dayOfWeek === 4) },
                      5: { dayName: 'Friday', enabled: !values.exceptions.find((e) => e.dayOfWeek === 5) },
                      6: { dayName: 'Saturday', enabled: !values.exceptions.find((e) => e.dayOfWeek === 6) },
                      7: { dayName: 'Sunday', enabled: !values.exceptions.find((e) => e.dayOfWeek === 7) },
                    }}
                  />
                )}

                {/*Exception calendar: The flexDirection should be column on width < 850 because otherwise there's no space on the right side, and it's better to not try and do a horizontal scroll here. */}
                <View
                  style={[
                    width < 850 ? globalStyles.flexColumn : globalStyles.flexRow,
                    styles.exceptionsCalendarContainer,
                  ]}
                >
                  <DistributionCalendar
                    distro={createDummyDistro(values, farm)}
                    onExceptionAdd={(e) => addException(setFieldValue, values.exceptions, e)}
                    exceptions={values.exceptions}
                  />
                  <View style={styles.ScheduleSideInfo}>
                    <View>
                      <View style={styles.flexRowCenter}>
                        <View style={[styles.markerIcon, styles.originalScheduledDayIconContainer]}>
                          <View style={[styles.markerIcon, styles.originalScheduledDayIcon]} />
                        </View>
                        <Text>Original Scheduled day</Text>
                      </View>

                      <View>
                        <View style={styles.flexRowCenter}>
                          <View style={[styles.markerIcon, { backgroundColor: Colors.blue }]} />
                          <Text>Skip day</Text>
                        </View>

                        <View style={styles.flexRowCenter}>
                          <View style={[styles.markerIcon, { backgroundColor: Colors.red }]} />
                          <Text>Exception day</Text>
                        </View>

                        <View style={styles.flexRowCenter}>
                          <View style={styles.markerIcon} />
                          <Text>Reschedule day</Text>
                        </View>
                      </View>
                    </View>
                    {values.exceptions.length > 0 && (
                      <View>
                        <TextH2 style={styles.exceptionsListLabel}>List of Exceptions</TextH2>
                        {values.exceptions.map((e) => {
                          return (
                            <View
                              key={e.sourceDate ? e.sourceDate.toISODate() : e.dayOfWeek}
                              style={styles.exceptionContainer}
                            >
                              <Text style={styles.exceptionSourceDate}>
                                {e.sourceDate ? e.sourceDate.toISODate() : getDayofWeekName(e.dayOfWeek)}
                              </Text>
                              <Icon style={styles.exceptionRightIcon} name="chevron-right" />
                              {e.targetDate ? (
                                <Text style={styles.exceptionTargetDate}>{e.targetDate.toISODate()}</Text>
                              ) : (
                                <Text>Skipped</Text>
                              )}
                              <Icon
                                style={styles.exceptionDeleteIcon}
                                name="times"
                                color={Colors.red}
                                onPress={() => removeException(setFieldValue, values.exceptions, e)}
                              />
                            </View>
                          )
                        })}
                      </View>
                    )}
                  </View>
                </View>
              </FormBuilder>
            )}
          </AdminView>
        )}
      </Formik>
    </LoadingView>
  )
}

export const DistributionDetailScreen = withAdminAuth(
  DistributionDetailScreenComp,
  Permission.ProductSetup,
  AccessRight.Edit,
)

const useResponsiveStyles = CreateResponsiveStyle(
  {
    addScheduleScreenContainer: {
      padding: 5,
    },
    distributionSession: { marginTop: 5 },
    ScheduleSideInfo: {
      marginTop: 20,
      marginLeft: 10,
    },
    radioButtonGroups: {
      flexDirection: 'row',
      marginHorizontal: 10,
      width: 250,
      justifyContent: 'space-between',
    },
    labelStyle: {
      fontSize: 14,
      color: Colors.black,
      marginTop: 10,
    },
    errorMessage: {
      marginTop: 0,
      marginLeft: 10,
      fontSize: 12,
    },
    placeholder: {
      margin: 10,
      marginTop: 0,
    },
    colorPickerTriggerContainer: {
      marginLeft: 10,
      width: '100%',
      paddingRight: 20,
    },
    flexRowCenter: {
      flexDirection: 'row',
      alignItems: 'center',
      marginVertical: 2,
    },
    markerIcon: {
      width: 25,
      height: 25,
      borderRadius: 15,
      backgroundColor: Colors.green,
      marginRight: 5,
    },
    originalScheduledDayIconContainer: {
      backgroundColor: Colors.transparent,
      alignItems: 'center',
      justifyContent: 'center',
    },
    originalScheduledDayIcon: { width: 6, height: 6 },
    selectColor: {
      width: 15,
      height: 15,
      borderRadius: 8,
      marginLeft: 10,
      marginTop: 8,
    },
    scheduleTypeContainer: { flexDirection: 'column', marginTop: 20 },
    scheduleTypeLabel: {
      margin: 10,
      color: Colors.black,
    },
    scheduleTypeError: { marginTop: 5, marginLeft: 15 },
    dateRangeContainer: { flexDirection: 'row', marginTop: 20 },
    dateTimePickerContainer: {
      marginLeft: 10,
      maxWidth: 200,
    },
    endDatePickerContainer: {
      marginLeft: 15,
      marginTop: 3,
    },
    datePickerLabel: {
      margin: 10,
      color: Colors.black,
      marginBottom: 2,
      fontSize: 14,
    },
    timeContainer: { flexDirection: 'row', marginTop: 30 },
    frequencyLabel: {
      margin: 10,
      color: Colors.black,
      marginBottom: 2,
    },
    frequencyError: { marginTop: -5, marginLeft: 14 },
    frequencyNotAllowed: { marginTop: 5, marginLeft: 14 },
    closedLabel: {
      color: Colors.black,
      fontWeight: 'bold',
      marginLeft: 10,
    },
    closedCheckbox: { marginLeft: 10 },
    exceptionsError: { marginLeft: 10 },
    exceptionsCalendarContainer: { marginTop: 10 },
    exceptionsListLabel: { marginVertical: 10 },
    exceptionContainer: { flexDirection: 'row', alignItems: 'center', marginVertical: 5 },
    exceptionSourceDate: { width: 80 },
    exceptionRightIcon: { marginHorizontal: 20 },
    exceptionTargetDate: { width: 80 },
    exceptionDeleteIcon: { marginLeft: 20 },
  },
  {
    [minSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      labelStyle: {
        marginBottom: 0,
      },
      errorMessage: {
        marginLeft: 0,
      },
      datePickerLabel: {
        fontSize: 16,
      },
    },
    [minSize(DEVICE_SIZES.LARGE_DEVICE)]: {
      addScheduleScreenContainer: {
        paddingLeft: 60,
      },
      distributionSession: {
        width: '80%',
        minWidth: 300,
        marginTop: 10,
      },
      labelStyle: {
        fontSize: 14,
      },
      placeholder: {
        marginTop: 0,
      },
    },
    [minSize(DEVICE_SIZES.EXTRA_LARGE_DEVICE)]: {
      distributionSession: {
        width: '50%',
        maxWidth: 800,
      },
      ScheduleSideInfo: {
        marginTop: 20,
        marginLeft: 30,
      },
    },
  },
)
