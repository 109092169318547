import { AlgoliaFarmDataContext } from '@/hooks/useAlgoliaFarmData/useAlgoliaFarmData'
import { useComponentRoute } from '@/hooks/useComponentRoute'
import { FarmDataContext } from '@/hooks/useFarmData'
import { ShoppingStackParamList } from '@/navigation/types'
import { wholesaleSelector } from '@/redux/selectors'
import { isNonNullish } from '@helpers/helpers'
import { ArrElement } from '@helpers/typescript'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  ProductFilterGroup,
  getCategoryRefinementGroup,
  getCsaRefinementGroup,
  getLocationRefinementGroup,
  getTypeRefinementGroup,
} from './Filtering/filterUtils'

/** Provides data for the shop sidebar and the mobile equivalent component */
export const useShopSidebarData = () => {
  const { isWholesale } = useSelector(wholesaleSelector)
  const navigation = useNavigation<StackNavigationProp<ShoppingStackParamList, 'FarmShop'>>()
  const { categoryFilter, products, typeFilter, locations, locationFilter, loadingLocations } =
    useContext(AlgoliaFarmDataContext)
  const { params } = useRoute<RouteProp<ShoppingStackParamList, 'FarmShop'>>()

  /** Whether we are on the csa screen or on the shop screen */
  const isCsaScreen = useComponentRoute()?.name === 'CSADetails'

  const {
    farm: { data: farm },
    csas: { data: csas },
  } = useContext(FarmDataContext)

  const onButtonPress = useCallback(
    (item: ArrElement<ProductFilterGroup['items']>) => {
      if (item.filterKey === 'csaId') {
        if (!farm) return
        return navigation.navigate('CSADetails', { farmSlug: farm.urlSafeSlug, csaId: item.value })
      }

      // On toggle or `All` press, reset filter
      const value = item.shouldClear || params[item.filterKey] === item.value ? undefined : item.value

      navigation.setParams({ [item.filterKey]: value })
    },
    [farm, navigation, params],
  )

  const locationRefinementGroup = useMemo(
    () => getLocationRefinementGroup(locations ?? [], locationFilter.items),
    [locationFilter.items, locations],
  )

  /** Data for the UI */
  const filterGroups = useMemo<ProductFilterGroup[]>(() => {
    const catGroup = getCategoryRefinementGroup(categoryFilter.items)

    if (!isCsaScreen) {
      // In the shop we only show categories and CSA filters.
      if (isWholesale) {
        // In the wholesale shop we don't show the CSA filters
        return [catGroup].filter(isNonNullish)
      }
      return [catGroup, getCsaRefinementGroup(csas, products)].filter(isNonNullish)
    }
    /** Return both categories and types if on the csa page */
    const typeGroup = getTypeRefinementGroup(typeFilter.items)

    return [catGroup, typeGroup].filter(isNonNullish)
  }, [categoryFilter.items, isCsaScreen, typeFilter.items, isWholesale, csas, products])

  return {
    filterGroups,
    onButtonPress,
    locationRefinementGroup,
    loadingLocations,
  }
}
