import { isDraftPickup, isPickupCancelled, Pickup, PickupItem } from '@models/Order'
import { isSameAddress } from './location'
import { Address } from '@models/Address'
import { UserAddress } from '@models/UserAddress'

/**
 * Find an existing pickup that is the same as the new one if one exists. We don't want to combine if it is cancelled, a draft or a different address.
 * @param existing The existing pickup to determine if it is combinable with the new pickup
 * @param newAddress The address of the new pickup we are attempting to merge
 */
export function shouldCombinePickups(existing: Pickup, newAddress: Address | UserAddress) {
  return (
    !isPickupCancelled(existing) && !isDraftPickup(existing) && isSameAddress(existing.distribution.address, newAddress)
  )
}

/** Will return true when a pickup item matches the expected product and order ID's*/
export const matchPickupItemsFromProductAndOrder = (productId: string, orderId: string) => (itm: PickupItem) => {
  return itm.product.id === productId && itm.order.id === orderId
}
