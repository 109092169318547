import React from 'react'
import { Image, View } from 'react-native'
import { farmerOnboardPartnersAssets } from '@/assets/images/farmer_onboard'
import Colors from '@/constants/Colors'
import { useSizeFnStyles } from '@/hooks/useFnStyles'

/**
 * `PartnersSection` is a functional component that displays a series of partner icons in a section.
 * The icons are arranged in a row, with the ability to wrap into multiple rows if necessary, providing
 * a responsive layout that adapts to various screen sizes.
 */
export function PartnersSection() {
  const styles = usePartnersSectionStyles()

  return (
    <View style={styles.container}>
      <View style={styles.iconsContainer}>
        {farmerOnboardPartnersAssets.map((image, index) => (
          <Image key={index} source={image} style={styles.icon} />
        ))}
      </View>
    </View>
  )
}

const usePartnersSectionStyles = () =>
  useSizeFnStyles(({ isSmallDevice, isMedDevice }) => ({
    container: {
      alignItems: 'center',
      justifyContent: 'center',
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderTopColor: Colors.black,
      borderBottomColor: Colors.black,
    },
    iconsContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginVertical: isSmallDevice ? 1 : 5,

      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    icon: {
      width: isSmallDevice ? 80 : isMedDevice ? 90 : 100,
      aspectRatio: 1,
      margin: isSmallDevice ? 8 : isMedDevice ? 20 : 30,
    },
  }))
