import Colors from '@/constants/Colors'
import { EbtIcon, Image, ResizedSuffix } from '@components'
import { Text } from '@elements'
import { getPriceString, pickupPriceRangeString } from '@helpers/display'
import { CartItem, isCartShare } from '@models/Order'
import { View } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'
import { QuantityStepper } from './QuantityStepper'
import { formatDistributionType } from '@helpers/location'

export type ProductDetailsProps = {
  item: CartItem
  setQuantity: (delta: number) => Promise<void>
  loading?: boolean
}

/** Product Detail Section of Cart Item */
export function ProductDetails({ item, setQuantity, loading }: ProductDetailsProps) {
  const styles = useStyles()
  const { pickups } = item
  return (
    <View style={styles.productDetailContainer}>
      <Image
        resizeSuffix={ResizedSuffix.THUMB}
        source={{ uri: item.product.images[0] }}
        style={styles.cardImage}
        resizeMode="cover"
      />
      <View style={styles.detailOrientationWrapper}>
        <View style={styles.productDetailTextContainer}>
          <Text style={styles.productNameText} type="medium" size={16}>
            {item.product.name} <EbtIcon product={item.product} size={22} />
          </Text>
          {isCartShare(item) ? (
            <Text>{`${pickupPriceRangeString(item.product)}/${formatDistributionType(
              item.distribution.location,
            )}`}</Text>
          ) : (
            <Text>
              {getPriceString(item.product, { price: item.price })}
              {`/${item.unit.name || 'unit'}`}
            </Text>
          )}
          {pickups && pickups.length >= 1 && (
            <View style={styles.descriptionDetails}>
              <Text style={styles.NumberQtyTextDetail} type="regular" color={Colors.black}>
                No. {formatDistributionType(item.distribution.location, { plural: pickups.length > 1 })}:{' '}
                {pickups.length}
              </Text>
              <Text style={styles.NumberQtyTextDetail} type="regular" color={Colors.black}>
                Qty. per {formatDistributionType(item.distribution.location, { plural: item.quantity > 1 })}:{' '}
                {item.quantity}
              </Text>
            </View>
          )}
        </View>
        <QuantityStepper item={item} setQuantity={setQuantity} loading={loading} />
      </View>
    </View>
  )
}

const useStyles = CreateResponsiveStyle(
  {
    cardImage: {
      width: 100,
      height: 100,
      borderRadius: 5,
      alignSelf: 'flex-start',
      marginRight: 14,
    },
    productDetailContainer: {
      flex: 1,
      flexDirection: 'row',
      width: '100%',
      marginRight: 10,
    },
    productDetailTextContainer: {
      flexDirection: 'column',
      alignSelf: 'flex-start',
    },
    NumberQtyTextDetail: {
      marginRight: 8,
    },
    descriptionDetails: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    productDistroDetailsWrapper: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
    pickupAndLocationDetails: {
      flexDirection: 'column',
    },
    pickupDetailContainer: {
      marginBottom: 10,
    },
    pickupAndLocationDetailsText: {
      marginTop: 4,
    },
    showDetailView: {
      paddingHorizontal: 12,
      marginTop: -8,
    },
    showDetailText: {
      color: Colors.green,
    },
    detailOrientationWrapper: {
      flex: 1,
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'space-between',
    },
    productNameText: {},
  },
  {
    [maxSize(DEVICE_SIZES.XS)]: {
      cardImage: {
        width: 64,
        height: 64,
      },
      detailOrientationWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      productDistroDetailsWrapper: {
        margin: 12,
      },
      productNameText: {
        maxWidth: '80%',
      },
      productDetailTextContainer: {
        flex: 1,
      },
    },
  },
)
