import { ScrollView } from 'react-native'

import { useLayout } from '@/hooks/useLayout'
import { Divider } from '../../components/elements/Divider'
import { Text } from '../../components/elements/Text'
import { globalStyles } from '../../constants/Styles'

export function PrivacyPolicy() {
  const { bottom } = useLayout()
  return (
    <ScrollView contentContainerStyle={globalStyles.padding10}>
      <Text>
        This Privacy Policy describes how GrownBy (“GrownBy”, “we” etc.) collects and uses Personally Identifiable
        Information or equivalent jurisdictional definition of the same (PII) from usage of the GrownBY platform, mobile
        app, and/or website, including interaction with, and/or viewing any of the video, blogs, audio, stories, text,
        photographs , graphics, artwork and/or other content featured on the GrownBy platform, mobile app, and/or
        website (“GrownBy Platform”) by users of the GrownBy Platform (“Licensee(s)”, “you” etc.), in furtherance of
        providing an online marketplace to connect Licensee’s with third party businesses (“Sellers”) of food, food
        products and other food related services (“product and services” ) for purchase directly with such Sellers
        (“Purchase”) using a third party online payment portal and processor (“Payment Processor”). This includes the
        information we collect and the choices you have concerning the collection and use of such information. The goal
        of this Policy is to ensure that your GrownBy experience is enjoyable and your sensitive date is secure. Please
        review this Policy carefully and if you do not agree to this Policy, you must immediately cease use the GrownBy
        Platform. Any continued use will be your express consent to be bound by this Privacy Policy. PLEASE NOTE: This
        Privacy Policy does not cover nor govern the collection, access, and/or processing of any PII by any Sellers or
        Payment Processors you may interact with by accessing and/or using the GrownBy Platform. Those terms are
        governed and controlled by the Seller and Payment Processor in their sole discretion. For review of those terms,
        you are responsible for obtaining those terms directly from the Seller or Payment Processor.
      </Text>
      <Divider clear />
      <Text>This Policy Addresses:</Text>
      <Text>A. Personal Information Collected</Text>
      <Text>B. GrownBy Use of Personally Identifiable Information -- General</Text>
      <Text>C. Security and Payment Processing</Text>
      <Text>D. California Consumer Privacy Act</Text>
      <Text>Third Party Vendor Security Use of PII</Text>
      <Text>E. General Data Protection Regulation</Text>
      <Text>F. Enhancement of the Customer Experience</Text>
      <Text>G. Do Not Call List(s)</Text>
      <Text>H. Limited Disclosure to Third Parties</Text>
      <Text>I. On-Line Information</Text>
      <Text>J. Security Measures</Text>
      <Text>K. Links</Text>
      <Text>L. Policy Updates</Text>
      <Text>M. Security Breaches</Text>
      <Text>N. Contact Us</Text>
      <Divider clear />
      <Text type="bold">A. Personal Information Collected</Text>
      <Text>
        As a general principle, GrownBy limits the information collected about you to only what is needed for providing
        the GrownBy Platform. You may choose to provide PII to GrownBy in a number of ways: in person, telephonically,
        by eMail or electronically via our websites. Examples of how you may share PII with us include: requesting a
        brochure or product information, responding to GrownBy surveys, attending GrownBy-sponsored events or
        conferences, or applying for a job. We may also obtain PII from third parties (for example, credit agencies or
        background checks), but only if we have first obtained your permission.
      </Text>
      <Text>- General Examples of common PII, though not necessarily collected by GrownBy, are:</Text>
      <Text>- Name (including company name for business customers)</Text>
      <Text>- E-mail address</Text>
      <Text>
        - Credit card number, financial/bank account number or wire transfer information, including routing numbers and
        instructions
      </Text>
      <Text>- Passwords or personal identification codes (PINs)</Text>
      <Text>- Date of birth</Text>
      <Text>- Social Security number or other government identification number</Text>
      <Text>- Employee number</Text>
      <Text>- Professional employment information</Text>
      <Text>- Company contact information for business customers</Text>
      <Divider clear />
      <Text>When our Licensees use the GrownBy Platform, we may collect additional information such as:</Text>
      <Text>- Mailing address, unless unlisted or restricted at your request</Text>
      <Text>- Telephone number, unless unlisted or restricted at your request</Text>
      <Text>- IP Address allowing you to access our internet services</Text>
      <Text>- PII included in aggregate data compiled by GrownBy</Text>
      <Divider clear />
      <Text>
        GrownBy may compile or aggregate PII from numerous Licensee’s to collect data about groups of customers or
        potential customers or categories of service. GrownBy does not consider this “aggregate” information as PII
        because the aggregated information does not contain the PII of any Licensee.
      </Text>
      <Divider clear />

      <Text type="bold">B. GrownBy Use of Personally Identifiable Information -- General</Text>
      <Text>
        GrownBy uses PII to provide products and services to meet our Licensee’s needs, including new products or
        services. GrownBy may share PII with any GrownBy-affiliated company, and these companies are subject to the
        terms of this Policy. GrownBy uses our employee’s PII to communicate with and manage our employees only. GrownBy
        retains PII only as long as is necessary for GrownBy to comply with business, tax and legal requirements. For
        Licensees and employees, this retention period is likely to be the entire time you are our Licensee or employee,
        depending on the type of PII.
      </Text>
      <Text>GrownBy does not:</Text>
      <Text>
        - Collect PII from you unless you provide it to us in person, telephonically or electronically by visiting the
        GrownBy Platform including information you provide in furtherance of your Purchase of products and services.
      </Text>
      <Text>
        - Sell the names and addresses of GrownBy Licensee’s to unaffiliated suppliers without your prior approval.
      </Text>
      <Text>
        - Allow third parties to change your PII, without complying with this Policy or requiring them to comply their
        industry standard privacy policy.
      </Text>
      <Divider clear />

      <Text type="bold">C. Security and Payment Processing</Text>
      <Text>
        GrownBy has Facilitated an industry leading Payment Processor to offer safe and secure credit card and
        electronic check transactions for Licensees who Purchase products and services from Sellers. The Payment
        Processor manages the complex routing of sensitive customer information through the credit card and electronic
        check processing. Payment Processor adheres to strict industry standards for payment processing aimed at keeping
        your comfort level high and your transaction secure.
      </Text>
      <Divider clear />
      <Text>
        For additional information regarding the privacy of your sensitive cardholder data, please review the Payment
        Processor’s Privacy Policy directly through their service website.
      </Text>
      <Divider clear />
      <Text>
        At all times GrownBy will keep any personal contact information provided to us through the GrownBy Platform, via
        telephone or by fax confidential. We do not under any circumstances share the information of our Licensees with
        any other companies, other than our business partners that work with GrownBy to assist us to provide the GrownBy
        Platform. GrownBy also DOES NOT store your credit card number, other sensitive card holder information, or PII
        on our servers. In particular, any sensitive card holder information you provide will be provided directly on
        the Payment Processor’s portal, not through the GrownBy Platform. When completing a Purchase, GrownBy is not
        responsible or liable for the security or protection of your sensitive card holder information
      </Text>
      <Divider clear />
      <Text>
        We use cookies and other technology to keep track of your usage of the GrownBy Platform. The information
        provided to us will be used only by GrownBy in furtherance of maintaining and/or improving the GrownBy Platform.
        We may use this information to personalize the GrownBy Platform and how we communicate with you. We also use the
        information you provide GrownBy to help enforce the Agreement and this Privacy Policy, to prevent fraud, and to
        protect our interests in the GrownBy Platform.
      </Text>
      <Text>
        If you have any questions on this Privacy Policy, Feel free to contact us directly at email:
        info@farmgenerations.coop
      </Text>
      <Divider clear />

      <Text type="bold">D. California Consumer Privacy Act</Text>
      <Text>
        The California Consumer Privacy Act (CCPA) applies to certain businesses who process California consumers
        personal information AND satisfies one or more of the following:
      </Text>
      <Text>
        a. Has annual gross revenues in excess of twenty-five million dollars ($25,000,000), as adjusted pursuant to
        paragraph (5) of subdivision (a) of Section 1798.185 of the CCPA.
      </Text>
      <Text>
        b. Alone or in combination, annually buys, receives for the business’s commercial purposes, sells, or shares for
        commercial purposes, alone or in combination, the personal information of 50,000 or more consumers, households,
        or devices.
      </Text>
      <Text>
        c. Derives 50 percent or more of its annual revenues from selling consumers’ personal information. If GrownBY is
        or becomes subject to the requirement of the CCPA, GrownBY does and will comply with all applicable requirements
        thereto.
      </Text>
      <Divider clear />

      <Text type="bold">E. Data Privacy and General Data Protection Regulation</Text>
      <Text>
        GrownBy is a US based online-platform and usage of GrownBy indicates you consent for your PII to be in the US.
        As such, GrownBy complies with the applicable provisions of the General Data Protection Regulation (“GDPR”)
        regarding the processing, collection, use, and retention of personal information from European Union member
        countries and Switzerland at all times when processing any personal information in furtherance of providing its
        products and services and has put in place adequate technical and organizational security measures that is
        appropriate to the form and type of processing being performed on your PII For any other future jurisdictional
        data privacy requirements that GrownBy may become obligated to comply with, GrownBy will use commercially
        reasonable efforts to comply thereto in a timely manner.
      </Text>
      <Divider clear />

      <Text type="bold">F. Enhancement of the Customer Experience</Text>
      <Text>
        GrownBy continually looks for ways to enhance your experience using the GrownBy Platform. From time-to-time, we
        may notify you using the PII you have provided to us either in person, telephonically or electronically by
        visiting the GrownBy Platform. We strive to limit our communications to those we think you would benefit from
        and appreciate receiving. We want every contact you have with us to be a positive experience. If you prefer not
        to receive these GrownBy communications, please contact us at email: info@farmgenerations.coop, subject line:
        “Licensee PII Deletion Request”
      </Text>
      <Divider clear />

      <Text type="bold">G. Do Not Call List(s)</Text>
      <Text>
        GrownBy complies with all applicable laws and regulations regarding “Do Not Call” Lists. Generally, GrownBy is
        allowed to contact its Licensees, even if the Licensees are registered with federal or state Do Not Call Lists,
        because of our relationship with you. GrownBy will, of course, honor any request to remove your name from our
        telephone, mail or e-mail solicitation lists and will delete your information from existing files within a
        reasonable time period by contacting us at email: info@farmgenerations.coop, subject line: “Licensee PII
        Deletion Request”.
      </Text>
      <Divider clear />

      <Text type="bold">H. Limited Disclosure to Third Parties</Text>
      <Text>
        GrownBy does not sell PII of its Licensee’s to third parties without your prior consent. In limited
        circumstances, GrownBy may provide PII to third parties:
      </Text>
      <Text>
        - To assist us in developing, promoting, establishing, maintaining and/or providing the GrownBy Platform to you,
        including joint marketing efforts or promotions, but PII may not be used by the third parties for any other
        purpose;
      </Text>
      <Text>
        - To assist us in enforcing our rights where permitted by law, and protecting or enforcing our rights or
        property or the services from fraudulent, abusive, or unlawful use by you of the GrownBy Platform;
      </Text>
      <Text>
        - To comply, when required by law, with court or administrative orders, civil or criminal subpoenas, warrants
        from law enforcement agencies, federal or state regulatory requirements, mandatory governmental audits, E911
        reporting requirements, grand jury investigations, civil or criminal governmental investigations or reporting
        required by the National Center for Missing and Exploited Children, designated by US federal law; and
      </Text>
      <Text>
        - To appropriate law enforcement, 911 centers or emergency services when GrownBy, in good faith, believes the
        disclosure is necessary to protect a person, GrownBy property or the public from an immediate threat of serious
        harm.
      </Text>
      <Divider clear />

      <Text type="bold">I. On-Line Information</Text>
      <Text>
        In addition to PII identified in Section A of this Policy we may collect non-personal information automatically
        when you enter the GrownBy Platform, such as domain name, browser, your computer operating system, information
        about the Web page from which you linked to the GrownBy Platform, and time spent on different pages within the
        GrownBy Platform. We may use this information to evaluate and improve our site, and may share the information
        with third parties on an aggregated basis to enhance the GrownBy Platform. This on-line information is typically
        collected via cookies. Like virtually all other web sites that you visit, we use cookies to deliver web content
        specific to your interests. The cookie permits the server to recall information from your previous visit. A
        cookie is not used to access or otherwise compromise the data on your hard drive. With most browsers, you can
        choose to change your browser settings to disable cookies. Please be aware that cookies may be required to
        complete certain functions on the GrownBy Platform and other websites you may link to from the GrownBy Platform.
      </Text>
      <Divider clear />

      <Text type="bold">J. Security Measures</Text>
      <Text>
        GrownBy or our third party hosting provider uses security techniques designed to protect your information from
        unauthorized access, including firewalls and access control procedures. We or our hosting provider have security
        measures in place to protect against the loss, misuse and alteration of information under our control, or
        information that is processed by the GrownBy Platform.
      </Text>
      <Text>
        All GrownBy employees are bound by non-disclosure agreements designed to prevent them from disclosing any PII.
        Further, GrownBy’s employee guidelines state that GrownBy employees must abide by all state and federal laws and
        regulations in the performance of their job duties. Our policies also limit access to PII to only those
        employees, contractors, agents or representatives that require the information to perform their jobs or assist
        GrownBy with providing the GrownBy Platform to Licensees.
      </Text>
      <Divider clear />

      <Text type="bold">K. Links</Text>
      <Text>
        The GrownBy Platform may facilitate links to our Payment Processor and/or other web sites outside of GrownBy.
        GrownBy is not responsible for the content or privacy policies of our Sellers, Payment Processor and/or other
        non-GrownBy websites. You should keep this in mind when accessing sites through links. Further, GrownBy may
        advertise on other websites. The advertising companies placing our ads may use cookies or web beacons to track
        the effectiveness of our ads. The use of such technology is subject to the privacy policies of the advertising
        companies, and not this Policy.
      </Text>
      <Divider clear />

      <Text type="bold">L. Policy Updates</Text>
      <Text>
        We may update this Privacy Policy at any time in connection with our ongoing efforts to improve the GrownBy
        Platform. We encourage you to periodically review this Policy. We reserve the right to expand our capabilities
        for information collection and use and change this Policy in this regard. If any change constitutes a material
        change to this Policy, we will post a notice on our the GrownBy Platform in a prominent position. If GrownBy
        decides to use PII in a manner that is materially different from what is contained herein, we will post the
        change on this page for 30 days before making the change.
      </Text>
      <Divider clear />

      <Text type="bold">M. Security Breaches</Text>
      <Text>
        While our goal is to prevent any unauthorized disclosure of PII, GrownBy cannot guarantee that an unauthorized
        disclosure will not occur. We will make reasonable efforts to contact you if we determine that security breach
        has occurred, and that there is a reasonable risk of identity theft or as otherwise required by law, as well as
        use reasonable efforts to remediate the event, all in accordance with applicable law.
      </Text>
      <Divider clear />

      <Text type="bold">N. Contact Us</Text>
      <Text>
        If you have questions, concerns, or complaints about this Policy or GrownBy’s privacy practices, please contact
        a GrownBy customer care representative at info@farmgenerations.coop; or write to us at Farm Generations, 1830
        Route 9 Germantown, NY 12526, ATTN: Data Privacy Officer. We will respond to your inquiries in a timely manner.
      </Text>
      <Divider bottom={bottom} clear />
    </ScrollView>
  )
}
