import { Alert } from '@elements'
import * as Updates from 'expo-updates'
import { useEffect } from 'react'
import { AppState } from 'react-native'

import { Logger } from '@/config/logger'
import { isWeb } from '@/constants/Layout'

export const useAutomaticUpdates = () => {
  /** Checks for automatic updates available on mobile, fetches update and reloads the app with new version */
  useEffect(() => {
    // This should only be run on mobile and not in development mode (Expo Dev Client)
    if (isWeb || __DEV__) return

    const subscription = AppState.addEventListener('change', (state) => {
      if (state === 'active') {
        Updates.checkForUpdateAsync().then((update) => {
          if (update.isAvailable) {
            Updates.fetchUpdateAsync().then(() => {
              Alert(
                'Update Available',
                'There is a new automatic update available for GrownBy. Press "Update" to restart your app with the newest version.',
                [
                  {
                    text: 'Update',
                    onPress: () => Updates.reloadAsync(),
                  },
                ],
              )
            })
          }
        })
      }
    })

    if (Updates.isEmergencyLaunch) {
      // Not a big concern right now as all our breaking changes are released as binary updates
      // But if we release a breaking change we should block the app on this case where expo was unable to launch the newest version of the app
      Logger.error('Emergency Launch', 'The newest update was not installed for the user')
    }

    return () => {
      subscription.remove()
    }
  }, [])
}
