import React, { ReactNode } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { isTablet } from '@/constants/Layout'

type SectionContainerProps = {
  /** The content to be displayed within the section. */
  children: ReactNode

  /** A flag indicating whether a divider should be rendered at the bottom of the section. Defaults to `false`. */
  divider?: boolean

  /** Optional style object to apply custom styling to the inner container. */
  innerContainerStyle?: StyleProp<ViewStyle>

  /** Optional style object to apply custom styling to the main container. */
  outerContainerStyle?: StyleProp<ViewStyle>
}

/**
 * `SectionContainer` is a reusable component designed to provide consistent section styling across the Farmer Onboard views.
 * It accepts children components, optional divider, and allows for style customization through `innerContainerStyle` and `mainContainerStyle` props.
 * The component uses responsive styles to adapt to different device sizes and orientations, ensuring a uniform appearance across various screens.
 */
export function SectionContainer({
  children,
  divider = false,
  innerContainerStyle,
  outerContainerStyle,
}: SectionContainerProps) {
  const styles = useSectionContainerStyles(divider)

  return (
    <View style={[styles.outerContainer, outerContainerStyle]}>
      <View style={[styles.innerContainer, innerContainerStyle]}>{children}</View>
    </View>
  )
}

const useSectionContainerStyles = (divider: boolean) =>
  useSizeFnStyles(({ isLargeDevice }) => ({
    outerContainer: {
      alignItems: 'center',
      borderBottomWidth: divider ? 1 : 0,
      borderBottomColor: 'black',
      marginVertical: 20,
    },
    innerContainer: {
      width: isTablet() ? '100%' : isLargeDevice ? '75%' : '92.5%',
      flexDirection: isLargeDevice ? 'row' : 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
  }))
