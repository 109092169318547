import { Text, typography } from '@elements'
import { openUrl } from '@helpers/client'
import { formatMoney, formatPickupDate, formatShortPickupDate, getOrderNum } from '@helpers/display'
import { Invoice, InvoiceStatus } from '@models/Invoice'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { memo, useCallback } from 'react'
import { useWindowDimensions, View, TouchableOpacity } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import { Icon } from '../../components/elements/Icon/Icon'
import Colors from '../../constants/Colors'
import { isSmallDevice, isWeb } from '../../constants/Layout'
import { globalStyles } from '../../constants/Styles'

type InvoicePropTypes = {
  invoice: Invoice
  highlighted: boolean
}

export const InvoiceCard = memo(function InvoiceCard({ invoice, highlighted }: InvoicePropTypes) {
  const layout = useWindowDimensions()
  const navigation = useNavigation<StackNavigationProp<any>>()

  const styles = useStyles()

  const onPress = useCallback(() => {
    const toShow = invoice.pdf ? 'pdf' : 'payUrl'
    let url = invoice[toShow]
    if (!url) return
    if (toShow === 'pdf') url = url.replace('/pdf', '')
    if (isWeb) return openUrl(url)
    navigation.navigate('ViewInvoice', {
      url,
      title: `${toShow === 'pdf' ? 'Download' : 'Pay'} Your Invoice`,
    })
  }, [invoice, navigation])

  const viewInvoice = useCallback(() => {
    navigation.navigate('InvoiceConsumer', {
      invoiceId: invoice.id,
    })
  }, [invoice.id, navigation])

  return (
    <View style={[styles.card, highlighted && styles.cardHighlighted]}>
      <Text style={styles.text}>
        {isSmallDevice(layout) ? formatShortPickupDate(invoice.dueDate) : formatPickupDate(invoice.dueDate)}
      </Text>
      <Text style={styles.text}>{getOrderNum(invoice.invoiceNum)}</Text>
      <Text style={styles.text}>{formatMoney(invoice.amountTotal)}</Text>
      <Text style={styles.text}>{invoice.status}</Text>
      {invoice.pdf || invoice.payUrl ? (
        <TouchableOpacity style={globalStyles.flex05} onPress={onPress}>
          {isSmallDevice(layout) ? (
            <Icon name="link" />
          ) : (
            <Text type="bold" style={styles.action}>
              {invoice.status === InvoiceStatus.Due ? 'Pay Now' : 'Receipt'}
            </Text>
          )}
        </TouchableOpacity>
      ) : (
        <TouchableOpacity style={globalStyles.flex05} onPress={viewInvoice}>
          {isSmallDevice(layout) ? (
            <Icon name="link" />
          ) : (
            <Text type="bold" style={styles.action}>
              View
            </Text>
          )}
        </TouchableOpacity>
      )}
    </View>
  )
})

const useStyles = CreateResponsiveStyle(
  {
    card: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginVertical: 16,
      marginHorizontal: 10,
      borderRadius: 10,
      borderWidth: 1,
      borderColor: Colors.shades['100'],
      backgroundColor: Colors.white,
      padding: 50,
    },
    cardHighlighted: {
      borderWidth: 2,
      borderColor: Colors.green,
    },
    text: {
      fontFamily: typography.body.regular,
      flex: 1,
      marginHorizontal: 5,
      fontSize: 16,
    },
    action: {
      color: Colors.green,
      fontSize: 16,
    },
  },
  {
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      card: {
        padding: 10,
        marginVertical: 12,
      },

      text: {
        fontSize: 13,
      },
      action: {
        fontSize: 13,
      },
    },
  },
)
