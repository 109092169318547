import { AppStatus, isValidAppStatus } from '@models/AppStatus'
import { collection, doc, getDoc, onSnapshot, Unsubscribe } from 'firebase/firestore'

import { errorCatcher } from './Errors'
import { db } from './db'

// InvalidFormat identifies a malformed version document.

export class InvalidFormat extends Error {}

// apiVersion returns the current version of the API as reported by the database. To remain backwards compatible,
// version 0.0.0 will be returned if no version has been assigned in the database.

export async function apiVersion(): Promise<string> {
  try {
    const appDoc = await getDoc(doc(collection(db(), 'app_constants'), 'version'))
    if (appDoc.exists()) {
      const data = appDoc.data()
      if (typeof data?.version !== 'string') {
        throw new InvalidFormat('API version unable to be determined')
      }
      return data.version
    }
    return '0.0.0'
  } catch (error) {
    if (error instanceof InvalidFormat) {
      throw new InvalidFormat('API version unable to be determined')
    } else {
      return '0.0.0'
    }
  }
}

/** apiVersionSnapshot returns the current version of the API as reported by the database.
 * This will be updated every time there is a change to the version
 */
export function apiVersionSnapshot(cb: (version: string) => void): Unsubscribe {
  const snapDoc = doc(collection(db(), 'app_constants'), 'version')

  return onSnapshot(snapDoc, (snapshot) => {
    if (snapshot.exists()) {
      const data = snapshot.data()
      if (typeof data?.version !== 'string') {
        throw new InvalidFormat('API version unable to be determined')
      }
      cb(data.version)
    } else {
      cb('0.0.0')
    }
  })
}

// apiStatus returns an APIStatus with the values stored in Firestore.

export function apiStatus(updateFn: (status: AppStatus) => void, errorFn = errorCatcher): () => void {
  return onSnapshot(
    doc(collection(db(), 'app_constants'), 'status'),
    (snapshot) => {
      const status = snapshot.get('current')
      if (!isValidAppStatus(status)) {
        updateFn('enabled')
        return
      }
      updateFn(status)
    },
    (err) => errorFn(err),
  )
}
