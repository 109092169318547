import { ToolTips } from '@components'
import { Text, Tooltip } from '@elements'
import { dequal } from '@helpers/customDequal'
import { memo } from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'

type Props = {
  label: string
  tooltipTitle?: string
  tooltipId?: ToolTips
  required?: boolean
  style?: ViewStyle
}
const InputLabel = memo(
  function InputLabel({ label, tooltipId, tooltipTitle, required, style }: Props) {
    return (
      <View style={[styles.labelContainer, style]}>
        <Text numberOfLines={1} style={styles.label}>
          {label}
          {required && '*'}
        </Text>
        {!!tooltipId && <Tooltip style={styles.tooltip} title={tooltipTitle ?? label} id={tooltipId} />}
      </View>
    )
  },
  (prev, next) => dequal(prev, next),
)
export default InputLabel

const styles = StyleSheet.create({
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 10,
  },
  label: {
    fontSize: 16,
  },
  tooltip: {
    marginLeft: 5,
  },
})
