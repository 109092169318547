import * as Application from 'expo-application'
import Constants from 'expo-constants'
import { FirebaseOptions } from 'firebase/app'

import { AppMode } from '@models/Global'
import { ClientEnv } from '../constants/ClientEnvType'
import { getBaseUrl } from '@shared/BaseUrl'

/**
 * Environment variables defined in app.config.extra, plus NODE_ENV.
 *
 * `NODE_ENV` is the only variable provided by default in react-native as `process.env.NODE_ENV`, and can't be managed by us, so it's only useful for the `isJest` constant. Nevertheless it is included here for completeness.
 * This should be the central point for accessing the environment in the frontend. (This 'env' is client only)
 */
export const env = {
  ...(Constants.expoConfig?.extra ?? {}),
  NODE_ENV: process.env.NODE_ENV,
} as ClientEnv

export const isEmul = env.IS_EMULATOR === true

export const releaseChannel = env.APP_ENV

/** nativeAppVersion is the version installed through the app store and tracks any native/ dependency changes.
 *  For web the native version is the most recent update(expoConfig.version) as all its changes are pushed OTA.
 */
export const nativeAppVersion = Application.nativeApplicationVersion || Constants.expoConfig?.version || '0'

/** updatesAppVersion is the version of the app installed through EAS updates. This tracks all JS code changes. */
export const updatesAppVersion = Constants.expoConfig?.version || '0'

/** Options for the app's firebase client initialization, which are obbtained from the current environment */
export function getRunConfiguration(): FirebaseOptions {
  return {
    apiKey: env.API_KEY,
    authDomain: env.AUTH_DOMAIN,
    databaseURL: env.DATABASE_URL,
    projectId: env.PROJECT_ID,
    storageBucket: env.STORAGE_BUCKET,
    messagingSenderId: env.MESSAGING_SENDER_ID,
    appId: env.APP_ID,
  }
}

/**
 * The base url for the app environment, will use localhost for dev
 * @param useWholesale Whether to use the wholesale URL or not. If not passed then we must rely on the environment variable. This is useful when we may be somewhere that can't determine the wholesale state from the code.
 */
export const grownbyWebsiteBaseUrl = (useWholesale: boolean | undefined) => {
  // We use the localhost URL for development mode, otherwise we use the release channel
  const channel = __DEV__ ? 'local' : releaseChannel
  const isWholesale = useWholesale ?? env.IS_WHOLESALE

  return getBaseUrl(channel, { isWholesale })
}
/** The base url for the app environment, using all deployed versions and never localhost. It is also always retail as it is built for places that need a redirect not a normal app link. Otherwise grownbyWebsiteBaseUrl would be used */
export const grownbyRetailWebsiteLiveUrl = () => getBaseUrl(releaseChannel, { isWholesale: false })

export default env

/** Determines the current app mode of the running client and returns it */
export function getAppModeFromEnv(): AppMode {
  return env.IS_WHOLESALE === true ? AppMode.Wholesale : AppMode.Retail
}
