import { DatePeriods, fromJSDate } from '@helpers/time'
import { differenceInDays, endOfDay, endOfToday, startOfToday, startOfWeek, startOfYesterday, sub } from 'date-fns'

export type DateType = 'DAY' | 'WEEK' | 'MONTH' | 'YEAR'
const today = startOfToday()

export interface DateValue {
  startDate: Date
  endDate: Date
  dateTrunc?: DateType
  pastDateInterval?: number
  pastStartDate?: ReturnType<typeof getPreviousDate>
}

export const convertToBigQueryDateFormat = (date: Date, zone?: string) => {
  return fromJSDate(date, zone).toISO().slice(0, 10).split('-').join('')
}

export const getPreviousDate = (startDate: Date, endDate: Date) => {
  const diffDays = Math.abs(differenceInDays(startDate, endDate))
  const pastDate = sub(startDate, {
    days: diffDays,
  })
  return { pastStartDate: pastDate, dayDifference: diffDays }
}

export const getTodayDate = (): DateValue => {
  const endDate = endOfDay(today)
  return {
    startDate: today,
    endDate,
    dateTrunc: 'DAY' as DateType,
    pastDateInterval: 1,
  }
}

export const getYesterdayDate = (): DateValue => {
  const startDate = startOfYesterday()
  const endDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 23, 59, 59)

  return {
    startDate,
    endDate,
    dateTrunc: 'DAY' as DateType,
    pastDateInterval: 1,
  }
}

export const getLast7DaysDate = (): DateValue => {
  const startDate = new Date(today)
  startDate.setDate(startDate.getDate() - 7)
  const endDate = endOfDay(today)

  return {
    startDate,
    endDate,
    dateTrunc: 'DAY' as DateType,
    pastDateInterval: 7,
  }
}

export const getLast30DaysDate = (): DateValue => {
  const startDate = new Date(today)
  startDate.setDate(startDate.getDate() - 30)
  const endDate = endOfDay(today)

  return {
    startDate,
    endDate,
    dateTrunc: 'DAY' as DateType,
    pastDateInterval: 30,
  }
}

export const getYearToDate = (): DateValue => {
  const startDate = new Date(today.getFullYear(), 0, 0, 0, 0, 0)
  const endDate = endOfDay(today)

  return {
    startDate,
    endDate,
    dateTrunc: 'MONTH' as DateType,
    pastDateInterval: 12,
  }
}

export const getMonthToDate = (): DateValue => {
  const startDate = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0)
  const endDate = endOfDay(today)
  return {
    startDate,
    endDate,
    dateTrunc: 'DAY' as DateType,
    pastDateInterval: 30,
  }
}
export const getLast3MonthsDate = (): DateValue => {
  const startDate = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate(), 0, 0, 0)
  const endDate = endOfDay(today)

  return {
    startDate,
    endDate,
    dateTrunc: 'MONTH' as DateType,
    pastDateInterval: 90,
  }
}
export const getLast6MonthsDate = (): DateValue => {
  const startDate = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate(), 0, 0, 0)
  const endDate = endOfDay(today)

  return {
    startDate,
    endDate,
    dateTrunc: 'MONTH' as DateType,
    pastDateInterval: 6,
  }
}
export const getLastYearDate = (): DateValue => {
  const startDate = new Date(today.getFullYear() - 1, 0, 1, 0, 0, 0)

  const endDate = new Date(today.getFullYear(), 0, 0, 0, 0, 0)

  return {
    startDate,
    endDate,
    dateTrunc: 'MONTH' as DateType,
    pastDateInterval: 12,
  }
}
export const getThisWeekDate = (): DateValue => {
  const startDate = startOfWeek(today)
  const endDate = endOfToday()

  return {
    startDate,
    endDate,
    dateTrunc: 'DAY' as DateType,
    pastDateInterval: 7,
  }
}

export type AnalyticsPeriodInfo = {
  dateTrunc: 'DAY' | 'MONTH'
  pastDateInterval: number
  pastLabel: string
}

export const AnalyticsPeriodInfoRecord: Record<DatePeriods, AnalyticsPeriodInfo> = {
  [DatePeriods.Today]: {
    pastLabel: 'yesterday',
    dateTrunc: 'DAY',
    pastDateInterval: 1,
  },
  [DatePeriods.Yesterday]: {
    pastLabel: 'the day before yesterday',
    dateTrunc: 'DAY',
    pastDateInterval: 1,
  },
  [DatePeriods.This_Week]: {
    pastLabel: 'last week',
    dateTrunc: 'DAY',
    pastDateInterval: 7,
  },
  [DatePeriods.Last_7_days]: {
    pastLabel: 'the previous 7 days',
    dateTrunc: 'DAY',
    pastDateInterval: 7,
  },
  [DatePeriods.This_Month]: {
    pastLabel: 'last month',
    dateTrunc: 'DAY',
    pastDateInterval: 30,
  },
  [DatePeriods.Last_30_days]: {
    pastLabel: 'the previous 30 days',
    dateTrunc: 'DAY',
    pastDateInterval: 30,
  },
  [DatePeriods.Last_3_Months]: {
    pastLabel: 'the previous 3 months',
    dateTrunc: 'MONTH',
    pastDateInterval: 3,
  },
  [DatePeriods.Last_6_Months]: {
    pastLabel: 'the previous 6 months',
    dateTrunc: 'MONTH',
    pastDateInterval: 6,
  },
  [DatePeriods.This_Year]: {
    pastLabel: 'last year',
    dateTrunc: 'MONTH',
    pastDateInterval: 12,
  },
  [DatePeriods.Last_Year]: {
    pastLabel: 'the year before last year',
    dateTrunc: 'MONTH',
    pastDateInterval: 12,
  },
  [DatePeriods.Custom]: {
    pastLabel: '',
    dateTrunc: 'MONTH',
    pastDateInterval: 12,
  },
}
