import { Address } from '@models/Address'
import { Coordinate } from '@models/Coordinate'
import { Timezone } from '@models/Timezone'
import { DateTime } from 'luxon'
// @ts-ignore
import zipcode_to_timezone from 'zipcode-to-timezone'

import env from '../../config/Environment'

type TimezoneAPI = {
  dstOffset: number
  rawOffset: number
  status: 'OK' | 'INVALID_REQUEST'
  timeZoneId: string
  timeZoneName: string
}

async function getGoogleTimezone(coordinate: Coordinate) {
  const params = `location=${coordinate.latitude},${coordinate.longitude}&timestamp=${Math.round(Date.now() / 1000)}`
  const url = `https://maps.googleapis.com/maps/api/timezone/json?${params}&key=${env.API_KEY}`
  const response = await fetch(url)
  const results: TimezoneAPI = await response.json()

  if (results.status !== 'OK' || !results.timeZoneId) throw new Error(results.status)
  return results.timeZoneId
}

// Timezone mapping from zipcode is complex and has inconsistencies across all implementations, so it is hard to find
// an exact map from zipcode to timezone, see more here https://stackoverflow.com/tags/timezone/info
export default async function getTimeZone(address: Address): Promise<Timezone> {
  const tz = zipcode_to_timezone.lookup(address.zipcode.split('-')[0])

  // First get timezone from local zipcode map
  if (tz) return tz

  // If we can't find it request google for timezone with coordinates
  try {
    return await getGoogleTimezone(address.coordinate)
  } catch (e) {
    // If it still cannot be determined it will get the user's current timezone, (should we also flag this so that we can verify later)
    return DateTime.local().zoneName
  }
}
