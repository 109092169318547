import { Farm } from '@models/Farm'
import { FarmAssociation } from '@models/User'
import { StyleSheet, Text, View } from 'react-native'
import { useSelector } from 'react-redux'

import { FarmCard } from '../components/FarmCard'
import { MessageWithIcon } from '../components/LoaderWithMessage'
import { ResponsiveList } from '../components/ResponsiveList'
import { withAuth } from '../hooks/withAuth'
import { RootState } from '../redux/reducers/types'
import { farmsSelector } from '../redux/selectors'

function MyFarmsScreen() {
  const farms = useSelector<RootState, FarmAssociation[]>(farmsSelector)
  const favorites = farms.filter((f) => f.isFavorite).map((f) => f.farm!)

  return (
    <View style={styles.container}>
      {favorites.length ? (
        <ResponsiveList data={favorites} renderItem={(farm: Farm) => <FarmCard farm={farm} />} />
      ) : (
        <MessageWithIcon icon="heart" title="No Favorites!">
          <Text>You have no favorite farms, search for a farm to add it here.</Text>
        </MessageWithIcon>
      )}
    </View>
  )
}

export default withAuth(MyFarmsScreen, { noSafeAreaInsets: true })

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
