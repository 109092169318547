import { isImmutable, Primitive } from '@helpers/typescript'
import { useMemo } from 'react'
import { StyleProp, StyleSheet } from 'react-native'

import { objToStr } from '@helpers/log'
import { useDeepCompareMemo } from './useDeepEqualEffect'
import { useDeviceSize, useLayout, UseLayoutReturn } from './useLayout'
import { Style } from './useMergeStyle'

export type UseFnStylesHandler<NamedStyles extends StyleSheet.NamedStyles<any>, ExtraArgs extends any[]> = (
  ...extraArgs: ExtraArgs
) => NamedStyles

/**
 * Allows you to create a stylesheet from a function that returns the styles based on some variable arguments.
 *
 * - This is the most lightweight variant of the "fn styles" hooks because it doesn't depend on screen size. So use this when styles are dependent only on outer variables.
 *
 * @param styleHandler the function that returns the styles object
 * @param extraArgs any primitives that are needed to generate styles
 */
export function useFnStyles<NamedStyles extends StyleSheet.NamedStyles<any>, ExtraArgs extends Primitive[]>(
  styleHandler: UseFnStylesHandler<NamedStyles, ExtraArgs>,
  ...extraArgs: ExtraArgs
) {
  return useMemo(
    () => StyleSheet.create(styleHandler(...extraArgs)),
    // fn changes are intentionally ignored.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...extraArgs],
  )
}

/** StyleSheet wrapper which allows you to generate a stylesheet based on extra arguments, which may be Primitives or simple objects.
 *
 * - You pass the extra arguments after the handler. Then they are available to the handler, and you can use them to generate your styles
 * - Will re-create styles whenever your extraArgs have a stringified inequality.
 * - For primitives, this works the same as a regular memo. For objects, it converts the object to a string to check for inequality. This should have less performance impact than deep equality check.
 */
export function useStringCompareFnStyles<
  NamedStyles extends StyleSheet.NamedStyles<any>,
  ExtraArgs extends (Primitive | Primitive[] | Record<any, Primitive> | Style | StyleProp<Style>)[],
>(styleHandler: UseFnStylesHandler<NamedStyles, ExtraArgs>, ...extraArgs: ExtraArgs) {
  return useMemo(() => {
    return StyleSheet.create(styleHandler(...extraArgs))
    // This coerces arguments to primitives before passing them to the deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...extraArgs.map((arg) => (isImmutable(arg) ? arg : objToStr(arg)))])
}

export type UseLayoutFnStylesHandler<NamedStyles extends StyleSheet.NamedStyles<any>, ExtraArgs extends any[]> = (
  layout: UseLayoutReturn,
  ...extraArgs: ExtraArgs
) => NamedStyles

/** StyleSheet wrapper which allows you to generate a stylesheet based on layout (default) and optionally any other extra arguments.
 * - You pass the extra arguments after the handler. Then they are available to the handler, and you can use them to generate your styles
 * - Will re-render styles whenever layout or your extraArgs have a deep-inequality change.
 * - Deep equality comparison has a performance impact.
 */
export function useDeepCompareLayoutFnStyles<NamedStyles extends StyleSheet.NamedStyles<any>, ExtraArgs extends any[]>(
  styleHandler: UseLayoutFnStylesHandler<NamedStyles, ExtraArgs>,
  ...extraArgs: ExtraArgs
) {
  const layout = useLayout()

  return useDeepCompareMemo(() => {
    return StyleSheet.create(styleHandler(layout, ...extraArgs))
    // fn changes are intentionally ignored.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout, ...extraArgs])
}

/** StyleSheet wrapper which allows you to generate a memoized stylesheet based on layout (default) and optionally any other extra arguments.
 * - You pass the extra arguments after the handler. Then they are available to the handler, and you can use them to generate your styles
 * - This uses referential inequality to re-generate styles, so it's lighter than the deepCompare counterpart
 */
export function useLayoutFnStyles<NamedStyles extends StyleSheet.NamedStyles<any>, ExtraArgs extends Primitive[]>(
  styleHandler: UseLayoutFnStylesHandler<NamedStyles, ExtraArgs>,
  ...extraArgs: ExtraArgs
) {
  const layout = useLayout()

  return useMemo(() => {
    return StyleSheet.create(styleHandler(layout, ...extraArgs))
    // fn changes are intentionally ignored.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout, ...extraArgs])
}

export type UseSizerStylesHandler<NamedStyles extends StyleSheet.NamedStyles<any>, ExtraArgs extends any[]> = (
  sizers: ReturnType<typeof useDeviceSize>,
  ...extraArgs: ExtraArgs
) => NamedStyles

/** Generates a memoized stylesheet from a function of device sizers.
 *
 * - Extra arguments can be provided, which will also be passed to the handler.
 * - Extra arguments should be used sparingly, because they will recreate the stylesheet.
 * - This is more efficient than useLayout because the sizers should only update when the device size actually changes from one discrete class to another. I.e. It shouldn't re-render continuously with every single window resize movement.
 */
export function useSizeFnStyles<NamedStyles extends StyleSheet.NamedStyles<any>, ExtraArgs extends Primitive[]>(
  styleHandler: UseSizerStylesHandler<NamedStyles, ExtraArgs>,
  ...extraArgs: ExtraArgs
) {
  const sizers = useDeviceSize()

  return useMemo(() => {
    return StyleSheet.create(styleHandler(sizers, ...extraArgs))
    // fn changes are intentionally ignored.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizers, ...extraArgs])
}
