import { Logger } from '@/config/logger'
import { Alert, Icon, TextH4, typography } from '@elements'
import { FontAwesome5 } from '@expo/vector-icons'
import { errorToString } from '@helpers/helpers'
import * as ImageManipulator from 'expo-image-manipulator'
import { Action, SaveFormat } from 'expo-image-manipulator'
import * as ImagePicker from 'expo-image-picker'
import { useEffect, useState } from 'react'
import { ImageStyle, Platform, StyleSheet, TouchableOpacity } from 'react-native'

import Colors from '../constants/Colors'
import { useLayout } from '../hooks/useLayout'
import { Image as ImageElement, ResizedSuffix } from './Image'
import { ImageDropzone } from './ImageDropzone'

type Props = {
  imageType?: 'profile' | 'farm' | 'product' | 'logo'
  defaultImage?: string
  onChange(media: string, type?: 'video' | 'image'): void
  imageStyle?: ImageStyle
  editOptions?: ImageOptions
  // If multiple is true then we don't want to show it in place because that will be handled outside
  multiple?: boolean
  // TODO: Video is currently broken on web, needs a deeper look before being enabled
  // allowVideo?: boolean
}

type ImageOptions = {
  quality?: number
  format?: SaveFormat
  /**
   * Values correspond to the result image dimensions. If you specify only one value, the other will
   * be calculated automatically to preserve image ratio. Specifying both with compress the image to fit.
   */
  width?: number
  height?: number
  // If true will force the image into the exact width and height specified above
  forceAspect?: boolean
}

const getPermissionAsync = async () => {
  if (Platform.OS !== 'web') {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
    if (status !== 'granted') {
      return Logger.debug('Permission to access location was denied')
    }
  }
}
// Will return the image size in MB from the base64 uri
const getImageSizeMb = (uri: string) => {
  const stringLength = uri.length - 'data:image/png;base64,'.length
  // Gets the file size from base64 https://stackoverflow.com/a/49750491
  const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812
  return sizeInBytes / 1000000
}

// Will get a list of edit actions that should be taken for the image
function getEditAction(editOptions: ImageOptions, pickerRes: ImagePicker.ImageInfo): Action[] {
  // If we don't pass in a new width or height then there should be no resize
  if (!editOptions.width && !editOptions?.height) return []

  // If we choose to force aspect ratio then no matter what we will use the specified width and height provided
  if (editOptions?.forceAspect) return [{ resize: { width: editOptions.width, height: editOptions.height } }]

  // If either with or height is specified and is smaller than the original then resize
  if (
    (editOptions.width && pickerRes.width > editOptions.width) ||
    (editOptions.height && pickerRes.height > editOptions.height)
  )
    return [{ resize: { width: editOptions.width, height: editOptions.height } }]

  // No resize if not
  return []
}

const addImage = async (editOptions?: ImageOptions, allowVideo = false) => {
  await getPermissionAsync()
  const pickerRes = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions[allowVideo ? 'All' : 'Images'],
    quality: 1,
  })
  if (pickerRes.canceled) return
  return processNewImage(pickerRes.assets[0], editOptions)
}

async function processNewImage(image: ImagePicker.ImagePickerAsset, editOptions: ImageOptions | undefined) {
  if (Platform.OS === 'web') {
    if (image.uri.startsWith('data:image/gif')) {
      throw new Error('GIFs are not supported.')
    }
  } else {
    if (image.uri.endsWith('.gif')) {
      throw new Error('GIFs are not supported.')
    }
  }

  let result = image as ImageManipulator.ImageResult
  // Will use fileSize on iOS and Android, but on web we need to calculate it from the base64 uri
  const originalImageSizeMb = image.fileSize ? image.fileSize / 1000000 : getImageSizeMb(result.uri)

  // If the image is larger than 5mb attempt to compress it if we don't have a compression already specified
  if (originalImageSizeMb > 2) {
    const compressAmount = originalImageSizeMb > 5 ? 0.6 : 0.8
    editOptions = {
      ...editOptions,
      // We still allow overriding quality but this will be a default for large images
      quality: editOptions?.quality ?? compressAmount,
    }
  }

  if (editOptions) {
    const actions: Action[] = getEditAction(editOptions, image)

    // resize or compress the image
    result = await ImageManipulator.manipulateAsync(image.uri, actions, {
      compress: editOptions?.quality || 1,
      format: editOptions?.format,
      base64: true,
    })
  }

  // Make sure the size is less than 2MB, we use the base64 if the image was manipulated, and if not use the original file size
  const imageSizeMb = result.base64 ? getImageSizeMb(result.base64) : originalImageSizeMb

  if (imageSizeMb > 2) {
    throw new Error(`Image is too large, try compressing or resizing it. Recommended size is under 2mb`)
  }

  return { type: 'image', uri: result.uri }
}

export default function ImageSelect({ imageType, onChange, imageStyle, defaultImage, editOptions, multiple }: Props) {
  const layout = useLayout()
  const [image, setImage] = useState<string | undefined>(defaultImage)

  useEffect(() => {
    setImage(defaultImage)
  }, [defaultImage])

  const addImageHandler = async () => {
    try {
      const res = await addImage(editOptions)
      // If the user cancelled ignore it
      if (!res) return

      // If we only allow one image then we want to set it
      if (!multiple) setImage(res.uri)

      // Return the image to the caller
      onChange(res.uri, 'image')
    } catch (err) {
      // If there was an issue uploading the issue notify the user
      Alert('Unable to add image', errorToString(err))
    }
  }

  const onImageAddedDropzone = async (image: ImagePicker.ImagePickerAsset) => {
    try {
      const res = await processNewImage(image, editOptions)

      // Return the image to the caller
      onChange(res.uri, 'image')
    } catch (err) {
      // If there was an issue uploading the issue notify the user
      Alert('Unable to add image', errorToString(err))
    }
  }

  if (image) {
    return (
      <TouchableOpacity style={styles.wrapper} onPress={addImageHandler}>
        <Icon style={styles.editIcon} name="camera" size={15} />
        <ImageElement
          type={imageType}
          style={[styles.image, imageStyle]}
          source={{ uri: image }}
          resizeSuffix={ResizedSuffix.NONE}
        />
      </TouchableOpacity>
    )
  }

  return (
    <ImageDropzone onImageAdded={onImageAddedDropzone}>
      <TouchableOpacity style={styles.wrapper} onPress={addImageHandler}>
        <FontAwesome5 size={23} name="camera" color={Colors.shades[200]} />
        <TextH4 color={Colors.shades[200]} style={styles.text} size={layout.isLargeDevice ? 10 : 8}>
          Add a Photo
        </TextH4>
      </TouchableOpacity>
    </ImageDropzone>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  text: {
    fontFamily: typography.body.regular,
    fontWeight: '400',
  },
  image: {
    width: '100%',
    height: '100%',
  },

  editIcon: {
    position: 'absolute',
    zIndex: 2,
    bottom: 6,
    left: 6,
  },
  imagePlaceholder: {
    backgroundColor: Colors.shades['100'],
    alignItems: 'center',
    justifyContent: 'center',
  },
  productPlaceholder: {
    backgroundColor: Colors.transparent,
    borderRadius: 15,
    borderWidth: 1,
    borderColor: Colors.lightGray,
    alignItems: 'center',
    justifyContent: 'center',
  },
})
