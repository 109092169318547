import { ShortState } from '@/assets/data/states'
import { usePropRefinement } from '@/hooks/useAlgoliaPropRefinement/useAlgoliaPropRefinement'
import { useRangeRefinement } from '@/hooks/useAlgoliaRangeRefinement'
import { useAlgoliaState } from '@/hooks/useAlgoliaState'
import { useTextRefinement } from '@/hooks/useAlgoliaTextRefinement'
import { useDeepCompareMemo } from '@/hooks/useDeepEqualEffect'
import { useSearchScreenConfigure } from '@/hooks/useSearchScreenConfigure/useSearchScreenConfigure'
import { sortAlgoliaProds } from '@helpers/sorting'
import { ArrElement } from '@helpers/typescript'
import { ShortZip } from '@models/Address'
import { AlgoliaGeoDoc, AlgoliaGeoProduct } from '@models/Algolia'
import { Coordinate } from '@models/Coordinate'
import { Farm } from '@models/Farm'

import { Product } from '@models/Product'
import { useState } from 'react'
import { useHits } from 'react-instantsearch'
import { LocType } from '../searchScreen-helpers'

/** These are meant to be the navigation params after being parsed and validated */
export type useAlgoliaSearchDataOpts = {
  /** A product category to apply as filter */
  categoryId?: Product['category']

  /** A certification to use as filter */
  practices?: ArrElement<Farm['practices']>
  /** The lowest value desired for the order minimum */
  orderMinLow?: number
  /** The highest value desired for the order minimum */
  orderMinHigh?: number
  /** Text from an input, for string search */
  stringSearchValue?: string
  /** Lowest price for search results */
  priceLow?: number
  /** Highest price for search results */
  priceHigh?: number
  /** Center point for the distance search */
  center?: Coordinate
  /** Search radius around the center, in meters */
  radius?: number
  /** The type of logistics search used */
  locType?: LocType
  /** A region to use as filter for NonPickup locations */
  region?: ShortState | ShortZip
}

/** Data layer for product search in the entire db */
export function useAlgoliaSearchData({
  categoryId,
  practices,
  orderMinLow,
  orderMinHigh,
  stringSearchValue,
  priceHigh,
  priceLow,
  center,
  radius,
  locType,
  region,
}: useAlgoliaSearchDataOpts) {
  const [includeNearbyZipcodes, setIncludeNearbyZipcodes] = useState(false)

  useSearchScreenConfigure(center, radius, locType, region, includeNearbyZipcodes)

  const categoryFilter = usePropRefinement({ attribute: 'category', value: categoryId })
  const practicesFilter = usePropRefinement({ attribute: 'farm.practices', value: practices })
  const orderMinFilter = useRangeRefinement('farm.orderMin', { min: orderMinLow, max: orderMinHigh })
  const priceFilter = useRangeRefinement('priceFilter', { min: priceLow, max: priceHigh })

  const textFilter = useTextRefinement(stringSearchValue)

  const { hits = [] } = useHits<AlgoliaGeoDoc<AlgoliaGeoProduct>>()

  const prods = useDeepCompareMemo(() => {
    return hits.sort(sortAlgoliaProds)
  }, [hits])

  const { loadingSearch, errorMsg } = useAlgoliaState()

  return {
    prods,
    isLoading: loadingSearch,
    errorMsg,
    categoryFilter,
    practicesFilter,
    orderMinFilter,
    priceFilter,
    textFilter,
    includeNearbyZipcodes,
    setIncludeNearbyZipcodes,
  }
}
