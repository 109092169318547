import { RouteProp, useRoute } from '@react-navigation/native'
import { StyleSheet, View } from 'react-native'

import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { useApiFx, useSnapshot } from '@/hooks/useApiFx'
import { useFocusFx } from '@/hooks/useFocusFx'
import { withAuth } from '@/hooks/withAuth'
import { OrdersParamList } from '@/navigation/types'
import { addNavProp } from '@/redux/actions/appState'
import { userSelector } from '@/redux/selectors'
import { snapshotDraftOrder } from '@api/DraftOrders'
import { cartsCollection } from '@api/framework/ClientCollections'
import {
  ConsumerScroll,
  DraftOrderItems,
  DraftOrderSummary,
  MessageWithIcon,
  onSendMessageToFarmCallback,
} from '@components'
import { Divider, HeaderText, LoadingView } from '@elements'
import { nonEmptyString } from '@helpers/helpers'
import { DraftOrderState } from '@models/DraftOrder'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DraftOrderDeclinedCard } from '../../../components/DraftOrder/DraftOrderDeclinedCard'
import { DraftOrderHeader } from './components/DraftOrderHeader'

function DraftOrderDetails() {
  const dispatch = useDispatch()
  const {
    params: { draftOrderId, confirmation },
  } = useRoute<RouteProp<OrdersParamList, 'DraftOrderDetails'>>()
  const user = useSelector(userSelector)

  const {
    data: draftOrder,
    loading: loadingDraftOrder,
    error: errorDraftOrder,
  } = useSnapshot(snapshotDraftOrder, [draftOrderId], nonEmptyString(draftOrderId))
  const {
    data: draftCart,
    loading: loadingDraftCart,
    err: errorDraftCart,
  } = useApiFx(cartsCollection.fetch.bind(cartsCollection), [draftOrder?.cart.id], nonEmptyString(draftOrder?.cart.id))

  useFocusFx(() => {
    if (draftOrder) {
      // Will keep the navprop in sync with draft order changes
      dispatch(addNavProp({ order: draftOrder }))
    }
  }, [dispatch, draftOrder])

  const onPressContactFarm = useCallback(() => {
    if (!draftOrder) return

    onSendMessageToFarmCallback(user, draftOrder.farm)
  }, [draftOrder, user])

  return (
    <ConsumerScroll>
      {confirmation && (
        <View style={styles.headerCard}>
          <HeaderText style={globalStyles.margin10}>
            Success! Thank you for your order! You will be notified when the farmer has accepted this order.
          </HeaderText>
        </View>
      )}
      {nonEmptyString(draftOrderId) ? (
        <LoadingView
          loading={loadingDraftOrder || loadingDraftCart}
          error={errorDraftOrder || errorDraftCart}
          spinnerProps={{ size: 'large' }}
          success={draftOrder && draftCart ? { draftOrder, draftCart } : undefined}
        >
          {({ draftOrder, draftCart }) => (
            <>
              {draftOrder.state === DraftOrderState.Declined && <DraftOrderDeclinedCard draftOrder={draftOrder} />}
              <DraftOrderHeader onPressContactFarm={onPressContactFarm} draftOrder={draftOrder} />
              <DraftOrderSummary draftOrder={draftOrder} draftCart={draftCart} isAdmin={false} />
              <Divider large top={10} bottom={10} clear />
              <DraftOrderItems draftCart={draftCart} />
            </>
          )}
        </LoadingView>
      ) : (
        <MessageWithIcon title="Missing Parameters">No Draft Order ID provided</MessageWithIcon>
      )}
    </ConsumerScroll>
  )
}

export const DraftOrderDetailsScreen = withAuth(DraftOrderDetails)

const styles = StyleSheet.create({
  headerCard: {
    backgroundColor: Colors.secondaryGreen3,
    padding: 10,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: Colors.green,
    borderRadius: 10,
  },
})
