import { CheckBox, Icon, MenuOverlay, Text } from '@elements'
import { useRef, useState } from 'react'
import { ScrollView, StyleProp, StyleSheet, TouchableOpacity, ViewStyle } from 'react-native'

import Colors from '../../constants/Colors'

export type SubFilterButtons = { label: string; state?: boolean; onPress?: () => void; disabled?: boolean }[]

type FilterButtonProps = {
  label: string
  onPress?: () => void
  buttonStyle?: ViewStyle
  selected: boolean
  subFilters?: SubFilterButtons
  filterCount?: number
  /** If not undefined, controls the chevron icon (up or down). If undefined, chevron icon will only be shown if there's subFilters */
  chevron?: boolean
  disabled?: boolean
}

function FilterButton({
  label,
  onPress,
  buttonStyle = {},
  selected,
  subFilters,
  filterCount = 0,
  chevron,
  disabled,
}: FilterButtonProps) {
  const [showDropdown, setShowDropdown] = useState(false)
  const btnRef = useRef<TouchableOpacity>(null)

  const computedButtonStyle: StyleProp<ViewStyle> = [
    styles.filterButtonStyle,
    {
      backgroundColor: selected ? Colors.secondaryGreen3 : Colors.white,
      borderColor: selected ? Colors.green : Colors.semiTransparent,
      paddingVertical: filterCount ? 7 : 10,
    },
    buttonStyle,
  ]

  let chevronIcon: 'chevron-up' | 'chevron-down'
  if (chevron !== undefined) chevronIcon = `chevron-${chevron ? 'up' : 'down'}`
  else chevronIcon = `chevron-${showDropdown ? 'up' : 'down'}`

  return (
    <>
      <TouchableOpacity
        style={computedButtonStyle}
        ref={btnRef}
        onPress={() => {
          if (!disabled) onPress?.()
          if (subFilters) setShowDropdown((prev) => !prev)
        }}
        disabled={disabled}
      >
        <Text color={disabled ? Colors.shades[200] : selected ? Colors.green : undefined}>{label}</Text>
        {filterCount > 0 && (
          <Text color="white" style={styles.filterIndicator}>
            {filterCount}
          </Text>
        )}
        {(subFilters || chevron !== undefined) && (
          <Icon
            name={chevronIcon}
            size={14}
            color={selected ? Colors.green : Colors.shades[300]}
            style={styles.iconStyle}
          />
        )}
      </TouchableOpacity>
      {subFilters && !!subFilters.length && (
        <MenuOverlay
          value="filter-button"
          isVisible={subFilters && !!subFilters.length && showDropdown}
          sourceRef={btnRef}
          onBackdropPressed={() => setShowDropdown(false)}
          otherProps={{ contentContStyle: styles.menuOverlay }}
        >
          <ScrollView>
            {subFilters.map((filter) => {
              return (
                <CheckBox
                  checked={filter.state ?? false}
                  title={filter.label}
                  onChange={filter.onPress}
                  disabled={filter.disabled}
                  key={filter.label}
                />
              )
            })}
          </ScrollView>
        </MenuOverlay>
      )}
    </>
  )
}
const styles = StyleSheet.create({
  filterButtonStyle: {
    flexDirection: 'row',
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.semiTransparent,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
    paddingHorizontal: 16,
    borderRadius: 10,
    margin: 10,
  },
  iconStyle: {
    marginLeft: 10,
  },
  filterIndicator: {
    backgroundColor: Colors.green,
    borderRadius: 4,
    marginLeft: 9,
    padding: 3,
    paddingHorizontal: 8,
  },
  menuOverlay: {
    marginTop: 5,
    maxHeight: 250,
    padding: 10,
  },
})

export default FilterButton
