import { AppPersist } from '@/redux/reducers/types'
import { SearchOptions } from '@algolia/client-search'
import { buildQueryFilter } from '@helpers/algolia-client'
import { getSortAlgoliaProducts } from '@helpers/sorting'
import { AlgoliaGeoDoc, AlgoliaGeoProduct, asFilter, FILTERS_QUERY, QueryFilterArray } from '@models/Algolia'
import { ProductFilters } from '@models/Filters'
import { Location } from '@models/Location'
import { AddonShare, isAddon } from '@models/Product'
import { useMenu } from 'react-instantsearch'

/** The original menu item type from algolia as a direct reference */
export type MenuItem = ReturnType<typeof useMenu>['items'][0]

export const ALL_CATEGORIES = 'All'
export const ALL_TYPES = 'All'

/** Prepares data for the shop sidebar from type refinement options
 * - For the moment will allow only primary and addons
 */

type BuildFilterOpts = {
  farmId: string | undefined
  filterByEbt: boolean
  csaId?: string
} & Pick<AppPersist['wholesale'], 'isWholesale'>

/** Creates algolia filter string, sent as queries to algolia */
export const buildFilters = (props: BuildFilterOpts): SearchOptions['filters'] => {
  const { farmId, filterByEbt, csaId, isWholesale } = props

  // If the necessary data is not ready yet, this will build a query that returns no results
  if (!farmId || typeof isWholesale !== 'boolean') return FILTERS_QUERY.NullDocType

  /** These filters must be in the correct order, otherwise some might not be applied.
   * - You should not apply the hidden filter before the docType filter, since some non-product docTypes don't possess a isHidden field */
  const filters: QueryFilterArray = [
    FILTERS_QUERY.Product,
    asFilter<AlgoliaGeoDoc, 'farm'>(`farm.id:${farmId}`),
    FILTERS_QUERY.NotHidden,
    isWholesale ? FILTERS_QUERY.Wholesale : FILTERS_QUERY.Retail,
  ]
  if (filterByEbt) {
    filters.push(FILTERS_QUERY.Ebt)
  }

  if (csaId) {
    // If a csaId is provided, this assumes we are on csa details, so we filter by the csa id and we allow addons and private prods
    filters.push(`csa:${csaId}`)
  } else {
    /** If we're in screens other than the CSADetails (homescreen, shop, etc), this must exclude private products.
     * Private products are only meant to be shown in the CSADetails screen. Private products, in this sense, means they are BOTH "Only show in CSA" AND are assigned to only private CSA group/s.
     * INFO: Those two criteria are supposed to already be encoded in the isPrivate field, which allows us to easily filter by the two criteria by using this single filter. */
    filters.push(FILTERS_QUERY.NotPrivateProd)
  }

  return buildQueryFilter(filters)
}

/** Creates the location data for the UI, which is a filtered subset of the farm's db locations which includes only those ids returned by the algolia location refinement opts */
export function getLocations(
  dbLocs: Location[] | undefined,
  algoliaLocItems: Pick<MenuItem, 'value'>[],
): { locations: undefined; loadingLocations: boolean } | { locations: Location[]; loadingLocations: boolean } {
  if (!dbLocs) return { locations: undefined, loadingLocations: true }

  const algoliaLocIds = algoliaLocItems.map((loc) => loc.value)
  return {
    locations: dbLocs.filter((loc) =>
      // This should only allow showing the db locations which are present in the algolia location ids
      algoliaLocIds.includes(loc.id),
    ),
    loadingLocations: false,
  }
}

type GetProductsOptions = Pick<ProductFilters, 'csaId' | 'searchTerm'> & {
  hits: AlgoliaGeoDoc<AlgoliaGeoProduct>[]
  /** The ids of those addons available for adding to the cart */
  availAddonsIds: string[]
  isLoading: boolean
  isWholesale: boolean | undefined
}

/** Processes the product data from db and algolia and the scrceen filters, to create the product data for the UI */
export function getProducts(opts: GetProductsOptions): (AlgoliaGeoDoc<AlgoliaGeoProduct> | AddonShare)[] {
  const { hits, csaId, availAddonsIds, isLoading, searchTerm } = opts

  /** When searching by term, we should not apply sorting because the hits should already be sorted by similarity */
  const shouldSort = !searchTerm?.length

  if (isLoading) {
    /**
     * TLDR: Do NOT return addons by themselves.
     *
     * This part is intentionally not returning products until the hits appear. Even if the availAddons are present, we won't show those alone. We will only show them in conjunction with the search hits. Otherwise the screen would show first a small set of results, and a larger set later. The addons integrated here are meant to be part of the search hits, not meant to be shown by themselves. Do NOT return addons by themselves. */
    return []
  }

  if (csaId) {
    /** If we're filtering by csaId, it is assumed we're in CSADetail screen.
     * - We allow showing private products because they're allowed in the CSADetails screen
     */

    return !shouldSort ? hits : [...hits].sort(getSortAlgoliaProducts(availAddonsIds, opts.isWholesale))
  }

  /** Since we're not filtering by csaId, it is assumed we are not in the CSADetail screen. (We're in the shop)
   * Requirements:
   * - We should only show addons available for adding to the cart. To accomplish that, we will remove any addon hits whose id isn't included in the availAddonsIds.
   */

  const filteredHits = [...hits.filter((hit) => (isAddon(hit) ? availAddonsIds.includes(hit.id) : true))]

  return !shouldSort ? filteredHits : [...filteredHits].sort(getSortAlgoliaProducts(availAddonsIds, opts.isWholesale))
}
