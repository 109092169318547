import { Primitive } from '@helpers/typescript'
import { useEffect, useRef } from 'react'

/** An object of fx dependencies whose values are primitives */
type DependencyObj = Record<string, Primitive>

/**
 * @param difference will contain the keys of the properties whose values changed in the current fx run. the previous and current values are expected to be different.
 * @param prevDependencies will contain the previous dependency object
 */
type DiffFxCallback<T extends DependencyObj> = (
  difference: { [K in keyof T]?: { previous: T[K]; current: T[K] } },
  prevDependencies: T,
) => (() => void) | void

/** Effect that allows you to inspect the difference the previous and new dependencies' values. It takes an object of primitive values as dependency and the effect callback receives the difference between the properties of the dependency object
 * @param effect is a callback that receives info about the difference between the dependencies previous and current value
 * @param dependencies is an object of primitive values that may trigger the effect. It doesn't need to be memoized because only the primitive values will trigger the effect, without the need for deep inequality comparison.
 * 
 * Initial call (on mount) will have an empty difference and empty previous dependencies
  useEffect(() => {
    effect({}, {});
  }, []);
 */
export function useDiffFx<T extends DependencyObj>(effect: DiffFxCallback<T>, dependencies: T) {
  const prevDependenciesRef = useRef<T>({} as T)
  const dependenciesRef = useRef<T>(dependencies)

  useEffect(() => {
    const prevDependencies = dependenciesRef.current
    const currentDependencies = { ...dependencies }

    const difference: { [K in keyof T]: { previous: T[K]; current: T[K] } } = {} as any
    for (const key in currentDependencies) {
      if (currentDependencies[key] !== prevDependencies[key]) {
        difference[key as keyof T] = {
          previous: prevDependencies[key],
          current: currentDependencies[key],
        }
      }
    }

    prevDependenciesRef.current = prevDependencies
    dependenciesRef.current = currentDependencies

    return effect(difference, prevDependencies)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, Object.values(dependencies))
}
