import { PropsWithChildren, memo, useContext } from 'react'

import { OrderSummary_Reusable } from '../OrderCreatorScreen/components/OrderCreatorSummary/OrderCreatorSummary-reusable'
import { OrderEditContext } from './useOrderEditData'

export type OrderSummaryProps = {
  children?: PropsWithChildren['children']
}
/** Adapter for order edit to render the reusable component for order summary */
export const OrderSummary = memo(function OrderSummary({ children }: OrderSummaryProps) {
  const {
    draftOrderFx: { data: draft },
    keyedStatePayments: [state],
  } = useContext(OrderEditContext)

  if (draft?.isWholesale === undefined) return null

  return (
    <OrderSummary_Reusable
      cartServiceType="orderEdit"
      farmId={draft.farm.id}
      isWholesale={draft.isWholesale}
      paymentsState={state}
      children={children}
    />
  )
})
