import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { formatPickupDate } from '@helpers/display'
import { DraftOrder } from '@models/DraftOrder'
import { memo } from 'react'
import { StyleSheet, View } from 'react-native'
import { ErrorText } from '../elements/Text'

interface DraftOrderDeclinedCardProps {
  draftOrder: DraftOrder
}

export const DraftOrderDeclinedCard = memo(function DraftOrderDeclinedCard({
  draftOrder,
}: DraftOrderDeclinedCardProps): JSX.Element {
  return (
    <View style={styles.declinedCard}>
      <ErrorText style={globalStyles.margin10}>
        This order has been declined. It will be deleted on {formatPickupDate(draftOrder.expiration)}.
      </ErrorText>
    </View>
  )
})

const styles = StyleSheet.create({
  declinedCard: {
    marginBottom: 10,
    borderWidth: 1,
    borderColor: Colors.primaryGray,
    borderRadius: 10,
  },
})
