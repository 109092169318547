import Colors from '@/constants/Colors'
import { RecaptchaContainer } from '@/hooks/useMultiFactor/useMultiFactor'
import { ConsumerScroll } from '@components'
import { Button, Text, ToggleButton } from '@elements'
import { StyleSheet, View } from 'react-native'

type Props = {
  isEnrolled: boolean
  sendResetPasswordEmail: () => void
  canBeUnEnrolled: boolean
  onDisableMFA: () => void
  onTwoFactorPress: () => void
}

/** View layer of Security Screen  */
export function SecurityScreenView({
  isEnrolled,
  sendResetPasswordEmail,
  canBeUnEnrolled,
  onDisableMFA,
  onTwoFactorPress,
}: Props) {
  return (
    <ConsumerScroll>
      <View style={styles.container}>
        <Text color={Colors.shades[400]}>Password</Text>
        <Button style={styles.noMargin} outline title="Reset Password" onPress={sendResetPasswordEmail} />
      </View>

      <View style={styles.container}>
        <Text color={Colors.shades[400]}>Authentication</Text>
        <RecaptchaContainer />

        {isEnrolled ? (
          <View>
            <Text>Two-Factor Authentication is currently enabled for your account.</Text>
            <ToggleButton
              iconRight
              title="Disable Two-Factor Authentication"
              disabled={isEnrolled && !canBeUnEnrolled}
              value={isEnrolled}
              onChange={onDisableMFA}
            />
            {isEnrolled && !canBeUnEnrolled && (
              <Text color={Colors.red}>
                If you would like to un-enroll Two-Factor Authentication, please do so from the web app.
              </Text>
            )}
          </View>
        ) : (
          <Button outline style={styles.noMargin} title="Two-Factor Authentication" onPress={onTwoFactorPress} />
        )}
      </View>
    </ConsumerScroll>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
    gap: 10,
    marginTop: 10,
  },

  noMargin: {
    marginHorizontal: 0,
    marginVertical: 0,
  },
})
