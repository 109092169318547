import { StyleSheet, View, ViewStyle } from 'react-native'

import { useLayout } from '@/hooks/useLayout'
import { memo } from 'react'
import Colors from '../constants/Colors'

type Props = {
  style?: ViewStyle
  withTabs?: boolean
  children: JSX.Element
}

export const FooterBtn = memo(function FooterBtn({ style, withTabs, children }: Props) {
  const { bottom } = useLayout()
  return <View style={[styles.footer, { paddingBottom: withTabs ? 5 : bottom }, style]}>{children}</View>
})

const styles = StyleSheet.create({
  footer: {
    width: '100%',
    padding: 5,
    backgroundColor: Colors.white,
  },
})
