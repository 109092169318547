import { ConsumerScroll } from '@components'
import { RouteProp } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'

import { OrdersParamList } from '../../navigation/types'
import { InvoiceBase } from './components/InvoiceBase'

import { withAuth } from '@/hooks/withAuth'

interface Props {
  route: RouteProp<OrdersParamList, 'InvoiceConsumer'>
  navigation: StackNavigationProp<OrdersParamList, 'InvoiceConsumer'>
}

function InvoiceConsumerComp({ route }: Props) {
  const { invoiceId } = route.params
  return (
    <ConsumerScroll>
      <InvoiceBase id={invoiceId} />
    </ConsumerScroll>
  )
}
export const InvoiceConsumer = withAuth(InvoiceConsumerComp, { noSafeAreaInsets: true })
