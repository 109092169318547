import { Coordinate } from '@models/Coordinate'
import { createContext, createRef, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { StyleProp, TextInput, TextInputProps, ViewStyle } from 'react-native'
import type { GeocodeResult } from 'use-places-autocomplete'

import { Logger } from '@/config/logger'
import { isMobile } from '@/constants/Layout'
import { AutoCompleteItem, useAutoComplete } from '@/hooks/useAutoComplete'
import useDeepCompareEffect from '@/hooks/useDeepEqualEffect'
import { useFocusFx } from '@/hooks/useFocusFx'
import { GoogleLocationDetailResult } from 'react-native-google-autocomplete/dist/services/Google.service'
import { makeEstablishment } from './GooglePlacesSearch'

export interface Establishment {
  coordinate: Coordinate
  address_components?: GeocodeResult['address_components']
  /** The establishment name will be the city when searching for cities */
  name?: string
}

const NO_RESULTS_TEXT = 'No results'
export const debouncedTimeGooglePlaces = 300

export const processTextGooglePlacesResult = (text: string) => text.replace(', USA', '')

/** These are the custom props we're adding to our component, to be combined with the TextInputProps */
export type GooglePlacesSearchCustomProps<DetailsType extends object> = {
  /** callback for externally handling the item selected */
  onSelect?: (item: AutoCompleteItem<DetailsType>) => void | Promise<void>
  /** handler run when selecting a result. Will pass the result already converted to an establishment for convenience */
  onSelectEstablishment?: (item: Establishment) => any
  /** This 'types' is borrowed from react-native-google-autocomplete's DefaultProps' queryTypes (The mobile library, which has better types). But it can't be imported directly because this type must be usable for both web and mobile. */
  types?: 'address' | 'geocode' | '(cities)' | 'establishment' | 'geocode|establishment'
  /** Initial search location and input value */
  initialValue?: string
  /** If true, when the initialValue changes the current input value will also change and a search will be performed at the new initial value */
  enableReinitialize?: boolean
  /** If true the first available result will be auto-selected. This is useful in some specific scenarios. For example, if you want to supply an initial value and you want to auto-select the first result for that query, it may allow the user to continue to the next step without having to manually select the first result. */
  autoSelectFirstResultOnce?: boolean
  /** If true the list view will be inline with the page */
  inline?: boolean
  /** Right-sided button inside the input that will clear the input on press */
  hasClearBtn?: boolean
  /** Will be called when the text is cleared via the clear button */
  onClearText?: () => void
  /** Style applied to the view container which wraps the TextInput and the "close" icon */
  contStyle?: StyleProp<ViewStyle>
  inputRef?: RefObject<TextInput>
  /** If the external component wants to access the internal onSubmit handler it can be obtained here. This would allow an external layer to imperatively trigger the on submit behavior */
  getOnSubmit?: (handler: () => Promise<Establishment | undefined>) => void
}

/** We omit the value because this component's value can't be managed from the outside. It is a controlled component but its value is managed inside the 3rd party library. You can still provide an initial value */
type AllowedTextInputProps = Omit<TextInputProps, 'value'>

/** Props for the external api of this component.
 * The DetailsType will vary by the mobile and web api from different libraries.
 */
export type GooglePlacesSearchProps<DetailsType extends object> = AllowedTextInputProps &
  GooglePlacesSearchCustomProps<DetailsType>

export const googlePlacesSearchAutocompleteKey = 'googlePlacesSearch'

/** Input for data hook */
export type UseGooglePlacesSearchDataInput<ResultType extends object, DetailType extends object> = {
  /** search results provided by the mobile or web 3rd party library */
  searchResults?: ResultType[]
  /** This is the value to show in the input. This is managed by the 3rd party library */
  inputValue: string
  /** This changes the current input value state, and performs a new search at the given place. It's managed by the mobile or web 3rd party library */
  setValue: (v: string) => void
  /** getResultDetails is intended to convert the result type into a detail type. In all google places search 3rd party libraries, there's a way to do that by passing a result item into a helper provided by the same library, which returns the detail item with an async request. This detail item is the one we pass to the final onSelect prop */
  getResultDetails: (itm: AutoCompleteItem<ResultType>) => Promise<AutoCompleteItem<DetailType>>
  /** isSearching comes from the 3rd party library */
  isSearching: boolean
} & Pick<
  GooglePlacesSearchCustomProps<DetailType>,
  | 'onSelect'
  | 'onSelectEstablishment'
  | 'inline'
  | 'initialValue'
  | 'enableReinitialize'
  | 'autoSelectFirstResultOnce'
  | 'inputRef'
  | 'getOnSubmit'
  | 'onClearText'
> &
  Pick<AllowedTextInputProps, 'onSubmitEditing' | 'onFocus' | 'onTouchStart' | 'onChangeText' | 'onBlur'>

/** Output for data hook */
export type UseGooglePlacesSearchDataOutput<ResultType extends { description: string }> = {
  items: AutoCompleteItem<ResultType>[]
  hideInlineResults: boolean
  inputRef: RefObject<TextInput>
  onSubmit: TextInputProps['onSubmitEditing']
  inlineOnPressItem: (item: ResultType) => void
  onFocus: TextInputProps['onFocus']
  onBlur: TextInputProps['onBlur']
  onChangeText: TextInputProps['onChangeText']
  onTouchStart: TextInputProps['onTouchStart']
  onClearText: () => void
  autoCompleteOverlay: JSX.Element | null
}

/** This hook provides the common data to the web and mobile components for the google places autotomplete, ensuring their behavior will remain on par going forward */
export function useGooglePlacesSearchData<ResultType extends { description: string }, DetailType extends object>({
  inline = false,
  searchResults = [],
  initialValue,
  enableReinitialize = false,
  inputValue,
  autoSelectFirstResultOnce,
  setValue,
  onSelect: onSelectProp,
  onSelectEstablishment: onSelectEstablishmentProp,
  getResultDetails,
  isSearching,
  onChangeText: onChangeTextProp,
  onFocus: onFocusProp,
  onBlur: onBlurProp,
  onSubmitEditing: onSubmitEditingProp,
  onTouchStart: onTouchStartProp,
  inputRef: inputRefProp,
  getOnSubmit,
  onClearText: onClearTextProp,
}: UseGooglePlacesSearchDataInput<ResultType, DetailType>): UseGooglePlacesSearchDataOutput<ResultType> {
  /** The inputRefProp?.current should be in the deps, so that on each re-render, the inputRef used will be either the prop if defined, or will be created if the prop is nullish*/
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const inputRef = useMemo(() => inputRefProp ?? createRef<TextInput>(), [inputRefProp?.current])

  /** Whether to show/hide the inline results */
  const [hideInlineResults, setHideInlineResults] = useState(!inline)
  const { autoCompleteOverlay, showAutocomplete, updateAutocomplete, hideAutocomplete, state: ac } = useAutoComplete()
  const [items, setItems] = useState<AutoCompleteItem<ResultType>[]>([])

  const itemsTimeout = useRef<NodeJS.Timeout | null>(null)

  /** Generates autotomplete items from location results */
  useEffect(() => {
    if (itemsTimeout.current) clearTimeout(itemsTimeout.current)
    if (searchResults.length) {
      setItems(searchResults.map((item) => ({ text: processTextGooglePlacesResult(item.description), data: item })))
    } else if (inputValue?.length > 3 && !isSearching) {
      /** If no results after searching done, and the input has a value, show the "No results" item after the debounce time. */
      itemsTimeout.current = setTimeout(() => {
        setItems([{ text: NO_RESULTS_TEXT, data: { description: '' } as ResultType }])
      }, debouncedTimeGooglePlaces)
    } else setItems([])
  }, [searchResults, inputValue, isSearching])

  /** Handles selecting an autocomplete item.
   * If there's an onSelect prop, will get the details on the result item, and pass it to the onSelect prop, which expects the details. */
  const onSelectItem = useCallback(
    async (item: AutoCompleteItem<ResultType>): Promise<Establishment> => {
      setValue(item.text)

      let detailItem: AutoCompleteItem<DetailType> | undefined

      try {
        detailItem = await getResultDetails(item)
      } catch (error) {
        Logger.error('Error: ', error)
        detailItem = undefined
      }

      if (detailItem && (onSelectProp || onSelectEstablishmentProp)) {
        onSelectProp?.(detailItem)
        onSelectEstablishmentProp?.(makeEstablishment(detailItem as AutoCompleteItem<GoogleLocationDetailResult>))
      }
      return makeEstablishment(detailItem as AutoCompleteItem<GoogleLocationDetailResult>)
    },
    [setValue, onSelectProp, onSelectEstablishmentProp, getResultDetails],
  )

  // auto-selects the first result item without user input if the autoSelectFirstResultOnce prop is true
  const hasAutoSelected = useRef(false)
  useFocusFx(() => {
    if (!hasAutoSelected.current && autoSelectFirstResultOnce && items[0] && items[0].text !== NO_RESULTS_TEXT) {
      hasAutoSelected.current = true
      onSelectItem(items[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  /** Show or update autocomplete UI when results change.
   * This must EITHER show results inline, or dispatch showAutoComplete
   */
  useDeepCompareEffect(() => {
    /** only show results UI if the input is currently focused.
     * This is required to prevent the items from showing again after an item is selected. On selection, the items should hide in both inline and overlay mode. and they should remain hidden */
    if (!inputRef.current?.isFocused()) return

    if (inline) {
      setHideInlineResults(false)
    } else {
      ac?.value
        ? updateAutocomplete(inputRef, items)
        : showAutocomplete(googlePlacesSearchAutocompleteKey, inputRef, items, onSelectItem, { matchWidth: true })
    }
    //Only meant to run on items change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  const inlineOnPressItem = useCallback(
    (item: ResultType) => {
      onSelectItem?.({ text: processTextGooglePlacesResult(item.description), data: item })
    },
    [onSelectItem],
  )

  /** This should have all the actions performed on submit except for anything that requires the native event */
  const onSubmitInternalActions = useCallback(async () => {
    /** On press enter, hide results for autocomplete. Inline is handled by the onBlur */
    if (!inline) hideAutocomplete()

    /** If we press enter, select the first result if there is a valid result */
    if (items[0] && items[0].text !== NO_RESULTS_TEXT) {
      return onSelectItem?.(items[0])
    }
  }, [hideAutocomplete, onSelectItem, inline, items])

  /** This is the onsubmit that gets passed into the TextInput element as a prop */
  const onSubmit = useCallback<NonNullable<TextInputProps['onSubmitEditing']>>(
    (e) => {
      onSubmitEditingProp?.(e)
      onSubmitInternalActions()
    },
    [onSubmitInternalActions, onSubmitEditingProp],
  )

  // Pass onSubmit to the external layer
  useEffect(() => {
    getOnSubmit?.(onSubmitInternalActions)
  }, [onSubmitInternalActions, getOnSubmit])

  /** On blur, hide results for inline. For autocomplete, clicking outside on the backdrop already hides the results */
  const onBlur: TextInputProps['onBlur'] = useCallback<NonNullable<TextInputProps['onBlur']>>(
    (e) => {
      onBlurProp?.(e)
      // A timeout is needed here so the user option press is captured. If no timeout is provided, onBlur will be called before onPress from the chosen option, and the component will be unmounted
      if (inline)
        setTimeout(() => {
          setHideInlineResults(true)
        }, 100)
    },
    [inline, setHideInlineResults, onBlurProp],
  )

  const displayResults = useCallback(() => {
    if (inline && hideInlineResults) setHideInlineResults(false)
    else if (ac?.value !== googlePlacesSearchAutocompleteKey) {
      /** This will ensure if the autocomplete modal is currently not shown, it will appear again */
      showAutocomplete(googlePlacesSearchAutocompleteKey, inputRef, items, onSelectItem, { matchWidth: true })
    }
  }, [inline, hideInlineResults, setHideInlineResults, showAutocomplete, items, onSelectItem, ac?.value, inputRef])

  const onChangeText = useCallback<NonNullable<TextInputProps['onChangeText']>>(
    (text: string) => {
      onChangeTextProp?.(text)
      setValue(text)
      displayResults()
    },
    [setValue, displayResults, onChangeTextProp],
  )

  const onFocus = useCallback<NonNullable<TextInputProps['onFocus']>>(
    (e) => {
      onFocusProp?.(e)
      setValue(inputValue)
      displayResults()
    },
    [inputValue, displayResults, setValue, onFocusProp],
  )

  /** This is helpful when the autocomplete has hidden and then the user presses the textInput area. Will make the dropdown reappear */
  const onTouchStart = useCallback<NonNullable<TextInputProps['onTouchStart']>>(
    (e) => {
      onTouchStartProp?.(e)

      /** This should display the results on mobile. However this has a bad behavior on web, so if not mobile it should return */
      if (!isMobile) return
      displayResults()
    },
    [displayResults, onTouchStartProp],
  )

  const onClearText = useCallback(() => {
    onClearTextProp?.()
    setValue('')
  }, [setValue, onClearTextProp])

  /** Handles the initial value and its changes */
  useFocusFx(
    () => {
      if (initialValue === undefined || !enableReinitialize) return

      if (initialValue !== inputValue) setValue(initialValue)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialValue],
    { noRefocus: true },
  )

  return {
    autoCompleteOverlay,
    items,
    hideInlineResults,
    inputRef,
    onSubmit,
    inlineOnPressItem,
    onFocus,
    onBlur,
    onChangeText,
    onTouchStart,
    onClearText,
  }
}

export type GooglePlacesSearchContextType = {
  textInputProps: AllowedTextInputProps
  customProps: GooglePlacesSearchCustomProps<any>
}

export const GooglePlacesSearchContext = createContext<GooglePlacesSearchContextType>(
  {} as GooglePlacesSearchContextType,
)
