import * as React from 'react'
import { StyleSheet, View } from 'react-native'

export function Grid({ children }: React.PropsWithChildren<Record<never, any>>): JSX.Element {
  return <View style={styles.grid}>{children}</View>
}

const styles = StyleSheet.create({
  grid: { flexDirection: 'row', justifyContent: 'space-evenly' },
})
