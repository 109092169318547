import { createContext, useMemo, useRef, useState } from 'react'
import { hideModal } from '@elements'
import { Platform } from 'react-native'

export type PaymentOptionsModalType = {
  isModalOpen: boolean
  setShowModal: (show: boolean) => void
  onModalDismiss: () => void
  openGlobalModal: (callback: () => void) => void
  openLocalModal: () => void
}

export const usePaymentOptionsModalData = (): PaymentOptionsModalType => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const onModalCloseCallback = useRef<() => void>()

  return useMemo(
    () => ({
      setShowModal: (value: boolean) => setIsModalOpen(value),
      isModalOpen,
      openLocalModal: () => {
        hideModal()
        setIsModalOpen(true)
      },
      openGlobalModal: (callback: () => void) => {
        // On iOS we need to wait for the onModalDismiss to be called to trigger this callback, however on web and android
        // we can call it immediately
        if (Platform.OS === 'ios') {
          onModalCloseCallback.current = callback
        } else {
          callback()
        }
        setIsModalOpen(false)
      },
      onModalDismiss: () => {
        // If the modal is closed internally by the component, we should mark closed here
        setIsModalOpen(false)
        setTimeout(() => {
          onModalCloseCallback.current?.()
          onModalCloseCallback.current = undefined
        }, 100)
      },
    }),
    [isModalOpen, setIsModalOpen, onModalCloseCallback],
  )
}

export const PaymentOptionsModalContext = createContext<PaymentOptionsModalType>({} as PaymentOptionsModalType)
