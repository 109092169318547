import { isWeb } from '@/constants/Layout'
import { getStore } from '@/redux/store'
import { CoordString, getCoordString } from '@helpers/coordinate'
import { AlgoliaGeoDoc } from '@models/Algolia'
import { FarmStatus } from '@models/Farm'
import { setCurrentHover } from '../../../redux/actions/appState'
import { Marker } from './Marker'
import { SelectedMarker, isMobileMarkerPress, isWebMarkerPress } from './MarkerCommon'

/** Render an array of all markers to be rendered by the map component.
 * - This can't be a component because on web that messes the markers' display coordinates since the web map library requires the marker elements to be direct children of the main map component */
export function renderMarkers({
  hits,
  onMarkerPress,
  currentHover,
  markersOpacity,
}: {
  hits: AlgoliaGeoDoc[]
  onMarkerPress: ({ x, y, docs }: NonNullable<SelectedMarker>) => void
  currentHover?: CoordString | null
  markersOpacity?: number
}) {
  const hitsByCoord = new Map<string, AlgoliaGeoDoc[]>() //keys are coords, values are hits in that location

  //Group hits by coord
  for (const hit of hits) {
    const hitCoord = getCoordString(hit._geoloc)
    if (hitsByCoord.has(hitCoord)) {
      hitsByCoord.get(hitCoord)?.push(hit)
    } else {
      hitsByCoord.set(hitCoord, [hit])
    }
  }

  return Array.from(hitsByCoord.entries()).map(([coordStr, coordHits]) => {
    //Only show the current marker as a gray dot if all the geodocs at this coord are from unregistered farms
    const isGrayDot = coordHits.every((doc: AlgoliaGeoDoc) => doc.farm.status !== FarmStatus.Registered)

    //Set default styles
    let z = 2
    let isHovered = false

    if (isGrayDot) {
      z--
    }
    if (currentHover === coordStr) {
      z++
      isHovered = true
    }
    const curLabel = hitsByCoord.get(coordStr)?.length //Count of hits at this location
    const onHoverIn = () => {
      getStore().dispatch(setCurrentHover(coordStr))
    }

    const coords = coordHits[0]._geoloc

    if (!coords) {
      return null
    }

    return (
      <Marker
        key={coordStr}
        // This 'lat' and 'lng' props are required AS-IS by the web 3rd party map library in order to parse the marker elements and render them correctly.
        lat={coords.lat}
        lng={coords.lng}
        label={curLabel ? curLabel.toString() : ''}
        isGrayDot={isGrayDot}
        isHovered={isHovered}
        onPress={(evt) => {
          if (!evt) return

          // This makes the marker to appear as if hovered on mobile press
          getStore().dispatch(setCurrentHover(coordStr))

          if (isMobileMarkerPress(evt)) {
            onMarkerPress({
              x: evt.nativeEvent.position?.x,
              y: evt.nativeEvent.position?.y,
              docs: hitsByCoord.get(coordStr)!,
            })
          } else if (isWebMarkerPress(evt)) {
            onMarkerPress({ x: evt.nativeEvent.pageX, y: evt.nativeEvent.pageY, docs: hitsByCoord.get(coordStr)! })
          } else {
            throw new Error('Marker press event not recognized')
          }
        }}
        onHoverIn={onHoverIn}
        zIndex={z}
        style={isWeb ? { opacity: markersOpacity } : undefined}
      />
    )
  })
}
