import { wait } from './helpers'

export type BackoffOptions = {
  // The maximum number of tries the rate limiter will attempt
  maxTries?: number
  // The maximum amount of randomness (in ms) for the rate limiter. For example, specifying 1000 will mean that each
  // request will wait an additional random amount between 0 and 1 seconds. This is useful for multiple requests to
  // spread them out a little more.
  maxRandomness?: number
  // A function that will determine if the rate limiter should continue
  retry?: (err: unknown) => Promise<boolean> | boolean
}

/** Backoff starts at 200ms then 400ms and ultimately fails after a 6.4 second retry. With some randomness **/
export async function backOff<Return>(callback: () => Return, opts?: BackoffOptions): Promise<Return> {
  // We wrap the runner here so that you cannot pass tries directly to the recursive function
  return exponentialBackoffRunner(callback, opts ?? {})
}

// the runner is the recursive function that will actually perform the backoff
async function exponentialBackoffRunner<Return>(
  callback: () => Return,
  { tries = 1, maxTries = 4, maxRandomness = 1000, retry }: BackoffOptions & { tries?: number },
): Promise<Return> {
  try {
    return await callback()
  } catch (err: unknown) {
    // If we have a retry function, and it returns false then we should not retry
    if (retry && !(await retry(err))) throw err
    if (tries >= maxTries) {
      throw err
    }
    // Exponential wait with up to a second of randomness
    const randomness = Math.round(Math.random() * maxRandomness)
    await wait(2 ** tries * 100 + randomness)

    return exponentialBackoffRunner(callback, { tries: tries + 1, retry, maxTries })
  }
}
