import { doc, onSnapshot, Unsubscribe } from 'firebase/firestore'

import { db } from './db'
import { errorCatcher } from './Errors'

/** snapshotSearchIndexState sets up a Firestore snapshot listener to notify callers of updates to the searchIndex collection when changes occur. */
export function snapshotSearchIndexState(
  farmId: string,
  updateFn: (data: Record<string, number>) => void,
  errorFn = errorCatcher,
): Unsubscribe {
  const ref = doc(db(), `farms/${farmId}/searchIndex/state`)
  return onSnapshot(
    ref,
    (snapshot) => {
      if (!snapshot.exists()) {
        updateFn({})
        return
      }

      updateFn(snapshot.data())
    },
    errorFn,
  )
}
