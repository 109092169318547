import { Text, TextH4, Touchable } from '@elements'
import { Entypo } from '@expo/vector-icons'
import { dequal } from '@helpers/customDequal'
import { Order, Pickup } from '@models/Order'
import { useNavigation } from '@react-navigation/native'
import * as React from 'react'
import { PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react'
import { LayoutChangeEvent, LayoutRectangle, View } from 'react-native'

import Colors from '../../constants/Colors'
import { globalStyles } from '../../constants/Styles'
import { ShareRowExpandedContext } from '../screens/Customer/AdminCustomerSubscriptionsSection/AdminCustomerSubscriptions'
import { rescheduleAll } from '../screens/Customer/AdminCustomerSubscriptionsSection/helper'

import { useFnStyles } from '@/hooks/useFnStyles'
import { useFocusFx } from '@/hooks/useFocusFx'
import { useHasPermissionWithFlag } from '@/hooks/useHasPermission'
import { AccessRight, Permission } from '@helpers/Permission'
import { ActionBtn, ActionsMenuComponent } from './elements/ActionsMenuComponent'

import { configureSubscriptionStatus } from '../screens/Customer/helpers/pickupStatusHelper'

type props = {
  /** prodId refers to current share subscription product ID */
  prodId: string
  /** itemName refers to current share product subscription name */
  itemName: string
  /** frequency refers to schedule frequency */
  frequency: string
  /** nextOrLastPickup refers to next available Pickup for current product, if no next pickup anymore, get the last Pickup */
  nextOrLastPickup: Pickup | undefined
  /** cancelled refers to whether current share subscription is cancelled */
  cancelled: boolean
  /** SKU is refers to current share subscription product sku */
  SKU: string
  /** dates refers to the range of first pickup date and last pickup date in string format */
  dates: string
  /** isPast refers to the last pickup date has already past (It means no more Pickups for current share subscription) */
  isPast: boolean
  /** order refers to order that is associated with current share subscription */
  order: Pick<Order, 'id' | 'orderNum'>
  /** setShareRowProps refers to setting (orderNum, prodId, layout, expand()) for each rows, it is related to the use case on Scrolling to share subscription and Accordion open and close issue */
  setShareRowProps: React.Dispatch<React.SetStateAction<ShareRowProp[]>>
}

export function SubscriptionAccordion({
  prodId,
  itemName,
  frequency,
  cancelled,
  nextOrLastPickup,
  SKU,
  dates,
  children,
  isPast,
  order,
  setShareRowProps,
}: PropsWithChildren<props>) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [layout, setLayout] = useState<LayoutRectangle>()
  const navigation = useNavigation<any>()
  const { shareExpandedGroup, setShareExpandedGroup, shareMap, onChange } = useContext(ShareRowExpandedContext)
  const hasAccessEditSubs = useHasPermissionWithFlag(Permission.Orders, AccessRight.Edit)
  const styles = useStyles(isExpanded)

  useFocusFx(() => {
    if (prodId && !!order.orderNum && layout) {
      setShareRowProps((prev) => {
        const newObj: ShareRowProp = {
          prodId,
          orderNum: order.orderNum,
          layout,
          //When scrolling to current share row from clicking subscription from customerOrder section and auto expand, it will reset the shareExpandedGroup only include current share row.
          expand: () => setShareExpandedGroup?.([`${prodId}_${order.orderNum}`]),
        }
        const curr = prev.find((prop) => prop.prodId === prodId && prop.orderNum === order.orderNum)
        if (curr)
          return prev.filter((prop) => !(prop.prodId === prodId && prop.orderNum === order.orderNum)).concat(newObj)
        else return prev.concat(newObj)
      })
    }
  }, [prodId, order.orderNum, layout, setShareRowProps, setShareExpandedGroup])

  /** Whenever we make any actions on subscription, All Rows are always regenerated, so this useFocusFx will always run one time in every SubscriptionAccordion to decide if itself need to be expanded or closed.*/
  useFocusFx(() => {
    if (shareExpandedGroup) {
      if (shareExpandedGroup.includes(`${prodId}_${order.orderNum}`)) {
        setIsExpanded(true)
      } else {
        setIsExpanded(false)
      }
    }
  }, [order.orderNum, prodId, shareExpandedGroup])

  const handleOnPress = useCallback(() => {
    setIsExpanded((prev) => !prev)
    setShareExpandedGroup?.((prev) => {
      //if Accordion is expanded, remove from array, else add to array
      if (isExpanded) {
        return prev.filter((uniqueId) => uniqueId !== `${prodId}_${order.orderNum}`)
      } else {
        return prev.concat(`${prodId}_${order.orderNum}`)
      }
    })
  }, [isExpanded, order.orderNum, prodId, setShareExpandedGroup])

  const textColor = cancelled || isPast ? Colors.shades[300] : undefined

  const navigateOrderDetail = useCallback(
    () => navigation.navigate('AdminOrderDetails', { orderId: order.id }),
    [navigation, order.id],
  )

  const rescheduleAllButton = useCallback(
    () => rescheduleAll(shareMap, prodId, order.id, onChange),
    [onChange, order.id, prodId, shareMap],
  )

  const viewLayoutFunc = useCallback(
    ({ nativeEvent: { layout: newLayout } }: LayoutChangeEvent) => !dequal(newLayout, layout) && setLayout(newLayout),
    [layout],
  )

  /** Generate action buttons for ShareRow */
  const actionButtons = useMemo((): ActionBtn[] => {
    return [
      { title: 'Cancel Share', onPress: navigateOrderDetail },
      { title: 'Reschedule All', onPress: rescheduleAllButton },
    ]
  }, [navigateOrderDetail, rescheduleAllButton])

  return (
    <View onLayout={viewLayoutFunc}>
      <Touchable style={styles.container} onPress={handleOnPress}>
        <View style={styles.rowItemOne}>
          <Entypo
            size={20}
            color={Colors.shades['500']}
            name={isExpanded ? 'chevron-up' : 'chevron-down'}
            style={styles.rowItemOneIcon}
          />
          <Text color={textColor} type="bold">
            {itemName}
          </Text>
        </View>
        <Text style={globalStyles.flex1} color={textColor}>
          {frequency}
        </Text>
        <Text style={globalStyles.flex1} color={textColor}>
          {order.orderNum ? `#000${order.orderNum}` : '?'}
        </Text>
        <Text style={globalStyles.flex1} color={textColor}>
          {SKU}
        </Text>
        <Text style={styles.flex15} color={textColor}>
          {nextOrLastPickup ? nextOrLastPickup.distribution.name : ''}
        </Text>
        <Text style={globalStyles.flex1} color={textColor}>
          {dates}
        </Text>
        <Text style={globalStyles.flex1} color={textColor}>
          {configureSubscriptionStatus(cancelled, isPast, shareMap, prodId, order.id)}
        </Text>
        <ActionsMenuComponent
          containerStyle={globalStyles.flex1}
          buttons={actionButtons}
          disabled={cancelled || isPast || !hasAccessEditSubs}
        />
      </Touchable>

      {isExpanded && (
        <>
          <View style={styles.headerContainer}>
            <View style={styles.firstHeader}>
              <View style={styles.firstHeaderIcon} />
              <TextH4 size={10}>Date</TextH4>
            </View>
            <TextH4 style={globalStyles.flex1} size={10}>
              Hours
            </TextH4>
            <View style={globalStyles.flex1} />
            <View style={globalStyles.flex1} />
            <View style={styles.flex15} />
            <View style={globalStyles.flex1} />
            <View style={globalStyles.flex1} />
            <View style={globalStyles.flex1} />
          </View>
          {children}
        </>
      )}
    </View>
  )
}

const useStyles = (isExpanded: boolean) =>
  useFnStyles(
    (isExpanded) => ({
      container: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 5,
        borderBottomColor: Colors.semiTransparent,
        borderBottomWidth: isExpanded ? 0 : 1,
      },
      rowItemOne: {
        flex: 2,
        flexDirection: 'row',
      },
      rowItemOneIcon: {
        marginRight: 15,
      },
      shareLink: {
        color: Colors.red,
        textDecorationLine: 'underline',
      },
      grayShareLink: {
        color: Colors.shades[300],
        textDecorationLine: 'underline',
      },
      itemName: { flex: 1.5, width: 1, fontWeight: 'bold', marginRight: 10 },
      flex15: { flex: 1.5 },
      textStyle2: { flex: 2, width: 1, marginRight: 10 },
      headerContainer: { flexDirection: 'row', marginBottom: 5 },
      firstHeader: { flex: 2, flexDirection: 'row' },
      firstHeaderIcon: { marginRight: 35 },
    }),
    isExpanded,
  )

/** Properties for each accordion row */
export type ShareRowProp = {
  orderNum: number | null
  prodId: string
  layout: LayoutRectangle
  expand: () => void
}
