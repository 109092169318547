import InputLabel from '@/admin/components/InputLabel'
import { globalStyles } from '@/constants/Styles'
import { ToolTips } from '@components'
import { ButtonClear, FormMoneyInput, FormPickerInput } from '@elements'
import { findPriceForAppMode } from '@helpers/products'
import { formatCatalog } from '@helpers/wholesale'
import { DefaultCatalog } from '@models/Product'
import { StyleSheet, View } from 'react-native'
import { buildPrice, BuyingOptionSectionProps } from '../../AdvancedPricing-helpers'

type PricePerCatalogProps = Pick<BuyingOptionSectionProps, 'formik' | 'formValues'>

/** Price per catalog component. Used on multi-catalog products*/
export function PricePerCatalog({ formik, formValues }: PricePerCatalogProps) {
  const { values: boValues, showSimpleError, setFieldValue, handleBlur } = formik

  return (
    <View style={styles.pricePerCatalogCont}>
      {boValues.prices.map((pr, prIx, prices) => (
        <View style={globalStyles.flexRow} key={`price[${pr.id}]`}>
          <View style={styles.pricesCont}>
            <FormPickerInput
              row
              onValueChange={(v) => {
                setFieldValue(`prices[${prIx}].priceGroup`, { type: 'default-catalog', catalog: v })
              }}
              label="Price Group"
              value={pr.priceGroup?.type === 'default-catalog' ? pr.priceGroup.catalog : ''}
              containerStyle={styles.priceGroupPicker}
              items={[DefaultCatalog.Retail, DefaultCatalog.Wholesale].map((v) => ({
                value: v,
                label: formatCatalog(v),
              }))}
              errorMessage={showSimpleError(`prices[${prIx}].priceGroup`)}
              onBlur={handleBlur(`prices[${prIx}].priceGroup`)}
            />
            <FormMoneyInput
              row
              value={pr.amount}
              label={
                <InputLabel
                  label={`Price${formValues.unitStock ? ' (calculated-editable)' : '*'}`}
                  tooltipId={formValues.unitStock ? ToolTips.PRICE_OPTION : ToolTips.PRICE_GLOBAL}
                  tooltipTitle="Price"
                />
              }
              onChangeText={(val) => {
                setFieldValue(`prices[${prIx}].amount`, val)
              }}
              errorMessage={showSimpleError(`prices[${prIx}].amount`)}
              onBlur={handleBlur(`prices[${prIx}].amount`)}
            />
          </View>
          <ButtonClear
            icon="times"
            disabled={boValues.prices.length === 1}
            size={14}
            style={styles.removePriceBtn}
            onPress={() => {
              setFieldValue(
                `prices`,
                prices.filter((prInner) => prInner.id !== pr.id),
              )
            }}
          />
        </View>
      ))}
      {boValues.prices.length < 2 && (
        <ButtonClear
          title="+ Add price"
          size={14}
          style={styles.addPrice}
          onPress={() => {
            const hasRetail = !!findPriceForAppMode(boValues.prices, false)
            const newPrice = buildPrice(hasRetail ? DefaultCatalog.Wholesale : DefaultCatalog.Retail)
            setFieldValue(`prices`, [...boValues.prices, newPrice])
          }}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  addPrice: {
    alignSelf: 'flex-end', // This forces the button to appear on the right side of the form, below the prices
  },
  pricesCont: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  removePriceBtn: {
    alignSelf: 'flex-end',
    height: 41.5, // matches the input height in order to be centered
    width: 41.5,
    marginBottom: 10,
  },
  priceGroupPicker: {
    paddingTop: 15, // Needed in order to be aligned with the right side inputs
  },
  pricePerCatalogCont: {
    maxWidth: 700,
    alignSelf: 'flex-end',
  },
})
