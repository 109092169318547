import { getCoupon, listCouponRedemptions, listPromosForCoupon, unArchiveCoupon } from '@api/Coupons'
import { InfoRow, ToolTips } from '@components'
import { Alert, ButtonClear, ErrorText, Loader, LoadingView, Modal, Text, Toast } from '@elements'
import { formatMoney, getOrderNum } from '@helpers/display'
import { errorToString } from '@helpers/helpers'
import { PromoCode, isPercentCoupon } from '@models/Coupon'
import { Invoice } from '@models/Invoice'
import { userName } from '@models/User'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useCallback, useEffect, useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { Logger } from '../../../config/logger'
import Colors from '../../../constants/Colors'
import { useApiFx } from '../../../hooks/useApiFx'
import { withAdminAuth } from '../../../hooks/withAdminAuth'
import { adminFarmSelector } from '../../../redux/selectors'
import { AdminView } from '../../components/AdminView'
import { BackTo } from '../../components/BackTo'
import { OfflineTable, OfflineTableProps } from '../../components/OfflineTable/OfflineTable'
import { CouponsParamList } from '../../navigation/types'
import { AdminCustomerHeader } from '../Customer/AdminCustomerOrdersSection/components/Header'
import { AddEditCoupon } from './AddEditCoupon'
import { AddEditPromo } from './AddEditPromo'
import { EmptyPromoCodes, EmptyRedemptions } from './components'
import { deletePromo } from './helpers'

import { AdminCard } from '@/admin/components/AdminCard'
import { AdminHalfCardsContainer } from '@/admin/components/AdminCard/AdminHalfCardsContainer'
import { ExpandableRow } from '@/admin/components/OfflineTable/ExpandableRow'
import { useHasPermissionWithFlag } from '@/hooks/useHasPermission'
import { AccessRight, Permission } from '@helpers/Permission'
import DecimalCalc from '@helpers/decimal'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'

function CouponDetailsComp() {
  const navigation = useNavigation<StackNavigationProp<CouponsParamList, 'CouponDetails'>>()
  const { params } = useRoute<RouteProp<CouponsParamList, 'CouponDetails'>>()

  const farm = useSelector(adminFarmSelector)

  const couponFx = useApiFx(getCoupon, [farm.id, params.id], !!params.id)
  const promoCodesFx = useApiFx(listPromosForCoupon, [farm.id, params.id], !!params.id)
  const redemptionsFx = useApiFx(listCouponRedemptions, [farm.id, params.id], !!params.id)
  const hasAccessEdit = useHasPermissionWithFlag(Permission.ProductSetup, AccessRight.Edit)
  const allowEdits = hasAccessEdit && !couponFx.data?.isSystemCoupon

  const editCoupon = useCallback(() => {
    Modal(<AddEditCoupon coupon={couponFx.data} onUpdate={() => couponFx.refresh()} />, {
      title: 'Edit Coupon/Discount',
    })
  }, [couponFx])

  const addEditPromo = useCallback(
    (promo?: PromoCode) => {
      if (!couponFx.data) {
        Logger.error('Could not load coupon to add promo for.')
        return Alert('Something went wrong', 'Please reload the page and try again')
      }
      Modal(
        <AddEditPromo
          promo={promo}
          coupon={couponFx.data}
          timezone={farm.timezone}
          onAdded={() => promoCodesFx.refresh()}
        />,
        { title: `${promo ? 'Edit' : 'Add'} Associated Promo Code` },
      )
    },
    [couponFx.data, promoCodesFx, farm.timezone],
  )
  const _unArchiveCoupon = useCallback(async () => {
    if (!couponFx.data) {
      Logger.error('Could not load coupon to un-archive')
      return Alert('Something went wrong', 'Please reload the page and try again')
    }
    try {
      Loader(true)
      await unArchiveCoupon(couponFx.data)
      Loader(false)
      couponFx.refresh()
      Toast('This coupon is now active.')
    } catch (e) {
      Logger.warn(e)
      Alert('Error', `Unable to archive coupon: ${errorToString(e)} `)
      Loader(false)
    }
  }, [couponFx])

  const _deletePromo = useCallback(
    (promo: PromoCode) => {
      deletePromo(promo, promoCodesFx.refresh)
    },
    [promoCodesFx],
  )

  const headerBtn = useMemo(() => {
    if (couponFx.data?.archived) {
      return [{ title: 'Un-archive', onPress: () => _unArchiveCoupon(), disabled: !allowEdits }]
    }
    return [{ title: 'Edit Coupon', onPress: () => editCoupon(), disabled: !allowEdits }]
  }, [couponFx.data?.archived, editCoupon, _unArchiveCoupon, allowEdits])

  const renderPromoCodeRow = useCallback<NonNullable<OfflineTableProps<PromoCode>['renderItem']>>(
    ({ item, index }) => (
      <ExpandableRow<PromoCode>
        item={item}
        index={index}
        key={`PromoCode_${item.code}_${index}`}
        columns={[
          {
            process: (item) => item.code,
            widthFlex: 2,
          },
          {
            process: (item) => item.timesRedeemed.toString(),
            widthFlex: 2,
          },
          {
            process: (promo) => (
              <ButtonClear
                small
                onPress={() => addEditPromo(promo)}
                disabled={!allowEdits}
                style={styles.btnFlex}
                title="Edit"
              />
            ),
            widthFlex: 0.5,
          },
          {
            process: (item) => (
              <ButtonClear
                small
                onPress={() => _deletePromo(item)}
                disabled={!allowEdits}
                style={styles.btnFlex}
                title="Delete"
              />
            ),
            widthFlex: 0.5,
          },
        ]}
        onRowPress={() => allowEdits && addEditPromo(item)}
      />
    ),
    [addEditPromo, _deletePromo, allowEdits],
  )

  const renderRedemptionRow = useCallback<NonNullable<OfflineTableProps<Invoice>['renderItem']>>(
    ({ item, index }) => (
      <ExpandableRow<Invoice>
        item={item}
        index={index}
        key={`Redemption_${item.invoiceNum}_${index}`}
        columns={[
          {
            process: (item) => getOrderNum(item.order.orderNum),
            widthFlex: 1,
          },
          {
            process: (item) => getOrderNum(item.invoiceNum),
            widthFlex: 1,
          },
          {
            process: (invoice) => invoice.couponApplied!.promo!.code,
            widthFlex: 1,
          },
          {
            process: (invoice) => (
              <View>
                <Text>{userName(invoice.user)}</Text>
                <Text color={Colors.shades[200]} size={10}>
                  {item.user.email}
                </Text>
              </View>
            ),
            widthFlex: 2,
          },
          {
            process: (item) => (
              <ButtonClear
                url={`/admin/orders/view/${item.order.id}`}
                small
                style={styles.btnFlex}
                title="View Order"
              />
            ),
            widthFlex: 0.5,
          },
        ]}
      />
    ),
    [],
  )

  /** This will automatically show the AddPromo modal based on the url params */
  useEffect(() => {
    if (!params.addPromo) return

    // Wait for data to be loaded
    if (!couponFx.data || couponFx.loading) return

    // Reset the param
    navigation.setParams({ addPromo: undefined })

    // Do not proceed if there are no edit permissions
    if (!hasAccessEdit) return

    addEditPromo()
  }, [addEditPromo, couponFx.data, couponFx.loading, hasAccessEdit, navigation, params.addPromo])

  return (
    <AdminView>
      <BackTo style={styles.backToBtn} onPress={() => navigation.navigate('CouponList')} title="Back to all Coupons" />
      <AdminCard>
        <LoadingView loading={couponFx.loading} success={couponFx.data}>
          {(coupon) => (
            <>
              {coupon.isSystemCoupon && (
                <ErrorText>This coupon is being used internally by GrownBy and cannot be modified.</ErrorText>
              )}
              <AdminCustomerHeader title={coupon.name} btns={headerBtn} />
              <View style={styles.infoRowWrapper}>
                <InfoRow data={{ left: 'Name', right: coupon.name }} />
                <InfoRow
                  data={{
                    left: 'Value',
                    right: isPercentCoupon(coupon)
                      ? DecimalCalc.multiply(coupon.value, 100) + '%'
                      : formatMoney(coupon.value),
                  }}
                />
                <InfoRow
                  data={{
                    left: 'Times Redeemed',
                    right: coupon.timesRedeemed.toString(),
                  }}
                />
                {coupon.ebtOnly && (
                  <InfoRow
                    data={{
                      left: 'This coupon is only allowed on EBT items',
                      right: '',
                    }}
                  />
                )}
              </View>
            </>
          )}
        </LoadingView>
      </AdminCard>
      <AdminHalfCardsContainer itemStyle={styles.adminHalfItem}>
        <View>
          <AdminCustomerHeader
            title="Associated Promo Codes"
            btns={
              couponFx.data && allowEdits ? [{ title: 'Add Promo Code', onPress: () => addEditPromo() }] : undefined
            }
            toolTipId={ToolTips.COUPON_PROMOCODE}
            toolTipTitle="Promo Codes"
          />
          <OfflineTable<PromoCode>
            headerColumns={[
              { title: 'Code', widthFlex: 2 },
              { title: 'Times Redeemed', widthFlex: 2 },
              { widthFlex: 0.5 },
              { widthFlex: 0.5 },
            ]}
            renderItem={renderPromoCodeRow}
            data={promoCodesFx.data}
            isLoading={promoCodesFx.loading}
            ListEmptyComponent={EmptyPromoCodes}
          />
        </View>
        <View>
          <AdminCustomerHeader title="Redemptions" toolTipId={ToolTips.COUPON_REDEMPTION} />
          <OfflineTable<Invoice>
            headerColumns={[
              { title: 'Order #', widthFlex: 1 },
              { title: 'Invoice #', widthFlex: 1 },
              { title: 'Promo Code', widthFlex: 1 },
              { title: 'Customer', widthFlex: 2 },
              { widthFlex: 0.5 },
            ]}
            renderItem={renderRedemptionRow}
            data={redemptionsFx.data}
            isLoading={redemptionsFx.loading}
            ListEmptyComponent={EmptyRedemptions}
          />
        </View>
      </AdminHalfCardsContainer>
    </AdminView>
  )
}
export const CouponDetails = withAdminAuth(CouponDetailsComp, Permission.ProductSetup)

const styles = StyleSheet.create({
  infoRowWrapper: { maxWidth: 300 },
  adminHalfItem: {
    flexBasis: 600, // Since we have tables inside those components, the base width should be larger than the default one
  },
  backToBtn: {
    marginHorizontal: 20,
    marginTop: 20,
  },
  btnFlex: {
    flex: 0.5,
    alignItems: 'center',
  },
})
