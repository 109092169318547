import { KeyedState } from '@/hooks/useKeyedState'
import { DraftOrder } from '@models/DraftOrder'
import { Product } from '@models/Product'
import { OrderCreatorStateProductsFilters } from '../OrderCreatorScreen/OrderCreatorScreen.helper'
import { useSchedulesAndDatesReusable } from '../OrderCreatorScreen/useSchedulesAndDates-reusable'

/** Data layer for any state related to schedules and dates in the order edit screen */
export function useSchedulesAndDates({
  draft,
  ...opts
}: {
  draft: DraftOrder | undefined
  keyedState: KeyedState<OrderCreatorStateProductsFilters>
  isLoadingProducts: boolean
  dbProds: Product[] | undefined
}) {
  return useSchedulesAndDatesReusable({
    ...opts,
    orderType: 'standard',
    farmId: draft?.farm.id || '',
    isWholesale: draft?.isWholesale,
  })
}
