import { Image } from '@components'
import {
  Button,
  CheckBox,
  Divider,
  ErrorText,
  fontSize,
  LoadingView,
  Text,
  TextH2,
  TextH4,
  ToggleButton,
} from '@elements'
import { isNonNullish, removeDuplicates } from '@helpers/helpers'
import { isPickupItemCancelled, Pickup, PickupItem } from '@models/Order'
import { isShare, isStandard } from '@models/Product'
import { useEffect, useMemo, useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'

import {
  cancelPickupOrClaimVacation,
  getVacationWeeks,
  isAllowedToCancel,
  setShouldCancel,
} from './helpers/cancelPickupHelper'

import Colors from '@/constants/Colors'
import { getOrderItem } from '@helpers/display'
import { useApiFx } from '../../hooks/useApiFx'
import { ordersCollection } from '@api/framework/ClientCollections'

export type ItemDetails = {
  pickupItem: PickupItem
  vacationWeeks: {
    value: boolean
    msg: string
  }
  shouldCancel?: boolean
}

type CancelPickupProps = {
  /** The Pickup for which this modal will cancel or claim-vacation on some pickup items */
  pickup: Pickup
  /** If a pickup item filter is passed, the modal will only show pickupItems which fulfill that condition. Useful for filtering by a certain order item. */
  pickupItemFilter?: (pItem: PickupItem) => boolean
  /** claim means either claimVacation or cancelPickup, unclaim means unclaimVacation */
  type?: 'unclaim' | 'claim'
  /** onSuccess will be called on completion of a pickup cancel or vacation claimed */
  onSuccess?: () => void
  isAdmin?: boolean
}

/**
  The CancelPickup modal is modified by previous modal claimVacation.
  Now, this CancelPickup would be used for both claimVacation and cancelPickup.
  For share product pickup, this CancelPickup modal would claimVacation with refund and cancelPickup without refund.
  For standard product pickup, this CancelPickup modal would cancelPickup with refund because of buying in future feature.
 */
export function CancelPickup({
  pickup,
  type = 'claim',
  onSuccess,
  pickupItemFilter = () => true,
  isAdmin = false,
}: CancelPickupProps) {
  const [items, setItems] = useState<ItemDetails[]>([])
  const [isLoading, setLoading] = useState(false)
  const [notAwardCredit, setNotAwardCredit] = useState(false)
  const shareItms = useMemo(() => items.filter((dItm) => isShare(dItm.pickupItem.product)), [items])
  const stdItms = useMemo(() => items.filter((dItm) => isStandard(dItm.pickupItem.product)), [items])

  // Loads the orders from the pickup items
  const orderIds = useMemo(() => removeDuplicates(pickup.items.map((pi) => pi.order.id)), [pickup.items])
  const ordersApi = useApiFx(ordersCollection.fetchByIds.bind(ordersCollection), [orderIds])

  /** Sets the ItemDetails data */
  useEffect(() => {
    if (ordersApi.loading || ordersApi.data === undefined) return
    setItems(
      pickup.items
        .filter(pickupItemFilter)
        .filter((pickupItem) => !isPickupItemCancelled(pickupItem))
        .map((pickupItem, _, arr) => {
          const orderItem = getOrderItem(ordersApi.data!, pickupItem)
          if (!orderItem) return undefined
          const vacationWeeks = getVacationWeeks({
            pickupItem,
            pickup,
            isAdmin,
            orderItem,
            type,
            notAwardCredit,
          })
          return {
            pickupItem,
            vacationWeeks,
            shouldCancel: arr.length === 1 && vacationWeeks?.value, // Auto-selects "shouldCancel" if there's a single item
          }
        })
        .filter(isNonNullish),
    )
    // 'pickupItemFilter' is intentionally left out of dependencies, because it is usually passed as inline function for practicality. It also is not expected to ever change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickup, isAdmin, notAwardCredit, type, ordersApi.data, ordersApi.loading])

  return (
    <LoadingView loading={ordersApi.loading}>
      <ScrollView style={styles.scrollViewCont}>
        {shareItms.length ? (
          <>
            {/* shareProduct cancelPickup or claimVacation */}
            {/* admin side only claim or cancel one share item a time */}
            {!isAdmin && (
              <TextH2 style={styles.headerText}>Select all subscription items to skip for this pickup</TextH2>
            )}
            {shareItms.map(({ pickupItem, vacationWeeks, shouldCancel }, idx) => (
              <View key={idx}>
                <View style={styles.card}>
                  <Image
                    source={{
                      uri: pickupItem.product.image,
                    }}
                    style={styles.cardImage}
                    resizeMode="cover"
                  />
                  <View style={styles.textContent}>
                    <TextH2 numberOfLines={1}>{pickupItem.product.name}</TextH2>
                    <Text numberOfLines={1} style={styles.description}>
                      Quantity: {pickupItem.orderItem.quantity}
                    </Text>
                    <TextH4 style={styles.errorText} color={vacationWeeks.value ? Colors.green : Colors.red}>
                      {vacationWeeks.msg}
                    </TextH4>
                  </View>
                  <View>
                    <CheckBox
                      size={30}
                      checked={!!shouldCancel}
                      onChange={(val) => setShouldCancel(idx, val, setItems)}
                      disabled={!notAwardCredit && !vacationWeeks.value}
                    />
                  </View>
                </View>
              </View>
            ))}
            {type === 'claim' && isAdmin && (
              <>
                <ToggleButton
                  title="Cancel and don't award credit?"
                  value={notAwardCredit}
                  onChange={setNotAwardCredit}
                />
                <Divider clear />
                <Text>
                  Choosing "cancel and don't award credit" will cancel the pickup, and not refund the customer or use
                  one of their vacation weeks. Otherwise the user will be awarded farm credit for the value of their
                  share and their vacation weeks will be decremented.
                </Text>
              </>
            )}
            {!isAdmin && (
              <>
                <Divider clear />
                <Text>
                  Subscription shares may include a set number of pickups that you can skip and receive farm credit.
                </Text>
              </>
            )}
          </>
        ) : null}
        {stdItms.length ? (
          <>
            {/* standardProduct cancelPickup */}
            <Divider clear />
            {/* admin side only cancel one standard item a time */}

            {stdItms.map(({ pickupItem, shouldCancel }, idx) => {
              const { value, msg, deadlineMessage } = isAllowedToCancel({
                pickup,
                isAdmin,
                pickupItem,
              })
              return (
                <View key={idx}>
                  <View style={styles.card}>
                    <Image
                      source={{
                        uri: pickupItem.product.image,
                      }}
                      style={styles.cardImage}
                      resizeMode="cover"
                    />
                    <View style={styles.textContent}>
                      <TextH2 numberOfLines={1}>{pickupItem.product.name}</TextH2>
                      <Text numberOfLines={1} style={styles.description}>
                        Quantity: {pickupItem.orderItem.quantity}
                      </Text>
                      <TextH4 style={styles.errorText} color={value ? Colors.green : Colors.red}>
                        {msg}
                      </TextH4>
                      <ErrorText>{deadlineMessage}</ErrorText>
                    </View>
                    <View>
                      <CheckBox
                        disabled={!value}
                        size={30}
                        checked={shouldCancel ?? false}
                        onChange={(val) => setShouldCancel(idx, val, setItems)}
                      />
                    </View>
                  </View>
                </View>
              )
            })}
            <Divider clear />
            <Text>
              If cancelling before your farm's Cancel Deadline, you may be able to receive a refund. Please reach out to
              your farmer with any questions.
            </Text>
          </>
        ) : null}
      </ScrollView>
      <Divider clear />
      <Button
        loading={isLoading}
        disabled={!items.some(({ shouldCancel }) => shouldCancel)}
        title="Submit"
        onPress={() =>
          cancelPickupOrClaimVacation({
            items: items.filter(({ shouldCancel }) => shouldCancel),
            pickup,
            type,
            notAwardCredit,
            onSuccess,
            setLoading,
            isAdmin,
          })
        }
      />
    </LoadingView>
  )
}

const styles = StyleSheet.create({
  scrollViewCont: { minHeight: 150, marginHorizontal: 20 },
  card: {
    display: 'flex',
    flexDirection: 'row',
    marginVertical: 5,
    alignItems: 'center',
  },
  cardImage: {
    borderRadius: 10,
    flex: 1,
    aspectRatio: 1,
    alignSelf: 'center',
    maxWidth: 108,
  },
  headerText: {
    fontSize: fontSize(16),
    margin: 10,
  },
  textContent: {
    flex: 2,
    padding: 10,
  },
  description: {
    color: Colors.shades[300],
    fontSize: 12,
  },
  errorText: {
    flexWrap: 'wrap',
  },
})
