import { payInvoiceOffline } from '@api/Invoices'
import { Alert, Divider, FormButton, FormInput, hideModal, Toast } from '@elements'
import { Formik, FormikProps } from 'formik'
import { useState } from 'react'
import { Text, View } from 'react-native'
import * as Yup from 'yup'

import { Logger } from '../../../../../config/logger'
import { globalStyles } from '../../../../../constants/Styles'

type FormType = {
  note: string
}

const initialValues: FormType = {
  note: '',
}

const validationSchema = Yup.object<FormType>().shape({
  note: Yup.string(),
})

/** Modal to mark an invoice as paid offline */
export function MarkPaid({ invoiceId, onPaid }: { invoiceId: string; onPaid?: () => void }) {
  const [loading, setLoading] = useState(false)

  const onSubmitHandler = async (values: FormType) => {
    setLoading(true)
    try {
      await payInvoiceOffline(invoiceId, values.note || undefined)
      setLoading(false)
      hideModal()
      Alert('Success!', 'Invoice has been successfully marked as paid. The changes will show up shortly.', [
        {
          text: 'OK',
          onPress: onPaid,
          style: 'default',
        },
      ])
    } catch (e) {
      Logger.error(e)
      setLoading(false)
      hideModal()
      Toast('Could not mark invoice as paid, please try again later or contact support if this issue persists.')
    }
  }
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmitHandler} validationSchema={validationSchema}>
      {({ values, handleChange, handleSubmit, handleBlur }: FormikProps<FormType>) => (
        <View style={globalStyles.margin10}>
          <Text>
            You can add a note for future reference like a check number or "paid in cash". This note will be shown on
            your customer's receipt.
          </Text>
          <Divider clear />
          <FormInput
            multiline
            numberOfLines={2}
            placeholder="Note"
            value={values.note}
            onChangeText={handleChange('note')}
            onBlur={handleBlur('note')}
          />
          <FormButton loading={loading} title="Submit" onPress={handleSubmit} />
          <Text>
            Are you sure you want to mark this invoice as paid? Only do this if you accepted the payment offline.
          </Text>
        </View>
      )}
    </Formik>
  )
}
