import { loadFarm } from '@api/Farms'
import { chargeInvoice, invoiceValidateDiscountConsumer, loadInvoice } from '@api/Invoices'
import { loadOrder } from '@api/Orders'
import { LoaderWithMessage, Payment3DAuth } from '@components'
import {
  Alert,
  Button,
  ButtonClear,
  Divider,
  ErrorText,
  LoadingView,
  Modal,
  Spinner,
  Text,
  TextH1,
  Toast,
} from '@elements'
import { AntDesign } from '@expo/vector-icons'
import { deepClone, errorToString, extendErr } from '@helpers/helpers'
import { openInvoiceReceiptPDF } from '@helpers/links'
import { MoneyCalc } from '@helpers/money'
import { CoverFee, CoverOptId, getServiceFeeAmountFromTender } from '@helpers/serviceFee'
import { Discount, PromoCode } from '@models/Coupon'
import {
  Invoice,
  InvoiceNextStep,
  InvoiceStatus,
  getInvoiceDiscounts,
  getInvoiceTips,
  invoiceEbtEligibleAmount,
  invoiceItemTotal,
} from '@models/Invoice'
import { Zero } from '@models/Money'
import { SplitTenderPayment, isSpecialOrder } from '@models/Order'
import { isEbtPayment, isFarmCreditPayment, pmt_CashMethod } from '@models/PaymentMethod'
import { validateEbtPin } from '@screens/PaymentMethods/validateEbtPin'
import * as Clipboard from 'expo-clipboard'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import { invoiceApplySplitTender } from '../../../constants/services/splitTender'
import useKeyedState from '../../../hooks/useKeyedState'
import { EnterPromo } from '../../Shopping/components/EnterPromo'
import { OrderCoverComponent } from '../../Shopping/components/OrderCover'
import { InvoiceHeader } from './InvoiceHeader'
import { OrderDetails } from './OrderDetails'
import { OrderDetailsHeader } from './OrderDetailsHeader'
import { PaidInvoice } from './PaidInvoice'

import {
  addInvoiceAdjustment,
  markInvoicePaid,
} from '@/admin/screens/Customer/AdminCustomerInvoicesSection/helpers/invoiceActions'
import { grownbyWebsiteBaseUrl } from '@/config/Environment'
import { Logger } from '@/config/logger'
import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { useApiFx } from '@/hooks/useApiFx'
import { useHasPermissionWithFlag } from '@/hooks/useHasPermission'
import { addNavProp } from '@/redux/actions/appState'
import { userSelector, wholesaleSelector } from '@/redux/selectors'
import { AccessRight, Permission } from '@helpers/Permission'
import { pick } from '@helpers/typescript'
import { PaymentSelector } from '../../PaymentMethods/PaymentSelection/PaymentSelector'
import { PaymentSelectorOptions } from '../../PaymentMethods/PaymentSelection/helpers/types'

type Props = {
  id: string
  onClosePress?: () => void
}

/** screen component reused for invoice landing page (invoice external), and invoice details (invoiceConsumer) because this component will be used in customer side and admin side, so it should be careful the usage of hooks and redux selector. */
export function InvoiceBase({ id, onClosePress }: Props) {
  const user = useSelector(userSelector)
  const { isWholesale } = useSelector(wholesaleSelector)
  const { data: invoice, ...invoiceApi } = useApiFx(loadInvoice, [id])
  const { data: order, loading: loadingOrder } = useApiFx(
    loadOrder,
    [invoice?.order.id],
    !!invoice?.order.id && !isSpecialOrder(invoice.order),
    { failedConditionMode: 'stop-loading' },
  )
  const [splitTender, setSplitTender] = useState<SplitTenderPayment>()
  const [paymentSelectorOptions, setPaymentSelectorOptions] = useState<PaymentSelectorOptions>()
  const [toggles, setToggle] = useKeyedState({
    isSubmitting: false,
    showInvDetails: true,
    showOrdDetails: false,
    discountLoading: false,
  })
  const [coverFee, setCoverFee] = useState<CoverFee>({ id: CoverOptId.None, tip: Zero, value: Zero })
  const dispatch = useDispatch()
  const farmApi = useApiFx(loadFarm, [invoice?.farm.id || ''], !!invoice?.farm.id)
  const [discount, setDiscount] = useState<Discount>()
  const changeInvoicePermit = useHasPermissionWithFlag(Permission.Orders, AccessRight.Edit)
  const hasExistingInvoiceTips = useMemo(
    () => (invoice ? !MoneyCalc.isZero(getInvoiceTips(invoice)) : false),
    [invoice],
  )

  const styles = useStyles()

  const amountDue = useMemo(
    () => (invoice ? MoneyCalc.subtract(invoice.amountTotal, invoice.amountPaid || Zero) : Zero),
    [invoice],
  )

  const verifyPayment = (url: string) => {
    Modal(
      <Payment3DAuth
        url={url}
        close={() => {
          setTimeout(() => {
            invoiceApi.refresh()
          }, 1000)
        }}
      />,
      {
        header: false,
        noPadding: true,
      },
    )
  }

  // The amount that is set to be paid with EBT
  const ebtPmtAmount = splitTender?.find((pay) => isEbtPayment(pay.paymentMethod))

  const serviceFeeAmount = useMemo(() => {
    const tip = invoice?.items.find((itm) => itm.id === 'tip')
    // If the invoice already has tip, we directly return the tip amount
    const hasTip = !!invoice && !!tip
    if (hasTip) return invoiceItemTotal(tip)

    return splitTender
      ? getServiceFeeAmountFromTender(splitTender, amountDue, farmApi.data?.pricingModel?.appFeePercent)
      : Zero
  }, [invoice, splitTender, amountDue, farmApi.data?.pricingModel?.appFeePercent])

  /** Update the payment selector options */
  useEffect(() => {
    if (farmApi.loading || !farmApi.data || farmApi.err) return

    const amountTotal = MoneyCalc.add(amountDue, coverFee?.value ?? Zero)
    const ebtEligibleAmount = invoice ? invoiceEbtEligibleAmount(invoice) : Zero
    setPaymentSelectorOptions({
      farm: pick(farmApi.data, 'id', 'name', 'offlinePayments', 'paymentTypes'),
      amountTotal,
      // Don't allow the EBT amount to be greater than the total, this can happen if the invoice has an adjustment
      amountEbt: MoneyCalc.min(amountTotal, ebtEligibleAmount),
      // On the invoice page we are paying a single invoice so there will never be future payments and no need to collect a backup payment method
      hasFuturePayments: false,
      hasDelivery: false,
      // You cannot purchase farm credit offline, so it will never be on a due invoice
      hasFarmBalanceItem: false,
      // Invoices paid from this page will be paid immediately
      isInvoiceDueToday: true,
      // We do not allow someone to pay offline when they are directly paying an invoice
      allowOfflinePayments: false,
    })
  }, [farmApi, amountDue, invoice, coverFee?.value])

  const downloadInvoice = useCallback(() => invoice?.id && openInvoiceReceiptPDF(invoice.id), [invoice?.id])

  //isOwner will be true if the user is the owner of the invoice or the order.
  const isOwner = (invoice: Invoice) => user?.id === invoice.user.id

  const payInvoice = async () => {
    if (!invoice) return

    try {
      // Make sure payments are defined, this should never be false after the payment selector confirms it
      if (!splitTender || splitTender.length === 0) {
        return Alert('Invoice Payment Error', 'No payment methods specified for this invoice')
      }

      // If there is an ebt payment than check the pin and request it if necessary
      const ebtPayment = splitTender?.find((pmt) => isEbtPayment(pmt.paymentMethod))
      if (ebtPayment && isEbtPayment(ebtPayment.paymentMethod)) {
        const { pin } = await validateEbtPin(ebtPayment.paymentMethod, user.id, true)
        ebtPayment.paymentMethod.pin = pin
      }

      setToggle('isSubmitting', true)

      await chargeInvoice(invoice.id, splitTender, { fees: coverFee, discount })
      invoiceApi.refresh()

      Toast('Invoice successfully paid')
    } catch (err) {
      const msg = errorToString(err)
      Logger.error(extendErr(err, 'Error paying invoice.'))
      if (msg.includes('User cancelled')) {
        setToggle('isSubmitting', false)
      }
      Alert('Failed to pay invoice: ', msg)
    }
    setToggle('isSubmitting', false)
  }

  const removeDiscount = useCallback(async (): Promise<void> => {
    if (!invoice?.id) throw new Error('Invoice is not defined but is required to remove a discount')
    try {
      const newInv = deepClone(invoice)
      // remove the discount from the invoice and reapply split tender to get the correct value
      delete newInv.couponApplied
      invoiceApplySplitTender(newInv, splitTender ?? [{ paymentMethod: pmt_CashMethod }])

      setDiscount(undefined)
      dispatch(addNavProp({ invoice: newInv }))
    } catch (e) {
      setToggle('discountLoading', false)
      // This will come from calculatePayments when the user has not selected all their payment methods and tries to add an EBT payment method
      if (errorToString(e) === 'This order requires an additional payment method.') {
        Alert(
          'Payment method required',
          "Please add an additional payment method below then re-add the coupon to see it's updated value",
        )
      } else {
        throw e
      }
    }
  }, [dispatch, invoice, setToggle, splitTender])

  /** adds a promo to the invoice */
  const addPromo = useCallback(
    async (promoCode: PromoCode['code']): Promise<void> => {
      if (!invoice?.id) throw new Error('Invoice is not defined but is required to add a discount')

      setToggle('discountLoading', true)

      try {
        const discount = await invoiceValidateDiscountConsumer(invoice?.id, promoCode)
        if (!discount) {
          Alert('Adding discount failed', 'Unable to add this discount to your invoice')
          setToggle('discountLoading', false)
          return
        }
        // If this is an EBT only coupon then make sure that there is an EBT payment added
        if (discount?.coupon.ebtOnly && MoneyCalc.isZero(ebtPmtAmount?.amount ?? Zero)) {
          Alert('Adding discount failed', 'You must select an EBT payment method in order to apply this discount')
          setToggle('discountLoading', false)
          return
        }

        const newInv = deepClone(invoice)
        // Add the discount to the invoice and reapply split tender to get the correct value with restrictions
        newInv.couponApplied = discount
        invoiceApplySplitTender(newInv, splitTender ?? [{ paymentMethod: pmt_CashMethod }])

        // If the discount does not change the total amount then we will notify the customer that it wasn't applied and remove
        if (MoneyCalc.isEqual(invoice.amountTotal, newInv.amountTotal)) {
          Alert(
            'Adding discount failed',
            'This coupon cannot be added because no items on the invoice are eligible for the discount.',
          )
          await removeDiscount()
          setToggle('discountLoading', false)
          return
        }

        setDiscount(discount)
        invoiceApi.setState((prev) => ({ ...prev, data: newInv }))
        setToggle('discountLoading', false)
      } catch (e) {
        setToggle('discountLoading', false)
        // This will come from calculatePayments when the user has not selected all their payment methods and tries to add an EBT payment method
        if (errorToString(e) === 'This order requires an additional payment method.') {
          Alert(
            'Payment method required',
            "Please add an additional payment method below then re-add the coupon to see it's updated value",
          )
        } else {
          throw e
        }
      }
    },
    [ebtPmtAmount?.amount, invoice, setToggle, splitTender, invoiceApi, removeDiscount],
  )

  if (invoiceApi.loading || loadingOrder) {
    return <Spinner />
  }

  if (
    invoice &&
    (invoice?.status === InvoiceStatus.Paid ||
      invoice?.status === InvoiceStatus.Refunded ||
      invoice?.status === InvoiceStatus.Void)
  ) {
    return (
      <>
        {onClosePress && <AntDesign name="close" size={36} style={styles.margin16} onPress={onClosePress} />}
        <PaidInvoice
          invStatus={invoice?.status}
          invoice={invoice}
          showInvoiceDetails={toggles.showInvDetails}
          onDownloadPress={downloadInvoice}
        />
      </>
    )
  }

  const invoiceLink = invoice ? `${grownbyWebsiteBaseUrl(isWholesale)}external/invoice/${invoice.id}` : ''

  return (
    <>
      {onClosePress && <AntDesign name="close" size={36} style={styles.margin16} onPress={onClosePress} />}
      {(invoice && order) || invoice?.order.id === 'manual_invoice' ? (
        <View>
          <InvoiceHeader invoice={invoice} amountDue={amountDue} onDownloadPress={downloadInvoice} />
          {isOwner(invoice) ? (
            <View style={styles.borderedCont}>
              {invoice.status === InvoiceStatus.Incomplete &&
              invoice.nextStep?.type === InvoiceNextStep.PENDING_BANK_AUTHORIZATION ? (
                <View>
                  <TextH1>Pending Bank Payment</TextH1>
                  <Text>
                    This payment is pending and may take up to 4 days to be successful. Please contact support if it has
                    been more than 4 days and your payment is still pending.
                  </Text>
                </View>
              ) : invoice.status === InvoiceStatus.Incomplete ? (
                <View>
                  <TextH1>Payment Verification Required</TextH1>
                  <Text>This invoice requires an additional step of verification in order to complete the payment</Text>
                  <Divider clear />
                  {invoice.nextStep?.type === InvoiceNextStep.STRIPE_3D_SECURE &&
                  invoice.nextStep?.url !== undefined ? (
                    <Button
                      loading={toggles.isSubmitting}
                      title="Complete Verification"
                      onPress={
                        () =>
                          invoice.nextStep?.type === InvoiceNextStep.STRIPE_3D_SECURE &&
                          verifyPayment(invoice.nextStep.url) // Typescript is complaining if I don't check again that the type is stripe-3D-secure
                      }
                    />
                  ) : (
                    <ErrorText size={14}>Please contact support to get your payment verified</ErrorText>
                  )}
                </View>
              ) : (
                <>
                  {/* We should only show this component when existing invoice tip is Zero*/}
                  {!hasExistingInvoiceTips && (
                    <OrderCoverComponent
                      tipOptions={farmApi.data?.tipsAndFees}
                      serviceFeeAmount={serviceFeeAmount}
                      coverFee={coverFee}
                      onUpdateCoverFee={setCoverFee}
                      hideInstallments
                      isWholesalePayment={!!invoice.order.isWholesale}
                    />
                  )}
                  <EnterPromo
                    addPromo={addPromo}
                    removeDiscount={removeDiscount}
                    discount={discount}
                    loading={toggles.discountLoading}
                    ebtTotal={ebtPmtAmount?.amount ?? Zero}
                  />
                  <LoadingView loading={!paymentSelectorOptions}>
                    <PaymentSelector
                      disabled={toggles.isSubmitting}
                      userId={user.id}
                      uniqueId={invoice.id}
                      options={paymentSelectorOptions!}
                      onSplitTenderUpdated={setSplitTender}
                    />
                  </LoadingView>
                  <Button loading={toggles.isSubmitting} title="Pay Invoice" onPress={payInvoice} />
                </>
              )}
            </View>
          ) : (
            <View style={styles.borderedCont}>
              <TextH1>Payment</TextH1>
              <View style={styles.spacing} />
              <Text size={14}>To have your customer pay this invoice online, you can send them the link below</Text>
              <View style={styles.spacing} />

              <View style={[styles.borderedCont, styles.copyTextCont]}>
                <Text numberOfLines={1} size={14}>
                  {invoiceLink}
                </Text>
                <ButtonClear
                  style={styles.copyLinkBtn}
                  title="Copy"
                  onPress={async () => {
                    await Clipboard.setStringAsync(invoiceLink)
                    Toast('Link copied!')
                  }}
                />
              </View>
              {changeInvoicePermit && (
                <>
                  <Text size={15} center type="bold">
                    OR
                  </Text>
                  <View style={styles.spacing} />
                  <View style={[globalStyles.flexRowCenter, { justifyContent: 'center' }]}>
                    <Button
                      style={styles.btn}
                      outline
                      onPress={() => {
                        markInvoicePaid(invoice, invoiceApi.refresh)
                      }}
                      title="Mark Paid Offline"
                    />
                    <Button
                      style={styles.btn}
                      outline
                      onPress={() => {
                        addInvoiceAdjustment(invoice, invoiceApi.refresh)
                      }}
                      title="Add Adjustment"
                    />
                  </View>
                </>
              )}
            </View>
          )}

          <View style={styles.borderedCont}>
            <TextH1>
              {invoice.order.id === 'manual_invoice'
                ? 'Manual Invoice'
                : invoice.order.id === 'balance_transaction'
                ? 'Monthly Balance Reload'
                : 'Order details'}
            </TextH1>
            {!isSpecialOrder(invoice.order) && order && <OrderDetailsHeader order={order} />}

            <ButtonClear
              size={12}
              style={styles.paddingLeft}
              onPress={() => setToggle('showOrdDetails', !toggles.showOrdDetails)}
              title={!toggles.showOrdDetails ? 'View full details' : 'Hide full details'}
            />
            {toggles.showOrdDetails && (
              <OrderDetails
                fcAmount={splitTender?.find((pay) => isFarmCreditPayment(pay.paymentMethod))?.amount ?? Zero}
                tips={
                  isOwner(invoice) && !hasExistingInvoiceTips
                    ? coverFee
                    : { id: CoverOptId.Custom, value: getInvoiceTips(invoice), tip: Zero }
                }
                invoice={invoice}
                discount={getInvoiceDiscounts(invoice)}
              />
            )}
          </View>
        </View>
      ) : (
        <LoaderWithMessage loading={invoiceApi.loading || loadingOrder} icon="shopping-bag" title="Not Found!">
          <Text>This invoice could not be loaded, please go back and try again.</Text>
        </LoaderWithMessage>
      )}
    </>
  )
}
const useStyles = CreateResponsiveStyle(
  {
    borderedCont: {
      borderWidth: 1,
      borderColor: Colors.shades[100],
      borderRadius: 10,
      padding: 20,
      marginVertical: 10,
    },
    copyTextCont: {
      padding: 10,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    copyLinkBtn: {
      padding: 0,
      marginLeft: 3,
    },
    margin16: {
      marginVertical: 16,
    },
    spacing: { height: 10 },
    btn: {
      alignSelf: 'center',
    },
    paddingLeft: {
      paddingLeft: 0,
    },
  },
  {
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      borderedCont: {
        padding: 5,
      },
      copyTextCont: {
        padding: 5,
      },
      spacing: {
        height: 5,
      },
      btn: {
        paddingHorizontal: 5,
      },
    },
  },
)
