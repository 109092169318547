import { CheckBox, Divider, ErrorText, Icon, Text } from '@elements'
import { InvalidAmount, capitalize, formatPickupDate, getDayofWeekName } from '@helpers/display'
import { getProductFrequency } from '@helpers/products'
import { format, formatDateRange } from '@helpers/time'
import { omit } from '@helpers/typescript'
import { Distribution } from '@models/Distribution'
import { isCartPhysical } from '@models/Order'
import { PhysicalProduct, ProductType, isShare } from '@models/Product'
import { Frequency, isSeasonalSchedule } from '@models/Schedule'
import { memo, useCallback, useMemo, useState } from 'react'
import { StyleSheet, View } from 'react-native'

import Colors from '../../constants/Colors'
import { DividerVertical } from '../elements/DividerVertical'

import { CartServiceType } from '@/constants/types/cartService'
import { useCartService } from '@/hooks/useCart'
import { sortByProperty } from '@helpers/sorting'
import { DistroExtended } from './PickupLocation'

type Props = {
  /** These distros belong to the product in the `product` prop, and possess extended attributes. They are assumed to be a group of distros associated with the same location */
  distros: DistroExtended[]
  product: PhysicalProduct
  /** If onPress is defined, there will be a checkbox UI, and this will be the handler for the distro selected by checkbox/button */
  onPress?: (distro: Distribution) => void
  cartServiceType?: CartServiceType
  isWholesale?: boolean
}

export const PickupOpts = memo(function PickupOpts({
  distros,
  product,
  onPress,
  cartServiceType = 'consumer',
  isWholesale,
}: Props) {
  const { cart, isAdmin } = useCartService({ cartServiceType, isWholesale })

  const cartDistrosIds = useMemo(() => cart.filter(isCartPhysical).map((ci) => ci.distribution.id), [cart])

  const data = useMemo(
    () =>
      distros
        .filter((d) => !d.isHidden && (isAdmin ? true : !d.closed))
        .sort(sortByProperty(['daily', 'weekly', 'biweekly', 'monthly'], (item) => item.schedule.frequency)),
    [distros, isAdmin],
  )
  const [checked, setChecked] = useState<string>('') //Distro id

  const onChecked = useCallback(
    (dist: DistroExtended) => {
      setChecked(dist.id)
      setTimeout(() => {
        // Please omit the extended attributes before passing the selected distro to onPress

        type ExtraFields = Exclude<keyof DistroExtended, keyof Distribution>
        // This type will prevent adding extended data to the cartitem schedule, by requiring any new keys to be added here, in case someone forgets to update this
        const extraFields: { [K in ExtraFields]: 0 } = {
          disable: 0,
          firstPickup: 0,
          pickups: 0,
          hideCheckbox: 0,
          orderDeadline: 0,
        }
        const keys = Object.keys(extraFields) as ExtraFields[]

        const result: Distribution = omit(dist, ...keys)
        onPress?.(result)
      }, 10)
    },
    [onPress],
  )

  return (
    <>
      {data.map((dist) => (
        <PickupOptItem
          dist={dist}
          product={product}
          cartDistrosIds={cartDistrosIds}
          checked={checked}
          onChecked={onPress ? onChecked : undefined}
          key={data[0].location.id + dist.id}
          isAdmin={isAdmin}
        />
      ))}
    </>
  )
})

type PickupOptItemProps = {
  dist: DistroExtended
  product: Props['product']
  cartDistrosIds: string[]
  checked: string
  onChecked?: (d: DistroExtended) => any
  isAdmin?: boolean
}

const PickupOptItem = memo(function PickupOptItem({
  dist,
  product,
  cartDistrosIds,
  checked,
  onChecked,
  isAdmin = false,
}: PickupOptItemProps) {
  const freq = getProductFrequency(product, dist.id, {
    strict: false,
    excludeClosedDistros: !isAdmin,
  })
  return (
    <View key={dist.id}>
      <Divider />
      <View style={styles.optContainer}>
        {!onChecked || dist.hideCheckbox ? (
          product.type === ProductType.Standard ? (
            <Icon name="calendar-day" />
          ) : (
            <Icon name="calendar-week" />
          )
        ) : (
          <CheckBox checked={checked === dist.id} onChange={() => onChecked?.(dist)} />
        )}
        <DividerVertical clear />
        <View style={styles.fill}>
          {!dist.pickups.length ? (
            <Text children="No pickups available" />
          ) : isShare(product) ? (
            <>
              <Text type="medium">{capitalize(freq ?? 'Bad data: Frequency')}</Text>
              <Text type="medium">{`starting ${formatPickupDate(dist.firstPickup!)}`}</Text>
              <Text type="medium">{`for ${dist.pickups.length} ${
                freq ? pickupNicknames[freq] : 'Bad data: Frequency'
              }`}</Text>
            </>
          ) : (
            <View style={styles.multiPickupView}>
              <Text type="medium">{`${
                product.disableBuyInFuture
                  ? ''
                  : `${
                      dist.schedule.frequency === Frequency.WEEKLY || dist.schedule.frequency === Frequency.BIWEEKLY
                        ? `${getDayofWeekName(dist.schedule.dayOfWeek)}s, ${capitalize(dist.schedule.frequency)}`
                        : dist.schedule.frequency === Frequency.MONTHLY || dist.schedule.frequency === Frequency.DAILY
                        ? capitalize(dist.schedule.frequency)
                        : InvalidAmount
                    }\n`
              }${
                dist.pickups.length === 1 || product.disableBuyInFuture
                  ? formatPickupDate(dist.firstPickup)
                  : isSeasonalSchedule(dist.schedule)
                  ? formatDateRange(
                      {
                        startDate: dist.pickups[0],
                        endDate: dist.pickups[dist.pickups.length - 1],
                      },
                      'MMM dd, yyyy',
                    )
                  : `Starting on ${formatPickupDate(dist.firstPickup)}`
              }`}</Text>
            </View>
          )}
          {(isShare(product) || dist.pickups.length === 1 || product.disableBuyInFuture) && (
            <ErrorText style={styles.errTxt}>
              {dist.closed
                ? 'This schedule is not accepting orders at this time'
                : dist.orderDeadline
                ? `Order deadline: ${format(dist.orderDeadline, "MM/dd/yyyy 'at' h:mma")}`
                : 'No future pickups'}
            </ErrorText>
          )}
          {cartDistrosIds.includes(dist.id) && (
            <ErrorText color={Colors.green}>
              <Icon solid name="check-square" size={12} /> You're already picking up here
            </ErrorText>
          )}
        </View>
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  multiPickupView: { marginVertical: 5 },
  errTxt: { paddingTop: 5 },
  fill: { flex: 1 },
  flatlist: { flex: 1, marginVertical: 5 },
  optContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
  },
})

const pickupNicknames: Record<Frequency, string> = {
  daily: 'days',
  weekly: 'weeks',
  monthly: 'months',
  biweekly: 'bi-weekly pickups',
}
