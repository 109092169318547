import { Divider, Text } from '@elements'
import { FILTERS } from '@models/Algolia'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback, useContext, useMemo } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { CheckBox } from '../../components/elements/CheckBox'
import ModalInline from '../../components/elements/Overlays/Modal/ModalInline'
import { isWeb } from '../../constants/Layout'
import { HomeParamList } from '../../navigation/types'
import { certificationSelector, searchFiltersSelector } from '../../redux/selectors'
import FilterButton, { SubFilterButtons } from './FilterButton'
import RefinementListCert from './RefinementListCert'
import RefinementListParam from './RefinementListParam'
import { useBuildFilters, useFiltersIncludes } from './buildFilters'
import { ExploreContext } from './components/ExploreContext'
import { handleRefine } from './helpers'
import { AttributeRefinementsProp, withAttributeRefinements } from './withListRefinement'

/** Component displayed at the top of the map, with the dropdown map filters */
function FilterMenuComp({ attributeRefinements }: AttributeRefinementsProp) {
  const filters = useSelector(searchFiltersSelector)
  const practices = useSelector(certificationSelector)
  const getCert = (id: string) => practices.find((cert) => cert.id === id)
  const navigation = useNavigation<StackNavigationProp<HomeParamList, 'ExploreScreen'>>()
  const { showModal, setShowModal } = useContext(ExploreContext)

  const buildFilters = useBuildFilters()
  const filtersIncludes = useFiltersIncludes(filters)

  const farmFiltersButtons: SubFilterButtons = [
    {
      label: 'Farms selling now',
      onPress: buildFilters(FILTERS.Registered),
      state: filtersIncludes(FILTERS.Farm) && (!filters[2] || filtersIncludes(FILTERS.Registered)),
    },
    {
      label: 'Farms not yet on GrownBy',
      onPress: buildFilters(FILTERS.NotRegistered),
      state: filtersIncludes(FILTERS.Farm) && (!filters[2] || filtersIncludes(FILTERS.NotRegistered)),
    },
  ] //sort by label Farm selling now first
    .sort((a, b) => b.label.localeCompare(a.label))

  const productFilters: SubFilterButtons = attributeRefinements['category'].items
    .sort((a, b) => b.count - a.count)
    .map((itm) => ({
      label: `${itm.label} (${itm.count})`,
      onPress: () =>
        handleRefine({
          item: itm,
          listProps: attributeRefinements['category'],
          updateParam: (category?: string) => navigation.setParams({ category }),
        }),
      state: itm.isRefined,
    }))

  const certFilters: SubFilterButtons = attributeRefinements['farm.practices'].items
    .sort((a, b) => b.count - a.count)
    .map((itm) => ({
      label: `${getCert(itm.label)?.title || itm.label} (${itm.count})`,
      onPress: () =>
        handleRefine({
          item: itm,
          listProps: attributeRefinements['farm.practices'],
          updateParam: (v?: string) => navigation.setParams({ certifications: v }),
        }),
      state: itm.isRefined,
    }))

  const tagFilters: SubFilterButtons = attributeRefinements['farm.tags'].items
    .sort((a, b) => b.count - a.count)
    .map((itm) => ({
      label: `${itm.label} (${itm.count})`,
      onPress: () =>
        handleRefine({
          item: itm,
          listProps: attributeRefinements['farm.tags'],
          updateParam: (v?: string) => navigation.setParams({ tags: v }),
        }),
      state: itm.isRefined,
    }))

  // TODO: For iPad's or any large device that is not a web browser it will show only the all filters button because the
  //  dropdowns do not work on iPad. Will be fixed with the improved dropdowns PR.
  if (!isWeb)
    return <FilterButton selected={false} label="All Filters" onPress={() => setShowModal(true)} chevron={showModal} />

  /** Should return a fragment because it is used as children of `ConnectedGeoSearch` component. */
  return (
    <>
      <FilterButton
        selected={filtersIncludes(FILTERS.Farm) && filters[2].length > 0}
        label="Farms"
        subFilters={farmFiltersButtons}
        filterCount={filters[2].length}
      />
      <FilterButton
        selected={attributeRefinements['category'].currentRefinement.length > 0}
        label="Products"
        subFilters={productFilters}
        filterCount={attributeRefinements['category'].currentRefinement.length}
        disabled={!attributeRefinements['category'].items.length}
      />
      <FilterButton
        selected={attributeRefinements['farm.practices'].currentRefinement.length > 0}
        label="Certifications"
        subFilters={certFilters}
        filterCount={attributeRefinements['farm.practices'].currentRefinement.length}
        disabled={!attributeRefinements['farm.practices'].items.length}
      />
      <FilterButton
        selected={attributeRefinements['farm.tags'].currentRefinement.length > 0}
        label="Tags"
        subFilters={tagFilters}
        filterCount={attributeRefinements['farm.tags'].currentRefinement.length}
        disabled={!attributeRefinements['farm.tags'].items.length}
      />
      <FilterButton selected={false} label="All Filters" onPress={() => setShowModal(true)} chevron={showModal} />
    </>
  )
}

export const FilterMenu = withAttributeRefinements(FilterMenuComp)

// This value comes from padding on the HalfModal (20 top and bottom) and an additional padding 20 + 15
const EXTRA_MARGIN = 75

/** Component displayed in the filters modal, which has all supported filtering options. The idea is the filter menu must be kept small and simple, while this modal may have more comprehensive options and will take more space, but gets hidden after making selections */
function AllFiltersModal({ attributeRefinements }: AttributeRefinementsProp) {
  const filters = useSelector(searchFiltersSelector)
  const buildFilters = useBuildFilters()
  const filtersIncludes = useFiltersIncludes(filters)
  const { showModal: isVisible, setShowModal: setIsVisible } = useContext(ExploreContext)

  const { scrollStyle } = useStyles()

  const onDismiss = useCallback(() => setIsVisible(false), [setIsVisible])

  const sortedAttributeRefinements = useMemo(() => {
    const sorted = { ...attributeRefinements }
    sorted['farm.practices'].items.sort((a, b) => b.count - a.count)
    sorted['farm.tags'].items.sort((a, b) => b.count - a.count)
    sorted['category'].items.sort((a, b) => b.count - a.count)
    return sorted
  }, [attributeRefinements])

  return (
    <ModalInline halfModal visible={isVisible} onDismiss={onDismiss} title="Filters">
      <ScrollView style={scrollStyle}>
        <Text type="bold" size={14}>
          Farms & Distributions
        </Text>
        <View style={styles.marginVerticalPadding}>
          <CheckBox
            checked={filtersIncludes(FILTERS.Registered)}
            title="Farms selling now"
            onChange={buildFilters(FILTERS.Registered)}
          />
          <CheckBox
            checked={filtersIncludes(FILTERS.NotRegistered)}
            title="Farms not yet on GrownBy"
            onChange={buildFilters(FILTERS.NotRegistered)}
          />
          <Divider />
          <CheckBox
            disabled={filtersIncludes(FILTERS.NotRegistered)}
            checked={filtersIncludes(FILTERS.Distro)}
            title="Show pickup locations"
            onChange={buildFilters(FILTERS.Distro)}
          />
        </View>

        {!!attributeRefinements['farm.practices'].items.length && (
          <View style={styles.marginVertical}>
            <Text type="bold" size={14}>
              Farm Certifications
            </Text>
            <RefinementListCert {...sortedAttributeRefinements['farm.practices']} />
          </View>
        )}

        <>
          <Text type="bold" size={14}>
            Supplemental Nutrition Assistance Program
          </Text>
          <View style={styles.marginVerticalPadding}>
            <CheckBox
              checked={filtersIncludes(FILTERS.Ebt)}
              title="Farms that accept SNAP benefits"
              onChange={buildFilters(FILTERS.Ebt)}
            />
          </View>
        </>

        {filtersIncludes(FILTERS.Product) && !!attributeRefinements['category'].items.length && (
          <View style={styles.marginVertical}>
            <Text type="bold" size={14}>
              Products
            </Text>
            <RefinementListParam {...sortedAttributeRefinements['category']} paramKey="category" />
          </View>
        )}

        {!!attributeRefinements['farm.tags'].items.length && (
          <View style={styles.marginVertical}>
            <Text type="bold" size={14}>
              Tags
            </Text>
            <RefinementListParam {...sortedAttributeRefinements['farm.tags']} paramKey="tags" />
          </View>
        )}
      </ScrollView>
    </ModalInline>
  )
}

export default withAttributeRefinements(AllFiltersModal)

const styles = StyleSheet.create({
  marginVertical: {
    marginVertical: 10,
  },
  marginVerticalPadding: {
    marginVertical: 10,
    paddingHorizontal: 25,
  },
})

const useStyles = () =>
  useLayoutFnStyles(({ top, bottom, height }) => ({
    scrollStyle: {
      paddingHorizontal: 15,
      /**To-do: The half modal should internally enforce a max height that takes into account all this stuff, so components can use it and freely pass their content without having to calculate this */
      maxHeight: height - (top + bottom + EXTRA_MARGIN),
    },
  }))
