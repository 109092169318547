import { loadFarm } from '@api/Farms'
import { DateTime } from 'luxon'
import { useDispatch } from 'react-redux'

import { ApiFxOptions, useApiFx } from './useApiFx'

import { addNavProp, updateFarmCache } from '@/redux/actions/appState'
import { useFarmDataFromCache } from '@/redux/selectors'
import { matchesIdOrSlug } from '@helpers/urlSafeSlug'

/** Some ApiFxOptions are omitted here because useFarmProp needs to set them to very specific values.
 * - In the case of onStateSet, it will be combined within another function for onStateSet,
 */
type Opts = {
  /** could be a slug or id for the farm */
  farmSlug?: string
} & Omit<ApiFxOptions<typeof loadFarm>, 'depsOnStateSet' | 'initialState' | 'getFromCache'>

/** Simple hook that loads a farm by slug or id, and keeps track of loading and error state. When data changes, it syncs with farm cache */
export function useFarmProp({ farmSlug, onStateSet, ...opts }: Opts) {
  const dispatch = useDispatch()

  const { farm: cachedFarm, time } = useFarmDataFromCache(farmSlug || '')

  /** Load farm */
  return useApiFx(loadFarm, [farmSlug], !!farmSlug, {
    getFromCache: () => {
      if (cachedFarm && matchesIdOrSlug(cachedFarm, farmSlug || '') && DateTime.now() < time.plus({ hours: 1 }))
        return cachedFarm
      return undefined
    },
    failedConditionMode: 'keep-loading',
    retryOnFailure: true,
    initialState: { data: cachedFarm, loading: true },
    onStateSet: (newState) => {
      const { data } = newState

      // Doesn't need to be checked for matching with the slug, because it was fetched from the slug
      if (data) {
        // Gives preference to the slug as the cache key instead of the id
        dispatch(updateFarmCache(data.urlSafeSlug, { farm: data }))
        dispatch(addNavProp({ farm: data }))
      }
      onStateSet?.(newState)
    },
    depsOnStateSet: [farmSlug],
    ...opts,
  })
}
