import { Product } from '@models/Product'
import { memo, useContext } from 'react'

import { ShareRow_Reusable } from '../../../OrderCreatorScreen/components/Rows/ShareRow_Reusable'
import { OrderEditContext } from '../../useOrderEditData'
import { AddBtnShare } from '../AddCartButtons/ShareBtn'

export type ShareRowProps = {
  prod: Product
  index: number
}
/** Row component for share products of the order edit table  */
export const ShareRow = memo(function ShareRow({ prod, index }: ShareRowProps) {
  const {
    keyedStateProds: [{ schedule }],
    draftOrderFx: { data: draft },
  } = useContext(OrderEditContext)

  if (!draft || draft.isWholesale === undefined) return null

  return (
    <ShareRow_Reusable
      AddCartBtn={AddBtnShare}
      index={index}
      isWholesale={draft.isWholesale}
      prod={prod}
      schedule={schedule}
      cartServiceType="orderEdit"
    />
  )
})
