import { hideModal, Modal, TextH2 } from '@elements'
import { isArray } from '@helpers/helpers'
import { CSA } from '@models/CSA'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useCallback } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'

import { ProductCardSquare } from '../ProductCard'

import { globalStyles } from '@/constants/Styles'
import { useDeviceSize } from '@/hooks/useLayout'
import { ConsumerNavigatorParamList } from '@/navigation/types'
import { Product } from '@models/Product'

type SuggedtedProductsProps = {
  /** the current csa */
  csa: CSA
  /** onClose - will be called when they press the AddCartBtn, and when they press the main card area. it's used to resolve an external promise and allow an async to await for any interactions with this modal to finish */
  onClose(): void
  /** these should be the suggested products to show on the modal */
  prods: Product[]
}

export function SuggestedProducts({ csa, onClose: onCloseProp, prods }: SuggedtedProductsProps) {
  const navigation = useNavigation<StackNavigationProp<ConsumerNavigatorParamList>>()
  const { isExtraSmallDevice } = useDeviceSize()
  /** "onClose" will be called as a side effect when they press the main card area */
  const onClose = useCallback(() => {
    hideModal()
    onCloseProp()
  }, [onCloseProp])

  /** If there's no suggested products after loading, and it was not the result of a loading error, we should close the modal and not render anything. This case should be prevented by checking before even opening this modal in the first place. But this check is also good to have here just in case. */
  if (isArray(prods) && !prods.length) {
    onClose()
  }

  return (
    <ScrollView>
      <TextH2 style={styles.substitle}>Your farmer has also suggested the following CSA products:</TextH2>

      <View style={styles.wrapper}>
        {prods.map((prod) => (
          <ProductCardSquare
            key={prod.id}
            style={isExtraSmallDevice ? globalStyles.margin10 : globalStyles.margin20}
            small={isExtraSmallDevice}
            csa={prod.csa?.includes(csa.id) ? csa : undefined}
            onPress={onClose}
            onActionPress={onCloseProp} //We don't need to hide the modal on action press because it will use the context modal for the next addToCartFlow
            onPressMobileNavigate={() =>
              navigation.navigate('Shopping', {
                screen: 'ProductDetails',
                params: { farmSlug: prod.farm.urlSafeSlug, productId: prod.id, csaId: csa.id },
              })
            }
            product={prod}
            cardAction="addcart"
          />
        ))}
      </View>
      <View style={styles.spacing} />
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  substitle: {
    marginLeft: 20,
  },

  spacing: {
    marginVertical: 20,
  },
})

/** Opens the Suggested Products modal, and returns a promise that will resolve when the modal is closed or a product is added from it */
export const suggestedProductsModal = async (csa: CSA, prods: Product[]) =>
  new Promise<void>(async (resolve) => {
    if (!csa.suggestedProducts) return resolve()
    if (!prods.length) return resolve()
    Modal(<SuggestedProducts csa={csa} onClose={resolve} prods={prods} />, {
      webWidth: 1000,
      title: 'Nice! Added to your cart!',
      onDismiss: () => {
        resolve()
      },
    })
  })
