import { TextH4, Button } from '@elements'
import { View, StyleSheet, ViewStyle, TouchableOpacity } from 'react-native'

import Colors from '../../constants/Colors'
import { useDeviceSize } from '../../hooks/useLayout'
import { withColorHover } from '../../hooks/withColorHover'

type PropTypes = {
  style?: ViewStyle
  title: string
  disabled?: boolean
  color?: string
  onPress(): void
}
function LinkButton({ style, disabled, title, color = Colors.blue, onPress }: PropTypes) {
  const { isSmallDevice } = useDeviceSize()
  if (!isSmallDevice) {
    return (
      <TouchableOpacity style={[style, styles.hyperLinkView]} disabled={disabled}>
        <TextH4
          style={!disabled ? { color, textDecorationLine: 'underline' } : { color: Colors.shades['300'] }}
          onPress={() => !disabled && onPress()}
        >
          {title}
        </TextH4>
      </TouchableOpacity>
    )
  } else {
    return (
      <View style={[style, styles.btnMoreInfo]}>
        <Button
          small
          disabled={disabled}
          outline
          style={{ minHeight: 25, marginBottom: 0 }}
          textStyle={{ fontSize: 10 }}
          title={title}
          onPress={onPress}
        />
      </View>
    )
  }
}

export default withColorHover(LinkButton)

const styles = StyleSheet.create({
  btnMoreInfo: {
    flex: 1,
    margin: 2,
  },
  hyperLinkView: {
    marginRight: 15,
  },
})
