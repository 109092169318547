import { createResetPassword } from '@api/ExternalLinks'
import { loadUserByEmail } from '@api/Users'
import { FormButton } from '@elements'
import { SignInProviders } from '@models/User'
import { Formik, FormikProps } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { Keyboard, Text } from 'react-native'
import { Input } from 'react-native-elements'
import * as Yup from 'yup'

import { Alert } from '../../elements/Overlays/Popups'
import { correctProvider } from '../LoginHelper'

type FormType = {
  email: string
}

const validationSchema = Yup.object<FormType>().shape({
  email: Yup.string().label('Email').email('Enter a valid email').required('Please enter a valid email'),
})

type Props = {
  email: string
  setEmail(email: string): void
}

export default function EmailReset({ email, setEmail }: Props) {
  const form = useRef<any>(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (form.current && email) {
      form.current!.setFieldValue('email', email)
    }
  }, [email])

  const onLoginHandler = async (values: FormType) => {
    const { email } = values

    setIsLoading(true)
    Keyboard.dismiss()

    // Check that the user exists before resetting the account
    loadUserByEmail(email)
      .then((user) => {
        if (user.signInProvider === SignInProviders.Email) {
          Alert('Reset Success', 'You will receive an email with instructions to reset your password.')
          createResetPassword(email)
          return
        }
        correctProvider(user, 'Reset')
      })
      .catch(() => {
        Alert(
          'Reset Failed',
          `This email is not associated with an account. Please try signing in with a different method or contact support if you believe this is an error.`,
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const initialValues: FormType = { email: '' }

  return (
    <Formik initialValues={initialValues} onSubmit={onLoginHandler} innerRef={form} validationSchema={validationSchema}>
      {({ setFieldValue, values, errors, touched, handleSubmit, handleBlur }: FormikProps<FormType>) => (
        <>
          <Input
            numberOfLines={1}
            value={values.email}
            placeholder="Email"
            onChangeText={(e) => {
              setEmail(e)
              setFieldValue('email', e)
            }}
            autoCapitalize="none"
            autoComplete="email"
            keyboardType="email-address"
            onBlur={handleBlur('email')}
            leftIcon={{ type: 'font-awesome', name: 'envelope' }}
            leftIconContainerStyle={{ marginRight: 8, opacity: 0.75 }}
            errorMessage={touched.email ? errors.email : ''}
            onSubmitEditing={() => handleSubmit()}
          />
          <FormButton loading={isLoading} title="Reset Password" onPress={handleSubmit} style={{ margin: 8 }} />
          <Text
            style={{
              fontSize: 16,
              margin: 8,
              marginVertical: 16,
              lineHeight: 24,
            }}
          >
            We'll send you an email to reset your password.
          </Text>
        </>
      )}
    </Formik>
  )
}
