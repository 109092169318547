import { EbtIcon } from '@components'
import { Button, Divider, HeaderText, Text, Touchable } from '@elements'
import { Foundation } from '@expo/vector-icons'
import { View } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import Colors from '../../constants/Colors'
import { FeatureCard } from './FeatureCard'

import { globalStyles } from '@/constants/Styles'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useLayout } from '@/hooks/useLayout'

export function FarmerOwnedFeatureCard() {
  const styles = useStyles()
  return (
    <FeatureCard height={300} image={require('../../assets/images/home/home-1.jpg')}>
      <View style={styles.contentWrapper}>
        <View style={globalStyles.flex05} />
        <HeaderText color={Colors.white}>Proud to be Farmer-Owned</HeaderText>
        <Divider clear />
        <Text color={Colors.white} size={14}>
          Sell directly to customers, set your own schedule, and increase sales. We are the only cooperatively-owned app
          for farm sales in the world.
        </Text>
        <Divider clear />
        <View style={styles.flexJustifyCenter}>
          <Button
            style={styles.marginLeft0}
            color={Colors.white}
            textStyle={styles.greenText}
            title="Start selling"
            url="/farm-onboard/walkthrough"
          />
        </View>
      </View>
    </FeatureCard>
  )
}

export function DoubleFeatureCards() {
  const layout = useLayout()
  const isVeryLargeDevice = layout.width > 1550
  const dynamicStyles = useSizeFnStyles(
    (_, largeDisplay) => ({
      wrapper: { flexDirection: largeDisplay ? 'row' : 'column', justifyContent: 'space-between' },
      card: { width: largeDisplay ? '49%' : undefined },
    }),
    isVeryLargeDevice,
  )
  const styles = useStyles()

  return (
    <View style={dynamicStyles.wrapper}>
      <FeatureCard height={220} style={dynamicStyles.card} image={require('../../assets/images/home/home-3.jpg')}>
        <View style={styles.contentWrapper}>
          <View style={globalStyles.flexRowCenter}>
            <View style={styles.iconCont}>
              <Foundation name="dollar" size={26} />
            </View>
            <HeaderText
              size={isVeryLargeDevice ? 17 : undefined}
              numberOfLines={2}
              color={Colors.white}
              style={styles.headerText}
            >
              Keeping Small Farms in Business
            </HeaderText>
          </View>

          <Divider clear />
          <Text color={Colors.white} size={isVeryLargeDevice ? 12 : undefined}>
            Sell directly to customers, set your own schedule, and increase sales. We are the only cooperatively-owned
            app for farm sales in the world.
          </Text>
          <Divider clear />
          <View style={styles.flexJustifyCenter}>
            <Button
              style={styles.marginLeft0}
              color={Colors.white}
              textStyle={styles.greenText}
              title="Start selling"
              url="/farm-onboard/walkthrough"
            />
          </View>
        </View>
      </FeatureCard>
      {!isVeryLargeDevice && <View style={globalStyles.margin20} />}
      <FeatureCard
        height={220}
        style={dynamicStyles.card}
        resizeMode="contain"
        imageContStyle={styles.snapImgCont}
        image={require('../../assets/images/icons/snap-logo.png')}
      >
        <View style={styles.whiteContentWrapper}>
          <View style={globalStyles.flexRowCenter}>
            <EbtIcon visible size={20} />
            <View style={globalStyles.margin10} />
            <HeaderText size={isVeryLargeDevice ? 17 : undefined}>Register to Accept SNAP/EBT on GrownBy</HeaderText>
          </View>

          <Divider clear />
          <Text size={isVeryLargeDevice ? 12 : undefined}>
            Farmers can now apply to accept SNAP/EBT benefits on GrownBy, and consumers can shop with SNAP/EBT at
            participating select farms.
          </Text>
          <Touchable url="http://snap.grownby.com">
            <Text size={isVeryLargeDevice ? 12 : undefined} style={styles.underlined}>
              Learn More
            </Text>
          </Touchable>
        </View>
      </FeatureCard>
    </View>
  )
}

export function RiverQueenFeatureCard() {
  const { isSmallDevice, isExtraLargeDevice } = useLayout()
  const styles = useStyles()
  return (
    <FeatureCard
      imageLeft
      height={isSmallDevice ? undefined : 400}
      imageContStyle={!isSmallDevice && !isExtraLargeDevice && styles.aspectRatio1}
      image={require('../../assets/images/home/home-2.jpg')}
    >
      <View style={styles.goldContentWrapper}>
        <View style={styles.alignStart}>
          <View style={styles.topFarmCont}>
            <Text>Top Farm</Text>
          </View>
        </View>
        {isSmallDevice && <Divider clear />}
        <View style={globalStyles.flex05} />

        <HeaderText>River Queen Greens is the First US Farm to Accept SNAP/EBT benefits</HeaderText>

        <Divider clear />
        <Text size={14}>
          Farmer-members Cheryl Nunes and Annie Moore run a 30-week, free-choice Farm Share in New Orleans. All of their
          produce is Certified Naturally Grown. After years of efforts to make their produce accessible to their whole
          community, they're proud to be the first farm in the US to accept online SNAP!
        </Text>
        <Divider clear />

        <Divider clear />
        <View style={styles.flexJustifyCenter}>
          <Button
            style={styles.marginLeft0}
            color={Colors.white}
            textStyle={{ color: Colors.green }}
            title="Shop now"
            url="https://grownby.app/farms/farmid-4012738/shop"
          />
        </View>
      </View>
    </FeatureCard>
  )
}

const useStyles = CreateResponsiveStyle(
  {
    flexJustifyCenter: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    underlined: {
      textDecorationLine: 'underline',
    },
    whiteContentWrapper: {
      backgroundColor: Colors.white,
      flex: 1,
      padding: 20,
      justifyContent: 'center',
    },
    goldContentWrapper: {
      backgroundColor: Colors.lightGold,
      flex: 1,
      padding: 20,
    },
    snapImgCont: {
      backgroundColor: Colors.white,
      padding: 20,
    },
    headerText: {
      marginLeft: 10,
      flex: 1,
    },
    iconCont: {
      height: 35,
      alignItems: 'center',
      justifyContent: 'center',
      width: 35,
      borderRadius: 50,
      backgroundColor: Colors.white,
    },
    contentWrapper: {
      backgroundColor: Colors.purple,
      flex: 1,
      padding: 20,
      justifyContent: 'center',
    },

    alignStart: {
      alignItems: 'flex-start',
    },
    aspectRatio1: {
      aspectRatio: 1,
    },
    marginLeft0: {
      marginLeft: 0,
    },
    greenText: {
      color: Colors.green,
    },
    topFarmCont: {
      paddingVertical: 5,
      paddingHorizontal: 10,
      backgroundColor: Colors.white,
      borderRadius: 5,
      alingSelf: 'center',
    },
  },
  {
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      flexJustifyCenter: {
        alignItems: 'center',
        marginVertical: 20,
      },
    },
  },
)
