import { Distribution } from '@models/Distribution'
import { isValidException } from '@models/Schedule'
import { formatPickupTime, getDayofWeekName } from './display'

/** Whether a distro has valid data */
export function isValidDistro(dist: Distribution): boolean {
  let isValid = true
  dist.schedule.exceptions?.forEach((ex) => {
    if (!isValidException(ex)) isValid = false
  })

  return isValid
}

/**
 * Formats the schedule text for a given distribution. Used in the Algolia Geo Product and Geo Distribution docs.
 */
export const formatScheduleText = (distro: Distribution | undefined) => {
  if (!distro) return ''
  const pickupTimeText = formatPickupTime(distro.schedule.hours, distro.location.type)
  return `${getDayofWeekName(distro.schedule.dayOfWeek)}s${pickupTimeText ? ` from ${pickupTimeText}` : ''}`
}
