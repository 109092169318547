import { productsCollection } from '@api/framework/ClientCollections'
import { Product } from '@models/Product'
import { FunctionComponent, PropsWithChildren, memo, useState } from 'react'
import { FlatListProps } from 'react-native'

import { useDeepCompareMemo } from '@/hooks/useDeepEqualEffect'
import { useDeepCompareFocusFx } from '@/hooks/useFocusFx'

type Props = {
  prodId: string
  /** Changes to "extraData" will force an update of memoed component and listener */
  extraData?: FlatListProps<Product>['extraData']
  /** This component can be passed inline */
  Component: FunctionComponent<{ prod: Product }>
}

/** This component loads the product from snapshot, and passes it down to the `Component` prop,
 * which might be either the card component, or the expandable row component in spreadsheet mode */
export const ProductSnapshot = memo(function ProductSnapshot({
  prodId,
  extraData,
  Component,
}: PropsWithChildren<Props>) {
  const [product, setProduct] = useState<Product | undefined>(undefined)

  /**
   * Memoize the component, to prevent Functional Component to recreate itself on every render. This memo the component, but it won't impact its child component's re-rendering when props change.
   * - This uses deep compare memoization because we want to re-render the component when there are changes to the product (Will react to hiding or editing), AND when there's changes to the extraData
   */
  const memoComponent = useDeepCompareMemo(() => {
    return product ? <Component prod={product} /> : null
    // don't need to update on Component change, since that's assumed to be static
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, extraData])

  useDeepCompareFocusFx(() => {
    return productsCollection.snapshotDoc(prodId, setProduct)
    // extraData isn't used but it's meant to re-trigger this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prodId, extraData])

  return memoComponent
})
