import { addCustomer, AddCustomerData, inviteExistingCustomer } from '@api/Customers'
import { loadUserByEmail } from '@api/Users'
import { Alert, FormButton, hideModal, Text } from '@elements'
import { Farm } from '@models/Farm'
import { User } from '@models/User'
import { Formik } from 'formik'
import { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { Input } from 'react-native-elements'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import Colors from '../../constants/Colors'
import { RootState } from '../../redux/reducers/types'
import { adminFarmSelector, userSelector } from '../../redux/selectors'

interface InviteCustomerPromptProps {
  setScreen: (screen: number) => void
  setNewCustomer: (customer: Partial<User>) => void
}

export function InviteCustomerPrompt({ setScreen, setNewCustomer: setCustomerExternal }: InviteCustomerPromptProps) {
  const farm = useSelector<RootState, Farm>(adminFarmSelector)
  const user = useSelector(userSelector)

  const [loading, setLoading] = useState<boolean>(false)

  // simple variable to check if the farmer tried to invite the user.
  const [customerChecked, setCustomerChecked] = useState<boolean>(false)
  const [customer, setCustomer] = useState<User | null>(null)

  const CustomerEmailSchema = Yup.object({
    email: Yup.string().email('Please enter a valid email').trim().label('Email Address').required(),
  })

  const close = () => {
    hideModal()
  }

  const emptyForm: boolean = !customer && !customerChecked

  /**
   * @param customer is the customer that got submitted before it errored
   */
  const onCustomerNotFound = (customer: Partial<User>) => {
    setCustomerExternal(customer) // this allows an outer state to still track the data entered here, despite we couldn't fetch the customer data locally
    setCustomer(null)
    setLoading(false)
    close()
    Alert(
      'New Customer',
      'This email is not a GrownBy customer yet. Enter their information to add them as a customer.',
      [
        {
          text: 'Cancel',
          style: 'default',
        },
        {
          text: 'Continue',
          style: 'default',
          // Move to next screen to add the customer
          onPress: () => setScreen(2),
        },
      ],
    )
  }

  /**
   * The function handles the submission of the form and checks if the customer exists in the database.
   * If the customer exists, the function will invite the customer to the farm. If the customer does
   * not exist, the function will proceed to the next page to enter details of the customer
   * @param values - { email: string } - This is the email address of the customer that the user is
   * trying to invite.
   */
  const handleSubmit = async (values: { email: string }) => {
    setCustomerChecked(true)
    setLoading(true)

    try {
      const customer = await loadUserByEmail(values.email)
      setCustomerExternal(customer)

      /* Trying to invite a customer if the customer exists, if not, they will proceed to the next page to enter details of the customer. */
      if (customer?.address) {
        const { street1, street2, city, state, zipcode } = customer.address
        const data: AddCustomerData = {
          firstName: customer.name.firstName,
          lastName: customer.name.lastName,
          email: customer.email.toLowerCase().trim(),
          phoneNumber: customer.phoneNumber,
          street1,
          street2,
          city,
          state,
          zipcode,
        }
        setCustomer(customer)
        await addCustomer(data, farm, user)

        setLoading(false)
      } else {
        await inviteExistingCustomer(customer, farm, user)
      }
      close()
    } catch (error) {
      onCustomerNotFound(values)
    }
  }

  return (
    <View>
      <Text style={styles.description}>
        To add a customer, enter their email here and GrownBy will send them an email to complete account creation. If a
        customer's email or phone number is already associated with an existing GrownBy account, GrownBy will send them
        an invitation to be your customer.
      </Text>
      <Formik
        validationSchema={CustomerEmailSchema}
        initialValues={{
          email: '',
        }}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, handleBlur, handleChange, values, errors, touched }) => (
          <View style={styles.form}>
            <Input
              placeholder="Customer Email"
              value={values.email}
              onChangeText={handleChange('email')}
              onBlur={handleBlur('email')}
              errorMessage={touched.email ? errors.email : ''}
            />
            <FormButton
              loading={loading}
              onPress={emptyForm ? handleSubmit : undefined}
              title={emptyForm ? 'Invite' : 'Check Email'}
            />
          </View>
        )}
      </Formik>
    </View>
  )
}

const styles = StyleSheet.create({
  success: {
    marginTop: 10,
    marginBottom: 10,
    color: Colors.green,
    fontWeight: 'bold',
  },
  error: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
    color: Colors.red,
    fontWeight: 'bold',
  },
  description: {
    marginLeft: 20,
    marginBottom: 50,
    color: Colors.shades['400'],
  },
  form: {
    paddingHorizontal: 10,
  },
})
