import { omit } from '@helpers/typescript'
import { Commodity } from '@models/Commodity'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { prepareMarshal, prepareUnmarshal } from './encoding'

/** unmarshalCommodity converts the data provided by the Firestore document into the structure expected by the Commodity model. */
export function unmarshalCommodity(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): Commodity {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)
  return { ...data, id } as Commodity
}

/** marshalCommodity converts the commodity structure into a Firebase document structure. */
export function marshalCommodity(commodity: Partial<Commodity>): DocumentData {
  return omit(prepareMarshal(commodity), 'id')
}
