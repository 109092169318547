import React from 'react'
import { Image, View } from 'react-native'
import Colors from '@/constants/Colors'
import { farmerOnboardMiscellaneousAssets } from '@/assets/images/farmer_onboard'
import { SectionContainer } from '@screens/FarmerOnboard/components'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { HeaderText, Text } from '@elements'
import { useDeviceSize } from '@/hooks/useLayout'

/**
 * `SupportSection` is a component that renders a section dedicated to showcasing the support provided to farmers.
 * It consists of a title, an icon with a testimonial text, and a detailed description of the support offered.
 */
export function SupportSection() {
  const styles = useSupportSectionStyles()
  const { isSmallDevice } = useDeviceSize()

  return (
    <>
      <HeaderText size={isSmallDevice ? 38 : 42} style={styles.greenText}>
        Hands on Support
      </HeaderText>
      <SectionContainer outerContainerStyle={styles.outerContainer}>
        <SectionIconWithText />
        <SectionDescription />
      </SectionContainer>
    </>
  )
}

const useSupportSectionStyles = () =>
  useSizeFnStyles(() => ({
    greenText: {
      color: Colors.darkGreen,
      textAlign: 'center',
      paddingTop: 55,
      paddingBottom: 42,
      fontStyle: 'italic',
    },
    outerContainer: { paddingBottom: 55 },
  }))

function SectionIconWithText() {
  const styles = useSectionIconWithTextStyles()

  return (
    <View style={styles.container}>
      <Image source={farmerOnboardMiscellaneousAssets.girlOnComputer} style={styles.icon} />
      <View style={styles.textContainer}>
        <Text size={17} style={styles.testimonialText}>
          "The most responsive software development group I've worked with. They held a zoom meeting and really
          listened." -GrownBy Farmer
        </Text>
      </View>
    </View>
  )
}

const useSectionIconWithTextStyles = () =>
  useSizeFnStyles(({ isSmallDevice, isMedDevice, isLargeDevice }) => ({
    container: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    icon: {
      width: isSmallDevice ? 226 : isMedDevice ? 340 : 453,
      height: isSmallDevice ? 191 : isMedDevice ? 286 : 382,
    },
    textContainer: {
      maxWidth: isLargeDevice ? 400 : '100%',
      width: '100%',
      alignItems: 'center',
    },
    testimonialText: {
      textAlign: 'center',
      color: Colors.primaryGray,
    },
  }))

function SectionDescription() {
  const styles = useSectionDescriptionStyles()

  return (
    <View style={styles.container}>
      <HeaderText size={26} style={styles.title}>
        We are here for you every step of the way.
      </HeaderText>
      <Text size={17} style={styles.description}>
        We know that starting another new software platform is a big investment of your time -- and requires a lot of
        trust.
      </Text>
      <Text size={17} style={styles.description}>
        We take pride in a support team that knows GrownBy inside and out -- and has deep farm experience to share.
      </Text>
      <Text size={17} style={styles.description}>
        We advise farmers on selling strategies, marketing language, logistics and whatever else they need. We are happy
        to talk to you while you’re on the tractor, weeding, milking, or at the market.
      </Text>
      <HeaderText size={26} style={styles.title}>
        We get it, we've been there, and we are on your team!
      </HeaderText>
    </View>
  )
}

const useSectionDescriptionStyles = () =>
  useSizeFnStyles(({ isLargeDevice, isSmallDevice }) => ({
    container: {
      maxWidth: isLargeDevice ? 400 : '92.5%',
      marginTop: isSmallDevice ? 9.5 : 1,
    },
    title: {
      marginBottom: 7,
    },
    description: {
      color: Colors.primaryGray,
      marginBottom: 18,
    },
  }))
