import { limit, where } from 'firebase/firestore'

import { Distribution } from '../models/Distribution'
import { Farm } from '../models/Farm'
import { isDigital, Product } from '../models/Product'
import { errorCatcher } from './Errors'
import { updateFarm } from './Farms'
import { distrosCollection, pickupsCollection, productsCollection } from './framework/ClientCollections'

/** returns the schedules associated with the supplied farm ID.
 * @deprecated please replace this with 'snapshotDistributionsByFarmUseSnapshot' and useSnapshot
 */
export function snapshotDistributionsByFarmStandalone(
  farmId: string,
  updateFn: (distributions: Distribution[]) => void,
  errorFn = errorCatcher,
): () => void {
  const q = distrosCollection.query(where('farm.id', '==', farmId))
  const snapshotDistroByFarm = distrosCollection.snapshotMany(q, updateFn, errorFn)
  return snapshotDistroByFarm
}

/** returns the schedules associated with the supplied farm ID.
 * - This is similar to 'snapshotDistributionsByFarmStandalone' but fulfills the useSnapshot interface
 */
export function snapshotDistributionsByFarm(
  updateFn: (distributions: Distribution[]) => void,
  errorFn = errorCatcher,
  farmId: string,
): () => void {
  const q = distrosCollection.query(where('farm.id', '==', farmId))
  const snapshotDistroByFarm = distrosCollection.snapshotMany(q, updateFn, errorFn)
  return snapshotDistroByFarm
}

/** returns all of the schedules that have been assigned to the supplied product */
export async function loadDistributionsForProduct(product: Product): Promise<Distribution[]> {
  if (isDigital(product)) return []

  return distrosCollection.fetchByIds(product.distributions.map((d) => d.id))
}

/** adds a new distribution to the database. */
export async function addDistribution(distribution: Distribution, farm?: Farm): Promise<Distribution> {
  const newDistro = await distrosCollection.create(distribution)
  // Update onboard walk-through
  if (farm && farm.onboardSteps && !farm.onboardSteps?.schedules) {
    updateFarm({ id: farm.id, onboardSteps: { ...(farm.onboardSteps || {}), schedules: true } })
  }
  return newDistro
}

/** checks if a distribution has any pickups associated with it, or if any products are linked to it. If it does, then it cannot be deleted.  maybe there is a better way to do this check */
export async function canDistributionBeDeleted(farmId: string, distroId: string): Promise<boolean> {
  const products = await productsCollection.fetchAll(
    where('farm.id', '==', farmId),
    where(`distributions.${distroId}.id`, '==', distroId),
    limit(1),
  )

  const pickups = await pickupsCollection.fetchAll(
    where('farm.id', '==', farmId),
    where('distribution.id', '==', distroId),
    limit(1),
  )

  return products.length === 0 && pickups.length === 0
}
