import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { MenuItem } from '@/hooks/useAlgoliaFarmData/useAlgoliaFarmData-helpers'
import { CheckBox, Icon, Text } from '@elements'
import { ProcessedMenuItem } from '@screens/Shopping/FarmShop/Filtering/filterUtils'
import { memo } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'

type Props = {
  item: ProcessedMenuItem
  onPress: (item: MenuItem) => void
  icon?: 'radio' | 'checkbox' | 'link'
}

/** Filter component that is used for displaying an option */
export const FilterItem = memo(function FilterItem({ item, icon = 'radio', onPress }: Props) {
  return (
    <Pressable onPress={() => onPress(item)} style={styles.main}>
      {icon === 'checkbox' ? (
        <CheckBox onChange={() => onPress(item)} checked={item.isRefined} color={Colors.shades['500']} />
      ) : icon === 'link' ? (
        <Icon color={Colors.shades['500']} size={18} name="external-link" iconSet="EvilIcons" />
      ) : (
        <Icon
          color={Colors.shades['500']}
          iconSet="Ionicons"
          name={item.isRefined ? 'radio-button-on' : 'radio-button-off'}
        />
      )}
      <View style={globalStyles.flex1}>
        <Text size={14}>{item.label}</Text>
        {!!item.subtitle && (
          <Text style={globalStyles.flex1} color={Colors.shades['300']} size={14}>
            {item.subtitle}
          </Text>
        )}
      </View>
      {!!item.count && !item.shouldClear && <Text>{item.count} products</Text>}
    </Pressable>
  )
})

const styles = StyleSheet.create({
  main: {
    padding: 12,
    borderBottomWidth: 1,
    borderColor: Colors.shades['100'],
    alignItems: 'center',
    flexDirection: 'row',
    gap: 10,
  },
})
