import { ErrorTypes, ErrorWithCode } from '@shared/Errors'
import * as yup from 'yup'
import { ValidateOptions } from 'yup'
import { hasOwnProperty } from '../../helpers'

/**
 * Will validate data against a Yup schema and cast any errors as YupValidationErrors
 * @param schema The schema to validate the data against
 * @param value The data to validate
 * @param opts Options to pass to the Yup Validation
 */
export function validateFromSchema<Type extends object>(
  schema: yup.ObjectSchema<Type>,
  value: unknown,
  opts?: Omit<ValidateOptions, 'strict'>,
): Type {
  try {
    return schema.validateSync(value, {
      ...opts,
      // strict should be true in builders validate method
      strict: true,
    }) as Type
  } catch (error) {
    if (error instanceof Error) {
      if (hasOwnProperty(error, 'path') && typeof error.path === 'string') {
        throw new YupValidationError({ path: error.path ?? '', msg: error.message })
      } else {
        throw new YupValidationError({ path: 'unknown', msg: error.message })
      }
    }
    // Unknown error throw it directly
    throw error
  }
}

/** The YupValidationError is thrown when a field validation fails and contains information about the path. */
export class YupValidationError extends ErrorWithCode<'form-schema-error', { path: string }> {
  constructor({ msg, path }: { msg: string; path: string }) {
    super({ type: ErrorTypes.Validation, code: 'form-schema-error', devMsg: msg, data: { path } })
    Object.setPrototypeOf(this, YupValidationError.prototype)
  }
}

/** isYupValidationError returns true if the supplied error is a validation error. */
export function isYupValidationError(err: unknown): err is YupValidationError {
  return err instanceof YupValidationError
}
