import useKeyedState from '@/hooks/useKeyedState'
import { Button, ErrorText } from '@elements'
import { isCompatibleLocation, isSameAddress } from '@helpers/location'
import { ItemNonPickup } from '@models/Order'
import { UserAddress } from '@models/UserAddress'
import { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { ChooseAddress } from '../../screens/Shopping/components/ChooseAddress'
import { AvailableRegions } from './PickupLocation'

type SetDeliveryAddressState = { newAddress: UserAddress; errorText: string | null; isEditAddress?: boolean }

type SetDeliveryAddressProps = {
  item: ItemNonPickup
  onSelect: (newAddress: UserAddress) => Promise<void>
}

/** Modal component, edits the delivery address of an item in the cart, while keeping the same schedule */
export function EditDeliveryAddress({ item, onSelect }: SetDeliveryAddressProps) {
  if (!item.distribution.location.address)
    throw new Error('An address is expected to exist for an item already in the cart')

  const [{ newAddress, errorText, isEditAddress }, , , setters] = useKeyedState<SetDeliveryAddressState>({
    newAddress: item.distribution.location.address!,
    errorText: null,
    isEditAddress: undefined,
  })
  const locationType = item.distribution.location.type

  const onChooseAddress = (newAddress: UserAddress) => {
    setters.newAddress(newAddress)
    const isCompatible = isCompatibleLocation(item.distribution.location, locationType, newAddress)
    const isDifferent = !isSameAddress(newAddress, item.distribution.location.address!)

    if (!isCompatible) {
      setters.errorText("Delivery isn't available for this address. ")
    } else if (!isDifferent) {
      setters.errorText(
        'The delivery already has this address. If you want to edit the address, enter a different one.',
      )
    } else {
      setters.errorText(null)
    }
  }

  /** The regions shown as available options should only be the ones for the item's schedule. That's because we're only allowing an address change within the same schedule, so the regions available should not include regions from other schedules of the product */
  const regions = useMemo(() => item.distribution.location.regions, [item])

  return (
    <View style={styles.container}>
      <ChooseAddress
        onSelect={onChooseAddress}
        type={locationType}
        address={newAddress}
        onStateSet={({ isEdit }) => setters.isEditAddress(isEdit)}
      />
      <AvailableRegions isVisible={isEditAddress} locationType={locationType} regions={regions} />
      {Boolean(errorText) && <ErrorText style={styles.errorText}>{errorText}</ErrorText>}
      <Button
        title="Update Address"
        onPress={() => onSelect(newAddress)}
        disabled={!!errorText}
        style={styles.updateButton}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginLeft: 10,
  },
  updateButton: {
    marginTop: 10,
    maxWidth: '50%',
    alignSelf: 'center',
  },
  errorText: {
    marginLeft: 10,
  },
  availZipCodes: {
    marginLeft: 10,
  },
})
