import * as Yup from 'yup'
import { PaymentSchedule } from '@models/Product'
import { PaymentType } from '@models/Payment'
import { YUP_MONEY_REQUIRED } from '../Yup'

export const paymentScheduleSchema: Yup.ObjectSchema<PaymentSchedule> = Yup.object().shape({
  paymentType: Yup.number().oneOf([PaymentType.INSTALLMENTS, PaymentType.PAY_FULL]).required(),
  frequency: Yup.string().oneOf(['ONCE', 'WEEKLY', 'MONTHLY', 'PER-PICKUP']).required(),
  paymentDates: Yup.object()
    .shape({
      startDate: Yup.mixed().isDateTime().optional(),
      endDate: Yup.mixed().isDateTime().required(),
    })
    .required(),
  amount: YUP_MONEY_REQUIRED('schedule amount', { allowZero: true }),
  deposit: YUP_MONEY_REQUIRED('deposit', { allowZero: true }),
})
