import { isArray, isNonNullish, removeDuplicates } from '@helpers/helpers'
import { Distribution } from '@models/Distribution'
import { Order, OrderItem } from '@models/Order'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { marshalDistribution, unmarshalDistribution } from './Distribution'
import { marshalPaymentSchedule, unmarshalPaymentSchedule } from './Product'
import { Time, marshalDate, unmarshalDate } from './Time'
import { prepareMarshal, prepareUnmarshal } from './encoding'

/** marshalOrder transforms and Order structure to Firestore data. */
export function marshalOrder(order: Partial<Order>): DocumentData {
  const data = prepareMarshal(order) as DocumentData

  if (order.date) {
    data.date = marshalDate(order.date)
  }

  if (Array.isArray(order.items)) {
    data.items = order.items.map((item) => marshalOrderItem(item))

    // Will save a list of distribution ids to the order so that we can easily query when a distribution is edited
    data.distributionsIds = removeDuplicates(order.items.map((itm) => itm.distribution?.id).filter(isNonNullish))
  }

  return data
}

/** unmarshalOrder transforms the Firestore data to an Order object. */
export function unmarshalOrder(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): Order {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)

  const order = { ...data, id } as Order

  if (order.date) {
    order.date = unmarshalDate(data.date)!
  }
  order.items = data.items?.map((item: any) => unmarshalOrderItem(item))

  return order
}

/** marshalOrderItem encodes the structure into a serializable format. */
export function marshalOrderItem(item: OrderItem): any {
  const data = { ...item } as any

  if (item.cancelled) {
    data.cancelled = marshalDate(item.cancelled)
  }
  if (item.paymentSchedule) {
    data.paymentSchedule = marshalPaymentSchedule(item.paymentSchedule)
  }
  if (item.distribution) {
    data.distribution = marshalDistribution(item.distribution as Partial<Distribution>, true)
  }

  // product unit quantity may be undefined which is not compatible with Firestore.
  if (item.product && item.purchasedUnit && item.purchasedUnit.quantity === undefined) {
    delete data.purchasedUnit.quantity
  }

  if (item.pickups) {
    data.pickups = item.pickups.map((d) => marshalDate(d))
  }

  return data
}

/** unmarshalOrderItem decodes the structure from a serializable format. */
export function unmarshalOrderItem(data: any): OrderItem {
  const item = { ...data } as OrderItem

  if (data.cancelled) {
    item.cancelled = unmarshalDate(data.cancelled)
  }
  if (data.paymentSchedule) {
    item.paymentSchedule = unmarshalPaymentSchedule(data.paymentSchedule)
  }
  if (data.distribution) {
    item.distribution = unmarshalDistribution(data.distribution)
  }
  if (data.pickups && isArray(data.pickups)) {
    item.pickups = data.pickups.map((d: Time) => unmarshalDate(d))
  }

  return item
}
