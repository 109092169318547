import { StyleSheet } from 'react-native'
import Colors from '../../../../constants/Colors'

export const sharedStyles = StyleSheet.create({
  headerText: {
    marginTop: 20,
    marginLeft: 10,
  },
})

export const finitePaymentStyles = StyleSheet.create({
  wrapper: {
    marginHorizontal: 15,
    padding: 15,
    marginVertical: 5,
  },
  containerStyle: {
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  textCont: {
    flex: 1,
    marginLeft: 10,
    justifyContent: 'center',
  },
  actionButton: {
    marginVertical: 5,
    marginRight: 10,
  },
  moneyInputOuterContainer: {
    width: 120,
    justifyContent: 'center',
  },
  moneyInputInnerContainer: {
    borderColor: Colors.shades['100'],
    borderWidth: 1,
    borderRadius: 10,
    width: 100,
    margin: 0,
    padding: 0,
  },
  moneyInput: {
    width: 100,
    padding: 10,
  },
  imageStyle: {
    width: 33.43,
    height: 26,
    paddingRight: 10,
  },
  iconStyle: {
    fontSize: 30,
    paddingRight: 10,
  },
})
