import { INITIAL_LIMIT_N_ORDERS, snapshotOrdersByUserAndDueDate } from '@api/Orders'
import { LoaderWithMessage } from '@components'
import { Spinner, Text } from '@elements'
import { Order } from '@models/Order'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list'
import { memo, useCallback, useContext, useEffect, useMemo } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { OrdersParamList } from '../../navigation/types'
import { addNavProp } from '../../redux/actions/appState'
import { userSelector } from '../../redux/selectors'
import { OrderCard } from './OrderCard'

import { useInfiniteScroll } from '@/hooks/useInfiniteScroll'
import { fetchDraftOrdersForUser } from '@api/DraftOrders'
import { sortByLatest } from '@helpers/sorting'
import { DraftOrder, isDraftOrder } from '@models/DraftOrder'
import { useApiFx } from '../../hooks/useApiFx'
import { OrdersScreenContext } from './OrdersScreen'

export const OrderTab = memo(function OrderTab() {
  const [{ orders, tab, showEmptyOrders }, set] = useContext(OrdersScreenContext)
  const visible = tab === 'orders'
  const user = useSelector(userSelector)
  const navigation = useNavigation<StackNavigationProp<OrdersParamList, 'Orders'>>()
  const dispatch = useDispatch()
  const draftOrders = useApiFx(fetchDraftOrdersForUser, [user.id])

  const { increaseLimit, limit, loading, setLoading } = useInfiniteScroll(INITIAL_LIMIT_N_ORDERS, orders.length)

  useEffect(() => {
    setLoading(true)
    return snapshotOrdersByUserAndDueDate(
      (newOrders: Order[]) => {
        set('orders', newOrders)
        disableLoading()
      },
      undefined,
      user.id,
      limit > MaxLimit ? null : limit,
    )
    // No other more dependencies should be passed, in order to avoid unintended snapshot calls
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, user.id])

  // Will combine draft and final orders into a single sorted list
  const data: (Order | DraftOrder)[] = useMemo(() => {
    const filteredOrders = orders.filter((order: Order) => {
      if (showEmptyOrders) return true
      // Show only if the order has at least 1 uncanceled item
      return order.items.filter((itm) => !itm.cancelled).length !== 0
    })

    // Sort the draft orders so that they show most recent at the top
    const drafts = draftOrders.data?.sort(sortByLatest('date')) ?? []
    // We show all drafts before finalized orders
    return [...drafts, ...filteredOrders]
  }, [draftOrders.data, orders, showEmptyOrders])

  /** This delay ensures that the dispatch has finished */
  const disableLoading = () =>
    setTimeout(() => {
      setLoading(false)
    }, 150)

  const goToOrderDetails = useCallback(
    (order: Order | DraftOrder) => {
      if (isDraftOrder(order)) {
        dispatch(addNavProp({ order }))
        navigation.navigate('DraftOrderDetails', { draftOrderId: order.id })
      } else {
        dispatch(addNavProp({ order }))
        navigation.navigate('OrderSummary', { orderId: order.id })
      }
    },
    [dispatch, navigation],
  )

  const ListEndLoader = useCallback(() => {
    if (loading) {
      return <Spinner />
    }
    return <View />
  }, [loading])

  const renderItem = useCallback(
    (info: ListRenderItemInfo<Order | DraftOrder>) => {
      return <OrderCard order={info.item} goToOrderDetails={goToOrderDetails} />
    },
    [goToOrderDetails],
  )
  if (!visible) return <View />
  return (
    <FlashList<Order | DraftOrder>
      onEndReached={increaseLimit}
      onEndReachedThreshold={0.9}
      estimatedItemSize={400}
      ListFooterComponent={ListEndLoader}
      renderItem={renderItem}
      data={data}
      ListEmptyComponent={
        <LoaderWithMessage loading={loading} icon="shopping-bag" title="No Orders!">
          <Text>After you make a purchase on GrownBy, you can find your orders here.</Text>
        </LoaderWithMessage>
      }
    />
  )
})
export const MaxLimit = 300
