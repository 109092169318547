import { StyleSheet, TouchableOpacity } from 'react-native'

import { Text } from '../../components/elements/Text'
import Colors from '../../constants/Colors'

type BannerProps = {
  children: string | string[]
  // If the message is a success message and should be green
  success?: boolean
  onPress?(): void
}

export function Banner({ onPress, success, ...props }: BannerProps) {
  return (
    <TouchableOpacity
      activeOpacity={onPress ? 0.2 : 1}
      style={{ backgroundColor: success ? Colors.green : Colors.red }}
      onPress={onPress}
    >
      <Text style={styles.text} {...props} />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  text: {
    color: Colors.white,
    padding: 10,
    fontSize: 14,
  },
})
