import { SmallTable } from '@/admin/components/Analytics/SmallTable'
import { Distributions } from '@/admin/components/Analytics/types'
import { AdminDrawerParamList } from '@/admin/navigation/types'
import { globalStyles } from '@/constants/Styles'
import { useDeviceSize } from '@/hooks/useLayout'
import { Text } from '@elements'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Pressable, StyleSheet } from 'react-native'

type Props = {
  isLoading: boolean
  data: Distributions[] | undefined
}

/** Table for displaying Distribution data*/
export function SignInTable({ isLoading, data }: Props) {
  const { isExtraSmallDevice } = useDeviceSize()
  const navigation = useNavigation<StackNavigationProp<AdminDrawerParamList>>()

  const headers = isExtraSmallDevice ? ['Date', 'Location', ''] : ['Date', 'Location', 'Orders', '']

  const getColumns = (item: Distributions) => {
    if (isExtraSmallDevice) {
      // Hide sales column on small devices
      return [item.hours, item.name, <SignInBtn key={item.id} item={item} />]
    }

    return [item.hours, item.name, item.sales, <SignInBtn key={item.id} item={item} />]
  }

  return (
    <SmallTable
      isLoading={isLoading}
      title="Sign-in Sheets"
      data={data}
      columnsStyle={{ 2: styles.ordersColumn, 3: globalStyles.flex05 }}
      headers={headers}
      getColumns={getColumns}
      rightHeaderBtn={{
        title: 'See all',
        onPress: () => {
          navigation.navigate('DistributionSummaryStack', {
            screen: 'DistributionSummary',
            params: {
              tab: 'signIn',
            },
          })
        },
      }}
    />
  )
}

function SignInBtn({ item }: { item: Distributions }) {
  const { isExtraSmallDevice } = useDeviceSize()
  const navigation = useNavigation<StackNavigationProp<AdminDrawerParamList>>()

  return (
    <Pressable
      onPress={() =>
        navigation.navigate('DistributionSummaryStack', {
          screen: 'LocationSummary',
          params: { locId: item.locationId, tab: 'signIn', date: item.distributionDate },
        })
      }
    >
      <Text size={isExtraSmallDevice ? 12 : 14} style={styles.underline}>
        Start Sign-in
      </Text>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  underline: {
    textDecorationLine: 'underline',
  },
  ordersColumn: {
    flex: 0.5,
    alignItems: 'center',
  },
})
