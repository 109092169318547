import { Button, ButtonClear, Text, TextH1 } from '@elements'
import { StackScreenProps } from '@react-navigation/stack'
import React from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'

import { globalStyles } from '@/constants/Styles'
import { SignInSheetParamList } from '@/navigation/types'
import { PickupItemStatus } from '@models/Order'
import { SharedScreenHeader, sharedStyles } from './components'
import { useSignInSheetContext } from './useSignInSheetData'
import { withDataAndAuth } from './withDataAndAuth'

type Props = StackScreenProps<SignInSheetParamList, 'EndSignIn'>

function EndSignInScreenComp({ navigation, route }: Props) {
  const { onUpdateUnpickedItems } = useSignInSheetContext()

  /**Will skip updating the unpicked items, so they will remain as `Active` */
  const onSkipPress = async () => {
    navigation.navigate('AddNotes', { id: route.params.id, token: route.params.token })
  }

  /**Will mark all the unpicked items as `Donated` */
  const onDonatePress = async () => {
    await onUpdateUnpickedItems(PickupItemStatus.Donated)
    navigation.navigate('PostDonation', { id: route.params.id, token: route.params.token })
  }

  /**Will mark all the unpicked items as `Missed` */
  const onMarkAsMissedPress = async () => {
    await onUpdateUnpickedItems(PickupItemStatus.Missed)
    navigation.navigate('AddNotes', { id: route.params.id, token: route.params.token })
  }

  return (
    <ScrollView contentContainerStyle={sharedStyles.wrapper}>
      <SharedScreenHeader />
      <View style={globalStyles.margin20}>
        <TextH1 center>Ending sign-in</TextH1>
      </View>
      <Text center>Are you donating items that were not picked up?</Text>
      <View />
      <View style={globalStyles.margin20}>
        <View style={globalStyles.flexRow}>
          <Button onPress={onDonatePress} small title="Yes donate all items" />
          <View style={globalStyles.margin10} />
          <Button outline onPress={onMarkAsMissedPress} small title="No, mark as missed" />
        </View>
        <View style={globalStyles.margin10} />
        <View style={styles.alignEnd}>
          <ButtonClear style={styles.paddingRight0} onPress={onSkipPress} title="Skip for now" />
        </View>
      </View>
    </ScrollView>
  )
}

export const EndSignInScreen = withDataAndAuth(EndSignInScreenComp)

const styles = StyleSheet.create({
  alignEnd: {
    alignItems: 'flex-end',
  },
  paddingRight0: {
    paddingRight: 0,
  },
})
