import { Icon, Text, Touchable } from '@elements'
import { isFarmAdmin } from '@models/User'
import { CommonActions, useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback, useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { Overlay } from 'react-native-elements'
import { useDispatch, useSelector } from 'react-redux'

import { globalStyles } from '@/constants/Styles'
import { Image, ResizedSuffix } from '../../components/Image'
import Colors from '../../constants/Colors'
import { setCurrFarmAdmin } from '../../redux/actions/adminPersist'
import { adminFarmSelector, farmsSelector } from '../../redux/selectors'
import { AdminDrawerParamList } from '../navigation/types'

export function FarmSelection() {
  const [isVisible, setIsVisible] = useState(false)
  const farm = useSelector(adminFarmSelector)
  const navigation = useNavigation<StackNavigationProp<AdminDrawerParamList>>()
  const farmAssociations = useSelector(farmsSelector)
  const dispatch = useDispatch()

  //Check if current farm can access other farms as admin
  const multipleFarms = farmAssociations.filter(isFarmAdmin).length > 1
  const switchFarms = useCallback(
    (farmId: string) => () => {
      dispatch(setCurrFarmAdmin(farmId))
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{ name: 'Dashboard' }],
        }),
      )
      setIsVisible(false)
    },
    [dispatch, navigation],
  )

  if (!farm) return null

  return (
    <>
      <Touchable style={styles.container} onPress={() => setIsVisible(true)} disabled={!multipleFarms}>
        <Image type="logo" style={styles.farmLogo} source={{ uri: farm.logo }} />
        <Text style={globalStyles.flex1} numberOfLines={2} size={14} type="medium">
          {farm.name}
        </Text>
        {multipleFarms ? <Icon name="chevron-down" /> : <View />}
      </Touchable>
      <Overlay
        isVisible={isVisible}
        onBackdropPress={() => setIsVisible(false)}
        backdropStyle={{ backgroundColor: Colors.transparent }}
        overlayStyle={styles.overlayStyle}
      >
        <ScrollView style={styles.scrollMaxHeight}>
          {farmAssociations.filter(isFarmAdmin).map((fassoc) => (
            <Touchable style={styles.itemContainer} onPress={switchFarms(fassoc.id)} key={fassoc.id}>
              <Image
                type="logo"
                style={styles.farmLogo}
                resizeSuffix={ResizedSuffix.THUMB}
                source={{ uri: fassoc.farm?.logo }}
              />
              <Text type="medium" color={farm.id === fassoc.id ? Colors.green : undefined}>
                {fassoc.farm?.name}
              </Text>
            </Touchable>
          ))}
        </ScrollView>
      </Overlay>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 10,
    justifyContent: 'space-between',
  },
  overlayStyle: { position: 'absolute', left: 5, top: 50, borderRadius: 10 },
  scrollMaxHeight: { maxHeight: 400 },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 5,
  },
  farmLogo: {
    width: 40,
    height: 40,
    marginRight: 15,
    borderRadius: 30,
  },
})
