import { Alert, Toast } from '@elements'
import { isDistroLocalPickup } from '@models/Distribution'
import { CartItem, isCartStandard } from '@models/Order'
import { isPhysical } from '@models/Product'

export function conflictingCartAlert(): Promise<boolean> {
  return new Promise((resolve) => {
    Alert(
      "Can't Add Item",
      'You already have items in your cart from a different farm. Please checkout or press clear cart to continue shopping from this farm',
      [
        {
          text: 'Replace Cart',
          style: 'destructive',
          onPress: () => {
            resolve(true)
          },
        },
        {
          text: 'Dismiss',
          style: 'cancel',
          onPress: () => resolve(false),
        },
      ],
    )
  })
}

export function outOfStockAlert() {
  Alert('Out of Stock', 'This product is out of stock, check back to see when new product is available.')
}

/** Informs the user the cart operation they were initiated could not be completed for reason of insufficient stock.
 * - In the normal course of events, this should not happen because the UI should prevent you from adding an item whose stock goes beyond the stock available.
 */
export function insufficientStockAlert() {
  Alert('Insufficient Stock', "The item's quantity exceeds the product stock.")
}

const getAlertMsg = (msg?: string, showMsg?: boolean) => {
  if (msg) {
    return showMsg ? msg : 'Something went wrong while adding this product to the cart'
  } else {
    return 'The product was not added to the cart'
  }
}

/**
 * @param result The resulting item after the product was added to the cart. If undefined, means the addToCart flow aborted without an error.
 * @param opts Options for customizing the addToCart confirmation
 */
export const addToCartConfirmation = (
  result: Pick<CartItem, 'product' | 'unit' | 'pickups' | 'distribution'> | void,
  opts?: {
    autoAddedDistro?: boolean //to-be used?
    autoAddedDates?: boolean // to-be used?
    /** This could be either an error message or an exit message */
    msg?: string
    /** If "showMsg" is true, the confirmation will show the "msg". Otherwise it will show a generic user-friendly but opaque message */
    showMsg?: boolean
    alertType?: 'toast' | 'alert'
  },
): void => {
  const { autoAddedDates = false, autoAddedDistro = false, msg, showMsg = false, alertType = 'toast' } = opts ?? {}

  if (!result) {
    return alertType === 'toast'
      ? Toast(getAlertMsg(msg, showMsg))
      : Alert("Can't add this product to the cart", getAlertMsg(msg, showMsg))
  }
  const { product, unit, pickups, distribution } = result
  const isLocalPickup = distribution && isDistroLocalPickup(distribution)
  Toast(
    `${product.name} ${unit ? `(${unit.name})` : ''} added to your cart${
      isCartStandard(result)
        ? ` with ${pickups!.length} ${isCartStandard(result) && !isLocalPickup ? 'delivery' : 'pickup'} dates`
        : ''
    }${isPhysical(product) && isLocalPickup ? ` for ${distribution.location.name}` : ''}`,
  )
}
