import { useFocusFx } from '@/hooks/useFocusFx'
import { useRange } from 'react-instantsearch'

/** Algolia refinement helper to filter numeric data by a range */
export function useRangeRefinement(numericAttribute: string, value: { min?: number; max?: number }) {
  const { range: limits, refine, canRefine } = useRange({ attribute: numericAttribute })

  /** This keeps the current algolia filter refinement in sync with the refinement value from params */
  useFocusFx(
    () => {
      if (!canRefine) return

      // Make sure that the limits are not exceeded as algolia won't work if so
      const from = value.min === undefined || limits.min === undefined ? undefined : Math.max(value.min, limits.min)
      const to = value.max === undefined || limits.max === undefined ? undefined : Math.min(value.max, limits.max)

      /** Undefined will reset the boundaries */
      refine([from, to])
    },
    [canRefine, value.min, value.max, limits.min, limits.max, refine],
    { noRefocus: true },
  )
  return {
    /** `value` is returned here intentionally because the current filter state has to be the one that was selected,
     and algolia is returning -Infinity / Infinity when the lower / upper limits are exceeded */
    currentFilter: value,
    limits,
    canRefine,
  }
}

export type RangeRefinementType = ReturnType<typeof useRangeRefinement>
