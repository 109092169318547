import { CartServiceType } from '@/constants/types/cartService'
import { useApiFx } from '@/hooks/useApiFx'
import { useCartService } from '@/hooks/useCart'
import { useCoords } from '@/hooks/useCoords/useCoords'
import { userSelector } from '@/redux/selectors'
import { getParsedAddressFromCoords } from '@api/Addresses'
import { dequal } from '@helpers/customDequal'
import { formatAddress } from '@helpers/display'
import { Coordinate } from '@models/Coordinate'
import { UserAddress } from '@models/UserAddress'
import { useSelector } from 'react-redux'

/** Provides the string that will be used as initial value in the google places search input */
export const useInitialAddress = ({
  condition = true,
  cartServiceType,
}: {
  /** While false the fx won't run and the loading state will remain false. This can be used for waiting on some condition to be met or to simply disable this. When it becomes true the fx will run */
  condition?: boolean
  /** When getting the initial address for the addToCart flow delivery selection in the admin side this should specify the admin cart service type so this can access the cart customer and use their address. In the consumer side it should be undefined because in that case the addToCart flow should use the signed-in user's address */
  cartServiceType: CartServiceType
}) => {
  const coords = useCoords()
  const { address: signedInUserAddr } = useSelector(userSelector)

  const {
    customer: {
      /** This is accessing the customer's address from the cart service. It should be used when the addToCart flow is used in the admin side and we need the customer's initial address for the google places search input for delivery */
      address: cartCustomerAddr,
    } = {},
    isCartInit,
  } = useCartService({ cartServiceType })

  const shouldRun: boolean = (() => {
    if (!condition) return false

    if (cartServiceType === 'consumer') {
      // In the consumer side the initial address should be based off of these best-effort coords if defined
      // If not defined should not run
      return !!coords
    } else {
      // In the admin side the initial address should be based off of the cart customer's address if it exists
      // If not defined should not run
      return isCartInit && !!cartCustomerAddr
    }
  })()

  return useApiFx(
    async (
      coords: Coordinate,
      signedInUserAddr: UserAddress | undefined,
      cartServiceType: CartServiceType,
      cartCustomerAddr: UserAddress | undefined,
    ) => {
      if (cartServiceType === 'consumer') {
        if (!coords) {
          throw new Error('Coords should be defined for this to run in consumer side')
        }
        if (!!signedInUserAddr && dequal(signedInUserAddr?.coordinate, coords)) {
          // If the coords selected are equal to those in the user address it should not reverse-geocode the coordinates. Just return the formatted user address
          return formatAddress(signedInUserAddr)
        }

        // In this scenario the coords are being determined by the last search location or the current device location. The coords will be reverse geocoded to get the initial address for the input
        const parser = await getParsedAddressFromCoords(coords)
        return formatAddress(parser.getAddress())
      } else {
        // Admin side scenario
        if (!cartCustomerAddr) {
          throw new Error('Cart customer address should be defined for this to run in admin side')
        }

        // If there's a cart service type specified and the specified cart has a customer, we must get the initial address from the cart customer
        return formatAddress(cartCustomerAddr)
      }
    },
    [coords, signedInUserAddr, cartServiceType, cartCustomerAddr],
    shouldRun,
    {
      initialState: { data: undefined },
      failedConditionMode: 'stop-loading',
      // This should only run once because we don't want a new initial address to be set when we clear the value
      once: true,
      noRefocus: true,
    },
  )
}
