import Colors from '@/constants/Colors'
import { useFocusFx } from '@/hooks/useFocusFx'
import useKeyedState from '@/hooks/useKeyedState'
import { shouldShowReLoginAlert } from '@/hooks/useMultiFactor/helpers'
import { userSelector } from '@/redux/selectors'
import { validatePhoneNumber } from '@api/Users'
import { FormBuilder, PhoneInput } from '@components'
import { Alert, Button, FormButton, Text, ToggleButton } from '@elements'
import { mainNumPatternForms, unmarshalPhoneNumber } from '@helpers/display'
import { errorToString } from '@helpers/helpers'
import { Formik, FormikProps } from 'formik'
import { useCallback } from 'react'
import { StyleSheet, View } from 'react-native'
import { Input } from 'react-native-elements'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { RecaptchaContainer, useMultiFactor } from '../../../../hooks/useMultiFactor/useMultiFactor'

type FormType = {
  phoneNumber: string
  verificationCode: string
}

const registerValidationSchema = Yup.object<FormType>().shape({
  phoneNumber: Yup.string()
    .matches(mainNumPatternForms, 'Please enter a valid phone number.')
    .label('Phone Number')
    .required('Please enter a valid phone number.'),
  verificationCode: Yup.string().required('This field is required'),
})

type StateTypes = {
  mfaEnable: boolean
  showCodeInput: boolean
  errorMessage: string
  loaders: {
    sendCode: boolean
    confirmCode: boolean
  }
}

/** Component states */
const initialValues: StateTypes = {
  mfaEnable: false,
  showCodeInput: false,
  errorMessage: '',
  loaders: {
    sendCode: false,
    confirmCode: false,
  },
}

const showReloginAlertIfNeeded = (err: unknown) => {
  if (shouldShowReLoginAlert(err)) {
    Alert('Security Alert', 'For security reasons, please log out and log back in to continue.')
  }
}

/** Multi-Factor Manage is a modal and used to handle 'enroll multi-factor and un-enroll multi-factor' */
export function MultiFactorManage() {
  const [{ mfaEnable, showCodeInput, errorMessage, loaders }, set, setAll] = useKeyedState<StateTypes>(initialValues)
  const user = useSelector(userSelector)
  const { onSendCode, onConfirmCode, onUnEnroll, isEnrolled, canBeUnEnrolled } = useMultiFactor()

  useFocusFx(() => {
    /** If status is true, set isEnrolled and mfaEnable as true */
    if (isEnrolled) setAll((value) => ({ ...value, mfaEnable: true }))
  }, [isEnrolled, setAll])

  /** Send Verification Code Handler */
  const sendCodeHandler = useCallback(
    async (phoneNumber: string) => {
      try {
        set('loaders', (loaders) => ({ ...loaders, sendCode: true }))
        const phone = await validatePhoneNumber(phoneNumber, user.id, false)
        await onSendCode(phone)

        setAll((value) => ({
          ...value,
          showCodeInput: true,
          errorMessage: '',
          loaders: { ...loaders, sendCode: false },
        }))
      } catch (err) {
        showReloginAlertIfNeeded(err)
        setAll((value) => ({
          ...value,
          errorMessage: errorToString(err),
          loaders: { ...value.loaders, sendCode: false },
        }))
      }
    },
    [loaders, onSendCode, set, setAll, user.id],
  )

  /** Verify Code Handler */
  const submitHandler = useCallback(
    async (values: FormType) => {
      try {
        set('loaders', (loaders) => ({ ...loaders, confirmCode: true }))
        await onConfirmCode(values.verificationCode)
        setAll((value) => ({ ...value, isEnrolled: true, errorMessage: '' }))
      } catch (err) {
        showReloginAlertIfNeeded(err)
        set('errorMessage', errorToString(err))
      } finally {
        set('loaders', (loaders) => ({ ...loaders, confirmCode: false }))
      }
    },
    [onConfirmCode, set, setAll],
  )

  /** enable MFA handler (will also handle unEnroll) */
  const enableMFAHandler = useCallback(
    async (resetForm: () => void) => {
      try {
        if (mfaEnable) {
          resetForm()
          setAll((value) => ({ ...value, showCodeInput: false, errorMessage: '' }))
        }
        if (isEnrolled) {
          await onUnEnroll?.()

          setAll((value) => ({
            ...value,
            mfaEnable: false,
            showCodeInput: false,
            isEnrolled: false,
            errorMessage: '',
          }))
        } else set('mfaEnable', (value) => !value)
      } catch (err) {
        showReloginAlertIfNeeded(err)
        setAll((value) => ({ ...value, mfaEnable: true, errorMessage: errorToString(err) }))
      }
    },
    [isEnrolled, mfaEnable, set, setAll, onUnEnroll],
  )

  return (
    <Formik
      initialValues={{
        phoneNumber: user?.phoneNumber ? unmarshalPhoneNumber(user.phoneNumber, false) : '',
        verificationCode: '',
      }}
      onSubmit={submitHandler}
      validationSchema={registerValidationSchema}
    >
      {({ values, errors, handleBlur, touched, handleChange, handleSubmit, resetForm }: FormikProps<FormType>) => (
        <View style={styles.container}>
          <RecaptchaContainer />
          {isEnrolled ? (
            <Text>Two-Factor Authentication is currently enabled for your account.</Text>
          ) : (
            <Text>
              Enhance the security of your account by requiring two methods of verification when logging in: your
              password as well as a code sent to your mobile phone number.
            </Text>
          )}
          <ToggleButton
            iconRight
            title={`${isEnrolled ? 'Disable' : 'Enable'} Two-Factor Authentication`}
            disabled={isEnrolled && !canBeUnEnrolled}
            value={mfaEnable}
            onChange={() => enableMFAHandler(resetForm)}
          />
          {isEnrolled && !canBeUnEnrolled && (
            <Text color={Colors.red}>
              If you would like to un-enroll Two-Factor Authentication, please do so from the web app.
            </Text>
          )}
          {mfaEnable && !isEnrolled && (
            <FormBuilder>
              <PhoneInput
                labelStyle={{ color: Colors.shades[500] }}
                value={values.phoneNumber}
                maskHandler={handleChange('phoneNumber')}
                onBlur={handleBlur('phoneNumber')}
                errorMessage={errors.phoneNumber}
                renderErrorMessage={touched.phoneNumber}
                rightIcon={
                  <Button
                    small
                    size={10}
                    loading={loaders.sendCode}
                    title="Send Code"
                    disabled={!!errors.phoneNumber || !values.phoneNumber}
                    onPress={() => sendCodeHandler(values.phoneNumber)}
                  />
                }
              />

              {showCodeInput && (
                <Input
                  inputStyle={styles.paddingLeft10}
                  numberOfLines={1}
                  placeholder="Verification Code"
                  errorMessage={touched.verificationCode ? errors.verificationCode : ''}
                  value={values.verificationCode}
                  onChangeText={handleChange('verificationCode')}
                  onBlur={handleBlur('verificationCode')}
                  rightIcon={
                    <FormButton
                      title="Verify Code"
                      loading={loaders.confirmCode}
                      onPress={handleSubmit}
                      small
                      size={10}
                    />
                  }
                />
              )}
              {showCodeInput ? (
                <Text>Please enter the code we have sent to your phone number to complete setup.</Text>
              ) : null}
            </FormBuilder>
          )}
          {!!errorMessage && (
            <Text color={Colors.red}>
              Something went wrong setting up Two-Factor Authentication, please try again in a little while.{' '}
              {errorMessage}
            </Text>
          )}
        </View>
      )}
    </Formik>
  )
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 15,
  },
  headerText: {
    textAlign: 'center',
    maxWidth: 400,
  },
  paddingLeft10: {
    paddingLeft: 10,
  },
})
