import { EbtIcon } from '@components'
import { CheckBox, Text, Touchable } from '@elements'
import { InvalidAmount, formatMoney, formatPickupDate } from '@helpers/display'
import { orderItemTotal } from '@helpers/order'
import { isNonPickup } from '@models/Location'
import { Order, OrderItem as OrderItemType } from '@models/Order'
import { isFarmBalance, isShare, isStandard } from '@models/Product'
import { DateTime } from 'luxon'
import { View } from 'react-native'
import { Image } from 'react-native-elements'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import { globalStyles } from '../../../../constants/Styles'

import Colors from '@/constants/Colors'
import { useHasPermissionWithFlag } from '@/hooks/useHasPermission'
import { useLayout } from '@/hooks/useLayout'
import { AccessRight, Permission } from '@helpers/Permission'
import { formatDistributionType } from '@helpers/location'

type PropTypes = {
  currentOrder: Order
  item: OrderItemType
  firstPickup?: DateTime
  numPayments: number
  cancelledItems: OrderItemType[]
  setCancelledItems: (items: OrderItemType[]) => void
}

function OrderItem({ currentOrder, item, numPayments, cancelledItems, firstPickup, setCancelledItems }: PropTypes) {
  const styles = useStyles()
  const isChecked = !!cancelledItems.find(({ id }) => id === item.id)
  const { isSmallDevice } = useLayout()
  const hasAccessCancel = useHasPermissionWithFlag(Permission.Orders, AccessRight.Edit)

  //handle checkbox
  const handleCheckBoxChange = () => {
    const findNum = cancelledItems.findIndex((currentItem) => currentItem.id === item.id)
    if (findNum === -1) {
      setCancelledItems([...cancelledItems, item])
    } else {
      setCancelledItems(cancelledItems.filter((currentItem) => currentItem.id !== item.id))
    }
  }

  const subscriptionUrlGen = () => {
    const custId = currentOrder.user.id
    const orderNum = currentOrder.orderNum
    const productId = item.product.id
    return `/admin/customers/${custId}?orderNum=${orderNum}&productId=${productId}`
  }

  return (
    <View
      style={[
        styles.productCard,
        {
          backgroundColor: isChecked ? Colors.lightGray : Colors.white,
        },
      ]}
    >
      <View style={styles.container1}>
        <View style={styles.imgCheckboxContainer}>
          <View style={globalStyles.flex1}>
            <CheckBox
              disabled={!hasAccessCancel || !!item.cancelled || isFarmBalance(item.product)}
              checked={!!cancelledItems.find((currentItem) => currentItem.id === item.id)}
              onChange={handleCheckBoxChange}
              size={isSmallDevice ? 30 : 25}
              style={{ marginLeft: 5, marginRight: 15 }}
            />
          </View>
          <Image
            source={{
              uri: item.product.image,
            }}
            style={styles.productImage}
            resizeMode="cover"
          />
          <View style={styles.rightImgContainer} />
        </View>
        <View style={styles.productInfo}>
          <View style={globalStyles.flexRow}>
            <Text size={12} numberOfLines={2}>
              (x{item.quantity})<EbtIcon product={item.product} />
              {item.product.name}{' '}
              {isStandard(item.product) &&
                (item.numPickups || 0) > 1 &&
                `(No. ${formatDistributionType(item.distribution?.location, { plural: true })}: ${item.numPickups})`}
            </Text>

            {item.cancelled && (
              <Text size={10} style={styles.cancelledWarning} type="bold">
                Item Cancelled
              </Text>
            )}
            {isFarmBalance(item.product) && (
              <Text
                size={10}
                style={[styles.cancelledWarning, { backgroundColor: Colors.green, color: Colors.white }]}
                type="bold"
              >
                Not Cancellable
              </Text>
            )}
          </View>
          {isShare(item.product) ? (
            <>
              <Text numberOfLines={2} style={styles.productDesc}>
                CSA: {item.csa?.name}
              </Text>

              <View
                style={[
                  styles.productDescWrapper,
                  {
                    flexDirection: item.distribution && item.distribution.location.name.length > 30 ? 'column' : 'row',
                  },
                ]}
              >
                <Text numberOfLines={2} style={[styles.productDesc, { marginRight: 5 }]}>
                  {item.distribution && isNonPickup(item.distribution.location)
                    ? `${formatDistributionType(item.distribution.location, { capitalize: true })} Zone`
                    : `Pickup Location`}
                  :
                </Text>
                <Text numberOfLines={2} style={styles.productDesc}>
                  {item.distribution?.location?.name}
                </Text>
              </View>
              {!!firstPickup && (
                <Text numberOfLines={2} style={styles.productDesc}>
                  {item.distribution && isNonPickup(item.distribution.location)
                    ? `${formatDistributionType(item.distribution.location, { capitalize: true })} Date`
                    : `Pickup Date`}
                  : {formatPickupDate(firstPickup)}
                </Text>
              )}
            </>
          ) : (
            <>
              <View
                style={[
                  styles.productDescWrapper,
                  {
                    flexDirection: item.distribution && item.distribution.location.name.length > 30 ? 'column' : 'row',
                  },
                ]}
              >
                <Text numberOfLines={2} style={[styles.productDesc, { marginRight: 5 }]}>
                  {item.distribution && isNonPickup(item.distribution.location)
                    ? `${formatDistributionType(item.distribution.location, { capitalize: true })} Zone`
                    : `Pickup Location`}
                  :
                </Text>
                <Text numberOfLines={2} style={styles.productDesc}>
                  {item.distribution?.location?.name || InvalidAmount}
                </Text>
              </View>
              {!!firstPickup && (
                <Text numberOfLines={2} style={styles.productDesc}>
                  {item.distribution && isNonPickup(item.distribution.location)
                    ? `${formatDistributionType(item.distribution.location, { capitalize: true })} Date`
                    : `Pickup Date`}
                  : {formatPickupDate(firstPickup)}
                </Text>
              )}
            </>
          )}
        </View>
      </View>
      <View style={styles.container2}>
        <View style={[styles['container2-1'], { alignItems: isShare(item.product) ? 'center' : 'flex-start' }]}>
          <View style={styles['container2-1-1']}>
            {isShare(item.product) ? (
              <>
                {item.paymentSchedule.frequency !== 'ONCE' ? (
                  <Text size={12} numberOfLines={1} style={{ marginBottom: isSmallDevice ? 5 : 20 }}>
                    ({item.paymentSchedule.frequency} price)
                  </Text>
                ) : (
                  <Text size={12} numberOfLines={1} style={{ marginBottom: isSmallDevice ? 5 : 20 }}>
                    (total price)
                  </Text>
                )}
                <Text size={12} numberOfLines={1}>
                  (up front payment)
                </Text>
              </>
            ) : (
              item.purchasedUnit && (
                <Text size={12} style={{ marginTop: isSmallDevice ? 20 : 0 }}>
                  {`${formatMoney(item.purchasedUnitPrice!.amount)}/ ${item.purchasedUnit.name}`}
                </Text>
              )
            )}
          </View>
          <View style={styles['container2-1-2']}>
            <View style={styles['container2-1-2-1']}>
              {isShare(item.product) ? (
                <>
                  <Text size={12} style={{ marginBottom: isSmallDevice ? 5 : 20 }}>
                    {formatMoney(
                      ((item.paymentSchedule.amount.value - item.paymentSchedule.deposit.value) / (numPayments || 1)) *
                        item.quantity,
                    )}
                  </Text>
                  <Text size={12}>{formatMoney(item.paymentSchedule.deposit.value * item.quantity)}</Text>
                </>
              ) : (
                <Text size={12} style={{ marginTop: isSmallDevice ? 20 : 0 }}>
                  {formatMoney(orderItemTotal(item))}
                </Text>
              )}
            </View>
            <View style={styles['container2-1-2-2']} />
          </View>
        </View>
        <View style={styles['container2-2']}>
          <Touchable url={subscriptionUrlGen()}>
            <Text size={12} numberOfLines={1} style={styles.manageBoxText}>
              {!isShare(item.product) ? '' : 'Subscription'}
            </Text>
          </Touchable>
        </View>
      </View>
    </View>
  )
}

export default OrderItem

const useStyles = CreateResponsiveStyle(
  {
    productCard: {
      flex: 1,
      flexDirection: 'row',
      overflow: 'hidden',
      paddingVertical: 40,
      paddingHorizontal: 10,
      borderColor: Colors.shades['100'],
    },
    imgCheckboxContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      alignSelf: 'stretch',
    },
    // empty container with flex 1 in order to center the image, only for x small devices
    rightImgContainer: {
      flex: 0,
    },
    container1: {
      flexDirection: 'row',
      flex: 0.5,
      minHeight: 100,
      alignItems: 'center',
    },
    productInfo: {
      alignSelf: 'stretch',
      paddingHorizontal: 20,
      justifyContent: 'space-between',
      letterSpacing: 0.5,
    },
    productDescWrapper: {
      alignItems: 'flex-start',
    },
    productImage: {
      width: 100,
      borderRadius: 5,
      height: 100,
      alignSelf: 'center',
    },
    productDesc: {
      color: '#606060',
      fontSize: 12,
      letterSpacing: 0.5,
    },
    cancelledWarning: {
      backgroundColor: Colors.red,
      color: Colors.white,
      paddingHorizontal: 10,
      paddingVertical: 5,
      borderRadius: 100,
      justifyContent: 'flex-start',
      marginLeft: 10,
    },
    container2: {
      flex: 0.5,
      flexDirection: 'row',
    },
    'container2-1': {
      flex: 0.8,
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    'container2-1-1': {
      flex: 0.65,
      alignItems: 'center',
      letterSpacing: 0.5,
    },
    'container2-1-2': {
      flex: 0.35,
      flexDirection: 'row',
    },
    'container2-1-2-1': {
      flex: 0.3,
      alignItems: 'flex-end',
    },
    'container2-1-2-2': {
      flex: 0.7,
    },
    'container2-2': {
      flex: 0.2,
      justifyContent: 'center',
      alignItems: 'center',
    },
    manageBoxText: {
      color: Colors.blue,
      letterSpacing: 0.5,
      textDecorationLine: 'underline',
    },
  },
  {
    [maxSize(DEVICE_SIZES.LARGE_DEVICE)]: {
      productCard: {
        flexDirection: 'column',
      },
      container2: {
        marginTop: 30,
      },
    },
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      productDesc: {
        fontSize: 12,
      },
    },
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      productCard: {
        paddingVertical: 10,
        paddingHorizontal: 5,
        paddingBottom: 20,
      },
      productImage: {
        width: 130,
        height: 130,
        marginTop: 10,
      },
      productInfo: {
        marginVertical: 10,
        alignItems: 'center',
      },
      productDescWrapper: {
        alignItems: 'center',
      },
      productDesc: {
        fontSize: 12,
      },
      container1: {
        flexDirection: 'column',
        marginBottom: -70,
        flex: 1,
      },
      container2: {
        flexDirection: 'column',
        marginTop: 50,
      },
      'container2-1': {
        marginTop: 40,
      },

      'container2-1-1': {
        flex: 0.6,
      },
      'container2-1-2': {
        flex: 0.4,
      },
      'container2-1-2-1': {
        flex: 0.8,
      },
      'container2-1-2-2': {
        flex: 0.2,
      },
      rightImgContainer: {
        flex: 1,
      },
    },
  },
)
