import { Alert, Modal, Text } from '@elements'
import { formatFormalShortDate, formatMiniDate, getOrderNum } from '@helpers/display'
import { Pickup, isPickupItemOnVacation, PickupItem, isDraftPickup } from '@models/Order'
import { isPhysical, isShare, isStandard } from '@models/Product'
import { dateTimeInZone } from '@models/Timezone'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { CancelPickup } from '@screens/Orders/CancelPickup'
import { makeItemListData } from '@screens/Orders/PickupOrderItems'
import { Reschedule } from '@screens/Orders/Reschedule'
import { useCallback, useState } from 'react'

import { ExpandableRow } from '@/admin/components/OfflineTable/ExpandableRow'
import { ActionsMenuComponent } from '@/admin/components/elements/ActionsMenuComponent'
import { AdminDrawerParamList, CustomerParamList } from '@/admin/navigation/types'
import Colors from '@/constants/Colors'
import { useFnStyles } from '@/hooks/useFnStyles'
import { useDeepCompareFocusFx } from '@/hooks/useFocusFx'
import { useHasPermissionWithFlag } from '@/hooks/useHasPermission'
import { AccessRight, Permission } from '@helpers/Permission'
import { isAfter } from '@helpers/time'
import { formatDistributionType } from '@helpers/location'

type PickupRowProps = {
  pickup: Pickup
  index: number
  reload: () => void
}

/** The PickupSectionRow holds each pickup row which is one pickup and data for all its items. */
export function PickupSectionRow({ pickup, index, reload }: PickupRowProps) {
  const [pickupRows, setPickupRows] = useState<PickupItem[][]>([])
  const now = dateTimeInZone(pickup.date.zoneName)
  const hasAccessEditPickup = useHasPermissionWithFlag(Permission.Orders, AccessRight.Edit) ?? false
  const styles = useStyles(index, hasAccessEditPickup)
  const navigation = useNavigation<StackNavigationProp<CustomerParamList & AdminDrawerParamList>>()

  useDeepCompareFocusFx(() => {
    /** Here we remove cancelled pickups and then group pickups by Order, so we have an array of PickupItem[] per orderId*/
    setPickupRows(makeItemListData(pickup.items))
  }, [pickup.items])

  /** Get total number of items that should be picked up or delivered */
  const getTotalItemsNum = useCallback((pickupItems: PickupItem[]) => {
    return pickupItems.reduce((acc, cur) => acc + (isPickupItemOnVacation(cur) ? 0 : cur.orderItem.quantity), 0)
  }, [])

  /** Local helper function inside displayCancelPress and getItemsDescription */
  const onCancelPress = useCallback(
    (pickup: Pickup, title: string, type: 'unclaim' | 'claim' = 'claim') => {
      if (isDraftPickup(pickup))
        return Alert(
          'Cannot cancel',
          'This pickup is part of a draft order which must be finalized before changes to this pickup can happen.',
        )
      //The delayed time to reload is intentionally set to 2 second to make sure any actions are completed on the backend before reloading data.
      Modal(<CancelPickup pickup={pickup} onSuccess={() => setTimeout(reload, 2000)} type={type} isAdmin />, { title })
    },
    [reload],
  )

  /** Get getItemsDescription for all items (similar format like signIn sheet) */
  const getItemsDescription = useCallback(
    (pickupItems: PickupItem[]) => (
      <Text>
        {pickupItems
          .filter((item) => isPhysical({ type: item.product.type }))
          .map((item, index) => {
            const notLastOne = index < pickupItems.length - 1

            // For share products
            if (isShare(item.product)) {
              const localReturnText = `${item.product.name} (${item.orderItem.quantity}x Share)${
                notLastOne ? '; ' : ''
              }`

              if (isPickupItemOnVacation(item)) {
                const localPickup: Pickup = { ...pickup, items: [item] }
                return (
                  <Text
                    key={`${item.product.type}_${item.product.name}_${index}`}
                    style={styles.actionLink}
                    onPress={
                      hasAccessEditPickup ? () => onCancelPress(localPickup, 'Cancel Vacation', 'unclaim') : undefined
                    }
                  >
                    [Skipped] {localReturnText}
                  </Text>
                )
              } else {
                return localReturnText
              }
            }
            // For standard products
            else {
              return `${item.product.name} (${item.orderItem.quantity}x ${item.orderItem.purchasedUnit?.name || ''})${
                notLastOne ? '; ' : ''
              }`
            }
          })}
      </Text>
    ),
    [onCancelPress, hasAccessEditPickup, pickup, styles.actionLink],
  )

  /** Display cancel standard pickup or claim share vacation for current row. */
  const displayCancelButton = useCallback(
    (items: PickupItem[]) => {
      const pickupItems = items.filter((item) => !isPickupItemOnVacation(item))
      const localPickup = { ...pickup, items: pickupItems }
      const locationType = formatDistributionType(
        { type: pickup.distribution.locationType },
        { capitalize: true, action: false },
      )

      const title = pickupItems.every((item) => isStandard(item.product.type))
        ? `Cancel ${locationType}`
        : pickupItems.some((item) => isStandard(item.product.type))
        ? `Claim Vacation / Cancel ${locationType}`
        : 'Claim Vacation'
      return {
        title,
        onPress: () => onCancelPress(localPickup, title),
      }
    },
    [onCancelPress, pickup],
  )

  /** Local helper function inside displayReschedulePress */
  const onReschedulePress = useCallback(
    (pickup: Pickup) => {
      Modal(<Reschedule pickup={pickup} onSuccess={() => setTimeout(reload, 2000)} isAdmin />, {
        title: 'Reschedule Items',
      })
    },
    [reload],
  )

  /** Display reschedule for current row. */
  const displayRescheduleButton = useCallback(
    (pickupItems: PickupItem[]) => {
      const localPickup = { ...pickup, items: pickupItems }

      return {
        title: 'Reschedule',
        onPress: () => onReschedulePress(localPickup),
      }
    },
    [onReschedulePress, pickup],
  )

  /** local helper function inside displayOrderNum */
  const onOrderNumPress = useCallback(
    (orderId: string) => {
      navigation.navigate('AdminOrderDetails', { orderId })
    },
    [navigation],
  )

  /** Display order number for current row. */
  const displayOrderNum = useCallback(
    (pickupItems: PickupItem[]) => {
      return (
        <Text color={Colors.blue} onPress={() => onOrderNumPress(pickupItems[0].order.id)}>
          {getOrderNum(pickupItems[0].order.orderNum)}
        </Text>
      )
    },
    [onOrderNumPress],
  )

  /** Generate the navigation logic to view status for current row. */
  const viewStatusNavigation = useCallback(() => {
    if (isDraftPickup(pickup)) {
      return <Text color={Colors.shades['300']}>Pending</Text>
    }
    return (
      <Text
        style={styles.actionLink}
        onPress={() =>
          navigation.navigate('DistributionSummaryStack', {
            screen: 'DistributionSummary',
            params: {
              tab: 'signIn',
              fromDate: formatFormalShortDate(pickup.date.startOf('day')),
              endDate: formatFormalShortDate(pickup.date.endOf('day')),
            },
          })
        }
      >
        View Status
      </Text>
    )
  }, [navigation, pickup, styles.actionLink])

  /** Display action buttons for current row. */
  const displayActionButtons = useCallback(
    (pickupItems: PickupItem[]) => {
      const isPast = isAfter(now.endOf('day'), pickup.date)
      /**
       * Disable action buttons if:
       * 1. User doesn't have access to edit pickup
       * 2. Pickup date is in the past
       * 3. There is technically no item in the pickup
       * 4. It is a draft pickup
       */
      const actionDisabled =
        !hasAccessEditPickup || isPast || getTotalItemsNum(pickupItems) === 0 || isDraftPickup(pickup)
      return (
        <ActionsMenuComponent
          buttons={[displayCancelButton(pickupItems), displayRescheduleButton(pickupItems)]}
          disabled={actionDisabled}
        />
      )
    },
    [displayCancelButton, displayRescheduleButton, getTotalItemsNum, hasAccessEditPickup, now, pickup],
  )

  return (
    <>
      {pickupRows.map((row, rowIndex) => (
        <ExpandableRow<PickupItem[]>
          item={row}
          index={index}
          key={`pickupRow_${pickup.id}_${rowIndex}`}
          rowContainerStyle={styles.rowContainerStyle}
          columns={[
            {
              process: () => (rowIndex === 0 ? formatMiniDate(pickup.date) : ''),
            },
            {
              process: () =>
                rowIndex === 0
                  ? formatDistributionType(
                      { type: pickup.distribution.locationType },
                      { capitalize: true, action: true },
                    )
                  : '',
            },
            {
              process: () => (rowIndex === 0 ? pickup.distribution.locationName : ''),
              widthFlex: 2,
            },
            {
              process: (pickupItems: PickupItem[]) => displayOrderNum(pickupItems),
              widthFlex: 0.8,
            },
            {
              process: (pickupItems: PickupItem[]) => getItemsDescription(pickupItems),
              widthFlex: 3,
            },
            {
              process: (pickupItems: PickupItem[]) => (
                <Text style={styles.totalItems}>{getTotalItemsNum(pickupItems)} TOTAL</Text>
              ),
            },
            {
              process: viewStatusNavigation,
              widthFlex: 1.5,
            },
            {
              process: displayActionButtons,
            },
          ]}
        />
      ))}
    </>
  )
}

const useStyles = (index: number, hasAccessEditPickup: boolean) =>
  useFnStyles(
    (index, hasAccessEditPickup) => ({
      rowContainerStyle: {
        backgroundColor: index % 2 === 0 ? Colors.shades['75'] : Colors.white,
        borderBottomWidth: 0,
      },
      totalItems: {
        marginLeft: 5,
      },
      actionLink: {
        color: hasAccessEditPickup ? Colors.blue : Colors.shades['300'],
        textDecorationLine: 'underline',
      },
    }),
    index,
    hasAccessEditPickup,
  )
