import Colors from '@/constants/Colors'
import React, { useCallback, useState } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import { Icon } from 'react-native-elements'
import { PICKER_PLACEHOLDER, PickerProps, getLabel, parseItems } from '../Picker'
import { Spinner } from '../Spinner'
import { Text } from '../Text'
import { BottomSheetList } from './BottomSheetList'

/** Touchable element compatible with the native Picker that opens a bottom sheet selector */
export function BottomSheetPicker({
  items,
  placeholder = PICKER_PLACEHOLDER,
  style,
  value,
  disabled = false,
  loading = false,
  minimal = false,
  onValueChange,
}: PickerProps) {
  const [isVisible, setIsVisible] = useState(false)

  const toggleVisible = useCallback(() => setIsVisible((c) => !c), [])

  return (
    <>
      <Pressable
        disabled={disabled || loading}
        onPress={toggleVisible}
        style={[styles.wrapper, minimal && styles.minimal, style]}
      >
        <Text color={Colors.shades[disabled ? '300' : '600']}>{getLabel({ placeholder, items, value })}</Text>
        <View style={styles.iconCont}>
          {loading ? (
            <Spinner size="small" />
          ) : (
            <Icon size={24} color={Colors.shades[disabled ? '300' : '600']} type="entypo" name="chevron-small-down" />
          )}
        </View>
      </Pressable>
      <BottomSheetList
        isVisible={isVisible}
        items={parseItems({ items, placeholder, value })}
        onBackdropPress={toggleVisible}
        selectedItem={items.find((items) => items.value === value)}
        getTitle={(itm) => itm.label ?? ''}
        onItemPress={(itm, index) => {
          toggleVisible()
          onValueChange(itm.value!, index)
        }}
      />
    </>
  )
}
const styles = StyleSheet.create({
  wrapper: {
    justifyContent: 'center',
    paddingHorizontal: 10,
    borderWidth: 1,
    height: 40,
    overflow: 'hidden',
    borderColor: Colors.lightGray,
    borderRadius: 8,
    paddingRight: 34, // to ensure the text is never behind the icon (icon: 30 width + 4 marginRight)
  },
  minimal: {
    borderWidth: 0,
  },
  iconCont: {
    right: 4,
    width: 30,
    top: 4,
    bottom: 4,
    zIndex: 2,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
  },
})
