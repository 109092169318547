import Colors from '@/constants/Colors'
import { isWeb } from '@/constants/Layout'
import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { RequireAtLeastOne } from '@helpers/typescript'
import React from 'react'
import { FlatList, FlatListProps, ListRenderItemInfo, View } from 'react-native'
import { ListItem, Overlay, OverlayProps } from 'react-native-elements'
import { Text } from '../Text'

type BaseProps<T> = {
  /** Custom render of the list item.
   * - If not provided, the default renderItem will show
   */
  renderItem?: FlatListProps<T>['renderItem']
  /** Extracts the title for the default render of list item from a specific item type
   * - Must be provided if a custom `renderItem` prop is not specified
   */
  getTitle?: (item: T) => string
  /** Items that are inside the bottom sheet list */
  items: T[]
  selectedItem?: T
  onItemPress?: (item: T, index: number) => void
} & Pick<FlatListProps<T>, 'ListFooterComponent' | 'ListHeaderComponent'> &
  Pick<OverlayProps, 'onBackdropPress' | 'isVisible' | 'overlayStyle' | 'children'>

export type BottomSheetListProps<T> = RequireAtLeastOne<BaseProps<T>, 'renderItem' | 'getTitle'>

/** Modal component bounded to the bottom of the screen, or center-aligned for web, large devices
 * - Contains a list of items, that can be pressed, customized, etc
 */
export function BottomSheetList<T>({
  onItemPress,
  items,
  getTitle,
  renderItem: renderItemProp,
  isVisible,
  onBackdropPress,
  selectedItem,
  ListFooterComponent,
  ListHeaderComponent,
  overlayStyle,
  children,
}: BottomSheetListProps<T>) {
  const styles = useStyles()

  const defaultRenderItem = ({ item, index }: ListRenderItemInfo<T>) => {
    /** FIXME: @sasiacob this approach to determining the "isSelected" value is poorly designed (`const isSelected = selectedItem === item`). "item" and "selectedItem" could be of any type, so this deep equality is not reliable because it only works if T is a primitive.
     */
    const isSelected = selectedItem === item
    return (
      <ListItem containerStyle={styles.listItem} onPress={() => onItemPress?.(item, index)}>
        <ListItem.Content>
          <ListItem.Title>
            <Text type={isSelected ? 'medium' : undefined} color={isSelected ? Colors.green : undefined} size={14}>
              {/* If custom renderItem is not specified, getTitle should be defined */}
              {getTitle!(item)}
            </Text>
          </ListItem.Title>
        </ListItem.Content>
        {!isSelected ? (
          <ListItem.Chevron type="FontAwesome5" name="chevron-right" color={Colors.shades['500']} />
        ) : (
          <ListItem.Chevron size={20} type="FontAwesome5" name="check" color={Colors.green} />
        )}
      </ListItem>
    )
  }
  if (!items.length) return null
  return (
    <Overlay
      animationType="none"
      overlayStyle={[styles.overlay, overlayStyle]}
      isVisible={isVisible ?? false}
      backdropStyle={styles.backdrop}
      onBackdropPress={onBackdropPress}
    >
      <FlatList
        ListHeaderComponent={ListHeaderComponent ?? <View style={styles.header} />}
        data={items}
        showsVerticalScrollIndicator={false}
        renderItem={renderItemProp ?? defaultRenderItem}
        ListFooterComponent={ListFooterComponent ?? <View style={styles.footer} />}
      />
      {children}
    </Overlay>
  )
}

const useStyles = () =>
  useLayoutFnStyles(({ height, isSmallDevice, bottom }) => {
    /** Whether the modal should start from the screen bottom or if should be vertically centered*/
    const isBottomBounded = !isWeb || isSmallDevice
    return {
      overlay: {
        backgroundColor: Colors.white,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderRadius: isBottomBounded ? 0 : 10, // should not have bottom border if attached
        maxHeight: height * 0.8,
        width: '100%',
        overflow: 'hidden',
        alignSelf: 'center',
        bottom: isBottomBounded ? 0 : undefined,
        position: isBottomBounded ? 'absolute' : 'relative', // Relative will allow aligning the container vertically
        maxWidth: isBottomBounded ? undefined : 768, // Should not have more than the Medium size
      },
      footer: { height: isBottomBounded ? bottom : 20 }, // If bounded, get the safeaArea bottom value, else set it the same as the header
      header: { height: 20 },
      listItem: {
        borderBottomWidth: 1,
        borderColor: Colors.shades['100'],
      },
      backdrop: {
        backgroundColor: Colors.semiTransparent,
      },
    }
  })
