import { useSelector } from 'react-redux'

import { OrderProductType } from '@/admin/navigation/types'
import { KeyedState } from '@/hooks/useKeyedState'
import { adminFarmIdSelector } from '@/redux/selectors'
import { OrderCreatorStateProductsFilters } from './OrderCreatorScreen.helper'
import { useCsasReusable } from './useCsas-reusable'

/** Data layer for state related to CSAs inside the order creator */
export function useCsas(opts: {
  orderType: OrderProductType
  keyedState: KeyedState<OrderCreatorStateProductsFilters>
}) {
  const adminFarmId = useSelector(adminFarmIdSelector)

  return useCsasReusable({
    farmId: adminFarmId || '',
    ...opts,
  })
}
