import { useCallback } from 'react'
import { Input, InputProps } from 'react-native-elements'
import { formatWithMask, MaskInputProps } from 'react-native-mask-input'

import CustomInput from '../CustomInput'

export type MaskedInputProps = Omit<InputProps, 'onChangeText' | 'defaultValue'> & {
  onChangeText: MaskInputProps['onChangeText']
  mask: MaskInputProps['mask']
  /**
   * @param useCustomInput Specifies if the mask should use the CustomInput component instead of default Input
   */
  useCustomInput?: boolean
}

/** MaskedInput is an input that will mask the text inside based on the provided mask */
export function MaskedInput({ value, onChangeText, mask, useCustomInput = false, ...rest }: MaskedInputProps) {
  const { masked } = formatWithMask({
    text: value,
    mask,
  })

  const onChangeTextMask = useCallback(
    (text: string) => {
      if (onChangeText) {
        const { masked, unmasked, obfuscated } = formatWithMask({ text, mask })
        onChangeText(masked, unmasked, obfuscated)
      }
    },
    [onChangeText, mask],
  )

  if (useCustomInput) return <CustomInput value={masked} onChangeText={onChangeTextMask} {...rest} />

  return <Input value={masked} onChangeText={onChangeTextMask} {...rest} />
}
