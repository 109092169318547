import { v4 as uuid } from 'uuid'
export default uuid

// Will generate a UUID string that can be used in places where we want only a certain number of chars
export const uuidString = (length?: number) => {
  const uuidStr = uuid().replace(/-/g, '')
  if (length) {
    return uuidStr.slice(0, length)
  }
  return uuidStr
}
