import { Divider, Text } from '@elements'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { formatMoney, getReadablePayment } from '@helpers/display'
import { MoneyCalc } from '@helpers/money'
import {
  Invoice,
  getInvoiceSourceDiscounts,
  getProcessorItemSubtotal,
  getProcessorItemTotal,
  invoiceItemTotal,
  invoiceTotal,
  isProductFee,
  isTipOrServiceFee,
} from '@models/Invoice'
import { Zero } from '@models/Money'
import React, { memo } from 'react'
import { StyleSheet, View } from 'react-native'

import { ItemRow } from './ItemRow'

import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { getInvItemDescription } from '@helpers/invoice'
import { getProductFeesFromInvoice } from '@helpers/productFee'
import { getServiceFeeNameForInvoice } from '@helpers/serviceFee'
import { FeeType } from '@models/ProductFee'
import { InvoiceProcessor, getProcessorInvoiceAmount } from '../invoiceUtils'

type TenderItemsSummaryProps = {
  invoice: Invoice
  payment: InvoiceProcessor
  index: number
  totalPaymentsLength: number
}

/** The component displays tender summary for all invoice items and will be displayed in SplitInvoiceSummary */
export const TenderItemsSummary = memo(function TenderItemsSummary({
  invoice,
  payment,
  index,
  totalPaymentsLength,
}: TenderItemsSummaryProps) {
  const tip = payment.items.find((itm) => isTipOrServiceFee(itm.id))
  const additionalFees = getProductFeesFromInvoice({ items: payment.items }, FeeType.Fee)
  const taxes = getProductFeesFromInvoice({ items: payment.items }, FeeType.Tax)
  const tipTotal = tip ? getProcessorItemTotal(tip, payment.source) : Zero
  const tenderDiscounts = getInvoiceSourceDiscounts(invoice, payment.source)
  const tenderSubTotal = formatMoney(
    getProcessorInvoiceAmount(
      payment.source,
      payment.items.filter((itm) => !itm.cancelled && !isTipOrServiceFee(itm.id) && !isProductFee(itm.id)),
    ),
  )

  return (
    <View key={payment.source} style={globalStyles.marginVertical10}>
      <View style={styles.paymentHeaderRow}>
        <Text style={styles.headerText} type="medium">
          {getReadablePayment(payment.source)}
        </Text>
        <Text style={styles.headerText} type="medium">
          {`Payment ${index + 1}/${totalPaymentsLength}`}
        </Text>
      </View>
      {payment.items.length > 0 && (
        <ItemRow isHeader leftSideValues={['Item']} rightSideValues={['Quantity', 'Price', 'Amount']} />
      )}
      {payment.items
        .filter((itm) => !isTipOrServiceFee(itm.id) && !isProductFee(itm.id))
        .map((itm) => (
          <ItemRow
            key={itm.id}
            leftSideValues={[`${itm.cancelled ? '(CANCELLED) ' : ''}${getInvItemDescription(itm)}`]}
            rightSideValues={[
              itm.quantity.toString(),
              formatMoney(MoneyCalc.add(MoneyCalc.divide(getProcessorItemSubtotal(itm, payment.source), itm.quantity))),
              formatMoney(itm.cancelled ? Zero : getProcessorItemSubtotal(itm, payment.source)),
            ]}
          />
        ))}
      <>
        <View style={styles.subtotalsContainer}>
          <View style={styles.leftColumn}>
            <Text type="medium" style={styles.subtotal}>
              SUBTOTAL
            </Text>
            {MoneyCalc.isGTZero(tenderDiscounts) && (
              <Text style={styles.subtotal} size={14} type="medium" color={Colors.green}>
                Discounts
              </Text>
            )}
            {!!tip && MoneyCalc.isGTZero(tipTotal) && <Text type="medium">{getServiceFeeNameForInvoice(invoice)}</Text>}
            {taxes.length > 0 && <Text type="medium">Taxes</Text>}
            {additionalFees.length > 0 &&
              additionalFees.map((fee) => (
                <Text key={`${fee.id}_${fee.description}`} type="medium">
                  {fee.description}
                </Text>
              ))}
          </View>
          <View style={styles.rightColumn}>
            <Text type="medium" style={styles.subtotal}>
              {tenderSubTotal}
            </Text>
            {MoneyCalc.isGTZero(tenderDiscounts) && (
              <Text style={styles.subtotal} color={Colors.green} size={14} type="medium">
                -{formatMoney(tenderDiscounts)}
              </Text>
            )}
            {!!tip && MoneyCalc.isGTZero(tipTotal) && <Text type="medium">{formatMoney(tipTotal)}</Text>}
            {taxes.length > 0 && <Text type="medium">{formatMoney(invoiceTotal({ items: taxes }))}</Text>}
            {additionalFees.length > 0 &&
              additionalFees.map((fee) => (
                <Text key={fee.id} type="medium">
                  {formatMoney(invoiceItemTotal(fee))}
                </Text>
              ))}
          </View>
        </View>
        <Divider />
      </>
    </View>
  )
},
propsAreDeepEqual)

const styles = StyleSheet.create({
  headerText: {
    fontSize: 14,
    marginTop: 15,
  },
  subtotalsContainer: {
    alignSelf: 'flex-end',
    flexDirection: 'row',
  },

  paymentHeaderRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftColumn: {
    marginRight: 30,
    alignItems: 'flex-end',
  },

  rightColumn: {
    alignItems: 'flex-end',
  },
  subtotal: {
    marginVertical: 10,
  },
})
