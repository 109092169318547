import { AppStackParamList, SignInSheetParamList } from '@/navigation/types'
import { Button, ButtonClear, SafeAreaView, Text, Toast } from '@elements'
import { formatFormalShortDate } from '@helpers/display'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback, useState } from 'react'
import { StyleSheet } from 'react-native'
import { useSignInSheetContext } from '../useSignInSheetData'

type Props = {
  title?: string
}

/** Shared Back button used across different screens */
export function SharedScreenHeader({ title }: Props) {
  const { onShareSignIn, location, signInSummary } = useSignInSheetContext()
  const navigation = useNavigation<StackNavigationProp<SignInSheetParamList & AppStackParamList>>()
  const route = useRoute<RouteProp<SignInSheetParamList>>()
  const [shareLoading, setShareLoading] = useState(false)

  const onSharePress = useCallback(async () => {
    setShareLoading(true)
    await onShareSignIn()
    setShareLoading(false)
    Toast('Link copied!')
  }, [onShareSignIn])

  const onBackPress = useCallback(() => {
    const activeRoutes = navigation.getState().routes.map((route) => route.name)

    switch (route.name) {
      case 'ElectronicSignInSheet': {
        if (activeRoutes.includes('EditSignIn') || activeRoutes.includes('AdminDrawerNavigator')) {
          return navigation.goBack()
        }

        if (!location?.id || !signInSummary?.date) return

        // Defaults 'LocationSummary' screen
        return navigation.navigate('AdminDrawerNavigator', {
          screen: 'DistributionSummaryStack',
          params: {
            screen: 'LocationSummary',
            params: { locId: location.id, date: formatFormalShortDate(signInSummary.date) },
          },
        })
      }
      case 'EditSignIn': {
        if (!location?.id || !signInSummary?.date) return

        // This screen can be opened only from 'SignInSheetSelector' screen, so it will go back to that screen
        return navigation.navigate('AdminDrawerNavigator', {
          screen: 'DistributionSummaryStack',
          params: {
            screen: 'SignInSheetSelector',
            params: { locId: location.id, date: formatFormalShortDate(signInSummary.date) },
          },
        })
      }
      case 'PickupSummary':
      case 'EndSignIn': {
        // These screens can be opened only from 'ElectronicSignInSheet' screen, so it will go back to that screen
        const params = route.params
        return navigation.navigate('ElectronicSignInSheet', { id: params.id, token: params.token })
      }
      case 'PostDonation': {
        // This screen can be opened only from 'EndSignIn' screen, so it will go back to that screen
        const params = route.params
        return navigation.navigate('EndSignIn', { id: params.id, token: params.token })
      }
      case 'AddNotes': {
        if (
          activeRoutes.includes('ElectronicSignInSheet') ||
          activeRoutes.includes('EndSignIn') ||
          activeRoutes.includes('PostDonation')
        ) {
          return navigation.goBack()
        }
        // Defaults to ElectronicSignInSheet screen
        const params = route.params
        return navigation.navigate('ElectronicSignInSheet', { id: params.id, token: params.token })
      }
      case 'FinalizeSignIn': {
        // This screen can be opened only from 'AddNotes' screen, so it will go back to that screen
        const params = route.params
        return navigation.navigate('AddNotes', { id: params.id, token: params.token })
      }
    }
  }, [location?.id, navigation, route.name, route.params, signInSummary?.date])

  return (
    <SafeAreaView style={styles.container}>
      <ButtonClear title="Back" icon="arrow-left" onPress={onBackPress} />
      {!!title && <Text size={16}>{title}</Text>}
      <Button title="Share" style={styles.shareButton} onPress={onSharePress} loading={shareLoading} />
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  shareButton: {
    marginLeft: 'auto',
  },
})
