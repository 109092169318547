import { memo, useCallback, useEffect, useState } from 'react'
import { LayoutRectangle, StyleProp, TextProps, TextStyle } from 'react-native'

import Colors from '../../constants/Colors'
import { defaultTextSize, lineHeight, Text } from './Text'

import { useControlledState } from '@/hooks/useControlledState'
import { useDeviceSize } from '@/hooks/useLayout'

type Props = {
  style?: StyleProp<TextStyle>
  children: any
  numLines?: number
  size?: number
}

/**
 * Hides text when it occupies more than a certain number of lines, and a ReadMore button allows the rest of the text to be shown.
 * @param children text to display or hide.
 * @param numLines the number of lines to allow, before the ReadMore button appears, and the rest gets hidden. Should be an integer
 * @param size is expected to never change
 * @returns
 */
export const ReadMore = memo(function ReadMore({ children, numLines, style, size = defaultTextSize }: Props) {
  const [limiting, [enableLimit, disableLimit]] = useControlledState<boolean>(true, [true, false]) //whether to hide any used lines of text, greater than the numLines prop
  const [usedLines, setUsedLines] = useState<number>(0) //the number of lines being used on the screen, by the text component
  const [textLayout, setTextLayout] = useState<LayoutRectangle>()
  const { isSmallDevice, isMedDevice } = useDeviceSize()
  const numLinesLimit: number =
    typeof numLines === 'number' ? Math.floor(numLines) : isSmallDevice ? 4 : isMedDevice ? 6 : 8

  /** Calculates the new used lines with a rate limiter, to prevent useLayout from triggering this calculation multiple times when pressing "Read More".
   * - Without this, the text gets shown in a progressive manner, which might seem odd
   */
  useEffect(() => {
    if (!textLayout) return
    const timeoutId = setTimeout(() => {
      // Determine number of used lines through division
      const newUsedLines = getCutoffLines(textLayout.height, size)
      if (newUsedLines !== usedLines) setUsedLines(newUsedLines)
    }, 30)
    return () => clearTimeout(timeoutId)
    // size prop is expected to not change so it doesn't need to go in deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textLayout, usedLines])

  return (
    <>
      <Text
        size={size}
        numberOfLines={limiting ? numLinesLimit : undefined}
        style={style}
        onLayout={useCallback<NonNullable<TextProps['onLayout']>>(
          ({ nativeEvent: { layout } }) => setTextLayout(layout),
          [],
        )}
        children={children}
      />
      {limiting && usedLines >= numLinesLimit && (
        <Text onPress={disableLimit} type="medium" color={Colors.green}>
          Read More
        </Text>
      )}
      {!limiting && usedLines > numLinesLimit && (
        <Text onPress={enableLimit} type="medium" color={Colors.green}>
          Read Less
        </Text>
      )}
    </>
  )
})
export default ReadMore

/** Helps the parent component to calculate an optimal number of lines which will be the cutoff for limiting.
 * - The "height" can be the height of some element measured, whose height encompasses the amount of space we want to allow the text to cover before being limited.
 */
export const getCutoffLines = (height: number, textSize = defaultTextSize /** 12 is the default text size */) =>
  Math.ceil(height / lineHeight(textSize))
