import { globalStyles } from '@/constants/Styles'
import { useCartService } from '@/hooks/useCart'
import { useLayout } from '@/hooks/useLayout'
import { HomeParamList } from '@/navigation/types'
import { wholesaleSelector } from '@/redux/selectors'
import {
  ProductCardSquare,
  ResponsiveGrid,
  paddedProdWidth,
  paddedProdWidthSM,
  prodHeight,
  prodSharedStyles,
} from '@components'
import { ScreenView } from '@elements'
import { ArrElement } from '@helpers/typescript'
import { NavigationProp, RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { ListFooter } from '@screens/Shopping/FarmShop/components'
import { MAX_SHOP_WIDTH, getCardActionShop } from '@screens/Shopping/FarmShop/helpers'
import { ListRenderItemInfo } from '@shopify/flash-list'
import { useContext } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import { SearchScreenContext } from './SearchScreen'
import { ListEmpty } from './components/ListEmpty/ListEmpty'
import { Sidebar } from './components/Sidebar/Sidebar'

/** The entire UI for the search screen */
export function SearchScreenUi() {
  const { isWholesale = false } = useSelector(wholesaleSelector)
  const layout = useLayout()
  const { cart } = useCartService()
  const { params: { radius } = {} } = useRoute<RouteProp<HomeParamList, 'SearchScreen'>>()
  const navigation = useNavigation<NavigationProp<HomeParamList, 'SearchScreen'>>()

  const {
    searchLayer: { prods: products, errorMsg, isLoading, categoryFilter, practicesFilter, priceFilter, orderMinFilter },
  } = useContext(SearchScreenContext)

  const renderItem = ({ item: prod }: ListRenderItemInfo<ArrElement<typeof products>>) => {
    return (
      <ProductCardSquare
        small={layout.isExtraSmallDevice}
        showFarmInfo
        product={prod}
        onPressMobileNavigate={(p) =>
          navigation.navigate('Shopping', {
            screen: 'ProductDetails',
            params: { productId: p.id, farmSlug: p.farm.urlSafeSlug },
          })
        }
        style={prodSharedStyles.responsiveWrapper}
        cardAction={getCardActionShop(
          prod,
          [],
          cart.map((ci) => ci.product.id),
          isWholesale,
        )}
      />
    )
  }

  return (
    // ScreenView is necessary here to make the main app Header stick to the top, while being outside of the screen component
    <ScreenView>
      <ScrollView contentContainerStyle={globalStyles.flex1}>
        <View style={styles.wrapper}>
          <Sidebar
            priceFilter={priceFilter}
            orderMinFilter={orderMinFilter}
            categories={categoryFilter.items}
            practices={practicesFilter.items}
            radius={radius}
          />

          <View style={globalStyles.flex1}>
            <View style={styles.main}>
              <View style={styles.prodListContainer}>
                <ResponsiveGrid
                  estimatedItemSize={prodHeight}
                  itemBaseWidth={layout.isExtraSmallDevice ? paddedProdWidthSM : paddedProdWidth}
                  showsVerticalScrollIndicator={false}
                  renderItem={renderItem}
                  /** Return empty list when hits are loading or if there is any error, in order to show the `ListEmpty` component in the UI
                   * - This will help when filtering is applied for example, and the connection is slow. So the user will see that something is loading
                   */
                  data={!!errorMsg || isLoading ? [] : products}
                  // extraData must include any dependencies of the renderItem function. Even if it's not inside a useCallback, due to the flatlist pure-component internal memoization
                  extraData={[layout.isExtraSmallDevice, cart]}
                  ListEmptyComponent={<ListEmpty hasError={!!errorMsg} prodsLoading={isLoading} />}
                />
              </View>
            </View>
          </View>
        </View>
        <ListFooter loading={isLoading} />
      </ScrollView>
    </ScreenView>
  )
}

const styles = StyleSheet.create({
  main: {
    maxWidth: MAX_SHOP_WIDTH,
    alignSelf: 'center',
    width: '100%',
    flex: 1,
    flexDirection: 'row',
  },
  prodListContainer: {
    padding: 15,
    flex: 1,
  },
  wrapper: {
    flexDirection: 'row',
    flexGrow: 1,
  },
})
