import { AdminOrdersParamList } from '@/admin/navigation/types'
import { Logger } from '@/config/logger'
import { useSetCartService } from '@/constants/appInitialization/useSetCartService'
import { useApiFx } from '@/hooks/useApiFx'
import useKeyedState from '@/hooks/useKeyedState'
import { approveDraftOrder, declineDraftOrder } from '@api/DraftOrders'
import { farmsCollection } from '@api/framework/ClientCollections'
import { Alert } from '@elements'
import { NavigationProp, RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { createContext, useCallback } from 'react'
import {
  OrderCreatorStatePayments,
  OrderCreatorStateProductsFilters,
  orderCreatorInitialStatePayments,
  orderCreatorInitialStateProductsFilters,
} from '../OrderCreatorScreen/OrderCreatorScreen.helper'
import { useCsas } from './useCsas'
import { useCustomerData } from './useCustomerData'
import { fetchDraftCart, fetchDraftForEdit } from './useOrderEditData-helpers'
import { usePaymentsAndTotalsData } from './usePaymentsAndTotalsData'
import { useProducts } from './useProducts'
import { useSchedulesAndDates } from './useSchedulesAndDates'

/** Provides the data for the order edit screen context */
export function useOrderEditData() {
  const { params: { draftOrderId } = {} } = useRoute<RouteProp<AdminOrdersParamList, 'OrderEdit'>>()
  const navigation = useNavigation<NavigationProp<AdminOrdersParamList, 'OrderEdit'>>()

  const keyedStateProds = useKeyedState<OrderCreatorStateProductsFilters>(orderCreatorInitialStateProductsFilters)

  const keyedStatePayments = useKeyedState<OrderCreatorStatePayments>(orderCreatorInitialStatePayments)

  const draftOrderFx = useApiFx(
    fetchDraftForEdit,
    [draftOrderId],
    undefined, // This should run regardless of the parameter value
    { noRefocus: true },
  )
  const { data: draftOrder, loading: loadingDraft, err: errDraft } = draftOrderFx

  const draftCartFx = useApiFx(fetchDraftCart, [draftOrder?.cart.id], !!draftOrder?.cart.id, {
    // Should continue loading until there is a cart id defined, to be fetched
    failedConditionMode: 'keep-loading',
    noRefocus: true,
  })

  const cartService = useSetCartService({
    cartServiceType: 'orderEdit',
    draftCartId: loadingDraft || errDraft ? undefined : draftOrder?.cart.id,
    isWholesale: loadingDraft || errDraft ? undefined : draftOrder?.isWholesale,
  })

  const { customerFx, custPickupsFx } = useCustomerData({
    draft: draftOrderFx.data,
    cart: cartService.cart,
  })

  const productsData = useProducts({
    keyedState: keyedStateProds,
    draftOrderFx,
  })

  const schedulesData = useSchedulesAndDates({
    draft: draftOrderFx.data,
    dbProds: productsData.dbProds,
    isLoadingProducts: productsData.isLoadingProductsInit,
    keyedState: keyedStateProds,
  })

  const csasData = useCsas({ draft: draftOrderFx.data, keyedState: keyedStateProds })

  const farmFx = useApiFx(
    farmsCollection.fetch.bind(farmsCollection),
    [draftOrderFx.data?.farm.id],
    !!draftOrderFx.data?.farm.id,
    {
      failedConditionMode: 'keep-loading',
      noRefocus: true,
    },
  )

  const { payments } = usePaymentsAndTotalsData({
    cartService,
    custPickupsFx,
    farm: farmFx.data,
    isWholesale: draftOrderFx.data?.isWholesale,
    keyedState: keyedStatePayments,
  })

  const { 1: set } = keyedStatePayments

  const onPressApprove = useCallback(async () => {
    if (!draftOrderId) return
    set('isSubmitting', true)
    try {
      await approveDraftOrder(draftOrderId)
      navigation.navigate('AdminDraftOrderDetails', { draftOrderId })
    } catch (error) {
      Logger.error(error)
    }
    set('isSubmitting', false)
  }, [draftOrderId, navigation, set])

  const onPressDecline = useCallback(async () => {
    if (!draftOrderId) return
    set('isSubmitting', true)
    const shouldProceed = await new Promise((res) =>
      Alert('Decline Draft Order', 'Are you sure you want to decline this order?', [
        { text: 'Yes', style: 'destructive', onPress: () => res(true) },
        { text: 'No', onPress: () => res(false) },
      ]),
    )
    if (!shouldProceed) return set('isSubmitting', false)
    try {
      await declineDraftOrder(draftOrderId)
      navigation.navigate('AdminOrderList')
    } catch (error) {
      Logger.error(error)
    }
    set('isSubmitting', false)
  }, [draftOrderId, navigation, set])

  return {
    draftOrderId,
    draftOrderFx,
    draftCartFx,
    customerFx,
    farmFx,
    keyedStateProds,
    keyedStatePayments,
    payments,
    ...productsData,
    ...schedulesData,
    ...csasData,
    onPressApprove,
    onPressDecline,
  }
}

export type OrderEditContextType = ReturnType<typeof useOrderEditData>

export const OrderEditContext = createContext<OrderEditContextType>({} as OrderEditContextType)
