import { Media } from '@models/shared/Media'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'

import { storage } from './db'

export const uploadImageAsync = async (location: string, media: Media): Promise<Media> => {
  return new Promise(async (resolve, reject): Promise<void> => {
    const filePath = await fetch(media.storageUrl)
    const blob = await filePath.blob()

    const storageRef = ref(storage(), location)
    const uploadTask = uploadBytesResumable(storageRef, blob)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        switch (snapshot.state) {
          case 'paused':
            break
          case 'running':
            break
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break
          case 'storage/canceled':
            // User canceled the upload
            break
          case 'storage/unknown':
            break
        }
        reject(error)
      },
      async () => {
        // We're done with the blob, close and release it
        try {
          //@ts-ignore
          blob.close()
        } catch (error) {
          //blob close seems to throw an error on web, but works on android
        }

        // Upload completed successfully, now we can get the download URL
        resolve({ storageUrl: await getDownloadURL(storageRef), type: media.type })
      },
    )
  })
}
