import { hasOwnProperty } from '@helpers/helpers'

export type Coordinate = {
  latitude: number
  longitude: number
}
export const isCoord = (coords: any): coords is Coordinate =>
  hasOwnProperty(coords, 'latitude') && hasOwnProperty(coords, 'longitude')

export type ShortCoord = { lat: number; lng: number }

export const isShortCoord = (coords: any): coords is ShortCoord =>
  hasOwnProperty(coords, 'lat') && hasOwnProperty(coords, 'lng')

export type HitRegion = {
  latitude: number
  longitude: number
  latitudeDelta: number
  longitudeDelta: number
  zoom?: number
}
