import { isWeb } from '@/constants/Layout'
import { useLayout } from '@/hooks/useLayout'
import { Header } from '@components'
import {
  CallToActionSection,
  CoopBenefitsSection,
  HeroSection,
  PartnersSection,
  PricingSection,
  SupportSection,
  TestimonialSection,
} from '@screens/FarmerOnboard/sections'
import React, { memo } from 'react'
import { ScrollView, View } from 'react-native'
import { withBuyerBlock } from '../../hooks/withRetailCheck/withBuyerBlock'
import { WebFooter } from '../Home/WebFooter'

/**
 * `FarmerOnboardScreen` renders the onboarding screen for farmers.
 * This screen is a scrollable view that contains various sections providing information and calls to action
 * relevant to farmers who are new to the platform.
 *
 * The sections included are:
 * - `HeroSection`: A prominent section designed to grab attention, typically with engaging images and a call to action.
 * - `PartnersSection`: Showcasing various partners associated with the platform.
 * - `CoopBenefitsSection`: Describing the benefits of the cooperative model of the platform.
 * - `PricingSection`: Providing information on the pricing model and costs associated with using the platform.
 * - `TestimonialSection`: Displaying testimonials from farmers who have used the platform.
 * - `CallToActionSection`: Another section prompting users to take specific actions, with engaging visuals and text.
 * - `SupportSection`: Offering information on the support available to farmers using the platform.
 *
 * On web platforms, a `HomeFooter` component is also rendered at the bottom of the screen.
 */
function FarmerOnboardScreenComp() {
  const { isLargeDevice, top } = useLayout()

  const showMainHeader = isWeb || isLargeDevice

  return (
    <>
      {showMainHeader ? <Header /> : <View style={{ height: top }} />}
      <ScrollView>
        <HeroSection />
        <PartnersSection />
        <CoopBenefitsSection />
        <PricingSection />
        <TestimonialSection />
        <CallToActionSection />
        <SupportSection />
        {isWeb && <WebFooter />}
      </ScrollView>
    </>
  )
}
export const FarmerOnboardScreen = withBuyerBlock(memo(FarmerOnboardScreenComp), {
  showHeader: true,
  blockWholesaleAuthorizedUser: true,
})
