import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useDeviceSize } from '@/hooks/useLayout'
import { memo } from 'react'
import { View } from 'react-native'
import { Skeleton } from 'react-native-elements'
import { MAX_SHOP_WIDTH } from './helpers'

export const ShopHeaderSkeleton = memo(function ShopHeaderSkeleton() {
  const { isLargeDevice, isSmallDevice } = useDeviceSize()
  const styles = useStyles()
  const imagesNo = isSmallDevice ? 1 : !isLargeDevice ? 2 : 3

  return (
    <View style={styles.wrapper}>
      <View style={styles.imgWrapper}>
        {Array.from(Array(imagesNo)).map((_, idx) => (
          <View style={styles.imgCont} key={idx}>
            <Skeleton style={globalStyles.flex1} />
          </View>
        ))}
      </View>
      <View style={styles.contentContainer}>
        {Array.from(Array(imagesNo)).map((_, idx) => (
          <View style={globalStyles.flex1} key={idx}>
            <View style={styles.descriptionCont}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </View>
          </View>
        ))}
      </View>
    </View>
  )
})

const useStyles = () =>
  useSizeFnStyles(({ isLargeDevice, isSmallDevice, isExtraSmallDevice }) => ({
    wrapper: {
      backgroundColor: Colors.shadeGold,
      paddingHorizontal: !isLargeDevice ? 0 : 10,
      paddingVertical: !isLargeDevice ? 0 : 20,
    },

    imgCont: {
      flex: 1,
      borderRadius: isSmallDevice ? 0 : 10,
      overflow: 'hidden',
    },
    imgWrapper: {
      flexDirection: 'row',
      height: isExtraSmallDevice ? 200 : 300,
      justifyContent: 'space-between',
      gap: 10,
      width: '100%',
      maxWidth: MAX_SHOP_WIDTH,
      alignSelf: 'center',
    },
    contentContainer: {
      marginTop: 10,
      marginHorizontal: !isLargeDevice ? 10 : 0,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      gap: 10,
      width: '100%',
      maxWidth: MAX_SHOP_WIDTH,
      alignSelf: 'center',
    },

    descriptionCont: {
      gap: 10,
    },
  }))
