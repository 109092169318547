import { useApiFx } from '@/hooks/useApiFx'
import { getNearbyFeaturedWholesale } from '@api/Products'
import { MediaCarousel } from '@components'
import { memo, useMemo, useState } from 'react'
import { StyleSheet, View } from 'react-native'

export const LogisticsSelectionCarousel = memo(function LogisticsSelectionCarousel() {
  const featuredFx = useApiFx(getNearbyFeaturedWholesale, [], undefined, { noRefocus: true })
  const [carouselWidth, setCarouselWidth] = useState(400)

  const media = useMemo(() => {
    return (featuredFx.data?.map((el) => el.images[0]) ?? []).map((val) => ({ storageUrl: val, type: 'image' }))
  }, [featuredFx.data])

  return (
    <View
      onLayout={(e) => {
        const width = e.nativeEvent.layout.width
        if (width > 0) {
          setCarouselWidth(e.nativeEvent.layout.width)
        }
      }}
      style={styles.carouselCont}
    >
      <MediaCarousel width={carouselWidth} media={media ?? []} height={carouselWidth * 0.6} />
    </View>
  )
})

const styles = StyleSheet.create({
  carouselCont: {
    flexBasis: 400,
    flex: 1,
  },
})
