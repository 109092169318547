import React, { FormEvent } from 'react'

import { Button, ButtonProps } from './Button'

/** Compatibility button for formik type of onPress event which differs from the one from TouchableOpacity */
function FormikButton({
  onPress,
  ...props
}: Omit<ButtonProps, 'onPress'> & { onPress?: (e?: FormEvent<HTMLFormElement>) => void }) {
  return <Button {...props} onPress={() => onPress?.()} />
}

export default FormikButton
