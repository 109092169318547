import { marshalDateObject, unmarshalDate, unmarshalDateObject, WithMarshalledDates } from '@api/encoding/Time'
import { callEndpoint } from '@api/v2'
import { sortByLatest } from '@helpers/sorting'
import { Order } from '@models/Order'
import {
  DraftOrderCreateRequest,
  OrderCancelPreflightResponse,
  OrderCancelRequest,
  OrderCancelResponse,
  OrderCreateRequest,
} from '@shared/types/v2/order'
import { limit, orderBy, QueryConstraint, where } from 'firebase/firestore'
import { DateTime } from 'luxon'

import { ErrorHandlerCallback } from 'react-native'
import { errorCatcher } from './Errors'
import { ordersCollection } from './framework/ClientCollections'

export const INITIAL_LIMIT_N_ORDERS = 30

/** snapshotOrdersByUserAndDueDate invokes as snapshot handler for the supplied user within the set date range.
The snapshot will be passed to the callback function as data changes. */
export function snapshotOrdersByUserAndDueDate(
  callback: (orders: Order[]) => void,
  /** `limitN`: The max number of documents to load. If null, there will be no limit. If undefined, will have the default limit */
  onErr: ErrorHandlerCallback = errorCatcher,
  userId: string,
  limitN: number | null = INITIAL_LIMIT_N_ORDERS,
): () => void {
  if (!userId) return () => {}
  const qFilters: QueryConstraint[] = [where('user.id', '==', userId), orderBy('date.utc', 'desc')]
  // firestore has a 10k max limit. This prevents misuse of this helper
  if (limitN !== null && limitN < 10000) qFilters.push(limit(limitN))
  const ref = ordersCollection.query(...qFilters)

  // Will sort the orders by most recent
  const sortedCallback = (orders: Order[]) => callback(orders.sort(sortByLatest('date')))
  return ordersCollection.snapshotMany(ref, sortedCallback, onErr)
}

export function snapshotOrdersByFarm(
  farmId: string,
  monthsDuration: number,
  callback: (orders: Order[]) => void,
): () => void {
  const ref = ordersCollection.query(
    where('farm.id', '==', farmId),
    where('date.utc', '>=', DateTime.now().minus({ months: monthsDuration }).toISO()),
  )
  return ordersCollection.snapshotMany(ref, callback)
}

export async function adminLoadOrdersByUserAndFarm(userId: string, farmId: string): Promise<Order[]> {
  const orders = await ordersCollection.fetchAll(where('user.id', '==', userId), where('farm.id', '==', farmId))
  return orders.sort(sortByLatest('date'))
}

/** loadOrder returns the order stored with the supplied ID. */
export const loadOrder = (id: string): Promise<Order> => ordersCollection.fetch(id)

/** Will load the order as a snapshot listener */
export const snapshotOrder = (callback: (ord?: Order) => void, onError: (err: Error) => void, id: string) =>
  ordersCollection.snapshotDoc(id, callback, onError)

/** addOrder calls the createNewOrder server function to create a new order with the supplied items. and returns the order id */
export async function addOrder(args: OrderCreateRequest | DraftOrderCreateRequest): Promise<{ id: string }> {
  return await callEndpoint('v2.Order.createOrderService', args)
}

/** Will commit the cancel order request started in preflight, returns a list of messages on success specifying any additional action the farmer needs to take, if it fails it will throw an error */
export async function cancelOrder(
  req: Omit<OrderCancelRequest, 'preflight'> & {
    preflight: false
  },
): Promise<string[]> {
  const data = marshalDateObject(req)

  const result = (await callEndpoint('v2.Order.cancelOrderService', data)) as OrderCancelResponse

  if (!result.success) throw new Error(result.messages[0])
  return result.messages
}

/** Will load all details to display to the user specifying how the cancel will go */
export async function cancelOrderPreflight(
  req: Omit<OrderCancelRequest, 'preflight'>,
): Promise<OrderCancelPreflightResponse> {
  const data = marshalDateObject<OrderCancelRequest>({ ...req, preflight: true })

  const res = await callEndpoint('v2.Order.cancelOrderService', data)

  return unmarshalDateObject<OrderCancelPreflightResponse>(res as WithMarshalledDates<OrderCancelPreflightResponse>)
}

export async function loadFirstOrderPickup(orderId: string) {
  const result = await callEndpoint('v2.Pickup.getFirstPickupsByOrderService', { orderId })
  return result.map((item) => ({ ...item, firstPickup: unmarshalDate(item.firstPickup) }))
}
