import Analytics from '../config/FBAnalytics'

// Will set the user as either a farmer or only customer for tracking
export function logIsFarmer(value: boolean) {
  Analytics.setUserProperty('isFarmer', value ? 'yes' : 'no')
}

export function logAddToCart(productId: string, distroId: string, farmId: string) {
  Analytics.logEvent('add_to_cart', {
    productId,
    distroId,
    farmId,
  })
}

export function logAddPaymentMethod(status: string) {
  Analytics.logEvent('add_payment_info', {
    status,
  })
}

export function logOrderPlaced(farmId: string, totalAmount: number) {
  Analytics.logEvent('purchase', {
    farmId,
    totalAmount,
  })
}
export function logOrderFailed(message: string) {
  Analytics.logEvent('order_failed', {
    message,
  })
}

export function logGeneric(name: string) {
  Analytics.logEvent(name)
}
