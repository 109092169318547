import { Farm } from '@models/Farm'
import { MoneyCalc } from '@helpers/money'
import { Money, Zero } from '@models/Money'
import { isEmptyValue } from '@helpers/helpers'

export type WholesaleFormType = {
  minCartAmount?: Money
  dueDateTolerance: number
}

/** A list of options for due date tolerance for invoices, this is specified as a set number of options right now, but it
 * is stored in the database as a number and in the future if we want more flexibility we could allow any number. */
export const wholesaleDueDateToleranceItems = [
  { label: 'Due on delivery', value: '0' },
  { label: '15 days', value: '15' },
  { label: '30 days', value: '30' },
  { label: '90 days', value: '90' },
]

/**
 * Will get just the fields required to update from the form changes
 * @param values the updated values from the form
 * @param farm the original values saved on the farm data
 */
export const getWholesaleUpdateFields = (
  values: WholesaleFormType,
  farm: Farm,
): Pick<Farm, 'orderMinimum' | 'dueDateTolerance'> | undefined => {
  const updateFields: Pick<Farm, 'orderMinimum' | 'dueDateTolerance'> = {}

  if (values.dueDateTolerance !== undefined && values.dueDateTolerance !== farm.dueDateTolerance?.wholesale) {
    updateFields.dueDateTolerance = {
      retail: farm.dueDateTolerance?.retail,
      wholesale: values.dueDateTolerance,
    }
  }

  // We only want to update the value if the value has changed. Since undefined is the same as Zero for this scenario, we
  // can safely treat undefined as Zero for this comparison
  if (!MoneyCalc.isEqual(values.minCartAmount ?? Zero, farm.orderMinimum?.wholesale ?? Zero)) {
    updateFields.orderMinimum = {
      retail: farm.orderMinimum?.retail,
      wholesale: values.minCartAmount,
    }
  }

  // Will return the fields to update or undefined if no fields need to be updated
  return !isEmptyValue(updateFields) ? updateFields : undefined
}
