import { CartServiceType } from '@/constants/types/cartService'
import { dequal } from '@helpers/customDequal'
import { useSelectorRoot } from '../../redux/selectors'

/** Gets the customer for the given cart */
export const useCartCustomer = ({ cartServiceType = 'consumer' }: { cartServiceType?: CartServiceType }) =>
  useSelectorRoot(
    (rs) =>
      cartServiceType === 'orderCreator'
        ? rs.adminState.cartService.customer
        : cartServiceType === 'consumer'
        ? rs.user
        : rs.adminState.cartServiceOrderEdit.customer,
    dequal,
  )
