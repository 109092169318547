import { isInstitutionAccountSelector, userSelector, wholesaleSelector } from '@/redux/selectors'
import { LoadingView } from '@elements'
import { getDisplayName } from '@helpers/client'
import { NotAllowedScreen } from '@screens/NotAllowedScreen/NotAllowedScreen'
import * as React from 'react'
import { memo } from 'react'
import { useSelector } from 'react-redux'

type ExtraProps = {
  /**
   * Whether to show the app header.
   * This should be true when the Header component is rendered at the screen level, as it will be replaced also with the screen
   */
  showHeader?: boolean
  /** block wholesale authorized user. */
  blockWholesaleAuthorizedUser?: boolean
}

/**
 * HOC that hides the wrapped component when the user is an institution acting in wholesale mode, or a wholesale authorized user in retail mode.
 * @param Component - The component that will be shown or hidden
 */
export function withBuyerBlock<P extends object>(Component: React.ComponentType<P>, extraProps?: ExtraProps) {
  const NewComponent = memo(function (props: P): JSX.Element {
    const isInstitutionAccount = useSelector(isInstitutionAccountSelector)
    const { isWholesale } = useSelector(wholesaleSelector)
    const user = useSelector(userSelector)

    return (
      <LoadingView loading={isWholesale === undefined} switchMode>
        {isInstitutionAccount || (!!user.isInstitutionAuthorizedUser && !!extraProps?.blockWholesaleAuthorizedUser) ? (
          <NotAllowedScreen showHeader={extraProps?.showHeader} />
        ) : (
          <Component {...props} />
        )}
      </LoadingView>
    )
  })
  NewComponent.displayName = `WithBuyerBlock(${getDisplayName(Component)})`

  return NewComponent
}
