import { Button, Text, Toast } from '@elements'
import { CSA } from '@models/CSA'
import { Share } from '@models/Product'
import { memo, useEffect, useMemo } from 'react'

import ImageCard, { ImageCardProps } from '../ImageCard'
import { ItemCardList } from '../ItemCardList'
import { MessageWithIcon } from '../LoaderWithMessage'
import { ColumnSize } from '../ResponsiveList'
import { LoadingView } from '../elements/LoadingView'

import { useApiFx } from '@/hooks/useApiFx'
import { loadCsasForProduct } from '@api/CSAs'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'

type CsaSelectorProps = {
  /** product to select a csa for */
  prod: Share
  /** handler for the selected csa */
  onSelect: (csa: CSA) => void
  isAdmin: boolean
}

/** Component that helps select a csa from among a product's csas.
 *
 * - If a single csa is found after filtering, it will auto call onSelect
 */
export function CsaSelector({ prod, onSelect, isAdmin = false }: CsaSelectorProps) {
  const { data, loading, err } = useApiFx(loadCsasForProduct, [prod.csa, isAdmin])
  const cardProps = useMemo(() => ({ onPress: onSelect }), [onSelect])
  const listEmpty = useMemo(() => <MessageWithIcon title="No valid csas were found for this product" />, [])

  /** auto selects the first csa if it's the only one after filters */
  useEffect(() => {
    if (data?.length === 1) {
      onSelect(data[0])
      Toast(`CSA "${data[0].name}" was selected `)
    }
  }, [data, onSelect])

  return (
    <LoadingView
      loading={loading}
      error={err ? 'Something went wrong while loading the CSA data for this product' : undefined}
      success={data}
    >
      {(data) => (
        <ItemCardList
          Component={CsaCard}
          items={data}
          itemProp="csa"
          onPress={onSelect}
          columnSize={ColumnSize.Small}
          cardProps={cardProps}
          ListEmptyComponent={listEmpty}
          smallHorizontal={false}
        />
      )}
    </LoadingView>
  )
}

type CsaCardProps = {
  csa: CSA
  onPress: (csa: CSA) => void
} & Omit<ImageCardProps, 'imageUri' | 'children' | 'onPress'>

const CsaCard = memo(function CsaCard({ csa, onPress, ...rest }: CsaCardProps) {
  return (
    <ImageCard key={csa.id} imageUri={csa.images[0]?.storageUrl} {...rest}>
      <Text>{csa.name}</Text>
      <Text numberOfLines={2}>{csa.shortDescription}</Text>
      <Button title="Choose CSA" outline onPress={() => onPress(csa)} />
    </ImageCard>
  )
}, propsAreDeepEqual)
