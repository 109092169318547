// import { useNavigation } from '@react-navigation/native'
import { useState, FunctionComponent, useEffect } from 'react'
import { ButtonGroup } from 'react-native-elements'
import { useSelector } from 'react-redux'

import { fontSize } from '../../components/elements/Text'
import Colors from '../../constants/Colors'
import { Layout } from '../../constants/types'
import { RootState } from '../../redux/reducers/types'
import { layoutSelector } from '../../redux/selectors'

type ButtonGroupProps = {
  active: number
  screens: { label: string; onPress: () => void }[]
  containerStyle?: any
  textStyle?: any
}

export const ButtonGroupNav: FunctionComponent<ButtonGroupProps> = ({ active, screens, containerStyle, textStyle }) => {
  const [btns, setBtns] = useState([''])
  const [selectedIndex, setSelectedIndex] = useState(active)
  const layout = useSelector<RootState, Layout>(layoutSelector)

  useEffect(() => {
    setBtns(screens.map((opt) => opt.label))
  }, [])

  useEffect(() => {
    setSelectedIndex(active)
  }, [active, selectedIndex])

  const updateIndex = (selected: number) => {
    setSelectedIndex(selected)
    screens[selected].onPress()
  }

  return (
    <ButtonGroup
      onPress={updateIndex}
      selectedIndex={selectedIndex}
      buttons={btns}
      containerStyle={[navWidth(layout), { alignSelf: 'center' }, containerStyle]}
      selectedButtonStyle={{ backgroundColor: Colors.green }}
      textStyle={[{ textAlign: 'center', fontSize: fontSize(14, 4) }, textStyle]}
    />
  )
}

const navWidth = (layout: Layout) => ({
  width: layout.width / 1.1,
})
