import { DatePeriods, convertPeriodToRange, displayDateRange, format } from '@helpers/time'
import { DateRange } from '@models/Schedule'
import { Timezone } from '@models/Timezone'

/** Creates Picker compatible option for a specific period */
export const convertPeriodToPickerOption = (period: DatePeriods, timezone: Timezone) => {
  const range = convertPeriodToRange(period, timezone)

  // Custom period won't have a range, so we return a simple string
  const label = range ? getRangeLabel(period, range) : 'Custom'
  return {
    label,
    value: period,
    // Return ranges also as they should not be computed again
    range,
  }
}

const getRangeLabel = (period: DatePeriods, range: DateRange): string => {
  let rangeText

  // Return only day if the range is same date
  if (range.startDate.hasSame(range.endDate, 'day')) {
    rangeText = format(range.startDate, 'MMM dd')
  }
  // Else return the period
  else {
    rangeText = displayDateRange(range)
  }

  return `${period}   ( ${rangeText} )`
}
