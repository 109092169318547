import React, { memo } from 'react'
import { StyleSheet, View } from 'react-native'

import Colors from '@/constants/Colors'
import { Text } from '@elements'
import { ArrElement } from '@helpers/typescript'
import { ProductType } from '@models/Product'
import { ReportType, SummaryItem } from '@models/Summary'

type ItemInfo = Pick<SummaryItem<ReportType.SignIn>['product'], 'id' | 'name' | 'type'>
type QtyInfo = Pick<ArrElement<SummaryItem<ReportType.SignIn>['selectedUnits']>, 'quantity' | 'status' | 'unit'>

/**Simplified data type used for easier data usage in `SignInItemRow` component */
export type ItemType = ItemInfo & QtyInfo

type ItemRowProp = {
  item: ItemType
  status?: string
}
/** Component for displaying a SignIn item */
export const SignInItemRow = memo(function SignInItemRow({ item, status }: ItemRowProp) {
  return (
    <View style={styles.item}>
      <View style={styles.largeCont}>
        <Text style={styles.nameText}>{item.name}</Text>
      </View>
      <View style={styles.smallCont}>
        <Text>{item.quantity}</Text>
      </View>

      <View style={styles.mediumCont}>
        <Text>{item.type === ProductType.Standard ? item.unit?.name : 'Share'}</Text>
      </View>
      {!!status && (
        <View style={styles.mediumCont}>
          <Text>{status}</Text>
        </View>
      )}
    </View>
  )
})
const styles = StyleSheet.create({
  item: {
    borderBottomWidth: 1,
    borderColor: Colors.shades[100],
    flexDirection: 'row',
    paddingHorizontal: 10,
    minHeight: 48,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  largeCont: {
    flex: 4,
  },
  mediumCont: {
    flex: 2,
  },
  smallCont: {
    flex: 1,
  },
  nameText: {
    marginRight: 10,
  },
})
