import { withAdminAuth } from '@/hooks/withAdminAuth'
import { withAdminIndexHooks } from '@/hooks/withAlgoliaIndex'
import { AccessRight, Permission } from '@helpers/Permission'
import { OrderEditUi } from './OrderEditUi'
import { OrderEditContext, useOrderEditData } from './useOrderEditData'

function OrderEditScreenComp() {
  const data = useOrderEditData()
  return (
    <OrderEditContext.Provider value={data}>
      <OrderEditUi />
    </OrderEditContext.Provider>
  )
}

export const OrderEditScreen = withAdminAuth(
  withAdminIndexHooks(OrderEditScreenComp),
  Permission.Orders,
  AccessRight.Edit,
)
