import { appCheck } from './db'
import { callEndpoint } from './v2'

/**
 * Verify App Check provides a method to confirm that the caller is a valid instance of the app and not a bot or hacker.
 * This can be used for sensitive actions or any actions that may be at a high risk of spam
 */
export async function verifyAppCheck(): Promise<boolean> {
  try {
    const token = await appCheck().getToken()
    return await callEndpoint('v2.AppCheck.verifyAppCheckService', { token })
  } catch (e) {
    return false
  }
}
