import { FormBuilder } from '@components'
import { YUP_WHOLE_NUMBER_REAL } from '@helpers/Yup'
import { isShare, Product, ProductType, Share } from '@models/Product'
import { useFormikContext } from 'formik'
import { Input } from 'react-native-elements'
import * as Yup from 'yup'

import { ProductSchemaContext } from '@helpers/builders/buildProduct'
import InputLabel from '../../../components/InputLabel'
import FormSectionHeader from '../components/FormSectionHeader'
import FormWrapper from '../components/FormWrapper'
import { ProductFormikComponent } from './helpers/ProductFormikComponent'

import { useDeviceSize } from '@/hooks/useLayout'
import { PartialPick } from '@helpers/typescript'
import { ProductTypeForm } from './helpers/ProductTypeInfo'

export type SharePricingForm = {
  numberOfPickups?: Share['numberPickups']
  /** global quantity specific to shares. Do not name this 'quantity' because it may overlap with the other global quantity for standard products */
  shareQuantity?: Share['quantity']
}

const pricingValidation: Yup.ObjectSchema<SharePricingForm, ProductSchemaContext> =
  Yup.object<ProductSchemaContext>().shape({
    numberOfPickups: Yup.number().when('type', {
      is: (type: ProductType) => isShare({ type }),
      then: () => YUP_WHOLE_NUMBER_REAL('Number Of Pickups'),
    }),
    shareQuantity: Yup.number().when('type', {
      is: (type: ProductType) => isShare({ type }),
      then: () => YUP_WHOLE_NUMBER_REAL('Quantity', { allowZero: true }).required('Quantity is required.'),
    }),
  })

const toFormik = (product: PartialPick<Product, 'type'>): SharePricingForm => {
  if (!isShare(product)) {
    return {}
  }

  return {
    numberOfPickups: product.numberPickups,
    shareQuantity: product.quantity,
  }
}

function fromFormik(values: SharePricingForm & ProductTypeForm): Partial<Product> {
  // If it is not a share return nothing
  if (!isShare(values)) return {}

  return {
    quantity: Number(values.shareQuantity),
    numberPickups: Number(values.numberOfPickups),
  }
}

export const FormikSharePricing = new ProductFormikComponent(pricingValidation, toFormik, fromFormik)

export default function SharePricing() {
  const { isSmallDevice } = useDeviceSize()
  const { handleChange, values, errors, touched, handleBlur } = useFormikContext<
    SharePricingForm & { isChild: boolean; templateProductId: string; type: ProductType }
  >()

  return (
    <FormWrapper>
      <FormSectionHeader title="Pickups and Inventory" />
      <FormBuilder row={!isSmallDevice}>
        <Input
          value={values.numberOfPickups?.toString() || ''}
          placeholder="0"
          label={<InputLabel label="Number of Pickups" required />}
          onChangeText={handleChange('numberOfPickups')}
          onBlur={handleBlur('numberOfPickups')}
          errorMessage={touched.numberOfPickups ? errors.numberOfPickups : ''}
        />

        {isShare({ type: values.type }) && (
          <Input
            value={values.shareQuantity?.toString() || ''}
            placeholder="0"
            label={<InputLabel label="In Stock" required />}
            onChangeText={handleChange('shareQuantity')}
            onBlur={handleBlur('shareQuantity')}
            errorMessage={touched.shareQuantity ? errors.shareQuantity : ''}
          />
        )}
      </FormBuilder>
    </FormWrapper>
  )
}
