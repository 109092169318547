import { isTruthy } from '@helpers/helpers'
import { Falsy, ImageStyle, StyleProp, TextStyle, ViewStyle } from 'react-native'

import { objToStr } from '@helpers/log'
import { useMemo } from 'react'
import { useDeepCompareMemo } from './useDeepEqualEffect'

/** A tyle object of a specific sub type */
export type Style = ViewStyle | TextStyle | ImageStyle

/** Given a set of styles, will return a memoized flattened style which updates when the external style has any deep change.
 * - This should only be used for very complex styles that require a higher level of precision in comparison.
 */
export function useFlatStyle(...styles: StyleProp<Style>[]) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useDeepCompareMemo(() => spreadStyle([...styles]), [...styles])
}

/** Given a set of styles, will return a memoized flattened style which updates when the external styles have a string-compared check.
 * - This is meant to be lighter/ more efficient than deep compare.
 * - String comparison isn't ideal for complex data but it is OK for styles, because styles don't change that much during execution.
 */
export function useFlatStyleSimple(...styles: StyleProp<Style>[]) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => spreadStyle([...styles]), [objToStr(styles)])
}

/** This is an alternative to the StyleProp type, which more accurately represents how we practically handle style props. The helper spreadStyle is expected to handle this style. The default StyleProp type is not assignable to this because it's a more complex type, but it doesn't matter because the differences do not represent how we really work with style props */
export type RecursiveStyle<S extends Style = Style> = (Style | Falsy | RecursiveStyle<S>)[]

/** Will recursively convert a style prop into a style object.
 * - Handles recursive styles and falsy values in array.
 * - This might be considered more efficient than StyleSheet.flatten() because there is no lookup of styles by Id when you provide a style object created by StyleSheet.create().
 */
export function spreadStyle<S extends Style>(prop: StyleProp<Style>): S {
  //@ts-expect-error
  return Array.isArray(prop)
    ? prop
        .filter(isTruthy)
        .map((s) => (Array.isArray(s) ? spreadStyle(s) : s))
        //@ts-expect-error
        .reduce((prev, curr) => ({ ...prev, ...curr }), {})
    : prop || {}
}
