import { ImageSourcePropType } from 'react-native'

export const farmerOnboardPartnersAssets: ImageSourcePropType[] = [
  require('./footprint-farm.png'),
  require('./river-queen-greens.png'),
  require('./hearty-roots.png'),
  require('./rachnito-milkyway.png'),
  require('./tipi.png'),
  require('./hawthorne-valley-farm.png'),
  require('./sleeping-lion-farm.png'),
]
