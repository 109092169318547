import React, { PropsWithChildren, useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { Spinner } from '../../components/elements/Spinner'
import { getPersistor, getStore, isStoreSet, setPersistor, setStore } from './index'
import { storeCreator } from './storeCreator'

export function ReduxProvider({ children }: PropsWithChildren) {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (!isStoreSet()) {
      storeCreator().then(({ store, persistor }) => {
        setStore(store)
        setPersistor(persistor)
        setIsLoaded(true)
      })
    }
  }, [])

  if (!isLoaded) return <Spinner />

  return (
    <Provider store={getStore()}>
      <PersistGate loading={<Spinner />} persistor={getPersistor()}>
        {children}
      </PersistGate>
    </Provider>
  )
}
