import { EbtIcon, Image, ProductCardSquare, SeeMoreProductList, paddedProdWidthSM } from '@components'
import { ButtonClear, LoadingView, Text, Touchable } from '@elements'
import { formatShortAddress } from '@helpers/display'
import { AlgoliaGeoDoc, AlgoliaGeoProduct } from '@models/Algolia'
import { memo, useCallback, useMemo } from 'react'
import { View } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import Colors from '@/constants/Colors'
import { SHADOW_BLUR } from '@/constants/Styles'
import { useApiFx } from '@/hooks/useApiFx'
import { useAvailAddons } from '@/hooks/useAvailAddons'
import { useCartService } from '@/hooks/useCart'
import { useLayout } from '@/hooks/useLayout'
import { ConsumerNavigatorParamList } from '@/navigation/types'
import { wholesaleSelector } from '@/redux/selectors'
import { getAlgoliaFarmsByIds } from '@api/Farms'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { getCardActionShop } from '@screens/Shopping/FarmShop/helpers'
import { useSelector } from 'react-redux'

type Props = {
  /** A group of products that belong to the same farm */
  prods: AlgoliaGeoDoc<AlgoliaGeoProduct>[]
}

type ProdRows = { 0: AlgoliaGeoDoc<AlgoliaGeoProduct>[]; 1: AlgoliaGeoDoc<AlgoliaGeoProduct>[] }

/** Displays a group of products results from a given farm */
export const NearbyProdsGroup = memo(function NearbyProdsGroup({ prods }: Props) {
  const { isWholesale } = useSelector(wholesaleSelector)
  const { cart } = useCartService()
  const { availAddonsIds } = useAvailAddons()
  const navigation = useNavigation<StackNavigationProp<ConsumerNavigatorParamList, 'Home'>>()
  const layout = useLayout()

  const slicedProds = useMemo(() => {
    const cols = Math.floor(layout.width / paddedProdWidthSM)
    const rows: ProdRows = {
      0: prods.slice(0, cols),
      1: prods.slice(cols, cols * 2),
    }
    return rows
  }, [layout.width, prods])

  const styles = useStyles()

  const renderItem = useCallback(
    (prod: AlgoliaGeoDoc<AlgoliaGeoProduct>) => {
      if (isWholesale === undefined) {
        // Should wait for isWholesale to be defined, before rendering
        return <></>
      }

      return (
        <ProductCardSquare
          small
          product={prod}
          goBack="home"
          cardAction={getCardActionShop(
            prod,
            availAddonsIds,
            cart.map((itm) => itm.product.id),
            isWholesale,
          )}
          onPressCsaNavigate={(prod, csa) => {
            navigation.navigate('Shopping', {
              screen: 'CSADetails',
              params: {
                farmSlug: prod.farm.urlSafeSlug,
                csaId: csa?.id ?? prod.csa?.[0],
                goBack: 'home',
              },
            })
          }}
          onPressMobileNavigate={() =>
            navigation.navigate('Shopping', {
              screen: 'ProductDetails',
              params: { productId: prod.id, farmSlug: prod.farm.urlSafeSlug, goBack: 'home' },
            })
          }
        />
      )
    },
    [navigation, availAddonsIds, cart, isWholesale],
  )

  const farmId = prods[0]?.farm.id
  const farmSlug = prods[0]?.farm.urlSafeSlug

  return (
    <View style={styles.wrapper}>
      {!!farmId && <NearbyFarmCard farmId={farmId} />}

      <LoadingView style={styles.prodsWrapper} loading={isWholesale === undefined} success={isWholesale}>
        {(isWholesale) =>
          layout.isSmallDevice ? (
            <>
              <View style={styles.list}>
                {slicedProds[0].map((p) => (
                  <View key={p.id} style={styles.marginRight30}>
                    {renderItem(p)}
                  </View>
                ))}
              </View>
              <View style={styles.list}>
                {slicedProds[1].map((p) => (
                  <View key={p.id} style={styles.marginRight30}>
                    {renderItem(p)}
                  </View>
                ))}
              </View>
            </>
          ) : (
            <View style={styles.seeMoreWrapper}>
              <SeeMoreProductList
                data={prods}
                title=""
                small
                seeAllLabel="Shop all products"
                seeAllPress={`/farms/${farmSlug}/shop`}
                renderItem={renderItem}
                extraData={[cart, availAddonsIds, navigation, isWholesale]}
              />
            </View>
          )
        }
      </LoadingView>
    </View>
  )
})

export const NearbyFarmCard = memo(function NearbyFarmCard({ farmId }: { farmId: string }) {
  const { isSmallDevice } = useLayout()
  const styles = useStyles()
  const { isWholesale } = useSelector(wholesaleSelector)
  const {
    data: farms,
    loading,
    err,
  } = useApiFx(getAlgoliaFarmsByIds, [[farmId], isWholesale], typeof isWholesale === 'boolean', { noRefocus: true })

  return (
    <LoadingView
      loading={loading}
      error={err}
      success={farms?.[0]}
      style={styles.farmCardWrapper}
      noDefaultLoadingContainerStyle
    >
      {(farm) => (
        <>
          <Image style={styles.farmImg} source={{ uri: farm.images[0] }} />
          <View style={styles.dataCont}>
            <Touchable url={`/farms/${farm.farm.urlSafeSlug}`}>
              <>
                <View style={styles.farmNameCont}>
                  <Text numberOfLines={1} type="medium" size={16}>
                    {farm.name}
                  </Text>
                </View>
                <Text>{formatShortAddress(farm.address)}</Text>
                {!isSmallDevice && (
                  <View style={styles.ebtWrapper}>
                    {farm.isEbt && !isWholesale && (
                      <View style={styles.ebtCont}>
                        <EbtIcon visible={farm.isEbt} />
                        <Text style={styles.ebtText} size={10}>
                          SNAP / EBT
                        </Text>
                      </View>
                    )}
                  </View>
                )}
              </>
            </Touchable>
            <ButtonClear
              size={13}
              url={`/farms/${farm.farm.urlSafeSlug}/shop?goBack=home`}
              style={styles.shopAllBtn}
              title="Shop all products"
            />
          </View>
        </>
      )}
    </LoadingView>
  )
})

const useStyles = CreateResponsiveStyle(
  {
    wrapper: {
      flexDirection: 'row',
      alignItems: 'flex-end',
      marginVertical: 10,
      borderRadius: 10,
      backgroundColor: Colors.white,
      ...SHADOW_BLUR,
    },
    prodsWrapper: {
      flex: 1,
    },
    list: {
      flexGrow: 1,
    },
    seeMoreWrapper: {
      marginTop: -16,
      paddingRight: 5,
    },

    farmCardWrapper: {
      width: 220,
      justifyContent: 'center',
      alignContent: 'center',
      alignSelf: 'center',
      marginHorizontal: 20,
    },

    farmImg: {
      height: 80,
      width: 80,
      borderRadius: 100,
    },
    farmNameCont: {
      minHeight: 40,
      justifyContent: 'center',
    },
    ebtWrapper: {
      minHeight: 40,
    },
    ebtCont: {
      borderWidth: 1,
      borderColor: Colors.primaryGray,
      paddingVertical: 2,
      paddingHorizontal: 10,
      borderRadius: 12,
      marginVertical: 10,
      flexDirection: 'row',
      alignSelf: 'flex-start',
      alignItems: 'center',
    },
    ebtText: {
      marginLeft: 5,
      marginVertical: 4,
      color: Colors.primaryGray,
    },
    shopAllBtn: {
      paddingLeft: 0,
      paddingTop: 2,
      alignSelf: 'flex-start',
    },
    marginRight30: {
      marginRight: 30,
    },
    dataCont: {},
  },
  {
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      wrapper: {
        flexDirection: 'column',
        alignItems: 'center',
        padding: 14,
      },

      prodsWrapper: {
        width: '100%',
      },

      farmCardWrapper: {
        marginHorizontal: 0,
        width: '90%',
        alignSelf: 'stretch',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 16,
      },
      list: {
        flexDirection: 'row',
      },

      farmImg: {
        marginRight: 20,
        height: 60,
        width: 60,
      },
      dataCont: { flex: 1 },
    },
  },
)
