import Colors from '@/constants/Colors'
import { createObjectStyles } from '@helpers/client/createObjectStyles'
import { ViewStyle } from 'react-native'

export const styles = createObjectStyles(({ merge }) => ({
  totalDiscount: { marginLeft: 10 },
  orderTotalCont() {
    return merge(this.headerOrderTotal, this.padded, { justifyContent: 'flex-end', paddingTop: 0 })
  },
  orderTotalAmount: { marginLeft: 10 },
  deliverySectionTitle: { marginTop: 15 },
  deliveryGroupCont: { marginVertical: 15 },
  deliveryGroupTop: { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' },
  deliveryTotalMain: { marginHorizontal: 10 },
  deliveryTotalCont(): ViewStyle {
    return merge(this.headerOrderTotal, this.padded, { justifyContent: 'flex-end', marginVertical: 15 })
  },
  deliveryDetailsCont: {
    backgroundColor: Colors.lightGreen,
    borderRadius: 10,
    padding: 5,
    marginTop: 5,
  },
  deliveryDetailsText: { marginTop: 5 },
  emptyCartText: { marginVertical: 10 },
  itemTop: { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' },
  itemProdName: { padding: 1 },
  itemBuyingOpt: { marginVertical: 10 },
  itemPickupsCont: { flexDirection: 'row', justifyContent: 'space-between' },
  buttonClear: { padding: 0 },
  pickupsExpanded: { marginHorizontal: 5, marginTop: 5 },
  stepperOverride: { borderWidth: 0 },
  image: {
    borderRadius: 10,
    overflow: 'hidden',
    width: 60,
    aspectRatio: 1,
    marginVertical: 10,
  },
  cartItemContainer: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightGray,
    paddingVertical: 10,
  },
  subtotalRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 5,
    alignItems: 'center',
  },
  subtotalContainer: {
    alignSelf: 'flex-end',
  },
  subtotalAmount: { marginLeft: 10 },
  stepperContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  wrapper: {
    borderWidth: 1,
    borderColor: Colors.lightGray,
    borderRadius: 10,
  },
  headerOrderTotal: {
    width: '100%',
    backgroundColor: Colors.lightGreen,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  padded: {
    padding: 15,
  },
  emptyCartCont: {
    paddingTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  addCoupon: {
    alignSelf: 'flex-end',
    margin: 10,
  },
  taxesAndFeesAndDiscountsCont: {
    paddingHorizontal: 15,
    alignSelf: 'flex-end',
  },
}))
