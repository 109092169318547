import { useCreateSearchParams } from '@components'
import { ButtonClear, SearchInputBar } from '@elements'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { memo, useContext, useState } from 'react'
import { Keyboard, StyleSheet, View } from 'react-native'

import Colors from '../../constants/Colors'
import { globalStyles } from '../../constants/Styles'
import { useDebouncedValue } from '../../hooks/useDebounce'
import { useKeyboard } from '../../hooks/useKeyboard'
import { useDeviceSize } from '../../hooks/useLayout'
import { HomeParamList } from '../../navigation/types'
import { ExploreContext } from './components/ExploreContext'

import { isWeb } from '@/constants/Layout'
import { useFocusFx } from '@/hooks/useFocusFx'

export const MobileExploreInput = memo(function MobileExploreSearch() {
  const { isSmallDevice } = useDeviceSize()
  const isKeyBoardOpen = useKeyboard()
  const navigation = useNavigation<StackNavigationProp<HomeParamList, 'ExploreScreen'>>()
  const { params } = useRoute<RouteProp<HomeParamList, 'ExploreScreen'>>()
  const [refinement, setRefinement] = useState<string>(params?.q || '')
  const debouncedRefinement = useDebouncedValue(refinement, 150)
  const createSearchParams = useCreateSearchParams()
  const { setShowModal } = useContext(ExploreContext)

  useFocusFx(() => {
    navigation.setParams(createSearchParams({ refinement: debouncedRefinement }))
  }, [debouncedRefinement, createSearchParams, navigation])

  const updateSearch = (refinement: string) => setRefinement(refinement)

  const redoSearch = (refinement: string) => {
    navigation.setParams(createSearchParams({ refinement }))
  }

  return (
    <View style={styles.container}>
      <SearchInputBar
        onChangeText={updateSearch}
        contStyle={globalStyles.flex1}
        value={refinement}
        placeholder="Search for farms or products"
        returnKeyType="search"
        onSubmitEditing={(e) => redoSearch(e.nativeEvent.text)}
        onIconPress={Keyboard.dismiss}
        loading={debouncedRefinement !== refinement}
        clear={refinement.length > 0 ? () => updateSearch('') : undefined}
      />
      {(!isKeyBoardOpen || !isSmallDevice) && (
        <ButtonClear icon="filter" style={styles.noPadding} onPress={() => setShowModal(true)} />
      )}
    </View>
  )
})

const styles = StyleSheet.create({
  noPadding: {
    paddingRight: 0,
  },
  marginRight: {
    marginRight: 7,
  },
  container: {
    flexDirection: 'row',
    marginHorizontal: 10,
    alignItems: 'center',
    marginBottom: 5,
    // The marginTop is only needed in web because in mobile it will already have a margin from the safeArea margin top, set at the screen level
    ...(isWeb ? { marginTop: 5 } : {}),
  },
  inputContainer: {
    height: '100%',
    flex: 1,
    flexDirection: 'row',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.shades['100'],
    backgroundColor: Colors.white,
  },
  leftIcon: {
    marginHorizontal: 16,
    marginVertical: 10,
  },
  rightIconsCont: {
    position: 'absolute',
    right: 0,
    flexDirection: 'row',
    alignItems: 'center',
  },

  closeBtn: {
    paddingVertical: 10,
    paddingHorizontal: 3,
  },
  spinner: {
    flex: 0,
    padding: 10,
  },
})
