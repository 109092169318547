import { omit, PartialExcept } from '@helpers/typescript'
import { TemplateProduct } from '@models/Product'
import { productsCollection, templateProductsCollection } from './framework/ClientCollections'
import { limit, where } from 'firebase/firestore'

/**
 * loadTemplateProduct returns all of the available TemplateProduct for a farm.
 */
export async function loadTemplateProductsByFarmId(farmId: string): Promise<TemplateProduct[]> {
  return templateProductsCollection.fetchAll(where('farm.id', '==', farmId))
}

/**
 * addTemplateProduct saves a new templateProduct to the database.
 */
export async function addTemplateProduct(templateProduct: Omit<TemplateProduct, 'id'>): Promise<TemplateProduct> {
  const newTemplateProduct = await templateProductsCollection.create(templateProduct)
  return newTemplateProduct
}

/**
 * updateTemplateProduct updates the templateProduct with properties that are editable.
 */
export function updateTemplateProduct(templateProduct: PartialExcept<TemplateProduct, 'id'>): Promise<void> {
  const data = omit(templateProduct, 'farm') as Partial<TemplateProduct>
  return templateProductsCollection.update(data as Partial<TemplateProduct> & { id: string })
}

/**
 * loadTemplateProduct by id.
 */
export async function loadTemplateProductById(id: string): Promise<TemplateProduct> {
  return templateProductsCollection.fetch(id)
}

/**
 * deleteTemplateProduct deletes a templateProduct by templateProduct.id.
 */
export async function deleteTemplateProduct(templateProductId: string): Promise<void> {
  return await templateProductsCollection.delete(templateProductId)
}

/**
 * checks if a templateProduct is associated to a product and if so returns true.
 */
export async function canTemplateProductBeDeleted(templateProductId: string): Promise<boolean> {
  try {
    const products = await productsCollection.fetchAll(where('templateProduct.id', '==', templateProductId), limit(1))
    return products.length === 0
  } catch (error) {
    return false
  }
}
