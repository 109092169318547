import { loadFarmAssociation } from '@api/FarmAssociations'
import { adminLoadOrdersByUserAndFarm } from '@api/Orders'
import { loadPickupsByUserAndFarm } from '@api/Pickups'
import { loadUser } from '@api/Users'
import { MessageWithIcon } from '@components'
import { LoadingView } from '@elements'
import { dequal } from '@helpers/customDequal'
import { errorToString } from '@helpers/helpers'
import { sortByLatest } from '@helpers/sorting'
import { Farm } from '@models/Farm'
import { Zero } from '@models/Money'
import { Order } from '@models/Order'
import { Product } from '@models/Product'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback, useRef, useState } from 'react'
import { LayoutChangeEvent, LayoutRectangle, ScrollView, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { AdminView } from '../../components/AdminView'
import { AdminDrawerParamList, CustomerParamList } from '../../navigation/types'
import { CustomerInvoices } from './AdminCustomerInvoicesSection/CustomerInvoices'
import { AdminCustomerOrdersSection } from './AdminCustomerOrdersSection/AdminCustomerOrdersSection'
import { AdminCustomerPickupsSection } from './AdminCustomerPickupsSection/AdminCustomerPickupsSection'
import { AdminCustomerSubscriptions } from './AdminCustomerSubscriptionsSection/AdminCustomerSubscriptions'
import { loadOrdersMapAndProducts } from './AdminCustomerSubscriptionsSection/helper'
import { CustomerDetailsCard } from './components/CustomerDetailsCard'
import CustomerDetailsNavTitle from './components/CustomerDetailsNavTitle'
import CustomerOverviewCard from './components/CustomerOverview'

import { BackTo } from '@/admin/components/BackTo'
import { ShareRowProp } from '@/admin/components/SubscriptionAccordion'
import { Logger } from '@/config/logger'
import { globalStyles } from '@/constants/Styles'
import { useApiFx } from '@/hooks/useApiFx'
import { useDeepCompareMemo } from '@/hooks/useDeepEqualEffect'
import { useDeepCompareFocusFx, useFocusFx } from '@/hooks/useFocusFx'
import { withAdminAuth } from '@/hooks/withAdminAuth'
import { setAdminNav } from '@/redux/actions/adminState'
import { RootState } from '@/redux/reducers/types'
import { adminFarmSelector, adminParamsSelector } from '@/redux/selectors'
import { Permission } from '@helpers/Permission'

function AdminCustomerDetailsScreen() {
  const farm = useSelector<RootState, Farm>(adminFarmSelector)

  const { params } = useRoute<RouteProp<CustomerParamList, 'CustomerDetails'>>()
  const [ordersMap, setOrdersMap] = useState<Map<string, Order>>(new Map()) //key is order id
  const [productsMap, setProductsMap] = useState<Map<string, Product>>(new Map())

  const [totalDue, setTotalDue] = useState(Zero)
  /** The farmassociation customerRef of the user specified in params.custId */
  const [stripeCust, setStripeCust] = useState<string | undefined>()
  const navigation = useNavigation<StackNavigationProp<CustomerParamList & AdminDrawerParamList, 'CustomerDetails'>>()
  const dispatch = useDispatch()
  const { customer } = useSelector(adminParamsSelector)
  const [loadingCustomer, setLoadingCustomer] = useState(true)

  /** useApiFx section */
  const pickupsFx = useApiFx(loadPickupsByUserAndFarm, [params.custId, farm.id], !!params.custId && !!farm.id, {
    transform: (pickups) => pickups.sort(sortByLatest('date')),
  })

  const ordersFx = useApiFx(adminLoadOrdersByUserAndFarm, [params.custId, farm.id])

  /** handle if there is a notFound error for customer form custId */
  const [customerErrorText, setCustomerError] = useState<string | undefined>()
  /** Helpers for scrolling to the correct subscription row and expanding the accordion. There's one object for each row in the subscriptions table */
  const [shareRowProps, setShareRowProps] = useState<ShareRowProp[]>([])
  /** The `y` offset of the subscriptions section is necessary for scrolling here from other components */
  const [subscriptionsLayout, setSubscriptionsLayout] = useState<LayoutRectangle>()
  const scrollRef = useRef<ScrollView>(null)

  /** Scroll to subscription row specified in navigation params*/
  useDeepCompareFocusFx(() => {
    if (params?.orderNum && params?.productId && shareRowProps.length > 0 && subscriptionsLayout?.y) {
      const searchedOrderNum = Number(params.orderNum)
      const searchedProductId = params.productId

      const shareRow = shareRowProps.find(
        (row) => row.orderNum === searchedOrderNum && row.prodId === searchedProductId,
      )

      if (shareRow) {
        /** expand share then scroll */
        //Running this function will only expand this shareRow and close others
        shareRow?.expand()

        const rowLayout = shareRow.layout
        scrollRef.current?.scrollTo({
          animated: true,
          y: subscriptionsLayout.y + rowLayout.y + 50, //there is an additional scrollview with extra offset, which requires additional y offset, but it's impractical and unscalable to add the y offset of every single parent, so we just add 50 as an estimated extra offset, which works well, empirically
        })

        //Resetting params when the scrolling performance is done, so that it doesn't scroll multiple times.
        navigation.setParams({ orderNum: undefined, productId: undefined })
      }
    }
  }, [params.orderNum, params.productId, shareRowProps, subscriptionsLayout?.y, navigation])

  /** Loads and sets the state for the customer and its stripe ref */
  const loadCustomerAndStripeRef = useCallback(
    async (id: string) => {
      try {
        setLoadingCustomer(true)
        const [customer, assoc] = await Promise.all([loadUser(id), loadFarmAssociation(id, farm.id)])
        dispatch(setAdminNav({ customer }))
        setStripeCust(assoc.customerRef)
        setLoadingCustomer(false)
      } catch (err) {
        Logger.error(err)
        setCustomerError(errorToString(err))
        setLoadingCustomer(false)
      }
    },
    [dispatch, farm.id],
  )

  /** Load the customer, only if necessary. On load, dispatch to adminNav selector. If customer in nav selector matches custId, stop loading.*/
  useFocusFx(() => {
    if (!params.custId) {
      navigation.navigate('CustomerList')
    } else {
      loadCustomerAndStripeRef(params.custId)
    }
  }, [params.custId, loadCustomerAndStripeRef, navigation])

  /** Load ordersMap and products(shares-subscription) for the selected customer
   *  It will run this useDeepCompareFocusFx again if orders.data is really changed.
   */
  useDeepCompareFocusFx(() => {
    if (!ordersFx.loading && ordersFx.data)
      loadOrdersMapAndProducts(ordersFx.data)
        .then(({ newOrdsMap, newProdsMap }) => {
          setOrdersMap(newOrdsMap)
          setProductsMap(newProdsMap)
        })
        .catch((err) => {
          Logger.error(err)
          setCustomerError(errorToString(err))
        })
  }, [ordersFx.data, ordersFx.loading])

  /** - Reload orders and pickups after any actions make and change in subscription and order section.*/
  const resetFunc = useCallback(() => {
    ordersFx.refresh()
    pickupsFx.refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersFx.refresh, pickupsFx.refresh])

  const onLayout = useCallback(
    ({ nativeEvent: { layout: newLayout } }: LayoutChangeEvent) => {
      if (!dequal(newLayout, subscriptionsLayout)) setSubscriptionsLayout(newLayout)
    },
    [subscriptionsLayout],
  )

  const success = useDeepCompareMemo(
    () => (pickupsFx.data && ordersFx.data ? { pickups: pickupsFx.data, orders: ordersFx.data } : undefined),
    [pickupsFx.data, ordersFx.data],
  )

  const errorGoBack = useCallback(() => {
    setCustomerError(undefined)
    setLoadingCustomer(true)
    navigation.navigate('CustomerList')
  }, [navigation])

  // If there was an error loading the customer then show it here
  if (!loadingCustomer && customerErrorText) {
    return (
      <MessageWithIcon icon="user" title="Customer not found">
        {customerErrorText}
      </MessageWithIcon>
    )
  }

  return (
    <AdminView
      scrollRef={scrollRef}
      customHeader={
        /* Instead of using replace here, we use navigate here to navigate to customer list, so filter state will be kept. */
        <BackTo style={globalStyles.margin20} onPress={errorGoBack} title="Back to all Customers" />
      }
      useDefaultScrollView
    >
      <CustomerDetailsNavTitle customer={customer} totalDue={totalDue} />
      <CustomerOverviewCard stripeCust={stripeCust} totalDue={totalDue} />
      <CustomerDetailsCard stripeCust={stripeCust} onCustomerChanged={loadCustomerAndStripeRef} />

      <CustomerInvoices onAmountTotalChange={setTotalDue} customerId={params.custId} farm={farm} />

      {/* Put onLayout function here to track layout data instead of LoadingView. It can prevent from re-loading when screen size gets changed. */}
      <View onLayout={onLayout}>
        {/* The reason for not using pickupsFx.loading and ordersFx.loading is because Whenever the pickups reload, The entire Section will be recreated / reset. We don't want to reset entire child component. */}
        <LoadingView loading={!pickupsFx.data || !ordersFx.data} success={success}>
          {({ pickups }) => (
            <AdminCustomerSubscriptions
              setShareRowProps={setShareRowProps}
              customerId={params.custId}
              productsMap={productsMap}
              pickups={pickups}
              ordersMap={ordersMap}
              onChange={resetFunc}
            />
          )}
        </LoadingView>
      </View>

      {/* The reason for not using pickupsFx.loading and ordersFx.loading is because Whenever the pickups reload, The entire Section will be recreated / reset. We don't want to reset entire child component. */}
      <LoadingView loading={!pickupsFx.data || !ordersFx.data} success={success}>
        {({ pickups }) => (
          <AdminCustomerOrdersSection
            customerId={params.custId}
            pickups={pickups}
            ordersMap={ordersMap}
            subscriptionsLayout={subscriptionsLayout}
            shareRowProps={shareRowProps}
            scrollRef={scrollRef}
            reload={resetFunc}
          />
        )}
      </LoadingView>

      {/* The reason for not using pickupsFx.loading is because, whenever the pickups reload, The entire Section will be recreated / reset. When Load More has been pressed and the list is expanded, we want the Section state to remain uninterrupted */}
      <LoadingView loading={!pickupsFx.data} success={pickupsFx.data}>
        {(pickups) => <AdminCustomerPickupsSection pickups={pickups} reload={pickupsFx.refresh} />}
      </LoadingView>
    </AdminView>
  )
}

export default withAdminAuth(AdminCustomerDetailsScreen, Permission.Orders)
