import { loadDistributionsForProduct } from '@api/Distributions'
import { reschedulePickup } from '@api/Pickups'
import { loadProduct } from '@api/Products'
import { Alert, Button, Divider, ErrorText, Picker, PickerProps, Text, TextH2, TextH4 } from '@elements'
import { formatPickupTime, formatShortDate } from '@helpers/display'
import { isNonNullish } from '@helpers/helpers'
import {
  getCsaChangeDeadlineFromPickup,
  getLastDay,
  getNextDay,
  getPickups,
  isPickupItemChangeOptionBlocked,
  mapPickupExceptions,
} from '@helpers/order'
import { getProductAvailability, matchesAppModeSchedule } from '@helpers/products'
import { sortByEarliest } from '@helpers/sorting'
import { format, isSameDay, isWithinInterval } from '@helpers/time'
import { CSA } from '@models/CSA'
import { Distribution } from '@models/Distribution'
import { Location, isLocalPickup, isNonPickup } from '@models/Location'
import { Pickup, PickupItem, isPickupItemActive, isPickupItemCancelled } from '@models/Order'
import { RescheduleRequestData } from '@models/OrderChanges'
import { PhysicalProduct, isShare } from '@models/Product'
import { Hours } from '@models/Schedule'
import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'

import { Image } from '@components'
import LoaderWithMessage from '../../components/LoaderWithMessage'

import { useCancelableDeepFocusFx } from '@/hooks/useCancelablePromise'
import { wholesaleSelector } from '@/redux/selectors'
import { formatDistributionType } from '@helpers/location'
import { ArrElement } from '@helpers/typescript'
import { DateTime } from 'luxon'
import { useSelector } from 'react-redux'
import Colors from '../../constants/Colors'
import { isAfterCSAChangeWindow } from './helpers/helpers'

type Details = {
  pickupItem: PickupItem
  /** Initially undefined. Will hold a value after a reschedule option is selected. If share, this will hold the new distroId. If standard, will hold the new date as formatted string */
  rescheduleValue?: string
  product: PhysicalProduct
  /** these are the product distros, but they need to be obtained separately from the product.distributions because these have the full distro data whereas the product.distributions only have denormalized distro data. This array should not filter distros by any criteria such as hidden or closed */
  distros: Distribution[]
  options: PickerProps['items']
  errMessage?: string
}

type RescheduleProps = {
  pickup: Pickup
  onSuccess?: () => void
  isAdmin?: boolean
}

export function Reschedule({ pickup, isAdmin = false, onSuccess }: RescheduleProps) {
  const { isWholesale } = useSelector(wholesaleSelector)
  const [itemDetails, setDetails] = useState<Details[]>([])
  const [isLoading, setLoading] = useState(true)

  const locationType = pickup.distribution.locationType

  if (isNonPickup(pickup.distribution.locationType)) {
    Alert(
      `${formatDistributionType({ type: locationType }, { capitalize: true })} cannot be changed`,
      `We currently don't allow rescheduling for ${formatDistributionType({
        type: locationType,
      })} . Please contact support for more information.`,
    )
  }

  const getNearbyDate = useGetNearbyDate(pickup, isAdmin)

  const getOptions = useGetRescheduleOptions(pickup, getNearbyDate, isAdmin, isWholesale)

  useLoadData(setDetails, setLoading, getOptions, pickup, isAdmin)

  const onSubmit = useReschedule(itemDetails, pickup, getNearbyDate, setLoading, onSuccess)

  const onSelect = useOnSelect(setDetails, pickup)

  return (
    <>
      <Text style={styles.descriptionText}>Any rescheduling options are indicated below each item</Text>
      {itemDetails.length > 0 ? (
        <ScrollView style={styles.marginHorizontal20}>
          {itemDetails.map(({ pickupItem, options, rescheduleValue, errMessage, product }: Details, idx) => {
            return (
              <View key={idx}>
                <View style={styles.card}>
                  <Image
                    source={{
                      uri: pickupItem.product.image,
                    }}
                    style={styles.cardImage}
                    resizeMode="cover"
                  />
                  <View style={styles.textContent}>
                    <TextH2 numberOfLines={1}>{pickupItem.product.name}</TextH2>
                    <Text numberOfLines={1} style={styles.description}>
                      Quantity: {pickupItem.orderItem.quantity}
                    </Text>
                    {errMessage ? (
                      <TextH4 style={{ color: Colors.red }}>{errMessage}</TextH4>
                    ) : (
                      <>
                        <Picker
                          placeholder={null}
                          value={rescheduleValue ?? options[0].value}
                          onValueChange={(value) => onSelect(idx, value, isShare(product))}
                          items={options}
                          useWebNativePicker
                        />
                        <View style={styles.margin5} />
                        <ErrorText>{getDeadlineText(pickup, pickupItem, isAdmin)}</ErrorText>
                      </>
                    )}
                  </View>
                </View>
              </View>
            )
          })}
          <Divider clear />
        </ScrollView>
      ) : (
        <LoaderWithMessage loading={isLoading} title="No items available for rescheduling" />
      )}

      <Divider clear />
      <View>
        <Button
          loading={isLoading}
          disabled={itemDetails.every(({ rescheduleValue }) => rescheduleValue === undefined)}
          title="Submit"
          onPress={onSubmit}
        />
      </View>
    </>
  )
}

type GetRescheduleOpts = (prod: PhysicalProduct, pickupItem: PickupItem) => PickerProps['items']

/** Used to get the list of reschedule options offered to a given order item, for use in a dropdown picker */
const useGetRescheduleOptions = (
  pickup: Pickup,
  getNearbyDate: GetNewPickupDate,
  isAdmin: boolean,
  isWholesale?: boolean,
): GetRescheduleOpts => {
  return useCallback(
    (prod: PhysicalProduct, pickupItem): PickerProps['items'] => {
      if (isWholesale === undefined) {
        // Should not return options until the wholesale mode is available
        return []
      }

      const changeWindow = pickupItem.csaChangeOptions?.changeWindow ?? 1

      //For shares, the options offered are pickup dates of different distros in same week. Dropdown values are distroIds
      const shareOptions: PickerProps['items'] = [
        //should use the first item for the initial value instead of a placeholder, in order for it to be a controlled component, and be able to correctly undo a selection
        {
          label:
            makeLabel(
              pickup.date,
              pickup.distribution.hours,
              pickup.distribution.locationName,
              pickup.distribution.locationType,
            ) + ' (Current)',
          value: pickup.distribution.id,
        },
      ].concat(
        prod.distributions
          .filter((d) => !d.isHidden)
          // exclude closed distros for consumers
          .filter((dist) => (isAdmin ? true : !dist.closed))
          // Only include schedules for the current appMode catalog
          // If isWholesale is undefined, it should not return any, because it means the app is still loading
          .filter(matchesAppModeSchedule(isWholesale))
          .filter((dist) => isLocalPickup(dist.location.type)) // exclude delivery and shipping distros for reschedule.
          .filter(
            (dist) =>
              isAdmin || !isPickupItemChangeOptionBlocked('blockLocationSwitching', pickupItem)
                ? dist.id !== pickup.distribution.id // If the user is accessing this behavior from the admin side or if the 'blockLocationSwitching' option is false for the order item, filter out the current pickup distribution (default behavior).
                : dist.id === pickup.distribution.id, // Else only show distributions at the current distribution.
          )
          .map((dist) => ({ date: getNearbyDate(dist, changeWindow, prod), dist })) // get nearby date for each distro within a week
          .filter((dist): dist is { date: DateTime; dist: Distribution } => !!dist.date) //exclude dist with no nearby date
          .map(({ date, dist }) => ({ date: mapPickupExceptions(dist.schedule.exceptions)(date), dist })) //get date after applying exceptions if needed
          .filter((dist): dist is { date: DateTime; dist: Distribution } => !!dist.date) //exclude dist with no date after applying exceptions if needed
          .filter(
            (dist): dist is { date: DateTime; dist: Distribution } =>
              !!getPickups(dist.dist, prod, {
                excludeClosedDistros: !isAdmin,
                ignoreDisableBuyInFuture: isAdmin,
                ignoreOrderCutoffWindow: isAdmin,
              }).find((date) => isSameDay(date, dist.date, dist.dist.location.timezone)),
          ) //check if the nearby date is a valid and available date for the distro and product
          .sort(sortByEarliest('date'))
          .map(({ date, dist }) => ({
            label: `${isAdmin && dist.closed ? '[CLOSED] ' : ''}${makeLabel(
              date,
              dist.schedule.hours,
              dist.location.name,
              dist.location.type,
            )}`,
            value: dist.id,
          })),
      )

      //For shares, the options should be returned first because the options offered are pickup dates of different distros in same week.
      if (isShare(prod)) return shareOptions

      const currentDistro = prod.distributions.find(({ id }) => id === pickup.distribution.id)
      if (!currentDistro) return shareOptions

      const standPickups = getPickups(currentDistro, prod, {
        //Admin should be able see the closed distros and reschedule to them as an option if they need
        excludeClosedDistros: !isAdmin,
        ignoreDisableBuyInFuture: isAdmin,
        ignoreOrderCutoffWindow: isAdmin,
      })

      //For standard, options offered are (pickup dates of different distros in same week) and  (any pickup date of same distro) . So dropdown values are formatted date strings or distroIds
      const standardOptions = standPickups
        .map((d) => d.setZone(currentDistro.location.timezone))
        .filter((d) => d.startOf('day') !== pickup.date.setZone(currentDistro.location.timezone).startOf('day'))
        .filter((date) => {
          // should only offer as reschedule options those pickup dates that are before the changeWindow
          // Admins should not be bound by the changeWindow constraint
          return !isAfterCSAChangeWindow(
            date,
            pickup.distribution.hours.startTime,
            isAdmin,
            pickupItem.csaChangeOptions?.changeWindow,
          )
        })
        .map((date) => ({
          label: `${isAdmin && currentDistro.closed ? '[CLOSED] ' : ''}${makeLabel(
            date,
            currentDistro.schedule.hours,
            currentDistro.location.name,
            currentDistro.location.type,
          )}`,
          value: date.toISODate(),
        }))

      return [...shareOptions, ...standardOptions]
    },
    [pickup, getNearbyDate, isAdmin, isWholesale],
  )
}

const makeLabel = (date: DateTime, hours: Hours, name: string, locationType: Location['type']): string => {
  const pickupTimeText = formatPickupTime(hours, locationType)

  return `${format(date, 'iii, MMM do')}${pickupTimeText ? ` ${pickupTimeText}` : ''} - ${name}`
}

/**
 * @param dist a distribution of the product @type {Distribution}
 * @param changeWindow a CSA change window @type {CSA['changeWindow']}
 * @param prod a product from a pickup item
 */
type GetNewPickupDate = (d: Distribution, changeWindow: number, p: PhysicalProduct) => DateTime | null

/** Returns a handler used to get a new pickup date that may be offered as a reschedule option. It works by getting the nearest pickup date from one of the product's distributions. It is intended for the case where we only allow a pickup item to be rescheduled to a nearby date on another schedule. (Shares). */
const useGetNearbyDate = (pickup: Pickup, isAdmin: boolean): GetNewPickupDate => {
  const { isWholesale } = useSelector(wholesaleSelector)
  return useCallback(
    (dist: Distribution, changeWindow: CSA['changeWindow'], prod: PhysicalProduct): DateTime | null => {
      let pickupDate
      if (dist.schedule.dayOfWeek < pickup.date.weekday) {
        // Earlier in the week, use last distribution
        pickupDate = getLastDay(dist.schedule.dayOfWeek, pickup.date)
      } else if (dist.schedule.dayOfWeek > pickup.date.weekday) {
        // Later in the week, use next distribution
        pickupDate = getNextDay(dist.schedule.dayOfWeek, pickup.date)
      } else {
        // Same day, go back one day then get next
        pickupDate = getNextDay(dist.schedule.dayOfWeek, pickup.date.minus({ days: 1 }))
      }

      // For admins, allow changes until the start of the pickup window on the same day
      // For non-admins, allow until the change-cutoff day for the pickup date
      if (isAfterCSAChangeWindow(pickup.date, pickup.distribution.hours.startTime, isAdmin, changeWindow)) return null

      // If the new pickup date is outside the product availability don't allow.
      // This should use the most recent product because rescheduling options should reflect up-to-date product data
      const avail = getProductAvailability(prod, dist, {
        excludeClosedDistros: !isAdmin,
        isWholesale,
      })

      if (!avail || !isWithinInterval(avail, pickupDate)) return null

      //Should check exception date and get new available date
      pickupDate = mapPickupExceptions(dist.schedule.exceptions)(pickupDate)

      return pickupDate ?? null
    },
    [pickup, isAdmin, isWholesale],
  )
}

/**
 * @param index is the index of a Detail object from the `details` state array.
 * @param dropdownValue is the `value` property of the Detail item
 * @param isShare whether the Detail item represents a share product
 */
type OnSelect = (index: number, dropdownValue: ArrElement<PickerProps['items']>['value'], isShare: boolean) => void

/** Returns a handler for when a selection is made in the reschedule dropdown */
const useOnSelect = (setDetails: Dispatch<SetStateAction<Details[]>>, pickup: Pickup): OnSelect =>
  useCallback(
    //If the dropdownValue is the same as the original pickup value, the `rescheduleValue` should again become undefined, because it means there's no rescheduling taking place for the item
    (index: number, dropdownValue: ArrElement<PickerProps['items']>['value'], isShare: boolean) => {
      setDetails((prev) =>
        prev.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              rescheduleValue:
                isShare && dropdownValue !== pickup.distribution.id
                  ? dropdownValue
                  : !isShare && dropdownValue !== pickup.date.toISODate()
                  ? dropdownValue
                  : undefined, //This should be undefined because it means there's no change against the initial pickup data. This will happen if the user changes their mind and selects the original value again.
            }
          }
          return item
        }),
      )
    },
    [setDetails, pickup],
  )

/** Returns a handler for submitting the reschedule request */
const useReschedule = (
  itemDetails: Details[],
  pickup: Pickup,
  getNearbyDate: GetNewPickupDate,
  setLoading: Dispatch<SetStateAction<boolean>>,
  onSuccess?: () => any,
) =>
  useCallback(async () => {
    try {
      setLoading(true)
      const changeItems = itemDetails.filter(
        (item): item is Details & { rescheduleValue: string } => item.rescheduleValue !== undefined,
      )
      const changeList = changeItems.map((item) => item.pickupItem.product.name).join(', ')

      const reqObj: RescheduleRequestData = {
        pickId: pickup.id,
        distId: pickup.distribution.id,
        userId: pickup.user.id,
        items: [],
      }

      reqObj.items = changeItems.map(({ product, distros, rescheduleValue, pickupItem }) => {
        //For shares, the rescheduledValue is the new distroId.
        //For standard products, if rescheduledValue can be found in the distros array, it means the pickup is scheduled to different distro.
        //For standard products, if rescheduledValue can't be found in the distros array, it means the pickup is scheduled to the same distro but a different date, so we keep the same distroId for standard prods
        const newDistroId = isShare(product)
          ? rescheduleValue
          : distros.find(({ id }) => id === rescheduleValue)
          ? rescheduleValue
          : pickup.distribution!.id

        const newDist = distros.find(({ id }) => id === newDistroId)!

        const pickupDate = getNearbyDate(newDist, pickupItem.csaChangeOptions?.changeWindow ?? 1, product)

        if (!pickupDate) throw new Error('Invalid Pickup Date')

        return {
          oldItm: pickupItem,
          newItm: {
            date: pickupDate,
            dist: newDist,
          },
        }
      })

      // Update the pickups in the database
      await reschedulePickup({ data: [reqObj], type: 'reschedulePickup' })
      setLoading(false)
      onSuccess?.()
      Alert(
        'Items Rescheduled',
        `You have successfully rescheduled ${changeList}. It may take a few minutes for the changes to show up.`,
      )
    } catch (err) {
      setLoading(false)
      Alert(
        'Failed to Reschedule',
        `One or more items were not rescheduled, please try again or contact support if the issue persists.`,
      )
    }
  }, [itemDetails, pickup, getNearbyDate, setLoading, onSuccess])

const getErrorMessage = (
  pickup: Pickup,
  pickupItem: PickupItem,
  isAdmin: boolean,
  dropDownItems: PickerProps['items'],
): string | undefined => {
  if (isPickupItemCancelled(pickupItem)) return 'This item has been cancelled'
  else if (isPickupItemChangeOptionBlocked('blockRescheduling', pickupItem)) return 'Rescheduling not available'
  else if (
    isAfterCSAChangeWindow(
      pickup.date,
      pickup.distribution.hours.startTime,
      isAdmin,
      pickupItem.csaChangeOptions?.changeWindow,
    )
  )
    return 'The change window has already passed'
  else if (dropDownItems.length === 1) return 'Sorry, rescheduling is not available for this item.'
}

/** Returns a string with the maximum date in which this pickup item can be rescheduled */
const getDeadlineText = (pickup: Pickup, pickupItem: PickupItem, isAdmin: boolean | undefined): string => {
  const cutoffDate = getCsaChangeDeadlineFromPickup(
    pickup.date,
    isAdmin ? 0 : pickupItem.csaChangeOptions?.changeWindow,
    pickup.distribution.hours.startTime,
  )
  return 'Rescheduling deadline: ' + formatShortDate(cutoffDate)
}

/** Fx that fetches the product and distro data, and sets the details data which contains the rescheduling options for each pickup item */
const useLoadData = (
  setDetails: Dispatch<SetStateAction<Details[]>>,
  setLoading: Dispatch<SetStateAction<boolean>>,
  getRescheduleOptions: GetRescheduleOpts,
  pickup: Pickup,
  isAdmin: boolean,
) => {
  useCancelableDeepFocusFx(
    async (isCurrent) => {
      setLoading(true)

      const activePickupItems = pickup.items.filter(isPickupItemActive)

      //For each pickup item, we get the product and the full product's distributions
      const requests = activePickupItems.map(async function (pItem: PickupItem) {
        const product = await loadProduct<PhysicalProduct>(pItem.product.id) //It should be safe to assume we're getting physical products from the pickup items, because pickup items shouldn't exist for digital products in the first place. So it shouldn't be necessary to filter out digital products here.
        const distros = await loadDistributionsForProduct(product) //This extra request should not be necessary, but it is, because the server fn expects a distribution with entire distro data, and product.distributions doesn't include farm data.
        return { product, distros }
      })

      const newItems: Details[] = (await Promise.all(requests))
        .map(({ product, distros }, idx) => {
          const pItem: PickupItem = activePickupItems[idx]
          const rescheduleOptions = getRescheduleOptions(product, pItem)
          const errMessage = getErrorMessage(pickup, pItem, isAdmin, rescheduleOptions)

          return {
            pickupItem: pItem,
            product,
            distros,
            options: rescheduleOptions,
            errMessage,
          }
        })
        .filter(isNonNullish)

      if (!isCurrent) return

      setDetails(newItems)
      setLoading(false)
    },
    // It's fine to ignore `setDetails` and `setLoading`
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pickup, getRescheduleOptions, isAdmin],
  )
}

const styles = StyleSheet.create({
  card: {
    display: 'flex',
    flexDirection: 'row',
    marginVertical: 5,
    alignItems: 'center',
  },
  cardImage: {
    borderRadius: 10,
    flex: 1,
    aspectRatio: 1,
    alignSelf: 'center',
    maxWidth: 108,
  },

  descriptionText: {
    margin: 10,
  },
  textContent: {
    flex: 2,
    padding: 10,
  },
  description: {
    color: Colors.shades[300],
    fontSize: 12,
  },
  marginHorizontal20: {
    marginHorizontal: 20,
  },
  margin5: {
    margin: 5,
  },
})
