import { DateTimePickerProps, fontSize, typography } from '@elements'
import { fromJSDate, toJSDate } from '@helpers/time'
import flatpickr from 'flatpickr'
import 'flatpickr/dist/themes/airbnb.css'
import { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { StyleSheet } from 'react-native'
import { createElement } from 'react-native-web'

import Colors from '../../../constants/Colors'

const TIME_PICKER = {
  enableTime: true,
  noCalendar: true,
  dateFormat: 'h:i K',
}

const DATE_PICKER: any = {
  dateFormat: 'm/d/y',
}

const DATE_TIME_PICKER = {
  enableTime: true,
  dateFormat: 'm/d/y h:i K',
}

export const DateTimePicker = memo(function DateTimePicker({
  mode = 'date',
  containerStyle,
  value,
  onChange,
  minDate,
  maxDate,
  timezone,
  disabled,
  placeholder,
}: DateTimePickerProps) {
  const picker = useRef<any>()
  const updateSignal = useRef(0)
  const createSignal = useRef(0)

  const onClose = useCallback(
    ([selectedDate]: Date[]) => {
      // onClose is called when the user clicks outside the picker, so we need to check if there is a selected date
      if (!selectedDate) return
      // If the date has not changed don't trigger the onChange callback
      if (fromJSDate(selectedDate, timezone).toISO() === value?.toISO()) return
      // If there is no selected date dismiss without the callback
      if (!selectedDate) return

      const date = fromJSDate(selectedDate, timezone)
      onChange(date)
    },
    [value?.toMillis(), onChange, timezone],
  )

  useEffect(() => {
    const config = mode === 'date' ? DATE_PICKER : mode === 'time' ? TIME_PICKER : DATE_TIME_PICKER

    flatpickr(picker.current, {
      ...config,
      defaultDate: value ? toJSDate(value) : undefined,
      mode: 'single',
      onClose,
      // https://flatpickr.js.org/mobile-support/
      disableMobile: 'true',
      clickOpens: !disabled,

      // The ideal thing would be to define min and max here, but for some reason these values don't get passed correctly through this call. They can only be set through `flatpickr.set('minDate', value )`.
      // minDate: toJSDate(minDate),
      // maxDate: toJSDate(maxDate),
    })

    // This also doesn't work, if done in this same effect run. It can only be done afterwards in other effects
    // if (minDate) picker.current._flatpickr.set('minDate', toJSDate(minDate))
    // if (maxDate) picker.current._flatpickr.set('maxDate', toJSDate(maxDate))

    //DEBUG WARNING: The way this component is designed means if this effect runs ever again, it will re-create the component from zero. Therefore any values not specified here will be lost in future re-renders unless they trigger the subsequent effects as well.
    // For that reason, there's two scenarios, both have their pros and cons:
    // On one hand, you can make this not render again, which makes min and max dates work correctly, but the other props won't update on subsequent re-renders: onClose, mode, disabled.
    // On the other hand, you can make this fx render on prop changes, and it will rebuild the date picker correctly for those props, except for the min and max dates, so min and max will stop working after the first interaction

    // The solution at this time is to force this fx to run the next one where `set('minDate', value)` happens.
    // The updateSignal.current +=1 forces the next fx to run, to make sure minDate and maxDate are set
    updateSignal.current += 1
  }, [onClose, mode, value?.toMillis(), disabled])

  useEffect(() => {
    // Warning: These calls `toJSDate()` should not be replaced with `value.toJSDate()`, or it may produce a different day depending on the timezone and the current time
    if (value && picker.current._flatpickr) {
      picker.current._flatpickr.setDate(value ? toJSDate(value) : undefined)
    }
    if (minDate && picker.current._flatpickr) {
      picker.current._flatpickr.set('minDate', toJSDate(minDate))
    }
    if (maxDate && picker.current._flatpickr) {
      picker.current._flatpickr.set('maxDate', toJSDate(maxDate))
    }
    createSignal.current += 1
  }, [value?.toMillis(), minDate, maxDate, updateSignal.current])

  return useMemo(
    () =>
      createElement('input', {
        ref: picker,
        style: [styles.input, containerStyle],
        placeholder: placeholder || 'Select date...',
      }),

    // The createSignal ref serves to make sure this element is only created after the minDate and maxDate have been set
    // The setting of minDate and maxDate MUST be done in a separate fx from the one where where we call `flatpickr( ref )`
    // Therefore the final output requires 3 sequential steps, for all props to be passed correctly to the final component generated
    [createSignal.current, containerStyle, placeholder],
  )
})

const styles = StyleSheet.create({
  input: {
    backgroundColor: Colors.transparent,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Colors.shades['100'],
    padding: 10,
    fontFamily: typography.body.regular,
    color: Colors.shades['500'],
    fontSize: fontSize(12, 2),
    borderRadius: 8,
  },
})
