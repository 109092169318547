import { isWeb } from '@/constants/Layout'
import { withAuth } from '@/hooks/withAuth'
import { ConsumerView } from '@components'
import { StyleSheet } from 'react-native'
import { MessagesScreenContext } from './MessagesScreen.context'
import { MessagesScreenView } from './MessagesScreen.view'
import { useMessagesScreenData } from './hooks/useMessagesScreenData'

/** Screen that renders user's conversations and messages
 * - It is exported because it can be used on both admin and consumer side
 */
export function MessagesScreenComp({ adminFarmId }: { adminFarmId?: string }) {
  const value = useMessagesScreenData(adminFarmId)

  return (
    <MessagesScreenContext.Provider value={value}>
      <MessagesScreenView adminFarmId={adminFarmId} />
    </MessagesScreenContext.Provider>
  )
}

export const ConsumerMessagesScreen = withAuth(() => (
  <ConsumerView style={styles.main}>
    <MessagesScreenComp />
  </ConsumerView>
))

const styles = StyleSheet.create({
  main: {
    // Overrides default value of ConsumerView (950). Do not set max width on tablets
    maxWidth: isWeb ? 1200 : undefined,
    paddingVertical: 20,
  },
})
