import { useSnapshot } from '@/hooks/useApiFx'
import { chatMessageListener, conversationListener } from '@api/Messaging'
import { Farm } from '@models/Farm'
import { ChatSession } from '@models/Messaging'
import { User } from '@models/User'
import { useState } from 'react'

type UseFetchMessagesProps = {
  userId: User['id']
  farmId?: Farm['id']
  chatSessionId?: ChatSession['id']
}

/** Data layer hook that is handling the fetching of conversations and messages*/
export function useFetchMessages({ userId, chatSessionId, farmId }: UseFetchMessagesProps) {
  const [searchValue, setSearchValue] = useState('')
  const chatsSnap = useSnapshot(conversationListener, [userId, false, searchValue, farmId], !!userId)

  const messagesSnap = useSnapshot(chatMessageListener, [chatSessionId!], !!chatSessionId)

  return {
    chatsSnap,
    messagesSnap,
    onSearchValueChange: setSearchValue,
  }
}
