import { Icon, Text, Touchable } from '@elements'
import { PaginationRenderState } from 'instantsearch.js/es/connectors/pagination/connectPagination'
import { memo, useCallback } from 'react'
import { TouchableWithoutFeedbackProps, View } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import { FontAwesome5IconName } from '../../../components/elements/Icon/iconNames'

import Colors from '@/constants/Colors'

/** This is the return type from algolia hook usePagination */
type Props = PaginationRenderState

export const Pagination = memo(function Pagination({
  pages: pageIndices,
  /** currPage starts from number 0, so currentRefinement of first page is 0 */
  currentRefinement: currPage,
  refine: goPage,
  nbPages,
}: Props) {
  const styles = responsiveStyle()
  const disableLeftArrow = currPage === 0
  const disableRightArrow = currPage === nbPages - 1

  const deltaRefine = useCallback(
    (number: number) => {
      // Check if incrementing the current page by 'number' stays within a valid page range
      if (currPage + number >= 0 && currPage + number <= nbPages - 1) goPage(currPage + number)
    },
    [currPage, goPage, nbPages],
  )

  // Hide the component if there's only one page or lower
  if (nbPages <= 1) return null

  return (
    <View style={styles.container}>
      <PageButton iconName="chevron-left" disabled={disableLeftArrow} onPress={() => deltaRefine(-1)} />
      {pageIndices.map((pgIx, index) => (
        <PageButton key={`${pgIx}_${index}`} pgIx={pgIx} onPress={() => goPage(pgIx)} selected={currPage === pgIx} />
      ))}
      <PageButton iconName="chevron-right" disabled={disableRightArrow} onPress={() => deltaRefine(1)} />
    </View>
  )
})

function PageButton({
  iconName,
  pgIx,
  selected,
  ...rest
}: TouchableWithoutFeedbackProps & { iconName?: FontAwesome5IconName; pgIx?: number; selected?: boolean }) {
  const styles = responsiveStyle()
  const pgNum = typeof pgIx === 'number' ? pgIx + 1 : undefined

  return (
    <Touchable style={[styles.button, selected && styles.btnSelected]} {...rest}>
      {!!iconName && <Icon disabled={rest.disabled} name={iconName} />}
      {typeof pgNum === 'number' && (
        <Text color={selected ? Colors.white : undefined} type="bold">
          {pgNum}
        </Text>
      )}
    </Touchable>
  )
}

const responsiveStyle = CreateResponsiveStyle(
  {
    container: {
      alignSelf: 'center',
      flexDirection: 'row',
    },
    button: {
      width: 50,
      height: 50,
      borderRadius: 5,
      alignItems: 'center',
      justifyContent: 'center',
    },
    btnSelected: {
      backgroundColor: Colors.green,
    },
  },
  {
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      button: {
        width: 35,
        height: 35,
      },
    },
  },
)
