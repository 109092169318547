import { Alert } from '@elements'
import { CartItem } from '@models/Order'
import { memo, useContext } from 'react'
import { OrderSummaryItem_Reusable } from '../OrderCreatorScreen/components/OrderCreatorSummary/OrderSummaryItem-reusable'
import { OrderEditContext } from './useOrderEditData'

/** Adapter for order edit to use the reusable component for order summary item */
export const OrderSummaryItem = memo(function OrderSummaryItem({ itemId }: { itemId: CartItem['id'] }) {
  const {
    draftOrderFx: { data: draft },
    farmFx: { data: farm },
  } = useContext(OrderEditContext)

  if (!draft || !farm || draft.farm.id !== farm.id) return null

  return (
    <OrderSummaryItem_Reusable
      cartServiceType="orderEdit"
      farm={farm}
      isWholesale={draft.isWholesale}
      itemId={itemId}
    />
  )
})

/** Alert to show when the user is prevented from removing the last item in the cart */
export function cantRemoveLastItemAlert() {
  return Alert('Empty draft order', "A draft order can't have no items. Consider canceling the order instead.")
}
