import { Rating } from '@/components'
import Colors from '@/constants/Colors'
import { HeaderText, Icon } from '@elements'
import { Farm, FarmStatus } from '@models/Farm'
import React, { memo } from 'react'
import { StyleSheet, View } from 'react-native'

export const FarmStats = memo(function FarmStats({
  farm,
  isStarred,
  numFavorites,
  onIconPress,
}: {
  farm?: Farm
  isStarred?: boolean
  numFavorites: number
  onIconPress: () => void
}) {
  if (!farm) return null
  return (
    <View style={styles.farmStatsCont}>
      {farm.status === FarmStatus.Registered && <Rating ratings={farm.reviews} />}
      <Icon
        name="heart"
        color={isStarred ? Colors.red : Colors.lightGray}
        size={16}
        noHover
        solid
        onPress={onIconPress}
      />
      <HeaderText color={Colors.shades[300]} style={styles.numFavTxt} size={12}>
        ({numFavorites})
      </HeaderText>
    </View>
  )
})

const styles = StyleSheet.create({
  numFavTxt: {
    marginLeft: 5,
    marginRight: 15,
  },
  farmStatsCont: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 20,
  },
})
