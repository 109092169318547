import { Divider, Skeleton } from '@elements'
import { memo } from 'react'
import { View } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize, minSize } from 'rn-responsive-styles'

import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { useLayout } from '@/hooks/useLayout'

export const FarmHeaderSkeleton = memo(function FarmHeaderSkeleton() {
  const styles = responsiveStyle()
  const layout = useLayout()
  return (
    <View style={styles.container}>
      <View style={styles.carouselCont}>
        <Skeleton style={styles.carousel} />
      </View>
      <View style={styles.descriptionCont}>
        <View>
          <Skeleton width={240} height={36} style={globalStyles.margin10} />
          <Skeleton height={20} width={240} style={globalStyles.margin10} />

          <View style={styles.farmInfo}>
            <Skeleton width={110} style={globalStyles.margin10} />
            <Skeleton width={110} style={globalStyles.margin10} />
          </View>
        </View>
        <View style={globalStyles.flex1} />
        <Skeleton style={styles.margin5} />
        <Skeleton style={styles.margin5} />
        {!layout.isSmallDevice && (
          <>
            <Skeleton style={styles.margin5} />
            <Skeleton style={styles.margin5} />
            <Skeleton style={styles.margin5} />
            <Divider clear />
          </>
        )}
      </View>
    </View>
  )
})

const responsiveStyle = CreateResponsiveStyle(
  {
    carousel: {
      flex: 1,
      borderRadius: 10,
    },
    farmInfo: {
      flexDirection: 'row',
    },
    container: {
      backgroundColor: Colors.lightGreen,
      flexDirection: 'row',
    },
    margin5: {
      margin: 5,
    },
    descriptionCont: {
      marginHorizontal: 20,
      flex: 1,
    },
    carouselCont: {
      margin: 10,
      width: '95%',
      aspectRatio: 3 / 2,
    },
    radius10: {
      borderRadius: 10,
    },
  },
  {
    [maxSize(DEVICE_SIZES.SM)]: {
      container: {
        flexDirection: 'column',
      },
      descriptionCont: {
        marginVertical: 10,
        marginHorizontal: 10,
      },
    },
    [minSize(DEVICE_SIZES.MD)]: {
      carouselCont: {
        width: 420,
        height: 320,
        margin: 0,
      },
    },
  },
)
