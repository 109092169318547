import * as React from 'react'
import { StyleSheet, View } from 'react-native'

import Colors from '../../../constants/Colors'

export function Card({ children }: React.PropsWithChildren<Record<never, any>>): JSX.Element {
  return <View style={styles.card}>{children}</View>
}

const styles = StyleSheet.create({
  card: {
    flex: 1,
    margin: 20,
    padding: 20,
    borderWidth: 1,
    borderColor: Colors.shades[100],
    borderRadius: 10,
    alignItems: 'center',
    shadowColor: Colors.shades[75],
    shadowRadius: 5,
  },
})
