import { StatCard } from '@/admin/components/Analytics/StatCard'
import { DashboardSummaryResult } from '@/admin/components/Analytics/types'
import { AnalyticsPeriodInfoRecord } from '@/admin/components/Analytics/util'
import { useDeviceSize } from '@/hooks/useLayout'
import { ToolTips } from '@components'
import { formatMoney } from '@helpers/display'
import { DatePeriods } from '@helpers/time'

type Props = {
  newOrders?: DashboardSummaryResult['newOrders']
  revenue?: DashboardSummaryResult['revenue']
  shopViews?: DashboardSummaryResult['shopViews']
  period: DatePeriods
  loading: boolean
  hasAccessAccounting: boolean
}

/** UI cards that expose farm summary data */
export function SummaryStatsCards({ newOrders, period, loading, revenue, shopViews, hasAccessAccounting }: Props) {
  const { isExtraSmallDevice } = useDeviceSize()
  return (
    <>
      <StatCard
        title="New Orders"
        value={newOrders?.orders}
        growth={
          newOrders
            ? {
                value: newOrders?.growth,
                period: AnalyticsPeriodInfoRecord[period].pastLabel,
              }
            : undefined
        }
        loading={loading}
      />
      <StatCard title="Shop Views" value={shopViews} loading={loading} />
      {hasAccessAccounting && (
        <StatCard
          loadingText="$-.--"
          title="Gross Revenue"
          toolTip={isExtraSmallDevice ? undefined : ToolTips.DASHBOARD_GROSS_REVENUE}
          value={revenue?.revenue ? formatMoney(revenue.revenue) : undefined}
          growth={
            revenue
              ? {
                  value: revenue.growth,
                  period: AnalyticsPeriodInfoRecord[period].pastLabel,
                }
              : undefined
          }
          loading={loading}
        />
      )}
    </>
  )
}
