import { User } from '@models/User'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { userSelector } from '../redux/selectors'
import { withAuth } from './withAuth'

/**
 * This component will add auth protection to any component where this is rendered, without having to wrap it inside the withAuth HOC.
 * - Use this when you want the UI to be covered by the login component on an overlay, and you want a callback to be invoked on login success.
 * - A callback passed to this component will run if the user is signed in; else, the withAuth modal will show, asking the user to sign in.
 * - Add this component anywhere in your render method and include your function as prop `callback`
 */
export const WithAuthCallback = withAuth(
  function WithAuthCallback({ callback }: { callback: (user: User) => void }) {
    const user = useSelector(userSelector)

    useEffect(() => {
      if (user.id) callback(user)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.id])
    return <></>
  },
  { useOverlay: true },
)
