import { Skeleton } from '@elements'
import { memo, useMemo } from 'react'
import { StyleSheet, View } from 'react-native'

import { useLayout } from '@/hooks/useLayout'

export const NearbyProdsGroupSkeleton = memo(function NearbyProdsGroupSkeleton() {
  const layout = useLayout()

  // The number of product skeletons that will show near the farm skeleton
  const prodsNo = useMemo(() => {
    if (layout.isExtraSmallDevice) return 2
    if (layout.isSmallDevice) return 3
    return Math.floor(layout.width / 300)
  }, [layout])

  if (layout.isSmallDevice) {
    return (
      <View>
        <View style={styles.containerSmall}>
          <Skeleton style={styles.farmSkeleton} />
        </View>
        <View style={styles.containerSmall}>
          {new Array(Math.floor(prodsNo)).fill(null).map((_, idx) => (
            <Skeleton key={`skeleton_${idx}`} style={styles.layoutSmall} />
          ))}
        </View>
      </View>
    )
  }
  return (
    <View style={styles.wrapper}>
      <Skeleton style={styles.farmSkeleton} />

      <View style={styles.container}>
        {new Array(Math.floor(prodsNo)).fill(null).map((_, idx) => (
          <Skeleton key={`skeleton_${idx}`} style={styles.layout} />
        ))}
      </View>
    </View>
  )
})
const styles = StyleSheet.create({
  containerSmall: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  wrapper: {
    flexDirection: 'row',
  },
  farmSkeleton: {
    width: 230,
    height: 120,
    borderRadius: 10,
    marginHorizontal: 20,
    marginVertical: 20,
  },
  layoutSmall: {
    width: 160,
    height: 160,
    borderRadius: 10,
    marginHorizontal: 5,
    marginVertical: 10,
  },

  layout: {
    height: 160,
    width: 160,
    borderRadius: 10,
    marginHorizontal: 20,
    marginVertical: 20,
  },
})
