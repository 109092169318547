import { Platform, TextInputProps } from 'react-native'

const FIRST_NAME: Partial<TextInputProps> = {
  textContentType: 'givenName',
  autoComplete: 'name',
}

const LAST_NAME: Partial<TextInputProps> = {
  textContentType: 'familyName',
  autoComplete: 'name',
}

const PHONE_NUMBER: Partial<TextInputProps> = {
  textContentType: 'telephoneNumber',
  autoComplete: 'tel',
  keyboardType: 'phone-pad',
}

const EMAIL_ADDRESS: Partial<TextInputProps> = {
  autoCapitalize: 'none',
  textContentType: 'emailAddress',
  autoComplete: 'email',
  keyboardType: 'email-address',
}

//TODO: Add address

const getAutoComplete = (itm: Partial<TextInputProps>) => {
  if (Platform.OS !== 'android') delete itm.autoComplete
  return itm
}

const InputAutocomplete = {
  FIRST_NAME: getAutoComplete(FIRST_NAME),
  LAST_NAME: getAutoComplete(LAST_NAME),
  PHONE_NUMBER: getAutoComplete(PHONE_NUMBER),
  EMAIL_ADDRESS: getAutoComplete(EMAIL_ADDRESS),
}

export default InputAutocomplete
