import { KeyedState } from '@/hooks/useKeyedState'
import { DraftOrder } from '@models/DraftOrder'
import { OrderCreatorStateProductsFilters } from '../OrderCreatorScreen/OrderCreatorScreen.helper'
import { useCsasReusable } from '../OrderCreatorScreen/useCsas-reusable'

/** Data layer for state related to CSAs in order edit */
export function useCsas({
  draft,
  keyedState,
}: {
  draft: DraftOrder | undefined
  keyedState: KeyedState<OrderCreatorStateProductsFilters>
}) {
  return useCsasReusable({
    farmId: draft?.farm.id || '',
    orderType: 'standard',
    keyedState,
  })
}
