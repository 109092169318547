import React from 'react'
import { Image, View } from 'react-native'
import { farmerOnboardMiscellaneousAssets } from '@/assets/images/farmer_onboard'
import Colors from '@/constants/Colors'
import { SectionContainer } from '@screens/FarmerOnboard/components'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { HeaderText, Text } from '@elements'

/**
 * `CoopBenefitsSection` is a component that showcases the benefits of being part of a cooperative (Co-op).
 * It consists of an icon with text and a details section. The icon with text visually represents the cooperative nature
 * of the organization, while the details section provides information on financial equity, voting rights, and the process
 * of becoming a member-owner in the Co-op.
 */
export function CoopBenefitsSection() {
  const styles = useSectionCoopBenefitsStyles()
  return (
    <SectionContainer outerContainerStyle={styles.outerContainer} divider>
      <SectionImageWithText />
      <SectionDetails />
    </SectionContainer>
  )
}
const useSectionCoopBenefitsStyles = () =>
  useSizeFnStyles(({ isSmallDevice, isMedDevice }) => ({
    outerContainer: { paddingVertical: isSmallDevice ? 1 : 34 },
  }))
function SectionImageWithText() {
  const sectionImageWithTextStyles = useSectionImageWithTextStyles()

  return (
    <View>
      <HeaderText size={42} style={sectionImageWithTextStyles.text}>
        We're a Co-op
      </HeaderText>
      <Image source={farmerOnboardMiscellaneousAssets.manRaisingHands} style={sectionImageWithTextStyles.icon} />
    </View>
  )
}

const useSectionImageWithTextStyles = () =>
  useSizeFnStyles(({ isSmallDevice, isMedDevice }) => ({
    icon: {
      width: isSmallDevice ? 200 : isMedDevice ? 300 : 400,
      aspectRatio: 1,
    },
    text: {
      color: Colors.darkGreen,
      fontStyle: 'italic',
      textAlign: 'center',
    },
  }))

function SectionDetails() {
  const sectionDetailsStyles = useSectionDetailsStyles()

  return (
    <View>
      <View style={sectionDetailsStyles.detail}>
        <HeaderText size={25} style={sectionDetailsStyles.title}>
          Financial Equity
        </HeaderText>
        <Text size={13} style={sectionDetailsStyles.description}>
          Member-owners receive payments based on sales volume from the co-op when the board decides to distribute
          dividends.
        </Text>
      </View>
      <View style={sectionDetailsStyles.detail}>
        <HeaderText size={25} style={sectionDetailsStyles.title}>
          Voting
        </HeaderText>
        <Text size={13} style={sectionDetailsStyles.description}>
          Member-owners can vote for and run for the co-op board, influencing direction and decisions in our co-op.
        </Text>
      </View>
      <View style={sectionDetailsStyles.detail}>
        <HeaderText size={25} style={sectionDetailsStyles.title}>
          Become a Member-owner
        </HeaderText>
        <Text size={13} style={sectionDetailsStyles.description}>
          Any seller on the site is eligible to join the co-op for $250. One time payment or payment plan that works for
          your farm.
        </Text>
      </View>
    </View>
  )
}

const useSectionDetailsStyles = () =>
  useSizeFnStyles(({ isSmallDevice, isMedDevice }) => ({
    detail: {
      marginBottom: 15,
    },
    title: {
      marginBottom: 7,
    },
    description: {
      maxWidth: isSmallDevice ? 300 : isMedDevice ? 350 : 450,
      lineHeight: 29,
    },
  }))
