import InputLabel from '@/admin/components/InputLabel'
import { Logger } from '@/config/logger'
import { globalStyles } from '@/constants/Styles'
import useKeyedState from '@/hooks/useKeyedState'
import { adminFarmSelector } from '@/redux/selectors'
import { DateRangePicker } from '@components'
import { ErrorText, FormButton, FormPickerInput, KeyboardAvoidingScrollView, hideModal } from '@elements'
import { errorToString } from '@helpers/helpers'
import { ProductFeeFilters, openProductFeesCSV } from '@helpers/links'
import { DatePeriods, convertPeriodToRange } from '@helpers/time'
import { FeeType } from '@models/ProductFee'
import { DateRange } from '@models/Schedule'
import { memo, useCallback } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

const pickerItems = [
  { label: 'All taxes and flat fees', value: '' },
  { label: 'Taxes', value: FeeType.Tax },
  { label: 'Flat fees', value: FeeType.Fee },
]

export const ProductFeeReportPopup = memo(function ProductFeeReportPopup() {
  const farm = useSelector(adminFarmSelector)
  const [{ period, range, error, reportType }, set, setState] = useKeyedState<{
    period: DatePeriods
    range: Partial<DateRange>
    error: string
    reportType: string
  }>({
    period: DatePeriods.This_Year,
    range: convertPeriodToRange(DatePeriods.This_Year, farm.timezone)!,
    error: '',
    reportType: '',
  })

  /** The helper to handle date picker onChange */
  const onRangePickerUpdate = useCallback(
    (period: DatePeriods, range: Partial<DateRange>) => {
      setState({ period, range, error: '', reportType })
    },
    [reportType, setState],
  )

  /** submit handler to generate report. */
  const onSubmitHandler = useCallback(async () => {
    if (error) set('error', '')
    if (!farm.id) return set('error', 'Could not load your farm, please reload and try again.')
    if (!range.startDate || !range.endDate) return set('error', 'Please select a date range.')

    try {
      const filters: ProductFeeFilters = {
        feeType: !reportType ? undefined : (reportType as FeeType),
        dateRange: {
          startDate: range.startDate,
          endDate: range.endDate,
        },
      }

      openProductFeesCSV(farm.id, filters)
      hideModal()
    } catch (e) {
      Logger.error(errorToString(e))
      set('error', 'Unable to generate the report: Please try again later. If the problem persists, contact support.')
    }
  }, [error, set, farm.id, range.startDate, range.endDate, reportType])

  return (
    <KeyboardAvoidingScrollView>
      <FormPickerInput
        placeholder={null}
        items={pickerItems}
        value={reportType}
        onValueChange={(value) => set('reportType', value)}
        useWebNativePicker
        label={<InputLabel label="Select tax or flat fee" />}
      />
      <View style={globalStyles.marginHorizontal10}>
        <InputLabel label="Select date range" style={globalStyles.marginVertical10} />
        <DateRangePicker timezone={farm.timezone} range={range} period={period} onUpdate={onRangePickerUpdate} />
      </View>

      <FormButton title="Download Report" onPress={onSubmitHandler} style={styles.formButton} />
      {!!error && <ErrorText>{error}</ErrorText>}
    </KeyboardAvoidingScrollView>
  )
})

const styles = StyleSheet.create({
  datePickerCon: {
    marginHorizontal: 20,
  },
  formButton: {
    marginTop: 45,
    alignSelf: 'flex-end',
  },
})
