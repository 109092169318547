import { limit, onSnapshot } from 'firebase/firestore'

import { PartialExcept } from '@helpers/typescript'
import { FarmAssociation } from '@models/User'
import { loadFarm } from './Farms'
import { unmarshalFarmAssociation } from './encoding/FarmAssociation'
import { farmAssociationsCollection } from './framework/ClientCollections'
import { callEndpoint } from './v2'

// snapshotFarmAssociationsWithFarms passes all the farm associations associated with the supplied user ID to the
// supplied callback. It also loads the farms that are related to the association.
// TODO: Consider denormalizing the farm load.

export function snapshotFarmAssociationsWithFarms(
  userId: string,
  callback: (farmAssociations: FarmAssociation[]) => void,
  limitN = 20,
): () => void {
  const docs = farmAssociationsCollection.resolve(userId).query(limit(limitN))

  return onSnapshot(docs, async (snapshot) => {
    if (snapshot.empty) {
      callback([])
    }
    const farmAssociations = await Promise.all(
      snapshot.docs.map(async (doc) => {
        const farmAssociation = unmarshalFarmAssociation(doc.id, doc.data())
        farmAssociation.farm = await loadFarm(doc.id)
        return farmAssociation
      }),
    )
    callback(farmAssociations)
  })
}

// loadFarmAssociation loads the farm association for the specified user/farm.

export async function loadFarmAssociation(userId: string, farmId: string): Promise<FarmAssociation> {
  return farmAssociationsCollection.resolve(userId).fetch(farmId)
}

// updateFarmAssociation updates an existing farm association for a user.

export async function updateFarmAssociation(
  farmAssociation: PartialExcept<FarmAssociation, 'id'>,
  userId: string,
): Promise<void> {
  return farmAssociationsCollection.resolve(userId).update(farmAssociation)
}

// toggleFarmAssociationFavorite toggles the favorite status of a farm for the logged in user.

export async function toggleFarmAssociationFavorite(farmId: string, isFavorite: boolean): Promise<void> {
  if (isFavorite) {
    await callEndpoint('v2.FarmAssociation.farmAssociationUnfavoriteService', { farmId })
  } else {
    await callEndpoint('v2.FarmAssociation.farmAssociationFavoriteService', { farmId })
  }
}

// update farmAssociations notes

export async function updateFarmAssociationNote(farmId: string, userId: string, note: string): Promise<void> {
  await callEndpoint('v2.FarmAssociation.farmAssociationNoteService', { farmId, userId, note })
}

/** update farmAssociation role for existing admin or add new farmAssociation role for new admin */
export async function writeFarmAssociationRole(
  farmId: string | undefined,
  userId: string,
  role: FarmAssociation['role'],
  type: 'change' | 'delete' | 'create',
): Promise<void> {
  await callEndpoint('v2.FarmAssociation.farmAssociationRoleService', {
    farmId,
    userId,
    role,
    type,
  })
}
