import { Column, ExpandableRow } from '@/admin/components/OfflineTable/ExpandableRow'
import { AdminDrawerParamList } from '@/admin/navigation/types'
import { Logger } from '@/config/logger'
import Colors from '@/constants/Colors'
import { canTemplateProductBeDeleted, deleteTemplateProduct } from '@api/TemplateProduct'
import { Image } from '@components'
import { Alert, Spinner, Text, Toast, Touchable } from '@elements'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { TemplateProduct } from '@models/Product'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { useNavigation } from '@react-navigation/native'
import React, { memo, useCallback, useState } from 'react'
import { StyleSheet, View } from 'react-native'

type RowProps = {
  templateProduct: TemplateProduct
  refreshFn: () => void
  index: number
}

export const ShareTemplateRow = memo(function ShareTemplateRow({ refreshFn, templateProduct, index }: RowProps) {
  const [loading, setLoading] = useState(false)
  const navigation = useNavigation<DrawerNavigationProp<AdminDrawerParamList, 'Products'>>()
  const handleEditPress = useCallback(() => {
    navigation.navigate('Products', {
      screen: 'EditTemplate',
      params: {
        templateId: templateProduct.id,
        goBack: 'ShareTemplates',
      },
    })
  }, [templateProduct, navigation])

  const handleDeletePress = useCallback(async () => {
    Alert('Delete Template', 'Are you sure you want to delete this Template?', [
      {
        text: 'Delete',
        onPress: async () => {
          try {
            setLoading(true)
            // First check if the template product can be deleted
            const canDelete = await canTemplateProductBeDeleted(templateProduct.id)
            if (canDelete) {
              // If it can be deleted, then proceed to delete
              await deleteTemplateProduct(templateProduct.id)
              refreshFn()
              Toast('Deleted template product: ' + templateProduct.name)
            } else {
              // If it cannot be deleted, show a message
              Toast('This template product cannot be deleted as it is associated with a product.')
            }
            setLoading(false)
          } catch (error) {
            Logger.error(error)
            Toast('Something went wrong while deleting this product')
            setLoading(false)
          }
        },
      },
      {
        text: 'Cancel',
        style: 'cancel',
      },
    ])
  }, [templateProduct, refreshFn])

  const columns: Column<TemplateProduct>[] = [
    {
      process: (p) => <Image source={{ uri: p.images[0] }} style={styles.mainImage} />,
      widthFlex: 0.25,
    },
    {
      process: () => <View style={styles.emptyView} />,
      widthFlex: 0.05,
    },
    {
      process: (p) => (
        <Text numberOfLines={2} onPress={handleEditPress}>
          {p.name}
        </Text>
      ),
      widthFlex: 2,
    },
    {
      process: (p) => p.type,
      widthFlex: 1.5,
    },
    {
      process: () =>
        loading ? (
          <Spinner />
        ) : (
          <Touchable onPress={handleEditPress} style={styles.rowBtnCont} disabled={loading}>
            <Text type="medium" color={Colors.green}>
              Edit
            </Text>
          </Touchable>
        ),
      widthFlex: 0.5,
    },
    {
      widthFlex: 0.5,
      process: () => (
        <Touchable onPress={handleDeletePress} style={styles.rowBtnCont}>
          <Text type="medium" color={Colors.green}>
            Delete
          </Text>
        </Touchable>
      ),
    },
  ]

  return (
    <ExpandableRow<TemplateProduct>
      item={templateProduct}
      index={index}
      columns={columns}
      rowContainerStyle={styles.row}
      onRowPress={handleEditPress}
    />
  )
}, propsAreDeepEqual)

const styles = StyleSheet.create({
  row: {
    alignItems: 'center',
    backgroundColor: Colors.white,
  },
  mainImage: {
    width: 50,
    height: 50,
    maxHeight: 50,
    minHeight: 50,
    maxWidth: 50,
    minWidth: 50,
    transform: [{ translateX: -10 }],
    borderRadius: 5,
    flexShrink: 0,
  },
  emptyView: {
    width: 20,
    height: 20,
  },
  rowBtnCont: {
    alignItems: 'center',
    minWidth: 18,
  },
})
