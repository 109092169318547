import { ButtonClear, LoadingView, Text } from '@elements'
import { CartItem } from '@models/Order'
import { memo, useCallback } from 'react'
import { StyleProp, StyleSheet, TouchableOpacityProps, View, ViewStyle } from 'react-native'

import { makeTestIdPrefix } from './AddCartBtn-helper'

import Colors from '@/constants/Colors'

export type StepperProps = {
  cartItem: CartItem
  /** Function to run when quantity increases/ decreases */
  updateQuantity: (delta: number) => any
  /** "disabled" Should disable the stepper "+" button when the max stock is reached */
  disableAdd?: TouchableOpacityProps['disabled']
  style?: StyleProp<ViewStyle>
  loading?: boolean
}

export const Stepper = memo(function Stepper({
  cartItem,
  updateQuantity,
  style,
  loading = false,
  disableAdd = false,
}: StepperProps) {
  const testIdPrefix = makeTestIdPrefix(cartItem.product, cartItem.unit)

  const onAdd = useCallback(() => {
    updateQuantity(1)
  }, [updateQuantity])

  const onSubstract = useCallback(() => {
    updateQuantity(-1)
  }, [updateQuantity])

  return (
    <View style={[styles.incrementBtn, style]} testID={testIdPrefix + '-stepper'} key={testIdPrefix}>
      <ButtonClear
        onPress={onSubstract}
        icon="minus-circle"
        disabled={cartItem.quantity <= 0}
        testID={testIdPrefix + '-step[-]'}
        textStyle={styles.textStyle}
      />

      <LoadingView loading={loading} switchMode>
        <Text type="medium" size={14}>
          {cartItem.quantity}
        </Text>
      </LoadingView>
      <ButtonClear
        onPress={onAdd}
        icon="plus-circle"
        disabled={disableAdd}
        testID={testIdPrefix + '-step[+]'}
        textStyle={styles.textStyle}
      />
    </View>
  )
})

const styles = StyleSheet.create({
  incrementBtn: {
    borderRadius: 10,
    borderWidth: 1,
    minHeight: 40,
    minWidth: 110,
    borderColor: Colors.green,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  textStyle: { padding: 0, paddingRight: 0 },
})
