import { memo, useCallback } from 'react'
import { Media } from '@models/shared/Media'
import { StyleSheet, View } from 'react-native'
import { ErrorText, TextH3 } from '@elements'
import Colors from '@/constants/Colors'
import { AddImages } from '@components'
import * as React from 'react'
import { useFormikContext } from 'formik'
import { CSAFormik } from './type'

export const CSAPhotoSection = memo(function PhotoSection({
  setIsNewImage,
  disabled = false,
}: {
  setIsNewImage: () => void
  disabled?: boolean
}) {
  const { setFieldValue, values, errors, touched } = useFormikContext<CSAFormik>()
  const setImages = useCallback(
    (data: Media[]) => {
      setFieldValue('images', data)
      setIsNewImage()
    },
    [setFieldValue, setIsNewImage],
  )
  const renderErrorMessage = () => {
    if (typeof errors.images === 'string') {
      return errors.images
    } else if (Array.isArray(errors.images)) {
      return errors.images.join(', ')
    }
    return ''
  }

  return (
    <View>
      <View style={stylesPhotoSection.photosHeader}>
        <TextH3 size={18} color={Colors.black}>
          Photos*
        </TextH3>
        <TextH3 size={18} color={Colors.black}>
          {values.images?.length}/5
        </TextH3>
      </View>
      {touched.images && !!errors.images && <ErrorText>{renderErrorMessage()}</ErrorText>}

      <View style={stylesPhotoSection.photoButtonWrapper}>
        <AddImages media={values.images} setMedia={setImages} maxImages={5} disabled={disabled} />
      </View>
    </View>
  )
})

const stylesPhotoSection = StyleSheet.create({
  photoButtonWrapper: {
    marginLeft: 10,
  },
  photosHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 17,
    paddingBottom: 15,
    paddingTop: 25,
    maxWidth: 400,
  },
})
