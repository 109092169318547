import { loadFarmAssociation } from '@api/FarmAssociations'
import { addUserAddress, getDefaultAddress, updateUserAddress } from '@api/UserAddress'
import { canEditCustomer, changeUser, validatePhoneNumber } from '@api/Users'
import { Button, FormButton, LoadingView, Text, TextH4, Toast, typography } from '@elements'
import { openUrl } from '@helpers/client'
import { unmarshalPhoneNumber } from '@helpers/display'
import { isValidAddress } from '@models/Address'
import { Farm } from '@models/Farm'
import { User, userName } from '@models/User'
import { UserAddress } from '@models/UserAddress'
import { Field, Formik } from 'formik'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/reducers/types'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import { AdminCustomerHeader } from '../AdminCustomerOrdersSection/components/Header'
import { AddNoteForCustomer, CustomerDetail, DetailInputField } from './helpers/CustomerDetailsCardHelper'

import { AdminCard } from '@/admin/components/AdminCard'
import { getShortState } from '@/assets/data/states'
import { Logger } from '@/config/logger'
import Colors from '@/constants/Colors'
import { useHasPermissionWithFlag } from '@/hooks/useHasPermission'
import { setAdminNav } from '@/redux/actions/adminState'
import { adminFarmSelector, adminParamsSelector, userSelector } from '@/redux/selectors'
import { onSendMessageToUserCallback } from '@components'
import { AccessRight, Permission } from '@helpers/Permission'
import { CustomerDetailFormType, customerDetailSchema } from '../helpers/customerDetailYup'

type Props = {
  /** If the stripeCust is undefined, it means it is currently being loaded from the customer details screen */
  stripeCust?: string
  /** onCustomerChanged is expected to update the state for customer(redux) and stripeCust(prop) from outside this component */
  onCustomerChanged: (custId: string) => void
}

/** Allows changing customer information and address */
export const CustomerDetailsCard = memo(function CustomerDetailsCard({ stripeCust, onCustomerChanged }: Props) {
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  /** If the customer adminParam is undefined, it means it is currently being loaded from the customer details screen */
  const { customer } = useSelector(adminParamsSelector)
  const farm = useSelector<RootState, Farm>(adminFarmSelector)
  const [isInfoEditing, setIsInfoEditing] = useState<boolean>(false)
  const [canEdit, setCanEdit] = useState<boolean>(false)
  const [isNoteEditing, setIsNoteEditing] = useState<boolean>(false)
  const [note, setNote] = useState<string>('')
  const [defaultAddress, setDefaultAddress] = useState<UserAddress>()
  const [formButtonLoading, setFormButtonLoading] = useState<boolean>(false)
  const hasAccessEditCustomer = useHasPermissionWithFlag(Permission.Orders, AccessRight.Edit)

  const styles = useStyles()

  /** Loads and sets the note from the farm association. It is intended to be called on mount and on note edit */
  const loadNote = (customerId: string, farmId: string) => {
    loadFarmAssociation(customerId, farmId)
      .then((res) => {
        if (res) {
          setNote(res.note!)
        } else setNote('')
      })
      .catch((err) => {
        Logger.debug(err)
      })
  }

  /** Will load the default address and set to state */
  const loadAddress = (customerId: string) => {
    getDefaultAddress(customerId)
      .then((res) => {
        setDefaultAddress(res)
      })
      .catch((err) => {
        Logger.debug(err)
      })
  }

  /** Loads necessary component data: Note, canEditCustomer, and default address */
  useEffect(() => {
    if (!customer) return

    loadAddress(customer.id)

    if (farm.id) {
      loadNote(customer.id, farm.id)
      canEditCustomer(farm.id, customer.id)
        .then((res) => {
          setCanEdit(res)
        })
        .catch((err) => {
          Logger.debug(err)
        })
    }
  }, [customer, farm])

  const goStripeCustomer = (customer: string) => {
    const url = `https://dashboard.stripe.com/${farm.accountRef}/customers/${customer}`
    openUrl(url)
  }

  const handleSendMessage = useCallback(() => {
    if (!customer) {
      return
    }
    onSendMessageToUserCallback(farm, user, customer)
  }, [customer, farm, user])

  /** Update customer info and default address */
  const handleSubmitAndUpdateCustomerDetails = async (values: CustomerDetailFormType) => {
    if (!customer) return // It is expected that this should have loaded by the time the form is submitted

    const { firstName, lastName, email, email2, phoneNumber, pronouns, street1, street2, city, state, zipcode, notes } =
      values

    // Check if any of the address fields are filled
    const addressWasTouched = street1 || city || state || zipcode

    try {
      const newCustomerInfo: Partial<User> = {
        name: { firstName: firstName.trim(), lastName: lastName.trim() },
        email: email.trim(),
        email2: email2?.trim(),
        phoneNumber: phoneNumber?.trim(),
        pronouns,
      }

      // initialize the newAddress with undefined. If an address was entered, will receive the new address data
      let newAddress: Omit<UserAddress, 'id' | 'coordinate'> | undefined = undefined

      // If any field was changed, validate and set the new address
      if (addressWasTouched) {
        if (!(street1 && city && state && zipcode)) {
          // Formik should prevent this from even running in this situation because of the schema tests. But anyway this is here just in case
          return Toast('All address fields must be filled.')
        }

        const stateAbbr = getShortState(state!)
        if (!stateAbbr) throw new Error('Invalid state')

        newAddress = {
          street1,
          street2,
          city,
          state: stateAbbr,
          zipcode,
          notes,
        }
      }

      setFormButtonLoading(true)

      // initialize newPhoneNumber with undefined. If they entered a phone number, will be validated and assigned to this
      let newPhoneNumber: string | undefined = undefined

      if (phoneNumber) {
        newPhoneNumber = await validatePhoneNumber(phoneNumber, customer.id, false).catch(() => {
          // This must be caught here for the execution to continue and not throw to the outer catch
          return undefined
        })
        // If validation failed, abort
        if (!newPhoneNumber) {
          Toast('The phone number you entered was invalid or is already being used.')
          setFormButtonLoading(false)
          return
        }
        // Add the new phone number to the new data if passed validation
        newCustomerInfo.phoneNumber = newPhoneNumber
      }

      //update customer info
      await changeUser(customer.id, customer, {
        ...newCustomerInfo,
      })

      if (addressWasTouched && newAddress) {
        /**
         * Handle updating the address in addresses subCollection in one of two ways:
         * - If defaultAddress exists: updateUserAddress
         * - If defaultAddress doesn't exist: addUserAddress. new address will become default
         * */
        if (defaultAddress) await updateUserAddress(customer.id, { ...defaultAddress, ...newAddress })
        else await addUserAddress(customer.id, { ...newAddress, isDefault: true })
        //call callback to load default address
        loadAddress(customer.id)
      }
      // This will update the UI immediately
      dispatch(setAdminNav({ customer: { ...customer, ...newCustomerInfo } }))
      // This should trigger a refresh of the customer and stripe customerRef from outside this component
      onCustomerChanged(customer.id)
      setIsInfoEditing(false)
      Toast(`Successfully updated customer ${!addressWasTouched ? 'details' : 'details and address'}!`)
      setFormButtonLoading(false)
    } catch (err: any) {
      Toast(`Error saving your information. ${err.message}`)
      setFormButtonLoading(false)
      Logger.error(err)
    }
  }

  const headerBtns = useMemo(() => {
    if (!isInfoEditing && hasAccessEditCustomer) {
      if (canEdit) {
        return [
          // { title: 'Send Message', onPress: handleSendMessage },
          { title: 'Edit Customer Details', onPress: () => setIsInfoEditing(true) },
        ]
      }
      return []
      // return [{ title: 'Send Message', onPress: handleSendMessage }]
    }
    return undefined
  }, [canEdit, handleSendMessage, hasAccessEditCustomer, isInfoEditing])

  return (
    <AdminCard>
      <LoadingView loading={!customer}>
        <AdminCustomerHeader title="Customer Details" btns={headerBtns} />

        {!isInfoEditing && (
          <View style={styles.wrapper}>
            <View style={styles.details}>
              {!!customer && (
                <>
                  <CustomerDetail field="Name" value={userName(customer)} pronouns={customer?.pronouns} />
                  <CustomerDetail field="Email" value={customer.email} />

                  <CustomerDetail field="Email 2" value={customer.email2} />

                  {!!customer.phoneNumber && (
                    <CustomerDetail
                      field="Phone Number"
                      value={unmarshalPhoneNumber(customer.phoneNumber || '', false)}
                    />
                  )}
                  {!!stripeCust && (
                    <View style={styles.detail}>
                      <TextH4 style={styles.field}>Stripe Account</TextH4>
                      {hasAccessEditCustomer && (
                        <View style={styles.info}>
                          <Text style={styles.hyperlink} onPress={() => goStripeCustomer(stripeCust)}>
                            {userName(customer)}
                          </Text>
                        </View>
                      )}
                    </View>
                  )}

                  <CustomerDetail field="Sign In Provider" value={customer.signInProvider} />
                </>
              )}
            </View>
            {!!customer && (
              <View style={styles.addressInfo}>
                {!!defaultAddress && isValidAddress(defaultAddress) && (
                  <>
                    <CustomerDetail field="Street 1" value={defaultAddress.street1} />
                    {!!defaultAddress.street2 && <CustomerDetail field="Street 2" value={defaultAddress.street2} />}
                    {!!defaultAddress.city && <CustomerDetail field="City" value={defaultAddress.city} />}
                    <CustomerDetail field="State" value={defaultAddress.state} />
                    <CustomerDetail field="Zip Code" value={defaultAddress.zipcode} />
                    <CustomerDetail field="Delivery Instructions" value={defaultAddress.notes} />
                  </>
                )}
                <View style={{ alignItems: 'flex-start' }}>
                  {hasAccessEditCustomer && (
                    <Button
                      title={note ? 'Edit a Note' : 'Add a Note'}
                      small
                      style={{ backgroundColor: Colors.transparent, marginLeft: -8 }}
                      textStyle={{ color: Colors.green }}
                      onPress={() => setIsNoteEditing((prev) => !prev)}
                    />
                  )}

                  {isNoteEditing && (
                    <AddNoteForCustomer
                      farmId={farm.id || ''}
                      customerId={customer.id || ''}
                      setNoteEdit={setIsNoteEditing}
                      note={note}
                      noteCallback={loadNote}
                    />
                  )}
                  {!isNoteEditing && <Text>{note}</Text>}
                </View>
              </View>
            )}
          </View>
        )}
        {isInfoEditing && customer && (
          <Formik
            validationSchema={customerDetailSchema}
            initialValues={{
              firstName: customer.name.firstName || '',
              lastName: customer.name.lastName || '',
              email: customer.email || '',
              email2: customer.email2,
              /** pronouns is not required, so we should load value depend existence of updatedCustomer and customer */
              pronouns: customer?.pronouns ? customer?.pronouns || '' : '',
              phoneNumber: unmarshalPhoneNumber(customer.phoneNumber || '', false) || '',
              street1: defaultAddress?.street1 || '',
              street2: defaultAddress?.street2 || '',
              city: defaultAddress?.city || '',
              state: defaultAddress?.state || '',
              zipcode: defaultAddress?.zipcode || '',
              notes: defaultAddress?.notes || '',
            }}
            onSubmit={handleSubmitAndUpdateCustomerDetails}
          >
            {({ handleSubmit }) => (
              <>
                <View style={styles.wrapperForm}>
                  <View style={styles.details}>
                    <Field name="firstName" placeholder="First Name" label="first Name" component={DetailInputField} />
                    <Field name="lastName" placeholder="Last Name" label="Last Name" component={DetailInputField} />
                    <Field name="pronouns" placeholder="Pronouns" label="Pronouns" component={DetailInputField} />
                    <Field name="email" placeholder="Email" label="Email" component={DetailInputField} />
                    <Field name="email2" placeholder="Email 2" label="Email 2" component={DetailInputField} />
                    <Field
                      name="phoneNumber"
                      placeholder="Phone Number"
                      label="Phone Number"
                      signInProvider={customer.signInProvider || ''}
                      component={DetailInputField}
                    />
                  </View>
                  <View style={styles.addressInfo}>
                    <Field name="street1" placeholder="Street 1" label="Street 1" component={DetailInputField} />
                    {!!customer.address && !!customer.address.street2 && (
                      <Field name="street2" placeholder="Street 2" label="Street 2" component={DetailInputField} />
                    )}
                    <Field name="city" placeholder="City" label="City" component={DetailInputField} />
                    <Field name="state" placeholder="State" label="State" component={DetailInputField} />
                    <Field name="zipcode" placeholder="Zip" label="Zip" component={DetailInputField} />
                    <Field
                      name="notes"
                      placeholder="Delivery Instructions"
                      label="Delivery Instructions"
                      component={DetailInputField}
                    />
                  </View>
                </View>
                <View style={styles.buttons}>
                  <Button
                    title="Cancel"
                    style={{ backgroundColor: Colors.primaryGray }}
                    onPress={() => setIsInfoEditing(false)}
                  />
                  <FormButton title="Save" loading={formButtonLoading} onPress={handleSubmit} />
                </View>
              </>
            )}
          </Formik>
        )}
      </LoadingView>
    </AdminCard>
  )
})

const useStyles = CreateResponsiveStyle(
  {
    sectionTitle: {
      marginBottom: 10,
    },
    hyperlink: {
      color: Colors.blue,
      textDecorationLine: 'underline',
    },
    wrapperForm: {
      paddingHorizontal: 20,
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    wrapper: {
      paddingHorizontal: 20,
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    details: {
      flex: 1,
      paddingRight: 20,
      marginRight: 20,
    },
    detail: {
      flexDirection: 'row',
      paddingVertical: 10,
    },
    field: {
      color: '#ababab',
      fontWeight: '400',
      flexBasis: '25%',
      fontFamily: typography.body.regular,
    },
    info: {
      fontWeight: '400',
      flexDirection: 'row',
      fontFamily: typography.body.regular,
      flexBasis: '75%',
      justifyContent: 'flex-start',
    },
    addressInfo: {
      flex: 1,
      paddingLeft: 20,
    },

    buttons: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingHorizontal: 20,
    },
    inputContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: 10,
      width: '100%',
    },
    inputLabel: {
      color: '#ababab',
      fontWeight: '400',
      fontFamily: typography.body.regular,
      margin: 0,
      padding: 0,
      flexBasis: '25%',
    },
    inputStyle: {
      backgroundColor: Colors.shades[75],
      borderRadius: 5,
      padding: 5,
      color: Colors.shades[500],
      width: '100%',
      flexBasis: '75%',
    },
    textareaStyle: {
      backgroundColor: Colors.shades[75],
      borderRadius: 5,
      padding: 5,
      color: Colors.shades[500],
      width: '100%',
    },
  },
  {
    // maxSize Medium will apply the below style overrides to medium and small devices
    [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      info: {
        fontFamily: typography.body.regular,
      },
      field: {
        fontFamily: typography.body.regular,
      },
      inputLabel: {
        fontFamily: typography.body.regular,
      },
      hyperlink: {
        fontFamily: typography.body.regular,
      },
      inputStyle: {
        fontSize: 14,
      },
      detail: {
        flexDirection: 'column',
      },
      details: {
        paddingRight: 0,
      },
      addressInfo: {
        paddingLeft: 0,
      },
      wrapperForm: {
        paddingHorizontal: 0,
        flexDirection: 'column',
      },
      buttons: {
        paddingVertical: 40,
      },
    },
    [maxSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      wrapper: {
        paddingHorizontal: 0,
      },
    },
  },
)
