import { TextH1 } from '@elements'
import { openUrl } from '@helpers/client'
import { sortByEarliest } from '@helpers/sorting'
import { Invoice, InvoiceStatus } from '@models/Invoice'
import { Order } from '@models/Order'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { PaymentCard } from '@screens/Orders/PaymentCard'
import { memo } from 'react'
import { View } from 'react-native'

import { grownbyWebsiteBaseUrl } from '@/config/Environment'
import { isMobile } from '@/constants/Layout'
import { useSelector } from 'react-redux'
import { wholesaleSelector } from '../../../../redux/selectors'

type Props = {
  invoices: Invoice[]
  currentOrder: Order
}

function PaymentsStructure({ invoices, currentOrder }: Props) {
  const navigation = useNavigation<StackNavigationProp<any>>()
  const { isWholesale } = useSelector(wholesaleSelector)

  return (
    <View>
      <TextH1 size={18}>Payments</TextH1>
      {invoices
        .filter((i) => i.order.id === currentOrder.id)
        .filter((inv) => inv.status !== InvoiceStatus.Void)
        .sort(sortByEarliest('dueDate'))
        .map((pay) => (
          <View key={pay.id}>
            <PaymentCard
              onPress={() =>
                isMobile
                  ? navigation.navigate('ExternalLinking', {
                      screen: 'InvoiceExternal',
                      params: { id: pay.id },
                    })
                  : openUrl(`${grownbyWebsiteBaseUrl(isWholesale)}external/invoice/${pay.id}`)
              }
              invoice={pay}
            />
          </View>
        ))}
    </View>
  )
}

export default memo(PaymentsStructure)
