import { Coordinate } from '@models/Coordinate'
import { createContext, Dispatch, SetStateAction } from 'react'

export type ExploreContextType = {
  condensedView: 'map' | 'list'
  setCondensedView: Dispatch<SetStateAction<'map' | 'list'>>
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  showCarousel: boolean
  setShowCarousel: Dispatch<SetStateAction<boolean>>
  redoSearch: (coords: Coordinate | undefined) => void
}

export const ExploreContext = createContext<ExploreContextType>({} as ExploreContextType)
