export class EventBridge<EventType> {
  eventName: string
  constructor(eventName: string) {
    this.eventName = eventName
  }
  dispatch(data: EventType) {
    EventRegister.dispatchEvent(this.eventName, data)
  }
  async listen(timeout = 10000): Promise<EventType> {
    return new Promise((resolve, reject) => {
      // Set timeout to reject the promise
      const timeoutHandle = setTimeout(() => {
        EventRegister.removeEventListener(listener)
        reject(new Error('Timeout'))
      }, timeout)

      // Listen for the event and cleanup on call
      const listener = EventRegister.addEventListener(this.eventName, (data) => {
        resolve(data)
        clearTimeout(timeoutHandle)
        EventRegister.removeEventListener(listener)
      })
    })
  }
}

type Listeners = {
  count: number
  refs: Record<string, { name: string; callback: (data: any) => void }>
}

// Adapted from https://github.com/meinto/react-native-event-listeners
class EventRegister {
  static _Listeners: Listeners = {
    count: 0,
    refs: {},
  }

  static addEventListener(eventName: string, callback: (data: any) => void) {
    EventRegister._Listeners.count++
    const eventId = 'EvtLstnr_' + EventRegister._Listeners.count
    EventRegister._Listeners.refs[eventId] = {
      name: eventName,
      callback,
    }
    return eventId
  }

  static removeEventListener(id: string) {
    EventRegister._Listeners.count--
    return delete EventRegister._Listeners.refs[id]
  }

  static dispatchEvent(eventName: string, data: any) {
    // Build list of callbacks to be called, if we call directly inside then
    // removingEventListeners in the callback modifies the list and causes a
    // `RangeError: Maximum call stack size exceeded.`
    const callbacks = Object.keys(EventRegister._Listeners.refs)
      .filter((_id) => eventName === EventRegister._Listeners.refs[_id].name)
      .map((_id) => EventRegister._Listeners.refs[_id].callback)

    // Loop through the callbacks and call them
    callbacks.forEach((callback) => callback(data))
  }
}
