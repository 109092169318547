import { useNavigation, useRoute } from '@react-navigation/native'
import { useFocusFx } from '../useFocusFx'
import { isNum } from '@helpers/helpers'

/**
 * Extracts and converts specific parameters into numeric/boolean values, if they're strings.
 *
 * @param keysToParse - Array of keys to extract from the params.
 * @param currParams - The object containing the parameters.
 * @returns An object containing the specified keys with numeric/boolean values.
 */
export const getConvertedParams = (
  keysToParse: string[] | undefined,
  currParams: Record<string, any>,
): Record<string, number | boolean> => {
  if (!keysToParse?.length) return {}

  const updatedParams: Record<string, number | boolean> = {}
  keysToParse.forEach((key) => {
    const paramValue = currParams?.[key]
    // Check if param is a stringified number
    if (paramValue !== undefined && typeof paramValue === 'string' && isNum(paramValue)) {
      const parsedValue = parseFloat(paramValue)

      updatedParams[key] = parsedValue
    }

    // Check if is a stringified boolean
    else if (paramValue === 'true' || paramValue === 'false') {
      updatedParams[key] = paramValue === 'true'
    }
  })

  return updatedParams
}

/**
 A custom hook that converts specified URL route parameters from strings to numbers or booleans
 * and updates the route parameters with the converted values
  - This can happen on page refresh, when all the values from the URL will be strings
 .*/
export function useParamsConverter(keysToParse: string[]) {
  const route = useRoute()
  const navigation = useNavigation<any>()

  useFocusFx(() => {
    const updatedParams = getConvertedParams(keysToParse, route.params ?? {})

    // Only call setParams if there's something to update
    if (Object.keys(updatedParams).length) {
      navigation.setParams(updatedParams)
    }
  }, [keysToParse, navigation, route.params])
}
