import { fontSize, typography } from '@elements'
import { StyleSheet } from 'react-native'

import Colors from '../../../constants/Colors'

export const styles = StyleSheet.create({
  headerStyle: {
    alignItems: 'center',
    margin: 20,
  },

  divText: {
    fontSize: fontSize(15, 2),
    padding: 5,
    fontWeight: 'bold',
    textAlign: 'center',
    color: Colors.black,
    fontFamily: typography.body.regular,
  },
  divTextHeader: {
    fontSize: 18,
    padding: 5,
    fontFamily: typography.body.regular,
    textAlign: 'center',
    color: '#555555',
  },

  signinheader: {
    fontSize: 18,
    textAlign: 'center',
    padding: 5,
    fontWeight: '400',
  },
  signintext: {
    padding: 5,
    textAlign: 'center',
    fontSize: fontSize(15, 2),
    flexWrap: 'wrap',
    color: Colors.black,
  },
  signinlink: {
    padding: 5,
    textAlign: 'center',
    fontSize: fontSize(15, 2),
    flexWrap: 'wrap',
    color: Colors.blue,
    flex: 1,
  },
  signinwrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 10,
  },

  subtext: {
    fontSize: fontSize(15, 2),
    padding: 5,
    textAlign: 'center',
    color: Colors.black,
  },

  tableHeader: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    //paddingHorizontal: 10,
    paddingVertical: 10,
    justifyContent: 'space-between',
    backgroundColor: '#F8FBF7',
    borderWidth: 0,
  },

  itemWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingHorizontal: 10,
    paddingVertical: 10,
    justifyContent: 'space-between',
  },

  dateWrapper: {
    margin: 5,
    flex: 1,
    maxWidth: 150,
  },

  datePicker: {
    borderWidth: 2,
  },
  filter: {
    margin: 5,
    maxWidth: 400,
    minWidth: 300,
  },
  filterCont: {
    flexWrap: 'wrap',
    flexDirection: 'row',
  },

  dailywrapper: {
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  sortContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  btnGroupCont: {
    borderRadius: 8,
    marginVertical: 40,
    width: '95%',
  },

  margin5: {
    margin: 5,
  },
  datesCont: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 5,
  },
  btnGroupText: {
    fontFamily: typography.body.regular,
  },
  exportBtn: {
    paddingHorizontal: 10,
  },
  smallDateCont: {
    padding: 5,
    borderRadius: 5,
  },
})
