import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { View, ViewProps } from 'react-native'

/** Custom SafeAreaView that uses the app's custom insets */
export function SafeAreaView({ style, ...props }: ViewProps) {
  const styles = useLayoutFnStyles(({ top, bottom, left, right }) => ({
    safeArea: {
      marginTop: top,
      marginBottom: bottom,
      marginLeft: left,
      marginRight: right,
    },
  }))

  return <View style={[styles.safeArea, style]} {...props} />
}
