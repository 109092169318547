import { PickerProps } from '@elements'
import { useCallback } from 'react'

/** Returns a stable handler for table filters onValueChange */
const useHandleRefine = (
  /** refinement state from algolia */
  refState: string,
  /** react set state for current refinement */
  refSetter: (v: string) => void,
  /** algolia's clear refinement helper */
  refClear: () => void,
  /** the refinement's default value. Ideally this default value should be the same as the placeholder value passed to RNPickerSelect */
  refDefault: string,
  /** algolia's listrefinement refine fn */
  refine: (v: string) => void,
): PickerProps['onValueChange'] => {
  return useCallback(
    (v: string) => {
      /** This prevents the picker refining the search on mount. Ideally the initial refinement state is the same as the placeholder value that will get called by RNPickerSelect on mount, so it will be caught here and return early, without doing any refinements on mount */
      if (v === refState) return

      refSetter(v) // Set the refinement state

      if (v !== refDefault) refine(v) // If selected value is not the default, refine
      else refClear() // If the default value was selected, clear refinement
    },
    [refState],
  )
}

export default useHandleRefine
