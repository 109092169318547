import { sortByConstraint } from '@helpers/sorting'
import { Optional, Replace } from '@helpers/typescript'
import { ValidateAddressOpts, getAddressErrors } from '@models/Address'
import { UserAddress } from '@models/UserAddress'
import { limit, where } from 'firebase/firestore'

import { geocode } from './Addresses'
import { addressCollection, usersCollection } from './framework/ClientCollections'

import { getShortState } from '@/assets/data/states'

/**
 * Will load the default address or first one if no default
 * @param userId
 */
export async function getDefaultAddress(userId: string): Promise<UserAddress | undefined> {
  const addresses = await addressCollection.resolve(userId).fetchAll(where('isDefault', '==', true), limit(1))
  return addresses[0]
}

/**
 * Will load all addresses and put the default one first
 * @param userId
 */
export async function loadUserAddresses(userId: string): Promise<UserAddress[]> {
  const addresses = await addressCollection.resolve(userId).fetchAll()

  return addresses.filter((a) => !a.deleted).sort(sortByConstraint((a) => !!a.isDefault))
}

/** Saves an address to a user  */
export async function addUserAddress(
  userId: string,
  /** An address without id and coords, where the state is a simple string instead of state code */
  address: Optional<Replace<UserAddress, 'state', string>, 'id' | 'coordinate'>,
  opts?: ValidateAddressOpts,
): Promise<UserAddress> {
  const errors = getAddressErrors(address, opts)
  if (errors) {
    throw new Error(Object.values(errors).join(', '))
  }

  // Confirms the state is valid and in state code form (ex. CA)
  const stateCode = getShortState(address.state)
  if (!stateCode) throw new Error('Invalid state')

  const { coordinate } = await geocode(address)

  // If we are setting a new default, make sure to clear the old one
  if (address.isDefault) await removeDefaultAddress(userId)

  const newAddr: Omit<UserAddress, 'id'> = {
    ...address,
    state: stateCode,
    coordinate,
  }

  const newAddress = await addressCollection.resolve(userId).create(newAddr)

  // only when the address is default, then we update the user's main address as default address
  if (address.isDefault) await usersCollection.update({ id: userId, address: newAddress })
  return newAddress
}

/**
 * Will validate and update the address with the new data.
 * @param userId the user to update the address on
 * @param address the address object we are updating
 */
export async function updateUserAddress(
  userId: string,
  address: Omit<Replace<UserAddress, 'state', string>, 'coordinate'>,
  opts?: ValidateAddressOpts,
): Promise<UserAddress> {
  const errors = getAddressErrors(address, opts)
  if (errors) {
    throw new Error(Object.values(errors).join(', '))
  }

  // If we are setting a new default, make sure to clear the old one
  if (address.isDefault) await removeDefaultAddress(userId)

  const { coordinate } = await geocode(address)
  const stateCode = getShortState(address.state)

  if (!stateCode) throw new Error('Invalid state')

  const newAddr: UserAddress = { ...address, coordinate, state: stateCode }

  await addressCollection.resolve(userId).update(newAddr)

  // only when the address is default, then we update the user's main address as default address
  if (address.isDefault) await usersCollection.update({ id: userId, address: newAddr })
  return newAddr
}

/**
 * Will mark an address as deleted which hides it from the user. It still exists for reference
 * @param userId the user to remove the address from
 * @param address the address object we are removing
 */
export async function removeUserAddress(userId: string, address: UserAddress): Promise<void> {
  return addressCollection.resolve(userId).update({ id: address.id, deleted: true, isDefault: false })
}

/** Will remove the address currently marked as default.
 * - Should be called whenever setting a new default address
 */
async function removeDefaultAddress(userId: string) {
  const collection = addressCollection.resolve(userId)
  const addresses = await collection.fetchAll(where('isDefault', '==', true))

  if (!addresses.length) return Promise.resolve()

  // Wait for all updates to be completed
  const writes = addresses.map((address) => collection.update({ id: address.id, isDefault: false }))
  return Promise.all(writes)
}
