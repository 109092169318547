import { loadCSAsByFarm } from '@api/CSAs'
import { useCallback, useMemo } from 'react'

import { OrderProductType } from '@/admin/navigation/types'
import { useApiFx } from '@/hooks/useApiFx'
import { useFocusFx } from '@/hooks/useFocusFx'
import { KeyedState } from '@/hooks/useKeyedState'
import { Farm } from '@models/Farm'
import { OrderCreatorStateProductsFilters } from './OrderCreatorScreen.helper'

/** Data layer for the reusable parts related to CSAs */
export function useCsasReusable({
  farmId,
  orderType,
  keyedState: [{ csa }, set],
}: {
  farmId: Farm['id']
  orderType: OrderProductType
  keyedState: KeyedState<OrderCreatorStateProductsFilters>
}) {
  /** Fetch farm CSAs into state
   * - Hidden CSAs will not be available as options in the order creator csa selector
   */
  const csasApiFx = useApiFx(loadCSAsByFarm, [farmId], !!farmId, {
    failedConditionMode: 'keep-loading',
    transform: (csas) => csas.filter((csa) => !csa.isHidden),
  })

  /** If there's a single csa, auto-select it */
  useFocusFx(() => {
    if (orderType === 'share' && !csa && csasApiFx.data?.length === 1) set('csa', csasApiFx.data[0])
    // We don't want this to run on csa change because it should be possible to de-select the auto-selected csa
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderType, csasApiFx.data])

  /** Sets the csa from the csa selector dropdown */
  const setCsa = useCallback(
    (csaId: string) =>
      set(
        'csa',
        csasApiFx.data?.find((csa) => csa.id === csaId),
      ),
    [set, csasApiFx.data],
  )
  /** Options for the csa dropdown selector */
  const csaDropdownOpts = useMemo(
    () =>
      (csasApiFx.data ?? []).map((csa) => ({
        label: csa.name,
        value: csa.id,
        key: csa.id,
      })),
    [csasApiFx.data],
  )

  return {
    csasApiFx,
    setCsa,
    csaDropdownOpts,
  }
}
