import { snapshotDistributionsByFarmStandalone } from '@api/Distributions'
import { ToolTips } from '@components'
import {
  Alert,
  Button,
  ButtonClear,
  CheckBox,
  ErrorText,
  FormDateTimeInput,
  FormDisplayRow,
  FormNumberInput,
  FormPickerInput,
  Icon,
  PickerProps,
  Toast,
} from '@elements'
import { dequal } from '@helpers/customDequal'
import { capitalize, formatShortPickupDate } from '@helpers/display'
import { extendErr, isNonNullish } from '@helpers/helpers'
import { getProductFrequency, isValidFreqConstraint, isValidScheduleNDateConstr } from '@helpers/products'
import { sortByName } from '@helpers/sorting'
import { isAfter, isBefore, isSameDay, isValidDateRange } from '@helpers/time'
import { PartialPick, pick } from '@helpers/typescript'
import {
  DefaultCatalog,
  PhysicalProduct,
  Product,
  ProductType,
  Standard,
  isDigital,
  isPhysical,
  isStandard,
} from '@models/Product'
import {
  DateRange,
  Frequency,
  Schedule,
  getFreqConstraintOptions,
  getScheduleAvailability,
  isSeasonalSchedule,
  isYearRoundSchedule,
  makeDateRange,
} from '@models/Schedule'
import { DataError } from '@shared/Errors'
import { useFormikContext } from 'formik'
import { DateTime } from 'luxon'
import * as React from 'react'
import { createContext, memo, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { View, ViewProps } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { ProductSchemaContext } from '@helpers/builders/buildProduct'
import FormSectionHeader from '../components/FormSectionHeader'
import { ProductFormikComponent } from './helpers/ProductFormikComponent'

import InputLabel from '@/admin/components/InputLabel'
import { FormikArray, FormikArrayRenderArgs } from '@/admin/components/elements/FormikArray'
import { AdminDrawerParamList, AdminProductsParamList } from '@/admin/navigation/types'
import { Logger } from '@/config/logger'
import Colors, { HexColor } from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { useComponentRoute } from '@/hooks/useComponentRoute'
import { useDeepCompareMemo, useDeepCompareMemoize } from '@/hooks/useDeepEqualEffect'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useDeepCompareFocusFx, useFocusFx } from '@/hooks/useFocusFx'
import useKeyedState from '@/hooks/useKeyedState'
import { useDeviceSize } from '@/hooks/useLayout'
import { setAdminSchedules } from '@/redux/actions/adminPersist'
import { adminFarmIdSelector, adminFarmSelector, adminParamsSelector } from '@/redux/selectors'
import { YUP_WHOLE_NUMBER_OPTIONAL_REAL } from '@helpers/Yup'
import {
  Distribution,
  DistributionConstraint,
  EditableFieldsSchedule,
  editableFieldsSchedule,
  isRetailSchedule,
  isWholesaleSchedule,
} from '@models/Distribution'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { AdvancedPricingForm } from './AdvancedPricing'
import { SharePricingForm } from './SharePricing'
import { ProductTypeForm } from './helpers/ProductTypeInfo'
import { getUnusedScheduleColor } from './helpers/schedulesComponent.helper'

/** This is a form schedule in its finished state, which has the schedule, frequency, color and date range defined. In its initial state, these values will be undefined */
export type FormSchedule = {
  distribution: Distribution
  frequency: Frequency
  startDate: DateTime
  endDate: DateTime
  color: HexColor
}

export type SchedulesFormType = {
  disableBuyInFuture?: Standard['disableBuyInFuture']
  minPickups?: number
  /** The form representation of the product schedules. They is set to be a partial of FormSchedule type, because they first get added as an empty object */
  formSchedules: Partial<FormSchedule>[]
}

/** Will get the min and max date ranges for the constraint pickers. schedule may be undefined in which case the return type is a partial date range */
const getOptionalScheduleAvailability = (schedule?: Pick<Distribution, 'schedule'>): Partial<DateRange> => {
  if (!schedule) return { startDate: undefined, endDate: undefined }
  return getScheduleAvailability(schedule.schedule)
}

/** Wrapper for getScheduleAvailability, which returns dates trimmed so they're no earlier than today.
 * - This approach should only be used when assigning a schedule to the product for the first time. Should never be used to calculate the availability dates of schedules already assigned to the product.
 */
const getScheduleAvailabilityFromToday = (schedule: Schedule) => {
  let { startDate, endDate } = getScheduleAvailability(schedule)

  startDate = DateTime.max(DateTime.now().setZone(startDate.zone).startOf('day'), startDate.startOf('day'))
  endDate = DateTime.max(DateTime.now().setZone(endDate.zone).startOf('day'), endDate.startOf('day'))

  return { startDate, endDate }
}

/** Helper that generates the form schedules from an existing product for edit mode */
const generateFormSchedules = (product: PhysicalProduct): SchedulesFormType['formSchedules'] => {
  const usedColors: Pick<Distribution, 'color' | 'id'>[] = product.distributions.map((d) => ({
    id: d.id,
    color: d.color,
  }))

  return product.distributions.map((schedule) => {
    const { startDate, endDate } = getFormDateConstraints(schedule, product.distributionConstraints)
    const formColor = schedule.color ?? getUnusedScheduleColor(usedColors, schedule.id)

    // If the schedule does not have a color then we should add this to the usedColors array so that we don't get duplicates
    if (!schedule.color) usedColors.push({ id: schedule.id, color: formColor })
    return {
      distribution: pick(schedule, ...editableFieldsSchedule, 'id') as Distribution,
      frequency: getProductFrequency(product, schedule, { strict: false }) ?? schedule.schedule.frequency,
      startDate,
      endDate,
      color: formColor,
    }
  })
}

/** Generates a new empty schedule object to the form.
 * - The undefined values will be set when selecting a schedule in the dropdown picker. When saving the changes, this new schedule object must not have any undefined values. */
const genFormSchedule = (): Partial<FormSchedule> => ({
  distribution: undefined,
  frequency: Frequency.WEEKLY,
  startDate: undefined,
  endDate: undefined,
  color: undefined,
})

const formScheduleObjectSchema: Yup.ObjectSchema<FormSchedule, ProductSchemaContext> =
  Yup.object<ProductSchemaContext>()
    .shape({
      distribution: Yup.mixed<Distribution>().required('Schedules are required'),
      frequency: Yup.mixed<Frequency>()
        .label('Frequency')
        .oneOf(Object.values(Frequency))
        .test(
          'frequency',
          "Frequency constraint must be wider than the schedule's frequency.",
          function (value: unknown) {
            if (!this.parent?.distribution?.schedule) return true
            return isValidFreqConstraint(
              value as Frequency,
              this.parent.distribution?.schedule.frequency,
              /** Should validate non-strictly for UI purposes */ false,
            )
          },
        )
        .required(),
      startDate: Yup.mixed<DateTime>()
        // .isDateTime() FIXME: This is crashing on web
        .label('Start Date')
        .test(
          'startDate',
          'Start date constraint must be after schedule start date and before the schedule end date',
          function (value: unknown) {
            if (!this.parent?.distribution?.schedule) return true
            //Check the start date: 1) doesn't start before the schedule start and 2) doesn't surpass the schedule end date
            const { startDate: scheduleStart, endDate: scheduleEnd } = getOptionalScheduleAvailability(
              this.parent?.distribution,
            )
            if (!scheduleStart) return this.createError({ message: 'Missing start date' })

            const startDate = value as DateTime
            if (isBefore(startDate, scheduleStart, { granularity: 'day', zone: scheduleStart.zone })) {
              return this.createError({
                message: `Product availability cannot start before the schedule (${formatShortPickupDate(
                  scheduleStart,
                )})`,
              })
            }

            if (!scheduleEnd) return true
            if (isAfter(startDate, scheduleEnd, { granularity: 'day', zone: scheduleEnd.zone })) {
              return this.createError({
                message: `Product availability cannot start after the schedule (${formatShortPickupDate(scheduleEnd)})`,
              })
            }

            //start date cannot be after the end date
            if (isAfter(startDate, this.parent?.endDate, { granularity: 'day', zone: startDate.zoneName })) {
              return this.createError({
                message: 'Start date is after end date',
              })
            }

            return true
          },
        )
        .required(),
      endDate: Yup.mixed<DateTime>()
        // .isDateTime() FIXME: This is crashing on web
        .label('End Date')
        .test(
          'endDate',
          'End date constraint must be after schedule start date and before schedule end date',
          function (value: unknown) {
            if (!this.parent?.distribution?.schedule) return true
            const { startDate: scheduleStart, endDate: scheduleEnd } = getOptionalScheduleAvailability(
              this.parent?.distribution,
            )
            if (!scheduleEnd) return this.createError({ message: 'Missing end date' })

            const endDate = value as DateTime
            if (isAfter(endDate, scheduleEnd, { granularity: 'day', zone: scheduleEnd.zone })) {
              return this.createError({
                message: `Product availability cannot end after the schedule (${formatShortPickupDate(scheduleEnd)})`,
              })
            }

            if (!scheduleStart) return true
            if (isBefore(endDate, scheduleStart, { granularity: 'day', zone: scheduleStart.zone })) {
              return this.createError({
                message: `Product availability cannot end before the schedule (${formatShortPickupDate(
                  scheduleStart,
                )})`,
              })
            }

            //end date cannot be before the start date
            if (isBefore(endDate, this.parent?.startDate, { granularity: 'day', zone: endDate.zoneName })) {
              return this.createError({
                message: 'End date is before start date',
              })
            }
            return true
          },
        )
        .required(),
      color: Yup.string<FormSchedule['color']>().required(),
    })
    .required()

const formSchedulesSchema: Yup.ObjectSchema<SchedulesFormType, ProductSchemaContext> =
  Yup.object<ProductSchemaContext>().shape({
    disableBuyInFuture: Yup.boolean(),
    minPickups: YUP_WHOLE_NUMBER_OPTIONAL_REAL('Minimum pickups').min(
      1,
      'Minimum pickups must be greater than or equal to 1',
    ),
    formSchedules: Yup.array<ProductSchemaContext>()
      .of(formScheduleObjectSchema)
      .required()
      .when('$type', {
        is: (type: ProductType) => isPhysical({ type }),
        then: (schema) => schema.min(1, 'You must add at least one schedule'),
        otherwise: (schema) => schema,
      })
      .test(
        'Check has at least one wholesale schedule',
        'There must be at least one wholesale schedule if the product is wholesale-retail',
        function (formSchedules) {
          // This test needs to be done at this level because inside the array we can't access the form parent which has the catalog value
          const { type, catalog } = (this.parent as AdvancedPricingForm & ProductTypeForm) ?? {}
          if (!catalog || !type) this.createError({ message: "Couldn't access the form context" })

          if (!isPhysical({ type }) || catalog !== DefaultCatalog.WholesaleRetail) return true
          return (
            formSchedules
              // must consider only the form schedules that have a distro assigned because the form is initialized with a dummy formSchedule of undefined distro
              .filter((fs) => isNonNullish(fs.distribution))
              .filter((fs) => isWholesaleSchedule(fs.distribution)).length > 0
          )
        },
      )
      .test(
        'Must be wholesale',
        'Only wholesale or wholesale-retail schedules are allowed if the product is wholesale',
        function (formSchedules) {
          // This test needs to be done at this level because inside the array we can't access the form parent which has the catalog value
          const { type, catalog } = (this.parent as AdvancedPricingForm & ProductTypeForm) ?? {}
          if (!catalog || !type) this.createError({ message: "Couldn't access the form context" })

          if (!isPhysical({ type }) || catalog !== DefaultCatalog.Wholesale) return true

          return (
            formSchedules
              // must consider only the form schedules that have a distro assigned because the form is initialized with a dummy formSchedule of undefined distro
              .filter((fs) => isNonNullish(fs.distribution))
              .every((fs) => isWholesaleSchedule(fs.distribution))
          )
        },
      )
      .test('Must be retail', 'Only retail schedules are allowed if the product is retail', function (formSchedules) {
        // This test needs to be done at this level because inside the array we can't access the form parent which has the catalog value
        const { type, catalog } = (this.parent as AdvancedPricingForm & ProductTypeForm) ?? {}
        if (!catalog || !type) this.createError({ message: "Couldn't access the form context" })

        if (!isPhysical({ type }) || catalog !== DefaultCatalog.Retail) return true

        return (
          formSchedules
            // must consider only the form schedules that have a distro assigned because the form is initialized with a dummy formSchedule of undefined distro
            .filter((fs) => isNonNullish(fs.distribution))
            .every((fs) => isRetailSchedule(fs.distribution))
        )
      }),
  })

/** Determines the initial date constraint form values for schedules already assigned to existing products. (Product editing)
 * - When editing products we should avoid producing different dates from those that were saved. If no constraint data was saved, it should return the current availability dates of the schedule, to prevent overwriting the product constraints inadvertently.
 * - If a year round schedule is assigned to a product with no date constraint,
 */
const getFormDateConstraints = (
  schedule: Distribution,
  scheduleConstraints: PhysicalProduct['distributionConstraints'],
): Partial<DateRange> => {
  try {
    const constraints = scheduleConstraints?.find((dc) => dc.id === schedule.id)
    if (constraints?.dateRange && !isValidDateRange(constraints.dateRange))
      throw new DataError('Invalid date range constraint data', {
        distro: schedule,
        distroConstraints: scheduleConstraints,
      })

    // Return the date constraint. If no date constraint exists, return the schedule availability.
    // Yearround schedules will have hard-coded dateRange constraints, to prevent getScheduleAvailability from producing a different result every day
    return constraints?.dateRange ?? getScheduleAvailability(schedule.schedule, undefined, schedule.location.timezone)
  } catch (err) {
    Logger.error(extendErr(err, 'Error while getting form date constraints in product schedules component. '))
    // This last-resort initial form values for the schedule constraints should not happen unless there's trully bad data
    return { startDate: undefined, endDate: undefined }
  }
}

const toFormik = (product: PartialPick<Product, 'type'>): SchedulesFormType => {
  if (!isPhysical(product)) {
    return {
      formSchedules: [],
    }
  }

  if (!product.distributions || product.distributions.length === 0) {
    // Handles new physical products
    return {
      formSchedules: [genFormSchedule()],
      disableBuyInFuture: isStandard(product) ? false : undefined,
      minPickups: isStandard(product) ? product.minPickups : undefined,
    }
  }

  //Handles existing physical products
  return {
    disableBuyInFuture: isStandard(product) ? product.disableBuyInFuture ?? false : undefined,
    minPickups: isStandard(product) ? product.minPickups : undefined,
    formSchedules: generateFormSchedules(product),
  }
}

function fromFormik(
  values: SchedulesFormType & ProductTypeForm & AdvancedPricingForm,
): Partial<Pick<Product, 'distributionConstraints' | 'distributions'>> &
  Partial<Pick<Standard, 'disableBuyInFuture' | 'minPickups'>> {
  if (isDigital(values)) return {}

  const schedules: Pick<Distribution, 'id' | EditableFieldsSchedule>[] = []
  const schedulesConstraints: DistributionConstraint[] = []

  values.formSchedules.forEach((fs) => {
    const schedule = fs.distribution
    // This error shouldn't be thrown in the regular course of events because formik schema will prevent saving a form with a missing schedule in a form schedule, but here it helps in discarding the optional type scenario
    if (!schedule) throw new Error('Schedule is required')

    const newConstraintCandidate: DistributionConstraint = {
      id: schedule.id,
    }

    // In practice, the formSchedule.frequency will be defined when it comes from formik because it must pass validation before it gets here. But the form schema needs to have it as optional for the form usage because it is empty when you add a new schedule since you get a blank item
    if (!fs.frequency) throw new Error('Missing form schedule frequency')

    /** The frequency selected should've been valid because options were generated based on the schedule frequency.
     * The product validator will ensure they are valid for the database in a later step.
     * Here we are only concerned with adding the frequency constraint if it's different from the schedule frequency because
     * the strict constraint validation will not allow the frequency to be the same, because it needs to be narrower.
     *  */
    if (fs.frequency !== schedule.schedule.frequency) {
      newConstraintCandidate.frequency = fs.frequency
    }

    // The startDate and endDate will be defined when they come from formik because this runs after schema validation. But the type needs to have them as optional because they're empty when a new schedule is added
    if (!fs.startDate || !fs.endDate) throw new Error('Missing date constraints in form schedule')

    const dateConstr: DateRange = makeDateRange(fs.startDate, fs.endDate, schedule.location.timezone)

    if (
      /** Requirement for adding a date constraint:
       * Either a) the schedule is yearround, or b) the date range is different from the schedule season.
       *
       * Explanation:
       * - Yearround schedules need to always get a date constraint because they lack an end date.
       * - Seasonal schedules only need a date constraint if the availability is narrower than the schedule season.
       * - It's not necessary to validate the constraint more strictly here because the schema validation already ran before this step, AND the product validator will run after the product is built. So this should be thought of as just a helper that assembles the data from this form, and passes the values up the ladder */
      isYearRoundSchedule(schedule.schedule) ||
      !isSameDay(dateConstr.startDate, schedule.schedule.season.startDate, schedule.location.timezone) ||
      !isSameDay(dateConstr.endDate, schedule.schedule.season.endDate, schedule.location.timezone)
    ) {
      newConstraintCandidate.dateRange = dateConstr
    }

    //Only save a schedule constraint if there was a constraint either in the frequency or the dates
    if (newConstraintCandidate.dateRange || newConstraintCandidate.frequency)
      schedulesConstraints.push(newConstraintCandidate)

    schedules.push(schedule)
  })

  return {
    disableBuyInFuture: isStandard(values) ? values.disableBuyInFuture ?? false : undefined,
    minPickups: isStandard(values) && values.catalog !== DefaultCatalog.Wholesale ? values.minPickups : undefined,
    // The product type requires type Distribution[], but the form requires only the editable fields. That should not produce any problems for the product data because the schedules will get denormalized anyway
    distributions: schedules as Distribution[],
    distributionConstraints: schedulesConstraints,
  }
}

export const FormikSchedules = new ProductFormikComponent(formSchedulesSchema, toFormik, fromFormik)

type SchedulesComponentProps = Pick<ViewProps, 'style'> & {
  /** If true, won't show a header above the schedules form. Intended for usage inside bulkEdit, where header is handled on the modal component */
  noHeader?: boolean
  /** Tooltips must be disabled from this component when used inside a modal because they will open a new modal and the current changes will be lost */
  noTooltips?: boolean
  /** Disables the bottom sheet used by the picker on small devices */
  noBottomSheet?: boolean
}

/** Internal state of the schedules component */
type State = {
  /** Fresh, unfiltered schedules for the current farm. Needs to be only the editable fields to prevent the ghost data from disturbing the deep equal result */
  farmSchedules: Pick<Distribution, EditableFieldsSchedule | 'id'>[] | undefined
  loadingFarmSchedules: boolean
}

type ContextType = State &
  Pick<SchedulesComponentProps, 'noTooltips' | 'noBottomSheet'> & {
    /** These ids are the ones available for linking schedules to the product. Should exclude hidden, used and past ones */
    availableScheduleIds: string[] | undefined
  }

const SchedulesContext = createContext<ContextType>({
  farmSchedules: [],
  loadingFarmSchedules: true,
  availableScheduleIds: [],
  noTooltips: undefined,
  noBottomSheet: undefined,
})
SchedulesContext.displayName = 'SchedulesContext'

/** Handles the schedules & availability UI for the product edit screen */
export function SchedulesComponent({ style, noHeader, noTooltips, noBottomSheet }: SchedulesComponentProps) {
  const dispatch = useDispatch()
  const farmId = useSelector(adminFarmIdSelector) || ''
  const [{ loadingFarmSchedules, farmSchedules }, set, setState] = useKeyedState<State>({
    loadingFarmSchedules: true,
    farmSchedules: undefined,
  })

  /** How many times the farm schedules have been set by the listener */
  const farmSchedulesListenerUpdatesCount = useRef(0)

  const {
    values: { formSchedules, disableBuyInFuture, minPickups, type, catalog },
    errors: arrayErrors,
    setFieldValue,
    handleBlur,
    setFieldTouched,
    touched,
    errors,
  } = useFormikContext<SchedulesFormType & SharePricingForm & ProductTypeForm & AdvancedPricingForm>()
  const { isSmallDevice } = useDeviceSize()
  const { product } = useSelector(adminParamsSelector) // The 'product' from adminParamsSelector should be the most up-to-date snapshot of the product if this is used within the product details screen which has the listener and dispatcher

  const styles = useStyles()

  /** Schedules listener: Watches for changes to farm schedules; sets current farm schedules. */
  useFocusFx(() => {
    set('loadingFarmSchedules', true)
    return snapshotDistributionsByFarmStandalone(farmId, (farmSchedules) => {
      // Set the admin schedules to redux. Other components in the product form need them. (AvailabilityOverview)
      dispatch(setAdminSchedules(farmSchedules))

      // Set state variables in a single call, so there can be a single re-render
      setState({
        loadingFarmSchedules: false,
        /** Must only get editable fields, to avoid wrong deep equal results */
        farmSchedules: farmSchedules.map((schedule) => pick(schedule, ...editableFieldsSchedule, 'id')),
      })
      farmSchedulesListenerUpdatesCount.current += 1
    })
    // Only needs the farmId dep.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmId])

  /** Schedule update fx: On DB schedules change, updates form schedules state */
  useDeepCompareFocusFx(
    () => {
      let updatedFormSchedules: typeof formSchedules = []

      try {
        /** We only want this to run on updates to the farm schedules, not on first set or before they are first set */
        if (loadingFarmSchedules || !farmSchedules || farmSchedulesListenerUpdatesCount.current <= 1) return

        const removedSchedules: string[] = []
        const incompatibleConstraints: string[] = []
        const incompatibleFreqs: string[] = []

        updatedFormSchedules = formSchedules
          .filter((fs) => {
            if (!fs.distribution) return true // This means the form schedule was a partial new schedule object recently generated

            // Keep only those product schedules included in the farmSchedules array
            const isIncluded = farmSchedules.map((d) => d.id).includes(fs.distribution.id)
            if (!isIncluded) removedSchedules.push(fs.distribution.id)
            return isIncluded
          })
          .map((fs) => {
            if (!fs.distribution) return fs // If it's a temporary partial form schedule, pass it unchanged

            // this schedule should be found because in the previous step we filtered form schedules not in the farm schedules
            const updatedSchedule = farmSchedules.find((d) => d.id === fs.distribution?.id)!

            // If the schedule is defined, the date constraints should have a form value. Else something is not working as expected
            if (!fs.startDate || !fs.endDate)
              throw new DataError(
                'Unexpected error: A form schedule had no date constraints although the schedule was already selected.',
                fs,
              )
            // Get the date constraints in the form state
            const dateConstr: Partial<DateRange> = { startDate: fs.startDate, endDate: fs.endDate }

            // Validate the date constraint state
            if (!isValidDateRange(dateConstr)) {
              throw new DataError('Invalid date constraint in form schedule', dateConstr)
            }

            // Validate the current date constraints are compatible with the updated schedule
            const validationErr = isValidScheduleNDateConstr(dateConstr, updatedSchedule.schedule)

            if (validationErr) {
              // If there's a validation error, would mean the schedule update made the current constraints incompatible
              incompatibleConstraints.push(updatedSchedule.id)
            }

            const frqConstr = fs.frequency
            if (frqConstr) {
              const isValid = isValidFreqConstraint(frqConstr, updatedSchedule.schedule.frequency, false)
              // If a frequency form value existed, and getting the frequency with the new schedule was not compatible, clear the form freq value
              if (!isValid) incompatibleFreqs.push(updatedSchedule.id)
            }

            return {
              ...fs,
              ...dateConstr,
              distribution: updatedSchedule as Distribution, //Must cast "as Distribution" because the type needs to remain as Distribution for the main product form type and related files
              frequency: frqConstr,
            }
          })

        /** This dequal needs the editable schedule data only, otherwise it will say there is an inequality, when there shouldn't be one, since this logic should've built the exact same formSchedules as the `toFormik` helper above. We don't want this to run more often than expected because it will show alerts
         */
        if (dequal(formSchedules, updatedFormSchedules)) return

        const hasChanges = removedSchedules.length || incompatibleConstraints.length || incompatibleFreqs.length
        if (!hasChanges)
          Toast('Recent schedule edits will be reflected in this form and will not alter unsaved changes')
        else
          Alert(
            'Schedules data changed',
            `Recent schedule edits may impact this product. Please review your product's availability.${
              !removedSchedules.length
                ? ''
                : `\n\nSchedules removed: ${removedSchedules
                    .map((sId) => {
                      const name = farmSchedules.find((fs) => fs.id === sId)!.name
                      return `"${name}"`
                    })
                    .join(', ')}`
            }${
              !incompatibleConstraints.length
                ? ''
                : `\n\nAvilability errors: ${incompatibleConstraints
                    .map((sId) => {
                      const name = farmSchedules.find((fs) => fs.id === sId)!.name
                      return `"${name}"`
                    })
                    .join(', ')}`
            }${
              !incompatibleFreqs.length
                ? ''
                : `\n\nFrequency errors: ${incompatibleFreqs
                    .map((sId) => {
                      const name = farmSchedules.find((fs) => fs.id === sId)!.name
                      return `"${name}"`
                    })
                    .join(', ')}`
            }`,
          )
      } catch (error) {
        // If there is an error, log it and reset the form schedules
        Logger.error(error)

        // If the product already exists, use the current product to re-generate the form schedules
        // This should re-generate the form schedules in the same way as `toFormik` helper, to ensure this update behaves like the first render
        updatedFormSchedules =
          product && isPhysical(product)
            ? generateFormSchedules({ ...product, distributions: farmSchedules as Distribution[] })
            : [genFormSchedule()]
      }

      setFieldValue('formSchedules', updatedFormSchedules.length > 0 ? updatedFormSchedules : [genFormSchedule()])
    },
    /** This hook is very delicate. No unnecessary deps should be added. This should only run when there is a change in the editable fields of the farm schedules */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [farmSchedules],
    { noRefocus: true },
  )

  /** These ids are the ones available for linking/ assigning new schedules to the product. */
  const availableScheduleIds = useMemo<ContextType['availableScheduleIds']>(() => {
    if (loadingFarmSchedules || !farmSchedules) return undefined

    const usedScheduleIds = formSchedules.map((fs) => fs.distribution?.id).filter(isNonNullish)

    // Must not include: hidden, or past ones
    return farmSchedules
      .filter(
        (fd) =>
          !usedScheduleIds.includes(fd.id) &&
          !fd.isHidden &&
          (isSeasonalSchedule(fd.schedule)
            ? isAfter(fd.schedule.season.endDate, DateTime.now(), {
                granularity: 'day',
                zone: fd.location.timezone,
              })
            : true),
      )
      .map((d) => d.id)
  }, [farmSchedules, formSchedules, loadingFarmSchedules])

  const handleDisableBuyInFutureChange = useCallback(
    async (v: boolean) => {
      await setFieldValue('disableBuyInFuture', v)
      setFieldTouched('disableBuyInFuture', true)
    },
    [setFieldValue, setFieldTouched],
  )

  const handleMinPickupsChange = useCallback(
    async (v?: number) => {
      await setFieldValue('minPickups', v)
      setFieldTouched('minPickups', true)
    },
    [setFieldValue, setFieldTouched],
  )

  useEffect(() => {
    // Required to enable the "Only allow Next Day purchases" checkbox if the catalog is set to wholesale and previously some minPickups value was set
    if (catalog === DefaultCatalog.Wholesale) {
      handleMinPickupsChange(undefined)
    }
  }, [catalog, handleMinPickupsChange])

  return (
    <SchedulesContext.Provider
      value={useDeepCompareMemoize({
        farmSchedules,
        loadingFarmSchedules,
        availableScheduleIds,
        noTooltips,
        noBottomSheet,
      })}
    >
      <View style={[styles.mainContainer, style]}>
        {!noHeader && (
          <View style={[styles.schedulesHeader, isSmallDevice && globalStyles.flexColumn]}>
            <FormSectionHeader
              title="Schedules & Availability"
              subtitle="Select one or more Schedules to sell your product."
            />
            {isStandard(type) ? (
              // The "noHeader" prop should hide this checkbox as well, that's intentional
              <CheckBox
                checked={disableBuyInFuture ?? false}
                style={styles.checkBoxStyle}
                title="Only allow Next Day purchases"
                onChange={handleDisableBuyInFutureChange}
                disabled={minPickups !== undefined && minPickups > 1}
                toolTipId={noTooltips ? undefined : ToolTips.PRODUCTS_SCHEDULE_NEXTDAY}
                toolTipTitle="Next Day purchases"
              />
            ) : null}
          </View>
        )}

        {isStandard({ type }) && catalog !== DefaultCatalog.Wholesale && (
          <FormNumberInput
            value={minPickups}
            disabled={disableBuyInFuture}
            placeholder="1"
            label={
              <InputLabel
                label="Minimum number of pickups (optional)"
                tooltipId={ToolTips.PRODUCTS_MIN_PICKUPS}
                tooltipTitle="Minimum Pickups"
              />
            }
            onChangeText={handleMinPickupsChange}
            onBlur={handleBlur('minPickups')}
            errorMessage={touched.minPickups ? errors.minPickups : ''}
          />
        )}

        <View>
          {!isSmallDevice && (
            <View style={styles.schedule}>
              <View style={styles.alignLeftIcon} />
              <InputLabel
                style={styles.formScheduleLabel}
                label="Schedule"
                tooltipId={noTooltips ? undefined : ToolTips.PRODUCTS_SCHEDULE_SELECT}
                required
              />
              <InputLabel
                style={styles.formScheduleLabel}
                label="Frequency"
                tooltipId={noTooltips ? undefined : ToolTips.PRODUCTS_SCHEDULE_FREQUENCY}
                required
              />
              <InputLabel
                style={styles.formScheduleLabel}
                label="Start Date"
                tooltipId={noTooltips ? undefined : ToolTips.PRODUCTS_SCHEDULE_START}
                required
              />
              <InputLabel
                style={styles.formScheduleLabel}
                tooltipId={noTooltips ? undefined : ToolTips.PRODUCTS_SCHEDULE_END}
                label="End Date"
                required
              />
              {formSchedules.length > 1 && <View style={styles.removeBtn} />}
            </View>
          )}
          <FormikArray<SchedulesFormType, 'formSchedules'>
            name="formSchedules"
            renderItem={({ formik, arrayHelpers, index }) => (
              <FormScheduleRenderItem
                formik={formik}
                arrayHelpers={arrayHelpers}
                index={index}
                key={formik.values.distribution?.id ?? 'new'}
              />
            )}
          />
        </View>

        {/* Must check that array level errors are a string. If there are any errors inside then it will be an array*/}
        {typeof arrayErrors.formSchedules === 'string' ? <ErrorText>{arrayErrors.formSchedules}</ErrorText> : null}
      </View>
    </SchedulesContext.Provider>
  )
}

const FormScheduleRenderItem = memo(function FormScheduleRenderItem({
  formik,
  arrayHelpers,
  index,
}: FormikArrayRenderArgs<SchedulesFormType, 'formSchedules'>) {
  const navigation = useNavigation<StackNavigationProp<AdminDrawerParamList, 'Products'>>()
  const currentScreen = useComponentRoute()?.name as keyof AdminProductsParamList | undefined
  const isProductDetailsScreen =
    currentScreen === 'AddProduct' || currentScreen === 'EditProduct' || currentScreen === 'ViewProduct'
  const { values: formSchedule, handleChange, setFieldValue, showError } = formik
  const { timezone: farmTimezone } = useSelector(adminFarmSelector)
  const { availableScheduleIds, farmSchedules, noTooltips, noBottomSheet, loadingFarmSchedules } =
    useContext(SchedulesContext)
  const {
    values: { formSchedules },
    setFieldTouched: setFieldTouchedForm,
  } = useFormikContext<SchedulesFormType>()
  const { isSmallDevice } = useDeviceSize()

  const canDelete = formSchedules.length > 1

  // The label above the schedule rows
  const showLabel = isSmallDevice

  const styles = useStyles()

  /** Get date limits for the start & end date constraints, from most recent schedule snapshot   */
  const { startDate: minDate, endDate: maxDate } = useDeepCompareMemo(
    () => getOptionalScheduleAvailability(farmSchedules?.find((d) => d.id === formSchedule.distribution?.id)),
    [farmSchedules, formSchedule.distribution?.id],
  )

  /** Frequency constraints available as form options should have equal or wider width than the original schedule frequency */
  const freqConstraintOptions: PickerProps['items'] = useDeepCompareMemo(
    () =>
      (formSchedule.distribution
        ? getFreqConstraintOptions(formSchedule.distribution.schedule.frequency, false)
        : []
      ).map((f) => ({ label: capitalize(f), value: f })),
    [formSchedule.distribution],
  )

  const scheduleIcon = useMemo(
    () => (
      <View style={styles.alignLeftIcon}>
        {formSchedule.distribution?.isHidden ? (
          <Icon name="eye-slash" style={styles.colorCircle} size={20} color={Colors.shades[300]} />
        ) : formSchedule.distribution?.closed ? (
          <Icon name="pause" style={styles.colorCircle} size={20} color={Colors.shades[300]} />
        ) : (
          <View style={[styles.colorCircle, { backgroundColor: formSchedule.color }]} />
        )}
      </View>
    ),
    [
      styles.alignLeftIcon,
      styles.colorCircle,
      formSchedule.distribution?.isHidden,
      formSchedule.distribution?.closed,
      formSchedule.color,
    ],
  )

  const removeButton = useMemo(() => {
    if (!canDelete) return null

    return (
      <ButtonClear
        icon="times"
        size={20}
        style={styles.removeBtn}
        textStyle={styles.removeBtnText}
        onPress={() => {
          arrayHelpers.remove(index)
        }}
      />
    )
  }, [arrayHelpers, canDelete, index, styles.removeBtn, styles.removeBtnText])

  /** Sets the initial availability when assigning a new schedule to the product */
  const onSelectSchedule: PickerProps['onValueChange'] = useCallback(
    async (scheduleId: string) => {
      if (!scheduleId || !farmSchedules) return
      const schedule = farmSchedules.find((schedule) => schedule.id === scheduleId)
      if (!schedule) return
      const { startDate, endDate } = getScheduleAvailabilityFromToday(schedule.schedule)
      await Promise.all([
        setFieldValue('distribution', schedule),
        setFieldValue('frequency', schedule.schedule.frequency),
        setFieldValue('startDate', startDate),
        setFieldValue('endDate', endDate),
        setFieldValue('color', schedule.color ?? getUnusedScheduleColor(formSchedules, schedule.id)),
      ])

      await setFieldTouchedForm('formSchedules', true)
    },
    [farmSchedules, setFieldValue, formSchedules, setFieldTouchedForm],
  )

  const scheduleOptionsPickerItems = useMemo<PickerProps['items']>(() => {
    if (!farmSchedules || !availableScheduleIds) return []
    return (
      farmSchedules
        .filter((schedule) => {
          // True if the schedule is available for potential new assignments
          const isSelectable = availableScheduleIds.includes(schedule.id)

          // True if the formSchedule has this schedule assigned
          const isAssigned = formSchedule.distribution?.id === schedule.id

          return isSelectable || isAssigned
        })
        /**
         * - SortBy name from combination of name and locationName
         * - closed schedule should be also sorted by name of combined distName and locationName, not by [closed] prefix
         */
        .sort((a, b) => sortByName(a, b, (schedule) => `${schedule.name}${schedule.location.name}`))
        .map((schedule) => {
          return {
            label: `${schedule.closed ? '[closed] ' : ''}${schedule.name} @ ${schedule.location.name}`,
            value: schedule.id,
          }
        })
    )
  }, [availableScheduleIds, farmSchedules, formSchedule.distribution])

  const schedulePicker = useMemo(() => {
    return (
      <FormPickerInput
        disabled={!farmSchedules}
        loading={loadingFarmSchedules}
        items={scheduleOptionsPickerItems}
        onValueChange={onSelectSchedule}
        value={formSchedule.distribution?.id}
        placeholder="Select a schedule"
        errorMessage={showError('distribution')}
        containerStyle={isSmallDevice ? styles.mobileSchedulePickerContainer : undefined}
        label={
          showLabel && (
            <View style={styles.scheduleLabelContainer}>
              <InputLabel
                style={styles.formScheduleLabel}
                label="Schedule"
                tooltipId={noTooltips ? undefined : ToolTips.PRODUCTS_SCHEDULE_SELECT}
                required
              />
              {isSmallDevice && removeButton}
            </View>
          )
        }
        useWebNativePicker={noBottomSheet}
      />
    )
  }, [
    scheduleOptionsPickerItems,
    farmSchedules,
    formSchedule.distribution?.id,
    isSmallDevice,
    loadingFarmSchedules,
    noBottomSheet,
    noTooltips,
    onSelectSchedule,
    removeButton,
    showError,
    showLabel,
    styles.formScheduleLabel,
    styles.mobileSchedulePickerContainer,
    styles.scheduleLabelContainer,
  ])

  const AddScheduleJSx = useMemo(() => {
    /** Conditions for showing the button to CREATE new schedule
     * - no longer loading farm schedules
     * - availableScheduleIds is empty
     * - formSchedules has exactly one item, with no schedule assigned
     * - is product details screen
     */
    if (
      !loadingFarmSchedules &&
      availableScheduleIds?.length === 0 &&
      formSchedules.length === 1 &&
      !formSchedules[0].distribution &&
      isProductDetailsScreen
    ) {
      return (
        <Button
          title="Create new Schedule"
          icon="plus"
          outline
          style={styles.addScheduleBtn}
          onPress={() => {
            navigation.navigate('DistributionSchedulesNavigator', {
              screen: 'AddDistribution',
              params: { goBack: 'AddProduct' },
            })
          }}
        />
      )
    } else if (
      /* Conditions for showing the button to ASSIGN schedule
       * - no longer loading farm schedules
       * - schedule should exist
       * - current index is last one
       * - availableScheduleIds has at least one item
       */
      !loadingFarmSchedules &&
      formSchedule.distribution &&
      index === formSchedules.length - 1 &&
      availableScheduleIds &&
      availableScheduleIds.length > 0
    ) {
      return (
        <Button
          title="Add Schedule"
          icon="plus"
          outline
          style={styles.addScheduleBtn}
          onPress={() => arrayHelpers.push(genFormSchedule())}
        />
      )
    }
    // This must return null if it's not going to show either the add or create option, because this jsx is rendered below every formSchedule item, so it should only show a button if necessary.
    // No spinners here because it would create one spinner for every form schedule item.
    return null
  }, [
    loadingFarmSchedules,
    isProductDetailsScreen,
    arrayHelpers,
    styles.addScheduleBtn,
    formSchedule.distribution,
    formSchedules,
    availableScheduleIds,
    index,
    navigation,
  ])

  return (
    <>
      <FormDisplayRow style={styles.scheduleContainer} ignoreSmall>
        {!isSmallDevice && scheduleIcon}
        {isSmallDevice ? (
          <View style={styles.mobileSchedulePickerAndIconContainer}>
            <View style={styles.mobileScheduleIconContainer}>{scheduleIcon}</View>
            {schedulePicker}
          </View>
        ) : (
          schedulePicker
        )}
        <FormPickerInput
          disabled={!formSchedule.distribution}
          loading={loadingFarmSchedules}
          items={freqConstraintOptions}
          placeholder="Select a distribution frequency"
          value={formSchedule.frequency ?? ''}
          onValueChange={handleChange('frequency')}
          errorMessage={showError('frequency')}
          label={
            showLabel && (
              <InputLabel
                style={styles.formScheduleLabel}
                label="Frequency"
                tooltipId={noTooltips ? undefined : ToolTips.PRODUCTS_SCHEDULE_FREQUENCY}
                required
              />
            )
          }
          useWebNativePicker={noBottomSheet}
        />
        <FormDateTimeInput
          placeholder="When is your product first available"
          disabled={!formSchedule.distribution}
          value={formSchedule.startDate}
          onChange={useCallback((startDate) => setFieldValue('startDate', startDate.startOf('day')), [setFieldValue])}
          minDate={minDate}
          maxDate={maxDate}
          timezone={farmTimezone}
          errorMessage={showError('startDate')}
          label={
            showLabel && (
              <InputLabel
                style={styles.formScheduleLabel}
                label="Start Date"
                tooltipId={noTooltips ? undefined : ToolTips.PRODUCTS_SCHEDULE_START}
                required
              />
            )
          }
        />
        <FormDateTimeInput
          placeholder="When is your product last available"
          disabled={!formSchedule.distribution}
          value={formSchedule.endDate}
          onChange={useCallback((date) => setFieldValue('endDate', date.endOf('day')), [setFieldValue])}
          timezone={farmTimezone}
          minDate={minDate}
          maxDate={maxDate}
          errorMessage={showError('endDate')}
          label={
            showLabel && (
              <InputLabel
                style={styles.formScheduleLabel}
                label="End Date"
                tooltipId={noTooltips ? undefined : ToolTips.PRODUCTS_SCHEDULE_END}
                required
              />
            )
          }
        />
        {!isSmallDevice && removeButton}
      </FormDisplayRow>
      {AddScheduleJSx}
    </>
  )
})

const useStyles = () =>
  useSizeFnStyles(({ isLargeDevice, isSmallDevice }) => ({
    mainContainer: {
      paddingHorizontal: isSmallDevice ? 0 : 25,
      paddingBottom: 40,
    },
    schedulesHeader: {
      flexDirection: isLargeDevice ? 'row' : 'column',
      justifyContent: 'space-between',
    },
    scheduleContainer: isSmallDevice
      ? {
          borderColor: Colors.shades['100'],
          borderWidth: 1,
          borderRadius: 10,
          marginVertical: 10,
          marginHorizontal: 10,
        }
      : {
          alignItems: 'center',
        },
    alignLeftIcon: { width: 30, paddingLeft: 10 },
    removeBtn: {
      paddingVertical: 0,
      paddingRight: 0,
      paddingLeft: 10,
    },
    removeBtnText: {
      paddingRight: 0,
    },
    schedule: {
      alignItems: 'center',
      flexDirection: 'row',
    },
    colorCircle: {
      width: 20,
      height: 20,
      borderRadius: 10,
    },
    formScheduleLabel: { flex: 1, marginHorizontal: isLargeDevice ? 13 : 0 },
    scheduleLabelContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: isLargeDevice ? 0 : -30,
    },
    checkBoxStyle: {
      margin: 10,
      marginRight: 0,
    },
    addScheduleBtn: { width: 250 },
    mobileSchedulePickerAndIconContainer: {
      flexDirection: 'row',
      paddingHorizontal: 10,
      paddingVertical: 10,
      alignItems: 'stretch',
    },
    mobileSchedulePickerContainer: {
      padding: 0,
    },
    mobileScheduleIconContainer: {
      flexDirection: 'column',
      justifyContent: 'flex-end',
      marginVertical: 10,
      marginRight: 10,
      marginLeft: -10,
    },
  }))
