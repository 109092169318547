import { Media } from '@models/shared/Media'
import { memo, ReactNode } from 'react'

import ParallaxHeaderWeb from './ParallaxHeaderWeb'
import { FontAwesome5IconName } from '../elements/Icon/iconNames'

import { useDeviceSize } from '@/hooks/useLayout'
import { ParallaxHeader } from './ParallaxHeader'

export type ParallaxButtons = {
  left: {
    // We pass loading in order to tell the header to replace this with a spinner
    icon: FontAwesome5IconName | 'loading'
    isSolid?: boolean
    onPress(): void
  }
  right?: {
    // We pass loading in order to tell the header to replace this with a spinner
    icon: FontAwesome5IconName | 'loading'
    isSolid?: boolean
    onPress(): void
  }
}

export type ParallaxProps = {
  media?: Media[]
  title: string
  buttons?: ParallaxButtons
  children: JSX.Element | JSX.Element[] | ReactNode
  webElements?: { content?: JSX.Element; sticky?: JSX.Element }
  /** When loading is true, children and parallax elements will have a loading indicator. So that means the parallax header acts as a kind of loading view */
  loading?: boolean
  /** Fn to call on back button press */
  back?: () => void
}

export default memo(function ParallaxHeaderMain({ ...props }: ParallaxProps) {
  const { isLargeDevice } = useDeviceSize()
  if (isLargeDevice) return <ParallaxHeaderWeb {...props} />
  else return <ParallaxHeader {...props} />
})
