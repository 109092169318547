import { Builder } from './Builder'
import { Product } from '@models/Product'
import { buildProduct } from './buildProduct'

/**
 * This builder will create a product object using the buildProduct helper */
export class ProductBuilder extends Builder<Product> {
  constructor() {
    super('product')
  }

  build<T extends Product>(obj: T): T {
    return buildProduct(obj)
  }

  validate<T extends Product>(obj: T): T {
    return buildProduct(obj)
  }
}
