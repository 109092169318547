import { DataError } from '@shared/Errors'
import { isObject } from './helpers'

/** omit returns the data with the supplied keys omitted from the result object. */
export function omit<T extends object, K extends keyof T>(data: T, ...keys: K[]): Omit<T, K> {
  if (!isObject(data)) throw new DataError('The omit() helper received something other than an object', data)
  const newData = { ...data }
  for (const key of keys) {
    delete newData[key]
  }
  return newData
}

/** pick returns the data with only the supplied keys included in the result object. It produces the opposite result to the omit function. */
export function pick<T extends object, K extends keyof T>(data: T, ...keys: K[]): Pick<T, K> {
  if (!isObject(data)) throw new DataError('The pick() helper received something other than an object', data)
  const result: Partial<T> = {}
  for (const key of keys) {
    result[key] = data[key]
  }
  return result as Pick<T, K>
}

/** Get all typescript keys with a certain type */
export type KeysMatching<T, V> = NonNullable<{ [K in keyof T]: T[K] extends V ? K : never }[keyof T]>

/** PartialExcept constructs a type with all properties of type T set to optional except those found within the set of keys K which are made required.
 * - This actually modifies the original type. So if the required key is already being required in the original type, it's better to instead use PartialPick<>
 * - This one should only be used in a situation where the keys required are optional in the original type.
 *  */
export type PartialExcept<T, K extends keyof T> = Partial<Omit<T, K>> & Required<Pick<T, K>>

/** Similar to PartialExcept, but it doesn't force the original type to become required. It only refers to it, whatever it is. */
export type PartialPick<T, K extends keyof T> = Partial<Omit<T, K>> & Pick<T, K>

/** Make sure at least one of the props specified are passed */
export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>
  }[Keys]

/** Converts an object type into a Record */
export type AsRecord<T> = Record<keyof T, T[keyof T]>

/** Will replace all instances of a deeply nested type with a new specified type */
export type ReplaceDeep<Type, FromType, ToType> = Type extends object
  ? { [key in keyof Type]: Type[key] extends FromType ? ToType : ReplaceDeep<Type[key], FromType, ToType> }
  : Type

/** This type will replace the type of an object property with a new type for the same property.
 * - Example: `Replace<{ fieldName: number}, fieldName, string>` will produce type `{ fieldName: string }`
 */
export type Replace<Obj extends Record<any, any>, Key extends keyof Obj, NewType> = Omit<Obj, Key> & {
  [P in Key]: NewType
}

/** This type will replace the type of an object property with a new type for the same property as a partial.
 * - Example: `Replace<{ fieldName: number}, fieldName, string>` will produce type `{ fieldName: string }`
 */
export type ReplaceOptional<Obj extends Record<any, any>, Key extends keyof Obj, NewType> = Omit<Obj, Key> & {
  [P in Key]?: NewType
}

/** Allow one or more properties to be partial */
export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

/** Allows the properties to be undefined, but they can't be missing */
export type PartialArgs<T> = {
  [P in keyof T]: T[P] | undefined
}

/** Given two Record objects, will return their intersection. Object B will overwrite any values in object A, and the resulting type should reflect that overwrite, if values of B extend those of A. */
export const intersect = <A extends Record<any, any>, B extends Record<any, any>>(a: A, b: B): A & B => {
  return { ...a, ...b }
}

/** Returns an object type where a set of values from a record are undefined. If keys are specified, will only undefine the type of those keys. Else, will set all values to undefined. */
export type Undefine<T extends Record<any, any>, K extends keyof T = keyof T> = Omit<T, K> & { [P in K]?: undefined }

/** Similar to PartialPick, but it makes the non-picked values undefined */
export type UndefinePick<T, K extends keyof T> = Undefine<Omit<T, K>> & Pick<T, K>

/** Sets values from an object to undefined. If keys are specified, will only undefine the value of those keys. Else, will set all values to undefined. */
export const undefine = <T extends Record<K, any>, K extends keyof T = keyof T>(
  obj: T,
  keys: K[] = Object.keys(obj) as K[],
): Undefine<T, K> => {
  const undef: { [P in K]?: undefined } = {}
  for (const k of keys) undef[k] = undefined
  return intersect(omit(obj, ...keys), undef)
}

export type Falsy = null | undefined | false | 0 | ''

export type Nullish = null | undefined

export type Truthy = number | object | string | true

export type Primitive = string | number | boolean | undefined | null

/** Identifies a Primitive value, including null */
export function isPrimitive(val: any): val is Primitive {
  return (
    typeof val === 'string' || typeof val === 'number' || typeof val === 'boolean' || val === undefined || val === null
  )
}

/** This attempts to identify variables we wish to treat immutably.
 * - This concept of immutable is slightly different from primitive because function and null are not primitives.
 * - Although a function is not completely immutable, functions aren't structures where we manage state.
 * - Class instances shouldn't be included because they have state.
 *  */
export const isImmutable = (val: any): val is Primitive | Function =>
  typeof val === 'string' ||
  typeof val === 'number' ||
  typeof val === 'undefined' ||
  typeof val === 'boolean' ||
  typeof val === 'function' ||
  val === null

/** Printable is a subset of Primitive types that can be coerced to a string literal */
export type Printable = string | number | boolean | null | undefined

/** A function with type generics for params and return */
export type TypedFn<ReturnType = any, ArgsType extends Parameters<any> = any[]> = (...args: ArgsType) => ReturnType

/** Async fn type is a Function that returns a promise of some value */
export type AsyncFn<T = any, A extends Parameters<any> = any[]> = TypedFn<Promise<T>, A>

/** A resolved type is the promised return type of an async fn */
export type Resolved<T extends AsyncFn | Promise<any>> = T extends (...args: any[]) => Promise<infer R>
  ? R
  : T extends Promise<infer R>
  ? R
  : unknown

/** A handled async fn type extends an async fn and returns either the resolved return type or undefined on failure */
export type HandledAsyncFn<F extends AsyncFn> = (...args: any[]) => Promise<Resolved<F> | undefined>

/** Will extract the type of one element from an array*/
export type ArrElement<T extends any[]> = T extends (infer S)[] ? S : never

/** Wrapper for Object.entries whose return type is true to the record's keys */
export const entries = <T extends Record<any, any>>(obj: T) => {
  return Object.entries(obj) as [keyof T, T extends { [k: string]: infer V } ? V : unknown][]
}

/** Typed wrapper for Object.keys */
export function keys<T extends Record<any, any>>(obj: T): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[]
}

/** Typed wrapper for Object.values */
export function values<T>(obj: Record<string, T>): T[] {
  return Object.values(obj) as T[]
}

/** If the item is included in the array, it will be considered of the array element type */
export const includes = <T extends Primitive>(arr: T[], itm: Primitive): itm is T => {
  return (arr as Primitive[]).includes(itm)
}

/** helps typescript identify a value as being of a given type, if it fulfills the condition */
export const isOfType = <T>(val: any, condition: boolean): val is T => {
  return condition
}

/** Picks an object's keys excluding the ones provided */
export type PickExcept<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

/** Selects an object where some keys will be made required */
export type PickRequire<T, K extends keyof T> = Required<Pick<T, K>> & Omit<T, K>

/** The optional object values will become maybe null */
export type OptionalToNull<T> = T extends Record<any, any>
  ? {
      [K in keyof T]: undefined extends T[K] ? Exclude<T[K], undefined> | null : T[K]
    }
  : T
