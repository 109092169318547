import { initialFiltersMapSearch } from '@models/Algolia'

import { DEVICE_SIZES, getAdminWidth, getDeviceSize } from '../../constants/Layout'
import { Layout } from '../../constants/types'
import {
  ADD_NAV_PROP,
  ADD_UNSUBSCRIBE,
  SET_ADMIN_OPEN,
  SET_CART_SERVICE,
  SET_CURRENT_HOVER,
  SET_FEATURES_AVAILABLE,
  SET_LAYOUT,
  SET_LISTENERS,
  SET_NAV_PROPS,
  SET_NAV_ROUTE,
  SET_REGION,
  SET_SEARCH_FILTERS,
  SET_SEARCH_LOCATION,
} from '../actions/actionTypes'
import { AppState } from './types'

import { initialCartService } from '@/constants/types/cartService'

export const initialState: AppState = {
  hasListeners: false,
  searchFilters: initialFiltersMapSearch,
  isAdminOpen: false,
  layout: {
    // The initial sizes in this layout should be for a hypothetical large size screen, to support testing-library tests in having a usable and static initial layout configuration.
    // In real execution, these sizes will be overwritten by the actual device size with the layout service
    width: 1250,
    screenWidth: 1250,
    height: 800,
    scale: 1,
    fontScale: 1,
    size: DEVICE_SIZES.LARGE_DEVICE,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  navRoute: 'Home',
  currentHover: null,
  unsubscribers: [],
  cartService: initialCartService,
  navProps: {
    farmCache: {},
  },
}

/**
 * In admin, the layout.width will be the width minus the sideBar width, as calculated in `adminWidth()`
 */
const setLayoutInternal = (layout: Layout, isAdmin: boolean): Layout => {
  const screenWidth = layout.screenWidth || layout.width
  const width = isAdmin ? getAdminWidth(screenWidth) : screenWidth
  const size = getDeviceSize(width)
  return { ...layout, width, screenWidth, size }
}

const appStateReducer = (state = initialState, action: any): AppState => {
  switch (action.type) {
    case SET_SEARCH_LOCATION:
      return { ...state, searchLocation: { ...state.searchLocation, ...action.payload } }
    case SET_REGION:
      return { ...state, region: action.payload }
    case SET_CURRENT_HOVER:
      return { ...state, currentHover: action.payload }
    case SET_SEARCH_FILTERS:
      return { ...state, searchFilters: action.payload }
    case ADD_NAV_PROP:
      return { ...state, navProps: { ...state.navProps, ...action.payload } }
    case SET_NAV_PROPS:
      return { ...state, navProps: action.payload === undefined ? { ...initialState.navProps } : action.payload }
    case SET_NAV_ROUTE:
      return { ...state, navRoute: action.payload }
    case SET_ADMIN_OPEN:
      // When setting admin_open, the payload is the new adminOpen value while the layout argument comes from state
      return { ...state, isAdminOpen: action.payload, layout: setLayoutInternal(state.layout, action.payload) }
    case SET_LAYOUT:
      // When setting layout, the payload is the new layout while the adminOpen value comes from state (Inverse of the "SET_ADMIN_OPEN" case)
      return {
        ...state,
        layout: setLayoutInternal(action.payload, state.isAdminOpen),
      }
    case SET_LISTENERS:
      return { ...state, hasListeners: action.payload }
    case ADD_UNSUBSCRIBE:
      return { ...state, unsubscribers: [...state.unsubscribers, action.payload] }
    case SET_CART_SERVICE:
      return { ...state, cartService: action.payload }
    case SET_FEATURES_AVAILABLE:
      return { ...state, navProps: { ...state.navProps, featuresAvailable: action.payload } }
    default:
      return state
  }
}
export default appStateReducer
