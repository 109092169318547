import { Button } from '@elements'
import { memo } from 'react'

import { refreshCartItemData } from '@helpers/cartItem'
import { Stepper } from './Stepper'
import { AddCartBtnProps, useAddCartBtnData } from './useAddCartBtnData'

/**
 * Button that handles the entire flow for adding a product to cart.
 * @returns adds to cart via the `useAddToCart` hook, with all the correct options, after all the modals selections.
 */
export const AddCartBtn = memo(function AddCartBtn(props: AddCartBtnProps) {
  const {
    loading,
    cartItem,
    disableAdd,
    id,
    updateQuantity,
    style,
    outline,
    onPressMemo,
    addCartStatus: title,
    disableStepper,
  } = useAddCartBtnData(props)

  /**Show Stepper if the product is in Cart and is not disabled by default*/
  if (cartItem && !disableStepper) {
    const freshCartItem = refreshCartItemData(cartItem, props.product)

    return (
      <Stepper
        cartItem={freshCartItem}
        disableAdd={disableAdd}
        key={id}
        updateQuantity={updateQuantity}
        style={style}
        loading={loading}
      />
    )
  }

  return (
    <Button
      small
      outline={outline}
      onPress={onPressMemo}
      title={title}
      disabled={disableAdd}
      loading={loading}
      size={14}
      style={style}
      testID={id}
      key={id}
    />
  )
})
