import { FontAwesome5 } from '@expo/vector-icons'
import { useEffect, useState } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import Colors from '../constants/Colors'
import { Farm } from '../models/Farm'
import { HeaderText } from './elements/Text'

type Props = {
  style?: ViewStyle
  stars: number
}

export function RatingStars({ stars, style }: Props) {
  const [halfStar, setHalfStar] = useState(false)

  useEffect(() => {
    if (stars > 5 || stars < 0) return
    setHalfStar(stars - Math.floor(stars) >= 0.5)
  }, [stars])

  if (stars > 5 || stars < 0) return null

  return (
    <View style={[styles.certContainer, style]}>
      {Array(Math.floor(stars))
        .fill(0)
        .map((_, index) => (
          <FontAwesome5 style={styles.icon} key={index} name="star" solid color={Colors.yellow} />
        ))}
      {halfStar && <FontAwesome5 style={styles.icon} name="star-half-alt" color={Colors.yellow} />}
      {Array(5 - Math.round(stars))
        .fill(0)
        .map((_, index) => (
          <FontAwesome5 style={styles.icon} key={index} name="star" solid color={Colors.lightGray} />
        ))}
    </View>
  )
}

export function Rating({ ratings, style }: { ratings: Farm['reviews']; style?: StyleProp<ViewStyle> }) {
  return (
    <View style={[styles.container, style]}>
      <RatingStars stars={ratings?.rating ? ratings?.rating : 0} />
      <HeaderText color={Colors.shades[300]} style={{ marginLeft: 5, marginRight: 15 }} size={12}>
        ({ratings?.numRatings ? ratings?.numRatings : 0})
      </HeaderText>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  certContainer: {
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  icon: {
    fontSize: 16,
    marginRight: 2,
  },
})
