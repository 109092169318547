import { Alert, Hoverable, Icon } from '@elements'
import { FontAwesome5 } from '@expo/vector-icons'
import { Media } from '@models/shared/Media'
import { SaveFormat } from 'expo-image-manipulator'
import { memo, useEffect, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import { Image } from './Image'
import ImageSelect from './ImageSelect'
import Colors, { withAlpha } from '../constants/Colors'

/**
 * @disabled - disables the ability to remove images or add extra images
 */
type Props = {
  imageType?: 'profile' | 'farm' | 'product' | 'logo'
  media?: Media[]
  setMedia(media: Media[]): void
  maxImages?: number
  disabled?: boolean
}

/**
 * Swaps specified items indexes
 */
function swapItems<T>(items: T[], idx1: number, idx2: number) {
  const temp = items[idx1]
  items[idx1] = items[idx2]
  items[idx2] = temp

  return [...items]
}

function AddImages({ media = [], imageType, setMedia, maxImages = 3, disabled = false }: Props) {
  const addMedia = async (storageUrl: string, type: 'image' | 'video') => {
    setMedia([...media, { storageUrl, type }])
  }
  const [selectedIdx, setSelectedIdx] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (media.length < 2) {
      setSelectedIdx(undefined)
    }
  }, [media.length])
  const removeMedia = (idx: number) => {
    Alert('Remove Media', `Are you sure you want to remove this ${media[idx]?.type}`, [
      {
        style: 'destructive',
        text: 'Remove',
        onPress: () => {
          const temp = [...media]
          temp.splice(idx, 1)
          setMedia(temp)
        },
      },
      {
        text: 'Cancel',
        onPress: () => {},
        style: 'cancel',
      },
    ])
  }
  const onSelectImage = (idx: number | undefined) => {
    if (media.length < 2) return
    setSelectedIdx(idx)
  }

  const onLeftPress = (idx: number) => {
    setMedia(swapItems(media, idx, idx - 1))
    setSelectedIdx(idx - 1)
  }
  const onRightPress = (idx: number) => {
    setMedia(swapItems(media, idx, idx + 1))
    setSelectedIdx(idx + 1)
  }
  function MoveArrows(idx: number) {
    return (
      <View style={styles.arrowCont}>
        {idx === selectedIdx ? (
          <>
            {idx !== 0 && (
              <TouchableOpacity disabled={media.length < 2} onPress={() => onLeftPress(idx)}>
                <Icon name="arrow-left" size={18} />
              </TouchableOpacity>
            )}
            <View style={styles.spacing} />
            {idx !== media.length - 1 && (
              <TouchableOpacity disabled={media.length < 2} onPress={() => onRightPress(idx)}>
                <Icon name="arrow-right" size={18} />
              </TouchableOpacity>
            )}
          </>
        ) : (
          <View />
        )}
      </View>
    )
  }
  const styles = useStyles()

  return (
    <View style={styles.imagesCont}>
      {media.map((mediaItm: Media, idx) => (
        <Hoverable
          key={mediaItm.storageUrl}
          onHoverIn={() => onSelectImage(idx)}
          onHoverOut={() => onSelectImage(undefined)}
        >
          <View style={styles.imgWrapper}>
            <TouchableOpacity
              activeOpacity={0.9}
              onPress={() => onSelectImage(idx === selectedIdx ? undefined : idx)}
              disabled={disabled}
            >
              <View style={[styles.imageCont, selectedIdx === idx && styles.selectedCont]}>
                {!disabled && (
                  <TouchableOpacity activeOpacity={0.6} style={styles.removeIconCont} onPress={() => removeMedia(idx)}>
                    <FontAwesome5 name="times" size={20} color={Colors.lightGray} />
                  </TouchableOpacity>
                )}

                <Image type={imageType} style={styles.image} source={{ uri: mediaItm.storageUrl }} />
              </View>
            </TouchableOpacity>
            {MoveArrows(idx)}
          </View>
        </Hoverable>
      ))}

      {media.length < maxImages && !disabled && (
        <View style={[styles.imageCont, styles.imgWrapper, styles.imgSelectCont]}>
          <ImageSelect onChange={addMedia} editOptions={{ width: 1200, format: SaveFormat.JPEG }} multiple />
        </View>
      )}
    </View>
  )
}
export default memo(AddImages)

const useStyles = CreateResponsiveStyle(
  {
    imagesCont: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    imgSelectCont: {
      borderWidth: 1,
      borderColor: Colors.shades[100],
    },

    imgWrapper: {
      marginRight: 15,
      marginVertical: 10,
    },
    selectedCont: {
      borderColor: Colors.green,
      borderWidth: 3,
    },

    removeIconCont: {
      position: 'absolute',
      zIndex: 2,
      top: 5,
      right: 5,
      backgroundColor: withAlpha(Colors.shades[500], 0.25),
      borderRadius: 99,
      paddingHorizontal: 8,
      paddingVertical: 5,
    },

    image: {
      height: '100%',
      width: '100%',
    },
    spacing: {
      width: 20,
    },
    arrowCont: {
      flexDirection: 'row',
      alignSelf: 'center',
      alignItems: 'center',
      minHeight: 30,
    },
    imageCont: {
      height: 150,
      width: 225,
      overflow: 'hidden',
      borderRadius: 10,
    },
  },
  {
    [maxSize(DEVICE_SIZES.LARGE_DEVICE)]: {
      imageCont: {
        height: 90,
        width: 135,
      },
      imgWrapper: {
        marginRight: 10,
        marginVertical: 5,
      },
    },
  },
)
