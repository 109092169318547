import { Logger } from '@/config/logger'
import Colors from '@/constants/Colors'
import { ModalComponentProps } from '@/constants/types/modalTypes'
import { useApiFx } from '@/hooks/useApiFx'
import useKeyedState from '@/hooks/useKeyedState'
import { Button, CheckBox, ErrorText, Modal, Text, hideModal } from '@elements'
import { bullet } from '@helpers/display'
import { errorToString, isNonNullish, nonEmptyString } from '@helpers/helpers'
import { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { BulkEditModalGenericProps } from './products/product'

type Doc = { id: string }

export type BulkEditBooleanProps<T extends Doc> = BulkEditModalGenericProps<T, boolean | undefined>

function BulkEditBooleanComp<T extends Doc>({
  getField,
  updateField,
  validate,
  onPassValidation,
  fieldNameDisplay,
  getItemDisplayName = (doc) => {
    if ('name' in doc && nonEmptyString(doc.name)) return doc.name
    return doc.id
  },
  ids,
  fetchByIds,
}: BulkEditBooleanProps<T>) {
  const [{ isSubmitting, submitError, validationErrors }, set, setState] = useKeyedState({
    isSubmitting: false,
    submitError: undefined as unknown,
    validationErrors: [] as { error: unknown; id: string }[],
  })

  const { data, loading: isFetchingData, setState: setDataState } = useApiFx(fetchByIds, [ids])

  const isLoading = isSubmitting || isFetchingData

  /** This tells us whether the field exists within all the items selected */
  const { allSelectedSupportField, itemsWithNoField, noneSelectedSupportField } = useMemo(() => {
    const itemsWithNoField = data?.filter((doc) => getField(doc) === 'Field_not_exist') || []

    return {
      allSelectedSupportField: itemsWithNoField.length === 0,
      itemsWithNoField,
      noneSelectedSupportField: itemsWithNoField.length === data?.length,
    }
  }, [data, getField])

  /** The state of the checkbox */
  const checked = useMemo(() => {
    if (!data) return undefined
    // get the field value for each item that supports the field
    const fieldData = data.map(getField).filter((v): v is boolean | undefined => v !== 'Field_not_exist')

    // if all are defined booleans and are the same, set the value. Else undefined should produce the "minus-square" icon on the checkbox
    if (!fieldData.every((v, _, arr) => typeof v === 'boolean' && v === arr[0])) {
      return undefined
    } else return fieldData[0]
  }, [data, getField])

  const onChecked = (newVal: boolean) => {
    if (!data) return
    setState((p) => ({ ...p, validationErrors: [], submitError: undefined }))
    // apply the edit to each item and update state
    const newData = data.map((doc) => {
      return updateField(newVal, doc)
    })
    setDataState((s) => ({ ...s, data: newData }))
  }

  const onSubmit = async () => {
    if (!data) return
    setState((p) => ({ ...p, isSubmitting: true, validationErrors: [], submitError: undefined }))
    const errors = data
      .filter((obj) => {
        // only need to validate those for which the field exists, because only those will get updated
        return getField(obj) !== 'Field_not_exist'
      })
      .map((obj) => {
        try {
          validate(obj)
        } catch (error) {
          return { error, id: obj.id }
        }
      })
      .filter(isNonNullish)

    if (errors.length) {
      setState((p) => ({ ...p, isSubmitting: false, validationErrors: errors }))
      return
    }

    try {
      await onPassValidation(data)
      hideModal()
    } catch (error) {
      Logger.error(error)
      set('submitError', error)
    } finally {
      set('isSubmitting', false)
    }
  }

  const errorText = useMemo(() => {
    let txt = ''
    if (!data) return ''
    if (validationErrors.length) {
      txt += 'Some items are incompatible with these edits: \n\n'
      validationErrors.forEach(({ id, error }) => {
        txt += ` ${bullet} ` + getItemDisplayName(data.find((p) => p.id === id)!) + ': ' + errorToString(error) + '\n'
      })
    } else if (submitError) {
      txt += 'There was a problem submitting this request. Try again \n\n'
    }
    return txt
  }, [validationErrors, submitError, getItemDisplayName, data])

  const warning = useMemo(() => {
    let txt = ''
    if (noneSelectedSupportField) {
      txt += `None of the items in your selection support the field "${fieldNameDisplay}". Update your selection or choose a different field.`
    } else if (!allSelectedSupportField) {
      txt += `The field "${fieldNameDisplay}" is not supported by the following items: \n\n`
      itemsWithNoField.forEach((doc) => {
        txt += ` ${bullet} ${getItemDisplayName(doc)}\n`
      })
      txt += '\n\nYour edits will be applied to the rest of your selection.'
    }
    return txt
  }, [allSelectedSupportField, itemsWithNoField, fieldNameDisplay, getItemDisplayName, noneSelectedSupportField])

  return (
    <View style={styles.main}>
      <View style={styles.content}>
        {!!warning && (
          <Text style={styles.warningText} color={Colors.brown}>
            {warning}
          </Text>
        )}
        <CheckBox
          checked={checked}
          onChange={onChecked}
          title={fieldNameDisplay}
          disabled={isLoading || noneSelectedSupportField}
        />
      </View>
      <Button title="Update multiple" onPress={onSubmit} loading={isLoading} disabled={noneSelectedSupportField} />
      {errorText ? <ErrorText>{errorText}</ErrorText> : null}
    </View>
  )
}

type OpenBooleanBulkEditOpts<T extends Doc> = ModalComponentProps & { contentProps: BulkEditBooleanProps<T> }

/** opens the bulk edit modal for boolean fields */
export function openBulkEditBoolean<T extends Doc>({
  contentProps,
  ...modalComponentProps
}: OpenBooleanBulkEditOpts<T>) {
  Modal(<BulkEditBooleanComp {...contentProps} />, modalComponentProps)
}

const styles = StyleSheet.create({
  main: {
    padding: 10,
    alignContent: 'center',
  },
  content: {
    padding: 15,
  },
  warningText: {
    backgroundColor: Colors.shadeGold,
    borderRadius: 20,
    padding: 10,
    margin: 10,
  },
})
