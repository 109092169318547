import { disableAddCartBtn, getCartItemForAddCartBtn } from '@components'
import { Toast } from '@elements'
import { PaymentSchedule, Share } from '@models/Product'
import { memo, useCallback, useMemo } from 'react'

import { useCartService } from '../../../../../../hooks/useCart'

import { CartServiceType } from '@/constants/types/cartService'
import { useAddToCartFlow } from '@/hooks/useCart/addToCartFlow/useAddToCartFlow'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { CSA } from '@models/CSA'
import { Distribution } from '@models/Distribution'
import { AdminAddCartBtnUI } from './AddCartBtnUI'

type AddBtnShareProps_Reusable = {
  prod: Share
  /** The payment schedule for the product in the cart. It is not optional because the user must select a value in the selector UI which is in the share row */
  paySchedule: PaymentSchedule | undefined
  isWholesale: boolean
  cartServiceType: CartServiceType
  schedule: Distribution | undefined
  csa: CSA | undefined
}

export const AddBtnShare_Reusable = memo(function AddBtnShare_Reusable({
  prod,
  paySchedule,
  isWholesale,
  cartServiceType,
  schedule,
  csa,
}: AddBtnShareProps_Reusable) {
  const farmId = prod.farm.id

  const { cart, loadingCart, isAdmin, updateQuantity } = useCartService({
    cartServiceType,
    farmId,
    isWholesale,
  })

  const { addToCartFlow, isLoadingAddCartFlow } = useAddToCartFlow({
    cartServiceType,
    isWholesale,
  })

  const itemInCart = useMemo(() => getCartItemForAddCartBtn(prod, cart), [prod, cart])

  const updateQuantityForUi = async (delta: number) => {
    if (!itemInCart) return
    try {
      await updateQuantity(itemInCart.id, itemInCart.quantity + delta)
    } catch {
      Toast('Something went wrong while updating the item quantity')
    }
  }

  const disableAdd = useMemo(
    () =>
      // This call to disableAddCartBtn should absolutely be memoized because it involves a pickup calculation on db products
      disableAddCartBtn({ prod, cart, isWholesale, mode: isAdmin ? 'admin' : 'consumer' }),
    [prod, cart, isWholesale, isAdmin],
  )

  const onAddPress = useCallback(
    () => addToCartFlow({ product: prod, csa, paymentSchedule: paySchedule, distribution: schedule }),
    [prod, schedule, addToCartFlow, paySchedule, csa],
  )

  return (
    <AdminAddCartBtnUI
      addToCart={onAddPress}
      disableAdd={disableAdd}
      isLoadingAddCartFlow={isLoadingAddCartFlow}
      isLoadingCart={loadingCart}
      itemInCart={itemInCart}
      updateQty={updateQuantityForUi}
    />
  )
},
propsAreDeepEqual)
