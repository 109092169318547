import { listPaymentMethods, removePaymentMethod } from '@api/Payments'
import { ConsumerScroll } from '@components'
import { Alert, Loader, LoadingView, Modal, Toast } from '@elements'
import { errorToString, nonEmptyString } from '@helpers/helpers'
import { isEbtPayment, PaymentForms, PaymentMethod } from '@models/PaymentMethod'
import { AddPaymentMethod } from '@screens/PaymentMethods/AddPaymentMethod/AddPaymentMethod'
import { useSelector } from 'react-redux'

import { withAuth } from '@/hooks/withAuth'
import { StyleSheet } from 'react-native'
import { Logger } from '../../config/logger'
import Colors from '../../constants/Colors'
import { useApiFx } from '../../hooks/useApiFx'
import { userSelector } from '../../redux/selectors'
import { AddPaymentMethodButton } from '../PaymentMethods/AddPaymentMethod/AddPaymentButtons'
import { PaymentButton } from '../PaymentMethods/PaymentButton'

/** Payments screen accessed from user profile */
function PaymentsScreenComp() {
  const user = useSelector(userSelector)
  const payMethods = useApiFx(listPaymentMethods, [user.id], nonEmptyString(user.id))
  const paymentsWithoutCash = payMethods.data?.filter((p) => p.token !== 'cash') ?? []

  const removePayment = async (pm: PaymentMethod) => {
    if (pm.type === PaymentForms.CASH) {
      Toast('Cannot remove this payment method')
      return
    }
    Alert('Remove Payment Method', 'Are you sure you want to remove this payment method?', [
      {
        text: 'Cancel',
        style: 'cancel',
      },
      {
        text: 'Remove',
        onPress: async () => {
          try {
            Loader(true)
            await removePaymentMethod(pm, user.id)
            payMethods.refresh()
            Toast('Removed payment method')
            return Loader(false)
          } catch (e) {
            Logger.debug(e)
            Loader(false)
            return Alert(
              'Unable to remove your payment method',
              `An error occurred, please try again later. ${errorToString(e)}`,
            )
          }
        },
      },
    ])
  }

  const addPaymentMethod = () => {
    Modal(
      <AddPaymentMethod
        userId={user.id}
        onDismiss={payMethods.refresh}
        ebtEnabled
        hasEbtCard={payMethods.data?.find(isEbtPayment) !== undefined}
      />,
      {
        noPadding: true,
        title: `Enter your card details`,
      },
    )
  }

  return (
    <ConsumerScroll>
      <LoadingView loading={payMethods.loading}>
        {paymentsWithoutCash.map((pay: PaymentMethod) => (
          <PaymentButton
            key={pay.id}
            payment={pay}
            onPress={removePayment}
            isSelected={false}
            isEditable
            style={styles.paymentButtons}
          />
        ))}
        <AddPaymentMethodButton onPress={addPaymentMethod} style={styles.paymentButtons} />
      </LoadingView>
    </ConsumerScroll>
  )
}
export const PaymentsScreen = withAuth(PaymentsScreenComp, { noSafeAreaInsets: true })
const styles = StyleSheet.create({
  paymentButtons: {
    borderColor: Colors.shades[200],
    borderWidth: 1,
    borderRadius: 5,
  },
})
