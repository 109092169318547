import { uploadImageAsync } from '@api/FirebaseStorage'
import { Alert } from '@elements'
import { TemplateProduct } from '@models/Product'
import { Media } from '@models/shared/Media'
import { StackNavigationProp } from '@react-navigation/stack'
import sha1 from 'sha1'

import { AdminProductsParamList, TemplatePageType } from '@/admin/navigation/types'
import { Logger } from '@/config/logger'

interface GoBackOpts {
  navigation: StackNavigationProp<AdminProductsParamList, 'AddTemplate' | 'EditTemplate'>
  pageType: TemplatePageType
  isDirty: boolean
  params?: AdminProductsParamList['AddTemplate'] | AdminProductsParamList['EditTemplate']
  template?: TemplateProduct
}

//handle go back action
export const goBack = ({ navigation, pageType, isDirty, params, template }: GoBackOpts) => {
  const go = () => {
    if (params?.goBack) {
      navigation.replace(params.goBack)
    } else if (pageType === TemplatePageType.ADD) {
      navigation.replace('ProductList')
    } else if (
      pageType === TemplatePageType.EDIT &&
      template &&
      (params as AdminProductsParamList['EditTemplate'])?.prodId
    ) {
      navigation.replace('EditProduct', { prodId: (params as AdminProductsParamList['EditTemplate']).prodId! })
    } else {
      navigation.replace('ProductList')
    }
  }

  if (isDirty) {
    Alert("Your changes aren't saved!", 'Do you want to continue without saving or want to go back?', [
      { text: 'Cancel', style: 'cancel' },
      {
        text: 'Continue without saving',
        onPress: go,
      },
    ])
  } else {
    go()
  }
}

//check is Edit or not Edit
export function isEdit(template?: TemplateProduct): template is TemplateProduct {
  return !!template
}

//handle image upload
// if the image is already uploaded don't upload the uploaded version which will cause a break in the url
export async function imageUploadAsync(media: Media[]) {
  try {
    const result = await Promise.all(
      media.map((m: Media) => {
        // Check if image is already hosted
        if (m.storageUrl.includes('http://') || m.storageUrl.includes('https://')) return m
        else return uploadImageAsync(sha1(m.storageUrl), m)
      }),
    )
    return result.map((m) => m.storageUrl)
  } catch (err) {
    Alert('Error saving images', 'There was an error saving your images. Please try again.')
    Logger.error('Error saving images', err)
  }
}
