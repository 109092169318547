import { snapshotDistributionsByFarm } from '@api/Distributions'
import { getPickups } from '@helpers/order'
import { sortByName } from '@helpers/sorting'
import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { OrderProductType } from '@/admin/navigation/types'
import { useSnapshot } from '@/hooks/useApiFx'
import { useFocusFx } from '@/hooks/useFocusFx'
import { KeyedState } from '@/hooks/useKeyedState'
import { setAdminSchedules } from '@/redux/actions/adminPersist'
import { isRetailSchedule, isWholesaleSchedule } from '@models/Distribution'
import { Product } from '@models/Product'
import { OrderCreatorStateProductsFilters } from './OrderCreatorScreen.helper'

/** Reusable data layer for any state related to schedules and dates in the order creator */
export function useSchedulesAndDatesReusable({
  orderType,
  keyedState: [{ schedule, pickupDate, pickupDates }, set],
  isLoadingProducts,
  dbProds,
  isWholesale,
  farmId,
}: {
  orderType: OrderProductType
  keyedState: KeyedState<OrderCreatorStateProductsFilters>
  isLoadingProducts: boolean
  dbProds: Product[] | undefined
  isWholesale: boolean | undefined
  farmId: string
}) {
  const dispatch = useDispatch()

  /** Syncs the farm distros into admin redux */
  const { loading: loadingSchedules, data: farmSchedules } = useSnapshot(
    snapshotDistributionsByFarm,
    [farmId],
    !!farmId,
    {
      onStateChange: ({ data }) => dispatch(setAdminSchedules(data ?? [])),
    },
  )

  /** If there's a single distro, auto-select it */
  useFocusFx(() => {
    if (!isLoadingProducts && orderType === 'standard' && !schedule && farmSchedules?.length === 1)
      set('schedule', farmSchedules[0])
  }, [orderType, schedule, farmSchedules, isLoadingProducts, set])

  /** Updates pickup date when the available dates change */
  useFocusFx(() => {
    if (!pickupDates.length) return
    let newDate = pickupDate
    /** When new pickup dates are computed, updates or resets pickup date previously selected */
    if (pickupDate && pickupDates.every((date) => date.toISODate() !== pickupDate.toISODate())) newDate = undefined
    if (pickupDates.length === 1) newDate = pickupDates[0]
    set('pickupDate', newDate)
  }, [pickupDates, pickupDate, set])

  /** Sets the distro from the dropdown picker interaction */
  const setDistro = useCallback(
    (id: string) =>
      set(
        'schedule',
        farmSchedules?.find((d) => d.id === id),
      ),
    [farmSchedules, set],
  )

  /** Options for date selector dropdown */
  const dateDropdownOpts = useMemo(
    () =>
      pickupDates.map((d) => ({
        label: d.toLocaleString(),
        value: d.toISODate(),
        key: d.toISODate(),
      })),
    [pickupDates],
  )

  /** Sets date from the date selector dropdown */
  const setDate = useCallback(
    (isoDate: string) =>
      set(
        'pickupDate',
        pickupDates.find((d) => d.toISODate() === isoDate),
      ),
    [set, pickupDates],
  )

  /** Distros should be sorted in groups of same location name, and within each location group, schedules sorted by schedule name */
  const distroDropdownOpts = useMemo(
    () =>
      (farmSchedules || [])
        .filter(
          (d) =>
            (isWholesale === undefined ? false : isWholesale ? isWholesaleSchedule(d) : isRetailSchedule(d)) &&
            !d.isHidden &&
            (dbProds?.length ? dbProds.find((p) => p.distributions?.find((pd) => pd.id === d.id)) : true) &&
            getPickups(d, undefined, {
              ignoreOrderCutoffWindow: true,
              ignoreDisableBuyInFuture: true,
            }).length > 0,
        )
        .map((d) => ({
          label: `${d.closed ? '[closed] ' : ''}${d.name} @ ${d.location.name}`,
          value: d.id,
          key: `${d.name} @ ${d.location.name} - ${d.id}`,
        }))
        /**
         * - SortBy name after combination distName and locationName
         * - closed distro should be also sorted by name of combined distName and locationName, not by [closed] prefix
         */
        .sort((a, b) => sortByName(a, b, (dist) => dist.key.toLocaleLowerCase())),
    [farmSchedules, dbProds, isWholesale],
  )

  return {
    loadingSchedules,
    setDistro,
    dateDropdownOpts,
    setDate,
    distroDropdownOpts,
  }
}
