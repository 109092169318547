import { memo } from 'react'
// eslint-disable-next-line no-restricted-imports
import { ActivityIndicator, ActivityIndicatorProps, StyleSheet } from 'react-native'

import Colors from '../../constants/Colors'

/**
 * Pre-configured Activity-Indicator
 *
 * Using this helps prevent the android issue where Loader elements without a "color" or "size" property sometimes aren't shown. At some point it was necessary to update all instances throughout the app, to fix that error.
 */
export const Spinner = memo(function Spinner({ style, ...args }: ActivityIndicatorProps) {
  return <ActivityIndicator color={Colors.green} size="large" style={[styles.spinner, style]} {...args} />
})

const styles = StyleSheet.create({
  spinner: { flex: 1, alignSelf: 'center' },
})
