import { ButtonClear, Text, TextH2 } from '@elements'

import React, { memo } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'

import Colors from '@/constants/Colors'
import { adminFarmSelector } from '@/redux/selectors'
import { Image } from '@components'
import { openUrl } from '@helpers/client'
import { Farm, FarmStatus } from '@models/Farm'
import { useSelector } from 'react-redux'
import { sharedStyles } from '../styles'
import { connectToStripe } from './PaymentsTab.controller'

/** Stripe connection section */
export function StripeConnection() {
  const farm = useSelector(adminFarmSelector)

  return (
    <View style={sharedStyles.baseGap}>
      <Text size={14}>
        GrownBy partners with Stripe for payment processing. Please create a Stripe account for GrownBy. If you already
        have a Stripe account, you can use your existing credentials to create a GrownBy Stripe account.
      </Text>

      <FarmStripeStatusInfo farm={farm} />
      <StripeImgComponent farm={farm} />
    </View>
  )
}

const FarmStripeStatusInfo = memo(function ComponentByStatus({ farm }: { farm: Farm }) {
  const goStripeDash = () => {
    openUrl(`https://dashboard.stripe.com/${farm.accountRef}`)
  }

  if (farm.status === FarmStatus.Registered) {
    return (
      <>
        <TextH2 color={Colors.green} style={{ color: Colors.green }}>
          Your GrownBy account is connected to Stripe!
        </TextH2>

        <ButtonClear title="Stripe Dashboard" onPress={goStripeDash} />
      </>
    )
  }

  if (farm.status === FarmStatus.NeedInfoStripe) {
    return (
      <TextH2 color={Colors.red}>
        Stripe needs more information about your farm. Please click the link below to complete your account setup.
      </TextH2>
    )
  }
  if (farm.status === FarmStatus.Claimed) {
    return (
      <TextH2 color={Colors.red}>
        All farms must create a Stripe account to sell on GrownBy. Please click the link below to get started.
      </TextH2>
    )
  }

  return <View />
})

const StripeImgComponent = memo(function StripeImgComponent({ farm }: { farm: Farm }) {
  if (farm.status === FarmStatus.Registered) return <View />
  return (
    <>
      <TextH2 size={16}>Connect your Stripe account by clicking the button</TextH2>

      <Pressable onPress={() => connectToStripe(farm)}>
        <Image style={styles.stripeImage} source={require('../../../../assets/stripe-connect-blurple-2x.png')} />
      </Pressable>
    </>
  )
})

const styles = StyleSheet.create({
  stripeImage: {
    width: 277,
    height: 44,
    borderRadius: 8,
  },
})
