import { Text, Touchable } from '@elements'
import { StyleSheet, View } from 'react-native'

import { HomeParamList } from '@/navigation/types'
import { formatMoney } from '@helpers/display'
import { isGeoProduct } from '@models/Algolia'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback } from 'react'
import { ProductInfoProps } from './ProductInfo'

/** Product information alongside farm details, placed under the image*/
export function ProductAndFarmInfo({ small, product, cardText }: ProductInfoProps) {
  const navigation = useNavigation<StackNavigationProp<HomeParamList>>()

  const onPress = useCallback(() => {
    if (!product) return

    navigation.navigate('Shopping', { screen: 'FarmDetailScreen', params: { farmSlug: product.farm.id } })
  }, [navigation, product])

  return (
    <View style={styles.textContWholesale}>
      <Text size={small ? 12 : 14} style={styles.nameText} numberOfLines={2}>
        {product?.name}
      </Text>
      {!!product?.farm.name && (
        <Touchable onPress={onPress} hitSlop={5}>
          <Text style={styles.underline} numberOfLines={2}>
            {product?.farm.name}
          </Text>
        </Touchable>
      )}
      <Text numberOfLines={2} size={small ? 10 : 12}>
        {cardText}
      </Text>
      {isGeoProduct(product) && product.farm.orderMin > 0 && <Text>{formatMoney(product.farm.orderMin)} min.</Text>}
    </View>
  )
}

const styles = StyleSheet.create({
  textContWholesale: {
    padding: 5,
    gap: 8,
  },
  nameText: {
    marginTop: 10,
  },
  underline: {
    textDecorationLine: 'underline',
  },
})
