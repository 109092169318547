import { StyleSheet, TextInput, View } from 'react-native'

export default function CopyEmails({ list }: { list: string[] }) {
  return (
    <View style={{ flex: 1, margin: 10 }}>
      <TextInput
        autoFocus
        selectTextOnFocus
        style={styles.textBox}
        multiline
        numberOfLines={8}
        value={list.reduce((_, itm) => itm + ',\n' + _, '')}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  textBox: {
    margin: 10,
    padding: 10,
    borderRadius: 5,
    borderWidth: 1,
    flex: 1,
  },
})
