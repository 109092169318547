import { ServiceUnavailable } from '@components'
import { NavigationContext } from '@react-navigation/native'
import * as React from 'react'
import { useSelector } from 'react-redux'

import { apiStatus } from '../api/App'
import { Modal, hideModal } from '../components/elements/Overlays/Popups'
import { AppStatus as AppStatusType } from '../models/AppStatus'
import { RootState } from '../redux/reducers/types'
import { isAdminSelector } from '../redux/selectors'

interface AppStatusProps {
  scope: 'consumer' | 'farmer'
}

// AppStatus provides a container which watches for app status updates from Firebase. If the application is taken
// offline the user will be presented with a notification of that taking place and will not be able to continue
// until service resumes. Service may be scoped to limit access to only certain parts of the application.
export function AppStatus({ scope, children }: React.PropsWithChildren<AppStatusProps>): JSX.Element {
  const [status, setStatus] = React.useState<AppStatusType>('enabled')
  const [modalVisible, setModalVisible] = React.useState(false)

  const navigation = React.useContext(NavigationContext)
  const isAdmin = useSelector<RootState, boolean | undefined>(isAdminSelector)

  React.useEffect(() => {
    return apiStatus((status) => {
      setStatus(status)
    })
  }, [])

  React.useEffect(() => {
    if (!isDisabled(status, scope)) {
      if (modalVisible) {
        setModalVisible(false)
        hideModal()
      }
      return
    }

    if (navigation && scope === 'consumer' && status === 'disabled-consumer' && isAdmin) {
      navigation.navigate('AdminDrawerNavigator')
      return
    }

    Modal(<ServiceUnavailable />, {
      header: false,
      dismissable: false,
      webWidth: 400,
    })

    setModalVisible(true)
  }, [status])

  return <>{children}</>
}

// isDisabled returns true if the app status indicates that the service is down within the supplied scope.
function isDisabled(status: AppStatusType, scope: 'consumer' | 'farmer'): boolean {
  switch (status) {
    case 'enabled':
      return false

    case 'disabled':
      return true

    case 'disabled-consumer':
      return scope === 'consumer'

    case 'disabled-farmer':
      return scope === 'farmer'
  }
}
