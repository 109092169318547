import { StyleSheet, View } from 'react-native'

import { Card } from './Card'
import { Text } from '../../../components/elements/Text'

import { useDeviceSize } from '@/hooks/useLayout'

interface TallyProps {
  title: string
  value?: string | number
}

export function Tally({ title, value }: TallyProps): JSX.Element {
  const { isExtraSmallDevice, isSmallDevice } = useDeviceSize()
  return (
    <Card>
      <View style={styles.heading}>
        <Text size={isExtraSmallDevice ? 14 : 16}>{title.toUpperCase()}</Text>
      </View>
      <Text size={isExtraSmallDevice ? 18 : isSmallDevice ? 24 : 32} type="bold">
        {value === undefined ? '...' : value}
      </Text>
    </Card>
  )
}

const styles = StyleSheet.create({
  heading: { marginBottom: 15 },
})
