import Colors from '@/constants/Colors'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { ListItem, ListItemAccordionProps as RNEListItemAccordionProps } from 'react-native-elements'
import { Text } from '../Text'

export type ListItemAccordionProps = Pick<RNEListItemAccordionProps, 'isExpanded' | 'children' | 'containerStyle'> & {
  title: string
  onToggle?: () => void
  wrapperStyle?: StyleProp<ViewStyle>
}

/** Wrapper component around RNE ListItem.Accordion */
export function ListItemAccordion({
  isExpanded,
  children,
  title,
  onToggle,
  wrapperStyle,
  containerStyle,
}: ListItemAccordionProps) {
  return (
    // This wraper view is needed in order to prevent parent-provided styles (i.e. gap) to be applied on both content and children container
    <View style={[styles.main, wrapperStyle]}>
      <ListItem.Accordion
        containerStyle={[styles.container, containerStyle]}
        isExpanded={isExpanded}
        onPress={onToggle}
        content={
          <Text type="medium" size={14}>
            {title}
          </Text>
        }
      >
        {children}
      </ListItem.Accordion>
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    gap: 10,
  },
  hovered: {
    borderColor: Colors.green,
  },
  container: {
    padding: 0,
    justifyContent: 'space-between',
  },
})
