import { AdminOrdersParamList } from '@/admin/navigation/types'
import { Logger } from '@/config/logger'
import { KeyedState } from '@/hooks/useKeyedState'
import { adminCartInfoSelector, adminFarmIdSelector, isWholesaleOrderCreatorSelector } from '@/redux/selectors'
import { addOrder } from '@api/Orders'
import { Alert, Loader } from '@elements'
import { errorToString, extendErr } from '@helpers/helpers'
import { SplitTenderPayment } from '@models/Order'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  OrderCreatorStatePayments,
  OrderCreatorStateProductsFilters,
  orderCreatorInitialStatePayments,
  orderCreatorInitialStateProductsFilters,
} from './OrderCreatorScreen.helper'

/** Handler for placing the order, intended to be called after the order configuration is validated */
export function usePlaceOrderProceed({
  keyedStatePayments: [{ markAsPaidInfo }, set, setStatePayments],
  keyedStateProdsFilters: { 2: setStateProds },
}: {
  keyedStatePayments: KeyedState<OrderCreatorStatePayments>
  keyedStateProdsFilters: KeyedState<OrderCreatorStateProductsFilters>
}) {
  const isWholesaleOrderCreator = useSelector(isWholesaleOrderCreatorSelector)
  const adminFarmId = useSelector(adminFarmIdSelector) || ''
  const navigation = useNavigation<StackNavigationProp<AdminOrdersParamList, 'OrderCreator'>>()

  const { cartId } = useSelector(adminCartInfoSelector)

  return useCallback(
    async (payments: SplitTenderPayment, startNew?: boolean) => {
      /**
       * Note: When this is called, a previous fn should've validated the order, and should've set the isSubmitting state to true.
       */

      if (!cartId) return Alert('Cart Id not found')
      if (typeof isWholesaleOrderCreator !== 'boolean') return // This shouldn't happen

      Loader(true)
      try {
        const result = await addOrder({
          cartId,
          farmId: adminFarmId,
          tender: payments,
          markAsPaidInfo,
          isDraft: false,
          isWholesale: isWholesaleOrderCreator,
        })

        if (!result?.id) {
          // This shouldn't happen
          throw new Error("Order wasn't created despite request success")
        }

        navigation.setParams({ custId: undefined }) // Clearing the customer id at the navigation level will trigger the customer fx and clear the customer state as well

        // reset the screen state. This reset will also set the loading state to false
        setStatePayments(orderCreatorInitialStatePayments)
        setStateProds(orderCreatorInitialStateProductsFilters)

        Loader(false)

        if (!startNew) {
          // If the admin is only placing one order then we should navigate to the orderDetails screen to see the order
          navigation.navigate('AdminOrderDetails', { orderId: result.id })
        }
      } catch (err) {
        Loader(false)
        set('isSubmitting', false)
        Alert('Error Placing Order', errorToString(err))
        Logger.error(extendErr(err, `Error placing an admin order. cartid: ${cartId}`))
      }
    },
    [cartId, markAsPaidInfo, navigation, setStatePayments, setStateProds, set, adminFarmId, isWholesaleOrderCreator],
  )
}
