import { getOrderNum, getPriceString, pickupPriceRangeString, unmarshalPhoneNumber } from '@helpers/display'
import { removeObjDuplicates } from '@helpers/helpers'
import { MoneyCalc } from '@helpers/money'
import { getPickups, orderItemTotal } from '@helpers/order'
import { omit } from '@helpers/typescript'

import {
  getCardPrice,
  getDefaultCatalog,
  getLastAvailTimestamp,
  getLowestPrice,
  getProductAvailability,
  getStock,
  isInStock,
  isPrivate,
  isProductEbtEligible,
  isProductEbtOnly,
  matchesAppModeProduct,
  matchesAppModeSchedule,
  shouldShow,
} from '@helpers/products'
import {
  AlgoliaAdminCustomer,
  AlgoliaAdminInvoice,
  AlgoliaAdminMeta,
  AlgoliaAdminMetaArr,
  AlgoliaAdminOrder,
  AlgoliaAdminOrderMeta,
  AlgoliaAdminProduct,
  AlgoliaDocType,
  AlgoliaGeoDistro,
  AlgoliaGeoDoc,
  AlgoliaGeoFarm,
  AlgoliaGeoProduct,
  AlgoliaIndexType,
  AlgoliaShareCategory,
} from '@models/Algolia'
import { CSA } from '@models/CSA'
import { Distribution, isDistroLocalPickup, isRetailSchedule, isWholesaleSchedule } from '@models/Distribution'
import { Farm, FarmStatus } from '@models/Farm'
import { Invoice } from '@models/Invoice'
import { Zero } from '@models/Money'
import { CartItem, Order, OrderItem } from '@models/Order'
import { FarmBalance } from '@models/Payment'

import { shortenCoordinate } from '@helpers/coordinate'
import { chainPaymentsMethod } from '@helpers/paymentMethodDisplay'
import { formatScheduleText } from '@helpers/schedule'
import { displayShortDateRange } from '@helpers/time'
import { DraftOrder, isDraftOrder } from '@models/DraftOrder'
import {
  DefaultCatalog,
  PhysicalProduct,
  Product,
  Standard,
  hasUnitStock,
  hasUnits,
  isDigital,
  isPhysical,
  isShare,
  isStandard,
} from '@models/Product'
import { FarmAssociation, User, userName } from '@models/User'
import { DateTime } from 'luxon'
import { getOrderStatusLabel } from '../orders-display'

/** Builds the nested farm object inside all geodocs */
export const buildGeoDocFarm = (farm: Farm, catalog: AlgoliaGeoDoc['defaultCatalog']): AlgoliaGeoDoc['farm'] => ({
  id: farm.id,
  name: farm.name,
  about: farm.about.substring(0, 500),
  logo: farm.logo,
  practices: farm.practices,
  tags: Object.keys(farm.properties || {}),
  reviews: farm.reviews,
  hasProducts: farm.productCount > 0,
  setupCount: Object.keys(farm.onboardSteps || {}).length,
  productCount: farm.productCount || 0,
  status: farm.status,
  urlSafeSlug: farm.urlSafeSlug,
  isWholesale: !!farm.isWholesale,
  orderMin: farm.orderMinimum?.[catalog === DefaultCatalog.Wholesale ? 'wholesale' : 'retail']?.value || 0,
  dueDateTolerance: farm.dueDateTolerance?.[catalog === DefaultCatalog.Wholesale ? 'wholesale' : 'retail'],
})

/** Builds an algolia geo farm doc */
export function buildGeoFarm({
  farm,
  catalog,
}: {
  farm: Farm
  catalog: AlgoliaGeoDoc['defaultCatalog']
}): AlgoliaGeoDoc<AlgoliaGeoFarm> {
  if (catalog === DefaultCatalog.Wholesale && !farm.isWholesale) {
    throw new Error("A non-wholesale farm can't have a wholesale document")
  }

  const images = farm.media.map((obj) => obj.storageUrl)
  const farmDoc: AlgoliaGeoDoc<AlgoliaGeoFarm> = {
    index: AlgoliaIndexType.geosearch,
    defaultCatalog: catalog,
    objectID: `${catalog}_${farm.id}`,
    id: farm.id,
    images,
    imageSort: images.length ? 1 : 0,
    name: farm.name,
    address: omit(farm.address, 'coordinate'),
    locationCount: farm.locationCount,
    description: farm.about.substring(0, 500),
    _geoloc: {
      lat: farm.address.coordinate.latitude,
      lng: farm.address.coordinate.longitude,
    },
    isEbt: farm.paymentTypes.ebt,
    docType: AlgoliaDocType.FARM,
    farm: buildGeoDocFarm(farm, catalog),
  }

  return farmDoc
}

/** Builds all the geosearch documents expected for a farm object */
export function buildFarmGeoDocs(farm: Farm) {
  if (farm.isWholesale) {
    return [
      buildGeoFarm({ farm, catalog: DefaultCatalog.Wholesale }),
      buildGeoFarm({ farm, catalog: DefaultCatalog.Retail }),
    ]
  } else {
    return [buildGeoFarm({ farm, catalog: DefaultCatalog.Retail })]
  }
}

/** Builds a geo distro doc */
export function buildGeoDistro({
  distro,
  farm,
  catalog,
}: {
  distro: Distribution
  farm: Farm
  catalog: AlgoliaGeoDoc['defaultCatalog']
}): AlgoliaGeoDoc<AlgoliaGeoDistro> {
  if (catalog === DefaultCatalog.Wholesale && !isWholesaleSchedule(distro)) {
    throw new Error("Can't create a wholesale geo distro for a non-wholesale distro ")
  }
  if (catalog === DefaultCatalog.Retail && !isRetailSchedule(distro)) {
    throw new Error("Can't create a retail geo distro for a non-retail distro ")
  }

  const images = farm.media.map((obj) => obj.storageUrl)
  const distroDoc: AlgoliaGeoDoc<AlgoliaGeoDistro> = {
    index: AlgoliaIndexType.geosearch,
    defaultCatalog: catalog,
    objectID: `${catalog}_${distro.id}`,
    id: distro.id,
    name: distro.name,
    images,
    imageSort: images.length ? 1 : 0,
    isHidden: distro.isHidden,
    address:
      isDistroLocalPickup(distro) && distro.location.address
        ? omit(distro.location.address, 'coordinate')
        : omit(farm.address, 'coordinate'),
    scheduleText: formatScheduleText(distro),
    distroNickname: distro.location.nickname || distro.location.name,
    _geoloc:
      isDistroLocalPickup(distro) && distro.location.address
        ? shortenCoordinate(distro.location.address.coordinate)
        : undefined,
    locationId: distro.location.id,
    isEbt: farm.paymentTypes.ebt,
    docType: AlgoliaDocType.DISTRO,
    farm: buildGeoDocFarm(farm, catalog),
    description: farm.about.substring(0, 500),
  }

  return distroDoc
}

/** Builds all the geosearch documents expected of a single schedule */
export function buildDistroGeoDocs({ distro, farm }: { distro: Distribution; farm: Farm }) {
  const docs: AlgoliaGeoDoc<AlgoliaGeoDistro>[] = []

  if (distro.isHidden || distro.closed || farm.status !== FarmStatus.Registered) {
    // Should only create geo distros if they're visible, taking orders and the farm is registered
    return docs
  }

  const pickups = getPickups(distro, undefined, {
    excludeClosedDistros: true,
    ignoreDisableBuyInFuture: false,
    ignoreOrderCutoffWindow: false,
    useCache: true,
  })

  if (!pickups.length) return docs

  if (isWholesaleSchedule(distro) && farm.isWholesale) {
    docs.push(buildGeoDistro({ distro, farm, catalog: DefaultCatalog.Wholesale }))
  }
  if (isRetailSchedule(distro)) {
    docs.push(buildGeoDistro({ distro, farm, catalog: DefaultCatalog.Retail }))
  }
  return docs
}

type BuildGeoProdOpts = {
  farm: Farm
  /** A schedule in the product for which to create a geo product at this distro's location. This is required for physical prods */
  distro?: Distribution
  prod: Product
  /** CSAs the product is associated with. Required for product creation */
  csas: CSA[]
  /** Which catalog to build the document for. Required for standard prods. If undefined, retail is assumed */
  catalog: DefaultCatalog.Retail | DefaultCatalog.Wholesale
}

/** Returns the isHidden value for algolia geo products based on a schedule combo and the product CSAs */
const getIsHiddenProduct = (
  prod: Product,
  prodCsas: Pick<CSA, 'isHidden'>[],
  catalog: AlgoliaGeoDoc['defaultCatalog'],
  distro?: Pick<Distribution, 'isHidden'>,
) => {
  // We should hide if either the distribution or the product is hidden, or if the share has only hidden csas
  return (
    distro?.isHidden ||
    prod.isHidden ||
    (isShare(prod) && (!prodCsas.length || prodCsas.every((csa) => csa.isHidden))) ||
    !shouldShow(prod, { isWholesale: catalog === DefaultCatalog.Wholesale })
  )
}

/** Builds a geo product doc */
export function buildGeoProduct({
  prod,
  farm,
  distro,
  csas,
  catalog,
}: BuildGeoProdOpts): AlgoliaGeoDoc<AlgoliaGeoProduct> {
  if (!catalog && isStandard(prod)) {
    throw new Error('A standard geo product requires a catalog specified')
  }
  if (!isStandard(prod) && catalog && catalog !== DefaultCatalog.Retail) {
    throw new Error("Non-standard products don't support wholesale geo documents")
  }
  if (isPhysical(prod) && !distro) {
    throw new Error('Tried building a physical geo prod without a distro')
  }
  if (distro && catalog === DefaultCatalog.Wholesale && !isWholesaleSchedule(distro)) {
    throw new Error("Can't build a wholesale doc with a non-wholesale distro")
  }
  if (distro && (!catalog || catalog === DefaultCatalog.Retail) && !isRetailSchedule(distro)) {
    throw new Error("Can't build a retail doc with a non-retail distro")
  }
  if (prod.csa?.length && prod.csa.some((id) => !csas.find((csa) => csa.id === id))) {
    // Check the csas array has all the csas for the product
    throw new Error('Tried to build a geoProd but some csas are missing')
  }

  const categories = [prod.category]
  if (isShare(prod) || prod.csa?.length) categories.push(AlgoliaShareCategory)

  const prodCsas = csas.filter((csa) => prod.csa?.includes(csa.id))

  // We should hide if either the distribution or the product is hidden, or if the share has only hidden csas
  const isHidden = getIsHiddenProduct(prod, prodCsas, catalog, distro)

  const prodDoc: AlgoliaGeoDoc<AlgoliaGeoProduct> = {
    index: AlgoliaIndexType.geosearch,
    defaultCatalog: catalog ?? DefaultCatalog.Retail,
    objectID: buildProductObjectID(prod, distro, catalog),
    id: prod.id,
    isHidden,
    urlSafeSlug: prod.urlSafeSlug,
    distroNickname: distro?.name ?? '',
    name: prod.name,
    images: prod.images,
    imageSort: prod.images.length ? 1 : 0,
    description: prod.longDescription.substring(0, 500),
    category: categories,
    priceInMap: isShare(prod)
      ? pickupPriceRangeString(prod)
      : getPriceString(prod, { isWholesale: catalog === DefaultCatalog.Wholesale }),
    priceInCard: getCardPrice(prod, { isWholesale: catalog === DefaultCatalog.Wholesale }),
    priceFilter: getLowestPrice(prod, catalog === DefaultCatalog.Wholesale)?.amount.value,
    type: prod.type,
    shortDescription: prod.description.substring(0, 500),
    _geoloc:
      distro && isDistroLocalPickup(distro) && distro.location.address
        ? shortenCoordinate(distro.location.address.coordinate)
        : undefined,
    isEbt: isProductEbtEligible(prod),
    isEbtOnly: isProductEbtOnly(prod),
    scheduleText: formatScheduleText(distro),
    docType: AlgoliaDocType.PRODUCT,
    farm: buildGeoDocFarm(farm, catalog),
    address: omit(
      distro && isDistroLocalPickup(distro) && distro.location.address ? distro.location.address : farm.address,
      'coordinate',
    ),
    lastAvailStamp: getLastAvailStampNumericFilter(prod, catalog),
    isInStock: isInStock(prod, { isWholesale: catalog === DefaultCatalog.Wholesale }),
    isPrivate: catalog === DefaultCatalog.Retail ? isPrivate(prod, prodCsas) : false,
    hideFromShop: catalog === DefaultCatalog.Retail ? hasUnits(prod) && !!prod.hideFromShop : false,
    csa: prod.csa ?? [],
    locations: getGeoDocLocations(prod, catalog),
    // There will be a maximum of 600 regions per document, because our rough calculations demonstrated this is equivalent to 9.5KB, and algolia's free tier allows a maximum of 10KB per document
    regions: distro?.location.regions?.slice(0, 600) ?? ['None'],
    isFeatured: prod.isFeatured ?? false,
  }

  return prodDoc
}

/** Builds the locations data for a product geo doc for the given default catalog */
function getGeoDocLocations(prod: Product, catalog: AlgoliaGeoDoc['defaultCatalog']): AlgoliaGeoProduct['locations'] {
  const locationsForCatalog: AlgoliaGeoProduct['locations'] =
    prod.distributions
      ?.filter(matchesAppModeSchedule(catalog === DefaultCatalog.Wholesale))
      .map((d) => ({ id: d.location.id, name: d.location.name })) ?? []

  // Make sure the locations are unique
  return removeObjDuplicates(locationsForCatalog, (obj) => obj.id)
}

/** This helper is a convenient wrapper around the product geodoc builder, which will produce all the algolia geosearch documents that should be associated with a given product */
export function buildProductGeoDocs({
  prod,
  farm,
  csas,
}: Pick<BuildGeoProdOpts, 'prod' | 'farm' | 'csas'>): AlgoliaGeoDoc<AlgoliaGeoProduct>[] {
  // If the farm isn't registered it will have no products in the index
  if (farm.status !== FarmStatus.Registered) return []

  if ((isShare(prod) && (!csas.length || csas.every((csa) => csa.isHidden))) || !shouldShow(prod)) {
    // If the product doesn't meet basic conditions to be considered relevant for search, don't build any geo docs for it
    return []
  }

  const buildGeoProdsByDistro = (prod: PhysicalProduct, catalog: AlgoliaGeoDoc['defaultCatalog']) =>
    prod.distributions

      .filter((sch) => !sch.isHidden && !sch.closed)
      .filter(matchesAppModeSchedule(catalog === DefaultCatalog.Wholesale))
      .filter((sch) => {
        const pickups = getPickups(sch, prod, {
          excludeClosedDistros: true,
          ignoreOrderCutoffWindow: false,
          ignoreDisableBuyInFuture: false,
          useCache: true,
        })
        return !!pickups.length
      })
      .map((dist) => buildGeoProduct({ prod, distro: dist, csas, farm, catalog }))

  const buildGeoProdsByCatalog = (prod: Standard, farm: Farm) => {
    const docs = []

    if (matchesAppModeProduct(false)(prod)) {
      docs.push(...buildGeoProdsByDistro(prod, DefaultCatalog.Retail))
    }

    if (matchesAppModeProduct(true)(prod) && farm.isWholesale) {
      // If the product is wholesale-only or wholesale-retail, and the farm is registered for wholesale, also build the wholesale docs
      docs.push(...buildGeoProdsByDistro(prod, DefaultCatalog.Wholesale))
    }

    return docs
  }

  if (isStandard(prod)) {
    // For physical types compatible with wholesale and retail, build docs based on catalog
    return buildGeoProdsByCatalog(prod, farm)
  } else if (isPhysical(prod)) {
    // For physical types only compatible with retail, build docs for retail
    return buildGeoProdsByDistro(prod, DefaultCatalog.Retail)
  } else {
    // For digital products, create a single document
    return [buildGeoProduct({ prod, farm, csas, catalog: DefaultCatalog.Retail })]
  }
}

/**
 * ADMIN ALGOLIA INDICES
 */

function createOrderMeta(orderItems: (OrderItem | CartItem)[], orderDate: DateTime): AlgoliaAdminOrderMeta {
  const date = orderDate.toMillis()
  const csas = orderItems.filter((itm) => itm.csa?.id).map((itm) => ({ id: itm.csa!.id, name: itm.csa!.name, date }))
  const products = orderItems.map((itm) => ({ id: itm.product.id, name: itm.product.name, date }))
  const locations = orderItems
    .filter((itm) => !!itm.distribution)
    .map((itm) => ({ id: itm.distribution!.location.id, name: itm.distribution!.location.name, date }))
  const distributions = orderItems
    .filter((itm) => !!itm.distribution)
    .map((itm) => ({ id: itm.distribution!.id, name: itm.distribution!.name, date }))
  return {
    csas: removeObjDuplicates(csas),
    products: removeObjDuplicates(products),
    locations: removeObjDuplicates(locations),
    distributions: removeObjDuplicates(distributions),
  }
}

/**
 *
 * @param product db product
 * @param csasArg csas array. Could be all of the farm csas, or only the product csas. This fn will only select those csas whose ids are in the product.csa ids list.
 * @returns
 */
function createProductMeta(product: Product, csasArg?: CSA[]): AlgoliaAdminMeta {
  const date = 0
  let csas: AlgoliaAdminMetaArr = []
  if (csasArg) {
    csas = csasArg.filter((csa) => product.csa?.includes(csa.id)).map((csa) => ({ id: csa.id, name: csa.name, date }))
  }
  const locations = (product.distributions ?? [])
    .filter((itm) => !!itm.location?.name)
    .map((itm) => ({ id: itm.location.id, name: itm.location.name, date }))
  const distributions = (product.distributions ?? [])
    .filter((itm) => !!itm.name)
    .map((itm) => ({ id: itm.id, name: itm.name, date }))
  return {
    csas: removeObjDuplicates(csas),
    locations: removeObjDuplicates(locations),
    distributions: removeObjDuplicates(distributions),
  }
}

export function createAlgoliaAdminOrder(order: Order): AlgoliaAdminOrder
export function createAlgoliaAdminOrder(order: DraftOrder, items: CartItem[]): AlgoliaAdminOrder
/** Will create an Algolia Admin order doc from either a Draft or finalized order. CartItems are required if it is a draft*/
export function createAlgoliaAdminOrder(order: Order | DraftOrder, cartItems?: CartItem[]): AlgoliaAdminOrder {
  const items = isDraftOrder(order) ? cartItems! : order.items

  // Compute the order subtotal using either the cart item total or the order total. If it is a draft order, get the subtotal from the draft order directly.
  const orderSubtotal = isDraftOrder(order)
    ? order.subtotal
    : order.items.map((itm) => orderItemTotal(itm)).reduce((a, b) => MoneyCalc.add(a, b), Zero)

  return {
    index: AlgoliaIndexType.admindata,
    id: order.id,
    objectID: order.id,
    docType: AlgoliaDocType.ORDER,
    farmId: order.farm.id,
    orderNum: order.orderNum,
    alternateOrderNums: [getOrderNum(order.orderNum).replace('#', ''), (order.orderNum || '').toString()],
    isWholesale: !!order.isWholesale,
    isDraft: isDraftOrder(order),
    user: {
      id: order.user.id,
      name: userName(order.user),
      email: order.user.email,
    },
    total: orderSubtotal.value,
    date: order.date.toMillis(),
    status: getOrderStatusLabel(order),
    ...createOrderMeta(items, order.date),
  }
}

export const createAlgoliaAdminInvoice = (invoice: Invoice): AlgoliaAdminInvoice => {
  return {
    index: AlgoliaIndexType.admindata,
    id: invoice.id,
    objectID: invoice.id,
    docType: AlgoliaDocType.INVOICE,
    status: invoice.status,
    farmId: invoice.farm.id,
    date: invoice.dueDate.toMillis(),
    amountDue: invoice.amountTotal.value,
    invoiceNum: invoice.invoiceNum,
    payUrl: invoice.payUrl,
    pdf: invoice.pdf,
    order: {
      id: invoice.order.id,
      orderNum: invoice.order.orderNum,
    },
    user: {
      id: invoice.user.id,
      name: userName(invoice.user),
      email: invoice.user.email,
    },
    paymentMethods: chainPaymentsMethod(invoice),
    paymentMethodsDisplay: chainPaymentsMethod(invoice, true),
  }
}

/** Gets a string that will represent the pre-calculated product availability for display purposes */
export const getAvailabilityDisplay = (prod: Product, opts: { catalog?: AlgoliaGeoDoc['defaultCatalog'] } = {}) => {
  if (isDigital(prod)) return 'Digital'

  const availDates = getProductAvailability(prod, undefined, {
    excludeClosedDistros: true,
    zone: prod.farm.timezone,
    isWholesale: opts.catalog === undefined ? undefined : opts.catalog === DefaultCatalog.Wholesale,
  })

  if (!availDates) return ''

  return displayShortDateRange(availDates)
}

/** Assembles an admin product document */
export function createAlgoliaAdminProduct(prod: Product, csas?: CSA[]): AlgoliaAdminProduct {
  // Generate categories
  const categories = [prod.category]
  if (isShare(prod) || prod.csa?.length) categories.push(AlgoliaShareCategory)

  return {
    index: AlgoliaIndexType.admindata,
    id: prod.id,
    objectID: prod.id,
    farmId: prod.farm.id,
    docType: AlgoliaDocType.PRODUCT,
    type: prod.type,
    unitStock: hasUnits(prod) && prod.unitStock,
    name: prod.name,
    description: prod.description.substring(0, 500),
    longDescription: prod.longDescription.substring(0, 500),
    skuDisplay: isShare(prod) ? prod.sku || '' : prod.unitSkuPrefix || '',
    productionMethod: prod.productionMethod || '',
    category: categories,
    isHidden: prod.isHidden,
    lastAvailStamp: getLastAvailStampNumericFilter(prod, undefined),
    isEbt: isProductEbtEligible(prod),
    isEbtOnly: isProductEbtOnly(prod),
    isFeatured: !!prod.isFeatured,
    image: prod.images[0],
    price: getPriceString(prod),
    pricePerUnit: hasUnitStock(prod) ? prod.pricePerUnit : undefined,
    stock: getStock(prod),
    urlSafeSlug: prod.urlSafeSlug,
    availabilityDisplay: getAvailabilityDisplay(prod),
    defaultCatalog: getDefaultCatalog(prod),
    ...createProductMeta(prod, csas),
  }
}

/** This should be the single source of logic for how to create an algolia customer.
 * - It should be used in the server and gb-cli scripts alike. For that, it needs to be agnostic to how data is loaded or saved.
 * So it needs to receive all the necessarydata and only execute the creation logic synchronously.
 * @param association the FarmAssociation between the user and the farm
 * @param user the firestore user
 * @param orders aray of orders between the `user` and the farm in `association`.
 * if not provided, it will produce empty arrays for metadata attributes.
 * can be used with a single order, when updating the metadata only.
 * @param farmBalance the farm balance between the user and the farm in `association`. May be undefined if not yet exists.
 * @returns Will always return an AlgoliaAdminCustomer, because no checks or async requests are involved.
 *  */
export function createAlgoliaAdminCustomer(
  association: FarmAssociation,
  user: User,
  orders?: Order[],
  farmBalance?: FarmBalance,
): AlgoliaAdminCustomer {
  const farmId = association.farmId || association.id
  const phoneNumber = user.phoneNumber || ''

  return {
    index: AlgoliaIndexType.admindata,
    id: user.id,
    objectID: user.id + '_' + farmId,
    docType: AlgoliaDocType.CUSTOMER,
    farmId,
    name: userName(user),
    email: user.email,
    phone: phoneNumber,
    alternatePhoneNums: [unmarshalPhoneNumber(phoneNumber), phoneNumber, phoneNumber.replace('+1 ', '')],
    farmCredit: farmBalance?.amount || 0,
    monthlyReloadAmt: farmBalance?.reload?.enabled ? farmBalance.reload.amount : 0,
    ...createCustomerMeta(orders),
  }
}

/** Takes in the orders between a customer and a farm, and creates the metadata for those orders.
 *  Can be used for a single order, when updating the metadata only */
function createCustomerMeta(orders?: Order[]): AlgoliaAdminMeta {
  const csas: AlgoliaAdminMetaArr = []
  const locations: AlgoliaAdminMetaArr = []
  const distributions: AlgoliaAdminMetaArr = []

  orders?.forEach((order) => {
    const date = order.date.toMillis()
    csas.push(
      ...order.items.filter((itm) => itm.csa?.id).map((itm) => ({ id: itm.csa!.id, name: itm.csa!.name, date })),
    )
    locations.push(
      ...order.items
        .filter((itm) => !!itm.distribution?.location?.name)
        .map((itm) => ({ id: itm.distribution!.location.id, name: itm.distribution!.location.name, date })),
    )
    distributions.push(
      ...order.items
        .filter((itm) => !!itm.distribution?.name)
        .map((itm) => ({ id: itm.distribution!.id, name: itm.distribution!.name, date })),
    )
  })
  return {
    csas: removeObjDuplicates(csas),
    locations: removeObjDuplicates(locations),
    distributions: removeObjDuplicates(distributions),
  }
}

/**This helper ensures all products in algolia have a lastAvailStamp numeric value.
 * - In some cases the value is coerced (as in digital products).
 */
function getLastAvailStampNumericFilter(prod: Product, catalog: AlgoliaGeoDoc['defaultCatalog'] | undefined): number {
  const lastAvailStamp = getLastAvailTimestamp(
    prod,
    typeof catalog === 'boolean' ? catalog === DefaultCatalog.Wholesale : undefined,
    true,
  )
  if (lastAvailStamp !== null) return lastAvailStamp.toMillis()

  // If the lastAvailStamp is not available this means that the product is either invalid or does not have availability. We will return a number that can help us use filter this product in the search
  if (isPhysical(prod)) {
    // For physical products return 0 because this will make them appear to be in the distant past, so they will not be shown in the results
    return 0
  } else {
    // For digital products return a value far into the future so they will still appear in the search as always available
    return DateTime.now().plus({ years: 100 }).toMillis()
  }
}

/** Creates the Algolia Object ID for a product.
 * - If product is physical, it requires a schedule.
 * - If product is digital, no schedule.
 */
export const buildProductObjectID = (
  product: Product,
  distro?: Distribution,
  catalog: AlgoliaGeoDoc['defaultCatalog'] = DefaultCatalog.Retail,
): AlgoliaGeoDoc<AlgoliaGeoProduct>['objectID'] => {
  // Validate the data makes sense for the catalog requested
  if (catalog === DefaultCatalog.Wholesale) {
    if (distro && !isWholesaleSchedule(distro)) {
      throw new Error("Can't create a wholesale object id for a non-wholesale schedule")
    }
    if (!isStandard(product) || !product.defaultCatalog || product.defaultCatalog === DefaultCatalog.Retail) {
      throw new Error("Can't create a wholesale object id for a non-wholesale product")
    }
  } else if (catalog === DefaultCatalog.Retail) {
    if (distro && !isRetailSchedule(distro)) {
      throw new Error("Can't create a retail object id for a non-retail schedule")
    }
    if (isStandard(product) && product.defaultCatalog === DefaultCatalog.Wholesale) {
      throw new Error("Can't create a retail object id for a non-retail product")
    }
  } else {
    throw new Error('Wrong catalog value for a geo document')
  }

  if (isDigital(product)) {
    // Digital products get "digital" instead of the distro id

    return `${catalog}_digital_${product.id}`
  } else {
    // Physical products require a distro for the id

    if (!distro) {
      // If no distro then it is expected to be a digital product. Check if that's true.
      throw new Error('A physical product requires a schedule to create a geosearch object id')
    }

    return `${catalog}_${distro.id}_${product.id}`
  }
}
