import Colors, { colorBank, colorBankCodes, HexColor } from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { BottomSheetList, ErrorText, Hoverable, Text, Touchable } from '@elements'
import { memo, ReactNode, useCallback, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { FormSharedProps } from './helpers/types'

type CUSTOM_COLOR_PICKER_PROPS = {
  color: HexColor
  onChange: (color: HexColor) => void
  placeholder?: string
}

type FormPickerInputType = FormSharedProps &
  CUSTOM_COLOR_PICKER_PROPS & {
    /** Props specific to form inputs that will be rendered alongside the inputs */
    errorMessage?: string
    label?: string | ReactNode
  }

/** Bottom Sheet Picker for picking colors on forms */
export const FormColorPicker = memo(function FormColorPicker({
  containerStyle,
  errorMessage,
  label,
  row = false,
  onChange,
  color,
  placeholder,
}: FormPickerInputType) {
  const [openColorPicker, setOpenColorPicker] = useState(false)
  const toggleColorPickerVisible = useCallback(() => setOpenColorPicker((openColorPicker) => !openColorPicker), [])
  const styles = useStyles(row, color)
  return (
    <>
      <Hoverable>
        {(isHovered) => (
          <View style={[styles.direction, containerStyle]}>
            <Text size={14}>{label}</Text>
            <View style={[styles.dropdownGroup, isHovered && styles.onHover]}>
              <Touchable style={styles.dropdownButton} onPress={toggleColorPickerVisible}>
                <View style={styles.colorPickerIndicator} />
                <Text>{colorBankCodes[color] ?? placeholder}</Text>
              </Touchable>
            </View>
          </View>
        )}
      </Hoverable>
      {!!errorMessage && <ErrorText style={styles.errorText}>{errorMessage}</ErrorText>}
      <BottomSheetList<HexColor>
        onBackdropPress={toggleColorPickerVisible}
        isVisible={openColorPicker}
        items={colorBank}
        ListHeaderComponent={
          <View style={globalStyles.flexRow}>
            <Text size={14} style={styles.itemText}>
              Select Schedule Color
            </Text>
          </View>
        }
        renderItem={({ item }) => {
          const isSelected = item === color
          return (
            <TouchableOpacity
              disabled={isSelected}
              onPress={() => {
                onChange(item)
                setOpenColorPicker(false)
              }}
              style={styles.colorPickerOption}
            >
              <View style={styles.colorPickerRow}>
                <View style={[styles.colorPickerIndicator, { backgroundColor: item }]} />
                <Text>{colorBankCodes[item]}</Text>
              </View>
            </TouchableOpacity>
          )
        }}
      />
    </>
  )
})

const useStyles = (row: boolean, color: HexColor) =>
  useSizeFnStyles(
    ({ isSmallDevice }, row, color) => ({
      itemText: { paddingLeft: 5 },
      colorPickerOption: {
        padding: 10,
        borderTopColor: Colors.shades['100'],
        borderTopWidth: 0.5,
      },
      errorText: { marginTop: 5, marginLeft: 15 },
      dropdownGroup: {
        flexDirection: 'row',
        width: isSmallDevice ? '100%' : '97%',
        marginRight: 10,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: Colors.shades['100'],
        backgroundColor: Colors.white,
        height: 46,
        marginVertical: 10,
      },
      dropdownButton: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        paddingHorizontal: 10,
      },
      colorPickerIndicator: {
        width: 15,
        height: 15,
        borderRadius: 7.5,
        marginRight: 10,
        backgroundColor: color,
      },
      colorPickerRow: {
        flexDirection: 'row',
        width: '90%',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
      onHover: {
        borderColor: Colors.green,
        borderWidth: 1,
      },
      selectColor: {
        width: 15,
        height: 15,
        borderRadius: 7.5,
        marginLeft: 10,
        marginTop: 7.5,
      },
      direction: {
        flexDirection: row ? 'row' : 'column',
      },
    }),
    row,
    color,
  )
