import {
  AntDesign,
  Entypo,
  EvilIcons,
  Feather,
  FontAwesome,
  FontAwesome5,
  Fontisto,
  Foundation,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
  Octicons,
  SimpleLineIcons,
  Zocial,
} from '@expo/vector-icons'
import { Icon as IconVectorIcons } from '@expo/vector-icons/build/createIconSet'
import { memo } from 'react'
import { TextProps } from 'react-native'

import Colors from '../../../constants/Colors'
import { withColorHover } from '../../../hooks/withColorHover'
import { fontSize } from '../Text'
import { FontAwesome5IconName } from './iconNames'

const IconLibs = {
  FontAwesome5,
  AntDesign,
  Entypo,
  EvilIcons,
  Feather,
  FontAwesome,
  Fontisto,
  Foundation,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
  Octicons,
  SimpleLineIcons,
  Zocial,
}
type IconLibsType = typeof IconLibs
type IconLibsOpts = {
  [K in keyof IconLibsType]: IconLibsType[K] extends IconVectorIcons<infer IconNames, any> ? IconNames : string
}
export type IconLibsNames = keyof typeof IconLibs

export type IconNamesFromLib<LibName extends IconLibsNames | undefined> = LibName extends undefined
  ? FontAwesome5IconName
  : LibName extends 'FontAwesome5'
  ? FontAwesome5IconName
  : LibName extends IconLibsNames
  ? IconLibsOpts[LibName]
  : never

export type IconProps<LibName extends IconLibsNames | undefined = undefined> = TextProps & {
  size?: number
  name: IconNamesFromLib<LibName>
  color?: string
  solid?: boolean
  noHover?: boolean
  iconSet?: LibName
}

/**
 * Find icons:
 *
 * https://fontawesome.com/v5/search?o=r&m=free
 *
 * https://oblador.github.io/react-native-vector-icons/
 *
 * https://icons.expo.fyi/
 */
function IconComp<LibName extends IconLibsNames | undefined = undefined>({
  size = 20,
  color = Colors.green,
  iconSet,
  ...props
}: IconProps<LibName>) {
  const Lib = IconLibs[iconSet ?? 'FontAwesome5'] as IconVectorIcons<any, any>

  return <Lib size={fontSize(size)} color={props.disabled ? Colors.shades['100'] : color} {...props} />
}
export const Icon = memo(withColorHover(IconComp)) as typeof IconComp
export * from './iconNames'
