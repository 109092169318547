import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useFocusFx } from '@/hooks/useFocusFx'
import { Text } from '@elements'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import {
  InvalidAmount,
  capitalizeFirst,
  getPickupPriceRangeForPaySchedule,
  getSharePriceShortText,
  pickupPriceSimple,
  toMoneyRange,
} from '@helpers/display'
import { getPaymentSchedules } from '@helpers/order'
import { sortByAmount } from '@helpers/sorting'
import { PaymentSchedule, Share, isPayInFull, isShare } from '@models/Product'
import { memo, useMemo, useState } from 'react'
import { View } from 'react-native'
import { styles } from './ProductDetails'
import { makeTitle } from './helpers'

export const SharePriceDetails = memo(function SharePriceDetails({ product }: { product: Share }) {
  const [paySchedules, setPaySchedules] = useState<PaymentSchedule[]>([])
  const hasSamePrice = paySchedules.every((ps) => ps.amount.value === paySchedules[0].amount.value)

  useFocusFx(() => {
    if (isShare(product)) {
      const schedules = getPaymentSchedules({ product })
      schedules.sort(sortByAmount((sch: PaymentSchedule) => sch.amount.value))
      setPaySchedules(schedules)
    }
  }, [product])

  const getPayMethodsDescription = () => {
    const { paymentSchedules } = product
    if (!paymentSchedules.length) {
      return 'No payment schedules available'
    }
    if (paymentSchedules.every(isPayInFull)) return ''

    const frequencies = paymentSchedules.map((pmt) =>
      pmt.frequency === 'ONCE' ? 'pay-in-full' : pmt.frequency.toLowerCase(),
    )

    let result = capitalizeFirst(frequencies.join(', '))

    if (!paymentSchedules.some(isPayInFull) || paymentSchedules.length > 1) {
      result += ' installments available'
    }
    return result
  }

  const pickupPrice = useMemo(() => {
    const priceTxt = pickupPriceSimple(product)
    return priceTxt !== InvalidAmount ? `${priceTxt}/share` : 'Price not available'
  }, [product])

  const sharePriceShortText = useMemo(() => {
    const priceTxt = getSharePriceShortText(product)
    if (priceTxt === InvalidAmount) {
      // We don't want to show N/A on the product details screen
      return ''
    }
    return priceTxt
  }, [product])

  const getPsString = (paySchedule: PaymentSchedule) => {
    const txt = toMoneyRange(getPickupPriceRangeForPaySchedule(product, paySchedule)) + '/pickup'
    if (txt === InvalidAmount) return ''
    return txt
  }

  const localStyles = useSizeFnStyles(({ isLargeDevice }) => ({
    payMethodsDescr: {
      marginBottom: isLargeDevice ? 40 : 20,
      marginTop: 15,
    },
    paySchedules: { marginBottom: isLargeDevice ? 40 : 20, marginTop: 15 },
  }))

  return (
    <>
      <Text numberOfLines={1} type="bold" size={16} style={styles.sharePriceText}>
        {sharePriceShortText}
      </Text>
      <Text>{pickupPrice}</Text>
      <Text style={localStyles.payMethodsDescr}>{getPayMethodsDescription()}</Text>
      {
        !!paySchedules.length && !hasSamePrice ? (
          <>
            <View style={localStyles.paySchedules}>
              {paySchedules.map((paySchedule) => {
                return (
                  <View style={styles.row} key={paySchedule.frequency}>
                    <Text numberOfLines={1} type="regular" style={styles.width180}>
                      {makeTitle(paySchedule) + ':'}
                    </Text>
                    <Text numberOfLines={1} type="bold">
                      {getPsString(paySchedule)}
                    </Text>
                  </View>
                )
              })}
            </View>
          </>
        ) : null //don't show details by payschedule if they're all the same, since we're already providing the simple pickup price above
      }
    </>
  )
}, propsAreDeepEqual)
