import { Alert, Button, ButtonClear, LoadingView, Text } from '@elements'
import { errorToString } from '@helpers/helpers'

import { StackScreenProps } from '@react-navigation/stack'
import React, { useCallback, useMemo } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'

import { DistributionSummaryParamList } from '@/admin/navigation/types'
import { Logger } from '@/config/logger'
import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { useCancelableFocusFx } from '@/hooks/useCancelablePromise'
import useKeyedState from '@/hooks/useKeyedState'
import { withAdminAuth } from '@/hooks/withAdminAuth'
import { AppStackParamList } from '@/navigation/types'
import { adminFarmSelector } from '@/redux/selectors'
import { loadLocationsByFarm } from '@api/Locations'
import { addSignInSummary, loadSummaryByDateAndLocation, updateSignInSummary } from '@api/SignInSummaries'
import { Permission } from '@helpers/Permission'
import { formatLongDate } from '@helpers/display'
import { pick } from '@helpers/typescript'
import { Location, isDelivery, isShipping } from '@models/Location'
import { SignInItem, SignInSummary } from '@models/SignInSummary'
import { DistributionDetailsSummary } from '@models/Summary'
import { SignInItemRow, sharedStyles } from '@screens/SignInSheet/components'
import {
  getBQSummary,
  getCustomerNoByStatus,
  getLocationSummaryRows,
  getReceivedLabelByLocation,
  parseDateString,
  toSignInSummaryItems,
} from '@screens/SignInSheet/helpers'
import { DateTime } from 'luxon'
import { useSelector } from 'react-redux'
import { SignInHeader } from './components'

type Props = StackScreenProps<DistributionSummaryParamList & AppStackParamList, 'LocationSummary'>

type LocationSummaryState = {
  distributionDetailsSummary?: DistributionDetailsSummary
  locationSummaryRows: ReturnType<typeof getLocationSummaryRows>
  location?: Location
  date?: DateTime
  customers?: ReturnType<typeof getCustomerNoByStatus>
  loading: boolean
  error?: string
}

function LocationSummaryScreenComp({ navigation, route }: Props) {
  const farm = useSelector(adminFarmSelector)
  const [{ distributionDetailsSummary, locationSummaryRows, location, date, customers, loading, error }, , setState] =
    useKeyedState<LocationSummaryState>({
      locationSummaryRows: {},
      loading: true,
    })

  useCancelableFocusFx(
    async (isCurrent) => {
      try {
        setState((prev) => ({ ...prev, loading: true, error: undefined }))
        const parsedDate = parseDateString(route.params.date, farm.timezone)

        const locations = await loadLocationsByFarm(farm.id)
        const selectedLocation = locations.find((loc) => loc.id === route.params.locId)

        if (!selectedLocation) throw Error('Location not found')

        if (!isCurrent) return
        const distributionDetailsSummary = await getBQSummary(farm.id, parsedDate, selectedLocation.id)
        const locationSummaryRows = getLocationSummaryRows(distributionDetailsSummary)
        const customers = getCustomerNoByStatus(distributionDetailsSummary)

        if (!isCurrent) return
        setState({
          distributionDetailsSummary,
          locationSummaryRows,
          location: selectedLocation,
          date: parsedDate,
          customers,
          loading: false,
          error: undefined,
        })
      } catch (err) {
        Logger.error(err)
        if (!isCurrent) return
        setState((prev) => ({ ...prev, error: errorToString(err), loading: false }))
      }
    },
    [farm.id, farm.timezone, route.params, setState],
    { noRefocus: true },
  )

  /** Checks if a SignInSummary is created with the selected location and date
   * - If the summary exists, it will override the `SignInSummary` items statuses
   * - Else, it will create a new summary with the provided data
   */
  const onContinuePress = useCallback(async () => {
    if (loading || error) return

    try {
      let signInSummary = await loadSummaryByDateAndLocation(farm.id, location!, date!)
      const signInItems: SignInItem[] = toSignInSummaryItems(distributionDetailsSummary!.items)

      if (!signInSummary) {
        const newSummary: Pick<SignInSummary, 'location' | 'farm' | 'items' | 'date'> = {
          farm: pick(farm, 'id'),
          location: { id: location!.id },
          items: signInItems,
          date: date!,
        }
        signInSummary = await addSignInSummary(newSummary)
      } else {
        await updateSignInSummary({ ...signInSummary, items: signInItems })
      }

      navigation.navigate('SignInSheet', {
        screen: 'ElectronicSignInSheet',
        params: {
          id: signInSummary.id,
        },
      })
    } catch (err) {
      Alert('Error Starting Signin', errorToString(err))
    }
  }, [date, distributionDetailsSummary, error, farm, loading, location, navigation])

  const locTypeDescription = useMemo(() => {
    if (!location) return ''

    if (isDelivery(location)) return 'To deliver'

    if (isShipping(location)) return 'To ship'

    return 'To pickup'
  }, [location])

  return (
    <LoadingView style={globalStyles.flex1} loading={loading} error={error}>
      <ScrollView contentContainerStyle={sharedStyles.wrapperNoAlign} showsVerticalScrollIndicator={false}>
        <View>
          <View style={styles.header}>
            <ButtonClear title="Back" icon="arrow-left" onPress={() => navigation.navigate('SignInSheetSelector')} />
            <Text size={16}>Location Summary</Text>
          </View>
          <View style={globalStyles.margin10} />
          <Text size={20} type="medium" center>
            {location?.name}
          </Text>
          {date && <Text center>{formatLongDate(date)}</Text>}
          <View style={globalStyles.margin10} />
          <Button onPress={onContinuePress} title="Continue to Sign-in" small />
          <View style={globalStyles.margin10} />
          <View style={styles.wrapper}>
            <View style={globalStyles.margin10}>
              <Text center size={16}>
                Customers
              </Text>
              <View style={styles.divider} />
              <View style={styles.custNumbersCont}>
                <Text size={14}>{`${locTypeDescription}: ${customers?.activeNo}`}</Text>
                <Text size={14}>{`${location && getReceivedLabelByLocation(location)}: ${customers?.receivedNo}`}</Text>
                <Text size={14}>{`Missed: ${customers?.missedNo}`}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={globalStyles.margin20} />
        <View style={styles.wrapper}>
          <View style={styles.item}>
            <Text type="medium">{location?.name}</Text>
            <View style={styles.horizontalSpacing} />
            {date && <Text type="medium">{formatLongDate(date)}</Text>}
          </View>
          {Object.keys(locationSummaryRows).map((cat) => {
            return (
              <View key={cat}>
                <SignInHeader item={locationSummaryRows[cat][0]} />
                {locationSummaryRows[cat].map((prod) => (
                  <SignInItemRow key={cat + prod.id} item={prod} />
                ))}
              </View>
            )
          })}
        </View>
      </ScrollView>
    </LoadingView>
  )
}

export const LocationSummaryScreen = withAdminAuth(LocationSummaryScreenComp, Permission.Distributions)

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
  },
  item: {
    borderBottomWidth: 1,
    borderColor: Colors.shades[100],
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  wrapper: {
    borderWidth: 1,
    borderColor: Colors.shades['100'],
    borderRadius: 8,
    overflow: 'hidden',
  },
  custNumbersCont: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  divider: {
    marginVertical: 5,
    borderBottomWidth: 1,
    borderColor: Colors.shades['100'],
  },
  horizontalSpacing: {
    marginHorizontal: 5,
  },
})
