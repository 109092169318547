import { linkStripeAccount } from '@api/Farms'
import { Farm } from '@models/Farm'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { WebViewNavigation } from 'react-native-webview/lib/WebViewTypes'
import { useSelector } from 'react-redux'

import { SafeAreaView } from '@elements'
import { Permission } from '@helpers/Permission'
import LoaderWithMessage from '../../components/LoaderWithMessage'
import { Text } from '../../components/elements/Text'
import WebView from '../../components/elements/WebView/WebView'
import { Logger } from '../../config/logger'
import Colors from '../../constants/Colors'
import { withAdminAuth } from '../../hooks/withAdminAuth'
import { RootState } from '../../redux/reducers/types'
import { adminFarmSelector } from '../../redux/selectors'
import { AppStatus } from '../../screens/AppStatus'
import { AdminDrawerParamList } from '../navigation/types'

// StripeConnect renders the Stripe Connect on-boarding flow.
function AdminOnboardStripeConnect(): JSX.Element {
  const farm = useSelector<RootState, Farm>(adminFarmSelector)
  const [retry, setRetry] = useState(0)
  const [loading, setLoading] = useState(true)
  const [onBoardingUrl, setOnBoardingUrl] = useState<string | undefined>()
  const { params } = useRoute<RouteProp<AdminDrawerParamList, 'OnboardStripeConnect'>>()

  const navigation = useNavigation<StackNavigationProp<AdminDrawerParamList>>()

  useEffect(() => {
    if (!farm?.accountRef) navigation.navigate('Consumer')
    if (!params?.url) return
    setOnBoardingUrl(params.url)
  }, [params?.url])

  useEffect(() => {
    if (retry === 0 && params?.url) return
    linkStripeAccount(farm.accountRef)
      .then(setOnBoardingUrl)
      .catch((err) => Logger.debug(err))
  }, [retry])

  // onShouldStartLoadWithRequest watches for action URLs passed from Stripe and handles them accordingly.
  const onShouldStartLoadWithRequest = (event: WebViewNavigation): boolean => {
    const action = event.url.split('#').pop()
    switch (action) {
      case 'failure':
        setRetry(retry + 1)
        setLoading(true)
        return false
      case 'refresh':
        setRetry(retry + 1)
        setLoading(true)
        return false
      case 'return':
        navigation.goBack()
        return false
      default:
        return true
    }
  }

  return (
    <AppStatus scope="farmer">
      <SafeAreaView style={styles.safeArea}>
        {!!onBoardingUrl && (
          <WebView
            source={{ uri: onBoardingUrl }}
            onShouldStartLoadWithRequest={onShouldStartLoadWithRequest}
            onLoadEnd={() => setLoading(false)}
          />
        )}
        {loading && (
          <View style={styles.overlay}>
            <LoaderWithMessage loading={loading} icon="exclamation-triangle" title="Stripe Error!">
              <Text>There was an error loading the Stripe URL, please try again in a little while.</Text>
            </LoaderWithMessage>
          </View>
        )}
      </SafeAreaView>
    </AppStatus>
  )
}

export default withAdminAuth(AdminOnboardStripeConnect, Permission.AccountSetup)

const styles = StyleSheet.create({
  safeArea: {
    padding: 16,
    paddingHorizontal: 24,
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    paddingBottom: 32,
    flexGrow: 1,
    backgroundColor: Colors.white,
  },
  overlay: {
    position: 'absolute',
    zIndex: 10,
    width: '100%',
    height: '100%',
    backgroundColor: Colors.white,
    alignItems: 'center',
    justifyContent: 'center',
  },
})
