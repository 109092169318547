import { CheckBox, ErrorText, TextH3 } from '@elements'
import { CartItem } from '@models/Order'
import { PaymentInterval } from '@models/Payment'
import { PaymentSchedule } from '@models/Product'
import { useMemo, useState } from 'react'
import { StyleSheet, View } from 'react-native'

import Colors from '../../constants/Colors'
import { makeTestId, makeTitle, paymentScheduleText } from './CartButtons-helper'

/**
 * Extended PaymentSchedule. Adds discount, proratedPrice, and PaymentInterval[]
 */
export type PayScheduleExt = PaymentSchedule & {
  /** this discount is expressed as a percentage (1-100%), and will only be defined if it's larger than 1% discount */
  discount?: number
  /** proratedPrice is expected to be defined for shares */
  proratedPrice?: number
  /** 1st interval is the upfront payment. Subsequent intervals are the installments */
  interval?: PaymentInterval[]
}

type Props = {
  item: CartItem
  /** PayScheduleExt contains computed data related to the item.product.paymentSchedules */
  paySchedules: PayScheduleExt[]
  /** When true, will show the error text for the case when a payment schedule has not been selected */
  showError?: boolean
  updatePaySchedule(paymentSchedule: PaymentSchedule): Promise<any>
  touched?: boolean
}

/** Checkboxes for choosing a CartItem's payment schedules */
export function CartButtons({ item, paySchedules, showError, updatePaySchedule, touched = false }: Props) {
  const [loadingIx, setLoadingIx] = useState<number>()

  const payScheduleText = useMemo(() => {
    const text = paymentScheduleText(item, paySchedules)
    return touched && text ? text : ''
  }, [item, paySchedules, touched])

  return (
    <View>
      <View style={styles.container}>
        {paySchedules.length > 1 &&
          paySchedules.map((paySchedule, i) => {
            return (
              <CheckBox
                style={styles.checkbox}
                key={i}
                title={makeTitle(paySchedule, item.distribution?.location.type)}
                checked={touched && paySchedule.frequency === item.paymentSchedule.frequency}
                onChange={async () => {
                  setLoadingIx(i)
                  await updatePaySchedule(paySchedule)
                  setLoadingIx(undefined)
                }}
                testID={makeTestId(item.id, paySchedule)}
                disabled={loadingIx !== undefined}
                loading={loadingIx === i}
              />
            )
          })}
      </View>
      {showError && <ErrorText>Please select a payment option for this item.</ErrorText>}
      <TextH3>{payScheduleText}</TextH3>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 10,
    flex: 1,
  },
  button: {
    backgroundColor: Colors.shades['50'],
    flex: 1,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 5,
    padding: 8,
    color: Colors.blueLink,
  },
  selected: {
    backgroundColor: Colors.green,
  },
  checkbox: {
    marginVertical: 5,
  },
})
