import { HexColor, colorBank } from '@/constants/Colors'
import { isTruthy } from '@helpers/helpers'
import { getScheduleColorFromId } from '@helpers/distributions'

/**
 *  Selects a default color for a schedule based on the colors already assigned to a product. It will try to choose a color that hasn't been used in the assigned schedules. This is intended to be used for a schedule that has no color assignment, when it is assigned to a product.
 *
 * @param assignedSchedules an array of schedules assigned to a product. This is only intended to be used when the schedule is being assigned to the product, with the purpose of ensuring there are no repeated colors shown for each of the product schedules. THIS WOULD HAVE NO MEANING IN OTHER SITUATIONS.
 * @param distroId if provided, the fn will try to use the static color based on the id, if it hasn't been used in the form yet. Otherwise it will return the next unused color.
 * @returns color to use for the schedule on the product's "Schedule & Availability" section.
 */
export function getUnusedScheduleColor(assignedSchedules: { color?: HexColor }[], distroId?: string) {
  let colorsToChooseFrom = [...colorBank]

  if (assignedSchedules) {
    const assignedColors = assignedSchedules.map((d) => d.color).filter(isTruthy)
    colorsToChooseFrom = colorsToChooseFrom.filter((color) => !assignedColors.includes(color as HexColor))
  }
  // Get the static default color for the schedule, from its id
  const colorFromDefaultBank = getScheduleColorFromId(distroId || '', colorBank)

  // If the static color has not yet been used in the product, use it
  if (colorFromDefaultBank && colorsToChooseFrom.includes(colorFromDefaultBank)) return colorFromDefaultBank

  // If the static color has already been used, return the next unused color
  return colorsToChooseFrom[0]
}
