import { TextInputBox } from '@components'
import { HeaderText, typography, Button } from '@elements'
import { unmarshalPhoneNumber } from '@helpers/display'
import { useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'

import { globalStyles } from '../../../constants/Styles'

type Props = {
  phoneNumber: string
  onVerify(code: string): void
  loading: boolean
}

function Verification({ phoneNumber, onVerify, loading }: Props) {
  const [code, setCode] = useState('')

  const changeCode = (text: string) => {
    if (text.length === 6) onVerify(text)
    setCode(text)
  }
  return (
    <View style={globalStyles.flex1}>
      <View style={styles.viewWrapper}>
        <HeaderText center style={globalStyles.flex1}>
          6 Digit Code:
        </HeaderText>
        <TextInputBox
          value={code}
          placeholder="000000"
          onChangeText={changeCode}
          dataDetectorTypes="phoneNumber"
          keyboardType="phone-pad"
          maxLength={6}
          textContentType="oneTimeCode"
          containerStyle={globalStyles.flex1}
          inputStyle={styles.inputStyle}
        />
      </View>
      <Button loading={loading} title="Login" onPress={() => onVerify(code)} style={styles.margin8} />
      <View style={globalStyles.padding10}>
        <Text>Please enter the verification code we've sent to {unmarshalPhoneNumber(phoneNumber)}.</Text>
        <Text>
          Don't see it? <Text>Fix your number and try again.</Text>
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  viewWrapper: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 10,
  },
  inputStyle: {
    fontSize: 20,
    fontFamily: typography.title.bold,
    letterSpacing: 10,
    textAlign: 'center',
    minWidth: 0,
  },
  margin8: {
    margin: 8,
  },
})

export default Verification
