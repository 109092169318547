import { useApiFx, useSnapshot } from '@/hooks/useApiFx'
import { MessagesParamList } from '@/navigation/types'
import { userSelector } from '@/redux/selectors'
import { chatSessionsCollection } from '@api/framework/ClientCollections'
import { chatMessageListener } from '@api/Messaging'
import { RouteProp, useRoute } from '@react-navigation/native'
import { useSelector } from 'react-redux'

/** Data layer hook that handles the business logic for  Chat screen */
export function useChatScreenData() {
  const route = useRoute<RouteProp<MessagesParamList, 'Chat'>>()
  const user = useSelector(userSelector)

  const { id } = route.params ?? {}

  /**  First will get the chat sessions so we ensure it exists*/
  const chatSessionFx = useApiFx(chatSessionsCollection.fetch.bind(chatSessionsCollection), [id], !!id)

  /** Run snapshot only when the chat session is retrieved, as the listener will return empty list if session does not exist*/
  const chatsFx = useSnapshot(chatMessageListener, [id], !!id && !!user.id && !!chatSessionFx.data)

  return {
    messages: chatsFx.data,
    currUserId: user.id,
    chatSession: chatSessionFx.data,
    error: chatSessionFx.err ?? chatsFx.error,
    loading: chatsFx.loading ?? chatSessionFx.loading,
  }
}

export type UseChatScreenDataType = ReturnType<typeof useChatScreenData>
