import { ButtonGroup, HeaderText, ToggleButton } from '@elements'
import { capitalize } from '@helpers/display'
import { useCallback, useContext, useEffect } from 'react'
import { StyleSheet, View } from 'react-native'

import { withKeyedStateProvider } from '@/hooks/useKeyedState'
import { Invoice } from '@models/Invoice'
import { Order, Pickup } from '@models/Order'
import { ConsumerView } from '../../components/ConsumerView'
import { globalStyles } from '../../constants/Styles'
import { withAuth } from '../../hooks/withAuth'
import { InvoiceTab } from './InvoiceTab'
import { OrderTab } from './OrderTab'
import { PickupTab } from './PickupTab'

const buttons = ['Schedule', 'Orders', 'Invoices']
const tabs = ['schedule', 'orders', 'invoices'] as const

export type OrdersScreenState = {
  pickups: Pickup[]
  orders: Order[]
  invoices: Invoice[]
  highlightedInvoices: string[]
  tab: typeof tabs[number]
  showEmptyOrders: boolean
}

const initialState: OrdersScreenState = {
  pickups: [],
  orders: [],
  invoices: [],
  highlightedInvoices: [],
  tab: tabs[0],
  showEmptyOrders: false,
}

function OrdersScreen() {
  const [{ highlightedInvoices, tab, showEmptyOrders }, set, setState] = useContext(OrdersScreenContext)

  const onSelectTab = useCallback(
    (index: number) => {
      set('tab', tabs[index])
    },
    [set],
  )

  /** Called from a pickup card, will switch to invoices tab and highlight the invoices for that pickup */
  const goToPickupInvoices = useCallback(
    (invoices: string[]) => {
      setState((p) => ({ ...p, tab: 'invoices', highlightedInvoices: invoices }))
    },
    [setState],
  )

  useEffect(() => {
    if (tab !== 'invoices' && highlightedInvoices.length) set('highlightedInvoices', [])
  }, [tab, highlightedInvoices, set])

  const toggleShowEmptyOrders = useCallback(() => set('showEmptyOrders', (curr) => !curr), [set])

  return (
    <ConsumerView style={styles.container}>
      <HeaderText size={30} style={globalStyles.margin20}>
        Your {capitalize(tab)}
      </HeaderText>
      <View style={styles.headerButtons}>
        <ButtonGroup onSelect={onSelectTab} selectedIndex={tabs.indexOf(tab)} buttons={buttons} />

        {tab === 'orders' && (
          <View style={styles.toggleCont}>
            <ToggleButton title="Show cancelled" value={showEmptyOrders} onChange={toggleShowEmptyOrders} />
          </View>
        )}
      </View>

      <PickupTab goToPickupInvoices={goToPickupInvoices} />
      <OrderTab />
      <InvoiceTab />
    </ConsumerView>
  )
}

const { Context: OrdersScreenContext, ComponentWithProvider } = withKeyedStateProvider(OrdersScreen, initialState)

export { OrdersScreenContext }

export default withAuth(ComponentWithProvider, { noSafeAreaInsets: true })

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: 1200,
  },
  headerText: {
    margin: 20,
  },
  headerButtons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  toggleCont: {
    marginHorizontal: 20,
  },
})
