import { FontAwesome5 } from '@expo/vector-icons'
import { Media } from '@models/shared/Media'
import { FlashList, ListRenderItemInfo, ViewToken } from '@shopify/flash-list'
import { ResizeMode, Video } from 'expo-av'
import * as React from 'react'
import { memo, useCallback, useRef, useState } from 'react'
import { Animated, StyleProp, StyleSheet, TouchableWithoutFeedback, View, ViewStyle } from 'react-native'

import Colors from '../constants/Colors'
import { isWeb } from '../constants/Layout'
import { FARM_IMAGE_PLACEHOLDER } from '../constants/Placeholders'
import { Image, ResizedSuffix } from './Image'
import { Icon } from './elements/Icon/Icon'

import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useFlatStyle } from '@/hooks/useMergeStyle'

/** Arrow height is needed so the arrow can be positioned exactly in the middle
 * of the content: (height / 2 + arrowHeight / 2)
 */
const arrowHeight = 30

//The circles below the Carousel, which represent the media id being shown

type CarouselProps = {
  media?: Media[]
  /** Do not pass a 'height' property in this style. It is meant to get height dynamically */
  style?: StyleProp<ViewStyle>
  height: number
  width: number
}
/** Media Carousel which can be used with a defined @param width and a defined @param height
 * Its content will be expaned and it will show one item at a time
 */
export const MediaCarousel = memo(function MediaCarousel({ media = [], style, height, width }: CarouselProps) {
  const opacity = useRef(new Animated.Value(0)).current
  const flashlistRef = useRef<FlashList<Media> | null>(null)
  const [currPos, setCurrPos] = useState(0)

  const onRightArrowPress = useCallback(() => {
    flashlistRef.current?.scrollToIndex({
      animated: true,
      index: currPos + 1,
    })
  }, [currPos])
  const onLeftArrowPress = useCallback(() => {
    flashlistRef.current?.scrollToIndex({
      animated: true,
      index: currPos - 1,
    })
  }, [currPos])

  const onLoadVid = useCallback(() => {
    Animated.timing(opacity, {
      toValue: 1,
      useNativeDriver: !isWeb,
    }).start()
  }, [opacity])

  const onViewableItemsChanged = useCallback((info: { viewableItems: ViewToken[]; changed: ViewToken[] }) => {
    if (info.viewableItems.length === 0) return
    setCurrPos(info.viewableItems[0].index ?? 0)
  }, [])

  const fnStyles = useSizeFnStyles(
    (_, width: number, height: number) => ({
      wrapper: {
        height,
        width,
        borderRadius: 10,
        overflow: 'hidden',
      },
      image: {
        height,
        width,
      },
      leftArrow: {
        ...styles.arrow,
        top: height / 2 - arrowHeight / 2,
        left: 10,
        // Adds padding as arrow icon to appear as centered
        paddingRight: 4,
      },
      rightArrow: {
        ...styles.arrow,
        top: height / 2 - arrowHeight / 2,
        right: 10,
      },
    }),
    width,
    height,
  )

  const renderItem = useCallback(
    ({ item }: ListRenderItemInfo<Media>) => {
      if (item.type === 'video') {
        return (
          <Video
            isLooping
            isMuted
            resizeMode={ResizeMode.COVER}
            shouldPlay
            onLoad={onLoadVid}
            source={{
              uri: item.storageUrl,
            }}
            style={fnStyles.image}
          />
        )
      }
      return (
        <Image
          type="farm"
          style={fnStyles.image}
          source={{ uri: item.storageUrl }}
          resizeSuffix={ResizedSuffix.LARGE}
          resizeMode="cover"
        />
      )
    },
    [fnStyles.image, onLoadVid],
  )

  return (
    <View style={useFlatStyle([fnStyles.wrapper, style])}>
      {/*Arrows*/}
      {isWeb && (
        <>
          {/*Left arrow*/}

          {currPos !== 0 && (
            <TouchableWithoutFeedback onPress={onLeftArrowPress}>
              <View style={fnStyles.leftArrow}>
                <Icon style={styles.icon} name="chevron-left" size={20} color={Colors.shades[500]} />
              </View>
            </TouchableWithoutFeedback>
          )}

          {/*Right arrow*/}
          {currPos !== media.length - 1 && (
            <TouchableWithoutFeedback onPress={onRightArrowPress}>
              <View style={fnStyles.rightArrow}>
                <Icon style={styles.icon} name="chevron-right" size={20} color={Colors.shades[500]} />
              </View>
            </TouchableWithoutFeedback>
          )}
        </>
      )}

      {/*Small indicator circles. One for each image*/}
      <Indicator currPos={currPos} numPos={media.length} />

      {/* Show just the image is there is only one item */}
      {media.length === 1 && media[0]?.type === 'image' ? (
        <Image
          type="farm"
          style={fnStyles.image}
          source={{ uri: media[0]?.storageUrl }}
          resizeSuffix={ResizedSuffix.LARGE}
          resizeMode="cover"
        />
      ) : (
        <FlashList
          decelerationRate="fast"
          horizontal
          bounces={false}
          extraData={height}
          renderItem={renderItem}
          estimatedItemSize={width}
          showsHorizontalScrollIndicator={false}
          scrollEventThrottle={16}
          data={media}
          snapToInterval={width}
          snapToAlignment="center"
          onViewableItemsChanged={onViewableItemsChanged}
          ref={flashlistRef}
          ListEmptyComponent={<Image style={fnStyles.image} source={FARM_IMAGE_PLACEHOLDER} />}
        />
      )}
    </View>
  )
})

type IndicatorProps = {
  currPos: number
  numPos: number
}

const Indicator = memo(function Indicator({ currPos, numPos }: IndicatorProps) {
  if (numPos < 2) return null
  return (
    <View style={styles.indicator}>
      {Array(numPos)
        .fill(0)
        .map((_, idx) => (
          <FontAwesome5 key={idx} style={styles.indicatorIcon} name="circle" color="white" solid={idx === currPos} />
        ))}
    </View>
  )
})

const styles = StyleSheet.create({
  icon: { paddingLeft: 2 },
  indicatorIcon: {
    margin: 2,
  },
  indicator: {
    flexDirection: 'row',
    position: 'absolute',
    right: 10,
    zIndex: 10,
    bottom: 10,
  },
  arrow: {
    width: arrowHeight,
    height: arrowHeight,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
    backgroundColor: Colors.lightGray,
    borderRadius: 80,
    opacity: 0.6,
  },
})
