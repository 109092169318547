import { addSupportTicket } from '@api/SupportTicket'
import { Alert, Button, hideModal, Text, Toast } from '@elements'
import { User } from '@models/User'
import { Formik, FormikProps } from 'formik'
import { useState } from 'react'
import { KeyboardAvoidingView, Platform, StyleSheet, View } from 'react-native'
import { Input } from 'react-native-elements'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'

import { nativeAppVersion } from '@/config/Environment'
import { SupportTicket as SupportTicketRequest, SupportTicketType } from '@models/SupportTicket'
import { Logger } from '../../config/logger'
import Colors from '../../constants/Colors'
import { RootState } from '../../redux/reducers/types'
import { userSelector } from '../../redux/selectors'

type FormType = {
  text: string
}

type SupportTicketProps = {
  type?: SupportTicketType
  customer?: User
}

const Schema = Yup.object<FormType>().shape({
  text: Yup.string().trim().required('Please leave a message for us!'),
})

export default function SupportTicket({ type = SupportTicketType.FEEDBACK, customer }: SupportTicketProps) {
  const user = useSelector<RootState, User>(userSelector)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const submit = ({ text }: { text: string }) => {
    setIsLoading(true)
    const ticket = {
      type,
      user: {
        id: user.id,
        email: user.email,
        name: user.name,
      },
      text,
      systemInfo: {
        appVersion: nativeAppVersion,
        browser: `${Platform.Version}`,
        deviceType: Platform.OS,
      },
    }

    let submitTicket: SupportTicketRequest

    if (type === SupportTicketType.CLEAR_EBT_HOLD) {
      submitTicket = {
        ...ticket,
        customer: {
          id: customer!.id,
          email: customer!.email,
          name: customer!.name,
        },
      } as SupportTicketRequest
    } else {
      submitTicket = ticket as SupportTicketRequest
    }

    addSupportTicket(submitTicket)
      .then(() => {
        const ticketMessage = 'We received your message and we will be getting in touch soon.'
        const deleteMessage = 'We received your request, your account will be deleted within 2 weeks'
        setIsLoading(false)
        hideModal()
        Alert('Thank you!', type === SupportTicketType.DELETE_REQUEST ? deleteMessage : ticketMessage)
      })
      .catch((e) => {
        Logger.error(e)
        Toast('Please try again later.')
        setIsLoading(false)
      })
  }

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={styles.keyboardAvoidingViewStyle}
      keyboardVerticalOffset={100}
    >
      <View style={styles.safeArea}>
        <Formik initialValues={{ text: '' }} validationSchema={Schema} onSubmit={submit}>
          {({ handleChange, handleBlur, handleSubmit, errors, touched }: FormikProps<FormType>) => (
            <View style={styles.modalContentStyle}>
              <Text>
                {type === SupportTicketType.DELETE_REQUEST
                  ? 'We are sorry to see you go! Is there any way for us to help improve your experience?'
                  : type === SupportTicketType.CLEAR_EBT_HOLD
                  ? 'Please let our team know why this customer needs to clear their SNAP Online security hold.'
                  : ''}
              </Text>
              <Input
                onChangeText={handleChange('text')}
                onBlur={handleBlur('text')}
                placeholder="Leave us a message..."
                multiline
                containerStyle={styles.containerStyle}
                inputContainerStyle={styles.inputContainerStyle}
                inputStyle={styles.inputStyle}
                textAlignVertical="top"
                errorMessage={touched.text ? errors.text : ''}
              />
              <Button
                color={type === SupportTicketType.DELETE_REQUEST ? Colors.red : undefined}
                loading={isLoading}
                onPress={() => handleSubmit()}
                title={type === SupportTicketType.DELETE_REQUEST ? 'Request account deletion' : 'Submit'}
              />
            </View>
          )}
        </Formik>
      </View>
    </KeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  safeArea: {
    padding: 16,
    paddingHorizontal: 24,
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    paddingBottom: 32,
    flexGrow: 1,
    backgroundColor: Colors.white,
  },
  inputContainerStyle: {
    flexGrow: 1,
    flexDirection: 'column',
  },
  inputStyle: {
    marginBottom: 8,
    flexGrow: 1,
    width: '100%',
    alignSelf: 'baseline',
  },
  containerStyle: { marginTop: 32, flexGrow: 1 },
  modalContentStyle: {
    marginTop: 10,
    flexDirection: 'column',
    flexGrow: 1,
  },
  keyboardAvoidingViewStyle: { flex: 1, backgroundColor: Colors.white },
})
