import { Logger } from '@/config/logger'
import { orderItemTotal } from '@helpers/order'
import { formatToSafeSlug } from '@helpers/urlSafeSlug'
import { Product } from '@models/Product'
import { ErrorWithCode } from '@shared/Errors'
import { deleteField, limit, or, where } from 'firebase/firestore'
import { PartialExcept } from '../constants/helpers/typescript'
import { SaleStats, newSaleStat } from '../constants/types'
import { CSA } from '../models/CSA'
import { buildUrlSafeSlug } from './UrlSafeSlugs'
import { csasCollection, ordersCollection, productsCollection } from './framework/ClientCollections'

/** loadCSAsByFarm returns all the CSAs that are associated with the supplied farm ID, regardless of hidden or private status */
export async function loadCSAsByFarm(farmSlug: string): Promise<CSA[]> {
  return csasCollection.fetchAll(or(where('farm.urlSafeSlug', '==', farmSlug), where('farm.id', '==', farmSlug)))
}

/** loadCSA returns the CSA identified by the supplied CSA ID. */
export async function loadCSA(csaId: string): Promise<CSA> {
  return csasCollection.fetch(csaId)
}

/** addCSA adds a new CSA to the database. */
export async function addCSA(csa: CSA): Promise<CSA> {
  // Validate the urlSafeSlug
  let validSafeSlug = formatToSafeSlug(csa.name)
  const newId = csasCollection.reference().id

  validSafeSlug = await buildUrlSafeSlug({
    slug: validSafeSlug,
    id: newId,
    type: 'create',
    collection: 'csas',
    farmId: csa.farm.id,
  })

  if (!validSafeSlug) {
    throw new ErrorWithCode({
      code: 'invalid-slug',
      devMsg:
        /**TODO: Use this when urlSafeSlug feature is implemented. Please choose a distinct name for your csa. The csa name is essential for creating a unique and secure URL. */
        'Please choose a distinct name for your csa to separate it from others.',
    })
  }
  // Assign valid urlSafeSlug and new ID
  const newCsa = { ...csa, urlSafeSlug: validSafeSlug, id: newId }

  await csasCollection.createWithId(newCsa)
  return newCsa
}

/** updateCSAByName updates the CSA document. */
export async function updateCSA({ changeOptions, ...csa }: PartialExcept<CSA, 'id'>): Promise<void> {
  // When the csa name is included or changed, we need to validate the urlSafeSlug
  if (csa.name) {
    if (!csa.farm?.id)
      throw new ErrorWithCode({
        code: 'farm-id-required',
        devMsg: 'The farmId is required to update the csa name.',
      })
    // Validate the urlSafeSlug
    let validSafeSlug = formatToSafeSlug(csa.name)
    validSafeSlug = await buildUrlSafeSlug({
      slug: validSafeSlug,
      type: 'update',
      collection: 'csas',
      id: csa.id,
      farmId: csa.farm.id,
    })

    if (!validSafeSlug) {
      throw new ErrorWithCode({
        code: 'invalid-slug',
        devMsg:
          /**TODO: Use this when urlSafeSlug feature is implemented. Please choose a distinct name for your csa. The csa name is essential for creating a unique and secure URL. */
          'Please choose a distinct name for your csa to separate it from others.',
      })
    }
    // Assign valid urlSafeSlug
    csa.urlSafeSlug = validSafeSlug
  }

  // If changeOptions is undefined, then we want to delete the field because undefined values will not be written to Firestore
  await csasCollection.update(csa, { changeOptions: changeOptions === undefined ? deleteField() : changeOptions })
}

export async function loadCSASales(farmId: string, csaIds: string[]): Promise<any> {
  const csaIDSet = new Set(csaIds)
  const salesMap = new Map<CSA['id'], SaleStats>()

  try {
    const allOrders = await ordersCollection.fetchAll(where('farm.id', '==', farmId))
    for (const order of allOrders) {
      //iterate through each order for the farm

      for (const orderItem of order.items) {
        //iterate through each item on that order for the farm
        const { quantity, csa } = orderItem

        if (!csa) continue

        //if the orderItem is not in the list of csaIds, then skip it
        if (!csaIDSet.has(csa.id)) {
          // Logger.debug('skipped because csa not in list of target Ids', orderItem)
          continue
        }

        if (salesMap.has(csa.id)) {
          const curStat = salesMap.get(csa.id)
          curStat!.sales += quantity
          curStat!.revenue += orderItemTotal(orderItem).value
          curStat!.uniqueCustomers.add(order.user.id)
          if (orderItem.cancelled) curStat!.cancelled += quantity
        } else {
          const curStat = newSaleStat()
          curStat.sales += quantity
          curStat.revenue += orderItemTotal(orderItem).value
          curStat.uniqueCustomers.add(order.user.id)
          if (orderItem.cancelled) curStat.cancelled += quantity
          salesMap.set(csa.id, curStat)
        }
      }
    }
  } catch (error) {
    Logger.debug('load product sales error', error)
  }
  // Logger.debug(salesMap)
  return salesMap
}

/** Checks for permission to delete a csa */
export async function canCSABeDeleted(farmId: string, CSAid: string): Promise<boolean> {
  const products = await productsCollection.fetchAll(
    where('farm.id', '==', farmId),
    where('csa', 'array-contains', CSAid),
    limit(1),
  )
  return products.length === 0
}

/** Deletes a csa and returns true on success */
export async function deleteCSA(CSAid: string): Promise<boolean> {
  try {
    await csasCollection.delete(CSAid)
    return true
  } catch (e) {
    return false
  }
}

/**
 * Fetches the csas for a share, and filters hidden or private csas.
 * @param isAdmin if true, csas won't be filtered by private
 *
 * - Note: The csaIds argument is supposed to be an array of csa IDs with less than 10 items, due to Firestore limitation */
export const loadCsasForProduct = async (csaIds: Product['csa'], isAdmin = false) => {
  if (!csaIds?.length) return []
  const q = [where('__name__', 'in', csaIds), where('isHidden', '!=', true)]
  if (isAdmin) {
    q.push(where('isPrivate', '==', false))
  }
  return csasCollection.fetchAll(...q)
}
