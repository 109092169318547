import Feedback from '@screens/UserScreen/Feedback'
import { PrivacyPolicy } from '@screens/UserScreen/PrivacyPolicy'
import { TermsAndConditions } from '@screens/UserScreen/TermsAndConditions'
import { StyleSheet, View } from 'react-native'

import { useLayout } from '@/hooks/useLayout'
import { Modal } from '../../components/elements/Overlays/Popups'
import { Text, fontStyle } from '../../components/elements/Text'

export function Footer() {
  const goTerms = () => Modal(<TermsAndConditions />, { title: 'Terms and Conditions' })
  const goPrivacy = () => Modal(<PrivacyPolicy />, { title: 'Privacy Policy' })
  const goFeedback = () => Modal(<Feedback />, { title: 'Provide Feedback' })
  const { bottom } = useLayout()

  return (
    <View style={[styles.containerRow, { marginBottom: bottom }]}>
      <Text style={styles.footerText} onPress={goTerms}>
        Terms
      </Text>
      <Text style={styles.footerText} onPress={goPrivacy}>
        Privacy Policy
      </Text>
      <Text style={styles.footerText} onPress={goFeedback}>
        Feedback
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  containerRow: {
    marginTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    zIndex: -1,
  },
  footerText: {
    ...fontStyle(12),
    textDecorationLine: 'underline',
  },
})
