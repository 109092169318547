import { Button, Divider, hideModal, Text } from '@elements'
import { plural } from '@helpers/display'
import { DeltaPickupsErrObj, ProdErrorsMap, ValidationErrObj } from '@helpers/distributionEditing'
import { ScrollView, StyleSheet, View } from 'react-native'

import { globalStyles } from '../../../constants/Styles'

export default function ScheduleEditBlock({
  prodErrorMap,
  onDismiss,
}: {
  prodErrorMap: ProdErrorsMap
  onDismiss: () => void
}) {
  const close = () => {
    hideModal()
    onDismiss()
  }

  const deltaPickupsErrors: DeltaPickupsErrObj[] = [...prodErrorMap]
    .map(([_, obj]) => obj)
    .filter((obj): obj is DeltaPickupsErrObj => obj.kind === 'deltaPickups')
  const validationErrors: ValidationErrObj[] = [...prodErrorMap]
    .map(([_, obj]) => obj)
    .filter((obj): obj is ValidationErrObj => obj.kind === 'validationError')

  return (
    <ScrollView style={globalStyles.margin10}>
      {!!deltaPickupsErrors.length && (
        <>
          <Text>
            Changing the schedule of this distribution will affect the number of pickups on the below shares. In order
            to make this change you must fix the availability of the shares so that the number of pickups is not
            altered. Please go into the product page and make the appropriate changes there then come back and edit this
            distribution.
          </Text>
          {deltaPickupsErrors.map(({ product, deltaPickups }) => (
            <View key={product.id}>
              <Divider />
              <Text size={16} type="medium">
                {product.name}
              </Text>
              <Text>
                {Math.abs(deltaPickups!)} {deltaPickups! < 0 ? 'less' : 'more'} {plural(deltaPickups!, 'pickup')}
              </Text>
            </View>
          ))}
        </>
      )}

      {!!validationErrors.length && (
        <>
          <Text style={{ marginTop: 15 }}>
            Editing this schedule is not possible due to the configuration of other products in your shop:
          </Text>
          {validationErrors.map(({ product }) => (
            <View key={product.id}>
              <Divider />
              <Text size={16} type="medium">
                {product.name}
              </Text>
              {/* We're temporarily not showing the actual errors */}
              {/* <Text>{errorToString(error)}</Text> */}
            </View>
          ))}
        </>
      )}

      <View style={styles.flexContainer}>
        <Button title="Cancel" onPress={close} />
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  flexContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 10,
  },
})
