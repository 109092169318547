import { useAvailAddons } from '@/hooks/useAvailAddons'
import { ErrorText, HeaderText, Text } from '@elements'
import { defaultAddonNotAvailableText } from '@helpers/products-display'
import { Product, isAddon } from '@models/Product'
import { View } from 'react-native'
import { PriceContainer } from './PriceContainer'

/** UI passed into the parallax header. Shows the product name and description */
export function Sticky({
  product,
  unitId,
  setUnitId,
}: {
  product?: Product
  unitId: string
  setUnitId: (unitId: string) => void
}) {
  const { availAddonsResults } = useAvailAddons()

  if (!product) return null

  const availAddonResult = isAddon(product) ? availAddonsResults.find((res) => res.id === product.id) : undefined

  return (
    <View>
      <HeaderText size={24}>{product.name}</HeaderText>
      <Text size={18}>{product.description}</Text>
      <PriceContainer product={product} unitId={unitId} setUnitId={setUnitId} />
      {isAddon(product) && !availAddonResult?.isAvail && (
        <ErrorText>{availAddonResult?.unavailReason || defaultAddonNotAvailableText}</ErrorText> // addon is not available and should not be added to cart - also show the correct message from why isn't available
      )}
    </View>
  )
}
