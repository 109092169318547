import { DraftOrder } from '@models/DraftOrder'
import { where } from 'firebase/firestore'
import { draftOrdersCollection } from './framework/ClientCollections'
import { callEndpoint } from './v2'

/** approveDraftOrder will mark the draft order as approved */
export async function approveDraftOrder(draftOrderId: string): Promise<boolean> {
  return await callEndpoint('v2.Order.approveDraftOrder', { draftOrderId })
}

/** finalizeDraftOrder will convert the draft order into a real order */
export async function finalizeDraftOrder(draftOrderId: string): Promise<{ id: string }> {
  return await callEndpoint('v2.Order.finalizeDraftOrder', { draftOrderId })
}

/** declineDraftOrder will mark the draft order as declined. The draft order and associated data will be deleted after 90 days. */
export async function declineDraftOrder(draftOrderId: string): Promise<boolean> {
  return await callEndpoint('v2.Order.declineDraftOrder', { draftOrderId })
}

/** Will get the active draft orders for a user */
export async function fetchDraftOrdersForUser(userId: string): Promise<DraftOrder[]> {
  return draftOrdersCollection.fetchAll(where('user.id', '==', userId))
}

/** getDraftOrdersCountByFarmAndUser will fetch non-finalized draftOrders count for users who are wholeSaleBuyers. (This will be used in Admin side.)  */
export async function getDraftOrdersCountByFarmAndUser(userId: string, farmId: string): Promise<number> {
  return await draftOrdersCollection.count(where('user.id', '==', userId), where('farm.id', '==', farmId))
}

/** Will load the draft order as a snapshot listener */
export const snapshotDraftOrder = (
  callback: (draftOrder?: DraftOrder) => void,
  onError: (err: Error) => void,
  id: string,
) => draftOrdersCollection.snapshotDoc(id, callback, onError)
