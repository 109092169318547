import { SignInProviders, User } from '@models/User'

/** Assembles a user object for the purpose of creating a new user in the db */
export const buildUser = (
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  signInProvider: SignInProviders,
  imageUrl?: string,
): User => ({
  id,
  avatar: imageUrl || '',
  name: {
    firstName,
    lastName,
  },
  email,
  notificationToken: '',
  notifications: {
    pushNotification: true,
    text: true,
  },
  promotions: {
    email: true,
    pushNotification: true,
    text: true,
  },
  customerRef: '',
  signInProvider,
})
