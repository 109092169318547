import { overWriteProduct } from '@api/Products'
import { productsCollection } from '@api/framework/ClientCollections'
import { hasGlobalStock } from '@models/Product'

/** Change the quantity of a product with global stock. ( Only product types that have global stock) */
export const saveGlobalQuantity = async (newQuantity: number, prodId: string) => {
  const prod = await productsCollection.fetch(prodId)
  if (!hasGlobalStock(prod)) throw new Error('The product must be of global stock type')

  const updatedProd = {
    ...prod,
    quantity: newQuantity,
  }
  return overWriteProduct(updatedProd)
}
