// eslint-disable-next-line no-restricted-imports
import { dequal as dequalSource } from 'dequal'
import { isObject } from './helpers'
import { isLuxonDateTime } from './time'

type FilterOpts = {
  /** filter out fields with keys starting with an underscore. */
  ignoreUnderscoreFields?: boolean
}

/** Processes the value into a format adequate for deep comparison */
export function getCompareValue<T extends any>(val: T, { ignoreUnderscoreFields = true }: FilterOpts = {}): T {
  const processObject = <T extends Record<any, any>>(obj: T): T => {
    const result = {} as T
    for (const key in obj) {
      if (!key.startsWith('_') || !ignoreUnderscoreFields) {
        result[key] = getCompareValue(obj[key])
      }
    }
    return result
  }

  return Array.isArray(val)
    ? val.map((subVal) => getCompareValue(subVal))
    : isLuxonDateTime(val) && typeof val.toISO === 'function'
    ? (val.toISO() as any) // For DateTime, ISO form is good for comparison
    : isObject(val)
    ? processObject(val)
    : val
}

/** Compares two values for equality.
 * - For objects, the comparison will ignore keys starting with an underscore */
export function dequal(val1: any, val2: any, opts?: FilterOpts): boolean {
  const filteredVal1 = getCompareValue(val1, opts)
  const filteredVal2 = getCompareValue(val2, opts)

  return dequalSource(filteredVal1, filteredVal2)
}
