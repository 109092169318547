import { omit } from '@helpers/typescript'
import { SendEmailRequest } from '@models/Mail'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { marshalDate, unmarshalDate } from './Time'
import { prepareMarshal, prepareUnmarshal } from './encoding'

/** marshalUser returns the Firestore document data for the supplied user. */
export function marshalEmail(email: Partial<SendEmailRequest<any>>): DocumentData {
  const data = omit(prepareMarshal(email), 'id') as DocumentData

  if (email.sendAt) {
    data.sendAt = marshalDate(email.sendAt)
  }

  return data
}

/** unmarshalUser transforms the data into the User structure. */
export function unmarshalEmail<T>(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): SendEmailRequest<T> {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)
  const email = { ...data, id } as SendEmailRequest<T>

  if (data.sendAt) {
    email.sendAt = unmarshalDate(data.sendAt)
  }

  return email
}
