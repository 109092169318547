import { Text, Toast, Touchable } from '@elements'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { capitalize, formatPickupTime, getDayofWeekName } from '@helpers/display'
import { errorToString } from '@helpers/helpers'
import { displayDateRange } from '@helpers/time'
import { Distribution } from '@models/Distribution'
import { isSeasonalSchedule } from '@models/Schedule'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { memo, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CardAction, handleDistroAction } from '../helpers/DistroRowActions'

import { ExpandableRow, ExpandableRowProps } from '@/admin/components/OfflineTable/ExpandableRow'
import { ActionsMenuComponent } from '@/admin/components/elements/ActionsMenuComponent'
import { DistributionSchedulesParamList } from '@/admin/navigation/types'
import { Logger } from '@/config/logger'
import Colors from '@/constants/Colors'
import { adminSchedulesSelector } from '@/redux/selectors'
import { StyleSheet, View } from 'react-native'
import { getScheduleColorFromId } from '@helpers/distributions'
import { formatCatalog } from '@helpers/wholesale'

type Props = ExpandableRowProps<Distribution>

/** This component is used for the Distribution List screen when in spreadSheet mode */
export const DistributionRow = memo(function DistributionRow({ index, item: distro }: Props) {
  const distributions = useSelector(adminSchedulesSelector)
  const navigation = useNavigation<StackNavigationProp<DistributionSchedulesParamList>>()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const actionColor = loading ? Colors.shades['200'] : Colors.blue

  /** onPress should handle loading state on each action and keep track them, so we could set loading to true, then await the action, then set loading to false when action fulfilled. */
  const onPress = useCallback(
    async (action: CardAction) => {
      try {
        setLoading(true)
        await handleDistroAction(action, distro, distributions, dispatch, navigation)
        setLoading(false)
      } catch (err) {
        Logger.error(err)
        Toast(errorToString(err))
        setLoading(false)
      }
    },
    [dispatch, distributions, distro, navigation],
  )

  return (
    <ExpandableRow<Distribution>
      item={distro}
      index={index}
      key={`prodList_${distro.id}`}
      rowContainerStyle={{ backgroundColor: distro.isHidden ? Colors.lightGray : Colors.white }}
      columns={[
        {
          process: () => (
            <View style={[styles.circle, { backgroundColor: distro.color || getScheduleColorFromId(distro.id) }]} />
          ),
          widthFlex: 0.4,
        },
        {
          process: (distro) => distro.name,
          widthFlex: 2,
        },
        {
          process: (distro) => distro.location.name,
          widthFlex: 1.5,
        },
        {
          process: (distro) =>
            distro.priceGroup?.type === 'default-catalog' ? formatCatalog(distro.priceGroup.catalog) : 'Retail',
        },
        {
          process: (distro) => capitalize(distro.schedule.frequency),
        },
        {
          process: (distro) =>
            isSeasonalSchedule(distro.schedule) ? displayDateRange(distro.schedule.season) : 'Year-round',
          widthFlex: 2,
        },
        {
          process: (distro) => {
            const pickupTimeText = formatPickupTime(distro.schedule.hours, distro.location.type)

            return `${pickupTimeText ? `${pickupTimeText}, ` : ''}${getDayofWeekName(distro.schedule.dayOfWeek)}`
          },
          widthFlex: 2,
        },
        {
          process: (distro) => {
            return distro.isHidden ? (
              <Text style={styles.disabledHiddenLink}>Hidden</Text>
            ) : (
              <Touchable disabled={loading}>
                <Text color={actionColor} style={styles.enabledLink} onPress={() => onPress('close')}>
                  {distro.closed ? 'Closed' : 'Open'}
                </Text>
              </Touchable>
            )
          },
        },
        {
          process: (distro) => (
            <ActionsMenuComponent<Distribution>
              data={distro}
              containerStyle={styles.actionRowContainer}
              buttons={[
                { title: 'Edit', onPress: () => onPress('edit') },
                { title: 'Copy', onPress: () => onPress('copy') },
                {
                  title: distro.isHidden ? 'Show' : 'Hide',
                  onPress: () => onPress('hide'),
                },
                { title: 'Delete', onPress: () => onPress('delete') },
              ]}
              disabled={loading}
            />
          ),
        },
      ]}
      onRowPress={() => onPress('edit')}
    />
  )
}, propsAreDeepEqual)

const styles = StyleSheet.create({
  disabledHiddenLink: {
    color: Colors.primaryGray,
  },
  enabledLink: {
    textDecorationLine: 'underline',
  },
  actionRowContainer: {
    alignItems: 'flex-start',
  },
  circle: {
    height: 20,
    width: 20,
    borderRadius: 20,
  },
})
