import { User } from '@models/User'
import * as sentry from '@sentry/react'

import { nonEmptyString } from '@helpers/helpers'
import { pick } from '@helpers/typescript'
import { DualMessageError, isDataError } from '@shared/Errors'
import env, { releaseChannel } from './Environment'
import Constants from 'expo-constants'

enum ErrorSeverity {
  Error = 'error',
  Warning = 'warning',
}

const SENTRY_DSN = env.SENTRY_DSN

export class Sentry {
  /**
   * Initialize sentry based on the environment
   */
  initialize() {
    sentry.init({
      environment: releaseChannel,
      dsn: SENTRY_DSN,
      tracesSampleRate: 0.2,
      autoSessionTracking: true,
      // This will disable sentry in Expo dev client as well as localhost
      enabled: !__DEV__,
      integrations: [sentry.browserTracingIntegration()],
    })

    this.setAppVersion()
  }
  /**
   * Will set up the user with sentry for useful tracking information.
   * @param user the user to associate with the session
   */
  setUser(user: User) {
    sentry.setUser({ email: user.email, id: user.id })
    sentry.setContext('User', { 'Login Method': user.signInProvider })
  }

  // Will set the update information for debugging what update the user has.
  setAppVersion() {
    sentry.setTags({
      AppVersion: Constants.expoConfig?.version,
    })
  }

  /**
   * Will capture a message as an error and send to sentry
   * @param message the message to send
   * @param severity the severity, defaults to an error
   * @param data is an object of debug data in key-value pairs. If it has a code, it will become a searchable tag in sentry.
   */
  captureError(
    message: string,
    severity: ErrorSeverity = ErrorSeverity.Error,
    data?: Record<string, unknown> & { code?: string },
  ) {
    // scope is necessary for passing error data
    // https://github.com/getsentry/sentry-javascript/issues/1607#issuecomment-578762771
    sentry.withScope((scope) => {
      if (data) scope.setExtras(data)
      if (data?.code && nonEmptyString(data.code)) scope.setTag('code', data.code)
      sentry.captureMessage(message, severity)
    })
  }

  /**
   * Will capture a raised exception and pass it to sentry
   * @param err the exception to capture
   * - Will handle data and error codes inside the error instance by setting them to the sentry error context.
   */
  captureException(err: unknown) {
    // scope is necessary for passing error data
    // https://github.com/getsentry/sentry-javascript/issues/1607#issuecomment-578762771
    sentry.withScope((scope) => {
      if (isDataError(err)) {
        scope.setExtras(err.data)
      }
      // This passes the error code which is embedded in the error name as a searchable tag in sentry
      if (err instanceof Error) {
        scope.setTag('code', err.name)
      }
      if (err instanceof DualMessageError) {
        scope.setExtras(pick(err, 'devMsg', 'uiMsg'))
      }
      sentry.captureException(err)
    })
  }

  /**
   * Register Navigation for performance monitoring only on Native
   */
  registerNavigationContainer(_: any): void {}

  ErrorSeverity = ErrorSeverity
}

export default new Sentry()
