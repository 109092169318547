import { openUrl } from '@helpers/client'

import env, { getRunConfiguration } from '../../config/Environment'

const firebaseConfig = getRunConfiguration()

export const FIRESTORE_BASE = `https://console.firebase.google.com/u/0/project/${firebaseConfig.projectId}/firestore/data/`

export function openFirebase(id: string, type: 'user' | 'farm') {
  openUrl(`${FIRESTORE_BASE}~2F${type}s~2F${id}`)
}

export function openAdminLinks(type: 'sentry' | 'stripe' | 'firebase') {
  switch (type) {
    case 'firebase':
      openUrl(
        `https://console.firebase.google.com/u/0/project/${firebaseConfig.projectId}/functions/health?range=last-24h`,
      )
      break
    case 'sentry':
      openUrl(
        `https://sentry.io/organizations/farm-generations/projects/grownby-prod/?environment=${env.APP_ENV}&project=5826993&statsPeriod=14d`,
      )
      break
    case 'stripe':
      openUrl(`https://dashboard.stripe.com/webhooks`)
      break
  }
}
