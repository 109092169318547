import { StyleSheet, View } from 'react-native'

import Colors from '../../constants/Colors'

type Props = {
  style?: any
  clear?: boolean
  top?: number
  bottom?: number
  large?: boolean
}

export function Divider({ style, large, top = 5, bottom = 5, clear }: Props) {
  return (
    <View
      style={[
        styles.divider,
        {
          marginTop: large ? 15 + top : top,
          marginBottom: large ? 15 + bottom : bottom,
          borderTopColor: clear ? Colors.transparent : Colors.shades['100'],
        },
        style,
      ]}
    />
  )
}

const styles = StyleSheet.create({
  divider: {
    flex: 0,
    borderTopWidth: 1,
  },
})
