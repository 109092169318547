import { Header, MessageWithIcon } from '@components'
import * as React from 'react'

import { StyleSheet, View } from 'react-native'

type Props = {
  /**
   * Whether to show the app header.
   * This should be true when the Header component is rendered at the screen level, as it will be replaced also with the screen
   */
  showHeader?: boolean
}

/**  This component renders a screen that is displayed when a user attempts to access a URL path they are not authorized to view. */
export function NotAllowedScreen({ showHeader = false }: Props) {
  return (
    <View>
      {showHeader && <Header />}
      <View style={styles.main} testID="NotAllowedScreen">
        <MessageWithIcon title="This feature is not available for your account." />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    paddingTop: 50,
    height: 200,
  },
})
