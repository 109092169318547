import { Alert } from '@elements'
import { PartialExcept, PartialPick, pick } from '@helpers/typescript'
import { LocalPickup, NonPickup } from '@models/Location'
import equal from 'fast-deep-equal'

export enum ReturnStates {
  GO_BACK,
  DO_NOTHING,
  SAVE,
}

type ReturnTypes<T> =
  | {
      status: ReturnStates.GO_BACK | ReturnStates.DO_NOTHING
    }
  | {
      status: ReturnStates.SAVE
      data: T
    }

/**
 * Will check that there are updates and if anything important is being changed.
 * @param oldLoc the old location to compare against
 * @param newLoc the new location to compare to
 * @return value a promise containing the data that was updated
 */
export function validateLocationEdit(
  oldLoc: LocalPickup,
  newLoc: LocalPickup,
): Promise<ReturnTypes<PartialExcept<LocalPickup, 'id'>>> {
  return new Promise((resolve) => {
    // Select the values that could've been updated to compare with
    const newData = pick(newLoc, 'address', 'name', 'nickname', 'type', 'timezone')

    // If we are editing the get the old values to compare with
    const oldData = pick(oldLoc, 'address', 'name', 'nickname', 'type', 'timezone')

    // Check that we actually made a change
    if (equal(newData, oldData)) {
      return resolve({ status: ReturnStates.GO_BACK })
    }

    // If we update the address warn the farmer to contact customers
    if (!equal(newData.address, oldData.address)) {
      Alert(
        'Warning! Important Change',
        'You are changing your locations address, this will affect all past pickups as well as future orders. GrownBy will not contact your customers, so be sure to communicate clearly the change with them.',
        [
          {
            text: 'Proceed',
            style: 'destructive',
            onPress: () => resolve({ status: ReturnStates.SAVE, data: { ...newData, id: oldLoc.id } }),
          },
          {
            text: 'Cancel',
            style: 'cancel',
            onPress: () => resolve({ status: ReturnStates.DO_NOTHING }),
          },
        ],
      )
    } else {
      resolve({ status: ReturnStates.SAVE, data: { ...newData, id: oldLoc.id } })
    }
  })
}

/**
 * Will check that there are updates and if anything important is being changed.
 * @param oldLoc the old shipping or delivery to compare against
 * @param newLoc the new shipping or delivery to compare to
 * @return value a promise containing the data that was updated
 */
export function validateDeliveryShippingEdit(
  oldLoc: NonPickup,
  newLoc: NonPickup,
): Promise<ReturnTypes<PartialPick<NonPickup, 'id'>>> {
  return new Promise((resolve) => {
    // Select the values that could've been updated to compare with
    const newData = pick(newLoc, 'address', 'name', 'nickname', 'type', 'timezone', 'cost', 'regions', 'feeWaiveOption')

    // If we are editing the get the old values to compare with
    const oldData = pick(oldLoc, 'address', 'name', 'nickname', 'type', 'timezone', 'cost', 'regions', 'feeWaiveOption')

    // Check that we actually made a change
    if (equal(newData, oldData)) {
      return resolve({ status: ReturnStates.GO_BACK })
    }
    resolve({ status: ReturnStates.SAVE, data: { ...newData, id: oldLoc.id } })
  })
}
