import { Icon } from '@elements'
import { isOrderItemProduct, OrderItem } from '@models/Order'
import { Product } from '@models/Product'

import { isProductEbtEligible } from '@helpers/products'
import {
  AlgoliaAdminProduct,
  AlgoliaGeoDoc,
  AlgoliaGeoProduct,
  isAlgoliaAdminProduct,
  isGeoProduct,
} from '@models/Algolia'
import { memo } from 'react'
import Colors from '../constants/Colors'
import { useSelector } from 'react-redux'
import { wholesaleSelector } from '../redux/selectors'

type Props = {
  /** Pass one of the below to determine if the icon should be shown */
  product?: Product | OrderItem['product'] | AlgoliaGeoDoc<AlgoliaGeoProduct> | AlgoliaAdminProduct
  visible?: boolean
  size?: number
}

const shouldShow = (product: Props['product'], visible: boolean | undefined) => {
  if (visible) return true
  if (!product) return false

  // Algolia Product
  if (isGeoProduct(product) || isAlgoliaAdminProduct(product)) {
    return product.isEbt
  }

  // Order Item Product
  if (isOrderItemProduct(product)) {
    return product.isEbtEligible
  }

  return isProductEbtEligible(product)
}

/** Will render a carrot icon if the product is standard and ebt eligible, else null */
export const EbtIcon = memo(function EbtIcon({ product, visible, size = 16 }: Props) {
  const { isWholesale } = useSelector(wholesaleSelector)

  if (!isWholesale && shouldShow(product, visible)) return <Icon name="carrot" color={Colors.gold} size={size} />
  return null
})
