import { useEffect, useRef, useState } from 'react'

import { useInstantSearch } from 'react-instantsearch'
import { snapshotSearchIndexState } from '../api/SearchIndex'
import { AlgoliaDocType } from '../models/Algolia'

/** Returns a value of 'true' when the search index needs to be refreshed. It will then revert to 'false' automatically.
 *
 * - The value is meant to be used by any external api which performs the actual refresh action. In the case of InstantSearch provider, it can take it in the "refresh" prop. In the case of algolia hooks, it must be used to trigger a call to the "refresh" callback from useInstantSearch().
 * - It listens for changes to Algolia indices within the supplied scope and updates the 'needsRefresh' state accordingly. */
export function useNeedsSearchRefresh(farmId: string, scope: AlgoliaDocType): boolean {
  const serial = useRef(0)
  const [needsRefresh, setNeedsRefresh] = useState(false)

  /** listens to the search index state for the farm and algolia type, and sets the refresh state when the new value is greater than the current count */
  useEffect(() => {
    serial.current = Date.now() // Reset the serial when the target changes to not conflict with previous values.

    if (!farmId) return
    return snapshotSearchIndexState(farmId, (update) => {
      const value: number = update[scope] || serial.current

      if (value <= serial.current) {
        return
      }
      serial.current = value
      setNeedsRefresh(true)
    })
  }, [farmId, scope])

  if (needsRefresh) {
    // setTimeout is used to reset the refresh state in the next cycle. Setting the state earlier could result in the
    // search component not refreshing.
    setTimeout(() => setNeedsRefresh(false), 10)
  }
  return needsRefresh
}

/** Checks if a refresh is needed on the provided scope, and calls the update inside an algolia instant search hooks context.
 * - This is only meant to be used by a component inside an instant search hooks provider.
 */
export function useAlgoliaHooksRefresh(farmId: string, scope: AlgoliaDocType) {
  const needsRefresh = useNeedsSearchRefresh(farmId, scope)
  const { refresh } = useInstantSearch()

  useEffect(() => {
    if (!needsRefresh) return
    refresh()
  }, [needsRefresh, refresh])
}
