import { searchLocationSelector, userLocationSelector, userSelector } from '@/redux/selectors'
import { CoordString } from '@helpers/coordinate'
import { Coordinate } from '@models/Coordinate'
import { getDefaultCoords } from '@screens/Explore/helpers'
import { useSelector } from 'react-redux'

/** Returns the coordinates that can be used for search purposes */
export function useCoords(coords?: CoordString | Coordinate): Coordinate | undefined {
  const user = useSelector(userSelector)
  const searchLoc = useSelector(searchLocationSelector)
  const userLoc = useSelector(userLocationSelector)

  return getDefaultCoords({ userAddress: user.address, searchLoc, userLoc, center: coords })
}

/** This will tell us if the two coordinates are reasonably different and should be considered different locations*/
export function hasSignificantCoordDifference(coord1?: Coordinate, coord2?: Coordinate): boolean {
  if (!coord1 || !coord2) return true
  // I chose 0.0001 as the threshold because it's about 10 meters, which is a reasonable distance to consider two locations different
  return Math.abs(coord1.latitude - coord2.latitude) > 0.0001 || Math.abs(coord1.longitude - coord2.longitude) > 0.0001
}
