import { Primitive } from '@helpers/typescript'
import { FC, Fragment, useMemo } from 'react'
import { ActivityIndicatorProps, StyleProp, StyleSheet, View, ViewProps, ViewStyle } from 'react-native'

import { MessageWithIcon, MessageWithIconProps } from '../LoaderWithMessage'
import { Spinner } from './Spinner'

import { useDeepCompareMemo, useDeepCompareMemoize } from '@/hooks/useDeepEqualEffect'
import { isNonNullish } from '@helpers/helpers'

export type LoadingViewProps<S extends Primitive | object> = Omit<ViewProps, 'children'> & {
  /** While loading is true, it will show the spinner only, with loadingProps */
  loading: boolean
  /** These props will be passed to the spinner component shown while loading is true */
  spinnerProps?: ActivityIndicatorProps
  /** If defined while loading, it will show this component instead of the default spinner */
  LoadingComponent?: FC
  /** If this value is not undefined after loading, instead of showing the children it will show MessageWithIcon with this error value as children. MessageWithIconProps.children has precedence over this value */
  error?: string | undefined
  /** The props for component MessageWithIcon. If you provide a 'children' prop here, it will take precedence over the error string as the children of MessageWithIcon */
  messageWithIconProps?: MessageWithIconProps
  /** If the children is a function, then this is expected to resolve to a non-nullish value when loading is complete. If the value is non-nullish, it will be the argument to the children function. Else, you'll get the load error component with the `messageWithIconProps` */
  success?: S
  /** If you provided a `success` value, the children value can be a function of non-nullable success type. If children is regular JSX, it will ignore the 'success' prop and render after loading if no errors */
  children?: FC<NonNullable<S>> | ViewProps['children']
  /** This allows you to customize what is shown in the error scenario. If there is an error after loading and this is defined, it will render this component instead of the default MessageWithIcon */
  ErrorComponent?: FC<{ error: string }>
  /** In switch mode, there won't be a parent View, but only a fragment. This is helpful if you need a custom container like the AdminView, or no container at al, or other complex container configurations. */
  switchMode?: boolean
  /** By default, when loading is true, the View style has the loadingContainer style as base (Overridden by the style prop). While that is a convenience in some situations, in others it's a pain. This prop allows to switch that off, so there will be no default styles on your View whatsoever. It also prevents the Spinner from getting a default flex value */
  noDefaultLoadingContainerStyle?: boolean
}

/** Component that handles conditional rendering of jsx based on a loading state.
 * - This component should not be wrapped in memo() because the children are jsx which means there can be no deep comparison, which will make memoization useless.
 */
export function LoadingView<S extends Primitive | object>({
  loading,
  spinnerProps: { style: spinnerStyle, ...spinnerPropsWithoutStyle } = {},
  success,
  children,
  error,
  messageWithIconProps = {},
  switchMode,
  style,
  noDefaultLoadingContainerStyle = false,
  ErrorComponent,
  ...viewPropsWithoutChildren
}: LoadingViewProps<S>) {
  /** Technically this style memo is not an expensive computation so in some sense it doesn't need to be memoized. However, if this is not memoized, the styled container would need to be re-created more often which is not good */
  const styleMemo = useMemo(
    (): StyleProp<ViewStyle> => [
      /** Will add a loadingContainer styles as base for the View during loading */
      loading && !noDefaultLoadingContainerStyle ? styles.loadingContainer : {},
      style,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, error, typeof children, success, useDeepCompareMemoize(style)],
  )

  /** This styled container is memoized because it fixed problems during initial iterations of this component. If it is ever removed, it should be tested thoroughly */
  const StyledContainer = useDeepCompareMemo(
    () =>
      switchMode
        ? Fragment
        : ({ children }: Pick<ViewProps, 'children'>) => (
            <View {...viewPropsWithoutChildren} style={styleMemo} children={children} />
          ),
    [switchMode, styleMemo, viewPropsWithoutChildren],
  )

  return (
    <StyledContainer>
      {loading ? (
        <Spinner
          size="small"
          style={[noDefaultLoadingContainerStyle ? { flex: undefined } : undefined, spinnerStyle]}
          {...spinnerPropsWithoutStyle}
        />
      ) : error !== undefined ? (
        ErrorComponent ? (
          <ErrorComponent error={error} />
        ) : (
          <MessageWithIcon {...messageWithIconProps} children={messageWithIconProps?.children || error} />
        )
      ) : typeof children === 'function' ? (
        isNonNullish(success) ? (
          children(success)
        ) : (
          <MessageWithIcon
            {...messageWithIconProps}
            children={messageWithIconProps?.children || 'Your data was not loaded correctly'}
          />
        )
      ) : (
        (children as ViewProps['children']) ?? null
      )}
    </StyledContainer>
  )
}
const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    padding: 30,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    textAlign: 'center',
  },
})
