import { StyleSheet, View } from 'react-native'

import { Text } from '../elements/Text'
import { Button, Divider } from '@elements'
import { openUrl } from '@helpers/client'
import { grownbyWebsiteBaseUrl } from '../../config/Environment'
import { Image } from '@components'
import React, { useCallback } from 'react'

/**
 * This is a temporary modal that we show if someone tries to log in to wholesale and doesn't have access. It will be
 * removed once Wholesale is out of beta and we have a signup flow for new users
 */
export function WholesaleBetaSignup() {
  const openRetail = useCallback(() => {
    openUrl(`${grownbyWebsiteBaseUrl(false)}/profile`, { target: '_self' })
  }, [])

  return (
    <View style={styles.container}>
      <Image style={styles.image} source={require('../../assets/images/farmer-onboard/farmer-onboard-image.png')} />
      <Divider clear />
      <Text>
        GrownBy Wholesale is currently in development. Stay tuned for information on how to join as a farmer or
        customer. Thank you!
      </Text>
      <Divider clear />
      <Button title="Go to GrownBy Retail" onPress={openRetail} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    margin: 20,
    alignSelf: 'center',
    alignItems: 'center',
    maxWidth: 500,
  },
  image: {
    width: 200,
    height: 200,
  },
})
