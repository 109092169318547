import { useDebouncedValue } from '@/hooks/useDebounce'
import { useFocusFx } from '@/hooks/useFocusFx'
import { HomeParamList } from '@/navigation/types'
import { SearchInputBar } from '@elements'
import { nonEmptyString } from '@helpers/helpers'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { memo, useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'

/** SearchBar wrapper that sets the input value as navigation query param for the Search Screen*/
export const SearchScreenInput = memo(function SearchScreenInput() {
  const navigation = useNavigation<StackNavigationProp<HomeParamList, 'SearchScreen'>>()
  const { q: searchTerm } = useRoute<RouteProp<HomeParamList, 'SearchScreen'>>().params ?? {}
  const [inputValue, setInputValue] = useState<string>(searchTerm ?? '')
  const debouncedValue = useDebouncedValue(inputValue, 500)

  useFocusFx(
    () => {
      // Reset the value when filter is cleared
      if (searchTerm === undefined) {
        setInputValue('')
      }
    },
    [searchTerm],
    { noRefocus: true },
  )

  useEffect(() => {
    navigation.setParams({ q: nonEmptyString(debouncedValue) ? debouncedValue : undefined })
  }, [debouncedValue, navigation])

  return (
    <SearchInputBar
      value={inputValue}
      onChangeText={setInputValue}
      inputMode="search"
      numberOfLines={1}
      contStyle={styles.searchInput}
      clear={inputValue ? () => setInputValue('') : undefined}
    />
  )
})

const styles = StyleSheet.create({
  searchInput: {
    flex: 2,
    height: 40,
  },
})
