import { FarmBalance } from '@models/Payment'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { prepareMarshal, prepareUnmarshal } from './encoding'

/** marshalFarmBalances returns the farm balance structure for Firestore. */
export function marshalFarmBalances(farmBalance: Partial<FarmBalance>): DocumentData {
  return prepareMarshal(farmBalance)
}

/** unmarshalFarmBalance returns the farm balance structure for Firestore. */
export function unmarshalFarmBalances(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): FarmBalance {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)
  return { ...data, id } as FarmBalance
}
