import { Icon, MoneyInput, Text } from '@elements'
import { formatMoney } from '@helpers/display'
import { Money } from '@models/Money'
import * as React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { Input } from 'react-native-elements'

import { Logger } from '@/config/logger'
import Colors from '@/constants/Colors'
import { extendErr } from '@helpers/helpers'
import { memo, useState } from 'react'

interface NumOrMoneyInputProps<T> {
  value: T
  /** onSave should throw errors on reject, so this component can revert to the original state. otherwise the optimistic ui update will be wrong */
  onSave: (value: T) => Promise<void> | void
  formatValue?: (value: T) => string
}

/** This Input can be used in number input or Money input with passing type  */
function NumOrMoneyInputComp<T extends Money | number = number>({
  value,
  onSave,
  formatValue,
}: NumOrMoneyInputProps<T>) {
  const [isEditing, setIsEditing] = useState(false)
  const [currentValue, setCurrentValue] = useState<T>(value)

  //format Money or number to string
  function getDisplayString(): string | number {
    if (typeof currentValue === 'number') {
      return formatValue ? formatValue(currentValue) : currentValue
    } else {
      return formatMoney(currentValue)
    }
  }

  //return the correct input type
  function inputTypeHelper(): JSX.Element {
    return typeof currentValue === 'number' ? (
      <Input
        value={currentValue.toString()}
        onChangeText={(value) => {
          if (value === '') {
            return setCurrentValue(0 as T)
          }
          setCurrentValue(parseInt(value, 10) as T)
        }}
        containerStyle={{ width: 80 }}
        inputContainerStyle={{ width: 70 }}
        inputStyle={{ width: 70 }}
      />
    ) : (
      <MoneyInput
        value={currentValue}
        onChangeText={(value) => setCurrentValue(value as T)}
        inputStyle={{ textAlign: 'left', fontSize: 15 }}
      />
    )
  }
  return (
    <TouchableOpacity style={{ flex: 2 }}>
      {!isEditing ? (
        <View>
          <Text
            numberOfLines={2}
            onPress={() => setIsEditing(!isEditing)}
            style={{ color: Colors.blue, fontSize: 15, textAlign: 'left' }}
          >
            {getDisplayString()}
          </Text>
        </View>
      ) : (
        <View style={{ flexDirection: 'column' }}>
          {inputTypeHelper()}
          <View style={{ flexDirection: 'row', marginLeft: 10 }}>
            <Icon
              size={20}
              name="times"
              color={Colors.red}
              onPress={() => {
                setIsEditing(!isEditing)
                setCurrentValue(value) // This resets the current value to the one from the prop
              }}
              style={{ marginRight: 5 }}
            />
            <Icon
              size={20}
              name="check"
              onPress={async () => {
                try {
                  await onSave(currentValue)
                } catch (error) {
                  Logger.error(extendErr(error, "Couldn't save a value in input"))
                  setCurrentValue(value) // This resets the current value to the one from the prop
                }
                setIsEditing(false)
              }}
            />
          </View>
        </View>
      )}
    </TouchableOpacity>
  )
}

export const NumOrMoneyInput = memo(NumOrMoneyInputComp) as typeof NumOrMoneyInputComp
