import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { MenuItem } from '@/hooks/useAlgoliaFarmData/useAlgoliaFarmData-helpers'
import { BottomSheetList, Icon, Text, TextH2 } from '@elements'
import { ProductFilterGroup } from '@screens/Shopping/FarmShop/Filtering/filterUtils'
import { useMemo, useState } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import { FilterItem } from '../FilterItem/FilterItem'

type Props = {
  onItemPress: (item: MenuItem) => void
  filter: ProductFilterGroup
}

/** Dropdown modal that shows a list of shares */
export function SharesDropdown({ filter, onItemPress }: Props) {
  const [isVisible, setIsVisible] = useState(false)

  const items = useMemo(
    () =>
      filter.items.map((item) => ({
        ...item,
        subtitle: `Navigate to ${item.label}`,
      })),
    [filter.items],
  )

  return (
    <>
      <Pressable onPress={() => setIsVisible(true)} style={globalStyles.flexRowCenter}>
        <Icon color={Colors.shades['500']} size={20} name="external-link" iconSet="EvilIcons" />
        <Text>Shares ({items.length})</Text>
      </Pressable>

      <BottomSheetList
        isVisible={isVisible}
        onBackdropPress={() => {
          setIsVisible(false)
        }}
        ListHeaderComponent={() => (
          <View style={globalStyles.margin20}>
            <TextH2 center size={16}>
              Shares
            </TextH2>
          </View>
        )}
        renderItem={({ item }) => (
          <FilterItem
            item={item}
            onPress={() => {
              setIsVisible(false)
              onItemPress(item)
            }}
            icon="link"
          />
        )}
        overlayStyle={styles.overlay}
        items={items}
      />
    </>
  )
}

const styles = StyleSheet.create({
  overlay: {
    paddingHorizontal: 0,
  },
})
