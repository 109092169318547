import { cancelPickup } from '@api/Pickups'
import { Alert } from '@elements'
import { format, isAfter } from '@helpers/time'
import { PartialPick, Resolved, pick } from '@helpers/typescript'
import { Pickup, PickupItem, isPickupItemCancelled, isPickupItemOnVacation, OrderItem } from '@models/Order'
import { isShare, isStandard } from '@models/Product'
import { dateTimeInZone } from '@models/Timezone'
import * as React from 'react'

import { Logger } from '@/config/logger'
import { formatShortDate } from '@helpers/display'
import { getCsaChangeDeadlineFromPickup } from '@helpers/order'
import { ItemDetails } from '../CancelPickup'
import { isAfterCSAChangeWindow } from './helpers'

type GetVacationWeeksArgs = {
  pickupItem: PickupItem
  isAdmin: boolean
  orderItem: OrderItem
  pickup: Pickup
  notAwardCredit: boolean
  type: 'claim' | 'unclaim'
}

/** This would be used to get vacation pickups left for shares */
export const getVacationWeeks = ({
  pickupItem,
  pickup,
  isAdmin,
  orderItem,
  type,
  notAwardCredit,
}: GetVacationWeeksArgs) => {
  if (!isShare(orderItem.product)) {
    return { value: false, msg: 'Product not eligible for claiming a pickup vacation' }
  }

  if (isAdmin && isPickupItemOnVacation(pickupItem) && type === 'unclaim')
    return { value: true, msg: 'Undo claimed vacation week' }

  if (isPickupItemOnVacation(pickupItem)) return { value: false, msg: 'This pickup has been skipped' }

  if (isPickupItemCancelled(pickupItem)) return { value: false, msg: 'This share has been cancelled' }

  // Check if the pickup is after the change window
  const afterChangeWindow = isAfterCSAChangeWindow(
    pickup.date,
    pickup.distribution.hours.startTime,
    isAdmin,
    pickupItem.csaChangeOptions?.changeWindow,
  )
  if (afterChangeWindow) return { value: false, msg: 'The change window has already passed' }
  if (orderItem.product.vacationWeeks >= 1)
    return {
      value: notAwardCredit || orderItem.product.vacationWeeks > 0,
      msg: `Vacation pickups left: ${orderItem.product.vacationWeeks}`,
    }
  return { value: false, msg: 'No vacation pickups left' }
}

type IsAllowedToCancelArgs = {
  isAdmin: boolean
  pickupItem: PickupItem
  pickup: Pickup
}

/** this would be used for standard product to get if cancel pickup is allowed */
export const isAllowedToCancel = ({ pickup, isAdmin, pickupItem }: IsAllowedToCancelArgs) => {
  if (isAdmin) return { value: true, msg: `Click check box to cancel item` }
  else {
    const now = dateTimeInZone(pickup.date.zoneName)
    // Allow until the end of the day on the cutoff day
    const cutoffDate = getCsaChangeDeadlineFromPickup(
      pickup.date,
      pickupItem.csaChangeOptions?.changeWindow,
      pickup.distribution.hours.startTime,
    )
    if (isAfter(now, cutoffDate)) return { value: false, msg: 'The cancel window has already passed' }
    else {
      const deadlineMessage = 'Cancellation deadline: ' + formatShortDate(cutoffDate)
      return { value: true, msg: `Click check box to cancel item`, deadlineMessage }
    }
  }
}

/** This would be used for handling Checkbox which is checked or not checked */
export const setShouldCancel = (
  index: number,
  shouldCancel: boolean,
  setItems: React.Dispatch<React.SetStateAction<ItemDetails[]>>,
) => {
  setItems((items) =>
    items.map((item, idx) => {
      if (idx === index) return { ...item, shouldCancel }
      return item
    }),
  )
}

type CancelPickupOrClaimVacationArgs = {
  /** The items to cancel */
  items: ItemDetails[]
  isAdmin?: boolean
  pickup: Pickup
  type?: 'claim' | 'unclaim'
  notAwardCredit?: boolean
  onSuccess?: () => void
  setLoading: (loading: boolean) => void
}

/** Cancels pickups, and claims vacations, and shows modals with the result from the server */
export const cancelPickupOrClaimVacation = async ({
  items,
  pickup,
  type = 'claim',
  notAwardCredit,
  onSuccess,
  setLoading,
  isAdmin,
}: CancelPickupOrClaimVacationArgs) => {
  if (!items.length) return
  const hasShares = !!items.filter((itm) => isShare(itm.pickupItem.product)).length
  const hasStd = !!items.filter((itm) => isStandard(itm.pickupItem.product)).length
  if (!hasShares && !hasStd) return

  // Handle vacation claiming or cancelPickup server side for share product
  // Handle cancelPickup server side for standard product
  setLoading(true)
  try {
    const result = await cancelPickup({
      pickId: pickup.id,
      userId: pickup.user.id,
      type,
      notAwardCredit,
      items: items.map(({ pickupItem }) => pickupItem),
    })
    onSuccess?.()
    setLoading(false)
    return confirmationAlert({
      items: items.map((itm) => pick(itm.pickupItem.product, 'name', 'type')),
      pickupDate: pickup.date,
      distroName: pickup.distribution.name,
      type,
      isAdmin,
      result,
    })
  } catch (err) {
    Logger.error(err)
    setLoading(false)
    Alert(
      `Failed to ${hasShares ? 'Claim Vacations' : ''}${hasShares && hasStd ? ' or ' : ''}${
        hasStd ? 'Cancel Pickups' : ''
      }`,
      `We were unable to process your request, please try again or contact support if the issue persists.`,
    )
  }
}

export const confirmationAlert = ({
  items,
  pickupDate,
  distroName,
  type = 'claim',
  isAdmin = false,
  result,
}: Pick<CancelPickupOrClaimVacationArgs, 'type' | 'isAdmin'> & {
  items: PartialPick<PickupItem['product'], 'name' | 'type'>[]
  pickupDate: Pickup['date']
  distroName: Pickup['distribution']['name']
  result?: Resolved<typeof cancelPickup>
}) => {
  const hasShares = !!items.filter((itm) => isShare(itm)).length
  const hasStd = !!items.filter((itm) => isStandard(itm)).length
  const shareNames = items
    .filter((item) => isShare(item))
    .map((item) => item.name)
    .join(', ')
  const stdNames = items
    .filter((item) => isStandard(item))
    .map((item) => item.name)
    .join(', ')
  const date = format(pickupDate, 'iii, MMM do yyyy')

  const alertTitle =
    type === 'claim'
      ? hasShares && hasStd
        ? 'Vacations Claimed & Pickups Cancelled'
        : hasShares
        ? 'Vacations Claimed'
        : 'Pickups Cancelled'
      : 'Vacations Cancelled'

  let alertMsg = ''
  if (type === 'claim') {
    if (hasShares)
      alertMsg += isAdmin
        ? `Your customer has had ${shareNames} removed from ${distroName} for ${date}.`
        : `You have successfully skipped ${shareNames} for ${date}. Any associated farm credit will be available in a few minutes.`
    if (hasStd) {
      if (hasShares) alertMsg += '\n\n' //Add space between the two paragraphs
      if (result && result.messages.length > 0) {
        if (isAdmin) {
          alertMsg += `Your customer has had ${stdNames} removed from ${distroName} for ${date}. However, some refunds failed. You can handle these failed refunds through the customer details page.\n ${result.messages.join(
            '\n',
          )}`
        } else {
          alertMsg += `You have successfully canceled ${stdNames} for ${date}. However, some refunds failed, please contact the farmer for refunding issues.`
        }
      } else {
        alertMsg += isAdmin
          ? `Your customer has had ${stdNames} removed from ${distroName} for ${date}. Any associated refunds will be available for your customer in a few minutes.`
          : `You have successfully canceled ${stdNames} for ${date}. Any associated refunds will be available in a few minutes.`
      }
    }
  } else {
    if (hasShares)
      alertMsg += isAdmin
        ? `Your customer has had their vacation for ${shareNames} removed from ${distroName} for ${date}.\n ${result?.messages.join(
            '\n',
          )}`
        : `You have successfully cancelled the vacation for ${shareNames} for ${date}. Your farm credit will be deducted to cover this change.`
  }

  Alert(alertTitle, alertMsg)
}
