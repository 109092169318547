import { Spinner } from '@elements'
import { loadScript } from '@helpers/client'
import { MutableRefObject, useEffect, useState } from 'react'
import { View } from 'react-native'

import EProtectClientWrapper from './helpers/EProtectClientWrapper'
import { EPROTECT_URL } from './helpers/constants'
import { EProtectClientInterface } from './helpers/types'

type Props = {
  mode: 'pin' | 'combined'
  eProtectRef: MutableRefObject<EProtectClientInterface | undefined>
  // Should be required, as eProtect always takes some time to load
  onFinishLoading: () => void
}

export default function EProtect(props: Props) {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    loadScript(
      EPROTECT_URL,
      () => {
        props.eProtectRef.current = new EProtectClientWrapper(props.mode)
        setLoading(false)
        props.onFinishLoading()
      },
      'worldpay-eProtect',
    )
  }, [])

  return (
    <View>
      {/*<Text>9999999812000005</Text>*/}
      {loading && <Spinner style={{ height: 100 }} />}
      <div id="payframe" style={{ borderWidth: 1 }} />
    </View>
  )
}
