import { arrayToObject, hasOwnProperty } from '@helpers/helpers'
import { omit, values } from '@helpers/typescript'
import { PaymentSchedule, Product } from '@models/Product'
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'

import { marshalDistribution, unmarshalDistribution } from './Distribution'
import { marshalDateRange, unmarshalDateRange } from './Schedule'
import { marshalDate, unmarshalDate } from './Time'
import { prepareMarshal, prepareUnmarshal } from './encoding'
import { marshalProductFee, unmarshalProductFee } from './ProductFees'

/** marshalProduct returns the data structure to be stored in Firebase. */
export function marshalProduct(product: Partial<Product>, isNested = false): DocumentData {
  let data = omit(prepareMarshal(product), 'id') as DocumentData

  if (isNested) {
    data = prepareMarshal(product) as DocumentData
  }

  // FIXME: Should be removed once availability is fully removed
  if (data.availability) {
    delete data.availability
  }
  if (data.frequency) {
    delete data.frequency
  }
  if ('paymentSchedules' in product && Array.isArray(product.paymentSchedules)) {
    data.paymentSchedules = product.paymentSchedules.map((item) => marshalPaymentSchedule(item))
  }

  if (product.distributions) {
    data.distributions = arrayToObject(
      product.distributions.map((distro) => marshalDistribution(distro, true)),
      (dist) => dist.id,
    )
  }
  if (product.taxesAndFees?.fees) {
    // We need to destructure the object here so that we do not mutate the original data
    data.taxesAndFees = {
      ...product.taxesAndFees,
      fees: arrayToObject(product.taxesAndFees.fees.map(marshalProductFee), (fee) => fee.id),
    }
  }

  if (product.distributionConstraints) {
    data.distributionConstraints = product.distributionConstraints.map((constraint) => ({
      ...constraint,
      dateRange: constraint.dateRange ? marshalDateRange(constraint.dateRange) : undefined,
    }))
  }

  return data
}

/** unmarshalProduct returns a Product form the supplied Firestore data. */
export function unmarshalProduct<T extends Product = Product>(
  idOrSnapshot: FirebaseFirestore.DocumentSnapshot | DocumentSnapshot | string,
  incomingData?: DocumentData,
): T {
  const [id, data] = prepareUnmarshal(idOrSnapshot, incomingData)
  const product = { ...data, id } as T

  // FIXME: Should be removed once availability is fully removed
  if (data.availability) {
    //@ts-expect-error availability is no longer on the product type so should throw an error below
    delete product.availability
  }

  if (hasOwnProperty(product, 'paymentSchedules') && Array.isArray(data.paymentSchedules)) {
    product.paymentSchedules = data.paymentSchedules.map((item: any) => unmarshalPaymentSchedule(item))
  }

  if (data.distributions) {
    product.distributions = values<DocumentData>(data.distributions).map((distro) =>
      unmarshalDistribution(distro.id, distro),
    )
  }
  if (data.taxesAndFees?.fees) {
    // We need to destructure the object here so that we do not mutate the original data
    product.taxesAndFees = {
      ...product.taxesAndFees,
      fees: values<DocumentData>(data.taxesAndFees.fees).map((fee) => unmarshalProductFee(fee.id, fee)),
    }
  }

  if (data.distributionConstraints) {
    product.distributionConstraints = data.distributionConstraints.map((constraint: any) => {
      const unmarshaledConstraint = { ...constraint }
      if (hasOwnProperty(constraint, 'dateRange') && !!constraint.dateRange)
        unmarshaledConstraint.dateRange = unmarshalDateRange(constraint.dateRange)
      return unmarshaledConstraint
    })
  }

  return product
}

export function marshalPaymentSchedule(paymentSchedule: DocumentData): DocumentData {
  const data: DocumentData = { ...paymentSchedule }

  if ('paymentDates' in paymentSchedule) {
    //TODO: Will remove startDate if it exists, should be removed and replaced with a migration
    data.paymentDates = { endDate: marshalDate(paymentSchedule.paymentDates.endDate) }
  }

  return data
}

export function unmarshalPaymentSchedule(data: any): PaymentSchedule {
  const paymentSchedule = { ...data }

  if (data.paymentDates) {
    //TODO: Will remove startDate if it exists, should be removed and replaced with a migration
    paymentSchedule.paymentDates = { endDate: unmarshalDate(data.paymentDates.endDate) }
  }

  return paymentSchedule
}
