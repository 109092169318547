import { MultiSelectAutocompleteComp } from '@/admin/components/MultiSelectAutocomplete'
import { AutoCompleteItem } from '@/hooks/useAutoComplete'
import { adminFarmSelector } from '@/redux/selectors'
import { Toast } from '@elements'
import { useFormikContext } from 'formik'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { CouponFormType } from '../AddEditCoupon'

/** MultipleSelect Producers Autocomplete component for AddEditCoupon Form  */
export function ProducersSelection() {
  const { values, setFieldValue } = useFormikContext<CouponFormType>()
  const farm = useSelector(adminFarmSelector)

  /** producer select callback */
  const onProducerSelect = useCallback(
    (producer: AutoCompleteItem<string>) => {
      if (values.producers?.includes(producer.data)) {
        Toast(`${producer.text} is already selected`)
        return
      }

      setFieldValue('producers', [...(values.producers ?? []), producer.data])
    },
    [setFieldValue, values.producers],
  )

  /** producer remove callback */
  const onRemoveProducer = useCallback(
    (producer: AutoCompleteItem<string>) => {
      setFieldValue(
        'producers',
        values.producers!.filter((item) => item !== producer.data),
      )
    },
    [setFieldValue, values.producers],
  )

  const items = useMemo(() => {
    if (!farm.localProducers) return []

    const filtered = farm.localProducers.filter((el) => !values.producers?.includes(el))

    return filtered.map((producer) => ({ data: producer, text: producer }))
  }, [farm.localProducers, values.producers])

  const selectedItems = useMemo(() => {
    return values.producers?.map((producer) => ({ data: producer, text: producer })) ?? []
  }, [values.producers])

  return (
    <MultiSelectAutocompleteComp
      inline
      onSelect={onProducerSelect}
      onRemove={onRemoveProducer}
      items={items}
      autoCompleteId="producerSearch"
      placeholder="Search for producers"
      selectedItems={selectedItems}
    />
  )
}
