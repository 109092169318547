import { registerWithPhone, signInWithPhone } from '@api/Sessions'
import { loadUserByEmail, loadUserByPhone } from '@api/Users'
import { PhoneInput } from '@components'
import { Alert, FormButton, TextH4, Toast } from '@elements'
import { mainNumPatternForms, marshalPhoneNumber, unmarshalPhoneNumber } from '@helpers/display'
import { PartialExcept, pick } from '@helpers/typescript'
import { SignInProviders, User } from '@models/User'
import { isNotFound } from '@shared/Errors'
import { Formik, FormikProps } from 'formik'
import * as React from 'react'
import { useCallback, useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import * as Yup from 'yup'

import PhoneRegister from './PhoneRegister'
import Verification from './Verification'
import { useRegisterWithAutoVerify } from './useRegisterWithAutoVerify'
import { RecaptchaContainer, useVerifyPhone } from './useVerifyPhone'
import { Logger } from '../../../config/logger'
import Colors from '../../../constants/Colors'
import { correctProvider } from '../LoginHelper'

type FormType = {
  phone: string
}

const validationSchema = Yup.object<FormType>().shape({
  phone: Yup.string()
    .matches(mainNumPatternForms, 'Please enter a valid phone number.')
    .label('phone')
    .required('Please enter a valid phone number.'),
})

export enum PhoneSteps {
  SignIn = 0,
  Register = 1,
  Verify = 2,
}

export default function PhoneNumber({ step = PhoneSteps.SignIn }: { step?: PhoneSteps }) {
  const [currStep, setStep] = useState<PhoneSteps>(step)
  const [verificationId, setVerificationId] = useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const [user, setUser] = useState<PartialExcept<User, 'phoneNumber'>>()
  const verifyPhone = useVerifyPhone(
    useCallback(
      (verificationId) => {
        setStep(PhoneSteps.Verify)
        setVerificationId(verificationId)
      },
      [setStep, setVerificationId],
    ),
  )
  // This is used for android only if the device has autoVerify enabled, and will assist in registering the user
  const autoVerifyListener = useRegisterWithAutoVerify()

  const onRegister = async (localUser: Pick<User, 'name' | 'email'>) => {
    setIsLoading(true)
    try {
      // Check if a user with the email already exists
      const existingUser = await loadUserByEmail(localUser.email)
      correctProvider(existingUser, 'Signup')
    } catch (err) {
      // If loadUserByEmail throws an unexpected error handle that here
      if (!isNotFound(err)) {
        Alert('Signup Error', 'An unknown error has occurred while logging you in. Please contact support.')
        Logger.error(err)
      }

      if (!user?.phoneNumber) throw new Error('Phone number not provided')
      // If the user is not found proceed with account creation
      setUser((prev) => ({ ...pick(prev!, 'phoneNumber'), ...localUser }))
      try {
        // This will start the auth listener so that if the verifyPhone call below automatically logs in the user we will
        // respond to that here
        autoVerifyListener.start({ ...localUser, phoneNumber: user.phoneNumber })
        await verifyPhone(user.phoneNumber)
      } catch (e) {
        autoVerifyListener.stop()
        Alert('Signup Error', 'An error has occurred while logging you in. Please contact support.')
        Logger.error(err)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const onLoginHandler = async ({ phone }: FormType) => {
    setIsLoading(true)
    const phoneNumber = marshalPhoneNumber(phone, false)
    if (!phoneNumber) {
      Toast('Phone number is invalid')
      return setIsLoading(false)
    }
    setUser((prev) => ({ ...prev, phoneNumber }))

    loadUserByPhone(phoneNumber)
      .then((user) => {
        // If the user exists and registered with phone then send verification
        if (user.signInProvider === SignInProviders.Phone) return verifyPhone(phoneNumber)
        // If it was a different provider let them know
        correctProvider(user, 'Login')
      })
      .catch((err) => {
        // User does not exist, so we need to create their account
        if (isNotFound(err)) {
          setStep(PhoneSteps.Register)
        } else {
          Alert('Login Error', 'An unknown error has occurred while logging you in. Please contact support.')
          Logger.error(err)
        }
      })
      .finally(() => setIsLoading(false))
  }

  const onVerify = async (code: string) => {
    if (!user) {
      Alert('Login Error', 'No phone number was provided to login, please go back and try again.')
      setIsLoading(false)
      return
    }
    setIsLoading(true)
    // If onVerify was called than it means the app was not autoVerified, and we should proceed with the regular
    // login flow, so we remove the listener before registering/logging in the user
    autoVerifyListener.stop()
    try {
      if (user.email && user.name) {
        // Registering
        await registerWithPhone(
          verificationId,
          code,
          user.phoneNumber,
          user.email,
          user.name.firstName,
          user.name.lastName,
        )
      } else {
        // Logging in
        await signInWithPhone(verificationId, code)
      }
      Toast('Signed in as ' + unmarshalPhoneNumber(user.phoneNumber))
      setIsLoading(false)
    } catch (e: any) {
      if (e.code === 'auth/code-expired') Toast('This code has expired, please go back and try again')
      else Toast('Error: ' + e.code.toString())
      setIsLoading(false)
    }
  }

  const initialValues: FormType = { phone: '' }

  return (
    <View>
      <Formik initialValues={initialValues} onSubmit={onLoginHandler} validationSchema={validationSchema}>
        {({ handleChange, values, errors, touched, handleSubmit, handleBlur }: FormikProps<FormType>) => (
          <>
            <TextH4 style={styles.inputLabel}>Phone Number</TextH4>
            <PhoneInput
              inputStyle={styles.maskedInput}
              inputContainerStyle={styles.phoneInput}
              label={false}
              onBlur={handleBlur('phone')}
              maskHandler={(txt) => {
                handleChange('phone')(txt)
                setStep(PhoneSteps.SignIn)
              }}
              value={values.phone}
              onSubmitEditing={() => handleSubmit()}
              renderErrorMessage={touched.phone && !!errors.phone}
              errorMessage={errors.phone}
            />
            {/* This is where the reCAPTCHA will be rendered on web*/}
            <RecaptchaContainer />
            {currStep === PhoneSteps.Verify && !!user?.phoneNumber && (
              <Verification loading={isLoading} phoneNumber={user.phoneNumber} onVerify={onVerify} />
            )}
            {currStep === PhoneSteps.Register && <PhoneRegister loading={isLoading} onSubmit={onRegister} />}
            {currStep === PhoneSteps.SignIn && (
              <FormButton title="Continue" loading={isLoading} onPress={handleSubmit} style={styles.margin8} />
            )}
          </>
        )}
      </Formik>
      {currStep === PhoneSteps.SignIn && (
        <Text style={styles.infoText}>
          We'll send you a verification text to make sure it's you. Message and data rates may apply.
        </Text>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  phoneInput: {
    borderBottomWidth: undefined,
    flex: 1,
    borderRadius: 5,
    borderWidth: 2,
    borderColor: Colors.shades['200'],
    flexDirection: 'row',
    height: 50,
    margin: 10,
  },
  maskedInput: {
    paddingVertical: 5,
  },
  inputLabel: {
    marginHorizontal: 10,
  },
  infoText: {
    padding: 10,
    color: Colors.shades['400'],
  },
  margin8: {
    margin: 8,
  },
})
