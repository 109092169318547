import { hasOwnProperty, isObject } from '@helpers/helpers'
import { isLuxonDateTime, isTime } from '@helpers/time'
import { Timezone } from '@models/Timezone'
import { Timestamp as FBTimestamp } from 'firebase/firestore'
import { DateTime } from 'luxon'

export type ISO8601Time = string

export type Timestamp = number

/** Time identifies a stored time that contains both the UTC time and the local time. The luxon DateTime type from client is always converted to this format for db storage. */
export type Time = {
  // The time in the UTC zone.
  utc: ISO8601Time

  // The time as observed locally.
  local: ISO8601Time

  // The timezone of the local time.
  timezone: Timezone
}

/** This type wrapper will deeply replace all instances of DateTime with Time. This is useful for defining types returned from server functions */
export type WithMarshalledDates<T> = T extends DateTime
  ? Time
  : T extends (infer U)[]
  ? WithMarshalledDates<U>[]
  : T extends object
  ? { [K in keyof T]: WithMarshalledDates<T[K]> }
  : T

/** marshalDate returns a serializable Time structure from a Date. */
export function marshalDate(date: DateTime): Time {
  if (isTime(date)) throw new Error("You're passing a Time object into marshalDate, which expects a DateTime object")
  if (!isLuxonDateTime(date)) throw new Error('This is not a DateTime object')

  return {
    utc: date.toUTC().toISO(),
    local: date.toISO(),
    timezone: date.zoneName,
  }
}

/** timeToDate returns a system Date object that is initialized with the UTC time. * It accepts Time structures, ISO 8601 formatted date strings as well as timestamps for backwards compatibility. */
export function unmarshalDate(time: Time | ISO8601Time | Timestamp | FBTimestamp): DateTime {
  if (typeof time === 'number') {
    return DateTime.fromMillis(time)
  }
  if (typeof time === 'string') {
    return DateTime.fromISO(time)
  }
  if (hasOwnProperty(time, '_nanoseconds')) {
    return DateTime.fromJSDate((time as FBTimestamp).toDate())
  }
  if (!isTime(time)) throw new Error('Could not unmarshal time due to bad data: ' + time)
  return DateTime.fromISO(time.utc).setZone(time.timezone)
}

/** Will encode all the DateTime objects nested in an object */
export const marshalDateObject = <T extends Record<any, any>>(obj: T): WithMarshalledDates<T> => {
  const processValue = (val: any): any =>
    Array.isArray(val)
      ? val.map((subVal) => processValue(subVal))
      : isLuxonDateTime(val)
      ? marshalDate(val)
      : isObject(val)
      ? marshalDateObject(val)
      : val

  const newObj: any = {}
  for (const key in obj) {
    const value = obj[key]
    newObj[key] = processValue(value)
  }
  return newObj as WithMarshalledDates<T>
}

/** Will parse all the Time objects nested in an object */
export const unmarshalDateObject = <T extends Record<any, any> | undefined>(obj: WithMarshalledDates<T>): T => {
  const newObj: any = {}
  // Handle undefined values passed in and don't convert them to empty objects
  if (!obj) return obj as T

  const processValue = (val: any): any =>
    Array.isArray(val)
      ? val.map((subVal) => processValue(subVal))
      : isTime(val)
      ? unmarshalDate(val)
      : isObject(val)
      ? unmarshalDateObject(val)
      : val

  for (const key in obj) {
    const value = obj[key]
    newObj[key] = processValue(value)
  }
  return newObj as T
}
