import { useDeviceSize } from '@/hooks/useLayout'
import { Image } from '@components'
import { ButtonClear, Modal, MoneyInput, Text, Toggle } from '@elements'
import { formatMoney } from '@helpers/display'
import { MoneyCalc } from '@helpers/money'
import { Money, Zero } from '@models/Money'
import { EbtCardTypes, EbtPaymentMethod, isEbtPayment } from '@models/PaymentMethod'
import { useContext, useState } from 'react'
import { View } from 'react-native'
import { globalStyles } from '../../../constants/Styles'
import { CheckEbtBalance } from '../AddPaymentMethod/CheckEbtBalance'
import { PaymentOptionsContext } from './PaymentOptionsContext'
import { PaymentOptionsModalContext } from './PaymentOptionsModalContext'
import { finitePaymentStyles } from './helpers/styles'
import { useFinitePayment } from './hooks/useFinitePayment'

type Props = {
  paymentMethod: EbtPaymentMethod
  selectedAmt: Money
  maxEbtAmount: Money
  onChangeSelectedAmount(amt: Money): void
  // If there are no EBT items available on this order
  disabled?: boolean
}

/** This component allows the user to configure EBT tender */
export function EbtPaymentSelection({
  selectedAmt,
  onChangeSelectedAmount,
  paymentMethod,
  maxEbtAmount,
  disabled,
}: Props) {
  const context = useContext(PaymentOptionsContext)
  const modalContext = useContext(PaymentOptionsModalContext)

  const { isSelected, isEditingAmt, updatedValue, onTogglePmt, updatePmtAmount, setValue } = useFinitePayment({
    selectedAmt,
    maxAmount: maxEbtAmount,
    setSelectedAmount: onChangeSelectedAmount,
  })
  const [cardType, setCardType] = useState<EbtPaymentMethod['card_type']>(EbtCardTypes.SNAP)

  const { isExtraSmallDevice } = useDeviceSize()

  /** This function will handle the entire flow of checking the EBT balance and displaying it, after that is done then we are returned back to this flow */
  const checkEBTBalance = async () => {
    modalContext.openGlobalModal(() => {
      Modal(
        <CheckEbtBalance
          userId={context.userId}
          farmId={context.options.farm.id}
          paymentId={paymentMethod.id}
          onDismiss={() => {
            modalContext.openLocalModal()
          }}
        />,
        {
          title: 'Check your EBT balance',
          header: false,
        },
      )
    })
  }

  /** This allows us to change an EBT card to use either SNAP or EBT Cash*/
  const toggleCardType = () => {
    const newType = cardType === EbtCardTypes.CASH ? EbtCardTypes.SNAP : EbtCardTypes.CASH
    setCardType(newType)

    const splitTenderEBT = context.splitTender.find(({ paymentMethod }) => isEbtPayment(paymentMethod))
    context.updateSplitTender({
      paymentMethod: { ...paymentMethod, card_type: newType },
      amount: splitTenderEBT?.amount,
    })
  }

  return (
    <View style={finitePaymentStyles.wrapper}>
      <View style={finitePaymentStyles.containerStyle}>
        <Image style={finitePaymentStyles.imageStyle} source={require('../../../assets/images/icons/ebt-card.png')} />
        <View style={finitePaymentStyles.textCont}>
          <Text type="medium" size={14}>
            EBT {cardType === EbtCardTypes.CASH ? 'Cash' : 'SNAP'} Benefits
          </Text>
          <Text>
            {formatMoney(MoneyCalc.isZero(selectedAmt) || isEditingAmt ? maxEbtAmount : selectedAmt)}{' '}
            {isSelected && !isEditingAmt ? 'will' : 'can'} be applied
          </Text>
        </View>
        <Toggle value={isSelected} disabled={disabled} onChange={onTogglePmt} />
      </View>
      <View>
        <View>
          {isEditingAmt ? (
            <View style={globalStyles.flexRowCenter}>
              <MoneyInput
                inputContainerStyle={finitePaymentStyles.moneyInputInnerContainer}
                style={finitePaymentStyles.moneyInput}
                containerStyle={finitePaymentStyles.moneyInputOuterContainer}
                renderErrorMessage={false}
                value={updatedValue}
                onChangeText={(value) => setValue('updatedValue', value)}
              />
              <ButtonClear
                style={finitePaymentStyles.actionButton}
                small
                title="Update"
                onPress={() => updatePmtAmount(updatedValue)}
                disabled={MoneyCalc.isGreaterThan(updatedValue ?? Zero, maxEbtAmount)}
              />
            </View>
          ) : (
            <View style={globalStyles.flexRow}>
              {isSelected && (
                <ButtonClear
                  style={finitePaymentStyles.actionButton}
                  small
                  onPress={() => setValue('isEditingAmt', true)}
                  title="Edit Amount"
                />
              )}

              <ButtonClear
                style={finitePaymentStyles.actionButton}
                small
                onPress={checkEBTBalance}
                title="Check Balance"
              />
              {isSelected && (
                <ButtonClear
                  style={finitePaymentStyles.actionButton}
                  small
                  onPress={toggleCardType}
                  title={`Use ${cardType === EbtCardTypes.SNAP ? 'EBT Cash' : 'SNAP'} ${
                    isExtraSmallDevice ? '' : 'instead'
                  }`}
                />
              )}
            </View>
          )}
        </View>
      </View>
    </View>
  )
}
