import { ImageSourcePropType } from 'react-native'

export type FarmerOnboardTestimonialData = {
  src: ImageSourcePropType
  description: string
  testimonialsName: string
  from: string
}

export const farmerOnboardTestimonialsData: FarmerOnboardTestimonialData[] = [
  {
    src: require('./christina-chan.png'),
    description: '"GrownBy is the exact e-commerce platform I\'ve been searching for."',
    testimonialsName: 'Christina Chan',
    from: 'Choy Division',
  },
  {
    src: require('./mark-and-taylor.png'),
    description:
      '"I didn\'t have a panic attack at the beginning of starting a new CSA season and it is 100% due to GrownBy"',
    testimonialsName: 'Taylor Mendell',
    from: 'Footprint Farm',
  },
  {
    src: require('./annie-more.png'),
    description:
      '"We\'re excited to offer Online SNAP as a way to increase our diverse community\'s access to healthy, local produce"',
    testimonialsName: 'Cheryl Nunes and Annie Moore',
    from: 'River Queen Greens',
  },
]
