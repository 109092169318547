import { isTruthy } from '@helpers/helpers'
import { Falsy } from '@helpers/typescript'
import React from 'react'
import { DimensionValue, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

type WrapperProps = {
  verticalSpacing?: number
  horizontalSpacing?: number
  itemWidth?: number | DimensionValue
  children: (JSX.Element | Falsy)[]
  style?: StyleProp<ViewStyle>
  itemStyle?: StyleProp<ViewStyle>
}
/**
 * Row component that wrap its children with consistent vertical and horizontal spacing
 *
 * This should not have any form of memoization, at it rerenders no matter what due to the children being JSX Elements (See Stories)
 *
 * @param chldren - inner components that will be wrapped. Must be more than 1
 *
 * @param verticalSpacing - the amount of space used in between the rows
 *
 * @param horizontalSpacing - the amount of space used in between components
 *
 * @param itemWidth - specific width of the inner components
 *
 * @returns
 */
export function RowWrapper({
  children,
  verticalSpacing = 10,
  horizontalSpacing = 10,
  itemWidth,
  itemStyle,
  style,
}: WrapperProps) {
  return (
    <View style={[styles.wrapper, style]}>
      {React.Children.map(children.filter(isTruthy), (child) => (
        <View style={[{ marginRight: horizontalSpacing, marginBottom: verticalSpacing, width: itemWidth }, itemStyle]}>
          {child}
        </View>
      ))}
    </View>
  )
}
const styles = StyleSheet.create({
  wrapper: {
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
})
