import { SeeMoreList } from '@components'
import { memo, useCallback } from 'react'
import { StyleSheet } from 'react-native'

import { AlgoliaGeoDoc, AlgoliaGeoFarm } from '@models/Algolia'
import { TopFarmCard } from './TopFarmCard'

export const FeaturedFarmsList = memo(function FeaturedFarmsList({
  items,
}: {
  items: AlgoliaGeoDoc<AlgoliaGeoFarm>[]
}) {
  const renderItem = useCallback(
    (farm: AlgoliaGeoDoc<AlgoliaGeoFarm>) => <TopFarmCard flagText="Top Farm" farm={farm} />,
    [],
  )
  return (
    <SeeMoreList
      cardWidth={330}
      title="Top GrownBy Farms"
      data={items}
      renderItem={renderItem}
      contentContainerStyle={styles.spacing}
    />
  )
})

const styles = StyleSheet.create({
  icon: {
    marginLeft: 10,
    height: 40,
    width: 40,
    resizeMode: 'contain',
  },
  spacing: {
    paddingTop: 10,
  },
})
