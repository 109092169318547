import { SignInSummary } from '@models/SignInSummary'
import React, { memo } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'

import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { Text } from '@elements'
import { formatDateWithMonth } from '@helpers/display'
import { removeDuplicates } from '@helpers/helpers'
import { Location } from '@models/Location'

type Props = {
  location: Location
  item: SignInSummary
  onPress: (item: SignInSummary) => void
}

/** SignIn card, responsible to show SignInSummary data in a compact way */
export const SummaryCard = memo(function SummaryCard({ location, item, onPress }: Props) {
  const customerNo = removeDuplicates(item.items.map((itm) => itm.user.id)).length

  return (
    <Pressable onPress={() => onPress(item)} style={styles.card}>
      <View style={globalStyles.flex1}>
        <Text type="medium" size={16}>
          {location.name}
        </Text>
        <Text size={10} color={Colors.shades[400]} type="medium">
          {customerNo} Customers
        </Text>
      </View>
      <View style={styles.dateCont}>
        <Text size={14}>{formatDateWithMonth(item.date)}</Text>
      </View>
    </Pressable>
  )
})

const styles = StyleSheet.create({
  card: {
    borderWidth: 1,
    borderColor: Colors.shades['200'],
    borderRadius: 8,
    padding: 10,
    marginBottom: 10,
    flexDirection: 'row',
  },
  editBtn: {
    alignSelf: 'flex-end',
    paddingVertical: 0,
    paddingRight: 0,
  },
  dateCont: {
    marginLeft: 4,
  },
})
