import { VerifyEbtCard } from './VerifyEbtCard'
import { StyleSheet, View } from 'react-native'
import { useState } from 'react'
import { loadAvailableBalance } from '@api/Payments'
import { PaymentSources } from '@models/Invoice'
import { Button, ButtonClear, HeaderText, LoadingView } from '@elements'
import { formatMoney } from '@helpers/display'
import Colors from '../../../constants/Colors'
import { useApiFx } from '../../../hooks/useApiFx'
import { errorToString } from '@helpers/helpers'

type Props = {
  // The payment method to check balance for
  paymentId: string
  // The user ID that owns the payment method
  userId: string
  // The farm that we are checking the balance for, must be provided to link the transaction
  farmId: string
  // A callback to be run when the modal is dismissed
  onDismiss(): void
}

/** This will display the results of the balance check to the user */
export function CheckEbtBalance({ userId, paymentId, farmId, onDismiss }: Props) {
  const [pinVerified, setPinVerified] = useState(false)
  const balance = useApiFx(
    loadAvailableBalance<PaymentSources.WORLD_PAY_EBT>,
    [paymentId, PaymentSources.WORLD_PAY_EBT, userId, farmId],
    pinVerified,
  )
  const onSuccess = async () => {
    setPinVerified(true)
  }
  if (pinVerified) {
    return (
      <View>
        <View style={styles.nestedHeader}>
          <ButtonClear icon="arrow-left" title="Back" color={Colors.green} onPress={() => onDismiss()} />
          <HeaderText>View EBT Balance</HeaderText>
        </View>
        <LoadingView
          loading={balance.loading}
          success={balance.data}
          error={balance.err}
          messageWithIconProps={{
            title: 'Error checking balance',
            children: errorToString(balance.err),
            style: { flex: undefined },
          }}
        >
          {(data) => (
            <>
              <HeaderText>EBT SNAP: {formatMoney(data.snap)}</HeaderText>
              <HeaderText>EBT Cash: {formatMoney(data.cash)}</HeaderText>
            </>
          )}
        </LoadingView>
        <Button title="Done" onPress={onDismiss} />
      </View>
    )
  }
  return (
    <View>
      <View style={styles.nestedHeader}>
        <ButtonClear icon="arrow-left" title="Back" color={Colors.green} onPress={() => onDismiss()} />
        <HeaderText>Check EBT Balance</HeaderText>
      </View>
      <VerifyEbtCard payId={paymentId} userId={userId} onSuccess={onSuccess} />
    </View>
  )
}

const styles = StyleSheet.create({
  nestedHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
  },
})
