import { DependencyList, useCallback, useEffect, useState } from 'react'

import { Logger } from '@/config/logger'
import useDeepCompareEffect from '@/hooks/useDeepEqualEffect'
import { FocusFxOpts, useDeepCompareFocusFx, useFocusFx } from '@/hooks/useFocusFx'
import { makeAbortable } from '@helpers/abortablePromise'
import { ErrorCode, isErrorWithCode } from '@shared/Errors'

/** @param isCurrent represents whether the effect has not cleaned up. If the fx deps change, the cleanup fn will be called, followed by the async callback re-triggered, and the isCurrent value will become false inside the previous execution. If isCurrent is false, the async callback should handle it by aborting promises and terminating early, in light of the knowledge the same callback has started running again in a parallel execution due to a change in parameters */
export type CancelableCallback = (isCurrent: boolean) => Promise<void>

/** Async effect. Automatically cancels previous promise on re-run
 *  * - Doesn't implement the 'refresh' return callback because that requires merging dependencies with a signal.current, which is only advisable on a deep compare effect
 */
export function useCancelableFx(
  asyncCallback: CancelableCallback,
  dependencies: DependencyList,
  onErr?: (err: unknown) => void,
) {
  useEffect(() => {
    let isCurrent = true

    const { abortable, controller } = makeAbortable(asyncCallback(isCurrent))

    abortable.catch((err: unknown) => {
      if (isErrorWithCode(err, ErrorCode.AbortedError)) {
        // Do nothing, because the promise was simply aborted
        return
      }
      Logger.error(err)
      onErr?.(err)
    })

    return () => {
      isCurrent = false
      controller.abort()
    }
    // asyncCallback intentionally left out of deps because effect callbacks by convention don't change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}

export type CancelableFocusFxOpts = {
  /** error handler for the main cancelable callback fx */
  onErr?: (err: unknown) => void
} & FocusFxOpts

/** async fx that runs only when screen is in focus
 * - Doesn't implement the 'refresh' return callback because that requires merging dependencies with a signal.current, which is only advisable on a deep compare effect, to prevent potentially infinite loops
 */
export function useCancelableFocusFx(
  asyncCallback: CancelableCallback,
  dependencies: DependencyList,
  { onErr, ...focusFxOpts }: CancelableFocusFxOpts = {},
) {
  useFocusFx(
    () => {
      let isCurrent = true
      const { abortable, controller } = makeAbortable(asyncCallback(isCurrent))

      abortable.catch((err) => {
        if (isErrorWithCode(err, ErrorCode.AbortedError)) {
          // Do nothing, because the promise was simply aborted
          return
        }
        Logger.error(err)
        onErr?.(err)
      })

      return () => {
        isCurrent = false
        controller.abort()
      }
    },
    // asyncCallback intentionally left out of deps because effect callbacks by convention don't change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies,
    focusFxOpts,
  )
}

/** Async effect that runs when deps have deep inequality */
export function useCancelableDeepCompareFx(
  asyncCallback: CancelableCallback,
  dependencies: DependencyList,
  onErr?: (err: unknown) => void,
) {
  const [signal, setSignal] = useState(0)

  const refresh = useCallback(() => {
    setSignal((p) => p + 1)
  }, [setSignal])

  useDeepCompareEffect(() => {
    let isCurrent = true
    const { abortable, controller } = makeAbortable(asyncCallback(isCurrent))

    abortable.catch((err) => {
      if (isErrorWithCode(err, ErrorCode.AbortedError)) {
        // Do nothing, because the promise was simply aborted
        return
      }
      Logger.error(err)
      onErr?.(err)
    })

    return () => {
      isCurrent = false
      controller.abort()
    }
    // asyncCallback intentionally left out of deps because effect callbacks by convention don't change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies.concat(signal))

  return refresh
}

/** focus fx that may take a cancelable async callback, and will re-run it when deps have deep inequality */
export function useCancelableDeepFocusFx(
  asyncCallback: CancelableCallback,
  dependencies: DependencyList,
  { onErr, ...focusFxOpts }: CancelableFocusFxOpts = {},
) {
  const [signal, setSignal] = useState(0)

  /** Will trigger the callback */
  const refresh = useCallback(() => {
    setSignal((p) => p + 1)
  }, [setSignal])

  useDeepCompareFocusFx(
    () => {
      let isCurrent = true
      const { abortable, controller } = makeAbortable(asyncCallback(isCurrent))

      abortable.catch((err) => {
        if (isErrorWithCode(err, ErrorCode.AbortedError)) {
          // Do nothing, because the promise was simply aborted
          return
        }
        Logger.error(err)
        onErr?.(err)
      })

      return () => {
        isCurrent = false
        controller.abort()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies.concat(signal),
    focusFxOpts,
  )

  return refresh
}

/** Takes a memoized callback and re-runs it on referential inequality, with automatic cancellation of simultaneous promises.
 * - Provides a refresh callback. On refresh, cleanup will cancel any prior pending callbacks to prevent simultaneous runs. */
export function useCancelableDeepFocusCallback(
  memoizedCallback: CancelableCallback,
  { onErr, ...focusFxOpts }: CancelableFocusFxOpts = {},
) {
  const [signal, setSignal] = useState(0)

  /** Will trigger the callback */
  const refresh = useCallback(() => {
    setSignal((p) => p + 1)
  }, [setSignal])

  useDeepCompareFocusFx(
    () => {
      let isCurrent = true
      const { abortable, controller } = makeAbortable(memoizedCallback(isCurrent))

      abortable.catch((err) => {
        if (isErrorWithCode(err, ErrorCode.AbortedError)) {
          // Do nothing, because the promise was simply aborted
          return
        }
        Logger.error(err)
        onErr?.(err)
      })

      return () => {
        isCurrent = false
        controller.abort()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [memoizedCallback, signal],
    focusFxOpts,
  )

  return refresh
}
