import { signInWithAppleWeb } from '@api/Sessions'
import { errorToString } from '@helpers/helpers'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'

import LoginOptButton from './LoginOptButton'
import { UserStackParamList } from '../../navigation/types'
import { Alert } from '../elements/Overlays/Popups'

import Colors from '@/constants/Colors'
import { Logger } from '@/config/logger'

export function AppleLogin() {
  const navigation = useNavigation<StackNavigationProp<UserStackParamList>>()

  const loginWithApple = async () => {
    signInWithAppleWeb().catch((err) => {
      const msg = errorToString(err)
      if (msg === 'Private Relay') {
        navigation.navigate('PersonalInformation', { updateEmail: true })
        return
      }
      switch (err.code) {
        case 'auth/popup-closed-by-user':
        case 'auth/cancelled-popup-request':
          Logger.debug('Popup Closed')
          break
        default:
          Alert('Login Failed', `Failed to login with Apple. (${msg})`)
          Logger.debug(err)
          break
      }
    })
  }
  return <LoginOptButton color={Colors.black} title="Login with Apple" icon="apple" onPress={loginWithApple} />
}
