import { Money } from '@models/Money'
import { DateTime } from 'luxon'
import { ProductFeesForInvoice } from './ProductFee'

export enum PaymentType {
  PAY_FULL = 0,
  INSTALLMENTS = 1,
}

export type FarmBalance = {
  id: string
  farmId: string
  farmName: string
  amount: number
  lastChange: {
    amount: number
    reason: string
    link?: string
  }
  reload: {
    enabled: boolean
    amount: number
  }
}

export type PaymentInterval = {
  date: DateTime
  /** The total amount after any discounts and fee items have been applied */
  total: Money
  /** Subtotal is the total without fee items */
  subtotal: Money
  /** Any discounts applied to the invoice */
  discounts?: Money
  ebtEligibleAmount?: Money
  /** Any productFees like taxes or flat fees applied to the invoice */
  taxesAndFees?: ProductFeesForInvoice
}
