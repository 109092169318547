import { useMemo, useState } from 'react'

/** Allows you to use state with a pre-defined group of setters, which can change your state to values specified in advance.
 * - The controlled states will only be read once, and future changes will be ignored
 */
export function useControlledState<State>(initial: State, states: State[]): [State, (() => void)[]] {
  const [state, setState] = useState(initial)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setters = useMemo(() => states.map((state) => () => setState(state)), [])
  return [state, setters]
}

/**
 * - The 1st item is the boolean state.
 * - The 2nd item is the toggle fn
 * - The 3rd item is the setState fn
 */
type UseBooleanStateReturn = [boolean, () => void, (state: boolean) => void]

/** Helps you to use a boolean state with a pre-defined toggle function */
export function useBooleanState(initial: boolean): UseBooleanStateReturn {
  const [state, setState] = useState(initial)

  return useMemo(() => [state, () => setState((prev) => !prev), setState], [state, setState])
}
