import { createDrawerNavigator, DrawerContentComponentProps, DrawerContentScrollView } from '@react-navigation/drawer'
import { useWindowDimensions, View } from 'react-native'
import { useSelector } from 'react-redux'
import { isMobile, isWeb, SIDEBAR_WIDTH } from '../../constants/Layout'
import { adminFarmSelector, adminFeaturesAvailableSelector, wholesaleSelector } from '../../redux/selectors'
import { AppStatus } from '../../screens/AppStatus'
import { AdminHeader } from '../components/AdminHeader'
import { FarmSelection } from '../components/FarmSelection'
import AdminAnalyticsScreen from '../screens/AdminAnalyticsScreen'
import { AdminDashboardScreen } from '../screens/AdminDashboardScreen/AdminDashboardScreen'
import AdminOnboardScreen from '../screens/AdminOnboardScreen'
import AdminSalesScreen from '../screens/AdminSalesScreen'
import CreateFarmProfile from '../screens/CreateFarmProfile'
import AdminInvoicesScreen from '../screens/Invoice/AdminInvoicesScreen'
import {
  AdminCouponsNavigator,
  AdminDistributionSummaryNavigator,
  AdminMessagesStack,
  AdminOrdersNavigator,
  AdminProducts,
  CSAStackNav,
  CustomerNavigator,
  DistributionSchedulesNavigator,
  LocationsAndZonesNavigator,
} from './AdminStackNavigators'
import { AdminDrawerParamList } from './types'

import { grownbyWebsiteBaseUrl } from '@/config/Environment'
import { useHasPermission } from '@/hooks/useHasPermission'
import { useDeviceSize } from '@/hooks/useLayout'
import { setNavProps } from '@/redux/actions/appState'
import { getStore } from '@/redux/store'
import { Divider } from '@elements'
import { openUrl, openUrlInApp } from '@helpers/client'
import { isNonNullish } from '@helpers/helpers'
import { Permission } from '@helpers/Permission'
import { createContext, Fragment } from 'react'
import { AdminFarmSetupScreen } from '../screens/FarmSetup/AdminSetupScreen'
import { DrawerItem, styles } from './adminDrawerComponents'

export const DrawerContentContext = createContext({} as DrawerContentComponentProps)

/** Component that provides the UI for the drawer when open */
function CustomDrawerContent(props: DrawerContentComponentProps) {
  const availFeatures = useSelector(adminFeaturesAvailableSelector)
  const farm = useSelector(adminFarmSelector)
  const { isWholesale } = useSelector(wholesaleSelector)
  const hasPermission = useHasPermission()

  const { height } = useWindowDimensions()

  const drawerItems = [
    [
      <DrawerItem
        key="onboard"
        label="Welcome! Start Here"
        route="Onboard"
        isSelected={(s, route) => route.name === 'Onboard'}
        shouldShow={() => hasPermission(Permission.AccountSetup)}
        noIcon
      />,
      <DrawerItem
        key="dashboard"
        label="Dashboard"
        route="Dashboard"
        icon="chart-line"
        isSelected={(s, route) => route.name === 'Dashboard'}
      />,
      <DrawerItem
        key="sales"
        label="Sales Report"
        route="Sales"
        icon="coins"
        isSelected={(s, route) => route.name === 'Sales'}
        shouldShow={() => hasPermission(Permission.AccountSetup)}
      />,
      <DrawerItem
        key="analytics"
        label="Analytics"
        route="Analytics"
        icon="chart-bar"
        isSelected={(s, route) => route.name === 'Analytics'}
        shouldShow={() => hasPermission(Permission.AccountSetup) && !!availFeatures?.analytics}
      />,
      <DrawerItem
        key="shop"
        label="Your Shop"
        onPress={() => {
          /** Clear farm cache before opening the shop, to help the farmer see their most recent changes */
          getStore().dispatch(setNavProps())
          const url = `${grownbyWebsiteBaseUrl(isWholesale)}farms/${farm?.id}/shop`
          if (isMobile) {
            /** Using an in-app browser in mobile will guarantee the farmer always sees the most recent shop regardless of app cache. It also allows them to come back to the admin dashoard easily after they're done checking their shop to verify their changes look the way they expected. */
            openUrlInApp(url)
          } else {
            /** In web, it's best to open it in a new tab, as usual, but this option relies on the farm cache being cleared, to show the up-to-date data */
            openUrl(url)
          }
        }}
        icon="store"
      />,
      // <DrawerItem
      //   key="messages"
      //   label="Messages"
      //   route="MessagesStack"
      //   isSelected={(s, route) => route.name === 'MessagesStack'}
      //   iconSet="Entypo"
      //   icon="mail"
      // />,
    ],
    [
      <DrawerItem
        key="locations"
        label="Locations & Zones"
        route="LocationsAndZonesNavigator"
        screen="Locations"
        icon="map-marker-alt"
        isSelected={(s, route) => route.name === 'LocationsAndZonesNavigator'}
        shouldShow={() => hasPermission(Permission.ProductSetup)}
      />,
      <DrawerItem
        key="schedules"
        label="Schedules"
        route="DistributionSchedulesNavigator"
        icon="calendar-alt"
        screen="Schedules"
        isSelected={(s, route) => route.name === 'DistributionSchedulesNavigator'}
        shouldShow={() => hasPermission(Permission.ProductSetup)}
      />,
      <DrawerItem
        key="csas"
        label="CSA Groups"
        route="CSAStackNav"
        icon="box"
        screen="CSAGroup"
        isSelected={(s, route) => route.name === 'CSAStackNav'}
        shouldShow={() => hasPermission(Permission.ProductSetup)}
      />,
      <DrawerItem
        key="products"
        label="Products"
        route="Products"
        icon="carrot"
        screen="ProductList"
        isSelected={(s, route) => route.name === 'Products'}
        shouldShow={() => hasPermission(Permission.ProductSetup)}
      />,
      <DrawerItem
        key="share-templates"
        label="Share Templates"
        route="Products"
        screen="ShareTemplates"
        isSelected={(s, route, subRoute) => {
          return subRoute?.name === 'ShareTemplates'
        }}
        shouldGoToScreen={() => true}
        shouldShow={(s, route) => {
          return route.name === 'Products' && hasPermission(Permission.ProductSetup)
        }}
        isSub
      />,
      <DrawerItem
        key="categories"
        label="Categories"
        route="Products"
        screen="Categories"
        isSelected={(s, route, subRoute) => {
          return subRoute?.name === 'Categories'
        }}
        shouldGoToScreen={() => true}
        shouldShow={(s, route) => {
          return route.name === 'Products' && hasPermission(Permission.ProductSetup)
        }}
        isSub
      />,
      <DrawerItem
        key="producers"
        label="Producers"
        route="Products"
        screen="Producers"
        isSelected={(s, route, subRoute) => {
          return subRoute?.name === 'Producers'
        }}
        shouldGoToScreen={() => true}
        shouldShow={(s, route) => {
          return route.name === 'Products' && hasPermission(Permission.ProductSetup)
        }}
        isSub
      />,
      <DrawerItem
        key="promos"
        label="Promotions"
        route="AdminCoupons"
        icon="tags"
        screen="CouponList"
        isSelected={(s, route) => route.name === 'AdminCoupons'}
        shouldShow={() => hasPermission(Permission.ProductSetup)}
      />,
    ],
    [
      <DrawerItem
        key="customers"
        label="Customers"
        route="Customer"
        icon="users"
        screen="CustomerList"
        isSelected={(s, route) => route.name === 'Customer'}
        shouldShow={() => hasPermission(Permission.Orders)}
      />,
      <DrawerItem
        key="orders"
        label="Orders"
        route="Orders"
        icon="shopping-cart"
        screen="AdminOrderList"
        isSelected={(s, route) => route.name === 'Orders'}
        shouldShow={() => hasPermission(Permission.Orders)}
      />,
      <DrawerItem
        key="invoices"
        label="Invoices"
        route="Invoices"
        icon="file-invoice-dollar"
        isSelected={(s, route) => route.name === 'Invoices'}
        shouldShow={() => hasPermission(Permission.Orders)}
      />,
      <DrawerItem
        key="distribution-summaries"
        label="Distributions"
        route="DistributionSummaryStack"
        icon="list"
        isSelected={(s, route) => route.name === 'DistributionSummaryStack'}
        shouldShow={() => hasPermission(Permission.Distributions)}
      />,
    ],
    [
      <DrawerItem
        key="profile"
        label="Farm Profile"
        route="FarmProfileSetUp"
        icon="barn"
        iconSet="MaterialCommunityIcons"
        isSelected={(s, route) => route.name === 'FarmProfileSetUp'}
        shouldShow={() => hasPermission(Permission.AccountSetup)}
      />,

      <DrawerItem
        key="account"
        label="Account Settings"
        route="FarmSetup"
        icon="cog"
        isSelected={(s, route) => route.name === 'FarmSetup'}
      />,
    ],
  ].filter((group) => group.some(isNonNullish))

  return (
    <DrawerContentContext.Provider value={props}>
      <DrawerContentScrollView>
        <FarmSelection />
        <View style={[styles.container, { maxHeight: isWeb ? height * 0.9 : 'auto' }]}>
          {drawerItems.map((group, index) => {
            return (
              <Fragment key={`group-${index}`}>
                {group}
                {index < drawerItems.length - 1 && <Divider />}
              </Fragment>
            )
          })}
        </View>
      </DrawerContentScrollView>
    </DrawerContentContext.Provider>
  )
}

const AdminDrawer = createDrawerNavigator<AdminDrawerParamList>()

/** Drawer navigator that contains all the farmer dashboard screens and sub navigators */
export function AdminDrawerNavigator() {
  const { isSmallDevice } = useDeviceSize()

  return (
    <AppStatus scope="farmer">
      <AdminDrawer.Navigator
        initialRouteName="Dashboard"
        drawerContent={(props) => <CustomDrawerContent {...props} />}
        screenOptions={{
          drawerType: isSmallDevice ? 'front' : 'permanent',
          header: () => <AdminHeader />,
          drawerStyle: { width: SIDEBAR_WIDTH },
        }}
      >
        <AdminDrawer.Screen name="Onboard" component={AdminOnboardScreen} />
        <AdminDrawer.Screen name="Dashboard" component={AdminDashboardScreen} />
        <AdminDrawer.Screen name="Sales" component={AdminSalesScreen} />
        <AdminDrawer.Screen name="Analytics" component={AdminAnalyticsScreen} />
        <AdminDrawer.Screen name="Customer" component={CustomerNavigator} />
        <AdminDrawer.Screen name="Orders" component={AdminOrdersNavigator} />
        <AdminDrawer.Screen name="Invoices" component={AdminInvoicesScreen} />
        <AdminDrawer.Screen name="LocationsAndZonesNavigator" component={LocationsAndZonesNavigator} />
        <AdminDrawer.Screen name="DistributionSchedulesNavigator" component={DistributionSchedulesNavigator} />
        <AdminDrawer.Screen name="DistributionSummaryStack" component={AdminDistributionSummaryNavigator} />
        <AdminDrawer.Screen name="CSAStackNav" component={CSAStackNav} />
        <AdminDrawer.Screen name="MessagesStack" component={AdminMessagesStack} />
        <AdminDrawer.Screen name="Products" component={AdminProducts} />
        <AdminDrawer.Screen name="AdminCoupons" component={AdminCouponsNavigator} />
        <AdminDrawer.Screen name="FarmSetup" component={AdminFarmSetupScreen} />
        <AdminDrawer.Screen name="FarmProfileSetUp" component={CreateFarmProfile} />
      </AdminDrawer.Navigator>
    </AppStatus>
  )
}
