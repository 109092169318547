import { formatUrl, openUrl } from '@helpers/client'
import { objToStr } from '@helpers/log'
import { memo, useCallback } from 'react'
import { GestureResponderEvent, TouchableOpacity, TouchableOpacityProps } from 'react-native'

import { isMobile } from '@/constants/Layout'

export type TouchableExternalLinkProps = TouchableOpacityProps & {
  url: string
  webOpts?: Parameters<typeof openUrl>[1]
}

/** Used for navigation with react-navigation deep links. Similar to a customized react-navigation <Link /> */
export const TouchableExternalLink = memo(function TouchableExternalLink({
  onPress,
  url,
  webOpts,
  children,
  ...touchableProps
}: TouchableExternalLinkProps) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Comp = useCallback(
    isMobile
      ? TouchableOpacity //In mobile, open the external link with a regular touchable
      : memo(function TouchableOpacityWeb(
          props: TouchableOpacityProps, //In web, open the external link via openUrl with web options, while displaying the href on browser, for semantic html
        ) {
          return (
            <TouchableOpacity
              {...props}
              // @ts-ignore
              href={formatUrl(url)}
              hrefAttrs={{ rel: 'noopener' }}
              role="link"
            />
          )
        }),
    [isMobile, url],
  )

  const onPressCb = useCallback(
    (e: GestureResponderEvent) => {
      e.stopPropagation()
      e.preventDefault()
      openUrl(url, webOpts)
      onPress?.(e)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [url, objToStr(webOpts), onPress],
  )

  return (
    <Comp {...touchableProps} onPress={onPressCb}>
      {children}
    </Comp>
  )
})
