import { ScrollView } from 'react-native'

import { useLayout } from '@/hooks/useLayout'
import { Divider } from '../../components/elements/Divider'
import { Text } from '../../components/elements/Text'
import { globalStyles } from '../../constants/Styles'

export function TermsAndConditions() {
  const { bottom } = useLayout()
  return (
    <ScrollView contentContainerStyle={globalStyles.padding10}>
      <Text>
        PLEASE REVIEW THE TERMS OF THIS AGREEMENT CAREFULLY. THIS IS AN END USER LICENSE AGREEMENT THAT COVERS YOUR
        ACCESS TO AND USAGE OF THE GROWNBY PLATFORM. IF YOU DO DECIDE TO VISIT OR USE THE GROWNBY PLATFORM AND/OR
        PURCHASE ANY GOODS OR SERVICES FROM THIRD PARTY SELLERS THROUGH THE GROWNBY PLATFORM, YOU ARE AGREEING TO BE
        BOUND BY THE TERMS OF THIS AGREEMENT BY SUCH ACTION. 1. Introduction. These terms and conditions (this
        “Agreement”) govern when you: (a) access the GrownBy platform, mobile app, and/or website, including interact
        with, and/or view any of the video, blogs, audio, stories, text, photographs , graphics, artwork and/or other
        content featured on the GrownBy platform, mobile app, and/or website (collectively “GrownBy Platform” or
        “Site”); (b) use the GrownBy Platform to access goods and/or services marketed for sale directly from third
        party businesses, including through their own individual website(s) linked through the GrownBy Platform
        (“Seller(s)”) for purchase; (c) use the GrownBy Platform to purchase goods and/or services directly from Sellers
        (“Purchase”); and/or (d) provide access to a third party payment portal provider’s (“Payment Processor”) online
        payment checkout portal and/or website to facilitate a Purchase (“Payment Processing”). The GrownBy Platform is
        an online market place that allows Sellers to offer and/or sell their goods and/or services to you for Purchase
        directly. GrownBy is not a party to any Purchase you may make through the GrownBy Platform. The actual contract
        for sale of a Purchase is directly between you and Sellers, not between you and GrownBy. Further, any Payment
        Processing for a Purchase, including the terms and conditions governing such Payment Processing, is between you
        and Payment Processor, not you and GrownBy
      </Text>
      <Divider clear />
      <Text>
        By using the GrownBy Platform, you acknowledge that you have read, understood, and agree to be legally bound by
        this Agreement and have read, understand and agree to be legally bound by our Privacy Policy found on the
        GrownBy Platform. For clarity, for all Payment Processing you will be directed to a third party online platform
        and Payment Processor and leave the GrownBy Platform to complete a Purchase. By completing such Payment
        Processing, you also will be required to agree to such Payment Processor’s terms of service as they exist on or
        through Payment Processor’s platform or service at the time of the completion of the Payment Processing.
      </Text>
      <Divider clear />
      <Text>
        GrownBy may modify this Agreement from time to time at our sole discretion. When changes are made, we will
        notify you by making the revised version available on the GrownBy Platform, and will indicate the date that
        revisions were last made. You should revisit this Agreement on a regular basis as revised versions will be
        binding on you. Any such modification will be effective upon our posting of the new terms and conditions.
      </Text>
      <Divider clear />
      <Text>
        <Text type="bold">2. Eligibility.</Text> By using the GrownBy Platform, you represent that you are at least
        eighteen (18) years of age (or the applicable age of majority if greater than eighteen (18) years of age in your
        jurisdiction), and have the requisite power and authority to enter into the Agreement and perform your
        obligations hereunder, including payment of all charges and fees for Purchases.
      </Text>
      <Divider clear />
      <Text>
        <Text type="bold">3. Registration.</Text> During the registration process, you will create an account and
        establish a password. Your account is for your personal use only. You must provide complete and accurate
        information at all times, and maintain the secrecy of account credentials (i.e. username and password). You are
        responsible for all activities, including payment obligations that occur in connection with your account unless
        GrownBy and/or the Payment Processor agrees otherwise in their sole and absolute discretion. GrownBy reserves
        the right to disable your access to the GrownBy Platform for any or no reason, if, in our opinion, you have
        violated any provisions of this Agreement or the GrownBy Platform is being threatened.
      </Text>
      <Divider clear />
      <Text>
        <Text type="bold">4. Nutritional Information.</Text> Any nutritional or health related information we may
        provide on the GrownBy Platform regarding the Seller’s and/or any Purchase is for convenience purposes only and
        is not being intended to, nor does it constitute medical, health and/or legal advice in any form. GrownBY does
        not guarantee the accuracy of any nutritional or health information provided by GrownBy. GrownBy will not be
        responsible for any loss or damage resulting from your reliance on nutritional or health information, including
        but not limited to ensuring that whatever you Purchase or consume are in accordance with your respective dietary
        needs, restrictions or preferences. As such, you should always check the ingredients associated with any
        Purchase that you receive from Sellers to avoid potential allergic reactions. If you have or suspect that you
        have an allergic reaction or other adverse health event, promptly contact your health care provider. Please
        Note: Seller’s value-added food items may contain or may have been manufactured in a facility that also
        processes: dairy, eggs, fish, shellfish, soy, and tree nuts.
      </Text>
      <Divider clear />
      <Text>
        <Text type="bold">5. Purchases of Goods and/or Services.</Text> All Purchases facilitated through the GrownBy
        Platform are subject to this Agreement as well as subject to Payment Processor’s terms of service and Seller’s
        agreement for sale with You. If you sign up for an ongoing Purchase such as a community supported agriculture or
        other recurring Purchase (a “Plan”), all Plans are subscription plans for a specific period designated in your
        Purchase, and you will be charged the applicable price listed for the Plan that you select. Except as otherwise
        expressly noted below or agreed to directly by the Seller or Payment Processor, any amounts charged to or paid
        by you will not be refunded in any form by or through GrownBy.
      </Text>
      <Divider clear />
      <Text>
        Applicable sales tax may be charged on your Purchase based on local and state laws in accordance Payment
        Processor’s terms of service and Seller’s agreement for sale with You.
      </Text>
      <Divider clear />
      <Text>
        WHEN YOU REGISTER FOR A PURCHASE (AND EACH TIME YOU CHANGE AND/OR ADD A PURCHASE ) YOU EXPRESSLY AUTHORIZE AND
        AGREE THAT THIRD PARTY PAYMENT PROCESSOR IS AUTHORIZED TO AUTOMATICALLY CHARGE YOUR REGISTERED PAYMENT METHOD IN
        ACCORDANCE WITH YOUR PURCHASE ORDER, PAYMENT PROCESSOR’S TERMS OF SERVICE AND SELLER’S AGREEMENT FOR SALE WITH
        YOU.
      </Text>
      <Divider clear />
      <Text>
        <Text type="bold">6. Cancellation or Modification a Purchase.</Text> All terms related to cancellation or
        modification of a Purchase must be directly discussed and agreed to between you and the Sellers. GrownBy offers
        no refunds or cancellations through use of the GrownBy Platform. GrownBy will use commercially reasonable
        efforts to provide accurate contact information to you for any Seller you wish to contact regarding any
        cancellation or modification of a Purchase but GrownBy is not responsible for facilitation or resolution of any
        dispute. This clause is an essential part of this Agreement and survives any expiration or termination of this
        Agreement.
      </Text>
      <Divider clear />
      <Text>
        <Text type="bold">7. Delivery of Goods and/or Services.</Text> GrownBy is not a parcel delivery company or
        supply chain distributor for any of your Purchases. Completing a Purchase does not include pickup and/or
        delivery of your Purchase, including the costs to do so. All pickup and/or delivery of your Purchase is
        coordinated and/or provided by the Seller directly to you and you are responsible to coordinate and/or follow
        all Seller directions in that regard. For clarity, pickup and/or delivery of your Purchase may include you
        traveling directly to the Seller’s location to receive the Purchase. This clause is an essential part of this
        Agreement and survives any expiration or termination of this Agreement.
      </Text>
      <Divider clear />
      <Text>
        <Text type="bold">8. Purchase Fulfillment.</Text> Although the GrownBy Platform helps to connect Sellers and
        You, GrownBy takes no responsibility for successfully ensuring your Purchase is completed and/or fulfilled,
        including but not limited to delivery and/or pickup of your goods or services in a Purchase. This is due to the
        imprecise nature and availability of any goods and/or services offered for sale by the Sellers. All terms and
        obligations regarding fulfillment of a Purchase are between you and Seller, including but not limited to
        availability of goods and/or services Purchased. Seller may change and/or substitute goods or services without
        notice and reserves the right to substitute a similar good or service. Prior to consumption, please be sure to
        carefully check all substituted items for any food allergies issues.
      </Text>
      <Divider clear />
      <Text>
        <Text type="bold">9. GrownBy Service Access.</Text> You shall be responsible, at all times, for ensuring that
        you have an applicable mobile device and/or other equipment and service necessary to access the GrownBy Platform
        and complete a Purchase. Standard messaging, data and wireless access charges may apply. You are fully
        responsible for all such charges and GrownBy has no liability or responsibility to you, whatsoever, for any such
        charges billed by your wireless carrier and/or applicable service provider.
      </Text>
      <Divider clear />
      <Text>
        <Text type="bold">10. Applicable Law/Export Restrictions.</Text> The GrownBy Platform is subject to, and you
        agree that you shall at all times comply with, all local, state, national and international laws, statutes,
        rules, regulations, and ordinances applicable to use of the GrownBy Platform, including those related to online
        social media or other content.
      </Text>
      <Divider clear />
      <Text>
        <Text type="bold">11. GrownBy Intellectual Property and Ownership.</Text> The GrownBy Platform and all
        associated software, text, displays, images, video and audio, interfaces and the design, selection and
        arrangement thereof, including any and all intellectual property right inherent therein (collectively, the
        “GrownBY Materials”), are the sole and exclusive property of, or duly licensed to, GrownBy. You may display
        specific GrownBy Materials, subject to any expressly stated restrictions or limitations relating to specific
        material, or download portions of the materials from the different areas of the GrownBy Platform solely for your
        own non-commercial, internal use (e.g., in the case of electronic coupons, etc.). You may not utilize and/or
        exploit for your commercial gain in any form, the GrownBy Materials or GrownBy Platform. Any redistribution,
        retransmission or publication of any copyrighted material is strictly prohibited without the express written
        consent of the copyright owner. You agree not to change or delete any proprietary notices from GrownBy Materials
        downloaded. This Agreement permit(s) you access to the GrownBy Platform and GrownBy Materials for your personal
        use only. You must not reproduce, translate, distribute, modify, reverse engineer, create derivative works from,
        publicly display, publicly perform, republish, delete, download, store or transmit, any of the GrownBy Platform
        or GrownBy Materials, or allow any other entity or person the ability to do the same. No right, title or
        interest in or to the GrownBY Platform or GrownBy Materials is transferred to you, and all rights not expressly
        granted herein are further reserved by GrownBY.
      </Text>
      <Divider clear />
      <Text>
        <Text type="bold">12. Links to Third Party Sites and Content.</Text> To facilitate Purchase and Payment
        Processing, you will be leaving the GrownBy Platform to a third party Payment Processor platform and/or website.
        Any Purchase will be facilitated by the Payment Processor through their terms and conditions regarding their
        Payment Processing service, which you are required to agree to in order to complete a Purchase. If the GrownBy
        Platform contains links to other sites and resources provided by third parties, these links are provided for
        your convenience only. GrownBy has no control over the contents of those sites or resources, and accepts no
        responsibility for them or for any loss or damage that may arise from Your use of them. If you decide to access
        any of the third-party websites linked to through the GrownBy Platform, you do so entirely at your own risk and
        subject to the terms and conditions of use for such third party websites.
      </Text>
      <Divider clear />
      <Text>
        The GrownBy Services may include content provided by third parties, including materials provided by other users,
        bloggers and third-party licensors, syndicators, aggregators and/or reporting services. All statements and/or
        opinions expressed in these materials, and all articles and responses to questions and other content, other than
        the content provided by GrownBY, are solely the opinions and the responsibility of the person or entity
        providing those materials. These materials do not necessarily reflect the opinion of GrownBY. GrownBY is not
        responsible, or liable to You or any third party, for the content or accuracy of any materials provided by any
        third parties that are found on the GrownBy Platform.
      </Text>
      <Divider clear />
      <Text>
        <Text type="bold">13. Data Privacy.</Text> GrownBy respects your privacy at all times in providing the GrownBy
        Platform. All information, including any personnel identifiable information we collect through your use of the
        GrownBy Platform is subject to our Privacy Policy found at farmgenerations.coop. By using the GrownBy Platform,
        you acknowledge and agree to such Privacy Policy as well as expressly consent the limited processing of any such
        information in accordance with our Privacy Policy in furtherance of providing the GrownBy Platform, where such
        processing may be located in the US. Once you leave the GrownBy Platform to third party websites or platforms,
        including Seller’s and Payment Processor’s, GrownBy is no longer collecting, accessing and/or processing any
        personal identifiable information is no longer subject to our Privacy Policy. You will be subject to such
        third-party privacy terms, if any, and GrownBy is not liable or responsible for any processing or security of
        your information once you leave the GrownBy Platform. This clause is an essential part of this Agreement and
        survives any expiration or termination of this Agreement.
      </Text>
      <Divider clear />
      <Text>
        <Text type="bold">14. Warranties.</Text> THE GROWNBY PLATFORM AND/OR ANY OTHER CONTENT, INFORMATION, PRODUCTS
        AND/OR SERVICES OFFERED BY AND THROUGH SAME ARE PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS AND ALL
        WARRANTIES, EXPRESS AND IMPLIED STATUTORY OR OTHERWISE, ARE DISCLAIMED TO THE FULLEST EXTENT PERMISSIBLE
        PURSUANT TO APPLICABLE LAW (INCLUDING, BUT NOT LIMITED TO, THE DISCLAIMER OF ANY WARRANTIES OF MERCHANTABILITY,
        NON-INFRINGEMENT OF THIRD PARTIES’ RIGHTS, ACCESS, DATA LOSS, DATA PRIVACY, AND/OR FITNESS FOR A PARTICULAR
        PURPOSE).
      </Text>
      <Divider clear />
      <Text>
        <Text type="bold">15. Damages.</Text> YOU EXPRESSLY UNDERSTAND AND AGREE THAT NEITHER GROWNBY NOR ANY OF ITS
        DIRECTORS, OFFICERS, EMPLOYEES, SHAREHOLDERS, AFFILIATES, AGENTS, REPRESENTATIVES, THIRD-PARTY INFORMATION
        PROVIDERS, MERCHANTS, OR LICENSORS (COLLECTIVELY,"GROWNBY PARTIES") SHALL BE LIABLE TO YOU OR ANY THIRD PARTY
        FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL AND/OR EXEMPLARY DAMAGES INCLUDING BUT NOT LIMITED TO
        DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES, (EVEN IF YOU OR THE THIRD PARTY
        APPLICABLE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), TO THE FULLEST EXTENT PERMISSIBLE BY LAW. THE
        NEGATION OF DAMAGES SET FORTH ABOVE IS A FUNDAMENTAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN YOU AND
        GROWNBY.
      </Text>
      <Divider clear />
      <Text>
        <Text type="bold">16. Limitation of Liability.</Text> AT NO TIME SHALL THE CUMULATIVE LIABILITY OF GROWNBY
        PARTIES FOR ALL CLAIMS ARISING OUT OF OR RELATED TO THIS AGREEMENT IN ANY WAY, UNDER ANY THEORY OF LIABILITY,
        EXCEED THE LESSER OF EITHER: (1) THE TOTAL AMOUNT OF ALL FEES PAID BY YOU TO SELLERS THROUGH USE THE GROWNBY
        PLATFORM DURING THE TWELVE-MONTH PERIOD IMMEDIATELY PRECEDING THE ACCRUAL OF THE MOST RECENT CLAIM; OR (2) ONE
        THOUSAND US DOLLARS ($1,000). THE LIMITATION OF LIABILITY SET FORTH ABOVE IS A FUNDAMENTAL ELEMENT OF THE BASIS
        OF THE BARGAIN BETWEEN YOU AND GROWNBY.
      </Text>
      <Divider clear />
      <Text>
        <Text type="bold">17. Indemnification.</Text> You agree to indemnify, defend, and hold GrownBy, its officers,
        directors, employees, shareholders, affiliates agents, licensors, and suppliers, harmless from and against any
        claims, actions or demands, liabilities and settlements including without limitation, reasonable legal and
        accounting fees, resulting from, or alleged to result from your breach of the terms of this Agreement.
      </Text>
      <Divider clear />
      <Text>
        <Text type="bold">18. Governing Law.</Text> This Agreement shall be governed by and construed in accordance with
        the laws of the State of New York, without regard to its conflict of laws rules and the parties exclusively
        agree to venue in State of New York courts for any such dispute.
      </Text>
      <Divider clear />
      <Text>
        <Text type="bold">19. Dispute Resolution.</Text> All disputes regarding Purchase and/or facilitation of Purchase
        with a Seller is a transaction directly between you and Seller. Further, all disputes you may have with the
        Payment Processor regarding Payment Processing is a transaction directly between you and Payment Processor
        according to their terms of service. You are responsible for facilitation and administration of any such
        disputes independently. GrownBy may in its sole and absolute discretion, but in no way is responsible or
        obligated to, provide information and/or assist with a dispute between the Seller and you, or the Payment
        Processor and you. For any dispute directly with GrownBy, you may submit such dispute to GrownBy through mail to
        the address listed below or through email: info@farmgenerations.coop, and GrownBy agrees to use commercially
        reasonable efforts to resolve such dispute in good faith. Otherwise, the parties agree to finally resolve all
        disputes in accordance with Section 18 above.
      </Text>
      <Divider clear />
      <Text>
        <Text type="bold">20. Waiver and Severability.</Text> No waiver by GrownBY of any of the terms and conditions
        set forth in this Agreement shall be deemed a further or continuing waiver of such term or condition or a waiver
        of any other term or condition, and any failure of GrownBY to assert a right or provision under these Terms and
        Conditions shall not constitute a waiver of such right or provision. If any provision of this Agreement is held
        by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason,
        such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of this
        Agreement will continue in full force and effect.
      </Text>
      <Divider clear />
      <Text>
        <Text type="bold">21. Entire Agreement.</Text> This Agreement and our Privacy Policy constitute the sole and
        entire agreement between you and GrownBy with respect to the GrownByPlatform and the contents of this Agreement
        and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both
        written and oral, with respect thereto.
      </Text>
      <Divider clear />
      <Text>
        <Text type="bold">22. Notice.</Text> GrownBy may deliver notice to you under this Agreement by means of
        electronic mail, a general notice on the site, or by written communication delivered by first class U.S. mail to
        your address on record in your GrownBy account. You may give notice to GrownBy at any time via electronic mail
        or by letter delivered by first class postage prepaid U.S. mail or overnight courier to the following address:
        Farm Generations Cooperative, 1830 Route 9 Germantown, NY 12526 Attn: Legal Department.
      </Text>
      <Divider bottom={bottom} clear />
    </ScrollView>
  )
}
