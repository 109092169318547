import { ToolTips } from './ToolTips'
import { InfoTooltip } from './InfoTooltip'
import { PropsWithChildren } from 'react'
import { IconProps } from '@expo/vector-icons/build/createIconSet'
import { TooltipBase } from './TooltipBase'
import { globalStyles } from '../../../constants/Styles'
import { Text } from 'react-native'

/** Props that should be defined for the tooltip base component.*/
export type TooltipBaseProps = PropsWithChildren<Omit<IconProps<string>, 'color' | 'name'>>

type Props = TooltipBaseProps & {
  /** Title is not used right now, but can remain in case we want to add it back. If we decide not to we should deprecate it. */
  title?: string
  id: ToolTips
}

/** The tooltip component renders a ? icon and allows clicking or hovering to open the tooltip */
export function Tooltip({ id, size = 10, ...iconProps }: Props) {
  return (
    <TooltipBase size={size} {...iconProps}>
      <InfoTooltip id={id} />
    </TooltipBase>
  )
}

type DynamicTooltipType = Omit<Props, 'id'> & { content: string }

/** The tooltip component renders a ? icon and allows clicking or hovering to open the tooltip */
export function DynamicTooltip({ size = 10, content, ...iconProps }: DynamicTooltipType) {
  return (
    <TooltipBase size={size} {...iconProps}>
      <Text style={globalStyles.marginHorizontal10}>{content}</Text>
    </TooltipBase>
  )
}
