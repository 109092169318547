import Colors from '@/constants/Colors'
import { StyleSheet, View, ViewProps } from 'react-native'

type Props = ViewProps & {
  noMargins?: boolean
}

/** Container used to wrap component with predefined styles in order to have consistent styling
 * - Should be placed at the screen root level
 */
export function AdminCard({ style: styleProp, noMargins, ...rest }: Props) {
  return <View style={[styles.card, noMargins && styles.noMargins, styleProp]} {...rest} />
}

const styles = StyleSheet.create({
  card: {
    borderRadius: 10,
    padding: 20,
    borderColor: Colors.shades['100'],
    borderWidth: 1,
    marginTop: 20,
    width: '95%',
    alignSelf: 'center',
  },
  noMargins: {
    marginTop: 0,
    width: '100%',
    alignSelf: 'stretch',
  },
})
