import Colors from '@/constants/Colors'
import { Text } from '@elements'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { Slider as RNESlider, SliderProps } from 'react-native-elements'

type Props = {
  label?: string
  maximumValue: number
  contStyle?: StyleProp<ViewStyle>
} & Omit<SliderProps, 'maximumValue' | 'onValueChange'>

/** Component that allows slide selection UI */
export function Slider({ label, thumbStyle, contStyle, minimumValue = 0, ...props }: Props) {
  return (
    <View testID="Slider" style={contStyle}>
      {!!label && <Text>{label}</Text>}
      <RNESlider
        animateTransitions
        allowTouchTrack
        thumbTintColor={Colors.green}
        thumbStyle={[styles.thumb, thumbStyle]}
        minimumValue={minimumValue}
        {...props}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  thumb: {
    height: 20,
    width: 20,
  },
})
