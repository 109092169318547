import { Text } from '@elements'
import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { Pickup, isPickupItemCancelled } from '@models/Order'
import { memo, useCallback, useMemo } from 'react'

import { PickupSectionRow } from './components/PickupSectionRow'

import { AdminCard } from '@/admin/components/AdminCard'
import { OfflineTable, OfflineTableProps } from '@/admin/components/OfflineTable/OfflineTable'
import Colors from '@/constants/Colors'
import { useDeepCompareMemo } from '@/hooks/useDeepEqualEffect'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import useKeyedState from '@/hooks/useKeyedState'
import { useDeviceSize } from '@/hooks/useLayout'
import { adminFarmSelector } from '@/redux/selectors'
import { MessageWithIcon } from '@components'
import { sortByLatest } from '@helpers/sorting'
import { isAfter } from '@helpers/time'
import { dateTimeInZone } from '@models/Timezone'
import { ListItem } from 'react-native-elements'
import { useSelector } from 'react-redux'
import { AdminCustomerHeader } from '../AdminCustomerOrdersSection/components/Header'

type Props = {
  /** All pickups for user and farm */
  pickups: Pickup[]
  /** Reloads orders and pickups. Should call after a reschedule or claim vacation */
  reload: () => void
}

/** limit is used to limit the number of pickups displayed or increased  at a time */
const limit = 10

/**
 * PickupsQtyCounts is used to store the quantity of currently displayed past and future pickups.
 * @props past: The quantity of past pickups currently displayed.
 * @props future: The quantity of future pickups currently displayed.
 */
type PickupsQtyCounts = { past: number; future: number }

/** The Invoices Section Component inside AdminCustomerDetail Screen */
export const AdminCustomerPickupsSection = memo(function AdminCustomerPickupsSection({ pickups, reload }: Props) {
  const { isExtraSmallDevice } = useDeviceSize()
  const farm = useSelector(adminFarmSelector)
  const now = useMemo(() => dateTimeInZone(farm.timezone), [farm.timezone])
  const [{ pickupsQtyCounts, isLoading, openSection }, set, setAll] = useKeyedState<{
    pickupsQtyCounts: PickupsQtyCounts
    isLoading: boolean
    openSection: boolean
  }>({ pickupsQtyCounts: { past: 0, future: 0 }, isLoading: false, openSection: false })

  const styles = useStyles()

  const onPresslistItemAccordion = useCallback(() => {
    setAll({ pickupsQtyCounts: { past: 0, future: 0 }, isLoading: false, openSection: !openSection })
  }, [openSection, setAll])

  /** The helper is used to load more past pickups */
  const handleLoadMore = useCallback(() => {
    setAll({
      pickupsQtyCounts: { ...pickupsQtyCounts, past: pickupsQtyCounts.past + limit },
      isLoading: true,
      openSection,
    })
  }, [openSection, pickupsQtyCounts, setAll])

  /** The helper is used to load more future pickups */
  const handleLoadFuture = useCallback(() => {
    setAll({
      pickupsQtyCounts: { ...pickupsQtyCounts, future: pickupsQtyCounts.future + limit },
      isLoading: true,
      openSection,
    })
  }, [openSection, pickupsQtyCounts, setAll])

  /** RenderItem for offlineTable */
  const renderItem = useCallback<NonNullable<OfflineTableProps<Pickup>['renderItem']>>(
    ({ item: pickup, index }) => {
      return <PickupSectionRow key={`${pickup.id}_${index}`} pickup={pickup} index={index} reload={reload} />
    },
    [reload],
  )

  /**
   * activePickups is pickups that do have at least one item not canceled.
   * displayPickups is used to display pickups in the table.
   * showFutureBtn is used to show the "Show Future" button.
   * showMoreBtn is used to show the "Show More" button.
   */
  const { activePickups, displayPickups, showFutureBtn, showMoreBtn } = useDeepCompareMemo(() => {
    /**
     * Filter out pickups with all items canceled, treating "vacation" status separately
     * PickupItemStatus.Vacation means they can still un-cancel so we should show them in the list
     * */
    const activePickups = pickups
      .filter((pickup) => !pickup.items.every((item) => isPickupItemCancelled(item)))
      .sort(sortByLatest('date'))

    // Calculate the total number of indexes in activePickups (indexes should be from 0 to array.length - 1)
    const totalIndexes = activePickups.length - 1

    // get pickups sorted by earliest from [...activePickups].reverse()
    const pickupsSortByEarliest = [...activePickups].reverse()
    // Find the index of the first pickup after or equal to the current date
    const indexOfNextOrEqualPickup = pickupsSortByEarliest.findIndex((pickup) =>
      isAfter(pickup.date, now, { orEqual: true, zone: farm.timezone, granularity: 'day' }),
    )

    // Calculate the original index for activePickups
    const originalIndex = indexOfNextOrEqualPickup >= 0 ? totalIndexes - indexOfNextOrEqualPickup : -1

    // Calculate start index for displayedPickups
    const startIndexForDisplay = originalIndex - pickupsQtyCounts.future
    // Calculate end index for displayedPickups
    const endIndexForDisplay = originalIndex + limit + pickupsQtyCounts.past

    // Determine whether to show the "Show Future" button and "Show More" button
    const showFutureBtn = startIndexForDisplay > 0
    const showMoreBtn = endIndexForDisplay < totalIndexes

    // Initialize the displayedPickups array
    let displayPickups: Pickup[] = []

    if (originalIndex === -1) {
      // No future pickups found, show past pickups from the beginning
      displayPickups = activePickups.slice(0, 0 + limit + pickupsQtyCounts.past)
    } else {
      // Show pickups between start and end indexes for displayedPickups
      const adjustedStartIndex = Math.max(0, startIndexForDisplay)
      const adjustedEndIndex = Math.min(endIndexForDisplay, totalIndexes + 1) // +1 to include the last index
      displayPickups = activePickups.slice(adjustedStartIndex, adjustedEndIndex)
    }

    // Indicate that loading has completed
    set('isLoading', false)

    return { activePickups, displayPickups, showFutureBtn, showMoreBtn }
  }, [pickups, pickupsQtyCounts.future, pickupsQtyCounts.past, set, now, farm.timezone])

  return (
    <AdminCard style={styles.adminCardContainer}>
      <ListItem.Accordion
        isExpanded={openSection}
        onPress={onPresslistItemAccordion}
        containerStyle={styles.listItemContainer}
        pad={isExtraSmallDevice ? 0 : 10}
        content={
          <AdminCustomerHeader
            title={`Pickups & Deliveries (${activePickups.length})`}
            containerStyle={styles.headerContainer}
          />
        }
      >
        {showFutureBtn && (
          <Text style={styles.showMore} type="bold" size={16} color={Colors.green} onPress={handleLoadFuture}>
            Show Future
          </Text>
        )}
        <OfflineTable<Pickup>
          minWidth={!activePickups.length ? undefined : 1180}
          data={displayPickups}
          containerStyle={styles.offlineTableContainer}
          headerProps={{
            containerStyle: { backgroundColor: Colors.transparent, borderBottomWidth: 0 },
            textProps: { type: 'bold' },
          }}
          headerColumns={[
            { title: 'Date' },
            { title: 'Type' },
            { title: 'Location', widthFlex: 2 },
            { title: 'Order #', widthFlex: 0.8 },
            { title: 'Items and (QTY)', widthFlex: 3 },
            { title: 'Total Items' },
            { widthFlex: 1.5 },
            { title: 'Action' },
          ]}
          renderItem={renderItem}
          isLoading={isLoading}
          ListEmptyComponent={
            <MessageWithIcon icon="box" title="No Pickups & deliveries">
              <Text>This customer does not have any pickups & deliveries.</Text>
            </MessageWithIcon>
          }
        />
        {showMoreBtn && (
          <Text style={styles.showMore} type="bold" size={16} color={Colors.green} onPress={handleLoadMore}>
            Show More
          </Text>
        )}
      </ListItem.Accordion>
    </AdminCard>
  )
}, propsAreDeepEqual)

const useStyles = () =>
  useSizeFnStyles(({ isExtraSmallDevice, isSmallDevice }) => ({
    offlineTableContainer: {
      borderWidth: 0,
      backgroundColor: Colors.transparent,
      marginTop: 10,
    },
    showMore: {
      alignSelf: 'center',
      marginTop: 10,
    },
    adminCardContainer: {
      paddingVertical: isSmallDevice ? 10 : 20,
      paddingHorizontal: isExtraSmallDevice ? 10 : 20,
      marginTop: isSmallDevice ? 10 : 20,
    },
    headerContainer: {
      flex: 1,
      marginBottom: 0,
    },
    listItemContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 0,
    },
  }))
