import Colors from '@/constants/Colors'
import { ToolTips } from '@components'
import { Spinner, Text, TextH2, Tooltip } from '@elements'
import { StyleSheet, View } from 'react-native'

export type TimeWindow = 'Last 7 Days' | 'Now'
interface Props {
  /** The data to display for the badge */
  data?: number
  loading: boolean
  title: string
  /** tooltipId  of event to be monitored */
  tooltipId: ToolTips
  /** time window of data - 'Last 7 Days' or 'Now' */
  timeWindow: TimeWindow
}

export function MonitorBadge({ data, loading, title, tooltipId, timeWindow }: Props) {
  return (
    <View style={styles.box}>
      <View style={styles.headerTextContainer}>
        <TextH2 size={40} type="medium">
          {!loading ? !data ? 0 : data : <Spinner size="large" />}
        </TextH2>
        <Text size={8}>{timeWindow}</Text>
      </View>
      <View style={styles.bottomTextContainer}>
        <Text size={10} style={styles.tooltipTitleText}>
          {title}
        </Text>
        <Tooltip id={tooltipId} title={title} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  box: {
    flex: 1,
    marginHorizontal: 10,
    padding: 20,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 20,
    backgroundColor: Colors.lightGray,
    height: '100%',
    maxWidth: 200,
    maxHeight: 160,
    minWidth: 158,
    marginVertical: 10,
  },
  headerTextContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: 10,
  },
  bottomTextContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    maxWidth: '100%',
    color: Colors.black,
    textAlign: 'center',
  },
  tooltipTitleText: {
    textAlign: 'center',
    maxWidth: '90%',
    marginRight: 5,
  },
})
