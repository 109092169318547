import { colorBank } from '../Colors'
import { alphabet } from './display'

/** Gets a default schedule color that will always be the same for the schedule id.
 * - Will return undefined if the id is empty string
 */
export function getScheduleColorFromId(distroId: string, colorsArr = colorBank) {
  if (!distroId) return undefined

  const bankIndexFromId =
    distroId
      .split('')
      .map((char) => {
        const ix = alphabet.findIndex((v) => v === char)
        return ix + 1
      })
      .reduce((prev, curr) => prev + curr, 0) % colorsArr.length
  return colorsArr[bankIndexFromId]
}
