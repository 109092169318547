import { Coupon, Discount, isFixedCoupon, isPercentCoupon } from '@models/Coupon'
import { Invoice, InvoiceItem, InvoiceItemTypes, PaymentSources, isFeeItem, isAdjustmentItem } from '@models/Invoice'
import { Money } from '@models/Money'
import { OrderItem } from '@models/Order'

import { MoneyCalc } from './money'
import { ArrElement } from './typescript'

/** Determines if the given coupon should be applied to the product */
export function shouldCouponApplyToProduct(coupon: Coupon, product: InvoiceItem['product']) {
  // If no product is passed in InvoiceItem, then we should not apply the coupon
  if (!product) return false

  const noCategoryRequirement = !coupon.categories || !coupon.categories.length
  const noProducerRequirement = !coupon.producers || !coupon.producers.length

  const categoryRequirement = coupon.categories && coupon.categories.includes(product.category)
  const producerRequirement = coupon.producers && product.producer && coupon.producers.includes(product.producer)

  // Either the coupon has no category or producer requirement, or the product meets the requirement, then the coupon should apply
  return (noCategoryRequirement && noProducerRequirement) || categoryRequirement || producerRequirement
}

type DiscountOptions = {
  // The id of the invoice item
  itemId: OrderItem['id'] | InvoiceItemTypes
  // If the item payment we are requesting should be inclusive or exclusive of discount
  // If it is true then it means that we are passing the total amount that can be paid to a payment method so the discount
  // can be in addition to this amount. If it is false then it means that the discount is included, and we should not
  // allow the total to go above the amount
  excludingDiscount?: boolean
  // The coupon to attempt to apply to the invoice item
  coupon?: Coupon

  //The product the invoice item is for
  product?: InvoiceItem['product']
}

/** Determines if a fixed coupon should be added to an invoice item. */
export function shouldAddFixedCoupon(coupon: Coupon, invoiceItem: Pick<InvoiceItem, 'id' | 'product'>) {
  // If the item is a fee or adjustment, we should not allow discounts to cover that amount
  if (isFeeItem(invoiceItem.id) || isAdjustmentItem(invoiceItem.id)) return false

  // Check if the coupon should be applied to the product (if applicable)
  if (invoiceItem.product && !shouldCouponApplyToProduct(coupon, invoiceItem.product)) return false

  // All conditions satisfied, allow adding the fixed coupon to the invoice item
  return true
}

/**
 * Will take an invoice payment and return that payment with any discounts applied that satisfy the requirements.
 * @param amount The original payment amount before discounts
 * @param source The payment source of the item that the percentage discount is being applied to
 * @param opts Any additional options to restrict the discount more
 */
export function makeDiscountedItemPayment(
  amount: Money,
  source: PaymentSources,
  opts: DiscountOptions,
): ArrElement<InvoiceItem['payments']> {
  // If we do not have a coupon, or it is a fixed coupon we should not add it here
  if (!opts.coupon || !isPercentCoupon(opts.coupon)) return { amount, source }

  // Do not apply coupon to invoice tips and fees or delivery fees or adjustments
  if (isFeeItem(opts.itemId) || isAdjustmentItem(opts.itemId)) return { amount, source }

  // If the coupon is ebtOnly then do not allow it to be used with payments that are not EBT
  if (opts.coupon.ebtOnly && source !== PaymentSources.WORLD_PAY_EBT) return { amount, source }

  // If we pass in a product we should make sure the coupon works with it
  if (opts.product && !shouldCouponApplyToProduct(opts.coupon, opts.product)) return { amount, source }

  // When we are excluding the discount we should calculate the amount inclusive of any applied discounts. An example is:
  // we have $10 of EBT to use and a 20% coupon, this means we should do 10/(1-0.2)=$12.5 now when we multiply this by 20%
  // we get the discount to be $2.50 and the amount to pay with EBT to be $10
  const newAmount = opts.excludingDiscount ? MoneyCalc.divide(amount, 1 - opts.coupon.value) : amount

  const discount = MoneyCalc.multiply(newAmount, opts.coupon.value)
  return {
    amount: MoneyCalc.subtract(newAmount, discount),
    discount,
    source,
  }
}

/** will add a coupon to a list of invoices and return the updated invoices */
export function addCouponToInvoices<Inv extends Partial<Invoice>>(invoices: Inv[], discount: Discount): Inv[] {
  return invoices.map((inv, idx) => {
    // Add any coupons as references on the invoice
    if (discount?.coupon) {
      // Fixed coupons should only be added to the first invoice
      if (isFixedCoupon(discount.coupon) && idx === 0) {
        return {
          ...inv,
          couponApplied: discount,
        }
        // Percent coupons will be added to all invoices for an order
      } else if (isPercentCoupon(discount.coupon)) {
        return {
          ...inv,
          couponApplied: discount,
        }
      }
    }
    return inv
  })
}
