import { Alert, Divider, LoadingView } from '@elements'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleSheet } from 'react-native'

import { BackTo } from '../../components/BackTo'
import { goBack, goBackString } from './helpers/AdminOrderDetails.helper'

import { AdminView } from '@/admin/components/AdminView'
import { AdminOrdersParamList } from '@/admin/navigation/types'
import { DraftOrderItems, DraftOrderSummary } from '@/components'

import { Logger } from '@/config/logger'
import { useApiFx } from '@/hooks/useApiFx'
import { approveDraftOrder, declineDraftOrder, finalizeDraftOrder } from '@api/DraftOrders'
import { cartsCollection, draftOrdersCollection } from '@api/framework/ClientCollections'
import { Permission } from '@helpers/Permission'
import { errorToString, nonEmptyString } from '@helpers/helpers'
import { DraftOrderState } from '@models/DraftOrder'
import { useCallback, useState } from 'react'
import { DraftOrderDeclinedCard } from '../../../components/DraftOrder/DraftOrderDeclinedCard'
import { withAdminAuth } from '../../../hooks/withAdminAuth'
import { DraftOrderHeader } from './components/DraftOrderHeader'

function AdminDraftOrderDetails() {
  const {
    params: { draftOrderId },
  } = useRoute<RouteProp<AdminOrdersParamList, 'AdminDraftOrderDetails'>>()
  const navigation = useNavigation<StackNavigationProp<AdminOrdersParamList>>()
  const [loading, setLoading] = useState(false)
  const {
    data: draftOrder,
    loading: loadingDraftOrder,
    err: errorDraftOrder,
    refresh: refreshDraftOrder,
  } = useApiFx(draftOrdersCollection.fetch.bind(draftOrdersCollection), [draftOrderId], nonEmptyString(draftOrderId))
  const {
    data: draftCart,
    loading: loadingDraftCart,
    err: errorDraftCart,
  } = useApiFx(cartsCollection.fetch.bind(cartsCollection), [draftOrder?.cart.id], nonEmptyString(draftOrder?.cart.id))

  const onPressDeclineOrder = useCallback(async () => {
    if (!draftOrder) return

    setLoading(true)
    try {
      await declineDraftOrder(draftOrder.id)
      refreshDraftOrder()
    } catch (err) {
      Logger.error(err)
      Alert(
        'Unable to decline pending order',
        `Something went wrong while attempting to decline this draft order. (${errorToString(err)})`,
      )
    }
    setLoading(false)
  }, [draftOrder, refreshDraftOrder])

  const onPressEditOrder = useCallback(() => {
    if (!draftOrder) return

    navigation.navigate('OrderEdit', { draftOrderId: draftOrder.id })
  }, [draftOrder, navigation])

  const onPressApproveOrder = useCallback(async () => {
    if (!draftOrder) return

    try {
      setLoading(true)
      await approveDraftOrder(draftOrder.id)
      refreshDraftOrder()
    } catch (err) {
      Logger.error(err)
      Alert(
        'Unable to approve pending order',
        `Something went wrong while attempting to approve this draft order. (${errorToString(err)})`,
      )
    } finally {
      setLoading(false)
    }
  }, [draftOrder, refreshDraftOrder, setLoading])

  const onPressFinalizeOrder = useCallback(async () => {
    if (!draftOrder) return

    try {
      setLoading(true)
      const order = await finalizeDraftOrder(draftOrder.id)
      navigation.navigate('AdminOrderDetails', { orderId: order.id })
    } catch (err) {
      Logger.error(err)
      Alert(
        'Unable to fulfill pending order',
        `Something went wrong while attempting to fulfill this draft order. (${errorToString(err)})`,
      )
      setLoading(false)
    }
  }, [draftOrder, navigation, setLoading])

  return (
    <AdminView>
      <BackTo
        style={styles.divWrapper}
        title={goBackString(navigation, draftOrder?.user?.id)}
        onPress={() => goBack(navigation, draftOrder?.user?.id)}
      />
      <LoadingView
        style={styles.divWrapper}
        loading={loadingDraftOrder || loadingDraftCart || loading}
        error={errorDraftOrder || errorDraftCart}
        spinnerProps={{ size: 'large' }}
        success={draftOrder && draftCart ? { draftOrder, draftCart } : undefined}
      >
        {({ draftOrder, draftCart }) => (
          <>
            {draftOrder.state === DraftOrderState.Declined && <DraftOrderDeclinedCard draftOrder={draftOrder} />}
            <DraftOrderHeader
              draftOrder={draftOrder}
              onPressDeclineOrder={onPressDeclineOrder}
              onPressEditOrder={onPressEditOrder}
              onPressApproveOrder={onPressApproveOrder}
              onPressFinalizeOrder={onPressFinalizeOrder}
            />
            <DraftOrderSummary draftOrder={draftOrder} draftCart={draftCart} isAdmin />
            <Divider large top={10} bottom={10} clear />
            <DraftOrderItems draftCart={draftCart} />
          </>
        )}
      </LoadingView>
    </AdminView>
  )
}

export const AdminDraftOrderDetailsScreen = withAdminAuth(AdminDraftOrderDetails, Permission.Orders)

const styles = StyleSheet.create({
  divWrapper: {
    margin: 25,
    marginRight: 20,
  },
})
