import { dequal } from '@helpers/customDequal'
import { pick } from './typescript'

/** Compares 2 objects, considering only the properties of the first object.
 * - This is useful when an object extends another, and you only want to compare the values of the first set of properties, and ignore the extra properties of the second one.
 * @params keys if keys are provided, only these keys will be compared between the 2 objects. By default, only the keys of the first object are checked.
 */
export function isPartialEqual<T extends Record<string, any>>(obj1: T, obj2: T, keys: (keyof T)[] = Object.keys(obj1)) {
  const obj1Data = pick(obj1, ...keys)
  const obj2Data = pick(obj2, ...keys)
  return dequal(obj1Data, obj2Data)
}
