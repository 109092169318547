import { Dimensions, Text, View } from 'react-native'

import Colors from '../../../constants/Colors'

function NoData() {
  return (
    <View
      style={{
        height: Dimensions.get('window').height / 2.5,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Text
        style={{
          textAlign: 'center',
          color: Colors.red,
          fontSize: 18,
        }}
      >
        No Data Available
      </Text>
    </View>
  )
}

export default NoData
