import { isNonNullish, removeDuplicates, removeObjDuplicates } from '@helpers/helpers'
import { getLocationKind } from '@helpers/location'
import { Distribution } from '@models/Distribution'
import { LocationTypes } from '@models/Location'
import { useEffect, useMemo, useState } from 'react'

export type UseCompatibleLocationsOpts = {
  schedules?: Distribution[]
  locationType?: LocationTypes
}

/** Returns the locations of the provided schedules, filtered by the location type provided. Also provides the regions for the filtered locations */
export function useFilteredLocations({ schedules, locationType }: UseCompatibleLocationsOpts) {
  const [locs, setLocs] = useState<Distribution['location'][]>()

  useEffect(() => {
    if (!schedules) return
    const newLocs = removeObjDuplicates(
      schedules
        .map((sch) => sch.location)
        .filter((loc) => {
          /** If locationType is provided, filter out if locationType isn't of the same kind */
          if (locationType && getLocationKind(locationType) !== getLocationKind(loc)) {
            return false
          }
          return true
        }),
    )

    setLocs(newLocs)
  }, [locationType, schedules])

  /** When the locationType is nonPickup, this should get the regions of the unique locations from each location group, filtered by the locationType. This represents the regions where the product is available for the given location type */
  const regions = useMemo(() => {
    if (!locs) return undefined
    return removeDuplicates(locs.flatMap((loc) => loc.regions).filter(isNonNullish) ?? [])
  }, [locs])

  return { locs, regions }
}
