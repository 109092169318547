import { geoLocateIPAddr, getParsedAddressFromCoords, loadExactCoords } from '@api/Addresses'
import { validCoords } from '@helpers/coordinate'
import { Coordinate } from '@models/Coordinate'
import { useDispatch, useSelector } from 'react-redux'

import { errorCatcher } from '@api/Errors'
import { extendErr, nonEmptyString, settleUnwrap } from '@helpers/helpers'
import { ErrorCode, isErrorWithCode } from '@shared/Errors'
import { Logger } from '../config/logger'
import { CurrentLocation } from '../constants/types'
import { setCurrLocation } from '../redux/actions/appPersist'
import { userLocationSelector } from '../redux/selectors'
import { useFocusFx } from './useFocusFx'

/** Returns the current location by either IP or exact device location as best as possible
 * - This should NOT return a default location as a fallback. If a fallback location is desired, it could be obtained outside of this function when the return value is undefined
 */
async function getCurrentLocation(
  cachedLoc?: CurrentLocation | null,
): Promise<{ coords: Coordinate; city?: string } | undefined> {
  // Only run if we haven't checked in the last hour, OR if there's no cached location yet
  if (
    !cachedLoc || // If it's not set
    cachedLoc.timestamp < Date.now() - 60 * 60 * 1000 || // If we haven't checked in the last hour
    !validCoords(cachedLoc.coordinate) ||
    !cachedLoc.city
  ) {
    /** Design considerations:
     * - The IP location call is expected to fail often. Therefore it's not a good idea to bundle the two api calls into a single Promise.all() because that would force both to fail together.
     */
    const [ipLoc, exactCoords] = await settleUnwrap([
      geoLocateIPAddr().catch((err) => {
        Logger.error(
          isErrorWithCode(err, ErrorCode.AbortedError)
            ? err
            : extendErr(err, 'Something went wrong while trying to get IP location: '),
        )
        return undefined
      }),
      loadExactCoords().catch((err) => {
        Logger.error(extendErr(err, 'Something went wrong while trying to get exact coordinates: '))
        return undefined
      }),
    ])

    if (!ipLoc && !exactCoords) {
      // If no data could be obtained from the IP or the exact location, return undefined
      return undefined
    }

    // prioritize exact coords over ip coords
    const coords = exactCoords ?? ipLoc?.coordinate
    if (!coords) return undefined

    let city = ipLoc?.city
    // Load the city from geocoding if IP did not detect the city
    if (validCoords(coords) && !city) {
      try {
        const parser = await getParsedAddressFromCoords(coords)
        // This parsed address does not need to be fully valid because for this purpose we only need the city, and optionally the state
        const addr = parser.result()
        if (nonEmptyString(addr.city)) city = `${addr.city}${addr.state ? `, ${addr.state}` : ''}`
      } catch (err) {
        Logger.error(extendErr(err, 'Error getting city from reverse geocoder.'))
      }
    }

    return { coords, city }
  }
}

/** When used inside a component, the screen will request device location and set it to redux */
export function useLocation() {
  const dispatch = useDispatch()
  const cachedLoc = useSelector(userLocationSelector)

  useFocusFx(
    () => {
      getCurrentLocation(cachedLoc)
        .then((data) => dispatch(setCurrLocation(data ? { city: data.city, coordinate: data.coords } : null)))
        .catch(errorCatcher)
    },
    // This function updates cached location, so we do not want the effect to run in a loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
    { noRefocus: true },
  )
}
