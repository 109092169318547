import { collectionMap } from '../encoding/CollectionMap'
import { ChildCollection, ClientCollection } from './collections'

export const usersCollection = new ClientCollection(...collectionMap.users)
export const farmsCollection = new ClientCollection(...collectionMap.farms)
export const externalLinksCollection = new ClientCollection(...collectionMap.external_links)
export const pickupsCollection = new ClientCollection(...collectionMap.pickups)
export const signinSummariesCollection = new ClientCollection(...collectionMap.signin_summaries)
export const reviewsCollection = new ClientCollection(...collectionMap.reviews)
export const notificationsCollection = new ClientCollection(...collectionMap.notifications)
export const locationsCollection = new ClientCollection(...collectionMap.locations)
export const invoicesCollection = new ClientCollection(...collectionMap.invoices)
export const ordersCollection = new ClientCollection(...collectionMap.orders)
export const csasCollection = new ClientCollection(...collectionMap.csa)
export const productsCollection = new ClientCollection(...collectionMap.products)
export const distrosCollection = new ClientCollection(...collectionMap.distributions)
export const eventsCollection = new ClientCollection(...collectionMap.events)
export const feedbackCollection = new ClientCollection(...collectionMap.feedback)
export const templateProductsCollection = new ClientCollection(...collectionMap.template_products)
export const cartsCollection = new ClientCollection(...collectionMap.carts)
export const draftOrdersCollection = new ClientCollection(...collectionMap.draft_orders)
export const chatSessionsCollection = new ClientCollection(...collectionMap.chat_sessions)

export const chatMessagesCollection = new ChildCollection(chatSessionsCollection, ...collectionMap.chat_messages)
export const addressCollection = new ChildCollection(usersCollection, ...collectionMap.addresses)
export const farmAssociationsCollection = new ChildCollection(usersCollection, ...collectionMap.farmAssociations)
export const farmBalancesCollection = new ChildCollection(usersCollection, ...collectionMap.farmBalances)
export const paymentMethodsCollection = new ChildCollection(usersCollection, ...collectionMap.payment_methods)
export const couponsCollection = new ChildCollection(farmsCollection, ...collectionMap.coupons)
export const promoCodeCollection = new ChildCollection(farmsCollection, ...collectionMap.promo_codes)
export const payoutsCollection = new ClientCollection(...collectionMap.payouts)
export const productFeesCollection = new ChildCollection(farmsCollection, ...collectionMap.product_fees)
