import { MessageWithIcon } from '@components'
import { Divider, FontAwesome5IconName, Icon, LoadingView, Text } from '@elements'
import { pmt_CashMethod } from '@models/PaymentMethod'
import { isPayInFull } from '@models/Product'
import { MarkAsPaidInfo } from '@shared/types/v2/invoice'
import { PropsWithChildren, memo, useCallback, useContext, useState } from 'react'
import { Pressable, StyleSheet, TouchableWithoutFeedback, View } from 'react-native'
import { Input } from 'react-native-elements'

import Colors from '../../../../../constants/Colors'
import { OrderCreatorScreenContext } from '../OrderCreatorScreen.helper'

import { useCartService } from '@/hooks/useCart'
import { adminFarmIdSelector } from '@/redux/selectors'
import uuid from '@helpers/uuid'
import { PaymentSelector } from '@screens/PaymentMethods/PaymentSelection/PaymentSelector'
import { useSelector } from 'react-redux'

//will be used in the MarkAsPaid session in the OrderSummary component
function MarkAsPaidComponent({
  markAsPaidInfo,
  setMarkAsPaidInfo,
}: {
  markAsPaidInfo: MarkAsPaidInfo
  setMarkAsPaidInfo: (info: MarkAsPaidInfo) => void
}) {
  return (
    <View>
      <Text>This order will be marked as paid and a receipt will be sent to the customer</Text>
      <Divider clear />
      <Input
        inputStyle={styles.fontSize}
        multiline
        numberOfLines={2}
        placeholder="Add a note for how payment was recieved (optional)"
        value={markAsPaidInfo.note}
        onChangeText={(val) => setMarkAsPaidInfo({ ...markAsPaidInfo, note: val })}
      />
    </View>
  )
}

/** This component holds the payment options for the order creator and allows the farmer to make selections*/
export const OrderCreatorPaymentOptions = memo(function OrderCreatorPaymentOptions() {
  const [selected, setSelected] = useState<'send-invoice' | 'pay' | 'mark-paid'>()
  const [resetId, setResetId] = useState<string>('')
  const adminFarmId = useSelector(adminFarmIdSelector) || ''

  const {
    isWholesaleOrderCreator,
    keyedStatePayments: [{ markAsPaidInfo }, , setState, setters],
    paymentSelectorOptions,
  } = useContext(OrderCreatorScreenContext)

  const { cart, customer } = useCartService({
    cartServiceType: 'orderCreator',
    farmId: adminFarmId,
    isWholesale: isWholesaleOrderCreator,
  })

  const markPayFull = useCallback(() => {
    setSelected('mark-paid')
    setState((prev) => ({ ...prev, markAsPaidInfo: { markAsPaid: true, note: '' }, splitTender: [] }))
  }, [setSelected, setState])

  const selectPaymentMethods = useCallback(() => {
    setSelected('pay')
    setters.markAsPaidInfo({ markAsPaid: false, note: '' })
    // Every time we focus this payment selector we should reset the selector and start fresh
    setResetId(uuid())
  }, [setters])

  const sendInvoiceOption = useCallback(() => {
    setSelected('send-invoice')
    setState((prev) => ({
      ...prev,
      markAsPaidInfo: { markAsPaid: false, note: '' },
      splitTender: [{ paymentMethod: pmt_CashMethod }],
    }))
  }, [setState, setSelected])

  return (
    <View style={styles.padded}>
      <PaymentPillOption
        selected={selected === 'send-invoice'}
        onPress={sendInvoiceOption}
        icon="file-invoice-dollar"
        title="Send invoice to customer"
      />
      {cart.every((ci) => isPayInFull(ci.paymentSchedule)) && (
        <PaymentPillOption
          selected={selected === 'mark-paid'}
          onPress={markPayFull}
          icon="receipt"
          title="Mark paid and send receipt"
        >
          <MarkAsPaidComponent setMarkAsPaidInfo={setters.markAsPaidInfo} markAsPaidInfo={markAsPaidInfo} />
        </PaymentPillOption>
      )}
      <PaymentPillOption
        selected={selected === 'pay'}
        onPress={selectPaymentMethods}
        icon="credit-card"
        title="Choose payment method"
      >
        {customer ? (
          <LoadingView loading={!paymentSelectorOptions}>
            <PaymentSelector
              userId={customer.id}
              // We use the resetId as the identifier because that allows us to reset the selector on demand
              uniqueId={resetId}
              options={paymentSelectorOptions!}
              onSplitTenderUpdated={setters.splitTender}
            />
          </LoadingView>
        ) : (
          <MessageWithIcon icon="user-slash" title="No Customer Selected">
            You must select a customer to choose one of their payment methods
          </MessageWithIcon>
        )}
      </PaymentPillOption>
    </View>
  )
})

type PillOptions = {
  selected: boolean
  onPress: () => void
  icon: FontAwesome5IconName
  title: string
}

function PaymentPillOption(props: PropsWithChildren<PillOptions>) {
  return (
    <Pressable onPress={props.onPress} style={[styles.pillStyle, props.selected && styles.pillSelected]}>
      <View style={styles.pillHeader}>
        <Icon name={props.icon} size={14} />
        <Text style={styles.headerText} type="medium">
          {props.title}
        </Text>
        {props.selected && <Icon name="check-circle" size={14} />}
      </View>
      {props.selected && !!props.children && (
        <>
          <Divider clear />
          {/*This is required to prevent click events inside the children from bubbling up */}
          <TouchableWithoutFeedback>{props.children}</TouchableWithoutFeedback>
        </>
      )}
    </Pressable>
  )
}

const styles = StyleSheet.create({
  padded: {
    padding: 15,
  },
  pillStyle: {
    borderWidth: 1,
    borderRadius: 10,
    borderColor: Colors.shades[200],
    padding: 15,
    marginVertical: 10,
  },
  pillSelected: {
    borderColor: Colors.green,
  },
  pillHeader: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerText: {
    flex: 1,
    paddingHorizontal: 10,
  },
  fontSize: {
    fontSize: 12,
  },
})
