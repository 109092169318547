import { fontSize, typography } from '@elements'
import { isJest } from '@helpers/Platform'
import {
  DefaultTheme,
  DocumentTitleOptions,
  NavigationContainerRefWithCurrent,
  NavigationState,
  ScreenListeners,
} from '@react-navigation/native'
import { StackNavigationEventMap, StackNavigationOptions } from '@react-navigation/stack'
import { MutableRefObject } from 'react'
import { Dispatch } from 'redux'

import Analytics from '../config/FBAnalytics'

import Sentry from '@/config/Sentry'
import Colors from '@/constants/Colors'
import { isAdminOpen, setNavRoute } from '@/redux/actions/appState'

export const navigationTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: Colors.white,
    primary: Colors.green,
  },
}

/** When navigator state changes, will set the navigation route name to global context.
 * - If imported in jest tests, sentry must be mocked despite not being used.
 */
export const onNavReady = (
  navRef: NavigationContainerRefWithCurrent<any>,
  routeNameRef: MutableRefObject<string | undefined>,
  dispatch: Dispatch,
) => {
  const route = navRef.getCurrentRoute()?.name
  if (route) {
    routeNameRef.current = route
    dispatch(setNavRoute(route))
    // Setup sentry for better route monitoring
    if (!isJest) Sentry.registerNavigationContainer(navRef)
  }
}

export const onNavStateChange = (
  navRef: NavigationContainerRefWithCurrent<any>,
  routeNameRef: MutableRefObject<string | undefined>,
  dispatch: Dispatch,
) => {
  const previousRouteName = routeNameRef.current

  const currentRouteName = navRef.getCurrentRoute()?.name

  if (currentRouteName && previousRouteName !== currentRouteName) {
    dispatch(setNavRoute(currentRouteName))
    // Save the current route name for later comparison
    routeNameRef.current = currentRouteName
    // Will set the current screen in FB Analytics
    Analytics.setCurrentScreen(currentRouteName)
  }
}

export const documentTitle: DocumentTitleOptions = {
  formatter: (options, route) => {
    const name = options?.title ?? route?.name
    if (!name || name === 'Consumer') return 'GrownBy'
    return `GrownBy | ${name}`
  },
}

export const stackScreenOptions: StackNavigationOptions = {
  headerShown: false,
  headerMode: 'screen',
  headerTitleStyle: {
    fontFamily: typography.body.medium,
    color: Colors.shades['500'],
    fontSize: fontSize(14, 2),
  },
  headerTitleAllowFontScaling: false,
}

/** Returns the screen listeners prop for the main app navigator */
export const getScreenListeners = (dispatch: Dispatch): ScreenListeners<NavigationState, StackNavigationEventMap> => ({
  focus: (e) => {
    // Listen for Admin Navigator focus and auto dispatch isAdminOpen
    if (e.target?.includes('AdminDrawerNavigator')) {
      dispatch(isAdminOpen(true))
    } else dispatch(isAdminOpen(false))
  },
})
