import { Logger } from '@/config/logger'
import Colors from '@/constants/Colors'
import { ModalComponentProps } from '@/constants/types/modalTypes'
import { useApiFx } from '@/hooks/useApiFx'
import useKeyedState from '@/hooks/useKeyedState'
import { Button, ErrorText, Modal, Text, hideModal } from '@elements'
import { bullet, capitalize } from '@helpers/display'
import { errorToString, isNonNullish, nonEmptyString } from '@helpers/helpers'
import { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { BulkEditModalGenericProps } from './products/product'

type Doc = { id: string }

export type BulkDeleteCompProps<T extends Doc> = Pick<
  BulkEditModalGenericProps<T, any>,
  'ids' | 'getItemDisplayName' | 'fetchByIds'
> & {
  /** plural noun for the model name ("products", "schedules", etc) */
  modelDisplayName: string
  /** validates the deletion of a doc by id */
  validate: (id: string) => Promise<void>
  /** this should perform the deletion of the ids provided, will only be called with the ids that passed validation.  */
  onPassValidation: (ids: string[]) => Promise<void>
}

function BulkDeleteComp<T extends Doc>({
  ids,
  fetchByIds,
  validate,
  onPassValidation,
  modelDisplayName,
  getItemDisplayName = (doc) => {
    if ('name' in doc && nonEmptyString(doc.name)) return doc.name
    return doc.id
  },
}: BulkDeleteCompProps<T>) {
  const [{ isSubmitting, submitError, validationErrors }, set, setState] = useKeyedState({
    isSubmitting: false,
    submitError: undefined as unknown,
    validationErrors: [] as { error: unknown; id: string }[],
    /** the currently selected item from the picker (field value option) */
  })

  const onSubmit = async () => {
    setState((p) => ({ ...p, isSubmitting: true, validationErrors: [], submitError: undefined }))
    const results = await Promise.all(
      ids.map(async (id) => {
        try {
          await validate(id)
        } catch (error) {
          return { error, id }
        }
      }),
    )

    const validationErrors = results.filter(isNonNullish)
    const idsToDelete = ids.filter((id) => !validationErrors.find((e) => e.id === id))

    if (idsToDelete.length === 0) {
      return setState((p) => ({ ...p, isSubmitting: false, validationErrors }))
    }

    try {
      await onPassValidation(idsToDelete)
      hideModal()
    } catch (error) {
      Logger.error(error)
      set('submitError', error)
    } finally {
      setState((p) => ({ ...p, isSubmitting: false, validationErrors }))
    }
  }

  const errorIds = useMemo(() => validationErrors.map(({ id }) => id), [validationErrors])

  const { data: errorDocs, loading: loadingErrorDocs } = useApiFx(fetchByIds, [errorIds], !!errorIds.length, {
    failedConditionMode: 'stop-loading',
  })

  const warning = useMemo(() => {
    let txt = ''

    if (validationErrors.length) {
      txt += `Some ${modelDisplayName} were not deleted: \n\n`

      if (loadingErrorDocs || !errorDocs) return txt + 'Please wait, we are fetching the results...'

      validationErrors.forEach(({ id, error }) => {
        const doc = errorDocs.find((doc) => doc.id === id)
        const itemName = doc ? getItemDisplayName(doc) || id : id
        txt += ` ${bullet} "${itemName}": ${errorToString(error)}\n`
      })
    }
    return txt
  }, [modelDisplayName, loadingErrorDocs, errorDocs, getItemDisplayName, validationErrors])

  const errorText = useMemo(() => {
    let txt = ''

    if (submitError) {
      txt += 'There was a problem submitting this request. Try again \n\n'
    }
    return txt
  }, [submitError])

  return (
    <View style={styles.main}>
      <View style={styles.content}>
        <Text>{`Are you sure you want to delete these ${modelDisplayName}? You cannot undo this action.`}</Text>
        {!!warning && (
          <Text style={styles.warningText} color={Colors.brown}>
            {warning}
          </Text>
        )}
      </View>
      <View style={styles.buttonsCont}>
        <Button
          title={`Delete ${capitalize(modelDisplayName)}`}
          onPress={onSubmit}
          loading={isSubmitting}
          style={styles.deleteButton}
        />
        <Button
          title="Cancel"
          onPress={hideModal}
          disabled={isSubmitting}
          style={styles.cancelButton}
          textStyle={styles.cancelButtonText}
        />
      </View>
      {errorText ? <ErrorText>{errorText}</ErrorText> : null}
    </View>
  )
}

export type OpenBulkDeleteOpts<T extends Doc> = ModalComponentProps & {
  contentProps: BulkDeleteCompProps<T>
}

/** opens the bulk delete modal */
export function openBulkDelete<T extends Doc>({ contentProps, ...modalComponentProps }: OpenBulkDeleteOpts<T>) {
  Modal(<BulkDeleteComp {...contentProps} />, modalComponentProps)
}

const styles = StyleSheet.create({
  main: {
    padding: 10,
    alignContent: 'center',
  },
  content: {
    padding: 15,
  },
  warningText: {
    backgroundColor: Colors.shadeGold,
    borderRadius: 20,
    padding: 10,
    margin: 10,
  },
  buttonsCont: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
  },
  deleteButton: {
    backgroundColor: Colors.red,
  },
  cancelButton: {
    backgroundColor: Colors.white,
    borderColor: Colors.red,
  },
  cancelButtonText: {
    color: Colors.red,
  },
})
