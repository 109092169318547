import { Order, Pickup } from '@models/Order'
import { dateTimeInZone } from '@models/Timezone'

import { defaultOrderOptionSelection, defaultTimeRangeOptionSelection } from '../AdminCustomerOrdersSection'

import { useDeepCompareCallback } from '@/hooks/useDeepEqualEffect'
import { matchPickupItemsFromProductAndOrder } from '@helpers/pickups'

//orderSelection data
export const ordersSelection = [
  { value: 'unComProgress', label: 'Upcoming / In Progress Orders' },
  { value: 'complete', label: 'Completed Orders' },
]

//timeSelection data
export const timeRangeSelection = [
  { value: 'last30Days', label: 'Last 30 Days' },
  { value: 'last3Months', label: 'Last 3 Months' },
  { value: 'last6Months', label: 'Last 6 Months' },
]

//Func: valueChange on orders condition selection
export const useOnOrderOptionChange = (
  orders: Order[],
  pickups: Pickup[],
  setOrderSelected: React.Dispatch<React.SetStateAction<string>>,
  setDisplayOrders: React.Dispatch<React.SetStateAction<Order[]>>,
  timeRangeSelected: string,
) =>
  useDeepCompareCallback(
    (value: string) => {
      const filteredOrders = filterOrders(orders, value, pickups)
      const filteredOrdersByTime = filterOrdersByTime(filteredOrders, timeRangeSelected)
      setDisplayOrders(filteredOrdersByTime)
      setOrderSelected(value)
    },
    [orders, pickups, setDisplayOrders, setOrderSelected, timeRangeSelected],
  )

//Func: valueChange on time dondition selection
export const useOnTimeRangeChange = (
  orders: Order[],
  pickups: Pickup[],
  setTimeRangeSelected: React.Dispatch<React.SetStateAction<string>>,
  setDisplayOrders: React.Dispatch<React.SetStateAction<Order[]>>,
  orderOptionsSelected: string,
) =>
  useDeepCompareCallback(
    (value: string) => {
      const filteredOrders = filterOrders(orders, orderOptionsSelected, pickups)
      const filteredOrdersByTime = filterOrdersByTime(filteredOrders, value)
      setDisplayOrders(filteredOrdersByTime)
      setTimeRangeSelected(value)
    },
    [orders, orderOptionsSelected, pickups, setDisplayOrders, setTimeRangeSelected],
  )

//Func: filterOrders base on order condition function
export const filterOrders = (orders: Order[], value: string, pickups: Pickup[]) => {
  //filter orders based on selection
  let filteredOrders: Order[]
  if (value === defaultOrderOptionSelection) {
    filteredOrders = orders
  } else if (value === 'unComProgress') {
    //get unComProgress orders
    filteredOrders = orders.filter((order) => {
      //get the order items that are not cancelled
      const itemsNotCancelled = order.items.filter((item) => {
        if (item.cancelled) {
          return false
        }
        return true
      })
      //check if the order items still have pickups after today.
      const itemsStillInPickupRange = itemsNotCancelled.filter((itemNotCancelled) => {
        const now = dateTimeInZone()
        const nextPickup = pickups.find((pickup) => {
          return pickup.items.find((item) => {
            return pickup.date > now && matchPickupItemsFromProductAndOrder(itemNotCancelled.product.id, order.id)(item)
          })
        })
        if (nextPickup) return true
      })

      if (itemsStillInPickupRange.length > 0) {
        return true
      }
      return false
    })
  } else {
    //get completed orders
    filteredOrders = orders.filter((order) => {
      //get the order items that are not cancelled
      const itemsNotCancelled = order.items.filter((item) => {
        if (item.cancelled) {
          return false
        }
        return true
      })
      //check if the order items still have pickups after today.
      const itemsStillInPickupRange = itemsNotCancelled.filter((itemNotCancelled) => {
        const now = dateTimeInZone()
        const nextPickup = pickups.find((pickup) => {
          return pickup.items.find((item) => {
            return pickup.date > now && matchPickupItemsFromProductAndOrder(itemNotCancelled.product.id, order.id)(item)
          })
        })
        if (nextPickup) return true
      })

      if (itemsStillInPickupRange.length > 0) {
        return false
      }
      return true
    })
  }
  return filteredOrders
}

//Func: filterOrders by Time function
export const filterOrdersByTime = (orders: Order[], value: string) => {
  //filter orders based on Time
  let filteredOrders: Order[]
  if (value === defaultTimeRangeOptionSelection) {
    filteredOrders = orders
  } else if (value === 'last30Days') {
    filteredOrders = orders.filter((order) => {
      const thirtyDaysAgo = dateTimeInZone().minus({ days: 30 }).plus({ day: 1 }).startOf('day')
      return order.date > thirtyDaysAgo
    })
  } else if (value === 'last3Months') {
    filteredOrders = orders.filter((order) => {
      const threeMonthsAgo = dateTimeInZone().minus({ months: 3 }).plus({ day: 1 }).startOf('day')
      return order.date > threeMonthsAgo
    })
  } else {
    filteredOrders = orders.filter((order) => {
      const sixMonthsAgo = dateTimeInZone().minus({ months: 6 }).plus({ day: 1 }).startOf('day')
      return order.date > sixMonthsAgo
    })
  }
  return filteredOrders
}
