import { Distribution } from '@models/Distribution'
import { CartItem, CartItemBase } from '@models/Order'
import { AddonShare, PrimaryShare, isPrimary } from '@models/Product'

import { removeDuplicates, removeObjDuplicates } from './helpers'
import { getPickups } from './order'
import { hasPickups, isInStock, shouldShow } from './products'

/**
 * Checks if an addon has schedules and csas in common with an array of primary shares.
 *
 * - Used to filter out addons with no potentially matching shares, so as to only show relevant options.
 * - This doesn't determine if the addon is available to add to cart; Only helps filtering out addons with no potential matches */
export const addonHasPotentialMatches = (addon: AddonShare, primaries: PrimaryShare[]) => {
  const primariesScheduleIds = removeDuplicates(primaries.flatMap((prim) => prim.distributions).map((d) => d.id))
  const primariesCsaIds = removeDuplicates(primaries.flatMap((prim) => prim.csa))

  const hasCompatibleSchedules = addon.distributions.some(({ id }) => primariesScheduleIds.includes(id))
  const hasCompatibleCsas = addon.csa.some((id) => primariesCsaIds.includes(id))

  return hasCompatibleSchedules && hasCompatibleCsas
}

export type AvailAddonResult = {
  /** Whether this addon is available for adding to the cart */
  isAvail: boolean
  /** The reason why the addon is not available for adding to the cart */
  unavailReason?: string
  /** The id of the addon for keys to aid in rendering **/
  id: string
  /** Those csa ids from the addon which have an eligible match */
  matchingCSAIds: string[]
  /** Those distribution ids from the addon which have an eligible match */
  matchingDistIds: string[]
}

/** Determines which addons are available for adding to the cart, based on primary shares from a list of cart items.
 *
 * @param cartItems list of cart items. Represents the items in the cart. Only need the ones with primary shares
 * @param addons list of addons to check for availability. These should be the addons for the farm whose items are in the cart.
 * @returns a list of results for each addons. This tells you whether each addon is available or not, and the reason if not.
 *
 * - This should also be used to determine available addons from past purchases. To do that, the order items must be transformed into the expected cart item type.
 *
 * The definition of an available addon is:
 * - Must be selling now: (Not hidden, Has schedules assigned, Has future pickups, Is in stock)
 * - Must have a csa id and schedule id that matches the selected csa and schedule id of at least one primary share in the cart.
 * - Must have a schedule id that matches the selected schedule id of at least one primary share in the cart.
 * - Addon pickups must be a subset of a primary share's pickups in the cart.
 */
export function getAvailAddonsFromCart(
  cartItems: Pick<CartItem, 'product' | 'distribution' | 'csa'>[],
  addons: AddonShare[],
): AvailAddonResult[] {
  const availAddOnsCart: AvailAddonResult[] = []

  const cartPrimaries = cartItems.filter((ci): ci is CartItemBase<PrimaryShare> => isPrimary(ci.product))
  const cartPrimaryCsaIds = removeDuplicates(cartPrimaries.map((ci) => ci.csa.id))
  const cartPrimaryScheduleIds = removeDuplicates(cartPrimaries.map((ci) => ci.distribution.id))

  addons.forEach((addon) => {
    if (!cartPrimaries.length)
      return availAddOnsCart.push({
        isAvail: false,

        id: addon.id,
        matchingCSAIds: [],
        matchingDistIds: [],
        unavailReason:
          'You have no Primary Shares in your cart. To purchase this addon you need a compatible Primary Share in your cart',
      })

    const matchingCSAIds = addon.csa.filter((csaId) => cartPrimaryCsaIds.includes(csaId))
    if (!matchingCSAIds.length)
      return availAddOnsCart.push({
        isAvail: false,

        id: addon.id,
        matchingCSAIds: [],
        matchingDistIds: [],
        unavailReason:
          'The Primary Share/s in your cart belong to different CSA/s. To purchase this addon you need to have a Primary Share in your cart from the same CSA',
      })

    const matchingSchedulesById = addon.distributions.filter((addonSch) => cartPrimaryScheduleIds.includes(addonSch.id))
    if (!matchingSchedulesById.length)
      return availAddOnsCart.push({
        isAvail: false,

        id: addon.id,
        matchingCSAIds: [],
        matchingDistIds: [],
        unavailReason:
          'The Primary Share/s in your cart have no schedules in common with this addon. To purchase this addon, your cart needs to need to have a Primary Share available at a matching schedule',
      })

    if (!shouldShow(addon))
      return availAddOnsCart.push({
        isAvail: false,

        id: addon.id,
        matchingCSAIds: [],
        matchingDistIds: [],
        unavailReason: 'The addon is hidden or has no schedules',
      })

    if (!isInStock(addon))
      return availAddOnsCart.push({
        isAvail: false,

        id: addon.id,
        matchingCSAIds: [],
        matchingDistIds: [],
        unavailReason: 'The addon is out of stock',
      })

    if (!hasPickups(addon, { useCache: true }))
      return availAddOnsCart.push({
        isAvail: false,

        id: addon.id,
        matchingCSAIds: [],
        matchingDistIds: [],
        unavailReason: 'The addon has no pickups left',
      })

    const matchingSchedulesByPickup = removeObjDuplicates(
      cartPrimaries.flatMap((ci) => getSchedulesWithMatchingPickups(addon, ci)),
    )
    if (!matchingSchedulesByPickup.length)
      return availAddOnsCart.push({
        isAvail: false,

        id: addon.id,
        matchingCSAIds: [],
        matchingDistIds: [],
        unavailReason:
          'The dates for this addon do not match with the dates of a Primary Share in your cart. The addon dates must be a subset of the dates from a Primary Share in your cart',
      })

    return availAddOnsCart.push({
      isAvail: true,

      id: addon.id,
      matchingCSAIds,
      matchingDistIds: matchingSchedulesByPickup.map((sch) => sch.id),
    })
  })

  return availAddOnsCart
}

/**
 * Gets the addon schedules that have matching pickups with a primary share cartItem. This is an internal helper for the calculation of available addons.
 *
 * @param addon the addon for which to find matching schedules
 * @param cartPrimary a cartItem with a primary share and a selected schedule belonging to it. this is meant to come from a cart item, where one of the primary's schedules was selected when adding to the cart.
 * @returns an array of the addon schedules which have matching pickups with this cartitem's schedule.
 *
 * The criteria for matching is:
 * 1. The addon schedule must have the same id as the selected cartPrimary schedule
 * 2. The addon schedule pickups must be a subset of the primaryShare pickups at the selected cartPrimary schedule
 */
export function getSchedulesWithMatchingPickups(
  addon: AddonShare,
  { product: primary, distribution: primarySchedule }: Pick<CartItemBase<PrimaryShare>, 'product' | 'distribution'>,
): Distribution[] {
  return addon.distributions.filter((addonSch) => {
    // Must be the same schedule from the cartItem
    if (addonSch.id !== primarySchedule.id) return false

    // Addon pickups must be a subset of the primary's pickups at the selected schedule
    // In other words, all the addon pickups must be present in the primary share pickups
    const addonPickups = getPickups(primarySchedule, addon, {
      excludeClosedDistros: true,
    }).map((p) => p.toISODate())

    const primaryPickups = getPickups(primarySchedule, primary, {
      excludeClosedDistros: true,
    }).map((p) => p.toISODate())

    for (const p of addonPickups) {
      if (!primaryPickups.includes(p)) return false
    }
    return true
  })
}
