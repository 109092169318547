import { updateUser } from '@api/Users'
import { Notification } from '@models/Notification'
import * as Notifications from 'expo-notifications'

import {
  SET_AVAIL_ADDONS,
  SET_AVAIL_ADDONS_CSAS,
  SET_AVAIL_ADDONS_PURCHASES,
  SET_MY_FARMS,
  SET_NOTIFICATIONS,
  SET_SIGNEDIN_TIME,
  SET_USER_LOCATION,
  SET_WHOLESALE,
  UPDATE_CART_INFO,
} from '../actions/actionTypes'
import { AppPersist } from './types'

export const initialState: AppPersist = {
  wholesale: {},
  farmsAssoc: [],
  userLocation: undefined,
  notifications: [],
  unreadCount: 0,
  availAddonsPurchases: { data: [], hash: 1 },
  availAddons: [],
  availAddonCsas: [],
  cartInfo: {},
  signedInTime: undefined,
}

const appPersistReducer = (state = initialState, action: any): AppPersist => {
  switch (action.type) {
    case UPDATE_CART_INFO:
      return { ...state, cartInfo: action.payload }
    case SET_MY_FARMS:
      return { ...state, farmsAssoc: action.payload }
    case SET_USER_LOCATION:
      return { ...state, userLocation: action.payload }
    case SET_AVAIL_ADDONS_PURCHASES:
      return { ...state, availAddonsPurchases: action.payload }
    case SET_AVAIL_ADDONS:
      return { ...state, availAddons: action.payload }
    case SET_AVAIL_ADDONS_CSAS:
      return { ...state, availAddonCsas: action.payload }
    case SET_NOTIFICATIONS: {
      const unread = action.payload.notifs.filter((el: Notification) => !el.isRead)
      Notifications.getBadgeCountAsync().then((count) => {
        if (count !== unread.length) {
          Notifications.setBadgeCountAsync(unread.length)
          if (action.payload.userNoteCount !== unread.length)
            updateUser({ id: action.payload.userId, notificationBadgeCount: unread.length || 0 })
        }
      })
      return { ...state, notifications: action.payload.notifs, unreadCount: unread.length }
    }
    case SET_SIGNEDIN_TIME: {
      return { ...state, signedInTime: action.payload }
    }
    case SET_WHOLESALE: {
      return { ...state, wholesale: { ...state.wholesale, ...action.payload } }
    }
    default:
      return state
  }
}
export default appPersistReducer
