import { grownbyWebsiteBaseUrl } from '@/config/Environment'
import { isWeb } from '@/constants/Layout'
import { openUrl } from '@helpers/client'
import { StackNavigationProp } from '@react-navigation/stack'

/** This function is used to generate correct way to connect to product or templateProduct screen */
export function getProductLink(
  navigation: StackNavigationProp<any>,
  productId: string,
  templateProductId?: string,
  isWholesale?: boolean,
) {
  if (isWeb) {
    const url = templateProductId
      ? `${grownbyWebsiteBaseUrl(isWholesale)}admin/products/edit-template/${templateProductId}`
      : `${grownbyWebsiteBaseUrl(isWholesale)}admin/products/edit/${productId}`

    openUrl(url, { target: '_blank' })
  } else {
    if (templateProductId) {
      navigation.navigate('Products', {
        screen: 'EditTemplate',
        params: { templateId: templateProductId },
      })
    } else {
      navigation.navigate('Products', {
        screen: 'EditProduct',
        params: { prodId: productId },
      })
    }
  }
}
